import { FC } from "react";
import { TypeListButtons } from "./ListButtons";
import LoadingActivities from "Components/UI/CustomLoading/LoadingActivities";
import LoadingActivitiesList from "Components/UI/CustomLoading/LoadingActivitiesList";

interface IActiveLoading {
  activeListButton: TypeListButtons;
  height: number;
}

const ActiveLoading: FC<IActiveLoading> = ({ activeListButton, height }) => {
  if (activeListButton === 'kanban') {
    return <LoadingActivities multiple={4} height={height} />;
  }
  
  if (activeListButton === 'list') {
    return <LoadingActivitiesList multiple={10} height={height} />;
  }

  if (activeListButton === 'detailVision') {
    return <></>;
  }

  return <></>;
}

export default ActiveLoading;
