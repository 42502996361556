import React, { FC } from 'react';
import Button from '../../../../Components/UI/Button';
import PlanoAvisoActivitiesUpgrade from '../../../../Static/images/plano-aviso-activities-upgrade.png';
import tagPro from '../../../../Static/images/tag-pro.png';
import { Icon } from '../../../../Components/UI';
import styles from './ActivitiesCTAUpgrade.module.scss';

interface IActivitiesCTAUpgrade {
  onConfirm: (role: number, order: number) => void;
  order?: number;
  role?: number;
  planFree?: boolean;
}

const ActivitiesCTAUpgrade: FC<IActivitiesCTAUpgrade> = ({ 
  onConfirm, 
  order,
  role, 
  planFree
}) => {
  const isAdmin = role === 1;
  
  return (
    <div className={styles['tag-wrapper']}>
      <div className={styles['wrapper']}>
        <div className={styles['tag-popularity']}>
          <img src={tagPro} alt="Tag Plano Pro" />
        </div>
        <div className={styles['imageWrapper']}>
          <img src={PlanoAvisoActivitiesUpgrade} alt="Imagem CTA Upgrade Activity" />
        </div>
        <div className={`${styles['contentWrapper']} ${!planFree ? styles['contentWrapperPro'] : ''}`}>
          <div>
            <label className={styles['avisoLimiteObras']}>
              {isAdmin 
                ? 'Atenção: você não possui acesso ao controle de atividades!'
                : 'Atenção: sua empresa não possui acesso ao controle de atividades!'
              }
            </label>
          </div>
          <h2>
            {planFree  
              ? isAdmin 
                  ? 'Dê um upgrade na sua conta gratuita'
                  : 'Dê um upgrade na conta da sua empresa'
              : isAdmin
                  ? 'Dê um upgrade no seu plano'
                  : 'Dê um upgrade no plano da sua empresa'
            }
          </h2>
          <h3>Para CONTROLAR atividades</h3>
          {planFree && (
            <>  
              <p>O plano Pro ainda proporciona:</p>
              <div className={styles['icons-wrapper']}>
                <div className={`${styles['icons-text-wrapper']} ${styles['icons-text-margin']}`}>
                  <div>
                    <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                    <span>Gestão de documentos 2D e 3D</span>
                  </div>
                  <div>
                    <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                    <span>Controle de nomeclatura</span>
                  </div>
                </div>
                <div className={styles['icons-text-wrapper']}>
                  <div>
                    <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                    <span>Gestão de usuários e papéis na obra</span>
                  </div>
                  <div>
                    <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                    <span>Controle de plotagem</span>
                  </div>
                </div>
                <div style={{ marginBottom: 14 }}>&nbsp;</div>
              </div>
            </>
          )}
          
          <div className={styles['button-wrapper']}> 
            <Button 
              className={styles['button-confirm']} 
              type='primary' 
              ispremium="true"
              width={228} 
              height={48} 
              onClick={() => onConfirm(role || 0, order || 0)}
            >
              <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
              {isAdmin 
                  ? 'Adquirir o plano Pro'
                  : 'Solicitar o plano Pro'
              }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesCTAUpgrade;
