import { FC, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import { Progress } from "antd";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { sizeFileRangeToInitials } from "Utils/toolBox";
import {
  getAttachmentsForUpload,
  getAttachmentsUploading,
} from "Store/Activities/Activities.selector";
import {
  ITopicAttachment,
  ITopicDnDResponse,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import AvatarsItems from "Pages/Activities/components/AvatarsItems";
import ActionAttachmentDropdown from "../../../ActionAttachmentDropdown";
import ImagePreviewGroup from "Components/UI/ImagePreviewGroup";
import ImagePreview from "Components/UI/ImagePreview";
import HeaderActionsButtons from "../HeaderActionsButtons";
import {
  actionImgPrint,
  actionDownloadImage,
  actionImgOpenInNewWindow,
} from "Utils/TextUtils";
import Ellipsis from "Components/UI/Ellipsis";
import { IUserData } from "Data/interfaces/User/IUserData";
import { Content, ImgCard } from "./styles";

import imgPlaceholder from 'Static/images/image_placeholder.webp';

interface IGaleryAttachmentsVision {
  updateActivity: ITopicDnDResponse | null;
  onHasPermissionDeleteAttachment: (creationUploadFk?: IUserData | null) => boolean;
  onCancel: (attachmentId: string | number) => void;
  onDelete: (attachment?: ITopicAttachment | null) => void;
}

const GaleryAttachmentsVision: FC<IGaleryAttachmentsVision> = ({
  updateActivity,
  onHasPermissionDeleteAttachment,
  onCancel,
  onDelete,
}) => {
  const theme = useSelector(getTheme);
  const attachmentsUploading = useSelector(getAttachmentsUploading);
  const attachmentsForUpload = useSelector(getAttachmentsForUpload);

  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);

  const filteredUploading = useMemo(() => {
    if (
      attachmentsUploading?.id &&
      attachmentsForUpload.attachmentsIds.includes(attachmentsUploading.id)
    ) {
      const attsIds = attachmentsForUpload.attachmentsIds.filter(att => att !== attachmentsUploading.id);
      return {
        ...attachmentsForUpload,
        attachmentsIds: attsIds,
      }
    }

    return attachmentsForUpload;
  }, [attachmentsForUpload, attachmentsUploading]);

  const currentAttachmentPreview = useMemo(() => {
    if (updateActivity?.Attachments && updateActivity.Attachments.length > 0) {
      return updateActivity.Attachments[currentAttachmentIndex];
    }

    return null;
  }, [currentAttachmentIndex, updateActivity]);

  return (
    <Content>
      {attachmentsUploading && (
        <ImgCard isprogress="true">
          <div className="image">
            <img
              src={imgPlaceholder}
              alt="Imagem de anexo"
            />
            <Progress
              percent={attachmentsUploading.percentage}
              size={[185, 5]}
              showInfo={false}
              trailColor={theme.colors.surface.surface}
              strokeColor={theme.colors.primary.primary}
              className="progressBar"
            />
            <div className="progressMask" />
          </div>

          <div className="imgContent">
            <div className="imgTextContent">
              <span className="textName">
                <Ellipsis phrase={attachmentsUploading.file?.name || ''} maxLength={42} />
              </span>
              <span className="textSize">
                {sizeFileRangeToInitials(
                  attachmentsUploading.sentSize,
                  attachmentsUploading.file?.size || 0,
                  true
                )
                }
              </span>
            </div>
          </div>

          <Button
            type="text"
            className="buttonCancelOrDelete"
            onClick={() => onCancel(attachmentsUploading.id)}
          >
            <Icon
              icon="cancelar"
              customSize={12}
              className="iconCancelOrDelete"
            />
          </Button>
        </ImgCard>
      )}
      {filteredUploading.attachmentsIds.map(imgId => {
        const attachment = filteredUploading.attachments[imgId];

        if (attachment && attachment?.file && imgId) {
          const hasError = attachment.isError;

          return (
            <ImgCard key={imgId} haserror={`${hasError}`}>
              <div className="image">
                {hasError && (
                  <Icon
                    icon="alerta"
                    customSize={24}
                    className="iconError"
                    error
                  />
                )}
                {!hasError && (
                  <img
                    src={imgPlaceholder}
                    alt="Imagem de anexo"
                  />
                )}
              </div>

              <div className="imgContent">
                <div className="imgTextContent">
                  <span className="textName">
                    <Ellipsis phrase={attachment.file.name} maxLength={42} />
                  </span>
                  <span className="textSize">
                    {hasError
                      ? 'Erro no upload do arquivo'
                      : sizeFileRangeToInitials(
                        0,
                        attachment.file.size,
                        true
                      )
                    }
                  </span>
                </div>
              </div>

              <Button
                type="text"
                className="buttonCancelOrDelete"
                onClick={() => onCancel(imgId)}
              >
                <Icon
                  icon={hasError ? 'excluir' : 'cancelar'}
                  customSize={12}
                  className="iconCancelOrDelete"
                />
              </Button>
            </ImgCard>
          )
        }

        return null;
      })}
      {updateActivity?.Attachments && updateActivity.Attachments.length > 0 && (
        <ImagePreviewGroup
          imagesForShow={updateActivity.Attachments.map(att => att.FileURL)}
          srcName={currentAttachmentPreview?.File.Name}
          visibleOn={false}
          current={currentAttachmentIndex}
          HeaderButtons={(
            <HeaderActionsButtons
              onPrint={() => {
                if (currentAttachmentPreview) {
                  actionImgPrint(currentAttachmentPreview.FileURL);
                }
              }}
              onDownload={() => {
                if (currentAttachmentPreview) {
                  actionDownloadImage(currentAttachmentPreview.FileURL, currentAttachmentPreview.File.Name);
                }
              }}
              onOpen={() => {
                if (currentAttachmentPreview) {
                  actionImgOpenInNewWindow(currentAttachmentPreview.FileURL);
                }
              }}
            />
          )}
          onCurrentIndex={setCurrentAttachmentIndex}
        >
          {updateActivity?.Attachments.map((attachment, index) => {
            if (attachment) {
              const hasPermission = onHasPermissionDeleteAttachment(
                attachment?.File?.CreatorUser
              );

              return (
                <ImgCard
                  key={attachment.TopicAttachmentId}
                  onClick={() => setCurrentAttachmentIndex(index)}
                >
                  <div className="image">
                    <ImagePreview
                      src={attachment.Thumbnail128x128Url}
                      alt="Imagem de anexo"
                      onError={(e) => e.currentTarget.src = imgPlaceholder}
                    />
                  </div>

                  <div className="imgContent">
                    <div className="imgTextContent">
                      <span className="textName">
                        <Ellipsis phrase={attachment.File.Name} maxLength={45} />
                      </span>
                      <span className="imgTextCreatorContent">
                        {attachment.File.CreatorUser && (
                          <AvatarsItems
                            users={[attachment.File.CreatorUser]}
                            customNormalSize={16}
                            customMarginRight="0"
                            customMarginTop="-10px"
                            showDetail
                          />
                        )}
                        <span className="textCreator">
                          {attachment.File.CreationDate.format('DD/MM/YYYY HH:mm')}
                        </span>
                      </span>
                    </div>
                  </div>

                  <ActionAttachmentDropdown
                    hasDeletePermission={hasPermission}
                    onDownload={() => actionDownloadImage(attachment.FileURL, attachment.File.Name)}
                    onDelete={() => onDelete(attachment)}
                    onOpenPage={() => actionImgOpenInNewWindow(attachment.FileURL)}
                  >
                    <Button
                      type="text"
                      className="btnOpenActionAttachment"
                    >
                      <Icon
                        icon="moreActions"
                        customSize={14}
                        className="iconOpenActionAttachment"
                      />
                    </Button>
                  </ActionAttachmentDropdown>
                </ImgCard>
              )
            }

            return null;
          })}
        </ImagePreviewGroup>
      )}
    </Content>
  )
};

export default GaleryAttachmentsVision;
