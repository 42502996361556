import { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { Buttons, FooterHr, Wrapper } from "./styles";

interface ILimitsInfo {
  visible: boolean;
  isExtensionLimit?: boolean;
  onCancel: () => void;
}

const LimitsInfo: FC<ILimitsInfo> = ({
  visible,
  isExtensionLimit,
  onCancel,
}) => {
  const footerConfirmButtons = (
    <Buttons>
      <Button
        onClick={onCancel}
        type="text"
        className="buttonCancel"
      >
        Fechar
      </Button>
    </Buttons>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      footerActive={false}
      width={350}
      customClassName
    >
      <Wrapper>
        <div className="titleContent">
          <span className="title">
            {isExtensionLimit
              ? 'Formato de arquivo não suportado'
              : 'Tamanho máximo de arquivo excedido'
            }
          </span>
        </div>
        <hr className="midleHr"/>
        <div className="descriptionContent">
          <span className="description">
            {isExtensionLimit
              ? 'O formato do arquivo que você está tentando carregar não é suportado pelo nosso sistema.'
              : 'O tamanho do arquivo que você está tentando carregar é muito grande.'
            }
          </span>
          <span className="description">
            {isExtensionLimit
              ? 'Apenas arquivos de imagem com o formato JPG, PNG, GIF e JPEG são suportados.'
              : 'O tamanho máximo suportado é de 128 MB.'
            }
          </span>
        </div>
      </Wrapper>
      <FooterHr />
    </Modal>
  )
}

export default LimitsInfo;
