import { FC } from "react";
import { Checkbox } from "antd";
import usePanelsEditModal from "./usePanelsEditModal";
import {
  coordlyOption,
  panelsOptions,
  panelsOptionsIcons,
  srcTypeOptions,
} from "./optionsObj";
import Modal from "Components/UI/Modal/Modal";
import { Icon } from "Components/UI/Icon";
import Input from "Components/UI/Input";
import Button from "Components/UI/Button";
import SelectFile from "./components/SelectFile";
import HTMLEditor from "Components/UI/HTMLEditor";
import { ErrorHelper } from "./components/ErrorHelper";
import { INewPanelsOptions } from "Data/interfaces/Panels/IPanelComponentsModels";
import {
  IPanelsResponse,
  PanelTypeEnum,
} from "Data/interfaces/Panels/IPanelsResponse";
import {
  CardSrcContainer,
  CardSrcOption,
  Container,
  Divider,
  InputContainer,
  Left,
  LeftOptionRow,
  Main,
  Right,
  RightFooterContainer,
  RightFooterContent,
  RightMainContainer,
  TitleContainer,
} from "./styles";

interface IPanelsEditModal {
  visible: boolean;
  active: INewPanelsOptions;
  csId: number;
  panelForEdit?: IPanelsResponse | null;
  onActivePanel: (panel: INewPanelsOptions) => void;
  onCancel: () => void;
}

const PanelsEditModal: FC<IPanelsEditModal> = ({
  visible,
  active,
  panelForEdit,
  csId,
  onActivePanel,
  onCancel,
}) => {
  const {
    isUpdate,
    inputPanelFileContentValue,
    inputPanelNameValue,
    activeSrcTypeOption,
    inputPanelStringContentValue,
    error,
    fixedValue,
    isCreateLoading,
    isDisabled,
    isUpdateLoading,
    setInputPanelNameValue,
    setInputPanelFileContentValue,
    setActiveSrcTypeOption,
    setInputPanelStringContentValue,
    setFixedValue,
    handleCreatePanel,
  } = usePanelsEditModal({
    active,
    csId,
    panelForEdit,
    onCancel,
  });

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerActive={undefined}
      footerButtons={null}
      width={750}
    >
      <Container>
        <TitleContainer>
          <span className="titleText">
            {isUpdate ? 'Editar painel' : 'Adicionar novo painel'}
          </span>
          <Icon
            icon="cancelar"
            customSize={10}
            onClick={onCancel}
            className="iconClose"
          />
        </TitleContainer>

        <Main>
          <Left>
            <LeftOptionRow
              key={coordlyOption.id}
              isactive={`${coordlyOption.id === active.id}`}
              onClick={() => onActivePanel(coordlyOption)}
            >
              {panelsOptionsIcons[coordlyOption.type]}
              <span className="leftOptionRowText">
                {coordlyOption.label}
              </span>
            </LeftOptionRow>
            <Divider />
            <div className="leftOptionList">
              {panelsOptions.map(option => (
                <LeftOptionRow
                  key={option.id}
                  isactive={`${option.id === active.id}`}
                  onClick={() => onActivePanel(option)}
                >
                  {panelsOptionsIcons[option.type]}
                  <span className="leftOptionRowText">
                    {option.label}
                  </span>
                </LeftOptionRow>
              ))}
            </div>
          </Left>

          <Right>
            <RightMainContainer>
              <span className="rightTitleText">
                {active.title}
              </span>
              <span className="rightDescriptionText">
                {active.description}
              </span>
              <Divider margin={16} />

              {active.id === PanelTypeEnum.InternalItem && (
                <SelectFile
                  isUpdate={isUpdate}
                  constructionSiteId={csId}
                  selectedFileFromMaleta={inputPanelFileContentValue}
                  panelName={inputPanelNameValue}
                  onPanelName={setInputPanelNameValue}
                  onSelectedFileFromMaleta={setInputPanelFileContentValue}
                />
              )}
              {active.id === PanelTypeEnum.Custom && (
                <CardSrcContainer>
                  {srcTypeOptions.map((option) => (
                    <CardSrcOption
                      key={option.id}
                      isactive={`${activeSrcTypeOption === option.id}`}
                      onClick={() => setActiveSrcTypeOption(option.id)}
                    >
                      {option.icon}
                      <span className="rightSrcTypeText">
                        {option.label}
                      </span>
                    </CardSrcOption>
                  ))}
                </CardSrcContainer>
              )}
              {active.id !== PanelTypeEnum.InternalItem && (
                <InputContainer>
                  <div className="rightInpuContainerHeader">
                    <span className="rightInputLabel">
                      <span className="rightInputLabelText">
                        Nome do painel<span className="danger">*</span>
                      </span>
                    </span>
                    <Input
                      label=""
                      placeholder="Ex: Sistema de..."
                      value={inputPanelNameValue}
                      onInput={setInputPanelNameValue}
                      inputClassName="rightInput"
                    />
                  </div>

                  <div className="rightInpuContainerMain">
                    <span className="rightInputLabel">
                      {panelsOptionsIcons[active.type]}
                      <span className="rightInputLabelText">
                        {active.id === PanelTypeEnum.Custom
                          ? `${activeSrcTypeOption.toString().toUpperCase()} de integração`
                          : `Link do ${active.label}`
                        }
                        <span className="danger">*</span>
                      </span>
                    </span>
                    {active.id !== PanelTypeEnum.Custom && (
                      <>
                        <Input
                          label=""
                          placeholder="Colar https://..."
                          value={inputPanelStringContentValue}
                          onInput={setInputPanelStringContentValue}
                          inputClassName="rightInput"
                          statusField={!!error ? 'error' : undefined}
                        />
                        {error && <ErrorHelper>{error}</ErrorHelper>}
                      </>
                    )}
                    {active.id === PanelTypeEnum.Custom && (
                      <>
                        {activeSrcTypeOption === 'url' && (
                          <Input
                            label=""
                            placeholder="Colar https://..."
                            value={inputPanelStringContentValue}
                            onInput={setInputPanelStringContentValue}
                            inputClassName="rightInput"
                          />
                        )}
                        {activeSrcTypeOption === 'html' && (
                          <HTMLEditor
                            html={inputPanelStringContentValue}
                            onHtml={setInputPanelStringContentValue}
                          />
                        )}
                      </>
                    )}
                  </div>
                </InputContainer>
              )}
            </RightMainContainer>

            <RightFooterContainer>
              <Divider margin={16} />

              <RightFooterContent>
                <div className="rightFooterContentLeft">
                  <Checkbox
                    checked={fixedValue}
                    onChange={() => setFixedValue(!fixedValue)}
                  >
                    <span className="rightCheckbox">
                      <Icon
                        icon="pin"
                        className="rightCheckboxIcon"
                      />
                      <span className="rightCheckboxText">
                        Fixar painel
                      </span>
                    </span>
                  </Checkbox>
                </div>
                <Button
                  type="primary"
                  loading={isCreateLoading}
                  disabled={isDisabled}
                  onClick={handleCreatePanel}
                >
                  {!isUpdate && (
                    isCreateLoading ? 'Adicionando...' : 'Adicionar painel'
                  )}
                  {isUpdate && (
                    isUpdateLoading ? 'Salvando...' : 'Salvar painel'
                  )}
                </Button>
              </RightFooterContent>
            </RightFooterContainer>
          </Right>
        </Main>
      </Container>
    </Modal>
  )
};

export default PanelsEditModal;
