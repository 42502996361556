import { FC, useEffect, useMemo, useState } from "react";
import { Divider as AntDivider } from "antd";
import CustomDomainPreview from "../../../../Static/images/custom-domain-preview.png";
import Button from "Components/UI/Button";
import Form from "Components/UI/Form/Form";
import schema from './customDomain.schema';
import { 
  CardSubTitle,
  CardTitle,
  DNSWrapper,
  Divider, 
  InfoWrapper, 
  InputDNSWrapper, 
  MainWrapperLeft, 
  MainWrapperRight,
  SvgCard,
} from "./styles";
import Field from "Components/UI/Field";
import { Icon } from "Components/UI";
import { Dispatch } from "redux";
import { MultiDomainPersonalizationStatusEnum } from "Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse";
import { useSelector } from "react-redux";
import { getDomain, getDomainLoading } from "Store/MultiDomain/MultiDomain.selector";
import { MultiDomainActions } from "Store/MultiDomain/MultiDomain.action";
import { ITenantPersonalizationUpdateData, IUpdateTenantPersonalizationRequest } from "Data/interfaces/MultiDomain/IUpdateMultiDomainPersonalization";
import ButtonsAction from "../ButtonsAction";
import { ICheckDNSRequest } from "Data/interfaces/MultiDomain/ICheckDNSRequest";

interface ICustomDomainTab {
  dispatch: Dispatch<any>;
  tenantFk?: number;
}

const CustomDomainTab: FC<ICustomDomainTab> = ({
  dispatch,
  tenantFk,
}) => {
  const domain = useSelector(getDomain);
  const loadingDomain = useSelector(getDomainLoading);

  const [statusDomain, setStatusDomain] = useState<MultiDomainPersonalizationStatusEnum>(domain.Status);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const defaultValue = useMemo(() => {
    if (
        statusDomain !== MultiDomainPersonalizationStatusEnum.NaoConfigurado && 
        statusDomain !== MultiDomainPersonalizationStatusEnum.Padrao && 
        domain.DomainName
      ) {
      const splitArray = domain.DomainName.split('.');
      const subdomainValue = splitArray.shift();
      const domainValue = splitArray.join('.');
      return {
        subdomain: subdomainValue,
        domain: domainValue,
      }
    }

    return {
      subdomain: '',
      domain: '',
    }
  }, [statusDomain, domain.DomainName])

  const handleUpdate = (
    useTenantFk: number,
    updateData: ITenantPersonalizationUpdateData[]
  ) => {
    if (useTenantFk && updateData.length > 0) {
      const request: IUpdateTenantPersonalizationRequest = {
        TenantFk: useTenantFk,
        updateData,
      }
      dispatch(MultiDomainActions.updateTenantPersonalization(request));
      setIsSubmiting(true);
    }
  }

  const handleSubmit = (values: any) => {
    if (tenantFk) {
      const updateData: ITenantPersonalizationUpdateData[] = [];

      if (
        values.subdomain && 
        typeof values.subdomain === 'string' &&
        values.domain &&
        typeof values.domain === 'string'
      ) {
        const newValue = `${values.subdomain}.${values.domain}`;
        updateData.push({
          path: "DomainName",
          op: 'replace',
          value: newValue,
        });
        updateData.push({
          path: "Status",
          op: 'replace',
          value: MultiDomainPersonalizationStatusEnum.ConfiguracaoEmAndamento,
        });
      }

      handleUpdate(tenantFk, updateData);
    }
  };

  const handleDNSConfirm = () => {
    if (tenantFk) {
      const request: ICheckDNSRequest = {
        tenantFk: tenantFk,
      }
      dispatch(MultiDomainActions.checkDNS(request));
      setIsSubmiting(true);
    }
  };

  const handleUpdateCancel = () => {
    setShowActionButtons(false);
  };

  useEffect(() => {
    if (isSubmiting && !loadingDomain) {
      setIsSubmiting(false);
      setShowActionButtons(false);
    }
  }, [loadingDomain, isSubmiting]);

  useEffect(() => {
    if (domain.Status) {
      setStatusDomain(domain.Status);
    }
  }, [domain.Status]);

  const initConfig = MultiDomainPersonalizationStatusEnum.NaoConfigurado === statusDomain || MultiDomainPersonalizationStatusEnum.Padrao === statusDomain;
  const DNSWating = MultiDomainPersonalizationStatusEnum.ConfiguracaoEmAndamento === statusDomain;
  const internalWating = MultiDomainPersonalizationStatusEnum.AguardandoAnaliseInterna === statusDomain;
  const OK = MultiDomainPersonalizationStatusEnum.ConfiguracaoConcluida === statusDomain;
  const DNSError = MultiDomainPersonalizationStatusEnum.ErroConfiguracao === statusDomain;

  const subDomainValue = (domain.DomainName && domain.DomainName.includes('.')) 
    ? domain.DomainName.split('.')[0] 
    : null;

  return (
    <>
      <MainWrapperLeft showInfo="true">
        <div className="cardHeader">
          <CardTitle>Conecte um domínio customizado</CardTitle>
          <CardSubTitle>
            Configure um subdomínio customizado (ex: projetos.seusite.com) para ter o sistema da Coordly no seu próprio domínio.
          </CardSubTitle>
          <AntDivider />
          {(initConfig || showActionButtons) && (
            <>
              <CardSubTitle isBold="true" noMargin="true">
                Adicione seu domínio:
              </CardSubTitle>
              <InputDNSWrapper showActionButton={`${showActionButtons}`}>
                <Form 
                  className="formWrapper" 
                  schema={schema} 
                  onSubmit={handleSubmit}
                  initialValues={defaultValue}
                >
                  <div className="formHeader">
                    <span className="infoText">https://</span>
                    <Field
                      name="subdomain"
                      label=""
                      placeholder="ex: projetos"
                      className="firstInput"
                    />
                    <span className="dot">.</span>
                    <Field
                      name="domain"
                      label=""
                      placeholder="ex: seusite.com"
                      className="lastInput"
                    />
                    {initConfig && (
                      <Button 
                        type="primary"
                        loading={loadingDomain}
                        htmlType="submit"
                      >
                        {loadingDomain ? 'Salvando...' : 'Salvar'}
                      </Button>
                    )}
                  </div>
                  <div className="formFooter">
                    <CardSubTitle noMargin="true">
                      Nota: só é possível cadastrar um subdomínio (ex: subdomain.domain.com), e não domínios raíz (ex: domain.com).
                    </CardSubTitle>
                    {showActionButtons && (
                      <>
                        <AntDivider />
                        <ButtonsAction
                          isSubmit
                          loading={loadingDomain}
                          onCancel={handleUpdateCancel}
                        />
                      </>
                    )}
                  </div>
                </Form>
              </InputDNSWrapper>
            </>
          )}
          {!showActionButtons && !initConfig && (
            <DNSWrapper isInternal={`${internalWating}`} isOK={`${OK}`}>
              {OK ? (
                <Icon
                  icon="confirmacao"
                  className="dnsConfirmIcon"
                  customSize={24}
                />
              ) : (
                <Icon
                  icon="relogio"
                  className="dnsRelogioIcon"
                  customSize={24}
                />
              )}
              <span className="infoWrapper">
                <span className="info">
                  {DNSWating && 'Aguardando configuração CNAME'}
                  {internalWating && 'Aguardando análise interna'}
                  {OK && 'Seu site está online.'}
                </span>
                <span className="dns">
                  https://
                  <span className="dnsBold">{domain.DomainName}</span>
                  /
                </span>
              </span>
              {(DNSWating || DNSError) && (
                <>
                  <Button 
                    type="text"
                    className="iconBtn"
                  >
                    <Icon
                      icon="editar"
                      className="dnsEditIcon"
                      customSize={10}
                      onClick={() => setShowActionButtons(true)}
                    />
                  </Button>
                  <Button 
                    type="primary"
                    loading={loadingDomain}
                    className="cnameBtn"
                    onClick={handleDNSConfirm}
                  >
                    {loadingDomain ? 'Verificando...' : 'Verificar CNAME'}
                  </Button>
                </>
              )}
              {(internalWating || OK) && (
                <Button 
                  type="text"
                  className="internalBtn"
                  onClick={() => setShowActionButtons(true)}
                >
                  Alterar
                </Button>
              )}
            </DNSWrapper>
          )}
          {(!initConfig) && (
            <>
              {!showActionButtons && (internalWating || DNSError) && (
                <InfoWrapper>
                  {internalWating && (
                    <span className="infoInternal">
                      Seu domínio já está configurado! Nosso time está analisando seu domínio e dentro de 48 horas ele estará online. Caso tenha alguma dúvida, <span className="infoInternalBold">entre em contato com nosso time de suporte.</span>
                    </span>
                  )}
                  {DNSError && (
                    <span className="infoError">
                      Não conseguimos verificar seu domínio. Verifique os registros CNAME e tente novamente.
                    </span>
                  )}
                </InfoWrapper>
              )}
            </>
          )}
        </div>

        {(DNSWating || DNSError) && (
          <div className="cardMain">
            <span className="title">Configure o registro CNAME</span>
            <span className="subTitle first">
              Para usar o sistema da Coordly no seu subdomínio, você precisa apontar seu subdomínio para o CNAME da Coordly. Para isso, siga <span className="subLink">o guia para configurar um registro CNAME no seu DNS.</span>
            </span>
            <span className="subTitle">
              Nota: a configuração do DNS no domínio pode demorar até uma hora para propagar e o domínio funcionar.
            </span>

            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Nome (host)</th>
                    <th>Destino (value)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CNAME</td>
                    <td>{subDomainValue ? subDomainValue : 'projetos'}</td>
                    <td>prd-web-maleta.azurewebsites.net</td>
                  </tr>
                  <tr>
                    <td>TXT</td>
                    <td>asuid.{subDomainValue ? subDomainValue : 'projetos'}</td>
                    <td>2DDA954477871E828F7D1DE5AE9EA99724EB2E4D22E0EAE8679F61188FFAF043</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <span className="subTitle">
              Caso esteja com problemas na configuração do domínio customizado, <span className="subBold">entre em contato com nosso time de suporte.</span>
            </span>
          </div>
        )}
      </MainWrapperLeft>

      <Divider />

      <MainWrapperRight> 
        <div className="fixedContainer">
          <SvgCard hasCustom={`${!!((internalWating || OK) && domain.DomainName)}`}>
            <img src={CustomDomainPreview} />
            <span className="overlap">
              {internalWating && (
                <Icon
                  icon="relogio"
                  className="overlapRelogioIcon"
                  customSize={12}
                />
              )}
              {OK && (
                <Icon 
                  icon="cadeado"
                  className="overlapCadeadoIcon"
                  customSize={12}
                />
              )}
              {((internalWating || OK) && domain.DomainName) 
                ? domain.DomainName
                : ''
              }
            </span>
          </SvgCard>
        </div>
      </MainWrapperRight>
    </>
  )
}

export default CustomDomainTab;
