import defaultHost from '../Static/multidomain/default.json';
import { 
  IMultiDomainPersonalizationResponse
} from 'Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse';

export const hostnamesDefault = [
  'app.coordly.co', 
  'app.maletadoengenheiro.com.br',
  'prd-web-maleta-stage.azurewebsites.net',
  'hmg-web-maleta.azurewebsites.net',
  'localhost'
]

export const getDefaultDomain: Record<string, IMultiDomainPersonalizationResponse> = {
  'default': defaultHost,
};

export const imagesAccepts =`
  image/apng,
  image/jpeg,
  image/pjpeg,
  image/png,
  image/webp,
`

export const imagesNotAccepts = [
  'image/svg+xml',
  'image/x-ico',
  'image/bmp',
  'image/gif',
  'image/tiff',
]

export const loadImage = (
  imageName?: string | File | null, 
  dynamicShow?: boolean,
) => {
  if (imageName !== null && imageName) {
    try {
      if (typeof imageName === 'string') {
        if (imageName.startsWith('http://') || imageName.startsWith('https://')) {
          return `${imageName}${dynamicShow ? `?v=${Math.random()}` : ''}`;
        }
        
        return require(`Static/images/${imageName}`);
      }

      if (typeof imageName === 'object') {
        return URL.createObjectURL(imageName);
      }

    } catch {
      return null;
    }
  }
  
  return null;
};
