import React, { FC, useEffect } from 'react';

import Modal from '../../../../Components/UI/Modal/Modal';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import Button from '../../../../Components/UI/Button';
import UsuariosConvidadosTab from '../Tabs/UsuariosConvidadosTab';
import PermissoesUsuariosTab from '../Tabs/PermissoesUsuariosTab';
import { IInvitedUser } from '../../../../Data/interfaces/User/IInvitedUser';
import { ISharedUser } from '../../../../Data/interfaces/User/ISharedUser';
import Tabs from '../../../../Components/UI/Tabs/Tabs';
import { ObraActions } from '../../../../Store/Obra/Obra.actions';
import IBindUsersConstructionSiteRequest from '../../../../Data/interfaces/User/IBindUsersConstructionSiteRequest';
import { Skeleton } from 'antd';
import { history } from 'Store';
import { IUserData } from 'Data/interfaces/User/IUserData';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface IUsuariosEPermissoes {
  isVisible?: boolean;
  onCancel?: () => void;
  maxUsers?: number;
  firstInvitedUsers?: IInvitedUser[];
}

const UsuariosEPermissoes: FC<Props & IUsuariosEPermissoes> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const [invitedUsers, setInvitedUsers] = React.useState<IInvitedUser[]>([]);
  const [sharedUsers, setSharedUsers] = React.useState<ISharedUser[]>([]);
  const [isSendingData, setSendingData] = React.useState<boolean>(false);
  const [newInvitedsUserandPerm, setNewInvitedsUserandPerm] = React.useState(0);

  if (isSendingData && !props.isLoading) {
    setSendingData(false);
    if (props.onCancel) {
      props.onCancel();
    }
  }
  
  const tenantUsers = () => {
    return [
      ...props.SharedUsers?.map((sharedUser) => sharedUser.User || {} as IUserData) || [],
      ...props.UnsharedUsers || [],
    ];
  };

  useEffect(() => {
    setInvitedUsers([...props.InvitedUsers || []]);
    setSharedUsers([...props.SharedUsers || []]);
  }, [props]);

  useEffect(() => {
    if (invitedUsers && props.firstInvitedUsers) {
      setNewInvitedsUserandPerm(invitedUsers.filter((user) => {
        return !props.firstInvitedUsers?.map(userAll => userAll.Email).includes(user.Email);
      })?.length);
    }
    if (invitedUsers && !props.firstInvitedUsers) {
      setNewInvitedsUserandPerm(invitedUsers.length);
    }
  }, [invitedUsers, props.firstInvitedUsers]);

  const updateUsers = (data: any) => {
    setInvitedUsers(data.InvitedUsers);
    setSharedUsers(data.SharedUsers);
  };

  const openUpgradePremium = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION', 
      props: {
        origin: 'convidar-usuarios-modal',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    if(props.currentRole === 1){
      if (props.onCancel) {
        props.onCancel();
      }
      history.push('/faturamento/planos');
    }else{
      if (props.onCancel) {
        props.onCancel();
      }
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium'); 
    }
     
  };
  const getTabs = () => {
    const tabs = [
      { title: 'Usuários', key: 'tabUsuario',
        children: (
          <UsuariosConvidadosTab 
            tenantRoles={props.tenantRoles}
            onConfirm={openUpgradePremium} 
            TenantSharedUsers={tenantUsers()} 
            sharedUsers={sharedUsers} 
            invitedUsers={invitedUsers}
            changeUsers={updateUsers}
            currentRole={props.currentRole}
            numberInvitedAllUsers={props.QtyActiveUsers}
            maxUsers={props.maxUsers}
            csId={props.ConstructionSiteId}
            firstInvitedUsers={props.firstInvitedUsers}
            isLoading={props.isLoading}
          />
        ),
      },
    ];
    if (invitedUsers.length || sharedUsers.length) {
      tabs.push({ title: 'Permissões', key: 'tabPermissoes',
        children: props.isLoading
          ? (<div style={{padding: 20}}><Skeleton loading={true} paragraph={{ rows: 4 }} /></div>)
          : (<PermissoesUsuariosTab 
              invitedUsers={invitedUsers} 
              sharedUsers={sharedUsers} 
              changeUsers={updateUsers}
            />),
       });
    }
    return tabs;
  };

  const bindUsers = () => {
    const filterInvitedUsers = invitedUsers.filter(user => props.allUsers?.tenantInvitedUsers.some(u => u.InvitedUserEmail === user.Email));
    const filterSharedUsers = props.allUsers?.tenantUsers?.filter(user => user.User.Id !== props.userInfo?.Id);
    const isFirstUser = ((filterInvitedUsers?.length || 0) <= 0) && ((filterSharedUsers?.length || 0) <= 0) && invitedUsers.length > 0;

    sharedUsers.forEach((sharedUser) => sharedUser.UserId = sharedUser.UserFk);
    const request: IBindUsersConstructionSiteRequest = {
      ConstructionSiteId: props.ConstructionSiteId || 0,
      InvitedUsers: invitedUsers,
      SharedUsers: sharedUsers,
      newInvitedUsers: newInvitedsUserandPerm,
      firstUserTracking: isFirstUser,
      userInfoTracking: props.userInfo,
      currentTenantTracking: currentTenant,
    };
    dispatch(ObraActions.bindUsersConstructionSite(request));
    setSendingData(true);
  };

  // TODO: Ver como vamos tratar a questão de nível de edição (papel da pessoa logada) dessa modal
  return (props.isVisible ?
    (<Modal width={860} title='Usuários' visible={props.isVisible} onCancel={props.onCancel}
      footerButtons={
        (<>
          <Button onClick={props.onCancel}>
            <span style={{ padding: '0 35px'}}>Cancelar</span>
          </Button>
          <Button loading={props.isLoading} type='primary' onClick={bindUsers}>
            <span style={{ padding: '0 35px'}}>Salvar</span>
          </Button>
        </>)
      } >
      <Tabs tabs={ getTabs() }/>
    </Modal>) : (<></>)
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra.Users,
  currentRole: state.auth.userInfo?.CurrentRoleFk,
  QtyActiveUsers: state.auth.userLimitsActive?.QtyActiveUsers,
  isLoading: state.obra.isLoadingGetUsersConstruction,
  tenantRoles: state.tenant.TenantRoles || [],
  allUsers: state.tenant.UsersWithConstruction,
  userInfo: state.auth.userInfo,
});

const connector = connect(
  mapState,
);

type Props = ConnectedProps<typeof connector>;

export default connector(UsuariosEPermissoes);
