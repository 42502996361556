import { FC, useEffect, useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";
import { Switch } from "antd";
import { history } from "Store";
import SpinCustom from "Components/UI/SpinCustom";
import Tooltip from "Components/UI/Tooltip";
import { 
  FooterButtons, 
  FooterHr, 
  Header, 
  InfoCard, 
  LinkCard, 
  LoadingWrapper, 
  SwitchCard, 
  Wrapper,
} from "./styles";
import { copyToClipboard } from "Utils/TextUtils";

interface IShareLink {
  visible: boolean;
  context?: number;
  shareLink?: string;
  loading?: boolean;
  onCancel: () => void;
}

const ShareLink: FC<IShareLink> = ({
  visible,
  loading,
  context,
  shareLink,
  onCancel,
}) => {
  const [shareShortlyLink, setShareShortlyLink] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);
  
  const defaultLnk = `${window.location.origin}/construtora/${context}?redirectUrl=${encodeURIComponent(`${history.location.pathname}${history.location.search}`)}`;

  const handleCurrentShareLink = () => {
    setShareShortlyLink(!shareShortlyLink);
  }

  const handleCopyCurrentShareLink = () => {
    if (shareShortlyLink && shareLink) {
      copyToClipboard(shareLink);
      setLinkCopy(true);

    } else {
      copyToClipboard(defaultLnk);
      setLinkCopy(true);
    }
  }

  useEffect(() => {
    if (shareLink) {
      setShareShortlyLink(true)
    }
  }, [shareLink]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (linkCopy) {
      timeout = setTimeout(() => {
        setLinkCopy(false);
      }, 1000)
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [linkCopy]);

  const footerConfirmButtons = (
    <FooterButtons>
      <Tooltip
        overlay={
          linkCopy
            ? <label style={{ color: 'green' }}>Link copiado!</label> 
            : "Copiar link"
        }
        placement="top"
      >
        <Button
          onClick={handleCopyCurrentShareLink}
          className="buttonCopy"
        >
          <Icon 
            icon="link"
            className="iconLink"
          />
          Copiar Link
        </Button>
      </Tooltip>
      <Button
        type="primary"
        onClick={onCancel}
        className="buttonClose"
      >
        Concluído
      </Button>
    </FooterButtons>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={!loading ? footerConfirmButtons : null}
      footerActive={false}
      width={400}
      style={{marginRight: '2px'}}
    >
      <Wrapper>
        <Header>
          <span className="title">
            Compartilhar lista de atividades
          </span>
          <Icon 
            icon="cancelar"
            onClick={onCancel}
            className="iconClose"
            customSize={12}
          />
        </Header>
        <hr className="midleHr"/>
        {!loading && (
          <>
            <span className="description">
              Compartilhe o link:
            </span>
            <LinkCard>
              <span className="shareLink">
                {shareShortlyLink ? (shareLink || '') : defaultLnk}
              </span>
              <Tooltip
                overlay={
                  linkCopy
                    ? <label style={{ color: 'green' }}>Link copiado!</label> 
                    : "Copiar link"
                }
                placement="top"
              >
                <div
                  className="customButtonCopy"
                  onClick={handleCopyCurrentShareLink}
                >
                  <Icon 
                    icon="copiar"
                    className="iconCopy"
                  />
                </div>
              </Tooltip>
            </LinkCard>
            {!!shareLink && (
              <SwitchCard checked={`${shareShortlyLink}`}>
                <Switch
                  size="small"
                  onChange={handleCurrentShareLink} 
                  className="antdSwitch"
                  checked={shareShortlyLink}
                />
                <span className="switchText">
                  Incluir filtros que estão aplicados na listagem.
                </span>
              </SwitchCard>
            )}
            <span className="subDescription">
              Acesso ao link
            </span>
            <InfoCard>
              <span className="leftCard">
                <Icon 
                  icon="cadeadoFechado"
                  className="infoIcon"
                />
              </span>
              <span className="rightCard">
                <span className="headerText">
                  Restrito à obra
                </span>
                <span className="subText">
                  Apenas os usuários da obra podem acessar usando o link.
                </span>
              </span>
            </InfoCard>
          </>
        )}
        {loading && (
          <LoadingWrapper>
            <SpinCustom size={32} />
            <span className="loadingText">
              Carregando...
            </span>
          </LoadingWrapper>
        )}
      </Wrapper>
      {!loading && <FooterHr />}
    </Modal>
  )
}

export default ShareLink;
