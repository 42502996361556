import React, { FC } from 'react';

import styles from './AvisoUpgradeConfirmacao.module.scss';
import Button from '../../../../../Components/UI/Button';
import Modal from '../../../../../Components/UI/Modal/Modal';
import LogoUpgradeConfirmacao from '../../../../../Static/images/logo-upgrade-confirmacao.png';

interface IAvisoUpgradeConfirmacao {
  visible?: boolean;
  onConfirm?: () => void;
}

const AvisoUpgradeConfirmacao: FC<IAvisoUpgradeConfirmacao> = ({ visible, onConfirm }) => {
  const buttons = (
    <div>
      <Button className={styles['button']} type='primary' height={37} onClick={onConfirm}>
        Ok
      </Button>
    </div>
  );

  return (
    <Modal
      footerButtons={buttons}
      visible={visible}
      width={650}>
      <div className={styles['wrapper']}>
          <img src={LogoUpgradeConfirmacao} className={styles['imageWrapper']}/>
        <div className={styles['contentWrapper']}>
          <label className={styles['avisoConfirmacaoEnvio']}>
            Tudo certo!
          </label>
          <h2>Em breve nosso time entrará em contato para auxiliá-lo no upgrade da sua conta</h2>
        </div>
      </div>
    </Modal>
  );
};

export default AvisoUpgradeConfirmacao;
