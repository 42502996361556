import { FC, CSSProperties, useState, useRef } from 'react';
import { Field as FieldReact, FieldRenderProps } from 'react-final-form';
import { valueType } from 'antd/lib/statistic/utils';
import { Icon, IconName } from 'Components/UI/Icon';
import styles from './NewSimpleDateText.module.scss';
import { dateToLocaleString, isExpiredDate } from 'Utils/DateUtils';
import { TypeCustomError } from 'Pages/Activities/Modals/ShowCreateActivity';
import { 
  TypePathUpdateData, 
  TypeValueUpdateData,
} from 'Data/interfaces/Activities/IUpdateActivityRequest';
import Tooltip from 'Components/UI/Tooltip';

export interface INewSimpleDateText {
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  icon?: IconName;
  type?: string;
  disabled?: boolean;
  onInput?: (value: string) => void;
  onChange?: () => void;
  value?: string;
  defaultValue?: string | number | undefined;
  parse?: (value: any, name: string) => valueType;
  maxLenght?: number;
  typeInput?: string;
  errorId?: TypeCustomError;
  error?: boolean;
  tooltipErrorText?: string;
  onCustomError?: (error: TypeCustomError) => void;
  onClearCustomError?: (error: TypeCustomError) => void;
  isUpdate?: boolean;
  path?: TypePathUpdateData;
  onDynamicUpdateSubmit?: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  notRequired?: boolean;
  havePermission?: boolean;
}

const NewSimpleDateText: FC<INewSimpleDateText> = ({
  name,
  defaultValue,
  icon,
  type = 'text',
  placeholder,
  onInput,
  disabled,
  value,
  onChange,
  parse,
  maxLenght,
  typeInput,
  errorId,
  error,
  tooltipErrorText,
  onCustomError,
  onClearCustomError,
  isUpdate,
  path,
  onDynamicUpdateSubmit,
  notRequired,
  havePermission,
}) => {
  const [isActive, setIsActive] = useState(false);
  const isSubmit = useRef(false);

  const handleCloseInput = () => {
    if (notRequired) {
      setTimeout(() => {
        if (!isSubmit.current) {
          setIsActive(false);
        }
      }, 150);
    } else {
      setIsActive(false);
    }
  };

  const handleClearInput = (field: FieldRenderProps<valueType, HTMLElement>) => {
    isSubmit.current = true;
    if (isUpdate && path && onDynamicUpdateSubmit) {
      onDynamicUpdateSubmit(path, null);
    }
    if (errorId && onClearCustomError) {
      onClearCustomError(errorId);
    }
    field.input.onChange(null);
    setIsActive(false);
  };

  const handleDynamicSubmit = (
    path: TypePathUpdateData, 
    value: valueType,
  ) => {
    if (onDynamicUpdateSubmit) {
      if (notRequired) {
        setTimeout(() => {
          if (!isSubmit.current) {
            onDynamicUpdateSubmit(path, value);
          }
          setIsActive(false);
        }, 150);
      } else {
        onDynamicUpdateSubmit(path, value);
        setIsActive(false);
      }
    }
  };

  const handleError = (error: any, value: string) => {
    if (errorId && onCustomError && onClearCustomError) {
      const dateExpired = isExpiredDate(value);
      if (notRequired) {
        setTimeout(() => {
          if (!isSubmit.current) {
            if (error || dateExpired) {
              onCustomError(errorId);
            }
            
            if (!error && (!value || (value && !dateExpired))) {
              onClearCustomError(errorId);
            }
          }
        }, 150);
      } else {
        if (error || dateExpired) {
          onCustomError(errorId);
        }
        
        if (!error && (!value || (value && !dateExpired))) {
          onClearCustomError(errorId);
        }
      }
    }
  };

  return (
    <FieldReact
      name={name}
      type={type}
      defaultValue={defaultValue}
      initialValue={value}
      parse={parse}
      typeInput={typeInput}
    >
      {(props) => (
        isActive ? (
          <Tooltip 
            overlay={
              isExpiredDate(props.input.value.toString())
                ? tooltipErrorText 
                : undefined
            }
          >
            <div className={styles['relativeWrapper']}>
              {icon ? <Icon icon={icon} color="cinzaIcone" /> : ''}
              <input
                {...props.input}
                // value={props.input.value?.toString()?.length === 0 ? undefined : props.input.value}
                disabled={disabled}
                maxLength={maxLenght}
                placeholder={placeholder}
                className={`
                  ${styles['defaultInput']}
                  ${(isExpiredDate(props.input.value.toString()) && !isUpdate)
                    ? styles['defaultInputError'] 
                    : ''
                  }
                `}
                onInput={(event) => onInput && onInput(event.currentTarget.value)}
                onBlur={(event) => {
                  props.input.onBlur(event);
                  handleError(props.meta.error, props.input.value.toString());
                  if (isUpdate && path) {
                    handleDynamicSubmit(path, props.input.value);
                  } else {
                    handleCloseInput();
                  }
                }}
                autoFocus
                onChangeCapture={onChange}
              />
              {(notRequired && props.input.value) && (
                <span
                  className={styles['iconClear']}
                  onClick={() => handleClearInput(props)}
                >
                  <Icon 
                    icon="error"
                    color="cinzaOpaco"
                    customSize={10}
                  />
                </span>
              )}
            </div>
          </Tooltip>
        ) : (
          <Tooltip 
            overlay={
              (error && isUpdate && tooltipErrorText) 
                ? tooltipErrorText 
                : undefined
            }
          >
            <div 
              className={`
                ${styles['noActiveWrapper']}
                ${(error && isUpdate) ? styles['noActiveWrapperError'] : ''}
                ${!havePermission ? styles['nPCursor'] : ''}
              `}
              onClick={() => {
                if (havePermission) {
                  setIsActive(true);
                }
                isSubmit.current = false;
              }}  
            >
              {props.input.value && props.input.value.toString().length >= 8 ? (
                <>
                  {(!error || (error && !isUpdate)) && (
                    <Icon 
                      icon="calendar"
                      customColor={error ? '#E2574C' : '#BEC2C6'}
                      customSize={14}
                      style={{
                        marginRight: '4px', 
                        cursor: havePermission ? 'pointer' : 'default'
                      }}
                    />
                  )}
                  <span 
                    className={`
                      ${styles['noActiveText']}
                      ${(error && !isUpdate) ? styles['noActiveTextError'] : ''}
                    `}
                  >
                    {dateToLocaleString(props.input.value.toString())}
                  </span>
                </>
              ) : (
                <span className={styles['noActiveTextSoft']}>{placeholder}</span>
              )}
            </div>
          </Tooltip>
        )
      )}
    </FieldReact>
  );
};

export default NewSimpleDateText;
