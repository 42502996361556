import styled, { createGlobalStyle, css } from "styled-components";

export const DropdownContainer = createGlobalStyle`

  .dropdownStyledMenu {

    ul {
      min-width: 200px;
      height: auto;
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .dropdownStyledMenuItem {
    padding: 0 !important;
  }
`;

interface IContainer {
  isempty?: boolean;
}

export const Container = styled.div<IContainer>`
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .leftText {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};

    ${({ isempty }) => isempty && css`
      font-size: 10px;
    `}
  }

  .separatorText {
    margin-left: 4px;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
  }

  .rightText {
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }
`;
