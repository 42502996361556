import { FC, ReactNode, useMemo, useState } from "react";
import { Checkbox, Popover } from "antd";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";
import { handlePriority, handleVisibility } from "Utils/ActivitiesUtils";
import { ITopicDnDResponse, arrayTopicKeys } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { 
  Container, 
  Content, 
  Title, 
  Check, 
  MainContent, 
  ShowComplex, 
  StatusIcon,
} from "./styles";
import Select, { IOptionData } from "Components/UI/Select";
import Input from "Components/UI/Input";
import { getDisciplineIcon, getTypeIcon } from "Utils/ObraUtils";
import { 
  ReversePtBrTopicPriorityEnum, 
  ReversePtBrTopicVisibilityEnum,
} from "Data/interfaces/Activities/ITopicResponse";
import { GetAvatar } from "Utils/generateThumbnail";
import { 
  arrayTopicKeysPtBr, 
  operationsFormatObj, 
  optionsOperationsObj,
} from "./helper";
import useFilterDropdown from "./useFilterDropdown";
import FilterDatePicker from "./components/FilterDatePicker";
import { dateToLocaleString } from "Utils/DateUtils";
import { 
  IFiltroOptions, 
  IUserDescription, 
  IUpdateFilter,
} from "Data/interfaces/Activities/IFilters";

interface IFilterDropdown {
  id: string;
  item?: IFiltroOptions<ITopicDnDResponse>;
  updateFilter: (values: IUpdateFilter) => void;
  deleteFilter: (filterId: string) => void;
  firstRenderFilters: boolean;
  blockFilterDropdown: boolean;
};
 
const FilterDropdown: FC<IFilterDropdown> = ({
  id,
  item,
  updateFilter,
  deleteFilter,
  firstRenderFilters,
  blockFilterDropdown,
}) => {
  const [open, setOpen] = useState(
    (firstRenderFilters || blockFilterDropdown) ? false : true
  );

  const {
    isCustom,
    checkOptions,
    hasCheck,
    topicStatusOptions,
    handleKeyUpdateFilter,
    handleOperationUpdateFilter,
    handleValueUpdateFilter,
    theme,
  } = useFilterDropdown({
    id,
    item,
    updateFilter,
  });

  const optionsKeys = useMemo(() => {
    const options: IOptionData[] = arrayTopicKeys.map(key => ({
      value: key,
      description: arrayTopicKeysPtBr[key],
      label: arrayTopicKeysPtBr[key],
    }));

    return options;
  }, []);

  const optionsOperations = useMemo(() => {
    let options: IOptionData[] = [];

    if (item?.topicId) {
      options = optionsOperationsObj[item.topicId].map(op => operationsFormatObj[op](isCustom));
    }

    return options;
  }, [isCustom, item?.topicId]);

  const title = (
    <Title>
      <span className="textTitle">Editar filtro</span>
      <Button 
        type="text" 
        className="deleteButton"
        onClick={() => deleteFilter(id)}
      >
        <Icon
          icon="emptyExcluir"
          customSize={14}
          className="deleteIcon"
        />
      </Button>
    </Title>
  );

  const mainContent = () => {
    if (item?.topicId) {
      if (
        [
          'Visibility',
          'TopicAssignedUsers',
          'ConstructionSiteDisciplines',
          'CommunicationTypes',
          'TopicPoints',
          'Stage',
          'Type',
          'Priority',
          'Status',
        ].includes(item.topicId) && 
        hasCheck
      ) {
        return checkOptions.options.map(option => (
          <Checkbox 
            key={option.value}
            className="checkBox"
            name={option.value}
            onChange={(e) => handleValueUpdateFilter(e.target.value)}
            value={option.value}
            checked={
              Array.isArray(item.value) 
                ? item.value.includes(option.value)
                : item.value === option.value
            }
          >
            <Check>
              {option.label}
            </Check>
          </Checkbox>
        ));
      }
      
      if (['Identifier', 'Title', 'Description'].includes(item.topicId)) {
        return (
          <Input 
            label="" 
            placeholder="Ex: Enviar modelo..."
            value={item.value}
            onInput={handleValueUpdateFilter} 
            inputClassName="inputDefault"
          />
        );
      }
  
      if (item.topicId === 'DueDate') {
        return (
          <FilterDatePicker 
            operation={item.operation}
            item={item}
            handleValueUpdateFilter={handleValueUpdateFilter}
          />
        );
      }
  
      if (item.topicId === 'Tumb') {
        return <Input label="" />;
      }
    }

    return <></>;
  };
  
  const content = (
    <Content>
      <div className="headerContent">
        <div className="selectContainer">
          <Select 
            options={optionsKeys}
            value={item?.topicId}
            size="small"
            className="select"
            customBackgroundColor={theme.colors.surface.surfaceOutline}
            onChange={handleKeyUpdateFilter} 
          />
        </div>
        <div className="selectContainer">
          <Select 
            options={optionsOperations}
            value={item?.operation}
            size="small"
            className="select"
            customBackgroundColor={theme.colors.surface.surfaceOutline}
            onChange={handleOperationUpdateFilter} 
          />
        </div>
      </div>
      <MainContent 
        hasCheck={hasCheck} 
        isDatePicker={item?.topicId === 'DueDate'}
      >
        {mainContent()}
      </MainContent>
    </Content>
  );

  const getIcon: Record<string, (value: string) => ReactNode> = {
    Visibility: (value: string) => {
      const priorityEnum = ReversePtBrTopicVisibilityEnum[value as keyof (typeof ReversePtBrTopicVisibilityEnum)];
      return (
        <Icon 
          icon={handleVisibility(priorityEnum, theme).icon}
          customSize={handleVisibility(priorityEnum, theme).size}
          customColor={handleVisibility(priorityEnum, theme).color}
        />
      );
    },
    ConstructionSiteDisciplines: (value: string) => {
      return (
        <Icon 
          icon={getDisciplineIcon(value)}
          customSize={getDisciplineIcon(value) === 'menuContexto' ? 12 : 14}
          customColor={theme.colors.surface.onSurfacePlaceholder}
        />
      );
    },
    Type: (value: string) => {
      return (
        <Icon 
          icon={getTypeIcon(value)}
          customSize={14}
          customColor={theme.colors.surface.onSurfacePlaceholder}
        />
      );
    },
    Priority: (value: string) => {
      const priorityEnum = ReversePtBrTopicPriorityEnum[value as keyof (typeof ReversePtBrTopicPriorityEnum)];
      return (
        <Icon 
          icon={handlePriority(priorityEnum, theme).icon}
          customSize={handlePriority(priorityEnum, theme).size}
          customColor={handlePriority(priorityEnum, theme).color}
        />
      )
    },
    Status: (value: string) => {
      const status = topicStatusOptions.find(sts => sts.Name === value);

      if (status) {
        return (
          <StatusIcon
            customColor={status.Color}
          />
        )
      }

      return <></>;
    },
  };

  const descriptionContent = () => {
    if (item?.topicId && item?.description) {   
      if (item.topicId === 'TopicAssignedUsers') {
        let showUsers = item.description as IUserDescription[];
        const total = item.description.length;
        let showCount = '';
        if (total > 5) {
          showCount = (total - 5).toString();
          showUsers = showUsers.slice(0, 6);
        }

        return (
          <ShowComplex customMargin="4px">
            {showUsers.map((user, i) => {
              if (i === 5) {
                return <span className="textOperation">+{showCount}</span>
              }

              return (
                <GetAvatar
                  name={user.name}
                  shape='circle'
                  size={24}
                  src={user.img}
                  border={`1px solid ${theme.colors.surface.surface}`}
                  zIndex={(showUsers.length + 1) - i}
                  marginRight={i === 4 ? '0px' :'-5px'}
                  cursor='pointer'
                />
              )
            })}
          </ShowComplex>
        );
      }

      if (
        [
          'Visibility',
          'ConstructionSiteDisciplines',
          'CommunicationTypes',
          'TopicPoints',
          'Stage',
          'Type',
          'Priority',
          'Status',
        ].includes(item.topicId) &&
        Array.isArray(item.description)
      ) {
        let showItems = item.description as string[];
        const total = item.description.length;
        let showCount = '';
        if (total > 2) {
          showCount = (total - 2).toString();
          showItems = showItems.slice(0, 3);
        }

        return showItems.map((val, i) => {
          if (i === 2) {
            return <span className="textOperation">+{showCount}</span>
          }

          return (
            <ShowComplex>
              {getIcon.hasOwnProperty(item.topicId) && getIcon[item.topicId](val)}
              <span className="text">{val}</span>
            </ShowComplex>
          )
        });
      }
      
      if (['Identifier', 'Title', 'Description'].includes(item.topicId)) {
        return <span className="textDescription">{item.description}</span>;
      }
  
      if (item.topicId === 'DueDate') {
        if (Array.isArray(item.description) ) {
          return (
            <span className="textDescription">
              {`${
                  item.description[0] 
                    ? dateToLocaleString(item.description[0] as string)
                    : ''
                }  ➔  ${
                  item.description[1] 
                    ? dateToLocaleString(item.description[1] as string)
                    : ''
              }`}
            </span>
          );
        }

        return (
          <span className="textDescription">
            {dateToLocaleString(item.description)}
          </span>
        );
      }
  
      if (item.topicId === 'Tumb') {
        return <></>;
      }
    }

    return <></>;
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Container>
      <Popover
        content={content}
        title={title}
        trigger="click"
        placement="bottomLeft"
        open={open}
        onOpenChange={handleOpenChange}
        overlayStyle={{
          width: 350,
          borderRadius: 8,
          border: `1px solid ${theme.colors.surface.surfaceOutline}`,
        }}
      >
        <Button 
          type="text"
          className="filterButton"
        >
          <span className="textId">
            {item?.topicId ? arrayTopicKeysPtBr[item.topicId] : ''}
          </span>
          {item?.operation && (
            <span className="textOperation">
              {operationsFormatObj[item.operation](isCustom).label}
            </span>
          )}
          {descriptionContent()}
          <Icon
            icon="expandir"
            color="cinzaEscuro"
            customSize={8}
            className="buttonIcon"
          />
        </Button>
      </Popover>
    </Container>
  );
};

export default FilterDropdown
