import { Badge } from "antd";
import styled, { css } from "styled-components";

interface INotification {
  customColor?: string;
}

export const CircleNotification = styled(Badge)<INotification>`
  sup {
    background-color: ${({theme}) => theme.colors.notification.notification} !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 16px !important;
    min-width: 16px !important;

    ${({customColor}) => customColor && css`
      background-color: ${customColor} !important;
    `}

    span {
      
      span {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: ${({theme}) => theme.colors.notification.onNotification} !important;
        font-size: 10px !important;
        line-height: 18px !important;
        font-weight: 600 !important;
        font-family: 'Open Sans' !important;
        text-align: center !important;
      }
    }

    color: ${({theme}) => theme.colors.notification.onNotification} !important;
    font-size: 10px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans' !important;
    text-align: center !important;
  }
`;

export const SquareNotification = styled(Badge)<INotification>`
  sup {
    background-color: ${({theme}) => theme.colors.notification.notification} !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 16px !important;
    min-width: 23px !important;

    ${({customColor}) => customColor && css`
      background-color: ${customColor} !important;
    `}

    span {
      
      span {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: ${({theme}) => theme.colors.notification.onNotification} !important;
        font-size: 10px !important;
        line-height: 18px !important;
        font-weight: 600 !important;
        font-family: 'Open Sans' !important;
        text-align: center !important;
      }
    }
    
    color: ${({theme}) => theme.colors.notification.onNotification} !important;
    font-size: 10px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans' !important;
    text-align: center !important;
  }
`;
 