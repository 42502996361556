/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAttachmentForUpload } from "Data/interfaces/Activities/IAttachmentsForUpload";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { getAttachmentsForUpload } from "Store/Activities/Activities.selector";

const useActionsContent = () => {
  const dispatch = useDispatch();
  const attachmentsForUpload = useSelector(getAttachmentsForUpload);

  useEffect(() => {
    const handleUploadAction = (attachment: IAttachmentForUpload) => {
      dispatch(ActivitiesActions.uploadAttachment(attachment));
    };

    if (attachmentsForUpload) {
      const attachmentsIds = attachmentsForUpload.attachmentsIds;
      const attachments = attachmentsForUpload.attachments;

      if (JSON.stringify(attachments) !== '{}') {
        const attachmentsValues = Object.values(attachments);
  
        if (attachmentsIds.length > 0 && attachmentsValues.length > 0) {
          const hasUploading = attachmentsValues.some(attachment => attachment.isUploading);
          const openAttachments = attachmentsValues.filter(attachment => {
            return !attachment.isCompleted && !attachment.isUploading && !attachment.isError
          });
    
          if (!hasUploading && openAttachments.length > 0) {
            dispatch(ActivitiesActions.clearUploadAttachmentCancelToken());
            const attachment = openAttachments[0];
    
            handleUploadAction(attachment);
          }
        }
      }
    }
  }, [attachmentsForUpload]);

  useEffect(() => {
    return () => {
      dispatch(ActivitiesActions.uploadAttachmentCancelAll());
    }
  }, []);

  return {}
}

export default useActionsContent;
