import React, { FC } from 'react';

import styles from './AvisoPlotagemUpgrade.module.scss';
import PlanoAvisoPlotagemUpgrade from '../../../Static/images/plano-aviso-plotagem-upgrade.png';
import { Icon } from '../../../Components/UI';
import Button from '../../../Components/UI/Button';

interface IAvisoObraUpgrade {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  role?: number;
}

const AvisoPlotagemUpgrade: FC<IAvisoObraUpgrade> = ({ onConfirm, role }) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['imageWrapper']}>
        <img src={PlanoAvisoPlotagemUpgrade} alt="" />
      </div>
      <div className={`${styles['contentWrapper']}`}>
        <div>
          <label className={styles['avisoLimiteObras']}>
            {role === 1 
              ? 'Atenção: você não possui acesso ao controle de plotagem!'
              : 'Atenção: sua empresa não possui acesso ao controle de plotagem!'
            }
          </label>
        </div>
        <h2>
          {role === 1 
            ? 'Dê um upgrade na sua conta gratuita'
            : 'Dê um upgrade na conta da sua empresa'
          }
        </h2>
        <h3>Para controlar plotagens</h3>

        <p>O plano Premium ainda proporciona:</p>
        <div className={styles['icons-wrapper']}>
          <div className={`${styles['icons-text-wrapper']} ${styles['icons-text-margin']}`}>
            <div>
              <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
              <span>Gestão de documentos 2D e 3D</span>
            </div>
            <div>
              <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
              <span>Controle de nomeclatura</span>
            </div>
          </div>
          <div className={styles['icons-text-wrapper']}>
            <div>
              <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
              <span>Gestão de usuários e papéis na obra</span>
            </div>
            <div>
              <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
              <span>Controle de plotagem</span>
            </div>
          </div>
          <div style={{ marginBottom: 14 }}>&nbsp;</div>
        </div>
        
        <div className={styles['button-wrapper']}>
          <Button className={styles['button-confirm']} type='primary' ispremium="true" width={287} height={48} onClick={onConfirm}>
            <Icon icon="crown" className={styles['icon']} size="xxs" customColor={'#324150'} />
            {role === 1 
              ? 'Adquirir o plano Premium'
              : 'Solicitar o plano Premium'
            }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AvisoPlotagemUpgrade;
