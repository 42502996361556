import styled, { css } from "styled-components";
import { Switch } from "antd";

export const NomenclatureContainer = styled.div`
  padding: 27px 36px 23px 36px;
  height: auto;
  max-height: 330px;
  overflow: auto;
`;

export const NomenclatureSwitch = styled.div`
  width: 100%;
  height: 54px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  border-radius: 5px;
  margin-bottom: 22px;
  padding: 13px 30px;

  .switchText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-left: 14px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }
`;

export const SwitchStyled = styled(Switch)`
  ${({checked}) => checked && css`
    background-color: ${({theme}) => theme.colors.primary.primary} !important;
  `}
`;

export const TitlePreviewCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;

  span {
    font-weight: 600;
    font-size: 12px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }

  .titlePreview {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }
`;

interface INomenclaturePreview {
  istabversion?: string;
}

export const NomenclaturePreview = styled.div<INomenclaturePreview>`
  width: 100%;
  min-height: 52px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
  border-radius: 5px;
  margin-bottom: 22px;
  padding: 13px 30px;

  .spansPreview {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  }
  color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};

  ${({istabversion}) => istabversion === 'true' && css`
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
  `}
`;

interface INomenclatureStructure {
  isshowedit?: boolean;  
}

export const NomenclatureStructure = styled.div<INomenclatureStructure>`
  .nomenclatureStructureTitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  .icon {
    display: inline;
    margin-left: 9px;
  }

  .nomenclatureFieldsContainer {
    padding-top: 11px;
    padding-bottom: 10px;

    .fieldRow {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .customName {
        height: 40px;

        input {
          float: left;
          padding-right: 30px;
          padding-left: 18px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        }

        i {
          position: relative;
          left: -20px;
          top: -30px;
          float: right;
          cursor: pointer;
        }
      }

      .field {
        display: flex;
        align-items: center;
        width: 292px;
        height: 40px;
        margin-right: 15px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        font-size: 12px;

        div {
          color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
          font-size: 12px;
        }

        i {

        display: flex !important;
        align-items: center !important;
        }
      }

      .separator {
        width: 188px;
        height: 40px;
        margin-right: 15px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        font-size: 12px;

        div {
          color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
          font-size: 12px;
        }
      }

      .deleteButton {
        width: 40px;
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        cursor: pointer;

        .deleteIcon {
          margin: 0;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .linkNewRow {
    width: 184px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    .iconAdd {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }

  .saveButton {
    display: flex;
    flex-direction: row;
    margin-top: ${({isshowedit}) => isshowedit ? 0 : 35}px;

    .saveBtn {
      display: flex;
      align-items: center;
      padding: ${({isshowedit}) => isshowedit ? undefined : '0 35px'};
    }

    .cancelBtn {
      margin-left: 6px;
    }
  }
`; 

interface IDragButton {
  isgrabbing?: string;
}

export const DragButton = styled.div<IDragButton>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  border-radius: 5px;
  cursor: grab;

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }

  ${({isgrabbing}) => isgrabbing === 'true' && css`
    cursor: grabbing;
  `}
`;

interface IDivider {
  nottop?: boolean;
}

export const Divider = styled.div<IDivider>`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 24px 0;

  ${({nottop}) => nottop && css`
    margin-top: 14px;
  `}
`;
