import { call, put } from 'redux-saga/effects';
import toastHandler from '../../Utils/toastHandler';
import {
  UpdateStatusActions,
  UpdateStatusRequestAction
} from './UpdateStatus.actions';
import { ListagemArquivosActions } from 'Store/ListagemArquivos/ListagemArquivos.actions';
import { ObrasApi } from 'Data/Obras.api';
import AppStorage from 'Utils/AppStorage';

export function* updateStatusFileList({ payload }: UpdateStatusRequestAction) {
  delete payload.requestFileList.userInfoTracking;

  try {
    const { data } = yield call(ObrasApi.listFiles, payload.requestFileList);
    
    yield put(ListagemArquivosActions.listFilesSuccess(data.data));
    yield put(ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUserPermissions));
    yield put(UpdateStatusActions.updateStatusSuccess(data.data.LastUpdateDate));
    yield put(ListagemArquivosActions.loadObsoleteFiles(data.data.RequestManageObsoleteFiles));

    AppStorage.SetItem('lastUpdateFileList', data.data.LastUpdateDate)
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : 'Erro atualização status dos arquivos'
    toastHandler.showError(error);
    yield put(UpdateStatusActions.updateStatusFailure(error));
  }
}
