import React, { FC } from 'react';

import styles from './AvisoRenderizacaoUpgrade.module.scss';
import Button from '../../../../Components/UI/Button';
import PlanoAvisoRenderizacaoUpgrade from '../../../../Static/images/plano-aviso-renderizacao-upgrade.png';
import { Icon } from '../../../../Components/UI';
import Modal from 'Components/UI/Modal/Modal';

interface IAvisoRenderizacaoUpgrade {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  order?: number;
  role?: number;
}

const AvisoRenderizacaoUpgrade: FC<IAvisoRenderizacaoUpgrade> = ({ 
  visible, 
  onCancel, 
  onConfirm, 
  order,
  role
}) => {
  
  return (
    <Modal
      footerButtons={false}
      visible={visible}
      width={'auto'}
      onCancel={onCancel}
    >
      <div className={styles['wrapper']}>
        <div className={styles['imageWrapper']}>
          <img src={PlanoAvisoRenderizacaoUpgrade} alt='' />
        </div>
        <div className={styles['contentWrapper']}>
          <div>
            <label className={styles['avisoLimiteObras']}>
              {role === 1 
                ? 'Atenção: você não possui mais renderizações 3D gratuitas!'
                : 'Atenção: sua empresa não possui mais renderizações 3D gratuitas!'
              }
            </label>
          </div>
          <h2>
            {role === 1 
              ? 'Dê um upgrade na sua conta gratuita'
              : 'Dê um upgrade na conta da sua empresa'
            }
          </h2>
          <h3>Para visualização 3D</h3>

          <p>O plano Premium ainda proporciona:</p>
          <div className={styles['icons-wrapper']}>
            <div className={`${styles['icons-text-wrapper']} ${styles['icons-text-margin']}`}>
              <div>
                <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                <span>Gestão de documentos 2D e 3D</span>
              </div>
              <div>
                <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                <span>Controle de nomeclatura</span>
              </div>
            </div>
            <div className={styles['icons-text-wrapper']}>
              <div>
                <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                <span>Gestão de usuários e papéis na obra</span>
              </div>
              <div>
                <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                <span>Controle de plotagem</span>
              </div>
            </div>
            <div style={{ marginBottom: 14 }}>&nbsp;</div>
          </div>
          
          <div className={styles['button-wrapper']}>
            <Button 
              className={styles['button-confirm']} 
              type='primary' 
              ispremium="true"
              width={287} 
              height={48} 
              onClick={onConfirm}
            >
              <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
              {role === 1 
                ? 'Adquirir o plano Premium'
                : 'Solicitar o plano Premium'
              }
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvisoRenderizacaoUpgrade;
