import React, { FC, useEffect } from 'react';

import styles from './PermissoesUsuariosTab.module.scss';
import PermissaoUsuarioItem from './PermissaoUsuarioItem';
import { IInvitedUser } from '../../../../../Data/interfaces/User/IInvitedUser';
import { ISharedUser } from '../../../../../Data/interfaces/User/ISharedUser';
import { IUpdatedUsers } from '../../CadastroObra';

interface IUsuariosConvidadosTab {
  isSubmited?: boolean;
  onSubmit?: (props: any) => void;
  invitedUsers: IInvitedUser[];
  sharedUsers: ISharedUser[];
  changeUsers: (changedUser: IUpdatedUsers) => void;
}

const PermissoesUsuariosTab: FC<IUsuariosConvidadosTab> = (props) => {
  const removeAdmin = (sharedUsers: ISharedUser[]) => {
    const indexAdmin = sharedUsers.findIndex((admin) => admin.User?.CurrentRoleFk === 1);
    if (indexAdmin >= 0) {
      sharedUsers.splice(indexAdmin, 1);
    }
    return sharedUsers;
  };
  const [InvitedUsers, setInvitedUsers] = React.useState(props.invitedUsers);
  const [SharedUsers, setSharedUsers] = React.useState(removeAdmin(props.sharedUsers));

  useEffect(() => {
    setInvitedUsers([...props.invitedUsers]);
    setSharedUsers(removeAdmin(props.sharedUsers));
  }, [props.invitedUsers, props.sharedUsers]);

  const updateUsers = () => {
    props.changeUsers({InvitedUsers, SharedUsers});
  };

  const changeInvitedUser = (data: any) => {
    InvitedUsers[data.index].HasPlottingPermission = data.hasPlottingPermission;
    InvitedUsers[data.index].Privileges = data.privileges;

    setInvitedUsers(JSON.parse(JSON.stringify(InvitedUsers)));
    updateUsers();
  };

  const changeSharedUser = (data: any) => {
    SharedUsers[data.index].HasPlottingPermission = data.hasPlottingPermission;
    SharedUsers[data.index].Privileges = data.privileges;

    setSharedUsers(JSON.parse(JSON.stringify(SharedUsers)));
    updateUsers();
  };

  if (props.isSubmited && props.onSubmit) {
    props.onSubmit({ InvitedUsers, SharedUsers });
  }

  return (
    <div className={styles['permissionsUsersWrapper']}>
      {
       InvitedUsers?.map((user, index) => (
        <PermissaoUsuarioItem
          key={`PermissionInvitedUser-${index}`}
          index={index}
          username={user.Email}
          privileges={user.Privileges}
          hasPlottingPermission={user.HasPlottingPermission}
          onChange={changeInvitedUser}/>
       ))
      }
      {
       SharedUsers?.map((sharedUser, index) => (
        <PermissaoUsuarioItem
          key={`PermissionSharedUser-${index}`}
          index={index}
          username={sharedUser.User?.Nome || ''}
          userAvatar={sharedUser.User?.OriginalImageUrl}
          userAvatarThumb={sharedUser.User?.ThumbnailImageUrl}
          userAvatarThumbType="small"
          privileges={sharedUser.Privileges}
          hasPlottingPermission={sharedUser.HasPlottingPermission}
          onChange={changeSharedUser}/>
       ))
      }
    </div>
  );
};

export default PermissoesUsuariosTab;
