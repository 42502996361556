import { ITenantData } from "../Tenant/ITenantData";

export interface IMultiDomainPersonalizationResponse {
  TenantFk?: number;
  TenantCustomizationId?: string;
  Status: MultiDomainPersonalizationStatusEnum;
  DomainName?: string;
  LoginBannerUrl?: string;
  LogoUrl?: string;
  PrimaryColor?: string;
  SecondaryColor?: string;
  Tenant?: ITenantData;
};

export enum MultiDomainPersonalizationStatusEnum {
  NaoConfigurado = 0,
  ConfiguracaoEmAndamento = 1,
  AguardandoAnaliseInterna = 2,
  ConfiguracaoConcluida = 3,
  ErroConfiguracao = 4,

  Padrao = 5,
};
