import React, { FC } from 'react';

import styles from './CadastroDisciplina.module.scss';
import Modal from '../../../Components/UI/Modal/Modal';
import Button from '../../../Components/UI/Button';
import Field from '../../../Components/UI/Field';
import Form from '../../../Components/UI/Form/Form';
import schema from './cadastroDisciplina.schema';
import { useDispatch } from 'react-redux';
import { DisciplinaActions } from '../../../Store/Disciplina/Disciplina.actions';
import { IDisciplineRequest } from '../../../Data/interfaces/Obra/IDisciplineRequest';

interface ICadastroDisciplina {
  visible?: boolean;
  id?: number;
  description?: string;
  constructionSiteId: number;
  onCancel: () => void;
  loading?: boolean;
}

const CadastroDisciplina: FC<ICadastroDisciplina> =
  ({ visible, id, description, constructionSiteId, onCancel, loading }) => {
  const [isSubmit, setIsSubmit] = React.useState(false);
  const dispatch = useDispatch();
  const submit = (values: any) => {
    const discipline: IDisciplineRequest = {
      ConstructionSiteId: constructionSiteId,
      Name: values.description,
    };
    if (id) {
      discipline.Id = id;
    }
    dispatch(DisciplinaActions.createOrUpdate(discipline));
    setIsSubmit(false);
  };

  const buttons = (
    <div>
      <Button height={37} onClick={onCancel}>
        <span style={{ padding: '0 20px' }}>
          Cancelar
        </span>
      </Button>
      <Button
        type='primary'
        height={37}
        htmlType='submit'
        onClick={() => setIsSubmit(true)}
        loading={loading}>
        <span style={{ padding: '0 20px' }}>
          Continuar
        </span>
      </Button>
    </div>
  );

  return (
    <Modal
      title={ id ? 'Editar disciplina' : 'Criar nova disciplina' }
      visible={visible}
      footerButtons={buttons}
      width={798}
      onCancel={onCancel}>
      <div className={styles['disciplinaWrapper']}>
        <Form schema={schema} onSubmit={submit} isSubmited={isSubmit} className={styles['disciplinaForm']}>
          <Field
            label='Nome da Disciplina'
            name='description'
            className={styles['inputDescription']}
            defaultValue={description}
            disabled={loading}/>
        </Form>
      </div>
    </Modal>
  );
};

export default CadastroDisciplina;