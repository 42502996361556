import { ColumnProps } from "antd/lib/table";
import { ITenantUsersResponse } from "Data/interfaces/Tenant/ITenantUsersResponse";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { GetAvatar } from "Utils/generateThumbnail";
import styles from "./Downgrade.module.scss";

export interface IControleAdmTenantsInput {
}

export const getColumnsConstructionSitesInactivate: (input: IControleAdmTenantsInput) =>
  Array<ColumnProps<IObraResponse>> = () => ([
    {
      title: 'Name',
      dataIndex: 'Name',
      render: (text, record) => {
        return (
          <div className={styles['row-wrapper']}>
            <div className={styles['image-row']}>
              <GetAvatar
                name={record.Name || ''}
                shape='circle'
                src={record?.OriginalImageUrl || ''}
                thumbSrc={record?.ThumbnailImageUrl}
                thumbType="small"
              />
            </div>
            <div className={styles['text-row']}>
              {text}
            </div>
          </div>
        )
      }
    },
  ]);

export const getColumnsTenantsInactivate: (input: IControleAdmTenantsInput) =>
  Array<ColumnProps<ITenantUsersResponse>> = () => ([
    {
      title: 'Name',
      dataIndex: '[User.Nome]',
      render: (text, record) => {
        return (
          <div className={styles['row-wrapper']}>
            <div className={styles['image-row']}>
              <GetAvatar
                name={record?.User?.Nome || 'Usuário Convidado'}
                shape='circle'
                src={record?.User?.OriginalImageUrl || ''}
                thumbSrc={record?.User?.ThumbnailImageUrl}
                thumbType="small"
              />
            </div>
            <div>
              <div className={styles['text-row']}>
                {record?.User?.Nome || record?.InvitedUserEmail}
              </div>
              <div className={styles['text-footer-row']}>
                {record?.User?.Email}
              </div>
            </div>
          </div>
        )
      }
    },
  ]);
