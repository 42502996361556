import React, { FC } from 'react';
import './Toast.css';
import styles from './Toast.module.scss';
import {  useDispatch } from 'react-redux';
import { Icon, IconName } from '../Icon';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
export interface IToast {
  title: string;
  description: string;
  error: boolean;
  info?: boolean;
  locationInfo?: string;
  timeRequest?: boolean;
  iconCustom?: IconName;
  iconCustomColor?: string;
  onCustomAction?: () => void;
}

export const Toast: FC<IToast> = ({ 
  title, 
  description, 
  error, 
  info, 
  locationInfo, 
  timeRequest, 
  iconCustom,
  iconCustomColor,
  onCustomAction,
}) => {
  const dispatch = useDispatch();

  const openModalInfoArquivos = () => {
    dispatch(ListagemArquivosActions.setShowModalInfoFiles(true));
  };

  return (
    <>
      {timeRequest ? (
        <div className={styles['NotificationRequest']}>
          <Icon
            icon="informacao"
            color="amarelo"
            customSize={18}
            className={styles['icon']}
          />
          <div className={styles['messageWrapperRequest']}>
            <strong>{title}</strong>
            <p>{description}</p>
          </div>
        </div>
      ) : (
        <div 
          className={styles['Notification']}
          onClick={onCustomAction ? onCustomAction : undefined}
        >
          <Icon
            icon={error ? 'error' : iconCustom ? iconCustom : 'confirmacao'}
            customColor={error ? '#E2574C' : iconCustomColor ? iconCustomColor : '#09B66D'}
            customSize={18}
            className={styles['icon']}
          />
          <div className={styles['messageWrapper']}>
            <strong>{title}</strong>
            <p>
              {description} {info === true && locationInfo === 'arquivos'? (
                <Icon 
                  icon="informacaoAdicional" 
                  onClick={()=>openModalInfoArquivos()} 
                  customColor="#8697A8" 
                  customSize={14}
                />
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
