import React, { FC, useMemo } from 'react';
import { CircleNotification, SquareNotification } from './styles';

type BadgeType = 'circle' | 'square';

interface IBadge {
  value: number;
  type: BadgeType;
  color?: string;
  style?: React.CSSProperties
}

const Badge:FC<IBadge> = ({ value, type, children, color, style }) => {
  const BadgeComponent = useMemo(() => {
    if (type === 'square') {
      return SquareNotification;
    }
    
    return CircleNotification;
  }, [type]);
  
  return (
    <BadgeComponent 
      count={value} 
      customColor={color}
      style={{
        ...style
      }}
    >
      {children}
    </BadgeComponent>
  )
}

export default Badge;
