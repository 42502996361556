import { FC, useMemo } from "react";
import { Icon } from "Components/UI";
import { ISharedUser } from "Data/interfaces/User/ISharedUser";
import { GetAvatar } from "Utils/generateThumbnail";
import Button from "Components/UI/Button";
import HighlighterWords from "Components/UI/HighlightWords";
import { IRole } from "../../user.model";
import { Container } from "./styles";

interface IItem {
  user: ISharedUser;
  search: string;
  roles: IRole[];
  loading?: boolean;
  onShare: (user: ISharedUser) => void;
  onUnshare: (user: ISharedUser) => void;
}

const Item: FC<IItem> = ({
  user,
  search,
  roles,
  loading,
  onShare,
  onUnshare,
}) => {
  const getSharedUserRoleName = useMemo(() => {
    const role = roles.find((role) => {
      return role.value === user.User?.CurrentRoleFk;
    });
    return role?.label;
  }, [roles, user]);

  return (
    <Container>
      <div className="avatarContainer">
        <GetAvatar
          name={user.User?.Nome || user.User?.Email || ''}
          shape='circle'
          src={user.User?.OriginalImageUrl}
          thumbSrc={user.User?.ThumbnailImageUrl}
          thumbType="small"
        />
        <HighlighterWords
          text={user.User?.Nome || user.User?.Email || ''}
          wordSearched={search}
          className="userName"
        />
      </div>
      <div className="actionContainer">
        <div className="roleName">
          {getSharedUserRoleName}
        </div>
        <Button
          type="text"
          customText={user.isShared}
          isShared={user.isShared}
          className="shareBtn"
          onClick={() => loading
            ? undefined
            : user.isShared ? onUnshare(user) : onShare(user)
          }
          disabled={loading}
        >
          {user.isShared && (
            <Icon
              icon="confirmacao"
              customSize={16}
              className="shareIcon"
            />
          )}
          <span className="shareText">
            {!user.isShared ? 'Compartilhar' : 'Compartilhado'}
          </span>
        </Button>
      </div>
    </Container>
  )
}

export default Item;
