import React, { FC } from 'react';

import styles from './AvisoTamanhoEQuantidadeArquivos.module.scss';
import Button from '../../../../Components/UI/Button';
import Modal from '../../../../Components/UI/Modal/Modal';
import AvisoLimitacaoArquivos from '../../../../Static/images/aviso-limitacao-arquivos.png';
import { Icon } from '../../../../Components/UI';

interface IAvisoTamanhoEQuantidadeArquivos {
  visible?: boolean;
  onConfirm?: () => void;
}

const AvisoTamanhoEQuantidadeArquivos: FC<IAvisoTamanhoEQuantidadeArquivos> = ({ visible, onConfirm }) => {
  const buttons = (
    <div>
      <Button className={styles['button']} type='primary' height={37} onClick={onConfirm}>
        Entendi
      </Button>
    </div>
  );

  return (
    <Modal
      footerButtons={buttons}
      visible={visible}
      width={798}>
      <div className={styles['wrapper']}>
        <img src={AvisoLimitacaoArquivos}  className={styles['imageWrapper']}/>
        <div className={styles['contentWrapper']}>
          <h2>Atenção</h2>
          <p>Atualmente possuimos limitações no upload de novos arquivos, mas fique tranquilo que a cada novo upload você pode enviar:</p>
          <div>
            <ul>
              <li><Icon icon={'arquivoTxt'} size={'xs'} customColor={'#bec2c6'} ></Icon> <label>Até 10 arquivos simultaneamente</label></li>
              <li><Icon icon={'estrela'} size={'xs'} customColor={'#bec2c6'} ></Icon> <label>Até 1.4GB de arquivos</label></li>
              <li style={{ marginBottom: 14 }}>&nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvisoTamanhoEQuantidadeArquivos;
