import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import LoadingUploadDisciplineList from "Components/UI/CustomLoading/LoadingUploadDisciplineList";
import { IconName } from "Components/UI";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { DisciplineIconsEnum } from "Pages/Disciplinas";
import { PanelsActions } from "Store/Panels/Panels.actions";
import { IListFilesRequest } from "Data/interfaces/ListagemArquivos/IListFilesRequest";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import SelectFileHeader from "./components/SelectFileHeader";
import SelectFileListDisciplines from "./components/SelectFileListDisciplines";
import SelectFileListChildren from "./components/SelectFileListChildren";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import SelectedFile from "./components/SelectedFile";
import { IPanelsFileContent } from "Data/interfaces/Panels/IPanelsFileContent";
import { Main, Wrapper } from "./styles";
import { IFileData } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { ConfigProvider } from "antd";
import CustomEmptyData from "./components/CustomEmptyData";

export interface ISelectFile {
  isUpdate?: boolean;
  currentDiscipline?: any;
  externalLoading?: boolean;
  constructionSiteId: number;
  panelName: string;
  selectedFileFromMaleta: IPanelsFileContent | null;
  onPanelName: (value: string) => void;
  onSelectedFileFromMaleta: (file: IPanelsFileContent | null) => void;
}

export interface IHandleOpenFolder {
  folderId: string,
  ConstructionSiteDisciplinesId?: number,
  folderName?: string,
  firstRenderUpdate?: boolean,
}

export interface IBreadcrumbPath {
  name: string;
  folderId: string;
  ConstructionSiteDisciplinesId?: number;
}

const SelectFile: FC<Props> = ({
  isUpdate,
  constructionSiteId,
  obra,
  disciplines,
  listFiles,
  disciplinesLoading,
  listFilesLoading,
  loading,
  panelName,
  selectedFileFromMaleta,
  panelsListFilesBreadCrumbItem,
  onPanelName,
  onSelectedFileFromMaleta
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  const firstRender = useRef(0);
  const [disciplineFolder, setDisciplineFolder] = useState<IDisciplineViewerList | null>(null);
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadcrumbPath[]>([]);
  const [searchDisciplineFile, setSearchDisciplineFile] = useState('');
  const [searchFile, setSearchFile] = useState('');

  const filteredDisciplines = useMemo(() => {
    if (disciplines) {
      return disciplines?.filter((discipline: any) => {
        if (discipline.CustomName) {
          return discipline?.CustomName.toLowerCase().includes(searchDisciplineFile.toLowerCase());
        } else {
          return discipline?.Discipline.Name.toLowerCase().includes(searchDisciplineFile.toLowerCase());
        }
      })
    }
    return disciplines;
  }, [disciplines, searchDisciplineFile]);

  const filteredFiles = useMemo(() => {
    if (listFiles) {
      return listFiles?.filter(file => {
        return file.Name.toLowerCase().includes(searchFile.toLowerCase());
      })
    }
    return listFiles;
  }, [listFiles, searchFile]);

  const handleDisciplineFolder = (disciplineFolder: IDisciplineViewerList | null) => {
    setDisciplineFolder(disciplineFolder);
    clearAllSearch();
  }

  const handleIconLeft = (name: string): IconName => {
    if ((DisciplineIconsEnum as any)[name]) {
      return (DisciplineIconsEnum as any)[name];
    }
    return 'menuContexto';
  };

  const handleOpenFolder = ({
    folderId,
    ConstructionSiteDisciplinesId,
    folderName,
    firstRenderUpdate,
  }: IHandleOpenFolder) => {
    clearAllSearch();
    if (!firstRenderUpdate) {
      onSelectedFileFromMaleta(null);
    }

    const request: IListFilesRequest = {
      folderId,
      constructionSiteId,
      loadObsoleteFiles: false,
      ignoreBreadCrumb: true,
    }

    setBreadCrumbs(prevState => {
      const folderIndex = prevState.findIndex(state => state.folderId === folderId);
      if (folderIndex === -1 && folderName) {
        return [...prevState, {
          folderId,
          name: folderName,
          ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId || ConstructionSiteDisciplinesId,
        }];
      }
      if (folderIndex === -1 && !folderName) {
        return [];
      }
      if (folderIndex >= 0 && folderName) {
        if (folderIndex === 0) {
          return [{
            folderId,
            name: folderName,
            ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId || ConstructionSiteDisciplinesId,
          }];
        } else {
          return prevState.slice(0, folderIndex + 1);
        }
      }
      return prevState;
    });

    dispatch(PanelsActions.panelsListFiles(request));
    dispatch(PanelsActions.panelsListFilesMoveToClear());
  };

  const handleListDisciplines = (firstRenderUpdate?: boolean) => {
    setBreadCrumbs([]);
    if (!firstRenderUpdate) {
      onSelectedFileFromMaleta(null);
      setDisciplineFolder(null);
      clearAllSearch();
      dispatch(PanelsActions.panelsListFilesUpdate([]));
    }
    dispatch(PanelsActions.panelsListDisciplina({ csId: constructionSiteId }));
  };

  const clearSelects = () => {
    onSelectedFileFromMaleta(null);
    clearAllSearch();
  };

  const finishModal = () => {
    setBreadCrumbs([]);
    onSelectedFileFromMaleta(null);
    setDisciplineFolder(null);
    clearAllSearch();
    dispatch(PanelsActions.panelsListFilesUpdate([]));
  }

  const isLoading = useMemo(() => {
    if (listFilesLoading || disciplinesLoading) {
      return true;
    } else {
      return false;
    }
  }, [listFilesLoading, disciplinesLoading])

  const handleConfirmFileFromMaleta = (item: IFileData) => {
    const breadCrumbId = panelsListFilesBreadCrumbItem?.PreviousItem?.BreadCrumbItemId && panelsListFilesBreadCrumbItem.PreviousItem?.BreadCrumbItemId > 0
      ? panelsListFilesBreadCrumbItem.PreviousItem.BreadCrumbItemId
      : null;
    const breadCrumbIdDropbox = panelsListFilesBreadCrumbItem.PreviousItem?.BreadCrumbItemIdDropbox && !panelsListFilesBreadCrumbItem.PreviousItem.BreadCrumbItemIdDropbox.includes('00000000-0000-0000-0000-000000000000')
      ? panelsListFilesBreadCrumbItem.PreviousItem.BreadCrumbItemIdDropbox
      : null;

    const request: IPanelsFileContent = {
      FileApiId: item.FileInfo.ApiId,
      BreadCrumbId: breadCrumbId,
      BreadCrumbItemIdDropbox: !breadCrumbId ? breadCrumbIdDropbox : null,
      IsFolder: item.IsFolder,
      inObsoleteFolder: item.inObsoleteFolder,
      Extension: item.Extension,
      Name: item.Name,
      ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId,
    };
    onSelectedFileFromMaleta(request);
  };

  const handleSearch = (value: string) => {
    if (!disciplineFolder) {
      setSearchFile('');
      setSearchDisciplineFile(value);
    } else {
      setSearchFile(value);
      setSearchDisciplineFile('');
    }
  }

  const clearAllSearch = () => {
    setSearchDisciplineFile('');
    setSearchFile('');
  }

  const renderEmpty = useCallback(() => {
    if (searchDisciplineFile) {
      return <CustomEmptyData isDiscipline />;
    }

    if (searchFile) {
      return <CustomEmptyData />;
    }

    return <></>;
  }, [searchFile, searchDisciplineFile]);

  useEffect(() => {
    handleListDisciplines(firstRender.current === 0 && isUpdate);

    if (firstRender.current === 0) firstRender.current++;

    return () => {
      finishModal();
    }
  }, []);

  useEffect(() => {
    if (
      firstRender.current === 1 &&
      isUpdate &&
      disciplines &&
      selectedFileFromMaleta
    ) {
      if (selectedFileFromMaleta?.ConstructionSiteDisciplinesId) {
        const currentDiscipline = disciplines.find((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId === selectedFileFromMaleta.ConstructionSiteDisciplinesId;
        })

        if (currentDiscipline) {
          handleOpenFolder({
            folderId: currentDiscipline.DisciplineApiFolderId,
            ConstructionSiteDisciplinesId: currentDiscipline.ConstructionSiteDisciplinesId,
            firstRenderUpdate: true,
          })
          handleDisciplineFolder(currentDiscipline);
        }
      }

      firstRender.current++;
    }
  }, [
    isUpdate,
    disciplines,
    selectedFileFromMaleta,
    selectedFileFromMaleta?.ConstructionSiteDisciplinesId,
  ]);

  return (
    <Wrapper>
      {disciplineFolder && selectedFileFromMaleta && (
        <SelectedFile
          item={selectedFileFromMaleta}
          disciplineFolder={disciplineFolder}
          panelName={panelName}
          onPanelName={onPanelName}
          onIconLeft={handleIconLeft}
          onClear={clearSelects}
        />
      )}
      {!selectedFileFromMaleta && (
        <>
          <SelectFileHeader
            value={searchDisciplineFile || searchFile}
            onSearch={handleSearch}
            disciplineFolder={disciplineFolder}
            onListDisciplines={handleListDisciplines}
            isLoading={isLoading}
            onOpenFolder={handleOpenFolder}
            onIconLeft={handleIconLeft}
            breadCrumbs={breadCrumbs}
          />
          <Main>
            {(isLoading || (!filteredDisciplines && filteredFiles.length <= 0)) && (
              <LoadingUploadDisciplineList multiple={5} />
            )}


            <ConfigProvider renderEmpty={renderEmpty}>
              {!isLoading && ((filteredDisciplines && filteredDisciplines.length > 0 && (!searchFile && !disciplineFolder && filteredFiles.length <= 0)) ? (
                <SelectFileListDisciplines
                  filteredDisciplines={filteredDisciplines}
                  onDisciplineFolder={handleDisciplineFolder}
                  onOpenFolder={handleOpenFolder}
                  onIconLeft={handleIconLeft}
                  searchDisciplineFile={searchDisciplineFile}
                />
              ) : (
                <SelectFileListChildren
                  filteredFiles={filteredFiles}
                  disciplineFolder={disciplineFolder}
                  searchFile={searchFile}
                  theme={theme}
                  onOpenFolder={handleOpenFolder}
                  onConfirmFileFromMaleta={handleConfirmFileFromMaleta}
                />
              ))}
            </ConfigProvider>
          </Main>
        </>
      )}
    </Wrapper>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.panels,
  obra: state.panels.panelsObra,
  disciplines: state.panels.panelsObra?.Disciplines,
  disciplinesLoading: state.panels.isPanelsCoordlyLoading,
  listFiles: state.panels.panelsListFiles,
  listFilesLoading: state.panels.isPanelsLoadingFileList,
  loading: state.panels.isLoading,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ISelectFile;

export default connector(SelectFile);
