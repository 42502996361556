import styled from "styled-components";
import { Menu } from "antd";

export const MenuStyled = styled(Menu)`
  width: 200px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  padding: 0 !important;

  .menuItem {
    padding: 15px 10px 15px 15px !important;
    
    span {
      display: flex;
      align-items: center;
      justify-content: left;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    &:hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;

      p {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }

      i {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }

      &:last-child {
        background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.danger.dangerHover}, ${theme.colors.surface.surface})`} !important;

        p {
          color: ${({theme}) => theme.colors.danger.danger} !important;
        }

        i {
          color: ${({theme}) => theme.colors.danger.danger} !important;
        }
      }
    }

    p {
      margin: 0px 0px 0px 18px;
    }

    .menuItemIcon {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    }
  }
`;
