import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IUploadFileData } from './interfaces/Upload/IUploadFileData';
import { store } from '../Store';
import { UploadActions } from '../Store/Upload/Upload.actions';
import { ICheckUploadFilesRequest } from './interfaces/Upload/ICheckUploadFilesRequest';
import { ICheckUploadFilesResponse } from './interfaces/Upload/ICheckUploadFilesResponse';

export class UploadApi {
  static async uploadFiles(uploadFile: IUploadFileData): Promise<AxiosResponse<object>> {
    const axios = await Http.axiosToDownloadUploadFile();
    const formData = new FormData();
    formData.append('File', uploadFile.file);
    const { csId, folderId, index, newFileName, disciplineApiFolderId, checkUploadFileInfo } = uploadFile;
    const { uploadFileList } = store.getState().upload;
    const url = `/api/Upload/Post?csId=${csId}&folderId=${folderId}&disciplineApiFolderId=${disciplineApiFolderId}\
${newFileName ? `&newFileName=${newFileName}` : ''}\
${checkUploadFileInfo?.FileIdToOverwrite ? `&fileIdToOverwrite=${checkUploadFileInfo?.FileIdToOverwrite}` : ''}`;
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (uploadFileList) {
          const { loaded } = progressEvent;
          uploadFile.sentSize = loaded;
          store.dispatch(UploadActions.updateUploadFile(uploadFile));
        }
      },
      validateStatus: (status: number) => {
        if (uploadFileList) {
          uploadFile.isSending = false;
          uploadFile.uploadCompleted = true;
          store.dispatch(UploadActions.updateUploadFile(uploadFile));
        }
        return true;
      },
    });
  }

  static async checkUploadFiles(checkData: ICheckUploadFilesRequest):
    Promise<AxiosResponse<ICheckUploadFilesResponse[]>> {
    const axios = await Http.axios(false);
    return axios.post('/Upload/CheckUploadFiles', checkData);
  }
}
