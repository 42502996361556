import { FC } from "react";
import { BtnContainer, Container } from "./styles";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";

import emptyPhase from "Static/images/empty-phase.png";

interface IEmptyList {
  onEdit: () => void;
  onLib: () => void;
}

const EmptyList: FC<IEmptyList> = ({ onEdit, onLib }) => {
  return (
    <Container>
      <img src={emptyPhase} alt="Imagem Etapas Vazias" />
      <span className="title">Nenhuma etapa cadastrada</span>
      <span className="text">
        Ainda não foi cadastrada nenhuma etapa. Crie uma nova etapa ou adicione da biblioteca de etapas:
      </span>

      <BtnContainer>
        <Button
          type="text"
          className="addBtn"
          onClick={onEdit}
        >
          <Icon
            icon="adicionar"
            customSize={14}
            className="actionIcon"
          />
          <span className="textBtn">Criar nova etapa</span>
        </Button>
        <Button
          type="text"
          className="libBtn"
          onClick={onLib}
        >
          <Icon
            icon="listDetailVision"
            customSize={18}
            className="actionIcon"
          />
          <span className="textBtn">Biblioteca de Etapas</span>
        </Button>
      </BtnContainer>
    </Container>
  )
}

export default EmptyList;
