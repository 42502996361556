import React, { FC } from "react";
import SearchViewerInput from "Components/UI/SearchViewerInput";
import { Icon, IconName } from "Components/UI";
import styles from '../FileDestination.module.scss';
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { IBreadcrumbPath } from "..";

interface IFileDestinationHeader {
  value: string;
  fromMaleta?: boolean;
  onSearch: (value: string) => void;
  disciplineFolder: IDisciplineViewerList | null;
  onListDisciplines: () => void;
  isLoading: boolean;
  onOpenFolder: (folderId: string, ConstructionSiteDisciplinesId?: number, folderName?: string) => void;
  onIconLeft: (name: string) => IconName;
  breadCrumbs: IBreadcrumbPath[];
}

const FileDestinationHeader: FC<IFileDestinationHeader> = ({
  value,
  fromMaleta,
  onSearch,
  disciplineFolder,
  onListDisciplines,
  isLoading,
  onOpenFolder,
  onIconLeft,
  breadCrumbs,
}) => {
  return (
    <div className={styles['header']}>
      {fromMaleta && (
        <SearchViewerInput 
          value={value}
          onSearch={onSearch}
          inputPlaceholder={JSON.stringify(disciplineFolder) === '{}' ? "Procure por uma disciplina" : "Procure por um arquivo"}
          maxWidth="100%"
          marginBottom="0"
          marginTop="12px"
          inputClassName={styles['customInput']}
          iconClassName={styles['customIcon']}
          isDebounce={false}
        />
      )}
      <div className={styles['mainHeader']}>
        <Icon 
          icon="menuHome" 
          customSize={12} 
          customColor="#8697A8" 
          onClick={() => onListDisciplines()}
          style={{cursor: 'pointer'}}
        />
        {(!isLoading && disciplineFolder) && (
          <div className={styles['rowHeader']}>
            <Icon icon="retroceder" customSize={6} className={styles['iconBreadCrumb']} color="cinzaSuperior" />
            <span
              onClick={() => onOpenFolder(disciplineFolder.DisciplineApiFolderId, disciplineFolder?.ConstructionSiteDisciplinesId)}
              className={styles['rowHeaderDiscipline']}
              style={{cursor: 'pointer'}}
            >
              <Icon 
                icon={onIconLeft(disciplineFolder.CustomName || disciplineFolder.Discipline.Name)} 
                customSize={10} className={styles['']} 
                customColor={breadCrumbs.length ? '#BEC2C6' : '#8697A8'} 
              />
              {disciplineFolder.CustomName || disciplineFolder.Discipline.Name}
            </span>
          </div>
        )}
        {!isLoading && (
          breadCrumbs.map((breadCrumb) => (
            <div className={styles['rowHeader']}>
              <Icon 
                icon="retroceder" 
                customSize={6} 
                className={styles['iconBreadCrumb']} 
                customColor="#8697A8" 
              />
              <span
                onClick={() => onOpenFolder(breadCrumb.folderId, breadCrumb?.ConstructionSiteDisciplinesId, breadCrumb.name)}
                style={{cursor: 'pointer'}}
              >
                {breadCrumb.name}
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default FileDestinationHeader;
