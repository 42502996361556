import React, { FC } from 'react';

import styles from './TextArea.module.scss';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export interface ITextArea extends FieldProps<any, FieldRenderProps<any, HTMLElement>, HTMLElement> {
  label: string;
}

const hasError = (props: any) => props.disabled && props.meta.invalid && props.meta.touched;

const TextArea: FC<ITextArea> = (props) => {
  return (
    <Field type='textarea' {...props}>
      {
        (propsField) => (
          <label className={styles['labelTextarea']}>
            <textarea
              {...propsField.input}
              style={propsField.style}
              placeholder={propsField.placeholder}
              className={`${hasError(propsField) ? styles['invalid'] : ''}`} />
            <span className={styles['label']}>{props.label}</span>
            <div className={styles['error']}>
              {hasError(propsField) ? propsField.meta.error : ''}
            </div>
          </label>
        )
      }
    </Field>
  );
};

export default TextArea;
