import { call, put } from 'redux-saga/effects';
import {
  PanelsActions,
  PanelsAutoDeskViewerAction,
  PanelsCreateAction,
  PanelsDeleteAction,
  PanelsGetFileInfoAction,
  PanelsListAction,
  PanelsListDisciplinaAction,
  PanelsListFilesAction,
  PanelsReorderAction,
  PanelsUpdateAction,
  PanelsVerifyAutodeskProgressAction,
  ShareLinkPanelsAction,
} from './Panels.actions';
import { PanelsApi } from 'Data/Panels.api';
import { ObrasApi } from 'Data/Obras.api';
import {
  ListagemArquivosActions,
} from 'Store/ListagemArquivos/ListagemArquivos.actions';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { IObraListResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { IListFilesRequest } from 'Data/interfaces/ListagemArquivos/IListFilesRequest';
import { ApisEnum } from 'Data/enums/Apis';
import { UpdateStatusActions } from 'Store/UpdateStatusFileList/UpdateStatus.actions';
import { ViewerApi } from 'Data/Viewer.api';
import { IRenderingSignalR } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import toastHandler from 'Utils/toastHandler';

export function* list({ payload }: PanelsListAction) {
  try {
    const { data } = yield call(PanelsApi.list, payload);

    yield put(PanelsActions.listSuccess(data.data));

  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : 'Erro ao carregar painéis';
    toastHandler.showError(error);
    yield put(PanelsActions.listFailure(error));
  }
}

export function* createPanel({ payload }: PanelsCreateAction) {
  try {
    const { data } = yield call(PanelsApi.createPanel, payload);

    yield put(PanelsActions.createPanelSuccess(data.data));

  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : 'Erro ao criar painel';
    toastHandler.showError(error);
    yield put(PanelsActions.createPanelFailure(error));
  }
}

export function* deletePanel({ payload }: PanelsDeleteAction) {
  try {
    yield call(PanelsApi.deletePanel, payload);

    yield put(PanelsActions.deletePanelSuccess(payload));

  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : 'Erro ao exluir painel';
    toastHandler.showError(error);
    yield put(PanelsActions.deletePanelFailure(error));
  }
}

export function* panelsListDisciplina({ payload }: PanelsListDisciplinaAction) {
  try {
    const { data } = yield call(ObrasApi.getConstructionSite, payload);
    yield put(ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUserPermissions));
    yield put(PanelsActions.panelsListDisciplinaSuccess(data.data));
    if (payload.ignoreCache) {
      const { ConstructionSiteId, Name } = data.data;
      const response: IObraListResponse = {
        newObraName: {
          ConstructionSiteId: ConstructionSiteId,
          Name: Name,
        }
      }
      yield put(ObraActions.listSuccess(response));
    }
    
  } catch (err: any) {
    yield put(PanelsActions.panelsListDisciplinaFailure("Erro ao listar Disciplinas"));
  }
}

export function* panelsListFiles({ payload }: PanelsListFilesAction) {
  try {
    const request: IListFilesRequest = {
      ...payload,
      loadObsoleteFiles: false
    };

    const isGoogleDriveExperimentOnTrmt =
      payload.api === ApisEnum.GoogleDrive && payload.googleDriveExperiment === 'test';

    const apiCall = isGoogleDriveExperimentOnTrmt
      ? ObrasApi.listGoogleDriveFiles
      : ObrasApi.listFiles;

    const { data } = yield call(apiCall, request);

    if (payload.isObsoleteFiles) {
      data.data.isObsoleteFiles = payload.isObsoleteFiles;
    }

    yield put(PanelsActions.panelsListFilesSuccess(data.data));
    yield put(
      ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUpdateRequestUserPermissions)
    );
    yield put(UpdateStatusActions.updateStatusSuccess(data.data.LastUpdateDate));

  } catch (e: any) {
    yield put(PanelsActions.panelsListFilesFailure("Erro ao listar Arquivos"));
  }
}

export function* panelsAutodestViewer({ payload }: PanelsAutoDeskViewerAction) {
  try {
      const { data } = yield call(ViewerApi.RenderAutoDesk, payload);

      yield put(PanelsActions.panelsAutoDeskViewerSuccess(data.data));
  } catch (err: any) {
      yield put(PanelsActions.panelsAutoDeskViewerFailure("Erro ao renderizar arquivo"));
  }
}
export function* panelsGetFileInfo({ payload }: PanelsGetFileInfoAction) {
  try {
    const { data } = yield call(ViewerApi.GetFileInfo, payload);

    yield put(PanelsActions.panelsGetFileInfoSuccess(data.data));
    
  } catch (err: any) {
    const error = "Erro ao buscar Informação de Arquivo";
    toastHandler.showError(error);
    yield put(PanelsActions.panelsGetFileInfoFailure(error));
  }
}

export function* updatePanel({ payload }: PanelsUpdateAction) {
  try {
    const { data } = yield call(PanelsApi.updatePanel, payload);

    yield put(PanelsActions.updatePanelSuccess(data.data));
    
  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : "Erro ao atualizar painél";
    toastHandler.showError(error);
    yield put(PanelsActions.updatePanelFailure(error));
  }
}

export function* reorderPanel({ payload }: PanelsReorderAction) {
  try {
    const { data } = yield call(PanelsApi.reorderPanel, payload);

    yield put(PanelsActions.reorderPanelSuccess(data.data));

  } catch (err: any) {
    yield put(PanelsActions.reorderPanelFailure("Erro ao reordenar painéis"));
  }
}

export function* shareLinkPanel({ payload }: ShareLinkPanelsAction) {
  try {
    const { data: { data } } = yield call(PanelsApi.shareLinkPanel, payload);

    yield put(PanelsActions.shareLinkPanelsSuccess(data.ShortUrl));

  } catch (err: any) {
    yield put(PanelsActions.shareLinkPanelsFailure(err));
  }
}

export function* panelsVerifyAutodeskProgress({ payload }: PanelsVerifyAutodeskProgressAction) {
  try {
    const { data } = yield call(
      ObrasApi.verifyFileRenderProgress,
      payload.FileApiId,
      payload.ConstructionSiteId
    );

    const res = data && data.data;

    let etapa = 0;
    let progress =
      res.progress && res.progress.indexOf('%') !== -1
        ? Number(res.progress.split('%')[0])
        : 0;

    switch (res.status) {
      case 'inprogress':
        etapa = 3;
        break;
      case 'success':
        etapa = 4;
        progress = 100;
        break;
      case 'notstarted':
        etapa = 1;
        break;
      case 'failed':
        etapa = 5;
        progress = 100;
        break;
    }

    let body: IRenderingSignalR = {
      etapa,
      fileIdentifier: payload.FileApiId || '',
      progress,
      urn: res.urn
    };

    yield put(PanelsActions.panelsAutodeskViewerRenderingResponse(body));
    yield put(PanelsActions.updateStatusFilePanelViewer(body));

  } catch (e) {
    yield put(PanelsActions.panelsAutoDeskViewerFailure("Erro ao renderizar arquivo"));
  }
}
