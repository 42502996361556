import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: absolute;
  width: 275px;
  height: 394px;
  z-index: 2;
  background-color: ${({theme}) => theme.colors.surface.surface};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  .header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    width: 100%;
    height: 150px;
    background-color: ${({theme}) => theme.colors.primary.primary};
    overflow: hidden;
    padding-top: 16px;

    .iconBox {
      position: absolute;
      top: 8px;
      right: 6px;

      i {
        cursor: pointer;
      }
    }
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 275px;
    height: 244px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;

      .tagBox {
        width: 100%;
        margin-bottom: 15px;

        .tag {
          height: 20px;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 8px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 10.89px;
          border-radius: 19px;
        }
      }

      .title {
        text-transform: none;
        font-weight: 700;
        font-size: 14px;
        line-height: 19.07px;
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
      
      .description {
        text-transform: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
    }

    .Footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: ${({theme}) => theme.colors.surface.surface};
      text-transform: none;

      .link {
        padding: 10px 20px;
        margin-right: 10px;

        a {
          font-weight: 600;
          font-size: 12px;
          line-height: 16.34px;
          color: ${({theme}) => theme.colors.surface.onSurface};
          text-decoration: none;
        }
      }

      .button {
        width: 72px;
        height: 36px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16.34px;
      }
    }
  }
`;
