import { FC, Suspense } from 'react';
import { Spin } from 'antd';

import { MainSwitch } from './MainSwitch';

const AuthenticatedRoutes: FC = () => (
  <Suspense fallback={<Spin />}>
    <MainSwitch />
  </Suspense>
);

export default AuthenticatedRoutes;
