import { CSSProperties, FC, KeyboardEvent, useCallback, useEffect, useState, Fragment } from 'react';
import { ConfigProvider, Select as SelectAnt } from 'antd';
import { SelectProps as SelectPropsAnt } from 'antd/es/select';
import { Field as FieldReact } from 'react-final-form';
import { ICustomOption } from 'Pages/Activities/Modals/ShowCreateActivity/useOptionsSelect';
import OptionCustom from '../OptionImg';
import { TypeCustomError } from 'Pages/Activities/Modals/ShowCreateActivity';
import {
  TypePathUpdateData,
  TypeValueUpdateData,
} from 'Data/interfaces/Activities/IUpdateActivityRequest';
import Tooltip from 'Components/UI/Tooltip';
import styled from 'styled-components';
import styles from './NewImgSelect.module.scss';
import './select.css'

const { Option } = SelectAnt;

type ModeType = 'multiple' | 'tags';

interface INewStageSelect extends SelectPropsAnt {
  options: ICustomOption[];
  name: string;
  style?: CSSProperties
  limit?: number;
  mode?: ModeType;
  errorId?: TypeCustomError;
  error?: boolean;
  tooltipErrorText?: string;
  tagsDontImg?: boolean;
  optionsTagsDontImg?: string[];
  isUpdate?: boolean;
  path?: TypePathUpdateData;
  sufix?: string;
  currentValue?: string | string[];
  isRequired?: boolean;
  havePermission?: boolean;
  haveCreateOptPermission?: boolean;
  emptyMsg?: string;
  onSelect?: (e: any) => void;
  onBlur?: () => void;
  onCustomError?: (error: TypeCustomError) => void;
  onClearCustomError?: (error: TypeCustomError) => void;
  onDynamicUpdateSubmit?: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  onValues?: (values: any) => void;
  onModal?: (value: string) => void;
}

const NewStageSelect: FC<INewStageSelect> = ({
  options,
  name,
  style,
  limit,
  mode,
  errorId,
  error,
  tooltipErrorText,
  tagsDontImg,
  optionsTagsDontImg,
  isUpdate,
  path,
  defaultValue,
  value,
  placeholder,
  sufix,
  currentValue,
  isRequired,
  havePermission,
  haveCreateOptPermission,
  emptyMsg,
  onSelect,
  onBlur,
  onCustomError,
  onClearCustomError,
  onDynamicUpdateSubmit,
  onValues,
  onModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [dynamicShowSearch, setDynamicShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (currentValue) {
      if (Array.isArray(currentValue)) {
        setTags(currentValue);
        if (onValues) onValues(currentValue);
      } else {
        setTags([currentValue]);
        if (onValues) onValues(currentValue);
      }
    }
  }, [currentValue, onValues]);

  const handleError = (value: any, error?: any) => {
    setTimeout(() => {
      if (errorId && onCustomError && error && (!isRequired || (isRequired && value?.length <= 0))
      ) {
        onCustomError(errorId);
      }

      if (errorId && onClearCustomError && !error && (!isRequired || (isRequired && value?.length > 0))
      ) {
        onClearCustomError(errorId);
      }
    }, 200);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, key: number) => {
    if (key === 13) {
      const value = event.currentTarget.value;
      if (!haveCreateOptPermission && tagsDontImg && !optionsTagsDontImg?.includes(value)) {
        return event.preventDefault();
      }

      if (value) {
        handleTags(value);
      }
    }
    return;
  }

  const handleSearch = (e: string) => {
    setSearch(e);
  }

  const clearSearch = () => {
    setSearch('');
    setDynamicShowSearch(false);
  }

  const clearTags = (value?: string) => {
    setTags(prev => {
      if (value) {
        return prev.filter(st => st.toLowerCase() !== value.toLowerCase());
      }
      return [];
    });
  }

  const handleTags = (value: string) => {
    clearSearch();
    let newValue = value;
    let hasSufix = false;

    if (sufix && newValue.endsWith(sufix)) {
      hasSufix = true;
      newValue = newValue.replace(sufix, '');
    }

    if (hasSufix && onModal) {
      onModal(newValue);
    } else {
      const valLower = newValue.toLowerCase();
      setTags(prev => {
        if (limit === 1) {
          return [newValue];
        }
  
        const index = prev.find(st => st.toLowerCase() === valLower);
        if (index) {
          return prev.filter(st => st.toLowerCase() !== index);
  
        } else {
          if (limit && prev.length >= limit) {
            return prev;
          }
  
          return [...prev, newValue];
        }
      });
    }
  }

  const renderOptions = useCallback(() => {
    if (tagsDontImg && optionsTagsDontImg) {
      return (
        optionsTagsDontImg
          .map((option, index: number) => (
            <Option
              key={option}
              className={styles['option']}
              value={option}
            >
              {option}
            </Option>
          ))
      )
    } else {
      return (
        options
          .map((option, index: number) => (
            <Option
              key={`${option.key}${index}`}
              className={styles['option']}
              value={option.id}
            >
              {option.label}
            </Option>
          ))
      )
    }
  }, [tagsDontImg, optionsTagsDontImg, search, options]);

  const dropdownRender = () => {
    if (tagsDontImg && optionsTagsDontImg) {
      const filteredOpts = optionsTagsDontImg.filter(opt => opt.toLowerCase().includes(search.toLowerCase()));
      if (search && !filteredOpts.some(opt => opt.toLowerCase() === search.toLowerCase()) && haveCreateOptPermission) {
        filteredOpts.push(`${search}${sufix}`);
      }

      return (
        <div
          onMouseDown={(e) => {
            e.preventDefault();
            return false;
          }}
          className={styles['customRenderWrapper']}
        >
          {filteredOpts.map(opt => (
            <div
              key={opt}
              onClick={() => handleTags(opt)}
              className={styles['customRenderItem']}
            >
              {opt}
            </div>
          ))}
          {filteredOpts.length <= 0 && search && !haveCreateOptPermission && (
            <div
              className={styles['customRenderItem']}
            >
              <span className={styles['emptyText']}>{emptyMsg}</span>
            </div>
          )}
        </div>
      );
    }
  };

  const filterOption = (inputValue: any, options: any) => {
    if (!tagsDontImg) {
      if (options.key) {
        return options.key.toString().toLowerCase().includes(inputValue.toLowerCase());
      } else {
        return true;
      }
    }
  };

  return (
    <ConfigProvider renderEmpty={() => <span className={styles['emptyText']}>{emptyMsg}</span>}>
      <FieldReact name={name} defaultValue={defaultValue} initialValue={value}>
        {
          (propsField) => {
            if (tagsDontImg && tags.length > 0) {
              propsField.input.onChange(tags);
            }

            const optionsActive = () => {
              const newOpts: ICustomOption[] = [];

              if (tagsDontImg && optionsTagsDontImg) {
                const filteredOptsTags = optionsTagsDontImg.filter(option => {
                  return tags.includes(option);
                });
                filteredOptsTags.map((opt, i) => newOpts.push({
                  id: opt,
                  key: opt,
                  label: <OptionCustom
                    label={opt}
                  />,
                  value: opt
                }));

              } else {
                const filteredOpts = options.filter(option => {
                  return Array.isArray(propsField.input.value)
                    ? ([...propsField.input.value as string[] | number[]].includes(Number(option.id)) || [...propsField.input.value as string[] | number[]].includes(option.id.toString()))
                    : ((Number(propsField.input.value) === Number(option.id)) || propsField.input.value.toString() === option.id.toString());
                });
                filteredOpts.map((opt, i) => newOpts.push({
                  id: opt.id,
                  key: opt.key,
                  label: opt.label,
                  value: opt.id
                }));
              }

              return newOpts;
            };

            const optionsExternal = () => {
              const newOpts: ICustomOption[] = [];

              if (tagsDontImg && optionsTagsDontImg) {
                let filteredOptsTags: string[] = [];
                filteredOptsTags = tags.filter(optionValue => {
                  return !optionsActive().some(opt => opt.key === optionValue)
                    && optionValue !== undefined;
                });
                filteredOptsTags.map((opt, i) => newOpts.push({
                  id: opt,
                  key: opt,
                  label: <OptionCustom
                    label={opt}
                  />,
                  value: opt
                }));

              } else {
                let filteredOpts: string[] = [];
                filteredOpts = (Array.isArray(propsField.input.value)
                  && [...propsField.input.value as string[]].filter(key => {
                    return !optionsActive().some(opt =>
                      ((Number(opt.id) === Number(key)) || (opt.id.toString() === key.toString()))
                      || ((Number(opt.key) === Number(key)) || (opt?.key?.toString() === key.toString())))
                      && key !== undefined;
                  })
                ) || [];
                filteredOpts.map((opt, i) => newOpts.push({
                  id: opt,
                  key: opt,
                  label: <OptionCustom
                    label={opt}
                  />,
                  value: opt
                }));
              }

              return newOpts;
            };

            const hasOptionsActive = optionsActive().length > 0 || optionsExternal().length > 0;

            return (
              isActive ? (
                <div
                  className={styles['selectWrap']}
                  style={style}
                >
                  <SelectAnt
                    {...propsField.input}
                    value={propsField.input.value?.toString()?.length === 0 ? undefined : propsField.input.value}
                    showArrow={false}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onBlur={() => {
                      onBlur && onBlur();
                      let realValue = propsField.input.value;

                      if (!haveCreateOptPermission && tagsDontImg) {
                        if (Array.isArray(realValue)) {
                          realValue = realValue.filter(v => optionsTagsDontImg?.includes(v) && v !== undefined);

                        } else {
                          if (!optionsTagsDontImg?.some(v => v === realValue)) {
                            realValue = null;
                          }
                        }
                      }

                      if (Array.isArray(realValue)) {
                        propsField.input.onChange(realValue);
                        setTags(realValue);
                        if (onValues) onValues(realValue);

                      } else {
                        if (realValue && realValue.length > 0) {
                          propsField.input.onChange([realValue]);
                          setTags([realValue]);
                          if (onValues) onValues(realValue);
                        }
                      }

                      if (isUpdate && path && onDynamicUpdateSubmit) {
                        if (limit && Array.isArray(realValue) && realValue.length >= limit) {
                          const newValue = [...realValue];
                          onDynamicUpdateSubmit(path, [newValue.pop()]);
                        } else {
                          onDynamicUpdateSubmit(path, realValue as string[]);
                        }
                      }

                      handleError(realValue, propsField.meta.error);

                      clearSearch();
                      setIsActive(false);
                    }}
                    autoClearSearchValue
                    onInputKeyDown={tagsDontImg ? (e: any) => handleKeyDown(e, e.keyCode) : undefined}
                    onDeselect={tagsDontImg ? (e: any) => clearTags(e) : undefined}
                    className={(limit === 1) ? 'select-custom' : ''}
                    mode={mode || "multiple"}
                    onChange={(e: any) => {
                      if (!tagsDontImg) {
                        if (limit && [...propsField.input.value as string[] | number[]].length >= limit) {
                          propsField.input.onChange([e.pop()]);
                        } else {
                          propsField.input.onChange(e);
                        }
                      } else {
                        propsField.input.onChange(tags);
                      }
                    }}
                    autoFocus
                    defaultOpen
                    filterOption={(!tagsDontImg && havePermission) ? (inputValue: any, options: any) => filterOption(inputValue, options) : undefined}
                    onSearch={(!dynamicShowSearch && havePermission) ? (e) => handleSearch(e) : undefined}
                    dropdownRender={tagsDontImg ? () => dropdownRender() as any : undefined}
                    style={{ width: '100%' }}
                    menuItemSelectedIcon={<></>}
                  >
                    {renderOptions()}
                  </SelectAnt>
                </div>
              ) : (
                <Tooltip
                  overlay={
                    (error && isUpdate && tooltipErrorText)
                      ? tooltipErrorText
                      : undefined
                  }
                >
                  <div
                    className={`
                      ${styles['noActiveWrapper']}
                      ${(error && isUpdate) ? styles['noActiveWrapperError'] : ''}
                      ${(!havePermission && !tagsDontImg) ? styles['nPCursor'] : ''}
                      ${(!havePermission && tagsDontImg) ? styles['nPCursorText'] : ''}
                    `}
                    onClick={() => havePermission ? setIsActive(true) : undefined}
                  >
                    {hasOptionsActive && (
                      <>
                        {(optionsActive().length > 0) && optionsActive().map(value => (
                          <Fragment key={value.id}>{value.label}</Fragment>
                        ))}
                        {(optionsExternal().length > 0) && optionsExternal().map(value => (
                          <Fragment key={value.id}>{value.label}</Fragment>
                        ))}
                      </>
                    )}
                    {!hasOptionsActive && (
                      <span
                        className={`
                          ${styles['noActiveText']}
                          ${(error && !isUpdate) ? styles['noActiveTextError'] : ''}
                        `}
                      >
                        {placeholder}
                      </span>
                    )}
                  </div>
                </Tooltip>
              )
            )
          }
        }
      </FieldReact>
    </ConfigProvider>
  );
};

const CustomNewStageSelect: FC<INewStageSelect> = styled(NewStageSelect)`
  .ant-select-selection-selected-value {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;
  }
`;

export { Option };
export default CustomNewStageSelect;
