import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('O campo deve ser preenchido'),
  email: Yup.string()
    .required('O campo deve ser preenchido')
    .email('Digite um endereço de email válido'),
  password: Yup.string()
    .required('O campo deve ser preenchido')
    .min(8, ' ')
    .matches(/[a-z]/, ' ')
    .matches(/[A-Z]/, ' ')
    .matches(/[0-9]/, ' '),
  confirmPassword: Yup.string()
    .required('O campo deve ser preenchido')
    .oneOf([Yup.ref('password')], ' '),
  isTermsAccepted: Yup.bool()
    .required('Você deve aceitar os termos de uso e políticas de privacidade')
    .oneOf([true], 'Você deve aceitar os termos de uso e políticas de privacidade'),
});

export default schema;

export const schemaPassword = Yup.object().shape({
  password: Yup.string()
    .min(8, '8 caracteres')
    .matches(/[a-z]/, 'Letra minúscula')
    .matches(/[A-Z]/, 'Letra maiúscula')
    .matches(/[0-9]/, 'Número'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('$password'), undefined], 'As senhas devem ser iguais'),
});
