export type moneyName =
  | 'real'
  | 'dolar';

export const moneySimbol = {
  real: 'R$',
  dolar: 'US$',
};

export const numberToMoney = (value?: number, money?: moneyName): string => `${money ? moneySimbol[money] : ''} ${value?.toFixed(2).toString().replace('.', ',')}`;
