import { FC, ReactNode, useState } from 'react';
import { IconName, Icon } from '../Icon';
import DropdownActionTopbar from './DropdownActionTopbar';
import Tooltip from '../Tooltip';
import { Spin } from "antd";
import SearchInput from '../SearchInput';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { TableActionTopbarButtonWrapper, TopbarWrapper } from './styles';

export interface ITableActionTopbarButton {
  icon?: IconName;
  iconCustomSize?: number;
  description?: string;
  disabled?: boolean;
  hidden?: boolean;
  dropdownMenu?: ReactNode;
  isLoading?: boolean;
  tooltipMessage?: string;
  iconLoading?: boolean;
  isBlocked?: boolean;
  showArrow?: boolean;
  action?: () => void;
}

export interface ITableActionTopbar {
  buttons: ITableActionTopbarButton[];
  totalSelectedElements?: number;
  inputPlaceholder?: string;
  inputClassName?: string | null;
  defaultValue?: string;
  LeftComponent?: ReactNode;
  showFilters?: boolean;
  onSearch?: (value: string) => void;
  onSearchLoading?: (value: boolean) => void;
}

const TableActionTopbar: FC<ITableActionTopbar> = ({ 
  buttons, 
  totalSelectedElements,
  onSearch,
  onSearchLoading,
  inputPlaceholder, 
  inputClassName,
  defaultValue,
  LeftComponent,
  showFilters,
}) => {
  const theme = useSelector(getTheme);

  const [displayDropdownButtons, setDisplayDropdownButtons]  = useState<boolean[]>([]);

  const toggleDropdown = (index: number) => {
    displayDropdownButtons[index] = !displayDropdownButtons[index];
    setDisplayDropdownButtons([...displayDropdownButtons]);
  };

  const antIcon = <LoadingOutlined rev=""style={{ color: theme.colors.primary.primary, fontSize: 14 }} spin/>;
  
  return (
    <TopbarWrapper showfilters={`${showFilters}`}>
      {LeftComponent && (
        <div>
          {LeftComponent}
        </div>
      )}
      <div>
        {(onSearch && onSearchLoading) && (
          <div className="TopbarInput">
            <SearchInput 
              defaultValue={defaultValue}
              setSearch={onSearch}
              setSearchLoading={onSearchLoading}
              inputPlaceholder={inputPlaceholder}
              inputClassName={inputClassName ? inputClassName : null}
            />
          </div>
        )}
      </div>
      <div  className="TopBarButtons">
      <span className="totalSelectedFiles">
        {
          totalSelectedElements
          ? `${totalSelectedElements} ${totalSelectedElements === 1 ? 'ARQUIVO' : 'ARQUIVOS'}`
          : ''          
        }
      </span>
      {buttons.filter((button) => !button.hidden).map((button, index) => {
        const actionButton = () => {
          if (button.dropdownMenu) {
            if (!displayDropdownButtons[index]) {
              toggleDropdown(index);
            }
          } else {
            if (button.action) {
              button.action();
            }
          }
        };

        if (button.isLoading) {
          return (
            <Tooltip
              key={`topbarTooltip${index}`} 
              overlay={button.tooltipMessage ?? ''} 
              placement="top">
              <TableActionTopbarButtonWrapper 
                disabled={`${button.disabled}`} 
                blocked={`${button.isBlocked}`}
                key={`topbarButton${index}`}>
                <div
                  className={`TableActionTopbarButton ${displayDropdownButtons[index] ? 'active' : ''}`}
                  onClick={button.isLoading ? undefined : actionButton}>
                  {
                    button.iconLoading ? (
                      <Spin indicator={antIcon}/>
                    ) : (
                      button.icon ? (
                        <Icon
                          color="primary"
                          icon={button.icon}
                          customSize={button.iconCustomSize}
                          disabled={button.disabled}
                          blocked={button.isBlocked}
                        />
                      ) : ''
                    )
                  }
                    <span className="titleButton">{button.description}</span>
                    {button.showArrow ? (
                      <Icon
                        customColor={'#BEC2C6'}
                        icon="expandir"
                        customSize={10}
                      />
                    ) : null}
                </div>
                {
                  button.dropdownMenu && displayDropdownButtons[index] && (
                    <DropdownActionTopbar
                      dropdownContent={button.dropdownMenu}
                      toggleDropdown={() => toggleDropdown(index)}/>
                  )
                }
              </TableActionTopbarButtonWrapper>
            </Tooltip>
          );
        }

        return (
          <TableActionTopbarButtonWrapper
            disabled={`${button.disabled}`}
            blocked={`${button.isBlocked}`}
            key={`topbarButton${index}`}>
            <div
              className={`TableActionTopbarButton ${displayDropdownButtons[index] ? 'active' : ''}`}
              onClick={actionButton}>
              {
                button.iconLoading ? (
                  <Spin indicator={antIcon} style={{marginRight: 8}}/>
                ) : (
                  button.icon ? (
                    <Icon
                      color="primary"
                      icon={button.icon}
                      customSize={button.iconCustomSize}
                      disabled={button.disabled}
                      blocked={button.isBlocked}
                    />
                  ) : ''
                )
              }
                <span className="titleButton">{button.description}</span>
                {button.showArrow ? (
                  <Icon
                    customColor={'#BEC2C6'}
                    icon="expandir"
                    customSize={10}
                  />
                ) : null}
            </div>
            {
              button.dropdownMenu && displayDropdownButtons[index] && (
                <DropdownActionTopbar
                  dropdownContent={button.dropdownMenu}
                  toggleDropdown={() => toggleDropdown(index)}/>
              )
            }
          </TableActionTopbarButtonWrapper>
        );
      })}
      </div>
    </TopbarWrapper>
  );
};

export default TableActionTopbar;
