import React, { FC } from "react";
import { Collapse, List, ConfigProvider, Empty } from "antd";
import { Icon } from "Components/UI";
import { IPranchas } from "Data/interfaces/FederatedViewer/IPranchas";
import HighlighterWords from "Components/UI/HighlightWords";
import styles from './ModelsPranchas.module.scss';
import { IChildrenNames, IDrawerOptions, IPrancha } from "Pages/FederatedViewer/useFederatedViewer";
import { RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

interface IModelsPranchas {
  listItems: IPranchas[];
  searchItems: string;
  childrenNames: IChildrenNames[];
  activeDrawer?: IDrawerOptions;
  togglePrancha: IPrancha;
  onTogglePrancha: (camera: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
}

const ModelsPranchas: FC<IModelsPranchas> = ({
  listItems,
  searchItems,
  childrenNames,
  activeDrawer,
  togglePrancha,
  onTogglePrancha,
}) => {
  const renderEmpty = () => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  const isActive = (urn: string, id: number) => {
    return (togglePrancha.urn === urn && togglePrancha.id === id);
  }

  return (
    <>
      <div className={styles['headerWrapper']}>
        <Icon 
          icon="filePile"
          customColor={'#BEC2C6'}
          customSize={16}
        />
        <span>
          {activeDrawer}
        </span>
        <Icon 
          icon="informacaoAdicional"
          customColor={'#BEC2C6'}
          customSize={14}
        />
      </div>
      <ConfigProvider renderEmpty={() => renderEmpty()}>
        {listItems.map((model, index) => {
          const name = childrenNames.find(name => name.urn === model.urn)?.name || '';
          const filteredPranchas = model.pranchas.filter(prancha => {
            return prancha.data.name.toLowerCase().includes(searchItems.toLowerCase());
          })
          
          return (
            <Collapse 
              key={`${name}-${index}`}
              bordered={false}
              expandIcon={({ isActive }) => 
                <RightOutlined
                  rev=""
                  rotate={isActive ? 90 : 0} 
                  style={{
                    fontSize: '10px',
                    marginLeft: '8px',
                    marginRight: '17px',
                    color: '#BEC2C6',
                  }}
                />
              }
              className={styles['childrenCollapse']}
            >
              <Panel 
                key={name} 
                header={name} 
                className={styles['childrenPanel']}
              >
                <List 
                  itemLayout="horizontal"
                  className={styles['listPanel']}
                  dataSource={filteredPranchas}
                  bordered={false}
                  renderItem={(item, index) => {
                    return (
                      <List.Item 
                        key={`${item.data.name}-${index}`}
                        className={`
                          ${styles['childrenList']}
                          ${isActive(model.urn, item.id) ? styles['childrenListActive'] : ''}
                        `}
                        onClick={() => onTogglePrancha(item, model.urn, item.id)}
                      >
                        <div className={styles['childrenListWrapper']}>
                          <div 
                            className={styles['childrenListWrapperLeft']}
                          >
                            <Icon 
                              icon="pranchasItens"
                              customColor={isActive(model.urn, item.id) ? '#324150' : '#8697A8'}
                              customSize={12}
                            />
                            <HighlighterWords 
                              text={item.data.name} 
                              wordSearched={searchItems} 
                              className={styles['listLeftText']} 
                              maxLength={38}
                            />
                          </div>
                          {isActive(model.urn, item.id) && (
                            <div className={styles['childrenListWrapperRight']}>
                              <Icon 
                                icon="visualizar"
                                customColor={'#3547C8'}
                                customSize={10}
                              />
                            </div>
                          )}
                        </div>
                      </List.Item>
                    )
                  }}
                />
              </Panel>
            </Collapse>
          )
        })}
      </ConfigProvider>
    </>
  )
}

export default ModelsPranchas;
