import { IRenderingSignalR } from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IAutodeskViewer } from '../../Data/interfaces/Viewer/IAutodeskViewer';
import { IFileInfo, IRequestFileInfo } from '../../Data/interfaces/Viewer/IGetFileInfo';
import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';

export enum ViewerActionKeys {
  AUTODESK_VIEWER_REQUEST = 'AUTODESK_VIEWER_REQUEST',
  AUTODESK_VIEWER_SUCCESS = 'AUTODESK_VIEWER_SUCCESS',
  AUTODESK_VIEWER_FAILED = 'AUTODESK_VIEWER_FAILED',

  GET_FILE_INFO_REQUEST = 'GET_FILE_INFO_REQUEST',
  GET_FILE_INFO_SUCCESS = 'GET_FILE_INFO_SUCCESS',
  GET_FILE_INFO_FAILED = 'GET_FILE_INFO_FAILED',

  CREATE_STATUS_FILE_VIEWER_AUTODESK = 'UPDATE_STATUS_FILE_VIEWER_AUTODESK',
  UPDATE_STATUS_FILE_VIEWER_AUTODESK = 'UPDATE_STATUS_FILE_VIEWER_AUTODESK',

  PDF_AUTODESK_VIEWER_REQUEST = 'PDF_AUTODESK_VIEWER_REQUEST',

  SET_OPEN_FILE_LINK = 'SET_OPEN_FILE_LINK',
  
  SET_SIMPLE_VIEWER_DOC = 'SET_SIMPLE_VIEWER_DOC',
  SET_SIMPLE_VIEWER_VIEWABLES = 'SET_SIMPLE_VIEWER_VIEWABLES',
  SET_SIMPLE_VIEWER_VIEWABLES_INDEX = 'SET_SIMPLE_VIEWER_VIEWABLES_INDEX',
}

export const ViewerActions = {
  autoDeskViewer: (request: IAutodeskViewer): AutoDeskViewerAction =>
    createAction(ViewerActionKeys.AUTODESK_VIEWER_REQUEST, request),
  autoDeskViewerSuccess: (response: IFileInfo): AutoDeskViewerSuccessAction =>
    createAction(ViewerActionKeys.AUTODESK_VIEWER_SUCCESS, response),
  autoDeskViewerFailure: (err: string): AutoDeskViewerFailureAction =>
    createAction(ViewerActionKeys.AUTODESK_VIEWER_FAILED, err),

  getFileInfo: (request: IRequestFileInfo): GetFileInfoAction =>
    createAction(ViewerActionKeys.GET_FILE_INFO_REQUEST, request),
  getFileInfoSuccess: (response: IFileInfo): GetFileInfoSuccessAction =>
    createAction(ViewerActionKeys.GET_FILE_INFO_SUCCESS, response),
  getFileInfoFailure: (err: string): GetFileInfoFailureAction =>
    createAction(ViewerActionKeys.GET_FILE_INFO_FAILED, err),

  createStatusFileViewerAutodesk: (request: IRenderingSignalR): CreateStatusFileViewerAutodeskAction =>
    createAction(ViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK, request),
  updateStatusFileViewerAutodesk: (request: IRenderingSignalR): UpdateStatusFileViewerAutodeskAction =>
    createAction(ViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK, request),

  pdfAutoDeskViewer: (request: IAutodeskViewer): PdfAutoDeskViewerAction =>
    createAction(ViewerActionKeys.PDF_AUTODESK_VIEWER_REQUEST, request),

  setOpenFileLink: (path: string): SetOpenFileLinkAction =>
    createAction(ViewerActionKeys.SET_OPEN_FILE_LINK, path),

  setSimpleViewerDoc: (doc: Autodesk.Viewing.Document | null): SetSimpleViewerDocAction =>
    createAction(ViewerActionKeys.SET_SIMPLE_VIEWER_DOC, doc),
  setSimpleViewerViewables: (viewables: Autodesk.Viewing.BubbleNode[] | null): SetSimpleViewerViewablesAction =>
    createAction(ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES, viewables),
  setSimpleViewerViewablesIndex: (index: number): SetSimpleViewerViewablesIndexAction =>
    createAction(ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES_INDEX, index),
}

export type ViewerActionUnion = ActionsUnion<typeof ViewerActions>;

export type AutoDeskViewerAction = Action<ViewerActionKeys.AUTODESK_VIEWER_REQUEST, IAutodeskViewer>;
export type AutoDeskViewerSuccessAction = Action<ViewerActionKeys.AUTODESK_VIEWER_SUCCESS, IFileInfo>;
export type AutoDeskViewerFailureAction = Action<ViewerActionKeys.AUTODESK_VIEWER_FAILED, string>;

export type GetFileInfoAction = Action<ViewerActionKeys.GET_FILE_INFO_REQUEST, IRequestFileInfo>;
export type GetFileInfoSuccessAction = Action<ViewerActionKeys.GET_FILE_INFO_SUCCESS, IFileInfo>;
export type GetFileInfoFailureAction = Action<ViewerActionKeys.GET_FILE_INFO_FAILED, string>;

export type CreateStatusFileViewerAutodeskAction = Action<ViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK, IRenderingSignalR>;
export type UpdateStatusFileViewerAutodeskAction = Action<ViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK, IRenderingSignalR>;

export type PdfAutoDeskViewerAction = Action<ViewerActionKeys.PDF_AUTODESK_VIEWER_REQUEST, IAutodeskViewer>;

export type SetOpenFileLinkAction = Action<ViewerActionKeys.SET_OPEN_FILE_LINK, string>;

export type SetSimpleViewerDocAction = Action<ViewerActionKeys.SET_SIMPLE_VIEWER_DOC, Autodesk.Viewing.Document | null>;
export type SetSimpleViewerViewablesAction = Action<ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES, Autodesk.Viewing.BubbleNode[] | null>;
export type SetSimpleViewerViewablesIndexAction = Action<ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES_INDEX, number>;
