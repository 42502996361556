import { Card, Button, Typography } from 'antd';
import { IUpdateConstructionSiteApiRequest } from 'Data/interfaces/Obra/IUpdateConstructionSiteApiRequest';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ObraActions } from 'Store/Obra/Obra.actions';

interface ReconnectIntegrationBoxProps {
    integrationEmail: string;
    showReconnectButton: boolean;
    serviceName: string;
    csId: number;
    api: number;
}

const ReconnectIntegrationBox: React.FC<ReconnectIntegrationBoxProps> = (props) => {

    const dispatch = useDispatch();
    const [isRedirecting, setIsRedirecting] = useState(false);

    const handleReconnection = () => {

        setIsRedirecting(true);

        const request: IUpdateConstructionSiteApiRequest = {
            CsId: props.csId,
            Api: props.api,
            ExistingConstructionSiteTokenId: 0,
            isRedirect: true,
            sameAccountRequired: true
        };
        dispatch(ObraActions.updateConstructionSiteApi(request));
    }

    return (
        <Card
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                textAlign: 'center',
                maxWidth: '700px',
                margin: '0 auto'
            }}
        >
            <Typography.Title level={3}>
                Conexão ao serviço {props.serviceName} expirou
            </Typography.Title>
            <Typography.Paragraph hidden={!props.showReconnectButton}>
                A conexão com o serviço de armazenamento de arquivos da sua obra foi desativada ou expirou.
                Porém, não se preocupe, todos os seus arquivos ainda estão armazenados e seguros.<br />
                Para voltar à normalidade, basta realizar a reconexão utilizando o mesmo e-mail<br /><b>{props.integrationEmail}</b>.
            </Typography.Paragraph>
            <Button type="primary" hidden={!props.showReconnectButton} onClick={handleReconnection} loading={isRedirecting}>
                Reconectar ao Google Drive
            </Button>
            <Typography.Paragraph hidden={props.showReconnectButton}>
                A conexão com o serviço de armazenamento de arquivos da sua obra foi desativada ou expirou.<br />
                <b>Entre em contato com o Administrador da conta para realizar a reconexão.</b>
            </Typography.Paragraph>
        </Card>
    );
};

export default ReconnectIntegrationBox;