import { ReactNode, useEffect, useMemo } from "react";
import OptionCustom from "Components/UI/NewFormGroup/OptionImg";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { 
  TopicPriorityEnum, 
  TopicVisibilityEnum,
} from "Data/interfaces/Activities/ITopicResponse";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { getDisciplineIcon, getTypeIcon } from "Utils/ObraUtils";
import { useSelector } from "react-redux";
import { getPropertiesObj, optionsDefaultOperationsObj } from "./helper";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { 
  FilterOperationMap, 
  IFiltroOptions, 
  IUpdateFilter,
} from "Data/interfaces/Activities/IFilters";

interface IUseFilterDropdown {
  id: string;
  item?: IFiltroOptions<ITopicDnDResponse>;
  updateFilter: (values: IUpdateFilter) => void;
}

export interface ICheckOptions {
  label: ReactNode | string;
  value: any;
  description: any;
}

const useFilterDropdown = ({
  id,
  item,
  updateFilter,
}: IUseFilterDropdown) => {
  const theme = useSelector(getTheme);
  const usersOptions = useSelector((state: IGlobalReducerState) => state.activities.usersOptions);
  const constructionSiteDisciplinesOptions = useSelector((state: IGlobalReducerState) => state.activities.constructionSiteDisciplinesOptions);
  const topicCommunicationTypesOptions = useSelector((state: IGlobalReducerState) => state.activities.topicCommunicationTypesOptions);
  const topicPointsOptions = useSelector((state: IGlobalReducerState) => state.activities.topicPointsOptions);
  const topicStagesOptions = useSelector((state: IGlobalReducerState) => state.activities.topicStagesOptions);
  const topicTypesOptions = useSelector((state: IGlobalReducerState) => state.activities.topicTypesOptions);
  const topicStatusOptions = useSelector((state: IGlobalReducerState) => state.activities.topicStatusOptions);

  const isCustom = item?.topicId === 'Identifier';

  const checkOptions = useMemo(() => {
    let options: ICheckOptions[] = [];

    const checkOptionsObj: Record<string, () => ICheckOptions[]> = {
      Visibility: () => [
        {label: <OptionCustom
            label="Rascunho"
            icon="editar"
            customColor={theme.colors.surface.onSurfacePlaceholder}
            customSize={12}
          />, 
          value: TopicVisibilityEnum.Draft,
          description: 'Rascunho',
        },
          {label: <OptionCustom
            label="Público"
            icon="globe"
            customColor={theme.colors.information.information}
            customSize={18}
          />, 
          value: TopicVisibilityEnum.Public,
          description: 'Público',
        },
          {label: <OptionCustom
            label="Privado"
            icon="cadeadoFechado"
            customColor={theme.colors.surface.onSurfaceVariant}
            customSize={16}
          />, 
          value: TopicVisibilityEnum.Private,
          description: 'Privado',
        },
      ],
      TopicAssignedUsers: () => {
        const opts: ICheckOptions[] = usersOptions.map(user => ({
          label: <OptionCustom
            label={user.Nome || user.Email || ''}
            avatar
            src={user.OriginalImageUrl || ''}
            thumbSrc={user.ThumbnailImageUrl}
            thumbType="small"
          />,
          value: user.Id,
          description: {
            name: user.Nome,
            img: user.OriginalImageUrl || user.ThumbnailImageUrl,
          },
        }));
        return opts;
      },
      ConstructionSiteDisciplines: () => {
        const opts: ICheckOptions[] = constructionSiteDisciplinesOptions.map(discipline => ({
          label: <OptionCustom
            label={discipline?.CustomName || discipline?.Discipline?.Name}
            icon={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name)}
            customColor={theme.colors.surface.onSurfacePlaceholder}
          />,
          value: discipline.ConstructionSiteDisciplinesId,
          description: discipline?.CustomName || discipline?.Discipline?.Name,
        }));
        return opts;
      },
      CommunicationTypes: () => {
        const opts: ICheckOptions[] = topicCommunicationTypesOptions.map(type => ({
          label: type.Name,
          value: type.TopicCommunicationTypeId,
          description: type.Name,
        }));
        return opts;
      },
      TopicPoints: () => {
        const opts: ICheckOptions[] = topicPointsOptions.map(type => ({
          label: type.Name,
          value: type.TopicPointId,
          description: type.Name,
        }));
        return opts;
      },
      Stage: () => {
        const opts: ICheckOptions[] = topicStagesOptions.map(type => ({
          label: type.Name,
          value: type.TopicStageId,
          description: type.Name,
        }));
        return opts;
      },
      Type: () => {
        const opts: ICheckOptions[] = topicTypesOptions.map(type => ({
          label: <OptionCustom
            label={type.Name}
            icon={getTypeIcon(type.Name)}
            customColor={theme.colors.surface.onSurfacePlaceholder}
          />,
          value: type.TopicTypeId,
          description: type.Name,
        }));
        return opts;
      },
      Priority: () => [
        {label: <OptionCustom
            label="Alta"
            icon="flag"
            customColor={theme.colors.danger.danger}
            customSize={18}
          />, 
          value: TopicPriorityEnum.High,
          description: 'Alta',
        },
          {label: <OptionCustom
            label="Média"
            icon="flag"
            customColor={theme.colors.warning.warning}
            customSize={16}
          />, 
          value: TopicPriorityEnum.Medium,
          description: 'Média',
        },
          {label: <OptionCustom
            label="Baixa"
            icon="flag"
            customColor={theme.colors.surface.onSurfacePlaceholder}
            customSize={16}
          />, 
          value: TopicPriorityEnum.Low,
          description: 'Baixa',
        },
      ],
      Status: () => {
        const opts: ICheckOptions[] = topicStatusOptions.map((type, i) => ({
          label: <OptionCustom
            label={type.Name}
            textColor={i === 0 ? undefined : type.Color}
            bgColor={`${type.Color}26`}
          />,
          value: type.TopicStatusId,
          description: type.Name,
        }));
        return opts;
      },
    }

    if (item?.topicId && checkOptionsObj.hasOwnProperty(item.topicId)) {
      options = checkOptionsObj[item.topicId]();
    }

    return {
      checkOptionsObj,
      options,
    };
  }, [
    item?.topicId, 
    constructionSiteDisciplinesOptions, 
    topicCommunicationTypesOptions, 
    topicPointsOptions, 
    topicStagesOptions, 
    topicStatusOptions, 
    topicTypesOptions, 
    usersOptions
  ]);
  const hasCheck = useMemo(() => checkOptions.options.length > 0, [checkOptions]);

  const handleKeyUpdateFilter = (selectValue: string) => {
    updateFilter({
      op: 'id',
      values: {
        filterId: id,
        topicId: selectValue as keyof ITopicDnDResponse,
      }
    });
  };

  const handleOperationUpdateFilter = (selectValue: keyof FilterOperationMap) => {
    updateFilter({
      op: 'op',
      values: {
        filterId: id,
        operation: selectValue,
      }
    });
  };

  const handleValueUpdateFilter = (selectValue: any) => {
    let properties = undefined;
    if (item?.topicId) {
      if (getPropertiesObj.hasOwnProperty(item.topicId)) {
        properties = getPropertiesObj[item.topicId];
      }
  
      if (hasCheck) {
        if (item?.value) {
          let value: any = item.value;

          if (value.includes(selectValue)) {
            value = value.filter((vl: any) => vl !== selectValue);
          } else {
            value.push(selectValue);
          }

          const filteredDescription = checkOptions.checkOptionsObj[item.topicId]()
            .filter(opt => {
              return value.includes(opt.value);
            });
          const description = filteredDescription.map(description => description.description);
  
          return updateFilter({
            op: 'vl',
            values: {
              filterId: id,
              value,
              properties,
              description,
            }
          });
        }
  
        const description = checkOptions.checkOptionsObj[item.topicId]()
          .find(opt => {
            return selectValue === opt.value;
          })?.description;

        return updateFilter({
          op: 'vl',
          values: {
            filterId: id,
            value: [selectValue],
            properties,
            description: [description],
          }
        });
      } 
      
      return updateFilter({
        op: 'vl',
        values: {
          filterId: id,
          value: selectValue,
          properties,
          description: selectValue,
        }
      });
    }
  };

  useEffect(() => {
    if (
      item?.topicId && 
      !item?.value && 
      !['empty', 'notEmpty'].includes(item.operation)
    ) {
      handleOperationUpdateFilter(optionsDefaultOperationsObj[item.topicId]);
    }
  }, [item?.topicId, item?.value]);

  return {
    isCustom,
    checkOptions,
    hasCheck,
    topicStatusOptions,
    handleKeyUpdateFilter,
    handleOperationUpdateFilter,
    handleValueUpdateFilter,
    theme,
  }
}

export default useFilterDropdown;
