import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import styles from './DadosFaturamento.module.scss';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import FaturamentoForm from '../Components/FaturamentoForm';
import ExitRedirect from '../Modals/ExitRedirect';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';
import BoxPlan from '../Components/BoxPlan';
import { history } from 'Store';
import Button from 'Components/UI/Button';
import { IBillingDataRequest } from 'Data/interfaces/Auth/IBillingDataRequest';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

export interface IDowngradeRequest {
  showWarning: number;
  billingData?: IBillingDataRequest;
}

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Faturamento',
    href: '/faturamento',
  },
  {
    description: 'Alterar plano',
    href: '/faturamento/planos',
  },
  {
    description: 'Dados de faturamento',
    href: '/faturamento/planos/dadosfaturamento',
  },
];

const DadosFaturamento: FC<Props> = ({ 
  showModalExitBillingData, 
  changePlan, 
  planIsLoading,
  downgradeRequestLoading,
  userInfo
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const [externalSubmit, setExternalSubmit] = React.useState(false);
  const [formValues, setFormValues] = React.useState({} as IBillingDataRequest);
  const [isSending, setIsSending] = React.useState(false);

  useEffect(() => {
    dispatch(RevenuesActions.cancelPageUpdateBillingData(true));
  }, []);

  useEffect(() => {
    if (formValues && isSending) {
      saveNewPlan();
    }
  }, [formValues, isSending]);

  useEffect(() => {
    if (isSending && !downgradeRequestLoading) {
      history.push('/faturamento');
    }
  }, [downgradeRequestLoading]);

  useEffect(() => {
    if (userInfo?.UserStoreItem.StorePlan?.StorePlansId) {
      dispatch(RevenuesActions.getDowgradeInfo(userInfo?.UserStoreItem.StorePlan?.StorePlansId));
    }
  }, [userInfo]);

  const onClose = () => {
    dispatch(RevenuesActions.showModalExitBillingData(false));
  };

  const redirectToPlanChange = () => {
    dispatch(RevenuesActions.cancelPageUpdateBillingData(false));
    history.push('/faturamento/planos');
  };

  const saveNewPlan = () => {
    if ((userInfo?.QtyActiveConstructionSites && changePlan?.MaxQtyConstructionSites) 
    && (userInfo.QtyActiveConstructionSites > changePlan.MaxQtyConstructionSites) 
    && (userInfo.QtyActiveUsers && changePlan?.MaxQtyUsers)
    && (userInfo.QtyActiveUsers > changePlan.MaxQtyUsers)) {
      setSubmitNewPlan(1);
    } else if ((userInfo?.QtyActiveConstructionSites && changePlan?.MaxQtyConstructionSites) 
    && (userInfo.QtyActiveConstructionSites > changePlan.MaxQtyConstructionSites)) {
      setSubmitNewPlan(2);
    } else if ((userInfo?.QtyActiveUsers && changePlan?.MaxQtyUsers)
    && (userInfo.QtyActiveUsers > changePlan.MaxQtyUsers)) {
      setSubmitNewPlan(3);
    } else {
      setSubmitNewPlan();
    }
  }

  const setSubmitNewPlan = (num?: number) => {
    if (num) {
      dispatch(RevenuesActions.cancelPageUpdateBillingData(false));
      dispatch(RevenuesActions.showWarningDowngradePlan({showWarning: num, billingData: formValues}));
      return history.push('/faturamento/planos/dadosfaturamento/downgrade');
    }

    if (changePlan?.StorePlansId) {
      dispatch(RevenuesActions.upgradeOrDowngradePlan({
        updateStorePlanId: changePlan?.StorePlansId,
        billingData: formValues,
        tenantUserIdsToDisable: [],
        tenantUserInviteIdsToRemove: [],
        constructionSiteIdsToDisable: [],
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      }));
      dispatch(RevenuesActions.cancelPageUpdateBillingData(false));
    }
  }

  return (
    <>
      <PageHeader  
        name={userInfo?.BillingData ? 'Confirme os dados de faturamento' : 'Preencha os dados de faturamento'}
      />
      <BreadCrumb breadcrumbs={breadCrumb}/>
      <div className={styles['Grid']}>
        <div className={styles['grid-left']}>
          <FaturamentoForm 
            title="Dados de faturamento"
            externalSubmit={externalSubmit} 
            externalButtons
            externalFormValues={setFormValues}
            externalSending={setIsSending} 
            setExternalSubmit={setExternalSubmit}
          />

        <div className={styles['box-external-buttons']}>
          <Button
            type="text"
            onClick={() => redirectToPlanChange()}
            className={styles['external-buttons-left']}
          >
            Voltar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setExternalSubmit(true)}
            loading={downgradeRequestLoading}
            className={styles['external-buttons-right']}
          >
            Confirmar dados de faturamento
          </Button>
        </div>
        </div>

        <div className={styles['grid-right']}>
          
          {changePlan ? (
            <BoxPlan
              title="Plano escolhido"
              plan={changePlan}
            />
          ) : null}

        {showModalExitBillingData ? (
          <ExitRedirect
            visible={showModalExitBillingData}
            onClose={onClose}
          />
        ) : null}

        </div>
      </div>
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  userInfo: state.auth.userInfo,
  downgradeRequestLoading: state.revenues.downgradeRequestLoading,
  changePlan: state.revenues.changePlan,
  Plano: state.adm.Plano,
  planIsLoading: state.adm.planIsLoading,
  showModalCancelBillingData: state.revenues.cancelPageUpdateBillingData,
  showModalExitBillingData: state.revenues.showModalExitBillingData
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux;

export default connector(DadosFaturamento);
