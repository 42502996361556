import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';

import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import { IFileHistory } from 'Data/interfaces/Notification/INotificationRequest';

export class NotificationApi {
  static async readNotification(): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Notification/SetAsReadNotifications');
  }

  static async fetchAllNotification(): Promise<AxiosResponse<INotificationData[]>> {
    const axios = await Http.axios();
    return axios.get('/Notification/ListNotifications');
  }

  static async fetchNewNotification(): Promise<AxiosResponse<INotificationData[]>> {
    const axios = await Http.axios();
    return axios.get('/Notification/ListNewNotifications');
  }

  static async fetchFileHistory(data: IFileHistory): Promise<AxiosResponse<INotificationData[]>> {
    const axios = await Http.axios();
    const query = Http.objectToQueryString(data)
    return axios.get(`/Notification/ListFileNotificationHistory?${query}`);
  }

  static async readNotificationByIds(request: number[]): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put('/Notification/SetAsReadNotificationByIds', request);
  }
}
