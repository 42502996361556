import styled from "styled-components";

export const TextWrap = styled.div`
  padding: 40px;

  div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .margin {
    margin-bottom: 25px;
  }
`;
