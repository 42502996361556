import styled, { css } from "styled-components";

interface ILeftBarStyled {
  isCollapsed?: string;
}

export const LeftBarStyled = styled.div<ILeftBarStyled>`
  width: 227px;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: ${({theme}) => theme.colors.surface.surface};
  transition: all 0.05s ease-out;
  border-right: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  z-index: 1;
  overflow-y: auto;

  ${({isCollapsed}) => isCollapsed === 'true' && css`
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    transition: all 0.3s ease-out;
    border: none;
    z-index: 1;
    overflow-y: visible;
  `}

  .menuItem {
    display: flex;
    justify-content: left;
    min-height: 45px;
    height: 45px;
    align-items: center;
    background-color: ${({theme}) => theme.colors.surface.surface};
    user-select: none;
    border-left: 4px solid transparent;
    cursor: pointer;

    &:hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
      background-color: ${({theme}) => theme.colors.surface.onSurfaceHover};
      border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;

      .icon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }
    }

    .icon {
      padding-left: 25px;
      padding-right: 25px;
    }

    p {
      margin-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .iconToggleWrap {
      padding: 0 10px;
      .iconChildren {
        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .iconPremium {
      width: 18px;
      position: relative;
      left: -14px;
    }

    .skeletonTitle {
      width: 150px;
    }

    .titleMenu {
      flex: 1;
      margin: 0 !important;
      height: 18px;
      display: flex;
      align-items: center;
    }

    ${({isCollapsed}) => isCollapsed === 'true' && css`
      height: 45px;
      display: block;

      p {
        visibility: hidden;
      }

      .children {
        visibility: hidden;
      }

      .icon {
        text-align: center;
        padding-top: 25px;
        padding-left: 22px;
        margin-left: auto;
        margin-right: auto;
      }

      .skeletonTitle {
        visibility: hidden;
      }

      @media screen and (max-height: 820px) {
        .icon {
          padding-top: 25px;
        }
      }
    `}
  }

  @media print {
    display: none;
  }
`;