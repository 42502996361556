import * as Yup from 'yup';

const schema = Yup.object().shape({
  username: Yup.string()
    .email('Digite um email válido')
    .required('Este campo deve ser preenchido'),
  password: Yup.string()
    .required('Este campo deve ser preenchido'),
});

export default schema;
