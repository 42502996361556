import React, { FC, useEffect } from "react";

import { Spin, Popover } from "antd";
import styles from "./MoreActionDropdown.module.scss";
import { Icon } from "../Icon";
import "./MoreActionDropdown.css"
import { LoadingOutlined } from "@ant-design/icons";

interface IMoreActionDropdown {
  isInvited?: boolean;
  isLoadingInvite?: boolean;
  isLoadingActive?: boolean;
  isLinkCopy?: boolean;
  isActive?: boolean;
  copyLink: () => void;
  reSendInvite: () => void;
  cancelInvite: () => void;
  removeUser: () => void;
  activeStatus: () => void;
  disabled?: boolean;
} 

const MoreActionDropdown: FC<IMoreActionDropdown> = ({ 
  isInvited, 
  isLoadingInvite,
  isLoadingActive,
  isLinkCopy,
  isActive,
  copyLink,
  reSendInvite,
  cancelInvite,
  removeUser,
  activeStatus,
  disabled
}) => {
  const [visible, setVisible] = React.useState<boolean>();
  const [isSentActive, setIsSentActive] = React.useState(false);
  const [isSentInvite, setIsSentInvite] = React.useState(false);
  
  useEffect(() => {
    if(isSentActive && (!isLoadingActive)) {
      hide();
    }
    if(isSentInvite && (!isLoadingInvite)) {
      hide();
    }
  }, [isLoadingActive, isLoadingInvite, isSentActive, isSentInvite]);

  const hide = () => {
    setVisible(false);
    setIsSentActive(false);
    setIsSentInvite(false);
  };

  const timeoutHide = () => {
    const timeout = setTimeout(() => {
      hide();
      clearTimeout(timeout);
    }, 1000);
  }

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const antIcon = <LoadingOutlined rev=""style={{ color: '#3547C8' , fontSize: 22 }} spin />;

  const overlay = (
    !isInvited ? (
      <div className={styles['MenuDropdown']}>
        {!isActive ? (
          <div 
            className={styles['menuItem']}
            onClick={() => {
              activeStatus()
              setIsSentActive(true)
            }}
          >
            {!isLoadingActive ? <Icon icon="conviteAceito" customColor="#8697A8"/> : <Spin indicator={antIcon}/>}
            Ativar
          </div>
        ) : (
          <div 
            onClick={() => {
              activeStatus();
              setVisible(false);
            }}
            className={styles['menuItem']}
          >
            <Icon icon="excluirUser" customColor="#8697A8"/>
            Inativar
          </div>
        )}
        <div 
          className={`${styles['menuItem']} ${styles['menuItemRemove']}`}
          onClick={() => {
            removeUser();
            setVisible(false);
          }}
        >
          <Icon icon="excluir" customColor="#8697A8"/>
          Excluir Usuário
        </div>
      </div>
    ) : (
      <div className={`${styles['MenuDropdown']} ${styles['MenuDropdownInvited']}`}>
        <span 
          className={styles['menuItem']} 
          style={{ color: `${isLinkCopy ? '#09B66D' : ''}` }}
          onClick={() => {
            copyLink();
            timeoutHide();
          }}
        >
          {isLinkCopy ? (
          <>  
            <Icon icon="confirmacao" customColor="#09B66D"/>
            Link copiado!
          </>  
          ) : (
          <>  
            <Icon icon="link" customColor="#8697A8"/>
            Copiar link do convite
          </>
          )}
        </span>
        <span 
          className={styles['menuItem']}
          onClick={() => {
            reSendInvite();
            setIsSentInvite(true);
          }}
        >
          {!isLoadingInvite ? <Icon icon="email" customColor="#8697A8"/> : <Spin indicator={antIcon} style={{ marginRight: 12}}/>}
          Reenviar convite
        </span>
        
        <span 
          className={`${styles['menuItem']} ${styles['menuItemRemove']}`}
          onClick={() => {
            cancelInvite()
            setVisible(false);
          }}
        >
          <Icon icon="excluir" customColor="#8697A8"/>
          Cancelar convite
        </span>
      </div>
    )
  )

  return (
    <Popover 
      content={overlay} 
      placement="bottomRight" 
      open={visible} 
      onOpenChange={handleVisibleChange}
    >
      <Icon
        icon="moreActions" 
        size="md" 
        color="cinzaPadrao" 
        className={styles['iconMoreAction']}
        style={disabled ? { cursor: 'not-allowed' } : {}}
        onClick={() => handleVisibleChange(!visible)}
      />
    </Popover>
  )
}

export default MoreActionDropdown;