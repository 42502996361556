import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IPanelsResponse } from './interfaces/Panels/IPanelsResponse';
import { IPanelsCreateRequest } from './interfaces/Panels/IPanelsCreateRequest';
import { IPanelsUpdateRequest } from './interfaces/Panels/IPanelsUpdateRequest';
import { IPanelsReorderRequest } from './interfaces/Panels/IPanelsReorderRequest';

export class PanelsApi {
  static async list(csId: number): Promise<AxiosResponse<IPanelsResponse[]>> {
    const axios = await Http.axios();
    return axios.get(`/obras/${csId}/panels`);
  }

  static async createPanel(panel: IPanelsCreateRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/panel`, panel);
  }

  static async deletePanel(panelId: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete(`/panel/${panelId}`);
  }

  static async updatePanel(panel: IPanelsUpdateRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.patch(`/panel/${panel.panelId}`, panel.data);
  }

  static async reorderPanel(reorder: IPanelsReorderRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/panel/reorder', reorder);
  }

  static async shareLinkPanel(request: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/panel/share', request);
  }
}
