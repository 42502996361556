import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { AuthActions } from "Store/Auth/Auth.actions";

import { Button } from "antd";
import Field from "Components/UI/Field";
import Form from "Components/UI/Form/Form";
import { IBillingDataResponse } from "Data/interfaces/Auth/IBillingData";
import { IBillingDataRequest } from "Data/interfaces/Auth/IBillingDataRequest";
import { stringToCEP, stringToCNPJ, stringToPhone } from "Utils/MaskUtils";

import styles from "./FaturamentoForm.module.scss";
import schema from "./FaturamentoForm.schema";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { Mixpanel } from "Utils/MixPanel";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

interface IFaturamentoForm {
  title?: string;
  externalButtons?: boolean;
  externalSubmit?: boolean;
  externalFormValues?: Dispatch<SetStateAction<IBillingDataRequest>>;
  externalSending?: Dispatch<SetStateAction<boolean>>;
  setExternalSubmit?: Dispatch<SetStateAction<boolean>>;
}

const FaturamentoForm: FC<Props> = ({ 
  title, 
  isLoading, 
  billingData, 
  externalButtons,
  externalSubmit,
  externalFormValues,
  externalSending,
  setExternalSubmit,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const [disabled, setDisabled] = React.useState(true);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [defaultValues, setDefaultValues] = React.useState({} as IBillingDataRequest);

  useEffect(() => {
    changeBillingData(billingData);
  }, [billingData]);

  useEffect(() => {
    changeBillingData(billingData);
  }, [disabled]);

  useEffect(() => {
    if (isSending && !isLoading) {
      cancelSubmit();
    }
  }, [setIsSending, isLoading]);
  
  useEffect(() => {
    if (externalButtons) {
      setDisabled(false);
    }
  }, [externalButtons]);

  const submit = (values: IBillingDataRequest) => {
    if (values) {
      Mixpanel.track({
        name: 'SAVE_BILLING_DATA', 
        props: {}, 
        userInfo,
        currentListTenant: currentTenant,
      });

      if (externalFormValues && externalSending && externalSubmit) {
        externalFormValues(values);
        return externalSending(true);
      }
      setIsSending(true);
      dispatch(AuthActions.updateBillingData(values));
    }
    if (setExternalSubmit) {
      setExternalSubmit(false);
    }
    setIsSubmit(false);
  };

  const changeBillingData = (setBillingData?: IBillingDataResponse) => {
    if (setBillingData) {
      return setDefaultValues({
        ...defaultValues,
        companyName: setBillingData.CompanyName,
        cnpj: setBillingData.CNPJ,
        phone: setBillingData.Phone,
        email: setBillingData.Email,
        ownerName: setBillingData.OwnerName,
        site: setBillingData.Site,
        zip: setBillingData.Zip,
        streetAddress: setBillingData.StreetAddress,
        number: setBillingData.Number,
        district: setBillingData.District,
        city: setBillingData.City,
        state: setBillingData.State,
        complement: setBillingData.Complement
      });
    }
    setDefaultValues({} as IBillingDataRequest);
  };

  const cancelSubmit = () => {
    changeBillingData();
    setDisabled(true);
  };
  
  return (
    <>
      <div 
        className={styles['box-form-wrapper']}
        style={externalButtons ? { paddingBottom: '5px', marginTop: 0 } : {}}
      >
        <div className={styles['title-form']}>
          <h4>{title}</h4>
        </div>

        <div className={styles['content']}/>

        <div className={styles['form-wrapper']}>
          <Form 
            schema={schema} 
            onSubmit={submit} 
            isSubmited={isSubmit || externalSubmit} 
            resetForm={true}
            initialValues={defaultValues} 
          > 
            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="CNPJ"
                  name="cnpj"
                  disabled={disabled}
                  parse={(value: any) => stringToCNPJ(value)}
                  maxLenght={18}
                />
              </div>
              <div>
                <Field
                  label="Nome da Empresa"
                  name="companyName"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="Telefone"
                  name="phone"
                  disabled={disabled}
                  parse={(value: any) => stringToPhone(value)}
                  maxLenght={15}
                />
              </div>
              <div>
                <Field
                  label="Responsável"
                  name="ownerName"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="E-mail"
                  name="email"
                  type="email"
                  disabled={disabled}
                />
              </div>
              <div>
                <Field
                  label="Site"
                  name="site"
                  disabled={disabled}
                />
              </div>
            </div>

            <div className={styles['content']}/>

            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="Logradouro"
                  name="streetAddress"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="Número"
                  name="number"
                  type="number"
                  disabled={disabled}
                />
              </div>
              <div>
                <Field
                  label="Complemento"
                  name="complement"
                  disabled={disabled}
                />
              </div>
              <div>
                <Field
                  label="Bairro"
                  name="district"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={styles['field-wrapper']}>
              <div>
                <Field
                  label="Cidade"
                  name="city"
                  disabled={disabled}
                />
              </div>
              <div>
                <Field
                  label="Estado"
                  name="state"
                  disabled={disabled}
                />
              </div>
              <div>
                <Field
                  label="CEP"
                  name="zip"
                  disabled={disabled}
                  parse={(value: any) => stringToCEP(value)}
                  maxLenght={9}
                />
              </div>
            </div>
          </Form>
        </div>
        {!externalButtons ? (
        <>  
          <div className={styles['content']}/>
          <div className={styles['footer-wrapper']}>
            {disabled ? (
              <p 
                onClick={() => setDisabled(false)}
                className={styles['button-data-change']}
              >
                Alterar dados de faturamento
              </p>
            ) : (
              <>
                <Button 
                  type="text"
                  onClick={() => cancelSubmit()}
                  className={styles['footer-button']}
                >
                  Cancelar
                </Button>
                <Button 
                  type="primary"
                  htmlType="submit"
                  onClick={() => setIsSubmit(true)}
                  className={styles['footer-button']}
                  loading={isLoading}
                >
                  Salvar
                </Button>
              </>
            )}
          </div>
        </>
        ) : null}
      </div>
    </>         
  )
}

const mapState = (state: IGlobalReducerState) => ({
  isLoading: state.auth.loadingBillingData,
  billingData: state.auth.userInfo?.BillingData,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & IFaturamentoForm;

export default connector(FaturamentoForm);
