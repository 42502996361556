import { FC, useState } from 'react';
import { Icon } from '../../Icon';
import Tooltip from '../../Tooltip';
import { ColumnHeaderWrap } from './styles';

export interface IColumnHeader {
  title: string;
  sorter?: (sortDirection: boolean) => void;
  showFilter?: () => void;
  informacaoAdicional?: string;
  showOrder?: boolean;
  className?: string;
}

const ColumnHeader: FC<IColumnHeader> = ({
  title,
  sorter,
  showFilter,
  informacaoAdicional,
  showOrder,
  className,
}) => {
  const [sortDirection, setSortDirection] = useState<boolean>();

  const sort = () => {
    if (sorter) {
      sorter(Boolean(sortDirection));
      setSortDirection(!sortDirection);
    }
  };

  return (
    <ColumnHeaderWrap className={className || ''}>
      {!!showFilter && (
        <Tooltip overlay="Filtrar" placement="bottom">
          <span className="filterWrap" onClick={showFilter}>
            <Icon
              icon="filtrar"
              color="cinzaIcone"
              customSize={12}
              className="filterIcon"
            />
            <span className="title">{title}</span>
          </span>
        </Tooltip>
      )}
      {!showFilter && (
        <>
          {!!informacaoAdicional && (
            <>
              <Tooltip
                overlay={informacaoAdicional}
                placement="bottom"
                overlayStyle={{ width: 330, wordWrap: 'break-word' }}
              >
                <span className="infoWrap">
                  <Icon
                    icon="informacaoAdicional"
                    color="cinzaIcone"
                    customSize={12}
                    className="infoIcon"
                  />
                </span>

              </Tooltip>
              <span className="title">{title}</span>
            </>
          )}
          {!informacaoAdicional && (
            <span className="title">{title}</span>
          )}
        </>
      )}
      {showOrder !== false && (
        <Tooltip overlay="Ordenar">
          <span className="orderButton" onClick={sort}>
            <Icon
              icon={sortDirection ? 'setaOrdenarAsc' : 'setaOrdenarDesc'}
              customSize={6}
              className="orderIcon"
            />
          </span>
        </Tooltip>
      )}

    </ColumnHeaderWrap>
  );
};

export default ColumnHeader;
