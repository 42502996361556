import styled, { css } from "styled-components";

interface IContainer {
  marginTop?: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  margin-top: ${({marginTop}) => marginTop || 0}px;
`;

export const Title = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({theme}) => theme.colors.surface.onSurface};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .searchContainer {
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
    margin-top: 12px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 43.5px 0;

    .emptyTitle {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .emptyText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      margin-top: 6px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  
  .formWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 12px;

    .fieldName {
      width: 100%;
    }
    
    .selectRole {
      width: 158px !important;
      height: 36px !important;
      display: flex;
      align-items: center;
      border-radius: 5px !important;
      margin-left: 12px;
    }

    .formBtn {
      display: flex;
      align-items: center;
      height: 32px !important;
      margin-left: 12px;
  
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({theme}) => theme.colors.secondary.onSecondary};
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .infoText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    .infoIcon {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      margin-left: 4px;
    }
  }
`;
