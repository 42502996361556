import styled, { css } from "styled-components";

interface IContent {
  loading?: boolean;
}

export const Content = styled.div<IContent>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  background-color: ${({theme}) => theme.colors.surface.surfaceContainer};

  ${({loading}) => loading && css`
    max-height: 420px;
    overflow: hidden;
  `}
`;

export const DataBadgeContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .text {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 999px;

    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
  }
`;

export const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
`;
