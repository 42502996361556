import React, { FC } from 'react';

import styles from './AvisoObraUpgrade.module.scss';
import Button from '../../../../Components/UI/Button';
import PlanoAvisoObraUpgrade from '../../../../Static/images/plano-aviso-obra-upgrade.png';
import { Icon } from '../../../../Components/UI';
import Modal from 'Components/UI/Modal/Modal';

interface IAvisoObraUpgrade {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm: (role: number, order: number) => void;
  order?: number;
  role?: number;
  planFree?: boolean;
}

const AvisoObraUpgrade: FC<IAvisoObraUpgrade> = ({ 
  visible, 
  onCancel, 
  onConfirm, 
  order,
  role, 
  planFree
}) => {
  
  return (
    <Modal
      footerButtons={false}
      visible={visible}
      width={'auto'}
      onCancel={onCancel}
    >
      <div className={styles['wrapper']}>
        <div className={styles['imageWrapper']}>
          <img src={PlanoAvisoObraUpgrade}></img>
        </div>
        <div className={`${styles['contentWrapper']} ${!planFree ? styles['contentWrapperPro'] : ''}`}>
          <div>
            <label className={styles['avisoLimiteObras']}>
              {planFree  
                ? role === 1 
                    ? 'Atenção: Você atingiu o limite de obras gratuitas!'
                    : 'Atenção: sua empresa atingiu o limite de obras gratuitas!'
                : role === 1
                    ? 'Atenção: Você atingiu o limite de obras do seu plano!'
                    : 'Atenção: sua empresa atingiu o limite de obras do plano!'
              }
            </label>
          </div>
          <h2>
            {planFree  
              ? role === 1 
                  ? 'Dê um upgrade na sua conta gratuita'
                  : 'Dê um upgrade na conta da sua empresa'
              : role === 1
                  ? 'Dê um upgrade no seu plano'
                  : 'Dê um upgrade no plano da sua empresa'
            }
          </h2>
          <h3>Para mais obras</h3>
          {planFree && <>  
            <p>O plano Premium ainda proporciona:</p>
            <div className={styles['icons-wrapper']}>
              <div className={`${styles['icons-text-wrapper']} ${styles['icons-text-margin']}`}>
                <div>
                  <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                  <span>Gestão de documentos 2D e 3D</span>
                </div>
                <div>
                  <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                  <span>Controle de nomeclatura</span>
                </div>
              </div>
              <div className={styles['icons-text-wrapper']}>
                <div>
                  <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                  <span>Gestão de usuários e papéis na obra</span>
                </div>
                <div>
                  <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                  <span>Controle de plotagem</span>
                </div>
              </div>
              <div style={{ marginBottom: 14 }}>&nbsp;</div>
            </div>
          </>}
          
          <div className={styles['button-wrapper']}>
            <Button className={styles['button-cancel']} type='link' width={190} height={48} onClick={onCancel}>
              Talvez mais tarde
            </Button>

            {order !== 4 && <Button className={styles['button-confirm']} type='primary' ispremium="true" width={287} height={48} onClick={() => onConfirm(role || 0, order || 0)}>
              <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
              {planFree && (role === 1 
                  ? 'Adquirir o plano Premium'
                  : 'Solicitar o plano Premium'
              )}
              {!planFree && `${role === 1 ? 'Adquirir' : 'Solicitar'} o plano ${order === 2 ? 'Pro' : 'Enterprise'}`}
            </Button>}

            {order === 4 && <Button className={styles['button-cancel']} type='primary' width={210} height={48} onClick={() => onConfirm(role || 0, order)}>
              Solicitar mais obras
            </Button>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvisoObraUpgrade;
