import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IRenderingSignalR } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IAutodeskViewer } from 'Data/interfaces/Viewer/IAutodeskViewer';
import { IModels } from 'Data/interfaces/FederatedViewer/IModels';
import { IPranchas } from 'Data/interfaces/FederatedViewer/IPranchas';
import { IProperties } from 'Data/interfaces/FederatedViewer/IProperties';
import { IModelsTree } from 'Data/interfaces/FederatedViewer/IModelsTree';
import { IViewpoints } from 'Data/interfaces/FederatedViewer/IViewpoints';
import { IUpdateStatusInViewer } from 'Data/interfaces/ListagemArquivos/IUpdateStatusInViewer';
import { IAutodeskViewerResponse, IFileViewerListResponse } from 'Data/interfaces/FederatedViewer/IFileViewerListResponse';
import { IDisciplineViewerList } from 'Data/interfaces/FederatedViewer/IDisciplineViewerList';
import { IUpdateFileInfoRequest } from 'Data/interfaces/Obra/IUpdateFileInfoRequest';
import { IUpdateStatusInViewerResponse } from 'Data/interfaces/ListagemArquivos/IUpdateStatusInViewerResponse';
import { IUploadViewerResponse } from 'Data/interfaces/FederatedViewer/IUploadViewerResponse';
import { IHideNodesAction, ISelectedNode } from 'Data/interfaces/FederatedViewer/INodes';
import { IVerifyAutodeskProgress } from 'Data/interfaces/ListagemArquivos/IVerifyAutodeskProgress';
import { IViews } from 'Data/interfaces/FederatedViewer/IViews';
import { IExtensionsValidated } from 'Data/interfaces/FederatedViewer/IExtensionsValidated';

export enum FederatedViewerActionKeys {
  VIEWER_LIST_DISCIPLINES_REQUEST = 'VIEWER_LIST_DISCIPLINES_REQUEST',
  VIEWER_LIST_DISCIPLINES_SUCCESS = 'VIEWER_LIST_DISCIPLINES_SUCCESS',
  VIEWER_LIST_DISCIPLINES_FAILED = 'VIEWER_LIST_DISCIPLINES_FAILED',

  VIEWER_LIST_FILES_REQUEST = 'VIEWER_LIST_FILES_REQUEST',
  VIEWER_LIST_FILES_SUCCESS = 'VIEWER_LIST_FILES_SUCCESS',
  VIEWER_LIST_FILES_FAILED = 'VIEWER_LIST_FILES_FAILED',
  VIEWER_UPDATE_FILE_LIST = 'VIEWER_UPDATE_FILE_LIST',

  UPDATE_STATUS_IN_VIEWER_REQUEST = 'UPDATE_STATUS_IN_VIEWER_REQUEST',
  UPDATE_STATUS_IN_VIEWER_SUCCESS = 'UPDATE_STATUS_IN_VIEWER_SUCCESS',
  UPDATE_STATUS_IN_VIEWER_FAILED = 'UPDATE_STATUS_IN_VIEWER_FAILED',

  ADD_NEW_VIEWER_FILE = 'ADD_NEW_VIEWER_FILE',

  AUTODESK_FEDERATED_VIEWER_REQUEST = 'AUTODESK_FEDERATED_VIEWER_REQUEST',
  AUTODESK_FEDERATED_VIEWER_SUCCESS = 'AUTODESK_FEDERATED_VIEWER_SUCCESS',
  AUTODESK_FEDERATED_VIEWER_FAILED = 'AUTODESK_FEDERATED_VIEWER_FAILED',

  SET_VIEWER_FILE_STATUS_RENDERING = 'SET_VIEWER_FILE_STATUS_RENDERING',
  SET_VIEWER_FILE_STATUS_RERENDERING = 'SET_VIEWER_FILE_STATUS_RERENDERING',
  SET_AUTODESK_FED_VIEWER_RENDERING_RESPONSE = 'SET_AUTODESK_FED_VIEWER_RENDERING_RESPONSE',

  VERIFY_VIEWER_AUTODESK_PROGRESS = 'VERIFY_VIEWER_AUTODESK_PROGRESS',

  SET_URNS = 'SET_URNS',
  SET_EXTENSION_VALIDATED = 'SET_EXTENSION_VALIDATED',
  CLEAR_UNLOAD_URN = 'CLEAR_UNLOAD_URN',
  SET_MODELS = 'SET_MODELS',
  SET_MODELS_TREE = 'SET_MODELS_TREE',
  SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES',
  SET_VIEWS = 'SET_VIEWS',
  SET_VIEWPOINTS = 'SET_VIEWPOINTS',
  SET_PRANCHAS = 'SET_PRANCHAS',
  CLEAR_ALL_SETS = 'CLEAR_ALL_SETS',
  
  SET_HIDE_NODES = 'SET_HIDE_NODES',
  SET_SELECT_NODE = 'SET_SELECT_NODE',

  CREATE_STATUS_FILE_VIEWER_AUTODESK = 'UPDATE_STATUS_FILE_VIEWER_AUTODESK',
  UPDATE_STATUS_FILE_VIEWER_AUTODESK = 'UPDATE_STATUS_FILE_VIEWER_AUTODESK',

  UPDATE_FILE_VIEWER_INFO = 'UPDATE_FILE_VIEWER_INFO',
}

export const FederatedViewerActions = {
  listDisciplines: (csId: number): ListDisciplinesAction =>  
    createAction(FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_REQUEST, csId),
  listDisciplinesSuccess: (ListDisciplinesResponse: IDisciplineViewerList[]): ListDisciplinesSuccessAction =>
    createAction(FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_SUCCESS, ListDisciplinesResponse),
  listDisciplinesFailure: (err: string): ListDisciplinesFailureAction =>
    createAction(FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_FAILED, err),

  listFiles: (disciplineId: number): ListFilesAction =>  
    createAction(FederatedViewerActionKeys.VIEWER_LIST_FILES_REQUEST, disciplineId),
  listFilesSuccess: (listFilesResponse: IFileViewerListResponse): ListFilesSuccessAction =>
    createAction(FederatedViewerActionKeys.VIEWER_LIST_FILES_SUCCESS, listFilesResponse),
  listFilesFailure: (err: string): ListFilesFailureAction =>
    createAction(FederatedViewerActionKeys.VIEWER_LIST_FILES_FAILED, err),
  updateFileList: (files: IFileViewerListResponse): UpdateFileListAction =>
    createAction(FederatedViewerActionKeys.VIEWER_UPDATE_FILE_LIST, files),

  updateStatusInViewer: (request: IUpdateStatusInViewer): UpdateStatusInViewerAction =>
    createAction(FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_REQUEST, request),
  updateStatusInViewerSuccess: (response: IUpdateStatusInViewerResponse): UpdateStatusInViewerSuccessAction =>
    createAction(FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_SUCCESS, response),
  updateStatusInViewerFailure: (err: string): UpdateStatusInViewerFailureAction =>
    createAction(FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_FAILED, err),

  addNewFile: (file: IUploadViewerResponse): AddNewFileAction =>
    createAction(FederatedViewerActionKeys.ADD_NEW_VIEWER_FILE, file),

  autoDeskViewer: (request: IAutodeskViewer): AutoDeskViewerAction =>
    createAction(FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_REQUEST, request),
  autoDeskViewerSuccess: (response: IAutodeskViewerResponse): AutoDeskViewerSuccessAction =>
    createAction(FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_SUCCESS, response),
  autoDeskViewerFailure: (err: string): AutoDeskViewerFailureAction =>
    createAction(FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_FAILED, err),

  verifyViewerAutodeskProgress: (data: IVerifyAutodeskProgress): VerifyViewerAutodeskProgressAction =>
    createAction(FederatedViewerActionKeys.VERIFY_VIEWER_AUTODESK_PROGRESS, data),

  autodeskViewerRenderingResponse: (data: IRenderingSignalR): AutodeskViewerRenderingResponseAction =>
    createAction(FederatedViewerActionKeys.SET_AUTODESK_FED_VIEWER_RENDERING_RESPONSE, data),

  setFileStatusRendering: (data: IAutodeskViewer): SetFileStatusRenderingAction =>
    createAction(FederatedViewerActionKeys.SET_VIEWER_FILE_STATUS_RENDERING, data),

  setFileStatusReRendering: (data: IAutodeskViewer): SetFileStatusReRenderingAction =>
    createAction(FederatedViewerActionKeys.SET_VIEWER_FILE_STATUS_RERENDERING, data),

  setUrns: (data: string): SetUrnsAction => 
    createAction(FederatedViewerActionKeys.SET_URNS, data),
  setExtensionValidated: (data: IExtensionsValidated): SetExtensionValidatedAction => 
    createAction(FederatedViewerActionKeys.SET_EXTENSION_VALIDATED, data),
  setModels: (data: IModels): SetModelsAction => 
    createAction(FederatedViewerActionKeys.SET_MODELS, data),
  clearUnloadUrn: (): ClearUnloadUrnAction => 
    createAction(FederatedViewerActionKeys.CLEAR_UNLOAD_URN),
  setModelsTree: (data: IModelsTree): SetModelsTreeAction => 
    createAction(FederatedViewerActionKeys.SET_MODELS_TREE, data),
  setSelectedProperties: (data: IProperties | null): SetSelectedPropertiesAction => 
    createAction(FederatedViewerActionKeys.SET_SELECTED_PROPERTIES, data),
  setViews: (data: IViews): SetViewsAction => 
    createAction(FederatedViewerActionKeys.SET_VIEWS, data),
  setViewpoints: (data: IViewpoints): SetViewpointsAction => 
    createAction(FederatedViewerActionKeys.SET_VIEWPOINTS, data),
  setPranchas: (data: IPranchas): SetPranchasAction => 
    createAction(FederatedViewerActionKeys.SET_PRANCHAS, data),
  clearAllSets: (): ClearAllSetsAction => 
    createAction(FederatedViewerActionKeys.CLEAR_ALL_SETS),

  setHideNodes: (data: IHideNodesAction | null): SetHideNodesAction => 
    createAction(FederatedViewerActionKeys.SET_HIDE_NODES, data),
  setSelectNode: (data: ISelectedNode | null): SetSelectNodeAction => 
    createAction(FederatedViewerActionKeys.SET_SELECT_NODE, data),

  createStatusFileViewerAutodesk: (request: IRenderingSignalR): CreateStatusFileViewerAutodeskAction =>
    createAction(FederatedViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK, request),
  updateStatusFileViewerAutodesk: (request: IRenderingSignalR): UpdateStatusFileViewerAutodeskAction =>
    createAction(FederatedViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK, request),

  updateFileViewerInfo: (response: IUpdateFileInfoRequest[]): UpdateFileViewerInfoAction =>
    createAction(FederatedViewerActionKeys.UPDATE_FILE_VIEWER_INFO, response),

};

export type FederatedViewerActionUnion = ActionsUnion<typeof FederatedViewerActions>;

export type ListDisciplinesAction = Action<FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_REQUEST, number>;
export type ListDisciplinesSuccessAction = Action<FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_SUCCESS, IDisciplineViewerList[]>;
export type ListDisciplinesFailureAction = Action<FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_FAILED, string>;

export type ListFilesAction = Action<FederatedViewerActionKeys.VIEWER_LIST_FILES_REQUEST, number>;
export type ListFilesSuccessAction = Action<FederatedViewerActionKeys.VIEWER_LIST_FILES_SUCCESS, IFileViewerListResponse>;
export type ListFilesFailureAction = Action<FederatedViewerActionKeys.VIEWER_LIST_FILES_FAILED, string>;
export type UpdateFileListAction = Action<FederatedViewerActionKeys.VIEWER_UPDATE_FILE_LIST, IFileViewerListResponse>;

export type UpdateStatusInViewerAction = Action<FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_REQUEST, IUpdateStatusInViewer>;
export type UpdateStatusInViewerSuccessAction = Action<FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_SUCCESS, IUpdateStatusInViewerResponse>;
export type UpdateStatusInViewerFailureAction = Action<FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_FAILED, string>;

export type AddNewFileAction = Action<FederatedViewerActionKeys.ADD_NEW_VIEWER_FILE, IUploadViewerResponse>;

export type AutoDeskViewerAction = Action<FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_REQUEST, IAutodeskViewer>;
export type AutoDeskViewerSuccessAction = Action<FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_SUCCESS, IAutodeskViewerResponse>;
export type AutoDeskViewerFailureAction = Action<FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_FAILED, string>;

export type VerifyViewerAutodeskProgressAction = Action<FederatedViewerActionKeys.VERIFY_VIEWER_AUTODESK_PROGRESS, IVerifyAutodeskProgress>;

export type SetFileStatusRenderingAction = Action<FederatedViewerActionKeys.SET_VIEWER_FILE_STATUS_RENDERING, IAutodeskViewer>;

export type SetFileStatusReRenderingAction = Action<FederatedViewerActionKeys.SET_VIEWER_FILE_STATUS_RERENDERING, IAutodeskViewer>;

export type AutodeskViewerRenderingResponseAction = Action<FederatedViewerActionKeys.SET_AUTODESK_FED_VIEWER_RENDERING_RESPONSE, IRenderingSignalR>;

export type SetUrnsAction = Action<FederatedViewerActionKeys.SET_URNS, string>;
export type SetExtensionValidatedAction = Action<FederatedViewerActionKeys.SET_EXTENSION_VALIDATED, IExtensionsValidated>;
export type ClearUnloadUrnAction = Action<FederatedViewerActionKeys.CLEAR_UNLOAD_URN>;
export type SetModelsAction = Action<FederatedViewerActionKeys.SET_MODELS, IModels>;
export type SetModelsTreeAction = Action<FederatedViewerActionKeys.SET_MODELS_TREE, IModelsTree>;
export type SetSelectedPropertiesAction = Action<FederatedViewerActionKeys.SET_SELECTED_PROPERTIES, IProperties | null>;
export type SetViewsAction = Action<FederatedViewerActionKeys.SET_VIEWS, IViews>;
export type SetViewpointsAction = Action<FederatedViewerActionKeys.SET_VIEWPOINTS, IViewpoints>;
export type SetPranchasAction = Action<FederatedViewerActionKeys.SET_PRANCHAS, IPranchas>;
export type ClearAllSetsAction = Action<FederatedViewerActionKeys.CLEAR_ALL_SETS>;

export type SetHideNodesAction = Action<FederatedViewerActionKeys.SET_HIDE_NODES, IHideNodesAction | null>;
export type SetSelectNodeAction = Action<FederatedViewerActionKeys.SET_SELECT_NODE, ISelectedNode | null>;

export type CreateStatusFileViewerAutodeskAction = Action<FederatedViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK, IRenderingSignalR>;
export type UpdateStatusFileViewerAutodeskAction = Action<FederatedViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK, IRenderingSignalR>;

export type UpdateFileViewerInfoAction = Action<FederatedViewerActionKeys.UPDATE_FILE_VIEWER_INFO, IUpdateFileInfoRequest[]>;
