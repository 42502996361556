import { FC } from "react";
import emptySearch from "Static/images/empty-search.png";
import { Container } from "./styles";

interface ICustomEmptyData {
  isDiscipline?: boolean;
}

const CustomEmptyData: FC<ICustomEmptyData> = ({ isDiscipline }) => {
  return (
    <Container>
      <img
        src={emptySearch}
        className="imgSearch"
        alt=""
      />
      <span className="title">
        {isDiscipline
          ? `Nenhuma disciplina encontrada.`
          : 'Nenhum arquivo encontrado.'}
      </span>
      <span className="subtitle">
        {isDiscipline
          ? 'A disciplina pesquisada não foi encontrada ou não existe. Verifique sua pesquisa e tente novamente.'
          : 'O arquivo pesquisado não foi encontrado ou não existe. Verifique sua pesquisa e tente novamente.'}
      </span>
    </Container>
  )
}

export default CustomEmptyData;