import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IObraResponse } from '../../Data/interfaces/Obra/IObraListResponse';
import { IDisciplineRequest } from '../../Data/interfaces/Obra/IDisciplineRequest';
import { IDisciplineResponse } from '../../Data/interfaces/Obra/IDisciplineResponse';
import { IListFilesRequest } from '../../Data/interfaces/ListagemArquivos/IListFilesRequest';
import { IDisciplineRequestDelete } from '../../Data/interfaces/Obra/IDisciplineRequestDelete';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { 
  ICreateSADisciplineRequest, 
  IDeleteSADisciplineRequest, 
  IMergeSADisciplineRequest, 
  IRestoreSADisciplineRequest,
} from 'Data/interfaces/Obra/ISolutionSADisciplineRequest';
import { IDisciplineSAStatusRequest } from 'Data/interfaces/Obra/IDisciplineSAStatusRequest';
import { IDisciplineSAStatusResponse } from 'Data/interfaces/Obra/IDisciplineSAStatusResponse';

export enum DisciplinaActionKeys {
  LIST_DISCIPLINE_REQUEST = 'LIST_DISCIPLINE_REQUEST',
  LIST_DISCIPLINE_SUCCESS = 'LIST_DISCIPLINE_SUCCESS',
  LIST_DISCIPLINE_FAILED = 'LIST_DISCIPLINE_FAILED',
  LIST_DISCIPLINE_UPDATE = 'LIST_DISCIPLINE_UPDATE',

  CREATE_OR_UPDATE_DISCIPLINE_REQUEST = 'CREATE_OR_UPDATE_DISCIPLINE_REQUEST',
  CREATE_OR_UPDATE_DISCIPLINE_SUCCESS = 'CREATE_OR_UPDATE_DISCIPLINE_SUCCESS',
  CREATE_OR_UPDATE_DISCIPLINE_FAILED = 'CREATE_OR_UPDATE_DISCIPLINE_FAILED',

  DISCIPLINES_RESET_STATE = 'DISCIPLINES_RESET_STATE',


  LIST_FILES_ON_DISCIPLINE_REQUEST = 'LIST_FILES_ON_DISCIPLINE_REQUEST',
  LIST_FILES_ON_DISCIPLINE_SUCCESS = 'LIST_FILES_ON_DISCIPLINE_SUCCESS',
  LIST_FILES_ON_DISCIPLINE_FAILED = 'LIST_FILES_ON_DISCIPLINE_FAILED',

  DELETE_DISCIPLINE_REQUEST = 'DELETE_DISCIPLINE_REQUEST',
  DELETE_DISCIPLINE_SUCCESS = 'DELETE_DISCIPLINE_SUCCESS',
  DELETE_DISCIPLINE_FAILED = 'DELETE_DISCIPLINE_FAILED',

  STATUS_SA_DISCIPLINE_REQUEST = 'STATUS_SA_DISCIPLINE_REQUEST',
  STATUS_SA_DISCIPLINE_SUCCESS = 'STATUS_SA_DISCIPLINE_SUCCESS',
  STATUS_SA_DISCIPLINE_FAILED = 'STATUS_SA_DISCIPLINE_FAILED',

  RESTORE_SA_DISCIPLINE_REQUEST = 'RESTORE_SA_DISCIPLINE_REQUEST',
  RESTORE_SA_DISCIPLINE_SUCCESS = 'RESTORE_SA_DISCIPLINE_SUCCESS',
  RESTORE_SA_DISCIPLINE_FAILED = 'RESTORE_SA_DISCIPLINE_FAILED',

  CREATE_SA_DISCIPLINE_REQUEST = 'CREATE_SA_DISCIPLINE_REQUEST',
  CREATE_SA_DISCIPLINE_SUCCESS = 'CREATE_SA_DISCIPLINE_SUCCESS',
  CREATE_SA_DISCIPLINE_FAILED = 'CREATE_SA_DISCIPLINE_FAILED',
  LIST_ARCHIVES_NEW_DISCIPLINE_NO_CACHE = 'LIST_ARCHIVES_NEW_DISCIPLINE_NO_CACHE', 

  DELETE_SA_DISCIPLINE_REQUEST = 'DELETE_SA_DISCIPLINE_REQUEST',
  DELETE_SA_DISCIPLINE_SUCCESS = 'DELETE_SA_DISCIPLINE_SUCCESS',
  DELETE_SA_DISCIPLINE_FAILED = 'DELETE_SA_DISCIPLINE_FAILED',

  MERGE_SA_DISCIPLINE_REQUEST = 'MERGE_SA_DISCIPLINE_REQUEST',
  MERGE_SA_DISCIPLINE_SUCCESS = 'MERGE_SA_DISCIPLINE_SUCCESS',
  MERGE_SA_DISCIPLINE_FAILED = 'MERGE_SA_DISCIPLINE_FAILED',
}

export const DisciplinaActions = {
  list: (request: IUpdateDisciplineStatusRequest): ListAction =>
    createAction(DisciplinaActionKeys.LIST_DISCIPLINE_REQUEST, request),
  listSuccess: (obra: IObraResponse): ListSuccessAction =>
    createAction(DisciplinaActionKeys.LIST_DISCIPLINE_SUCCESS, obra),
  listFailure: (err: string): ListFailureAction =>
    createAction(DisciplinaActionKeys.LIST_DISCIPLINE_FAILED, err),
  listUpdate: (obra: IObraResponse): ListUpdateAction =>
    createAction(DisciplinaActionKeys.LIST_DISCIPLINE_UPDATE, obra),

  createOrUpdate: (discipline: IDisciplineRequest): CreateOrUpdateAction =>
    createAction(DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_REQUEST, discipline),
  createOrUpdateSuccess: (response: IDisciplineResponse): CreateOrUpdateSuccessAction =>
    createAction(DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_SUCCESS, response),
  createOrUpdateFailure: (err: string): CreateOrUpdateFailureAction =>
    createAction(DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_FAILED, err),

  resetState: (): ResetStateAction =>
    createAction(DisciplinaActionKeys.DISCIPLINES_RESET_STATE),

  listFilesOnDisciplineRequest: (infoFile: IListFilesRequest): ListFilesOnDisciplineRequestAction =>
    createAction(DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_REQUEST, infoFile),
  listFilesOnDisciplineSuccess: (disciplineContainsFile: number): ListFilesOnDisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_SUCCESS, disciplineContainsFile),
  listFilesOnDisciplineFailure: (err: string): ListFilesOnDisciplineFailureAction =>
    createAction(DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_FAILED, err),

  deleteDisciplineRequest: (infoDisciplineDelete: IDisciplineRequestDelete): DeleteDisciplineRequestAction =>
    createAction(DisciplinaActionKeys.DELETE_DISCIPLINE_REQUEST, infoDisciplineDelete),
  deleteDisciplineSuccess: (infoDisciplineDelete: IDisciplineRequestDelete): DeleteDisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.DELETE_DISCIPLINE_SUCCESS, infoDisciplineDelete),
  deleteDisciplineFailure: (err: string): DeleteDisciplineFailureAction =>
    createAction(DisciplinaActionKeys.DELETE_DISCIPLINE_FAILED, err),

  statusSADisciplineRequest: (request: IDisciplineSAStatusRequest): StatusSADisciplineRequestAction =>
    createAction(DisciplinaActionKeys.STATUS_SA_DISCIPLINE_REQUEST, request),
  statusSADisciplineSuccess: (response: IDisciplineSAStatusResponse): StatusSADisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.STATUS_SA_DISCIPLINE_SUCCESS, response),
  statusSADisciplineFailure: (err: string): StatusSADisciplineFailureAction =>
    createAction(DisciplinaActionKeys.STATUS_SA_DISCIPLINE_FAILED, err),

  restoreSADisciplineRequest: (request: IRestoreSADisciplineRequest): RestoreSADisciplineRequestAction =>
    createAction(DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_REQUEST, request),
  restoreSADisciplineSuccess: (ConstructionSiteDisciplinesId: number): RestoreSADisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_SUCCESS, ConstructionSiteDisciplinesId),
  restoreSADisciplineFailure: (err: string): RestoreSADisciplineFailureAction =>
    createAction(DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_FAILED, err),

  createSADisciplineRequest: (request: ICreateSADisciplineRequest): CreateSADisciplineRequestAction =>
    createAction(DisciplinaActionKeys.CREATE_SA_DISCIPLINE_REQUEST, request),
  createSADisciplineSuccess: (response: IDisciplineResponse): CreateSADisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.CREATE_SA_DISCIPLINE_SUCCESS, response),
  createSADisciplineFailure: (err: string): CreateSADisciplineFailureAction =>
    createAction(DisciplinaActionKeys.CREATE_SA_DISCIPLINE_FAILED, err),
  listArchiveNewDisciplineNoCache: (list: boolean | null): ListArchiveNewDisciplineNoCacheAction =>
    createAction(DisciplinaActionKeys.LIST_ARCHIVES_NEW_DISCIPLINE_NO_CACHE, list),

  deleteSADisciplineRequest: (request: IDeleteSADisciplineRequest): DeleteSADisciplineRequestAction =>
    createAction(DisciplinaActionKeys.DELETE_SA_DISCIPLINE_REQUEST, request),
  deleteSADisciplineSuccess: (ConstructionSiteDisciplinesId: number): DeleteSADisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.DELETE_SA_DISCIPLINE_SUCCESS, ConstructionSiteDisciplinesId),
  deleteSADisciplineFailure: (err: string): DeleteSADisciplineFailureAction =>
    createAction(DisciplinaActionKeys.DELETE_SA_DISCIPLINE_FAILED, err),

  mergeSADisciplineRequest: (request: IMergeSADisciplineRequest): MergeSADisciplineRequestAction =>
    createAction(DisciplinaActionKeys.MERGE_SA_DISCIPLINE_REQUEST, request),
  mergeSADisciplineSuccess: (response: IDisciplineResponse): MergeSADisciplineSuccessAction =>
    createAction(DisciplinaActionKeys.MERGE_SA_DISCIPLINE_SUCCESS, response),
  mergeSADisciplineFailure: (err: string): MergeSADisciplineFailureAction =>
    createAction(DisciplinaActionKeys.MERGE_SA_DISCIPLINE_FAILED, err),
};

export type DisciplinaActionUnion = ActionsUnion<typeof DisciplinaActions>;

export type ListAction = Action<DisciplinaActionKeys.LIST_DISCIPLINE_REQUEST, IUpdateDisciplineStatusRequest>;
export type ListSuccessAction = Action<DisciplinaActionKeys.LIST_DISCIPLINE_SUCCESS, IObraResponse>;
export type ListFailureAction = Action<DisciplinaActionKeys.LIST_DISCIPLINE_FAILED, string>;
export type ListUpdateAction = Action<DisciplinaActionKeys.LIST_DISCIPLINE_UPDATE, IObraResponse>;

export type CreateOrUpdateAction = Action<DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_REQUEST, IDisciplineRequest>;
export type CreateOrUpdateSuccessAction =
  Action<DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_SUCCESS, IDisciplineResponse>;
export type CreateOrUpdateFailureAction = Action<DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_FAILED, string>;

export type ResetStateAction = Action<DisciplinaActionKeys.DISCIPLINES_RESET_STATE>;

export type ListFilesOnDisciplineRequestAction = Action<DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_REQUEST, IListFilesRequest>;
export type ListFilesOnDisciplineSuccessAction = Action<DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_SUCCESS, number>;
export type ListFilesOnDisciplineFailureAction = Action<DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_FAILED, string>;

export type DeleteDisciplineRequestAction = Action<DisciplinaActionKeys.DELETE_DISCIPLINE_REQUEST, IDisciplineRequestDelete>;
export type DeleteDisciplineSuccessAction = Action<DisciplinaActionKeys.DELETE_DISCIPLINE_SUCCESS, IDisciplineRequestDelete>;
export type DeleteDisciplineFailureAction = Action<DisciplinaActionKeys.DELETE_DISCIPLINE_FAILED, string>;

export type StatusSADisciplineRequestAction = Action<DisciplinaActionKeys.STATUS_SA_DISCIPLINE_REQUEST, IDisciplineSAStatusRequest>;
export type StatusSADisciplineSuccessAction = Action<DisciplinaActionKeys.STATUS_SA_DISCIPLINE_SUCCESS, IDisciplineSAStatusResponse>;
export type StatusSADisciplineFailureAction = Action<DisciplinaActionKeys.STATUS_SA_DISCIPLINE_FAILED, string>;

export type RestoreSADisciplineRequestAction = Action<DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_REQUEST, IRestoreSADisciplineRequest>;
export type RestoreSADisciplineSuccessAction = Action<DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_SUCCESS, number>;
export type RestoreSADisciplineFailureAction = Action<DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_FAILED, string>;

export type CreateSADisciplineRequestAction = Action<DisciplinaActionKeys.CREATE_SA_DISCIPLINE_REQUEST, ICreateSADisciplineRequest>;
export type CreateSADisciplineSuccessAction = Action<DisciplinaActionKeys.CREATE_SA_DISCIPLINE_SUCCESS, IDisciplineResponse>;
export type CreateSADisciplineFailureAction = Action<DisciplinaActionKeys.CREATE_SA_DISCIPLINE_FAILED, string>;
export type ListArchiveNewDisciplineNoCacheAction = Action<DisciplinaActionKeys.LIST_ARCHIVES_NEW_DISCIPLINE_NO_CACHE, boolean | null>;

export type DeleteSADisciplineRequestAction = Action<DisciplinaActionKeys.DELETE_SA_DISCIPLINE_REQUEST, IDeleteSADisciplineRequest>;
export type DeleteSADisciplineSuccessAction = Action<DisciplinaActionKeys.DELETE_SA_DISCIPLINE_SUCCESS, number>;
export type DeleteSADisciplineFailureAction = Action<DisciplinaActionKeys.DELETE_SA_DISCIPLINE_FAILED, string>;

export type MergeSADisciplineRequestAction = Action<DisciplinaActionKeys.MERGE_SA_DISCIPLINE_REQUEST, IMergeSADisciplineRequest>;
export type MergeSADisciplineSuccessAction = Action<DisciplinaActionKeys.MERGE_SA_DISCIPLINE_SUCCESS, IDisciplineResponse>;
export type MergeSADisciplineFailureAction = Action<DisciplinaActionKeys.MERGE_SA_DISCIPLINE_FAILED, string>;
