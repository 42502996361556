import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { ILoginRequest } from '../../Data/interfaces/Auth/ILoginRequest';
import { IListCompanyPosition, IRegisterRequest } from '../../Data/interfaces/Auth/IRegisterRequest';
import { IForgotPasswordRequest } from '../../Data/interfaces/Auth/IForgotPasswordRequest';
import { IResetPasswordRequest } from '../../Data/interfaces/Auth/IResetPasswordRequest';
import { IRegisterGoogleRequest } from '../../Data/interfaces/Auth/IRegisterGoogleRequest';
import { IUserInforResponse } from '../../Data/interfaces/Auth/IUserInfoResponse';
import { ILoginResponse } from '../../Data/interfaces/Auth/ILoginResponse';
import { IBillingDataRequest } from 'Data/interfaces/Auth/IBillingDataRequest';
import { IBillingDataResponse } from 'Data/interfaces/Auth/IBillingData';
import { IUserLimitsActive } from './interfaces/IAuthState';

export enum AuthActionKeys {
  AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',

  LIST_COMPANY_POSITION_REQUEST = 'LIST_COMPANY_POSITION_REQUEST',
  LIST_COMPANY_POSITION_SUCCESS = 'LIST_COMPANY_POSITION_SUCCESS',
  LIST_COMPANY_POSITION_FAILED = 'LIST_COMPANY_POSITION_FAILED',

  AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST',
  AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS',
  AUTH_REGISTER_FAILED = 'AUTH_REGISTER_FAILED',

  AUTH_REGISTER_GOOGLE_REQUEST = 'AUTH_REGISTER_GOOGLE_REQUEST',
  AUTH_REGISTER_GOOGLE_SUCCESS = 'AUTH_REGISTER_GOOGLE_SUCCESS',
  AUTH_REGISTER_GOOGLE_FAILED = 'AUTH_REGISTER_GOOGLE_FAILED',

  AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST',
  AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS',
  AUTH_FORGOT_PASSWORD_FAILED = 'AUTH_FORGOT_PASSWORD_FAILED',

  AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST',
  AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS',
  AUTH_RESET_PASSWORD_FAILED = 'AUTH_RESET_PASSWORD_FAILED',

  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',

  UPDATE_BILLING_DATA_REQUEST = 'UPDATE_BILLING_DATA_REQUEST',
  UPDATE_BILLING_DATA_SUCCESS = 'UPDATE_BILLING_DATA_SUCCESS',
  UPDATE_BILLING_DATA_FAILED = 'UPDATE_BILLING_DATA_FAILED',
  
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  
  WARNING_REQUEST_TIME = 'WARNING_REQUEST_TIME',

  TOOGLE_LIMITS_ACTIVE = 'TOOGLE_LIMITS_ACTIVE',
}

export const AuthActions = {
  userLogin: (userData: ILoginRequest): UserLoginAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_REQUEST, userData),
  userLoginSuccess: (response: ILoginResponse): UserLoginSuccessAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_SUCCESS, response),
  userLoginFailure: (err: string): UserLoginFailureAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_FAILED, err),

  listCompanyPosition: (): ListCompanyPositionAction =>
    createAction(AuthActionKeys.LIST_COMPANY_POSITION_REQUEST),
  listCompanyPositionSuccess: (companyPositionList: IListCompanyPosition[]): ListCompanyPositionSuccessAction =>
    createAction(AuthActionKeys.LIST_COMPANY_POSITION_SUCCESS, companyPositionList),
  listCompanyPositionFailure: (err: string): ListCompanyPositionFailureAction =>
    createAction(AuthActionKeys.LIST_COMPANY_POSITION_FAILED, err),

  userRegister: (userData: IRegisterRequest): UserRegisterAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_REQUEST, userData),
  userRegisterSuccess: (message: string): UserRegisterSuccessAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_SUCCESS, message),
  userRegisterFailure: (message: string): UserRegisterFailureAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_FAILED, message),

  userRegisterGoogle: (userData: IRegisterGoogleRequest): UserRegisterGoogleAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_GOOGLE_REQUEST, userData),
  userRegisterGoogleSuccess: (message: string): UserRegisterGoogleSuccessAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_GOOGLE_SUCCESS, message),
  userRegisterGoogleFailure: (message: string): UserRegisterGoogleFailureAction =>
    createAction(AuthActionKeys.AUTH_REGISTER_GOOGLE_FAILED, message),

  userForgotPassword: (userData: IForgotPasswordRequest): UserForgotPasswordAction =>
    createAction(AuthActionKeys.AUTH_FORGOT_PASSWORD_REQUEST, userData),
  userForgotPasswordSuccess: (message: string): UserForgotPasswordSuccessAction =>
    createAction(AuthActionKeys.AUTH_FORGOT_PASSWORD_SUCCESS, message),
  userForgotPasswordFailure: (message: string): UserForgotPasswordFailureAction =>
    createAction(AuthActionKeys.AUTH_FORGOT_PASSWORD_FAILED, message),

  userResetPassword: (userData: IResetPasswordRequest): UserResetPasswordAction =>
    createAction(AuthActionKeys.AUTH_RESET_PASSWORD_REQUEST, userData),
  userResetPasswordSuccess: (message: string): UserResetPasswordSuccessAction =>
    createAction(AuthActionKeys.AUTH_RESET_PASSWORD_SUCCESS, message),
  userResetPasswordFailure: (message: string): UserResetPasswordFailureAction =>
    createAction(AuthActionKeys.AUTH_RESET_PASSWORD_FAILED, message),

  getUserInfo: (): GetUserInfoAction =>
    createAction(AuthActionKeys.GET_USER_INFO_REQUEST),
  getUserInfoSuccess: (userInfo: IUserInforResponse): GetUserInfoSuccessAction =>
    createAction(AuthActionKeys.GET_USER_INFO_SUCCESS, userInfo),
  getUserInfoFailure: (message: string): GetUserInfoFailureAction =>
    createAction(AuthActionKeys.GET_USER_INFO_FAILED, message),

  updateBillingData: (billingData: IBillingDataRequest): UpdateBillingDataAction =>
    createAction(AuthActionKeys.UPDATE_BILLING_DATA_REQUEST, billingData),
  updateBillingDataSuccess: (billingData: IBillingDataResponse): UpdateBillingDataSuccessAction =>
    createAction(AuthActionKeys.UPDATE_BILLING_DATA_SUCCESS, billingData),
  updateBillingDataFailure: (message: string): UpdateBillingDataFailureAction =>
    createAction(AuthActionKeys.UPDATE_BILLING_DATA_FAILED, message),

  userLogout: (): UserLogoutAction =>
    createAction(AuthActionKeys.AUTH_LOGOUT),
  
  warningRequestTime: (time: boolean): WarningRequestTimeAction =>
    createAction(AuthActionKeys.WARNING_REQUEST_TIME, time),

  toogleUserLimitsActive: (response: IUserLimitsActive): ToggleUserLimitsActiveAction =>
    createAction(AuthActionKeys.TOOGLE_LIMITS_ACTIVE, response),
};

export type AuthActionUnion = ActionsUnion<typeof AuthActions>;

export type UserLoginAction = Action<AuthActionKeys.AUTH_LOGIN_REQUEST, ILoginRequest>;
export type UserLoginSuccessAction = Action<AuthActionKeys.AUTH_LOGIN_SUCCESS, ILoginResponse>;
export type UserLoginFailureAction = Action<AuthActionKeys.AUTH_LOGIN_FAILED, string>;

export type ListCompanyPositionAction = Action<AuthActionKeys.LIST_COMPANY_POSITION_REQUEST>;
export type ListCompanyPositionSuccessAction = Action<AuthActionKeys.LIST_COMPANY_POSITION_SUCCESS, IListCompanyPosition[]>;
export type ListCompanyPositionFailureAction = Action<AuthActionKeys.LIST_COMPANY_POSITION_FAILED, string>;

export type UserRegisterAction = Action<AuthActionKeys.AUTH_REGISTER_REQUEST, IRegisterRequest>;
export type UserRegisterSuccessAction = Action<AuthActionKeys.AUTH_REGISTER_SUCCESS, string>;
export type UserRegisterFailureAction = Action<AuthActionKeys.AUTH_REGISTER_FAILED, string>;

export type UserRegisterGoogleAction = Action<AuthActionKeys.AUTH_REGISTER_GOOGLE_REQUEST, IRegisterGoogleRequest>;
export type UserRegisterGoogleSuccessAction = Action<AuthActionKeys.AUTH_REGISTER_GOOGLE_SUCCESS, string>;
export type UserRegisterGoogleFailureAction = Action<AuthActionKeys.AUTH_REGISTER_GOOGLE_FAILED, string>;

export type UserForgotPasswordAction = Action<AuthActionKeys.AUTH_FORGOT_PASSWORD_REQUEST, IForgotPasswordRequest>;
export type UserForgotPasswordSuccessAction = Action<AuthActionKeys.AUTH_FORGOT_PASSWORD_SUCCESS, string>;
export type UserForgotPasswordFailureAction = Action<AuthActionKeys.AUTH_FORGOT_PASSWORD_FAILED, string>;

export type UserResetPasswordAction = Action<AuthActionKeys.AUTH_RESET_PASSWORD_REQUEST, IResetPasswordRequest>;
export type UserResetPasswordSuccessAction = Action<AuthActionKeys.AUTH_RESET_PASSWORD_SUCCESS, string>;
export type UserResetPasswordFailureAction = Action<AuthActionKeys.AUTH_RESET_PASSWORD_FAILED, string>;

export type GetUserInfoAction = Action<AuthActionKeys.GET_USER_INFO_REQUEST>;
export type GetUserInfoSuccessAction = Action<AuthActionKeys.GET_USER_INFO_SUCCESS, IUserInforResponse>;
export type GetUserInfoFailureAction = Action<AuthActionKeys.GET_USER_INFO_FAILED, string>;

export type UpdateBillingDataAction = Action<AuthActionKeys.UPDATE_BILLING_DATA_REQUEST, IBillingDataRequest>;
export type UpdateBillingDataSuccessAction = Action<AuthActionKeys.UPDATE_BILLING_DATA_SUCCESS, IBillingDataResponse>;
export type UpdateBillingDataFailureAction = Action<AuthActionKeys.UPDATE_BILLING_DATA_FAILED, string>;

export type UserLogoutAction = Action<AuthActionKeys.AUTH_LOGOUT>;

export type WarningRequestTimeAction = Action<AuthActionKeys.WARNING_REQUEST_TIME, boolean>;

export type ToggleUserLimitsActiveAction = Action<AuthActionKeys.TOOGLE_LIMITS_ACTIVE, IUserLimitsActive>;
