export interface IFiltersRequest {
  Type: FilterType;
  ConstructionSiteFk: number;
  ParentId?: string;
  CreateShortURL: boolean;
  Filters: IFiltersData[];
};

export enum FilterType {
    File = 1,
    Activity = 2,
}

export interface IFiltersData {
  Field: string;
  Values: any[];
  ExtraData: Record<string, any>;
};
