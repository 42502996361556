import { call, put } from 'redux-saga/effects';
import { ObrasApi } from '../../Data/Obras.api';
import toastHandler from '../../Utils/toastHandler';

import {
  ListagemArquivosActions,
  ListFilesAction,
  DeleteFilesAction,
  DownloadFilesAction,
  UpdateFileInfoAction,
  RenameFileAction,
  CreateFolderAction,
  MoveFileAction,
  UnarchiveFilesAction,
  VerifyAutodeskProgressAction,
  FilesVersionAction
} from './ListagemArquivos.actions';
import { IUpdateFileInfoRequest } from '../../Data/interfaces/Obra/IUpdateFileInfoRequest';
import {
  IFileData,
  IRenderingSignalR
} from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { loadError } from '../../Utils/Messages';
import { ViewerActions } from '../Viewer/Viewer.actions';
import { IVerifyAutodeskProgress } from '../../Data/interfaces/ListagemArquivos/IVerifyAutodeskProgress';
import { UpdateStatusActions } from 'Store/UpdateStatusFileList/UpdateStatus.actions';
import { IListFilesRequest } from 'Data/interfaces/ListagemArquivos/IListFilesRequest';
import { FederatedViewerActions } from 'Store/FederatedViewer/FederatedViewer.actions';
import { ApisEnum } from 'Data/enums/Apis';
import { Mixpanel } from 'Utils/MixPanel';
import dayjs from 'dayjs';

export function* listFiles({ payload }: ListFilesAction) {
  const { userInfoTracking, currentTenantTracking } = payload;
  delete payload.userInfoTracking;
  delete payload.currentTenantTracking;

  try {
    const request: IListFilesRequest = {
      ...payload,
      loadObsoleteFiles: false
    };

    const isGoogleDriveExperimentOnTrmt =
      payload.api === ApisEnum.GoogleDrive && payload.googleDriveExperiment === 'test';

    const beforeApiCall = dayjs();

    const apiCall = isGoogleDriveExperimentOnTrmt
      ? ObrasApi.listGoogleDriveFiles
      : ObrasApi.listFiles;

    const { data } = yield call(apiCall, request);

    const apiResponseTime = dayjs().diff(beforeApiCall, 'milliseconds');

    Mixpanel.track({
      name: 'FILE_LIST_API_CALL',
      props: {
        elapsed: apiResponseTime,
        googleDriveExperiment: payload.googleDriveExperiment,
        api: payload.api
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    if (payload.isObsoleteFiles) {
      data.data.isObsoleteFiles = payload.isObsoleteFiles;
    }

    yield put(ListagemArquivosActions.listFilesSuccess(data.data));
    yield put(
      ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUserPermissions)
    );
    yield put(UpdateStatusActions.updateStatusSuccess(data.data.LastUpdateDate));
    yield put(
      ListagemArquivosActions.loadObsoleteFiles(data.data.RequestManageObsoleteFiles)
    );

    const hasRenderFile =
      data.data.Files.findIndex((x: IFileData) => x.RenderingStatus !== 0) !== -1;
    if (hasRenderFile) {
      const filesApiId = data.data.Files.filter(
        (x: IFileData) => x.RenderingStatus === 2
      ).map((x: IFileData) => x.Identifier.ApiId);
      const dataVerifyAutodesk = {
        constructionSiteId: payload.constructionSiteId,
        filesApiId: filesApiId
      } as IVerifyAutodeskProgress;
      yield put(ListagemArquivosActions.verifyAutodeskProgress(dataVerifyAutodesk));
    }
  } catch (e: any) {
    Mixpanel.track({
      name: 'ERROR_FILE_LIST_API_CALL',
      props: {
        googleDriveExperiment: payload.googleDriveExperiment,
        api: payload.api
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    const msg = 'Erro ao listar arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.listFilesFailure(error));
    toastHandler.showError(error);
  }
}

export function* verifyAutodeskProgress({ payload }: VerifyAutodeskProgressAction) {
  try {
    for (let i = 0; i < payload.filesApiId.length; i++) {
      const currentFileApiId: string = payload.filesApiId[i];

      const { data } = yield call(
        ObrasApi.verifyFileRenderProgress,
        currentFileApiId,
        payload.constructionSiteId
      );

      const res = data && data.data;
      if (!res) {
        continue;
      }

      let etapa = 0;
      let progress =
        res.progress && res.progress.indexOf('%') !== -1
          ? Number(res.progress.split('%')[0])
          : 0;

      switch (res.status) {
        case 'inprogress':
          etapa = 3;
          break;
        case 'success':
          etapa = 4;
          progress = 100;
          break;
        case 'notstarted':
          etapa = 1;
          break;
        case 'failed':
          etapa = 5;
          progress = 100;
          break;
      }

      let body: IRenderingSignalR = {
        etapa,
        fileIdentifier: currentFileApiId || '',
        progress,
        urn: res.urn
      };
      yield put(ListagemArquivosActions.autodeskViewerRenderingResponse(body));
      yield put(ViewerActions.updateStatusFileViewerAutodesk(body));
    }
  } catch (e) {
    const error = loadError('informações da renderização de um arquivo');
    toastHandler.showError(error);
  }
}

export function* deleteFiles({ payload }: DeleteFilesAction) {
  try {
    const { hasFolder, onlyFolder } = payload;
    delete payload.hasFolder;
    delete payload.onlyFolder;

    yield call(ObrasApi.deleteFiles, payload);
    yield put(ListagemArquivosActions.deleteFilesSuccess(payload));
    yield put(ListagemArquivosActions.setShowModalDeleteFiles(false));

    let coreMsg = 'Arquivo(s) excluído(s)';
    if (hasFolder) {
      if (onlyFolder) {
        coreMsg = 'Pasta(s) excluída(s)';
      } else {
        coreMsg = 'Arquivo(s) e Pasta(s) excluído(s)';
      }
    }
    const msg = `${coreMsg} com sucesso!`
    toastHandler.showSuccess(msg);
  } catch (e: any) {
    const msg = 'Erro ao realizar exclusão dos arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.deleteFilesFailure(error));
    yield put(ListagemArquivosActions.setShowModalDeleteFiles(false));
    toastHandler.showError(error);
  }
}

export function* downloadFiles({ payload }: DownloadFilesAction) {
  const { userInfoTracking, currentTenantTracking } = payload;
  delete payload.userInfoTracking;
  delete payload.currentTenantTracking;

  try {
    const beforeDownload = dayjs();
    const { data } = yield call(ObrasApi.downloadFiles, payload);

    const downloadResponseTime = dayjs().diff(beforeDownload, 'milliseconds');
    Mixpanel.track({
      name: 'DOWNLOAD_FILE',
      props: {
        api: payload.api,
        constructionSiteId: payload.constructionSiteId,
        elapsed: downloadResponseTime,
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    yield put(ListagemArquivosActions.downloadFilesSuccess(data.data));
  } catch (e: any) {
    Mixpanel.track({
      name: 'DOWNLOAD_FILE_ERROR',
      props: {
        api: payload.api,
        constructionSiteId: payload.constructionSiteId,
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    const msg = 'Erro ao realizar download dos arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.downloadFilesFailure(error));
    toastHandler.showError(error);
  }
}

export function* renameFile({ payload }: RenameFileAction) {
  try {
    const { data } = yield call(ObrasApi.renameFile, payload);
    yield put(ListagemArquivosActions.renameFileSuccess(data.data));
    toastHandler.showSuccess('Arquivo atualizado com sucesso!');
  } catch (e: any) {
    const msg = 'Erro ao renomear arquivo';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.renameFileFailure(error));
    toastHandler.showError(error);
  }
}

export function* updateFileInfo({ payload }: UpdateFileInfoAction) {
  try {
    const { data } = yield call(ObrasApi.updateFileInfo, payload);
    const response: IUpdateFileInfoRequest[] = data.data;
    response.forEach((fileEdit) => {
      const file = payload.find((filePayload) => fileEdit.ApiId === filePayload.ApiId);
      if (file) {
        file.Description = fileEdit.Description;
        file.finished = true;
      }
    });
    yield put(ListagemArquivosActions.updateFileInfoSuccess(response));
    yield put(FederatedViewerActions.updateFileViewerInfo(response));

    if (!payload.find((item) => item.notShowToast === true)) {
      const isStatusEdit = payload.every(item => item.isStatusEdit);
      const onlyOne = payload.length === 1 ? '' : 's';

      toastHandler.showSuccess(isStatusEdit 
        ? `Status do${onlyOne} arquivo${onlyOne} alterado${onlyOne} com sucesso!`
        : `Descrição do${onlyOne} arquivo${onlyOne} atualizada${onlyOne} com sucesso!`
      );
    }
  } catch (e: any) {
    const msg = 'Erro ao realizar atualização dos arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.updateFileInfoFailure(error));
    toastHandler.showError(error);
  }
}

export function* createFolder({ payload }: CreateFolderAction) {
  try {
    const { data } = yield call(ObrasApi.createFolder, payload);
    yield put(ListagemArquivosActions.createFolderSuccess(data.data));
  } catch (e: any) {
    const msg = 'Erro ao criar pasta';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.createFolderFailure(error));
    toastHandler.showError(error);
  }
}

export function* listFilesMoveTo({ payload }: ListFilesAction) {
  try {
    const { data } = yield call(ObrasApi.listFiles, payload);
    if (payload.isObsoleteFiles) {
      data.data.isObsoleteFiles = payload.isObsoleteFiles;
    }
    yield put(ListagemArquivosActions.listFilesMoveToSuccess(data.data));
  } catch (e: any) {
    const msg = 'Erro ao listar arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.listFilesMoveToFailure(error));
    toastHandler.showError(error);
  }
}

export function* moveFile({ payload }: MoveFileAction) {
  try {
    yield call(ObrasApi.moveFile, payload);
    yield put(ListagemArquivosActions.moveFileSuccess(payload));
  } catch (e: any) {
    const msg = 'Erro ao mover arquivo';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.moveFileFailure(error));
    toastHandler.showError(error);
  }
}

export function* unarchiveFiles({ payload }: UnarchiveFilesAction) {
  try {
    yield call(ObrasApi.unarchiveFiles, payload);
    yield put(ListagemArquivosActions.unarchiveFilesSuccess(payload));
    toastHandler.showSuccess('Desarquivado com sucesso');
  } catch (e: any) {
    const msg = 'Erro ao desarquivar arquivo';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.unarchiveFilesFailure(error));
    toastHandler.showError(error);
  }
}

export function* filesVersion({ payload }: FilesVersionAction) {
  delete payload.userInfoTracking;

  try {
    const { data } = yield call(ObrasApi.listFiles, payload);

    yield put(ListagemArquivosActions.filesVersionSuccess(data.data));
    yield put(
      ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUserPermissions)
    );
    yield put(UpdateStatusActions.updateStatusSuccess(data.data.LastUpdateDate));

    const hasRenderFile =
      data.data.Files.findIndex((x: IFileData) => x.RenderingStatus !== 0) !== -1;
    if (hasRenderFile) {
      const filesApiId = data.data.Files.filter(
        (x: IFileData) => x.RenderingStatus === 2
      ).map((x: IFileData) => x.Identifier.ApiId);
      const dataVerifyAutodesk = {
        constructionSiteId: payload.constructionSiteId,
        filesApiId: filesApiId
      } as IVerifyAutodeskProgress;
      yield put(ListagemArquivosActions.verifyAutodeskProgress(dataVerifyAutodesk));
    }
  } catch (e: any) {
    const msg = 'Erro ao listar arquivos';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(ListagemArquivosActions.listFilesFailure(error));
    yield put(ListagemArquivosActions.filesVersionFailure(error));
    toastHandler.showError(error);
  }
}
