import styled, { css } from "styled-components";

interface ITabsWrapper {
  isCollapsed?: string;
}

export const TabsWrapper = styled.div<ITabsWrapper>`
  width: calc(100vw - 227px);
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.colors.surface.surface};
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin-left: 1px;
  position: fixed;
  z-index: 3;

  .tabsLeft {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .tabsObra {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 14px 0 30px;
    height: 100%;
    max-width: 200px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    .avatar {
      margin-right: 8px;
    }
  }

  .divider {
    width: 1px;
    height: 36px;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
    margin-left: 6px;
  }

  ${({isCollapsed}) => isCollapsed === 'true' && css`
    width: calc(100vw - 52px);
    margin-left: 0;
  `}
`;

export const DividerMain = styled.div`
  height: 52px;
`;

interface ITabsTitle {
  active?: boolean;
  marginRight?: number;
}

export const TabsTitle = styled.div<ITabsTitle>`
  margin-left: 6px;
  display: flex;
  align-items: center;
  padding: 0 8px 0 11px;
  height: 100%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;

  .icon {
    margin-right: 4px;
  }

  .countPanelsBadge {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-left: 8px;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};

    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.secondary};
  }

  ${({active}) => active && css`
    border-bottom: 2px solid ${({theme}) => theme.colors.primary.primary};
  `}

  ${({marginRight}) => marginRight && css`
    margin-right: ${marginRight}px;
  `}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 275px;
  border-radius: 8px;
 
  .imgWrapper {
    width: 100%;
    min-height: 150px;
    background-color: ${({theme}) => theme.colors.primary.primary};
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .iconDropClose {
      position: absolute;
      color: ${({theme}) => theme.colors.surface.surface};
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .contentWrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .titleContent {
      margin-top: 8px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 700;
      color: #324150;
    }

    .textContent {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.secondary.onSecondary};
    }
  }
`;
