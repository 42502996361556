import React, { FC } from 'react';

import styles from './UpdateConta.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import Form from '../../../../Components/UI/Form/Form';
import Field from '../../../../Components/UI/Field';
import FloatingFeedback from '../../../../Components/UI/Input/TextInput/FloatingFeedback/FloatingFeedback';

import schema, { schemaPassword }from './updateConta.schema'
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { MinhaContaActions } from '../../../../Store/MinhaConta/MinhaConta.actions';
import { INovaSenhaRequest } from '../../../../Data/interfaces/MinhaConta/INovaSenhaRequest';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';

interface IModal {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
  userInfoId: string;
}

const UpdatePassword: FC<IModal> = ({ visible, isLoading, onCancel, onConfirm, userInfoId }) => {
  const dispatch = useDispatch();
  const [isSubmited, setIsSubmited] = React.useState(false);

  const [newPassword, setNewPassword] = React.useState<string | undefined>(undefined);
  const [newPasswordFocus, setNewPasswordFocus] = React.useState<boolean>(false);

  const [confirmNewPassword, setConfirmNewPassword] = React.useState<string | undefined>(undefined);
  const [confirmNewPasswordFocus, setConfirmNewPasswordFocus] = React.useState<boolean>(false);

  const alterarSenha = (values: any, errors: any) => {
    if(isSubmited){
      const alterarSenha : INovaSenhaRequest = {
        UserId: userInfoId || '',
        Password: values.Password,
        NewPassword: values.NewPassword,
        ConfirmNewPassword: values.ConfirmNewPassword
      };
      dispatch(MinhaContaActions.novaSenha(alterarSenha)); 
      setNewPassword(undefined);
      setNewPasswordFocus(false);
      setConfirmNewPassword(undefined);
      setConfirmNewPasswordFocus(false);
      setIsSubmited(false);
    }
  };

  const buttons = (
    <div>
      <Button height={37} onClick={onCancel}>
        <span style={{ padding: '0 20px' }}>
          Cancelar
        </span>
      </Button>
      <Button
        type='primary'
        height={37}
        onClick={() => setIsSubmited(true)}
        loading={isLoading}>
        <span style={{ padding: '0 18px' }}>
          Atualizar
        </span>
      </Button>
    </div>
  );

  return (
    <Modal title='Dados da segurança' footerButtons={buttons} visible={visible} width={798} onCancel={onCancel}>
      <div className={styles['boxDetalhesConta']}>
            <div className={styles['DetalhesConta']}>
              <div className={styles['boxData']}>
                <Form onSubmit={alterarSenha} schema={schema} isSubmited={isSubmited} className={styles['form']} resetForm={true}>
                  <Field 
                    type='password' 
                    name='Password' 
                    label='Senha Atual' 
                  />
                  
                  <Field className={styles['newPassword']}
                    type='password' 
                    name='NewPassword' 
                    label='Nova senha'
                    onInput={setNewPassword}
                    onBlur={() => setNewPasswordFocus(false)}
                    onFocus={() => setNewPasswordFocus(true)}
                  />
                  <FloatingFeedback
                    name='NewPassword' schema={schemaPassword} inputValue={newPassword}
                    visible={newPasswordFocus} className={styles['newPasswordFeedback']} 
                  />

                  <Field className={styles['confirmPassword']}
                    type='password' 
                    name='ConfirmNewPassword' 
                    label='Repita nova senha'
                    onInput={setConfirmNewPassword}
                    onBlur={() => setConfirmNewPasswordFocus(false)}
                    onFocus={() => setConfirmNewPasswordFocus(true)} 
                  />
                  <FloatingFeedback
                    name='ConfirmNewPassword' schema={schemaPassword} inputValue={confirmNewPassword}
                    visible={confirmNewPasswordFocus} className={styles['confirmNewPasswordFeedback']}
                    context={{ newPassword }}
                  />
                </Form>
              </div>
            </div>
        </div>
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(UpdatePassword);