import React, { FC } from "react";
import Button from "./../../../../Components/UI/Button";
import Modal from "./../../../../Components/UI/Modal/Modal";
import styles from "./VisualizacaoBloqueadosModal.module.scss";

interface IVisualizacaoBloqueadosModal {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
  hasPermission: boolean;
}

const VisualizacaoBloqueadosModal: FC<IVisualizacaoBloqueadosModal> = ({
  visible,
  onConfirm,
  onClose,
  hasPermission,
}) => {

  const buttons = () => {
    if (hasPermission) {
      return (
        <div className={styles['footer-buttons']}>
          <Button
            onClick={onClose}
            className={styles['cancel-button']}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={onConfirm}
            className={styles['confirm-button']}
          >
            Visualizar arquivo
          </Button>
        </div>
      )
    }
    return (
      <div className={styles['footer-buttons']}>
        <Button
          type="text"
          onClick={onClose}
          className={styles['cancel-button-not-permission']}
        >
          Fechar
        </Button>
      </div>
    )
  };

  return (
    <Modal
      title={hasPermission ? "Atenção" : "Visualização bloqueada"}
      width={487}
      visible={visible}
      onCancel={onClose}
      footerButtons={buttons()}
    >
      <div className={styles['main-wrapper']}>
        <p>
          {hasPermission
            ? 'O arquivo que você está tentando visualizar está bloqueado! Isso significa que ele pode não estar finalizado e liberado para visualizar.' 
            : 'O arquivo que você está tentando visualizar está bloqueado! Isso significa que ele não esta finalizado e liberado para visualização.'
          }
        </p>
        <p>
          {hasPermission
            ? 'Você deseja continuar?' 
            : 'Caso precise visualizar o arquivo, entre em contato com o responsável para liberá-lo.'
          }
        </p>
      </div>
    </Modal>
  )
}

export default VisualizacaoBloqueadosModal;
