import React, { FC, useEffect } from 'react';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import Button from '../../../Components/UI/Button';
import { Icon } from '../../../Components/UI';
import { iconFileByExtension } from '../../../Utils/Icons';
import toastHandler from '../../../Utils/toastHandler';

import { Row, Col, Checkbox } from 'antd';

import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { PlotagemActions } from '../../../Store/Plotagem/Plotagem.actions';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { IPlotagemSolicitacao } from '../../../Data/interfaces/Plotagem/IPlotagemListResponse';

import {
  getbreadCrumb,
  getbreadCrumbLoading,
  IPlotagemAReceber,
} from './PlotagemAReceber.helpers';

import styles from './PlotagemAReceber.module.scss';
import { IPlotagemArquivo } from '../../../Data/interfaces/Plotagem/IPlotagemArquivo';
import { useHistory, useParams } from 'react-router';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import LoadingReceberPlotagens from 'Components/UI/CustomLoading/LoadingReceberPlotagens';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { Http } from 'Utils/Http';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface Files {
  id: number;
  name: string;
  qtyCopies: string;
}

interface PlottingInfo {
  key: number;
  description: string;
  qtyArqchives: number;
  qtyCopies: number;
  responsibleName: string;
  files: string[];
  filesCheck: string[];
  indeterminate: boolean;
  checkAll: boolean;
  showHidde: boolean;
}

const PlotagemArquivos: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const history = useHistory();

  const { constructionSiteId, ConstructionSiteDisciplineFk } = useParams<any>();
  const CheckboxGroup = Checkbox.Group;
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [plottingGroup, setPlottingGroup] = React.useState<PlottingInfo[]>([]);
  const [contSelecteds, setContselecteds] = React.useState(false);
  const folderName = Http.GetQueryParams('folderName');

  useEffect(() => {
    dispatch(PlotagemActions.listPlotagemSolicitacoesDisciplinas(props.match.params.ConstructionSiteDisciplineFk));
    dispatch(ListagemArquivosActions.setShowPlotRequestTable(false))
    if (props.PlotagemSolicitacaoResponse.data?.length && !isSubmit) {
      setPlottingGroup(getPlottingRowFromData(props.PlotagemSolicitacaoResponse.data));
    }
  }, [props.PlotagemSolicitacaoResponse.data?.length, props.match.params.ConstructionSiteDisciplineFk, dispatch]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const search = history.location.search;
    dispatch(ObraActions.storePlotagensPath({
      ...props.match,
      url: `${pathname}${search}`
    }));
  }, [props.match]);

  const getPlottingRowFromData = (plottingData: IPlotagemSolicitacao[]) => {
    const plotsGroup: PlottingInfo[] = [];
    let countInfo: number = 1;
    plottingData.forEach((plotInfo) => {
      const files: string[] = [];
      let archive: string = '';
      plotInfo.Files.forEach((fileInfoRequest) => {
        let hasColor = fileInfoRequest.HasColor ? 'Colorido' : 'P&B';
        archive = fileInfoRequest.PlottingFilesId + '|' + fileInfoRequest.Name + '|' + fileInfoRequest.Extension + '|' + hasColor;
        files.push(archive);
      });

      plotsGroup.push({
        key: countInfo,
        description: plotInfo.Description,
        qtyArqchives: plotInfo.QtyRequest,
        qtyCopies: plotInfo.QtyRequest,
        responsibleName: plotInfo.PlotterResponsibleName,
        files: files,
        filesCheck: [],
        indeterminate: false,
        checkAll: false,
        showHidde: true,
      });
      countInfo++;
    });

    plotsGroup.forEach((plottingGroup) => {
      plottingGroup.indeterminate = !!plottingGroup.filesCheck.length && plottingGroup.filesCheck.length < plottingGroup.files.length;
      plottingGroup.checkAll = plottingGroup.filesCheck.length === plottingGroup.files.length;
    })
    return plotsGroup;
  };

  const validateSelected = () => {
    let countFilesCheck = 0;
    plottingGroup.forEach((plotGroup) => {
      plotGroup.filesCheck.forEach((file) => {
        countFilesCheck++;
      });
    });
    if (countFilesCheck == 0) {
      setContselecteds(false);
    } else {
      setContselecteds(true);
    }
  }

  const onCheckOne = (checkedList: any, key: number) => {
    const plotsGroupAux = [...plottingGroup];
    plotsGroupAux.forEach((plotGroup) => {
      if (plotGroup.key == key) {
        plotGroup.filesCheck = checkedList;
        plotGroup.indeterminate = !!checkedList.length && checkedList.length < plotGroup.files.length;
        plotGroup.checkAll = checkedList.length === plotGroup.files.length;
      }
    });
    setPlottingGroup(plotsGroupAux);
    validateSelected();
  };

  const onCheckAll = (e: any, key: number) => {
    const plotsGroupAux = [...plottingGroup];
    plotsGroupAux.forEach((plotGroup) => {
      if (plotGroup.key == key) {
        plotGroup.filesCheck = e.target.checked ? plotGroup.files : [];
        plotGroup.indeterminate = false;
        plotGroup.checkAll = e.target.checked;
      }
    });
    setPlottingGroup(plotsGroupAux);
    validateSelected();
  };

  const hiddeShow = (key: number) => {
    const plotsGroupAux = [...plottingGroup];
    plotsGroupAux.forEach((plotGroup) => {
      if (plotGroup.key == key) {
        plotGroup.showHidde = !plotGroup.showHidde;
      }
    });
    setPlottingGroup(plotsGroupAux);
  }

  const cancelReceivingPlots = () => history.push(`/obras/plotagens/filelist/${constructionSiteId}/${ConstructionSiteDisciplineFk}`)

  const submit = () => {
    setIsSubmit(true);
    if (!props.isLoading) {
      if (contSelecteds) {
        const requestFilesData: number[] = []
        plottingGroup.forEach((plotGroup) => {
          plotGroup.filesCheck.forEach((file) => {
            let getId = Number(plotGroup.filesCheck[plotGroup.filesCheck.indexOf(file)].split('|')[0]);
            requestFilesData.push(getId);
          });
        });
        const requestData: IPlotagemArquivo = {
          ConstructionSiteId: props.match.params.constructionSiteId,
          PlottingFilesId: requestFilesData,
          disciplineIdTracking: props.match.params.ConstructionSiteDisciplineFk,
          userInfoTracking: props.userInfo,
          currentTenantTracking: currentTenant,
        }
        dispatch(PlotagemActions.recebePlotagem(requestData));
      } else {
        toastHandler.showError('Selecione uma ou mais arquivos');
      }
    }
  }

  let breadcrumbs: IBreadcrumbItem[] = []
  if (props.isLoading && !props.isDownloading) {
    breadcrumbs = getbreadCrumbLoading();
  } else {
    breadcrumbs = getbreadCrumb(props.PlotagemSolicitacaoResponse.info, props.match.params.constructionSiteId);
  }
  return (
    <div>
      <TabsShell 
        ConstructionSiteId={props.match.params.constructionSiteId}
        active={PathActiveEnum.plotagens}
        loading={props.isLoading && !props.isDownloading}
        match={props.match}
      />
      <PageHeader name='Recebimento de Plotagem'>
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className={styles['Wrapper']}>
          <div className={styles['tableWrapper']}>
            <span className={styles['title']}>Aguardando a confirmação de Recebimento</span>
            <div className={styles['plotsContainer']}>
              {
                props.isLoading && !isSubmit
                  ? <LoadingReceberPlotagens multiple={6} />
                  : (<>
                    <Row className={styles['headerItens']}>
                      <Col span={12}> <span>SOLICITAÇÃO</span> </Col>
                      <Col span={3}> <span>ARQUIVOS</span> </Col>
                      <Col span={3}> <span>CÓPIAS</span> </Col>
                      <Col span={6}> <span>ENTREGAR PARA</span> </Col>
                    </Row>
                    <div className={styles['bodyItens']}>
                      {
                        plottingGroup.map((plotGroup, index) => (<>
                          <table 
                            key={plotGroup.key} 
                            style={{ 
                              width: '100%', 
                              marginTop: '5px' 
                            }}
                          >
                            <tbody>
                              <tr className={styles['headerTableItens'] + ' ' + styles[`${plotGroup.checkAll ? 'fileCheckedColor' : 'fileUncheckedColor'}`]}>
                                <td style={{ width: '2%' }}>
                                  <Checkbox
                                    indeterminate={plotGroup.indeterminate}
                                    checked={plotGroup.checkAll}
                                    onChange={e => onCheckAll(e, plotGroup.key)}
                                  />
                                </td>
                                <td style={{ width: '2%' }}>
                                  <Icon color='primary' className={styles['iconHideShow']} icon={plotGroup.showHidde ? 'avancar' : 'expandir'} customSize={8} onClick={() => hiddeShow(plotGroup.key)} />
                                </td>
                                <td style={{ width: '2%' }}>
                                  <Icon color='cinzaPadrao' className={styles['iconArchive']} icon={'arquivar'} customSize={18} />
                                </td>
                                <td style={{ width: '44.5%' }}>{plotGroup.description}</td>
                                <td style={{ width: '12%' }}>{plotGroup.qtyArqchives}</td>
                                <td style={{ width: '12%' }}>{plotGroup.qtyCopies}</td>
                                <td>{plotGroup.responsibleName}</td>
                              </tr>
                            </tbody>
                          </table>
                          <CheckboxGroup
                            value={plotGroup.filesCheck}
                            onChange={e => onCheckOne(e, plotGroup.key)}
                            style={{ width: '100%' }}
                            className={styles[`${plotGroup.showHidde ? 'itemPlotRequestHidde' : ''}`]}
                          >
                            <table style={{ width: '100%' }}>
                              <tbody>
                                {plotGroup.files.map(option =>
                                  <tr key={option} className={styles['bodyTableItens'] + ' ' + styles[`${plotGroup.filesCheck[plotGroup.filesCheck.indexOf(option)] ? 'fileCheckedColor' : 'fileUncheckedColor'}`]}>
                                    <td style={{ width: '2%' }} className={styles['checkBoxItem']}>
                                      <Checkbox value={option} key={option} />
                                    </td>
                                    <td style={{ width: '2%' }} className={styles['iconFileItem']}>
                                      <Icon color='cinzaPadrao' icon={iconFileByExtension(option.split('|')[3])} customSize={20} />
                                    </td>
                                    <td style={{ width: '59%' }} className={styles['']}>
                                      <div className={styles['FileNameItem']}>
                                        {option.split('|')[1]}
                                      </div>
                                    </td>
                                    <td style={{ width: '36%' }} className={styles['ColorItem']}>{option.split('|')[3]}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </CheckboxGroup>
                        </>))
                      }
                    </div>
                  </>)
              }
            </div>
          </div>
        </div>
      </PageHeader>

      <PageHeader name='' style={{ marginTop: 16 }}>
        <div className={styles['footer']}>
          <div className={styles['footerStyle']}>
            <div className={styles['buttonsFooter']}>
              <Button id={styles['btCancelar']} style={{ width: 145 }} onClick={cancelReceivingPlots} >Cancelar</Button>
              <Button id={styles[`${contSelecteds ? 'btConfirmar' : 'btConfirmarBlock'}`]} style={{ width: 145 }} onClick={submit} loading={props.isLoading}>Confirmar</Button>
            </div>
            <div className={styles['titleFooter']}>
              Selecione os arquivos para prosseguir
            </div>

          </div>
        </div>
      </PageHeader>

    </div>
  )
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.plotagem,
  userInfo: state.auth.userInfo,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPlotagemAReceber;

export default connector(PlotagemArquivos);