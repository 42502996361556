import { FC, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { Image, ImageProps } from "antd";
import { 
  LoadingOutlined, 
  MinusOutlined, 
  PlusOutlined, 
  SearchOutlined,
} from "@ant-design/icons";
import {
  ActionsCard,
  HeaderBar,
  NameCard,
  PreviewGroupStyled,
  ToolBar,
} from "./styles";

interface IImagePreview extends ImageProps {
  src: string;
  srcPreview?: string;
  alt: string;
  isUnique?: boolean;
  srcName?: string;
  HeaderButtons?: ReactNode;
  visible?: boolean;
  visibleOn?: boolean;
  onChangeVisible?: (visible: boolean) => void;
}

const ImagePreview: FC<IImagePreview> = ({
  src,
  srcPreview,
  alt,
  isUnique,
  srcName,
  HeaderButtons,
  visible,
  visibleOn,
  onChangeVisible,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  const [active, setActive] = useState(false);
  const [currLoad, setCurrLoad] = useState(true);

  const antIcon = <LoadingOutlined rev="" style={{ color: theme.colors.primary.primary, fontSize: 144 }} spin />;

  const handlePreviewOn = (value: boolean) => {
    if (onChangeVisible) {
      onChangeVisible(value);
    }
    
    setActive(value);
    dispatch(ActivitiesActions.attachmentPreviewOn(value));
  }

  const handleImageLoad = () => {
    if (currLoad) {
      setCurrLoad(false);
    }
  }

  if (isUnique) {
    return (
      <>
        <PreviewGroupStyled />
        <Image.PreviewGroup
          preview={{
            imageRender: (originalNode, info) => {
              if ((visible && currLoad) || (!visibleOn && active && currLoad)) {
                originalNode.props?.imgRef?.current?.addEventListener('load', handleImageLoad);
                originalNode.props?.imgRef?.current?.setAttribute('hidden', true);
              } else {
                originalNode.props?.imgRef?.current?.removeAttribute('hidden');
              }
  
              return (
                <>
                  {originalNode}
                  {currLoad && antIcon}
                </>
              );
            },
            visible: visibleOn ? visible : undefined,
            src: srcPreview,
            onVisibleChange(value, prevValue, current) {
              handlePreviewOn(value);
            },
            toolbarRender: (originalNode, info) => (
              <ToolBar>
                <MinusOutlined
                  rev=""
                  className="minusIcon"
                  onClick={() => info.actions.onZoomOut()}
                />
                <SearchOutlined rev="" className="searchIcon" />
                <PlusOutlined
                  rev=""
                  className="plusIcons"
                  onClick={() => info.actions.onZoomIn()}
                />
              </ToolBar>
            ),
            countRender: () => (
              <HeaderBar>
                <div>
                  {!!srcName && (
                    <NameCard>
                      <span className="nameCardText">{srcName}</span>
                    </NameCard>
                  )}
                </div>
                <div>
                  {!!HeaderButtons && (
                    <ActionsCard>
                      {HeaderButtons}
                    </ActionsCard>
                  )}
                </div>
              </HeaderBar>
            ),
            rootClassName: 'previewGroupCustomClass',
          }}
        >
          <Image
            src={src}
            alt={alt}
            preview={{
              mask: false
            }}
            {...props}
          />
        </Image.PreviewGroup>
      </>
    )
  }

  return (
    <Image
      src={src}
      alt={alt}
      preview={{
        mask: false
      }}
      {...props}
    />
  )
};

export default ImagePreview;
