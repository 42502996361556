import React, { FC } from 'react';
import { Modal as ModalAnt } from 'antd';
import { Icon } from '../Icon/index';
import { ModalStyled } from './styles';

export interface IModal {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  visible?: boolean;
  footerButtons?: React.ReactNode;
  width?: number | string;
  wrapClassName?: string;
  className?: string;
  customClassName?: boolean;
  onCancel?: () => void;
  mask?: boolean;
  footerActive?: boolean;
  zIndex?: number;
  style?: React.CSSProperties;
}

const Modal: FC<IModal> = ({ 
  title, 
  children, 
  visible, 
  footerButtons, 
  width, 
  wrapClassName, 
  className, 
  customClassName,
  onCancel, 
  mask=true, 
  footerActive=true, 
  zIndex,
  style,
}) => {
  return (
    <>
      <ModalStyled />
      <ModalAnt
        title={title}
        open={visible}
        footer={footerButtons}
        closeIcon={<Icon icon='error' size='lg' color='branco' />}
        centered
        width={width}
        wrapClassName={wrapClassName}
        className={`
          ${title === undefined ? "ant-modal-hide-header" : className}
          ${!footerActive ? "ant-modal-footer-border-none" : ""}
          ${customClassName ? "custom-class-name" : ""}
        `}
        onCancel={onCancel}
        mask={mask}
        zIndex={zIndex}
        style={style ? style : undefined}
      >
        {children}
      </ModalAnt>
    </>
  );
};

export default Modal;
