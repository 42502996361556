import axios, { AxiosResponse } from 'axios';
import { IGoogleApiResponse } from './interfaces/GoogleApi/IGoogleApiResponse';

export class GoogleApi {
  static async getUserData(tokenGoogle: string): Promise<AxiosResponse<IGoogleApiResponse>> {
    const GOOGLE_API_URL = 'https://www.googleapis.com/oauth2/v2/userinfo';
    const instance = await axios.create();
    return instance
      .get(`${GOOGLE_API_URL}?access_token=${tokenGoogle}`);
  }
}
