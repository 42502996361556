import React, { FC } from 'react';
import styles from './BoxDownResources.module.scss';
import { Progress } from 'antd';

interface IBoxDownResources {
  title?: string;
  value?: number;
  maxLimits?: number;
  resourseName?: string;
  dowgradebox?: boolean;
}

const BoxDownResources: FC<IBoxDownResources> = ({ 
  title, 
  value, 
  maxLimits, 
  resourseName, 
  children,
  dowgradebox
}) => {
  const percentOfConstructionsSites = (constructionsSites: number| undefined, constructionsSitesLimit: number) => {
    if(constructionsSites) {
      const value = (constructionsSites / constructionsSitesLimit) * 100;
      return Math.floor(value);
    }
  }

  return (
    <div className={styles['main-wrapper']}>
      <h4>{title}</h4>
      
      <div className={styles['divider']}/>

      <span className={styles['div-text-resource']}>
        {'Selecione '}
        <b>{maxLimits}</b>
        {` ${resourseName} para arquivar. Você selecionou `}
        <b>{value}</b>
        {` ${resourseName}`}
      </span>

      <Progress
        percent={maxLimits
          ? percentOfConstructionsSites(value, maxLimits)
          : 100
        }
        strokeWidth={12} 
        strokeColor={(value && maxLimits) && value >= maxLimits ? "#09B66D" : "#3547C8"} 
        showInfo={false}
        className={styles['progress-bar']} 
      />

      <div>
        {children}
      </div>
    </div>
  )
}

export default BoxDownResources;