import { useEffect, useState } from "react";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [debouncedLoading, setDebouncedLoading] = useState(false);
  
  useEffect(
    () => {
      setDebouncedLoading(true);

      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setDebouncedLoading(false);
      }, delay);

      return () => {
        clearTimeout(handler);
        setDebouncedLoading(false);
      };
    },
    [value, delay]
  );

  return { debouncedValue, debouncedLoading };
}

export default useDebounce;
