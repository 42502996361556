import React, { FC } from 'react';

import styles from './ArmazenamentoTab.module.scss';
import ListContainer from '../../../../../Components/UI/Containers/ListContainer/ListContainer';
import { Icon } from '../../../../../Components/UI';
import { IAccountInfo } from '../../../../../Data/interfaces/Obra/IAccountInfo';
import { getIconApi, ApisEnumLabel } from '../../../../../Data/enums/Apis';
import iconOneDrive from '../../../../../Static/images/icon-onedrive.svg';

const Apis = [2, 3, 5];

interface IArmazenamentoTab {
  isSubmited?: boolean;
  onSubmit: (values?: any, errors?: string[]) => void;
  accountsInfo?: IAccountInfo[];
  accountIdSelected?: number;
}

const ArmazenamentoTab: FC<IArmazenamentoTab> = (props) => {
  const [selectedAccountRow, setSelectedAccountRow] = React.useState<number | undefined>();
  const [selectedServiceRow, setSelectedServiceRow] = React.useState<number>();
  const [keySelected, setKeySelected] = React.useState<number | undefined>(props.accountsInfo?.findIndex(x => x.ConstructionSiteApiTokenId == (props.accountIdSelected)));

  const selectAccountRow = (key: number, accountId: number) => {
    setSelectedServiceRow(undefined);
    setSelectedAccountRow(key);
  };

  const selectServiceRow = (key: number) => {
    setSelectedAccountRow(undefined);
    setSelectedServiceRow(key);
  };

  setTimeout(() => {

    if (keySelected != undefined) {
      setSelectedAccountRow(keySelected);
      setKeySelected(undefined);
    }
  }, 100);

  if (props.isSubmited) {
    if (selectedServiceRow !== undefined) {
      const values = {
        Api: Apis[selectedServiceRow],
      };
      props.onSubmit(values);
    } else if (selectedAccountRow !== undefined && props.accountsInfo && selectedAccountRow > -1) {
      const values = {
        Api: props.accountsInfo[selectedAccountRow].Api,
        ExistingConstructionSiteTokenId: props.accountsInfo[selectedAccountRow].ConstructionSiteApiTokenId,
      };
      props.onSubmit(values);
    } else {
      props.onSubmit(undefined, ['Um serviço de nuvem deve ser selecionado.']);
    }
  }

  return (
    <div className={styles['container']} >
      <div className={styles['titleStorage']} >
        Selecione o serviço de armazenamento em nuvem de sua preferência para salvar os arquivos da sua obra.
      </div>
      {
        props.accountsInfo?.length
          ? (<div>
            <div className={styles['titleServiceList']}>Contas conectadas</div>
            {
              props.accountsInfo?.map((account, key) => (
                <ListContainer
                  key={`service-${key}`}
                  height={51}
                  className={`${styles['serviceContainer']} ${key === selectedAccountRow ? styles['active'] : ''}`}
                  onClick={() => selectAccountRow(key, account.ConstructionSiteApiTokenId)}>
                  {
                    key === selectedAccountRow
                      ? (<Icon icon='confirmacao'
                        customSize={15}
                        customColor='#0081FF'
                        className={styles['selectedIcon']} />)
                      : <span className={styles['unselectedIcon']} ></span>
                  }
                  <Icon icon={getIconApi(account.Api)} customSize={20} customColor={`${key === selectedAccountRow ? '#3547C8' : '#B3C0CE'}`} />
                  <span className={styles['titleService']}>{ApisEnumLabel[account.Api]}</span>
                  <span className={styles['emailService']}>{account.ApiEmail}</span>
                </ListContainer>
              ))
            }
          </div>)
          : ''
      }

      <div>
        <div className={styles['titleServiceList']}>Conectar nova conta</div>
        {
          Apis.map((api, key) => (
            <ListContainer
              key={`service-${key}`}
              height={51}
              className={`${styles['serviceContainer']} ${key === selectedServiceRow ? styles['active'] : ''}`}
              onClick={() => selectServiceRow(key)}>
              {
                key === selectedServiceRow
                  ? (<Icon icon='confirmacao'
                    customSize={15}
                    customColor='#0081FF'
                    className={styles['selectedIcon']} />)
                  : <span className={styles['unselectedIcon']} ></span>
              }
              <Icon icon={getIconApi(api)} customSize={20} customColor={`${key === selectedServiceRow ? '#3547C8' : '#B3C0CE'}`} />
              <span className={styles['titleService']}>{ApisEnumLabel[api]}</span>
            </ListContainer>
          ))
        }
        {/* <ListContainer
          key={`service-5`}
          height={51}
          className={`${styles['serviceContainer']} ${styles['disabled']}`}>
          <span className={styles['unselectedIcon']} ></span>
          <img src={iconOneDrive} width={24}></img>
          <span className={styles['titleService']}>OneDrive</span>
          <p>Em breve</p>
        </ListContainer> */}
      </div>
    </div>
  );
};

export default ArmazenamentoTab;
