import React, { FC, useState } from "react";
import { Popover } from "antd";
import styles from '../Models.module.scss';
import { Icon } from "Components/UI";
import { IFileData } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import AccessPermission from "Utils/AcessPermission";

interface IOtherActionsFile {
  file: IFileData;
  onUnbindFile: (file: IFileData) => void;
  onReRenderFileError: (file: IFileData) => void;
}

const OtherActionsFile: FC<IOtherActionsFile> = ({
  file,
  onUnbindFile,
  onReRenderFileError,
}) => {
  const [visible, setVisible] = useState<boolean>();
  
  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleFileForUnbind = () => {
    onUnbindFile(file);
    handleVisibleChange(false);
  }

  const handleFileForReRenderFileError = () => {
    onReRenderFileError(file);
    handleVisibleChange(false);
  }

  const overlay = (
    <div className={`
      ${styles['MenuDropdown']} 
      ${(!AccessPermission.isAdmin() && !AccessPermission.isCoordenador()) || file.FileInfo.BucketFile?.Status === 1 ? styles['MenuDropdownHeight'] : ''}
    `}>
      {(AccessPermission.isAdmin() || AccessPermission.isCoordenador()) && ( 
        <div 
          className={styles['menuItem']}
          onClick={() => handleFileForUnbind()}
        >
          <Icon 
            icon="unbind" 
            customSize={18} 
            className={styles['unbind']} 
            customColor="#BEC2C6" 
          />
          Desvincular modelo do viewer
        </div>
      )}
      {file.FileInfo.BucketFile?.Status !== 1 && (
        <div 
          className={styles['menuItem']}
          onClick={() => handleFileForReRenderFileError()}
        >
          <Icon 
            icon="atualizar" 
            customSize={16} 
            className={styles['unbind']} 
            customColor="#BEC2C6" 
          />
          Tentar renderizar novamente
        </div>
      )}
    </div>
  );
  return (
    <Popover 
      content={overlay} 
      placement="bottomRight"
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      <Icon 
        icon="moreActions" 
        customSize={18} 
        className={styles['actions']} 
        customColor="#BEC2C6"
        onClick={() => handleVisibleChange(!visible)}
      />
    </Popover>
  )
}

export default OtherActionsFile;