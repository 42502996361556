import styled, { createGlobalStyle } from "styled-components";

export const PreviewGroupStyled = createGlobalStyle`
  .previewGroupCustomClass {
    
    .ant-image-preview-switch-left {
      width: 44px;
      height: 44px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.50);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;

      span {
        font-size: 22px;
      }
    }

    .ant-image-preview-switch-right {
      width: 44px;
      height: 44px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.50);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;

      span {
        font-size: 22px;
      }
    }

    .ant-image-preview-close {
      top: 25px;
      left: 30px;
      right:  none;
      width: 44px;
      height: 44px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.50);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;

      span {
        font-size: 22px;
      }
    }
  }

  @media print {
    .previewGroupCustomClass {
      
      .ant-image-preview-switch-left {
        display: none;
      }

      .ant-image-preview-switch-right {
        display: none;
      }

      .ant-image-preview-close {
        display: none;
      }

      .ant-image-preview-footer {
        display: none;
      }
    }
  }
`;

export const ToolBar = styled.div`
  width: 140px;
  height: 44px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  justify-content: space-around;

  .minusIcon {
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .searchIcon {
    font-size: 22px;
    color: #FFFFFF;
  }

  .plusIcons {
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
  }
`;

export const CloseIcon = styled.div`
  position: fixed;
  top: 25px;
  left: 30px;
  width: 44px;
  height: 44px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  justify-content: center;

  .closeIcon {
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
  }
`;

export const HeaderBar = styled.div`
  position: fixed;
  top: 25px;
  left: 86px;
  right: 30px;
  height: 44px;
  display: flex;
  justify-content: space-between;
`;

export const NameCard = styled.div`
  width: auto;
  height: 44px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  padding: 0 12px;

  .nameCardText {
    color: #FFFFFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ActionsCard = styled.div`
  position: fixed;
  top: 25px;
  right: 30px;
  width: auto;
  height: 44px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  padding: 0 4px;
`;
