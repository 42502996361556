import { FC } from "react";
import SkeletonMain from "../LoadingMain";
import { Container } from "./styles";

export interface ILoadingStages {
  multiple?: number;
}

const LoadingStages: FC<ILoadingStages> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (
        <Container key={index.toString()}>
          <div className="column1">
            <SkeletonMain width="12px" height="12px" borderRadius="2px" />
          </div>
          <div className="column2">
            <SkeletonMain width="25px" height="12px" borderRadius="2px" />
          </div>
          <div className="column3">
            <SkeletonMain width="157px" height="12px" borderRadius="2px" />
          </div>
          <div className="column4">
            <SkeletonMain width="14px" height="14px" borderRadius="2px" />
            <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="8px" />
            <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="8px" />
            <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="8px" />
            <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="8px" />
          </div>
          <div className="column5">
            <SkeletonMain width="32px" height="32px" borderRadius="5px" />
            <SkeletonMain width="32px" height="32px" borderRadius="5px" marginLeft="6px" />
          </div>
        </Container>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <div style={{ marginTop: '6px' }}>
      {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
    </div>
  )
}

export default LoadingStages;