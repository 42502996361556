import { FC, ReactNode, useCallback } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { INomenclatureFieldRow } from "../..";

interface IDnDContext {
  nomenclatureFields: INomenclatureFieldRow[];
  dragOverlay: ReactNode;
  onActiveId: (id: UniqueIdentifier | null) => void;
  onNomenclatureFields: (data: INomenclatureFieldRow[]) => void;
}

export const DnDContext: FC<IDnDContext> = ({
  children,
  nomenclatureFields,
  dragOverlay,
  onActiveId,
  onNomenclatureFields,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;

    return onActiveId(active.id);
  }, [onActiveId]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = nomenclatureFields.findIndex(field => field.id === active.id);
      const overIndex = nomenclatureFields.findIndex(field => field.id === over.id);

      const newFields = arrayMove(nomenclatureFields, activeIndex, overIndex);

      onNomenclatureFields(newFields);
    }

    return onActiveId(null);
  }, [nomenclatureFields, onActiveId, onNomenclatureFields]);

  const handleDragCancel = useCallback(() => {
    return onActiveId(null);
  }, [onActiveId]);

  return (
    <DndContext
      key="DnDContextKey"
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={(event) => handleDragStart(event)}
      onDragEnd={(event) => handleDragEnd(event)}
      onDragCancel={() => handleDragCancel()}
    >
      <SortableContext items={nomenclatureFields}>
        {children}
      </SortableContext>
      {dragOverlay}
    </DndContext>
  );

}

export default DnDContext;
