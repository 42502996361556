import { FC } from 'react';
import { Icon } from '..';
import Button from '../Button';
import { Tag } from 'antd';
import { ApisEnumLabel } from 'Data/enums/Apis';
import { Modal } from './styles';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';

type ModalBloquedSADownloadProps = {
  platform: number;
  onCancel: () => void;
}

const ModalBloquedSADownload: FC<ModalBloquedSADownloadProps> = ({ 
  platform, 
  onCancel, 
}) => {
  const blockedSABlogUrlFlag = useFeatureFlag('blocked-SA-blog-url');
  const blockedSABlogUrl = (blockedSABlogUrlFlag.enabled && typeof blockedSABlogUrlFlag.value === 'string') 
    ? blockedSABlogUrlFlag.value 
    : 'https://www.maletadoengenheiro.com.br/blog';

  return (
    <Modal>
      <div className="Wrapper">
        <div className="content">
          <div className="iconBox">
            <Tag color="#E2574C" className="tag">
              <Icon icon="aviso" color="branco" customSize={12} />
              erro de conexão
            </Tag>
            
            <Icon 
              icon='cancelar' 
              customSize={12} 
              color='cinzaOpaco' 
              onClick={onCancel}
            />
          </div>

          <h4 className="title">
            {`Ocorreu um erro ao tentar conectar com o link público da obra no ${ApisEnumLabel[platform]}.`}
          </h4>
          <p className="description">
            {`Não conseguimos conectar com o link público da obra ${ApisEnumLabel[platform]}, e por isso, você não consegue abrir essa pasta. Para resolver esse problema é necessário entrar em contato com o administrador da conta.`}
          </p>
        </div>

        <div className="Footer">
          <div className="link">
            <a
              href={blockedSABlogUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Saiba mais
            </a>
          </div>
          <Button 
            type="primary"
            onClick={onCancel}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalBloquedSADownload;
