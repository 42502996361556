import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px 12px 12px;

  .imgPremium {
    width: 72px;
    height: 72px;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 24px;

    .textTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }
  
    .textDescription {
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      margin-top: 4px;
    }
  
    .btnPremium {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 178px;
      height: 40px;
      margin-top: 12px;
  
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
  
      .iconCrow {
        margin-right: 6px;
        color: ${({ theme }) => theme.colors.surface.onSurface} !important;
      }
    }
  }

  .iconCancel {
    position: absolute;
    top: -6px;
    right: -6px;
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
    cursor: pointer;
  }
`;
