import { FC } from "react";
import emptySearch from "Static/images/empty-search.png";
import { Container } from "./styles";

const MenuMainEmpty: FC = () => {
  return (
    <Container>
      <img
        src={emptySearch}
        className="imgEmpty"
        alt=""
      />
      <span className="title">
        Nenhum painel encontrado.
      </span>
      <span className="description">
        O painel pesquisado não foi encontrado ou não existe. Verifique sua pesquisa e tente novamente.
      </span>
    </Container>
  )
}

export default MenuMainEmpty;
