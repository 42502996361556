import { FC, useEffect, useMemo } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { history } from 'Store';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import { Icon, IconName } from 'Components/UI';
import Details from './components/Details';
import Disciplines from './components/Disciplines';
import Users from './components/Users';
import Permissions from './components/Permissions';
import Nomenclature from './components/Nomenclature';
import Stages from './components/Stages';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { Container, Content, DrawerItem, DrawerLocal } from './styles';

const breadCrumbs: IBreadcrumbItem[] = [];

type ActiveDrawerType =
  'details' |
  'disciplines' |
  'users' |
  'permissions' |
  'phases' |
  'nomenclature' |
  // 'storage' |
  'integrations';

interface ActiveDrawerItem {
  id: ActiveDrawerType;
  label: string;
  icon: IconName;
  badge?: string;
  disabled?: boolean;
  size?: number;
};

interface IPanels {
  match: any;
}

const EditObra: FC<Props> = ({
  match,
  isLoading,
  editLoading,
  isListStagesLoading,
  isListLibrariesLoading,
  Obra,
  userInfo,
  Disciplines: disciplines,
  tenantRoles,
  tenantSharedUsers,
  userLimitsActive,
  usersForLimitInvited,
  ObrasList,
  error,
  stages,
  libraries,
  tenantAllUsers,
}) => {
  const dispatch = useDispatch();

  const stagesFeatureFlag = useFeatureFlag('stages-feature');
  const stagesFeature = stagesFeatureFlag.enabled && stagesFeatureFlag.value === 'test';

  const csId = Number(match.params.constructionSiteId);
  const editId: ActiveDrawerType = match.params.editId;

  const drawerTypes = useMemo(() => {
    const data: Record<ActiveDrawerType, ActiveDrawerItem> = {
      details: {
        id: 'details',
        label: 'Detalhes',
        icon: 'collapse',
        size: 8,
      },
      disciplines: {
        id: 'disciplines',
        label: 'Disciplinas',
        icon: 'menuContexto',
        size: 10,
      },
      users: {
        id: 'users',
        label: 'Usuários',
        icon: 'team',
      },
      permissions: {
        id: 'permissions',
        label: 'Permissões',
        icon: 'key',
      },
      nomenclature: {
        id: 'nomenclature',
        label: 'Nomenclatura',
        icon: 'input',
      },
      phases: {
        id: 'phases',
        label: 'Etapas',
        icon: 'phases',
        badge: 'Em Breve',
        disabled: !stagesFeature,
      },
      integrations: {
        id: 'integrations',
        label: 'Integrações',
        icon: 'bolt',
        badge: 'Em breve',
        disabled: true,
      },
      // storage: {
      //   id: 'storage',
      //   label: 'Armazenamento',
      //   icon: 'storage',
      //   disabled: true,
      // },
    };
    return data;
  }, [stagesFeature]);

  const currentEditId = drawerTypes[editId];

  const toggleActiveDrawer = (title: ActiveDrawerType) => {
    history.push(`/obras/edit/${csId}/${title}`);
  };

  useEffect(() => {
    if (!Obra || JSON.stringify(Obra) === '{}') {
      dispatch(ObraActions.getObra({ csId }));
      dispatch(ObraActions.getUsersConstructionSite(csId));
      dispatch(TenantActions.getUsers());
      dispatch(TenantActions.getTenantListUsersConstruction());
    }
  }, [])

  if (Obra) {
    breadCrumbs[0] = {
      href: `/obras/detail/${csId}`,
      description: Obra.Name,
    };
    breadCrumbs[1] = {
      href: `/obras/edit/${csId}/details`,
      description: 'Editar obra',
    };
    breadCrumbs[2] = {
      href: `/obras/edit/${csId}/${currentEditId.id}`,
      description: currentEditId.label,
    };
  };

  return (
    <>
      <TabsShell
        ConstructionSiteId={csId}
        active={PathActiveEnum.edit}
        loading={editLoading}
        match={match}
      />
      <PageHeader name="Editar obra" headerMarginLeft={16}>
        <BreadCrumb breadcrumbs={breadCrumbs} custom />
        <Container>
          <div>
            <DrawerLocal>
              {Object.values(drawerTypes).map(item => (
                <DrawerItem
                  key={item.id}
                  active={editId === item.id}
                  disabled={item.disabled}
                  onClick={() => !item.disabled
                    ? toggleActiveDrawer(item.id)
                    : undefined
                  }
                >
                  <Icon
                    icon={item.icon}
                    className="iconDrawer"
                    customSize={item.size || 16}
                  />
                  <span className="textDrawer">{item.label}</span>
                  {!!item.badge && (
                    <span className="badgeDrawer">{item.badge}</span>
                  )}
                </DrawerItem>
              ))}
            </DrawerLocal>
          </div>

          <Content>
            {editId === 'details' && (
              <Details
                {...Obra}
                csId={csId}
                loading={isLoading}
              />
            )}
            {editId === 'disciplines' && (
              <Disciplines
                csId={csId}
                loading={isLoading}
                disciplines={Obra.Disciplines}
                disciplinesData={disciplines}
              />
            )}
            {editId === 'users' && (
              <Users
                csId={csId}
                loading={isLoading}
                tenantRoles={tenantRoles}
                tenantSharedUsers={tenantSharedUsers}
                invitedUsers={Obra.InvitedUsers}
                sharedUsers={Obra.SharedUsers}
                currentRole={userInfo?.CurrentRoleFk}
                firstInvitedUsers={usersForLimitInvited}
                numberInvitedAllUsers={userLimitsActive?.QtyActiveUsers}
                userInfo={userInfo}
                tenantAllUsers={tenantAllUsers}
              />
            )}
            {editId === 'permissions' && (
              <Permissions
                csId={csId}
                loading={isLoading}
                invitedUsers={Obra.InvitedUsers}
                sharedUsers={Obra.SharedUsers}
                error={error}
              />
            )}
            {editId === 'phases' && (
              <Stages
                csId={csId}
                stages={stages}
                libraries={libraries}
                loading={isLoading}
                listStagesLoading={isListStagesLoading}
                listLibrariesLoading={isListLibrariesLoading}
                disciplines={Obra.Disciplines}
                versionConfigFk={Obra?.VersionConfigFk}
                obraVersionData={Obra?.VersionConfig}
                ValidateStagesInNomenclature={Obra.ValidateStagesInNomenclature}
                AllowOnlyStagesLinkedToDisciplinesInUpload={Obra.AllowOnlyStagesLinkedToDisciplinesInUpload}
                AllowOnlyStagesLinkedToDisciplinesInTopics={Obra.AllowOnlyStagesLinkedToDisciplinesInTopics}
              />
            )}
            {editId === 'nomenclature' && (
              <Nomenclature
                csId={csId}
                loading={isLoading}
                obraVersionData={Obra?.VersionConfig}
                versionConfigFk={Obra?.VersionConfigFk}
                hasValidateStagesInNomenclature={Obra.ValidateStagesInNomenclature}
              />
            )}
          </Content>
        </Container>
      </PageHeader>
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  userInfo: state.auth.userInfo,
  globalLoading: state.activities.externalGlobalLoading,
  currentTenant: state.tenant.currentListTenant,
  tenantRoles: state.tenant?.TenantRoles || [],
  tenantSharedUsers: state.tenant?.Users || [],
  userLimitsActive: state.auth.userLimitsActive,
  isLoading: state.obra.newEditLoading,
  editLoading: state.obra.isLoading,
  isListStagesLoading: state.obra.listStagesLoading,
  isListLibrariesLoading: state.obra.listLibrariesLoading,
  tenantAllUsers: state.tenant.UsersWithConstruction,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPanels;

export default connector(EditObra);
