import React, { FC } from "react";

import styles from "./LoadingArchives.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingActivitiesList {
  multiple?: number;
  isCollapsed?: boolean;
  height: number;
}

const LoadingActivitiesList: FC<ILoadingActivitiesList> = ({ 
  multiple = 1,
  isCollapsed,
  height,
}) => {
  const skeletonObra = {
    render: (index: number) => {
      return (   
        <tr key={index.toString()} className={styles['rowTable']}>
          {/* <td className={styles['tdCheckLoad']}>
            <span className={styles['rowCheckLoad']}>
              <SkeletonMain width="16px" height="16px" borderRadius="4px" />
            </span>
          </td> */}
          <td style={{ width: '60px', paddingLeft: '8px' }}>
            <SkeletonMain width="44px" height="12px" borderRadius="2px" minWidth="16px"/>
          </td>
          <td style={{ width: '34px', paddingLeft: '8px' }}>
            <SkeletonMain width="12px" height="12px" borderRadius="2px" minWidth="12px" />
          </td>
          <td  style={{ paddingLeft: '8px' }}>
            <span className={styles['rowName']}>
              <SkeletonMain width="100%" height="12px" borderRadius="2px" />
              {isOdd(index) && (
                <SkeletonMain width="62px" height="37px" borderRadius="2px" marginLeft="12px" />
              )}
            </span>
          </td>
          <td  style={{ width: '100px', paddingLeft: '8px' }}>
            <span className={styles['rowName']}>
              <SkeletonMain width="10px" height="10px" borderRadius="2px" minWidth="10px" />
              <SkeletonMain width="57px" height="12px" borderRadius="2px" marginLeft="6px" />
            </span>
          </td>
          <td  style={{ width: '100px', paddingLeft: '8px' }}>
            <span className={styles['rowName']}>
              <SkeletonMain width="12px" height="12px" borderRadius="2px" minWidth="12px" />
              <SkeletonMain width="35px" height="12px" borderRadius="2px" marginLeft="7px" />
            </span>
          </td>
          <td  style={{ width: '195px', paddingLeft: '8px' }}>
            <span className={styles['rowNameCustomContainer']}>
              <span className={styles['rowNameCustom']}>
                <SkeletonMain width="12px" height="12px" borderRadius="2px" minWidth="12px" />
                <SkeletonMain width="60px" height="12px" borderRadius="2px" marginLeft="7px" />
              </span>
              <span className={styles['rowNameCustom']}>
                <SkeletonMain width="12px" height="12px" borderRadius="2px" minWidth="12px" />
                <SkeletonMain width="60px" height="12px" borderRadius="2px" marginLeft="7px" />
              </span>
            </span>
          </td>
          <td style={{ width: '125px', paddingRight: '8px', paddingLeft: '8px' }}>
            <div className={styles['rowCircles']}>
              <SkeletonMain 
                width="24px" 
                height="24px" 
                border="1px solid #FFFFFF"
                borderRadius="50%"
                zIndex={3}
                isCustom="skeleton-custom-dark2"
              />
              <SkeletonMain 
                width="24px" 
                height="24px" 
                border="1px solid #FFFFFF"
                borderRadius="50%"
                marginLeft="-6px"
                zIndex={2}
                isCustom="skeleton-custom-dark2"
              />
              <SkeletonMain 
                width="24px" 
                height="24px" 
                border="1px solid #FFFFFF"
                borderRadius="50%"
                marginLeft="-6px"
                zIndex={1}
                isCustom="skeleton-custom-dark2"
              />
            </div>
          </td>
          <td style={{ width: '100px', paddingRight: '8px', paddingLeft: '8px' }}>
            <SkeletonMain width="84px" height="12px" borderRadius="2px" />
          </td>
          <td style={{ width: '125px', paddingRight: '8px', paddingLeft: '8px' }}>
            <SkeletonMain width="109px" height="24px" borderRadius="2px" />
          </td>
        </tr>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  const isOdd = (value: number) => {
    return value % 2 === 0;
  }

  return (
    <>
      <table className={styles['renderTable']}>
        <thead className={styles['headTable']}>
          <tr>
            {/* <th className={styles['rowCheck']}>
              <span>
                <input className={styles['checkbox']} type="checkbox" disabled />
              </span>
            </th> */}
            <th style={{ width: '60px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Cód."
                showOrder={false}
              />
            </th>
            <th style={{ width: '34px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="V."
                showOrder={false}
              />
            </th>
            <th style={{ paddingLeft: '8px' }}>
              <ColumnHeader
                title="Resumo"
              />
            </th>
            <th style={{ width: '100px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Tipo"
              />
            </th>
            <th  style={{ width: '100px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Priorid."
              />
            </th>
            <th  style={{ width: '195px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Disciplinas"
              />
            </th>
            <th  style={{ width: '125px', paddingRight: '8px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Responsáveis"
              />
            </th>
            <th  style={{ width: '100px', paddingRight: '8px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Prazo"
              />
            </th>
            <th  style={{ width: '125px', paddingRight: '8px', paddingLeft: '8px' }}>
              <ColumnHeader
                title="Status"
              />
            </th>
          </tr>
        </thead>
        
        <tbody className={styles['rowTable']}>
          {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
        </tbody>
      </table>
    </>
  )
}

export default LoadingActivitiesList;