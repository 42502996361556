import React, { FC } from "react";

import styles from "./LoadingArchives.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingArchivesPlotagens {
  multiple?: number;
}

const LoadingArchivesPlotagens: FC<ILoadingArchivesPlotagens> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (   
        <tr key={index.toString()} className={styles['rowTable']}>
          <td className={styles['tdCheckLoad']}>
            <span className={styles['rowCheckLoad']}>
              <SkeletonMain width="16px" height="16px" borderRadius="4px" />
            </span>
          </td>
          <td>
            <span className={styles['rowName']}>
              <SkeletonMain width="16px" height="16px" borderRadius="4px" marginLeft="12px" minWidth="16px"/>
              <SkeletonMain width="100%" height="12px" borderRadius="2px" marginLeft="12px" />
            </span>
          </td>
          <td>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '250px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '250px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '180px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
        </tr>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      <table className={styles['renderTable']}>
        <thead className={styles['headTable']}>
          <tr>
            <th className={styles['rowCheck']}>
              <span>
                <input className={styles['checkbox']} type="checkbox" disabled />
              </span>
            </th>
            <th style={{ width: '250px' }}>
              <ColumnHeader
                title="Solicitação"
                showFilter={() => {}}
              />
            </th>
            <th>
              <ColumnHeader
                title="Arquivo"
                showFilter={() => {}}
              />
            </th>
            <th style={{ width: '250px' }}>
              <ColumnHeader
                title="Versão"
                showFilter={() => {}}
              />
            </th>
            <th  style={{ width: '250px' }}>
              <ColumnHeader
                title="Solicitado"
                showFilter={() => {}}
              />
            </th>
            <th  style={{ width: '180px' }}>
              <ColumnHeader
                title="Status"
                showFilter={() => {}}
              />
            </th>
          </tr>
        </thead>
        
        <tbody className={styles['rowTable']}>
          {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
        </tbody>
      </table>
    </>
  )
}

export default LoadingArchivesPlotagens;