import { FC, CSSProperties, FormEvent, useState } from 'react';
import { Field as FieldReact } from 'react-final-form';
import { valueType } from 'antd/lib/statistic/utils';
import { Icon, IconName } from 'Components/UI/Icon';
import styles from './NewSimpleTextArea.module.scss';
import { TypePathUpdateData, TypeValueUpdateData } from 'Data/interfaces/Activities/IUpdateActivityRequest';
import Linkify from 'linkify-react';

export interface INewSimpleTextArea {
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  icon?: IconName;
  type?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  onInput?: (value: string) => void;
  onChange?: () => void;
  value?: string;
  defaultValue?: string | number | undefined;
  parse?: (value: any, name: string) => valueType;
  maxLenght?: number;
  typeInput?: string;
  outError?: string;
  isUpdate?: boolean;
  path?: TypePathUpdateData;
  onDynamicUpdateSubmit?: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  havePermission?: boolean;
}

const hasError = (meta: any, disabled?: boolean) =>
  !disabled && meta.invalid && meta.touched;

const NewSimpleTextArea: FC<INewSimpleTextArea> = ({
  name,
  defaultValue,
  icon,
  type = 'text',
  placeholder,
  onFocus,
  onBlur,
  onInput,
  disabled,
  value,
  onChange,
  parse,
  maxLenght,
  typeInput,
  outError,
  isUpdate,
  path,
  onDynamicUpdateSubmit,
  havePermission,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [openString, setOpenString] = useState(false);

  const maxStringLenght = 136;

  const autoGrow = (element: FormEvent<HTMLTextAreaElement>) => {
    element.currentTarget.style.height = "5px";
    element.currentTarget.style.height = (element.currentTarget.scrollHeight)+"px";
  }

  const handleOpenString = (value: boolean) => {
    setOpenString(value);
  }

  const formatString = (value: string) => {
    if (!openString && (maxStringLenght < value.length)) {
      return `${value.slice(0, maxStringLenght - 3)}...`
    }
    return value;
  }

  const options: any = {
    render: ({ attributes, content }: { attributes: any, content: any }) => {
      const { href, ...props } = attributes;
      return (
        <a 
          href={href} 
          target="_blank"
          rel="noopener noreferrer" 
          {...props}
        >
          {content}
        </a>
      );
    },
  }

  return (
    <FieldReact
      name={name}
      type={type}
      defaultValue={defaultValue}
      initialValue={value}
      parse={parse}
      typeInput={typeInput}
    >
      {(props) => (
        isActive ? (
          <>
            {icon ? <Icon icon={icon} color="cinzaIcone" /> : ''}
            <textarea
              {...props.input}
              disabled={disabled}
              maxLength={maxLenght}
              placeholder={placeholder}
              className={`
                ${styles['defaultInput']}
                ${icon ? styles['paddingIcon'] : ''}
                  ${
                    hasError(props.meta, disabled) && !props.meta.active
                      ? styles['invalid']
                      : ''
                  } ${disabled ? styles['disabledInput'] : ''}`}
              onInput={(event) => autoGrow(event)}
              onBlur={(e) => {
                if (isUpdate && path && onDynamicUpdateSubmit) {
                  onDynamicUpdateSubmit(path, props.input.value as string);
                }
                setIsActive(false);
              }}
              onFocus={(event) => autoGrow(event)}
              autoFocus
              onChangeCapture={onChange}
            />
            {(hasError(props.meta, disabled) || outError) && (
              <div className={styles['errorInput']}>
                {hasError(props.meta, disabled) ? props.meta.error : ''}
                {!hasError(props.meta, disabled) && outError ? outError : ''}
              </div>
            )}
            {props.meta.active ? onFocus && onFocus() : onBlur && onBlur()}
          </>
        ) : (
          <div className={styles['noActiveContainer']}>
            <div 
              className={`
                ${styles['noActiveWrapper']}
                ${openString ? styles['noActiveWrapperOpen'] : ''}
                ${isUpdate ? styles['noActiveWrapperVisualization'] : ''}
                ${isUpdate ? styles['noActiveWrapperVisualizationShort'] : ''}
                ${!havePermission ? styles['nPCursor'] : ''}
              `}
              onClick={() => havePermission ? setIsActive(true) : null}  
            >
              <span className={`
                ${styles['noActiveText']}
                ${!props.input.value.toString() ? styles['noActiveTextPlaceholder'] : ''}
              `}>
                <Linkify options={options}>
                  {formatString(props.input.value.toString() || placeholder || '')}
                </Linkify>
              </span>
            </div>
            {(isUpdate && (props.input.value.toString().length > maxStringLenght || (placeholder && placeholder.length > maxStringLenght))) && (
              <div
                className={styles['noActiveBtn']}
                onClick={() => handleOpenString(!openString)}
              >
                {openString ? 'Ver menos' : 'Ver mais'}
              </div>
            )}
          </div>
        )
      )}
    </FieldReact>
  );
};

export default NewSimpleTextArea;
