import React, { FC, useState } from 'react';
import { ObjectSchema } from 'yup';
import styles from './FloatingFeedback.module.scss';
import FloatingFeedbackItem from './FloatingFeedbackItem/FloatingFeedbackItem';

export interface IFloatingFeedback {
  confirmPassword?: boolean;
  inputValue?: string;
  name: string;
  schema: ObjectSchema<any>;
  className?: string;
  visible?: boolean;
  context?: object;
}

interface IError {
  description: string;
  hasError: boolean;
}

const FloatingFeedback: FC<IFloatingFeedback> = ({ schema, inputValue, name, className, visible, context }) => {
  const [allErrors, setAllErrors] = useState<IError[]>();
  const tmp: any = {};
  tmp[name] = inputValue || '';
  const allErrorsOld = JSON.stringify(allErrors);
  schema.validate(tmp, { abortEarly: false, context }).then((value) => {
    allErrors?.forEach((x: IError) => x.hasError = false);
  }).catch((err) => {
    if (allErrors) {
      allErrors.forEach((error: IError) => {
        error.hasError = err.errors.some((y: string) => y === error.description);
      });
    } else {
      setAllErrors(err.errors.map((x: string) => {
        return {
          description: x,
          hasError: true,
        };
      }));
    }
  }).finally(() => {
    if (allErrorsOld !== JSON.stringify(allErrors)) {
      setAllErrors([...(allErrors ?? [])]);
    }
  });

  return visible ?
    (<>
      <section className={`${styles['FloatingWrapper']} ${className || ''}`}>
        {allErrors?.map((x: IError, key) =>
          <FloatingFeedbackItem
            description={x.description}
            hasError={x.hasError} key={`error-${key}`}
          />,
        )}
      </section>
    </>) : (<></>);

};

export default FloatingFeedback;
