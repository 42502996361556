import { FC, useEffect } from 'react';
import Button from '../../UI/Button';
import { Icon } from '../../UI/Icon';
import styles from './TopBar.module.scss';
import Profile from './Profile/Profile';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import UpgradePremium from './Modals/UpgradePremium';
import AvisoUpgradeConfirmacao from './Modals/AvisoUpgradeConfirmacao';
import { MinhaContaActions } from '../../../Store/MinhaConta/MinhaConta.actions';
import Notification from './Notification/Notification';

import { NotificationActions } from 'Store/Notification/Notification.actions';
import { getUnreadNotifications } from 'Utils/Sort';
import { history } from 'Store';
import CompletarCadastro from './CompletarCadastro/CompletarCadastro';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

export interface ITopBar {
  onCollapseClick: () => void;
  isCollapsed: boolean;
}

const TopBar: FC<Props> = ({
  showModalUpdatePremium,
  showModalConfirmUpdatePremium,
  registerCompleted,
  onCollapseClick,
  isCollapsed,
  isLoading,
  valueNotification,
  newNotifications,
  planFree,
  userInfo: userInforGet,
  userLoading,
  updateEmpresaLoading,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  useEffect(() => {
    dispatch(NotificationActions.listNewNotification());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openUpgradePremium = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION', 
      props: {
        origin: 'top-bar-upgrade-button',
        originPath: window.location.pathname
      },
      userInfo: userInforGet,
      currentListTenant: currentTenant,
    });

    if (userInforGet?.CurrentRoleFk === 1) {
      history.push('/faturamento/planos');
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const closeUpgradePremium = () => {
    dispatch(MinhaContaActions.setShowModalUpdatePremium(false));
  };

  return (
    <>
      <nav className={`${isCollapsed ? styles['TopBarCollapsed'] : styles['TopBar']}`}>
        <div className={styles['left']}>
          <div 
            className={styles['buttonCollapse']}
            onClick={onCollapseClick}
          >
            <Icon icon="collapse" color="cinzaSuperior" customSize={12} />
          </div>

          {(!userInforGet?.isRegisterComplete && userInforGet?.CurrentRoleFk === 1) && (
            <CompletarCadastro
              icon="informacao"
              title="Complete seu cadastro"
              isLoading={isLoading || userLoading}
              userInfo={userInforGet}
              updateEmpresaLoading={updateEmpresaLoading}
            />
          )}
        </div>

        <aside>
          {planFree && !isLoading ? (
            <Button
              type="primary"
              ispremium="true"
              onClick={() => openUpgradePremium()}
              className={styles['buttonPremium']}
            >
              <div>
              <Icon 
                icon="crown" 
                color="cinzaEscuro"
                size='xxs'
              />
              <span>Upgrade</span>
              </div>
            </Button>
          ) : (
            <></>
          )}

          <Notification 
            value={valueNotification} 
            items={getUnreadNotifications(newNotifications)}
          />
          <span className={styles['divider']}/>

          <Profile
            name={userInforGet?.Nome || ''}
            image={userInforGet?.OriginalImageUrl || ''}
            thumb={userInforGet?.ThumbnailImageUrl}
            email={userInforGet?.Email || ''}
            hash={userInforGet?.UserHash || ''}
            userId={userInforGet?.Id || ''}
            isFreemium={planFree || true}
            tenantId={userInforGet?.CurrentTenantContext || 0}
            tenantUserRole={userInforGet?.CurrentRoleFk || 0}
            isLoading={isLoading}
            currentRole={userInforGet?.CurrentRoleFk}
            isSP={userInforGet?.isSP}
          />
        </aside>
      </nav>
      {showModalUpdatePremium && <UpgradePremium 
        visible={showModalUpdatePremium} 
        onCancel={closeUpgradePremium} 
      />}
      {showModalConfirmUpdatePremium && <AvisoUpgradeConfirmacao
        visible={showModalConfirmUpdatePremium}
        onConfirm={() => dispatch(MinhaContaActions.setShowModalConfirmPremium(false))}
      />}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
  showModalConfirmUpdatePremium: state.minhaConta.showModalConfirmUpdatePremium,
  showModalUpdatePremium: state.minhaConta.showModalUpdatePremium,
  registerCompleted: state.minhaConta.userInfo?.isRegisterComplete,
  valueNotification: state.notification.newNotifications?.reduce((acc, cur) => {
    if (cur.hasRead) {
      return acc;
    }

    return acc + 1;
  }, 0),
  newNotifications: state.notification.newNotifications,
  userLoading: state.auth.isLoading,
  updateEmpresaLoading: state.minhaConta.isLoading,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ITopBar;

export default connector(TopBar);
