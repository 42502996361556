import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IFiltersRequest } from './interfaces/Activities/IFiltersRequest';

export class FilterApi {
  static async shareLinkActivity(request: IFiltersRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Filter/Create', request);
  }
}
