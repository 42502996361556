export interface ITopicResponse {
  Guid: string;
  Identifier: string;
  Title: string;
  Description: string | null;
  Priority: TopicPriorityEnum | null;
  Visibility: TopicVisibilityEnum;
  DueDate: Date | null;
  CreationDate: Date;
  ModifiedDate: Date;
  StageFk: number | null;
  CommunicationTypeFk: number | null;
  TopicPointsFk: number[];
  ConstructionSiteDisciplinesFk: number[];
  TopicAssignedUsersFk: string[];
  ConstructionSiteFk: number | null;
  CreationAuthorFk: string | null;
  StatusFk: number;
  TypeFk: number;
  Attachments: IAttachment[];
}

export interface IAttachment {
  TopicAttachmentId: number;
  TopicFk: string;
  FileFk: string;
  FileURL: string;
  Thumbnail256x256Url: string;
  Thumbnail128x128Url: string;
  Thumbnail64x64Url: string;
  File: IAttachmentFile;
} 

export interface IAttachmentFile {
  FileId: string;
  Name: string;
  Size: number;
  Type: FileTypeAttachmentEnum;
  CreationDate: Date;
  CreatorUserFk: string;
  TenantFk: number;
  ConstructionSiteFk: number;
}

export enum TopicPriorityEnum {
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum TopicVisibilityEnum {
  Draft = 0,
  Public = 1,
  Private = 2,
}

export enum FileTypeAttachmentEnum {
  Topic = 1,
}

export enum PtBrTopicPriorityEnum {
  'Baixa' = 1,
  'Média' = 2,
  'Alta' = 3,
}

export const ReversePtBrTopicPriorityEnum = {
  'Baixa': 1,
  'Média': 2,
  'Alta': 3,
}

export enum PtBrTopicVisibilityEnum {
  'Rascunho' = 0,
  'Público' = 1,
  'Privado' = 2,
}

export const ReversePtBrTopicVisibilityEnum = {
  'Rascunho': 0,
  'Público': 1,
  'Privado': 2,
}
