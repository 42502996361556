import { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { IPanelsResponse } from "Data/interfaces/Panels/IPanelsResponse";
import { Buttons, FooterHr, Wrapper } from "./styles";

interface IConfirmDeletePanels {
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  panel: IPanelsResponse;
}

const ConfirmDeletePanels: FC<IConfirmDeletePanels> = ({
  visible,
  loading,
  onCancel,
  onConfirm,
  panel,
}) => {
  const footerConfirmButtons = (
    <Buttons>
      <Button
        onClick={onCancel}
        type="text"
        className="buttonCancel"
      >
        Cancelar
      </Button>
      <Button
        id="deleteBtn"
        type="primary"
        htmlType="submit"
        onClick={onConfirm}
        className="buttonSubmit"
        loading={loading}
        danger
      >
        Excluir
      </Button>
    </Buttons>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      footerActive={false}
      width={450}
      customClassName
      zIndex={1102}
    >
      <Wrapper>
        <span className="title">
          {`Excluir painel ¨${panel.Name}¨`}
        </span>
        <hr className="midleHr" />
        <span className="description">
          Você tem certeza que deseja excluir o painel? Essa ação não poderá ser desfeita.
        </span>
      </Wrapper>
      <FooterHr />
    </Modal>
  )
}

export default ConfirmDeletePanels;
