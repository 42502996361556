import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { IFilterDateOption } from 'Store/Notification/interfaces/INotificationState';
import { NotificationActions } from 'Store/Notification/Notification.actions';

import styles from './FilterDate.module.scss';

export interface ITipoFilter {
  width?: string;
  className?: string;
  dates: IFilterDateOption[];
  propsFilter: any;
}

const FilterData: FC<ITipoFilter> = ({ className, width, dates, propsFilter }) => {
  const dispatch = useDispatch();
  const [selectedKeys, setSelectedKeys] = React.useState<number[]>([]);

  const changeFilterValues = (value: number) => {
    propsFilter.setSelectedKeys([...selectedKeys, value]);
    setSelectedKeys([...selectedKeys, value]);
    dispatch(NotificationActions.choseFilterDate(value));
    propsFilter.confirm();
  };

  return (
    <div style={{ minWidth: width ? width : '200px' }} className={className ? className : styles['Wrapper']}>
      {
        dates.map(({ name, subtractDays }: IFilterDateOption) => (
          <div key={`key-filter-date-${name}`} style={{ minWidth: width ? width : '200px' }} className={styles['tipoOptionsWrap']} onClick={() => changeFilterValues(subtractDays)}>
            <p>{name}</p>
          </div>
        ))
      }
    </div>
  );
};

export default FilterData;
