import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imgPremium {
    width: 72px;
    height: 72px;
  }

  .textTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-top: 16px;
  }

  .textDescription {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-top: 4px;
  }

  .btnPremium {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 178px;
    height: 40px;
    margin-top: 12px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};

    .iconCrow {
      margin-right: 6px;
      color: ${({ theme }) => theme.colors.surface.onSurface} !important;
    }
  }
`;
