import { FC } from 'react';
import { Menu } from 'antd';
import { Icon, IconName } from 'Components/UI/Icon';
import { AuthUtils } from 'Utils/AuthUtils';
import { MenuStyled } from './styles';

interface IDropdownMenu {
  historyPush: (path: string) => void; 
  currentRole?: number;
  isSP?: boolean;
}
interface OptionsMenuProps {
  title: string;
  icon: IconName,
  urlRedirect: string;
  isLogout?: boolean;
  role?: number;
  isSP?: boolean;
}

interface IRedirect {
  urlRedirect: string;
  isLogout?: boolean;
}

const DropdownMenu: FC<IDropdownMenu> = ({ historyPush, currentRole, isSP }) => {
  const redirect = ({ urlRedirect, isLogout }: IRedirect) => {
    if (isLogout) {
      AuthUtils.logout();
      return historyPush(urlRedirect);
    }
  
    return historyPush(urlRedirect);
  };

  const optionsMenu: OptionsMenuProps[] = [
    {
      icon: 'menuGerenciarUsuario',
      title: 'Minha conta',
      urlRedirect: '/conta',
    },
    {
      icon: 'arquivoTxt',
      title: 'Faturamento',
      urlRedirect: '/faturamento',
      role: 1,
    },
    {
      icon: 'informacao',
      title: 'Sobre a Coordly',
      urlRedirect: '/sobre',
    },
    {
      icon: 'cadeado',
      title: 'Admin',
      urlRedirect: '/adm/tenants',
      isSP: true,
    },
    {
      icon: 'sair',
      title: 'Sair',
      urlRedirect: '/login',
      isLogout: true,
    }
  ]

  return (
    <MenuStyled>
      {optionsMenu.map(opt => (
        (!opt.role || (opt.role === currentRole)) && (!opt.isSP || (opt.isSP === isSP)) && (
          <Menu.Item 
            key={`key-to-profile-opt-${opt.title}`}
            className="menuItem"
            onClick={() => redirect({urlRedirect: opt.urlRedirect, isLogout: opt.isLogout})}
          >
            <Icon icon={opt.icon} className="menuItemIcon" customSize={15} />
            <p>{opt.title}</p>
          </Menu.Item>
        )
      ))}
    </MenuStyled>
  );
};

export default DropdownMenu;
