import { FC } from 'react';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import { GetAvatar } from '../../../../Utils/generateThumbnail';
import useHistoryPush from 'Hooks/useHistoryPush';
import { ProfileStyled } from './styles';
export interface IProfile {
  name: string;
  image: string;
  thumb?: string;
  email: string;
  hash: string;
  userId: string;
  tenantUserRole: number;
  tenantId: number;
  isFreemium: boolean;
  isLoading: boolean;
  currentRole?: number;
  isSP?: boolean;
}

const Profile: FC<IProfile> = ({
  name,
  image,
  thumb,
  email,
  hash,
  currentRole,
  isSP,
}) => {
  const { historyPush } = useHistoryPush();

  return (
    <ProfileStyled
      overlay={DropdownMenu({
        historyPush: historyPush,
        currentRole: currentRole,
        isSP: isSP,
      }) as any}
      align={{ offset: [5, 15] }}
      placement='bottomRight'
      trigger={['click']}
    >
      <div>
        <GetAvatar
          name={name}
          shape="circle"
          src={image}
          thumbSrc={thumb}
          thumbType="small"
        />
        <p className="userName">{name}</p>
        <p id="UserHash" className="hiddenValue">{hash}</p>
        <p id="UserEmail" className="hiddenValue">{email}</p>
        <p id="UserName" className="hiddenValue">{name}</p>
        <p id="EnvNumber" className="hiddenValue">
          {process.env.REACT_APP_TAWK_TO_NUMBER}
        </p>
      </div>
    </ProfileStyled>
  );
};

export default Profile;
