import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import { INomenclatureData } from "Data/interfaces/Nomenclatura/INomenclatureData";
import { IObraNomenclaturesRequest } from "Data/interfaces/Obra/IObraNomenclaturas";
import { INomenclatureObraData } from "Data/interfaces/Obra/ICreateOrUpdate";
import NomenclaturaForm from "Components/UI/NomenclaturaForm";
import toastHandler from 'Utils/toastHandler';
import { Container, Main, Title } from "./styles";

interface INomenclature {
  csId: number;
  loading?: boolean;
  obraVersionData?: INomenclatureData;
  versionConfigFk?: number;
  hasValidateStagesInNomenclature?: boolean;
}

const Nomenclature: FC<INomenclature> = ({
  csId,
  loading,
  obraVersionData,
  versionConfigFk,
  hasValidateStagesInNomenclature,
}) => {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (values?: INomenclatureObraData, erros?: string[]) => {
    erros?.forEach((error) => {
      toastHandler.handler({ description: error, icon: 'error', title: '' });
    });

    if (!showEdit || !values || erros) return;

    const request: IObraNomenclaturesRequest = {
      constructionStieId: csId,
      nomenclatures: {
        isVersionControlDefault: values.isVersionControlDefault,
        versionConfig: {
          hasRevisionControl: values.VersionConfig.HasRevisionControl,
          versionFields: values.VersionConfig.VersionFields.map(field => ({
            ordem: field.Ordem,
            campoPersonalizado: field.CampoPersonalizado,
            separador: field.Separador,
            campoVersaoId: field.CampoVersaoId,
          })),
        },
      },
    };
    dispatch(ObraActions.updateNomenclature(request))
  }

  return (
    <Container>
      <Title>Nomenclatura</Title>
      <Main>
        <NomenclaturaForm
          isSubmited={isSubmit}
          isEditObra
          obraVersionData={obraVersionData}
          versionConfigFk={versionConfigFk}
          isTabVersion
          showEditObra={showEdit}
          showEditLoading={loading}
          hasValidateStagesInNomenclature={hasValidateStagesInNomenclature}
          onShow={() => setShowEdit(true)}
          onCancel={() => setShowEdit(false)}
          onSubmit={handleSubmit}
        />
      </Main>
    </Container>
  )
}

export default Nomenclature;
