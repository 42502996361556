import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 10px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin-top: 6px;

  .avatarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .userName {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      margin-left: 16px;
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .roleName {
      width: 160px;
      height: 36px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
      background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      margin-left: 16px;
    }

    .selectRole {
      width: 158px !important;
      height: 36px;
      display: flex;
      align-items: center;
      border-radius: 5px !important;

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    }
    
    .watingContainer {
      position: relative;
      width: 150px;
      height: 36px;
      margin-left: 16px;
      
      .waitingTitle {
        position: absolute;
        top: 0;
        left: 0;
        width: 115px;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme.colors.warning.warning};
        background-color: ${({ theme }) => theme.colors.surface.surface};
        z-index: 2;

        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.warning.warning};
      }

      .watingBtn {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        z-index: 1;
  
        .waitingIcon {
          color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
        }
      }
    }
  }
`;