import { IUserInforResponse } from "../Auth/IUserInfoResponse";
import { ITenantData } from "../Tenant/ITenantData";

export interface IPlotRequestRequest {
  Description: string,
  PlotterResponsibleName: string,
  UseConstructionAddress: boolean,
  Address: string,
  PlotterEmail: string,
  ConstructionSiteFk: number,
  isDefaultPlotterEmail: boolean,
  Observation: string,
  Files: IFilePlotRequest[],
  isLoading?: boolean,
  QRCodePosition: QRCodePositionEnum,

  disciplineIdTracking?: number,
  userInfoTracking?: IUserInforResponse,
  currentTenantTracking?: ITenantData | null,
}

export interface IFilePlotRequest {
  FileApiId: string,
  FileInfoFk: number,
  QtyCopies: number,
  HasColor: boolean,
  ConstructionSiteDisciplineFolderId: string,
  Name: string,
  ShowQRCode: boolean,
}

export enum QRCodePositionEnum {
  TopLeft = 1,
  TopRight = 2,
  TopCenter = 3,
  CenterLeft = 4,
  CenterRight = 5,
  CenterCenter = 6,
  BottomLeft = 7,
  BottomRight = 8,
  BottomCenter = 9,
}
