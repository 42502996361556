import { ITopicDnDResponse } from "./IDragAndDropDataFormat";
import { PtBrTopicPriorityEnum, PtBrTopicVisibilityEnum } from "./ITopicResponse";
import { filterOperations } from "Utils/ActivitiesUtils";

export interface IEnumMap<T> {
  [key: string]: T;
}

export const enumToStringValueMap: IEnumMap<any> = {
  PtBrTopicPriorityEnum: PtBrTopicPriorityEnum,
  PtBrTopicVisibilityEnum: PtBrTopicVisibilityEnum,
};

export type FilterOperationMap = typeof filterOperations;

export interface IUserDescription {
  name: string,
  img?: string,
}

export interface IFiltroOptions<T> {
  topicId: string;
  properties?: string[];
  value: any;
  operation: keyof FilterOperationMap;
  description?: string | string[] | IUserDescription[];
}

export type FiltersRecordType = Record<string, IFiltroOptions<ITopicDnDResponse>>;

export interface IFilters<T, EnumsMap extends IEnumMap<any> = IEnumMap<any>> {
  filters?: Record<string, IFiltroOptions<T>>;
  array: T[];
  enumToStringValueMap?: EnumsMap;
}

export type OPType = 'id' | 'op' | 'vl';

export interface IValuesFilter {
  filterId?: string; 
  topicId?: keyof ITopicDnDResponse; 
  operation?: keyof FilterOperationMap;
  value?: any;
  properties?: string[];
  description?: string[];
}

export interface IUpdateFilter {
  op: OPType;
  values: IValuesFilter;
}

export const uniqueSelectionKeys: (keyof ITopicDnDResponse)[] = [ 
  'DueDate',
  'Description',
  'Title',
  'Identifier',
];
