import { FC, useState } from 'react';
import { Icon } from 'Components/UI';
import Button from 'Components/UI/Button';
import { ApisEnumLabel } from 'Data/enums/Apis';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { CustomPopover, Modal } from './styles';

interface IErrorSAConnection {
  platform: number;
}

const ErrorSAConnection: FC<IErrorSAConnection> = ({
  platform, 
  children,
}) => {
  const disciplineSAErrorBlogUrlFlag = useFeatureFlag('discipline-sa-error-blog-url');
  const diciplineSAErrorUrl = (disciplineSAErrorBlogUrlFlag.enabled && typeof disciplineSAErrorBlogUrlFlag.value === 'string') 
    ? disciplineSAErrorBlogUrlFlag.value 
    : 'https://www.maletadoengenheiro.com.br/blog';

  const [visible, setVisible] = useState<boolean>();

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const overlay = (
    <Modal>
      <div className="Wrapper">
        <div className="content">
          <div className="iconBox">
            <h4 className="title">
              {`Ocorreu um erro ao tentar conectar com a disciplina no ${ApisEnumLabel[platform]}.`}
            </h4>
            <Icon 
              icon="cancelar" 
              customSize={12} 
              color="cinzaOpaco" 
              onClick={hide}
              className="closeIcon"
            />
          </div>

          <p className="description">
            {`Não conseguimos conectar com a pasta da disciplina no ${ApisEnumLabel[platform]}, no entanto você ainda consegue acessar as informações de plotagem.`} 
          </p>
        </div>

        <div className="Footer">
          <div className="link">
            <a
              href={diciplineSAErrorUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Saiba mais
            </a>
          </div>
          <Button 
            type="primary"
            onClick={hide}
            className="buttonAction"
          >
            {'Fechar'}
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <CustomPopover 
      content={overlay} 
      placement="bottomLeft" 
      trigger="click"
      open={visible} 
      onOpenChange={handleVisibleChange}
      overlayInnerStyle={{padding: 0}}
    > 
      {children}
    </CustomPopover>
  );
}

export default ErrorSAConnection;
