import { IUserData } from "../User/IUserData";

export interface IHistoryActivityApiResponse {
  Action: HistoryActivityActionEnum;
  TopicId: string;
  ItemName: string;
  From: IHistoryActivityDetail;
  To: IHistoryActivityDetail;
  DateTimeUtc: Date;
  Author: IUserData;
}

export interface IHistoryActivityDetail {
  Value: string;
  DisplayValue: string;
}

export enum HistoryActivityActionEnum {
  Created = 0,
  StatusChanged = 1,
}

export interface IHistoryActivityRequestList {
  topicId: string;
}

export interface IHistoryActivityResponseList {
  histories: IHistoryActivityApiResponse[];
}
