import { FC } from "react"
import { Icon } from "Components/UI/Icon";
import Tag from "Components/UI/Tag";
import { NoResults } from "./components/NoResults";
import styles from './SearchRow.module.scss';

export interface ISearchRow {
  show: boolean
  search?: string;
  count?: number;
  obrasForShow: boolean;
  onRemoveSearch: () => void;
}

export const SearchRow: FC<ISearchRow> = ({
  show,
  search,
  count,
  obrasForShow,
  onRemoveSearch,
}) => {
  return (
    <div className={styles['Container']}>
      {search && show && (
        <Tag
          type="secondary"
          closable
          closeIcon={
            <Icon
              icon="cancelar"
              color="cinzaSuperior"
              customSize={10}
              className={styles['clickable']}
              onClick={onRemoveSearch}
            />
          }
        >
          <Icon icon='buscar' customSize={14} size="xxs" color="cinzaSuperior" />
          <p className={styles['tag-text']}>“{search}”</p>
        </Tag>
      )}
      {obrasForShow && count === 0 && (<NoResults search={search} />)}
    </div>
  );
}
