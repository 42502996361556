import { FC, useMemo } from "react";
import { Icon } from "Components/UI";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { List } from "antd";
import HighlighterWords from "Components/UI/HighlightWords";
import {
  BucketFileStatusBREnum,
  FileStatusEnum,
  IFileData,
} from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { iconFileByExtension } from "Utils/Icons";
import Tag from "Components/UI/Tag";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import { IHandleOpenFolder } from "..";

interface ISelectFileListChildren {
  filteredFiles: IFileData[];
  disciplineFolder: IDisciplineViewerList | null;
  searchFile: string;
  theme: IMDTheme;
  onOpenFolder: (data: IHandleOpenFolder) => void;
  onConfirmFileFromMaleta: (item: IFileData) => void;
}

const SelectFileListChildren: FC<ISelectFileListChildren> = ({
  filteredFiles,
  disciplineFolder,
  searchFile,
  theme,
  onOpenFolder,
  onConfirmFileFromMaleta,
}) => {
  const renderColor = useMemo(() => {
    return {
      0: theme.colors.surface.onSurfaceVariant,
      1: theme.colors.success.success,
      2: theme.colors.information.information,
      3: theme.colors.danger.danger,
    }
  }, [theme.colors]);

  return (
    <List
      itemLayout="horizontal"
      dataSource={filteredFiles}
      split={false}
      renderItem={(item: IFileData) => (
        <List.Item
          className="rowFilesList"
          onClick={() => (item.IsFolder || item.inObsoleteFolder)
            ? onOpenFolder({
              folderId: item.Identifier.ApiId || item.Identifier.ItemId,
              ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId,
              folderName: item.Name,
            })
            : onConfirmFileFromMaleta(item)
          }
        >
          <div className="leftRowFilesList">
            <Icon
              icon={item.IsFolder
                ? 'arquivar'
                : (item.inObsoleteFolder
                  ? 'obsoleteArchive'
                  : iconFileByExtension(item.Extension)
                )
              }
              customSize={18}
              className="iconLeftRowFilesList"
            />
            <span className="leftRowFilesListMain">
              <HighlighterWords
                text={item.Name}
                maxLength={57}
                wordSearched={searchFile}
                className="filesListText"
              />
              {!item.IsFolder && !item.inObsoleteFolder && (
                <span className="leftRowFilesListMainRight">
                  <Tag
                    customClassName="renderTag"
                    customColor={renderColor[item.RenderingStatus]}
                  >
                    {BucketFileStatusBREnum[item.RenderingStatus]}
                  </Tag>
                  <span className="dividerTags">|</span>
                  <Tag
                    customClassName="statusTag"
                    customColor={item.Status === FileStatusEnum.Liberado
                      ? theme.colors.success.success
                      : theme.colors.surface.onSurfaceVariant
                    }
                  >
                    {FileStatusEnum[item.Status]}
                  </Tag>
                </span>
              )}
            </span>
          </div>
          {(item.IsFolder || item.inObsoleteFolder) && (
            <Icon
              icon="retroceder"
              customSize={8}
              className="iconExpanded"
              color={(item.IsFolder || item.inObsoleteFolder)
                ? "cinzaSuperior"
                : "cinzaOpaco"
              }
            />
          )}
        </List.Item>
      )}
    />
  )
}

export default SelectFileListChildren;
