import React, { FC } from 'react';

import styles from './GoogleLinkButton.module.scss';
import LogoGoogle from '../../../Static/images/logo-google.png';

interface IGoogleLinkButton {
  emailHash?: string;
  redirectUrl?: string;
}

const GoogleLinkButton: FC<IGoogleLinkButton> = ({ emailHash, redirectUrl }) => {
  const getExternalLink = () => {
    let href = process.env.REACT_APP_EXTERNAL_AUTH_GOOGLE_URL || '';

    if (emailHash || redirectUrl) {
      href += '?';
    }

    if (emailHash) {
      href += `tenantInviteEmailHash=${emailHash}`;
    }

    if (redirectUrl) {
      href += `&redirectUrl=${redirectUrl}`;
    }

    return (window.location.href = href);
  };

  return (
    <div onClick={getExternalLink} className={styles['loginGoogle']}>
      <img src={LogoGoogle} alt="Logo Google" />
      <span> Entrar com o Google</span>
    </div>
  );
};

export default GoogleLinkButton;
