import styled, { css } from "styled-components";

type ContainerProps = {
  customColor: string;
  height: number;
  showFilters?: boolean;
}

type TitleProps = {
  customColor: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({customColor}) => customColor}1F;
  display: flex;
  flex-direction: column;
  width: 308px;
  min-width: 308px;
  max-width: 308px;
  border-radius: 4px;
  height: ${({height}) => height - 290}px;
  margin-left: 12px;

  ${({ height, showFilters }) => showFilters && css`
    height: ${height - 332}px;
  `}

  &:first-child {
    margin-left: 0;
  }

  .columnHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .columnHeaderLeft {
      display: flex;
      align-items: center;
    }

    .columnHeaderRight {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .iconToggleClose {
        background-color: transparent;
        display: flex;
        cursor: pointer;
      }

      .iconChildrenAdd {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .columnChild {
    padding: 6px;
    height: ${({height}) => height - 340}px;
    overflow-y: hidden;
  }
  
`;

export const Title = styled.div<TitleProps>`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #324150;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 600;
  margin: 6px;
  margin-right: 8px;

  &::before {
    content: ' ';
    display: block;
    width: 4px;
    height: 32px;
    margin-right: 12px;
    border-radius: 2px;
    background-color: ${({customColor}) => customColor};
  }
`;

export const Collapse = styled.div<ContainerProps>`
  background-color: ${({customColor}) => customColor}1F;
  border-radius: 4px;
  width: 44px;
  height: ${({height}) => height - 290}px;
  margin-left: 12px;

  ${({ height, showFilters }) => showFilters && css`
    height: ${height - 332}px;
  `}

  &:first-child {
    margin-left: 0;
  }
  
  .columnHeaderCollapse {
    display: flex;
    align-items: center;
    transform: rotate(90deg);

    .iconToggleOpen {
      margin-left: 18px;
      margin-right: 18px;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
`;