import React from "react";
import { ColumnProps } from "antd/lib/table";
import ColumnHeader from "Components/UI/Table/ColumnHeader";
import { numberToMoney } from "Utils/Money";
import { IStorePlanResponse } from "Data/interfaces/Store/IStorePlanResponse";

export interface IControleAdmPlanosInput {
  plansList?: IStorePlanResponse[];
  sortPlansList: (sorterDirection: boolean, key: string) => void;
}

export const getColumnsTablePlans: (input: IControleAdmPlanosInput) =>
  Array<ColumnProps<IStorePlanResponse>> = ({
    plansList, sortPlansList
  }) => ([
    {
      title: (
        <ColumnHeader
          title="Nome Planos"
          sorter={(sorterDirection) => sortPlansList(sorterDirection, 'Name')}
        />
      ),
      dataIndex: 'Name',
    },
    {
      title: (
        <ColumnHeader
          title="Preço"
          showOrder={false}
        />
      ),
      dataIndex: 'Price',
      render: (text, record) => {
        return (
          <span>{record.isCustom === 1
            ? 'Sob Consulta'
            : record.Price === 0
              ? 'Grátis'
              : numberToMoney(text, 'real')
          }</span>
        )
      },
    },
    {
      title: (
        <ColumnHeader
          title="Max Obras"
          showOrder={false}
        />
      ),
      dataIndex: 'MaxQtyConstructionSites',
      render: (text, record) => {
        return (
          <span>{record.MaxQtyConstructionSites ? text : 'Ilimitado'}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Max Usuários"
          showOrder={false}
        />
      ),
      dataIndex: 'MaxQtyUsers',
      render: (text, record) => {
        return (
          <span>{record.MaxQtyUsers ? text : 'Ilimitado'}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Max Upload"
          showOrder={false}
        />
      ),
      dataIndex: 'MaxQtyUploadFiles',
      render: (text, record) => {
        return (
          <span>{record.MaxQtyUploadFiles ? text : 'Ilimitado'}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Order"
          sorter={(sorterDirection) => sortPlansList(sorterDirection, 'Order')}
        />
      ),
      dataIndex: 'Order',
      render: (text, record) => {
        return (
          <span>{text}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Status"
          sorter={(sorterDirection) => sortPlansList(sorterDirection, 'isActive')}
        />
      ),
      dataIndex: 'isActive',
      render: (text, record) => {
        return (
          <span>{record.isActive === 1 ? 'Ativo' : 'Inativo'}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Popular"
          sorter={(sorterDirection) => sortPlansList(sorterDirection, 'IsDefault')}
        />
      ),
      dataIndex: 'IsDefault',
      render: (text, record) => {
        return (
          <span>{record.IsDefault === 1 ? 'Popular' : 'Não'}</span>
        )
      }
    },
    {
      title: (
        <ColumnHeader
          title="Aba Viewer"
          sorter={(sorterDirection) => sortPlansList(sorterDirection, 'IsDefault')}
        />
      ),
      dataIndex: 'AccessViewerTab',
      render: (text, record) => {
        return (
          <span>{record.AccessViewerTab ? 'Sim' : 'Não'}</span>
        )
      }
    },
  ]);