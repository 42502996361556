import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { Skeleton } from "antd";
import { MultiDomainActions } from "Store/MultiDomain/MultiDomain.action";
import { 
  ITenantPersonalizationUpdateData, 
  IUpdateTenantPersonalizationRequest, 
  TypePathUpdateData,
} from "Data/interfaces/MultiDomain/IUpdateMultiDomainPersonalization";
import { 
  MultiDomainPersonalizationStatusEnum,
} from "Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse";
import { getDomainLoading } from "Store/MultiDomain/MultiDomain.selector";
import { getAdmError, getAdmTenantDomain, getAdmTenantDomainLoading } from "Store/Adm/Adm.selector";
import { DNSWrapper, InfoWrapper, Wrapper } from "./styles";
import { Icon } from "Components/UI";
import { ICheckDNSRequest } from "Data/interfaces/MultiDomain/ICheckDNSRequest";

interface ITenantPersonalization {
  visible?: boolean;
  onClose: () => void;
  closeEditTenantPersonalizationModal: () => void;
}

const TenantPersonalization: FC<ITenantPersonalization> = ({
  visible,
  onClose,
  closeEditTenantPersonalizationModal, 
}) => {
  const dispatch = useDispatch();
  const loadingDomain = useSelector(getDomainLoading);
  const admTenantDomain = useSelector(getAdmTenantDomain);
  const admTenantDomainLoading = useSelector(getAdmTenantDomainLoading);
  const error = useSelector(getAdmError); 

  const [currentAction, setCurrentAction] = useState<TypePathUpdateData | null>(null);

  const handleDNSConfirm = () => {
    if (admTenantDomain?.Tenant?.TenantId) {
      const request: ICheckDNSRequest = {
        tenantFk: admTenantDomain.Tenant.TenantId,
        isAdmConfig: true,
      }
      dispatch(MultiDomainActions.checkDNS(request));
      setCurrentAction('DomainName');
    }
  };

  const handleUpdate = (
    useTenantFk: number,
    updateData: ITenantPersonalizationUpdateData[]
  ) => {
    if (useTenantFk && updateData.length > 0) {
      const request: IUpdateTenantPersonalizationRequest = {
        TenantFk: useTenantFk,
        updateData,
        isAdmConfig: true,
      }
      dispatch(MultiDomainActions.updateTenantPersonalization(request));
      setCurrentAction('Status');
    }
  }

  const handleSubmit = () => {
    if (admTenantDomain?.Tenant?.TenantId) {
      const updateData: ITenantPersonalizationUpdateData[] = [];

      updateData.push({
        path: "Status",
        op: 'replace',
        value: MultiDomainPersonalizationStatusEnum.ConfiguracaoConcluida,
      });

      handleUpdate(admTenantDomain.Tenant.TenantId, updateData);
    }
  };

  const onCloseAux = () => {
    onClose();
    closeEditTenantPersonalizationModal();
  };

  const buttons = (
    <div>
      <Button
        onClick={onCloseAux}
      >
        <span>Cancelar</span>
      </Button>
    </div>
  );

  const loadingDNS = currentAction === 'DomainName' && loadingDomain;
  const loadingConfirm = currentAction === 'Status' && loadingDomain;

  const initConfig = MultiDomainPersonalizationStatusEnum.NaoConfigurado === admTenantDomain?.Status || MultiDomainPersonalizationStatusEnum.Padrao === admTenantDomain?.Status;
  const DNSWating = MultiDomainPersonalizationStatusEnum.ConfiguracaoEmAndamento === admTenantDomain?.Status;
  const internalWating = MultiDomainPersonalizationStatusEnum.AguardandoAnaliseInterna === admTenantDomain?.Status;
  const OK = MultiDomainPersonalizationStatusEnum.ConfiguracaoConcluida === admTenantDomain?.Status;
  const DNSError = MultiDomainPersonalizationStatusEnum.ErroConfiguracao === admTenantDomain?.Status;

  return (
    <Modal
      title="Gerenciamento de Tenant"
      visible={visible}
      footerButtons={buttons}
      onCancel={onCloseAux}
      width={676}
    >
      <Wrapper>
        {admTenantDomainLoading && (
          <Skeleton active />
        )}
        {!admTenantDomainLoading && admTenantDomain && (
          <>
            <div>
              <h4>{admTenantDomain?.Tenant?.CompanyName}</h4>
            </div>

            {!initConfig && (
              <DNSWrapper isInternal={`${internalWating}`} isOK={`${OK}`}>
                {OK && (
                  <Icon
                    icon="confirmacao"
                    className="dnsConfirmIcon"
                    customSize={24}
                  />
                )}
                {!OK && !DNSError && (
                  <Icon
                    icon="relogio"
                    className="dnsRelogioIcon"
                    customSize={24}
                  />
                )}
                {DNSError && (
                  <Icon
                    icon="error"
                    className="dnsErrorIcon"
                    customSize={24}
                  />
                )}
                <span className="infoWrapper">
                  <span className="info">
                    {DNSWating && 'Aguardando configuração CNAME.'}
                    {internalWating && 'Aguardando análise interna.'}
                    {OK && 'Site online!'}
                    {DNSError && 'Configuração incompleta.'}
                  </span>
                  {admTenantDomain?.DomainName && (
                    <span className="dns">
                      https://
                      <span className="dnsBold">{admTenantDomain.DomainName}</span>
                      /
                    </span>
                  )}
                </span>
                {(DNSWating || DNSError) && (
                  <>
                    <Button 
                      type="primary"
                      loading={loadingDNS}
                      className="cnameBtn"
                      onClick={handleDNSConfirm}
                    >
                      {loadingDNS ? 'Verificando...' : 'Verificar CNAME'}
                    </Button>
                  </>
                )}
                {(internalWating) && (
                  <Button 
                    type="primary"
                    loading={loadingConfirm}
                    className="cnameBtn"
                    onClick={handleSubmit}
                  >
                    {loadingConfirm ? 'Salvando...' : 'Concluir Personalização'}
                  </Button>
                )}
              </DNSWrapper>
            )}
            <InfoWrapper>
              {initConfig && (
                <span className="infoInternal">
                  Tenant ainda não iniciou a configuração de domínio.
                </span>
              )}
              {DNSWating && (
                <span className="infoInternal">
                  Tenant aguardando a configuração do DNS ser realizado ou aguardando a validação.
                </span>
              )}
              {internalWating && (
                <span className="infoInternal">
                  Tenant aguardando confirmação interna do super Admin Coordly.
                </span>
              )}
              {OK && (
                <span className="infoInternal">
                  Tenant com domínio personalizado ativo.
                </span>
              )}
              {(DNSError || error) && (
                <span className="infoError">
                  Não foi possível verificar o domínio. Ainda não foi configurado ou está em processo de validação.
                </span>
              )}
            </InfoWrapper>
          </>
        )}
      </Wrapper>
    </Modal>
  )
}

export default TenantPersonalization;
