import React, { FC } from 'react';
import { Icon } from 'Components/UI';
import { dateUpdateListFiles } from 'Utils/DateUtils';
import styles from './updateStatus.module.scss';

export interface IUpdateStatus {
  status: 'atualizado' | 'atualizando' | 'error';
  date: Date | string | null | undefined;
  loading: boolean;
  custom?: boolean;
}

const UpdateStatus: FC<IUpdateStatus> = ({
  status,
  date,
  loading,
  custom,
}) => {
  const formatedDate = new Date(date || new Date());

  if (loading && !custom) {
    return <></>
  }

  if (status === "atualizando") {
    return (
      <div className={styles['WrapperStatusUpdating']}>
        <p className={styles['updateStatus']}>
          {custom 
            ? loading
              ? 'Carregando'
              : 'Atualizando'
            : 'Carregando'
              
          }
        </p>
        <Icon 
          color={custom ? 'primary' : 'cinzaSuperior'} 
          icon="atualizar" 
        />
      </div>
    )
  }

  if (status === "atualizado") {
    return (
      <div className={styles['WrapperStatus']}>
        <p className={styles['updateStatus']}>{dateUpdateListFiles(formatedDate, custom)}</p>
      </div>
    )
  }

  return (
    <div className={styles['WrapperStatus']}>
      <p className={styles['updateStatus']}>Erro ao atualizar, favor recarregar a página</p>
      <Icon color="vermelho" icon="aviso" />
    </div>
  )
}

export default UpdateStatus;
