import React, { FC } from 'react';
import { Switch, Route } from 'react-router';

import Login from '../../Pages/Auth/Login';
import Signup from '../../Pages/Auth/Signup';
import SignupGoogle from '../../Pages/Auth/SignupGoogle';
import ForgotPassword from '../../Pages/Auth/ForgotPassword';
import ResetPassword from '../../Pages/Auth/ResetPassword';
import ExternalAuth from '../../Pages/Auth/ExternalAuth';

const UnauthenticatedRoutes: FC = () => {
  return (
    <>
      <Switch>
        <Route path='/login/:emailHash?' component={Login} />
        <Route path='/externalauth' component={ExternalAuth}/>
        <Route path='/signupgoogle' component={SignupGoogle} />
        <Route path='/signup/:emailHash?' component={Signup} />
        <Route path='/forgotpassword' component={ForgotPassword} />
        <Route path='/resetpassword' component={ResetPassword} />
        <Route path='/' component={Login} />
      </Switch>
    </>
  );
};

export default UnauthenticatedRoutes;
