import React, { FC, useEffect } from 'react';
import styles from './ObrasConvidadosTab.module.scss';
import { Icon } from '../../../../../Components/UI';
import ListContainer from '../../../../../Components/UI/Containers/ListContainer/ListContainer';
import Button from '../../../../../Components/UI/Button';
import { GetAvatar } from '../../../../../Utils/generateThumbnail';
import { IUserInfoResponse } from '../../../../../Data/interfaces/MinhaConta/IUserInfoResponse';
interface IObrasConvidadosTab {
  isSubmited?: boolean;
  workToEdit?: any;
  currentUser?: IUserInfoResponse;
  onSubmit?: (props: any) => void;
  changeWorks: (changedUser: any) => void;
  usuarioAtualSharedWorks?: any;
  combinedUsers?: any
}

const ObrasConvidadosTab: FC<IObrasConvidadosTab> = ({
  workToEdit,
  changeWorks,
  usuarioAtualSharedWorks,
  combinedUsers,
  currentUser
}) => {
  const [worksToRender, setWorksToRender] = React.useState<any>(workToEdit);
  
  const updateWorks = () => {
    changeWorks(worksToRender);
  };
  
  const changeSharedWorks = (index: number) => {
    const privileges = getPrivilegesByRole(worksToRender.Role.RoleName) ?? [];
    const hasPlottingPermission = privileges.indexOf(4) > -1;

    if (hasPlottingPermission)
      privileges.splice(privileges.indexOf(4), 1);

    worksToRender.ConstructionSites[index].IsShared = !worksToRender.ConstructionSites[index].IsShared;
    worksToRender.ConstructionSites[index].HasPlottingPermission = hasPlottingPermission;
    worksToRender.ConstructionSites[index].Privileges = privileges;

    setWorksToRender(JSON.parse(JSON.stringify(worksToRender)));
    updateWorks();
  };

  const getPrivilegesByRole = (roleName: string) => {
    switch (roleName) {
      case 'Coordenador':
        return [0, 1, 2, 3, 4];
      case 'Engenheiro':
        return [0, 1, 2, 4];
      case 'Projetista':
        return [0, 1, 2];
      case 'Consultor':
        return [0];
    }
  };

  useEffect(() => {
    setWorksToRender(workToEdit)
  }, [workToEdit])

  return (
    <div className={styles['usuariosConvidadosWrapper']}>
      <div>
        <div>
          {worksToRender?.ConstructionSites?.map((shared: any, index: any) => (
            <ListContainer
              height={51}
              key={`sharedUser-${index}`}
              className={styles['sharedUserItem']}
            >
              <div className={styles['invitedUserWrapper']}>
                <GetAvatar
                  name={shared?.ConstructionSiteName || ''}
                  shape='circle'
                />
                <span className={styles['sharedUserName']}>
                  {shared?.ConstructionSiteName}
                </span>
                {usuarioAtualSharedWorks.find(
                  (x: any) => x.ConstructionSiteId === shared.ConstructionSiteId
                ) ?
                  <Button
                    //Desabilita o compartilhamento com o Coordenador, porém, se removido esta condição, não recarrega a página
                    disabled={workToEdit?.Role?.RoleName === "Coord" && combinedUsers?.find(
                      (user: any) => user.User.Id === currentUser?.Id
                    ).Role.RoleName !== 'Admin' ? true : false}
                    height={30}
                    customText
                    isShared={shared?.IsShared &&
                      usuarioAtualSharedWorks.find(
                        (x: any) => x.ConstructionSiteId === shared.ConstructionSiteId
                      )}
                    className={`${styles['shareButton']} ${shared?.IsShared &&
                      usuarioAtualSharedWorks.find(
                        (x: any) => x.ConstructionSiteId === shared.ConstructionSiteId
                      ) ? styles['shared'] : ''
                      }`}
                    onClick={() => changeSharedWorks(index)}
                  >
                    {shared?.IsShared &&
                      usuarioAtualSharedWorks.find(
                        (x: any) => x.ConstructionSiteId === shared.ConstructionSiteId
                      ) ? (
                      <>
                        <span>Compartilhado</span>
                        <Icon
                          icon="confirmar"
                          color="verde"
                          customSize={9}
                          style={{ height: 9 }}
                        />
                      </>
                    ) : (
                      'Compartilhar'
                    )}
                  </Button> : <></>}
              </div>
            </ListContainer>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ObrasConvidadosTab;
