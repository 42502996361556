import React, { FC, useEffect } from 'react';
import styles from './PlotagemArquivos.module.scss';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import Table from '../../../Components/UI/Table';
import BreadCrumb from '../../../Components/UI/BreadCrumb';
import TableActionTopbar from '../../../Components/UI/TableActionTopbar';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { PlotagemActions } from '../../../Store/Plotagem/Plotagem.actions';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import ReenvioSolicitacao from '../Modals/ReenvioSolicitacao/index';
import ExcluiArquivos from '../Modals/ExcluiArquivo/index';
import {
  getButtonsTableToolbar
  , breadCrumb
  , IPlotagemArquivos
  , columnsTable
  , getArquivosRelatorio,
  TipoRelatorio
} from './PlotagemArquivos.helpers';
import PlotagemRelatorioArquivos from '../PlotagemRelatorioArquivos';
import { IArquivoData } from '../../../Data/interfaces/Plotagem/IArquivoData';
import { IDownloadRelatorioRequest } from '../../../Data/interfaces/Plotagem/IDownloadRelatorioRequest';
import TabsShell, { PathActiveEnum } from '../../../Components/UI/TabsShell';
import LoadingArchivesPlotagens from 'Components/UI/CustomLoading/LoadingArchivesPlotagens';
import { DisciplineIconsEnum } from 'Pages/Disciplinas';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { Http } from 'Utils/Http';
import { useHistory } from 'react-router';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

const PlotagemArquivos: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTenant = useSelector(getCurrentTenant);

  const [selectedArquivos, setSelectedArquivos] = React.useState([]);
  const onChange = (selecteds: any) => setSelectedArquivos(selecteds);
  const [arquivosPrint, setArquivosPrint] = React.useState<IArquivoData[]>([]);
  const folderName = Http.GetQueryParams('folderName');
  let obraName: string | undefined;
  let disciplineName: string | undefined;
  let currentArquivos: IArquivoData[] = [];
  
  useEffect(() => {
    if (!props.isLoading) {
      setSelectedArquivos([]);
    }
  }, [props.isLoading]);

  const rowSelection = {
    selectedArquivos,
    onChange,
  };

  useEffect(() => {
    dispatch(PlotagemActions.listPlotagemArquivos(props.match.params.ConstructionSiteDisciplineFk));
    dispatch(ListagemArquivosActions.setShowPlotRequestTable(false))
  }, [props.match.params, dispatch]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const search = history.location.search;
    dispatch(ObraActions.storePlotagensPath({
      ...props.match,
      url: `${pathname}${search}`
    }));
  }, [props.match]);

  useEffect(() => {
    if (('matchMedia' in window) && arquivosPrint.length > 0) {
      const handlePrintEvent = (event: MediaQueryListEvent) => {
        if (!event.matches) {
          Mixpanel.track({
            name: 'REPORT_CREATE', 
            props: {
              constructionSiteId: Number(props.match.params.constructionSiteId),
              disciplineId: Number(props.match.params.ConstructionSiteDisciplineFk),
              fileQuantity: arquivosPrint.length,
              reportType: 'pdf',
            },
            userInfo: props.userInfo,
            currentListTenant: currentTenant,
          })
        }
      };

      const mediaQueryList = window.matchMedia('print');
      mediaQueryList.addEventListener('change', handlePrintEvent);

      return () => {
        mediaQueryList.removeEventListener('change', handlePrintEvent);
      };
    }
  }, [arquivosPrint]);

  const exportRelatorioPlotagem = (tipoRelatorio: TipoRelatorio) => {
    const arquivosRelatorio = getArquivosRelatorio(currentArquivos);

    if (tipoRelatorio == 'pdf') {
      setArquivosPrint(arquivosRelatorio);

      setTimeout(() => {
        window.print();
      }, 500);
    }
    else if (tipoRelatorio == 'excel') {
      setTimeout(() => {

        const request: IDownloadRelatorioRequest = {
          ArquivosRelatorio: arquivosRelatorio,
          ConstructionSiteDisciplineId: props.match.params.ConstructionSiteDisciplineFk,
          constructionSiteIdInfoTracking: props.match.params.constructionSiteId,
          userInfoTracking: props.userInfo,
          currentTenantTracking: currentTenant,
        };

        dispatch(PlotagemActions.downloadRelatorioExcel(request));
      }, 500);
    }
  }

  const isLoading = () => {
    if (props.isLoading && !props.isDownloading) {
      return true;
    }
    return false;
  }

  if (props.ArquivoResponse.info && !isLoading()) {
    obraName = props.ArquivoResponse.info.PreviousItem?.Name;
    disciplineName = props.ArquivoResponse.info.Name;

    breadCrumb[0] = {
      description: `${obraName}`,
      href: `/obras/filelist/${props.match.params.constructionSiteId}`,
    };
    breadCrumb[1] = {
      description: 'Plotagens',
      href: `/obras/plotagens/${props.match.params.constructionSiteId}`,
    };
    breadCrumb[2] = {
      description: `${disciplineName}`,
      href: `/obras/plotagens/filelist/${props.ArquivoResponse.info.PreviousItem?.Identifier}/${props.ArquivoResponse.info.Identifier}${folderName ? `?folderName=${folderName}` : ''}`,
    };
  } else {
    breadCrumb[0] = {
      href: '',
      isLoading: true,
    };
    breadCrumb[1] = {
      href: '',
      isLoading: true,
    };
    breadCrumb[2] = {
      href: '',
      isLoading: true,
    };
  }

  const iconLeft = () => {
    if (disciplineName && (DisciplineIconsEnum as any)[folderName || disciplineName]) {
      return (DisciplineIconsEnum as any)[folderName || disciplineName];
    }
    return 'menuContexto'; 
  }

  return (
    <>
      <TabsShell 
        ConstructionSiteId={props.match.params.constructionSiteId}
        active={PathActiveEnum.plotagens}
        loading={isLoading()}
        match={props.match}
      />
      <PageHeader name={folderName || `${disciplineName || ''}`} iconLeft={iconLeft()} >
        <BreadCrumb breadcrumbs={breadCrumb} />
        <div className={`${isLoading() ? styles['tableLoading'] : styles['tableContainer']} ${props.isCollapsed ? styles['tableCollapsed'] : ''}`}>
          <TableActionTopbar
            buttons={isLoading() ? [] : getButtonsTableToolbar(
              selectedArquivos, 
              props.ArquivoResponse.data, 
              dispatch, 
              props.match.params.ConstructionSiteDisciplineFk,
              props.match.params.constructionSiteId, 
              exportRelatorioPlotagem, 
              props.isDownloading,
              folderName,
              props.userInfo,
              currentTenant,
            )}
          />
          {isLoading() ? (
            <LoadingArchivesPlotagens multiple={11} />
          ) : (
            <>
              <Table className={styles['tableListaArquivos']}
                footer={currentPageData => {
                  currentArquivos = currentPageData as any;
                  return null;
                }}
                columns={columnsTable(props.ArquivoResponse.data, props.filters, props.ArquivoResponse.info,
                  dispatch, props.ArquivoPrint)}
                dataSource={props.ArquivoResponse?.data?.map((discipline: any, index: number) =>
                  ({ ...discipline, key: index }))}
                rowSelection={rowSelection}></Table>
              <PlotagemRelatorioArquivos obraName={obraName} 
              disciplineName={disciplineName} arquivosPrint={arquivosPrint}></PlotagemRelatorioArquivos>
            </>
          )}
        </div>

        {!props.showModalReenviaSolicitacaoPlotagem ? (
          ''
        ) : (
            <ReenvioSolicitacao
              visible={props.showModalReenviaSolicitacaoPlotagem}
              onCancel={() => dispatch(PlotagemActions.setShowModalReenviaSolicitacaoPlotagem(false))}
              constructionSiteId={props.match.params.constructionSiteId}
              props={props}
            />
        )}

        <ExcluiArquivos      
          visible={props.showModalExcluiArquivosPlotagem}
          onCancel={() => dispatch(PlotagemActions.setShowModalExcluiArquivosPlotagem(false))}
          listaArquivos={props.ArquivoResponse.data}
          posicaoSelecionados={selectedArquivos}
          constructionSiteId={props.match.params.constructionSiteId}
          constructionSiteDisciplineFk={props.match.params.ConstructionSiteDisciplineFk}
        >        
        </ExcluiArquivos>
      </PageHeader>
    </>
  )
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.plotagem,
  isCollapsed: state.obra.isCollapsed,
  userInfo: state.auth.userInfo,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPlotagemArquivos;

export default connector(PlotagemArquivos);