import * as Yup from 'yup';

const msgMinMax = 'Deve conter valor entre 1 e 28';
const positiveNumber = 'Deve ser positivo, zero ou nulo';

const schema = Yup.object().shape({
  maxQtyConstructionSites: Yup.number().moreThan(-1, positiveNumber).notRequired().nullable(),
  maxQtyUploadFiles: Yup.number().moreThan(-1, positiveNumber).notRequired().nullable(),
  price: Yup.number().notRequired().nullable(),
  billingDay: Yup.number().min(1, msgMinMax).max(28, msgMinMax).notRequired().nullable(),
  contractPrice: Yup.number().notRequired().nullable(),
})


export default schema;