import { Row } from "antd";
import styled, { css } from "styled-components";

export const InternalDatePicker = styled.div`
  width: 100%;
  height: auto;
  
  .ant-picker-body {
    .ant-picker-content {

      th {
        text-transform: lowercase;
        font-size: 10px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
      }

      .ant-picker-calendar-date-value {
        font-size: 12px;
        font-weight: 400;
      }
    
      .ant-picker-cell-today {
        .ant-picker-calendar-date-today {
          &::before {
            border: 1px solid ${({ theme }) => theme.colors.primary.primary} !important;
          }
        }
      }
    
      .ant-picker-cell-selected {
        .ant-picker-calendar-date-value {
          background-color: ${({ theme }) => theme.colors.primary.primary};
          border-radius: 4px;
        }
      }
    }
  }
`;

export const RowHeader = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TextDate = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.surface.onSurface};

  .month {
    text-transform: capitalize;
  }
`;

interface IDateComponentWrapper {
  iscurrentmonth?: string;
  isselected?: string;
  isrange?: string;
}

export const DateComponentWrapper = styled.div<IDateComponentWrapper>`
  .ant-picker-cell-inner {
    color: ${({ theme }) => theme.colors.surface.onSurface} !important;
    background: transparent !important;
  }

  ${({ iscurrentmonth, theme }) => iscurrentmonth !== 'true' && css`
    .ant-picker-cell-inner {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }
  `}

  ${({ isrange, theme }) => isrange === 'true' && css`
    .ant-picker-cell-inner {
      color: ${theme.colors.surface.onSurface} !important;
      background: ${`color-mix(in srgb, ${theme.colors.primary.primaryPressed}, ${theme.colors.surface.surface})`} !important;
    }
  `}
  
  ${({ isselected, theme }) => isselected === 'true' && css`
    .ant-picker-cell-inner {
      color: ${theme.colors.primary.onPrimary} !important;
      background: ${theme.colors.primary.primary} !important;
    }
  `}
`;
