import { FC } from 'react';
import styles from './BoxPlanChange.module.scss';
import PlanInfo from '../PlanInfo';
import { numberToMoney } from 'Utils/Money';
import PlanContainer from 'Components/UI/Containers/PlanContainer/PlanContainer';
import logoMaleta from '../../../../Static/images/logo-maleta-collapse.png';
import tagPopular from '../../../../Static/images/tag-popular.png';
import Button from 'Components/UI/Button';
import { history } from 'Store';
import { useDispatch, useSelector } from 'react-redux';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { IStorePlanResponse } from 'Data/interfaces/Store/IStorePlanResponse';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { Mixpanel } from 'Utils/MixPanel';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface IBoxPlanChange {
  plan: IStorePlanResponse;
  currentPlanId?: number;
  currentPlanOrder?: number;
  userInfo?: IUserInforResponse;
}

const BoxPlanChange: FC<IBoxPlanChange> = ({
  plan,
  currentPlanId,
  currentPlanOrder,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const planIsFree = useSelector((state: IGlobalReducerState) => state.auth.planFree);
    
  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string') 
    ? whatsappContactLinkFlag.value 
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const advantages = plan?.Info.split('\n');

  const upgradePlan = (plano: IStorePlanResponse | undefined) => {
    if(plano) {
      dispatch(RevenuesActions.getPlanForChange({
        ...plan
      }));
      history.push('/faturamento/planos/dadosfaturamento');
    }
  };

  return (
    <div className={styles['tag-wrapper']}>
      <PlanContainer
        width="259px"
        heigth="671px"
        style={{ margin: '25px 0 25px 15px', padding: '0' }}
      >
        <div className={styles['description-box-plan']}>
          {plan.IsDefault ? (
            <div className={styles['tag-popularity']}>
              <img src={tagPopular} alt="Tag Popularidade" />
            </div>
          ) : null}
          <div className={styles['logo-description']}>
            <img src={logoMaleta} alt="logo da maleta" />
            <span>{plan.Name}</span>
          </div>
          <span>{plan.Description}</span>
        </div>

        <div className={styles['content']}/>

        <div className={styles['info-box-plan']}>
          {advantages?.map((advn: string, index: number) => (
            <PlanInfo key={index} advantage={advn} />
          ))}
        </div>

        <div className={styles['content']}/>

        <div className={styles['footer-wrapper']}>      
          <div className={styles['price-box-plan']}>
            <div>
              {plan.Price === 0 ? (
                plan.isCustom === 1 ?
                <> 
                <div className={styles['price-custom']}>
                  {'Preço Customizado. Entre em contato para mais informações'}
                </div>
                </>
                : <span className={styles['price-text']}>Grátis</span>
              ) : (
                <div  className={styles['price-box']}>
                  <span className={styles['price-text']}>{numberToMoney(plan.Price, 'real')}</span>
                  <span className={styles['price-text-last']}>/mês</span>
                </div>
              )}
            </div>
          </div>
            
          
          <div className={styles['button-box-plan']}>
            {((currentPlanId && plan.StorePlansId) && (currentPlanId === plan.StorePlansId)) || 
            (planIsFree && (plan.Price === 0 || !plan.Price) && plan.isCustom === 0) ? (
              <Button 
                className={styles['button-plan']}
                disabled
              >
                Plano Atual
              </Button>
            ) : ((currentPlanOrder && plan.Order) && (plan.Order < currentPlanOrder)) ? (
                <Button 
                  className={styles['button-plan']}
                  onClick={() => upgradePlan({
                    ...plan
                  })}
                >
                  Downgrade
                </Button>
              ) : (plan.isCustom === 1) ? (
                <Button 
                  className={styles['button-custom-plan']}
                  type="text"
                >
                  <a 
                    href={whatsappContactLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => Mixpanel.track({
                      name: 'ENTERPRISE_CALL_CONTACT', 
                      props: {}, 
                      userInfo,
                      currentListTenant: currentTenant,
                    })
                    }
                  >
                    Entrar em contato
                  </a>
                </Button>
              ) : (
                <Button 
                  className={styles['button-plan']}
                  type="primary"
                  onClick={() => upgradePlan({
                    ...plan
                  })}
                >
                  Selecionar Plano
                </Button>
              )}
          </div>
        </div>   
      </PlanContainer>
    </div>
  )
}

export default BoxPlanChange;
