import { FC, useEffect, useRef, useState } from 'react';

interface EmbedOptions {
  link: string;
  file?: {
    zoom: 'best' | 'fit';
  };
  folder?: {
    view: 'list' | 'grid';
    headerSize: 'normal' | 'small';
  };
}

interface IDropboxFolderEmbed {
  link: string;
}

const DropboxFolderEmbed: FC<IDropboxFolderEmbed> = ({link}) => {
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const embed = useRef<any>(null);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  
  useEffect(() => {
    const scriptExist = document.head.querySelector('#dropboxjs');

    if (!scriptExist) {
      const script = document.createElement("script");
      script.src = "https://www.dropbox.com/static/api/2/dropins.js";
      script.id = "dropboxjs";
      script.onload = () => {
        setScriptLoaded(true);
      };
      script.setAttribute("data-app-key", process.env.REACT_APP_DROPBOX_API_KEY as string);
      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);
  
  useEffect(() => {
    if (!embedContainerRef.current || !scriptLoaded) return;

    const options: EmbedOptions = {
      link,
      file: {
        zoom: "best"
      },
      folder: {
        view: "list",
        headerSize: "normal"
      }
    };

    if (!embed.current) {
      embedContainerRef.current.style.height = 'calc(100vh - 136px)'
      embed.current = Dropbox.embed(options, embedContainerRef.current);
    }

    return () => {
      if (Dropbox && embed.current) {
        Dropbox.unmount(embed.current);
        embed.current = null;
      }
    }
  }, [link, scriptLoaded]);

  return <div ref={embedContainerRef}></div>;
};

export default DropboxFolderEmbed;
