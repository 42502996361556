import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UsuariosConvidados.module.scss';
import { Icon } from '../../../../Components/UI';
import Button from '../../../../Components/UI/Button';
import Select from '../../../../Components/UI/Select';
import Form from '../../../../Components/UI/Form/Form';
import Modal from '../../../../Components/UI/Modal/Modal';
import Field from '../../../../Components/UI/Field';
import schema from './UsuariosConvidar.schema';
import toastHandler from '../../../../Utils/toastHandler';
import { REPEATED_EMAILS_ERROR } from '../../../../Utils/Messages';
import { ITenantRole } from '../../../../Data/interfaces/Tenant/ITenantRole';
import { ISharedUser } from '../../../../Data/interfaces/User/ISharedUser';
import { IInvitedUser } from '../../../../Data/interfaces/User/IInvitedUser';
import {
  ITenantSharedUsers,
  ITenantInvitedUsers
} from '../../../../Data/interfaces/Tenant/ITenantUsersWithConstruction';
import { TenantActions } from '../../../../Store/Tenant/Tenant.actions';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { history } from 'Store';
import { ADMIN } from 'Utils/AcessPermission';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface IUsuariosConvidar {
  visible: boolean;
  changeUsers: (changedUser: IUpdatedUsers) => void;
  onCancel?: () => void;
  tenantRoles: ITenantRole[];
  isLoading: boolean;
  tenantUsers: ITenantSharedUsers[];
  tenantInvitedUsers: ITenantInvitedUsers[];
  userIsFreemium?: boolean;
  limitUsers?: number;
  userInfo?: IUserInforResponse;
  planFree?: boolean;
}
interface IUpdatedUsers {
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}

const UsuariosConvidar: FC<IUsuariosConvidar> = ({
  tenantUsers,
  tenantInvitedUsers,
  tenantRoles,
  visible,
  changeUsers,
  onCancel,
  isLoading,
  userIsFreemium, 
  limitUsers,
  userInfo,
  planFree,
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);
  const currentTenant = useSelector(getCurrentTenant);

  const [NewInvitedUsers, setNewInvitedUsers] = React.useState<IInvitedUser[]>([]);
  const [newNameEmail, setNewNameEmail] = React.useState<string>('');
  const [RoleNewUser, setRoleNewUser] = React.useState<any>(4);
  const [isSendingData, setSendingData] = React.useState<boolean>(false);
  const [isOneUserToSend, setIsOneUserToSend] = React.useState<boolean>(true);
  const [disableInviteUsers, setDisableInviteUsers] = React.useState<boolean>(false);

  const hasRepeatedEmail = (email: string) => {
    return (
      tenantInvitedUsers.some(
        (user) => user?.InvitedUserEmail.toLowerCase() === email.toLowerCase()
      ) ||
      tenantUsers.some(
        (sharedUser) => sharedUser?.User?.Email?.toLowerCase() === email.toLowerCase()
      ) ||
      NewInvitedUsers.some((user) => user.Email.toLowerCase() === email.toLowerCase())
    );
  };
  const getSelectedSharedUsers = () => {
    return NewInvitedUsers.filter((sharedUser) => sharedUser);
  };

  const updateUsers = () => {
    changeUsers({
      InvitedUsers: getSelectedSharedUsers(),
      SharedUsers: getSelectedSharedUsers()
    });
  };

  const addInvitedUser = (data: any) => {
    if (hasRepeatedEmail(data.Email)) {
      toastHandler.showError(REPEATED_EMAILS_ERROR);
    } else {
      const newUser: IInvitedUser = {
        Email: data.Email.toLowerCase(),
        RoleFk: RoleNewUser,
        Privileges: [0, 1, 2],
        HasPlottingPermission: true
      };
      NewInvitedUsers.push(newUser);
      setNewInvitedUsers(NewInvitedUsers);
      updateUsers();
      setNewNameEmail('');
    }
  };

  const removeInvitedUser = (index: number) => {
    NewInvitedUsers.splice(index, 1);
    setNewInvitedUsers(NewInvitedUsers);
    updateUsers();
  };

  const roles = tenantRoles.map((role) => ({
    value: role.TenantRoleId,
    description: role.RoleName,
    label: role.RoleName
  }));

  const userOnFirstRow: IInvitedUser = {
    Email: newNameEmail,
    RoleFk: RoleNewUser,
    Privileges: [0, 1, 2],
    HasPlottingPermission: true
  };

  const sendInviteToUsers = () => {
    const filterTenantUsers = tenantUsers.filter(user => user.User.Id !== userInfo?.Id);
    const isFirstUser = tenantInvitedUsers.length <= 0 && filterTenantUsers.length <= 0 && (NewInvitedUsers.length > 0 || !!userOnFirstRow);
    
    if (NewInvitedUsers.length === 0 && newNameEmail !== '') {
      if (hasRepeatedEmail(newNameEmail)) {
        toastHandler.showError(REPEATED_EMAILS_ERROR);
      } else {
        NewInvitedUsers.push(userOnFirstRow);
        setNewInvitedUsers(NewInvitedUsers);
        updateUsers();
        dispatch(TenantActions.tenantBindInviedUsersRequest({
          Users: [userOnFirstRow],
          firstUserTracking: isFirstUser,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        }));
        setSendingData(true);
      }
    } else {
      if (hasRepeatedEmail(newNameEmail)) {
        toastHandler.showError(REPEATED_EMAILS_ERROR);
      } else if (NewInvitedUsers?.length > 0 && newNameEmail !== '') {
        NewInvitedUsers.push(userOnFirstRow);
        setNewNameEmail('');
        setNewInvitedUsers(NewInvitedUsers);
        dispatch(TenantActions.tenantBindInviedUsersRequest({
          Users: NewInvitedUsers,
          firstUserTracking: isFirstUser,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        }));
        setSendingData(true);
      }else if (NewInvitedUsers?.length > 0 && newNameEmail === '') {
        setNewInvitedUsers(NewInvitedUsers);
        dispatch(TenantActions.tenantBindInviedUsersRequest({
          Users: NewInvitedUsers,
          firstUserTracking: isFirstUser,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        }));
        setSendingData(true);
      }
    }
  };
  
  useEffect(() => {
    if (limitUsers) {
      const totalUsersOnConstructionNow = tenantInvitedUsers?.length + tenantUsers?.length;
      let MaxToAddUsersOnConstruction = limitUsers - totalUsersOnConstructionNow;
      if (NewInvitedUsers?.length >= MaxToAddUsersOnConstruction) {
        setDisableInviteUsers(true);
      } else {
        setDisableInviteUsers(false);
      }
    }
  }, [updateUsers]);

  if (isSendingData && !isLoading) {
    setSendingData(false);
    if (onCancel) {
      setNewNameEmail('');
      setNewInvitedUsers([]);
      onCancel();
    }
  }
  const closeModalResetForm = () => {
    if (onCancel) {
      setNewNameEmail('');
      setNewInvitedUsers([]);
      userOnFirstRow.Email = '';
      onCancel();
    }
    setNewNameEmail('');
    setNewInvitedUsers([]);
  };

  const redirectUsersPermissions = () => {
    if (planFree) {
      Mixpanel.track({
        name: 'CALL_T0_ACTION', 
        props: {
          origin: 'convidar-usuarios-modal',
          originPath: window.location.pathname
        },
        userInfo,
        currentListTenant: currentTenant,
      });

      if (userInfo?.CurrentRoleFk === ADMIN) {
        return history.push('/faturamento/planos');
      }

      return window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    } 

    return history.push('/gerenciar-usuarios/papeis');
  };

  const buttons = (
    <div>
      <Button
        loading={isLoading}
        className={styles['button']}
        type="primary"
        height={37}
        onClick={sendInviteToUsers}
      >
        Enviar convite
      </Button>
    </div>
  );

  return (
    <Modal
      footerButtons={buttons}
      title={'Enviar convites para os seus usuários'}
      onCancel={closeModalResetForm}
      visible={visible}
      width={798}
    >
      <div className={styles['usuariosConvidarWrapper']}>
        <Form schema={schema} onSubmit={addInvitedUser}>
          <div className={styles['newUsersWrapper']}>
            <Field
              disabled={disableInviteUsers ? true : false}
              name="Email"
              label="Email usuário"
              defaultValue={newNameEmail}
              placeholder="usuario@dominio.com.br"
              className={styles['inputEmail']}
              onInput={setNewNameEmail}
            />
            <Select
              disabled={disableInviteUsers ? true : false}
              onSelect={setRoleNewUser}
              className={styles['selectRole']}
              placeholder="Selecione um papel"
              defaultValue={RoleNewUser}
              options={roles}
            />
            <Button
              disabled={disableInviteUsers ? true : false}
              htmlType="submit"
              className={styles['buttonNewUser']}
              onClick={() => setIsOneUserToSend(false)}
            >
              {disableInviteUsers ? (
                <Icon
                  color="cinzaOpaco" 
                  className={styles['iconeNewUserDisaled']} 
                  icon="adicionar"
                />
              ) : (
                <Icon 
                  className={styles['iconeNewUser']} 
                  color="primary"
                  icon="adicionar" 
                />
              )}
            </Button>
          </div>
        </Form>
        <div>
          <div>
            {isOneUserToSend ? (
              <></>
            ) : (
              NewInvitedUsers.map((user, index) => (
                <Form schema={schema} onSubmit={() => {}} resetForm={false}>
                  <div className={styles['newUsersWrapper']}>
                    <Field
                      name="Email"
                      label="Email usuário"
                      value={user.Email}
                      placeholder="usuario@dominio.com.br"
                      className={styles['inputEmail']}
                    />
                    <Select
                      onSelect={setRoleNewUser}
                      className={styles['selectRole']}
                      placeholder="Selecione um papel"
                      defaultValue={user.RoleFk}
                      options={roles}
                    />
                    <Button
                      htmlType="submit"
                      onClick={() => removeInvitedUser(index)}
                      className={styles['buttonNewUser']}
                    >
                      <Icon 
                        color="cinzaSuperior" 
                        icon="cancelar" 
                      />
                    </Button>
                  </div>
                </Form>
              ))
            )}
          </div>
          <div className={styles['permissionsHintWrapper']}>
            <span>
              Precisa de ajuda com os papéis? 
              <span 
                style={{cursor: 'pointer', color: theme.colors.primary.primary}} 
                onClick={redirectUsersPermissions}>
                {' Clique aqui'}
              </span> para ver as permissões de cada um
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UsuariosConvidar;
