import React, { FC } from 'react';
import styles from './FloatingFeedbackItem.module.scss';
import { Icon } from '../../../../Icon';

export interface IFloatingFeedbackItem {
  hasError: boolean;
  description: string;
}

const FloatingFeedbackItem: FC<IFloatingFeedbackItem> = ({ hasError, description }) => {
  return (
    <div
      className={`
      ${styles['FloatingFeedbackItem']}
      ${hasError ? styles['isError'] : ''}`}
    >
      <Icon
        icon={hasError ? 'errorSenha' : 'checkedSenha'}
        customColor={hasError ? '#E2574C' : '#09B66D'}
        customSize={9}
        className={styles['icon']}
      />
      <p>{description}</p>
    </div>
  );
};

export default FloatingFeedbackItem;
