import { FC, useEffect, useRef, useState } from "react";
import DatePickerInline from "Components/UI/DatePickerInline";
import { 
  FilterOperationMap, 
  IFiltroOptions,
} from "Data/interfaces/Activities/IFilters";
import Input from "Components/UI/Input";
import { Icon } from "Components/UI";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Container, HeaderContent, InputContainer } from "./styles";

interface IFilterDatePicker {
  operation: keyof FilterOperationMap;
  item?: IFiltroOptions<ITopicDnDResponse>;
  handleValueUpdateFilter: (value: any) => void;
}

const FilterDatePicker: FC<IFilterDatePicker> = ({
  operation,
  item,
  handleValueUpdateFilter,
}) => {
  const theme = useSelector(getTheme);

  const firstMultipleChange = useRef(0);
  const [value1, setValue1] = useState('');
  const [focus1, setFocus1] = useState(false);
  const [value2, setValue2] = useState('');
  const [focus2, setFocus2] = useState(false);

  const isSingle = operation !== 'between';

  const focusInput1 = () => {
    setFocus1(true);
    setFocus2(false);
  }

  const focusInput2 = () => {
    setFocus2(true);
    setFocus1(false);
  }

  const clearInput1 = () => {
    setValue1('');
  }

  const clearInput2 = () => {
    setValue2('');
  }

  const clearAllFocus = () => {
    setFocus1(false);
    setFocus2(false);
  }

  const handleDatePickerValue = (value: string) => {
    const dateValue = dayjs(value);

    if (isSingle) {
      if (focus1) {
        setValue1(value);
        setFocus2(false);
      }

    } else {
      if (focus1) {
        if (!dateValue.isAfter(dayjs(value2))) {
          setValue1(value);
          if (operation === 'between') {
            setFocus1(false);
            setFocus2(true);
          } else {
            setFocus2(false);
          }
        }
      }

      if (focus2) {
        if (!dateValue.isBefore(dayjs(value1))) {
          setValue2(value);
          if (operation === 'between') {
            setFocus1(true);
            setFocus2(false);
          } else {
            setFocus1(true);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (item?.value) {
      focusInput1();

      if (Array.isArray(item.value)) {
        setValue1(item.value[0]);
        setValue2(item.value[1]);

      } else {
        setValue1(item.value);
      }

    } else {
      const now = dayjs().format('YYYY-MM-DD');
      setValue1(now);
      focusInput1();
    }
  }, []);

  useEffect(() => {
    if (operation === 'between') {
      if (!item?.value && firstMultipleChange.current === 0) {
        firstMultipleChange.current++;

        const now = dayjs().format('YYYY-MM-DD');
        setValue1(value1 || now);
        focusInput1();
        clearInput2();
      }
      
      const values = [value1, value2].filter(val => val);
      handleValueUpdateFilter(values);

    } else {
      if (operation === 'empty' || operation === 'notEmpty') {
        clearInput1();
        clearInput2();
        clearAllFocus();
        handleValueUpdateFilter(null);

      } else {
        handleValueUpdateFilter(value1);
      }

      firstMultipleChange.current = 0;
    }
  }, [value1, value2, operation]);

  return (
    <Container>
      <HeaderContent>
        <InputContainer issingle={`${isSingle}`} isfocused={`${focus1}`}>
          <Input
            label=""
            placeholder={isSingle ? 'Ex: 12...' : 'Começando'}
            value={value1}
            inputClassName="inputDefault"
            isFocused={focusInput1}
          />
          <Icon
            icon="error"
            customSize={16}
            customColor={theme.colors.surface.onSurfacePlaceholder}
            className="clearInput"
            onClick={clearInput1}
          />
        </InputContainer>
        {!isSingle && (
          <InputContainer issingle={`${isSingle}`} isfocused={`${focus2}`}>
            <Input
              label=""
              placeholder="Terminando"
              value={value2}
              inputClassName="inputDefault"
              isFocused={focusInput2}
            />
            <Icon
              icon="error"
              customSize={16}
              customColor={theme.colors.surface.onSurfacePlaceholder}
              className="clearInput"
              onClick={clearInput2}
            />
          </InputContainer>
        )}
      </HeaderContent>

      <DatePickerInline 
        isDoubleDate={!isSingle}
        firstDate={dayjs(value1)}
        firstDateSelected={focus1}
        secondDate={dayjs(value2)}
        secondDateSelected={focus2}
        onSelect={handleDatePickerValue} 
      />
    </Container>
  )
}

export default FilterDatePicker;
