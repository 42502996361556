import React, { FC } from 'react';
import { Alert as AlertAnt } from 'antd';
import { AlertProps } from 'antd/es/alert';
import styles from './WarningAlert.module.scss';
import { Icon } from '../Icon';

interface IAlert extends AlertProps {}

const WarningAlert: FC<IAlert> = (props) => {
  const icon = (
    <Icon 
      icon="aviso"
      customSize={15}
      color="amarelo"
      className={styles['icon-warning']} 
    />
  );

  const message = (
    <div className={styles['title']}>
      {props.message}
    </div>
  );

  const description = (
    <div className={styles['description']}>
      {props.description}
    </div>
  );

  return (
    <div style={{ backgroundColor: 'white' }}>
      <AlertAnt 
        {...props} 
        icon={icon} 
        message={message} 
        description={description}
        style={props.type === 'warning' ? {
          marginBottom: '15px',
          padding: '10px 42px 10px 46px',
          height: '72px',
          background: 'rgba(253, 191, 94, 0.05)',
          border: '1px solid rgba(253, 191, 94, 0.5)',
          borderRadius: '5px'
        } : {}}
      >
        {props.children}
      </AlertAnt>
    </div>
  )
}

export default WarningAlert;
