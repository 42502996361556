import * as Yup from 'yup';

const schema = Yup.object().shape({
  subdomain: Yup.string()
    .trim()
    .matches(
      /^([a-zA-Z0-9-]{1,63}\.)?[a-zA-Z0-9-]{1,63}$/,
      'Sub-domínio inválido.'
    )
    .required('Sub-domínio inválido.'),
  
  domain: Yup.string()
    .trim()
    .matches(
      /^(?![0-9]+$)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
      'Insira um domínio válido'
    )
    .required('Insira um domínio válido'),
});

export default schema;
