import { FC, useEffect, useState } from 'react';
import { Icon } from '../../../../../Components/UI/Icon';
import Field from '../../../../../Components/UI/Field';
import Form from '../../../../../Components/UI/Form/Form';
import schema from './cadastroTab.schema';
import UploadAvatar from '../../../../../Components/UI/UploadAvatar';
import { IObraResponse } from '../../../../../Data/interfaces/Obra/IObraListResponse';
import { REPEATED_CONSTRUCTION_NAME_ERROR } from '../../../../../Utils/Messages';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CadastroWrapper } from './styles';

interface ICadastroTab {
  isSubmited?: boolean;
  onSubmit: (values?: any, errors?: string[]) => void;
  constructionSiteId?: number;
  name?: string;
  type?: string;
  address?: string;
  avatarFileName?: string;
  listObras?: IObraResponse[];
}

const CadastroTab: FC<ICadastroTab> = ({
  isSubmited,
  onSubmit,
  name,
  type,
  address,
  avatarFileName,
  listObras,
  constructionSiteId,
}) => {
  const [imageConstruction, setImageConstruction] = useState(avatarFileName);

  useEffect(() => {
    setImageConstruction(avatarFileName);
  }, [name, avatarFileName]);

  const onUploadImageConstruction = (uploadedImage: string) => {
    setImageConstruction(uploadedImage);
  };

  const hasConstructionSameName = (nameConstruction: string) => {
    const IdConstructionSameName = listObras?.find((obra) => obra.Name === nameConstruction)?.ConstructionSiteId;
    return IdConstructionSameName && IdConstructionSameName !== constructionSiteId;
  };

  const submit = (values: any, errors: any) => {
    if (values) {
      if (hasConstructionSameName(values.Name)) {
        errors = [REPEATED_CONSTRUCTION_NAME_ERROR];
      }
      values.AvatarFileName = imageConstruction;
    }
    onSubmit(values, errors);
  };

  return (
    <CadastroWrapper img={`${process.env.REACT_APP_IMAGE_STORAGE_URL}${imageConstruction}`}>
      <UploadAvatar onUploadImage={onUploadImageConstruction}>
        {(isLoading: boolean) => {
          return (
            <div className="changeImageWrapper">
              {isLoading
                ? <Spin indicator={<LoadingOutlined rev="" color='white' style={{ fontSize: 40, color: 'white' }} />} />
                : (
                  <>
                    <Icon color='branco' customSize={19} icon='editarImg' />
                    <span className="changeImageLabel" >Alterar imagem</span>
                  </>
                )}
            </div>
          );
        }}
      </UploadAvatar>

      <div className="formWrapper">
        <Form schema={schema} onSubmit={submit} isSubmited={isSubmited}
          initialValues={{
            Name: name,
            Type: type,
            Address: address,
          }}>
          <Field label='Nome da Obra' name='Name' value={name} className="fieldCadastro" />
          <Field label='Tipo' name='Type' className="fieldCadastro" />
          <Field label='Endereço da Obra' name='Address' className="fieldCadastro" />
        </Form>
      </div>
    </CadastroWrapper>
  );
};

export default CadastroTab;
