import { IUserData } from "../User/IUserData";

export interface IHistoryFileApiResponse {
  Action: HistoryFileActionEnum;
  ItemId: string;
  ItemName: string;
  From: IHistoryFileDetail;
  To: IHistoryFileDetail;
  DateTimeUtc: Date;
  Author: IUserData;
}

export interface IHistoryFileDetail {
  Value: string;
  DisplayValue: string;
}

export enum HistoryFileActionEnum {
  StatusChanged = 0,
  FileDownloaded = 1
}

export interface IHistoryFileRequestList {
  title: string;
  parentName: string;
  itemId: string;
  isFolder: boolean;
}

export interface IHistoryFileResponseList {
  title: string;
  parentName: string;
  histories: IHistoryFileApiResponse[];
}
