import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IObraResponse } from './interfaces/Obra/IObraListResponse';
import { IArchivedStatusRequest } from './interfaces/Obra/IArchivedStatusRequest';
import { IObraData, IDisciplineData } from './interfaces/Obra/IObraData';
import { IListFilesRequest } from './interfaces/ListagemArquivos/IListFilesRequest';
import {
  IListFilesResponse,
  IFileData
} from './interfaces/ListagemArquivos/IListFilesResponse';
import { IUsersConstructionSiteResponse } from './interfaces/User/IUsersConstructionSiteResponse';
import IBindUsersConstructionSiteRequest from './interfaces/User/IBindUsersConstructionSiteRequest';
import {
  IDownloadFilesRequest,
  IProgressDownloading
} from './interfaces/Obra/IDownloadFilesRequest';
import { IUpdateFileInfoRequest } from './interfaces/Obra/IUpdateFileInfoRequest';
import { IFileRenameData } from './interfaces/ListagemArquivos/IFileRenameRequest';
import { IDisciplineRequest } from './interfaces/Obra/IDisciplineRequest';
import { IAccountInfo } from './interfaces/Obra/IAccountInfo';
import { IUpdateConstructionSiteApiRequest } from './interfaces/Obra/IUpdateConstructionSiteApiRequest';
import { ICreateFolderRequest } from './interfaces/Obra/ICreateFolderRequest';
import { IDeleteFilesRequest } from './interfaces/Obra/IDeleteFilesRequest';
import { IMoveFileRequest } from './interfaces/ListagemArquivos/IMoveFileRequest';
import { IStorageData } from './interfaces/Obra/IStorageData';
import { ListagemArquivosActions } from '../Store/ListagemArquivos/ListagemArquivos.actions';
import { store } from '../Store';
import { IUnarchiveFilesRequest } from './interfaces/Obra/IUnarchiveFilesRequest';
import { IBindConstructionSitesToUser } from './interfaces/Obra/IBindConstructionSitesToUser';
import { IBindConstructionSitesToInvitedUser } from './interfaces/Obra/IBindConstructionSitesToInvitedUser';
import { IDisciplineRequestDelete } from './interfaces/Obra/IDisciplineRequestDelete';
import { IUpdateDisciplineStatusRequest } from './interfaces/Obra/IUpdateDisciplineStatusRequest';
import {
  ICreateSADisciplineRequest,
  IDeleteSADisciplineRequest,
  IMergeSADisciplineRequest,
  IRestoreSADisciplineRequest,
} from './interfaces/Obra/ISolutionSADisciplineRequest';
import { IDisciplineSAStatusRequest } from './interfaces/Obra/IDisciplineSAStatusRequest';
import { IHistoryFileApiResponse } from './interfaces/History/IHistoryFileApi';

export class ObrasApi {
  static async list(): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/Obras/List');
  }

  static async create(obra: IObraData): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post('/Obras/Create', obra);
  }

  static async update(obra: IObraData): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.put(`/Obras/Update/${obra.ConstructionSiteId}`, obra);
  }

  static async updateArchivedStatus(
    status: IArchivedStatusRequest
  ): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.put(`/Obras/UpdateArchivedStatus/${status.ConstructionSiteId}`, status);
  }

  static async getConstructionSite(
    request: IUpdateDisciplineStatusRequest
  ): Promise<AxiosResponse<IObraResponse>> {
    const axios = await Http.axios();
    if (request.ignoreCache) {
      return axios.get(
        `/Obras/Get/${request.csId}?ignoreCacheDisciplines=${request.ignoreCache}`
      );
    }
    return axios.get(`/Obras/Get/${request.csId}?ignoreCacheDisciplines=false`);
  }

  static async getDisciplines(): Promise<AxiosResponse<IDisciplineData[]>> {
    const axios = await Http.axios();
    return axios.get('/Obras/GetDisciplines');
  }

  static async listGoogleDriveFiles(
    data: IListFilesRequest
  ): Promise<AxiosResponse<IListFilesResponse>> {
    const axios = await Http.axios();
    const query = Http.objectToQueryString(data);
    return axios.get(`/GoogleDrive/ListFiles?${query}`);
  }

  static async listFiles(
    data: IListFilesRequest
  ): Promise<AxiosResponse<IListFilesResponse>> {
    const axios = await Http.axios();
    const query = Http.objectToQueryString(data);
    return axios.get(`/Obras/ListFiles?${query}`);
  }

  static async verifyFileRenderProgress(
    fileApiId: string,
    constructionSiteId: number
  ): Promise<AxiosResponse<IListFilesResponse>> {
    const axios = await Http.axios(false);
    const query = Http.objectToQueryString({ fileApiId, constructionSiteId });
    return axios.get(`/Viewer/VerifyProgressAutodesk?${query}`);
  }

  static async getUserByConstructionSite(
    constructionSiteId: number
  ): Promise<AxiosResponse<IUsersConstructionSiteResponse>> {
    const axios = await Http.axios();
    return axios.get(`/Obras/GetUsersByConstructionSite/${constructionSiteId}`);
  }

  static async bindUsersToConstructionSite(
    users: IBindUsersConstructionSiteRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(
      `/Obras/BindUsersToConstructionSite/${users.ConstructionSiteId}`,
      users
    );
  }

  static async downloadFiles(
    request: IDownloadFilesRequest
  ): Promise<AxiosResponse<File>> {
    const axios = await Http.axiosToDownloadUploadFile();
    return new Promise((res, rej) => {
      axios
        .post('/api/Download', request, {
          responseType: 'arraybuffer',
          onDownloadProgress: (progressEvent) => {
            let percentCompleted: IProgressDownloading = {
              loaded: progressEvent.loaded,
              total: progressEvent.total
            };
            store.dispatch(ListagemArquivosActions.progressDownload(percentCompleted));
          }
        })
        .then((response) => {
          const returnCOntentDisposition = decodeURIComponent(
            response.headers['content-disposition']!
              .split('=')[1]
              .replace(/"/g, '')
              .replace(/\+/g, ' ')
          );
          const filename = returnCOntentDisposition.split(';')[0];
          const blob = new Blob([response.data], {
            type: response.headers['content-type']
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  static async deleteFiles(request: IDeleteFilesRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios(false);
    return axios.delete('/Obras/DeleteFiles', { data: request });
  }

  static async renameFile(
    request: IFileRenameData
  ): Promise<AxiosResponse<IFileRenameData>> {
    const axios = await Http.axios();
    return axios.post('/Obras/FileRename', request);
  }

  static async updateFileInfo(
    request: IUpdateFileInfoRequest[]
  ): Promise<AxiosResponse<IUpdateFileInfoRequest[]>> {
    const axios = await Http.axios();
    return axios.post('/Obras/UpdateFileInfo', request);
  }

  static async createOrUpdateDiscipline(
    discipline: IDisciplineRequest
  ): Promise<AxiosResponse<IDisciplineData>> {
    const axios = await Http.axios();
    if (discipline.Id) {
      return axios.put(`/Obras/UpdateDiscipline/${discipline.Id}`, discipline);
    }
    return axios.post('/Obras/CreateDiscipline', discipline);
  }

  static async listConstructionSiteAccountsInfo(): Promise<AxiosResponse<IAccountInfo>> {
    const axios = await Http.axios();
    return axios.get('/Obras/ListConstructionSiteAccountsInfo');
  }

  static async updateConstructionSiteApi(
    request: IUpdateConstructionSiteApiRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(`/Obras/UpdateConstructionSiteApi/${request.CsId}`, request);
  }

  static async createFolder(
    request: ICreateFolderRequest
  ): Promise<AxiosResponse<IFileData>> {
    const axios = await Http.axios(false);
    return axios.post('/Obras/CreateFolder', request);
  }

  static async moveFile(
    data: IMoveFileRequest
  ): Promise<AxiosResponse<IMoveFileRequest>> {
    const axios = await Http.axios(false);
    return axios.post('/Obras/MoveFile', data);
  }

  static async listConstructionSitesStorage(): Promise<AxiosResponse<IStorageData>> {
    const axios = await Http.axios();
    return axios.get('/Obras/ListConstructionSitesStorage');
  }

  static async unarchiveFiles(
    data: IUnarchiveFilesRequest
  ): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post('/Obras/RestoreObsoleteFiles', data);
  }

  static async bindConstructionSitesToUser(
    data: IBindConstructionSitesToUser
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(
      `/Obras/BindConstructionSitesToUser/${data.UserId}`,
      data.BindConstructionSites
    );
  }

  static async bindConstructionSitesToInvitedUser(
    data: IBindConstructionSitesToInvitedUser
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(
      `/Obras/BindConstructionSitesToInvitedUser/${data.InviteId}`,
      data.BindConstructionSites
    );
  }

  static async deleteDiscipline(
    request: IDisciplineRequestDelete
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete('/Obras/DeleteConstructionSiteDiscipline', { data: request });
  }

  static async statusSADiscipline(
    request: IDisciplineSAStatusRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get(`/Obras/GetCsDisciplineFolderStatusFromStorage?fileApiId=${request.fileApiId}&csId=${request.csId}`);
  }
  // A IMPLEMENTAR
  static async restoreSADiscipline(
    request: IRestoreSADisciplineRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(`/Obras/RestoreCsDisciplineFromStorage/${request.ConstructionSiteDisciplinesId}`);
  }

  static async createSADiscipline(
    request: ICreateSADisciplineRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(`/Obras/CreateFolderToCsDiscipline/${request.ConstructionSiteDisciplinesId}${request.existingFolderApiId ? `?existingFolderApiId=${request.existingFolderApiId}` : ''}`);
  }

  static async deleteSADiscipline(
    request: IDeleteSADisciplineRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete(`/Obras/DeleteDiscipline/${request.ConstructionSiteDisciplinesId}${request.existingFolderApiId ? `?existingFolderApiId=${request.existingFolderApiId}` : ''}`);
  }

  static async mergeSADiscipline(
    request: IMergeSADisciplineRequest
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/Obras/MergeCsDisciplineWithFolder?csDisciplineId=${request.ConstructionSiteDisciplinesId}${request.existingFolderApiId ? `&existingFolderApiId=${request.existingFolderApiId}` : ''}`);
  }

  static async fetchAllConstructionsOfUser(): Promise<AxiosResponse<IObraData[]>> {
    const axios = await Http.axios();
    return axios.get('/Obras/ListConstructionSitesByUserId');
  }

  static async listHistoryFile(itemId: string): Promise<AxiosResponse<IHistoryFileApiResponse[]>> {
    const axios = await Http.axios();
    return axios.get(`/obras/file-history/${itemId}`);
  }

  static async listHistoryFolder(itemId: string): Promise<AxiosResponse<IHistoryFileApiResponse[]>> {
    const axios = await Http.axios();
    return axios.get(`/obras/folder-history/${itemId}`);
  }
}
