import { call, put } from 'redux-saga/effects';
import { NomenclaturaActions, CreateAction, SetExtensionsAction } from './Nomenclatura.actions';
import { NomenclaturaApi } from '../../Data/Nomenclatura.api';
import toastHandler from '../../Utils/toastHandler';
import { loadError, updateSuccess } from '../../Utils/Messages';

export function* create({ payload }: CreateAction) {
  try {
    const { data } = yield call(NomenclaturaApi.create, payload);
    yield put(NomenclaturaActions.createSuccess(data.data));
    if (!payload.IsTabSubmit) {
      toastHandler.showSuccess(updateSuccess('nomenclatura'));
    }
  } catch (e: any) {
    const error = e.error ? e.error[0].Message : 'Erro ao salvar nomenclatura';
    toastHandler.showError(error);
    yield put(NomenclaturaActions.createFailure(error));
  }
}

export function* getNomenclature() {
  try {
    const { data } = yield call(NomenclaturaApi.getNomenclature);
    yield put(NomenclaturaActions.getNomenclatureSuccess(data.data));
  } catch (e: any) {
    const error = e.error ? e.error[0].Message : loadError('nomenclatura');
    yield put(NomenclaturaActions.getNomenclatureFailure(error));
    toastHandler.showError(error);
  }
}

export function* getCampoVersao() {
  try {
    const { data } = yield call(NomenclaturaApi.getCampoVersao);
    yield put(NomenclaturaActions.getCampoVersaoSuccess(data.data));
  } catch (e: any) {
    const error = e.error ? e.error[0].Message : loadError('campos versão');
    yield put(NomenclaturaActions.getCampoVersaoFailure(error));
    toastHandler.showError(error);
  }
}

export function* setExtensions({ payload }: SetExtensionsAction) {
  try {
    const { data } = yield call(NomenclaturaApi.setExtensions, payload);
    yield put(NomenclaturaActions.setExtensionsSuccess(data.data));
    toastHandler.showSuccess('Extensões atualizadas com sucesso!');
  } catch (e: any) {
    const error = e.error ? e.error[0].Message : 'Erro ao salvar extensões';
    yield put(NomenclaturaActions.setExtensionseFailure(error));
    toastHandler.showError(error);
  }
}

export function* getExtensions() {
  try {
    const { data } = yield call(NomenclaturaApi.getExtensions);
    yield put(NomenclaturaActions.getExtensionsSuccess(data.data));
  } catch (e: any) {
    const error = e.error ? e.error[0].Message : loadError('extensões');
    yield put(NomenclaturaActions.getExtensionsFailure(error));
    toastHandler.showError(error);
  }
}
