import React, { FC } from 'react';

import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import './infoTooltip.css';

const InfoTooltip: FC<TooltipProps> = (props) => {
  return (
    <Tooltip {...props} placement={props.placement ?? 'bottomLeft'}>
      {props.children}
    </Tooltip>
  );
};

export default InfoTooltip;
