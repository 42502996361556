import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px 32px;

  .description {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    color: #44566C;

  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cancel {
    cursor: pointer;
    min-width: 224px;
    height: 37px;
    border: none;

    &:hover {
      border: none;
    }

    .cancelText {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      color: #324150;
    }
  }

  .confirm {
    cursor: pointer;
    width: 123px;
    height: 37px;
    border-radius: 5px;
    margin-right: 18px;

    .confirmText {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      color: #FFFFFF;
    }
  }
`;
