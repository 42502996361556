import React, { FC } from 'react';
import { history } from '../../../../../Store/index';
import styles from './UpgradePremium.module.scss';
import Modal from '../../../../../Components/UI/Modal/Modal';
import Button from '../../../../../Components/UI/Button';
import UpgradePremiumLogo from '../../../../../Static/images/logo-upgrade-premium.png';
import { IGlobalReducerState } from '../../../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { MinhaContaActions } from '../../../../../Store/MinhaConta/MinhaConta.actions';

interface IModal {
  visible?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
}

const UpgradePremium: FC<Props> = ({ userData, visible, isLoading, onCancel }) => {
  const dispatch = useDispatch();

  const [isSubmited, setIsSubmited] = React.useState(false);

  const handleValidateUserInfo = () => {
    
    if (userData.CompanyPosition?.Type === 0) {
      history.push(`/conta?show=${0}`);
    }else{
      history.push(`/conta?show=${1}`);
    }

    if (onCancel) {
      onCancel();
    }
     
  };

  const confirmUpdatePremium = () => {
    if (userData) {
      setIsSubmited(true);
      let userToUpdatePremium = {
        userId: userData.Id
      };
      dispatch(MinhaContaActions.notifyUserConversion(userToUpdatePremium));
      dispatch(MinhaContaActions.setShowModalUpdatePremium(false));
      dispatch(MinhaContaActions.setShowModalConfirmPremium(true));
    }
  };

  const buttons = (
    <div>
      <Button height={37} onClick={() => handleValidateUserInfo()}>
        <span style={{ padding: '0 20px' }}>Dados Incorretos</span>
      </Button>
      <Button
        type="primary"
        height={37}
        onClick={() => confirmUpdatePremium()}
        loading={isLoading}
      >
        <span style={{ padding: '0 18px' }}>Meus dados estão corretos</span>
      </Button>
    </div>
  );
  return (
    <Modal footerButtons={buttons} visible={visible} width={798} onCancel={onCancel}>
      <div className={styles['boxDetalhesConta']}>
        <div className={styles['DetalhesContaLeft']}>
          <img src={UpgradePremiumLogo} className={styles['ImgUpgrade']} />
        </div>
        <div className={styles['DetalhesContaRight']}>
          {userData.CompanyPosition?.Type === 0 ? (
            <>
              <h2>Confirme se seu contato está correto</h2>
              <div className={styles['boxData']}>
                <label className={styles['fieldToConfirmLabel']}>
                  <text>Telefone particular</text>
                </label>
                <p className={styles['fieldToConfirm']}>
                  <text>{userData?.Telefone}</text>
                </p>
              </div>
            </>
          ) : (
            <>
              <h2>Confirme suas informações abaixo</h2>
              <div className={styles['boxData']}>
                <label className={styles['fieldToConfirmLabel']}>
                  <text>Nome da empresa</text>
                </label>
                <p className={styles['fieldToConfirm']}>
                  <text>{userData?.Company?.Name}</text>
                </p>
                <div style={{display:"inline-flex"}}>
                  <div style={{marginRight: "10px"}}>
                    <label className={styles['fieldToConfirmLabel']}>
                      <text>Telefone comercial</text>
                    </label>
                    <p  className={styles['fieldToConfirmDuple']}>
                      <text>{userData?.Company?.Phone}</text>
                    </p>
                  </div>
                  <div>
                    <label className={styles['fieldToConfirmLabel']}>
                      <text>Telefone particular</text>
                    </label>
                    <p  className={styles['fieldToConfirmDuple']}>
                      <text>{userData?.Telefone}</text>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
  userData: state.minhaConta.userInfo
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IModal;

export default connector(UpgradePremium);
