import React, { FC, useEffect } from 'react'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';

import logoCrowFree from '../../Static/images/logo-crow-free.png';
import logoPending from '../../Static/images/logo-requisition-pending.png';

import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import styles from './Faturamento.module.scss';
import { AdmActions } from 'Store/Adm/Adm.actions';
import BoxAviso from './Components/BoxAviso';
import BarraProgresso from './Components/BarraProgresso';
import BoxPlan from './Components/BoxPlan';
import Button from 'Components/UI/Button';
import FaturamentoForm from './Components/FaturamentoForm';
import { Skeleton } from 'antd';
import { Icon as IconUI } from '../../Components/UI/Icon';
import PlanContainer from 'Components/UI/Containers/PlanContainer/PlanContainer';
import { billingDate } from 'Utils/DateUtils';
import { history } from "Store";
import { AuthActions } from 'Store/Auth/Auth.actions';
import { PaymentMethodEnum } from 'Data/interfaces/Store/IUserStoreItem';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';
import CancelUpdatePlan from './Modals/CancelUpdatePlan';
import { BarcodeOutlined, CreditCardOutlined } from '@ant-design/icons';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Faturamento',
    href: '/faturamento',
  }
];

const Faturamento: FC<Props> = ({ Planos, UserInfo, isLoading, downgradeRequestLoading, planFree }) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);
  const currentTenant = useSelector(getCurrentTenant);
  
  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string') 
    ? whatsappContactLinkFlag.value 
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState<number | undefined>();
  const [bestPlan, setBestPlan] = React.useState<number>();
  const [showCancelUpdatePlanModal, setShowCancelUpdatePlanModal] = React.useState(false);
  const [downOrUp, setDownOrUp] = React.useState(0);

  useEffect(() => {
    if (UserInfo?.UserStoreItem.PaymentMethod) {
      setCurrentPaymentMethod(UserInfo?.UserStoreItem.PaymentMethod);
    }
  }, [UserInfo]);

  useEffect(() => {
    dispatch(AdmActions.listPlans());
  }, []);

  useEffect(() => {
    if (Planos) {
      const orderPlans = Planos?.map((plan) => plan.Order);
      const bestPlan = Math.max(...orderPlans);
      setBestPlan(bestPlan);
    }
  }, [Planos]);

  useEffect(() => {
    dispatch(AuthActions.getUserInfo());
  }, [downgradeRequestLoading]);

  const headerActions = (
    <div className={styles['box-header-button']}>
      <a 
        href={whatsappContactLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button
          type="primary"
          className={styles['header-button']}
        >
          <IconUI 
            icon="menuAjuda" 
            color="branco" 
            className={styles['header-button-icon']}
          />
          <span>Entre em contato com o financeiro</span>
        </Button>
      </a>
    </div>
  );

  const boxAvisoButton = (
    <Button ispremium="true" onClick={() => history.push('/faturamento/planos')}>
      <span>
        <IconUI icon="crown" color="cinzaEscuro" size="xxs" />
      </span>
      <span>
        Fazer upgrade
      </span>
    </Button>
  );

  const buttonPlanControl = (
    (UserInfo?.UserStoreItem.StorePlan?.Order && bestPlan) && (UserInfo?.UserStoreItem.StorePlan?.Order >= bestPlan) ? (
      <Button onClick={() => history.push('/faturamento/planos')}>
        Downgrade
      </Button>
    ) : (
      <Button type='primary' onClick={() => history.push('/faturamento/planos')}>
        Alterar Plano
      </Button>
    )
  );

  const buttonPlanCancel = (
    (UserInfo?.UserStoreItem.UpdateStorePlan?.Order && UserInfo?.UserStoreItem.StorePlan?.Order) && 
    (UserInfo?.UserStoreItem.UpdateStorePlan?.Order >= UserInfo?.UserStoreItem.StorePlan?.Order) ? (
      <Button type="primary" danger onClick={() => askCancelUpdatePlan(1)}>
        Cancelar upgrade
      </Button>
    ) : (
      <Button onClick={() => askCancelUpdatePlan(2)}>
        Cancelar downgrade
      </Button>
    )
  );

  const confirmCancelUpdatePlan = () => {
    setShowCancelUpdatePlanModal(false);
    dispatch(RevenuesActions.cancelUpdatePlan({ 
      userInfoTracking: UserInfo,
      currentTenantTracking: currentTenant,
    }));
  };

  const askCancelUpdatePlan = (downOrUp: number) => {
    setShowCancelUpdatePlanModal(true);
    setDownOrUp(downOrUp);
  }

  return (
    <>
      <PageHeader 
        name="Faturamento" 
        actions={headerActions}
        customAction
      />
      <BreadCrumb breadcrumbs={breadCrumb} />
      <div className={styles['Grid']}>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className={styles['grid-left']}>
              {(planFree && !UserInfo?.UserStoreItem.UpdateStorePlan) && <BoxAviso
                img={{
                  arquivo: logoCrowFree,
                  alt: 'logo upgrade',
                  width: '76px',
                  height: '76px'
                }}
                button={boxAvisoButton}
              >
                  <h2>Dê um <span>upgrade</span> na sua conta gratuita</h2>
                  <p>e faça uso de todas as funcionalidades premium da Coordly!</p>
              </BoxAviso>}

              {UserInfo?.UserStoreItem.UpdateStorePlan && <BoxAviso
                img={{
                  arquivo: logoPending,
                  alt: 'logo upgrade',
                  width: '50px',
                  height: '50px'
                }}
              >
                  <h2>Seu pedido está quase pronto</h2>
                  <p>Estamos processando seu pedido de upgrade e logo entraremos em contato.</p>
              </BoxAviso>}

              <div className={styles['grid-progress-bar']}>
                <h4 className={styles['title-progress-bar']}>Uso do Plano</h4>
                
                <BarraProgresso 
                  titleProgress="Obras"
                  description="obras criadas"
                  value={UserInfo?.QtyActiveConstructionSites}
                  maxLimits={UserInfo?.UserStoreItem.MaxQtyConstructionSites || UserInfo?.UserStoreItem.StorePlan?.MaxQtyConstructionSites}
                />
                <BarraProgresso 
                  titleProgress="Usuários"
                  description="usuários cadastrados"
                  value={UserInfo?.QtyActiveUsers}
                  maxLimits={UserInfo?.UserStoreItem.MaxQtyUsers || UserInfo?.UserStoreItem.StorePlan?.MaxQtyUsers}
                />
                <BarraProgresso 
                  titleProgress="Renderizações de arquivos 3D"
                  description="arquivos 3D renderizados"
                  value={UserInfo?.QtyRenderedFiles}
                  maxLimits={UserInfo?.UserStoreItem.MaxQtyRenderedFiles || UserInfo?.UserStoreItem.StorePlan?.MaxQtyRenderedFiles}
                />
              </div>

              {(!planFree || UserInfo?.UserStoreItem.UpdateStorePlan) && (
                <div className={styles['grid-form']}>
                  <FaturamentoForm 
                    title="Dados de Faturamento"
                  />
                </div>  
              )}
            </div>

            <div className={styles['grid-right']}>
              
              {(!UserInfo?.UserStoreItem.UpdateStorePlan && UserInfo?.UserStoreItem.StorePlan) &&
                <BoxPlan 
                  title="Detalhes do Plano"
                  plan={UserInfo?.UserStoreItem.StorePlan}
                  buttonPlanControl={buttonPlanControl}
                  firstPrice={UserInfo.UserStoreItem.Price}  
                />
              }

              {UserInfo?.UserStoreItem.UpdateStorePlan &&
                <BoxPlan 
                  title="Detalhes do Plano"
                  plan={UserInfo?.UserStoreItem.UpdateStorePlan}
                  buttonPlanCancel={buttonPlanCancel}    
                />
              }

              {!planFree &&
                <PlanContainer width="100%" heigth="100%" style={{ marginTop: '15px' }}>
                  <div className={styles['divider-top']}>
                    <h4 className={styles['title']}>Método de pagamento</h4>
                  </div>
                  <div className={styles['ticket']}>
                    {(currentPaymentMethod && (currentPaymentMethod === 2)) 
                      ? <CreditCardOutlined rev="" style={{ color: '#BEC2C6'}} />
                      : <BarcodeOutlined rev="" style={{ color: '#BEC2C6'}} />
                    }
                    <span className={styles['text-ticket']}>
                      <strong>
                        {currentPaymentMethod ? PaymentMethodEnum[currentPaymentMethod] : 'Não informado'}
                      </strong>
                    </span>
                  </div>
                  <div className={styles['divider-middle']}>
                    <p className={styles['text']}>
                      {'O boleto será enviado para o email '} 
                      <strong>
                        {UserInfo?.BillingData ? UserInfo.BillingData.Email : (UserInfo?.Email ? UserInfo.Email : UserInfo?.EmailAlternativo)}
                      </strong> 
                      {' na data próxima do faturamento.'}
                    </p>
                    <p className={styles['text']}>{'Próximo faturamento em '} 
                      <strong>
                        {(UserInfo?.UserStoreItem.BillingDay) 
                          ? `${billingDate(UserInfo?.UserStoreItem.BillingDay)}` : 'Não Informado'
                        }
                      </strong>.
                    </p>
                  </div>
                  <p className={`${styles['text']} ${styles['text-link']}`}>Para alterar o metodo de pagamento,  
                    <a 
                      href={whatsappContactLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: theme.colors.primary.primary, textDecoration: 'none' }}
                    >
                        {' entre em contato'}
                    </a> 
                  </p>
                </PlanContainer>
              }
            </div>

            {showCancelUpdatePlanModal && 
            <CancelUpdatePlan
              visible={showCancelUpdatePlanModal}
              onClose={() => setShowCancelUpdatePlanModal(false)}
              downOrUp={downOrUp}
              confirmCancelUpdate={confirmCancelUpdatePlan}
              isLoading={downgradeRequestLoading}
            />}
          </>
        )}
      </div>
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  Planos: state.adm.Planos,
  UserInfo: state.auth.userInfo,
  isLoading: state.auth.isLoading,
  planFree: state.auth.planFree,
  downgradeRequestLoading: state.revenues.downgradeRequestLoading
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux;

export default connector(Faturamento);
