import React, { FC } from "react";
import SkeletonMain from "../LoadingMain";
import { Container } from "./styles";

export interface ILoadingActivities {
  multiple?: number;
  isCollapsed?: boolean;
  height: number;
}

const LoadingActivities: FC<ILoadingActivities> = ({ multiple = 1, isCollapsed, height }) => {
  const Card = () => {
    return (
      <div className='rowCard'>
        <SkeletonMain 
          width="100%" 
          height="96px"
          minWidth="16px" 
          borderRadius="2px"
        />
        <div className='rowCardHeader'>
          <div className='RowCardHeaderHead'>
            <SkeletonMain 
              width="32px" 
              height="14px" 
              borderRadius="2px"
            />
            <SkeletonMain 
              width="50px" 
              height="14px" 
              borderRadius="2px" 
              marginLeft="12px"
            />
          </div>
          <SkeletonMain 
            width="100%" 
            height="14px" 
            borderRadius="2px"
            marginTop="9px"
          />
          <SkeletonMain 
            width="50%" 
            height="14px" 
            borderRadius="2px"
            marginTop="5px"
          />
        </div>
        <div className='rowCircles'>
          <SkeletonMain 
            width="24px" 
            height="24px" 
            border="1px solid #FFFFFF"
            borderRadius="50%"
            zIndex={3}
            isCustom="skeleton-custom-dark2"
          />
          <SkeletonMain 
            width="24px" 
            height="24px" 
            border="1px solid #FFFFFF"
            borderRadius="50%"
            marginLeft="-6px"
            zIndex={2}
            isCustom="skeleton-custom-dark2"
          />
          <SkeletonMain 
            width="24px" 
            height="24px" 
            border="1px solid #FFFFFF"
            borderRadius="50%"
            marginLeft="-6px"
            zIndex={1}
            isCustom="skeleton-custom-dark2"
          />
        </div>
        <div className='rowCardFooter'>
          <div className='RowCardFooterHead'>
            <div className='RowCardFooterHeadLeft'>
              <SkeletonMain 
                width="16px" 
                height="16px"
                minWidth="16px" 
                borderRadius="2px"
              />
              <SkeletonMain 
                width="100%" 
                height="14px" 
                borderRadius="2px" 
                marginLeft="4px"
              />
            </div>
            <div className='RowCardFooterHeadRight'>
              <SkeletonMain 
                width="16px" 
                height="16px"
                minWidth="16px" 
                borderRadius="2px"
              />
              <SkeletonMain 
                width="100%" 
                height="14px" 
                borderRadius="2px" 
                marginLeft="4px"
              />
            </div>
          </div>
          <div className='RowCardFooterMid'>
            <SkeletonMain 
              width="16px" 
              height="16px"
              minWidth="16px" 
              borderRadius="2px"
            />
            <SkeletonMain 
              width="65px" 
              height="14px" 
              borderRadius="2px" 
              marginLeft="4px"
            />
          </div>
          <div className='RowCardFooterFoot'>
            <div className='RowCardFooterFootLeft'>
              <SkeletonMain 
                width="16px" 
                height="16px"
                minWidth="16px" 
                borderRadius="2px"
              />
              <SkeletonMain 
                width="43px" 
                height="14px" 
                borderRadius="2px" 
                marginLeft="4px"
              />
            </div>
            <div className='RowCardFooterFootRight'>
              <SkeletonMain 
                width="16px" 
                height="16px"
                minWidth="16px" 
                borderRadius="2px"
                marginLeft="12px"
              />
              <SkeletonMain 
                width="43px" 
                height="14px" 
                borderRadius="2px" 
                marginLeft="4px"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const SkeletonUsers = () => {
    return (
      <div className='rowWrapper'>
        <div className='rowHeader'>
          <div className='rowHeaderLeft'>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginLeft="12px"
              isCustom="skeleton-custom-dark1"
            />
            <SkeletonMain 
              width="24px" 
              height="16px"
              minWidth="24px" 
              borderRadius="9.5px" 
              marginLeft="8px" 
              isCustom="skeleton-custom-dark1"
            />
          </div>
          <SkeletonMain 
            width="16px" 
            height="16px" 
            borderRadius="2px" 
            isCustom="skeleton-custom-dark1"
          />
        </div>
        <div className='rowColumn'>
          <Card />
          <Card />
        </div>
      </div>
    );
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <Container height={height}>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} />
      ))}
    </Container>
  );
}

export default LoadingActivities;
