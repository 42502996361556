import React, { FC } from 'react';

import styles from './FileDrop.module.scss';
import {
  getDragInfo,
  getDragError
} from '../../../Pages/ListagemArquivos/ListagemArquivos.helpers';

export interface IFileDrop {
  dragInfo?: string;
  onDropFiles: (files: FileList) => void;
  maxQtyUploadFiles?: number;
  limitUpload?: number;
}

const FileDrop: FC<IFileDrop> = ({ 
  children, 
  dragInfo, 
  onDropFiles,
  maxQtyUploadFiles,
  limitUpload,
}) => {
  const [displayZone, setDisplayZone] = React.useState(false);
  const [dragDropInfo, setDragDropInfo] = React.useState(getDragInfo(dragInfo));
  const onDragOver = (ev: any) => {
    ev.preventDefault();
  };

  const onDragEnter = (ev: any) => {
    if (ev.dataTransfer.types.includes('Files')) {
      setDisplayZone(true);
    }
    if(maxQtyUploadFiles && (ev.dataTransfer.items.length > maxQtyUploadFiles)){
      setDragDropInfo(getDragError(limitUpload))
    } else {
      setDragDropInfo(getDragInfo(dragInfo))
    }
  };

  const onDragLeave = (ev: any) => {
    setDisplayZone(false);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();
    setDisplayZone(false);
    onDropFiles(ev.dataTransfer.files);

  };

  return (
    <div
      className={styles['fileDropWrap']}
      onDragEnter={onDragEnter}>
      {children}
      <div
        className={styles['dragInfoWrap']}
        hidden={!displayZone}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}>
        {dragDropInfo}
      </div>
    </div>
  );
};

export default FileDrop;
