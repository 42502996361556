import React, { FC } from "react";

import styles from "./LoadingUsersPermissions.module.scss";

import SkeletonMain from "../LoadingMain";

export interface ILoadingUsersPermissions {
  multiple?: number;
}

const LoadingUsersPermissions: FC<ILoadingUsersPermissions> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (
        <div key={index.toString()} className={styles['main']}>
            <div className={styles['header']}>
              <SkeletonMain width="32px" height="32px" borderRadius="50%" />
              <SkeletonMain width="112px" height="14px" borderRadius="2px" marginLeft="25px" />
            </div>
            <div className={styles['divider']}>
              <div className={styles['middle']}>
                <SkeletonMain width="68px" height="12px" borderRadius="2px" />
                <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="58px"/>
              </div>
              <div className={styles['footer']}>
                <SkeletonMain width="132px" height="12px" borderRadius="2px" />
              </div>
            </div>
        </div>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
    </>
  )
}

export default LoadingUsersPermissions;