import { FC } from "react"
import styles from './NoResults.module.scss'
import { Icon } from "Components/UI/Icon"

interface INoResults {
  search?: string;
}

export const NoResults: FC<INoResults> = ({search}) => {
    return <div className={styles['Container']}>
        <div className={styles['text-container']}>
            <div className={styles['icon-container']}>
                <Icon icon='buscar' customSize={24}  color="cinzaSuperior" />
            </div>
            <p className={styles['title']}>{`Nenhuma obra encontrada por “${search}”.`}</p>
            <p className={styles['description']}>A obra pesquisada não foi encontrada ou não existe. Verifique sua pesquisa e tente novamente.</p>
        </div>
    </div>
}
