import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 14px;
  min-width: 180px;
  width: 100%;

  .input {
    margin-left: 12px;
    border: none;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    outline: none;
    appearance: none;

    &::placeholder {
      color: #BEC2C6;
    }
  }
`;
