import { FC, useCallback, useMemo } from 'react';
import { Button } from 'antd';
import Tooltip from '../../../../Components/UI/Tooltip';
import { GetAvatar } from '../../../../Utils/generateThumbnail';
import styles from './ObrasItem.module.scss';

export interface IObrasItem {
  userIsAdmin?: boolean;
  currentPeperUser?: number;
  openUsuariosEPermissoes: (userToEdit: any) => void | undefined;
  currentUser?: any;
  disabled?: boolean;
}

const ObrasItem: FC<IObrasItem> = ({
  userIsAdmin,
  openUsuariosEPermissoes,
  currentUser,
  currentPeperUser,
  disabled
}) => {
  const filteredWorks = useMemo(() => {
    return currentUser?.ConstructionSites?.filter(
      (obra: any) => obra.IsShared === true
    )
  }, [currentUser]);
  
  const displayWorks = useCallback(() => {
    if (filteredWorks && filteredWorks.length > 5) {
      const visibleUsers = filteredWorks.slice(0, 4);
      
      const works = filteredWorks.slice(4);
      
      const usersList = (
        <div className={styles['listsharedObra']}>
          {works.map((work: any, index: number) => (
            <label key={`worksLabe${index}`}>{work.ConstructionSiteName}</label>
          ))}
        </div>
      );

      return (
        <>
          {visibleUsers.map((sharedObra: any, index: any) => 
            <Tooltip
              title={sharedObra?.ConstructionSiteName}
              placement="bottom"
              key={`SharedUser${index}`}
            >
              <GetAvatar
                name={sharedObra?.ConstructionSiteName}
                shape='circle'
                size={28}
                src={sharedObra?.AvatarFileName}
              />
            </Tooltip>
          )}
        
          <Tooltip title={usersList} placement="bottom">
            <Button shape="circle" type="default" className={styles['buttomListUser']}>
              +{works.length}
            </Button>
          </Tooltip>
        </>
      )
    }

    return (
      filteredWorks.map((sharedObra: any, index: any) => 
        <Tooltip
          title={sharedObra?.ConstructionSiteName}
          placement="bottom"
          key={`sharedObra${index}`}
        >
          <GetAvatar
            name={sharedObra?.ConstructionSiteName}
            shape='circle'
            size={28}
            src={sharedObra?.AvatarFileName}
          />
        </Tooltip>
      )
    )
  }, [currentUser]);

  return (
    <div>
      <div data-testid="avatares-obra" className={styles['obraAvatar']}>
        {displayWorks()}
        <Tooltip title="Compartilhar Obra" placement="bottom">
          {userIsAdmin || currentPeperUser === 3 ? (
            <></>
          ) : (
            <Button
              shape="circle"
              className={styles['buttomUser']}
              onClick={() => openUsuariosEPermissoes(currentUser)}
              disabled={disabled}
              style={disabled ? { display: 'none' } : {}}
            >
              +
            </Button>
          )}
        </Tooltip>
      </div>
    </div>
  );
}

export default ObrasItem;
