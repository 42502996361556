import styled, { css } from "styled-components";

interface IMenuLogoStyled {
  iscollapsed?: string;
}

export const MenuLogoStyled = styled.div<IMenuLogoStyled>`
  display: flex;
  justify-content: space-between;
  height: 62px;
  align-items: center;
  background-color: ${({theme}) => theme.colors.surface.surface};
  user-select: none;
  padding-left: 12px;
  padding-right: 12px;
  
  img {
    width: 80px;
    height: 40px;
    cursor: pointer;

    ${({iscollapsed}) => iscollapsed === 'true' && css`
      padding-left: 2px;
    `}
  }
`;