import { FC, useState } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import styles from './ActionMenuItem.module.scss';
import { Icon } from '../../UI/Icon/Icon';
import AvisoArquivarObra from '../../../Pages/Obras/Modals/AvisoArquivarObra';
import { useDispatch } from 'react-redux';
import { ObraActions } from '../../../Store/Obra/Obra.actions';
import { IArchivedStatusRequest } from '../../../Data/interfaces/Obra/IArchivedStatusRequest';
import AccessPermission from 'Utils/AcessPermission';

interface IActionMenuItem {
  constructionSiteId: number;
  constructionSiteName?: string;
  activeObrasCount?: number;
  openInviteUser: () => void;
}

const ActionMenuItem: FC<IActionMenuItem> = ({
  constructionSiteId, 
  children, 
  openInviteUser,
  constructionSiteName,
  activeObrasCount,
}) => {
  const dispatch = useDispatch();
  const [arquivarObraVisible, setArquivarObraVisible] = useState(false);

  const arquivarObra = () => {
    const status: IArchivedStatusRequest = {
      ConstructionSiteId: constructionSiteId,
      isArchived: true,
    };
    dispatch(ObraActions.updateArchivedStatus(status));
    if (activeObrasCount && activeObrasCount === 1) {
      dispatch(ObraActions.clearConstructionSiteSearch(true));
    }
  };

  return (
    <div style={{width: '100%'}}>
      <ContextMenuTrigger 
        id={`ActionMenuObra${constructionSiteId}`}
        disable={!AccessPermission.isAdmin() && !AccessPermission.isCoordenador()}
      >
        {children}
      </ContextMenuTrigger>

      <ContextMenu  id={`ActionMenuObra${constructionSiteId}`} className={styles['ActionMenu']}>
        <MenuItem onClick={openInviteUser}><Icon icon='adicionarUsuario' customColor='#44566C' size='xs' /> Adicionar colaborador</MenuItem>
        <MenuItem divider className={styles['divider']} />
        <MenuItem onClick={() => setArquivarObraVisible(true)}>
          <Icon icon='arquivar' customColor='#44566C' size='xs' /> Arquivar
        </MenuItem>
      </ContextMenu>

      <AvisoArquivarObra
        nomeObra={constructionSiteName}
        visible={arquivarObraVisible}
        onCancel={() => setArquivarObraVisible(false)}
        onConfirm={arquivarObra}
      />
    </div>
  );
};

export default ActionMenuItem;
