import { HistoryActivityActionEnum } from "Data/interfaces/History/IHistoryActivityApi";
import { activitiesContentOptions } from "./ActivityOptions";
import { HistoryActivityActionDescriptionRecord, HistoryActivityIconRecord } from "Data/interfaces/History/IHistoryActivityRecord";

const contentActivityIcon: HistoryActivityIconRecord = {
  [HistoryActivityActionEnum.Created]: { icon: 'activities' },
  [HistoryActivityActionEnum.StatusChanged]: { icon: 'trocarStatus' },
  // [HistoryActivityActionEnum.edit]: { icon: 'editar' },
  // [HistoryActivityActionEnum.attachments]: { icon: 'paperclip', size: 18 },
}
const actionActivityDescription: HistoryActivityActionDescriptionRecord = {
  [HistoryActivityActionEnum.Created]: 'criou a atividade.',
  [HistoryActivityActionEnum.StatusChanged]: 'alterou o Status',
}

export {
  activitiesContentOptions,
  contentActivityIcon,
  actionActivityDescription,
};
