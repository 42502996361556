import styled from "styled-components";
import { css } from "styled-components";

export const TableWrapper = styled.div`
  margin-right: 42px;
  margin-left: 38px;
  margin-top: 15px;

  table {
    background-color: rgba(229, 229, 229, 0.3);
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    tr th:first-child {
      width: 85px;
    }
  }

  tbody {
    display: block;
    max-height: calc(100vh - 326px);
    overflow-y: auto;
    
    tr td:first-child {
      width: 85px;
    }
  }
`;

interface IDisciplineItem {
  error?: boolean;
}

export const DisciplineItem = styled.div<IDisciplineItem>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 51px;

  .disciplineItemText {
    color: #44566C;
    padding: 0 18px;
  }

  ${({error}) => error && css`
    cursor: default;
    
    .disciplineItemText {
      color: #E2574C;
      cursor: pointer;
    }
  `}
`;

export const BackgroundPlanFree = styled.div`
  background-image: url('../../../Static/images/fundo-plotagem.svg');
  background-repeat: no-repeat;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .plotagem-free-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const WithoutConstruction = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .title {
    width: 278px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin: auto;
    margin-top: 20px;
    color: #44566C;
    text-align: center;
    padding-top: 20px;
  }

  .buttonEmptyPlot {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 38px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
`;
