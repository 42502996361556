import React, { FC } from "react";
import { Progress } from "antd";
import styles from "./ProgressRenderBar.module.scss";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { IOpenFileAction } from "Pages/ListagemArquivos";
import { openFile } from "Pages/ListagemArquivos/ListagemArquivos.helpers";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { ITenantData } from "Data/interfaces/Tenant/ITenantData";

interface IProgressRenderBar {
  maxLimits?: number;
  value: number; 
  visible?: boolean;
  role?: number;
  onCancel: () => void;
  openFileAction?: IOpenFileAction;
  onConfirmRender?: () => void;
  inFederatedViewer?: boolean;
  userInfo?: IUserInforResponse;
  currentTenant?: ITenantData | null;
  disciplineName?: string | null;
}

const ProgressRenderBar: FC<IProgressRenderBar> = ({ 
  maxLimits, 
  value,
  visible,
  role,
  onCancel,
  openFileAction,
  onConfirmRender,
  inFederatedViewer,
  userInfo,
  currentTenant,
  disciplineName,
}) => {

  const percentOfConstructionsSites = (currentValue: number| undefined, currentLimit: number) => {
    if(currentValue) {
      const value = (currentValue / currentLimit) * 100;
      return Math.floor(value);
    }
  }

  const handleOpenFile = () => {
    if (openFileAction) {
      openFile(
        openFileAction?.csId,
        openFileAction?.ConstructionSiteDisciplinesId,
        openFileAction?.record,
        openFileAction?.constructionSiteName,
        openFileAction?.breadCrumbId,
        openFileAction?.dispatch,
        openFileAction?.constructionSiteApiId,
        openFileAction?.event,
        userInfo,
        currentTenant,
        disciplineName,
      )
      onCancel();
    }
    if (!openFileAction && onConfirmRender) {
      onConfirmRender();
      onCancel();
    }
  }

  const renderBarModalButtons = (
    <div className={styles['progress-render-footer-buttons']}>
      <Button
        onClick={onCancel}
        className={styles['progress-render-cancel-button']}
      >
        Cancelar
      </Button>
      <Button
        onClick={() => handleOpenFile()}
        type="primary"
        className={styles['progress-render-confirm-button']}
      >
        {inFederatedViewer ? 'Continuar' : 'Renderizar'}
      </Button>
    </div>
  );

  return (
    <Modal
      title="Renderizar Arquivos?"
      visible={visible}
      footerButtons={renderBarModalButtons}
      onCancel={onCancel}
      mask={false}
      wrapClassName="ant-modal-mask-render"
    >
      <div className={styles['wrapper']} >
        <div>
          <Progress
            type="circle"
            width={120}
            className={styles['progress-bar']} 
            percent={maxLimits
              ? percentOfConstructionsSites(value, maxLimits)
              : 100
            } 
            strokeWidth={7} 
            strokeColor={"#3547C8"} 
            format={() => (
              <div>
                <div className={styles['progress-circle-resources']}>
                  <span>{value}</span>/{maxLimits}
                </div>
                <div className={styles['progress-circle-text']}>
                  {'Arquivos renderizados'}
                </div>
              </div>
            )}
          />
        </div>

        <div className={styles['progress-text']}>
          <p>{`${role === 1 ? 'Você' : 'Sua empresa'} só pode renderizar mais ${maxLimits && (maxLimits - value)} arquivos!`}</p>
          <p>Você tem certeza que deseja renderizar esse arquivo?</p>
        </div>
      </div>
    </Modal>
  )
}

export default ProgressRenderBar;
