import WarningAlert from 'Components/UI/WarningAlert';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import React, { FC, useEffect } from 'react';
import BoxPlan from '../Components/BoxPlan';
import styles from './Downgrade.module.scss';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import ExitRedirect from '../Modals/ExitRedirect';
import Button from 'Components/UI/Button';
import BoxDownResources from '../Components/BoxDownResources';
import Table from 'Components/UI/Table';
import { getColumnsConstructionSitesInactivate, getColumnsTenantsInactivate } from './Downgrade.columns';
import { Skeleton } from 'antd';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { ITenantUsersResponse } from 'Data/interfaces/Tenant/ITenantUsersResponse';
import { history } from 'Store';
import useHistoryPush from 'Hooks/useHistoryPush';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Faturamento',
    href: '/faturamento',
  },
  {
    description: 'Alterar plano',
    href: '/faturamento/planos',
  },
  {
    description: 'Dados de faturamento',
    href: '/faturamento/planos/dadosfaturamento',
  },
  {
    description: 'Downgrade',
    href: '/faturamento/planos/dadosfaturamento/downgrade',
  },
];

const Downgrade: FC<Props> = ({
  showModalExitDowngrade,
  downgradeRequest,
  changePlan,
  userInfo,
  getDowngradeInfo,
  isLoading,
  downgradeRequestLoading
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const { historyPush } = useHistoryPush();

  const [disabled, setDisabled] = React.useState(true);
  const [selectedRowCS, setSelectedRowCS] = React.useState([] as IObraResponse[]);
  const [selectedRowTenant, setSelectedRowTenant] = React.useState([] as ITenantUsersResponse[]);
  const [maxLimitsCS, setMaxLimitsCS] = React.useState(0);
  const [maxLimitsTenants, setMaxLimitsTenants] = React.useState(0);
  const [requestSend, setRequestSend] = React.useState(false);

  useEffect(() => {
    dispatch(RevenuesActions.cancelPageDowgrade(true));
  }, []);

  useEffect(() => {
    if (requestSend && !downgradeRequestLoading) {
      dispatch(RevenuesActions.cancelPageDowgrade(false));
      history.push('/faturamento');
    }
  }, [requestSend, downgradeRequestLoading]);

  useEffect(() => {
    if (downgradeRequest.showWarning === 1 && 
      (selectedRowCS.length >= maxLimitsCS) &&
      (selectedRowTenant.length >= maxLimitsTenants) &&
      ((maxLimitsCS && maxLimitsTenants) !== 0)
    ) {
      return setDisabled(false);
    }
    if (downgradeRequest.showWarning === 2 && 
      (selectedRowCS.length >= maxLimitsCS) &&
      (maxLimitsCS !== 0)
    ) {
      return setDisabled(false);
    }
    if (downgradeRequest.showWarning === 3 &&
      (selectedRowTenant.length >= maxLimitsTenants) &&
      (maxLimitsTenants !== 0) 
    ) {
      return setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [downgradeRequest.showWarning, maxLimitsCS, maxLimitsTenants, selectedRowCS, selectedRowTenant]);
  
  useEffect(() => {
    if (userInfo?.QtyActiveConstructionSites && changePlan?.MaxQtyConstructionSites) {
      setMaxLimitsCS(userInfo?.QtyActiveConstructionSites - changePlan?.MaxQtyConstructionSites);
    }
    if (userInfo?.QtyActiveUsers && changePlan?.MaxQtyUsers) {
      setMaxLimitsTenants(userInfo?.QtyActiveUsers - changePlan?.MaxQtyUsers);
    }            
  }, [changePlan, userInfo]);

  useEffect(() => {
    if (userInfo?.UserStoreItem.StorePlan?.StorePlansId) {
      dispatch(RevenuesActions.getDowgradeInfo(userInfo?.UserStoreItem.StorePlan?.StorePlansId));
    }
  }, [userInfo, changePlan]);

  const onClose = () => {
    dispatch(RevenuesActions.showModalExitDowgrade(false));
  };

  const handlePlanChange = () => {
    if (changePlan && downgradeRequest.billingData) {
      dispatch(RevenuesActions.upgradeOrDowngradePlan({
        updateStorePlanId: changePlan?.StorePlansId,
        billingData: downgradeRequest.billingData,
        constructionSiteIdsToDisable: selectedRowCS.map((cs) => cs.ConstructionSiteId) ?? [],
        tenantUserIdsToDisable: selectedRowTenant.filter((tenant) => tenant.TenantInviteId === 0).map((tenant) => tenant.TenantUserId) ?? [],
        tenantUserInviteIdsToRemove: selectedRowTenant.filter((tenant) => tenant.TenantUserId === 0).map((tenant) => tenant.TenantInviteId) ?? [],
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      }));
      setRequestSend(true);
    }
  }

  const buttonPlanDowngrade = (
    <div>
      <Button
        type={disabled ? "dashed" : "primary"}
        disabled={disabled}
        width={133}
        className={disabled ? styles['confirm-button-box-plan'] : ''}
        onClick={handlePlanChange}
        loading={downgradeRequestLoading}
      >
        Confirmar
      </Button>
      <Button
        type="text"
        width={123}
        className={styles['cancel-button-box-plan']}
        onClick={() => historyPush('/faturamento')}
      >
        Cancelar
      </Button>
    </div>
  );
  const onChangeCS = (selectedRowKeys: any, selectedRows: any) => setSelectedRowCS(selectedRows);

  const onChangeTenant = (selectedRowKeys: any, selectedRows: any) => setSelectedRowTenant(selectedRows);

  const rowSelectionCS = {
    selectedRowCS,
    onChange: onChangeCS,
  };

  const rowSelectionTenant = {
    selectedRowTenant,
    onChange: onChangeTenant,
  };


  return (
    <>
      <PageHeader name="Complete o downgrade da sua conta" />
      <BreadCrumb breadcrumbs={breadCrumb} />

      <div className={styles['Grid']}>
        <div className={styles['grid-left']}>
          {downgradeRequest.showWarning === 1 && <WarningAlert
            message="Você precisa arquivar obras e inativar usuários"
            description="Como o plano escolhido tem uma capacidade de obras e usuários menor que a quantidade que você tem cadastrado, é necessário inativar as obras e inativar os usuários excedentes."
            type="warning"
            showIcon
            closable
          />}
          {downgradeRequest.showWarning === 2 && <WarningAlert
            message="Você precisa arquivar obras"
            description="Como o plano escolhido tem uma capacidade de obras menor que a quantidade de obras que você tem cadastrado, é necessário fazer o arquivamento de algumas obras."
            type="warning"
            showIcon
            closable
          />}
          {downgradeRequest.showWarning === 3 && <WarningAlert
            message="Você precisa inativar usuários"
            description="Como o plano escolhido tem uma capacidade de usuários menor que a quantidade de usuários que você tem cadastrado, é necessário inativar os usuários excedentes."
            type="warning"
            showIcon
            closable
          />}

          {(downgradeRequest.showWarning === 1 || 
          downgradeRequest.showWarning === 2) && 
          <>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <BoxDownResources 
                title="Arquive obras"
                maxLimits={maxLimitsCS}
                value={selectedRowCS.length}
                resourseName="obras"
                dowgradebox
              >
                <Table
                  showHeader={false}
                  isSpaced
                  columns={getColumnsConstructionSitesInactivate({})}
                  dataSource={getDowngradeInfo?.ConstructionSites?.map(
                    (constructionSite: any, index: number) => (
                      { ...constructionSite, key: index}
                    )
                  )}
                  rowSelection={rowSelectionCS}
                  size="middle"
                />
              </BoxDownResources>
            )}
          </>
          }

          {(downgradeRequest.showWarning === 1 || 
          downgradeRequest.showWarning === 3) &&
          <>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <BoxDownResources 
                title="Inative usuários"
                maxLimits={maxLimitsTenants}
                value={selectedRowTenant.length}
                resourseName="usuários"
                dowgradebox
              >
                <Table
                  showHeader={false}
                  isSpaced
                  columns={getColumnsTenantsInactivate({})}
                  dataSource={getDowngradeInfo?.TenantUsers?.map(
                    (tenants: any, index: number) => (
                      { ...tenants, key: index}
                    )
                  )}
                  rowSelection={rowSelectionTenant}
                  size="middle"
                />
              </BoxDownResources>
            )}
          </>
          }
        </div>

        <div className={styles['grid-right']}>
          {changePlan && <BoxPlan
            title="Plano escolhido"
            plan={changePlan}
            buttonPlanDowngrade={buttonPlanDowngrade}
          />}
        </div>

        {showModalExitDowngrade && (
          <ExitRedirect
            visible={showModalExitDowngrade}
            onClose={onClose}
          />
        )}
      </div>
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  showModalExitDowngrade: state.revenues.showModalExitDowngrade,
  downgradeRequest: state.revenues.showWarningDowngradePlan,
  changePlan: state.revenues.changePlan,
  userInfo: state.auth.userInfo,
  getDowngradeInfo: state.revenues.getDowngradeInfo,
  isLoading: state.revenues.isLoading,
  downgradeRequestLoading: state.revenues.downgradeRequestLoading
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux;

export default connector(Downgrade);
