import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContentRect } from 'react-measure';
import { ICategoryProperties } from 'Data/interfaces/FederatedViewer/IProperties';
import { ViewerApi } from 'Data/Viewer.api';
import { FederatedViewerActions } from 'Store/FederatedViewer/FederatedViewer.actions';
import { IModels } from 'Data/interfaces/FederatedViewer/IModels';
import { IPranchas } from 'Data/interfaces/FederatedViewer/IPranchas';
import { IHideNodesAction } from 'Data/interfaces/FederatedViewer/INodes';
import { IPersistOptionNode, IUseInternalViewer } from './internalViewer.model';
import {
  IModelsTree,
  ITreeObjectModel,
} from 'Data/interfaces/FederatedViewer/IModelsTree';
import {
  IFoldersBubleNodesCameras,
  IViewpoints,
} from 'Data/interfaces/FederatedViewer/IViewpoints';
import {
  IFoldersBubleNodesViews,
  IViews,
} from 'Data/interfaces/FederatedViewer/IViews';
import {
  extensionPranchas,
  extensionViewpoints,
  extensionViews2d3d,
} from 'Utils/ViewerUtils';

const useInternalViewer = ({
  viewerDiv,
  loadUrn,
  onLoadUrn,
  extensionValidateds,
  unloadUrn,
  models,
  views,
  viewpoints,
  pranchas,
  hideNodes,
  toggleNode,
  toggleSelectNode,
  toggleViewCamera,
  toggleView,
  onToggleNode,
  onToggleSelectNode,
  onToggleViewCamera,
  onTogglePrancha,
  onToggleView,
  togglePrancha,
  onClearAllToggles,
}: IUseInternalViewer) => {
  const dispatch = useDispatch();

  const viewer = useRef<Autodesk.Viewing.GuiViewer3D | null>(null);
  const init = useRef<boolean>(false);
  const resizeHandling = useRef<NodeJS.Timeout | null>(null);
  const accessToken = useRef('');
  const persistOptionNode = useRef<IPersistOptionNode | null>(null);
  const [urnInit, setUrnInit] = useState<string[]>([]);
  const [viewerOn, setViewerOn] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [finishModelLoading, setFinishModelLoading] = useState(false);
  const [drawerPropertiesVisible, setDrawerPropertiesVisible] = useState(false);

  const [options] = useState<Autodesk.Viewing.InitializerOptions>({
    env: 'AutodeskProduction',
    api: 'derivativeV2',
    accessToken: '',
    language: 'pt',
  })

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getTokenAndViewerInit();
    }

    return (() => {
      viewerFinishAndClosed();
    })
  }, []);

  useEffect(() => {
    if (viewerOn && loadUrn && !finishModelLoading && !unloadUrn) {
      if (!urnInit.includes(loadUrn)) {
        handleViewerLoadUrn(loadUrn);
      }
    }
  }, [loadUrn, viewerOn, finishModelLoading, urnInit]);

  useEffect(() => {
    if (unloadUrn && !finishModelLoading) {
      setFinishModelLoading(true);
      finishModels(unloadUrn);
    }
  }, [unloadUrn, finishModelLoading]);

  useEffect(() => {
    const handleLoadActiveModelsUrns = (
      viewer: Autodesk.Viewing.GuiViewer3D
    ) => {
      setModelLoading(true);
      const visibleModels = viewer.getVisibleModels();
      const getModelsInit = models.filter(model => !urnInit.includes(model.urn));

      if (
        !visibleModels
        || (!visibleModels.some(item => item.is2d())
          && (models.length === 1
            || (models.length > 1 && !models.some(item => item.model.is2D()))))
      ) {
        if (getModelsInit.length > 0) {
          getModelsInit.map(async (item) => {
            if (viewer && item.doc) {
              await viewer.loadDocumentNode(item.doc, item.model, {
                keepCurrentModels: true,
                globalOffset: { x: 0, y: 0, z: 0 },
              });
              setUrnInit(prevState => {
                const urnExist = prevState.find(prev => prev === item.urn);
                if (urnExist) {
                  return prevState;
                }
                return [...prevState, item.urn];
              });
            }
          });
        }

      } else {
        render3dModelsForFunctionRequired3d(viewer);
      }
    }

    if (viewer.current && viewerOn && models.length && !finishModelLoading && !unloadUrn && !modelLoading) {
      handleLoadActiveModelsUrns(viewer.current);
    }
  }, [models, viewerOn]);

  useEffect(() => {
    if (viewer.current && viewerOn) {
      viewer.current.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, getSelectChanged);
      viewer.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, getLoadedChanged);

      return () => {
        viewer.current?.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, getSelectChanged);
        viewer.current?.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, getLoadedChanged);
      }
    }
  }, [viewer.current, viewerOn, models, viewpoints]);

  useEffect(() => {
    if (viewer.current && viewerOn) {
      viewer.current.addEventListener(Autodesk.Viewing.HIDE_EVENT, getHideNodeChange);
      viewer.current.addEventListener(Autodesk.Viewing.SHOW_EVENT, getHideNodeChange);

      return () => {
        viewer.current?.removeEventListener(Autodesk.Viewing.HIDE_EVENT, getHideNodeChange);
        viewer.current?.removeEventListener(Autodesk.Viewing.SHOW_EVENT, getHideNodeChange);
      }
    }
  }, [viewer.current, viewerOn]);

  // HIDE/SHOW MODELO ÁRVORE POR TOGGLE EYE 
  useEffect(() => {
    const toggleNodeVisible = (
      viewer: Autodesk.Viewing.GuiViewer3D,
      node: number,
      urn: string,
    ) => {
      const visibleModels = viewer.getVisibleModels();
      if (visibleModels.some(model => model.is2d())) {
        render3dModelsForFunctionRequired3d(viewer);
        persistOptionNode.current = { option: node, type: 'hideShowNode', urn };

      } else {
        const currentModel = visibleModels.find(model => 'urn:'.concat(model.getData()?.urn) === urn);
        if (currentModel) {
          viewer.clearSelection();
          dispatch(FederatedViewerActions.setSelectedProperties(null));
          dispatch(FederatedViewerActions.setSelectNode(null));
          if (viewer.isNodeVisible(node, currentModel)) {
            viewer.hide(node, currentModel);
          } else {
            viewer.show(node, currentModel);
          }
        }
      }
    }

    if (viewer.current && viewerOn && toggleNode.node && toggleNode.urn) {
      toggleNodeVisible(viewer.current, toggleNode.node, toggleNode.urn);
    }
  }, [toggleNode, viewerOn]);

  // SELEÇÃO MODELO ÁRVORE POR ROW SELECT 
  useEffect(() => {
    const toggleSelectedNodeIdForRowTree = async (
      viewer: Autodesk.Viewing.GuiViewer3D,
      node: number,
      urn: string,
    ) => {
      const visibleModels = viewer.getVisibleModels();
      if (visibleModels.some(model => model.is2d())) {
        persistOptionNode.current = { option: node, type: 'selectNode', urn };
        render3dModelsForFunctionRequired3d(viewer);

      } else {
        const currentModel = visibleModels.find(model => 'urn:'.concat(model.getData()?.urn) === urn);
        if (currentModel) {
          const currentSelection = viewer.getAggregateSelection();
          if (currentSelection.length > 0 && (currentSelection[0].selection[0] === node) && ('urn:'.concat(currentSelection[0].model.getData().urn) === urn)) {
            viewer.clearSelection();
            dispatch(FederatedViewerActions.setSelectedProperties(null));
            dispatch(FederatedViewerActions.setSelectNode(null));
          } else {
            viewer.clearSelection();
            dispatch(FederatedViewerActions.setSelectedProperties(null));
            viewer.select(node, currentModel, Autodesk.Viewing.SelectionType.REGULAR);
            dispatch(FederatedViewerActions.setSelectNode({
              urn,
              nodeId: node,
            }));
          }
        }
      }
    }

    if (viewer.current && viewerOn && toggleSelectNode.node && toggleSelectNode.urn) {
      toggleSelectedNodeIdForRowTree(
        viewer.current,
        toggleSelectNode.node,
        toggleSelectNode.urn
      );
    }
  }, [toggleSelectNode, viewerOn]);

  // VISUALIZAR VIEW
  useEffect(() => {
    const handleSetViewCamera = (
      viewer: Autodesk.Viewing.GuiViewer3D,
      view: Autodesk.Viewing.BubbleNode,
      urn: string,
      id: number,
      doc?: Autodesk.Viewing.Document,
    ) => {
      if (viewer) {
        try {
          if (view.is2D() && doc) {
            viewer.loadDocumentNode(doc, view);

          } else {
            const visibleModels = viewer.getVisibleModels();
            if (visibleModels.some(model => model.is2d())) {
              render3dModelsForFunctionRequired3d(viewer);
              persistOptionNode.current = { option: view, type: 'view', urn, id };

            } else {
              viewer.setView(view, { useExactCamera: true });
            }
          }
        } catch (error) {
          console.log({ error })
        }
      }
    }

    if (viewer.current && viewerOn && toggleView.view && toggleView.urn && toggleView.id) {
      const currentDoc = views.find(view => view.urn === toggleView.urn);
      handleSetViewCamera(viewer.current, toggleView.view, toggleView.urn, toggleView.id, currentDoc?.doc);
    }
  }, [toggleView, viewerOn]);

  // VISUALIZAR VIEWPOINT
  useEffect(() => {
    const handleSetViewCamera = (
      viewer: Autodesk.Viewing.GuiViewer3D,
      camera: Autodesk.Viewing.BubbleNode,
      urn: string,
      id: number,
    ) => {
      if (viewer) {
        try {
          const visibleModels = viewer.getVisibleModels();
          if (visibleModels.some(model => model.is2d())) {
            render3dModelsForFunctionRequired3d(viewer);
            persistOptionNode.current = { option: camera, type: 'camera', urn, id };

          } else {
            viewer.setView(camera, { useExactCamera: true });
          }
        } catch (error) {
          console.log({ error })
        }
      }
    }

    if (viewer.current && viewerOn && toggleViewCamera.camera && toggleViewCamera.urn && toggleViewCamera.id) {
      handleSetViewCamera(viewer.current, toggleViewCamera.camera, toggleViewCamera.urn, toggleViewCamera.id);
    }
  }, [toggleViewCamera, viewerOn]);

  // CARREGAR PRANCHA
  useEffect(() => {
    const handleLoadPrancha = (
      doc: Autodesk.Viewing.Document,
      camera: Autodesk.Viewing.BubbleNode | null,
    ) => {
      if (viewer.current) {
        const data: IHideNodesAction = {
          type: 'clearAll',
        };
        dispatch(FederatedViewerActions.setHideNodes(data));
        try {
          viewer.current.loadDocumentNode(doc, camera);
        } catch (error) {
          console.log({ error })
        }
      }
    }

    if (viewer.current && viewerOn && togglePrancha.prancha && pranchas) {
      const currentDoc = pranchas.find(prancha => prancha.urn === togglePrancha.urn);
      if (currentDoc) {
        handleLoadPrancha(currentDoc.doc, togglePrancha.prancha);
      }
    }
  }, [togglePrancha, pranchas, viewerOn]);

  // RERENDER 3D MODELS
  const render3dModelsForFunctionRequired3d = async (
    viewer: Autodesk.Viewing.GuiViewer3D,
    unloadCurrentUrn?: string,
  ) => {
    onTogglePrancha(null, null, null);
    viewer.tearDown();

    let currentModels: IModels[] = [];
    currentModels = models;
    if (unloadCurrentUrn) {
      currentModels = models.filter(model => model.urn !== unloadCurrentUrn);
    }
    currentModels = currentModels.filter(model => model.model.is3D());

    currentModels.map(async (item) => {
      if (viewer && item.doc) {
        await viewer.loadDocumentNode(item.doc, item.model, {
          keepCurrentModels: true,
          globalOffset: { x: 0, y: 0, z: 0 },
        });
      }
      setUrnInit(prevState => {
        const urnExist = prevState.find(prev => prev === item.urn);
        if (urnExist) {
          return prevState;
        }
        return [...prevState, item.urn];
      });
    });
  }

  // CAPTURAR MUDANÇA DE HIDE/SHOW NOS NODES DO VIEWER
  const getHideNodeChange = (event: any) => {
    if (viewer.current) {
      const aggregateHiddenNodes = viewer.current.getAggregateHiddenNodes();
      aggregateHiddenNodes.find(item => item.model.myData.urn === event.model.myData.urn)?.ids.map((id: number) => {
        if (viewer.current?.isNodeVisible(id, event.model)) {
          viewer.current.show(id, event.model)
        }
      });

      const currentShowNodes: number[] = [];
      hideNodes.find(item => item.urn === event.model.myData.urn)?.showNodes.map((id: number) => {
        if (viewer.current?.isNodeVisible(id, event.model)) {
          currentShowNodes.push(id);
        }
      })

      const data: IHideNodesAction = {
        urn: event.model.myData.urn,
        type: event.type,
        nodeId: event.nodeIdArray[0],
        nodeIdArrayHidden: aggregateHiddenNodes.find(item => item.model.myData.urn === event.model.myData.urn)?.ids || [],
        nodeIdArrayShow: currentShowNodes,
      };
      dispatch(FederatedViewerActions.setHideNodes(data));
    }
  }

  const clearModelUrns = (unloadModel: IModels) => {
    dispatch(FederatedViewerActions.setModels({
      ...unloadModel,
      removeModel: true,
    }));
    setUrnInit(prevState => {
      return prevState.filter(prev => prev !== unloadUrn);
    });
    dispatch(FederatedViewerActions.clearUnloadUrn());
  }

  const finishModels = (unloadUrn: string) => {
    const unloadModel = models.find(item => item.urn === unloadUrn);

    if (unloadModel) {
      if (viewer.current) {
        const visibleModels = viewer.current.getVisibleModels();

        if (visibleModels.some(item => item.is2d())) {
          clearModelUrns(unloadModel);
          setTimeout(() => {
            if (viewer.current) {
              const activeUrnsNow = viewer.current.getVisibleModels().map(model => model.getData().urn);
              if (activeUrnsNow.includes(unloadUrn.replace('urn:', ''))) {
                render3dModelsForFunctionRequired3d(viewer.current, unloadUrn);
              }
            }
            setFinishModelLoading(false);
          }, 500);

        } else {
          const done = viewer.current.unloadDocumentNode(unloadModel.model);
          if (done) {
            clearModelUrns(unloadModel);
            setTimeout(() => {
              setFinishModelLoading(false);
            }, 500);

          } else {
            setFinishModelLoading(false);
          }
        }
      }
    }
  };

  const viewerFinishAndClosed = () => {
    dispatch(FederatedViewerActions.clearAllSets());
    setUrnInit([]);
    onClearAllToggles();
    setViewerOn(false);
    viewer.current?.tearDown();
    viewer.current?.finish();
    viewer.current = null;
    init.current = false;
  };

  // CAPTURAR MUDANÇA DE SELECTED NOS NODES DO VIEWER
  const getSelectChanged = (value: any) => {
    const selected = value.selections[0];
    if (selected) {
      const model = selected.model;
      const urnId = selected.model.myData.urn;
      const setReduxProperties: ICategoryProperties[] = [];

      model.getProperties(selected.dbIdArray[0], (properties: Autodesk.Viewing.PropertyResult) => {
        const listProperties = properties.properties;
        listProperties.map((current: Autodesk.Viewing.Property) => {
          const categoryExist = setReduxProperties.find(category => category.categoryName === current.displayCategory);
          if (categoryExist) {
            return categoryExist.categoryProperties?.push({
              displayName: current.displayName,
              displayValue: current.displayValue,
              units: current.units,
            });
          } else {
            return setReduxProperties.push({
              categoryName: current.displayCategory || 'Other Properties',
              categoryProperties: [{
                displayName: current.displayName,
                displayValue: current.displayValue,
                units: current.units,
              }]
            })
          }
        }, setReduxProperties);

        if (viewer.current) {
          viewer.current.showAll();
          dispatch(FederatedViewerActions.setHideNodes(null));
        }
        dispatch(FederatedViewerActions.setSelectedProperties({
          dbId: properties.dbId,
          name: properties.name,
          properties: setReduxProperties,
        }));
        dispatch(FederatedViewerActions.setSelectNode({
          urn: urnId,
          nodeId: properties.dbId,
        }));

        showDrawerProperties();
      });
    }
  };

  const getLoadedChanged = async (event: any) => {
    if (viewer.current && viewerOn) {
      onLoadUrn(null);
      setModelLoading(false);

      switch (persistOptionNode.current?.type) {
        case 'hideShowNode':
          if (typeof persistOptionNode.current.option === 'number')
            onToggleNode(persistOptionNode.current.option, persistOptionNode.current.urn);
          break;
        case 'selectNode':
          if (typeof persistOptionNode.current.option === 'number')
            onToggleSelectNode(persistOptionNode.current.option, persistOptionNode.current.urn);
          break;
        case 'camera':
          if (typeof persistOptionNode.current.option !== 'number' && persistOptionNode.current.id)
            onToggleViewCamera(persistOptionNode.current.option, persistOptionNode.current.urn, persistOptionNode.current.id);
          break;
        case 'view':
          if (typeof persistOptionNode.current.option !== 'number' && persistOptionNode.current.id)
            onToggleView(persistOptionNode.current.option, persistOptionNode.current.urn, persistOptionNode.current.id);
      };
      persistOptionNode.current = null;

      viewer.current.unloadExtension('Autodesk.ModelStructure');
      viewer.current.unloadExtension('Autodesk.PropertiesManager');

      await viewer.current.loadExtension('Autodesk.VisualClusters');
      await viewer.current.loadExtension('Autodesk.LayerManager');
      await viewer.current.loadExtension('Autodesk.AEC.LevelsExtension');

      viewer.current.getExtension('Autodesk.Measure', (ext: any) => {
        ext.measureTool.setUnits('m');
      });

      const svfId = "urn:".concat(event.model.myData?.urn || event.model.loader?.svfUrn);
      const currModel = models.find(model => model.urn === svfId);
      const currentExtension = extensionValidateds.find(ext => ext.urn === svfId)?.extension || '';
      const hasViewpoints = extensionViewpoints.includes(currentExtension);

      if (currModel && hasViewpoints) {
        const foldersCamera: IFoldersBubleNodesCameras[] = [];
        const cameras: Autodesk.Viewing.BubbleNode[] = [];

        const viewableFolders = currModel.doc.getRoot().search({ type: 'folder' });
        const currViewpoints = viewpoints.find(view => view.urn === svfId);

        // EXTRACT VIEWPOINTS
        if (viewableFolders) {
          viewableFolders.map(bubles => {
            if (
              bubles.children &&
              bubles.children.length > 0 &&
              bubles.data.type === 'folder' &&
              bubles.data.role === 'viewable' &&
              !bubles.children.some(child => child.data.type === 'folder') &&
              bubles.children.some(child => child.data.type === 'view') &&
              !currViewpoints?.folders?.some(fC => fC?.name === bubles.data.name)
            ) {
              return foldersCamera.push({
                name: bubles.data.name,
                cameras: bubles.children,
              });
            }
            if (bubles.data.role === '3d' && bubles.data.type === 'view') {
              return cameras.push(bubles);
            }
            return bubles;
          });
        }

        if (hasViewpoints && (cameras.length > 0 || foldersCamera.length > 0)) {
          const currentViewpoints: IViewpoints = {
            urn: svfId,
            cameras,
            folders: foldersCamera,
            add: true,
          }
          dispatch(FederatedViewerActions.setViewpoints(currentViewpoints));
        }
      }

      // INICIO EXTRAÇÃO MODELTREE DIRETO DO MODELO RENDERIZADO
      onObjectTreeCreated(event);
    }
  };

  // CRIAÇÃO/FORMATAÇÃO MODELTREE EXTRAINDO DIRETO DO MODELO RENDERIZADO
  const onObjectTreeCreated = (e: any) => {
    if (e.model.is3d()) {
      const svfId = "urn:".concat(e.model.myData?.urn || e.model.loader?.svfUrn);

      getAllUnformatedTreeNodes(e.model, function (jsonData: ITreeObjectModel[]) {
        jsonData.reverse().shift();

        function objectTreeModelFormat(objectTree: ITreeObjectModel[]) {
          const lookup: any = {};
          const objectTreeFormat: ITreeObjectModel[] = [];

          for (const item of objectTree) {
            if (!lookup.hasOwnProperty(item.id)) {
              item.children = [];
              lookup[item.id] = item;
            } else {
              item.children = lookup[item.id].children;
              lookup[item.id] = item;
            }

            if (item.parent === '#') {
              objectTreeFormat.push(item);
              continue;
            }

            if (lookup.hasOwnProperty(item.parent)) {
              lookup[item.parent].children.push(item);
            } else {
              lookup[item.parent] = { children: [item] }
            }
          }

          return objectTreeFormat;
        }

        const newList = objectTreeModelFormat(jsonData);

        const modelTree: IModelsTree = {
          urn: svfId,
          list: newList,
        };
        dispatch(FederatedViewerActions.setModelsTree(modelTree));
      });
    }
  };

  // EXTRAÇÃO MODELTREE DO MODELO RENDERIZADO
  const getAllUnformatedTreeNodes = (
    model: Autodesk.Viewing.Model,
    callback: (jsonData: ITreeObjectModel[],
    ) => void) => {
    let cbCount: any = 0;
    let tree: any;
    let jsData: any[] = [];

    const getLeafComponentsRec = (current: any, parent: any) => {
      cbCount++;
      if (tree.getChildCount(current) != 0) {
        tree.enumNodeChildren(current, function (children: any) {
          getLeafComponentsRec(children, current);
        }, false);
      }
      let nodeName = model.getInstanceTree().getNodeName(current);
      jsData.push({ id: current, parent: parent, title: nodeName });

      if (--cbCount == 0) callback(jsData);
    }

    model.getObjectTree(function (objectTree: any) {
      tree = objectTree;
      let rootId = tree.getRootId();
      let nodeName = model.getInstanceTree().getNodeName(rootId);
      jsData.push({ id: rootId, parent: '#', title: nodeName });
      getLeafComponentsRec(rootId, '#');
    });
  };

  const getTokenAndViewerInit = () => {
    getAccessToken((token) => {
      options.accessToken = token;
      accessToken.current = token;
      handleViewerStart();
    });
  }

  const handleViewerStart = () => {
    Autodesk.Viewing.Initializer(options, () => {
      const div: any = viewerDiv;
      const config3d: Autodesk.Viewing.Viewer3DConfig = getViewerConfig();

      viewer.current = new Autodesk.Viewing.GuiViewer3D(div.current, config3d);

      const startedCode = viewer.current.start();
      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      return setViewerOn(true);
    });
  }

  const handleViewerLoadUrn = (urn: string) => {
    Autodesk.Viewing.Document.load(
      urn,
      onDocumentLoadSuccess,
      onDocumentLoadError
    );
  }

  const getViewerConfig = (): Autodesk.Viewing.Viewer3DConfig => {
    let extensions: any[] = [];
    const confViewer: Autodesk.Viewing.Viewer3DConfig = {
      theme: 'light-theme',
      extensions,
    };
    return confViewer;
  }

  const getAccessToken = async (callBack: (value: string) => void) => {
    try {
      const { data } = await ViewerApi.GetAccessToken();
      callBack(data.data.access_token);
    } catch (error) {
      console.log({ error })
      window.location.href = '/login';
    }
  }

  const onDocumentLoadSuccess = async (viewerDocument: Autodesk.Viewing.Document) => {
    const currentUrn = viewerDocument.getPath();
    const viewableRoot: Autodesk.Viewing.BubbleNode = viewerDocument.getRoot().getDefaultGeometry();
    const viewables3d = viewerDocument.getRoot().search(Autodesk.Viewing.BubbleNode.MODEL_NODE);
    const viewables2d = viewerDocument.getRoot().search(Autodesk.Viewing.BubbleNode.SHEET_NODE);
    const viewableFolders = viewerDocument.getRoot().search({ type: 'folder' });

    const currentExtension = extensionValidateds.find(ext => ext.urn === currentUrn)?.extension;

    if (viewableRoot || viewables3d.length > 0) {
      const handleUseModel = () => {
        if (viewableRoot.is3D()) {
          return viewableRoot;
        }
        if (viewables3d.length > 0) {
          return viewables3d[0];
        }
        return viewableRoot;
      };

      dispatch(FederatedViewerActions.setModels({
        urn: currentUrn,
        doc: viewerDocument,
        model: handleUseModel(),
      }));
    }

    if (currentExtension) {
      const hasViews2d3d = extensionViews2d3d.includes(currentExtension);
      const hasPranchas = extensionPranchas.includes(currentExtension);
      const hasViewpoints = extensionViewpoints.includes(currentExtension);

      const pranchas: Autodesk.Viewing.BubbleNode[] = [];
      const foldersView: IFoldersBubleNodesViews[] = [];
      const foldersCamera: IFoldersBubleNodesCameras[] = [];
      const cameras: Autodesk.Viewing.BubbleNode[] = [];

      if (!hasViewpoints) {
        if (viewables2d.length > 0 || viewableFolders[0].children.length) {
          const filteredFoldersWithChildren = viewableFolders[0].children.filter(item => item.children);

          // EXTRACT VIEWS
          const formatViews = (bubleNodes: Autodesk.Viewing.BubbleNode[]): IFoldersBubleNodesViews[] => {
            const foldersRootViews2d3d: IFoldersBubleNodesViews[] = [];
            let folder2d: IFoldersBubleNodesViews = {
              name: 'Views 2D',
              views: [],
              folders: [],
            };
            let folder3d: IFoldersBubleNodesViews = {
              name: 'Views 3D',
              views: [],
              folders: [],
            };

            const formatData = (
              buble: Autodesk.Viewing.BubbleNode,
              level: number,
              folders?: IFoldersBubleNodesViews[],
              views?: Autodesk.Viewing.BubbleNode[],
              folder3D?: boolean,
            ): boolean => {
              let folders2d = {} as IFoldersBubleNodesViews;

              if (buble.data.type === 'folder' && buble.children) {
                if (folders) {
                  if (level > 1) {
                    folders2d = {
                      name: buble.data.name,
                      views: [],
                      folders: [],
                    };
                    folders.push(folders2d);
                  }
                  buble.children.forEach(item => {
                    if (item.children) {
                      if (!item.children.some(child => child.is3D())) {
                        if (level === 1) {
                          formatData(item, level + 1, folder2d.folders, folder2d.views);
                        } else {
                          formatData(item, level + 1, folders2d.folders, folders2d.views);

                        }
                      }
                      if (item.children.some(child => child.is3D())) {
                        formatData(item, level + 1, folder3d.folders, folder3d.views, true);
                      }
                    }
                  });
                }
              }

              if (folders && buble.data.type === 'geometry') {
                if (buble.children && buble.children.length > 0) {
                  if (!folder3D) {
                    const filtered2dViews = buble.children.filter(view => view.is2D());
                    if (filtered2dViews.length > 0) {
                      if (filtered2dViews.length === 1) {
                        if (level === 2) {
                          pranchas.push(...filtered2dViews);
                        } else {
                          views?.push(...filtered2dViews);
                        }
                      } else {
                        if (folders) {
                          folders.push({
                            name: buble.data.name,
                            views: filtered2dViews,
                          });
                        }
                      }
                    }
                  }
                  if (folder3D) {
                    const filtered3dViews = buble.children.filter(view => view.is3D());
                    if (filtered3dViews.length > 0) {
                      if (filtered3dViews.length === 1) {
                        views?.push(...filtered3dViews);
                      } else {
                        folders?.push({
                          name: buble.data.name,
                          views: filtered3dViews,
                        });
                      }
                    }
                  }
                }
              }

              return true;
            }

            for (const item of bubleNodes) {
              formatData(item, 1, foldersRootViews2d3d);
            }

            if ((folder2d.folders && folder2d.folders?.length > 0) ||
              (folder2d.views && folder2d.views?.length > 0)
            ) {
              foldersRootViews2d3d.push(folder2d);
            }
            if ((folder3d.folders && folder3d.folders?.length > 0) ||
              (folder3d.views && folder3d.views?.length > 0)
            ) {
              foldersRootViews2d3d.push(folder3d);
            }

            return foldersRootViews2d3d;
          }
          const dataFormat = formatViews(filteredFoldersWithChildren);
          foldersView.push(...dataFormat);

          // EXTRACT PRANCHAS
          if (hasPranchas && pranchas.length <= 0 && filteredFoldersWithChildren.length > 0) {
            filteredFoldersWithChildren.map(item => {
              if (item.children && item.children.some(bubles => bubles.is2D())) {
                if (item.children.length > 0) {
                  const filteredPranchas = item.children.filter(prancha => prancha.is2D());
                  if (filteredPranchas.length > 0) {
                    return pranchas.push(...filteredPranchas);
                  }
                }
              }
              return item;
            });
          }

          if (hasPranchas && pranchas.length <= 0) {
            viewables2d.map(prancha => {
              if (prancha.children && prancha.children.length > 0) {
                prancha.children.map((buble: any) => {
                  if (buble.data.viewbox) {
                    pranchas.push(buble);
                  }
                  return buble;
                })
              }
              return prancha;
            });
          }
        }
      }

      // EXTRACT VIEWPOINTS
      if (hasViewpoints && viewables3d.length > 0) {
        viewables3d.map(models => {
          models.children.map(bubles => {
            if (bubles.children && bubles.children.length > 0 && bubles.data.type === 'folder' && bubles.data.role === 'viewable') {
              return foldersCamera.push({
                name: bubles.data.name,
                cameras: bubles.children,
              });
            }
            if (bubles.data.role === '3d' && bubles.data.type === 'view') {
              return cameras.push(bubles);
            }
            return bubles;
          });

          return models;
        });
      }

      if (hasPranchas && pranchas.length > 0) {
        const currentpranchas: IPranchas = {
          urn: currentUrn,
          doc: viewerDocument,
          pranchas,
        }
        dispatch(FederatedViewerActions.setPranchas(currentpranchas));
      }

      if (hasViews2d3d && foldersView.length > 0) {
        const currentViews: IViews = {
          urn: currentUrn,
          doc: viewerDocument,
          folders: foldersView,
        };
        dispatch(FederatedViewerActions.setViews(currentViews))
      }

      if (hasViewpoints && (cameras.length > 0 || foldersCamera.length > 0)) {
        const currentViewpoints: IViewpoints = {
          urn: currentUrn,
          cameras,
          folders: foldersCamera,
        }
        console.log({ currentViewpoints })
        dispatch(FederatedViewerActions.setViewpoints(currentViewpoints));
      }
    }
  }

  const onDocumentLoadError = (errorCode: Autodesk.Viewing.ErrorCodes) => {
    console.log({ errorCode })
  }

  const handleResize = (rect: ContentRect) => {
    //cancel any previous handlers that were dispatched
    if (resizeHandling.current)
      clearTimeout(resizeHandling.current)

    //defer handling until resizing stops
    resizeHandling.current = setTimeout(() => {
      if (viewer.current) viewer.current.resize()
    }, 100)
  }

  const showDrawerProperties = () => {
    setDrawerPropertiesVisible(true);
  };

  const closeDrawerProperties = () => {
    setDrawerPropertiesVisible(false);
  };

  return {
    handleResize,
    drawerPropertiesVisible,
    closeDrawerProperties,
    showDrawerProperties,
  }
}

export default useInternalViewer;
