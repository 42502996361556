import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "Store/Auth/Auth.selector";
import { MultiDomainActions } from "Store/MultiDomain/MultiDomain.action";
import { getDefaultInfoTheme, getDomain } from "Store/MultiDomain/MultiDomain.selector";
import { AuthUtils } from "Utils/AuthUtils";
import { hostnamesDefault } from "Utils/MultidomailUtils";

interface IMultiDomain {
  isAuthenticated: boolean;
}

const MultiDomain: FC<IMultiDomain> = ({
  isAuthenticated,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const domain = useSelector(getDomain);
  const defaultConfigDomain = useSelector(getDefaultInfoTheme);

  const isEnterprise = (userInfo?.UserStoreItem?.StorePlan?.Order || 0) >= 4;

  useEffect(() => {
    if (
      (window.location.hostname &&
        !isAuthenticated) &&
      (!hostnamesDefault.includes(window.location.hostname) ||
        (hostnamesDefault.includes(window.location.hostname) &&
          (domain.LoginBannerUrl ||
            domain.theme.colors.tertiary.tertiary !== defaultConfigDomain.SecondaryColor)
        ))
    ) {
      dispatch(MultiDomainActions.getMultiDomain(window.location.hostname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    domain.LoginBannerUrl,
    domain.theme.colors.tertiary.tertiary,
    defaultConfigDomain.SecondaryColor,
  ]);

  useEffect(() => {
    if (
      isEnterprise &&
      (userInfo?.CurrentTenantContext || AuthUtils.getLoggedUser()?.currentTenant)
    ) {
      dispatch(MultiDomainActions.getTenantPersonalization(
        userInfo?.CurrentTenantContext ||
        AuthUtils.getLoggedUser()?.currentTenant ||
        0
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnterprise, userInfo?.CurrentTenantContext]);

  return <></>
}

export default MultiDomain;
