import { FC, useEffect, useMemo, useState } from 'react';
import { Icon, IconName } from '../../../../Components/UI';
import Button from '../../../../Components/UI/Button';
import Modal from 'Components/UI/Modal/Modal';
import { ButtonsWrapper, Card, Footer, Header, Main, Wrapper } from './styles';
import { ApisEnumLabel } from 'Data/enums/Apis';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import { ActionSolutionType, ICreateSADisciplineRequest, IDeleteSADisciplineRequest, IMergeSADisciplineRequest, IRestoreSADisciplineRequest } from 'Data/interfaces/Obra/ISolutionSADisciplineRequest';
import { FileStatusInStorageEnum } from 'Data/interfaces/Obra/IDisciplineSAStatusResponse';
import { Dispatch } from 'redux';
import { DisciplinaActions } from 'Store/Disciplina/Disciplina.actions';
import LoadingDisciplineSAConnection from 'Components/UI/CustomLoading/LoadingDisciplineSAConnection';

interface ISolutionSAConnection {
  platform: number;
  visible?: boolean;
  onCancel: () => void;
  onConfirm: (sent: boolean) => void;
  isLoading?: boolean;
  isLoadingStatus?: boolean;
  discipline: any;
  disciplineStatus?: FileStatusInStorageEnum;
  existingFolderApiId?: string;
  dispatch: Dispatch<any>;
}

export interface IActionOption {
  id: ActionSolutionType;
  action: (discipline: any) => void;
  icon: IconName;
  iconSize: number;
  header: string;
  description: string;
}

const SolutionSAConnection: FC<ISolutionSAConnection> = ({
  platform,
  visible,
  onCancel,
  onConfirm,
  isLoading,
  isLoadingStatus,
  discipline,
  disciplineStatus,
  existingFolderApiId,
  dispatch,
}) => {
  const [showDelete, setShowDelete] = useState(false);

  const [selectedOpt, setSelectedOpt] = useState<ActionSolutionType | null>(null);
  const trashed = FileStatusInStorageEnum.Trashed === disciplineStatus;
  const merged = FileStatusInStorageEnum.Recreated === disciplineStatus;
  const solutionDelete = selectedOpt === 'del';

  const actionOptions: Record<string, IActionOption> = useMemo(() => {
    const options: Record<string, IActionOption> = {
      'rest': {
        id: 'rest',
        action: (useDicipline: any) => {
          const request: IRestoreSADisciplineRequest = {
            ConstructionSiteDisciplinesId: useDicipline.ConstructionSiteDisciplinesId,
          };
          dispatch(DisciplinaActions.listArchiveNewDisciplineNoCache(true))
          dispatch(DisciplinaActions.restoreSADisciplineRequest(request))
        },
        icon: 'relogio',
        iconSize: 26,
        header: 'Restaurar disciplina do serviço de armazenamento',
        description: 'Isso fará com que a disciplina seja restaurada com os arquivos antigos e continuará com as informações de plotagens e atividades.',
      },
      'new': {
        id: 'new',
        action: (useDicipline: any) => {
          const request: ICreateSADisciplineRequest = {
            ConstructionSiteDisciplinesId: useDicipline.ConstructionSiteDisciplinesId,
          };
          if (existingFolderApiId) {
            request.existingFolderApiId = existingFolderApiId;
          }
          dispatch(DisciplinaActions.listArchiveNewDisciplineNoCache(true))
          dispatch(DisciplinaActions.createSADisciplineRequest(request))
        },
        icon: 'adicionarPasta',
        iconSize: 26,
        header: 'Criar nova pasta da disciplina',
        description: 'Isso fará com que uma nova pasta vazia seja criada no serviço de armazenamento e manterá as informações de plotagens e atividades.',
      },
      'merge': {
        id: 'merge',
        action: (useDicipline: any) => {
          const request: IMergeSADisciplineRequest = {
            ConstructionSiteDisciplinesId: useDicipline.ConstructionSiteDisciplinesId,
          };
          if (existingFolderApiId) {
            request.existingFolderApiId = existingFolderApiId;
          }
          dispatch(DisciplinaActions.listArchiveNewDisciplineNoCache(true))
          dispatch(DisciplinaActions.mergeSADisciplineRequest(request))
        },
        icon: 'adicionarPasta',
        iconSize: 26,
        header: 'Restaurar dados para a disciplina já existente',
        description: 'Isso fará com que as informações de plotagens e atividades sejam restauradas para a disciplina já existente no serviço de armazenamento.',
      },
      'del': {
        id: 'del',
        action: (useDicipline: any) => {
          const request: IDeleteSADisciplineRequest = {
            ConstructionSiteDisciplinesId: useDicipline.ConstructionSiteDisciplinesId,
          };
          if (existingFolderApiId) {
            request.existingFolderApiId = existingFolderApiId;
          }
          dispatch(DisciplinaActions.deleteSADisciplineRequest(request))
        },
        icon: 'excluir',
        iconSize: 20,
        header: 'Excluir disciplina e todos os dados',
        description: 'Isso fará com que a disciplina seja excluída permanentemente do sistema, o que também excluirá todas as informações de plotagens e atividades vinculadas à disciplina.',
      },
    };

    if (!trashed) {
      delete options['rest'];
    }

    if (!merged) {
      delete options['merge'];
    } else {
      delete options['new'];
    }
    
    return options;
  }, [trashed, merged, existingFolderApiId, dispatch]);
  
  const handleSelectedOpt = (opt: ActionSolutionType) => {
    setSelectedOpt(opt);
  };

  const handleConfirm = () => {
    if (solutionDelete && !showDelete) {
      return setShowDelete(true);
    }

    if (selectedOpt && discipline) {
      actionOptions[selectedOpt].action(discipline);
      return onConfirm(true);
    }
  };

  const footerButtons = (
    <ButtonsWrapper disabled={`${(isLoading || !selectedOpt)}`}>
      <Button 
        className="cancel"
        onClick={onCancel}
      >
        <span className="cancelText">Cancelar</span>
      </Button>
      <Button
        type="primary"
        className="confirm"
        onClick={() => handleConfirm()}
        loading={isLoading && !solutionDelete}
        disabled={isLoading || !selectedOpt}
      >
        <span className="confirmText">Continuar</span>
      </Button>
    </ButtonsWrapper>
  );

  useEffect(() => {
    if (disciplineStatus === FileStatusInStorageEnum.Ok) {
      onCancel();
    }
  }, [disciplineStatus])

  return (
    <Modal
    width={500}
    title="Migração de dados"
    visible={visible}
    onCancel={onCancel}
    footerButtons={footerButtons}
    >
      {isLoadingStatus && (
        <LoadingDisciplineSAConnection multiple={3} />
      )}
      {!isLoadingStatus && (
        <Wrapper>
          <Header>
            {trashed && (
              <span>
                A pasta <span className="headerTextBold">{`“${discipline?.CustomName || discipline?.Discipline?.Name}”`}</span> foi movida para a lixeira no {ApisEnumLabel[platform]}. Você pode restaurar ela clicando abaixo. Caso não queira restaurar a pasta, você pode fazer a migração dos dados para uma nova pasta ou excluir todos os dados do sistema vinculados à essa pasta.
              </span>
            )} 
            {!trashed && (
              merged ? (
                <span>
                  A pasta <span className="headerTextBold">{`“${discipline?.CustomName || discipline?.Discipline?.Name}”`}</span> foi excluída e uma pasta com o mesmo nome foi identificada no {ApisEnumLabel[platform]}. É necessário fazer a migração dos dados para a nova pasta ou excluir todos os dados do sistema vinculados à pasta excluída.
                </span>
              ) : (
                <span>
                  A pasta <span className="headerTextBold">{`“${discipline?.CustomName || discipline?.Discipline?.Name}”`}</span> não existe mais no {ApisEnumLabel[platform]}. É necessário fazer a migração dos dados para uma nova pasta ou excluir todos os dados do sistema vinculados à essa pasta.
                </span>
              )
            )}
          </Header>
          
          <Main>
            {Object.values(actionOptions).map(option => {
              const selected = selectedOpt === option.id;

              return (
                <Card 
                  key={option.id}
                  selected={`${selected}`}
                  onClick={() => handleSelectedOpt(option.id)}
                >
                  <div className="cardLeft">
                    <Icon 
                      color="primary"
                      icon={option.icon}
                      customSize={option.iconSize}
                      blocked={!selected}
                    />
                  </div>
                  <div className="cardRight">
                    <span className="cardHeader">{option.header}</span>
                    <span className="cardDescription">{option.description}</span>
                  </div>
                </Card>
              )
            })}
          </Main>

          <Footer>
            <Icon 
              icon="alerta"
              customSize={18}
              customColor={'#E2574C'}
            />
            <span className="footerText">Essa ação é permanente e não pode ser desfeita.</span>
          </Footer>
        </Wrapper>
      )}

      {showDelete && (
        <ConfirmDeleteModal 
          visible={showDelete}
          onConfirm={() => handleConfirm()}
          onCancel={() => setShowDelete(false)}
          loading={isLoading}
        />
      )}
    </Modal>
  );
}

export default SolutionSAConnection;
