import React, { FC, useEffect } from 'react';

import styles from './Signup.module.scss';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Button from '../../../Components/UI/Button';
import Select from '../../../Components/UI/Select';
import Background from '../Backgroud/background';
import { Checkbox } from 'antd';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import Form from '../../../Components/UI/Form/Form';
import schema from './signup.schema';
import { Field as FieldReact } from 'react-final-form';
import Field from '../../../Components/UI/Field';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { GoogleApi } from '../../../Data/GoogleApi.api';
import { IGoogleApiResponse } from '../../../Data/interfaces/GoogleApi/IGoogleApiResponse';
import { Http } from '../../../Utils/Http';
import toastHandler from '../../../Utils/toastHandler';
import { IRegisterGoogleRequest } from '../../../Data/interfaces/Auth/IRegisterGoogleRequest';
import Link from 'Components/UI/Link';
import { getDomain } from 'Store/MultiDomain/MultiDomain.selector';

const SignupGoogle: FC<Props> = (props) => {
  const [userData, setUserData] = React.useState<IGoogleApiResponse>();
  const domain = useSelector(getDomain);

  const dispatch = useDispatch();
  const tokenGoogle = Http.GetQueryParams('access_token') || '';
  const tenantInviteEmailHash = Http.GetQueryParams('tenantInviteEmailHash');
  const pathname = Http.GetQueryParams('redirectUrl');
  const [isPositionCompany, setPositionCompany] = React.useState<any>();
  useEffect(() => {
    async function getGoogleUser() {
      const { data } = await GoogleApi.getUserData(tokenGoogle);
      setUserData(data);
    }
    getGoogleUser();
  }, [tokenGoogle]);

  useEffect(() => {
    dispatch(AuthActions.listCompanyPosition());
  }, [dispatch]);

  const companyPositions = props.listCompanyPosition?.map((role) => ({
    value: role.CompanyPositionId,
    description: role.Name,
    label: role.Name
  }));

  const signup = (data: IRegisterGoogleRequest) => {
    if (tenantInviteEmailHash) {
      data.TenantInviteEmailHash = tenantInviteEmailHash;
    }

    if (!isPositionCompany) {
      toastHandler.showError('Selecione um cargo.');
    } else {
      data.CompanyPositionId = isPositionCompany;
      data.redirectUrl = pathname || '/';
      dispatch(AuthActions.userRegisterGoogle(data));
    }
  };

  const validField = (meta: any) => {
    return !meta.submitFailed ? 'default' : meta.valid ? 'valid' : 'error';
  };

  return (
    <Background>
      <BoxContainer height={domain?.LogoUrl ? 490 : 440} width={403}>
        <div className={styles['contentWrapper']}>
          <h2 className={styles['title']}>Criar conta</h2>
          <Form
            schema={schema}
            onSubmit={() =>
              signup({
                AccessToken: tokenGoogle,
                IsTermsAccepted: true,
                CompanyPositionId: isPositionCompany
              })
            }
          >
            <Field
              disabled={true}
              name="nome"
              label="Nome"
              placeholder={userData?.name}
            />
            <Select
              onSelect={setPositionCompany}
              className={styles['selectPositionCompany']}
              placeholder="Qual seu cargo?"
              options={companyPositions}
              size="large"
            />
            <Field
              className={styles['emailCad']}
              disabled={true}
              name="email"
              label="E-mail"
              placeholder={userData?.email}
            />
            <FieldReact name="isTermsAccepted" type="checkbox">
              {(propsField) => (
                <Checkbox
                  className={`${styles['checkbox']}
                                        ${styles[validField(propsField.meta)]}`}
                  {...propsField.input}
                >
                  <span>Declaro que li e concordo com os </span>
                  <Link
                    to="https://maletadoengenheiro.com.br/terms-of-use/"
                    target="_blank"
                    rel="noopener noreferrer"
                    isExternal={true}
                  >
                    Termos de uso
                  </Link>
                  <span> e as </span>
                  <Link
                    to="https://maletadoengenheiro.com.br/privacy-policies/"
                    target="_blank"
                    rel="noopener noreferrer"
                    isExternal={true}
                  >
                    Políticas de privacidade
                  </Link>
                </Checkbox>
              )}
            </FieldReact>

            <Button
              width="100%"
              type="primary"
              htmlType="submit"
              loading={props.isLoading}
            >
              Criar conta
            </Button>
          </Form>

          <div className={styles['hasAccount']}>
            <span>
              Já tem uma conta?&nbsp;
              <Link to="/login">
                Faça o login agora mesmo
              </Link>
            </span>
          </div>

          {domain?.LogoUrl && (
            <p className={styles['powered-by']}>
              Powered by <span>Coordly</span>
            </p>
          )}
        </div>
      </BoxContainer>
    </Background>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

export default connector(SignupGoogle);
