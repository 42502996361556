import { FC, CSSProperties } from 'react';
import styles from './Input.module.scss';
import { Icon, IconName } from '../Icon';
import { FieldInputProps } from 'react-final-form';

export interface IInputProps {
  label: string;
  placeholder?: string;
  icon?: IconName;
  customHeight?: number;
  customWidth?: number;
  type?: string;
  className?: string;
  inputClassName?: string;
  name?: string;
  style?: CSSProperties;
  onInput?: (e: any) => void;
  isFocused?: (focused: boolean) => void;
  statusField?: 'valid' | 'error' | 'default';
  value?: string;
  props?: FieldInputProps<any, HTMLElement>;
  autoFocus?: boolean;
  disabled?: boolean;
  isTextarea?: boolean;
}

const Input: FC<IInputProps> = ({ 
  label, 
  icon, 
  placeholder, 
  customHeight = 35,
  customWidth = '100%', 
  type = 'text', 
  onInput, 
  isFocused,
  className, 
  inputClassName, 
  style, 
  name, 
  props, 
  statusField = 'default', 
  value, 
  autoFocus, 
  disabled,
  isTextarea,
}) => {
  const currentPlaceholder = placeholder ?? label;

  if (isTextarea) {
    return (
      <>
        <label
          className={`${styles['labelInput']} ${className ?? ''} `}
          style={style}>
          {icon ? <Icon icon={icon} color='cinzaIcone' /> : null}
          <textarea
            name={name}
            className={`${icon ? styles['paddingIcon'] : ''} ${statusField === 'default' ? '' : styles[statusField]} ${inputClassName ?? ''}`}
            type={type}
            autoFocus={autoFocus}
            value={value}
            placeholder={currentPlaceholder}
            style={{ height: customHeight, width: customWidth }}
            disabled={disabled}
            {...props}
            onFocus={() => isFocused && isFocused(true)}
            onBlur={() => isFocused && isFocused(false)}
            onInput={(e: any) => onInput && onInput(e.target.value)}
          />
          <span>{label}</span>
        </label>
      </>
    );
  }

  return (
    <>
      <label
        className={`${styles['labelInput']} ${className ?? ''} `}
        style={style}>
        {icon ? <Icon icon={icon} color='cinzaIcone' /> : null}
        <input
          name={name}
          className={`${icon ? styles['paddingIcon'] : ''} ${statusField === 'default' ? '' : styles[statusField]} ${inputClassName ?? ''}`}
          type={type}
          autoFocus={autoFocus}
          value={value}
          placeholder={currentPlaceholder}
          style={{ height: customHeight, width: customWidth }}
          disabled={disabled}
          {...props}
          onFocus={() => isFocused && isFocused(true)}
          onBlur={() => isFocused && isFocused(false)}
          onInput={(e: any) => onInput && onInput(e.target.value)}
        />
        <span>{label}</span>
      </label>
    </>
  );
};

export default Input;
