import { FC, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './Armazenamento.module.scss';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import { Skeleton } from 'antd';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { ObraActions } from '../../../Store/Obra/Obra.actions';
import { IStorageData } from '../../../Data/interfaces/Obra/IStorageData';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import ConexaoServico from '../../Obras/Modals/ConexaoServico';
import { ApisEnum, ApisEnumLabel, getIconApi } from '../../../Data/enums/Apis';
import { Icon } from '../../../Components/UI/Icon';
import { GetAvatar } from '../../../Utils/generateThumbnail';
import { isObjectEmpty } from 'Utils/ObjectUtils';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Configurar Serviço',
    href: '#'
  },
  {
    description: 'Armazenamento',
    href: '/config/armazenamento'
  }
];

interface IObraInfo {
  id: number;
  name: string;
  image: string;
}

interface IStorageGroup {
  id: number;
  apiEmail: string;
  api: ApisEnum;
  obras?: IObraInfo[];
}

const Armazenamento: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const currentTenantId = useRef<number | undefined>(undefined);
  const [storageListGroup, setStorageListGroup] = useState<IStorageGroup[]>([]);
  const [noConnectionListGroup, setNoConnectionListGroup] = useState<
    IStorageGroup[]
  >([]);
  const [csIdConexaoServico, setCsIdConexaoServico] = useState<number>();
  const [conexaoServicoVisible, setConexaoServicoVisible] = useState(false);
  const [accountIdSelected, setAccountIdSelected] = useState<number | undefined>();

  useEffect(() => {
    if (
      !props.StorageList?.length ||
      props.currentTenant?.TenantId !== currentTenantId.current
    ) {
      dispatch(ObraActions.listConstructionSitesStorage());
      currentTenantId.current = props.currentTenant?.TenantId;
    }
    if (props.StorageList?.length) {
      let obrasComConexao = props.StorageList.filter((storage) => storage.Token);
      let obrasSemConexao = props.StorageList.filter((storage) => !storage.Token);

      setStorageListGroup(getStorageGroupRowFromData(obrasComConexao));
      setNoConnectionListGroup(getStorageGroupRowFromData(obrasSemConexao));
    }
  }, [
    props.isLoading,
    props.StorageList?.length,
    props.currentTenant?.TenantId,
  ]);

  const getStorageGroupRowFromData = (storageListData: IStorageData[]) => {
    const storageListGroup: IStorageGroup[] = [];

    storageListData.map((storageData) => {
      const obrasInfo: IObraInfo[] = [];

      storageData?.ConstructionSites?.map((obra) => {
        obrasInfo.push({
          name: obra.Name,
          id: obra.ConstructionSiteId,
          image: obra.OriginalImageUrl
        } as IObraInfo);
      });

      storageListGroup.push({
        id: storageData.Token?.ConstructionSiteApiTokenId,
        apiEmail: storageData.Token?.ApiEmail,
        api: storageData.Token?.Api,
        obras: [...obrasInfo]
      } as IStorageGroup);
    });

    return storageListGroup;
  };

  const changeArmazenamentoObra = (obraId: number, accountIdSelected?: number) => {
    setConexaoServicoVisible(true);
    setCsIdConexaoServico(obraId);
    setAccountIdSelected(accountIdSelected);
  };

  const onCancel = () => {
    setConexaoServicoVisible(false);
  };

  useEffect(() => {
    if (!props.minhaConta || isObjectEmpty(props.minhaConta))
      dispatch(AuthActions.getUserInfo());
  }, []);

  return (
    <div>
      {props.minhaConta.CurrentRoleFk === 1 || props.minhaConta.CurrentRoleFk === 7 ? (
        <PageHeader name="Armazenamento">
          <BreadCrumb breadcrumbs={breadCrumb} />
          <div className={styles['armazenamentoContainer']}>
            {!props.StorageList ? (
              <Skeleton active />
            ) : (
              <>
                {storageListGroup?.map((storage) => (
                  <>
                    <div className={styles['storageGroup']}>
                      <div className={styles['apiIcon']}>
                        <Icon
                          icon={getIconApi(storage.api)}
                          customSize={20}
                          customColor={'#3547C8'}
                        />
                      </div>
                      <div className={styles['apiName']}>
                        {ApisEnumLabel[storage.api]}
                      </div>
                      <div className={styles['apiEmail']}>{storage.apiEmail}</div>
                    </div>

                    {storage?.obras?.map((obra) => (
                      <div className={styles['storageItem']}>
                        <div className={styles['obraIcon']}>
                          <GetAvatar
                            name={obra.name}
                            shape='circle'
                            size={40}
                            src={obra.image}
                          />
                        </div>
                        <div className={styles['obraName']}>{obra.name}</div>
                        <div className={styles['storageItemBtn']}>
                          <Icon
                            icon={'menuConfiguracoes'}
                            customSize={24}
                            customColor={'#B3C0CE'}
                            onClick={() => changeArmazenamentoObra(obra.id, storage.id)}
                          />
                        </div>
                      </div>
                    ))}
                    <br />
                  </>
                ))}
              </>
            )}
          </div>
        </PageHeader>
      ) : (
        <>
          {props.minhaConta.CurrentRoleFk === 4 ||
            props.minhaConta.CurrentRoleFk === 3 ||
            props.minhaConta.CurrentRoleFk === 5 ? (
            <Redirect to="/obras" />
          ) : (
            <></>
          )}
        </>
      )}

      {noConnectionListGroup[0]?.obras?.length ? (
        <PageHeader
          name="Obras sem conexão de armazenamento"
          showCount
          count={noConnectionListGroup[0]?.obras.length}
          isCollapsiable
          style={{ marginTop: -35, marginBottom: -15, paddingLeft: 10 }}
        >
          <div className={styles['armazenamentoContainer']}>
            {noConnectionListGroup[0]?.obras.map((obra) => (
              <div className={styles['storageItem']}>
                <div className={styles['obraIcon']}>
                  <GetAvatar
                    name={obra.name}
                    shape='circle'
                    size={40}
                    src={obra.image}
                  />
                </div>
                <div className={styles['obraName']}>{obra.name}</div>
                <div className={styles['storageItemBtn']}>
                  <Icon
                    icon={'menuConfiguracoes'}
                    customSize={24}
                    customColor={'#B3C0CE'}
                    onClick={() => changeArmazenamentoObra(obra.id, 0)}
                  />
                </div>
              </div>
            ))}
          </div>
        </PageHeader>
      ) : (
        ''
      )}

      <ConexaoServico
        csId={csIdConexaoServico}
        isVisible={conexaoServicoVisible}
        onCancel={() => onCancel()}
        isRedirect={false}
        accountIdSelected={accountIdSelected}
      />
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  storageList: state.obra.StorageList,
  minhaConta: state.minhaConta.userInfo,
  currentTenant: state.tenant.currentListTenant,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Armazenamento);
