import { IHistoryState } from './interfaces/IHistoryState';
import { HistoryActionKeys, HistoryActionUnion } from './History.actions';
import { IHistoryFileComponet, IHistoryFileList } from 'Data/interfaces/History/IHistoryFileRecord';
import { IHistoryActivityComponet, IHistoryActivityList } from 'Data/interfaces/History/IHistoryActivityRecord';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/pt-br";
import { IHistoryFile } from 'Data/interfaces/History/IHistoryFileList';
import { IHistoryActivity } from 'Data/interfaces/History/IHistoryActivityList';

dayjs.locale('pt-br');
dayjs.extend(utc);

const initialState: IHistoryState = {
  isLoading: false,
  error: undefined,
  message: undefined,
  historyDrawerVisible: false,
  currentHistoryFileList: null,
  currentHistoryActivityList: null,
};

const HistoryReducer = (state = initialState, action: HistoryActionUnion): IHistoryState => {
  switch (action.type) {
    case HistoryActionKeys.SHOW_HISTORY_DRAWER:
      return {
        ...state,
        historyDrawerVisible: action.payload,
      };

    case HistoryActionKeys.LIST_HISTORY_FILE_REQUEST:
      return {
        ...state,
        historyDrawerVisible: true,
        currentHistoryFileList: null,
        isLoading: true,
      };
    case HistoryActionKeys.LIST_HISTORY_FILE_SUCCESS:
      let newCurrentHistoryList: IHistoryFileList | null = null;

      if (action.payload) {
        const data: IHistoryFileComponet = {};
        let historyContent: IHistoryFile[] = [];

        const orderedHistories = action.payload.histories.sort((a, b) => {
          return dayjs(a.DateTimeUtc).isAfter(dayjs(b.DateTimeUtc)) ? 1 : -1;
        })
        for (let i = 0; i < orderedHistories.length; i++) {
          const DateTimeUtcFormated = dayjs(orderedHistories[i].DateTimeUtc).utc(true);
          const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');
          const first = i === 0;
          const last = i === orderedHistories.length - 1;
          let titleOn = false;

          if (!data.hasOwnProperty(dataFormated)) {
            data[dataFormated] = [];
            titleOn = true;
          }

          data[dataFormated].push({
            ...orderedHistories[i],
            DateTimeTitle: dataFormated,
            DateTimeUtcFormated,
            titleOn,
            first,
            last,
          });
        }

        for (const histories of Object.values(data)) {
          historyContent = [...historyContent, ...histories];
        }

        newCurrentHistoryList = {
          parentName: action.payload.parentName,
          title: action.payload.title,
          historyContent,
        };
      }

      return {
        ...state,
        currentHistoryFileList: newCurrentHistoryList,
        isLoading: false,
      };
    case HistoryActionKeys.LIST_HISTORY_FILE_FAILURE:
      return {
        ...state,
        historyDrawerVisible: false,
        currentHistoryFileList: null,
        isLoading: false,
      };

    case HistoryActionKeys.LIST_HISTORY_ACTIVITY_REQUEST:
      return {
        ...state,
        currentHistoryActivityList: null,
        isLoading: true,
      };
    case HistoryActionKeys.LIST_HISTORY_ACTIVITY_SUCCESS:
      let newCurrentHistoryAtivityList: IHistoryActivityList | null = null;

      if (action.payload) {
        const data: IHistoryActivityComponet = {};
        let historyContent: IHistoryActivity[] = [];

        const orderedHistories = action.payload.histories.sort((a, b) => {
          return dayjs(a.DateTimeUtc).isAfter(dayjs(b.DateTimeUtc)) ? 1 : -1;
        })
        for (let i = 0; i < orderedHistories.length; i++) {
          const DateTimeUtcFormated = dayjs(orderedHistories[i].DateTimeUtc).utc(true);
          const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');
          const first = i === 0;
          const last = i === orderedHistories.length - 1;
          let titleOn = false;

          if (!data.hasOwnProperty(dataFormated)) {
            data[dataFormated] = [];
            titleOn = true;
          }

          data[dataFormated].push({
            ...orderedHistories[i],
            DateTimeTitle: dataFormated,
            DateTimeUtcFormated,
            titleOn,
            first,
            last,
          });
        }

        for (const histories of Object.values(data)) {
          historyContent = [...historyContent, ...histories];
        }

        newCurrentHistoryAtivityList = {
          historyContent,
        };
      }

      return {
        ...state,
        currentHistoryActivityList: newCurrentHistoryAtivityList,
        isLoading: false,
      };
    case HistoryActionKeys.LIST_HISTORY_ACTIVITY_FAILURE:
      return {
        ...state,
        currentHistoryActivityList: null,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default HistoryReducer;
