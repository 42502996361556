import React, { FC, useEffect } from "react";
import PlanContainer from "Components/UI/Containers/PlanContainer/PlanContainer";
import PlanInfo from "../PlanInfo";
import logoMaletaCollapse from "../../../../Static/images/logo-maleta-collapse.png";
import styles from "./BoxPlan.module.scss";
import { numberToMoney } from "Utils/Money";
import { IStorePlanResponse } from "Data/interfaces/Store/IStorePlanResponse";

interface IBoxPlan {
  title?: string;
  plan?: IStorePlanResponse;
  buttonPlanControl?: React.ReactNode;
  buttonPlanDowngrade?: React.ReactNode;
  firstPrice?: number;
  buttonPlanCancel?: React.ReactNode;
  position?: boolean;
}

const BoxPlan: FC<IBoxPlan> = ({ 
  title,
  plan,
  buttonPlanControl,
  buttonPlanDowngrade,
  firstPrice,
  buttonPlanCancel,
  position
}) => {
  const advantage = plan?.Info.split('\n');

  return (
    <PlanContainer
      width="350px"
      heigth="100%"
      style={position ? { position: 'fixed' } : {}}
    >
      <div className={styles['title-box-plan']}>
        <h4>{title}</h4>
      </div>

      <div className={styles['description-box-plan']}>
        <div className={styles['logo-description']}>
          <img src={logoMaletaCollapse} alt="logo da maleta" />
          <span>{plan?.Name}</span>
        </div>
        <span>{plan?.Description}</span>
      </div>

      <div className={styles['info-box-plan']}>
        {advantage?.map((advn: string, index: number) => (
          <PlanInfo key={index} advantage={advn} />
        ))}
      </div>

      {!buttonPlanDowngrade && <div className={styles['price-box-plan']}>
        <span>TOTAL</span>
        {firstPrice && <div  className={styles['price-box']}>
          <span className={styles['price-text']}>{numberToMoney(firstPrice, 'real')}</span>
          <span className={styles['price-text-last']}>/mês</span>
        </div>}

        {!firstPrice && <div>
          {plan?.Price === 0 ? (
            plan?.isCustom === 1 ? <span className={styles['price-text']}>Sob Consulta</span>
            : <span className={styles['price-text']}>Gratuito</span>
          ) : (
            <div  className={styles['price-box']}>
              <span className={styles['price-text']}>{numberToMoney(plan?.Price, 'real')}</span>
              <span className={styles['price-text-last']}>/mês</span>
            </div>
          )}
        </div>}
      </div>}

      {buttonPlanControl && 
        <div className={styles['button-box-plan']}>
          {buttonPlanControl}
        </div>
      }

      {buttonPlanDowngrade && 
        <div className={styles['button-box-downgrade']}>
          {buttonPlanDowngrade}
        </div>
      }

      {buttonPlanCancel && 
        <div className={styles['button-box-downgrade']}>
          {buttonPlanCancel}
        </div>
      }
    </PlanContainer>
  )
}

export default BoxPlan;
