import { FC, useEffect, useState } from 'react';
import styles from './Signup.module.scss';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Button from '../../../Components/UI/Button';
import Background from '../Backgroud/background';
import { Checkbox } from 'antd';
import { useLocation } from 'react-router-dom';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import Form from '../../../Components/UI/Form/Form';
import { IRegisterRequest } from '../../../Data/interfaces/Auth/IRegisterRequest';
import schema, { schemaPassword } from './signup.schema';
import { Field as FieldReact } from 'react-final-form';
import Field from '../../../Components/UI/Field';
import FloatingFeedback from '../../../Components/UI/Input/TextInput/FloatingFeedback/FloatingFeedback';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { TenantActions } from '../../../Store/Tenant/Tenant.actions';
import GoogleLinkButton from '../../../Components/UI/GoogleLinkButton';
import Select from '../../../Components/UI/Select';
import Link from 'Components/UI/Link';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { getDomain } from 'Store/MultiDomain/MultiDomain.selector';

export interface ISignup {
  match: any;
}

const Signup: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);
  const termsOfUseUrlFlag = useFeatureFlag('terms-of-use-url');
  const termsOfUseUrl = (termsOfUseUrlFlag.enabled && typeof termsOfUseUrlFlag.value === 'string') 
    ? termsOfUseUrlFlag.value 
    : (process.env.REACT_APP_TERMS_OF_USE_URL || '');
  const privacyPolicesUrlFlag = useFeatureFlag('privacy-policies-url');
  const privacyPolicesUrl = (privacyPolicesUrlFlag.enabled && typeof privacyPolicesUrlFlag.value === 'string') 
    ? privacyPolicesUrlFlag.value 
    : (process.env.REACT_APP_PRIVACY_POLICIES_URL || '');

  const [isPositionCompany, setPositionCompany] = useState<any>();
  const [errorPosition, setErrorPosition] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(AuthActions.listCompanyPosition());
  }, [dispatch]);

  useEffect(() => {
    if (isPositionCompany) setErrorPosition(undefined);
  }, [isPositionCompany]);

  const companyPositions = props.listCompanyPosition?.map((role) => ({
    value: role.CompanyPositionId,
    description: role.Name,
    label: role.Name
  }));

  const signup = (data: IRegisterRequest) => {
    if (!isPositionCompany) {
      setErrorPosition('O campo deve ser selecionado');
      return;
    }

    if (props.tenantInviteInfo?.EmailHash) {
      data.tenantInviteEmailHash = props.tenantInviteInfo.EmailHash;
    }
    data.companyPositionId = isPositionCompany;
    dispatch(AuthActions.userRegister(data));
  };

  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(
    undefined
  );
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState<boolean>(false);

  const { pathname } = useLocation();

  const validField = (meta: any) => {
    return !meta.submitFailed ? 'default' : meta.valid ? 'valid' : 'error';
  };

  useEffect(() => {
    if (props.match.params.emailHash) {
      dispatch(TenantActions.getTenantInviteInfo(props.match.params.emailHash));
    }
  }, [dispatch, props.match]);

  return (
    <Background>
      <BoxContainer height={domain?.LogoUrl ? 646 : 636} width={372}>
        <div className={styles['contentWrapper']}>
          <h2 className={styles['title']}>Criar uma conta</h2>
          <Form schema={schema} onSubmit={signup}>
            <Field
              name="nome"
              label="Nome"
              value={props.tenantInviteInfo?.InvitedName}
              icon="menuGerenciarUsuario"
              placeholder="Insira seu nome"
            />
            <Select
              onSelect={setPositionCompany}
              className={styles['selectPositionCompany']}
              iconClassName={styles['selectIcon']}
              placeholder="Qual seu cargo?"
              options={companyPositions}
              iconInput="buscar"
              size="large"
              error={errorPosition}
            />
            <Field
              className={styles['emailCad']}
              name="email"
              label="E-mail"
              icon="arroba"
              disabled={props.match.params.emailHash}
              value={props.tenantInviteInfo?.InvitedEmail}
              placeholder="usuario@dominio.com.br"
            />
            <Field
              name="password"
              label="Senha"
              icon="cadeado"
              type="password"
              onInput={setPassword}
              onBlur={() => setPasswordFocus(false)}
              onFocus={() => setPasswordFocus(true)}
            />
            <FloatingFeedback
              name="password"
              schema={schemaPassword}
              inputValue={password}
              visible={passwordFocus}
              className={styles['passwordFeedback']}
            />

            <Field
              name="confirmPassword"
              label="Confirme a senha"
              icon="cadeado"
              type="password"
              onInput={setConfirmPassword}
              onBlur={() => setConfirmPasswordFocus(false)}
              onFocus={() => setConfirmPasswordFocus(true)}
            />
            <FloatingFeedback
              name="confirmPassword"
              schema={schemaPassword}
              inputValue={confirmPassword}
              visible={confirmPasswordFocus}
              className={styles['confirmPasswordFeedback']}
              context={{ password }}
            />

            <FieldReact name="isTermsAccepted" type="checkbox">
              {(propsField) => (
                <Checkbox
                  className={`${styles['checkbox']}
                                        ${styles[validField(propsField.meta)]}`}
                  {...propsField.input}
                >
                  <span>Declaro que li e concordo com os </span>
                  <Link
                    to={termsOfUseUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    isExternal={true}
                  >
                    Termos de uso
                  </Link>
                  <span> e as </span>
                  <Link
                    to={privacyPolicesUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    isExternal={true}
                  >
                    Políticas de privacidade
                  </Link>
                </Checkbox>
              )}
            </FieldReact>

            <Button
              width="100%"
              type="primary"
              htmlType="submit"
              loading={props.isLoading}
              disabled={props.isLoading}
            >
              Criar conta
            </Button>
          </Form>

          <GoogleLinkButton
            emailHash={props.match.params.emailHash}
            redirectUrl={pathname}
          />

          <div className={styles['hasAccount']}>
            <span>
              Já tem uma conta?&nbsp;
              <Link
                to={`/login${
                  props.match.params.emailHash ? `/${props.match.params.emailHash}` : ''
                }`}
              >
                Faça o login agora mesmo
              </Link>
            </span>
          </div>

          {domain?.LogoUrl && (
            <p className={styles['powered-by']}>
              Powered by <span>Coordly</span>
            </p>
          )}
        </div>
      </BoxContainer>
    </Background>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
  tenantInviteInfo: state.tenant.TenantInviteInfo
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & ISignup;

export default connector(Signup);
