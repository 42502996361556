import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .customInput {
    height: 36px !important;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline} !important;
    padding-left: 48px !important;
    margin: 0 !important;
  }

  .customIcon {
    left: 18px !important;
    
    i {
      font-size: 14px !important;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }

  .mainHeader {
    width: 100%;
    min-height: 44px;
    max-height: 132px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    margin-top: 6px;
    background-color: ${({theme}) => theme.colors.secondary.secondary};
    overflow-y: auto;
    
    .menuHome {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
      cursor: pointer;
    }

    .rowHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #BEC2C6;
      
      .iconBreadCrumb {
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
      }

      .rowHeaderDiscipline {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        cursor: pointer;
        
        .iconLeft {
          color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
        }
      }

      &:last-child {
        .rowHeaderDiscipline {
          color: ${({theme}) => theme.colors.surface.onSurface};
  
          .iconLeft {
            color: ${({theme}) => theme.colors.surface.onSurface} !important;
          }
        }
      }
    }

    .iconBreadCrumb {
      transform: rotate(180deg);
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  max-height: 352px;
  margin-top: 6px;
  overflow-y: auto;

  .rowDisciplinesList {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }

    .leftRowDisciplinesList {
      display: flex;
      flex-direction: row;
      align-items: center;

      .iconLeftRowDisciplinesList {
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
      }

      .disciplineListText {
        margin: 0;
        margin-left: 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({theme}) => theme.colors.surface.onSurface};
      }

      .disciplineListTextOpaco {
        color: #BEC2C6;
      }
    }

    .iconExpanded {
      transform: rotate(180deg);
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }

  .rowFilesList {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }

    .leftRowFilesList {
      display: flex;
      flex-direction: row;
      align-items: center;

      .iconLeftRowFilesList {
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
      }

      .leftRowFilesListMain {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .filesListText {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          color: ${({theme}) => theme.colors.surface.onSurface};
        }

        .leftRowFilesListMainRight {
          display: flex;
          flex-direction: row;
          height: 14px;

          .renderTag {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }

          .statusTag {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }
          
          .dividerTags {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            margin: 0 4px;
            color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
          }
        }
      }

      .filesListTextOpaco {
        color: #BEC2C6;
      }
    }

    .iconExpanded {
      transform: rotate(180deg);
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 174px !important;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #EAEDF0;
  }
`;

export const FilesSelectedWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurface};

    .warn {
      color: ${({theme}) => theme.colors.danger.danger};
    }
  }

  .inputFiles {
    height: 32px !important;
    border-radius: 3px !important;
    border: 1px solid ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    background-color: ${({theme}) => theme.colors.secondary.secondary} !important;
    margin: 0 !important;
    margin-top: 6px !important;
  }

  .titleFile {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-top: 16px;

    .warn {
      color: ${({theme}) => theme.colors.danger.danger};
    }
  }
`;

export const RowFilesSelected = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  padding: 8px;
  padding-left: 12px;
  border-radius: 3px;
  background-color: ${({theme}) => theme.colors.secondary.secondary};

  .leftRowFilesSelected {
    display: flex;
    flex-direction: row;
    align-items: center;

    .iconLeftRowFilesSelected {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }

    .leftRowFilesSelectedMain {
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      .filesSelectedText {
        margin: 0;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({theme}) => theme.colors.secondary.onSecondary};
      }

      .leftRowFilesSelectedMainRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 16px;

        .iconleftRowFilesSelectedMainRight {
          color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
          margin-left: -4px;
        }

        .textleftRowFilesSelectedMainRight {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: ${({theme}) => theme.colors.secondary.onSecondaryVariant};
        }
      }
    }
  }

  .iconChange {
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    cursor: pointer !important;

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }
  }
`;
