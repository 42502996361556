import styled from "styled-components";

type ContainerProps = {
  height: number;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${({height}) => height - 290}px;
  display: flex;
  flex-direction: row;
  background-color: transparent;

  .rowWrapper {
    display: flex;
    flex-direction: column;
    width: 308px;
    min-width: 308px;
    max-width: 308px;
    height: ${({height}) => height - 290}px;
    padding: 16px 6px 0 6px;
    border-radius: 4px;
    background-color: #F2F2F2;
    
    &+.rowWrapper {
      margin-left: 12px;
    }
    
    .rowHeader {
      min-height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-left: 4px solid #EAEDF0;
      
      .rowHeaderLeft {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    
    .rowColumn {
      overflow-y: hidden;

      .rowCard {
        margin-top: 12px;
        background-color: #FFFFFF;
        border-left: 4px solid #EAEDF0;
        padding: 8px 8px 14px 11px;
        overflow-y: scroll;
        
        .rowCardHeader {
          display: flex;
          flex-direction: column;
          margin-top: 14.5px;
          margin-bottom: 14.5px;
          
          .RowCardHeaderHead {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
  
        .rowCircles {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
  
        .rowCardFooter {
          display: flex;
          flex-direction: column;
          margin-top: 14.5px;
          
          .RowCardFooterHead {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
  
            .RowCardFooterHeadLeft {
              width: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-right: 6px;
            }
            
            .RowCardFooterHeadRight {
              width: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 6px;
            }
          }
  
          .RowCardFooterMid {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 14.5px;
            margin-bottom: 14.5px;
          }
  
          .RowCardFooterFoot {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .RowCardFooterFootLeft {
              width: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-right: 6px;
            }
            
            .RowCardFooterFootRight {
              width: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 6px;
            }
          }
        }
      } 
    }
  }
`;
