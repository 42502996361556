import { FC, useEffect } from "react";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import Card from "Components/HistoryDrawer/components/Contents/ActivityOptions/Card";
import { useDispatch, useSelector } from "react-redux";
import { getTopicStatusOptions } from "Store/Activities/Activities.selector";
import {
  getCurrentHistoryActivityList,
  getHistoryIsLoading,
} from "Store/History/History.selector";
import LoadingHistoryActivity from "Components/UI/CustomLoading/LoadingHistoryActivity";
import { HistoryActions } from "Store/History/History.actions";
import { Virtuoso } from "react-virtuoso";
import { Content } from "./styles";

interface IHistory {
  csId: number;
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
}

const History: FC<IHistory> = ({
  csId,
  updateActivity,
  loadingExternalLink,
}) => {
  const dispatch = useDispatch();

  const topicStatusOptions = useSelector(getTopicStatusOptions);
  const currentHistoryActivityList = useSelector(getCurrentHistoryActivityList);
  const isLoading = useSelector(getHistoryIsLoading);

  useEffect(() => {
    if (updateActivity?.Guid) {
      dispatch(HistoryActions.listHistoryActivityRequest({
        topicId: updateActivity.Guid
      }));
    }
  }, [updateActivity?.Guid]);

  return (
    <Content loading={isLoading}>
      {isLoading && (
        <LoadingHistoryActivity multiple={8} />
      )}
      {!isLoading && currentHistoryActivityList?.historyContent && (
        <Virtuoso
          style={{ flex: 1 }}
          totalCount={currentHistoryActivityList?.historyContent.length}
          data={currentHistoryActivityList?.historyContent}
          initialTopMostItemIndex={currentHistoryActivityList?.historyContent.length - 1}
          itemContent={(index, history) => (
            <Card
              key={history.TopicId}
              item={history}
              topicStatusOptions={topicStatusOptions}
            />
          )}
          alignToBottom={true}
          followOutput="smooth"
        />
      )}
    </Content>
  );
}

export default History;
