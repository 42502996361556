import { FC, useEffect, useState } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import {
  attachmentsImagesAccepts,
  attachmentsImagesNotAccepts,
  maxAttachmentsUploadSize,
} from "Utils/ActivitiesUtils";
import UploadImg from "Components/UI/UploadImg";
import FileDrop from "../FileDrop";
import { useDispatch, useSelector } from "react-redux";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import {
  ITopicAttachment,
  ITopicDnDResponse,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import LimitsInfo from "./Modals/LimitsInfo";
import RegisterAttachments from "./components/RegisterAttachments";
import ListButtons, {
  TypeButton,
  TypeListButtons,
} from "Pages/Activities/components/ListButtons";
import AppStorage from 'Utils/AppStorage';
import GaleryAttachmentsVision from "./components/GaleryAttachmentsVision";
import ApresentationAttachmentsVision from "./components/ApresentationAttachmentsVision";
import ListAttachmentsVision from "./components/ListAttachmentsVision";
import ConfirmDeleteAttachment from "./Modals/ConfirmDeleteAttachment";
import { getIsLoadingAttachmentDeleting } from "Store/Activities/Activities.selector";
import { IAttachmentForUploadRequest } from "Data/interfaces/Activities/IAttachmentsForUpload";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";
import { getUserInfo } from "Store/Auth/Auth.selector";
import AccessPermission from "Utils/AcessPermission";
import { IUserData } from "Data/interfaces/User/IUserData";
import Tooltip from "Components/UI/Tooltip";
import LoadingAttachments from "Components/UI/CustomLoading/LoadingAttachments";
import { Content, Header, InfoDrop, Wrapper } from "./styles";

export const BUTTON_ATTACHMENTS_LIST_KEY = 'BUTTON_ATTACHMENTS_LIST_KEY';

const buttons: TypeButton = {
  'galery': {
    nameId: 'galery',
    placement: 'Galeria',
    icon: 'galery',
    iconSize: 16,
  },
  'list': {
    nameId: 'list',
    placement: 'Lista',
    icon: 'listList',
    iconSize: 16,
  },
  'apresentation': {
    nameId: 'apresentation',
    placement: 'Apresentação',
    icon: 'carousel',
    iconSize: 16,
  },
};

type ErrorType = 'extension' | 'size';

interface IAttachments {
  csId: number;
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
}

const Attachments: FC<IAttachments> = ({
  csId,
  updateActivity,
  loadingExternalLink,
}) => {
  const dispatch = useDispatch();
  const loadingDeleting = useSelector(getIsLoadingAttachmentDeleting);
  const currentTenant = useSelector(getCurrentTenant);
  const userInfo = useSelector(getUserInfo);

  const [dropFocus, setDropFocus] = useState(false);
  const [hasError, setHasError] = useState<ErrorType | null>(null);
  const [showRemove, setShowRemove] = useState<ITopicAttachment | null>(null);
  const [sentDeletingAttachment, setSentDeletingAttachment] = useState(false);
  const [activeListButton, setActiveListButton] = useState<TypeListButtons>(
    AppStorage.GetItem(BUTTON_ATTACHMENTS_LIST_KEY, 'local') as TypeListButtons || 'galery'
  );

  const isUpdate = !!updateActivity?.Guid;
  const showGalery = activeListButton === 'galery';
  const showList = activeListButton === 'list';
  const showApresentation = activeListButton === 'apresentation';

  const hasPermissionAddAttachment = AccessPermission.addAttachmentsPermission(
    isUpdate,
    userInfo,
    updateActivity?.CreationAuthor,
    updateActivity?.TopicAssignedUsers,
    updateActivity?.Visibility,
  );

  const handleActiveListButton = (value: TypeListButtons) => {
    setActiveListButton(value);
  };

  const handleChange = (files: File | File[]) => {
    if (Array.isArray(files) && files.length > 0) {
      if (!files.every(file => attachmentsImagesAccepts.includes(file.type))) {
        return setHasError('extension');
      }
      if (files.some(file => file.size > maxAttachmentsUploadSize)) {
        return setHasError('size');
      }

      const request: IAttachmentForUploadRequest[] = [];
      for (const file of files) {
        request.push({
          topicId: updateActivity?.Guid,
          constructionSiteId: updateActivity?.ConstructionSiteFk || csId,
          tenantId: userInfo?.CurrentTenantContext || currentTenant?.TenantId || 0,
          file,
          userInfoTracking: userInfo,
          tenantTracking: currentTenant,
        });
      }
      dispatch(ActivitiesActions.addAttachmentsForUpload(request));
    }
  };

  const handleCancel = (attachmentId: string | number) => {
    dispatch(ActivitiesActions.notShowAttachmentCancelError(true));

    return dispatch(ActivitiesActions.removeAttachmentActivity(attachmentId));
  };

  const handleHasPermissionDeleteAttachment = (creationUploadFk?: IUserData | null) => {
    const result = AccessPermission.deleteAttachmentsPermission(
      userInfo,
      creationUploadFk,
      updateActivity?.Visibility,
    );

    return result;
  }

  const handleDelete = (attachment?: ITopicAttachment | null) => {
    if (attachment) {
      const hasPermission = handleHasPermissionDeleteAttachment(attachment.File.CreatorUser);

      if (hasPermission) {
        return setShowRemove(attachment);
      }
    }

    return;
  };

  const confirmDelete = (attachment: ITopicAttachment) => {
    if (isUpdate) {
      dispatch(ActivitiesActions.deleteAttachment({
        topicFk: attachment.TopicFk,
        topicAttachmentId: attachment.TopicAttachmentId,
      }));

    } else {
      dispatch(ActivitiesActions.deleteAttachmentForCreate({
        fileId: attachment.FileFk,
      }));
    }

    setSentDeletingAttachment(true);
  };

  useEffect(() => {
    if (sentDeletingAttachment && !loadingDeleting) {
      setSentDeletingAttachment(false);
      setShowRemove(null);
    }
  }, [loadingDeleting, sentDeletingAttachment]);

  return (
    <Wrapper>
      {!loadingExternalLink && !isUpdate && (
        <RegisterAttachments
          dropFocus={dropFocus}
          hasPermissionAddAttachment={hasPermissionAddAttachment}
          onChange={handleChange}
          onCancel={handleCancel}
          onDelete={handleDelete}
        />
      )}
      {(loadingExternalLink || isUpdate) && (
        <FileDrop
          borderRadius={5}
          info={(
            <InfoDrop>
              <Icon
                icon="upload"
                className="iconInfoDrop"
                customSize={24}
              />
              <span className="infoDropText">
                Solte os arquivos aqui para anexar
              </span>
            </InfoDrop>
          )}
          onDropFiles={handleChange}
          onFocus={setDropFocus}
          isDisabled={loadingExternalLink || !hasPermissionAddAttachment}
        >
          <Header>
            <ListButtons
              storageKey={BUTTON_ATTACHMENTS_LIST_KEY}
              buttons={buttons}
              activeButton={activeListButton}
              onActiveOption={handleActiveListButton}
              disabled={loadingExternalLink}
            />

            {!AccessPermission.isConsultor() && (
              <UploadImg
                imagesAccepts={attachmentsImagesAccepts.join(', ')}
                imagesNotAccepts={attachmentsImagesNotAccepts}
                button={(
                  <Tooltip
                    placement="top"
                    overlay={
                      hasPermissionAddAttachment
                        ? undefined
                        : 'Você não tem permissão de enviar anexos nessa atividade.'
                    }
                  >
                    <Button
                      type="primary"
                      className="uploadBtn"
                      disabled={loadingExternalLink || !hasPermissionAddAttachment}
                    >
                      <Icon
                        icon="upload"
                        color="primary"
                        customSize={14}
                        className="uploadIcon"
                        disabled={!hasPermissionAddAttachment}
                      />
                      Enviar arquivo
                    </Button>
                  </Tooltip>
                )}
                onChange={handleChange}
              />
            )}
          </Header>

          <Content>
            {loadingExternalLink && (
              <LoadingAttachments keyList={activeListButton} />
            )}
            {!loadingExternalLink && (
              <>
                {showGalery && (
                  <GaleryAttachmentsVision
                    updateActivity={updateActivity}
                    onHasPermissionDeleteAttachment={handleHasPermissionDeleteAttachment}
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                  />
                )}
                {showList && (
                  <ListAttachmentsVision
                    updateActivity={updateActivity}
                    onHasPermissionDeleteAttachment={handleHasPermissionDeleteAttachment}
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                  />
                )}
                {showApresentation && (
                  <ApresentationAttachmentsVision
                    updateActivity={updateActivity}
                    onHasPermissionDeleteAttachment={handleHasPermissionDeleteAttachment}
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                  />
                )}
              </>
            )}
          </Content>
        </FileDrop>
      )}
      {!!hasError && (
        <LimitsInfo
          visible={!!hasError}
          isExtensionLimit={hasError === 'extension'}
          onCancel={() => setHasError(null)}
        />
      )}
      {!!showRemove && (
        <ConfirmDeleteAttachment
          visible={!!showRemove}
          attachment={showRemove}
          loading={loadingDeleting}
          onCancel={() => setShowRemove(null)}
          onConfirm={() => confirmDelete(showRemove)}
        />
      )}
    </Wrapper >
  );
}

export default Attachments;
