
import { ColumnProps } from 'antd/lib/table';

export const columnsTablePlot: Array<ColumnProps<any>> = [
    {
        title: 'Solicitação',
        dataIndex: 'Description'
    },
    {
        title: 'Entregar para',
        dataIndex: 'PlotterResponsibleName'
    },
];
