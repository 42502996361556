import { FC } from "react";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { GetAvatar } from "Utils/generateThumbnail";
import Ellipsis from "Components/UI/Ellipsis";
import { Icon } from "Components/UI";
import { Container } from "./styles";
import { history } from "Store";

interface IObraCard {
  obra: IObraResponse;
  shorter?: boolean;
  onOpen: (csId: number) => void;
}

const ObraCard: FC<IObraCard> = ({ obra, shorter, onOpen }) => {
  const openObra = (csId: number) => {
    history.push(`/obras/detail/${csId}`);
  };

  return (
    <Container onClick={() => openObra(obra.ConstructionSiteId)}>
      <span className="avatarContainer">
        <GetAvatar
          name={obra.Name}
          shape='circle'
          size={28}
          src={obra?.OriginalImageUrl || obra?.AvatarFileName}
          thumbSrc={obra?.ThumbnailImageUrl}
          thumbType="small"
        />

        <Ellipsis
          className="nameContainer"
          phrase={obra.Name}
          maxLength={shorter ? 23 : 65}
        />
      </span>

      <div
        className="iconContainer"
        onClick={(event) => {
          event.stopPropagation();
          onOpen(obra.ConstructionSiteId);
        }}
      >
        <Icon icon="menuConfiguracoes" className="iconEdit"/>
      </div>
    </Container>
  )
}

export default ObraCard;
