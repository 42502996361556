import styled, { css } from "styled-components";

interface IContentRenderizando {
  iserror: string;
}

export const ContentRenderizando = styled.div<IContentRenderizando>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;

  .infoRenderizando {
    display: flex;
    flex-direction: row;
    align-items: center;

    .circularProgress {
      width: 80px;
    }

    .infoEtapas {
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      p {
        margin: 0;
        font-size: 30px;
      }
    }
  }

  ${({ iserror, theme }) => iserror === 'true' && css`
    .infoRenderizando {
      .infoEtapas {
        margin-top: 7px;

        label {
            display: none;
        }

        p {
            color: ${theme.colors.danger.danger} !important;
        }
      }
    }
  `}
`;
