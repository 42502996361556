import styled from "styled-components";
import { Dropdown } from "antd";

export const ProfileStyled = styled(Dropdown)`
  display: flex;
  align-items: center;
  height: 32px;
  cursor: default;

  .userName {
    font-size: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    font-weight: 600;
    width: max-content;
    margin-top: 20px;
    margin-left: 15px;
    cursor: pointer;
  }

  .hiddenValue{
    display: none !important;
  }

  img {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    line-height: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }

  .icon {
    margin-left: 8px;
    margin-top: 5px;
    cursor: pointer;
  }
`;
