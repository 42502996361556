import { FC, useEffect, useState } from 'react';
import styles from './Login.module.scss';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Field from '../../../Components/UI/Field';
import Button from '../../../Components/UI/Button';
import Background from '../Backgroud/background';
import { AuthActionKeys } from '../../../Store/Auth/Auth.actions';
import { Checkbox } from 'antd';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import schema from './login.schema';
import { ILoginRequest } from '../../../Data/interfaces/Auth/ILoginRequest';
import AppStorage from '../../../Utils/AppStorage';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import Form from '../../../Components/UI/Form/Form';
import { TenantActions } from '../../../Store/Tenant/Tenant.actions';
import GoogleLinkButton from '../../../Components/UI/GoogleLinkButton';
import { Http } from 'Utils/Http';
import toastHandler from 'Utils/toastHandler';
import Link from 'Components/UI/Link';
import poweredBy from '../../../Static/images/powered-by.png';
import { getDefaultInfoTheme, getDomain, getDomainLoading } from 'Store/MultiDomain/MultiDomain.selector';
import { loadImage } from 'Utils/MultidomailUtils';

interface ILogin {
  match: any;
}

const Login: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);
  const defaultInfoTheme = useSelector(getDefaultInfoTheme);
  const domainLoading = useSelector(getDomainLoading);

  const [msgErro, setMsgErro] = useState(Http.GetQueryParams('erro'));
  const location = useLocation();
  const redirectUrl = window.location.origin + location.pathname + location.search;

  if (msgErro) {
    toastHandler.showError(msgErro);
    setMsgErro(null);
    window.history.pushState('data', 'Coordly', '/login');
  }

  useEffect(() => {
    if (props.match.params.emailHash) {
      dispatch(TenantActions.getTenantInviteInfo(props.match.params.emailHash));
    }
  }, [dispatch, props.match]);

  const loginSubmit = (values: ILoginRequest) => {
    if (props.match.params.emailHash) {
      values.TenantInviteEmailHash = props.match.params.emailHash;
    }

    props.login({ beforeUrl: redirectUrl, ...values });
  };

  return (
    <Background loading={domainLoading}>
      <div className={styles['containerWrapper']}>
        <BoxContainer height={630} width={805}>
          <div className={styles['contentWrapper']}>
            <div
              className={styles['halfWrapper']}
              style={{
                background: domainLoading 
                  ? '' 
                  :`url(${loadImage(domain?.LoginBannerUrl || defaultInfoTheme.LoginBannerUrl)}) no-repeat center/contain`,
              }}
            ></div>
            <div className={`${styles['halfWrapper']} ${styles['formLoginWrapper']}`}>
              <div className={styles['logo-container']}>
                {!domainLoading && (
                  <img
                    alt="logo"
                    src={loadImage(domain?.LogoUrl || defaultInfoTheme.LogoUrl)}
                    style={{ maxWidth: 100, maxHeight: 60 }}
                  />
                )}
                {domain?.LogoUrl && (
                  <img src={poweredBy} alt="decorative powered by logo" />
                )}
              </div>

              <h2 className={styles['titleLogin']}>
                <div style={{ fontFamily: domain.theme.font?.name }}>Bem-vindo a</div>
                <div
                  style={{
                    fontFamily: domain.theme.font?.name,
                    color: domain.theme.colors.primary.primary
                  }}
                >
                  {domainLoading 
                    ? '' 
                    : (domain.Tenant?.CompanyName || defaultInfoTheme.Tenant?.CompanyName)
                  }
                </div>
              </h2>
              <h3
                style={{ fontFamily: domain.theme.font?.name }}
                className={styles['subtitleLogin']}
              >
                Bem-vindo de volta! Por favor, insira seus dados para realizar o login no
                sistema
              </h3>

              <Form schema={schema} onSubmit={loginSubmit}>
                <Field
                  name="username"
                  label="E-mail"
                  value={props.tenantInviteInfo?.InvitedEmail}
                  icon="menuGerenciarUsuario"
                  placeholder="usuario@dominio.com.br"
                  disabled={props.match.params.emailHash}
                />
                <Field
                  name="password"
                  label="Senha"
                  icon="cadeado"
                  type="password"
                />

                <div className={styles['esqueceuSenha']}>
                  <Checkbox onChange={(e) => AppStorage.SetRememberMe(e.target.checked)}>
                    <span
                      style={{
                        fontFamily: domain.theme.font?.name,
                      }}
                    >
                      Lembrar dados
                    </span>
                  </Checkbox>
                  <Link to="/forgotpassword">
                    Esqueceu a senha?
                  </Link>
                </div>
                <Button
                  width="100%"
                  type="primary"
                  htmlType="submit"
                  loading={props.isLoading}
                  domainloading={`${domainLoading}`}
                >
                  Login
                </Button>
              </Form>

              <GoogleLinkButton
                emailHash={props.match.params.emailHash}
                redirectUrl={redirectUrl}
              />
              <div
                className={styles['novoCadastro']}
                style={{ fontFamily: domain.theme.font?.name }}
              >
                Ainda não tem uma conta?{' '}
                <Link
                  to={`/signup${
                    props.match.params.emailHash ? `/${props.match.params.emailHash}` : ''
                  }`}
                >
                  Crie agora mesmo
                </Link>
              </div>
            </div>
          </div>
        </BoxContainer>
      </div>
    </Background>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
  tenantInviteInfo: state.tenant.TenantInviteInfo
});
const mapDispatch = {
  login: (data: ILoginRequest) => ({
    type: AuthActionKeys.AUTH_LOGIN_REQUEST,
    payload: data
  })
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ILogin;

export default connector(Login);
