import React, { FC } from "react";

import styles from "./LoadingObras.module.scss";

import SkeletonMain from "../LoadingMain";

export interface ILoadingObras {
  multiple?: number;
}

const LoadingObras: FC<ILoadingObras> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (
        <div key={index.toString()} className={styles['main']}>
          <div className={styles['header']}>
            <SkeletonMain width="224px" height="135px" borderRadius="4px" />
          </div>
          
          <div className={styles['midle']}>
            <SkeletonMain width="194px" height="24px" borderRadius="4px" />
            <SkeletonMain width="97px" height="12px" borderRadius="4px" />
          </div>
          
          <div className={styles['footer']}>
            <SkeletonMain width="28px" height="28px" borderRadius="50%"/>
            <SkeletonMain width="28px" height="28px" borderRadius="50%"/>
            <SkeletonMain width="28px" height="28px" borderRadius="50%"/>
          </div>
        </div>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
    </>
  )
}

export default LoadingObras;