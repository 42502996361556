export interface IDisciplineSAStatusResponse {
  ConstructionSiteDisciplineId: number;
  disciplineSAStatus: FileStatusInStorageEnum;
  existingFolderApiId: string;
}

export enum FileStatusInStorageEnum {
  Ok = 0,
  Trashed = 1,
  Deleted = 2,
  Recreated = 3
}
