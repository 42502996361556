import React, { CSSProperties, FC } from 'react';

import { Select as SelectAnt, Divider } from 'antd';
import { SelectProps as SelectPropsAnt } from 'antd/es/select';
import { Field as FieldReact } from 'react-final-form';
import { IconName, Icon } from '../../UI';
import styles from './SelectForm.module.scss';
import './select.css';
const { Option } = SelectAnt;

export interface IOptionData {
  value?: string | number;
  description: React.ReactNode | string;
  label: string;
  removed?: boolean;
  isDivider?: boolean;
  customClass?: string;
  color?: string;
  icon?: IconName;
}

interface ISelectProps extends SelectPropsAnt {
  options: IOptionData[];
  iconInput?: IconName;
  name: string;
  style?: CSSProperties

  onBlur?: () => void;
  onSelect?: (e: any) => void;
}

const Select: FC<ISelectProps> = (props) => {
  const [focus, setFocus] = React.useState(false);

  return (
    <FieldReact name={props.name} defaultValue={props.defaultValue} initialValue={props.value}>
      {
        (propsField) => (
          <div
            className={styles['selectWrap']}
            onBlur={() => setFocus(false)}
            onClick={() => setFocus(!focus)}
            style={props.style}
          >
            {
              props.iconInput
                ? (
                  <Icon
                    icon={props.iconInput}
                    color='cinzaIcone'
                    customSize={14}
                    className={styles['iconInput']}
                  />
                )
                : <></>
            }
            <Icon
              icon="expandir"
              color="cinzaSuperior"
              customSize={10}
              className={`${styles['iconSelect']} ${focus ? styles['focus'] : ''}`}
            />
            <SelectAnt
              {...propsField.input}
              value={propsField.input.value?.toString()?.length === 0 ? undefined : propsField.input.value}
              showArrow={false}
              getPopupContainer={(node) => (node.parentNode || document) as HTMLElement}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              onBlur={() => props.onBlur && props.onBlur()}
              onSelect={(e: any) => props.onSelect && props.onSelect(e)}
            >
              {props.options
                .filter((option: IOptionData) => !option.removed)
                .map((option: IOptionData, index: number) => (
                  <Option
                    label={option.label}
                    value={option.value}
                    className={`${styles['SelectText']}`}
                    key={`option${index}`}
                  >
                    {option.isDivider ? (
                      <Divider
                        style={{ color: '#828282', marginTop: "-1%", width: 200, right: 30 }}
                      />
                    ) : (
                      <></>
                    )}
                    {option.icon ? (
                      <div className={`${styles['iconSelectBeforeText']}`}>
                        <Icon
                          icon={option.icon}
                          color={'cinzaOpaco'}
                        />
                        {option.description}
                      </div>
                    ) : (
                      <p>{option.description}</p>
                    )}
                  </Option>
                ))}
            </SelectAnt>
            <Icon
              icon="expandir"
              color="cinzaSuperior"
              customSize={10}
              className={`${styles['iconSelect']} ${focus ? styles['focus'] : ''}`}
            />
          </div>
        )
      }
    </FieldReact>
  );
};

export { Option };
export default Select;
