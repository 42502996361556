import React, { FC } from "react";
import { Icon } from "Components/UI";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { List } from "antd";
import HighlighterWords from "Components/UI/HighlightWords";
import { IFileData } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { isValid3DExtension } from "Utils/ViewerUtils";
import { iconFileByExtension } from "Utils/Icons";
import styles from '../FileDestination.module.scss';

interface IFileDestinationListChildren {
  filteredFiles: IFileData[];
  fromMaleta?: boolean;
  selectedFileFromMaleta: IFileData | null;
  onOpenFolder: (folderId: string, ConstructionSiteDisciplinesId?: number, folderName?: string) => void;
  disciplineFolder: IDisciplineViewerList | null;
  onConfirmFileFromMaleta: (item: IFileData) => void;
  searchFile: string;
  excludeListFiles?: string[];
}

const FileDestinationListChildren: FC<IFileDestinationListChildren> = ({
  filteredFiles,
  fromMaleta,
  selectedFileFromMaleta,
  onOpenFolder,
  disciplineFolder,
  onConfirmFileFromMaleta,
  searchFile,
  excludeListFiles,
}) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={filteredFiles}
      split={false}
      renderItem={(item: IFileData) => (
        <List.Item
          className={`
            ${styles['rowDisciplinesList']}
            ${fromMaleta && !item.IsFolder && !item.inObsoleteFolder && !isValid3DExtension(item.Extension) && excludeListFiles?.includes(item.Identifier.ApiId || item.Identifier.ItemId) ? styles['disabledRowDisciplinesList'] : ''}
            ${((item.Identifier.ApiId || item.Identifier.ItemId) === (selectedFileFromMaleta?.Identifier.ApiId || selectedFileFromMaleta?.Identifier.ItemId)) ? styles['selectedFileList'] : ''}
          `}
          onClick={() => (item.IsFolder || item.inObsoleteFolder) 
            ? onOpenFolder(
                item.Identifier.ApiId || item.Identifier.ItemId,
                disciplineFolder?.ConstructionSiteDisciplinesId,
                item.Name,
              )
            : (fromMaleta && isValid3DExtension(item.Extension) && !excludeListFiles?.includes(item.Identifier.ApiId || item.Identifier.ItemId))
              ? onConfirmFileFromMaleta(item)
              : undefined
          }
        >
          <div className={styles['leftRowDisciplinesList']}>
            <Icon 
              icon={item.IsFolder
                ? 'arquivar'
                : (item.inObsoleteFolder
                  ? 'obsoleteArchive'
                  : iconFileByExtension(item.Extension)
                )} 
              customSize={18} className={styles['']} 
              customColor={((item.Identifier.ApiId || item.Identifier.ItemId) === (selectedFileFromMaleta?.Identifier.ApiId || selectedFileFromMaleta?.Identifier.ItemId))
                ? "#3547C8" : "#BEC2C6"
              } 
            />
            <HighlighterWords 
              text={item.Name} 
              maxLength={65}
              wordSearched={searchFile} 
              className={`${styles['disciplineListText']} 
                ${((item.IsFolder || item.inObsoleteFolder) || (fromMaleta && isValid3DExtension(item.Extension) && !excludeListFiles?.includes(item.Identifier.ApiId || item.Identifier.ItemId))) 
                  ? ''
                  : styles['disciplineListTextOpaco']}  
              `}
            />
          </div>
          {(item.IsFolder || item.inObsoleteFolder) && (
            <Icon 
              icon="retroceder" 
              customSize={8} 
              className={styles['iconExpanded']} 
              color={(item.IsFolder || item.inObsoleteFolder) 
                ? "cinzaSuperior"
                : "cinzaOpaco"
              } 
            />
          )}
        </List.Item>
      )}
    />
  )
}

export default FileDestinationListChildren;
