import { 
  ITopicDnDResponse,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { 
  PtBrTopicPriorityEnum, 
  PtBrTopicVisibilityEnum, 
  TopicPriorityEnum, 
  TopicVisibilityEnum,
} from "Data/interfaces/Activities/ITopicResponse";
import { dateToLocaleString } from "./DateUtils";
import { 
  IPriorityOrVisibility,
} from "Pages/Activities/components/KanbanVision/components/Item";
import dayjs, { Dayjs } from "dayjs";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import { 
  IEnumMap, 
  IFilters, 
  IFiltroOptions,
} from "Data/interfaces/Activities/IFilters";

export const sortActivities = (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
  if (a.Priority && b.Priority) {
    if (a.Priority !== b.Priority) {
      return b.Priority - a.Priority;
    
    } else {
      const aIdentifier = Number(a.Identifier);
      const bIdentifier = Number(b.Identifier);

      if (!isNaN(aIdentifier) && !isNaN(bIdentifier)) {
        return bIdentifier - aIdentifier;
      }
    }
  }
  
  return 0;
};

export const filterActivities = (
  activities: ITopicDnDResponse[], 
  searchActivity: string,
) => {
  return activities.filter(activity => {
    if (searchActivity) {
      const DuoDateFormat = activity?.DueDate 
        ? dateToLocaleString(activity.DueDate) 
        : undefined;
      const searchActivityToLower = searchActivity.toLowerCase();

      return (`#${activity?.Identifier}` === searchActivity)
        || activity?.Title?.toLowerCase().includes(searchActivityToLower)
        || activity?.Description?.toLowerCase().includes(searchActivityToLower)
        || activity?.Stage?.Name.toLowerCase().includes(searchActivityToLower)
        || (activity?.Priority 
          && PtBrTopicPriorityEnum[activity.Priority].toString().toLowerCase().includes(searchActivityToLower))
        || (activity?.Visibility 
          && PtBrTopicVisibilityEnum[activity.Visibility].toString().toLowerCase().includes(searchActivityToLower))
        || DuoDateFormat?.toLowerCase().includes(searchActivityToLower)
        || activity?.ConstructionSiteDisciplines?.some(discipline => 
            discipline?.CustomName?.toLowerCase().includes(searchActivityToLower) 
            || discipline?.Discipline?.Name?.toLowerCase().includes(searchActivityToLower))
        || activity?.CommunicationTypes?.Name?.toLowerCase().includes(searchActivityToLower)
        || activity?.TopicPoints?.some(topic => 
            topic?.Name?.toLowerCase().includes(searchActivityToLower))
        || activity?.TopicAssignedUsers?.some(topic => 
            topic?.Nome?.toLowerCase().includes(searchActivityToLower)
            || topic?.Email?.toLowerCase().includes(searchActivityToLower))
        || activity?.Status?.Name?.toLowerCase().includes(searchActivityToLower)
        || activity?.Type?.Name?.toLowerCase().includes(searchActivityToLower);
    }
    return true;
  });
};

export const handleVisibility = (
  visibility: TopicVisibilityEnum,
  theme: IMDTheme
): IPriorityOrVisibility => {
  if (visibility === TopicVisibilityEnum.Public) {
    return {
      icon: 'globe',
      label: 'Público',
      color: theme.colors.information.information,
      size: 18,
    }    
  }
  if (visibility === TopicVisibilityEnum.Private) {
    return {
      icon: 'cadeadoFechado',
      label: 'Privado',
      color: theme.colors.surface.onSurfaceVariant,
      size: 18,
    }  
  }
  return {
    icon: 'editar',
    label: 'Rascunho',
    color: theme.colors.surface.onSurfacePlaceholder,
    size: 12,
  }  
};

export const handlePriority = (
  priority: TopicPriorityEnum,
  theme: IMDTheme
): IPriorityOrVisibility => {
  if (priority === TopicPriorityEnum.High) {
    return {
      icon: 'flag',
      label: 'Alta',
      color: theme.colors.danger.danger,
      size: 18,
    }  
  }
  if (priority === TopicPriorityEnum.Medium) {
    return {
      icon: 'flag',
      label: 'Média',
      color: theme.colors.warning.warning,
      size: 18,
    }    
  }
  return {
    icon: 'flag',
    label: 'Baixa',
    color: theme.colors.surface.onSurfacePlaceholder,
    size: 18,
  }  
};

export const compareIfIdenticalArrays = <T>(
  array: T[], 
  otherArray: T[],
  noCaseSensitive?: boolean,
): boolean => {
  if (array.length !== otherArray.length) {
    return false;
  }

  if (
    noCaseSensitive && 
    typeof array[0] === 'string' && 
    typeof otherArray[0] === 'string'
  ) {
    const sortedArrayLowerCase = array.map((str) => (str as string).toLowerCase()).sort();
    const sortedOtherArrayLowerCase = otherArray.map((str) => (str as string).toLowerCase()).sort();

    return arraysEqual(sortedArrayLowerCase, sortedOtherArrayLowerCase);
    
  } else {
    const sortedArray = array.sort();
    const sortedOtherArray = otherArray.sort();

    return arraysEqual(sortedArray, sortedOtherArray);
  }
};

function arraysEqual<T>(array: T[], otherArray: T[]): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== otherArray[i]) {
      return false;
    }
  }

  return true;
}

const FILTERS_ACTIVITIES_KEY = 'FILTERS_ACTIVITIES_KEY';

export const getFiltersKey = (csId: number) => {
  return `${FILTERS_ACTIVITIES_KEY}_${csId}`;
};

export const filterOperations = {
  equal: (fieldValue: any, filterValue: any) => {
    if (Array.isArray(filterValue)) {
      let useFieldValue: any[];

      if (Array.isArray(fieldValue)) {
        useFieldValue = fieldValue;
      } else {
        useFieldValue = [fieldValue];
      }

      return compareIfIdenticalArrays(filterValue, useFieldValue);
    }

    if (dayjs.isDayjs(fieldValue) && dayjs.isDayjs(filterValue)) {
      return dayjs(fieldValue).isSame(filterValue);
    }

    return fieldValue === filterValue;
  },
  different: (fieldValue: any, filterValue: any) => {
    if (Array.isArray(filterValue)) {
      let useFieldValue: any[];

      if (Array.isArray(fieldValue)) {
        useFieldValue = fieldValue;
      } else {
        useFieldValue = [fieldValue];
      }

      return filterValue.every((name) => !useFieldValue.includes(name));
    }

    return fieldValue !== filterValue;
  },
  bigger: (fieldValue: any, filterValue: any) => {
    return fieldValue > filterValue;
  },
  smaller: (fieldValue: any, filterValue: any) => {
    return fieldValue < filterValue;
  },
  biggerOrEqual: (fieldValue: any, filterValue: any) => {
    return fieldValue >= filterValue;
  },
  lessOrEqual: (fieldValue: any, filterValue: any) => {
    return fieldValue <= filterValue;
  },
  contain: (fieldValue: string, filterValue: string) => {
    if (Array.isArray(filterValue)) {
      let useFieldValue: any[];

      if (Array.isArray(fieldValue)) {
        useFieldValue = fieldValue;
      } else {
        useFieldValue = [fieldValue];
      }

      return filterValue.some((name) => useFieldValue.includes(name));
    }

    if (fieldValue) {
      return fieldValue.includes(filterValue);
      
    } else {
      return false;
    }
  },
  doesNotContain: (fieldValue: string, filterValue: string) => {
    if (Array.isArray(filterValue)) {
      let useFieldValue: any[];

      if (Array.isArray(fieldValue)) {
        useFieldValue = fieldValue;
      } else {
        useFieldValue = [fieldValue];
      }

      return filterValue.every((name) => !useFieldValue.includes(name));
    }
    
    if (fieldValue) {
      return !fieldValue.includes(filterValue);
      
    } else {
      return true;
    }
  },
  beginsWith: (fieldValue: string, filterValue: string) => {
    return fieldValue.startsWith(filterValue);
  },
  endsWith: (fieldValue: string, filterValue: string) => {
    return fieldValue.endsWith(filterValue);
  },
  empty: (fieldValue: any) => {
    if (Array.isArray(fieldValue)) {
        return !fieldValue.length || fieldValue.length <= 0;
    }

    return fieldValue === undefined || fieldValue === null;
  },
  notEmpty: (fieldValue: any) => {
    if (Array.isArray(fieldValue)) {
        return fieldValue.length && fieldValue.length > 0;
    }

    return fieldValue !== undefined && fieldValue !== null;
  },
  before: (fieldValue: Dayjs, filterValue: Dayjs) => {
    return dayjs(fieldValue).isBefore(filterValue);
  },
  after: (fieldValue: Dayjs, filterValue: Dayjs) => {
    return dayjs(fieldValue).isAfter(filterValue);
  },
  onOrBefore: (fieldValue: Dayjs, filterValue: Dayjs) => {
    return dayjs(fieldValue).isSame(filterValue) || 
      dayjs(fieldValue).isBefore(filterValue);
  },
  onOrAfter: (fieldValue: Dayjs, filterValue: Dayjs) => {
    return dayjs(fieldValue).isSame(filterValue) || 
      dayjs(fieldValue).isAfter(filterValue);
  },
  between: (fieldValue: Dayjs, filterValue: Dayjs[]) => {
    const dataInicial = filterValue[0];
    const dataFinal = filterValue[1];
    const dataItem = fieldValue;

    return dayjs(dataItem).isAfter(dataInicial) && 
      dayjs(dataItem).isBefore(dataFinal);
  },
};


const getPropertyByPath = (obj: any, path: string) => {
  const props = path.split('.');
  let currentObj = obj;
  for (const prop of props) {
    if (!currentObj || !currentObj.hasOwnProperty(prop)) {
      return undefined;
    }
    currentObj = currentObj[prop];
  }
  return currentObj;
};
 
const getFieldValue = <T>(
  item: T, 
  campo: string,
  enumToStringValueMap?: IEnumMap<any>,
  properties?: string[],
) => {
  let valorCampo: any = item[campo as keyof T];

  if (valorCampo === undefined || valorCampo === null) return undefined;

  if (!Array.isArray(valorCampo) && typeof valorCampo !== 'object') {
    if (enumToStringValueMap && valorCampo in enumToStringValueMap) {
      const enumValue: any = enumToStringValueMap[valorCampo];
      valorCampo = enumValue[valorCampo];
    }
  }

  if (properties) {
    let newValorCampo: any = null;

    for (const property of properties) {
      if (!Array.isArray(valorCampo)) {
        if (valorCampo.hasOwnProperty(property) && !newValorCampo) {          
          if (valorCampo[property]) {
            newValorCampo = getPropertyByPath(valorCampo, property);
            
            if (!Array.isArray(newValorCampo) && typeof newValorCampo !== 'object') {
              if (enumToStringValueMap && newValorCampo in enumToStringValueMap) {
                const enumValue: any = enumToStringValueMap[newValorCampo];
                newValorCampo = enumValue[newValorCampo];
              } 
            }
          }
        }

      } else {
        const newValorArray = [];
        let count = valorCampo.length;

        while (count > 0) {
          const oneValorCampo: any = getPropertyByPath(valorCampo[count-1], property);
          if (oneValorCampo) {
            newValorArray.push(oneValorCampo);
          }
          count--;
        }

        newValorCampo = newValorArray;
      }
    }

    valorCampo = newValorCampo;
  }

  return valorCampo;
};

const filterArray = <T>(
  array: T[], 
  filter: Record<string, IFiltroOptions<T>>,
  enumToStringValueMap?: IEnumMap<any>,
) => {
  return array.filter((item) => {
    if (item) {
      for (const campo in filter) {
        const filterId = filter[campo].topicId;
        let filterValue = filter[campo]?.value;
  
        const operation = filter[campo].operation;
        const properties = filter[campo]?.properties || undefined;
  
        if (operation && item) {
          let fieldValue = getFieldValue(item, filterId, enumToStringValueMap, properties);

          if (
            (!Array.isArray(filterValue) && filterValue) ||
            (Array.isArray(filterValue) && filterValue.length > 0) || 
            ((!filterValue || (Array.isArray(filterValue) && filterValue.length <= 0)) && 
              (operation === 'empty' || operation === 'notEmpty'))
          ) {
            if (filterId === 'Identifier') {
              if (typeof filterValue === 'string' && filterValue.includes('#')) {
                filterValue = filterValue.replace('#', '');
              }
              fieldValue = Number(fieldValue);
              filterValue = Number(filterValue);
            }

            if (filterId === 'DueDate') {
              if (operation !== 'empty' && operation !== 'notEmpty') {
                if (!fieldValue) {
                  return false;
                }
    
                if (Array.isArray(filterValue)) {
                  fieldValue = dayjs(fieldValue);
                  filterValue = filterValue.map(value => dayjs(value));
                } else {
    
                  fieldValue = dayjs(fieldValue);
                  filterValue = dayjs(filterValue);
                }
              }
            }

            if (typeof fieldValue === 'string') {
              fieldValue = fieldValue.toLowerCase();
            }
            if (typeof filterValue === 'string') {
              filterValue = filterValue.toLowerCase();
            }
            
            if (
              filterId !== 'DueDate' || 
              (filterId === 'DueDate' && operation !== 'between') ||
              (filterId === 'DueDate' && operation === 'between' && Array.isArray(filterValue))
            ) {
              if (!filterOperations[operation](fieldValue, filterValue)) return false;
            }
          }
        }
      }
    }

    return true;
  });
};

export const complexfilterActivities = <T, EnumsMap extends IEnumMap<any> = IEnumMap<any>>({
  filters,
  array,
  enumToStringValueMap,
}: IFilters<T, EnumsMap>) => {
  if (filters && JSON.stringify(filters) !== '{}' && array?.length && array.length > 0) {
    return filterArray(array,  filters, enumToStringValueMap);
  }

  return array;
};

export const attachmentsImagesAccepts = [
  'image/apng',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
];
  
export const attachmentsImagesNotAccepts = [
  'image/webp',
  'image/svg+xml',
  'image/x-ico',
  'image/bmp',
  'image/tiff',
];

export const maxAttachmentsUploadSize = 1024 * 1024 * 128;
