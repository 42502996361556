import styled, { css } from "styled-components";

export const Divider = styled.div`
  width: 1px;
  height: auto;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
`;

export const MainWrapperLeft = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding-right: 15px;
  padding-bottom: 15px;

  .cardHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
  }

  .cardMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
    margin-top: 16px;
  }
`;

export const CardTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const CardSubTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurface};
  margin-bottom: 6px;
`;

interface IImgWrapper {
  isProgress?: string;
}

export const ImgWrapper = styled.div<IImgWrapper>`
  width: 180px;
  height: 300px;
  border: 1px solid;
  border-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  img {
    width: 180px;
    height: 300px;
  }

  .progressBar {
    position: absolute;
    left: 10px;
    bottom: -8px;
    z-index: 2;
  }

  ${({isProgress}) => isProgress === 'true' && css`
    .progressMask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
      z-index: 1;
    }
  `}
`;

export const InfoTextBold = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurface};
  margin-top: 15px;
  margin-bottom: 6px;
`;

export const InfoText = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  margin-top: 6px;
`;

export const MainWrapperRight = styled.div`
  width: 100%;
  max-width: 550px;
  margin-left: 15px;
  position: relative;
  
  .fixedContainer {
    width: 100%;
    max-width: 550px;
    position: fixed;
  }
`;

interface ISvgCard {
  newColor: string;
  haveLogo: string;
}

export const SvgCardBanner = styled.div<ISvgCard>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};

  svg {
    #customColorRect-1 {
      fill: ${({newColor}) => newColor};
    }

    #customColorRect-2 {
      fill: ${({theme}) => theme.colors.surface.surface};
      stroke: ${({theme}) => theme.colors.surface.surfaceOutline};
    }

    #customColorRect-3 {
      fill: ${({theme}) => theme.colors.primary.primary};
    }

    #image0_2791_5062 {
      display: none;
    }
  }

  .bannerImage {
    position: absolute;
    top: 69.5px;
    left: 145.5px;
    width: 122.8px;
    height: 202px;
  }

  .logoImage {
    position: absolute;
    top: 72px;
    left: 280.5px;
    width: 50px;
    height: 18px;
  }

  .poweredByImage {
    position: absolute;
    top: 72px;
    right: 134px;
    width: 50px;
    height: 18px;
  }

  ${({haveLogo}) => haveLogo === 'true' && css`
    #Vector {
      display: none;
    }
  `}
`;

export const WarningCard = styled.div`
  width: 100%;
  padding: 14px;
  margin-top: 16px;
  background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.warning.warningContainer}, ${theme.colors.surface.surface})`} !important;
  border: 1px solid ${({theme}) => `color-mix(in srgb, ${theme.colors.warning.onWarningOutline}, ${theme.colors.warning.warning})`} !important;
  border-radius: 5px;
  display: flex;

  .iconWarningWrapper {
    .warningIcon {
      color: ${({theme}) => theme.colors.warning.warning} !important;
    }
  }

  .textWarningWrapper {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.surface.onSurface};

    .warningTextBold {
      font-weight: 600;
    }

    .warningText {
      margin-top: 6px;

      .warningAction {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
`;
