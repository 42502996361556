import Cookies, { CookieAttributes } from "js-cookie";

interface IUseStorage {
  cookie: typeof Cookies;
  session: typeof sessionStorage;
  local: typeof localStorage;
};

type TypeStorage = keyof IUseStorage;

const useStorageObj: IUseStorage = {
  cookie: Cookies,
  session: sessionStorage,
  local: localStorage,
};

class UseStorage {
  static set(storage: TypeStorage, key: string, value: string, options?: CookieAttributes) {
    if (storage === 'cookie') {
      return useStorageObj[storage].set(key, value, options);
    }

    return useStorageObj[storage].setItem(key, value);
  }

  static get(storage: TypeStorage, key: string) {
    if (storage === 'cookie') {
      return useStorageObj[storage].get(key);
    }

    return useStorageObj[storage].getItem(key);
  }

  static remove(storage: TypeStorage, key: string) {
    if (storage === 'cookie') {
      return useStorageObj[storage].remove(key);
    }

    return useStorageObj[storage].removeItem(key);
  }
}

class AppStorage {
  REMEMBER_ME_KEY = 'REMEMBER_ME';

  SetItem(key: string, value: string, storage: TypeStorage = 'cookie') {
    const rememberMe = this.GetRememberMe();

    if (rememberMe) {
      return UseStorage.set(storage, key, value, { expires: 365 * 10 });
    }
    return UseStorage.set(storage, key, value);
  }

  GetItem(key: string, storage: TypeStorage = 'cookie'): string | null | undefined {
    return UseStorage.get(storage, key);
  }

  RemoveItem(key: string, storage: TypeStorage = 'cookie') {
    return UseStorage.remove(storage, key);
  }

  SetRememberMe(rememberMe: boolean) {
    UseStorage.set('local', this.REMEMBER_ME_KEY, rememberMe.toString());
  }

  private GetRememberMe(): boolean {
    const rememberMe = UseStorage.get('local', this.REMEMBER_ME_KEY);
    return rememberMe === 'true';
  }
}

const instance = new AppStorage();
export default instance;
