import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';
import styles from './ExitRedirect.module.scss';
import Modal from 'Components/UI/Modal/Modal';
import Button from 'Components/UI/Button';

interface IExitRedirect {
  visible: boolean;
  onClose: () => void;
}

const ExitRedirect: FC<IExitRedirect> = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const cancelUpdateBillingData = () => {
    dispatch(RevenuesActions.cancelPageUpdateBillingData(false));
    dispatch(RevenuesActions.showModalExitBillingData(false));
    dispatch(RevenuesActions.cancelPageDowgrade(false));
    dispatch(RevenuesActions.showModalExitDowgrade(false));
  }
  
  const buttons = (
    <div className={styles['footer-buttons']}>
      <Button
        onClick={cancelUpdateBillingData}
        className={styles['exit-button']}
      >
        Sair
      </Button>
      <Button
        type="primary"
        onClick={onClose}
        className={styles['cancel-button']}
      >
        Voltar ao processo
      </Button>
    </div>
  );
  
  return (
    <Modal
      title="Atenção"
      visible={visible}
      onCancel={onClose}
      footerButtons={buttons}
    >
      <div className={styles['main-wrapper']}>
        <p>
          Deseja sair do processo de mudança de plano?
        </p>
        <p>
          Você possui modificações em andamento que serão perdidas caso você não conclua o processo.
        </p>
      </div>
    </Modal>
  )
}

export default ExitRedirect;
