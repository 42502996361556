import { Popover } from "antd";
import { FC, useMemo } from "react";
import Button from "../Button";
import { IconName } from "../Icon/IconClasses";
import { Container, IconContainer } from "./styles";
import { Icon } from "../Icon/Icon";

interface IIconPicker {
  options: IconName[];
  value: IconName | null;
  onChange: (icon: IconName) => void;
}

const IconPicker: FC<IIconPicker> = ({ options, value, onChange }) => {
  const content = useMemo(() => {
    return (
      <Container>
        {options.map((option) => (
          <IconContainer
            key={option}
            selected={value === option}
            onClick={() => onChange(option)}
          >
            <Icon
              icon={option}
              customSize={18}
              className="icon"
            />
          </IconContainer>
        ))}
      </Container>
    )
  }, [options, value, onChange]);

  return (
    <Popover content={content} trigger="click" placement="right">
      <Button>
        Selecione um ícone
      </Button>
    </Popover>
  )
}

export default IconPicker;
