import {
  HelperActionKeys,
  HelperActionUnion,
} from "./Helper.actions";
import { IHelperState } from "./interfaces/IHelperState";


const initialState: IHelperState = {
  imgsOnErrorControl: {},
  isLoading: false,
};

const HelperReducer = (
  state = initialState,
  action: HelperActionUnion
): IHelperState => {
  switch (action.type) {
    case HelperActionKeys.UPLOAD_IMGS_ONERROR_SET:
      if (state.imgsOnErrorControl.hasOwnProperty(action.payload)) {
        let count = state.imgsOnErrorControl[action.payload];
        count++;
        state.imgsOnErrorControl[action.payload] = count;
      } else {
        state.imgsOnErrorControl[action.payload] = 1;
      }
      return {
        ...state,
      };
    case HelperActionKeys.UPLOAD_IMGS_ONERROR_DEL:
      if (action.payload) {
        delete state.imgsOnErrorControl[action.payload];
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default HelperReducer;
