import { FC, useEffect } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IRenderingSignalR } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IVerifyAutodeskProgress } from 'Data/interfaces/ListagemArquivos/IVerifyAutodeskProgress';
import { IFileInfo } from 'Data/interfaces/Viewer/IGetFileInfo';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { ListagemArquivosActions } from 'Store/ListagemArquivos/ListagemArquivos.actions';
import { ViewerActions } from 'Store/Viewer/Viewer.actions';
import SignalR from 'Components/UI/SignalR';
import SimpleViewerComponent from 'Components/UI/SimpleViewerComponent';
import styles from './Autodesk.module.scss';

interface IAutodeskViewer {
  fileInfo?: IFileInfo;
  constructionSiteId?: number;
}

const AutodeskViewer: FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ViewerActions.createStatusFileViewerAutodesk({
        urn: props.fileInfo?.urn,
        etapa: 0,
        progress: 0,
        fileIdentifier: props.fileInfo?.fileIdentifier
      } as IRenderingSignalR)
    );

    dispatch(
      ListagemArquivosActions.verifyAutodeskProgress({
        constructionSiteId: props.constructionSiteId,
        filesApiId: [props.fileInfo?.fileIdentifier]
      } as IVerifyAutodeskProgress)
    );
  }, []);

  const getNameEtapa = () => {
    const etapa =
      (props.FileViewerAutodesk?.etapa && props.FileViewerAutodesk?.etapa < 4) &&
        props.FileViewerAutodesk?.progress == 100
        ? props.FileViewerAutodesk?.etapa + 1
        : props.FileViewerAutodesk?.etapa;

    switch (etapa) {
      case 0:
        return 'Carregando informações do arquivo';
      case 1:
        return 'Download do Serviço de armazenamento';
      case 2:
        return 'Fazendo upload para Servidor';
      case 3:
        return 'Processamento da visualização';
      case 5:
        return 'Erro ao processar arquivo';
      default:
        return 'Iniciando processamento de arquivo';
    }
  };

  const infoRenderizando = () => (
    <div
      className={`${styles['infoRenderizando']} ${props.FileViewerAutodesk?.etapa == 5 ? styles['infoError'] : ''}`}
    >
      <div>
        <CircularProgressbar
          className={styles['circularProgress']}
          strokeWidth={14}
          styles={buildStyles({ pathColor: '#0081FF', trailColor: '#cdcdcd' })}
          value={
            (props.FileViewerAutodesk?.progress == 100
              ? 0
              : props.FileViewerAutodesk?.progress) ?? 0
          }
          maxValue={100}
        />
      </div>
      <div className={styles['infoEtapas']}>
        <label>
          Etapa{' '}
          {props.FileViewerAutodesk?.progress == 100
            ? props.FileViewerAutodesk?.etapa + 1
            : props.FileViewerAutodesk?.etapa ?? 0}{' '}
          de 3:
        </label>
        <p>{getNameEtapa()}</p>
      </div>
    </div>
  );

  return (
    <>
      <SignalR />
      {(props.fileInfo?.isRendering && (props.FileViewerAutodesk?.etapa ?? 0) < 4) ||
        (!props.fileInfo?.isRendering && (props.FileViewerAutodesk?.etapa ?? 0) == 5) ||
        (props.fileInfo?.fileRenderStatus == null && (props.FileViewerAutodesk?.etapa ?? 0) != 5) ? (
        <div key="render" className={styles['contentRenderizando']}>{infoRenderizando()}</div>
      ) : (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 89px)',
            borderTop: '1px solid #bdbdbd'
          }}
        >
          <SimpleViewerComponent
            fileInfo={props.fileInfo}
            userInfo={props.userInfo}
            currentTenant={props.currentTenant}
          />
        </div>
      )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.viewer,
  userInfo: state.auth.userInfo,
  currentTenant: state.tenant.currentListTenant,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAutodeskViewer;

export default connector(AutodeskViewer);
