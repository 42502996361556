import { FC, useState } from "react";

import { Popover } from "antd";
import styles from "./MoreAction.module.scss";
import "./MoreAction.css"
import { Icon } from "Components/UI";

interface IMoreAction {
  onDelete: () => void;
  disabled?: boolean;
  className?: string;
  iconColor?: string;
  iconRemoveColor?: string;
  bgColor?: boolean;
} 

const MoreAction: FC<IMoreAction> = ({ 
  onDelete,
  disabled,
  className,
  iconColor,
  iconRemoveColor,
  bgColor,
}) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const overlay = (
    <div className={styles['MenuDropdown']}>
      <span 
        className={`${styles['menuItem']} ${styles['menuItemRemove']} ${bgColor ? styles['menuItemCustomRemove'] : ''}`}
        onClick={() => {
          onDelete();
          hide();
        }}
      >
        <Icon icon="excluir" customColor={iconRemoveColor ? iconRemoveColor : '#E2574C'}/>
          Excluir
      </span>
    </div>
  )

  return (
    <Popover 
      content={overlay} 
      placement="bottomRight"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <button className={className || ''}>
        <Icon
          icon="moreActions"
          customSize={16}
          customColor={iconColor ? iconColor : '#324150'}
        />
      </button>
    </Popover>
  )
}

export default MoreAction;