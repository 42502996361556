import React, { FC } from "react";
import imgBottom from "../../../../Static/images/empty-viewer-bottom.png";
import imgCenter from "../../../../Static/images/empty-viewer-center.png";
import styles from "./EmptyViewer.module.scss";

interface IEmptyViewer {
  isDrawerVisible: boolean;
}

const EmptyViewer: FC<IEmptyViewer> = ({ 
  isDrawerVisible
 }) => {
  return (
    <div 
      className={styles['wrapper']}
    >
      <div className={styles['mainWrapper']}>
        <div className={styles['center']}>
          <img src={imgCenter} alt="" />
        </div>
        <div className={styles['bottom']}>
          <img src={imgBottom} alt="" />
        </div>
      </div>
    </div>
  )
}

export default EmptyViewer;
