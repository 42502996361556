import { Icon } from "Components/UI/Icon";
import { PanelTypeEnum } from "Data/interfaces/Panels/IPanelsResponse";
import {
  INewPanelsOptions,
  ISrcTypeOption,
  PanelsOptionsIconsType,
} from "Data/interfaces/Panels/IPanelComponentsModels";

import docs from "Static/icons/docs.png";
import sheets from "Static/icons/sheets.png";
import slides from "Static/icons/slides.png";
import calendar from "Static/icons/calendar.png";
import drive from "Static/icons/drive.png";
import maps from "Static/icons/maps.png";
import youtube from "Static/icons/youtube.png";
import onedrive from "Static/icons/onedrive.png";
import dropbox from "Static/icons/dropbox.png";
import miro from "Static/icons/miro.png";
import loom from "Static/icons/loom.png";

export const panelsOptionsIcons: PanelsOptionsIconsType = {
  '0': <Icon icon="coordly" customSize={12} className="leftCoordlyIconOptionRow" />,
  '1': <Icon icon="customTag" className="leftIconOptionRowCustom" />,
  '2': <img src={docs} alt="Google Docs" className="leftIconOptionRow" />,
  '3': <img src={sheets} alt="Google Sheets" className="leftIconOptionRow" />,
  '4': <img src={slides} alt="Google Slides" className="leftIconOptionRow" />,
  '5': <img src={calendar} alt="Google Calendar" className="leftIconOptionRow" />,
  '6': <img src={drive} alt="Google Drive" className="leftIconOptionRow" />,
  '7': <img src={maps} alt="Google Maps" className="leftIconOptionRow" />,
  '8': <img src={youtube} alt="YouTube" className="leftIconOptionRow" />,
  '9': <img src={onedrive} alt="OneDrive" className="leftIconOptionRow" />,
  '10': <img src={dropbox} alt="DropBox" className="leftIconOptionRow" />,
  '11': <img src={miro} alt="Miro" className="leftIconOptionRow" />,
  '12': <img src={loom} alt="Loom" className="leftIconOptionRow" />,
}

export const coordlyOption: INewPanelsOptions = {
  id: PanelTypeEnum.InternalItem,
  label: 'Arquivo na Coordly',
  type: PanelTypeEnum.InternalItem,
  title: 'Selecione um arquivo da obra na Coordly.',
  description: 'Selecione um arquivo já enviado para disponibilizar a sua visualização em um painel.',
};

export const panelsOptions: INewPanelsOptions[] = [
  {
    id: PanelTypeEnum.Custom,
    label: 'Personalizado',
    type: PanelTypeEnum.Custom,
    title: 'Adicione uma integração personalizada.',
    description: 'Adicione uma URL de uma integração personalizada para ter acesso dentro da plataforma ou insira um HTML da integração para ter ainda mais customização.',
  },
  {
    id: PanelTypeEnum.GoogleDocs,
    label: 'Google Docs',
    type: PanelTypeEnum.GoogleDocs,
    title: 'Adicione um Google Docs.',
    description: 'Anexe uma planilha do Google Docs para ter acesso dentro da plataforma. Copie o link público do link no seu Google Docs e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.GoogleSheets,
    label: 'Google Sheets',
    type: PanelTypeEnum.GoogleSheets,
    title: 'Adicione um Google Sheets.',
    description: 'Anexe uma planilha do Google Sheets para ter acesso dentro da plataforma. Copie o link público do link no seu Google Sheets e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.GoogleSlides,
    label: 'Google Slides',
    type: PanelTypeEnum.GoogleSlides,
    title: 'Adicione um Google Slides.',
    description: 'Anexe uma planilha do Google Slides para ter acesso dentro da plataforma. Copie o link público do link no seu Google Slides e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.GoogleCalendar,
    label: 'Google Calendar',
    type: PanelTypeEnum.GoogleCalendar,
    title: 'Adicione um Google Calendar.',
    description: 'Anexe uma planilha do Google Calendar para ter acesso dentro da plataforma. Copie o link público do link no seu Google Calendar e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.GoogleDrive,
    label: 'Google Drive',
    type: PanelTypeEnum.GoogleDrive,
    title: 'Adicione um Google Drive.',
    description: 'Anexe uma planilha do Google Drive para ter acesso dentro da plataforma. Copie o link público do link no seu Google Drive e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.GoogleMaps,
    label: 'Google Maps',
    type: PanelTypeEnum.GoogleMaps,
    title: 'Adicione um Google Maps.',
    description: 'Anexe uma planilha do Google Maps para ter acesso dentro da plataforma. Copie o link público do link no seu Google Maps e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.Youtube,
    label: 'YouTube',
    type: PanelTypeEnum.Youtube,
    title: 'Adicione um YouTube.',
    description: 'Anexe uma planilha do YouTube para ter acesso dentro da plataforma. Copie o link público do link no seu YouTube e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.OneDrive,
    label: 'OneDrive',
    type: PanelTypeEnum.OneDrive,
    title: 'Adicione um OneDrive.',
    description: 'Anexe uma planilha do OneDrive para ter acesso dentro da plataforma. Copie o link público do link no seu OneDrive e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.Dropbox,
    label: 'DropBox',
    type: PanelTypeEnum.Dropbox,
    title: 'Adicione um DropBox.',
    description: 'Anexe uma planilha do DropBox para ter acesso dentro da plataforma. Copie o link público do link no seu DropBox e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.Miro,
    label: 'Miro',
    type: PanelTypeEnum.Miro,
    title: 'Adicione um Miro.',
    description: 'Anexe uma planilha do Miro para ter acesso dentro da plataforma. Copie o link público do link no seu Miro e cole no campo de URL abaixo.',
  },
  {
    id: PanelTypeEnum.Loom,
    label: 'Loom',
    type: PanelTypeEnum.Loom,
    title: 'Adicione um Loom.',
    description: 'Anexe uma planilha do Loom para ter acesso dentro da plataforma. Copie o link público do link no seu Loom e cole no campo de URL abaixo.',
  },
];

export const srcTypeOptions: ISrcTypeOption[] = [
  {
    id: 'url',
    label: 'Integrar URL',
    icon: <Icon icon="link" customSize={12} className="rightIconSrcOption" />,
  },
  {
    id: 'html',
    label: 'Integrar HTML',
    icon: <Icon icon="customTag" customSize={16} className="rightIconSrcOption" />,
  },
];
