import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import PageHeader from 'Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import Table from 'Components/UI/Table';
import SignalR from 'Components/UI/SignalR';

import styles from './Notification.module.scss';
import { tableColumns } from './Notification.columns';

import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { NotificationActions } from 'Store/Notification/Notification.actions';

import { Skeleton } from 'antd';
import TableActionTopbar, { ITableActionTopbarButton } from 'Components/UI/TableActionTopbar';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import toastHandler from 'Utils/toastHandler';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import LoadingNotification from 'Components/UI/CustomLoading/LoadingNotification';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface INotification {
  match: any;
}

const Notification: FC<Props> = ({ 
  filters, 
  notifications, 
  isLoadingListAll,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const dates = useSelector((state: IGlobalReducerState) => state.notification.filtersDates);
  const subtractDays = useSelector((state: IGlobalReducerState) => state.notification.subtractDays);

  const breadCrumb: IBreadcrumbItem[] = [
    {
      description: 'Central de notificações',
      href: '/notification',
    }
  ];

  const [selectedRows, setSelectedRow] = React.useState<INotificationData[]>([]);
  const onChange = (selecteds: any, selectedRows: any) => {
    setSelectedRow(selectedRows);
  };

  const rowSelection = {
    onChange,
  };

  useEffect(() => {
    dispatch(NotificationActions.listNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSelectedAsRead = () => {
    let notificationsMap: INotificationsMapAux[] = selectedRows.map(
      (notification) => {
        return {
          NotificationId: !notification.hasRead ? notification.NotificationId : 0,
          UserFk: notification.UserFk,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        } as INotificationsMapAux;
      }
    );

    notificationsMap = notificationsMap.filter(x => x.NotificationId > 0);

    if (notificationsMap?.length > 0) {
      dispatch(NotificationActions.setNotificationAsView(notificationsMap));
      dispatch(NotificationActions.setNotificationAsRead(notificationsMap.map(x => x?.NotificationId ?? 0)));

      setTimeout(() => {
        toastHandler.showSuccess('Notificações marcadas como lidas!')
      }, 500);
    }
  }

  const setAllAsRead = () => {
    dispatch(NotificationActions.setReadNotification({ userInfo }));

    setTimeout(() => {
      toastHandler.showSuccess('Notificações marcadas como lidas!')
    }, 500);
  }

  const buttonsTableToolbar: ITableActionTopbarButton[] = [
    {
      icon: 'confirmar',
      description: selectedRows.length > 0 ? 'Marcar selecionadas como lidas' : 'Marcar todas como lidas',
      iconCustomSize: 10,
      action: selectedRows.length > 0 ? setSelectedAsRead : setAllAsRead,
    },
  ];

  return (
    <>
      <SignalR />
      <PageHeader name="Notificações">
        <BreadCrumb breadcrumbs={breadCrumb} />
        <div className={styles['space']} />
        <div className={styles['WrapperTable']}>
          {isLoadingListAll ?
            <LoadingNotification multiple={13} /> :
            <div className={styles['tableContainer']}>
              <TableActionTopbar buttons={buttonsTableToolbar} />
              <Table
                rowKey={'NotificationId'}
                columns={tableColumns({
                  filters,
                  notificationList: notifications?.sort((a: INotificationData, b: INotificationData) => b.NotificationId - a.NotificationId),
                  dispatch,
                  dates: dates,
                  subtractDays: subtractDays,
                })}
                dataSource={notifications?.sort((a: INotificationData, b: INotificationData) => b.NotificationId - a.NotificationId)}
                pagination={{ defaultPageSize: 20, className: styles['pagination'] }}
                rowSelection={rowSelection}
              />
            </div>
          }
        </div>
      </PageHeader>
    </>
  );
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.notification,
  isLoadingListAll: state.notification.isLoadingListAll,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & INotification;

export default connector(Notification);
