import { FC, useState } from "react";
import { IUserInfoResponse } from "Data/interfaces/MinhaConta/IUserInfoResponse";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import * as Yup from 'yup';
import { ROLES } from "..";
import Tooltip from "Components/UI/Tooltip";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI/Icon";
import Form from "Components/UI/Form/Form";
import Field from "Components/UI/Field";
import styles from './HeaderActions.module.scss';

export interface IHeaderActions {
  minhaConta: IUserInfoResponse;
  obras?: IObraResponse[];
  isLoading: boolean;
  obrasForShow: boolean;
  openCadastroObra: () => void;
  onRemoveSearch: () => void;
  onSearch: (search: string) => void;
  onSearchChange: (search: string) => void;
}

export const HeaderActions: FC<IHeaderActions> = ({
  minhaConta,
  isLoading,
  obras,
  obrasForShow,
  onSearch,
  onSearchChange,
  onRemoveSearch,
  openCadastroObra,
}) => {
  const [showCSSearchInput, setShowCSSearchInput] = useState(false);
  const [search, setSearch] = useState('');
  const allowedRoles = [ROLES.ADMIN, ROLES.COORDENADOR];

  if (
    !allowedRoles.includes(minhaConta.CurrentRoleFk) ||
    (!isLoading && obras === undefined)
  ) {
    return <></>;
  }

  const handleSearchChange = (value: string) => {
    setSearch(value);
    onSearchChange(value);
  };

  const onSearchSubmit = (values: { search: string }) => {
    setShowCSSearchInput(false);
    onSearch(values.search);
  };

  const toggleSearchInput = () => {
    onRemoveSearch();
    setSearch('');
    setShowCSSearchInput(true);
  };

  return (
    <div className={styles['actions-container']}>
      {obrasForShow &&
        (showCSSearchInput ? (
          <Form
            schema={Yup.object().shape({})}
            className={styles['form-container']}
            onSubmit={onSearchSubmit}
          >
            <Field
              name="search"
              label=""
              icon="buscar"
              autoFocus={true}
              className={styles['search-input']}
              placeholder="Pesquise por uma obra..."
              value={search}
              onInput={handleSearchChange}
            />
            <div
              className={styles['search-input-backdrop']}
              onClick={() => {
                if (!search) {
                  setShowCSSearchInput(false)
                }
              }}
            />
          </Form>
        ) : (
          <Tooltip title="Filtrar Obras">
            <Button
              type='text'
              height={36}
              width={36}
              shape="circle"
              className={styles['buttonObra']}
              onClick={toggleSearchInput}
            >
              <Icon icon='buscar' customSize={16} />
            </Button>
          </Tooltip>
        ))
      }

      <Tooltip title="Adicionar Nova Obra">
        <Button
          height={45}
          shape="circle"
          className={styles['buttonObra']}
          onClick={openCadastroObra}
        >
          <Icon
            icon="adicionar"
            customSize={20}
            color="onPrimary"
            style={{ fontWeight: 700 }}
          />
        </Button>
      </Tooltip>
    </div>
  );
}
