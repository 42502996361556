import { Dispatch } from 'react';
import { Link } from 'react-router-dom';

import ColumnHeader from 'Components/UI/Table/ColumnHeader';
import { Icon } from 'Components/UI';
import { Badge, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import FilterDate from './components/filterDate';

import { formatDateByTime } from 'Utils/DateUtils';
import { getFilters } from './Notification.helpers';
import { NotificationActionUnion, NotificationActions } from 'Store/Notification/Notification.actions';
import { INotificationFilters, IFilterDateOption } from 'Store/Notification/interfaces/INotificationState';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';

import styles from './Notification.module.scss';
import FilterModal from 'Pages/ListagemArquivos/Modals/FilterModal';
import { GetAvatar, getUserAvatarOrThumb } from 'Utils/generateThumbnail';
import { iconFileByFilename } from 'Utils/Icons';
import { NotificationActionEnum } from 'Data/enums/Notification';
import dayjs from 'dayjs';

interface IColumns {
  dispatch: Dispatch<NotificationActionUnion>;
  filters: INotificationFilters;
  notificationList: INotificationData[];
  dates: IFilterDateOption[];
  subtractDays: number;
}

export const tableColumns: (input: IColumns) => Array<ColumnProps<any>> = ({ notificationList, filters, dispatch, dates, subtractDays }) => [
  {
    title: (<ColumnHeader title="Notificações" showFilter={() => dispatch(NotificationActions.setShowNotification(true))} />),
    dataIndex: 'ActionName',
    key: 'ActionName',
    className: styles['notificationHeader'],
    onFilter: (value: any, record: any) => {
      return record.ActionName.toLowerCase() === value;
    },
    onFilterDropdownVisibleChange: (visible) => dispatch(NotificationActions.setShowNotification(visible)),
    filterDropdownVisible: filters.showNotification,
    filterDropdown: (propsFilters: any) => {
      const showDataFilters = getFilters(notificationList, 'ActionName', (x: string) => x || '');

      return (
        <FilterModal
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={() => dispatch(NotificationActions.setShowNotification(false))}
          placeholder="Buscar ação"
        />
      );
    },
    render: (_: any, { ActionName, hasRead, Description, ItemLocationUrl, ItemName, Action, ItemUrl }: INotificationData) => {

      let itemNameAux = ItemName;

      if (Action === NotificationActionEnum.ArquivoMovido && Description?.length > 0 && Description.lastIndexOf('/') > -1) {
        const folderName = Description.substring(Description.lastIndexOf('/') + 1);
        itemNameAux += ` para "${folderName}"`;
      }

      return (
        <div className={styles['notificationRow']}>
          <Icon color="cinzaPadrao" icon={iconFileByFilename(ItemName)} customSize={18} />
          <Link to={ItemUrl}>{ActionName}</Link>
          <Tooltip overlay={Description}>
            <Link to={ItemLocationUrl}>
              <span style={{ display: 'flex' }}>
                <Icon color="cinzaPadrao" icon={'arquivar'} size='xxs' style={{ marginTop: 5 }} />
                <span className={styles['itemName']}>{itemNameAux}</span>
              </span>
            </Link>
          </Tooltip>
          {!hasRead ? <Badge dot color='#FF3D57' className={styles['badge']} /> : <></>}
        </div>
      )
    },
  },
  {
    title: (<ColumnHeader title="Usuário" showFilter={() => dispatch(NotificationActions.setShowNotificationUsers(true))} />),
    dataIndex: 'UserName',
    key: 'UserName',
    className: styles['userHeader'],
    onFilter: (value: any, record: any) => record.UserName.toLowerCase() === value,
    onFilterDropdownVisibleChange: (visible) => dispatch(NotificationActions.setShowNotificationUsers(visible)),
    filterDropdownVisible: filters.showUsers,
    filterDropdown: (propsFilters: any) => {
      const showUsersFilters = getFilters(notificationList, 'UserName', undefined, (index) => index !== '-', (updateIndex) => {
        return (
          <div className={styles['filterModificadoPor']}>
            <GetAvatar name={updateIndex} shape='circle' size='small' />
            {updateIndex}
          </div>
        );
      });

      return <FilterModal
        width='200px'
        propsFilter={propsFilters}
        tipoOptions={showUsersFilters}
        showFilter={(visible: boolean) => dispatch(NotificationActions.setShowNotificationUsers(visible))}
        placeholder="Buscar usuário"
      />;
    },

    render: (_: any, { UserName, AvatarFileNameUser }: Pick<INotificationData, 'AvatarFileNameUser' | 'UserName'>) => {
      return (
        <div className={styles['userRow']}>
          {getUserAvatarOrThumb(AvatarFileNameUser, UserName)}
          <Tooltip title={UserName}>
            <p>{UserName}</p>
          </Tooltip>
        </div>
      )
    },
  },
  {
    title: (<ColumnHeader title="Data" showFilter={() => dispatch(NotificationActions.setShowNotificationData(true))} />),
    dataIndex: 'DateCreateUtc',
    key: 'DateCreateUtc',
    className: styles['dateHeader'],
    onFilter: (_, record: any) => {
      const now = dayjs();
      const date = now.subtract(subtractDays, 'days');
      const formatedDate = dayjs(record.DateCreateUtc);

      if (subtractDays === 0) {
        const today = now.startOf('day');
        const tomorrow = today.add(1, 'day');
        
        return formatedDate.isBefore(tomorrow) && today.isBefore(formatedDate);
      }
      
      return date.isBefore(formatedDate) || date.isSame(formatedDate, 'day');
    },
    onFilterDropdownVisibleChange: (visible) => dispatch(NotificationActions.setShowNotificationData(visible)),
    filterDropdownVisible: filters.showData,
    filterDropdown: (propsFilter: any) => {
      return <FilterDate
        width='200px'
        dates={dates}
        propsFilter={propsFilter}
      />;
    },
    render: (DateCreateUtc: string) => {
      const convertedDate = new Date(DateCreateUtc);

      return (
        <div className={styles['dateRow']}>
          <p>{formatDateByTime(convertedDate)}</p>
        </div>
      )
    },
  }
];
