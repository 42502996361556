import { NotificationActionEnum } from 'Data/enums/Notification';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import { INotificationState, IFilterDateOption } from './interfaces/INotificationState';
import { NotificationActionKeys, NotificationActionUnion } from './Notification.actions';

const dates: IFilterDateOption[] = [
  {
    name: 'Hoje',
    subtractDays: 0
  },
  {
    name: 'Últimos 7 dias',
    subtractDays: 7
  },
  {
    name: 'Últimos 15 dias',
    subtractDays: 15
  },
  {
    name: 'Últimos 30 dias',
    subtractDays: 30
  },
  {
    name: 'Últimos 60 dias',
    subtractDays: 60
  },
]

const initialState: INotificationState = {
  isLoading: false,
  isLoadingListAll: false,
  isLoadingVersionHistory: false,
  filters: {
    showNotification: false,
    showUsers: false,
    showData: false,
  },
  notifications: [],
  newNotifications: [],
  notificationsMap: [],
  fileVersionHistory: [],
  readIdNotifications: [],
  filtersDates: dates,
  subtractDays: 0
}

const notificationReducer = (
  state = initialState,
  action: NotificationActionUnion
): INotificationState => {
  switch (action.type) {
    case NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoadingListAll: true,
      }

    case NotificationActionKeys.LIST_NOTIFICATION_UPDATE:
      const newUnreadNotifications = state.notifications;

      const otherNotifications = newUnreadNotifications.filter(item => item.NotificationId !== action.payload.newNotification.NotificationId);

      return {
        ...state,
        isLoadingListAll: false,
        notifications: [...otherNotifications, action.payload.newNotification]
      }

    case NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        isLoadingListAll: false,
      }

    case NotificationActionKeys.LIST_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoadingListAll: false
      }

    case NotificationActionKeys.LIST_NEW_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      }

    case NotificationActionKeys.LIST_NEW_NOTIFICATION_UPDATE:
      const newSUnreadNotifications = state.newNotifications;

      const otherNotificationsAux = newSUnreadNotifications.filter(item => item.NotificationId !== action.payload.newNotification.NotificationId);

      return {
        ...state,
        newNotifications: [...otherNotificationsAux, action.payload.newNotification]
      }

    case NotificationActionKeys.LIST_NEW_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        newNotifications: action.payload,
      }

    case NotificationActionKeys.LIST_NEW_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    case NotificationActionKeys.SET_NOTIFICATION_MAP:
      return {
        ...state,
        notificationsMap: action.payload,
      }

    case NotificationActionKeys.UPDATE_NOTIFICATION_MAP:

      const newNotificationMap = action.payload;
      const auxNotificationsMap = state.notificationsMap;
      let newNotificationsMap: INotificationsMapAux[] = [];

      if (newNotificationMap.Action !== NotificationActionEnum.ArquivoExcluido) {
        const otherNotificationsMap = auxNotificationsMap.filter(item => item.NotificationId !== newNotificationMap.NotificationId);
        newNotificationsMap = [...otherNotificationsMap, action.payload];
      }
      else {
        newNotificationsMap = auxNotificationsMap.filter(x => x.ItemId !== newNotificationMap.ItemId);
      }

      return {
        ...state,
        notificationsMap: newNotificationsMap
      }

    case NotificationActionKeys.CLEAR_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: [],
        notificationsMap: [],
        newNotifications: [],
        isLoading: false,
      }

    case NotificationActionKeys.SET_NOTIFICATION_READ_REQUEST:

      const prevNotificationsMap3 = state.notificationsMap;
      const prevNewNotifications3 = state.newNotifications;
      const prevNotifications3 = state.notifications;

      const notificationsMapAux3 = prevNotificationsMap3
        .filter(item => (!item.hasRead))
        .map(x => ({
          ...x,
          hasRead: true,
        }))

      const newsNotificationsAux3 = prevNewNotifications3
        .filter(item => (!item.hasRead))
        .map(x => ({
          ...x,
          hasRead: true,
        }))

      const notificationsAux3 = prevNotifications3
        .filter(item => (!item.hasRead))
        .map(x => ({
          ...x,
          hasRead: true,
        }))

      const notificationsMap3 = [...notificationsMapAux3.filter(x => !notificationsMapAux3.map(x => x.NotificationId).includes(x.NotificationId)), ...notificationsMapAux3];
      const newNotifications3 = [...prevNewNotifications3.filter(x => !newsNotificationsAux3.map(x => x.NotificationId).includes(x.NotificationId)), ...newsNotificationsAux3];
      const notifications3 = [...prevNotifications3.filter(x => !notificationsAux3.map(x => x.NotificationId).includes(x.NotificationId)), ...notificationsAux3];

      return {
        ...state,
        notificationsMap: notificationsMap3,
        newNotifications: newNotifications3,
        notifications: notifications3.sort((a: INotificationData, b: INotificationData) => b.NotificationId - a.NotificationId),
      }

    case NotificationActionKeys.SET_NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        readIdNotifications: action.payload
      }

    case NotificationActionKeys.SET_NOTIFICATION_READ_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    case NotificationActionKeys.GET_FILE_VERSION_HISTORY_REQUEST:
      return {
        ...state,
        isLoadingVersionHistory: true
      }

    case NotificationActionKeys.GET_FILE_VERSION_HISTORY_SUCCESS:
      return {
        ...state,
        fileVersionHistory: action.payload,
        isLoadingVersionHistory: false
      }

    case NotificationActionKeys.GET_FILE_VERSION_HISTORY_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingVersionHistory: false
      }

    case NotificationActionKeys.TOOGLE_SHOW_FILTER_NOTIFICATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          showNotification: action.payload,
        }
      };

    case NotificationActionKeys.TOOGLE_SHOW_FILTER_USERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showUsers: action.payload
        }
      }

    case NotificationActionKeys.TOOGLE_SHOW_FILTER_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          showData: action.payload
        }
      };

    case NotificationActionKeys.CHOSE_FILTER_DATE:
      return {
        ...state,
        subtractDays: action.payload,
      };


    case NotificationActionKeys.SET_NOTIFICATION_AS_VIEW:
      const notificationIds = action.payload.map(x => x.NotificationId);
      const prevNotificationsMap = state.notificationsMap;
      const prevNewNotifications = state.newNotifications;

      const notificationsMapAux = prevNotificationsMap.filter(item => ((notificationIds.includes(item.NotificationId)) && !item.hasView)).map(x => ({
        ...x,
        hasView: true,
      }))

      const newsNotificationsAux = prevNewNotifications.filter(item => ((notificationIds.includes(item.NotificationId)) && !item.hasView)).map(x => ({
        ...x,
        hasView: true,
      }))

      const notificationsMap = [...prevNotificationsMap.filter(x => !notificationsMapAux.map(x => x.NotificationId).includes(x.NotificationId)), ...notificationsMapAux];
      const newNotifications = [...prevNewNotifications.filter(x => !newsNotificationsAux.map(x => x.NotificationId).includes(x.NotificationId)), ...newsNotificationsAux];

      return {
        ...state,
        notificationsMap,
        newNotifications
      }

    case NotificationActionKeys.SET_NOTIFICATION_AS_READ:
      const notificationIds2 = action.payload;
      const prevNotificationsMap2 = state.notificationsMap;
      const prevNewNotifications2 = state.newNotifications;
      const prevNotifications2 = state.notifications;

      const notificationsMapAux2 = prevNotificationsMap2.filter(item => ((notificationIds2.includes(item.NotificationId)) && !item.hasRead)).map(x => ({
        ...x,
        hasRead: true,
      }))

      const newsNotificationsAux2 = prevNewNotifications2.filter(item => ((notificationIds2.includes(item.NotificationId)) && !item.hasRead)).map(x => ({
        ...x,
        hasRead: true,
      }))

      const notificationsAux2 = prevNotifications2.filter(item => ((notificationIds2.includes(item.NotificationId)) && !item.hasRead)).map(x => ({
        ...x,
        hasRead: true,
      }))

      const notificationsMap2 = [...prevNotificationsMap2.filter(x => !notificationsMapAux2.map(x => x.NotificationId).includes(x.NotificationId)), ...notificationsMapAux2];
      const newNotifications2 = [...prevNewNotifications2.filter(x => !newsNotificationsAux2.map(x => x.NotificationId).includes(x.NotificationId)), ...newsNotificationsAux2];
      const notifications2 = [...prevNotifications2.filter(x => !notificationsAux2.map(x => x.NotificationId).includes(x.NotificationId)), ...notificationsAux2];

      return {
        ...state,
        notificationsMap: notificationsMap2,
        newNotifications: newNotifications2,
        notifications: notifications2.sort((a: INotificationData, b: INotificationData) => b.NotificationId - a.NotificationId),
      }

    default:
      return state;
  }


}



export default notificationReducer;
