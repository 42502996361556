
import { IAutodeskViewer } from '../../Data/interfaces/Viewer/IAutodeskViewer';
import { IFileInfo } from '../../Data/interfaces/Viewer/IGetFileInfo';
import { IViewerState } from './interfaces/IViewerState';
import { ViewerActionUnion, ViewerActionKeys } from './Viewer.actions';

const initialState: IViewerState = {
  isLoading: false,
  error: undefined,
  message: undefined,
  AutoDeskViewer: {} as IAutodeskViewer,
  FileInfo: {} as IFileInfo,
  isRedirectAutodeskViewer: false,
  openFileLink: '',
  doc: null,
  viewables: null,
  viewablesIndex: 0,
}

const viewerReducer = (state = initialState, action: ViewerActionUnion): IViewerState => {
  switch (action.type) {
    case ViewerActionKeys.AUTODESK_VIEWER_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ViewerActionKeys.AUTODESK_VIEWER_SUCCESS:
      const fileInfo = { ...state.FileInfo };
      if (fileInfo.urn == null) {
        fileInfo.urn = action.payload.urn;
        if (action.payload.status === 'error') {
          fileInfo.fileRenderStatus = 'error';
          fileInfo.isRendering = false;
        } else {
          fileInfo.urn = action.payload.urn;
          fileInfo.fileRenderStatus = 'rendering';
          fileInfo.isRendering = true;
        }
      }
      return {
        ...state,
        FileInfo: fileInfo,
        isLoading: false
      }
    case ViewerActionKeys.AUTODESK_VIEWER_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case ViewerActionKeys.GET_FILE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ViewerActionKeys.GET_FILE_INFO_SUCCESS:
      return {
        ...state,
        FileInfo: action.payload,
        isLoading: false
      }
    case ViewerActionKeys.GET_FILE_INFO_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case ViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK:
      return {
        ...state,
        FileViewerAutodesk: action.payload,
        isLoading: false
      }

    case ViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK:
      const fileViewer = { ...state }.FileViewerAutodesk;
      if (fileViewer &&
        fileViewer?.fileIdentifier === action.payload.fileIdentifier &&
        ((fileViewer.etapa < action.payload.etapa) || (fileViewer.etapa === action.payload.etapa && fileViewer?.progress < action.payload.progress))) {

        return {
          ...state,
          FileViewerAutodesk: action.payload,
          isLoading: false
        }
      }
      return {
        ...state,
      }

    case ViewerActionKeys.PDF_AUTODESK_VIEWER_REQUEST:
      return {
        ...state,
        isRedirectAutodeskViewer: true,
        isLoading: true
      }

    case ViewerActionKeys.SET_OPEN_FILE_LINK:
      return {
        ...state,
        openFileLink: action.payload,
      }

    case ViewerActionKeys.SET_SIMPLE_VIEWER_DOC:
      return {
        ...state,
        doc: action.payload,
      }
    case ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES:
      return {
        ...state,
        viewables: action.payload,
      }
    case ViewerActionKeys.SET_SIMPLE_VIEWER_VIEWABLES_INDEX:
      return {
        ...state,
        viewablesIndex: action.payload,
      }

    default:
      return state;
  }
};

export default viewerReducer