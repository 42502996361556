import styled, { createGlobalStyle, css } from "styled-components";
import { Dropdown } from "antd";

interface IMenuDropdown {
  isactive?: string;
}

export const MenuDropdown = styled(Dropdown) <IMenuDropdown>`
  
  ${({ isactive }) => isactive === 'true' && css`
    display: flex !important;
  `}
`;

interface IMenuItemStyled {
  copy?: string;
}

export const MenuItemStyled = styled.div<IMenuItemStyled>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .iconMenuItem {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .textItem {
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
 
  ${({ copy }) => copy === 'true' && css`

    .iconMenuItem {
      color: ${({ theme }) => theme.colors.success.success} !important;
    }

    .textItem {
      color: ${({ theme }) => theme.colors.success.success};
    }
  `}
`;

export const ActionsMenuItemDropdownGlobalStyles = createGlobalStyle`
  .rowMenuActionsDropdown {
    border-radius: 0;
    margin: 0;

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }
  }

  .rowDeleteActionsDropdown {
    border-radius: 0;
    margin: 0;

    .iconMenuItemDelete {
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      
      &:hover {
        color: ${({ theme }) => theme.colors.danger.danger} !important;
      }
    }

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerHover}, ${theme.colors.surface.surface})`} !important;

      .iconMenuItemDelete {
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.danger.danger} !important;
      }
    }
  }

  .ActionsMenuItemDropdown {
    background-color: ${({ theme }) => theme.colors.surface.surface};
    border-radius: 4px !important;
    overflow: hidden;
    
    .ant-dropdown-menu {
      padding: 4px 0;
      
      &:hover {
        background-color: ${({ theme }) => theme.colors.surface.surface};
      }
    }
  }

  .ant-dropdown {
    padding: 0 !important;
    z-index: 1093;
  }
`;
