import { FC, ReactNode, useMemo, useRef, useState } from "react";
import { Control, FieldValues, UseFormTrigger } from "react-hook-form";
import { MenuProps } from "antd/lib/menu";
import { Dropdown } from "antd";
import FieldControled from "../FieldControled";
import { DropdownContainer } from "./styles";

interface IOpt {
  key: string;
  label: ReactNode;
  labelSearch: string;
}

interface ISelectStage {
  key: string;
  name: string;
  control: Control;
  value: string;
  label: string;
  opts: IOpt[];
  emptyLabel?: ReactNode;
  height?: string;
  trigger: UseFormTrigger<FieldValues>;
  onChangeValue: (value: string) => void;
}

const SelectStage: FC<ISelectStage> = ({
  key,
  name,
  label,
  emptyLabel,
  value,
  opts,
  control,
  height,
  trigger,
  onChangeValue,
}) => {
  const currValRef = useRef('');
  const [open, setOpen] = useState(false);
  const [tempValue, setTempValue] = useState<string>('');

  const itemsFiltered = useMemo(() => {
    const data: MenuProps['items'] = [];

    if (opts && opts.length > 0) {
      for (const opt of opts) {
        if (
          !tempValue ||
          (tempValue &&
            opt.labelSearch.toLowerCase().includes(tempValue.toLowerCase()))
        ) {
          data.push({
            key: opt.key,
            label: opt.label,
            className: 'dropdownStyledMenuItem',
          });
        }
      }
    }

    if (data.length === 0) {
      data.push({
        key: 'noData',
        label: emptyLabel || 'Nenhum dado encontrado.',
        className: 'dropdownStyledMenuItem',
        disabled: true,
      });
    }

    return data;
  }, [opts, tempValue, emptyLabel]);

  const handleMenuClick: MenuProps['onClick'] = (event) => {
    setTempValue('');
    onChangeValue(event.key || '');
    trigger(name);
    currValRef.current = event.key;
    setOpen(false);
  }

  const menuProps: MenuProps = {
    items: itemsFiltered,
    onClick: handleMenuClick,
  };

  const handleClear = () => {
    setTempValue('');
    onChangeValue('');
    trigger(name);
    currValRef.current = '';
  }

  const handleChange = (value: string) => {
    setTempValue(value);
    onChangeValue(value || '');
    trigger(name);
    setOpen(true);
  }

  const handleBlur = () => {
    if (!open && !opts?.some((opt) => opt.key === value)) {
      handleClear();
    };
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) setOpen(open);

    if (!open && !currValRef.current) {
      if (itemsFiltered.length > 0 && itemsFiltered[0]?.key !== 'noData') {
        setTempValue('');
        onChangeValue(itemsFiltered[0]?.key
          ? itemsFiltered[0].key.toString() || ''
          : ''
        );
        trigger(name);
        currValRef.current = itemsFiltered[0]?.key
          ? itemsFiltered[0].key.toString() || ''
          : '';
      } else {
        handleClear();
      }
    };
  }

  return (
    <>
      <DropdownContainer />
      <Dropdown
        open={open}
        menu={menuProps}
        placement="topLeft"
        trigger={['click']}
        onOpenChange={handleOpenChange}
        overlayClassName="dropdownStyledMenu"
      >
        <a onClick={(e) => e.preventDefault()}>
          <FieldControled
            key={key}
            name={name}
            control={control}
            label={label}
            className="field"
            height={height || "30px"}
            trigger={trigger}
            onChange={handleChange}
            onClick={() => !open && setOpen(true)}
            onBlur={handleBlur}
          />
        </a>
      </Dropdown>
    </>
  )
}

export default SelectStage;
