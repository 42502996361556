import { FC } from 'react';

import { Tooltip as TooltipAnt } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import '../../../Static/css/tooltip.css';

const Tooltip: FC<TooltipProps> = (props) => {
  return (
    <TooltipAnt
      {...props}
      placement={props.placement ?? 'bottomLeft'}
      overlayStyle={props.overlayStyle ?? {padding: 0}}>
      {props.children}
    </TooltipAnt>
  );
};

export default Tooltip;
