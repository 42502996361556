import React from 'react';

type FiltersProps = {
  notificationList: any[],
  key: string,
  render?: (value: any) => string,
  validation?: (index: any) => boolean,
  renderNode?: (value: any) => React.ReactNode,
}

type OptionsProps = {
  description: string;
  renderNode?: React.ReactNode;
  value: string;
}

export const getFilters = (
  notificationList: any[],
  key: string,
  render?: (value: any) => string,
  validation?: (index: any) => boolean,
  renderNode?: (value: any) => React.ReactNode
) => {
  const types: any = {};
  const filterList: OptionsProps[] = [];
  notificationList?.forEach((file) => {
    if (file[key] === undefined || file[key] === null) {
      return;
    }
    const updateIndex = render ? render(file[key]) : file[key];
    
    if (
      types[updateIndex] === undefined &&
      (!validation || (validation && validation(updateIndex)))
    ) {
      types[updateIndex] = true;
      filterList.push({
        description: updateIndex.toString(),
        renderNode: renderNode && renderNode(updateIndex),
        value: updateIndex.toString()
      });
    }
  });

  return filterList;
}

export const textDate = [
  'Hoje',
  'Últimos 7 dias',
  'Últimos 15 dias',
  'Últimos 30 dias',
  'Últimos 90 dias',
]

export const filterDate = (
  notificationList: any[],
  key: string,
  render?: (value: any) => string[],
  validation?: (index: any) => boolean,
  renderNode?: (value: any) => React.ReactNode
) => {
  const types: any = {};
  const filterList: OptionsProps[] = [];
  notificationList?.forEach((file) => {
    if (file[key] === undefined || file[key] === null) {
      return;
    }
    const updateIndex = render ? render(file[key]) : file[key];
    
    if (
      types[updateIndex] === undefined &&
      (!validation || (validation && validation(updateIndex)))
    ) {
      types[updateIndex] = true;
      filterList.push({
        description: updateIndex.toString(),
        renderNode: renderNode && renderNode(updateIndex),
        value: updateIndex.toString()
      });
    }
  });

  return filterList;
}