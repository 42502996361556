import { FC, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { Image } from "antd";
import {
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ActionsCard,
  HeaderBar,
  NameCard,
  PreviewGroupStyled,
  ToolBar,
} from "./styles";

interface IImagePreviewGroup {
  imagesForShow: string[];
  srcName?: string;
  visible?: boolean;
  visibleOn: boolean;
  current?: number;
  HeaderButtons?: ReactNode;
  onCurrentIndex: (index: number) => void;
  onChangeVisible?: (visible: boolean) => void;
}

const ImagePreviewGroup: FC<IImagePreviewGroup> = ({
  imagesForShow,
  srcName,
  visible,
  visibleOn,
  current,
  HeaderButtons,
  onCurrentIndex,
  onChangeVisible,
  children,
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  const [active, setActive] = useState(false);
  const [currLoad, setCurrLoad] = useState(
    new Map<number, boolean>(imagesForShow.map((img, i) => [i, true]))
  );

  const antIcon = <LoadingOutlined rev="" style={{ color: theme.colors.primary.primary, fontSize: 144 }} spin />;

  const handlePreviewOn = (value: boolean) => {
    if (onChangeVisible) {
      onChangeVisible(value);
    }

    setActive(value);
    dispatch(ActivitiesActions.attachmentPreviewOn(value));
  }

  const handleImageLoad = (curr: number) => {
    if (currLoad.get(curr)) {
      setCurrLoad(prev => {
        return new Map(prev.set(curr, false))
      });
    }
  }

  return (
    <>
      <PreviewGroupStyled />
      <Image.PreviewGroup
        items={imagesForShow}
        preview={{
          imageRender: (originalNode, info) => {
            const isLoading = currLoad.get(info.current);
            if ((visible && isLoading) || (!visibleOn && active && isLoading)) {
              originalNode.props?.imgRef?.current?.addEventListener('load', () => handleImageLoad(info.current));
              originalNode.props?.imgRef?.current?.setAttribute('hidden', true);
            } else {
              originalNode.props?.imgRef?.current?.removeAttribute('hidden');
            }

            return (
              <>
                {originalNode}
                {isLoading && antIcon}
              </>
            );
          },
          visible: visibleOn ? visible : undefined,
          onChange: (current, prevCurrent) => {
            onCurrentIndex(current);
          },
          onVisibleChange: (value, prevValue, current) => {
            handlePreviewOn(value);
            onCurrentIndex(current);
          },
          toolbarRender: (originalNode, info) => (
            <ToolBar>
              <MinusOutlined
                rev=""
                className="minusIcon"
                onClick={() => info.actions.onZoomOut()}
              />
              <SearchOutlined rev="" className="searchIcon" />
              <PlusOutlined
                rev=""
                className="plusIcons"
                onClick={() => info.actions.onZoomIn()}
              />
            </ToolBar>
          ),
          countRender: () => (
            <HeaderBar>
              <div>
                {!!srcName && (
                  <NameCard>
                    <span className="nameCardText">{srcName}</span>
                  </NameCard>
                )}
              </div>
              <div>
                {!!HeaderButtons && (
                  <ActionsCard>
                    {HeaderButtons}
                  </ActionsCard>
                )}
              </div>
            </HeaderBar>
          ),
          rootClassName: 'previewGroupCustomClass',
          current: typeof current === 'number' ? current : undefined,
        }}
      >
        {children}
      </Image.PreviewGroup>
    </>
  )
};

export default ImagePreviewGroup;
