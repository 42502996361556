import React, { FC, useEffect } from "react";
import styles from "./CancelUpdatePlan.module.scss";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";

interface ICancelUpdatePlan {
  visible?: boolean;
  onClose: () => void;
  downOrUp?: number;
  confirmCancelUpdate: () => void;
  isLoading?: boolean;
}

const CancelUpdatePlan: FC<ICancelUpdatePlan> = ({ 
  visible, 
  onClose, 
  downOrUp, 
  confirmCancelUpdate, 
  isLoading 
}) => {
  const [send, setSend] = React.useState(false); 

  useEffect(() => {
    if(send && !isLoading) {
      onClose();
    }
  }, [send, isLoading]);

  const onConfirm = () => {
    setSend(true);
    confirmCancelUpdate();
  }

  const buttons = (
    <>
      {downOrUp === 1 && <>
        <Button 
          onClick={onConfirm}
          className={styles['cancel-buttons']}
          loading={isLoading}
        >
          Sim, cancelar upgrade
        </Button>
        <Button 
          type="primary"
          onClick={onClose}
          className={styles['cancel-buttons']}
        >
          Voltar ao processo
        </Button>
      </>}
      {downOrUp === 2 && <>
        <Button 
          onClick={onClose}
          className={styles['cancel-buttons']}
          loading={isLoading}
        >
          Voltar ao processo
        </Button>
        <Button 
          type="primary"
          onClick={onConfirm}
          className={styles['cancel-buttons']}
        >
          Cancelar downgrade
        </Button>
      </>}
    </>
  );

  return (
    <Modal 
      title="Você tem certeza?"
      visible={visible}
      onCancel={onClose}
      footerButtons={buttons}
      width={515}
    >
      <div className={styles['main-wrapper']}>
        {downOrUp === 1 && <>
          <p>{'Você tem certeza que deseja cancelar o processo de upgrade do seu plano?'}</p>
          <p>{'Se você cancelar, terá que refazer o processo de solicitação de alteração do plano.'}</p>
        </>}

        {downOrUp === 2 && <>
          <p>{'Você tem certeza que deseja cancelar o processo de downgrade do seu plano?'}</p>
          <p>{'Se você cancelar, terá que refazer o processo de solicitação de alteração do plano.'}</p>
        </>}
        
      </div>
    </Modal>
  )
}

export default CancelUpdatePlan;