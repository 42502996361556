import styled, { createGlobalStyle, css } from "styled-components";
import Button from "Components/UI/Button";

export const MenuEmptyStyled = styled.div`
  width: 276px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 22px;
  cursor: pointer;

  .emptyPanels {
  }

  .textTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    text-align: center;
    margin-top: 32px;
  }

  .textDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    text-align: center;
    margin-top: 6px;
  }

  .buttonAdd {
    height: 40px !important;
    display: flex;
    align-items: center;
    margin-top: 24px;
    
    .iconMenuItem {
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
    }
  
    .textItem {
      font-weight: 400;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;

export const MenuInputStyled = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  padding-left: 10px;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};

  .customSearch {
    margin-left: 0;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};

    .icon {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }

  .customSearchInput {
    margin: 0;
    margin-left: 6px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
  }
`;

export const MenuMainStyled = styled.div`
  width: 276px;
  height: auto;
  max-height: 360px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  cursor: pointer;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${({ theme }) => theme.colors.surface.surface};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
  }

  .customRow {
    height: 36px;
    padding-right: 10px;
    padding-left: 2px;
    border-radius: 6px;

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
    }
  }

  .customDragRow {
    height: 36px;
    padding-right: 10px;
    padding-left: 2px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.surface.surface};
    box-shadow: 0px 6px 16px -2px rgba(0, 0, 0, 0.12);
  }
`;

export const MenuTitleStyled = styled.div`
  width: 100%;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .textItem {
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }
`;

interface IMenuItemStyled {
  isdropactive?: string;
}

export const MenuItemStyled = styled.div<IMenuItemStyled>`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .leftCoordlyIconOptionRow {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      overflow: hidden;
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
      background-color: ${({ theme }) => theme.colors.primary.primary} !important;
    }

    .leftIconOptionRowCustom {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }

    .leftIconOptionRow {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  
    .textItem {
      font-weight: 400;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pinSpan {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .pinIcon {
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      }
    }

    .actionIcon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      margin-left: 6px;
    }

    .rightHide {
      display: none;
      flex-direction: row;
      align-items: center;
    }
  }

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
    
    .right {

      .rightHide {
        display: flex;
      }
    }
  }


  ${({ isdropactive }) => isdropactive === 'true' && css`
    .right {

      .rightHide {
        display: flex;
      }
    }
  `}
`;

export const MenuButtonStyled = styled(Button)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: transparent !important;
  cursor: pointer;

  .iconMenuItem {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .textItem {
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &:active {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
  }
  
`;

export const ActionPanelsGlobalStyles = createGlobalStyle`
  .rowMenu {

    &:hover {
      background-color: transparent !important;
    }
    
    &:active {
      background-color: transparent !important;
    }
  }

  .rowMain {

    &:hover {
      background-color: transparent !important;
    }
    
    &:active {
      background-color: transparent !important;
    }
  }

  .ant-dropdown {
    z-index: 1090;

    .ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }
  }
`;
