import { FC } from 'react';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import { Collapse, Row, Col } from 'antd';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import { Icon } from 'Components/UI';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps } from 'react-redux';
import MockPapeisPermissoes from './mockPapeisPermissoes.json';
import LogoPapeisPermissoes from 'Static/images/logo-papeis-permissoes.png';
import styles from './PapeisPermissoes.module.scss';
import './PapeisPermissoes-override.css';

interface IPapeisPermissoes {
  isVisible?: boolean;
  onCancel?: () => void;
  isEdit?: boolean;
}

interface IDadosPapeisPermissoes {
  title: string;
  admin: {
    hasPermission: string | boolean;
    marked: boolean;
  };
  coordernador: {
    hasPermission: string | boolean;
    marked: boolean;
  };
  engenheiro: {
    hasPermission: string | boolean;
    marked: boolean;
  };
  projetista: {
    hasPermission: string | boolean;
    marked: boolean;
  };
  consultor: {
    hasPermission: string | boolean;
    marked: boolean;
  };
}

const breadCrumbs: IBreadcrumbItem[] = [
  {
    description: 'Gerenciar usuários',
    href: '/gerenciar-usuarios/usuarios'
  },
  {
    description: 'Papéis e Permissões',
    href: '/gerenciar-usuarios/papeis'
  }
];
const { Panel } = Collapse;

const PapeisPermissoes: FC<Props> = (props) => {

  return (
    <div className={styles['hearderPage']}>
      <PageHeader name="Papéis e Permissões">
        <div className={styles['breadCrumb']}>
          <BreadCrumb breadcrumbs={breadCrumbs} />
        </div>
        <div className={styles['infoPapelContainer']}>
          <div className={styles['containerPapeisPermissoes']}>
            <img src={LogoPapeisPermissoes} alt="PapeisPermissoesLogo" />
          </div>
          <div className={styles['text']}>
            <h2>O que são papéis e permissões?</h2>
            <h3>
              Cada <b>papel</b> possui <b>permissões</b> específicas que garantem
              diferentes níveis de acesso aos usuários. Veja abaixo, como cada Papel é
              distribuído em função dos módulos do sistema.
            </h3>
            {/* <h3>
              <b>Ainda tem dúvidas?</b> Leia o artigo sobre{' '}
              <b>"Como alterar as permissões de um usuário?"</b> no menu Ajuda.
            </h3> */}
          </div>
        </div>
        <Row gutter={[16, 16]} className={styles['headerPapelContainer']}>
          <Col span={6} style={{ padding: '8px 8px 8px 36px', textAlign: 'left' }}>
            <b>PERMISSÕES</b>
          </Col>
          <Col span={2}>
            <b>Admin</b>
          </Col>
          <Col span={4}>
            <b>Coordenador</b>
          </Col>
          <Col span={4}>
            <b>Projetista</b>
          </Col>
          <Col span={4}>
            <b>Engenheiro</b>
          </Col>
          <Col span={4}>
            <b>Consultor</b>
          </Col>
        </Row>
        <div className={styles['pageContainer']}>
          <>
            {MockPapeisPermissoes.modulos.map((modulo: any) => (
              <div className={styles['containerCollapse']}>
                <Collapse
                  expandIconPosition={'right'}
                  style={{ backgroundColor: 'white', border: '1px solid #EAEDF0' }}
                  bordered={false}
                >
                  <Panel
                    header={
                      <div className={styles['panelContainer']}>
                        <Icon
                          icon={modulo.icon}
                          className={styles['iconTablePapeisPermissoes']}
                          customSize={18}
                        />
                        {modulo.nomeModulo}
                      </div>
                    }
                    style={{ padding: '0px' }}
                    key={modulo.index}
                  >
                    {modulo.data.map((infoModulo: IDadosPapeisPermissoes, index: any) => (
                      <Row
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        className={
                          index % 2 === 0 ? styles['indexGray'] : styles['indexWhite']
                        }
                        justify="space-between"
                      >
                        <Col className={styles['rowTitleContainer']} span={6}>
                          <div className={styles['title']}>{infoModulo.title}</div>
                        </Col>
                        <Col className={styles['rowDataContainer']} span={2}>
                          {typeof infoModulo.admin.hasPermission === 'string' ? (
                            <div
                              className={
                                infoModulo.admin.marked
                                  ? styles['rowDataMarked']
                                  : styles['rowDataUnMarked']
                              }
                            >
                              {infoModulo.admin.hasPermission}
                            </div>
                          ) : (
                            <>
                              {infoModulo.admin.hasPermission === true ? (
                                <Icon
                                  icon={'confirmar'}
                                  className={
                                    infoModulo.admin.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              ) : (
                                <Icon
                                  icon={'cancelar'}
                                  className={
                                    infoModulo.admin.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styles['rowDataContainer']} span={4}>
                          {typeof infoModulo.coordernador.hasPermission === 'string' ? (
                            <div
                              className={
                                infoModulo.coordernador.marked
                                  ? styles['rowDataMarked']
                                  : styles['rowDataUnMarked']
                              }
                            >
                              {infoModulo.coordernador.hasPermission}
                            </div>
                          ) : (
                            <>
                              {infoModulo.coordernador.hasPermission === true ? (
                                <Icon
                                  icon={'confirmar'}
                                  className={
                                    infoModulo.coordernador.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              ) : (
                                <Icon
                                  icon={'cancelar'}
                                  className={
                                    infoModulo.coordernador.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styles['rowDataContainer']} span={4}>
                          {typeof infoModulo.projetista.hasPermission === 'string' ? (
                            <div
                              className={
                                infoModulo.projetista.marked
                                  ? styles['rowDataMarked']
                                  : styles['rowDataUnMarked']
                              }
                            >
                              {infoModulo.projetista.hasPermission}
                            </div>
                          ) : (
                            <>
                              {infoModulo.projetista.hasPermission === true ? (
                                <Icon
                                  icon={'confirmar'}
                                  className={
                                    infoModulo.projetista.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              ) : (
                                <Icon
                                  icon={'cancelar'}
                                  className={
                                    infoModulo.projetista.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styles['rowDataContainer']} span={4}>
                          {typeof infoModulo.engenheiro.hasPermission === 'string' ? (
                            <div
                              className={
                                infoModulo.engenheiro.marked
                                  ? styles['rowDataMarked']
                                  : styles['rowDataUnMarked']
                              }
                            >
                              {infoModulo.engenheiro.hasPermission}
                            </div>
                          ) : (
                            <>
                              {infoModulo.engenheiro.hasPermission === true ? (
                                <Icon
                                  icon={'confirmar'}
                                  className={
                                    infoModulo.engenheiro.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              ) : (
                                <Icon
                                  icon={'cancelar'}
                                  className={
                                    infoModulo.engenheiro.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styles['rowDataContainer']} span={4}>
                          {typeof infoModulo.consultor.hasPermission === 'string' ? (
                            <div
                              className={
                                infoModulo.consultor.marked
                                  ? styles['rowDataMarked']
                                  : styles['rowDataUnMarked']
                              }
                            >
                              {infoModulo.consultor.hasPermission}
                            </div>
                          ) : (
                            <>
                              {infoModulo.consultor.hasPermission === true ? (
                                <Icon
                                  icon={'confirmar'}
                                  className={
                                    infoModulo.consultor.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              ) : (
                                <Icon
                                  icon={'cancelar'}
                                  className={
                                    infoModulo.consultor.marked
                                      ? styles['rowIconMarked']
                                      : styles['rowIconUnMarked']
                                  }
                                  customSize={12}
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Panel>
                </Collapse>
              </div>
            ))}
          </>
        </div>
      </PageHeader>
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  ...state.auth,
  isLoading: state.tenant.isLoading,
  tenantRoles: [...(state.tenant.TenantRoles || [])],
  TenantSharedUsers: [...(state.tenant.Users || [])],
  minhaConta: state.minhaConta.userInfo,
  UsersWithConstruction: state.tenant.UsersWithConstruction
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPapeisPermissoes;

export default connector(PapeisPermissoes);
