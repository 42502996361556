import ReactDOM from 'react-dom';
import App from './Pages/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { history, store } from './Store';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import TagManager from 'react-gtm-module';

import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';

import './Static/icomoon/style.css';

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY}`
};

TagManager.initialize(tagManagerArgs);

const root = document.getElementById('root') as HTMLElement;
ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="bottom-right"
      newestOnTop={false}
      style={{ maxHeight: 285, height: 'auto' }}
    />
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
