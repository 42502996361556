import { FC } from "react"
import { CardOption, Container } from "./styles";
import { Icon, IconName } from "Components/UI";

export type ActiveTabType = 'appearance' | 'loginScreen' | 'customDomain';

export interface IActiveTab {
  id: ActiveTabType;
  label: string;
  icon: IconName;
};

interface IOptionsTabs {
  activeTab: IActiveTab;
  options: IActiveTab[];
  onActiveTab: (tab: IActiveTab) => void;
}

const OptionsTabs: FC<IOptionsTabs> = ({
  activeTab,
  options,
  onActiveTab,
}) => {

  return (
    <Container>
      {options.map(option => {
        const isActive = option.id === activeTab.id;

        return (
          <CardOption 
            key={option.id}
            isActive={`${isActive}`} 
            onClick={() => onActiveTab(option)}
          >
            <Icon 
              icon={option.icon}
              customSize={16}
              className="cardOptionsIcon"
            />
            <span className="cardOptionsText">{option.label}</span>
          </CardOption>
        )
      })}
    </Container>
  )
}

export default OptionsTabs;
