import { FC } from "react";
import { Container } from "./styles";
import { Checkbox, ConfigProvider } from "antd";
import { getDisciplineIcon } from "Utils/ObraUtils";
import { Icon } from "Components/UI";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import Tooltip from "Components/UI/Tooltip";

interface IItemDiscipline {
  name: string;
  checked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  onCheck: () => void;
}

const ItemDiscipline: FC<IItemDiscipline> = ({
  name,
  checked,
  loading,
  disabled,
  tooltipText,
  onCheck,
}) => {
  const theme = useSelector(getTheme);

  return (
    <ConfigProvider theme={{
      token: {
        colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
        colorTextDisabled: theme.colors.primary.onPrimary,
        colorPrimary: theme.colors.primary.primary
      }
    }}>
      <Container
        isloading={loading}
        onClick={(!loading && !disabled) ? onCheck : undefined}
      >
        <Tooltip
          placement="top"
          title={(disabled && !!tooltipText) ? tooltipText : ''}
        >
          <Checkbox checked={checked} disabled={disabled || loading} />
        </Tooltip>
        <Icon icon={getDisciplineIcon(name)} customSize={16} className="icon" />
        <span className="disciplinaTitle">{name}</span>
      </Container>
    </ConfigProvider>
  )
}

export default ItemDiscipline;
