import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: center;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-top: 12px;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: center;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    margin-top: 6px;
  }
`;