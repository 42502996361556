import { FC } from 'react';

import { Table as TableAnt } from 'antd';
import { TableProps as TableAntProps } from 'antd/es/table';
import './table.css';
import './spacedRows.css';

interface ITableProps<T> extends TableAntProps<T> {
  isSpaced?: boolean;
}

const Table: FC<ITableProps<any>> = (props) => {
  props = { ...props, pagination: props.pagination ?? false };
  if (props.isSpaced) {
    props = {...props, className: 'spacedRows'};
  }

  props.columns?.forEach((column) => {
    column.filterIcon = column.onFilter ? <span style={{width: 0}}></span> : undefined;
  });

  return (
    <TableAnt {...props} >
      {props.children}
    </TableAnt>
  );
};

export default Table;
