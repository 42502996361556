import { FC } from "react";
import Button from  "Components/UI/Button";
import { ButtonsWrapper } from "./styles";

interface IButtonsAction {
  loading?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  marginTop?: number;
  isSubmit?: boolean;
}

const ButtonsAction: FC<IButtonsAction> = ({
  loading,
  onConfirm,
  onCancel,
  disabledConfirm,
  disabledCancel,
  marginTop,
  isSubmit,
}) => {
  return (
    <ButtonsWrapper marginTop={marginTop}>
      {isSubmit && (
        <Button
          className="btnConfirm"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={disabledConfirm}
        >
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      )}
      {!isSubmit && onConfirm && (
        <Button
          className="btnConfirm"
          type="primary"
          loading={loading}
          disabled={disabledConfirm}
          onClick={onConfirm}
        >
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      )}
      <Button
        className="btnCancel"
        type="text"
        disabled={disabledCancel}
        onClick={onCancel}
      >
        Cancelar
      </Button>
    </ButtonsWrapper>
  )
};

export default ButtonsAction;
