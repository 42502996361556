import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './AvisoInativarUsuario.module.scss';
import Button from '../../../../Components/UI/Button';
import Modal from '../../../../Components/UI/Modal/Modal';
import { ITenantSharedUsers } from 'Data/interfaces/Tenant/ITenantUsersWithConstruction';
import { TenantActions } from 'Store/Tenant/Tenant.actions';

interface IAvisoInativarUsuario {
  visible?: boolean;
  onClose: () => void;
  isLoading?: boolean;
  user?: ITenantSharedUsers;
}

const AvisoInativarUsuario: FC<IAvisoInativarUsuario> = ({
  visible,
  onClose,
  isLoading,
  user
}) => {
  const dispatch = useDispatch();

  const [isSendingData, setIsSendingData] = useState<boolean>(false);

  const updateUserStatus = () => {
    if(user) {
      dispatch(TenantActions.updateTenantStatus({
        tenantUserId: user?.TenantUserId,
        isActive: !user?.isActive
      }));
      setIsSendingData(true);
    }
  }

  if (!isLoading && isSendingData) {
    onClose();
    setIsSendingData(false);
  }

  const buttons = (
    <div>
      <Button 
        loading={isLoading} 
        type={'link'} 
        className={styles['link']} 
        onClick={updateUserStatus}
      >
          Sim, inativar usuário
        </Button>
      <Button 
        className={styles['button']} 
        type="primary" height={37} 
        onClick={onClose}
      >
        Cancelar
      </Button>
    </div>
  );
  return (
    <Modal
      title="Atenção!"
      footerButtons={buttons}
      visible={visible}
      width={515}
      onCancel={onClose}
    >
      <div className={styles['descriptionWrapper']}>
        <div>O usuário "{user?.User.Nome}" será inativado e não poderá mais acessar a construtora.</div>
        <div>Deseja mesmo inativá-lo?</div>  
      </div>
    </Modal>
  );
};

export default AvisoInativarUsuario;
