import { FC, useMemo } from 'react';
import { Collapse, Drawer, List } from 'antd';
import { IProperties } from 'Data/interfaces/FederatedViewer/IProperties';
import Ellipsis from 'Components/UI/Ellipsis';
import emptyProperties from "../../../../Static/images/empty-properties.png";
import { CaretRightOutlined } from '@ant-design/icons';
import styles from './PropertiesDrawer.module.scss';
import '../FederatedDrawer/FederatedDrawer.css';

const { Panel } = Collapse;

interface IPropertiesDrawer {
  properties: IProperties | null;
  visible: boolean;
  onClose: () => void;
  onShow: () => void;
}

const PropertiesDrawer: FC<IPropertiesDrawer> = ({
  properties,
  visible,
  onClose,
  onShow,
}) => {
  const filteredProperties = useMemo(() => {
    return properties?.properties.filter(property => !property?.categoryName?.startsWith('_') && !property?.categoryName?.endsWith('_'));
  }, [properties]);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => onClose()}
      visible={visible}
      destroyOnClose={true}
      width={300}
      height={541}
      style={{
        position: 'absolute',
        height: 541,
        marginRight: 12,
        marginTop: 126,
        border: visible ? '1px solid #EAEDF0' : 'none',
        borderTop: visible ? 'none' : undefined,
        borderRight: visible ? '1px solid #EAEDF0' : undefined,
      }}
      className={styles['drawer']}
      zIndex={2}
      mask={false}
      title="Propriedades"
      headerStyle={{
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
        color: '#44566C',
        position: 'fixed',
        height: '43px',
        width: '300px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '-4px',
        background: '#FFFFFF',
        zIndex: 2,
      }}
    >
      <div style={{ height: '43px' }} />
      {properties && properties?.properties.length > 0 ? (
        <div className={styles['childrenContainer']}>
          <List.Item className={styles['headerList']}>
            <span className={styles['headerLeftText']}>Name</span>
            <Ellipsis
              phrase={properties?.name || ''}
              maxLength={38}
              className={styles['headerRightText']}
            />
          </List.Item>
          {filteredProperties?.map((property, index) => (
            <Collapse
              key={`${property.categoryName}-${index}`}
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rev="" className={styles['iconPanel']} rotate={isActive ? 90 : 0} />}
              className={styles['childrenCollapse']}
            >
              <Panel
                key={property.categoryName}
                header={property.categoryName}
                className={styles['childrenPanel']}
              >
                <List
                  itemLayout="horizontal"
                  className={styles['listPanel']}
                  dataSource={property.categoryProperties}
                  bordered={false}
                  renderItem={(item, index) => {
                    return (
                      <List.Item
                        key={`${item.displayName}-${index}`}
                        className={styles['childrenList']}
                      >
                        <Ellipsis
                          phrase={item.displayName}
                          maxLength={18}
                          className={styles['listLeftText']}
                        />
                        <Ellipsis
                          phrase={`${item.displayValue} ${item.units}`}
                          maxLength={18}
                          className={styles['listRightText']}
                        />
                      </List.Item>
                    )
                  }}
                />
              </Panel>
            </Collapse>
          ))}
        </div>
      ) : (
        <div className={styles['emptyDrawer']}>
          <img src={emptyProperties} alt="Drawer de propriedades vazio" />
          <p>Nenhum objeto selecionado</p>
        </div>
      )}
    </Drawer>
  );
}

export default PropertiesDrawer;
