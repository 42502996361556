import styled from "styled-components"

export const Buttons = styled.div`
  .buttonCancel {
    border-radius: 5px !important;
  }
`;

export const Wrapper = styled.div`
  padding: 24px 14px 18px 14px;
  background: ${({theme}) => theme.colors.surface.surface};

  .midleHr {
    margin-top: 12px;
    margin-bottom: 18px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  }

  .titleContent {
    display: flex;
    padding: 0 10px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }
  }

  .descriptionContent {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurface};
  
      &+.description {
        margin-top: 16px !important;
      }
    }
  }
`;

export const FooterHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 0 20px;
`;
