import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  width: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .iconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 0;
  }
`;

interface ILine {
  height: number;
  istransparent?: string;
}

export const Line = styled.div<ILine>`
  width: 1px;
  height: calc(${({ height }) => height}px - 15px);
  background-color: ${({ istransparent, theme }) => istransparent === 'true'
    ? 'transparent'
    : theme.colors.surface.surfaceOutline};
`;

export const Right = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3px 0 3px 12px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .rightHeaderLeft {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
      margin-left: 4px;
    }
  
    .action {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
      margin-left: 2px;
    }
  }

  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const RightContent = styled.div`
  margin-top: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;
