import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  UniqueIdentifier,
  DragEndEvent,
  DragOverEvent,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import { ActivitiesActions } from 'Store/Activities/Activities.actions';
import Column from './components/Column';
import Item from './components/Item';
import { 
  IDragAndDropDataFormat, 
  ITopicDnDResponse, 
  ITopicStatusDnDFormat,
} from 'Data/interfaces/Activities/IDragAndDropDataFormat';
import { IUpdateListRequest } from 'Data/interfaces/Activities/IUpdateList';
import { 
  IChangeStatus, 
  IInitialListUpdateRequest,
} from 'Data/interfaces/Activities/IChangeStatus';
import { 
  complexfilterActivities, 
  filterActivities, 
  sortActivities,
} from 'Utils/ActivitiesUtils';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { 
  IFiltroOptions, 
  enumToStringValueMap,
} from 'Data/interfaces/Activities/IFilters';

interface IKanbanVision {
  listActivities?: IDragAndDropDataFormat;
  height: number;
  searchActivity: string;
  onOpenCreateActivity: () => void;
  onOpenShowActivity: (activity: string) => void;
  endStatus?: IChangeStatus;
  showFilters?: boolean;
  filters?: Record<string, IFiltroOptions<ITopicDnDResponse>>;
  hasFilters: boolean;
}

interface IColumnCreate {
  column: ITopicStatusDnDFormat;
  filteredActivities: ITopicDnDResponse[];
}
interface IColumnCreateObj {
  columns: Record<string, IColumnCreate>
  totalFilteredAllItems: number;
}

const KanbanVision = ({
  listActivities,
  height,
  searchActivity,
  onOpenCreateActivity,
  onOpenShowActivity,
  endStatus,
  showFilters,
  filters,
  hasFilters,
}: IKanbanVision) => {
  const dispatch = useDispatch(); 
  const theme = useSelector(getTheme);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeColumn, setActiveColumn] = useState<ITopicStatusDnDFormat | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const wrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
  };

  useEffect(() => {
    if (endStatus) {
      dispatch(ActivitiesActions.changeActivityStatus(endStatus));
    }
  }, [endStatus]);

  const handleNewData = useCallback((newData: IUpdateListRequest) => {
    if (newData.provider === 'end') {
      dispatch(ActivitiesActions.listActivitiesUpdate(newData));
    } else {
      setTimeout(() => {
        dispatch(ActivitiesActions.listActivitiesUpdate(newData));
      }, 50);
    }
  }, [dispatch]); 

  const findContainer = useCallback((id: UniqueIdentifier) => {
    if (!id) return;
    if (!listActivities) return;

    if (listActivities.columns.hasOwnProperty(id)) {
      return id;
    }
  
    const column = listActivities.columnOrder.find(columnId => {
      return listActivities.columns[columnId].taskIds.includes(id.toString());
    })
    return column;
  }, [listActivities]);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const {active} = event;
    const {id} = active;

    if (listActivities?.columns && listActivities?.activities && id) {
      const activeColumnStatus = listActivities.activities[id].Status?.TopicStatusId;
      const getActiveColumn = listActivities.columns[`column-${activeColumnStatus}` || '']

      const activeColumnTaskIds = Array.from(getActiveColumn.taskIds);
      const activitieIndex = activeColumnTaskIds.indexOf(id.toString());

      setActiveColumn(getActiveColumn);

      const initialStatus: IInitialListUpdateRequest = {
        currentId: id,
        initialIndex: activitieIndex, 
        initialContainer: activeColumnStatus,
      };
      dispatch(ActivitiesActions.initialChangeActivityStatus(initialStatus));
    }

    return setActiveId(id);
  }, [listActivities]);

  const handleDragOver = useCallback((event: DragOverEvent) => {
    if (!listActivities) return;

    const {active, over, delta} = event;    
    
    if (!over) {
      return;
    }

    const {id} = active;
    const {id: overId} = over;
    
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    const data: IUpdateListRequest = {
      provider: 'over',
      active,
      activeContainer,
      over,
      overContainer,
      delta,
    };

    return handleNewData(data);
  }, [handleNewData, listActivities, findContainer]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    if (!listActivities) return;

    const {active, over} = event;

    if (!over) {
      return;
    }

    const {id} = active;
    const {id: overId} = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const data: IUpdateListRequest = {
      provider: 'end',
      active,
      activeContainer,
      over,
      overContainer,
    };

    handleNewData(data);
    return setActiveId(null);
  }, [handleNewData, listActivities, findContainer]);

  const columnObj = useCallback(() => {
    const lookupColumn = {
      columns: {},
      totalFilteredAllItems: 0,
    } as IColumnCreateObj;

    listActivities?.columnOrder?.map(columnId => {
      const column = listActivities.columns[columnId];
      const activities = column.taskIds?.map(taskId => listActivities.activities[taskId]);
      const clearActivities = activities.filter(activity => activity);

      const sortedActivities = clearActivities.sort(sortActivities);

      const filteredArray = complexfilterActivities<ITopicDnDResponse, typeof enumToStringValueMap>({
        filters,
        array: sortedActivities,
        enumToStringValueMap,
      });
      
      const filteredActivities = filterActivities(filteredArray, searchActivity);

      lookupColumn.totalFilteredAllItems += filteredActivities.length;
      return lookupColumn.columns[columnId] = {
        column,
        filteredActivities,
      }
    })

    return lookupColumn;
  }, [
    listActivities,
    searchActivity, 
    filters,
  ]);
  
  return (
    <div style={wrapperStyle}>
      <DndContext
        key="DnDContextKey"
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={(event) => handleDragStart(event)}
        onDragOver={(event) => handleDragOver(event)}
        onDragEnd={(event) => handleDragEnd(event)}
      >
        {listActivities?.columnOrder?.map(columnId => {
            const currentColumn = columnObj().columns[columnId];
            const totalFilteredAllItems = columnObj().totalFilteredAllItems;

            return (
              <Column 
                key={currentColumn.column.TopicStatusId} 
                height={height}
                id={`column-${currentColumn.column.TopicStatusId}`} 
                activeId={activeId}
                items={currentColumn.filteredActivities.map(act => act.Guid)}
                column={currentColumn.column}
                title={currentColumn.column.Name} 
                activitiesList={currentColumn.filteredActivities} 
                totalItems={currentColumn.filteredActivities.length}
                totalAllItems={Object.keys(listActivities.activities).length}
                totalFilteredAllItems={totalFilteredAllItems}
                onOpenCreateActivity={onOpenCreateActivity}
                onOpenShowActivity={onOpenShowActivity}
                searchActivity={searchActivity}
                showFilters={showFilters}
                hasFilters={hasFilters}
              />
            )
          })}
        <DragOverlay>
          {activeId ? (
            <Item 
              activeId={activeId}
              activity={listActivities?.activities[activeId] || {} as ITopicDnDResponse}
              column={activeColumn || {} as ITopicStatusDnDFormat}
              searchActivity={searchActivity}
              theme={theme}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

export default KanbanVision;
