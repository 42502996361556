import React, { Dispatch, ReactNode, SetStateAction } from "react";

import { ColumnProps } from "antd/lib/table";
import ColumnHeader from "Components/UI/Table/ColumnHeader";
import FilterModal, { ITipoOption } from "Pages/ListagemArquivos/Modals/FilterModal";
import { ITenantAdm } from "Data/interfaces/Tenant/ITenantAdm";
import { numberToMoney } from "Utils/Money";
import { IStorePlanResponse } from "Data/interfaces/Store/IStorePlanResponse";
import { billingDate } from "Utils/DateUtils";

export interface IControleAdmTenantsInput {
  tenantsList?: ITenantAdm[];
  plansList?: IStorePlanResponse[];
  sortTenantsList: (sorterDirection: boolean, key: string, key2?: string) => void;
  showFilterCompanyTenants?: boolean;
  setShowFilterCompanyTenants: Dispatch<SetStateAction<boolean>>;
  showFilterEmailTenants?: boolean;
  setShowFilterEmailTenants: Dispatch<SetStateAction<boolean>>;
  showFilterNameTenants?: boolean;
  setShowFilterNameTenants: Dispatch<SetStateAction<boolean>>;
}

export const getColumnsTableTenants: (input: IControleAdmTenantsInput) => 
Array<ColumnProps<ITenantAdm>> = ({
  tenantsList,
  plansList,
  sortTenantsList,
  showFilterCompanyTenants,
  setShowFilterCompanyTenants,
  showFilterEmailTenants,
  setShowFilterEmailTenants,
  showFilterNameTenants,
  setShowFilterNameTenants
}) => ([
  {
    title: (
      <ColumnHeader 
        title="Empresa" 
        showFilter={() => 
          setShowFilterCompanyTenants(true)}
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'Tenant', 'CompanyName')}
      />
    ),
    dataIndex: '[Tenant.CompanyName]',
    onFilter: (value: any, record: any) => {
      if(!record.Tenant.CompanyName) {
        return record;
      }
      return record.Tenant.CompanyName?.toLowerCase() === value?.toLowerCase();
    },
    onFilterDropdownVisibleChange: (visible) => 
      setShowFilterCompanyTenants(visible),
    filterDropdownVisible: showFilterCompanyTenants,
    filterDropdown: (propsFilters: any) => {
      const options = getFilterOptions(tenantsList, 'Tenant', 'CompanyName');
      return <FilterModal
          propsFilter={propsFilters}
          showFilter={(visible: boolean) => 
            setShowFilterCompanyTenants(visible)}
          tipoOptions={options}
        />
    },
    render: (text: string, record) => record.Tenant.CompanyName,
  },
  {
    title: (
      <ColumnHeader 
        title="Email Adm" 
        showFilter={() => 
          setShowFilterEmailTenants(true)}
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserAdminEmail')}
      />
    ),
    dataIndex: 'UserAdminEmail',
    onFilter: (value: any, record: any) => {
      if(!record.UserAdminEmail) {
        return record;
      }
      return record.UserAdminEmail?.toLowerCase() === value?.toLowerCase();
    },
    onFilterDropdownVisibleChange: (visible) => 
    setShowFilterEmailTenants(visible),
    filterDropdownVisible: showFilterEmailTenants,
    filterDropdown: (propsFilters: any) => {
      const options = getFilterOptions(tenantsList, 'UserAdminEmail');
      return <FilterModal
          propsFilter={propsFilters}
          showFilter={(visible: boolean) => 
            setShowFilterEmailTenants(visible)}
          tipoOptions={options}
        />
    },
    render: (text: string, record) => record.UserAdminEmail,
  },
  {
    title: (
      <ColumnHeader 
        title="Nome Adm" 
        showFilter={() => 
          setShowFilterNameTenants(true)}
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserAdminName')}
      />
    ),
    dataIndex: 'UserAdminName',
    onFilter: (value: any, record: any) => {
      if(!record.UserAdminName) {
        return record;
      }
      return record.UserAdminName?.toLowerCase() === value?.toLowerCase();
    },
    onFilterDropdownVisibleChange: (visible) => 
    setShowFilterNameTenants(visible),
    filterDropdownVisible: showFilterNameTenants,
    filterDropdown: (propsFilters: any) => {
      const options = getFilterOptions(tenantsList, 'UserAdminName');
      return <FilterModal
          propsFilter={propsFilters}
          showFilter={(visible: boolean) => 
            setShowFilterNameTenants(visible)}
          tipoOptions={options}
        />
    },
    render: (text, record) => record.UserAdminName,
  },
  {
    title: (
      <ColumnHeader 
        title="Status"
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserStoreItem', 'isActive')}
      />
    ),
    dataIndex: '[UserStoreItem.isActive]',
    render: (text, record) => {
      return (
        <span>{text === 1 ? 'Ativo' : 'Inativo'}</span>
      )
    },
  },
  {
    title: (
      <ColumnHeader title="Plano" showOrder={false} />
    ),
    dataIndex: '[UserStoreItem.StorePlan.Name]',
    render: (text, record) => {
      return (
        <span>{record.UserStoreItem.StorePlan ? record.UserStoreItem.StorePlan.Name : 'Pro (Legado)'}</span>
      )
    }
  },
  {
    title: (
      <ColumnHeader title="Preço Atual" showOrder={false} />
    ),
    dataIndex: '[UserStoreItem.Price]',
    render: (text, record) => {
      const plano = plansList?.find(plan => record.UserStoreItem.StorePlan?.Name === plan.Name);
      if (record.UserStoreItem.Price) {
        return (
          <span>{numberToMoney(record.UserStoreItem.Price, 'real')}</span>
        )
      }
      return (
        <span>{record.UserStoreItem.StorePlan?.Name !== 'Enterprise'
        ? (record.UserStoreItem.StorePlan?.Name === 'Start' 
          ? 'Grátis' 
          : (typeof plano?.Price === 'number' && numberToMoney(Number(plano?.Price), 'real')))
        : (typeof text === 'number' 
          ? numberToMoney(text, 'real')
          : 'A negociar')
        }</span>
      )
    },
  },
  {
    title: (
      <ColumnHeader 
        title="Final Contrato" 
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserStoreItem', 'ContractEndDate')}
      />
    ),
    dataIndex: '[UserStoreItem.ContractEndDate]',
    render: (text, record) => {
      const date = new Date(text).toLocaleDateString();
      return (
        <span style={{marginLeft: '10px'}} >{date}</span>
      )
    },
  },
  {
    title: (
      <ColumnHeader 
        title="Up/Down Plano"
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserStoreItem', 'UpdateStorePlan')} 
      />
    ),
    dataIndex: '[UserStoreItem.UpdateStorePlan]',
    render: (text: IStorePlanResponse, record) => {
      return (
        <>
          {(text && record.UserStoreItem.StorePlan?.Order)
            ? (text.Order > record.UserStoreItem.StorePlan?.Order 
              ? (<>
                  <span>{text.Name}</span>
                  <span style={{ color: 'green' }}>{' UPGRADE'}</span>
                </>)
              : (<>
                  <span>{text.Name}</span>
                  <span style={{ color: 'red' }}>{' DOWNGRADE'}</span>
                </>)
          ) : (
            <span>-----</span>
          )}
        </>
      )
    }
  },
  {
    title: (
      <ColumnHeader 
        title="Data faturamento" 
        sorter={(sorterDirection) => sortTenantsList(sorterDirection, 'UserStoreItem', 'BillingDay')} 
      />
    ),
    dataIndex: '[UserStoreItem.BillingDay]',
    render: (text, record) => {
      return (
        <span>{text ? billingDate(text) : '---'}</span>
      )
    }
  },
]);

const getFilterOptions = (
  fileList: any[] | undefined,
  key: string,
  key2?: string,
  render?: (value: any) => string,
  validation?: (index: any) => boolean,
  renderNode?: (value: any) => ReactNode
) => {
  const types: any = {};
  const filterList: ITipoOption[] = [];
  if (key2) {
    fileList?.forEach((file) => {
      if (file[key][key2] === undefined || file[key][key2] === null) {
        return;
      }

      const updateIndex = render ? render(file[key][key2]) : file[key][key2];
      if (
        types[updateIndex] === undefined &&
        (!validation || (validation && validation(updateIndex)))
      ) {
        types[updateIndex] = true;
        filterList.push({
          description: updateIndex.toString(),
          renderNode: renderNode && renderNode(updateIndex),
          value: updateIndex.toString()
        });
      }
    });
  } else {
    fileList?.forEach((file) => {
      if (file[key] === undefined || file[key] === null) {
        return;
      }

      const updateIndex = render ? render(file[key]) : file[key];
      if (
        types[updateIndex] === undefined &&
        (!validation || (validation && validation(updateIndex)))
      ) {
        types[updateIndex] = true;
        filterList.push({
          description: updateIndex.toString(),
          renderNode: renderNode && renderNode(updateIndex),
          value: updateIndex.toString()
        });
      }
    });
  }
  return filterList;
};