import React, { FC, useEffect } from "react";
import * as Yup from 'yup';
import { connect, ConnectedProps, useDispatch } from "react-redux";
import styles from "./TenantBillingData.module.scss";
import Modal from "Components/UI/Modal/Modal";
import { AdmActions } from "Store/Adm/Adm.actions";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { Skeleton } from "antd";
import { IBillingDataResponse } from "Data/interfaces/Auth/IBillingData";
import Form from "Components/UI/Form/Form";
import Field from "Components/UI/Field";
import { stringToCEP, stringToCNPJ, stringToPhone } from "Utils/MaskUtils";
import Button from "Components/UI/Button";

interface ITTenantBillingData {
  UserAdminId: string;
  tenantName: string;
  onClose: () => void;
  closeEditTenantUpdateModal: () => void;
  visible?: boolean;
}

const TenantBillingData: FC<Props> = ({
  UserAdminId,
  tenantName,
  onClose,
  closeEditTenantUpdateModal,
  visible, 
  tenantIsLoading,
  BillingData 
}) => {
  const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = React.useState({} as IBillingDataResponse);
  
  useEffect(() => {
    if(UserAdminId){
      dispatch(AdmActions.getBillingData(UserAdminId))
    }
  }, [UserAdminId]);
  
  useEffect(() => {
    if(BillingData){
      setDefaultValues({
        ...defaultValues,
        ...BillingData
      })
    }
  }, [BillingData]);

  const onCloseAux = () => {
    closeEditTenantUpdateModal();
    onClose();
  }

  const schema = Yup.object().shape({});

  const buttons = (
    <Button
      onClick={onCloseAux}
    >
      <span>Sair</span>
    </Button>
  );

  return (
    <Modal
      title="Dados de Faturamento"
      width={800}
      visible={visible}
      onCancel={onCloseAux}
      footerButtons={buttons}
    >
      <div 
        className={styles['box-wrapper']} 
        key={`status-${tenantName}`}
      >
        {tenantIsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div>
              <h4>{tenantName}</h4>
            </div>

            <div className={styles['form-wrapper']}>
              <Form onSubmit={() => false} schema={schema} initialValues={defaultValues}> 
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="CNPJ"
                      name="CNPJ"
                      disabled
                      parse={(value: any) => stringToCNPJ(value)}
                    />
                  </div>
                  <div>
                    <Field
                      label="Nome da Empresa"
                      name="CompanyName"
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="Telefone"
                      name="Phone"
                      disabled
                      parse={(value: any) => stringToPhone(value)}
                    />
                  </div>
                  <div>
                    <Field
                      label="Responsável"
                      name="OwnerName"
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="E-mail"
                      name="Email"
                      type="email"
                      disabled
                    />
                  </div>
                  <div>
                    <Field
                      label="Site"
                      name="Site"
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="Logradouro"
                      name="StreetAddress"
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="Número"
                      name="Number"
                      disabled
                    />
                  </div>
                  <div>
                    <Field
                      label="Complemento"
                      name="Complement"
                      disabled
                    />
                  </div>
                  <div>
                    <Field
                      label="Bairro"
                      name="District"
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['field-wrapper']}>
                  <div>
                    <Field
                      label="Cidade"
                      name="City"
                      disabled
                    />
                  </div>
                  <div>
                    <Field
                      label="Estado"
                      name="State"
                      disabled
                    />
                  </div>
                  <div>
                    <Field
                      label="CEP"
                      name="Zip"
                      disabled
                      parse={(value: any) => stringToCEP(value)}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  tenantIsLoading: state.adm.tenantIsLoading,
  BillingData: state.adm.BillingData
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & ITTenantBillingData;

export default connector(TenantBillingData);