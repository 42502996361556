import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './AvisoRemoverUsuario.module.scss';
import Button from '../../../../Components/UI/Button';
import Modal from '../../../../Components/UI/Modal/Modal';
import { TenantActions } from '../../../../Store/Tenant/Tenant.actions';
interface IAvisoRemoverUsuario {
  visible?: boolean;
  userToDelete?: any;
  onClose: () => void;
  onConfirm?: () => void;
  isLoading: boolean;
}


const AvisoRemoverUsuario: FC<IAvisoRemoverUsuario> = ({
  visible,
  userToDelete,
  onClose,
  onConfirm,
  isLoading
}) => {
  const [isSendingData, setIsSendingData] = useState<boolean>(false);
  const dispatch = useDispatch();

  const removerConviteUsuario = (InvitedUserId: number) => {
   
    if (InvitedUserId) {
      dispatch(TenantActions.removeTenantInviteUser(InvitedUserId));
      setIsSendingData(true);
    }
  };

  const removerTenantUser = (userId: string) => {
    if (userId) {
      dispatch(TenantActions.removeTenantUser(userId));
      setIsSendingData(true);
    }
  };

  if (!isLoading && isSendingData) {
    onClose();
    setIsSendingData(false);
  }

  const buttons = (
    <div>
      {userToDelete?.InvitedUserId ? (
        <Button loading={isLoading} type={'link'} className={styles['link']} onClick={() => { removerConviteUsuario(userToDelete?.InvitedUserId); }}>
          Sim, cancelar convite
        </Button>
      ) : (
          <Button loading={isLoading} className={styles['link']} onClick={() => { removerTenantUser(userToDelete?.Id); }}>
            Sim, remover usuário
          </Button>
        )}
      <Button className={styles['button']} type="primary" height={37} onClick={onClose}>
        Cancelar
      </Button>
    </div>
  );
  return (
    <Modal
      title="Atenção!"
      footerButtons={buttons}
      visible={visible}
      width={500}
      onCancel={onClose}
    >
      <div className={styles['descriptionWrapper']}>
        {userToDelete?.InvitedUserId ? (
          <>
            <div>O usuário "{userToDelete?.Nome}" irá ter seu convite removido.</div>
            <div>Deseja mesmo cancelar o convite?</div>
          </>
        ) : (
            <>
              <div>O usuário "{userToDelete?.Nome}" será removido da construtora.</div>
              <div>Deseja mesmo remove-lo?</div>
            </>
          )}
      </div>
    </Modal>
  );
};

export default AvisoRemoverUsuario;
