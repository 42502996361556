import styled, { css } from "styled-components";

interface IWrapperStyled {
  isCollapsed?: string;
  isChildren?: string;
  active?: string;
  isselected?: string;
  isopen?: string;
}

export const WrapperStyled = styled.div<IWrapperStyled>`

  ${({isCollapsed}) => isCollapsed === 'true' && css`
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    transition: all 0.3s ease-out;
    border: none;
    z-index: 1;
    overflow-y: visible;
  `}

  .obrasMenuItem {
    display: flex;
    justify-content: left;
    min-height: 45px;
    height: 45px;
    align-items: center;
    background-color: ${({theme}) => theme.colors.surface.surface};
    border-left: 4px solid transparent !important;
    user-select: none;
    cursor: pointer;
 
    .obrasIcon {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      padding-left: 25px;
      padding-right: 25px;
    }

    .avatar {
      padding-left: 12px;
      padding-right: 12px;
    }

    p {
      margin-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .obrasIconToggleWrap {
      padding: 0 10px;

      .obrasIconChildren {
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }

    .titleMenu {
      flex: 1;
      margin: 0 !important;
      height: 18px;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
      border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;

      .obrasIcon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }
    }

    ${({isCollapsed}) => isCollapsed === 'true' && css`
      height: 45px;
      display: block;

      p {
        visibility: hidden;
      }

      .children {
        visibility: hidden;
      }

      .obrasIcon {
        text-align: center;
        padding-top: 25px;
        padding-left: 22px;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-height: 820px) {
        .obrasIcon {
          padding-top: 25px;
        }
      }
    `}

    ${({isselected}) => isselected === 'true' && css`
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
    `}

    ${({active}) => active === 'true' && css`
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
      border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;

      .obrasIcon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }

      > p {
        font-weight: bold;
      }
    `}

    ${({isopen}) => isopen === 'true' && css`
      .obrasIconToggleWrap {

        .obrasIconChildren {
          transform: rotate(90deg);
        }
      }
    `}

    ${({isChildren}) => isChildren === 'true' && css`
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer} !important;

      &:hover {
        background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
        border-left: 4px solid transparent !important;
      }
    `}

    ${({isChildren, active}) => isChildren === 'true' && active === 'true' && css`
      &:hover {
        border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;
      }
    `}
  }

  .obrasChildrenWrapper {
    max-height: 290px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 113px !important;
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }
  }
`;