import styled, { css } from "styled-components"

export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 4px;

  .buttonCopy {
    display: flex;
    align-items: center;

    .iconLink{
      color: ${({theme}) => theme.colors.primary.primary} !important;
    }
  }

  .buttonClose {
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;

  .loadingText {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({theme}) => theme.colors.secondary.onSecondaryVariant};
  }
`;

export const Wrapper = styled.div`
  padding: 24px 24px 24px 24px;
  background: ${({theme}) => theme.colors.surface.surface};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;

  .midleHr {
    margin-top: 12px;
    margin-bottom: 18px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    width: 100%;
  }

  .description {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }

  .subDescription {
    margin-bottom: 10px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  .iconClose {
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    cursor: pointer;
  }
`;

export const LinkCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: ${({theme}) => theme.colors.secondary.secondary};
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 6px;

  .shareLink {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; 
    color: ${({theme}) => theme.colors.secondary.onSecondary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customButtonCopy {
    margin-left: 6px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    cursor: pointer;

    :hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
    }

    :active {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
    }

    .iconCopy {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }
`;

interface ISwitchCard {
  checked?: string;
}

export const SwitchCard = styled.div<ISwitchCard>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  margin-top: 10px;

  .antdSwitch {
    
    ${({checked}) => checked === 'true' && css`
      background-color: ${({theme}) => theme.colors.primary.primary} !important;
    `}
  }

  .switchText {
    margin-left: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; 
    color: ${({theme}) => theme.colors.secondary.onSecondary};
  }
`;

export const InfoCard = styled.div<ISwitchCard>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  margin-top: 10px;

  .leftCard {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background-color: ${({theme}) => theme.colors.secondary.secondary};

    .infoIcon {
      color: ${({theme}) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }

  .rightCard {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    
    .headerText {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px; 
      color: ${({theme}) => theme.colors.surface.onSurface};
    }
    .subText {
      margin-top: 2px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px; 
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  }

`;

export const FooterHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 0 22px;
`;
