import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { actionImgPrint } from "Utils/TextUtils";
import { Icon } from "Components/UI";
import { Container, Footer, ImageStyled, Button, Top } from "./styles";

interface IImage {
  src: string;
  onDownload: () => void;
}
const Image: FC<IImage> = ({
  src,
  onDownload,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [scale, setScale] = useState(0.8);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleZoom = (zoom: number) => {
    setScale(prev => {
      const newScale = prev + zoom;
      if (newScale >= 0.2) {
        return newScale;
      }
      return prev;
    });
  };

  const handleMouseMove = (e: any): void => {
    e.preventDefault();
    if (!dragging || !imageRef.current) return;

    const x = e.clientX - dragOffset.x;
    const y = e.clientY - dragOffset.y;
    imageRef.current.style.left = `${x}px`;
    imageRef.current.style.top = `${y}px`;
  };

  const handleMouseDown = (e: MouseEvent<HTMLImageElement>): void => {
    e.preventDefault();
    if (!imageRef.current) return;

    setDragging(true);
    setDragOffset({
      x: e.clientX - imageRef.current.offsetLeft,
      y: e.clientY - imageRef.current.offsetTop,
    });

    document.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseUp = (): void => {
    setDragging(false);

    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseLeave = (): void => {
    setDragging(false);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    }
  }, []);

  return (
    <Container onMouseLeave={handleMouseLeave}>
      <Top>
        <Button onClick={onDownload}>
          <Icon
            icon="download"
            customSize={14}
            className="iconColor"
          />
        </Button>
        <Button onClick={() => actionImgPrint(src)}>
          <Icon
            icon="solicitarPlotagem"
            customSize={14}
            className="iconColor"
          />
        </Button>
      </Top>

      <ImageStyled
        ref={imageRef}
        src={src}
        scale={scale}
        isdragging={`${dragging}`}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      />

      <Footer>
        <Button onClick={() => handleZoom(-0.2)}>
          <span className="less">-</span>
        </Button>
        <Button nohover="true">
          <Icon
            icon="buscar"
            customSize={14}
            className="iconColor"
          />
        </Button>
        <Button onClick={() => handleZoom(0.2)}>
          <span className="more">+</span>
        </Button>
      </Footer>
    </Container>
  );
}

export default Image;
