import styled, { css } from "styled-components";

interface ILabelInput {
  hasicon?: boolean;
  isdisabled?: boolean;
  invalid?: boolean;
  height?: string;
}

export const LabelInput = styled.label<ILabelInput>`
  position: relative;
  display: block;

  .label {
    position: absolute;
    background-color: white;
    font-size: 10px;
    top: -7px;
    left: 9px;        
    padding: 0 9px;
    color: #BDBDBD;
    pointer-events: none;
  }

  .input {
    height: 35px;
    width: 100%;
    border-radius: 2px;
    padding-left: 10px;
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
    outline-color: ${({theme}) => `${theme.colors.primary.primary} !important`};
    border-radius: 5px;
    border: 2px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    ${({theme}) => theme.font ? `${theme.colors.primary.primary} !important` : undefined}

    ${({height}) => !!height && css`
      height: ${height};
    `}
    
    ${({ invalid }) => invalid && css`
      border: 2px solid ${({theme}) => theme.colors.danger.danger};
    `}

    ${({ hasicon }) => hasicon && css`
      padding-left: 42px !important;
    `}

    &:focus {
      outline-color: ${({theme}) => theme.colors.primary.primary};

      &::placeholder {
        color: transparent;
      }

      &+.label {
        font-size: 10px;
        top: -7px;
        left: 9px;
        display: block;
        transition: ease .2s;
      }
    }

    &:not(:focus):placeholder-shown {
      &+.label {
        color: transparent;
        background: transparent;
        font-size: 14px;
        top: 0px;
        left: 15px;
      }
    }

    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      color: #BDBDBD;
    }
  }

  .fieldIcon {
    position: absolute;
    left: 12px;
    top: 5px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .errorInput {
    font-size: 10px;
    padding-left: 10px;
    font-weight: 600;
    height: 18px;
    color: ${({theme}) => theme.colors.danger.danger};
  }
  
  ${({ isdisabled }) => isdisabled && css`
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  `}
`;
