import { IconName } from "Components/UI";

export interface IConstructionSiteStageLibraryItem {
  ConstructionSiteStageLibraryItemId: number;
  Name: string;
  Acronym: string;
  Order: number;
  ConstructionSiteStageLibraryFk: number;
  IsDefault: boolean;
}

export interface IConstructionSiteStageLibrary {
  ConstructionSiteStageLibraryId: number;
  Name: string;
  Description?: string;
  IconName?: IconName;
  Type: ConstructionSiteStageLibraryEnum;
  Items: IConstructionSiteStageLibraryItem[];
}

export interface IConstructionSiteStageLibraryRequest {
  constructionSiteId: number;
  constructionSiteStageLibraryId: number;
  constructionSiteStageLibraryItems: number[];
  type: ImportedLibraryTypeEnum;
}

export enum ImportedLibraryTypeEnum {
  Predefined,
  ConstructionSite,
}

export enum ConstructionSiteStageLibraryEnum {
  Standard,
  MarketStandard,
  ConstructionSite,
}

export interface IStageLibraryItem {
  name: string;
  acronym: string;
  isDefault: boolean;
}

export interface ICreateStageLibraryRequest {
  name: string;
  description: string;
  type: number;
  iconName: IconName;
  items: IStageLibraryItem[];
}

export interface IUpdateStageLibraryRequest {
  stageLibraryId: number;
  stageLibrary: ICreateStageLibraryRequest;
}
