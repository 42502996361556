import styled, { css } from "styled-components";

interface ICadastroWrapper {
  img: string;
}

export const CadastroWrapper = styled.div<ICadastroWrapper>`
  display: flex;
  padding: 27px 32px 32px 40px;

  .changeImageWrapper {
    width: 245px;
    height: 182px;
    background: ${({theme}) => theme.colors.primary.primary};
    ${({img, theme}) => img && css`
      background-image: 
        linear-gradient(${theme.colors.primary.primary}99, ${theme.colors.primary.primary}99), 
        url('${img}')
      ;
    `}
    opacity: 0.75;
    border-radius: 5px;
    color: ${({theme}) => theme.colors.primary.onPrimary};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin-right: 30px;
    background-size: cover;
    background-repeat: no-repeat;

    .changeImageLabel {      
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: ${({theme}) => theme.colors.primary.onPrimary};
    }
  }

  .formWrapper {
    flex: 1;
  }

  .fieldCadastro {
    margin-bottom: 10px;
    input {
      height: 42px !important;
    }
  }
`;
