import { FC } from "react";
import { 
  ActiveCard,
  Container, 
  ExternalCard, 
  InternalCard, 
  OptionCard
} from "./styles";
import { QRCodePositionEnum } from "Data/interfaces/Plotagem/IPlotRequestRequest";

interface IQRCodePosition {
  QRCodeIdPosition: QRCodePositionEnum;
  onQRCodeIdPosition: (idPosition: QRCodePositionEnum) => void;
}

const QRCodePosition: FC<IQRCodePosition> = ({
  QRCodeIdPosition,
  onQRCodeIdPosition,
}) => {
  const positionOptions = [
    [1, 3, 2],
    [4, 6, 5],
    [7, 9, 8],
  ];

  return (
    <Container>
      <ExternalCard>
        {positionOptions.map((positions, i) => (
          <InternalCard key={`qri-${i}`}>
            {positions.map((idPosition, j) => (
              <OptionCard 
                key={`qrj-${idPosition}`}
                onClick={() => onQRCodeIdPosition(idPosition)}
              >
                <ActiveCard isactive={idPosition === QRCodeIdPosition}>
                  <div className="point" />
                </ActiveCard>
              </OptionCard>
            ))}
          </InternalCard>
        ))}
      </ExternalCard>
    </Container>
  )
}

export default QRCodePosition;
