import { AuthUtils } from './AuthUtils';
import { store } from '../Store';
import { ISharedUser } from 'Data/interfaces/User/ISharedUser';
import { IUserData } from 'Data/interfaces/User/IUserData';
import { TopicVisibilityEnum } from 'Data/interfaces/Activities/ITopicResponse';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';

export const ADMIN = 1;
export const COORDENADOR = 7;
export const PROJETISTA = 4;
export const ENGENHEIRO = 3;
export const CONSULTOR = 5;

enum PermissionsEnum {
  VIEW,
  DOWNLOAD,
  UPLOAD,
  EDIT,
}

export default class AccessPermission {
  //Obras
  static downloadFiles(user?: ISharedUser) {
    const hasDownloadPermission = AccessPermission.isAdmin() || user?.Privileges.includes(PermissionsEnum.DOWNLOAD);
    return hasDownloadPermission;
  }

  static downloadSelectBlockedFiles(user?: ISharedUser) {
    const { listagemArquivos } = store.getState();
    const hasSelectBlockedFiles = listagemArquivos.ListFiles.filter((file) => listagemArquivos.selectedEditRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).some((file) => file.Status === 0);
    const hasBlockedPermission = !(hasSelectBlockedFiles && AccessPermission.isEngOrConsult());
    const hasDownloadPermission = AccessPermission.isAdmin() || (user?.Privileges.includes(PermissionsEnum.DOWNLOAD) && hasBlockedPermission);
    return hasDownloadPermission;
  }

  static downloadViewerButtonBlockedFiles(fileStatus?: number) {
    const hasBlockedFile = fileStatus === 0;
    const hasBlockedPermission = !(hasBlockedFile && AccessPermission.isEngOrConsult());
    const hasDownloadPermission = AccessPermission.isAdmin() || hasBlockedPermission;
    return hasDownloadPermission;
  }

  static uploadFiles(user?: ISharedUser) {
    const hasUploadPermission = AccessPermission.isAdmin() || user?.Privileges.includes(PermissionsEnum.UPLOAD);
    return hasUploadPermission;
  }

  static deleteFiles(user?: ISharedUser) {
    const hasDeletePermission = AccessPermission.isAdmin() || user?.Privileges.includes(PermissionsEnum.EDIT);
    return hasDeletePermission;
  }

  static changeStatusFiles(user?: ISharedUser) {
    const hasPermission = AccessPermission.isAdmin() || (AccessPermission.isCoordenador() && user?.Privileges.includes(PermissionsEnum.EDIT));
    return hasPermission;
  }

  static deleteDiscipline() {
    const hasPermission = AccessPermission.isAdmin() || AccessPermission.isCoordenador();
    return hasPermission;
  }

  static accessObsoleteFolder() {
    const hasPermission = AccessPermission.isAdmin() || AccessPermission.isCoordenador();
    return hasPermission;
  }

  static editFiles(user?: ISharedUser) {
    const hasEditPermission = AccessPermission.isAdmin() || user?.Privileges.includes(PermissionsEnum.EDIT);
    return hasEditPermission && AccessPermission.hasNoFolderSelected();
  }

  static editSelectedFiles(user?: ISharedUser) {
    return AccessPermission.hasNoFolderSelected() && (AccessPermission.isAdmin() || (AccessPermission.hasOnlySelectedFilesCreatedByMe() && user?.Privileges.includes(PermissionsEnum.EDIT)));
  }

  static requestPlotting(user?: ISharedUser) {
    return (AccessPermission.isAdmin() || user?.HasPlottingPermission) && AccessPermission.hasNoFolderSelected();
  }

  static hasNoFolderSelected() {
    const { listagemArquivos } = store.getState();
    return !listagemArquivos.ListFiles.filter((file) => listagemArquivos.selectedEditRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).some((file) => file.IsFolder);
  }

  static hasOnlySelectedFilesCreatedByMe() {
    const { nome } = AuthUtils.getLoggedUser();
    const { listagemArquivos } = store.getState();
    return !listagemArquivos.ListFiles.filter((file) => listagemArquivos.selectedEditRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).every((file) => file.ModifiedBy === nome);
  }

  static newOrEditDiscipline(user?: ISharedUser) {
    return AccessPermission.isAdmin() || AccessPermission.isCoordenador() || user?.Privileges.includes(PermissionsEnum.EDIT);
  }

  static isAdmin() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === ADMIN;
    }
    
    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === ADMIN;
  }

  static isCoordenador() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === COORDENADOR;
    }

    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === COORDENADOR;
  }

  static isProjetista() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === PROJETISTA;
    }

    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === PROJETISTA;
  }

  static isEngOrConsult() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === ENGENHEIRO || userInfo.CurrentRoleFk === CONSULTOR;
    }

    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === ENGENHEIRO || currentTenantInfo?.RoleFk === CONSULTOR;
  }

  static isEngenheiro() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === ENGENHEIRO;
    }

    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === ENGENHEIRO;
  }

  static isConsultor() {
    const { userInfo } = store.getState().auth;
    const { currentTenant } = AuthUtils.getLoggedUser();
    const { tenant } = store.getState();
    
    if (userInfo) {
      return userInfo.CurrentRoleFk === CONSULTOR;
    }

    const currentTenantInfo = tenant.TenantUsers?.find((tenantUser) => tenantUser.TenantFk === currentTenant);
    return currentTenantInfo?.RoleFk === CONSULTOR;
  }

  static visualizationActivitiePermission(
    userInfo?: IUserInforResponse,
    CreationAuthorFk?: IUserData | null, 
    TopicAssignedUsersFk?: IUserData[], 
    Visibility?: TopicVisibilityEnum
  ) {
    if (Visibility === TopicVisibilityEnum.Public) {
      return true;
    }

    if (AccessPermission.isAdmin()) {
      return true;
    }

    if (Visibility === TopicVisibilityEnum.Private) {
      if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
        return true;
      }
    }

    if (Visibility === TopicVisibilityEnum.Draft) {
      if (userInfo?.Id === CreationAuthorFk?.Id) {
        return true;
      }
    }

    return false;
  }

  static createOrEditActivitiePermission(
    userInfo?: IUserInforResponse,
    isUpdate?: boolean, 
    CreationAuthorFk?: IUserData | null, 
    TopicAssignedUsersFk?: IUserData[], 
    Visibility?: TopicVisibilityEnum,
    customPermission?: boolean,
  ) {
    if (customPermission) {
      if (AccessPermission.isProjetista()) {
        if (Visibility !== TopicVisibilityEnum.Draft) {
          if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
            return true;
          }
        }
      }
    }
    
    if (isUpdate) {
      if (AccessPermission.isConsultor()) {
        return false;
      }

      if (AccessPermission.isAdmin() && Visibility !== TopicVisibilityEnum.Draft) {
        return true;
      }

      if (Visibility === TopicVisibilityEnum.Draft) {
        if (userInfo?.Id === CreationAuthorFk?.Id) {
          return true;
        }
      }

      if (AccessPermission.isCoordenador()) {
        if (Visibility === TopicVisibilityEnum.Public) {
          return true;
        }

        const userInfo = store.getState().auth.userInfo;
        if (Visibility === TopicVisibilityEnum.Private) {
          if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
            return true;
          }
        }
      }

      return false;
    }

    if (!AccessPermission.isConsultor()) {
      return true;
    }

    return false;
  }
  
  static deleteActivitiePermission(
    userInfo?: IUserInforResponse,
    CreationAuthorFk?: IUserData | null, 
    TopicAssignedUsersFk?: IUserData[], 
    Visibility?: TopicVisibilityEnum
  ) {
    if (AccessPermission.isAdmin()) {
      return true;
    }

    if (AccessPermission.isCoordenador()) {
      if (Visibility === TopicVisibilityEnum.Public) {
        return true;
      }

      if (Visibility === TopicVisibilityEnum.Private) {
        if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
          return true;
        }
      }
    }

    return false;
  }

  static statusActivitieEditPermission(
    userInfo?: IUserInforResponse,
    CreationAuthorFk?: IUserData | null, 
    TopicAssignedUsersFk?: IUserData[], 
    Visibility?: TopicVisibilityEnum
  ) {
    if (AccessPermission.isAdmin() && Visibility !== TopicVisibilityEnum.Draft) {
      return true;
    }

    if (AccessPermission.isConsultor()) {
      return false;
    }

    if (Visibility === TopicVisibilityEnum.Draft) {
      if (userInfo?.Id === CreationAuthorFk?.Id) {
        return true;
      }
    }

    if (Visibility === TopicVisibilityEnum.Public) {
      if (AccessPermission.isCoordenador() || userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
        return true;
      }
    }

    if (Visibility === TopicVisibilityEnum.Private) {
      if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
        return true;
      }
    }

    return false;
  }

  static addAttachmentsPermission(
    isUpdate: boolean, 
    userInfo?: IUserInforResponse,
    CreationAuthorFk?: IUserData | null, 
    TopicAssignedUsersFk?: IUserData[], 
    Visibility?: TopicVisibilityEnum,
  ) {
    if (AccessPermission.isConsultor()) {
      return false;
    }

    if (isUpdate) {
      if (Visibility !== TopicVisibilityEnum.Draft) {
        if ((AccessPermission.isAdmin() || AccessPermission.isCoordenador())) {
          return true;
        }
  
        if (userInfo?.Id === CreationAuthorFk?.Id || TopicAssignedUsersFk?.some(user => user.Id === userInfo?.Id)) {
          return true;
        }
      }
  
      if (Visibility === TopicVisibilityEnum.Draft) {
        if (userInfo?.Id === CreationAuthorFk?.Id) {
          return true;
        }
      }

    } else {
      return true;
    }
  }

  static deleteAttachmentsPermission(
    userInfo?: IUserInforResponse,
    CreationUploadFk?: IUserData | null, 
    Visibility?: TopicVisibilityEnum
  ) {
    if (AccessPermission.isConsultor()) {
      return false;
    }

    if (Visibility !== TopicVisibilityEnum.Draft) {
      if ((AccessPermission.isAdmin() || AccessPermission.isCoordenador())) {
        return true;
      }
    }
    
    if (userInfo?.Id === CreationUploadFk?.Id) {
      return true;
    }
    
    return false;
  }

  static createNewOptsActivitiePermission() {
    if (AccessPermission.isAdmin() || AccessPermission.isCoordenador()) {
      return true;
    }
    return false;
  }

}
