import { FC, ReactNode } from 'react';
import { Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import Button from '../Button';

interface IUploadCropImg {
  button?: ReactNode;
  maxCount?: number;
  imagesAccepts?: string;
  imagesNotAccepts?: string[];
  onChange: (file: any) => void; 
}

const UploadImg: FC<IUploadCropImg> = ({
  button,
  maxCount,
  imagesAccepts,
  onChange,
}) => {
  const handleBeforeUpload = (file: RcFile) => {
    if (maxCount === 1) {
      onChange(file);
      
    } else {
      onChange([file]);
    }

    return false;
  };

  return (
    <Upload
      action={undefined}
      beforeUpload={(handleBeforeUpload)}
      multiple={maxCount !== 1 ? true : false}
      maxCount={maxCount}
      itemRender={() => false}
      accept={imagesAccepts}
    >
      {button ? button : (
        <Button type="text">
          Escolher um arquivo
        </Button>
      )}
    </Upload>
  );
};

export default UploadImg;
