import mixpanel from 'mixpanel-browser';
import { AuthUtils } from './AuthUtils';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');

let env_check = process.env.NODE_ENV === 'production';

interface IMixPanelTrackAction {
  name: string;
  props?: any;
  userInfo?: IUserInforResponse;
  currentListTenant?: ITenantData | null;
};

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: ({
    name,
    props,
    userInfo,
    currentListTenant,
  }: IMixPanelTrackAction) => {
    let {
      userId,
      currentTenant,
      plan,
      currentRoleFk,
      companyPositionId,
      nome,
      email,
    } = AuthUtils.getLoggedUser();

    if (userInfo) {
      const {
        Id,
        CurrentTenantContext,
        CurrentRoleFk,
        CompanyPosition,
        UserStoreItem,
        Nome,
        Email,
      } = userInfo;

      userId = Id || userId;
      currentTenant = CurrentTenantContext || currentTenant;
      currentRoleFk = CurrentRoleFk || currentRoleFk;
      companyPositionId = Number(CompanyPosition?.CompanyPositionId) || companyPositionId;
      plan = UserStoreItem.StorePlan?.Name || plan;
      nome = Nome || nome;
      email = Email || email;
    }
    
    const defaultProps = {
      userId,
      userName: nome,
      userEmail: email,
      tenantId: currentTenant,
      tenantName: currentListTenant?.CompanyName || null,
      storePlan: plan,
      userRole: currentRoleFk,
      companyPositionId: companyPositionId,
      ...props
    }

    if (env_check) mixpanel.track(name, defaultProps);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    }
  }
};

export let Mixpanel = actions;
