import { IFileData } from "../Data/interfaces/ListagemArquivos/IListFilesResponse";
import { store } from "../Store";
import { UploadActions } from "../Store/Upload/Upload.actions";
import { Http } from "../Utils/Http";

interface IOptions {
  file: any;
  fileName: string;
  fileSize: number;
  threadsQuantity?: number;
  chunkSize: number;
  cancelToken: any;
  fileIdUpload: string;
  objectQuery: object;
  url: string;
}

interface IProgress {
  sent: number;
  total: number;
  percentage: number;
}

export class UploadChunks {
    threadsQuantity: number;
    chunkSize: number;
    objectQuery: object;
    url: string;
    file: File;
    fileName: string;
    fileSize: number;
    cancelToken: any;
    fileIdUpload: string;
    aborted: boolean;
    uploadedSize: number;
    progressCache: any;
    activeConnections: any;
    chunkTotal: number;
    parts: number[];
    uploadedParts: number[];
    lastResponse: IFileData;
    stopChunks: boolean;
    onProgressFn: (event: IProgress) => void
    onFinishFn: (value?: any) => void
    onErrorFn: (error?: any) => void

  constructor(options: IOptions) {
    this.threadsQuantity = options?.threadsQuantity || 4;
    this.chunkSize = options.chunkSize;
    this.objectQuery = options.objectQuery;
    this.url = options.url;
    this.file = options.file;
    this.fileName = options.fileName;
    this.fileSize = options.fileSize;
    this.cancelToken = options.cancelToken;
    this.fileIdUpload = options.fileIdUpload;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.chunkTotal = 0;
    this.parts = [];
    this.uploadedParts = [];
    this.lastResponse = {} as IFileData;
    this.stopChunks = false;
    this.onProgressFn = () => {};
    this.onFinishFn = () => {};
    this.onErrorFn = () => {};
  }

  // starting the multipart upload request
  async start() {
    this.initialize()
  }

  async initialize() {
    try {
      // initializing the multipart request
      if (!this.file) {
        throw new Error("Upload não será iniciado sem um arquivo.");
      }
      if (!this.fileIdUpload) {
          throw new Error("Upload não será iniciado sem um Id do arquivo.");
      }

      const numberOfparts = Math.ceil(this.fileSize / this.chunkSize);
      this.chunkTotal = numberOfparts;
      this.parts = new Array(numberOfparts).fill((value: any) => {}).map((_, index) => index).reverse();
      
      this.sendNext();  
    } catch (error) {
      await this.complete(error)
    }
  }

  // try send chunks
  async sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    const stoppedId = await store.getState().upload.stopped.uploadFileId;
    if (stoppedId === this.fileIdUpload) {
      this.stopChunks = await store.getState().upload.stopped?.isStopped;
    }

    if (this.stopChunks) {
      return
    }
    
    if (this.aborted) {
      return 
    }
    
    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      return
    }

    const part = this.parts.pop()

    if (typeof part !== 'undefined') {
      const sentSize = part * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.upload(chunk, part, sendChunkStarted)
        .then((value) => {
          this.sendNext()
        })
        .catch((error) => {
          // re-send error chunk
          // this.parts.push(part)
          this.complete(error.errors || error)
        })
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: any, response?: any) {
    if (error) {
      const stoppedId = await store.getState().upload.stopped.uploadFileId;
      if (stoppedId === this.fileIdUpload) {
        this.stopChunks = await store.getState().upload.stopped?.isStopped;
      }

      if (!this.stopChunks) {
        this.onErrorFn(error)
      }
      
      await store.dispatch(UploadActions.stopUploadChunkFile({
        isStopped: true,
        uploadFileId: this.fileIdUpload,
      }));
      this.abort();
      return
    }

    if (response) {
      this.lastResponse = response;
      return this.completeUpload()
    }
  }

  // return 'data' complete response
  completeUpload() {
    if (JSON.stringify(this.lastResponse) !== '{}') {
      return this.onFinishFn(this.lastResponse)
    }  
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: number, event?: any) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded * 0.7
      }

      if (event.type === "complete") {
        this.progressCache[part] = event.loaded
      }

      if (event.type === "loadend") {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      let sent = Math.min(this.uploadedSize + inProgress, this.fileSize)

      const total = this.fileSize

      const percentage = Math.round((sent / total) * 100)
      
      this.onProgressFn({
        sent: sent > 0 ? sent : 0,
        total: total,
        percentage: percentage,
      })
    }
  }

  // uploading a part
  upload(
    chunk: Blob, 
    part: any, 
    sendChunkStarted: () => void
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const api = this.activeConnections[part] = await Http.axiosToDownloadUploadFile();
      
      sendChunkStarted();
      
      const formData = new FormData();
      formData.append('chunk', chunk, this.fileName);

      const currentObjectQuery = {
        ...this.objectQuery,
        fileName: this.fileName,
        chunkIndex: part,
        chunkTotal: this.chunkTotal,
        fileIdUpload: this.fileIdUpload,
      }
      Object.entries(currentObjectQuery).forEach(([key, value]) => {
        formData.append(key, value);
      });

      api.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: any) => {
          this.handleProgress(part, progressEvent)
        },
        cancelToken: this.cancelToken.token,
      }).then(({data}) => {
        if (data.data) {
          this.complete(null, data.data)
        }
        
        this.handleProgress(part, {
          type: 'complete',
          loaded: this.chunkSize,
        })
        
        resolve(data.success)
        delete this.activeConnections[part]
      }).catch((error: any) => {
        reject(error)
        delete this.activeConnections[part]
      });
    })
  }

  onProgress(onProgress: any) {
    this.onProgressFn = onProgress
    return this
  }

  onFinish(onFinish: any) {
    this.onFinishFn = onFinish
    return this
  }

  onError(onError: any) {
    this.onErrorFn = onError
    return this
  }

  // cancel all pending request
  abort() {
    this.cancelToken.cancel()
  }
}
