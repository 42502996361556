import { IDisciplineData } from './IObraData';

export interface IDisciplineResponse {
  ConstructionSiteDisciplinesId: number;
  ConstructionSiteFk: number;
  CustomName: string;
  Discipline: IDisciplineData;
  DisciplineApiFolderId: string;
  DisciplineFk: number;
  FolderStatus: DisciplineFolderStatusEnum;
}

export enum DisciplineFolderStatusEnum {
  Sync = 0,
  NotFound = 1,
}
