import styled from "styled-components";

type ContainerProps = {
  borderColor?: string;
  overlay?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-left: 4px solid ${({ borderColor }) => borderColor} !important;
  opacity: ${({ overlay }) => overlay ? 0.5 : 1};
  display: flex;
  flex-direction: column;
  padding: 8px 8px 12px 16px;
  border: 1px solid #EAEDF0;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 6px;
  cursor: grab;

  &:hover {
    background-color: #F8FAFB;
  }

  .singleImgContainer {
    position: relative;
    width: 100%;
    height: 96px;
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    margin-bottom: 12px;
    overflow: hidden;
    cursor: zoom-in;

    .img {
      width: 260px;
      min-width: 260px;
      max-width: 260px;
      height: 96px;
      min-height: 96px;
      max-height: 96px;
      border-radius: 4px;
      object-fit: contain;
    }
  }

  .multipleImgContainer {
    position: relative;
    width: 100%;
    height: 96px;
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    margin-bottom: 12px;
    overflow: hidden;
    cursor: zoom-in;

    .img {
      width: 50%;
      height: 96px;
      object-fit: contain;
    }

    .imgCount {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000;
      opacity: 0.3;
      pointer-events: none;

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        color: #fff;
      }
    }
  }

  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .cardHeaderLeft {
      display: flex;
      align-items: center;

      .cardTextHeader {
        font-size: 12px;
        line-height: 16.34px;
        font-weight: 400;
        color: #8697A8;
      }
      
      .cardIconHeader {
        margin-left: 12px;
      }
  
      .dot {
        display: flex;
        margin-left: 12px;
      }
    }

    .cardIconAlarm {
      cursor: pointer;
    }
  }

  .cardTitleText {
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
    color: #324150;
    padding-top: 4px;
    word-wrap: break-word;
  }

  .cardAvatars {
    display: flex;
    align-items: center;
    padding-top: 12px;
  }

  .cardDisciplinesWrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;

    .cardDiscipline {
      display: flex;
      align-items: center;
      padding-right: 8px;

      .cardDisciplineText {
        font-size: 12px;
        line-height: 16.34px;
        font-weight: 600;
        color: #324150;
        padding-left: 2px;
      }
    }
  }

  .cardDate {
    display: flex;
    align-items: center;
    padding-top: 12px;

    .cardDateText {
      font-size: 12px;
      line-height: 16.34px;
      font-weight: 600;
      color: #324150;
      padding-left: 2px;
    }
  }

  .cardTags {
    display: flex;
    align-items: center;
    padding-top: 12px;

    .cardTagVisibility {
      display: flex;
      align-items: center;
    }

    .cardTagPriority {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }

    .cardTagText {
      font-size: 12px;
      line-height: 16.34px;
      font-weight: 600;
      color: #324150;
      padding-left: 2px;
    }
  }
`;
