import { FC } from "react";
import { Container } from "./styles";

interface IOptStage {
  left: string;
  right?: string;
  separator?: string;
  isEmpty?: boolean;
}

const OptStage: FC<IOptStage> = ({ left, separator, right, isEmpty }) => {
  return (
    <Container isempty={isEmpty}>
      <span className="leftText">{left}</span>
      {separator && <span className="separatorText">{separator}</span>}
      {right && <span className="rightText">{right}</span>}
    </Container>
  );
}

export default OptStage;
