import { Popover } from "antd";
import styled from "styled-components";

export const CustomPopover = styled(Popover)`
  display: flex;
  align-items: center;
  user-select: none;
  height: 51px;
  cursor: pointer;
`;

export const Modal = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 275px;
  /* height: 280px; */
  z-index: 2;
  background-color: #FFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  .Wrapper {

    .content {

      .iconBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 19.07px;
          color: #324150;
        }

        .closeIcon {
          cursor: pointer;
        }
      }
      
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8697A8;
      }
    }

    .Footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #FFF;

      .link {
        padding: 10px 20px;
        margin-right: 10px;

        a {
          font-family: Open Sans;
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          color: #324150;
          text-decoration: none;
        }
      }

      .buttonAction {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 36px ;
        border-radius: 5px;

        font-size: 12px;
        font-weight: 600;
        line-height: 16.34px;
      }
    }
  }
`;
