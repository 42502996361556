import { FC } from 'react';
import { useDispatch } from 'react-redux';

import styles from './ExcluiArquivo.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import { IPlotagemArquivo } from '../../../../Data/interfaces/Plotagem/IPlotagemArquivo';
import { IArquivoData } from '../../../../Data/interfaces/Plotagem/IArquivoData';
import { PlotagemActions } from '../../../../Store/Plotagem/Plotagem.actions';

interface IModal {
  visible?: boolean;
  onCancel?: () => void;
  isLoading?: boolean;
  listaArquivos: IArquivoData[];
  posicaoSelecionados: never[];
  constructionSiteId: number;
  constructionSiteDisciplineFk: number;
}

const ExcluiArquivos: FC<IModal> = ({
  visible,
  onCancel,
  isLoading,
  listaArquivos,
  posicaoSelecionados,
  constructionSiteId,
  constructionSiteDisciplineFk,
}) => {
  const dispatch = useDispatch();

  const excluiArquivos = () => {
    dispatch(PlotagemActions.setShowModalExcluiArquivosPlotagem(false));

    const files: number[] = [];
    posicaoSelecionados?.forEach((pos) => {
      files.push(listaArquivos[pos].PlottingFilesId);
    });

    const deleteArquivos: IPlotagemArquivo = {
      ConstructionSiteId: constructionSiteId,
      PlottingFilesId: files,
    }

    //necessidade de dois reloads, para limpar os arquivos deletados
    dispatch(PlotagemActions.excluiPlotagemArquivos(deleteArquivos));
  }

  const buttons = (
    <div>
      <Button
        type="primary"
        height={37}
        onClick={() => excluiArquivos()}
      >
        <span style={{ padding: '0 18px' }}>
          Sim, excluir arquivo da solicitação
        </span>
      </Button>
    </div>
  );


  return (
    <Modal
      title='Exclusão de arquivo'
      footerButtons={buttons}
      visible={visible}
      width={646}
      onCancel={onCancel}
    >
      <div className={styles['textWrap']} >
        <div className={styles['margin']} >
          <div className={styles['margin']} >
            Atenção: a exclusão de arquivos da solicitação de plotagem é realizada de forma permanente, não sendo possível desfazer a ação.
          </div>
          <div>Deseja continuar?</div>
        </div>
      </div>


    </Modal>
  );
};

export default ExcluiArquivos;
