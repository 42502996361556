import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IPlotagemResponse, IPlotagemSolicitacao, IPlotagemSolicitacaoResponse } from './interfaces/Plotagem/IPlotagemListResponse';
import { IDisciplineData } from './interfaces/Plotagem/IPlotagemData';
import { IArquivoData } from './interfaces/Plotagem/IArquivoData';
import { IUpdatePlotagemArquivoStatusRequest } from './interfaces/Plotagem/IUpdatePlotagemArquivoStatusRequest';
import { IPlotRequestRequest } from './interfaces/Plotagem/IPlotRequestRequest';
import { IPlotagemArquivo } from './interfaces/Plotagem/IPlotagemArquivo';
import { IReenviaPlotagem } from './interfaces/Plotagem/IReenviaPlotagem';
import { IDownloadRelatorioRequest, IProgressDownloading } from './interfaces/Plotagem/IDownloadRelatorioRequest';
import { store } from '../Store';
import { PlotagemActions } from '../Store/Plotagem/Plotagem.actions';

export class PlotagensApi {
  static async list(): Promise<AxiosResponse<IPlotagemResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/Plotagens/List');
  }

  static async listDisciplinasPlotagem(constructionSiteId: number): Promise<AxiosResponse<IDisciplineData[]>> {
    const axios = await Http.axios();
    return axios.get(`/Plotagens/ListDisciplinesByConstructionSiteId/${constructionSiteId}`);
  }

  static async listPlotagemArquivos(constructionSiteId: number): Promise<AxiosResponse<IArquivoData[]>> {
    const axios = await Http.axios();
    return axios.get(`/Plotagens/GetFilesByConstructionSiteDisciplineId/${constructionSiteId}`);
  }

  static async updatePlotagemArquivosStatus(request: IUpdatePlotagemArquivoStatusRequest):
    Promise<AxiosResponse<IUpdatePlotagemArquivoStatusRequest>> {
    const axios = await Http.axios();
    return axios.put('/Plotagens/UpdatePlottingFilesStatus', request);
  }

  static async plotRequest(request: IPlotRequestRequest): Promise<AxiosResponse<IPlotRequestRequest>> {
    const axios = await Http.axios();
    return axios.post('/Plotagens/RequestPlotting', request);
  }

  static async listPlotagemSolicitacoes(constructionSiteId: number): Promise<AxiosResponse<IPlotagemSolicitacao[]>> {
    const axios = await Http.axios();
    return axios.get(`/Plotagens/GetPlottingByConstructionSite/${constructionSiteId}`);
  }

  static async listPlotagemSolicitacoesDisciplinas(constructionSiteDisciplineId: number): Promise<AxiosResponse<IPlotagemSolicitacaoResponse>> {
    const axios = await Http.axios();
    return axios.get(`/Plotagens/ListPlotsByConstructionSiteDisciplineId/${constructionSiteDisciplineId}`);
  }

  static async excluiArquivosPlotting(request: IPlotagemArquivo): Promise<AxiosResponse<IPlotagemArquivo>> {
    const axios = await Http.axios();
    return axios.delete('/Plotagens/RemovePlottingItems', { data: request });
  }

  static async reenviaPlotagem(request: IReenviaPlotagem): Promise<AxiosResponse<IReenviaPlotagem>> {
    const axios = await Http.axios();
    return axios.post('/Plotagens/ResendPlotRequestEmail', request);
  }

  static async recebePlotagem(request: IPlotagemArquivo): Promise<AxiosResponse<IPlotagemArquivo>> {
    const axios = await Http.axios();
    return axios.post('/Plotagens/ReceivePlottingFiles', request);
  }

  static async downloadRelatorioExcel(request: IDownloadRelatorioRequest): Promise<AxiosResponse<File>> {
    const axios = await Http.axios();
    return new Promise((res, rej) => {
      axios.post(`/Plotagens/ExportListraMestraToExcel/${request.ConstructionSiteDisciplineId}`, request.ArquivosRelatorio, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {
          let percentCompleted: IProgressDownloading = {
            loaded: progressEvent.loaded,
            total: progressEvent.total
          };
          store.dispatch(PlotagemActions.progressDownload(percentCompleted));
        }
      }).then((response) => {
        const returnCOntentDisposition = decodeURIComponent(response.headers['content-disposition']!.split('=')[1].replace(/"/g, '').replace(/\+/g, ' '));
        const filename = returnCOntentDisposition.split(';')[0];
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        res(response);
      })
        .catch((error) => {
          rej(error);
        });
    });
  }
}
