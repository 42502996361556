import React, { FC } from "react";

import styles from "./LoadingActivitiesExternalLinkHeader.module.scss";

import SkeletonMain from "../LoadingMain";
import { Icon } from "Components/UI/Icon";

const LoadingActivitiesExternalLinkHeader: FC = () => {
  const Skeleton = () => {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['header']}>
          <span className={styles['titleHeader']}>
            <SkeletonMain 
              width="30px" 
              height="12px"
              minWidth="30px" 
              borderRadius="2px"
            />
            <span className={styles['titleHeaderBar']}>/</span>
            <SkeletonMain 
              width="10px" 
              height="10px"
              minWidth="10px" 
              borderRadius="2px"
              marginLeft="8px"
            />
            <SkeletonMain 
              width="75px" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginLeft="6px"
            />
          </span>

          <div className={styles['multipleIconHeader']}>
            <div className={styles['iconHeader']}>
              <Icon 
                icon="link"
                customSize={12}
                customColor={'#BEC2C6'}
                className={styles['link']}
              />
            </div>
            <div className={styles['iconHeader']}>
              <Icon
                icon="moreActions"
                customSize={16}
                customColor={'#BEC2C6'}
                className={styles['more']}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles['mainWrapper']}>
        <Skeleton />
    </div>
  )
}

export default LoadingActivitiesExternalLinkHeader;