import React, { FC, useEffect, useState } from 'react';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import BreadCrumbViewer from '../../Components/UI/BreadCrumbViewer';

import { Skeleton, Dropdown, Menu, Tooltip } from 'antd';
import { IGlobalReducerState } from '../../Store/Base/interface/IGlobalReducerState';
import { ViewerActions } from '../../Store/Viewer/Viewer.actions';

import logoMaleta from '../../Static/images/logo-maleta-collapse.png';
import styles from './Viewer.module.scss';
import { Icon } from '../../Components/UI/Icon';
import { IRequestFileInfo } from '../../Data/interfaces/Viewer/IGetFileInfo';
import { IDownloadFilesRequest } from '../../Data/interfaces/Obra/IDownloadFilesRequest';
import { ListagemArquivosActions } from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import AutodeskViewer from './Autodesk/Autodesk';
import { IBreadCrumbItem as IBreadCrumbItemResponse } from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { history } from '../../Store';
import { IAutodeskViewer } from '../../Data/interfaces/Viewer/IAutodeskViewer';
import SignalR from '../../Components/UI/SignalR';

import VersionHistory from './Drawer'
import { NotificationActions } from 'Store/Notification/Notification.actions';

import { datetimeToString } from '../../Utils/DateUtils';
import { allOtherExtensions } from '../../Utils/ViewerUtils';
import AccessPermission from '../../Utils/AcessPermission';
import { Http } from 'Utils/Http';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

export interface IViewer {
  match: any;
}

const Viewer: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const fileInformation = useSelector((state: IGlobalReducerState) => state.viewer.FileInfo);

  const firstOpen = React.useRef(0);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const viewerGoogle = 'https://docs.google.com/viewer?embedded=true&url=';
  const viewerType = props.match.params.viewerType;
  const ConstructionSiteDisciplinesId = Number(Http.GetQueryParams('ConstructionSiteDisciplinesId'));
  const extension = fileInformation.extension;
  const fileTypeImages = ['jpg', 'jpeg', 'png', 'tif', 'bmp'];
  const hasDownloadPermission = fileInformation.hasDownloadPermission && AccessPermission.downloadViewerButtonBlockedFiles(fileInformation?.fileStatus);

  const constructionSiteID = props.match.params.constructionSiteId;
  const fileApiID = props.match.params.fileApiId;
  const obraApi = Http.GetQueryParams('obraApi');

  useEffect(() => {
    if (
      firstOpen.current === 0
      && JSON.stringify(fileInformation) !== '{}'
    ) {
      Mixpanel.track({
        name: 'RENDER_FILE',
        props: {
          constructionSiteId: Number(props.match.params.constructionSiteId),
          api: fileInformation.apiId,
          extension: fileInformation.extension,
          federatedViewer: false,
        },
        userInfo: props.userInfo,
        currentListTenant: currentTenant,
      });
      firstOpen.current++;
    }
  }, [fileInformation]);

  useEffect(() => {
    const request: IRequestFileInfo = {
      ConstructionSiteId: props.match.params.constructionSiteId,
      FileApiId: props.match.params.fileApiId,
      breadCrumbId: props.match.params.breadCrumbId
    };

    dispatch(ViewerActions.getFileInfo(request));
    dispatch(NotificationActions.getFileVersionHistory({ constructionSiteId: constructionSiteID, fileApiId: fileApiID }));
  }, [constructionSiteID, dispatch, fileApiID, props.match.params.breadCrumbId, props.match.params.constructionSiteId, props.match.params.fileApiId]);

  useEffect(() => {
    dispatch(ViewerActions.setOpenFileLink(''));
  }, []);

  const currentTypeFile = fileInformation.extension;

  useEffect(() => {
    if (fileInformation?.fileRenderStatus !== null) {
      return;
    }

    if (allOtherExtensions.indexOf(currentTypeFile?.toLowerCase()) !== -1 || currentTypeFile === '') {
      return;
    }

    const requestAutoDeskViewer: IAutodeskViewer = {
      ConstructionSiteId: props.match.params.constructionSiteId,
      FileApiId: props.match.params.fileApiId,
    };

    dispatch(ListagemArquivosActions.setFileStatusRendering(requestAutoDeskViewer));
    dispatch(ViewerActions.autoDeskViewer(requestAutoDeskViewer));
  }, [fileInformation.fileRenderStatus]);

  const getBreadCrumb: (breadCrumbItem: IBreadCrumbItemResponse) => IBreadcrumbItem[] = (
    breadCrumbItem
  ) => {
    const breadCrumb: IBreadcrumbItem[] = [
      {
        description: 'Obras',
        href: '/obras'
      },
      {
        description: fileInformation.constructionName,
        href: `/obras/detail/${props.match.params.constructionSiteId}${ConstructionSiteDisciplinesId ? `?ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`
      }
    ];

    let nextBCI: IBreadCrumbItemResponse | undefined = breadCrumbItem;
    const breadCrumbFolders = [];
    do {
      breadCrumbFolders.push({
        description: nextBCI.Name,
        href: `/obras/filelist/${props.match.params.constructionSiteId}/${nextBCI.Identifier}?obraApi=${obraApi}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}${nextBCI.PreviousItem?.BreadCrumbItemId || nextBCI.PreviousItem?.BreadCrumbItemIdDropbox ? `&breadCrumbId=${nextBCI.PreviousItem?.BreadCrumbItemId || nextBCI.PreviousItem?.BreadCrumbItemIdDropbox}` : ''}`
      });
      nextBCI = nextBCI.PreviousItem;
    } while (nextBCI);

    breadCrumb.push(...breadCrumbFolders.reverse());

    breadCrumb.push({
      description: props.FileInfo?.fileName,
      href: `#`
    });

    return breadCrumb;
  };

  const downloadFile = () => {
    const request: IDownloadFilesRequest = {
      api: props.FileInfo.apiId,
      constructionSiteId: props.match.params.constructionSiteId,
      itemsId: [props.match.params.fileApiId]
    };
    dispatch(ListagemArquivosActions.downloadFiles(request));
  };

  const openViewerAutodesk = () => {

    if (props.FileInfo?.fileRenderStatus == null) {

      if (props.FileInfo?.fileIdentifier) {
        const request: IAutodeskViewer = {
          ConstructionSiteId: props.match.params.constructionSiteId,
          FileApiId: props.FileInfo?.fileIdentifier
        };

        dispatch(ViewerActions.pdfAutoDeskViewer(request))
      }
    }
    else {

      const currentUrl = window.location.pathname;
      const redirectUrl = `${currentUrl.substr(0, 7)}/1/${currentUrl.substr(10)}`;

      history.push(redirectUrl);
    }

  };

  const openViewerDefault = () => {
    const currentUrl = window.location.pathname;
    const redirectUrl = `${currentUrl.substr(0, 7)}/2/${currentUrl.substr(10)}`;
    history.push(redirectUrl);
  };

  const menuPadrao = (
    <Menu>
      <Menu.Item onClick={openViewerDefault} className={styles['action-menu-item']}>
        <Icon icon="estrela" customColor="#44566C" customSize={12} /> Visualizador Padrão
      </Menu.Item>
    </Menu>
  );
  const menuViewer = (
    <Menu>
      <Menu.Item onClick={openViewerAutodesk} className={styles['action-menu-item']}>
        <Icon icon="estrela" customColor="#44566C" customSize={12} /> Visualizador de projetos
      </Menu.Item>
    </Menu>
  );

  const historyVersions = () => {
    const formatedDate = new Date(props.FileInfo.date);

    if (props.FileInfo.isVersionable && props.FileInfo.hasVersionHistory) {
      return (
        <>
          <Tooltip placement="bottom" title="Abrir histórico de versões">
            <p className={styles['drawer']} onClick={() => setVisibleDrawer(!visibleDrawer)}>{`Última alteração feita ${datetimeToString(formatedDate)}`}</p>
          </Tooltip>
          <VersionHistory onclose={() => setVisibleDrawer(false)} visible={visibleDrawer} />
        </>
      )
    }

    return <p className={styles['isOldFile']}>Arquivo enviado em {datetimeToString(formatedDate)}</p>
  }

  return (
    <div className={styles['viewer']}>
      <header>
        <div className={styles['page-head']}>
          <img src={logoMaleta} alt="Engineer" />
          {!props.FileInfo?.fileIdentifier ? (
            <div
              style={{
                width: 300,
                marginLeft: 30,
                height: 25,
                position: 'relative',
                top: -11
              }}
            >
              <Skeleton paragraph={false} active />
            </div>
          ) : (
            <div className={styles['wrapperBreadcrumb']} style={{ display: 'flex', alignItems: 'center' }}>
              <BreadCrumbViewer
                breadcrumbs={getBreadCrumb(
                  props.FileInfo?.breadCrumb ?? ({} as IBreadCrumbItemResponse)
                )}
              />
              {historyVersions()}
            </div>
          )}
          {viewerType == 1 ? (
            <>
              <div className={styles['action']}>
                {currentTypeFile?.toLowerCase() === 'pdf'
                  ? <><div className={styles['containerDropwDownaction']}>
                    <Dropdown trigger={['click']} overlay={menuPadrao}>
                      <span
                        className={styles['action-item']}
                        onClick={(e) => e.preventDefault()}
                      >
                        Abrir com <Icon customSize={10} icon="expandir" />
                      </span>
                    </Dropdown>
                  </div>
                    | </> : <></>
                }
                <span
                  title="Download do arquivo"
                  className={styles['action-item-download']}
                  hidden={!hasDownloadPermission}
                >
                  <Icon customSize={20} icon="download" onClick={downloadFile} />
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={styles['action']}>
                {currentTypeFile?.toLowerCase() === 'pdf' ?
                  <>
                    <div className={styles['containerDropwDownaction']}>
                      <Dropdown trigger={['click']} overlay={menuViewer}>
                        <span
                          className={styles['action-item']}
                          onClick={(e) => e.preventDefault()}
                        >
                          Abrir com <Icon customSize={10} icon="expandir" />
                        </span>
                      </Dropdown>
                    </div>
                    |
                  </> : <></>}
                <span
                  title="Download do arquivo"
                  className={styles['action-item-download']}
                  hidden={!hasDownloadPermission}
                >
                  <Icon customSize={20} icon="download" onClick={downloadFile} />
                </span>
              </div>
            </>
          )}
        </div>
      </header>
      {viewerType == 1 ? (
        <>
          {!props.FileInfo?.fileIdentifier ? (
            <Skeleton className={styles['skeleton']} active />
          ) : (
            <>
              <SignalR />
              <AutodeskViewer
                fileInfo={props.FileInfo}
                constructionSiteId={props.match.params.constructionSiteId}
              ></AutodeskViewer>
            </>
          )}
        </>
      ) : (
        <>
          {props.isLoading || props.isRedirectAutodeskViewer || fileInformation.fileViewer === undefined ? (
            <Skeleton className={styles['skeleton']} active />
          ) : fileTypeImages.indexOf(extension?.toLocaleLowerCase()) !== -1 ? (
            <img
              className={styles['content-viewer-img']}
              src={`${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${fileInformation.fileViewer}`}
              alt={`${props.FileInfo?.fileName}`}
            />
          ) : extension?.toLocaleLowerCase() === 'pdf' ? (
            <iframe
              className={styles['content-viewer']}
              src={`${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${fileInformation.fileViewer}${hasDownloadPermission ? '' : '#toolbar=0'}`}
            ></iframe>
          ) : (
            <>
              <iframe
                className={styles['content-viewer']}
                src={`${viewerGoogle}${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${fileInformation.fileViewer}`}
              ></iframe>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.viewer,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IViewer;

export default connector(Viewer);
