import React, { FC } from 'react';

import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import { ConnectedProps, connect } from 'react-redux';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import ListContainer from '../../../../Components/UI/Containers/ListContainer/ListContainer';
import { disciplineIcons } from '../../../../Utils/Icons';
import { Icon, IconName } from '../../../../Components/UI';
import { Skeleton } from 'antd';

import styles from './ExclusaoDisciplina.module.scss';
export interface IExclusaoDisciplina {
  visible?: boolean;
  onCancel?: () => void;
  onConfirmDelete: () => void;
  onConfirmOpenDiscipline: () => void;
  isLoading?: boolean;
  id?: number;
  name?: string;
  icon: string;
  constructionSiteId: number;
  disciplineFiles?: number;
}

const ExclusaoDisciplina: FC<Props> = ({
  visible,
  onCancel,
  onConfirmDelete,
  onConfirmOpenDiscipline,
  isLoading,
  id,
  name,
  icon,
  constructionSiteId,
  disciplineFiles
}) => {
  const footerButtons = (
    <div>
      {disciplineFiles !== 0? (
        <>
          <Button height={37} onClick={onCancel}>
            <span style={{ padding: '0 18px' }}>Não, Cancelar exclusão</span>
          </Button>
          <Button
            type="primary"
            height={37}
            onClick={() => onConfirmOpenDiscipline()}
            loading={isLoading}
          >
            <span style={{ padding: '0 18px' }}>Ir para disciplina</span>
          </Button>
        </>
      ) : (
        <>
          <Button height={37} onClick={onCancel}>
            <span style={{ padding: '0 18px' }}>Não, Cancelar exclusão</span>
          </Button>
          <Button
            type="primary"
            height={37}
            onClick={() => onConfirmDelete()}
            loading={isLoading}
          >
            <span style={{ padding: '0 18px' }}>Sim, excluir disciplina</span>
          </Button>
        </>
      )}
    </div>
  );

  return (
    <Modal
      width={798}
      title="Exclusão de disciplina"
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerButtons}
    >
      {isLoading ? (
        <div style={{ padding: 20 }}>
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      ) : (
        <div className={styles['textWrap']}>
          {disciplineFiles !==0 ? (
            <>
              <div className={styles['margin']}>
                Atenção: não é permitida a exclusão de uma disciplina que contenha
                arquivos, para completar a ação você precisa excluir os arquivos.
              </div>
            </>
          ) : (
            <>
              <div className={styles['margin']}>
                Atenção: a exclusão de disciplinas é realizada de forma permanente, não
                sendo possível desfazer a ação.
              </div>
              <div>Deseja continuar?</div>
            </>
          )}
          <ListContainer height={51} key={id} className={styles['listDisciplineWrap']}>
            <Icon
              color="cinzaPadrao"
              icon={disciplineIcons.get(icon) as IconName}
              customSize={18}
            />
            <span className={styles['title']}>{name}</span>
            {disciplineFiles !== 0 ? (
              <>
                <span className={styles['infoFile']}>{disciplineFiles} Arquivos encontrados</span>
              </>
            ) : (
              <>
              </>
            )}
          </ListContainer>
        </div>
      )}
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ListFiles: state.listagemArquivos.ListFiles,
  selectedEditRowKeys: state.listagemArquivos.selectedEditRowKeys,
  BreadCrumb: state.listagemArquivos.BreadCrumbItem,
  ObsoleteFolderId: state.listagemArquivos.ObsoleteFolderId
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IExclusaoDisciplina;

export default connector(ExclusaoDisciplina);
