import React, { FC } from "react";
import { Progress } from "antd";

import styles from "./BarraProgresso.module.scss";

interface IBarraProgresso {
  titleProgress?: string;
  description?: string;
  maxLimits?: number;
  value?: number; 
}

const BarraProgresso: FC<IBarraProgresso> = ({ maxLimits, value, titleProgress, description }) => {

  const percentOfConstructionsSites = (constructionsSites: number| undefined, constructionsSitesLimit: number) => {
    if(constructionsSites) {
      const value = (constructionsSites / constructionsSitesLimit) * 100;
      return Math.floor(value);
    }
  }

  return (
    <div>
    <span className={styles['title-progress']}>{titleProgress}</span>
      {maxLimits ? (
        <p className={styles['description-progress']}>
          <b>{value || 0} </b>de<b> {maxLimits} </b>{description}
        </p>
      ) : (
        <p className={styles['description-progress']}>
          <b>{value || 0} </b> {`${description} / Ilimitado`}
        </p>
      )}     

      <Progress
        className={styles['progress-bar']} 
        percent={maxLimits
          ? percentOfConstructionsSites(value, maxLimits)
          : 100
        } 
        strokeWidth={5} 
        strokeColor={((value && maxLimits) && (value >= maxLimits)) ? "#E2574C" : "#3547C8"} 
        showInfo={false} 
      />
    </div>
  )
}

export default BarraProgresso;
