import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';

export enum FileActionKeys {
  UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED',

  UPLOAD_AVATAR_RESET_STATE = 'UPLOAD_AVATAR_RESET_STATE',
}

export const FileActions = {
  uploadAvatar: (file: Blob): UploadAvatarAction =>
    createAction(FileActionKeys.UPLOAD_AVATAR_REQUEST, file),
  uploadAvatarSuccess: (src: string): UploadAvatarSuccessAction =>
    createAction(FileActionKeys.UPLOAD_AVATAR_SUCCESS, src),
  uploadAvatarFailure: (err: string): UploadAvatarFailureAction =>
    createAction(FileActionKeys.UPLOAD_AVATAR_FAILED, err),

  resetState: (): ResetStateAction =>
    createAction(FileActionKeys.UPLOAD_AVATAR_RESET_STATE),
};

export type FileActionUnion = ActionsUnion<typeof FileActions>;

export type UploadAvatarAction = Action<FileActionKeys.UPLOAD_AVATAR_REQUEST, Blob>;
export type UploadAvatarSuccessAction = Action<FileActionKeys.UPLOAD_AVATAR_SUCCESS, string>;
export type UploadAvatarFailureAction = Action<FileActionKeys.UPLOAD_AVATAR_FAILED, string>;

export type ResetStateAction = Action<FileActionKeys.UPLOAD_AVATAR_RESET_STATE>;
