import { FC, useEffect, useMemo, useState } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import LoadingUploadDisciplineList from "Components/UI/CustomLoading/LoadingUploadDisciplineList";
import { IconName } from "Components/UI";
import { DisciplinaActions } from "Store/Disciplina/Disciplina.actions";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { DisciplineIconsEnum } from "Pages/Disciplinas";
import { ListagemArquivosActions } from "Store/ListagemArquivos/ListagemArquivos.actions";
import { IListFilesRequest } from "Data/interfaces/ListagemArquivos/IListFilesRequest";
import { IFileData } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { UploadActions } from "Store/Upload/Upload.actions";
import { IUploadFileData } from "Data/interfaces/Upload/IUploadFileData";
import { IUpdateStatusInViewer } from "Data/interfaces/ListagemArquivos/IUpdateStatusInViewer";
import { FederatedViewerActions } from "Store/FederatedViewer/FederatedViewer.actions";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import FileDestinationHeader from "./components/FileDestinationHeader";
import FileDestinationListDisciplines from "./components/FileDestinationListDisciplines";
import FileDestinationListChildren from "./components/FileDestinationListChildren";
import { NomenclaturaActions } from "Store/Nomenclatura/Nomenclatura.actions";
import { v4 as uuid } from "uuid";
import styles from "./FileDestination.module.scss";

export interface IFileDestination {
  visible: boolean;
  onCancel: () => void;
  file: File;
  currentDiscipline?: any;
  externalLoading?: boolean;
  constructionSiteId: number;
  fromMaleta?: boolean;
  disciplineList: IDisciplineViewerList[];
}

export interface IFileFinalPath {
  folderId: string;
  disciplineApiFolderId: string;
  ConstructionSiteDisciplinesId: number;
  trackFolderName?: string;
}

export interface IBreadcrumbPath {
  name: string;
  folderId: string;
  ConstructionSiteDisciplinesId?: number;
}

const FileDestination: FC<Props> = ({
  visible,
  onCancel,
  file,
  constructionSiteId,
  obra,
  disciplines,
  listFiles,
  disciplinesLoading,
  listFilesLoading,
  fromMaleta,
  loading,
  disciplineList,
  nomenclaturaData,
}) => {
  const dispatch = useDispatch();

  const [fileFinalPath, setFileFinalPath] = useState({} as IFileFinalPath);
  const [disciplineFolder, setDisciplineFolder] = useState<IDisciplineViewerList | null>(null);
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadcrumbPath[]>([]);
  const [searchDisciplineFile, setSearchDisciplineFile] = useState('');
  const [searchFile, setSearchFile] = useState('');
  const [selectedFileFromMaleta, setSelectedFileFromMaleta] = useState<IFileData | null>(null);
  const [sentFile, setSentFile] = useState(false);

  const filteredDisciplines = useMemo(() => {
    if (fromMaleta && disciplines) {
      return disciplines?.filter((discipline: any) => {
        if (discipline.CustomName) {
          return discipline?.CustomName.toLowerCase().includes(searchDisciplineFile.toLowerCase());
        } else {
          return discipline?.Discipline.Name.toLowerCase().includes(searchDisciplineFile.toLowerCase());
        }
      })
    }
    return disciplines;
  }, [disciplines, fromMaleta, searchDisciplineFile]);

  const filteredFiles = useMemo(() => {
    if (fromMaleta && listFiles) {
      return listFiles?.filter(file => {
        return file.Name.toLowerCase().includes(searchFile.toLowerCase());
      })
    }
    return listFiles;
  }, [listFiles, fromMaleta, searchFile]);

  const excludeListFiles = useMemo(() => {
    if (fromMaleta && disciplineList) {
      const disciplineExist = disciplineList.find(discipline => discipline.ConstructionSiteDisciplinesId === disciplineFolder?.ConstructionSiteDisciplinesId);
      if (disciplineExist?.FilesList && disciplineExist?.FilesList?.length > 0) {

        return disciplineExist.FilesList.map(file => file.Identifier.ApiId || file.Identifier.ItemId);
      }
    }
    return [];
  }, [disciplineList, fromMaleta, disciplineFolder]);

  useEffect(() => {
    handleListDisciplines();

    if (!nomenclaturaData.VersionFields) {
      dispatch(NomenclaturaActions.getNomenclature());
    }
  }, []);

  useEffect(() => {
    if (sentFile && loading) {
      closeModal();
    }
  }, [sentFile, loading]);

  const handleDisciplineFolder = (disciplineFolder: IDisciplineViewerList | null) => {
    setDisciplineFolder(disciplineFolder);
    clearAllSearch();
  }

  const handleIconLeft = (name: string): IconName => {
    if ((DisciplineIconsEnum as any)[name]) {
      return (DisciplineIconsEnum as any)[name];
    }
    return 'menuContexto';
  };

  const handleOpenFolder = (folderId: string, ConstructionSiteDisciplinesId?: number, folderName?: string) => {
    setSelectedFileFromMaleta(null);
    clearAllSearch();

    const request: IListFilesRequest = {
      constructionSiteId,
      folderId,
      loadObsoleteFiles: false,
      ignoreBreadCrumb: true,
    }

    const filePath: IFileFinalPath = {
      folderId,
      disciplineApiFolderId: disciplineFolder?.DisciplineApiFolderId || folderId,
      ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId || ConstructionSiteDisciplinesId || 0,
      trackFolderName: folderName,
    }
    setFileFinalPath(filePath);

    setBreadCrumbs(prevState => {
      const folderIndex = prevState.findIndex(state => state.folderId === folderId);
      if (folderIndex === -1 && folderName) {
        return [...prevState, {
          folderId,
          name: folderName,
          ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId || ConstructionSiteDisciplinesId,
        }];
      }
      if (folderIndex === -1 && !folderName) {
        return [];
      }
      if (folderIndex >= 0 && folderName) {
        if (folderIndex === 0) {
          return [{
            folderId,
            name: folderName,
            ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId || ConstructionSiteDisciplinesId,
          }];
        } else {
          return prevState.slice(0, folderIndex + 1);
        }
      }
      return prevState;
    })

    dispatch(ListagemArquivosActions.listFiles(request));
    dispatch(ListagemArquivosActions.listFilesMoveToClear());
  };

  const handleListDisciplines = () => {
    setBreadCrumbs([]);
    setSelectedFileFromMaleta(null);
    setDisciplineFolder(null);
    clearAllSearch();
    dispatch(ListagemArquivosActions.updateFileList([]));
    dispatch(DisciplinaActions.list({ csId: constructionSiteId }));
  };

  const closeModal = () => {
    setSelectedFileFromMaleta(null);
    setFileFinalPath({} as any);
    setDisciplineFolder(null);
    clearAllSearch();
    onCancel();
  };

  const isLoading = useMemo(() => {
    if (listFilesLoading || disciplinesLoading) {
      return true;
    } else {
      return false;
    }
  }, [listFilesLoading, disciplinesLoading])

  const confirmUploadFilePath = () => {
    if (obra && disciplineFolder) {
      setSentFile(true);
      if (fromMaleta && selectedFileFromMaleta) {
        const updateStatusInViewer: IUpdateStatusInViewer = {
          fileApiId: selectedFileFromMaleta.Identifier.ApiId || selectedFileFromMaleta.Identifier.ItemId,
          constructionSiteId: constructionSiteId,
          constructionSiteDisciplineId: disciplineFolder.ConstructionSiteDisciplinesId,
          api: obra.Api,
          inViewer: true,
          discipline: disciplineFolder,
          currentFile: selectedFileFromMaleta,
        };
        dispatch(FederatedViewerActions.updateStatusInViewer(updateStatusInViewer));

      } else {
        const fileIdUpload = uuid();
        const uploadFiles: IUploadFileData[] = [{
          csId: constructionSiteId,
          folderId: fileFinalPath.folderId,
          trackFolderName: fileFinalPath.trackFolderName,
          disciplineApiFolderId: fileFinalPath.disciplineApiFolderId,
          trackDisciplineName: disciplineFolder.Discipline.Name || disciplineFolder.CustomName,
          file: file,
          isSending: false,
          sentSize: 0,
          totalSize: file.size,
          uploadCompleted: false,
          uploadError: false,
          Api: obra?.Api,
          inViewer: true,
          constructionSiteDisciplineId: fileFinalPath.ConstructionSiteDisciplinesId,
          discipline: disciplineFolder,
          hasRevisionControl: obra.VersionConfig.HasRevisionControl,
          fileIdUpload,
        }];
        dispatch(UploadActions.addNewUploadFiles(uploadFiles));
        closeModal();
      }
    }
  };

  const handleConfirmFileFromMaleta = (item: IFileData) => {
    setSelectedFileFromMaleta(item);
  };

  const handleSearch = (value: string) => {
    if (!disciplineFolder) {
      setSearchFile('');
      setSearchDisciplineFile(value);
    } else {
      setSearchFile(value);
      setSearchDisciplineFile('');
    }
  }

  const clearAllSearch = () => {
    setSearchDisciplineFile('');
    setSearchFile('');
  }

  const buttons = (
    <div className={styles['buttons']}>
      <Button
        onClick={closeModal}
        type="text"
        className={styles['buttonCancel']}
      >
        Cancelar
      </Button>
      <Button
        onClick={() => confirmUploadFilePath()}
        type="primary"
        className={`${styles['buttonConfirm']} ${styles['buttonConfirmMaleta']}`}
        disabled={fromMaleta
          ? selectedFileFromMaleta === null
          : !disciplineFolder
        }
        loading={loading}
      >
        {fromMaleta ? 'Selecionar arquivo' : 'Enviar aqui'}
      </Button>
    </div>
  );

  return (
    <Modal
      title={fromMaleta ? "Selecione um arquivo" : "Selecione um destino"}
      width={500}
      visible={visible}
      onCancel={closeModal}
      footerButtons={buttons}
    >
      <div className={styles['wrapper']}>
        <FileDestinationHeader
          value={searchDisciplineFile || searchFile}
          fromMaleta={fromMaleta}
          onSearch={handleSearch}
          disciplineFolder={disciplineFolder}
          onListDisciplines={handleListDisciplines}
          isLoading={isLoading}
          onOpenFolder={handleOpenFolder}
          onIconLeft={handleIconLeft}
          breadCrumbs={breadCrumbs}
        />
        <div className={styles['main']}>
          {(isLoading || (!filteredDisciplines && filteredFiles.length <= 0)) && (
            <LoadingUploadDisciplineList multiple={12} />
          )}
          {!isLoading && ((filteredDisciplines && filteredDisciplines.length > 0 && (!searchFile && !disciplineFolder && filteredFiles.length <= 0)) ? (
            <FileDestinationListDisciplines
              filteredDisciplines={filteredDisciplines}
              onDisciplineFolder={handleDisciplineFolder}
              onOpenFolder={handleOpenFolder}
              onIconLeft={handleIconLeft}
              searchDisciplineFile={searchDisciplineFile}
            />
          ) : (
            <FileDestinationListChildren
              filteredFiles={filteredFiles}
              fromMaleta={fromMaleta}
              selectedFileFromMaleta={selectedFileFromMaleta}
              onOpenFolder={handleOpenFolder}
              disciplineFolder={disciplineFolder}
              onConfirmFileFromMaleta={handleConfirmFileFromMaleta}
              searchFile={searchFile}
              excludeListFiles={excludeListFiles}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  obra: state.disciplina.Obra,
  disciplines: state.disciplina.Obra?.Disciplines,
  disciplinesLoading: state.disciplina.isLoading,
  listFiles: state.listagemArquivos.ListFiles,
  listFilesLoading: state.listagemArquivos.isLoadingFileList,
  loading: state.federatedViewer.isLoading,
  nomenclaturaData: state.nomenclatura.NomenclatureData,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IFileDestination;

export default connector(FileDestination);
