import { FC, useEffect } from 'react';
import PageHeader from '../../Components/UI/PageHeader/PageHeader';
import DetalhesConta from './DetalhesConta/DetalhesConta';
import DadosEmpresa from './DadosEmpresa/DadosEmpresa';
import BreadCrumb from '../../Components/UI/BreadCrumb';
import { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import { Skeleton } from 'antd';
import styles from './MinhaConta.module.scss';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { MinhaContaActions } from '../../Store/MinhaConta/MinhaConta.actions';
import { IGlobalReducerState } from '../../Store/Base/interface/IGlobalReducerState';
import UpdateConta from './Modals/UpdateConta';
import UpdateEmpresa from './Modals/UpdateEmpresa';
import { AuthActions } from '../../Store/Auth/Auth.actions';
import UpdatePassword from './Modals/UpdatePassword';

export interface IMinhaConta {
  match: any;
  location: any
}

const MinhaConta: FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MinhaContaActions.getUserInfo());
    dispatch(AuthActions.listCompanyPosition());

    const QS: string[] = props.location.search.replace('?', '').split('&');
    QS.forEach((queryParam) => {
      if (queryParam === 'show=0' && !props.isLoading) {
        dispatch(MinhaContaActions.setShowModalUpdateConta(true));
      } else if (queryParam === 'show=1' && !props.isLoading) {
        dispatch(MinhaContaActions.setShowModalUpdateEmpresa(true));
      }
    });

  }, []);

  const companyPositions = props.lisCompanyPosition?.map((role) => ({
    value: role.CompanyPositionId,
    description: role.Name,
    label: role.Name
  }));

  const breadCrumbs: IBreadcrumbItem[] = [
    {
      description: 'Minha Conta',
      href: '/conta',
    },
  ];

  return (
    <PageHeader name='Minha conta'>
      <BreadCrumb breadcrumbs={breadCrumbs} />

      {
        props.isLoading
          ? <Skeleton active className={styles['MinhaContaSkeleton']} />
          : (<>
            <div className={styles['MinhaContaWrapper']}>
              <DetalhesConta />
            </div>
            {
              props.userInfo?.CompanyPosition?.Type === 1 && props.userInfo?.CurrentRoleFk === 1
                ? (
                  <div className={styles['MinhaContaWrapper']}>
                    <DadosEmpresa />
                  </div>
                )
                : <></>
            }
          </>)
      }

      <UpdateConta
        visible={props.showModalUpdateConta}
        onCancel={() => dispatch(MinhaContaActions.setShowModalUpdateConta(false))}
        userInfos={props.userInfo}
        companyPositions={companyPositions}
      />
      <UpdatePassword
        visible={props.showModalUpdatePassword}
        onCancel={() => dispatch(MinhaContaActions.setShowModalUpdatePassword(false))}
        userInfoId={props.userInfo?.Id || ''}
      />
      <UpdateEmpresa
        visible={props.showModalUpdateEmpresa}
        onCancel={() => dispatch(MinhaContaActions.setShowModalUpdateEmpresa(false))}
        userInfos={props.userInfoGet || undefined}
      />
    </PageHeader>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
  lisCompanyPosition: state.auth.listCompanyPosition,
  userInfoGet: state.auth.userInfo,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IMinhaConta;


export default connector(MinhaConta);
