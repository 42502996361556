import { FC } from 'react';

import styles from './Tag.module.scss';
import { ColorName, getColor } from '../../../Utils/Color';
import { Tag as AntdTag, TagProps } from 'antd';

interface ITag extends TagProps {
  type?: 'primary' | 'secondary'
  color?: ColorName;
  loading?: boolean;
  customColor?: string;
  customClassName?: string;
}

const Tag: FC<ITag> = ({
  type = 'primary',
  color = 'cinzaSuperior',
  loading = false,
  customColor,
  customClassName,
  children,
  ...props
}) => {

  if (type === 'secondary') {
    return (
      <AntdTag className={styles['secondary-tag']} {...props}>
        {children}
      </AntdTag>
    );
  }

  return (
    <span
      className={`${styles['tag']} ${customClassName || ''}`}
      style={{
        color: customColor
          ? customColor
          : getColor(color)
      }}
    >
      {loading ? <div className={styles['loader']} ></div> : ''}
      {children}
    </span>
  );
};

export default Tag;
