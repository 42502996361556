import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  .icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.secondary.secondary};
    
    .iconContexto {
      color: ${({theme}) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }

  .text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: center;
    margin-top: 12px;
      color: ${({theme}) => theme.colors.surface.onSurface};
  }
`;
