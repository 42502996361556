import { useCallback } from "react";
import { getImgsOnErrorControl } from "Store/Helper/Helper.selector";
import { useDispatch, useSelector } from "react-redux";
import { HelperActions } from "Store/Helper/Helper.actions";
import notFound from 'Static/images/not-found.png';

const useImgOnErrorControl = () => {
  const dispatch = useDispatch();
  const imgsOnErrorControl = useSelector(getImgsOnErrorControl);

  const handleImgOnError = useCallback((
    e: React.SyntheticEvent<HTMLImageElement, Event>,
    id: string,
    originalURL: string,
  ) => {
    const imgControl = imgsOnErrorControl[id];
    if (!imgControl || imgControl < 2) {
      e.currentTarget.src = originalURL;
      dispatch(HelperActions.uploadImgsOnErrorSet(id))
    } else {
      e.currentTarget.src = notFound;
    }
  }, [dispatch, imgsOnErrorControl]);

  return { handleImgOnError, imgsOnErrorControl };
}

export default useImgOnErrorControl;
