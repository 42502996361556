import styled from "styled-components"

export const Container = styled.section`
  margin: 20px 10px 0 0;
  width: 98%;
  height: 70px;

  background: #FFFFFF;
  border: 1px solid #EAEDF0;
  border-radius: 5px;
`;
