import { call, put } from 'redux-saga/effects';
import { ObrasApi } from '../../Data/Obras.api';
import { 
  DisciplinaActions, 
  ListAction, 
  CreateOrUpdateAction, 
  DeleteDisciplineRequestAction, 
  StatusSADisciplineRequestAction,
  RestoreSADisciplineRequestAction,
  CreateSADisciplineRequestAction,
  DeleteSADisciplineRequestAction,
  MergeSADisciplineRequestAction,
} from './Disciplina.actions';
import toastHandler from '../../Utils/toastHandler';
import { history } from '..';
import { 
  openError, 
  updateError, 
  createError, 
  updateSuccess, 
  createSuccess,
} from '../../Utils/Messages';
import { 
  ListagemArquivosActions, 
  ListFilesAction,
} from '../ListagemArquivos/ListagemArquivos.actions';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { IObraListResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { IDisciplineSAStatusResponse } from 'Data/interfaces/Obra/IDisciplineSAStatusResponse';

export function* list({ payload }: ListAction) {
  try {
    const { data } = yield call(ObrasApi.getConstructionSite, payload);
    yield put(ListagemArquivosActions.currentUserPrivileges(data.data.CurrentCsUserPermissions));
    yield put(DisciplinaActions.listSuccess(data.data));
    if (payload.ignoreCache) {
      const { ConstructionSiteId, Name } = data.data;
      const response: IObraListResponse = { 
        newObraName: {
          ConstructionSiteId: ConstructionSiteId,
          Name: Name,
        }
      }
      yield put(ObraActions.listSuccess(response));
    }
  } catch (e: any) {
    history.push('/Obras');
    const error = e.errors ? e.errors[0].Message : openError('disciplinas');
    toastHandler.handler({ description: error, icon: 'error', title: '' });
    yield put(DisciplinaActions.listFailure(error));
  }
}

export function* createOrUpdateDiscipline({ payload }: CreateOrUpdateAction) {
  try {
    const { data } = yield call(ObrasApi.createOrUpdateDiscipline, payload);
    yield put(DisciplinaActions.createOrUpdateSuccess(data.data));
    const msg = payload.Id ? updateSuccess('disciplina') : createSuccess('disciplina');
    toastHandler.handler({ description: msg, icon: 'success', title: '' });
  } catch (e: any) {

    const error = e.errors && e.errors.length > 0 ? e.errors[0].Message : (payload.Id ? updateError('disciplina') : createError('disciplina'));
    toastHandler.showError(error);
    yield put(DisciplinaActions.createOrUpdateFailure(error));
  }
}

export function* listFilesOnDiscipline({ payload }: ListFilesAction) {
  try {
    const { data } = yield call(ObrasApi.listFiles, payload);
    yield put(DisciplinaActions.listFilesOnDisciplineSuccess(data.data.Files.length));
  } catch (e: any) {

    const error =e.errors ? e.errors[0].Message : "Erro ao listar disciplinas"
    toastHandler.showError(error);
    yield put(DisciplinaActions.listFilesOnDisciplineFailure(error));
  }
}

export function* deleteDiscipline({ payload }: DeleteDisciplineRequestAction) {
  try {
    const { data } = yield call(ObrasApi.deleteDiscipline, payload);
    toastHandler.showSuccess('Disciplina removida com sucesso!');
    yield put(DisciplinaActions.deleteDisciplineSuccess(data.data));

  } catch (e: any) {

    const error = e.errors ? e.errors[0].Message : "Erro ao listar disciplinas"
    toastHandler.showError(error);
    yield put(DisciplinaActions.deleteDisciplineFailure(error));
  }
}

export function* statusSADiscipline({ payload }: StatusSADisciplineRequestAction) {
  try {
    const { data } = yield call(ObrasApi.statusSADiscipline, payload);
    const response: IDisciplineSAStatusResponse = {
      ConstructionSiteDisciplineId: payload.ConstructionSiteDisciplineId,
      disciplineSAStatus: data.data,
      existingFolderApiId: data?.info || undefined,
    } 
    yield put(DisciplinaActions.statusSADisciplineSuccess(response));

  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : "Erro ao carregar status da disciplina"
    toastHandler.showError(error);
    yield put(DisciplinaActions.statusSADisciplineFailure(error));
  }
}

export function* restoreSADiscipline({ payload }: RestoreSADisciplineRequestAction) {
  try {
    yield call(ObrasApi.restoreSADiscipline, payload);
    yield put(DisciplinaActions.restoreSADisciplineSuccess(payload.ConstructionSiteDisciplinesId));

  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : "Erro ao restaurar disciplina"
    toastHandler.showError(error);
    yield put(DisciplinaActions.restoreSADisciplineFailure(error));
  }
}

export function* createSADiscipline({ payload }: CreateSADisciplineRequestAction) {
  try {
    const { data } = yield call(ObrasApi.createSADiscipline, payload);
    yield put(DisciplinaActions.createSADisciplineSuccess(data.data));

  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : "Erro ao criar disciplina"
    toastHandler.showError(error);
    yield put(DisciplinaActions.createSADisciplineFailure(error));
  }
}

export function* deleteSADiscipline({ payload }: DeleteSADisciplineRequestAction) {
  try {
    yield call(ObrasApi.deleteSADiscipline, payload);
    yield put(DisciplinaActions.deleteSADisciplineSuccess(payload.ConstructionSiteDisciplinesId));

  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : "Erro ao excluir disciplina"
    toastHandler.showError(error);
    yield put(DisciplinaActions.deleteSADisciplineFailure(error));
  }
}

export function* mergeSADiscipline({ payload }: MergeSADisciplineRequestAction) {
  try {
    const { data } = yield call(ObrasApi.mergeSADiscipline, payload);
    yield put(DisciplinaActions.mergeSADisciplineSuccess(data.data));

  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : "Erro ao excluir disciplina"
    toastHandler.showError(error);
    yield put(DisciplinaActions.mergeSADisciplineFailure(error));
  }
}
