import styled from "styled-components";

interface IContainer {
  height: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height}px;

  .imgSearch {
    width: 44px;
    height: 44px;
    margin-bottom: 12px;
  }
  .imgEmpty {
    width: 67.84px;
    height: 57.6px;
    margin-bottom: 24px;
  }

  .title {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-bottom: 6px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }

  .button {
      width: 145px;
      height: 38px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 32px;

      .buttonIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({theme}) => theme.colors.primary.onPrimary} !important;
      }

      .buttonText {
        font-size: 12px;
        line-height: 16.37px;
        font-weight: 600;
        margin-left: 11.67px;
      }
    }
`;
