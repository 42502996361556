import { FC, useEffect, useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import Table from '../../../Components/UI/Table';
import BreadCrumb, { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import Button from '../../../Components/UI/Button';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import UsuariosConvidar from '../Modals/UsuariosConvidar';
import AvisoRemoverUsuario from '../Modals/AvisoRemoverUsuario';
import { TenantActions } from '../../../Store/Tenant/Tenant.actions';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import ObrasEPermissoes from '../Modals/ObrasEPermissoes';
import { IInvitedUser } from '../../../Data/interfaces/User/IInvitedUser';
import { ISharedUser } from '../../../Data/interfaces/User/ISharedUser';
import AvisoUsuarioUpgrade from '../../SharedModals/AvisoUsuarioUpgrade';
import { isObjectEmpty } from 'Utils/ObjectUtils';
import { copyToClipboard } from 'Utils/TextUtils';
import { history } from 'Store';
import AvisoInativarUsuario from '../Modals/AvisoInativarUsuario';
import { ITenantSharedUsers } from 'Data/interfaces/Tenant/ITenantUsersWithConstruction';
import LoadingUsuarios from 'Components/UI/CustomLoading/LoadingUsuarios';
import { columnsTable } from './Usuarios.columns';
import TableActionTopbar, { ITableActionTopbarButton } from 'Components/UI/TableActionTopbar';
import { ConfigProvider, Empty } from 'antd';
import CustomEmptyData from 'Components/UI/CustomEmptyData';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import './Usuarios.css';
import styles from './Usuarios.module.scss';

const breadCrumbs: IBreadcrumbItem[] = [
  {
    description: 'Gerenciar usuários',
    href: '/gerenciar-usuarios/usuarios'
  },
  {
    description: 'Usuários da maleta',
    href: '/gerenciar-usuarios/usuarios'
  }
];

interface IUpdatedUsers {
  isVisible?: boolean;
  onCancel?: () => void;
  isEdit?: boolean;
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}
interface IUsuarios {
  match: any;
  sharedUsers?: any[];
  isConnectedApi?: boolean;
}

const Usuarios: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  // const [isEditObra, setIsEditObra] = useState(false);
  const [obrasEPermissoesVisible, setObrasEPermissoesVisible] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState<IUpdatedUsers>({
    InvitedUsers: props.Obra.InvitedUsers,
    SharedUsers: props.Obra.SharedUsers
  });
  const [currentUserEdit, setCurrentUserEdit] = useState([]);
  const [RoleNewUser, setRoleNewUser] = useState<any>(4);
  const [usersToRender, setUsersToRender] = useState<any>();
  const [searchUsers, setSearchUsers] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [workToEdit, setWorkToEdit] = useState<any>();
  // const [cadastroObraVisible, setCadastroObraVisible] = useState(false);
  // const [conexaoServicoVisible, setConexaoServicoVisible] = useState(false);
  // const [csIdConexaoServico, setCsIdConexaoServico] = useState<number>();
  const [isVisibleModalRemoverUsuario, setIsVisibleModalRemoverUsuario] = useState(false);
  const [isVisibleModalInativarUsuario, setIsVisibleModalInativarUsuario] = useState(false);
  const [modalUsuarioUpgradeVisible, setModalUsuarioUpgradeVisible] = useState(false);
  const [modalUserInviteVisible, setModalUserInviteVisible] = useState(false);
  const [hashEmailCopiado, setHashEmailCopiado] = useState<string>();
  const [currentUserStatusEdit, setCurrentUserStatusEdit] = useState<ITenantSharedUsers>();
  const [numberActiveUsers, setNumberActiveUsers] = useState(1);

  const defaultRoles = props.tenantRoles.map((role) => ({
    value: role.TenantRoleId,
    description: role.RoleName,
    label: role.RoleName
  }));

  let deleteOption = {
    value: -1,
    icon: 'excluir',
    description: 'Remover usuários',
    label: 'Remover usuários',
    isDivider: true,
    customClass: 'IconRemoveUser',
  };

  const roles = defaultRoles?.concat(deleteOption);

  useEffect(() => {
    // dispatch(ObraActions.list());
    dispatch(TenantActions.getTenantListUsersConstruction());

    if (!props.minhaConta || isObjectEmpty(props.minhaConta))
      dispatch(AuthActions.getUserInfo());
  }, []);

  // const openConexaoServico = (csId: number) => {
  //   setConexaoServicoVisible(true);
  //   setCsIdConexaoServico(csId);
  // };

  const reSendInvite = (InvitedUserId: number) => {
    dispatch(TenantActions.tenantUsersConstructionReSendInvite(InvitedUserId));
  };

  const openObrasEPermissoes = (workToEdit: any) => {
    if (!obrasEPermissoesVisible && workToEdit) {
      setWorkToEdit(workToEdit);
    }
    setObrasEPermissoesVisible(!obrasEPermissoesVisible);
  };

  const closeObrasEPermissoes = () => {
    setWorkToEdit([]);
    setObrasEPermissoesVisible(false);
  };

  // const openEdicaoObra = (constructionSiteId: number) => {
  //   const request: IUpdateDisciplineStatusRequest = {
  //     csId: constructionSiteId
  //   } 
  //   dispatch(ObraActions.getObra(request));
  //   setIsEditObra(true);
  //   dispatch(TenantActions.getUsers());
  //   setCadastroObraVisible(true);
  // };

  const upgradePlanRedirect = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'usuarios',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    setModalUsuarioUpgradeVisible(false);
    if (props.userInfo?.CurrentRoleFk === 1) {
      history.push('/faturamento/planos');
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const openInviteUser = () => {
    if (!props.isLoading) {
      if (props.limitUsers) {
        if (numberActiveUsers >= props.limitUsers) {
          setModalUsuarioUpgradeVisible(true);
        } else {
          setModalUserInviteVisible(true);
        }
      } else {
        setModalUserInviteVisible(true);
      }
    }
  };

  const selectPaperUser = (userRemove: any, value: any) => {
    if (value === -1) {
      setIsVisibleModalRemoverUsuario(true);
      setCurrentUserEdit(userRemove);
    } else {
      let data = {
        UserId: userRemove.Id,
        RoleId: value
      };
      dispatch(TenantActions.updateTenantUserRoleRequest(data));
    }
  };

  const updateUserStatus = (request: ITenantSharedUsers) => {
    if (!request.isActive) {
      if (props.limitUsers && (numberActiveUsers >= props.limitUsers)) {
        setModalUsuarioUpgradeVisible(true);
      } else {
        setCurrentUserStatusEdit(request);
        dispatch(TenantActions.updateTenantStatus({
          tenantUserId: request.TenantUserId,
          isActive: !request.isActive
        }));
      }
    } else {
      setCurrentUserStatusEdit(request);
      setIsVisibleModalInativarUsuario(true);
    }
  }

  const getSharedUserRoleName = (roleFk?: number) => {
    const roleName = props.tenantRoles.find((role) => role.TenantRoleId === roleFk)
      ?.RoleName;
    return roleName;
  };

  const InvitedUsers: any = props.UsersWithConstruction?.tenantInvitedUsers?.map(
    (user: any) => ({
      ...user,
      User: {
        Id: user.InvitedUserId,
        Email: user.InvitedUserEmail,
        Nome: user.InvitedUserEmail,
        ...user
      }
    })
  );

  const ActiveUsers: any = props.UsersWithConstruction?.tenantUsers?.filter((user: any) => user.isActive === true)
    .map((user: any) => ({
      ...user,
      User: {
        Id: user.User.Id,
        Email: user.User.Email,
        Nome: user.User.Nome,
        ...user
      }
    }));

  const InativeUsers: any = props.UsersWithConstruction?.tenantUsers?.filter((user: any) => user.isActive === false)
    .map((user: any) => ({
      ...user,
      User: {
        Id: user.User.Id,
        Email: user.User.Email,
        Nome: user.User.Nome,
        ...user
      }
    }));

  useEffect(() => {
    joinAllUsers();
    if (!props.customLoading) {
      setCurrentUserStatusEdit(undefined);
    }
  }, [
    props.UsersWithConstruction?.tenantInvitedUsers,
    props.UsersWithConstruction?.tenantUsers,
    props.isLoading,
    props.customLoading,
  ]);

  const copyInviteLink = (hashEmail: any) => {
    copyToClipboard(`${window.location.origin}/signup/${hashEmail}`);
    setHashEmailCopiado(hashEmail);
    const timeout = setTimeout(() => {
      setHashEmailCopiado('');
      clearTimeout(timeout);
    }, 1500);
  }

  const joinAllUsers = useCallback(() => {
    let combinedUsers: any = ActiveUsers?.concat(InvitedUsers).concat(InativeUsers);
    if (ActiveUsers && InvitedUsers) {
      setNumberActiveUsers((ActiveUsers?.length || 0) + (InvitedUsers?.length || 0))
    }
    setUsersToRender(combinedUsers);
  }, [
    props.UsersWithConstruction?.tenantInvitedUsers,
    props.UsersWithConstruction?.tenantUsers,
    props.isLoading,
    props.customLoading,
  ]);

  // const obras = usersToRender?.ConstructionSites;

  const sortList = (sortOrder: boolean) => {
    if (usersToRender) {
      const sortedFiles = [
        ...(usersToRender?.sort((a: any, b: any) => {
          const propA = a['User']['Nome'] || a['User']['Email'] || '';
          const propB = b['User']['Nome'] || b['User']['Email'] || '';

          if (typeof propA === 'number' && typeof propB === 'number') {
            return sortOrder ? propB - propA : propA - propB;
          }

          return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
        }) || [])
      ];
      return setUsersToRender(sortedFiles);
    }
  };

  const getActionsToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        iconLoading: (props.isLoading ||
          props.UsersWithConstruction?.tenantInvitedUsers === undefined ||
          props.UsersWithConstruction?.tenantUsers === undefined),
        icon: 'atualizar',
        description: (props.isLoading ||
          props.UsersWithConstruction?.tenantInvitedUsers === undefined ||
          props.UsersWithConstruction?.tenantUsers === undefined) ? 'Atualizando...' : 'Atualizar',
        action: () => {
          dispatch(TenantActions.getTenantListUsersConstruction());
        },
      },
    ]
  }

  const filteredUsers = useMemo(() => usersToRender?.filter((users: any) => (
    users.User?.Nome?.toLowerCase().includes(searchUsers?.toLowerCase()) ||
    users.User?.Email?.toLowerCase().includes(searchUsers?.toLowerCase())
  )), [usersToRender, searchUsers]);

  return (
    <div className={styles['hearderPage']}>
      {props.minhaConta.CurrentRoleFk === 1 ||
        props.minhaConta.CurrentRoleFk === 3 ||
        props.minhaConta.CurrentRoleFk === 7 ? (
        <PageHeader
          name="Gerenciar usuários"
          actions={
            <Button
              disabled={props.minhaConta.CurrentRoleFk === 3}
              className={
                props.minhaConta.CurrentRoleFk === 3
                  ? styles['buttonConvidarUsuariosDisabled']
                  : styles['buttonConvidarUsuarios']
              }
              onClick={() => openInviteUser()}
            >
              Convidar usuários
            </Button>
          }
        >
          <div className={styles['breadCrumb']}>
            <BreadCrumb breadcrumbs={breadCrumbs} />
          </div>
          <div className={styles['tableWrapper']}>
            <TableActionTopbar
              onSearch={setSearchUsers}
              onSearchLoading={setSearchLoading}
              buttons={getActionsToolbar()}
              inputPlaceholder="Pesquise por um usuário"
            />
            {props.isLoading ||
              props.UsersWithConstruction?.tenantInvitedUsers === undefined ||
              props.UsersWithConstruction?.tenantUsers === undefined ||
              searchLoading ? (
              <>
                <LoadingUsuarios multiple={6} />
              </>
            ) : (
              <ConfigProvider renderEmpty={() => (
                searchUsers ? (
                  <CustomEmptyData
                    title="Nenhum usuário encontrado"
                    subtitle="Tente pesquisar pelo e-mail ou nome do usuário."
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              )}>
                <Table
                  columns={columnsTable({
                    sortList,
                    updateUserStatus,
                    customLoading: props.customLoading,
                    currentUserStatusEdit,
                    userInfo: props.userInfo,
                    openObrasEPermissoes,
                    minhaConta: props.minhaConta,
                    ActiveUsers,
                    hashEmailCopiado,
                    copyInviteLink,
                    reSendInvite,
                    reSendInviteLoading: props.reSendInviteLoading,
                    reSendInviteId: props.reSendInviteId,
                    setIsVisibleModalRemoverUsuario,
                    setCurrentUserEdit,
                    selectPaperUser,
                    roles,
                    getSharedUserRoleName,
                    RoleNewUser
                  })}
                  dataSource={filteredUsers?.map((usuario: any, index: number) => ({
                    ...usuario.User,
                    key: index
                  }))}
                  isSpaced={true}
                  rowClassName={(record) => {
                    return (!record.isActive && !record.InvitedUserId) ? 'rowClassNameActive' : ''
                  }}
                />
              </ConfigProvider>
            )}
          </div>
        </PageHeader>
      ) : (
        <>
          {props.minhaConta.CurrentRoleFk === 4 ||
            props.minhaConta.CurrentRoleFk === 5 ? (
            <Redirect to="/obras" />
          ) : (
            <></>
          )}
        </>
      )}
      <ObrasEPermissoes
        workToEdit={workToEdit}
        currentUserLogged={props.minhaConta}
        combinedUsers={usersToRender}
        isVisible={obrasEPermissoesVisible}
        onCancel={closeObrasEPermissoes}
      />
      <AvisoRemoverUsuario
        isLoading={props.customLoading}
        visible={isVisibleModalRemoverUsuario}
        userToDelete={currentUserEdit}
        onClose={() => setIsVisibleModalRemoverUsuario(false)}
      />
      <AvisoInativarUsuario
        isLoading={props.customLoading}
        visible={isVisibleModalInativarUsuario}
        user={currentUserStatusEdit}
        onClose={() => setIsVisibleModalInativarUsuario(false)}
      />
      <UsuariosConvidar
        userIsFreemium={props.planFree}
        onCancel={() => setModalUserInviteVisible(false)}
        isLoading={props.isLoading}
        visible={modalUserInviteVisible}
        tenantRoles={props.tenantRoles}
        tenantUsers={ActiveUsers}
        tenantInvitedUsers={InvitedUsers}
        changeUsers={setUpdatedUsers}
        limitUsers={props.limitUsers}
        userInfo={props.userInfo}
        planFree={props.planFree}
      />
      <AvisoUsuarioUpgrade
        onConfirm={upgradePlanRedirect}
        visible={modalUsuarioUpgradeVisible}
        onCancel={() => setModalUsuarioUpgradeVisible(false)}
        role={props.userInfo?.CurrentRoleFk}
      />
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  ...state.auth,
  isLoading: state.tenant.isLoading,
  customLoading: state.tenant.customLoading,
  reSendInviteLoading: state.tenant.reSendInviteLoading,
  reSendInviteId: state.tenant.reSendInviteId,
  tenantRoles: [...(state.tenant.TenantRoles || [])],
  TenantSharedUsers: [...(state.tenant.Users || [])],
  minhaConta: state.minhaConta.userInfo,
  UsersWithConstruction: state.tenant.UsersWithConstruction,
  registerCompleted: state.minhaConta.userInfo?.isRegisterComplete,
  limitUsers: state.auth.userInfo?.UserStoreItem.MaxQtyUsers || state.auth.userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IUsuarios;

export default connector(Usuarios);
