import React, { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import styles from "./ConfirmCloseModal.module.scss";

interface IConfirmCloseModal {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmCloseModal: FC<IConfirmCloseModal> = ({
  visible,
  onCancel,
  onConfirm,
}) => {
  const footerConfirmButtons = (
    <div className={styles['buttons']}>
      <Button
        onClick={onCancel}
        type="text"
        className={styles['buttonCancel']}
      >
        Cancelar
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        onClick={onConfirm}
        className={styles['buttonSubmit']}
      >
        Descartar
      </Button>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      footerActive={false}
      width={450}
      customClassName
    >
      <div className={styles['wrapper']}>
        <span className={styles['title']}>
          As alterações não foram salvas
        </span>
        <hr className={styles['midleHr']} />
        <span className={styles['description']}>
          Você tem certeza que deseja descartar as informações preenchidas? Não podemos salvar os dados se você sair desta página.
        </span>
      </div>
      <hr className={styles['footerHr']} />
    </Modal>
  )
}

export default ConfirmCloseModal;
