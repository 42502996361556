import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IAutodeskViewer } from './interfaces/Viewer/IAutodeskViewer';
import { IRequestFileInfo } from './interfaces/Viewer/IGetFileInfo';
import { IUpdateStatusInViewer } from './interfaces/ListagemArquivos/IUpdateStatusInViewer';

export class ViewerApi {
    static async RenderAutoDesk(file: IAutodeskViewer): Promise<AxiosResponse<any>> {
      const axios = await Http.axios(false);
      return axios.post(`/Viewer/AutodeskViewer`, file);
    }

    static async GetFileInfo(request: IRequestFileInfo): Promise<AxiosResponse<any>> {
      const axios = await Http.axios(false);
      return axios.get(`/Viewer/GetFileInfo?fileApiId=${request.FileApiId}&constructionSiteId=${request.ConstructionSiteId}${request.breadCrumbId ? `&breadCrumbId=${request.breadCrumbId}` : ''}`);
    }

    static async listDisciplines(csId: number): Promise<AxiosResponse<any>> {
      const axios = await Http.axios();
      return axios.get(`/Viewer/ListDisciplines?csId=${csId}`);
    }

    static async listFiles(constructionSiteDisciplineId: number): Promise<AxiosResponse<any>> {
      const axios = await Http.axios();
      return axios.get(`/Viewer/ListFiles?constructionSiteDisciplineId=${constructionSiteDisciplineId}`);
    }

    static async updateStatusInViewer(request: IUpdateStatusInViewer): Promise<AxiosResponse<any>> {
      const axios = await Http.axios();
      return axios.put(`/Viewer/UpdateFileInViewerStatus`, request);
    }

    static async GetAccessToken(): Promise<AxiosResponse<any>> {
      const axios = await Http.axios(false);
      return axios.get(`/Viewer/GetAutoDeskAccessToken`);
    }

    static async GetManifest(accessToken: string, urn: string): Promise<AxiosResponse<any>> {
      const axios = await Http.getAxiosForge(accessToken);
      return axios.get(`/${urn}/manifest`);
    }

    static async GetAllHierarchy(accessToken: string, urn: string, guiId: string): Promise<AxiosResponse<any>> {
      const axios = await Http.getAxiosForge(accessToken);
      return axios.get(`/${urn}/metadata/${guiId}?forceget=true`);
    }

    static async GetMetadata(accessToken: string, urn: string): Promise<AxiosResponse<any>> {
      const axios = await Http.getAxiosForge(accessToken);
      return axios.get(`/${urn}/metadata`);
    }

    static async GetHierarchy(accessToken: string, urn: string, guiId: string): Promise<AxiosResponse<any>> {
      const axios = await Http.getAxiosForge(accessToken);
      return axios.get(`/${urn}/metadata/${guiId}`);
    }

    static async GetProperties(accessToken: string, urn: string, guiId: string): Promise<AxiosResponse<any>> {
      const axios = await Http.getAxiosForge(accessToken);
      return axios.get(`/${urn}/metadata/${guiId}/properties`);
    }
}
