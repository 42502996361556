import React, { FC, useEffect, useState } from "react";
import styles from "./SearchViewerInput.module.scss";
import { Icon } from "../Icon";
import useDebounce from "Hooks/useDebounce";
import { Popover } from "antd";
import AccessPermission from "Utils/AcessPermission";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

export interface ISearchViewerInput {
  value: string;
  uploadButton?: boolean;
  onSearch: (value: string) => void; 
  onSearchLoading?: (value: boolean) => void; 
  inputPlaceholder?: string;
  firstLoading?: boolean;
  loading?: boolean;
  maxWidth?: string;
  onUploadFile?: () => void;
  onMaletaFile?: () => void;
  inputClassName?: string;
  marginBottom?: string;
  marginTop?: string;
  iconClassName?: string;
  isDebounce?: boolean;
  limitRender?: number;
  activeRender?: number;
  onShowCTARender?: () => void;
  onLimitBarProgress?: () => void;
}

const SearchViewerInput: FC<ISearchViewerInput> = ({
  value,
  uploadButton,
  onSearch,
  onSearchLoading,
  inputPlaceholder,
  firstLoading,
  maxWidth,
  onUploadFile,
  onMaletaFile,
  inputClassName,
  marginBottom,
  marginTop,
  iconClassName,
  isDebounce = true,
  limitRender,
  activeRender,
  onShowCTARender,
  onLimitBarProgress,
}) => {
  const theme = useSelector(getTheme);

  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showUploadOptions, setShowUploadOptions] = React.useState(false);

  const { debouncedValue, debouncedLoading } = useDebounce(inputValue, 600);

  useEffect(() => {
    if (isDebounce) {
      onSearch(debouncedValue);
      if (onSearchLoading) {
        onSearchLoading(debouncedLoading);
      }
    }
  }, [debouncedValue, debouncedLoading])

  const handleChangeSearch = (event: any) => {
    if (isDebounce) {
      setInputValue(event.target.value);
    } else {
      onSearch(event.target.value);
    }
  } 

  const handleVisibleChange = (visible: boolean) => {
    setShowUploadOptions(visible);
  };

  const handleUploadAction = () => {
    if ((onShowCTARender && limitRender && activeRender) && (activeRender >= limitRender)) {
      handleVisibleChange(false);
      return onShowCTARender();
    }
    if ((onLimitBarProgress && limitRender && activeRender) && (activeRender < limitRender)) {
      return onLimitBarProgress();
    }
    if (onUploadFile) {
      onUploadFile();
      return handleVisibleChange(false);
    } else {
      return undefined;
    }
  }

  const handleMaletaAction = () => {
    if (onMaletaFile) {
      if ((onShowCTARender && limitRender && activeRender) && (activeRender >= limitRender)) {
        handleVisibleChange(false);
        return onShowCTARender();
      }
      onMaletaFile();
      handleVisibleChange(false);
    } else {
      return undefined;
    }
  }

  const overlay = (
    <div className={styles['menuDropdown']}>
      <div
        className={styles['menuItem']}
        onClick={() => handleMaletaAction()}
      >
        <Icon icon="maleta" customSize={18} customColor="#8697A8" />
        <span className={styles['textItem']}>
          Arquivo da Coordly
        </span>
      </div>
      <div
        className={styles['menuItem']}
        onClick={() => handleUploadAction()}
      >
        <Icon icon="upload" customSize={16} customColor="#8697A8" />
        <span className={styles['textItem']}>
          Fazer upload
        </span>
      </div>
    </div>
  )

  return (
    <div 
      className={styles['wrapper']}
      style={{
        maxWidth: maxWidth ? maxWidth : '312px',
        marginBottom: marginBottom ? marginBottom : '16px',
        marginTop: marginTop ? marginTop : '',
      }}
    >
      {(uploadButton && !AccessPermission.isEngOrConsult()) && (
        <Popover
          content={overlay} 
          placement="bottomLeft" 
          trigger="click"
          open={showUploadOptions} 
          onOpenChange={(visible) => firstLoading ? undefined : handleVisibleChange(visible)}
          overlayStyle={{position: 'absolute' , paddingTop: '0px'}}
        >
          <div 
            className={`
            ${styles['button']}  
            ${firstLoading ? styles['disabled'] : ''}
            `}
            style={{backgroundColor: `${firstLoading ? '' : theme.colors.primary.primary}`}}
          >
            <Icon icon="adicionar" color={firstLoading ? "cinzaOpaco" : "onPrimary"} size="xxs" />
          </div>
        </Popover>
      )}
      <div className={styles['inputWrapper']}>
        <span className={`${styles['icon']} ${iconClassName || ''}`}>
          <Icon icon="buscar" color={focus ? 'cinzaEscuro' : 'cinzaPadrao'} size="xxs" />
        </span>
        <input 
          type="text" 
          value={value}
          disabled={firstLoading}
          onChange={(e) => handleChangeSearch(e)}
          onFocus={(e) => setFocus(!!e)}
          onBlur={() => setFocus(false)}
          className={`
            ${styles['input']} 
            ${inputClassName || ''}
            ${!uploadButton ? styles['notUploadButton'] : ''}  
          `}
          placeholder={inputPlaceholder}
        />
      </div>
    </div>
  )
}

export default SearchViewerInput;
