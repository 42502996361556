import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgSearch {
    width: 44px;
    height: 44px;
    margin-bottom: 12px;
  }

  .title {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-bottom: 6px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }
`;
