import { Icon, IconName } from "Components/UI";
import Button from "Components/UI/Button";
import React, { FC } from "react";
import imgModels from "../../../../Static/images/empty-models.png";
import imgOthers from "../../../../Static/images/empty-models-other.png";
import styles from "./EmptyModels.module.scss";
import { IDrawerOptions } from "Pages/FederatedViewer/useFederatedViewer";

interface IEmptyModels {
  onPlay: () => void;
  isModelActive?: boolean;
  activeDrawer?: IDrawerOptions;
  hasUrns?: boolean;
}

const EmptyModels: FC<IEmptyModels> = ({
  onPlay,
  isModelActive,
  activeDrawer,
  hasUrns,
}) => {
  const getIcon = (): IconName => {
    if (activeDrawer === 'pranchas') {
      return 'filePile';
    }
    if (activeDrawer === 'viewpoints') {
      return 'viewPoint';
    }
    if (activeDrawer === 'views') {
      return 'docEye';
    }
    return 'menuHome';
  };

  return (
    <div 
      className={styles['wrapper']}
    >
      {!isModelActive && (
        <div className={styles['headerWrapper']}>
          <Icon 
            icon={getIcon()} 
            customColor={'#BEC2C6'}
            customSize={16}
          />
          <span>
            {activeDrawer}
          </span>
          {/* <Icon 
            icon="informacaoAdicional"
            customColor={'#BEC2C6'}
            customSize={14}
          /> */}
        </div>
      )}
      <div className={styles['mainWrapper']}>
        <img src={isModelActive ? imgModels : imgOthers} alt="" />
        <p className={styles['title']}>
          {hasUrns 
            ? `Nenhuma ${activeDrawer} disponível.` 
            : `Nenhum modelo ${isModelActive ? 'disponível' : 'ativo'}.`}
        </p>
        <p className={styles['text']}>
          {hasUrns 
            ? `Nenhuma ${activeDrawer} encontrada nos modelos ativos. Verifique se o modelo que você procura está ativado na aba ”Modelos”.` 
            : isModelActive 
              ? 'Não foi feito o upload de nenhum modelo no viewer. Para isso adicione um modelo através do botão +' 
              : `Para visualizar as ${activeDrawer} de um modelo, primeiro você precisa ativar esse modelo na aba “Modelos”.`
        }
        </p>
        <Button
          type="text"
          onClick={onPlay}
          className={styles['buttonVideo']}
        >
          <Icon 
            icon={isModelActive ? 'camera' : 'ccube'} 
            customColor={'#324150'}
            customSize={14}
          />
          <span>
            {isModelActive ? 'Assistir tutorial' : 'Ver modelos'}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default EmptyModels;
