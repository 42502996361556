const OPEN_ERROR = 'Erro ao abrir';
const LOAD_ERROR = 'Erro ao carregar';
const UPDATE_ERROR = 'Erro ao atualizar';
const CREATE_ERROR = 'Erro ao criar';
const CREATE_SUCCESS = 'Sucesso ao criar';
const UPDATE_SUCCESS = 'Sucesso ao editar';
const REQUISITION_SUCCESS = 'Sucesso na requisição de';
const REQUISITION_ERROR = 'Erro na requisição de';
const APROVE_SUCCESS = 'Sucesso na aprovação';
const REPROVE_SUCCESS = 'Sucesso na reprovação';
const APROVE_ERROR = 'Erro na aprovação';
const REPROVE_ERROR = 'Erro na reprovação';
export const SERVER_ERROR = 'Ocorreu um erro inesperado no nosso servidor.';
export const REPEATED_EMAILS_ERROR = 'Não é permitido emails repetidos.';
export const REPEATED_CONSTRUCTION_NAME_ERROR = 'Já existe uma obra com este nome.';
export const SEND_EMAIL_RESET_PASSWORD_MESSAGE = 'Enviamos um e-mail para você redefinir sua senha';
export const PASSWORD_CHANGED_SUCCESSFULLY = 'Senha Alterada com sucesso';
export const HAS_LOCKED_FILES_REQUEST_PLOT =
  'Alguns arquivos não foram inclusos na solicitação por estarem com status \'Bloqueado\'.'
export const HAS_LOCKED_FILES_REQUEST_PLOT_EXTENSION =
  'Existem arquivos selecionados com extensões não permitidas para solicitação de plotagem.';
const LIST_UNREAD_NOTIFICATION_ERROR = 'Erro ao listar notificações não lidas';
const LIST_NOTIFICATION_ERROR = 'Erro ao listar notificações não lidas';
const SET_NOTIFICATION_TO_READ = 'Erro ao marcar notificações como lidas';
const LIST_TENANT_OF_USER_ERROR = 'Erro ao listar obras do usuário';
export const REVENUE_ERROR = 'Erro ao listar plano';
export const LOAD_VERSION_FILE = 'Erro ao carregar histórico de versões do arquivo';

export const openError = (entityName: string) => {
  return `${OPEN_ERROR} ${entityName}`;
};

export const loadError = (entityName: string) => {
  return `${LOAD_ERROR} ${entityName}`;
};

export const updateError = (entityName: string) => {
  return `${UPDATE_ERROR} ${entityName}`;
};

export const createError = (entityName: string) => {
  return `${CREATE_ERROR} ${entityName}`;
};

export const updateSuccess = (entityName: string) => {
  return `${UPDATE_SUCCESS} ${entityName}`;
};

export const createSuccess = (entityName: string) => {
  return `${CREATE_SUCCESS} ${entityName}`;
};

export const requisitonSuccess = (entityName: string) => {
  return `${REQUISITION_SUCCESS} ${entityName}`;
};

export const requisitonError = (entityName: string) => {
  return `${REQUISITION_ERROR} ${entityName}`;
};

export const aproveSuccess = (entityName: string) => {
  return `${APROVE_SUCCESS} ${entityName}`;
};

export const aproveError = (entityName: string) => {
  return `${APROVE_ERROR} ${entityName}`;
};

export const reproveSuccess = (entityName: string) => {
  return `${REPROVE_SUCCESS} ${entityName}`;
};

export const reproveError = (entityName: string) => {
  return `${REPROVE_ERROR} ${entityName}`;
};

export const unreadNotificationError = () => {
  return LIST_UNREAD_NOTIFICATION_ERROR;
}

export const notificationError = () => {
  return LIST_NOTIFICATION_ERROR;
}

export const setNotificationToReadError = () => {
  return SET_NOTIFICATION_TO_READ;
}

export const listAllTenantOfUserError = () => {
  return LIST_TENANT_OF_USER_ERROR;
}