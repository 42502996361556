import { ColumnProps } from "antd/lib/table";
import ColumnHeader from "Components/UI/Table/ColumnHeader";
import { Menu, Tag } from 'antd';
import Select from '../../../Components/UI/Select';
import { GetAvatar } from '../../../Utils/generateThumbnail';
import StatusDropdown, { colorTag, colorText } from 'Components/UI/StatusDropdown';
import MoreActionDropdown from 'Components/UI/MoreActionDropdown';
import Tooltip from 'Components/UI/Tooltip';
import { ITenantSharedUsers } from "Data/interfaces/Tenant/ITenantUsersWithConstruction";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import ObrasItem from "./ObrasItem/ObrasItem";
import { IUserInfoResponse } from "Data/interfaces/MinhaConta/IUserInfoResponse";
import Button from "Components/UI/Button";
import styles from "./Usuarios.module.scss";

import iconLink from '../../../Static/images/icon-link.png';

interface IRoles {
  value: number;
  description: string;
  label: string;
}

export interface IColumnsInput {
  sortList: (sortOrder: boolean) => void;
  updateUserStatus: (request: ITenantSharedUsers) => void;
  customLoading: boolean;
  currentUserStatusEdit?: ITenantSharedUsers;
  userInfo?: IUserInforResponse;
  openObrasEPermissoes: (workToEdit: any) => void;
  minhaConta: IUserInfoResponse;
  ActiveUsers: any;
  hashEmailCopiado?: string;
  copyInviteLink: (hashEmail: any) => void;
  reSendInvite: (InvitedUserId: number) => void;
  reSendInviteLoading?: boolean;
  reSendInviteId?: number;
  setIsVisibleModalRemoverUsuario: (state: boolean) => void;
  setCurrentUserEdit: (state: []) => void;
  selectPaperUser: (userRemove: any, value: any) => void;
  roles: IRoles[];
  getSharedUserRoleName: (roleFk?: number) => string | undefined;
  RoleNewUser: any;
}

export const columnsTable: (input: IColumnsInput) => Array<ColumnProps<any>> = ({
  sortList,
  updateUserStatus,
  customLoading,
  currentUserStatusEdit,
  userInfo,
  openObrasEPermissoes,
  minhaConta,
  ActiveUsers,
  hashEmailCopiado,
  copyInviteLink,
  reSendInvite,
  reSendInviteLoading,
  reSendInviteId,
  setIsVisibleModalRemoverUsuario,
  setCurrentUserEdit,
  selectPaperUser,
  roles,
  getSharedUserRoleName,
  RoleNewUser
}) => [
    {
      title: (
        <ColumnHeader
          title={'Nome'}
          sorter={(sortOrder: boolean) => sortList(sortOrder)}
        />
      ),
      dataIndex: 'Nome',
      render: (text, record) => (
        <div className={styles['columnName']}>
          <div className={styles['columnNameIcon']}>
            <GetAvatar
              name={record.AvatarFileName || text}
              shape="circle"
              src={record?.User?.OriginalImageUrl}
              thumbSrc={record?.User?.ThumbnailImageUrl}
              thumbType="small"
              size={38}
            />
          </div>
          <div>
            <div className={`${styles['columnNameName']} ${(!record.isActive && !record.InvitedUserId) ? styles['columnNameNameInactive'] : ''}`}>
              {text}
            </div>
            <div className={`${styles['columnNameEmail']} ${(!record.isActive && !record.InvitedUserId) ? styles['columnNameEmailInactive'] : ''}`}>
              {record.InvitedUserEmail ? '' : record.Email}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <ColumnHeader
          title={'Status'}
          showOrder={false}
        />
      ),
      dataIndex: 'isActive',
      render: (text, record) => {
        const overlay = (
          <Menu className={styles['menuColumn']}>
            <Menu.Item
              onClick={() => {
                if (!record.isActive) {
                  updateUserStatus(record);
                }
              }}
              className={styles['tagItem']}
              key="resumo"
            >
              <Tag color={colorTag('ativo') || ''} className={styles['tag']}>
                <span style={{ color: colorText('ativo') }}>
                  Ativo
                </span>
              </Tag>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                if (record.isActive) {
                  updateUserStatus(record);
                }
              }}
              className={styles['tagItem']}
              key="id"
            >
              <Tag color={colorTag('inativo')} className={styles['tag']}>
                <span style={{ color: colorText('inativo') }}>
                  Inativo
                </span>
              </Tag>
            </Menu.Item>
          </Menu>
        );

        return (
          <div className={styles['columnStatus']}>
            <StatusDropdown
              text={text ? 'Ativo' : 'Inativo'}
              isInvited={record.InvitedUserEmail ? true : false}
              isAdmin={record.Role.TenantRoleId === 1}
              overlay={overlay}
              activeLoading={customLoading && (currentUserStatusEdit?.TenantUserId === record.TenantUserId)}
              disabled={userInfo?.CurrentRoleFk === 3}
            />
          </div>
        );
      },
    },
    {
      title: <ColumnHeader title={'Obras'} showOrder={false} />,
      dataIndex: 'ConstructionSites',
      render: (text, record) => {
        return (
          <div className={styles['columnObras']}>
            <ObrasItem
              openUsuariosEPermissoes={() => (record.isActive || record.InvitedUserId) ? openObrasEPermissoes(record) : undefined}
              disabled={!record.InvitedUserId && !record.isActive}
              currentUser={record}
              userIsAdmin={record?.Role?.TenantRoleId === 1}
              currentPeperUser={minhaConta.CurrentRoleFk}
            />
          </div>
        );
      },
    },
    {
      title: (
        <ColumnHeader
          title={'Papel'}
          showOrder={false}
          informacaoAdicional="Cada papel possui permissões específicas dentro do Coordly que garantem diferentes níveis de acesso aos usuários. Acesse ”papéis e permissões” no menu lateral."
        />
      ),
      dataIndex: 'papel',
      render: (index, record) => {
        return (
          <div className={styles['drowpDownSelector']}>
            {record?.InvitedUserId ? (
              <div className={styles['buttonRenviarContainer']}>
                <Tooltip
                  overlay={
                    hashEmailCopiado === record.TenantInviteHash
                      ? <label style={{ color: 'green' }}>Link copiado!</label>
                      : "Copiar link do convite"
                  }
                  placement="top"
                >
                  <Button
                    type="text"
                    disabled={minhaConta.CurrentRoleFk === 3}
                    className={styles['buttonCopiarLink']}
                    onClick={() => copyInviteLink(record.TenantInviteHash)}
                  >
                    <div style={{ fontSize: 12, display: 'grid', cursor: 'pointer' }}>
                      <img
                        src={iconLink}
                        alt="Copiar link de convite"
                        className={styles['iconeCopyLink']}
                      />
                    </div>
                  </Button>
                </Tooltip>
                <Button
                  type="primary"
                  disabled={minhaConta.CurrentRoleFk === 3}
                  className={styles['buttonRenviar']}
                  onClick={() => reSendInvite(record.InvitedUserId)}
                  loading={reSendInviteLoading && (reSendInviteId === record.InvitedUserId)}
                >
                  <span style={{ fontSize: 12 }}>Reenviar convite</span>
                </Button>
                <Button
                  disabled={minhaConta.CurrentRoleFk === 3}
                  className={styles['buttonCancelar']}
                  onClick={() => {
                    setIsVisibleModalRemoverUsuario(true);
                    setCurrentUserEdit(record);
                  }}
                >
                  <label>x</label>
                </Button>
              </div>
            ) : (
              ActiveUsers?.find(
                (user: any) =>
                  user.User?.Id === record?.Id && user.Role?.RoleName === 'Admin'
              )
                ? (
                  <Select
                    onSelect={(value) => selectPaperUser(record, value)}
                    className={styles['selectRole']}
                    placeholder="Selecione um papel"
                    defaultValue="Admin"
                    value="Admin"
                    disabled={true}
                    options={roles}
                  />
                ) : (
                  ActiveUsers?.find(
                    (user: any) =>
                      user.User?.Id === record?.Id && user.Role?.RoleName === 'Coordenador'
                  )
                    ? (
                      <Select
                        onSelect={(value) => selectPaperUser(record, value)}
                        className={styles['selectRoleDisabled']}
                        placeholder="Selecione um papel"
                        value={record?.Role?.RoleName || getSharedUserRoleName(
                          ActiveUsers?.find((user: any) => user.User?.Id === record?.Id)?.Role
                            .TenantRoleId
                        )}
                        defaultValue={RoleNewUser}
                        disabled={
                          ActiveUsers?.find(
                            (user: any) =>
                              user.User?.Id === minhaConta?.Id &&
                              user.Role?.RoleName === 'Admin'
                          )
                            ? false
                            : true
                        }
                        options={roles}
                      />
                    ) : (
                      !record.isActive
                        ? (
                          <Select
                            className={`${styles['selectRole']} ${(!record.isActive && !record.InvitedUserId) ? 'inactiveSelect' : ''}`}
                            placeholder="Selecione um papel"
                            defaultValue={record?.Role?.RoleName || getSharedUserRoleName(record?.Role?.TenantRoleId)}
                            disabled={true}
                            options={roles}
                          />
                        ) : (
                          <Select
                            onSelect={(value) => selectPaperUser(record, value)}
                            className={styles['selectRoleDisabled']}
                            placeholder="Selecione um papel"
                            value={record?.Role?.RoleName || getSharedUserRoleName(
                              ActiveUsers?.find((user: any) => user.User?.Id === record?.Id)?.Role
                                .TenantRoleId
                            )}
                            disabled={minhaConta.CurrentRoleFk === 3}
                            defaultValue={RoleNewUser}
                            options={roles}
                          />
                        )
                    )
                )
            )}
          </div >
        );
      },
    },
    {
      title: (
        <ColumnHeader title="" showOrder={false} />
      ),
      dataIndex: 'key',
      render: (text, record) => (
        record.Role.TenantRoleId !== 1 && (
          <div className={styles['columnMoreActions']}>
            <MoreActionDropdown
              isInvited={record.InvitedUserId ? true : false}
              isLoadingInvite={reSendInviteLoading}
              reSendInvite={() => reSendInvite(record.InvitedUserId)}
              isLoadingActive={customLoading}
              isLinkCopy={hashEmailCopiado === record.TenantInviteHash ? true : false}
              copyLink={() => copyInviteLink(record.TenantInviteHash)}
              cancelInvite={() => {
                setIsVisibleModalRemoverUsuario(true);
                setCurrentUserEdit(record);
              }}
              removeUser={() => {
                setIsVisibleModalRemoverUsuario(true);
                setCurrentUserEdit(record);
              }}
              isActive={record.isActive ? record.isActive : false}
              activeStatus={() => updateUserStatus(record)}
              disabled={userInfo?.CurrentRoleFk === 3}
            />
          </div>
        )
      ),
    },
  ];