import styled, { css, keyframes } from "styled-components";

const shimmer = keyframes`
  100% {
    transform: translateX(140%);
  }
`;

interface IMain {
  iscustom?: string;
}

export const Main = styled.span<IMain>`
  width: 100%;
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${({theme}) => theme.colors.surface.surface}00 0,
      ${({theme}) => theme.colors.surface.surface}80 50%,
      ${({theme}) => theme.colors.surface.surface}00
    );
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }

  ${({iscustom}) => iscustom === 'skeleton-custom' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.primary.onPrimary};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.primary.primary}00 0,
        ${({theme}) => theme.colors.primary.primary}80 50%,
        ${({theme}) => theme.colors.primary.primary}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}

  ${({iscustom}) => iscustom === 'skeleton-custom-light' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #F2F2F2;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.surface.surface}00 0,
        ${({theme}) => theme.colors.surface.surface}80 50%,
        ${({theme}) => theme.colors.surface.surface}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}

  ${({iscustom}) => iscustom === 'skeleton-custom-dark' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #E0E0E0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.surface.surface}00 0,
        ${({theme}) => theme.colors.surface.surface}80 50%,
        ${({theme}) => theme.colors.surface.surface}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}
  
  ${({iscustom}) => iscustom === 'skeleton-custom-dark1' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.surface.surfaceOutline}00 0,
        ${({theme}) => theme.colors.surface.surfaceOutline}80 50%,
        ${({theme}) => theme.colors.surface.surfaceOutline}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}

  ${({iscustom}) => iscustom === 'skeleton-custom-dark2' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.surface.surfaceOutline}00 0,
        ${({theme}) => theme.colors.surface.surfaceOutline}80 50%,
        ${({theme}) => theme.colors.surface.surfaceOutline}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}

  ${({iscustom}) => iscustom === 'skeleton-danger' && css`
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        ${({theme}) => theme.colors.danger.danger}00 0,
        ${({theme}) => theme.colors.danger.danger}80 50%,
        ${({theme}) => theme.colors.danger.danger}00
      );
      animation: ${shimmer} 1.5s infinite;
      content: '';
    }
  `}
`;
