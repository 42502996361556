import { ActivitiesApi } from 'Data/ActivitiesApi';
import { IListActivities } from 'Data/interfaces/Activities/IListActivities';
import { ITopicCreateResponse } from 'Data/interfaces/Activities/ITopicCreateResponse';
import { call, put } from 'redux-saga/effects';
import toastHandler from '../../Utils/toastHandler';
import {
  ActivitiesActions,
  ChangeActivityStatusAction,
  CreateActivityAction,
  DeleteActivityAction,
  DeleteAttachmentAction,
  DeleteAttachmentForCreateAction,
  ListActivitiesAction,
  ListActivitiesUpdateAction,
  ShareLinkActivityAction,
  UpdateActivityAction,
  UploadAttachmentAction,
} from './Activities.actions';
import { IUpdateActivitieResponse } from 'Data/interfaces/Activities/IUpdateActivityResponse';
import { IChangeStatusError } from 'Data/interfaces/Activities/IChangeStatus';
import { FilterApi } from 'Data/FilterApi';
import { UploadChunksAttachmentsApi } from 'Data/UploadChunksAttachments.api';
import { ITopicRequest } from 'Data/interfaces/Activities/ITopicRequest';

export function* listActivities({ payload }: ListActivitiesAction) {
  try {
    const request: ITopicRequest = {
      csId: payload.csId,
      filtersId: payload.filtersId,
    }
    const { data } = yield call(ActivitiesApi.listActivities, request);

    const { data: activities, info } = data;
    const dataAux = info?.dataAux || info;
    const filtersData = info?.filtersData || [];

    const response: IListActivities = {
      listActivities: activities,
      ...dataAux,
      stagesFeatureValidate: payload.stagesFeatureValidate,
      csId: Number(payload.csId),
      filtersData,
    };
    yield put(ActivitiesActions.listActivitiesSuccess(response));

  } catch (e: any) {
    const msg = 'Erro ao listar atividades';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.listActivitiesFailure(error));
    toastHandler.showError(error);
  }
}

export function* updateList({ payload }: ListActivitiesUpdateAction) {
  try {
    yield put(ActivitiesActions.listActivitiesUpdateSuccess(payload));

  } catch (e: any) {
    const msg = 'Erro ao trocar status da atividade';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.listActivitiesUpdateFailure(error));
  }
}

export function* createActivity({ payload }: CreateActivityAction) {
  try {
    const { data: { data, info } } = yield call(ActivitiesApi.createActivity, payload);

    const response: ITopicCreateResponse = {
      activity: data,
      ...info,
    };
    yield put(ActivitiesActions.createActivitySuccess(response));
    toastHandler.showSuccess('Atividade criada com sucesso');

  } catch (e: any) {
    const msg = 'Erro ao criar nova atividade';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.createActivityFailure(error));
    toastHandler.showError(error);
  }
}

export function* changeActivityStatus({ payload }: ChangeActivityStatusAction) {
  try {
    const { data: { success } } = yield call(ActivitiesApi.changeStatus, payload);

    yield put(ActivitiesActions.changeActivityStatusSuccess(success));

  } catch (e: any) {
    const msg = 'Erro ao atualizar status da atividade';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    const errorResponse: IChangeStatusError = {
      getEndContainer: payload.getEndContainer,
      error,
    };
    yield put(ActivitiesActions.changeActivityStatusFailure(errorResponse));
    toastHandler.showError(error);
  }
}

export function* updateActivity({ payload }: UpdateActivityAction) {
  try {
    const { data: { data } } = yield call(ActivitiesApi.updateActivity, payload);

    const response: IUpdateActivitieResponse = {
      ...payload,
      ...data,
    }
    yield put(ActivitiesActions.updateActivitySuccess(response));

  } catch (e: any) {
    const msg = 'Ocorreu um erro ao atualizar a atividade.';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.updateActivityFailure(error));
    toastHandler.showError(error);
  }
}

export function* deleteActivity({ payload }: DeleteActivityAction) {
  try {
    yield call(ActivitiesApi.deleteActivity, payload);

    yield put(ActivitiesActions.deleteActivitySuccess(payload));

  } catch (e: any) {
    const msg = 'Erro ao remover atividade';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.deleteActivityFailure(error));
    toastHandler.showError(error);
  }
}

export function* shareLinkActivity({ payload }: ShareLinkActivityAction) {
  try {
    const { data: { data } } = yield call(FilterApi.shareLinkActivity, payload);

    yield put(ActivitiesActions.shareLinkActivitySuccess(data.ShortUrl));

  } catch (e: any) {
    const msg = 'Erro ao gerar link';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.shareLinkActivityFailure(error));
    toastHandler.showError(error);
  }
}

export function* uploadAttachment({ payload }: UploadAttachmentAction) {
  try {
    yield call(UploadChunksAttachmentsApi.uploadChunkFiles, payload);
  } catch (e: any) {
    // console.log('error dentro sagas', {e})
  }
}

export function* deleteAttachmentForCreate({ payload }: DeleteAttachmentForCreateAction) {
  try {
    yield call(ActivitiesApi.deleteAttachmentForCreate, payload.fileId);

    yield put(ActivitiesActions.deleteAttachmentForCreateSuccess(payload));

  } catch (e: any) {
    const msg = 'Erro ao remover anexo';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.deleteAttachmentForCreateFailure(error));
    toastHandler.showError(error);
  }
}

export function* deleteAttachment({ payload }: DeleteAttachmentAction) {
  try {
    yield call(ActivitiesApi.deleteAttachment, payload.topicAttachmentId);

    yield put(ActivitiesActions.deleteAttachmentSuccess(payload));

  } catch (e: any) {
    const msg = 'Erro ao remover anexo';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(ActivitiesActions.deleteAttachmentFailure(error));
    toastHandler.showError(error);
  }
}
