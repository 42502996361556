import styled, { css } from "styled-components";
import { Dropdown, Menu } from "antd";

export const DropdownStyled = styled(Dropdown)`
  cursor: pointer;
`;

interface IDropdownNotification {
  multiple?: boolean;
}

export const DropdownNotification = styled(Menu) <IDropdownNotification>`
  width: 640px;
  max-width: 640px;
  height: auto;
  max-height: 400px;
  background: ${({theme}) => theme.colors.surface.surface};
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  overflow-x: hidden !important;
  padding: 0 !important;

  ${({ multiple }) => !multiple && css`
    width: 390px;
  `}
`;

export const ThereIsNotNotification = styled(Menu.Item)`
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important; 
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 30px 16px !important;
  cursor: default !important;
  color: ${({ theme }) => theme.colors.surface.onSurface} !important;
  padding: 0 19px !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.surface} !important;
  }
`;

export const Title = styled.span`
  height: 50px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.surface.onSurface};
  padding: 0 19px !important;
`;

export const NotificationItem = styled(Menu.Item)`
  width: 100%  !important;
  height: 44px !important;
  background-color: ${({theme}) => theme.colors.surface.surface} !important;
  transition: ease .2s all !important;
  padding: 0 19px !important;

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    .content {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .avatar {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
  
      .strong {
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: ease .2s all;
        cursor: pointer;
  
        .description {
          font-weight: 600;
          font-size: 12px !important;
          line-height: 16px;
          color: ${({theme}) => theme.colors.surface.onSurface};
          text-overflow: ellipsis;
        }
  
        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: red;
          margin-left: 8px;
        }
      }
  
      .name {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        margin-left: 8px;
      }
    }
  
    .small {
      height: 100%;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  }
`;

export const LinkStyled = styled(Menu.Item)`
  width: 100% !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  background-color: ${({theme}) => theme.colors.surface.surfaceContainer} !important;
  padding: 0 16px !important;
  cursor: default !important;

&:hover {
  background-color: ${({theme}) => theme.colors.surface.surfaceContainer} !important;
}

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.information.information};
    cursor: pointer !important;
  }
`;
