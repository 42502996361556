import { FC } from 'react';
import { Button } from 'antd';
import Ellipsis from '../../../Components/UI/Ellipsis';
import Tooltip from '../../../Components/UI/Tooltip';
import { GetAvatar, ThumbType } from '../../../Utils/generateThumbnail';
import { history } from '../../../Store';
import Badge from 'Components/UI/Badge'
import { NotificationActionEnum } from 'Data/enums/Notification';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import HighlighterWords from 'Components/UI/HighlightWords';
import styles from './ObrasItem.module.scss';

export interface IObrasItem {
  constructionSiteId: number;
  image?: string;
  thumbSrc?: string;
  thumbType?: ThumbType;
  name: string;
  data: string;
  openUsuariosEPermissoes: () => void;
  openConexaoServico: () => void;
  updateCount?: number;
  sharedUsers?: any[];
  isConnectedApi?: boolean;
  currentPeperUser?: number;
  notification: INotificationsMapAux[];
  search: { show: boolean, search?: string }
}

const ObrasItem: FC<IObrasItem> = ({
  constructionSiteId,
  image,
  thumbSrc,
  thumbType,
  name,
  data,
  openUsuariosEPermissoes,
  updateCount,
  sharedUsers,
  isConnectedApi,
  currentPeperUser,
  openConexaoServico,
  notification,
  search
}) => {
  const openObra = () => {
    if (isConnectedApi) {
      history.push(`/obras/detail/${constructionSiteId}`);
    } else {
      openConexaoServico();
    }
  };

  const displayUsers = () => {
    if (sharedUsers && sharedUsers?.length > 5) {
      const visibleUsers = sharedUsers.slice(0, 4);
      const users = sharedUsers.slice(4);
      const usersList = (
        <div className={styles['listSharedUsers']}>
          {users.map((user, index) => (
            <label key={`${user.User.Id}-${index}`}>{user.User.Nome}</label>
          ))}
        </div>
      );

      return visibleUsers
        .map((sharedUser, index) => (
          <Tooltip
            title={sharedUser.User.Nome}
            placement="bottom"
            key={`${sharedUser.User.Id}-${index}`}
          >
            <GetAvatar
              name={sharedUser.User.Nome}
              shape='circle'
              size={28}
              src={sharedUser.User?.OriginalImageUrl}
              thumbSrc={sharedUser.User?.ThumbnailImageUrl}
              thumbType="small"
            />
          </Tooltip>
        ))
        .concat(
          <Tooltip title={usersList} placement="bottom" key={`Tooltip-users-${users}`}>
            <Button shape="circle" type="default" className={styles['buttomListUser']}>
              +{users.length}
            </Button>
          </Tooltip>
        );
    }

    return sharedUsers?.map((sharedUser, index) => (
      <Tooltip
        title={sharedUser.User.Nome}
        placement="bottom"
        key={`${sharedUser.User.Id}-${index}`}
        className={styles[`${!isConnectedApi ? 'opacitySemConexao' : ''}`]}
      >
        <GetAvatar
          name={sharedUser.User.Nome}
          shape='circle'
          size={28}
          src={sharedUser.User?.OriginalImageUrl}
          thumbSrc={sharedUser.User?.ThumbnailImageUrl}
          thumbType="small"
        />
      </Tooltip>
    ));
  };

  const valueNotification = notification?.filter((obra, index, self) => obra.ConstructionSiteFk === constructionSiteId && self.findIndex(obraIndex => obraIndex.ItemId === obra.ItemId) === index).reduce((acc, cur) => {
    const allowedActions = [NotificationActionEnum.ArquivoMovido, NotificationActionEnum.NovoArquivo, NotificationActionEnum.NovaVersaoArquivo];

    if (allowedActions.includes(cur.Action) && !cur.hasRead) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const MAX_NAME_SIZE = 13;

  return (
    <div className={styles['obraItemWrapper']}>
      <div
        className={currentPeperUser === 1 || currentPeperUser === 7 ? styles['obraImg'] : styles['obraImgDisabled']}
        onClick={openObra}
        style={{ opacity: !isConnectedApi ? 0.25 : '' }}
      >
        <GetAvatar
          name={name}
          shape="square"
          fontSize={50}
          src={image}
          thumbSrc={thumbSrc}
          thumbType={thumbType}
        />
      </div>

      <div className={styles['obraName']} onClick={openObra}>
        {isConnectedApi ? (
          <>
            <div className={styles['wrapperNameAndBadge']}>
              <strong className={styles['name']}>
                <HighlighterWords
                  text={name.length > MAX_NAME_SIZE
                    ? `${name.slice(0, MAX_NAME_SIZE - 3)}...`
                    : name
                  }
                  maxLength={MAX_NAME_SIZE}
                  wordSearched={!search.search || !search.show ? '' : search.search}
                />
              </strong>
              {valueNotification > 0 ? <Badge type="square" value={valueNotification} /> : <></>}
            </div>
            <span>Última alteração {data}</span>
          </>
        ) : (
          <>
            <strong className={styles['obraNameSemConexao']}>
              <Ellipsis phrase={name} maxLength={13} />
              {updateCount && (<Badge type='square' value={updateCount} />)}
            </strong>
            <span className={styles['semConexao']}> Sem conexão</span>
          </>
        )}
      </div>

      <div data-testid="avatares-obra" className={styles['obraAvatar']}>
        {displayUsers()}
        <Tooltip
          title="Compartilhar Obra"
          placement="bottom"
          className={styles[`${!isConnectedApi ? 'opacitySemConexao' : ''}`]}
        >
          {currentPeperUser === 1 || currentPeperUser === 7 ? (
            <Button
              shape="circle"
              className={styles['buttomUser']}
              onClick={openUsuariosEPermissoes}
            >
              <label>+</label>
            </Button>
          ) : (
            <></>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default ObrasItem;
