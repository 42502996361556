import React, { FC } from "react";

import styles from "./LoadingNotification.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingNotification {
  multiple?: number;
}

const LoadingNotification: FC<ILoadingNotification> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (   
        <tr key={index.toString()} className={styles['rowTable']}>
          <td>
            <span className={styles['rowName']}>
              <SkeletonMain width="16px" height="16px" borderRadius="2px" marginLeft="12px" minWidth="16px"/>
              <SkeletonMain width="100%" height="10px" borderRadius="2px" marginLeft="12px" minWidth="146px" maxWidth="206px" />
              <SkeletonMain width="10px" height="10px" borderRadius="2px" marginLeft="12px" minWidth="10px"/>
              <SkeletonMain width="100%" height="10px" borderRadius="2px" marginLeft="12px" minWidth="146px" maxWidth="188px" />
            </span>
          </td>
          <td style={{ width: '' }}>
          <span className={styles['rowName']}>
              <SkeletonMain width="24px" height="24px" borderRadius="50%" marginLeft="12px" minWidth="24px" />
              <SkeletonMain width="100%" height="10px" borderRadius="2px" marginLeft="12px" minWidth="202px" />
            </span>
          </td>
          <td  style={{ width: '' }}>
            <SkeletonMain width="100%" height="10px" borderRadius="2px" minWidth="134px"/>
          </td>
        </tr>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      <table className={styles['renderTable']}>
        <thead className={styles['headTable']}>
          <tr>
            <th style={{ paddingLeft: '8px' }}>
              <ColumnHeader
                title="Notificação"
                showFilter={() => {}}
              />
            </th>
            <th style={{ width: '250px', paddingLeft: '10px' }}>
              <ColumnHeader
                title="Usuário"
                showFilter={()=>{}}
              />
            </th>
            <th style={{ width: '150px' }}>
              <ColumnHeader
                title="Data"
                showFilter={()=>{}}
              />
            </th>
          </tr>
        </thead>
        
        <tbody className={styles['rowTable']}>
          {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
        </tbody>
      </table>
    </>
  )
}

export default LoadingNotification;