import { FC, ReactNode, useState } from "react"
import { CardOption, Container, Content, Header, Main } from "./styles";
import Badge from "Components/UI/Badge";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

export type ActiveTabType = 'attachement' | 'comments' | 'history';

export type ITabMap = Map<ActiveTabType, ITab>;

export interface ITab {
  id: ActiveTabType;
  label: string;
  isDisabled: boolean;
  tagNumber?: number;
  children: ReactNode;
  customContentPadding?: string;
};

interface ITabs {
  defaultActiveTab: ActiveTabType;
  options: ITabMap;
  loadingExternalLink: boolean;
}

const Tabs: FC<ITabs> = ({
  defaultActiveTab,
  options,
  loadingExternalLink,
}) => {
  const theme = useSelector(getTheme);

  const [activeTab, setActiveTab] = useState<ActiveTabType>(defaultActiveTab);

  const handleActiveTab = (tabId: ActiveTabType) => {
    setActiveTab(tabId);
  };

  const optionsArray = Array.from(options);

  return (
    <Container>
      <Header>
        {optionsArray.map(([id, option]) => {
          const isActive = id === activeTab;

          return (
            <CardOption
              key={id}
              isactive={`${!loadingExternalLink && isActive}`}
              isdisabled={`${loadingExternalLink || option.isDisabled}`}
              onClick={() => (loadingExternalLink || option.isDisabled) ? undefined : handleActiveTab(id)}
            >
              <span className="cardOptionsText">{option.label}</span>
              {(!loadingExternalLink && option.tagNumber && option.tagNumber > 0) ? (
                <Badge
                  type="square"
                  value={option.tagNumber}
                  color={theme.colors.primary.primary}
                  style={{ marginLeft: '6px' }}
                />
              ) : null}
            </CardOption>
          )
        })}
      </Header>

      <Content style={{ padding: options.get(activeTab)?.customContentPadding }}>
        <Main>
          {options.get(activeTab)?.children}
        </Main>
      </Content>
    </Container>
  )
}

export default Tabs;
