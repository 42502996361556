import React, { FC } from 'react';
import { Drawer } from 'antd';
import ButtonDrawer from '../../../../Components/UI/ButtonDrawer';
import { Icon, IconName } from '../../../../Components/UI';
import styles from './FederatedDrawer.module.scss';
import './FederatedDrawer.css';
import { IDrawerOptions } from 'Pages/FederatedViewer/useFederatedViewer';

interface IFederatedDrawer {
  children: React.ReactNode;
  activeButton: IDrawerOptions;
  onActiveOption: (value: IDrawerOptions) => void;
  visible: boolean;
  onClose: () => void;
  onShow: () => void;
}

interface IDrawerButtons {
  nameId: IDrawerOptions;
  placement: string;
  icon: IconName;
  iconSize: number;
  customClass?: string;
}

const FederatedDrawer: FC<IFederatedDrawer> = ({
  children,
  activeButton,
  onActiveOption,
  visible,
  onClose,
  onShow,
}) => {


  const buttons: IDrawerButtons[] = [
    {
      nameId: 'models',
      placement: 'Modelos',
      icon: 'ccube',
      iconSize: 14,
    },
    {
      nameId: 'views',
      placement: 'Views',
      icon: 'docEye',
      iconSize: 14,
    },
    {
      nameId: 'viewpoints',
      placement: 'Viewpoints',
      icon: 'viewPoint',
      iconSize: 15,
    },
    {
      nameId: 'pranchas',
      placement: 'Pranchas',
      icon: 'filePile',
      iconSize: 12,
    },
  ];



  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => onClose()}
        visible={visible}
        getContainer={false}
        width={352}
        style={{ 
          position: 'absolute',
          top: 52,
          height: 'calc(100vh - 113px)',
          border: visible ? '1px solid #EAEDF0' : 'none',
          borderTop: visible ? 'none' : undefined,
          borderRight: visible ? '1px solid #EAEDF0' : undefined,
          padding: '24px 19px 0 19px !important'
        }}
        className={styles['drawer']}
        zIndex={2}
        mask={false}
      >
        {children}
      </Drawer>
      <ButtonDrawer
        onClick={() => visible ? onClose() : onShow()}
        style={{
          position: 'absolute',
          top: 76,
          left: visible ? 374 : 22,
          zIndex: 99,
        }}
        placement={visible ? "Fechar painél" : "Expandir painél"}
        border
        delay
      >
        <span className={styles['iconToggleWrap']} >
            <Icon icon="avancarDuplo"
              customSize={10}
              className={`${styles['iconChildren']} ${visible ? styles['iconActive'] : ''}`} 
            />
        </span>
      </ButtonDrawer>
      <div 
        className={styles['iconCardWrap']} 
        style={{
          position: 'absolute',
          top: 120,
          left: visible ? 366 : 14,
          zIndex: 99,
        }}
      >
        {buttons.map((button, index) => (
          <ButtonDrawer
            key={`${button.nameId}-${index}`}
            onClick={() => onActiveOption(button.nameId)}
            placement={button.placement}
          >
            <span className={styles['iconToggleWrap']} >
                <Icon 
                  icon={button.icon}
                  customSize={button.iconSize}
                  className={`${styles['iconCardChildren']} ${activeButton === button.nameId ? styles['iconActive'] : ''}`} 
                />
            </span>
          </ButtonDrawer>
        ))}
        <ButtonDrawer
          onClick={() => onActiveOption('activities')}
          placement="Atividades"
          hideHover
        >
          <span className={styles['iconToggleWrap']} >
              <Icon icon="activities"
                customSize={14}
                className={`
                  ${styles['iconCardChildren']} 
                  ${styles['iconCardChildrenActivie']}
                  ${activeButton === 'activities' ? styles['iconActive'] : ''}               
                `} 
              />
          </span>
        </ButtonDrawer>
      </div>
    </>
  );
}

export default FederatedDrawer;
