import React, { FC, useEffect, useCallback } from 'react';

import styles from './MoverArquivos.module.scss';
import { Icon } from '../../../Components/UI';

import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { IListFilesRequest } from '../../../Data/interfaces/ListagemArquivos/IListFilesRequest';
import { Skeleton } from 'antd';
import { IMoveFileRequest } from '../../../Data/interfaces/ListagemArquivos/IMoveFileRequest';
import { GetDisciplineApiFolderIdFromBreadCrumb } from 'Utils/ObraUtils';

export interface IFolderInfo {
  icon?: React.ReactNode;
  description?: string;
  href?: string;
}

export interface IMoverArquivos {
  folderId: string;
  breadCrumbId: string | null;
  toggleDropdown: () => void;
}

const MoverArquivos: FC<Props> = ({ toggleDropdown, constructionSiteId, listagemArquivos, folderId, breadCrumbId }) => {
  const dispatch = useDispatch();

  const searchFolder = useCallback((folderId: string, breadCrumbId?: string | number | null) => {
    if (folderId) {
      const request: IListFilesRequest = {
        constructionSiteId: constructionSiteId || 0,
        folderId,
        onlyFolder: true
      };

      if (breadCrumbId)
        request.breadCrumbId = breadCrumbId.toString();

      dispatch(ListagemArquivosActions.listFilesMoveTo(request));
    }
  }, [dispatch, constructionSiteId]);

  useEffect(() => {
    if (!listagemArquivos.ListFilesMoveTo.isLoading) {
      searchFolder(folderId, breadCrumbId);
    }
  }, []);

  const moveFiles = () => {

    if (listagemArquivos.BreadCrumbItem.Identifier !== listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.Identifier) {

      const filesId = listagemArquivos.selectedEditRowKeys;

      const request: IMoveFileRequest = {
        ConstructionSiteId: constructionSiteId || 0,
        CurrentFolderId: listagemArquivos.BreadCrumbItem.Identifier,
        FilesId: filesId,
        NewFolderId: listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.Identifier || '',
        DisciplineApiFolderId: GetDisciplineApiFolderIdFromBreadCrumb(listagemArquivos.BreadCrumbItem)
      };

      dispatch(ListagemArquivosActions.moveFile(request));
      toggleDropdown();
    }
  };

  return (
    <div className={styles['container']} >
      <div className={styles['header']} >
        {
          listagemArquivos.ListFilesMoveTo.isLoading
            ? <div className={styles['skeletonTitle']} >
              <Skeleton paragraph={false} active />
            </div>
            : (<>
              {
                listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.PreviousItem
                  ? (<span
                    className={styles['backButton']}
                    onClick={() =>
                      searchFolder(String(listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.PreviousItem?.Identifier),
                        listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.PreviousItem?.PreviousItem?.BreadCrumbItemId ||
                        listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.PreviousItem?.PreviousItem?.BreadCrumbItemIdDropbox)
                    }>
                    <Icon icon='voltar' color='branco' customSize={11} />
                  </span>)
                  : ''
              }

              <span className={styles['titleCurrentFolder']}>
                {listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.Name}
              </span>
            </>)
        }
        <span className={styles['closeButton']} onClick={toggleDropdown}>
          <Icon icon='error' color='branco' customSize={18} />
        </span>
      </div>
      <div className={styles['body']}>
        {
          listagemArquivos.ListFilesMoveTo.isLoading
            ? <div className={styles['skeletonFiles']} >
              <Skeleton paragraph={{ rows: 10, width: '50px' }} active />
            </div>
            : listagemArquivos.ListFilesMoveTo.ListFiles?.map((folder) => (
              <div className={styles['folderItem']} onClick={() => searchFolder(String(folder.Identifier.ApiId),
               listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.BreadCrumbItemId ||
               listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.BreadCrumbItemIdDropbox)}>
                <Icon icon='arquivar' color='cinzaPadrao' customSize={15} />
                <span className={styles['titleFolderItem']} >
                  {folder.Name}
                </span>
              </div>
            ))
        }
      </div>
      <div
        className={styles['footer']}
        onClick={moveFiles}>
        <span className={`${styles['moveFileButton']}
          ${(listagemArquivos.BreadCrumbItem.Identifier
            === listagemArquivos.ListFilesMoveTo.BreadCrumbItem?.Identifier)
            || listagemArquivos.ListFilesMoveTo.isLoading
            ? styles['disabled']
            : ''}`}
        >
          Mover aqui
        </span>
      </div>
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  listagemArquivos: state.listagemArquivos,
  constructionSiteId: state.listagemArquivos.Obra.ConstructionSiteId,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IMoverArquivos;

export default connector(MoverArquivos);
