import React, { FC, useEffect, useCallback } from 'react';
import styles from './PermissoesObrasTab.module.scss';
import PermissaoObra from './PermissaoObraItem';
import { IUserInfoResponse } from '../../../../../Data/interfaces/MinhaConta/IUserInfoResponse';

interface IObrasConvidadosTab {
  isSubmited?: boolean;
  onSubmit?: (props: any) => void;
  workToEdit?: any;
  currentUser?: IUserInfoResponse;
  changeWorks: (changedUser: any) => void;
  usuarioAtualSharedWorks?: any;
  combinedUsers?:any
}

const PermissoesObrasTab: FC<IObrasConvidadosTab> = ({ workToEdit, changeWorks, usuarioAtualSharedWorks, currentUser, combinedUsers }) => {
  const [worksToRender, setWorksToRender] = React.useState<any>(workToEdit);

  const changeSharedWorks = useCallback((
    privileges: number[],
    index: number,
    hasPlottingPermission: boolean
  ) => {
    let workIndexEdit = workToEdit.ConstructionSites.findIndex(
      (work: any) => work.ConstructionSiteId === index
    );

    worksToRender.ConstructionSites[workIndexEdit].Privileges = privileges;
    worksToRender.ConstructionSites[
      workIndexEdit
    ].HasPlottingPermission = hasPlottingPermission;
    setWorksToRender(JSON.parse(JSON.stringify(worksToRender)));
    changeWorks(worksToRender);
  },[workToEdit, worksToRender]);

  useEffect(() => {
    setWorksToRender(workToEdit);
  }, [workToEdit, changeWorks]);

  
  return (
    <div className={styles['PermissoesObrasTabWrapper']}>
      {workToEdit?.ConstructionSites?.map((work: any, index: any) =>
        work.IsShared === true &&  usuarioAtualSharedWorks.find(
          (x: any) => x.ConstructionSiteId === work.ConstructionSiteId
        )? (
          <PermissaoObra
            combinedUsers={combinedUsers}
            workToEdit={workToEdit}
            currentUser={currentUser}
            key={`PermissionInvitedUser-${index}`}
            index={work.ConstructionSiteId}
            username={work.ConstructionSiteName}
            privileges={work.Privileges}
            hasPlottingPermission={work.HasPlottingPermission}
            onChange={changeSharedWorks}
          />
        ) : (
          <></>
        )
      )}
    </div>
  );
};

export default PermissoesObrasTab;
