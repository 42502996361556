import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IUserInfoResponse, IUpdateUserToPremium } from './interfaces/MinhaConta/IUserInfoResponse';
import { INovaSenhaRequest } from './interfaces/MinhaConta/INovaSenhaRequest';
import { IUpdateUserPersonalDataRequest } from './interfaces/MinhaConta/IUpdateUserPersonalDataRequest';
import { IUpdateUserCompanyDataRequest } from './interfaces/MinhaConta/IUpdateUserCompanyDataRequest';

export class MinhaContaApi {
  static async getUserInfo(): Promise<AxiosResponse<IUserInfoResponse>> {
    const axios = await Http.axios();
    return axios.get('/Account/GetUserInfo');
  }

  static async notifyUserConversion(payload: IUpdateUserToPremium): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Account/NotifyUserConversion', payload);
  }

  static async novaSenha(payload: INovaSenhaRequest): Promise<AxiosResponse<INovaSenhaRequest>> {
    const axios = await Http.axios();
    return axios.put('/Account/AlterarSenha', payload);
  }

  static async updateDadosPessoais(payload: IUpdateUserPersonalDataRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put('/Account/UpdateUserPersonalData', payload);
  }

  static async updateDadosEmpresa(payload: IUpdateUserCompanyDataRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put('/Account/UpdateUserCompanyData', payload);
  }
}