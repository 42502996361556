import { FC, useEffect, useRef, useState } from 'react';
import PageHeader from '../../Components/UI/PageHeader/PageHeader';
import Table from '../../Components/UI/Table';
import BreadCrumb, { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import TableActionTopbar, {
  ITableActionTopbarButton
} from '../../Components/UI/TableActionTopbar';
import { ColumnProps } from 'antd/lib/table';
import { Icon, IconName } from '../../Components/UI';
import { IGlobalReducerState } from '../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { DisciplinaActions } from '../../Store/Disciplina/Disciplina.actions';
import { ListagemArquivosActions } from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import ActionMenu from './ActionMenu/';
import { Badge } from 'antd';
import { history } from '../../Store';
import { ApisEnumLabel, getIconApi } from '../../Data/enums/Apis';
import CadastroDisciplina from './CadastroDisciplina';
import toastHandler from '../../Utils/toastHandler';
import ColumnHeader from '../../Components/UI/Table/ColumnHeader';
import ExclusaoDisciplinas from './Modals/ExclusaoDisciplina';
import { IObraResponse } from '../../Data/interfaces/Obra/IObraListResponse';
import AccessPermission from '../../Utils/AcessPermission';
import { mapNotification } from 'Utils/ObraUtils';
import { NotificationActions } from 'Store/Notification/Notification.actions';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import LoadingDisciplinas from 'Components/UI/CustomLoading/LoadingDisciplinas';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { IListSaveOrder, saveOrderList } from 'Pages/ListagemArquivos/ListagemArquivos.helpers';
import AppStorage from '../../Utils/AppStorage';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import ModalBloquedSADownload from 'Components/UI/ModalBloquedSADownload';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { Mixpanel } from 'Utils/MixPanel';
import { DisciplineItem, TableWrapper } from './styles';
import TagIcon from 'Components/UI/TagIcon';
import ErrorSAConnection from './Modals/ErrorSAConnection';
import SolutionSAConnection from './Modals/SolutionSAConnection';
import { DisciplineFolderStatusEnum } from 'Data/interfaces/Obra/IDisciplineResponse';
import { IDisciplineSAStatusRequest } from 'Data/interfaces/Obra/IDisciplineSAStatusRequest';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import ReconnectIntegrationBox from 'Components/UI/ReconnectIntegrationBox';
import { Http } from 'Utils/Http';

const DisciplineIcons: IconName[] = [
  'disciplinaArquitetura',
  'disciplinaEstrutura',
  'disciplinaEletrica',
  'disciplinaHidrossanitaria',
  'disciplinaMecanica',
  'disciplinaFundacao',
  'disciplinaAr',
  'disciplinaIncendio',
  'menuContexto'
];

export enum DisciplineIconsEnum {
  'Arquitetura' = 'disciplinaArquitetura',
  'Estrutura' = 'disciplinaEstrutura',
  'Instalações Eletricas e Sistemas' = 'disciplinaEletrica',
  'Instalações Hidrossanitarias' = 'disciplinaHidrossanitaria',
  'Instalações Mecânicas' = 'disciplinaMecanica',
  'Fundações' = 'disciplinaFundacao',
  'Ar Condicionado' = 'disciplinaAr',
  'Incêndio' = 'disciplinaIncendio',
  'Engineer' = 'menuContexto',
  'Arquivos obsoletos' = 'obsoleteArchive',
}

const breadCrumbs: IBreadcrumbItem[] = [
  // {
  //   icon: 'menuHome',
  //   href: '/'
  // },
];

interface IDisciplians {
  match: any;
}

const Disciplinas: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const firstOpen = useRef(0);
  const [selectedDisciplinas, setSelectedDisciplinas] = useState([]);
  const [showErrorSAConnectionInfo, setShowErrorSAConnectionInfo] = useState(false);
  const [showSolutionSAConnection, setShowSolutionSAConnection] = useState(false);
  const [disciplineForSolution, setDisciplineForSolution] = useState<any>(null);
  const [sentSolutionSAConnection, setSentSolutionSAConnection] = useState(false);
  const [showCadastroDisciplina, setShowCadastroDisciplina] = useState(false);
  const [showModalExclusaoDisciplina, setShowModalExclusaoDisciplina] = useState(
    false
  );
  const [editedDisciplineId, setEditedDisciplineId] = useState<number>();
  const [editedDisciplineName, setEditedDisciplineName] = useState<string>();
  const [editedDisciplineIcon, setEditedDisciplineIcon] = useState<string>(
    'menuContexto'
  );

  const onChange = (selecteds: any) => setSelectedDisciplinas(selecteds);

  const IdNotification = mapNotification(props.notificationsMap);

  if (props.match.params.isNewConstructionSite === 'true') {
    toastHandler.showSuccess(
      'Obra criada com sucesso. Você já pode utilizar sua nova obra!'
    );
    props.match.params.isNewConstructionSite = false;
  }

  if (Http.GetQueryParams('SameAccountRequiredError') === 'true') {
    toastHandler.showError(
      'O email deve ser o mesmo da conta já conectada ao serviço de armazenamento.'
    );

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('SameAccountRequiredError');  // Remove the query parameter
    history.push({
      pathname: window.location.pathname,
      search: queryParams.toString() ? `?${queryParams.toString()}` : '',  // Update the URL
    });
  }

  useEffect(() => {
    if (
      firstOpen.current === 0
      && props.Obra
      && props.Obra.ConstructionSiteId === Number(props.match.params.constructionSiteId)
    ) {
      Mixpanel.track({
        name: 'PAGE_VIEW',
        props: {
          constructionSiteId: Number(props.match.params.constructionSiteId),
        },
        userInfo: props.userInfo,
        currentListTenant: currentTenant,
      });
      firstOpen.current++;
    }
  }, [props.Obra]);

  useEffect(() => {
    if (AppStorage.GetItem('listDisciplinesOrder') && !props.isLoading) {
      const listDisciplinesSaveOrder: IListSaveOrder = JSON.parse(AppStorage.GetItem('listDisciplinesOrder') ?? '');
      sortDisciplinesList(listDisciplinesSaveOrder.sortOrder)
    }
  }, [props.isLoading])

  useEffect(() => {
    if (!props.isLoading && props.Obra?.Disciplines?.length) {
      const disciplinesIds: string[] = props.Obra?.Disciplines.map((d: any) => d.DisciplineApiFolderId);
      const notificationsMarkAsView: INotificationsMapAux[] = [];

      props.notificationsMap
        .filter(notification => notification.ItemsAux?.length === 1 && notification.ConstructionSiteFk === props.Obra?.ConstructionSiteId)
        .forEach(notification => {
          if (!disciplinesIds.includes(notification.ItemParentId)) {
            notificationsMarkAsView.push(notification);
          }
        })

      if (notificationsMarkAsView.length > 0) {
        dispatch(NotificationActions.setNotificationAsView(notificationsMarkAsView));
      }
    }
  }, [props.Obra?.Disciplines]);

  useEffect(() => {
    if (!props.isLoading) {
      if (showCadastroDisciplina) {
        closeModalDiscipline();
      }
      setSelectedDisciplinas([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const search = history.location.search;
    dispatch(ObraActions.storeArchivesPath({
      ...props.match,
      url: `${pathname}${search}`,
    }));
  }, [props.match]);

  useEffect(() => {
    if (sentSolutionSAConnection && !props.isDisciplineSolutionLoading) {
      closeSoluctionSAConnection();
    }
  }, [props.isDisciplineSolutionLoading, sentSolutionSAConnection]);

  const editDiscipline = (id: number, name: string) => {
    setEditedDisciplineId(id);
    setEditedDisciplineName(name);
    setShowCadastroDisciplina(true);
  };

  const newDiscipline = () => {
    setEditedDisciplineId(undefined);
    setEditedDisciplineName(undefined);
    setShowCadastroDisciplina(true);
  };

  const closeModalDiscipline = () => {
    setShowCadastroDisciplina(false);
    setEditedDisciplineId(undefined);
    setEditedDisciplineName('');
  };

  const openModalExcluirDisciplina = (
    id: number,
    name: string,
    icon: string,
    folderId: string
  ) => {
    setEditedDisciplineId(id);
    setEditedDisciplineName(name);
    setEditedDisciplineIcon(icon);
    setShowModalExclusaoDisciplina(true);
    const request = {
      constructionSiteId: props.match.params.constructionSiteId,
      folderId: folderId
    };
    dispatch(DisciplinaActions.listFilesOnDisciplineRequest(request));
  };

  const removeFiles = () => {
    setShowModalExclusaoDisciplina(false);
    const request = {
      ConstructionSiteId: parseInt(props.match.params.constructionSiteId),
      ConstructionSiteDisciplineId: editedDisciplineId
    };
    dispatch(DisciplinaActions.deleteDisciplineRequest(request))
  };

  const openDisciplineOnCloud = () => {
    setShowModalExclusaoDisciplina(false);
    window.open(props.Obra?.CloudServiceUrl, '_blank');
  };

  const forceListDisciplinesUpdate = (constructionSiteId: number) => {
    const request: IUpdateDisciplineStatusRequest = {
      csId: constructionSiteId,
      ignoreCache: true
    }
    dispatch(DisciplinaActions.list(request));
  }

  const handleShowErrorSAConnectionInfo = () => {
    setShowErrorSAConnectionInfo(!showErrorSAConnectionInfo);
  }

  const getActionsToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        icon: getIconApi(props.Obra?.Api || 0),
        description: `Ver no ${ApisEnumLabel[props.Obra?.Api || 0]}`,
        hidden: !AccessPermission.downloadFiles(props.currentUserPrivileges) || AccessPermission.isEngOrConsult() || !props.Obra?.IsIntegrationAvailable,
        isBlocked: !props.Obra?.CloudServiceUrl,
        action: () => {
          if (props.Obra?.CloudServiceUrl) {
            window.open(props.Obra?.CloudServiceUrl, '_blank');

            Mixpanel.track({
              name: 'REDIRECT_SA_ACTION',
              props: {
                constructionSiteId: Number(props.match.params.constructionSiteId),
                api: props.Obra?.Api,
              },
              userInfo: props.userInfo,
              currentListTenant: currentTenant,
            });
          }
        },
        dropdownMenu: props.Obra?.CloudServiceUrl
          ? undefined
          : (toggleDropdown: () => void) => (
            <ModalBloquedSADownload
              platform={props.Obra?.Api || 0}
              onCancel={() => toggleDropdown()}
            />
          )
      },
      {
        icon: 'atualizar',
        description: 'Atualizar',
        hidden: selectedDisciplinas.length > 0 || !props.Obra?.IsIntegrationAvailable,
        action: () => forceListDisciplinesUpdate(parseInt(props.match.params.constructionSiteId)),
      },
      {
        icon: 'arquivar',
        description: 'Nova disciplina',
        hidden: selectedDisciplinas.length > 0 || !AccessPermission.newOrEditDiscipline(props.currentUserPrivileges) || !props.Obra?.IsIntegrationAvailable,
        action: () => newDiscipline(),
      },
      {
        icon: 'editar',
        description: 'Editar',
        hidden:
          selectedDisciplinas.length !== 1 || !AccessPermission.newOrEditDiscipline(props.currentUserPrivileges),
        action: () => {
          const index = selectedDisciplinas[0];
          const discipline = props.Obra?.Disciplines[index];
          editDiscipline(
            discipline.ConstructionSiteDisciplinesId,
            discipline.CustomName || discipline.Discipline.Name
          );
        },
      },
      {
        icon: 'excluir',
        hidden: selectedDisciplinas.length !== 1 || !AccessPermission.deleteDiscipline(),
        action: () => {
          const index = selectedDisciplinas[0];
          const discipline = props.Obra?.Disciplines[index];
          openModalExcluirDisciplina(
            discipline.ConstructionSiteDisciplinesId,
            discipline.CustomName || discipline.Discipline.Name,
            discipline.Discipline.Icon,
            discipline.DisciplineApiFolderId
          );
        },
      },
    ];
  };

  const sortDisciplinesList = (sortOrder: boolean) => {
    saveOrderList('listDisciplinesOrder', 'CustomName', sortOrder)

    const sortedDisciplines = [
      ...(props.Obra?.Disciplines.sort((a: any, b: any) => {
        const propA = a['CustomName'] || a['Discipline']['Name'] || '';
        const propB = b['CustomName'] || b['Discipline']['Name'] || '';

        if (typeof propA === 'number' && typeof propB === 'number') {
          return sortOrder ? propB - propA : propA - propB;
        }

        return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
      }) || [])
    ];

    const dataSortedDisciplines: IObraResponse = props.Obra ?? ({} as IObraResponse);
    dataSortedDisciplines.Disciplines = sortedDisciplines;

    dispatch(DisciplinaActions.listUpdate(dataSortedDisciplines));
  };

  const handleSoluctionSAConnection = (discipline: any) => {
    const request: IDisciplineSAStatusRequest = {
      ConstructionSiteDisciplineId: discipline.ConstructionSiteDisciplinesId,
      csId: discipline.ConstructionSiteFk,
      fileApiId: discipline.DisciplineApiFolderId,
    };
    dispatch(DisciplinaActions.statusSADisciplineRequest(request))
    setDisciplineForSolution(discipline);
    setShowSolutionSAConnection(true);
  }

  const closeSoluctionSAConnection = () => {
    setDisciplineForSolution(null);
    setShowSolutionSAConnection(false);
  }

  const confirmSoluctionSAConnection = (
    sent: boolean,
  ) => {
    setSentSolutionSAConnection(sent);
  }

  const columnsTable: Array<ColumnProps<any>> = [
    {
      title: (
        <ColumnHeader
          title="Nome"
          sorter={(sortDirection: boolean) => sortDisciplinesList(sortDirection)}
        />
      ),
      dataIndex: 'CustomName',
      render: (text, record) => {
        const findId = IdNotification.find(id => id === record.DisciplineApiFolderId);
        const errorConnection = DisciplineFolderStatusEnum.NotFound === record.FolderStatus;

        return (
          <ActionMenu
            disciplineId={record.DisciplineApiFolderId}
            editDiscipline={() =>
              editDiscipline(
                record.ConstructionSiteDisciplinesId,
                record.CustomName || record.Discipline.Name
              )
            }
          >
            <DisciplineItem
              error={errorConnection}
              onClick={() =>
                errorConnection
                  ? handleShowErrorSAConnectionInfo()
                  : history.push(
                    `/obras/filelist/${record.ConstructionSiteFk}/${record.DisciplineApiFolderId}?obraApi=${props.Obra?.Api}&folderName=${text ?? record.Discipline.Name}&ConstructionSiteDisciplinesId=${record.ConstructionSiteDisciplinesId}&DisciplineName=${record.CustomName || record.Discipline.Name}`
                  )
              }
            >
              <ErrorSAConnection
                platform={props.Obra?.Api || 0}
                userRole={props.userInfo?.CurrentRoleFk}
                onAction={() => handleSoluctionSAConnection(record)}
                triggered={showSolutionSAConnection || !errorConnection}
              >
                <Icon
                  error={errorConnection}
                  color="cinzaPadrao"
                  icon={DisciplineIcons[record.Discipline.DisciplineId - 1] || 'menuContexto'}
                  customSize={20}
                />
                <span className="disciplineItemText">
                  {text ?? record.Discipline.Name}
                </span>
                {findId ? <Badge color="#FF3D57" /> : null}
                {errorConnection ? (
                  <TagIcon
                    text="erro de conexão"
                    icon={<Icon icon="aviso" color="branco" customSize={12} />}
                    error={errorConnection}
                    onClick={handleShowErrorSAConnectionInfo}
                  />
                ) : null}
              </ErrorSAConnection>
            </DisciplineItem>
          </ActionMenu>
        )
      }
    },
  ];

  const rowSelection = {
    selectedDisciplinas,
    onChange
  };

  useEffect(() => {
    if (props.match.params.constructionSiteId) {
      const request: IUpdateDisciplineStatusRequest = {
        csId: props.match.params.constructionSiteId
      }
      dispatch(DisciplinaActions.list(request));
    }
    dispatch(ListagemArquivosActions.setShowPlotRequestTable(false));
  }, [props.match.params.constructionSiteId]);

  if (props.Obra) {
    breadCrumbs[0] = {
      href: `/obras/detail/${props.Obra.ConstructionSiteId}`,
      description: props.Obra.Name,
    };
    breadCrumbs[1] = {
      href: `/obras/detail/${props.Obra.ConstructionSiteId}`,
      description: 'Arquivos',
    };
  } else {
    breadCrumbs[0] = {
      href: '',
      isLoading: props.isLoading,
    };
    breadCrumbs[1] = {
      href: '',
      isLoading: props.isLoading,
    };
  }

  return (
    <>
      <TabsShell
        ConstructionSiteId={props.match.params.constructionSiteId}
        active={PathActiveEnum.detail}
        loading={props.isLoading}
        match={props.match}
      />
      <PageHeader name="Disciplinas">
        <BreadCrumb breadcrumbs={breadCrumbs} />
        <TableWrapper>
          <TableActionTopbar buttons={(props.Obra && getActionsToolbar()) || []} />
          {props.isLoading ? (
            <LoadingDisciplinas multiple={6} />
          ) : (
            !props.Obra?.IsIntegrationAvailable ?
              <ReconnectIntegrationBox
                integrationEmail={props.Obra?.IntegrationEmail!}
                serviceName='Google Drive'
                showReconnectButton={AccessPermission.isAdmin() || AccessPermission.isCoordenador()}
                csId={props.Obra?.ConstructionSiteId!}
                api={props.Obra?.Api!} />
              :
              <Table
                columns={columnsTable}
                dataSource={props.Obra?.Disciplines.map(
                  (discipline: any, index: number) => ({ ...discipline, key: index })
                )}
                isSpaced={true}
                rowSelection={rowSelection}
              />
          )}
        </TableWrapper>
      </PageHeader>
      <CadastroDisciplina
        loading={props.isLoading}
        constructionSiteId={props.Obra?.ConstructionSiteId || 0}
        id={editedDisciplineId}
        description={editedDisciplineName}
        visible={showCadastroDisciplina}
        onCancel={closeModalDiscipline}
      />
      <ExclusaoDisciplinas
        isLoading={props.isLoading}
        disciplineFiles={props.disciplineContainsFile}
        id={editedDisciplineId}
        icon={editedDisciplineIcon}
        constructionSiteId={props.Obra?.ConstructionSiteId || 0}
        name={editedDisciplineName}
        visible={showModalExclusaoDisciplina}
        onCancel={() => setShowModalExclusaoDisciplina(false)}
        onConfirmDelete={removeFiles}
        onConfirmOpenDiscipline={openDisciplineOnCloud}
      />
      {showSolutionSAConnection && disciplineForSolution && (
        <SolutionSAConnection
          platform={props.Obra?.Api || 0}
          visible={showSolutionSAConnection}
          onCancel={closeSoluctionSAConnection}
          onConfirm={confirmSoluctionSAConnection}
          isLoading={props.isDisciplineSolutionLoading}
          isLoadingStatus={props.isDisciplineStatusSALoading}
          discipline={disciplineForSolution}
          disciplineStatus={props.disciplineSAStatus}
          existingFolderApiId={props.existingFolderApiId}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.disciplina,
  notificationsMap: state.notification.notificationsMap,
  currentUserPrivileges: state.listagemArquivos.currentUserPrivileges,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IDisciplians;

export default connector(Disciplinas);
