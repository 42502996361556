import { FC, ReactNode } from 'react';
import { CustomTag } from './styles';

export interface ITagIcon {
  icon?: ReactNode;
  text?: string;
  error?: boolean;
  onClick?: () => void;
}

const TagIcon: FC<ITagIcon> = ({ icon, text, error, onClick }) => {
  return (
    <CustomTag error={error} onClick={onClick}>
      {icon}
      {text}
    </CustomTag>
  );
};

export default TagIcon;
