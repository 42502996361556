import React, { FC } from 'react';

import styles from './BreadCrumbViewer.module.scss';
import { Breadcrumb } from 'antd';
import { Icon, IconName } from '../Icon';

export interface IBreadcrumb {
  breadcrumbs: IBreadcrumbItem[];
}

export interface IBreadcrumbItem {
  description?: string;
  icon?: IconName;
  href: string;
}

const BreadCrumbViewer: FC<IBreadcrumb> = ({ breadcrumbs }) => {
  return (
    <Breadcrumb className={styles['breadcrumb']}
      separator={<Icon customSize={10} style={{ display: 'inline', color: '#8697A8' }} icon='avancar' />}>
    {
      breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb.Item href={breadcrumb.href} key={`breadCrumb${index}`}>
          {breadcrumb.icon ? <Icon customSize={15} style={{ display: 'inline' }} icon={breadcrumb.icon} /> : ''}
          {breadcrumb.description ? <span>{breadcrumb.description}</span> : ''}
        </Breadcrumb.Item>
      ))
    }
    </Breadcrumb>
  );
};

export default BreadCrumbViewer;
