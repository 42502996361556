import { Drawer } from "antd";
import styled, { css } from "styled-components";

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;

interface IDrawerContent {
  ismobile?: string;
}

export const DrawerContent = styled.div<IDrawerContent>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  background-color: ${({theme}) => theme.colors.surface.surface};

  .content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    .leftContent {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
  
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: ${({theme}) => theme.colors.surface.onSurface};
        margin-top: 32px;
      }
    
      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        margin-top: 12px;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &+.row {
        margin-top: 12px;
      }

      .rowLeft {
        display: flex;
        flex-direction: row;
        align-items: center;

        .rowIcon {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};

          img {
            width: 32px;
            height: 32px;
          }
        }

        .rowLabel {
          font-size: 14px;
          font-weight: 600;
          line-height: 19.07px;
          color: ${({theme}) => theme.colors.surface.onSurface};
          margin-left: 12px;
        }
      }

      .rowBtn {
        width: 91px;
        height: 36px;

        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  ${({ismobile}) => ismobile !== 'true' && css`
    .content {
      flex-direction: row;
    }
  `}
`;
