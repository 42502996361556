import { IListagemArquivosState } from './interfaces/IListagemArquivosState';
import {
  ListagemArquivosActionUnion,
  ListagemArquivosActionKeys
} from './ListagemArquivos.actions';
import {
  IBreadCrumbItem,
  IFileData
} from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IProgressDownloading } from '../../Data/interfaces/Obra/IDownloadFilesRequest';
import { IUnarchiveFilesRequest } from '../../Data/interfaces/Obra/IUnarchiveFilesRequest';
import { IObraData } from '../../Data/interfaces/Obra/IObraData';

const initialState: IListagemArquivosState = {
  ListFiles: [],
  BreadCrumbItem: {} as IBreadCrumbItem,
  isLoadingFileList: false,
  isLoadingRenameFile: false,
  filters: {
    showTipo: false,
    showVisualization: false,
    showStatus: false,
    showVersions: false,
    showModificado: {
      show: false,
      showDropdown: false,
      showData: false,
      showUsers: false
    }
  },
  editFormIndex: [],
  showEditTable: false,
  showPlotRequestTable: false,
  selectedEditRowKeys: [],
  selectedPlotRequestRowKeys: [],
  isLoading: false,
  showModalDeleteFiles: false,
  showModalCreateFolder: false,
  showModalInfoFiles: false,
  showModalDownloadMultipleFiles: false,
  hasFolderModalDownload: false,
  ObsoleteFolderId: undefined,
  ListFilesMoveTo: {},
  downloadFileList: undefined,
  progressDownload: {} as IProgressDownloading,
  ArquivosObsoletos: {} as IUnarchiveFilesRequest,
  Obra: {} as IObraData,
  loadObsoleteFiles: false,
  isLoadingFilesObsoleteList: false,
  currentUserPrivileges: undefined
};

const listagemArquivos = (
  state = initialState,
  action: ListagemArquivosActionUnion
): IListagemArquivosState => {
  switch (action.type) {
    case ListagemArquivosActionKeys.LIST_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingFileList: true,
        loadObsoleteFiles: false,
        ObsoleteFolderId: undefined
      };
    case ListagemArquivosActionKeys.LIST_FILES_SUCCESS:
      let files: IFileData[] = [];
      if (action.payload.isObsoleteFiles) {
        action.payload.Files?.forEach((file) => {
          files.push({ ...file, Versions: [] });
          file.Versions?.forEach((olderFiles) => files.push(olderFiles));
        });
      } else {
        files = action.payload.Files;
      }

      let filesList: IFileData[] = files;
      filesList.forEach((fileRow: IFileData) => {
        if (!fileRow.RenderingSignalR) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.FileInfo.ApiId
          };
        }
      });

      let newState = state;
      if (action.payload.BreadCrumbItem) {
        newState.BreadCrumbItem = action.payload.BreadCrumbItem;
      }
      if (action.payload.ObsoleteFolderId) {
        newState.ObsoleteFolderId = action.payload.ObsoleteFolderId;
      }
      if (action.payload.ConstructionSite) {
        newState.Obra = action.payload.ConstructionSite;
      }
      if (action.payload.CloudServiceFolderUrl) {
        newState.CloudServiceFolderUrl = action.payload.CloudServiceFolderUrl;
      }
      return {
        ...state,
        ...newState,
        ListFiles: filesList,
        isLoading: false,
        isLoadingFileList: false
      };
    case ListagemArquivosActionKeys.LIST_FILES_FAILED:
      return {
        ...state,
        ListFiles: [],
        isLoading: false,
        isLoadingFileList: false
      };

    case ListagemArquivosActionKeys.UPDATE_FILE_LIST:
      return {
        ...state,
        isLoading: false,
        ListFiles: action.payload
      };

    case ListagemArquivosActionKeys.ADD_OBRA_CHUNCK_DATA:
      return {
        ...state,
        Obra: { ...state.Obra, ...action.payload }
      };

    // DOWNLOAD
    case ListagemArquivosActionKeys.DOWNLOAD_FILES_REQUEST:
      return {
        ...state,
        downloadFileList: action.payload,
        isLoading: true,
        isDownloading: true
      };
    case ListagemArquivosActionKeys.DOWNLOAD_FILES_SUCCESS:
      const progressBar = {
        total: 1000,
        loaded: 0
      };
      return {
        ...state,
        isLoading: false,
        isDownloading: false,
        progressDownload: progressBar
      };
    case ListagemArquivosActionKeys.DOWNLOAD_FILES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isDownloading: false
      };
    case ListagemArquivosActionKeys.PROGRESS_DOWNLOAD:
      return {
        ...state,
        progressDownload: action.payload
      };

    case ListagemArquivosActionKeys.DELETE_FILES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ListagemArquivosActionKeys.DELETE_FILES_SUCCESS:
      action.payload.FilesId.forEach((id) => {
        const index = state.ListFiles.findIndex((file) => file.Identifier.ApiId === id);
        if (
          action.payload.RestoreObsoleteFiles?.length &&
          state.ListFiles[index].Versions &&
          state.ListFiles[index].Versions?.length
        ) {
          const lastFile = state.ListFiles[index].Versions?.splice(0, 1)[0];
          if (lastFile) {
            lastFile.Versions = state.ListFiles[index].Versions;
            state.ListFiles[index] = lastFile;
          }
        } else {
          state.ListFiles.splice(index, 1);
        }
      });
      return {
        ...state,
        ListFiles: [...state.ListFiles],
        selectedEditRowKeys: [],
        isLoading: false
      };
    case ListagemArquivosActionKeys.DELETE_FILES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case ListagemArquivosActionKeys.UPDATE_FILE_INFO_REQUEST:
      return {
        ...state,
        isLoadingRenameFile: true,
        isLoading: true
      };
    case ListagemArquivosActionKeys.UPDATE_FILE_INFO_SUCCESS:
      action.payload.forEach((editFileInfo) => {
        const fileEditedInfo = state.ListFiles.find(
          (file) => file.Identifier.ApiId === editFileInfo.ApiId
        );
        if (fileEditedInfo) {
          fileEditedInfo.FileInfo = editFileInfo;
          fileEditedInfo.Status = editFileInfo.Status;
        }
      });
      return {
        ...state,
        isLoadingRenameFile: false,
        isLoading: false
      };
    case ListagemArquivosActionKeys.UPDATE_FILE_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingRenameFile: false,
        isLoading: false
      };

    case ListagemArquivosActionKeys.RENAME_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingRenameFile: true
      };
    case ListagemArquivosActionKeys.RENAME_FILE_SUCCESS:
      let listFile = [...state.ListFiles];
      let fileResponse = action.payload;
      let selectedEditRowKeysAux = [...state.selectedEditRowKeys];
      let newFileResponse = {} as IFileData;

      const fileEdited = listFile.find(
        (file) => file.Identifier.ApiId === fileResponse.FileApiId
      );

      const indexFileExist = listFile.findIndex(
        (file) =>
          file.NameWithoutRevision === fileResponse.NameWithoutRevision &&
          file.Identifier.ApiId !== fileResponse.FileApiId
      );

      const indexFileResponse = listFile.findIndex(
        (file) => file.Identifier.ApiId === fileResponse.FileApiId
      );

      if (fileEdited && fileEdited.Name !== fileResponse.Name) {
        fileEdited.Name = fileResponse.Name;
        fileEdited.NameWithoutRevision = fileResponse.NameWithoutRevision;
        fileEdited.HasInconsistencia = false;
        fileEdited.Revision = fileResponse.Revision;
        fileEdited.RevisionNumber = fileResponse.RevisionNumber;

        if (fileEdited.Versions !== null) {
          if (indexFileExist > -1) {
            const fileExist = listFile[indexFileExist];
            let fileUpdateIndex: number;
            let fileRemoveIndex: number;
            let fileRemoveKey: string;

            if (fileExist.RevisionNumber > fileResponse.RevisionNumber) {
              fileExist.Versions?.push({
                ...fileExist,
                Identifier: {
                  ...fileExist.Identifier,
                  ApiId: fileResponse.FileApiId
                },
                Name: fileResponse.Name,
                Revision: fileResponse.Revision,
                NameWithoutRevision: fileResponse.NameWithoutRevision,
                RevisionNumber: fileResponse.RevisionNumber
              });
              newFileResponse = fileExist;

              fileRemoveIndex = indexFileResponse;
              fileUpdateIndex = indexFileExist;
            } else {
              const newVersions: IFileData[] = fileExist.Versions ?? [];
              newVersions.push(fileExist);
              newFileResponse.Versions = newVersions;

              fileUpdateIndex = indexFileResponse;
              fileRemoveIndex = indexFileExist;
              fileRemoveKey =
                listFile[indexFileExist].Identifier.ApiId ||
                listFile[indexFileExist].Identifier.ItemId;
            }

            listFile.splice(fileUpdateIndex, 1, newFileResponse);
            listFile.splice(fileRemoveIndex, 1);

            selectedEditRowKeysAux = [...state.selectedEditRowKeys].filter(
              (x) => x !== fileRemoveKey
            );
          }
        }
      }

      return {
        ...state,
        ListFiles: listFile,
        selectedEditRowKeys: selectedEditRowKeysAux,
        isLoading: false,
        isLoadingRenameFile: false
      };
    case ListagemArquivosActionKeys.RENAME_FILE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadingRenameFile: false
      };

    case ListagemArquivosActionKeys.ADD_NEW_FILE:
      let list: IFileData[] = [];
      let newFile = action.payload;

      if (newFile.FileIdToOverwrite) {
        const listFiles = [...state.ListFiles];
        const indexToOverwrite = listFiles?.findIndex(
          (x) => x?.Identifier?.ApiId === newFile?.FileIdToOverwrite
        );
        listFiles[indexToOverwrite] = newFile;
        list = listFiles;
      } else {
        const listFiles = [...state.ListFiles];

        if (newFile.hasRevisionControl && newFile.RevisionNumber) {
          const index = listFiles.findIndex(
            (file) => file.NameWithoutRevision === newFile.NameWithoutRevision
          );

          if (index > -1) {
            let fileExist = listFiles[index];

            if (fileExist.RevisionNumber > newFile.RevisionNumber) {
              fileExist.Versions?.push(newFile);
              newFile = fileExist;
            } else {
              const newVersions: IFileData[] = fileExist.Versions ?? [];
              newVersions.push(fileExist);
              newFile.Versions = newVersions;
            }

            listFiles.splice(index, 1);
          }
        }

        list = [...listFiles, newFile];
      }
      return {
        ...state,
        ListFiles: list,
        isLoading: false
      };

    case ListagemArquivosActionKeys.SET_SHOW_EDIT_TABLE:
      return {
        ...state,
        showEditTable: action.payload
      };

    case ListagemArquivosActionKeys.SET_SHOW_PLOT_REQUEST_TABLE:
      return {
        ...state,
        showPlotRequestTable: action.payload
      };

    case ListagemArquivosActionKeys.SET_SHOW_MODAL_DELETE_FILES:
      return {
        ...state,
        showModalDeleteFiles: action.payload
      };

    case ListagemArquivosActionKeys.SET_SHOW_MODAL_CREATE_FOLDER:
      return {
        ...state,
        showModalCreateFolder: action.payload
      };
    case ListagemArquivosActionKeys.SET_SHOW_MODAL_INFO_FILES:
      return {
        ...state,
        showModalInfoFiles: action.payload
      };
    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_TIPO:
      return {
        ...state,
        filters: {
          ...state.filters,
          showTipo: action.payload
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VISUALIZATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          showVisualization: action.payload
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showStatus: action.payload
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VERSIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showVersions: action.payload
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO:
      return {
        ...state,
        filters: {
          ...state.filters,
          showModificado: {
            show: action.payload.show,
            showDropdown: action.payload.showDropdown,
            showData: false,
            showUsers: false
          }
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          showModificado: {
            show: true,
            showDropdown: false,
            showData: action.payload,
            showUsers: false
          }
        }
      };

    case ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_USERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showModificado: {
            show: true,
            showDropdown: false,
            showData: false,
            showUsers: action.payload
          }
        }
      };

    case ListagemArquivosActionKeys.SET_EDIT_FORM_INDEX:
      let newEditArray: number[] = [];
      if (state.editFormIndex.includes(action.payload) === true) {
        newEditArray = state.editFormIndex.filter((item) => item !== action.payload);
      }
      if (state.editFormIndex.includes(action.payload) === false) {
        newEditArray = [...state.editFormIndex, action.payload];
      }
      return {
        ...state,
        editFormIndex: newEditArray
      };

    case ListagemArquivosActionKeys.SET_SELECTED_EDIT_ROW_KEYS:
      return {
        ...state,
        selectedEditRowKeys: [...action.payload]
      };

    case ListagemArquivosActionKeys.SET_SELECTED_PLOT_REQUEST_ROW_KEYS:
      return {
        ...state,
        selectedPlotRequestRowKeys: action.payload
      };

    case ListagemArquivosActionKeys.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ListagemArquivosActionKeys.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        ListFiles: [action.payload, ...state.ListFiles],
        showModalCreateFolder: false,
        isLoading: false
      };
    case ListagemArquivosActionKeys.CREATE_FOLDER_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_REQUEST:
      return {
        ...state,
        ListFilesMoveTo: { ...state.ListFilesMoveTo, isLoading: true }
      };
    case ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_SUCCESS:
      return {
        ...state,
        ListFilesMoveTo: {
          ...state.ListFilesMoveTo,
          ListFiles: action.payload.Files,
          BreadCrumbItem: action.payload.BreadCrumbItem,
          isLoading: false
        }
      };
    case ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_FAILED:
      return {
        ...state,
        ListFilesMoveTo: {
          ...state.ListFilesMoveTo,
          isLoading: false,
          error: action.payload
        }
      };
    case ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_CLEAR:
      return {
        ...state,
        isLoadingFileList: true,
        ListFilesMoveTo: {
          ListFiles: undefined,
          isLoading: false
        }
      };

    case ListagemArquivosActionKeys.MOVE_FILE_REQUEST:
      return {
        ...state,
        isLoadingFileList: true,
        ListFilesMoveTo: { ...state.ListFilesMoveTo, isLoading: true }
      };
    case ListagemArquivosActionKeys.MOVE_FILE_SUCCESS:
      const newFileListMoveFiles = [...state.ListFiles];

      action.payload.FilesId.forEach((fileId) => {
        const index = newFileListMoveFiles.findIndex(
          (file) => file.Identifier.ApiId === fileId
        );
        newFileListMoveFiles.splice(index, 1);
      });

      return {
        ...state,
        isLoadingFileList: false,
        ListFiles: newFileListMoveFiles,
        selectedEditRowKeys: [],
        ListFilesMoveTo: {
          ...state.ListFilesMoveTo,
          isLoading: false
        }
      };
    case ListagemArquivosActionKeys.MOVE_FILE_FAILED:
      return {
        ...state,
        isLoadingFileList: false,
        error: action.payload,
        ListFilesMoveTo: {
          ...state.ListFilesMoveTo,
          isLoading: false,
          error: action.payload
        }
      };

    case ListagemArquivosActionKeys.UNARCHIVE_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingFileList: true
      };
    case ListagemArquivosActionKeys.UNARCHIVE_FILES_SUCCESS:
      const newlistFiles = [...state.ListFiles];
      action.payload.FilesId.forEach((FileId: string) => {
        const index = newlistFiles.findIndex((file) => file.Identifier.ApiId === FileId);
        newlistFiles.splice(index, 1);
      });
      return {
        ...state,
        ListFiles: newlistFiles,
        selectedEditRowKeys: [],
        isLoadingFileList: false,
        isLoading: false
      };
    case ListagemArquivosActionKeys.UNARCHIVE_FILES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadingFileList: false
      };

    case ListagemArquivosActionKeys.SET_FILE_STATUS_RENDERING:
      let newFileList: IFileData[] = state.ListFiles;
      newFileList.forEach((fileRow: IFileData) => {
        if (
          fileRow.FileInfo?.ApiId === action.payload.FileApiId ||
          fileRow.Identifier.ApiId === action.payload.FileApiId
        ) {
          fileRow.RenderingStatus = 2;
        }

        if (!fileRow.RenderingSignalR) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.FileInfo.ApiId
          };
        }
      });
      return {
        ...state,
        ListFiles: [...newFileList]
      };

    case ListagemArquivosActionKeys.SET_FILE_STATUS_RERENDERING:
      let newList: IFileData[] = state.ListFiles;
      newList.forEach((fileRow: IFileData) => {
        if (
          fileRow.FileInfo?.ApiId === action.payload.FileApiId ||
          fileRow.Identifier.ApiId === action.payload.FileApiId
        ) {
          fileRow.RenderingStatus = 2;
        }

        if (fileRow.RenderingSignalR.etapa === 5) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.FileInfo.ApiId
          };
        }
      });

      return {
        ...state,
        ListFiles: [...newList]
      };

    case ListagemArquivosActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE:
      let renderingStatus = action.payload;
      let fileItem: IFileData | undefined;

      if (state.ListFiles) {
        fileItem = state.ListFiles.find(
          (file) =>
            file.Identifier.ApiId === renderingStatus.fileIdentifier ||
            file.Identifier.ItemId === renderingStatus.fileIdentifier ||
            file.FileInfo?.ApiId === renderingStatus.fileIdentifier
        );

        if (fileItem) {
          if (!fileItem.RenderingSignalR) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              etapa: 1,
              progress: 0
            };
          }

          if (
            renderingStatus.etapa > fileItem.RenderingSignalR.etapa ||
            (renderingStatus.etapa === fileItem.RenderingSignalR.etapa &&
              renderingStatus.progress > fileItem.RenderingSignalR.progress)
          ) {
            fileItem.RenderingSignalR = {
              ...renderingStatus
            };
            fileItem.RenderingStatus = 2;
          }

          if (renderingStatus.etapa === 4) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              etapa: 0,
              progress: 0
            };
            fileItem.RenderingStatus = 1;
          }

          if (renderingStatus.etapa === 5) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              progress: 0
            };
            fileItem.RenderingStatus = 3;
          }
        }
      }
      return {
        ...state,
        ...fileItem
      };

    case ListagemArquivosActionKeys.SET_SHOW_MODAL_DOWNLOAD_MULTIPLE_FILES:
      return {
        ...state,
        showModalDownloadMultipleFiles: action.payload.isVisible,
        hasFolderModalDownload: action.payload.hasFolder || false
      };

    case ListagemArquivosActionKeys.SET_UPLOAD_PROGRESS_RESPONSE:
      let uploadList = state.ListFiles;
      let uploadRenderingStatus = action.payload;

      const fileUpload = uploadList.find(
        (fileRow: IFileData) =>
          fileRow.Identifier.ApiId === uploadRenderingStatus.fileIdentifier ||
          fileRow.Identifier.ItemId === uploadRenderingStatus.fileIdentifier
      );

      if (fileUpload) {
        fileUpload.UploadRenderingStatus = {
          fileIdentifier: uploadRenderingStatus.fileIdentifier,
          progress: uploadRenderingStatus.progress,
          archiveStatus: uploadRenderingStatus.archiveStatus
        };
      }

      return {
        ...state,
        ...fileUpload
      };

    case ListagemArquivosActionKeys.FILES_VERSION_REQUEST:
      return {
        ...state,
        isLoadingFilesObsoleteList: true
      };
    case ListagemArquivosActionKeys.FILES_VERSION_SUCCESS:
      let newFilesList: IFileData[] = action.payload.Files;
      newFilesList.forEach((fileRow: IFileData) => {
        if (!fileRow.RenderingSignalR) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.FileInfo.ApiId
          };
        }
      });

      let newFilesState = state;
      if (action.payload.BreadCrumbItem) {
        newFilesState.BreadCrumbItem = action.payload.BreadCrumbItem;
      }
      if (action.payload.ObsoleteFolderId) {
        newFilesState.ObsoleteFolderId = action.payload.ObsoleteFolderId;
      }
      if (action.payload.ConstructionSite) {
        newFilesState.Obra = action.payload.ConstructionSite;
      }
      if (action.payload.CloudServiceFolderUrl) {
        newFilesState.CloudServiceFolderUrl = action.payload.CloudServiceFolderUrl;
      }
      return {
        ...state,
        ...newFilesState,
        ListFiles: newFilesList,
        isLoadingFilesObsoleteList: false,
        loadObsoleteFiles: false
      };
    case ListagemArquivosActionKeys.FILES_VERSION_FAILED:
      return {
        ...state,
        isLoadingFilesObsoleteList: false,
        error: action.payload
      };

    case ListagemArquivosActionKeys.LOAD_OBSOLETE_FILES:
      return {
        ...state,
        loadObsoleteFiles: action.payload
      };

    case ListagemArquivosActionKeys.CURRENT_USER_PRIVILEGES:
      return {
        ...state,
        currentUserPrivileges: action.payload
      };

    default:
      return state;
  }
};

export default listagemArquivos;
