import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  width: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .iconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
    margin: 6px 0;

    .iconLeft {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }
`;

interface ILine {
  height: number;
  istransparent?: string;
}

export const Line = styled.div<ILine>`
  width: 1px;
  height: calc(${({ height }) => height}px - 15px);
  background-color: ${({ istransparent, theme }) => istransparent === 'true'
    ? 'transparent'
    : theme.colors.surface.surfaceOutline};
`;

export const Right = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3px 0 3px 12px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .rightHeaderLeftText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-right: 4px;
    word-break: break-all; 
    overflow: hidden;

    .name {
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
      margin-left: 4px;
    }
    
    .action {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
      margin-left: 2px;
    }
    
    .customAction {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
    }
  }

  .date {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
    white-space: nowrap;
  }
`;

export const RightContent = styled.div``;
