import styled from "styled-components"

export const ButtonsWrapper = styled.div`
  .buttonCancel {
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  .buttonSubmit {
    border-radius: 5px;
  }
`;

export const Wrapper = styled.div`
  padding-top: 24px;
  background: ${({theme}) => theme.colors.surface.surface};
  border-radius: 10px !important;
  overflow: hidden;

  .midleHr {
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    margin-bottom: 4px;

    .titleHeader {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }

    .titleHeaderBold {
      color: ${({theme}) => theme.colors.surface.onSurface};
    }
    
    .multipleIconHeader {
      display: flex;
      flex-direction: row;
      align-items: center;

      .updateStatus {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .updateStatusSpan {
          display: flex;
          flex-direction: row;
          align-items: center;

          .updateStatusTextLoading {
            color: ${({theme}) => theme.colors.primary.primary};
            font-size: 12px;
            line-height: 16.34px;
            font-weight: 600;
            margin-left: 8px;
            margin-right: 6px;
          }
          .updateStatusTextSave {
            color: ${({theme}) => theme.colors.success.success};
            font-size: 12px;
            line-height: 16.34px;
            font-weight: 600;
            margin-right: 6px;
          }
          .updateStatusTextError {
            color: ${({theme}) => theme.colors.danger.danger};
            font-size: 12px;
            line-height: 16.34px;
            font-weight: 600;
            margin-right: 6px;
          }
        }
      }
    }

    .iconHeaderAlarm {
      cursor: pointer;
    }

    .iconHeader {
      padding: 5px;
      background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
      border-radius: 5px;
      margin-left: 6px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
      }

      .tooltipLink {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .bodyForm {

    .bodyDefault {
      display: flex;
      align-items: center;
    }

    .bodyDescription {
      display: flex;
      align-self: flex-start;
    }
  }
`;

export const TooltipLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VerticalHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  height: auto;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
`;

export const LeftContent = styled.div`
  padding: 0 24px;
  padding-bottom: 16px;
  width: 400px;
`;

export const RightContent = styled.div`
  width: 454px;
`;

export const FooterHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 0 20px;
`;
