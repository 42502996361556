import { IUpdateUserCompanyDataRequest } from '../../Data/interfaces/MinhaConta/IUpdateUserCompanyDataRequest';
import { IUpdateUserPersonalDataRequest } from '../../Data/interfaces/MinhaConta/IUpdateUserPersonalDataRequest';
import { IUserInfoResponse } from '../../Data/interfaces/MinhaConta/IUserInfoResponse';
import { IMinhaContaState } from './interfaces/IMinhaContaState';
import { MinhaContaActionUnion, MinhaContaActionKeys } from './MinhaConta.actions';

const initialState: IMinhaContaState = {
  isLoading: false,
  showModalUpdateConta: false,
  showModalUpdatePassword: false,
  showModalUpdateEmpresa: false,
  showModalUpdatePremium: false,
  showModalConfirmUpdatePremium: false,
  userInfo: {} as IUserInfoResponse,
  UpdateDadosPessoais: {} as IUpdateUserPersonalDataRequest,
  UpdateDadosEmpresa: {} as IUpdateUserCompanyDataRequest,
};

const minhaConta = (state = initialState, action: MinhaContaActionUnion): IMinhaContaState => {
  switch (action.type) {
    case MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_CONTA:
      return {
        ...state,
        showModalUpdateConta: action.payload
      };

    case MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PASSWORD:
      return {
        ...state,
        showModalUpdatePassword: action.payload
      };

    case MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_EMPRESA:
      return {
        ...state,
        showModalUpdateEmpresa: action.payload
      };

    case MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PREMIUM:
      return {
        ...state,
        showModalUpdatePremium: action.payload
      };

    case MinhaContaActionKeys.SET_SHOW_MODAL_CONFIRM_PREMIUM:
      return {
        ...state,
        showModalConfirmUpdatePremium: action.payload
      };

    case MinhaContaActionKeys.GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case MinhaContaActionKeys.GET_USER_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case MinhaContaActionKeys.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false
      };

    // ALTERAR SENHA
    case MinhaContaActionKeys.NOVA_SENHA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MinhaContaActionKeys.NOVA_SENHA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MinhaContaActionKeys.NOVA_SENHA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // ALTERAR DADOS DA CONTA
    case MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: action.payload
      };
    case MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // ALTERAR DADOS DA EMPRESA
    case MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: action.payload
      };
    case MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default minhaConta;
