import { IBreadCrumbItem, IFileData } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { PanelsActionKeys, PanelsActionUnion } from './Panels.actions';
import { IPanelsState } from './interfaces/IPanelsState';
import { IObraData } from 'Data/interfaces/Obra/IObraData';
import { IPanelsFileInfo } from 'Data/interfaces/Panels/IPanelsFileInfo';

const initialState: IPanelsState = {
  fixedPanels: [],
  notFixedPanels: [],
  isLoading: false,
  isCreateLoading: false,
  isUpdateLoading: false,
  isDeleteLoading: false,
  error: undefined,
  panelsObra: undefined,
  isPanelsCoordlyLoading: false,
  panelsListFiles: [],
  panelsListFilesMoveTo: {},
  panelsListFilesBreadCrumbItem: {} as IBreadCrumbItem,
  panelsListFilesObra: {} as IObraData,
  panelsListFilesObsoleteFolderId: undefined,
  panelsListFilesCloudServiceFolderUrl: undefined,
  isPanelsLoadingFileList: false,
  panelsFileInfo: undefined,
  shareLink: undefined,
  isLoadingShareLink: false,
  newPanelId: undefined,
  fileViewerAutodesk: undefined,
  isLoadingRender: false,
};

const panelsReducer = (state = initialState, action: PanelsActionUnion): IPanelsState => {
  switch (action.type) {
    case PanelsActionKeys.PANELS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PanelsActionKeys.PANELS_LIST_SUCCESS:
      state.fixedPanels = action.payload.filter(panel => panel.IsFixed);
      state.notFixedPanels = action.payload.filter(panel => !panel.IsFixed);

      return {
        ...state,
        isLoading: false,
      };
    case PanelsActionKeys.PANELS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case PanelsActionKeys.UPDATE_FIXED_PANELS_LIST:
      state.fixedPanels = action.payload;
      return {
        ...state,
      };
    case PanelsActionKeys.UPDATE_NOT_FIXED_PANELS_LIST:
      state.notFixedPanels = action.payload;
      return {
        ...state,
      };

    case PanelsActionKeys.PANELS_CREATE_REQUEST:
      return {
        ...state,
        newPanelId: undefined,
        isCreateLoading: true,
      };
    case PanelsActionKeys.PANELS_CREATE_SUCCESS:
      if (action.payload.IsFixed) {
        state.fixedPanels.push(action.payload);

      } else {
        state.notFixedPanels.push(action.payload);
      }

      return {
        ...state,
        newPanelId: action.payload.PanelId,
        isCreateLoading: false,
      };
    case PanelsActionKeys.PANELS_CREATE_FAILURE:
      return {
        ...state,
        newPanelId: undefined,
        isCreateLoading: false,
        error: action.payload,
      };

    case PanelsActionKeys.PANELS_UPDATE_REQUEST:
      return {
        ...state,
        newPanelId: undefined,
        isUpdateLoading: true,
      };
    case PanelsActionKeys.PANELS_UPDATE_SUCCESS:
      const newFixedPanels = state.fixedPanels;
      const newNotFixedPanels = state.notFixedPanels;

      const notFixedPanelIndex = newNotFixedPanels.findIndex(panel => {
        return panel.PanelId === action.payload.PanelId
      });
      const fixedPanelIndex = newFixedPanels.findIndex(panel => {
        return panel.PanelId === action.payload.PanelId
      });

      if (action.payload.IsFixed) {
        if (notFixedPanelIndex !== -1) {
          newNotFixedPanels.splice(notFixedPanelIndex, 1);
        }

        if (fixedPanelIndex !== -1) {
          newFixedPanels[fixedPanelIndex] = action.payload;

        } else {
          newFixedPanels.push(action.payload);
        }

      } else {
        if (fixedPanelIndex !== -1) {
          newFixedPanels.splice(fixedPanelIndex, 1);
        }

        if (notFixedPanelIndex !== -1) {
          newNotFixedPanels[notFixedPanelIndex] = action.payload;

        } else {
          newNotFixedPanels.push(action.payload);
        }
      }

      return {
        ...state,
        fixedPanels: [...newFixedPanels],
        notFixedPanels: [...newNotFixedPanels],
        newPanelId: undefined,
        isUpdateLoading: false,
      };
    case PanelsActionKeys.PANELS_UPDATE_FAILURE:
      return {
        ...state,
        isUpdateLoading: false,
        newPanelId: undefined,
        error: action.payload,
      };

    case PanelsActionKeys.PANELS_REORDER_REQUEST:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case PanelsActionKeys.PANELS_REORDER_SUCCESS:
      return {
        ...state,
        isUpdateLoading: false,
      };
    case PanelsActionKeys.PANELS_REORDER_FAILURE:
      return {
        ...state,
        isUpdateLoading: false,
        error: action.payload,
      };

    case PanelsActionKeys.PANELS_DELETE_REQUEST:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case PanelsActionKeys.PANELS_DELETE_SUCCESS:
      if (action.payload) {
        const fixedDeleteIndex = state.fixedPanels.findIndex(panel => {
          return panel.PanelId === action.payload
        });

        if (fixedDeleteIndex !== -1) {
          state.fixedPanels.splice(fixedDeleteIndex, 1);

        } else {
          const notFixedDeleteIndex = state.notFixedPanels.findIndex(panel => {
            return panel.PanelId === action.payload
          });

          if (notFixedDeleteIndex !== -1) {
            state.notFixedPanels.splice(notFixedDeleteIndex, 1);
          }
        }
      }

      return {
        ...state,
        isDeleteLoading: false,
      };
    case PanelsActionKeys.PANELS_DELETE_FAILURE:
      return {
        ...state,
        isDeleteLoading: false,
        error: action.payload,
      };

    case PanelsActionKeys.PANELS_LIST_DISCIPLINA_REQUEST:
      return {
        ...state,
        panelsObra: undefined,
        isPanelsCoordlyLoading: true
      };
    case PanelsActionKeys.PANELS_LIST_DISCIPLINA_SUCCESS:
      return {
        ...state,
        panelsObra: { ...action.payload },
        isPanelsCoordlyLoading: false
      };
    case PanelsActionKeys.PANELS_LIST_DISCIPLINA_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPanelsCoordlyLoading: false
      };

    case PanelsActionKeys.PANELS_LIST_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isPanelsLoadingFileList: true,
        panelsListFilesObsoleteFolderId: undefined,
      };
    case PanelsActionKeys.PANELS_LIST_FILES_SUCCESS:
      let files: IFileData[] = [];
      if (action.payload.isObsoleteFiles) {
        action.payload.Files?.forEach((file) => {
          files.push({ ...file, Versions: [] });
          file.Versions?.forEach((olderFiles) => files.push(olderFiles));
        });
      } else {
        files = action.payload.Files;
      }

      let filesList: IFileData[] = files;
      filesList.forEach((fileRow: IFileData) => {
        if (!fileRow.RenderingSignalR) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.FileInfo.ApiId
          }
        }
      });

      let newState = state;
      if (action.payload.BreadCrumbItem) {
        newState.panelsListFilesBreadCrumbItem = action.payload.BreadCrumbItem;
      }
      if (action.payload.ObsoleteFolderId) {
        newState.panelsListFilesObsoleteFolderId = action.payload.ObsoleteFolderId;
      }
      if (action.payload.ConstructionSite) {
        newState.panelsListFilesObra = action.payload.ConstructionSite;
      }
      if (action.payload.CloudServiceFolderUrl) {
        newState.panelsListFilesCloudServiceFolderUrl = action.payload.CloudServiceFolderUrl;
      }
      return {
        ...state,
        ...newState,
        panelsListFiles: filesList,
        isPanelsLoadingFileList: false,
      };
    case PanelsActionKeys.PANELS_LIST_FILES_FAILURE:
      return {
        ...state,
        isPanelsLoadingFileList: false
      };

    case PanelsActionKeys.PANELS_LIST_FILES_UPDATE:
      return {
        ...state,
        panelsListFiles: action.payload,
      };
    case PanelsActionKeys.PANELS_LIST_FILES_MOVE_TO_CLEAR:
      return {
        ...state,
        isPanelsLoadingFileList: true,
        panelsListFilesMoveTo: {
          ListFiles: undefined,
          isLoading: false
        }
      };

    case PanelsActionKeys.PANELS_GET_FILE_INFO_REQUEST:
      return {
        ...state,
        panelsFileInfo: undefined,
        isLoading: true
      }
    case PanelsActionKeys.PANELS_GET_FILE_INFO_SUCCESS:
      return {
        ...state,
        panelsFileInfo: action.payload,
        isLoading: false
      }
    case PanelsActionKeys.PANELS_GET_FILE_INFO_FAILURE:
      return {
        ...state,
        panelsFileInfo: undefined,
        isLoading: false
      }

    case PanelsActionKeys.SHARE_LINK_PANELS_REQUEST:
      return {
        ...state,
        isLoadingShareLink: true,
      };
    case PanelsActionKeys.SHARE_LINK_PANELS_SUCCESS:
      return {
        ...state,
        shareLink: action.payload,
        isLoadingShareLink: false,
      };
    case PanelsActionKeys.SHARE_LINK_PANELS_FAILURE:
      return {
        ...state,
        isLoadingShareLink: false,
        error: action.payload,
      };
    case PanelsActionKeys.SHARE_LINK_PANELS_CLEAR:
      return {
        ...state,
        shareLink: undefined,
      };

    case PanelsActionKeys.PANELS_AUTODESK_VIEWER_REQUEST:
      return {
        ...state,
        isLoadingRender: true
      }
    case PanelsActionKeys.PANELS_AUTODESK_VIEWER_SUCCESS:
      const newRenderPanelsFileInfo = state.panelsFileInfo;
      if (newRenderPanelsFileInfo) {
        if (newRenderPanelsFileInfo.urn === null && !newRenderPanelsFileInfo.bucketFileName) {
          newRenderPanelsFileInfo.urn = action.payload.urn;
          if (action.payload.status === 'error') {
            newRenderPanelsFileInfo.fileRenderStatus = 'error';
            newRenderPanelsFileInfo.isRendering = false;
          } else {
            newRenderPanelsFileInfo.urn = action.payload.urn;
            newRenderPanelsFileInfo.fileRenderStatus = 'rendering';
            newRenderPanelsFileInfo.isRendering = true;
          }
        }
      }
      return {
        ...state,
        panelsFileInfo: newRenderPanelsFileInfo ? { ...newRenderPanelsFileInfo } : undefined,
        isLoadingRender: false
      }
    case PanelsActionKeys.PANELS_AUTODESK_VIEWER_FAILURE:
      return {
        ...state,
        isLoadingRender: false
      }

    case PanelsActionKeys.CREATE_STATUS_FILE_PANEL_VIEWER:
      return {
        ...state,
        fileViewerAutodesk: action.payload,
        isLoadingRender: false
      }

    case PanelsActionKeys.UPDATE_STATUS_FILE_PANEL_VIEWER:
      const fileViewer = { ...state }.fileViewerAutodesk;
      if (fileViewer &&
        fileViewer?.fileIdentifier === action.payload.fileIdentifier &&
        ((fileViewer.etapa < action.payload.etapa) || (fileViewer.etapa === action.payload.etapa && fileViewer?.progress < action.payload.progress))) {

        return {
          ...state,
          fileViewerAutodesk: action.payload,
          isLoadingRender: false
        }
      }
      return {
        ...state,
      }

    case PanelsActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE:
      let renderingStatus = action.payload;
      let fileItem: IPanelsFileInfo | undefined; 
      
      if (state?.panelsFileInfo) {
        fileItem = state.panelsFileInfo;

        if (
          (fileItem.fileIdentifier === renderingStatus.fileIdentifier) || 
          (fileItem.fileInfo.ApiId === renderingStatus.fileIdentifier)
        ) {
          if (!fileItem.RenderingSignalR) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              etapa: 1,
              progress: 0,
            }
          }

          if ((renderingStatus.etapa > fileItem.RenderingSignalR.etapa || (renderingStatus.etapa === fileItem.RenderingSignalR.etapa && renderingStatus.progress > fileItem.RenderingSignalR.progress))) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
            }
            fileItem.RenderingStatus = 2;
          }

          if (renderingStatus.etapa === 4) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              etapa: 0,
              progress: 0,
            }

            fileItem.RenderingStatus = 1;
            if (renderingStatus.urn) {
              fileItem.urn = renderingStatus.urn;
            }
            if (renderingStatus.bucketFileName) {
              fileItem.bucketFileName = renderingStatus.bucketFileName;
            }
          }

          if (
            renderingStatus.etapa === 5 || 
            (fileItem.extension === 'pdf' && !fileItem.bucketFileName)
          ) {
            fileItem.RenderingSignalR = {
              ...renderingStatus,
              progress: 0,
            }
            fileItem.RenderingStatus = 3;
          }
        }
      }
      return {
        ...state,
        panelsFileInfo: fileItem ? { ...fileItem } : undefined,
      };
    case PanelsActionKeys.SET_FILE_STATUS_RENDERING:
      let newPanelsFileInfo = state.panelsFileInfo;
      if (newPanelsFileInfo) {
        if (newPanelsFileInfo?.fileIdentifier === action.payload.FileApiId || newPanelsFileInfo?.fileInfo.ApiId === action.payload.FileApiId) {
          newPanelsFileInfo.RenderingStatus = 2;
        }
  
        if (!newPanelsFileInfo?.RenderingSignalR) {
          newPanelsFileInfo.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: newPanelsFileInfo.fileIdentifier || newPanelsFileInfo.fileInfo.ApiId
          }
        }
      }
      return {
        ...state,
        panelsFileInfo: newPanelsFileInfo ? { ...newPanelsFileInfo } : undefined,
      };

    default:
      return state;
  }
};

export default panelsReducer;
