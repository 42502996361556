import { FC, useEffect, useRef, useState } from "react";
import SkeletonMain from "../LoadingMain";
import {
  Container,
  Left,
  Right,
  RightHeader,
  RightContent,
  Line,
  ContentContainer,
} from "./styles";

export interface ILoadingHistoryActivity {
  multiple?: number;
}

const LoadingHistoryActivity: FC<ILoadingHistoryActivity> = ({ multiple = 1 }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [height, seetHeight] = useState(48 / 2);

  useEffect(() => {
    if (ref.current) {
      seetHeight(ref.current.offsetHeight / 2)
    }
  }, []);

  const SkeletonUsers = ({ index }: { index: number }) => {
    return (
      <Container ref={ref}>
        <Left>
          <Line height={height} istransparent={`${(index + 1) === multiple}`} />
          <div className="iconContainer">
            <SkeletonMain width="24px" height="24px" borderRadius="50%" isCustom="skeleton-custom-dark" />
          </div>
          <Line height={height} istransparent={`${index === 0}`} />
        </Left>

        <Right>
          <RightHeader>
            <div className="rightHeaderLeft">
              <SkeletonMain width="16px" height="16px" borderRadius="50%" isCustom="skeleton-custom-dark" />
              <SkeletonMain width="80px" height="12px" borderRadius="2px" marginLeft="4px" isCustom="skeleton-custom-dark" />
              <SkeletonMain width="50px" height="12px" borderRadius="2px" marginLeft="2px" isCustom="skeleton-custom-dark" />
            </div>
            <span className="date">
              <SkeletonMain width="70px" height="12px" borderRadius="2px" isCustom="skeleton-custom-light" />
            </span>
          </RightHeader>
          <RightContent>
            <ContentContainer>
              <SkeletonMain width="80px" height="24px" borderRadius="2px" isCustom="skeleton-custom-light" />
              <SkeletonMain width="12px" height="12px" borderRadius="3px" marginLeft="8px" isCustom="skeleton-custom-light" />
              <SkeletonMain width="80px" height="24px" borderRadius="2px" marginLeft="8px" isCustom="skeleton-custom-light" />
            </ContentContainer>
          </RightContent>
        </Right>
      </Container>
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} index={i} />
      ))}
    </>
  )
}

export default LoadingHistoryActivity;