import styled, { css } from "styled-components";

interface IColumn {
  customcolor?: string;
  error?: string;
}

export const ColumnIdentifier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.surface.onSurface};
`;

export const ColumnTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.surface.onSurface};
  
  .titleResume {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;

    .highlighterWords {
      cursor: pointer;
    }
  }

  .titleContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    .singleImgContainer {
      width: 70px;
      height: 38px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
      cursor: zoom-in;

      .img {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        object-fit: contain;
      }
    }

    .multipleImgContainer {
      position: relative;
      width: 70px;
      height: 38px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
      cursor: zoom-in;

      .img {
        width: 34px;
        min-width: 34px;
        max-width: 34px;
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        object-fit: contain;
      }
    
      .imgCount {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        opacity: 0.3;

        .textCount {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.34px;
          color: #fff;
        }
      }
    }
  
    .cardIconAlarm {
      margin-left: 8px;
      margin-right: 8px;
      color: ${({ theme }) => theme.colors.danger.danger} !important;
    }
  }
`;

export const ColumnType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }

  .cardIconHeader { 
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
  }
`;

export const ColumnPriority = styled.div<IColumn>`
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 600;
    color: ${({ customcolor }) => customcolor};
  }
`;

export const ColumnDisciplines = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 175px;
  flex-wrap: nowrap;
  overflow: hidden;
`;

export const ColumnUsers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const ColumnDate = styled.div<IColumn>`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 12px;
  line-height: 16.34px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.surface.onSurface};

  ${({ error }) => error === 'true' && css`
    color: ${({ theme }) => theme.colors.danger.danger};
  `}
`;

export const ColumnStatus = styled.div<IColumn>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
`;
