import React, { FC } from "react";
import { Icon, IconName } from "Components/UI";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { List } from "antd";
import HighlighterWords from "Components/UI/HighlightWords";
import styles from '../FileDestination.module.scss';

interface IFileDestinationListDisciplines {
  filteredDisciplines: any;
  onDisciplineFolder: (value: IDisciplineViewerList | null) => void;
  onOpenFolder: (folderId: string, ConstructionSiteDisciplinesId?: number, folderName?: string) => void;
  onIconLeft: (name: string) => IconName;
  searchDisciplineFile: string;
}

const FileDestinationListDisciplines: FC<IFileDestinationListDisciplines> = ({
  filteredDisciplines,
  onDisciplineFolder,
  onOpenFolder,
  onIconLeft,
  searchDisciplineFile,
}) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={filteredDisciplines}
      split={false}
      renderItem={(item: any) => (
        <List.Item
          className={styles['rowDisciplinesList']}
          onClick={() => {
            onDisciplineFolder(item);
            onOpenFolder(item.DisciplineApiFolderId, item.ConstructionSiteDisciplinesId);
          }}
        >
          <div className={styles['leftRowDisciplinesList']}>
            <Icon 
              icon={onIconLeft(item.CustomName || item.Discipline.Name)} 
              customSize={18} className={styles['']} 
              customColor="#BEC2C6" 
            />
            <HighlighterWords 
              text={item.CustomName || item.Discipline.Name} 
              wordSearched={searchDisciplineFile} 
              className={styles['disciplineListText']} 
            />
          </div>
          <Icon icon="retroceder" customSize={8} className={styles['iconExpanded']} color="cinzaSuperior" />
        </List.Item>
      )}
    />
  )
}

export default FileDestinationListDisciplines;
