import React, { FC, useState, CSSProperties } from 'react';
import { PageHeader as PageHeaderAntd } from '@ant-design/pro-components';
import { Icon, IconName } from '../Icon';

import styles from './PageHeader.module.scss';
import './collapse.css';
import { CSSTransition } from 'react-transition-group';

export interface IPageHeader {
  name: string;
  count?: number;
  showCount?: boolean;
  isFiltered?: boolean;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  isCollapsiable?: boolean;
  style?: CSSProperties;
  customAction?: boolean;
  iconLeft?: IconName;
  headerMarginLeft?: number;
}

const PageHeader: FC<IPageHeader> = ({ 
  name, 
  count,
  showCount,
  isFiltered,
  actions, 
  children, 
  isCollapsiable, 
  style, 
  customAction, 
  iconLeft, 
  headerMarginLeft,
}) => {
  const [collapse, setCollapse] = useState<boolean>(false);

  const toggleCollapse = () => {
    if (!isCollapsiable) {
      return;
    }

    setCollapse(!collapse);
  };

  return (
    <>
      <div className={styles['Header']} onClick={toggleCollapse} style={style}>
        <div className={styles['headerContent']}>
          {iconLeft && <Icon icon={iconLeft} size='lg' className={styles['iconHeader']} color='cinzaPadrao' />}
          <PageHeaderAntd title={name} style={{ paddingLeft: headerMarginLeft || 38 }} />
          {showCount && <strong>({count || 0}{isFiltered && ` resultado${count === 1 ? '' : 's'}` })</strong>}
          {isCollapsiable ?
            <Icon
              icon={!collapse ? 'avancar' : 'expandir'}
              size='xs'
              customColor='#44566C'
              className={styles['icon']}
            />
          : ''}
        </div>
        <div data-testid='header-actions' className={!customAction ? styles['actions'] : styles['custom-actions']}>
          {actions}
        </div>
      </div>
      {!isCollapsiable ?
          children
        : <CSSTransition
            in={collapse} classNames='collapse' timeout={300} unmountOnExit>
            {children}
          </CSSTransition>
      }
    </>
  );
};

export default PageHeader;
