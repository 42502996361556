import styled, { css } from "styled-components";

interface IButtonsWrapper {
  marginTop?: number; 
}

export const ButtonsWrapper = styled.div<IButtonsWrapper>`
  width: 1px;
  height: auto;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  display: flex;
  align-items: center;

  ${({marginTop}) => marginTop && css`
    margin-top: ${marginTop}px;
  `}

  .btnConfirm {
    border-radius: 5px;
  }

  .btnCancel {
    border-radius: 5px;
    margin-left: 6px;
  }
`;