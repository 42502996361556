import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Container } from "./styles";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import FilterDropdown from "./components/FilterDropdown";
import { IFiltroOptions, IUpdateFilter } from "Data/interfaces/Activities/IFilters";

interface IFilters {
  filters: Record<string, IFiltroOptions<ITopicDnDResponse>>;
  addFilter: () => void;
  updateFilter: (values: IUpdateFilter) => void;
  deleteFilter: (filterId: string) => void;
  onShowFilters: (show: boolean) => void;
  firstRenderFilters: boolean;
  lastFilterUpdateId: string;
  blockFilterDropdown: boolean;
}

const Filters: FC<IFilters> = ({
  filters,
  addFilter,
  updateFilter,
  deleteFilter,
  onShowFilters,
  firstRenderFilters,
  lastFilterUpdateId,
  blockFilterDropdown,
}) => {
  const filterContainerRef = useRef<HTMLDivElement | null>(null);

  const filtersArray = useMemo(() => {
    if (filters && JSON.stringify(filters) !== '{}') {
      return Object.keys(filters);
    }

    return [];
  }, [filters]);

  const filtersLength = filtersArray.length;
  const haveFilters = filtersLength > 0;

  const scrollNow = useCallback(() => {
    if (filterContainerRef.current) {
      filterContainerRef.current.scrollLeft = filterContainerRef.current.scrollWidth;
    }
  }, []);

  const handleAddFilter = () => {
    addFilter();
    scrollNow();
  }

  useEffect(() => {
    if (filtersArray[filtersLength - 1] === lastFilterUpdateId) {
      scrollNow();
    }
  }, [filters, filtersArray, filtersLength, lastFilterUpdateId, scrollNow]);

  return (
    <Container ref={filterContainerRef}>
      {haveFilters && filtersArray.map(filter => {
        const currentObj = filters[filter];

        return (
          <FilterDropdown 
            key={filter} 
            id={filter} 
            item={currentObj} 
            updateFilter={updateFilter}
            deleteFilter={deleteFilter}
            firstRenderFilters={firstRenderFilters}
            blockFilterDropdown={blockFilterDropdown}
          />
        )
      })}
      {!haveFilters && (
        <Button 
          type="text"
          className="moreFilterButton"
          onClick={handleAddFilter}
        >
          <Icon
            icon="adicionar"
            color="cinzaEscuro"
            customSize={14}
            className="buttonIcon"
          />
          <span className="buttonText">Adicionar Filtro</span>
        </Button>
      )}
      {haveFilters && (
        <Button 
          type="text"
          className="moreFilterButton moreFilterButtonMore"
          onClick={handleAddFilter}
        >
          <Icon
            icon="adicionar"
            color="cinzaEscuro"
            customSize={14}
            className="buttonIcon"
          />
        </Button>
      )}
    </Container>
  )
}

export default Filters;
