import { IconName } from '../../Components/UI';
import googleDriveGif from 'Static/images/googleDrive.gif';
import oneDriveGif from 'Static/images/oneDrive.gif';
import dropboxGif from 'Static/images/dropbox.gif';

export const DRIVE_FOLDER_PATH = 'https://drive.google.com/drive/u/1/folders/';

export enum ApisEnum {
  Todas,
  A360,
  Dropbox,
  GoogleDrive,
  Bim360,
  OneDrive
}

export const ApisEnumLabel = [
  'Todas',
  'A360',
  'Dropbox',
  'Google Drive',
  'Bim360',
  'OneDrive'
];

export const getIconApi = (api: number): IconName | undefined => {
  switch (api) {
    case ApisEnum.Dropbox:
      return 'viewerDropbox';
    case ApisEnum.GoogleDrive:
      return 'viewerDrive';
    case ApisEnum.OneDrive:
      return 'viewerOnedrive';
    default:
      return;
  }
};

export const getGifApi = (api: number) => {
  switch (api) {
    case ApisEnum.Dropbox:
      return dropboxGif

    case ApisEnum.GoogleDrive:
      return googleDriveGif

    case ApisEnum.OneDrive:
      return oneDriveGif
  
    default:
      return;
  }
}
