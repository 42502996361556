import styled from "styled-components";

export const UnselectedIcon = styled.span`
  height: 15px;
  width: 15px;
  border: 1px solid ${({theme}) => theme.colors.surface.onSurfaceVariant};
  border-radius: 20px;
  margin-left: 5px;
  margin-top: 4px;
  margin-right: 4px;
`;

export const RadioButton = styled.div`
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;

  .selectedIcon {
    color: ${({theme}) => theme.colors.primary.primary} !important;
  }
`;
