import styled, { css } from "styled-components";

interface IContainer {
  showFilters?: boolean;
}

export const Container = styled.div<IContainer>`
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};

  ${({ showFilters }) => showFilters && css`
    height: calc(100vh - 313px);
  `}

  table, .listTable {

    .fileListRow {
      
      .ant-table-cell {
        display: flex;
        align-items: center;
        height: 45px !important;
      }
    }

    thead {
      
      tr {
        height: 38px !important;
        
        th {
          text-transform: capitalize !important;
          padding-left: 14px !important;
        }
      }
    }
  }

  .rc-virtual-list-scrollbar, .rc-virtual-list-scrollbar-vertical {
    width: 4px !important;

    .rc-virtual-list-scrollbar-thumb {
      height: 115px !important;
    }
  }
`;
