import { FC, useEffect, useMemo, useState } from 'react';
import type { MenuProps } from 'antd';
import { Icon } from 'Components/UI';
import AccessPermission from 'Utils/AcessPermission';
import { IPanelsResponse } from 'Data/interfaces/Panels/IPanelsResponse';
import {
  ActionsMenuItemDropdownGlobalStyles,
  MenuDropdown,
  MenuItemStyled,
} from './styles';

interface IActionsMenuItemDropdown {
  panel: IPanelsResponse;
  onActiveId: (panelId: string | null) => void;
  onShare: (panelId: string) => void;
  onFixe: (panelId: string, isFixed: boolean) => void;
  onEdit: (panel: IPanelsResponse) => void;
  onDelete: (panel: IPanelsResponse) => void;
}

const ActionsMenuItemDropdown: FC<IActionsMenuItemDropdown> = ({
  panel,
  children,
  onActiveId,
  onShare,
  onFixe,
  onEdit,
  onDelete,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);

  const handleShareLink = (e: any) => {
    onShare(panel.PanelId);
    setLinkCopy(true);
    e.stopPropagation();
  };

  const handleChange = (open: boolean) => {
    setIsActive(open);
    if (open) {
      onActiveId(panel.PanelId);
    } else {
      onActiveId(null);
    }
  };

  const items = useMemo(() => {
    const defalt: MenuProps['items'] = [
      {
        label: (
          <MenuItemStyled
            copy={`${linkCopy}`}
            onClick={handleShareLink}
          >
            <Icon
              icon={linkCopy ? 'confirmacao' : 'link'}
              customSize={14}
              className="iconMenuItem"
            />
            <span className="textItem">
              {linkCopy ? 'Link copiado!' : 'Copiar link'}
            </span>
          </MenuItemStyled>
        ),
        key: '21',
        className: 'rowMenuActionsDropdown',
      }
    ];

    if (AccessPermission.isAdmin() || AccessPermission.isCoordenador()) {
      defalt.push(
        {
          label: (
            <MenuItemStyled
              onClick={(e) => {
                e.stopPropagation();
                onFixe(panel.PanelId, !panel.IsFixed);
                setIsActive(false);
              }}
            >
              <Icon
                icon={panel.IsFixed ? 'unpin' : 'pin'}
                customSize={18}
                className="iconMenuItem"
              />
              <span className="textItem">
                {panel.IsFixed ? 'Desafixar painel' : 'Fixar painel'}
              </span>
            </MenuItemStyled>
          ),
          key: '22',
          className: 'rowMenuActionsDropdown',
        },
        {
          label: (
            <MenuItemStyled
              onClick={(e) => {
                e.stopPropagation();
                onEdit(panel);
                setIsActive(false);
              }}
            >
              <Icon
                icon="editar"
                customSize={14}
                className="iconMenuItem"
              />
              <span className="textItem">
                Editar
              </span>
            </MenuItemStyled>
          ),
          key: '23',
          className: 'rowMenuActionsDropdown',
        },
        {
          label: (
            <MenuItemStyled
              onClick={(e) => {
                e.stopPropagation();
                onDelete(panel);
                setIsActive(false);
              }}
            >
              <Icon
                icon="excluir"
                customSize={14}
                className="iconMenuItemDelete"
              />
              <span className="textItem">
                Excluir
              </span>
            </MenuItemStyled>
          ),
          key: '24',
          className: 'rowDeleteActionsDropdown',
        },
      );
    }

    return defalt;
  }, [linkCopy, onDelete, onEdit, onFixe, panel]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLinkCopy(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    }
  }, [linkCopy]);

  return (
    <>
      <ActionsMenuItemDropdownGlobalStyles />
      <MenuDropdown
        open={isActive}
        trigger={["click"]}
        rootClassName="ActionsMenuItemDropdown"
        onOpenChange={handleChange}
        menu={{ items }}
        isactive={`${isActive}`}
      >
        {children}
      </MenuDropdown>
    </>
  )
};

export default ActionsMenuItemDropdown;
