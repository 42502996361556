import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import '../../../../node_modules//jquery/src/jquery';
import '../../../../node_modules/signalr/jquery.signalR';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ActivitiesActions } from 'Store/Activities/Activities.actions';
import { ITopicSignalRHasUpdate } from 'Data/interfaces/Activities/ITopicSignalRHasUpdate';

export interface ISignalRResponse {
  api?: string;
  fileIdentifier: string;
  progress: string;
  urn?: string;
  version?: undefined;
  timeStamp?: number;
}

export interface IActivitySignalR {
  csId: number;
}

const ActivitySignalR: FC<IActivitySignalR> = ({ csId }) => {
  const [connection, setConnection] = useState<HubConnection>();
  const dispatch = useDispatch();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_BASE_API_URL?.toString().replace("/api", "")}/hubs/topics?csId=${csId}`
      )
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    newConnection.serverTimeoutInMilliseconds = 100000;
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('hasUpdate', (data: string) => {
            const response = JSON.parse(data);
            const responseFormat: ITopicSignalRHasUpdate = {
              ...response,
              csId,
            };

            dispatch(ActivitiesActions.topicSignalRHasUpdate(responseFormat));
          });

          connection.on('TagValuesRemoved', (data: string) => {
            const response = JSON.parse(data);

            dispatch(ActivitiesActions.hasRemovedOptions(response));
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return <></>;
};

export default memo(ActivitySignalR, () => true);
