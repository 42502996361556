import { FC } from "react";
import Button from "Components/UI/Button";
import emptyActivities from "../../../../../../Static/images/empty-activity-listVision.png";
import emptySearch from "../../../../../../Static/images/empty-search.png";
import { Container } from "./styles";
import { Icon } from "Components/UI";

interface ICustomEmptyData {
  search?: boolean;
  searchText?: string;
  hasFilters?: boolean;
  onOpenCreateActivity?: () => void;
  height: number;
}

const CustomEmptyData: FC<ICustomEmptyData> = ({ 
  search, 
  searchText,
  hasFilters,
  onOpenCreateActivity,
  height,
}) => {
  return (
    <Container height={height}>
      <img 
        src={search ? emptySearch : emptyActivities} 
        className={search ? 'imgSearch' : 'imgEmpty'}
        alt="" 
      />
      <span className="title">
        {search && `Nenhuma atividade encontrada por “${searchText}”.`}
        {!search && hasFilters
          ? `Nenhuma atividade encontrada.`
          : 'Nenhuma atividade cadastrada'}
      </span>
      <span className="subtitle">
        {search && 'A atividade pesquisada não foi encontrada ou não existe. Verifique sua pesquisa e tente novamente.'}
        {!search && hasFilters
          ? 'Não foi encontrado nenhuma atividade com os filtros selecionados. Verifique os filtros e tente novamente.'
          : 'Ainda não foi cadastrada nenhuma atividade.'}
      </span>
      {!search && !hasFilters && (
        <Button
          className="button"
          type="primary"
          height={37}
          onClick={onOpenCreateActivity}
        >
        <Icon 
          icon="adicionar"
          customSize={13}
          className="buttonIcon"
        />
          <span className="buttonText">Nova Atividade</span>
        </Button>
      )}
    </Container>
  )
}

export default CustomEmptyData;