import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { INomenclatureData, ICampoVersao, IExtensionData, IBindSuportedFileExtensions } from './interfaces/Nomenclatura/INomenclatureData';

export class NomenclaturaApi {
  static async create(nomenclatura: INomenclatureData): Promise<AxiosResponse<INomenclatureData>> {
    const axios = await Http.axios();
    return axios.post('/Nomenclatura/BindVersionConfig', nomenclatura);
  }

  static async getNomenclature(): Promise<AxiosResponse<INomenclatureData>> {
    const axios = await Http.axios();
    return axios.get('/Nomenclatura/GetVersionConfig');
  }

  static async getCampoVersao(): Promise<AxiosResponse<ICampoVersao[]>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/GetFieldsVersionConfig');
  }

  static async setExtensions(extensoes: IBindSuportedFileExtensions[]): Promise<AxiosResponse<IBindSuportedFileExtensions[]>> {
    const axios = await Http.axios();
    return axios.post('/Nomenclatura/SetExtensions', extensoes);
  }

  static async getExtensions(): Promise<AxiosResponse<IExtensionData[]>> {
    const axios = await Http.axios();
    return axios.get('/Nomenclatura/GetExtensions');
  }
}
