import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "Store";
import { PanelsActions } from "Store/Panels/Panels.actions";
import {
  getNewPanelId,
  getPanelsCreateLoading,
  getPanelsUpdateLoading,
} from "Store/Panels/Panels.selector";
import {
  INewPanelsOptions,
  SrcTypeOption,
} from "Data/interfaces/Panels/IPanelComponentsModels";
import {
  IPanelsContent,
  IPanelsCreateRequest,
} from "Data/interfaces/Panels/IPanelsCreateRequest";
import { IPanelsFileContent } from "Data/interfaces/Panels/IPanelsFileContent";
import {
  IPanelsResponse,
  PanelTypeEnum,
} from "Data/interfaces/Panels/IPanelsResponse";
import {
  IPanelsUpdateRequest,
  PanelObjKeyUpdate,
} from "Data/interfaces/Panels/IPanelsUpdateRequest";

interface IUsePanelsEditModal {
  active: INewPanelsOptions;
  csId: number;
  panelForEdit?: IPanelsResponse | null;
  onCancel: () => void;
}

const usePanelsEditModal = ({
  active,
  csId,
  panelForEdit,
  onCancel,
}: IUsePanelsEditModal) => {
  const dispatch = useDispatch();
  const isCreateLoading = useSelector(getPanelsCreateLoading);
  const isUpdateLoading = useSelector(getPanelsUpdateLoading);
  const newPanelId = useSelector(getNewPanelId);

  const [activeSrcTypeOption, setActiveSrcTypeOption] = useState<SrcTypeOption>('url');
  const [inputPanelNameValue, setInputPanelNameValue] = useState('');
  const [inputPanelStringContentValue, setInputPanelStringContentValue] = useState('');
  const [inputPanelFileContentValue, setInputPanelFileContentValue] = useState<IPanelsFileContent | null>(null);
  const [fixedValue, setFixedValue] = useState(false);
  const [sentData, setSentData] = useState(false);
  const [error, setError] = useState('');

  const isUpdate = !!panelForEdit;
  const isDisabled = useMemo(() => {
    if (
      (active.type === PanelTypeEnum.InternalItem && !inputPanelFileContentValue) ||
      (active.type !== PanelTypeEnum.InternalItem && !inputPanelStringContentValue) ||
      !inputPanelNameValue
    ) {
      return true;
    }
    return false;
  }, [
    active.type,
    inputPanelStringContentValue,
    inputPanelFileContentValue,
    inputPanelNameValue,
  ]);

  const formatContent = (content: string) => {
    let formatedContent: string | null = content;

    switch (active.type) {
      case PanelTypeEnum.Youtube:
        formatedContent = formatYoutubeContent(content);
        if (!formatedContent) setError('URl ou iFrame de integração inválido!');
        break;
      case PanelTypeEnum.Dropbox:
        if (!formatedContent.startsWith('https://www.dropbox.com/scl/')) {
          setError('URl inválida!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleCalendar:
        const formatedCalendarIFrame = formatIFrameContent(formatedContent);
        formatedContent = formatedCalendarIFrame || formatedContent;
        if (!formatedContent.startsWith('https://calendar.google.com/calendar/')) {
          setError('URl ou iFrame de integração inválido!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleDocs:
        if (!formatedContent.startsWith('https://docs.google.com/document/')) {
          setError('URL inválida!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleDrive:
        formatedContent = formatDriveContent(formatedContent);
        if (!formatedContent.startsWith('https://drive.google.com/')) {
          setError('URL inválida!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleMaps:
        const formatedMapsIFrame = formatIFrameContent(formatedContent);
        formatedContent = formatedMapsIFrame || formatedContent;
        if (!formatedContent.startsWith('https://www.google.com/maps/')) {
          setError('URl ou iFrame de integração inválido!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleSheets:
        if (!formatedContent.startsWith('https://docs.google.com/spreadsheets/')) {
          setError('URL inválida!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.GoogleSlides:
        if (!formatedContent.startsWith('https://docs.google.com/presentation/')) {
          setError('URL inválida!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.Loom:
        const formatedLoomIFrame = formatIFrameContent(formatedContent);
        formatedContent = formatedLoomIFrame || formatedContent;
        if (!formatedContent.startsWith('https://www.loom.com/')) {
          setError('URl ou iFrame de integração inválido!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.Miro:
        const formatedMiroIFrame = formatIFrameContent(formatedContent);
        formatedContent = formatedMiroIFrame || formatedContent;
        if (!formatedContent.startsWith('https://miro.com/')) {
          setError('URl ou iFrame de integração inválido!');
          formatedContent = null;
        }
        break;
      case PanelTypeEnum.OneDrive:
        const formatedOneDriveIFrame = formatIFrameContent(formatedContent);
        formatedContent = formatedOneDriveIFrame || formatedContent;
        if (!isValidOneDrive(formatedContent)) {
          setError('URL inválida!');
          formatedContent = null;
        }
        break;
      default:
        setError('');
        break;
    }

    return formatedContent;
  };

  const isValidOneDrive = (content: string) => {
    if (['https://onedrive.live.com/', 'https://1drv.ms/'].some(link => content?.startsWith(link))) return true;

    const sharepointRegex = /^https:\/\/[a-zA-Z0-9-]+\.sharepoint\.com\//g;
    if (sharepointRegex.test(content)) return true;

    return false;
  }

  const formatYoutubeContent = (content: string) => {
    const youtubeRegex = /(v\=|v%3D|\/e\/|\/embed\/|\/watch\/|\/shorts\/|\/live\/|\/v\/|\.be\/)([A-z0-9-]+)/g;
    const arrYoutubeValue = youtubeRegex.exec(content);

    const newYoutubePrefix = "https://www.youtube.com/embed/"
    let formatedYoutubeValue = (arrYoutubeValue && arrYoutubeValue.length >= 3)
      ? `${newYoutubePrefix}${arrYoutubeValue[2]}`
      : null;

    if (!formatedYoutubeValue) {
      formatedYoutubeValue = formatIFrameContent(content);
    }

    return formatedYoutubeValue;
  };

  const formatIFrameContent = (content: string) => {
    const mapsRegex = /src="([^"]*)"/g;
    const arrMapsValue = mapsRegex.exec(content);

    const formatedMapsValue = (arrMapsValue && arrMapsValue.length >= 2)
      ? arrMapsValue[1]
      : null;

    return formatedMapsValue;
  };

  const formatDriveContent = (content: string) => {
    let newContent = content;
    newContent = newContent.replace('?', '&');
    newContent = newContent.replace('drive/folders/', 'embeddedfolderview?id=');
    newContent = newContent.concat('#grid')

    return newContent;
  };

  const handleCreatePanel = () => {
    if (isUpdate) {
      const keys: PanelObjKeyUpdate = new Map([
        [
          'name', {
            old: panelForEdit.Name,
            new: inputPanelNameValue,
          },
        ],
        [
          'isFixed', {
            old: panelForEdit.IsFixed,
            new: fixedValue,
          }
        ],
        [
          'type', {
            old: panelForEdit.Type,
            new: active.type,
          }
        ]
      ]);

      const oldContent: IPanelsContent = JSON.parse(panelForEdit?.Content);
      if (
        PanelTypeEnum.InternalItem &&
        activeSrcTypeOption !== 'file' &&
        typeof oldContent.content === 'string'
      ) {
        const formatedInputPanelStringContentValue = formatContent(inputPanelStringContentValue)
        if (!formatedInputPanelStringContentValue) return;

        keys.set('content', {
          old: oldContent.content,
          new: formatedInputPanelStringContentValue,
        });

      } else {
        keys.set('content', {
          old: oldContent.content as IPanelsFileContent,
          new: inputPanelFileContentValue as IPanelsFileContent,
        });
      }

      const request: IPanelsUpdateRequest = {
        panelId: panelForEdit.PanelId,
        data: [],
      };
      let error = false;
      keys.forEach((value, key) => {
        if (key !== 'content') {
          if (value.old !== value.new) {
            request.data.push({
              path: key,
              op: 'replace',
              value: value.new,
            });
          }

        } else {
          if (inputPanelFileContentValue || inputPanelStringContentValue) {
            const contentValue = activeSrcTypeOption === "file"
              ? inputPanelFileContentValue
              : formatContent(inputPanelStringContentValue);
            if (!contentValue) error = true;

            const newContentValue: IPanelsContent = {
              type: activeSrcTypeOption,
              content: contentValue,
            };

            if (activeSrcTypeOption !== 'file' && value.new !== value.old) {
              request.data.push({
                path: key,
                op: 'replace',
                value: JSON.stringify(newContentValue),
              });
            }

            if (activeSrcTypeOption === 'file') {
              const oldValue = value.old as IPanelsFileContent;
              const newValue = value.new as IPanelsFileContent;

              if (oldValue.FileApiId !== newValue.FileApiId) {
                request.data.push({
                  path: key,
                  op: 'replace',
                  value: JSON.stringify(newContentValue),
                });
              }
            }
          }
        }
      });

      if (error) return;
      dispatch(PanelsActions.updatePanel(request));

    } else {
      const contentValue = active.type === PanelTypeEnum.InternalItem
        ? inputPanelFileContentValue
        : formatContent(inputPanelStringContentValue);
      if (!contentValue) return;

      const request: IPanelsCreateRequest = {
        type: active.type,
        name: inputPanelNameValue,
        content: JSON.stringify({
          type: activeSrcTypeOption,
          content: contentValue,
        } as IPanelsContent),
        isFixed: fixedValue,
        constructionSiteFk: csId,
      }
      dispatch(PanelsActions.createPanel(request));
    }

    setSentData(true);
  };

  const handleRedirect = useCallback((id: string) => {
    history.push(`/obras/panels/${csId}/${id}`);
  }, [csId]);

  useEffect(() => {
    setError('');
  }, [inputPanelStringContentValue]);

  useEffect(() => {
    if (isUpdate) {
      const panelContent: IPanelsContent = JSON.parse(panelForEdit.Content);
      const { type, content } = panelContent;

      setActiveSrcTypeOption(type);
      setInputPanelNameValue(panelForEdit.Name);
      if (type !== 'file') {
        setInputPanelStringContentValue(content as string);
      } else {
        setInputPanelFileContentValue(content as IPanelsFileContent);
      }
      setFixedValue(panelForEdit.IsFixed);
    }
  }, [
    isUpdate,
    panelForEdit?.Content,
    panelForEdit?.IsFixed,
    panelForEdit?.Name,
  ]);

  useEffect(() => {
    if (isUpdate && active.type !== panelForEdit.Type) {
      setInputPanelStringContentValue('');
      setInputPanelFileContentValue(null);
    }


    let usetype: SrcTypeOption = 'url';
    if (isUpdate) {
      const panelContent: IPanelsContent = JSON.parse(panelForEdit.Content);
      const { type } = panelContent;
      usetype = type;
    }

    setActiveSrcTypeOption(
      active.type === PanelTypeEnum.InternalItem
        ? 'file'
        : usetype
    );
  }, [isUpdate, active.type, panelForEdit?.Type]);

  useEffect(() => {
    if (
      sentData &&
      ((!isCreateLoading && !isUpdate) ||
        (!isUpdateLoading && isUpdate))
    ) {
      setSentData(false);
      if (newPanelId && !isUpdate) {
        handleRedirect(newPanelId);
      }
      onCancel();
    }
  }, [
    isCreateLoading,
    sentData,
    newPanelId,
    isUpdate,
    isUpdateLoading,
  ]);

  return {
    isUpdate,
    inputPanelFileContentValue,
    inputPanelNameValue,
    activeSrcTypeOption,
    inputPanelStringContentValue,
    error,
    fixedValue,
    isCreateLoading,
    isDisabled,
    isUpdateLoading,
    setInputPanelNameValue,
    setInputPanelFileContentValue,
    setActiveSrcTypeOption,
    setInputPanelStringContentValue,
    setFixedValue,
    handleCreatePanel,
  }
};

export default usePanelsEditModal;
