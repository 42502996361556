import React, { FC, useCallback } from 'react';
import { Button } from 'antd';
import Tooltip from '../../../../Components/UI/Tooltip';
import { GetAvatar } from '../../../../Utils/generateThumbnail';
import { IUserData } from 'Data/interfaces/User/IUserData';
import styles from './AvatarsItems.module.scss';
import { Icon } from 'Components/UI';

export interface IAvatarsItems {
  users: IUserData[];
  showDetail?: boolean;
  customNormalSize?: number;
  customDetailSize?: number;
  customMarginRight?: string;
  customMarginTop?: string;
}

const AvatarsItems: FC<IAvatarsItems> = ({
  users,
  showDetail,
  customNormalSize,
  customDetailSize,
  customMarginRight,
  customMarginTop,
}) => {
  const detailUser = useCallback((user: IUserData) => (
    <div className={styles['detailUserWrapper']}>
      <GetAvatar
        name={user?.Nome || user?.Email || ''}
        shape='circle'
        size={customDetailSize || 32}
        src={user?.OriginalImageUrl || user?.AvatarFileName || ''}
        thumbSrc={user?.ThumbnailImageUrl}
        thumbType="small"
        border={'1px solid #FFFFFF'}
      />
      <span className={styles['detailName']}>{user?.Nome || ''}</span>
      <span className={styles['detailEmail']}>
        <Icon 
          icon="email"
          customSize={12}
          customColor={'#BEC2C6'}
          style={{marginRight: '8px', marginLeft: '-4px'}}
        />
        {user?.Email || ''}
      </span>
    </div>
  ), []);

  const detailUserList = useCallback((userList: IUserData[]) => {
    if (showDetail) {
      return (
        <div className={styles['detailListUserWrapper']}>
          <span className={styles['detailTitle']}>Responsáveis</span>
          <div className={styles['detailContent']}>
            {userList.map((user, index) => (
              <div 
                key={`${index}-${user.Id}`}
                className={styles['detailListUserRow']}
              >
                <GetAvatar
                  name={user?.Nome || user?.Email || ''}
                  shape='circle'
                  size={customDetailSize || 32}
                  src={user?.OriginalImageUrl || user?.AvatarFileName || ''}
                  thumbSrc={user?.ThumbnailImageUrl}
                  thumbType="small"
                  border={'1px solid #FFFFFF'}
                />
                <span className={styles['detailRight']}>
                  <span className={styles['detailName']}>{user?.Nome || ''}</span>
                  <span className={styles['detailEmail']}>{user?.Email || ''}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
      )
    }
    return (
      <div className={styles['listUsers']}>
        {userList.map((work, index) => (
          <label key={`worksLabel${index}`}>
            {work?.Nome || work?.Email || ''}
          </label>
        ))}
      </div>
    );
  }, [showDetail]);

  const displayWorks = useCallback(() => {
    if (users && users.length > 4) {
      const visibleUsers = users.slice(0, 3);
      
      const works = users.slice(3);

      return (
        <>
          {visibleUsers.map((user, index) => 
            <Tooltip
              title={showDetail 
                ? detailUser(user) 
                : user?.Nome || user?.Email || ''
              }
              placement="bottom"
              key={`SharedUser${index}`}
            >
              <GetAvatar
                name={user?.Nome || user?.Email || ''}
                shape='circle'
                size={customNormalSize || 24}
                src={user?.OriginalImageUrl || user?.AvatarFileName || ''}
                thumbSrc={user?.ThumbnailImageUrl}
                thumbType="small"
                border={'1px solid #FFFFFF'}
                zIndex={(visibleUsers.length + 1) - index}
                marginRight={customMarginRight || '-5px'}
                cursor='pointer'
                marginTop={customMarginTop}
              />
            </Tooltip>
          )}
        
          <Tooltip 
            title={detailUserList(works)}
            placement="bottom"
          >
            <Button 
              shape="circle" 
              type="default" 
              className={styles['buttomListUser']}
            >
              +{works.length}
            </Button>
          </Tooltip>
        </>
      )
    }

    return (
      users.map((user, index) => 
        <Tooltip
          title={showDetail 
            ? detailUser(user) 
            : user?.Nome || user?.Email || ''
          }
          placement="bottom"
          key={`user${index}`}
        >
          <GetAvatar
            name={user?.Nome || user?.Email || ''}
            shape='circle'
            size={customNormalSize || 24}
            src={user?.OriginalImageUrl || user?.AvatarFileName || ''}
            thumbSrc={user?.ThumbnailImageUrl}
            thumbType="small"
            border={'1px solid #FFFFFF'}
            zIndex={(users.length + 1) - index}
            marginRight={customMarginRight || '-5px'}
            cursor='pointer'
            marginTop={customMarginTop}
          />
        </Tooltip>
      )
    )
  }, [users]);

  return (
    <div>
      <div className={styles['avatarWrapper']}>
        {displayWorks()}
      </div>
    </div>
  );
}

export default AvatarsItems;
