import { FC, useState } from 'react';
import { Icon } from 'Components/UI';
import Button from 'Components/UI/Button';
import { Popover } from 'antd';
import { Container } from './styles';
import premiumImg from "Static/images/logo-crow-free.png";

interface IPremiumDropdown {
  role: number;
  onRedirect: (role: number) => void;
}

const PremiumDropdown: FC<IPremiumDropdown> = ({
  children,
  role,
  onRedirect,
}) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <Container>
      <img
        className="imgPremium"
        src={premiumImg}
        alt="Imagem Coroa Premium"
      />

      <div className="right">
        <span className="textTitle">
          {role === 1
            ? 'Dê um upgrade na sua conta'
            : 'Dê um upgrade na conta da sua empresa'
          }
        </span>
        <span className="textDescription">
          Para personalizar nomenclaturas por obra
        </span>
        <Button
          className="btnPremium"
          ispremium="true"
          onClick={() => onRedirect(role)}
        >
          <Icon
            icon="crown"
            customSize={12}
            className="iconCrow"
          />
          {role === 1
            ? 'Adquirir o plano Pro'
            : 'Solicitar o plano Pro'
          }
        </Button>
      </div>

      <Icon 
        icon="cancelar"
        className="iconCancel"
        customSize={8}
        onClick={hide}
      />
    </Container>
  );

  return (
    <Popover
      trigger="click"
      placement="bottom"
      open={open}
      content={content}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  )
};

export default PremiumDropdown;
