import { FC } from "react";
import { Main } from "./styles";

export interface ILoadingMain {
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: string;
  marginLeft?: string;
  marginTop?: string;
  minWidth?: string;
  maxWidth?: string;
  isCustom?: string;
  zIndex?: number;
}

const LoadingMain: FC<ILoadingMain> = ({ 
  width, 
  height, 
  border, 
  borderRadius, 
  zIndex = undefined, 
  marginLeft, 
  minWidth, 
  maxWidth, 
  marginTop, 
  isCustom, 
}) => {
  return (
    <Main 
      iscustom={isCustom} 
      style={{
        width: `${width}`, 
        minWidth: `${minWidth}`, 
        maxWidth: `${maxWidth}`, 
        height: `${height}`, 
        border: `${border}`, 
        borderRadius: `${borderRadius}`, 
        zIndex: zIndex, 
        marginLeft: `${marginLeft}`, 
        marginTop: `${marginTop}`}} 
    />
  )
}

export default LoadingMain;