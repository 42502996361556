import { filesContentOptions } from "./FileOptions";
import { HistoryFileActionDescriptionRecord, HistoryFileIconRecord } from "Data/interfaces/History/IHistoryFileRecord";
import { HistoryFileActionEnum } from "Data/interfaces/History/IHistoryFileApi";


const contentFileIcon: HistoryFileIconRecord = {
  [HistoryFileActionEnum.StatusChanged]: { icon: 'trocarStatus' },
  [HistoryFileActionEnum.FileDownloaded]: { icon: 'download' },
  // [HistoryFileActionEnum.upload]: { icon: 'upload' },
  // [HistoryFileActionEnum.rename]: { icon: 'input' },
  // [HistoryFileActionEnum.revision]: { icon: 'upload' },
  // [HistoryFileActionEnum.obsolet]: { icon: 'obsoleteArchive' },
  // [HistoryFileActionEnum.longText]: { icon: 'collapse', size: 8 },
  // [HistoryFileActionEnum.delete]: { icon: 'excluir' },
  // [HistoryFileActionEnum.move]: { icon: 'mover' },
  // [HistoryFileActionEnum.edit]: { icon: 'editar' },
  // [HistoryFileActionEnum.newFolder]: { icon: 'adicionarPasta', size: 16 },
}

const actionFileDescription: HistoryFileActionDescriptionRecord = {
  [HistoryFileActionEnum.StatusChanged]: 'atualizou o arquivo' ,
  [HistoryFileActionEnum.FileDownloaded]: 'fez download do arquivo' ,
}

export { filesContentOptions, contentFileIcon, actionFileDescription };
