import styled from "styled-components";

interface IBGColor {
  bgColor?: string;
}
interface IText {
  textColor?: string;
}

export const Container = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 16px;
  
  .avatarSelectText {
    margin-left: 8px;
  }
`;

export const BGColor = styled.div<IBGColor>`
  background-color: ${({bgColor}) => bgColor || undefined};
  width: auto;
  display: flex;
  align-items: center;
  padding: 2px 12px;
  border-radius: 3px;
`;

export const Text = styled.span<IText>`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: ${({textColor}) => textColor || '#324150'};
`;

export const TextImg = styled.span<IText>`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: ${({textColor}) => textColor || '#324150'};
`;
