import { Popover } from "antd";
import styled, { css } from "styled-components";

export const CustomPopover = styled(Popover)`
  height: 28px !important;
  border-radius: 5px;
  border: none;
  padding-left: 12px;
  padding-right: 10px;
  background-color: ${({theme}) => theme.colors.secondary.secondary};

  .ant-popover-inner {
    padding: 0 !important;
  }
`;

interface IButtonDrawer {
  disabled?: string;
}

export const ButtonDrawer = styled.div<IButtonDrawer>`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .btnDrawerIcon {
    margin-right: 2px;
    color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.secondary.onSecondary};
    white-space: nowrap;
  }

  .btnDrawerExpand {
    margin-left: 2px;
    color: ${({theme}) => theme.colors.secondary.onSecondaryVariant} !important;
  }

  ${({disabled}) => disabled === 'true' && css`
    cursor: not-allowed;

    .btnDrawerIcon {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    }

    span {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    .btnDrawerExpand {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    }
  `}
`;

export const Overlaycontainer = styled.div`
  width: 200px;
  border-radius: 6px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ButtonAction = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  cursor: pointer;

  &+& {
    margin-top: 6px;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.secondary};
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btnActionIconLeft {
      color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
    }

    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: ${({theme}) => theme.colors.secondary.onSecondary};
    }
  }

  .btnActionIconConfirm {
    color: ${({theme}) => theme.colors.primary.primary} !important;
  }
`;
