import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthUtils, ILoggedUser } from '../../../Utils/AuthUtils';
import { IRenderingSignalR } from '../../../Data/interfaces/ListagemArquivos/IListFilesResponse';

import '../../../../node_modules//jquery/src/jquery';
import '../../../../node_modules/signalr/jquery.signalR';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { ViewerActions } from '../../../Store/Viewer/Viewer.actions';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { FederatedViewerActions } from 'Store/FederatedViewer/FederatedViewer.actions';
import { PanelsActions } from 'Store/Panels/Panels.actions';

export interface ISignalRResponse {
  api?: string;
  fileIdentifier: string;
  progress: string;
  urn?: string;
  version?: undefined;
  timeStamp?: number;
}

const SignalR: FC = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const dispatch = useDispatch();
  const user: ILoggedUser = AuthUtils.getLoggedUser();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_BASE_API_URL?.toString().replace("/api", "")}/hubs/autodesk?userId=${user.userId}`
      )
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    newConnection.serverTimeoutInMilliseconds = 100000;
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('onDownloadChange', (message: string) => {
            const response = JSON.parse(message);

            let data: IRenderingSignalR = {
              etapa: 1,
              fileIdentifier: response.fileIdentifier,
              progress: Number(response.progress)
            };
            dispatch(ListagemArquivosActions.autodeskViewerRenderingResponse(data));
            dispatch(ViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(FederatedViewerActions.autodeskViewerRenderingResponse(data));
            dispatch(FederatedViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(PanelsActions.panelsAutodeskViewerRenderingResponse(data));
            dispatch(PanelsActions.updateStatusFilePanelViewer(data));
          });

          connection.on('onUploadChange', (message: string) => {
            const response = JSON.parse(message);

            let data: IRenderingSignalR = {
              etapa: 2,
              fileIdentifier: response.fileIdentifier,
              progress: Number(response.progress)
            };
            dispatch(ListagemArquivosActions.autodeskViewerRenderingResponse(data));
            dispatch(ViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(FederatedViewerActions.autodeskViewerRenderingResponse(data));
            dispatch(FederatedViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(PanelsActions.panelsAutodeskViewerRenderingResponse(data));
            dispatch(PanelsActions.updateStatusFilePanelViewer(data));
          });

          connection.on('onProgressChange', (message: string) => {
            const response = JSON.parse(message);

            let data: IRenderingSignalR = {
              etapa: 3,
              fileIdentifier: response.fileIdentifier,
              progress: Number(response.progress.split('%')[0])
            };
            dispatch(ListagemArquivosActions.autodeskViewerRenderingResponse(data));
            dispatch(ViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(FederatedViewerActions.autodeskViewerRenderingResponse(data));
            dispatch(FederatedViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(PanelsActions.panelsAutodeskViewerRenderingResponse(data));
            dispatch(PanelsActions.updateStatusFilePanelViewer(data));
          });

          connection.on('itemRendered', (message: string) => {
            const response = JSON.parse(message);

            let data: IRenderingSignalR = {
              etapa: 4,
              fileIdentifier: response.fileIdentifier,
              progress: 100,
              urn: response.urn,
              bucketFileName: response.bucketFileName,
            };
            dispatch(ListagemArquivosActions.autodeskViewerRenderingResponse(data));
            dispatch(ViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(FederatedViewerActions.autodeskViewerRenderingResponse(data));
            dispatch(FederatedViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(PanelsActions.panelsAutodeskViewerRenderingResponse(data));
            dispatch(PanelsActions.updateStatusFilePanelViewer(data));
          });

          connection.on('itemError', (message: string) => {
            const response = JSON.parse(message);

            let data: IRenderingSignalR = {
              etapa: 5,
              fileIdentifier: response.fileIdentifier,
              progress: 0,
              urn: response.urn
            };
            dispatch(ListagemArquivosActions.autodeskViewerRenderingResponse(data));
            dispatch(ViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(FederatedViewerActions.autodeskViewerRenderingResponse(data));
            dispatch(FederatedViewerActions.updateStatusFileViewerAutodesk(data));
            dispatch(PanelsActions.panelsAutodeskViewerRenderingResponse(data));
            dispatch(PanelsActions.updateStatusFilePanelViewer(data));
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return <></>;
};

export default memo(SignalR, () => true);
