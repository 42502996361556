import React, { FC } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import PlanoAvisoViewerUpgrade from '../../../../Static/images/plano-aviso-viewer-upgrade.png';
import styles from "./CTAUpgradeAccessViewer.module.scss";

interface ICTAUpgradeAccessViewer {
  title: string;
  role?: number;
  onConfirm: () => void;
}

const CTAUpgradeAccessViewer: FC<ICTAUpgradeAccessViewer> = ({
  title,
  role,
  onConfirm,
}) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['main']}>
        <div className={styles['header']}>
          <span>Plano PRO</span>
        </div>
        <div className={styles['content']}>
          <h3>{`Visualize as ${title}`}</h3>
          <p>{`Faça o upgrade para o plano PRO para visualizar as ${title} do seu modelo.`}</p>
        </div>
        <img src={PlanoAvisoViewerUpgrade} alt='' />
        <Button 
          className={styles['button-confirm']} 
          ispremium="true"
          width={215} 
          height={36} 
          onClick={onConfirm}
        >
          <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
          {role === 1 
            ? 'Adquirir o plano Premium'
            : 'Solicitar o plano Premium'
          }
        </Button>
      </div>
    </div>
  )
}

export default CTAUpgradeAccessViewer;
