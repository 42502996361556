import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { ITenantRole } from './interfaces/Tenant/ITenantRole';
import { IUserInforResponse } from './interfaces/Auth/IUserInfoResponse';
import { ITenantUsersResponse } from './interfaces/Tenant/ITenantUsersResponse';
import { ITenanInviteInfo } from './interfaces/Tenant/ITenantInviteData';
import { ITenantUsersWithConstruction } from './interfaces/Tenant/ITenantUsersWithConstruction';
import { ITenantData } from './interfaces/Tenant/ITenantData';
import { ITenantBindInvitedUsers } from './interfaces/Tenant/ITenantBindInvitedUsers';
import { ITenantUpdateTenantUserRole } from './interfaces/Tenant/ITenantUpdateTenantUserRole';
import { ITenantUpdateStatusRequest } from './interfaces/Tenant/ITenantUpdateStatusRequest';
import { IMultiDomainPersonalizationResponse } from './interfaces/MultiDomain/IMultiDomainPersonalizationResponse';
import { IUpdateTenantPersonalizationRequest } from './interfaces/MultiDomain/IUpdateMultiDomainPersonalization';
import { store } from 'Store';
import { MultiDomainActions } from 'Store/MultiDomain/MultiDomain.action';
import { IUploadPersonalization } from './interfaces/MultiDomain/IUploadPersonalization';
import { IUpdateUploadPersonalization } from './interfaces/MultiDomain/IUpdateUploadPersonalization';

export class TenantApi {
  static async changeContext(tenantId: number): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/Tenant/ChangeContext?tenantId=${tenantId}`);
  }

  static async getTenantUsersByUser(): Promise<AxiosResponse<ITenantUsersResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/GetTenantUsersByUser');
  }

  static async getAllUsers(): Promise<AxiosResponse<IUserInforResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/GetAllUsers');
  }

  static async getAllTenantRoles(): Promise<AxiosResponse<ITenantRole[]>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/GetAllTenantRoles');
  }

  static async getTenantInviteInfo(emailHash: string): Promise<AxiosResponse<ITenanInviteInfo>> {
    const axios = await Http.axios();
    return axios.get(`/Tenant/GetTenantInviteByEmailHash?emailHash=${emailHash}`);
  }

  static async getTenantsByUser(): Promise<AxiosResponse<ITenantData[]>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/GetTenantsByUser?loadConstructionSitesInfo=false');
  }

  static async getTenanListUsersConstructionSites(): Promise<AxiosResponse<ITenantUsersWithConstruction>> {
    const axios = await Http.axios();
    return axios.get('/Tenant/ListUsersConstructionSites');
  }

  static async removeTenantUser(userId: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/Tenant/RemoveTenantUser?userId=${userId}`);
  }

  static async removeTenantInvite(tenantInviteId: number): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/Tenant/RemoveTenantInvite?tenantInviteId=${tenantInviteId}`);
  }

  static async tenantReSendInvite(tenantInviteId: number): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`/Tenant/SendTenantInviteEmail?tenantInviteId=${tenantInviteId}`);
  }

  static async tenantBindInvitedUser(bindInvitedUsers: ITenantBindInvitedUsers[]): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post(`Tenant/BindInvitedUsers`, bindInvitedUsers);
  }

  static async bindConstructionSitesToUser(updateTenantUserRole: ITenantUpdateTenantUserRole): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(`Tenant/UpdateTenantUserRole`, updateTenantUserRole);
  }

  static async updateTenantStatus(request: ITenantUpdateStatusRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.put(`Tenant/UpdateStatusTenantUser/${request.tenantUserId}?isActive=${request.isActive}`);
  }

  static async getMultiDomain(domain: string): Promise<AxiosResponse<IMultiDomainPersonalizationResponse>> {
    const axios = await Http.axios();
    return axios.get(`Tenant/GetCustomizationByDomainName?domainName=${domain}`);
  }

  static async getTenantPersonalization(tenantFk: number): Promise<AxiosResponse<IMultiDomainPersonalizationResponse>> {
    const axios = await Http.axios();
    return axios.get(`Tenant/GetCustomizationByTenantId?tenantId=${tenantFk}`);
  }

  static async updateTenantPersonalization(request: IUpdateTenantPersonalizationRequest): Promise<AxiosResponse<IMultiDomainPersonalizationResponse>> {
    const axios = await Http.axios();
    return axios.patch(`Tenant/UpdateCustomization/${request.TenantFk}`, request.updateData);
  }

  static async checkDNS(tenantFk: number): Promise<AxiosResponse<IMultiDomainPersonalizationResponse>> {
    const axios = await Http.axios();
    return axios.get(`Tenant/CheckDomainDns/${tenantFk}`);
  }

  static async uploadTenantPersonalization(request: IUploadPersonalization): Promise<AxiosResponse<IMultiDomainPersonalizationResponse>> {
    const axios = await Http.axios(false);
    const formData = new FormData();
    formData.append('File', request.file);
    const url = `/Tenant/Upload${request.path}TenantCustomization`;
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (request.file) {
          const { loaded } = progressEvent;
          const res: IUpdateUploadPersonalization = {
            path: request.path,
            sentSize: loaded,
            isSending: true,
            uploadCompleted: false,
          }
          store.dispatch(MultiDomainActions.updateUploadTenantPersonalization(res));
        }
      },
      validateStatus: (status: number) => {
        if (request.file) {
          const res: IUpdateUploadPersonalization = {
            path: request.path,
            sentSize: null,
            isSending: false,
            uploadCompleted: true,
          }
          store.dispatch(MultiDomainActions.updateUploadTenantPersonalization(res));
        }
        return true;
      },
    });
  }
}
