import { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import { ColumnProps } from 'antd/lib/table';

import { IPlotagemSolicitacao } from '../../../Data/interfaces/Plotagem/IPlotagemListResponse';
import { IBreadCrumb } from '../../../Data/interfaces/Plotagem/IBreadCrumb';

export interface IPlotagemAReceber {
    match: any
} 
export interface dadosTablePlotFile {
  key: number;
  id: number;
  name: string;
  arquivos: string;
  Copies: string;
  responsavel: string;
}
export interface dadosTablePlotInfo {
  key: number;
  id: number;
  name: string;
  arquivos: number;
  Copies: number;
  responsavel: string;
  children: dadosTablePlotFile[];
}

export const organizaObjeto = (obj: IPlotagemSolicitacao[]): any => {
  let info: dadosTablePlotInfo[] = [];
  let contInfo: number = 1;
  obj.forEach((plotRequest) => {
      let file: dadosTablePlotFile[] = [];
      let contFile: number = 1;
      plotRequest.Files.forEach((fileRequest) => {
          let hasColor = fileRequest.HasColor ? ' - Colorido' : ' - P&B'
          file.push({
              key: parseInt(contInfo.toString()+contFile.toString()),
              id: fileRequest.PlottingFilesId,
              name: fileRequest.Name,
              arquivos: '',
              Copies: hasColor,
              responsavel: '',
          }as dadosTablePlotFile);
          contFile++;
      });
      info.push({
          key: contInfo,
          id: 0,
          name: plotRequest.Description,
          arquivos: plotRequest.QtyRequest,
          Copies: plotRequest.QtyRequest,
          responsavel: plotRequest.PlotterResponsibleName,
          children: file,
      });
      contInfo++;
  });
  return info;
}

export const columnsTable: Array<ColumnProps<any>> = [
  {
      title: 'Solicitação',
      dataIndex: 'name',
      key: 'name'
  },
  {
      title: 'Arquivos',
      dataIndex: 'arquivos',
      key: 'arquivos'
  },
  {
      title: 'Cópias',
      dataIndex: 'Copies',
      key: 'Copies'
  },
  {
      title: 'Entregar Para',
      dataIndex: 'string',
      key: 'string'
  }
];


export const getbreadCrumb = (breadCrumb: IBreadCrumb, constructionSiteId: number):  IBreadcrumbItem[] => {
  const data: IBreadcrumbItem[] = [
    {
      description: `${breadCrumb?.PreviousItem?.Name}`,
      href: `/obras/plotagens/${constructionSiteId}`,
    },
    {
      description: 'Plotagens',
      href: `/obras/plotagens/${constructionSiteId}`,
    },
    {
      description: `${breadCrumb?.Name}`,
      href: `/obras/plotagens/filelist/${breadCrumb?.PreviousItem?.Identifier}/${breadCrumb?.Identifier}`,
    },
    {
      description: `Recebimento de plotagem`,
      href: `/obras/plotagens/filelist/receber/${breadCrumb?.PreviousItem?.Identifier}/${breadCrumb?.Identifier}`,
    },
  ];

  return data;
};

export const getbreadCrumbLoading = ():  IBreadcrumbItem[] => {
  const data: IBreadcrumbItem[] = [
    {
      href: '',
      isLoading: true,
    },
    {
      href: '',
      isLoading: true,
    },
    {
      href: '',
      isLoading: true,
    },
    {
      href: '',
      isLoading: true,
    },
  ];

  return data;
};
