import { FC } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  BtnContainer,
  Container,
  Content,
  Title,
} from "./styles";
import { Icon } from "Components/UI";

interface IDeleteModal {
  visible: boolean;
  loading?: boolean;
  name: string;
  stageId: number;
  isDefault?: boolean;
  onConfirm: (stageId: number) => void;
  onCancel: () => void;
}

const DeleteModal: FC<IDeleteModal> = ({
  visible,
  loading,
  name,
  stageId,
  isDefault,
  onConfirm,
  onCancel,
}) => {

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">
            {`Excluir etapa “${name}”?`}
          </span>
        </Title>

        <Content isdefault={isDefault}>
          <span className="contentText">
            {isDefault
              ? 'A etapa selecionada não pode ser excluída pois está definida como uma etapa padrão. É necessário sempre existir uma etapa padrão no cadastro.'
              : 'Você tem certeza que deseja excluir a etapa? Isso fará com que a etapa seja excluída permanentemente do sistema, o que também fará que as atividades e as nomenclaturas dos arquivos percam o vínculo com a etapa.'
            }
          </span>
          <span className="warningBox">
            <Icon
              icon={isDefault ? 'informacao' : 'alerta'}
              customSize={16}
              className="alertIcon"
            />
            <span className="alertText">
              {isDefault
                ? 'Caso queira excluir a etapa, defina outra etapa como padrão antes de excluir essa etapa.'
                : 'Essa ação é permanente e não pode ser desfeita.'
              }
            </span>
          </span>
        </Content>

        <BtnContainer>
          <Button type="text" onClick={onCancel}>
            {isDefault ? 'Fechar' : 'Cancelar'}
          </Button>
          {!isDefault && (
            <Button
              type="primary"
              className="deleteBtn"
              onClick={() => onConfirm(stageId)}
              primaryDanger
            >
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      rev=""
                      color="white"
                      style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                    />
                  }
                />
              )}
              {loading ? 'Excluindo...' : 'Excluir'}
            </Button>
          )}
        </BtnContainer>
      </Container>
    </Modal>
  )
}

export default DeleteModal;
