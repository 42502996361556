import React, { FC } from "react";
import { Collapse, List, ConfigProvider } from "antd";
import { Icon } from "Components/UI";
import HighlighterWords from "Components/UI/HighlightWords";
import styles from './ModelsViews.module.scss';
import { IChildrenNames, IDrawerOptions, IView } from "Pages/FederatedViewer/useFederatedViewer";
import { IViews, IFoldersBubleNodesViews } from "Data/interfaces/FederatedViewer/IViews";
import Ellipsis from "Components/UI/Ellipsis";
import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

interface IModelsViews {
  listItems: IViews[];
  searchItems: string;
  childrenNames: IChildrenNames[];
  activeDrawer?: IDrawerOptions;
  toggleView: IView;
  onToggleView: (view: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
}

const ModelsViews: FC<IModelsViews> = ({
  listItems,
  searchItems,
  childrenNames,
  activeDrawer,
  toggleView,
  onToggleView,
}) => {
  const renderEmpty = () => {
    return <></>
  }

  const isActive = (urn: string, id: number) => {
    return (toggleView.urn === urn && toggleView.id === id);
  }

  const builderList = (
    urn: string, 
    item?: IFoldersBubleNodesViews, 
    level?: number,
    totalItems?: number,
  ) => {
    if (item && level && totalItems) {
      const currentLevel = level - 1;

      if (item.views && item.views.length > 0) {
        const is2dRootViews = level === 1 && item.name === 'Views 2D';
        const is3dRootViews = level === 1 && item.name === 'Views 3D';
        const filteredViews = item.views?.filter((view) => {
          return view.name().toLowerCase().includes(searchItems.toLowerCase());
        });
  
        return filteredViews.length > 0 ? (
          <Collapse 
            key={`${item.name}-${level}`}
            bordered={false}
            expandIcon={({ isActive }) => 
              <CaretRightOutlined
                rev=""
                rotate={isActive ? 90 : 0} 
                style={{
                  fontSize: '10px',
                  marginLeft: '8px',
                  marginRight: '17px',
                  color: '#BEC2C6',
                }}
              />
            }
            className={styles['childrenCamerasCollapse']}
            style={{marginLeft: `${(currentLevel * 10) + 8}px`}}
          >
            <Panel 
              key={item.name} 
              header={(
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {is2dRootViews && (
                    <Icon 
                      icon="arquivo2d"
                      customColor={'#8697A8'}
                      customSize={16}
                      style={{marginRight: 10}}
                    />
                  )}
                  {is3dRootViews && (
                    <Icon 
                      icon="arquivo3d"
                      customColor={'#8697A8'}
                      customSize={16}
                      style={{marginRight: 10}}
                    />
                  )}
                  <Ellipsis phrase={is2dRootViews ? 'Views 2D' : item.name} maxLength={32} />
                </div>
              )} 
              className={styles['childrenCamerasPanel']}
            >
              <List 
                itemLayout="horizontal"
                className={styles['listPanel']}
                dataSource={filteredViews}
                bordered={false}
                renderItem={(item, index) => {
                  return (
                    <List.Item 
                      key={`${item.name()}-${index}`}
                      className={`
                        ${styles['childrenList']}
                        ${isActive(urn, item.id) ? styles['childrenListActive'] : ''}
                      `}
                      onClick={() => onToggleView(item, urn, item.id)}
                    >
                      <div className={styles['childrenListWrapper']}>
                        <div 
                          className={styles['childrenListWrapperLeft']}
                        >
                          <Icon 
                            icon="viewpointsItens"
                            customColor={isActive(urn, item.id) ? '#324150' : '#8697A8'}
                            customSize={12}
                          />
                          <HighlighterWords 
                            text={item.name()} 
                            wordSearched={searchItems} 
                            className={styles['listLeftText']} 
                            maxLength={28}
                          />
                        </div>
                        {isActive(urn, item.id) && (
                          <div className={styles['childrenListWrapperRight']}>
                            <Icon 
                              icon="visualizar"
                              customColor={'#3547C8'}
                              customSize={10}
                            />
                          </div>
                        )}
                      </div>
                    </List.Item>
                  )
                }}
              />
            </Panel>
          </Collapse>
        ) : <></>;
      } else if (item.folders && item.folders.length > 0) {
        const is2dRootViews = level === 1 && item.name === 'Views 2D';
        const is3dRootViews = level === 1 && item.name === 'Views 3D';
        return (
          <Collapse 
            key={`${item.name}-${level}`}
            bordered={false}
            expandIcon={({ isActive }) => 
              <CaretRightOutlined
                rev=""
                rotate={isActive ? 90 : 0} 
                style={{
                  fontSize: '10px',
                  marginLeft: '8px',
                  marginRight: '17px',
                  color: '#BEC2C6',
                }}
              />
            }
            className={styles['childrenCamerasCollapse']}
            style={{marginLeft: `${(currentLevel * 10) + 8}px`}}
          >
            <Panel 
              key={item.name} 
              header={(
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {is2dRootViews && (
                    <Icon 
                      icon="arquivo2d"
                      customColor={'#8697A8'}
                      customSize={16}
                      style={{marginRight: 10}}
                    />
                  )}
                  {is3dRootViews && (
                    <Icon 
                      icon="arquivo3d"
                      customColor={'#8697A8'}
                      customSize={16}
                      style={{marginRight: 10}}
                    />
                  )}
                  <Ellipsis phrase={is2dRootViews ? 'Views 2D' : item.name} maxLength={32} />
                </div>
              )} 
              className={styles['childrenCamerasPanel']}
            >
              <List 
                itemLayout="horizontal"
                className={styles['listFolder']}
                dataSource={item.folders}
                bordered={false}
                renderItem={(folder, index) => {
                  return builderList(urn, folder, level + 1, totalItems);
                }}
              />
            </Panel>
          </Collapse>
        );
      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }

  return (
    <>
      <div className={styles['headerWrapper']}>
        <Icon 
          icon="viewPoint"
          customColor={'#BEC2C6'}
          customSize={16}
        />
        <span>
          {activeDrawer}
        </span>
        <Icon 
          icon="informacaoAdicional"
          customColor={'#BEC2C6'}
          customSize={14}
        />
      </div>
      <ConfigProvider renderEmpty={() => renderEmpty()}>
        {listItems.map((views, index) => {
          const name = childrenNames.find(name => name.urn === views.urn)?.name || '';
          
          return (
            <Collapse 
              key={`${name}-${index}`}
              bordered={false}
              expandIcon={({ isActive }) => 
                <RightOutlined
                  rev=""
                  rotate={isActive ? 90 : 0} 
                  style={{
                    fontSize: '10px',
                    marginLeft: '8px',
                    marginRight: '17px',
                    color: '#BEC2C6',
                  }}
                />
              }
              accordion
              destroyInactivePanel
              className={styles['childrenCollapse']}
            >
              <Panel 
                key={name} 
                header={<Ellipsis className={styles['childrenPanelText']} phrase={name} maxLength={32} />} 
                className={styles['childrenPanel']}
              >
                <List 
                  itemLayout="horizontal"
                  className={styles['listFolder']}
                  dataSource={views.folders}
                  bordered={false}
                  renderItem={(child) => {
                    return builderList(views.urn, child, 1, listItems.length);
                  }}
                />
              </Panel>
            </Collapse>
          )
        })}
      </ConfigProvider>
    </>
  )
}

export default ModelsViews;
