import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'Components/UI';
import Ellipsis from 'Components/UI/Ellipsis';
import Button from 'Components/UI/Button';
import ListButtons, {
  TypeButton,
  TypeListButtons,
} from 'Components/UI/ListButtons';
import { IPanelsFileInfo } from 'Data/interfaces/Panels/IPanelsFileInfo';
import { HistoryActions } from 'Store/History/History.actions';
import { Container, Left, Right } from './styles';

export const BUTTON_PANEL_PDF_LIST_KEY = 'BUTTON_PANEL_PDF_LIST_KEY';

interface IHeader {
  file: IPanelsFileInfo;
  buttons: TypeButton;
  activeListButton: TypeListButtons;
  onActiveListButton: (value: TypeListButtons) => void;
  onOpenFolder: (folderId: string) => void;
}

const Header: FC<IHeader> = ({
  file,
  buttons,
  activeListButton,
  onActiveListButton,
  onOpenFolder,
}) => {
  const dispatch = useDispatch();

  const handleOpenHistoryDrawer = () => {
    dispatch(HistoryActions.listHistoryFileRequest({
      title: 'Histórico de Arquivo',
      parentName: file.fileName,
      itemId: file.fileIdentifier,
      isFolder: false,
    }));
  }

  const isPdf = file.extension === 'pdf';

  return (
    <>
      <Container>
        <Left>
          <Icon
            customSize={16}
            icon="arquivoPdf"
            className="iconFile"
          />
          <Ellipsis
            phrase={file.fileName}
            maxLength={52}
            className="fileName"
          />
        </Left>
        <Right>
          <Button
            type="text"
            className="btnFolder"
            onClick={() => onOpenFolder(file.fileInfo.ApiFolderId)}
          >
            <Icon
              customSize={12}
              icon="arquivar"
              className="iconFolder"
            />
          </Button>
          <Button
            type="text"
            className="btnHistory"
            onClick={handleOpenHistoryDrawer}
          >
            <Icon
              customSize={16}
              icon="relogio"
              className="iconHistory"
            />
            <span className="textHistory">Histórico</span>
          </Button>
          {isPdf && (
            <div className="btnListContainer">
              <ListButtons
                storageKey={BUTTON_PANEL_PDF_LIST_KEY}
                buttons={buttons}
                activeButton={activeListButton}
                onActiveOption={onActiveListButton}
              />
            </div>
          )}
        </Right>
      </Container>
    </>
  );
};

export default Header;
