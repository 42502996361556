import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import PageHeader from "Components/UI/PageHeader/PageHeader";
import TableActionTopbar, { ITableActionTopbarButton } from "Components/UI/TableActionTopbar";
import { AdmActions } from "Store/Adm/Adm.actions";
import DeleteForm from "./Modals/DeleteForm";
import CreateForm from "./Modals/CreateForm";
import { history } from "Store";
import Library from "./components/Library";
import { TableWrapper } from "./styles";
import { IConstructionSiteStageLibrary } from "Data/interfaces/Obra/IObraLibraries";

const Stages: FC<Props> = ({
  libraries,
  listLibrariesLoading,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const [libEdit, setLibEdit] = useState<IConstructionSiteStageLibrary | null>(null);
  const [showCreateOrUpdateLibModal, setShowCreateOrUpdateLibModal] = useState(false);
  const [showDeleteLibModal, setShowDeleteLibModal] = useState(false);

  const getActionsToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        icon: 'voltar',
        description: 'Voltar para Controle Administrativo de Tenants',
        action: () => history.push('/adm/tenants'),
      },
      {
        icon: 'arquivar',
        description: 'Criar Biblioteca',
        action: newLib,
      },
    ];
  };

  const newLib = () => {
    setShowCreateOrUpdateLibModal(true);
  }

  const updateLib = (lib: IConstructionSiteStageLibrary) => {
    setLibEdit(lib)
    setShowCreateOrUpdateLibModal(true);
  }

  const deleteLib = (lib: IConstructionSiteStageLibrary) => {
    setLibEdit(lib)
    setShowDeleteLibModal(true);
  }

  const closeModal = () => {
    setLibEdit(null);
    setShowCreateOrUpdateLibModal(false);
    setShowDeleteLibModal(false);
  }

  useEffect(() => {
    dispatch(AdmActions.listLibrary());
  }, []);

  return (
    <>
      <PageHeader name="Controle Administrativo das Etapas" />

      <TableWrapper>
        <TableActionTopbar buttons={getActionsToolbar()} />
        <Library
          libraries={libraries}
          loading={isLoading}
          listLoading={listLibrariesLoading}
          onOpen={updateLib}
          onDelete={deleteLib}
        />
      </TableWrapper>

      {showCreateOrUpdateLibModal && (
        <CreateForm
          visible={showCreateOrUpdateLibModal}
          editLib={libEdit}
          onClose={closeModal}
        />
      )}

      {showDeleteLibModal && libEdit && (
        <DeleteForm
          visible={showDeleteLibModal}
          isLoading={isLoading}
          deleteLib={libEdit}
          onCancel={closeModal}
        />
      )}
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Stages);
