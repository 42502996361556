import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background: #FFFFFF;
  border: solid 1px #EAEDF0;
  margin-top: 6px;

  .column1 {
    width: 41px;
    margin-left: 16px;
  }
  .column2 {
    width: 62px;
    margin-left: 12px;
  }
  .column3 {
    display: flex;
    width: 40%;
    margin-left: 12px;
  }
  .column4 {
    display: flex;
    width: 40%;
    margin-left: 12px;
  }
  .column5 {
    width: 100px;
    padding-left: 12px;
    padding-right: 10px;
  }
`;
