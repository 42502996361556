import { Switch } from "antd";
import styled, { css } from "styled-components";

interface IContainer {
  marginTop?: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  margin-top: ${({marginTop}) => marginTop || 0}px;

  &+& {
    margin-top: 16px;
  }
`;

export const Title = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({theme}) => theme.colors.surface.onSurface};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .customRowBtn {
    margin-top: 12px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  .searchBox {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline} !important;
    background-color: ${({ theme }) => theme.colors.surface.surface} !important;
    
    .search {
      background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    }
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  .column1 {
    width: 41px;
    margin-left: 16px;
  }
  .column2 {
    width: 62px;
    margin-left: 12px;
  }
  .column3 {
    display: flex;
    width: 40%;
    margin-left: 12px;
  }
  .column4 {
    display: flex;
    width: 40%;
    margin-left: 12px;
  }
  .column5 {
    width: 100px;
    padding-left: 12px;
    padding-right: 10px;
  }

  .columnText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

interface IDivider {
  nottop?: boolean;
}

export const Divider = styled.div<IDivider>`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 24px 0;

  ${({nottop}) => nottop && css`
    margin-top: 14px;
  `}
`;

interface IBtnContainer {
  margintop?: number;
}

export const BtnContainer = styled.div<IBtnContainer>`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${({margintop}) => margintop || 16}px;

  .libBtn {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  .configBtn {
    display: flex;
    align-items: center;
  }

  .actionIcon {
    color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
  }

  .textBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
    margin-left: 6px;
  }

  .addBtn {
    display: flex;
    align-items: center;

    :disabled {
      .textBtn {
        color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
      }
      .actionIcon {
        color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }
  }

  .textConfigBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
  }

  .configIcon {
    color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    margin-left: 6px;
  }
`;

export const Warning = styled.div`
  width: 100%;
  height: 36px;
  padding: 10px 14px;
  border-radius: 5px;
  margin-top: 24px;
  border: 1px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.warning.warningOutline}, ${theme.colors.surface.surface})`} !important;
  background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.warning.warningContainer}, ${theme.colors.surface.surface})`} !important;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.surface.onSurface};
`;

export const SwitchContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  .textContent {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .title {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .text {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      margin-top: 6px;
    }
  }
`;

export const SwitchStyled = styled(Switch)`
  ${({checked}) => checked && css`
    background-color: ${({theme}) => theme.colors.primary.primary} !important;
  `}
`;
