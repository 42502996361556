import { useCallback, useMemo, useState } from 'react';
import {
  complexfilterActivities,
  filterActivities,
  sortActivities,
} from 'Utils/ActivitiesUtils';
import { getColumnsTableActivities } from './components/Columns/ListVision.columns';
import CustomEmptyData from './components/CustomEmptyData';
import {
  IDragAndDropDataFormat,
  ITopicDnDResponse,
} from 'Data/interfaces/Activities/IDragAndDropDataFormat';
import { useSelector } from 'react-redux';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import {
  IFiltroOptions,
  enumToStringValueMap,
} from 'Data/interfaces/Activities/IFilters';
import useImgOnErrorControl from 'Hooks/useImgOnErrorControl';

export type TypeOrderType = "Identifier" |
  "Title" |
  "Type" |
  "Priority" |
  "ConstructionSiteDisciplines" |
  "TopicAssignedUsers" |
  "DueDate" |
  "Status";

interface IOrderOpt {
  type?: TypeOrderType;
  sortDirection?: boolean;
}

interface IUseListVision {
  listActivities?: IDragAndDropDataFormat;
  height: number;
  searchActivity: string;
  onOpenCreateActivity: () => void;
  onOpenShowActivity: (activity: string) => void;
  filters?: Record<string, IFiltroOptions<ITopicDnDResponse>>;
  showFilters?: boolean;
  hasFilters: boolean;
}

const useListVision = ({
  listActivities,
  height,
  searchActivity,
  onOpenCreateActivity,
  onOpenShowActivity,
  filters,
  showFilters,
  hasFilters,
}: IUseListVision) => {
  const { handleImgOnError, imgsOnErrorControl } = useImgOnErrorControl();
  const theme = useSelector(getTheme);

  const [orderOpt, setOrderOpt] = useState<IOrderOpt | null>(null);
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);

  const [showPreview, setShowPreview] = useState<Map<string, boolean>>(new Map());

  const handlePreview = useCallback((id: string, visible: boolean) => {
    setShowPreview(prevState => {
      prevState.set(id, visible);
      return new Map(prevState);
    });
  }, []);

  const listActivitiesFormat = useMemo(() => {
    if (listActivities?.activities && JSON.stringify(listActivities.activities) !== '{}') {
      const arrayList = Object.values(listActivities.activities);
      return arrayList.sort(sortActivities);
    }
    return [];
  }, [listActivities]);

  const handleOrderOpt = (orderType?: TypeOrderType, sorterOrder?: boolean) => {
    setOrderOpt({ type: orderType, sortDirection: sorterOrder });
  }

  const columns = useMemo(() => {
    return getColumnsTableActivities({
      searchActivity,
      theme,
      currentAttachmentIndex,
      imgsOnErrorControl,
      showPreview,
      onOpenShowActivity,
      onOrderOpt: handleOrderOpt,
      setCurrentAttachmentIndex,
      onImgOnError: handleImgOnError,
      onPreview: handlePreview,
    });
  }, [
    searchActivity,
    theme,
    currentAttachmentIndex,
    imgsOnErrorControl,
    showPreview,
    onOpenShowActivity,
    handleImgOnError,
    handlePreview,
  ]);

  const sorterActivitiesTable = useCallback((sortOrder?: boolean) => {
    return {
      Identifier: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        const identifierA = Number(a.Identifier);
        const identifierB = Number(b.Identifier);

        if (identifierA && b.Identifier) {
          return sortOrder ? identifierB - identifierA : identifierA - identifierB;
        } else if (identifierA) {
          return -1;
        } else if (identifierB) {
          return 1;
        }
        return 0;
      },
      Title: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        return a.Title.localeCompare(b.Title) * (sortOrder ? -1 : 1);
      },
      Priority: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        if (a.Priority && b.Priority) {
          return sortOrder ? b.Priority - a.Priority : a.Priority - b.Priority;
        } else if (a.Priority) {
          return -1;
        } else if (b.Priority) {
          return 1;
        }
        return 0;
      },
      DueDate: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        const dueDateA = a.DueDate ? new Date(a.DueDate).getTime() : 0;
        const dueDateB = b.DueDate ? new Date(b.DueDate).getTime() : 0;

        if (a.DueDate && b.DueDate) {
          return sortOrder ? dueDateB - dueDateA : dueDateA - dueDateB;
        } else if (a.DueDate) {
          return -1;
        } else if (b.DueDate) {
          return 1;
        }
        return 0;
      },
      ConstructionSiteDisciplines: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        const disciplinesA = a.ConstructionSiteDisciplines || [];
        const disciplinesB = b.ConstructionSiteDisciplines || [];

        if (disciplinesA.length === 0 && disciplinesB.length === 0) {
          return 0;
        } else if (disciplinesA.length === 0) {
          return disciplinesB.length > 0 ? sortOrder ? -1 : 1 : 0;
        } else if (disciplinesB.length === 0) {
          return sortOrder ? 1 : -1;
        }

        const minLength = Math.min(disciplinesA.length, disciplinesB.length);

        for (let i = 0; i < minLength; i++) {
          const nameA = disciplinesA[i].CustomName || disciplinesA[i].Discipline.Name || '';
          const nameB = disciplinesB[i].CustomName || disciplinesB[i].Discipline.Name || '';
          const disciplineComparison = nameA.localeCompare(nameB) * (sortOrder ? -1 : 1);
          if (disciplineComparison !== 0) {
            return disciplineComparison;
          }
        }

        return sortOrder ? disciplinesB.length - disciplinesA.length : disciplinesA.length - disciplinesB.length;
      },
      TopicAssignedUsers: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        const usersA = a.TopicAssignedUsers || [];
        const usersB = b.TopicAssignedUsers || [];

        if (usersA.length === 0 && usersB.length === 0) {
          return 0;
        } else if (usersA.length === 0) {
          return usersB.length > 0 ? sortOrder ? -1 : 1 : 0;
        } else if (usersB.length === 0) {
          return sortOrder ? 1 : -1;
        }

        const minLength = Math.min(usersA.length, usersB.length);

        for (let i = 0; i < minLength; i++) {
          const nameA = usersA[i].Nome || '';
          const nameB = usersB[i].Nome || '';
          const userComparison = nameA.localeCompare(nameB) * (sortOrder ? -1 : 1);
          if (userComparison !== 0) {
            return userComparison;
          }
        }

        return sortOrder ? usersB.length - usersA.length : usersA.length - usersB.length;
      },
      Status: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        return sortOrder ? b.Status.Order - a.Status.Order : a.Status.Order - b.Status.Order;
      },
      Type: (a: ITopicDnDResponse, b: ITopicDnDResponse) => {
        return a.Type.Name.localeCompare(b.Type.Name) * (sortOrder ? -1 : 1);
      },
    }
  }, []);

  const filteredActivities = useMemo(() => {
    let orderedListActivities = complexfilterActivities<ITopicDnDResponse, typeof enumToStringValueMap>({
      filters,
      array: listActivitiesFormat,
      enumToStringValueMap,
    });

    if (searchActivity) {
      orderedListActivities = filterActivities(orderedListActivities, searchActivity);
    }
    if (orderOpt?.type) {
      orderedListActivities = orderedListActivities.sort(sorterActivitiesTable(orderOpt?.sortDirection)[orderOpt.type]);
    }
    return orderedListActivities;
  }, [
    filters,
    listActivitiesFormat,
    searchActivity,
    orderOpt?.type,
    orderOpt?.sortDirection,
    sorterActivitiesTable,
  ]);

  const renderEmpty = useCallback(() => {
    const customDecreasedHeight = showFilters ? 394 : 351;

    if (searchActivity) {
      return <CustomEmptyData
        search
        height={height - customDecreasedHeight}
        searchText={searchActivity}
      />;
    }

    if (hasFilters) {
      return <CustomEmptyData
        height={height - customDecreasedHeight}
        hasFilters={hasFilters}
      />;
    }

    return <CustomEmptyData
      height={height - customDecreasedHeight}
      onOpenCreateActivity={onOpenCreateActivity}
    />;
  }, [searchActivity, showFilters, hasFilters, height]);

  return {
    columns,
    filteredActivities,
    currentAttachmentIndex,
    setCurrentAttachmentIndex,
    renderEmpty,
  };
}

export default useListVision;
