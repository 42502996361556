import React, { FC, CSSProperties } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

import styles from './BoxContainer.module.scss';

export interface IBoxContainer {
  height?: number;
  width?: number;
  children?: React.ReactNode;
  style?: CSSProperties;
}

const BoxContainer: FC<IBoxContainer> = ({ height, width, style, children }) => {
  const heightBox = height ?? 282;
  const widthBox = width ?? 244;
  return (
    <div className={styles['BoxContainerWrapper']}>
      <ContextMenuTrigger id='unique-id'>
        <section className={styles['BoxContainer']} style={{...style, height: heightBox, width: widthBox}}>
          {children}
        </section>
      </ContextMenuTrigger>
    </div>
  );
};

export default BoxContainer;
