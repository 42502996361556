import { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { Buttons, FooterHr, Wrapper } from "./styles";
import { ITopicAttachment } from "Data/interfaces/Activities/IDragAndDropDataFormat";

interface IConfirmDeleteAttachment {
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  attachment: ITopicAttachment;
}

const ConfirmDeleteAttachment: FC<IConfirmDeleteAttachment> = ({
  visible,
  loading,
  onCancel,
  onConfirm,
  attachment,
}) => {
  const footerConfirmButtons = (
    <Buttons>
      <Button
        onClick={onCancel}
        type="text"
        className="buttonCancel"
      >
        Cancelar
      </Button>
      <Button
        id="deleteBtn"
        type="primary"
        htmlType="submit"
        onClick={onConfirm}
        className="buttonSubmit"
        loading={loading}
        danger
      >
        Excluir
      </Button>
    </Buttons>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      footerActive={false}
      width={400}
      customClassName
    >
      <Wrapper>
        <span className="title">
          {`Excluir anexo ${attachment.File.Name}`}
        </span>
        <hr className="midleHr"/>
        <span className="description">
          Você tem certeza que deseja excluir o anexo? Essa ação não poderá ser desfeita.
        </span>
      </Wrapper>
      <FooterHr />
    </Modal>
  )
}

export default ConfirmDeleteAttachment;
