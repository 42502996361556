export const stringToCNPJ = (data: string) =>{
    if(!data || data == '') return data;
    let NewCNPJ;

    //Remove tudo o que não é dígito
    NewCNPJ = data.replace(/\D/g,""); 

    //Coloca ponto entre o segundo e o terceiro dígitos
    NewCNPJ = NewCNPJ.replace(/(\d{2})(\d)/,"$1.$2"); 
    
    //Coloca ponto entre o quinto e o sexto dígitos
    NewCNPJ = NewCNPJ.replace(/(\d{2}).(\d{3})(\d)/,"$1.$2.$3"); 
    
    //Coloca uma barra entre o oitavo e o nono dígitos
    NewCNPJ = NewCNPJ.replace(/.(\d{3})(\d)/,".$1/$2"); 
    
    //Coloca um hífen depois do bloco de quatro dígitos
    NewCNPJ = NewCNPJ.replace(/(\d{4})(\d)/,"$1-$2"); 

    return NewCNPJ;    
};

export const stringToCPF = (data: string) =>{
    if(!data || data == '') return data;
    let NewCPF;

    //Remove tudo o que não é dígito
    NewCPF = data.replace(/\D/g,""); 

    //Coloca um ponto entre o terceiro e o quarto dígitos
    NewCPF = NewCPF.replace(/(\d{3})(\d)/,"$1.$2");

    //Coloca um ponto entre o terceiro e o quarto dígitos(para o segundo bloco de números)
    NewCPF = NewCPF.replace(/(\d{3})(\d)/,"$1.$2");

    //Coloca um hífen entre o terceiro e o quarto dígitos
    NewCPF = NewCPF.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

    return NewCPF;
}; 

export const stringToPhone = (data: string) =>{
    if(!data || data == '') return data;
    let newPhone;

    //Remove tudo o que não é dígito
    newPhone = data.replace(/\D/g,""); 

    //Coloca parênteses em volta dos dois primeiros dígitos
    newPhone = newPhone.replace(/^(\d{2})(\d)/g,"($1) $2");
    
    //Coloca hífen entre o quarto e o quinto dígitos
    newPhone = newPhone.replace(/(\d)(\d{4})$/,"$1-$2");    
    
    return newPhone;
};

export const stringToCEP = (data: string) =>{
    if(!data || data == '') return data;
    let newCEP;

    //Remove tudo o que não é dígito
    newCEP = data.replace(/\D/g,""); 

    //Esse é tão fácil que não merece explicações
    newCEP = newCEP.replace(/(\d)(\d{3})$/,"$1-$2"); 

    return newCEP;
};

export const cleanMask = (data: string) => {
    if(!data || data == '') return data;
    return data.replace(/\D/g,"");
}
