import { call, put } from 'redux-saga/effects';
import { MinhaContaApi } from '../../Data/MinhaConta.api';
import {
  MinhaContaActions,
  NotifyUserConversionAction,
  NovaSenhaAction,
  UpdateDadosEmpresaAction,
  UpdateDadosPessoaisAction
} from './MinhaConta.actions';
import toastHandler from '../../Utils/toastHandler';
import { loadError } from '../../Utils/Messages';
import { TenantActions } from '../Tenant/Tenant.actions';
import { store } from 'Store';
import { AuthActions } from 'Store/Auth/Auth.actions';


export function* getUserInfo() {
  try {
    const { data } = yield call(MinhaContaApi.getUserInfo);
    yield put(MinhaContaActions.getUserInfoSuccess(data.data));
  } catch {
    yield put(MinhaContaActions.getUserInfoFailure('Erro ao carregar informações do usuário'));
  }
}

export function* notifyUserConversion({ payload }: NotifyUserConversionAction) {
  try {
    yield call(MinhaContaApi.notifyUserConversion, payload);
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('Erro ao enviar a solicitação de update de conta');
    toastHandler.showError(error);
  }
}

export function* novaSenha({ payload }: NovaSenhaAction) {
  try {
    const { data } = yield call(MinhaContaApi.novaSenha, payload);
    yield put(MinhaContaActions.setShowModalUpdatePassword(false));
    yield put(MinhaContaActions.novaSenhaSuccess(data.data));
    toastHandler.showSuccess('Alteração efetuada com sucesso');
  } catch (e: any) {
    yield put(MinhaContaActions.novaSenhaFailure(e));
    const error = e.errors ? e.errors[0].Message : loadError('Erro ao efetuar a alteração');
    toastHandler.showError(error);
  }
}

export function* updateDadosPessoais({ payload }: UpdateDadosPessoaisAction) {
  try {
    const { data } = yield call(MinhaContaApi.updateDadosPessoais, payload);
    yield put(MinhaContaActions.setShowModalUpdateConta(false));
    yield put(MinhaContaActions.updateDadosPessoaisSuccess(data.data));
    toastHandler.showSuccess('Alteraçães efetuada com sucesso');
  } catch (e: any) {
    yield put(MinhaContaActions.updateDadosPessoaisFailure(e));
    const error = e.errors ? e.errors[0].Message : loadError('Erro ao efetuar a alterações');
    toastHandler.showError(error);
  }
}

export function* updateDadosEmpresa({ payload }: UpdateDadosEmpresaAction) {
  try {
    const { data } = yield call(MinhaContaApi.updateDadosEmpresa, payload);
    const userInfoUpdate = store.getState().auth.userInfo;
    if (userInfoUpdate && data.data) {
      userInfoUpdate.Company = data.data.Company;
      userInfoUpdate.isRegisterComplete = data.data.isRegisterComplete;
      yield put(AuthActions.getUserInfoSuccess(userInfoUpdate));
    }
    yield put(MinhaContaActions.setShowModalUpdateEmpresa(false));
    yield put(MinhaContaActions.updateDadosEmpresaSuccess(data.data));
    yield put(TenantActions.listUserTenants());
    toastHandler.showSuccess('Alteraçães efetuada com sucesso');
  } catch (e: any) {
    yield put(MinhaContaActions.updateDadosEmpresaFailure(e));
    const error = e.errors ? e.errors[0].Message : loadError('Erro ao efetuar a alterações');
    toastHandler.showError(error);
  }
}
