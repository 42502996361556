import styled, { css } from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 52px;
`;

export const CarrouselWrapper = styled.div`
  position: relative;

  .iconBtnPrev {
    position: absolute;
    top: 107px;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    
    .iconPrev {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  }

  .iconBtnNext {
    position: absolute;
    top: 107px;
    right: 0;
    width: 28px;
    height: 28px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;

    .iconNext {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  }
`;

export const ImgCardCarousel = styled.div`
  position: relative;
  width: 422px;
  height: 283px;
  display: flex;
  flex-direction: column;
  border: none !important;
  cursor: zoom-in;
  
  .image {
    position: relative;
    width: 422px;
    height: 243px;
    
    img {
      width: 422px;
      min-width: 422px;
      max-width: 422px;
      height: 243px;
      min-height: 243px;
      max-height: 243px;
      object-fit: contain;
      z-index: 1;
    }
  }

  .imgContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;

    .imgTextContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: normal;
      overflow: hidden;

      .textName {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px; 
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }

      .imgTextCreatorContent {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 4px;

        .textCreator {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; 
          color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
          margin-left: 6px;
        }
      }
    }
  }
`;

export const MidleHr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin: 10px 0;
`;

export const ListContent = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
`;

interface IImgCard {
  isprogress?: string;
  haserror?: string;
  active?: string;
}

export const ImgCard = styled.div<IImgCard>`
  position: relative;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 56px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  
  &+& {
    margin-left: 6px;
  }
  
  .image {
    position: relative;
    width: 80px;
    height: 56px;
    
    img {
      width: 80px;
      min-width: 80px;
      max-width: 80px;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
      object-fit: contain;
      z-index: 1;
    }
    
    .progressBar {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      margin-bottom: 0;
      z-index: 2;
    }
  
    ${({ isprogress }) => isprogress === 'true' && css`
      .progressMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
        z-index: 3;
      }
    `}
  
    ${({ haserror }) => haserror === 'true' && css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      min-height: 56px;
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerContainer}, ${theme.colors.surface.surface})`};
    `}
  }
  
  .buttonCancelOrDelete {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 0;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    z-index: 3;
    display: none;

    .iconCancelOrDelete {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }
  
  .btnOpenActionAttachment {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 0;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .iconOpenActionAttachment {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }

    :focus {
      border: none !important;
    }
  }

  .btnOpenActionAttachment {
    display: none;
  }

  :hover {
    .buttonCancelOrDelete {
      display: flex;
    }

    .btnOpenActionAttachment {
      display: flex;
    }

    .ActionAttachmentDropdown {
      display: flex;
    }

    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`} !important;
  }
  
  ${({ active }) => active === 'true' && css`
    border: 1px solid ${({ theme }) => theme.colors.primary.primary};
  `}
`;
