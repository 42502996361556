import styled from "styled-components";

export const MainWrapper = styled.div`
  padding: 30px 40px;

  p {
    font-size: 14px;
    font-weight: 600;
    
    &+p {
      margin-top: 25px;
      margin-bottom: 0;
    }
  }
`;

export const FooterButtons = styled.div`
  .cancel-button {
    border-radius: 5px;
    width: 123px;
  }

  .confirm-button {
    border-radius: 5px;
    width: 184px;
  }
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.surface};
  padding: 20px;
  border-radius: 8px;
  width: 311px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 55px;
  top: 202px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .titleContent {
      font-size: 14px;
      line-height: 19px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .textContent {
      margin-top: 8px;
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    }

    .close {
      width: 71px;
      height: 36px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};
      border: none;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      cursor: pointer;
    }
  }

  .contentBlockedWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tag {
      width: 122px;
      height: 18px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      font-size: 8px;
      line-height: 11px;
      font-weight: 700;
      text-transform: uppercase;

      i {
        margin-right: 7px;
      }
    }

    .titleBlockedContent {
      margin-top: 15px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    p {
      margin-top: 8px;
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};

      &+p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    .close {
      width: 71px;
      height: 36px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};
      border: none;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      cursor: pointer;
    }
  }
`;
