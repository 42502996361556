import React, { FC } from 'react';
import { Icon } from '../../../UI';

import styles from './Search.module.scss';

interface ISearch {
  placeholder?: string;
  className?: string;
  value?: string;
  changeValue?: (value?: string) => void;
}

const Search: FC<ISearch> = ({ placeholder= 'Buscar na Coordly', className = '', value = '', changeValue }) => {
  return (
    <div className={`${styles['Search']} ${className}`}>
      <Icon icon='buscar' customSize={18} color='cinzaSuperior' className={styles['icon']} />
      <input
        placeholder={placeholder}
        value={value}
        onChange={(event: any) => changeValue && changeValue(event.currentTarget.value)}/>
    </div>
  );
};

export default Search;
