import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IUpdateFileList } from './interfaces/IUpdateStatusFileList';

export enum UpdateStatusActionKeys {
  UPDATE_STATUS_FILE_LIST_REQUEST = 'UPDATE_STATUS_FILE_LIST_REQUEST',
  UPDATE_STATUS_FILE_LIST_SUCCESS = 'UPDATE_STATUS_FILE_LIST_SUCCESS',
  UPDATE_STATUS_FILE_LIST_FAILURE = 'UPDATE_STATUS_FILE_LIST_FAILURE',
}

export const UpdateStatusActions = {
  updateStatus: ({ requestFileList }: IUpdateFileList): UpdateStatusRequestAction => createAction(UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_REQUEST, { requestFileList }),
  updateStatusSuccess: (date: string): UpdateStatusSuccessAction => createAction(UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_SUCCESS, date),
  updateStatusFailure: (err: string): UpdateStatusFailureAction => createAction(UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_FAILURE, err),
}

export type UpdateStatusActionUnion = ActionsUnion<typeof UpdateStatusActions>;

export type UpdateStatusRequestAction = Action<UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_REQUEST, IUpdateFileList>;
export type UpdateStatusSuccessAction = Action<UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_SUCCESS, string>;
export type UpdateStatusFailureAction = Action<UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_FAILURE, string>;
