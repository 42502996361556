import { FC } from "react";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { Tag } from "antd";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { useSelector } from "react-redux";
import { FooterButtons, MainWrapper, ModalContent } from "./styles";

export interface IBlockedParams {
  hasPermission: boolean;
  isBlocked: boolean;
  hasBlockedFolders: boolean;
  hasMultipleFiles: boolean;
}

interface IDownloadBloqueadosModal {
  visible: boolean;
  blockedParams: IBlockedParams;
  onConfirm: () => void;
  onClose: () => void;
}

const DownloadBloqueadosModal: FC<IDownloadBloqueadosModal> = ({
  visible,
  blockedParams,
  onConfirm,
  onClose,
}) => {
  const theme = useSelector(getTheme);

  const buttons = (
    <FooterButtons>
      <Button
        onClick={onClose}
        className="cancel-button"
      >
        Cancelar
      </Button>
      <Button
        type="primary"
        onClick={onConfirm}
        className="confirm-button"
      >
        Fazer o download
      </Button>
    </FooterButtons>
  );

  if (blockedParams.hasPermission) {
    return (
      <Modal
        title="Atenção"
        width={487}
        visible={visible}
        onCancel={onClose}
        footerButtons={buttons}
      >
        <MainWrapper>
          <p>
            {blockedParams.hasMultipleFiles
              ? 'Os arquivos que você está tentando baixar estão bloqueados! Isso significa que eles podem não estar finalizados e liberados para uso.'
              : 'O arquivo que você está tentando baixar está bloqueado! Isso significa que ele pode não estar finalizado e liberado para uso.'
            }
          </p>
          <p>
            Você deseja continuar com o download?
          </p>
        </MainWrapper>
      </Modal>
    )
  }

  return (
    <ModalContent>
      {blockedParams.isBlocked ? (
        <div className="contentBlockedWrapper">
          <Tag color={theme.colors.surface.onSurfaceVariant} className="tag">
            <Icon icon="cadeadoFechado" color="branco" customSize={9} />
            Arquivo bloqueado
          </Tag>
          <h3 className="titleBlockedContent">
            {blockedParams.hasMultipleFiles
              ? 'Os arquivos estão bloqueados!'
              : 'O arquivo está bloqueado!'
            }
          </h3>
          <p>
            {blockedParams.hasMultipleFiles
              ? 'O download foi impedido pois os arquivos que você selecionou estão bloqueados! Isso significa que eles podem não estar finalizados e/ou liberados para uso.'
              : 'O download foi impedido pois o arquivo que você selecionou está bloqueado! Isso significa que ele pode não estar finalizado e/ou liberado para uso.'
            }
          </p>
          <p>
            {blockedParams.hasMultipleFiles
              ? 'Caso precise dos arquivos, entre em contato com o responsável para liberá-lo.'
              : 'Caso precise do arquivo, entre em contato com o responsável para liberá-lo.'
            }
          </p>
          <button
            className="close"
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      ) : (
        <div className="contentWrapper">
          <h3 className="titleContent">
            {blockedParams.hasBlockedFolders
              ? 'Download múltiplo de pastas indisponível.'
              : 'Download múltiplo de arquivos indisponível.'
            }
          </h3>
          <span className="textContent">
            {blockedParams.hasBlockedFolders
              ? 'O download múltiplo de pastas está indisponível. Selecione apenas arquivos liberados para fazer o download múltiplo ou entre em contato com um coordenador.'
              : 'O download múltiplo de arquivos está indisponível. Selecione apenas um arquivo para fazer o download direto ou entre em contato com um coordenador.'
            }
          </span>
          <button
            className="close"
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      )}
    </ModalContent>
  )
}

export default DownloadBloqueadosModal;
