import { BucketFileStatusEnum, IRenderingSignalR } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import React, { FC, useMemo } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Icon } from "../Icon";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import styles from "./InfoRender.module.scss";

export interface IInfoRender {
  status?: BucketFileStatusEnum;
  signalRender?: IRenderingSignalR;
  extension?: string;
}
 
const InfoRender: FC<IInfoRender> = ({
  status,
  signalRender,
  extension,
}) => {
  const tooltipRender = (data: IRenderingSignalR) => (
    <div className={styles['tooltipRender']}>
      {data.etapa === 0 ? (
        <>
          <div className={styles['title']}>Aguarde:</div>
          <div className={styles['value']}>Iniciando processamento de arquivo</div>
        </>
      ) : (
        <>
          <div className={styles['title']}>Etapa {data.etapa}:</div>
          <div className={styles['value']}>
            {data.etapa === 1
              ? 'Download do Serviço de armazenamento'
              : data.etapa === 2
                ? 'Fazendo upload para Servidor'
                : data.etapa === 3
                  ? 'Processamento da visualização '
                  : 'Procedimento não identificado '}
          </div>
          <div className={styles['percent']}>
            <CircularProgressbar
              className={styles['loading']}
              strokeWidth={14}
              styles={buildStyles({ pathColor: '#3547C8', trailColor: '#EDEFF2' })}
              value={data.progress}
              maxValue={100}
            />
            {data.progress}%
          </div>
        </>
      )}
    </div>
  );

  const renderProcess = useMemo(() => {
    if ((!extension || (extension && extension.toLocaleLowerCase() !== "pdf"))) {
      if (status === 1 && (!signalRender || signalRender.etapa !== 2)) {
        return BucketFileStatusEnum.Rendered;
      }
      if (status === 2) {
        return BucketFileStatusEnum.Rendering;
      }
      if (status === 3) {
        return BucketFileStatusEnum.Error;
      }
    }
    return BucketFileStatusEnum.NotRendered;
  }, [extension, status, signalRender]);

  if (renderProcess === 1) {
    return <Tag color="verde">Renderizado</Tag>;
  } 
  
  if (renderProcess === 2 && signalRender) {
    return (
      <Tooltip overlay={signalRender.etapa !== 5 ? tooltipRender(signalRender) : undefined}>
        <div className={styles['fileRender']}>
          {signalRender.etapa !== 5 ? (
            <CircularProgressbar
              className={styles['loading']}
              strokeWidth={14}
              styles={buildStyles({ pathColor: '#3547C8', trailColor: '#EDEFF2' })}
              value={
                signalRender.etapa === 3 && signalRender.progress > 50
                  ? 2.6
                  : signalRender.etapa === 3 && signalRender.progress < 50
                    ? 2.25
                    : signalRender.etapa
              }
              maxValue={3}
            />
          ) : null}
          <Tag color={signalRender.etapa === 5 ? 'vermelho' : 'primary'}>
            {signalRender.etapa === 5 ? 'Erro ao renderizar' : 'Renderizando'}
          </Tag>
        </div>
      </Tooltip>
    )
  } 
  
  if (renderProcess === 3) {
    return (
      <Tag color="vermelho">
        <Icon 
          icon="aviso"
          customSize={12}
          color="vermelho"
          className={styles['iconError']}
        />
        Erro ao renderizar
      </Tag>);
  } 
  
  return <> - </> 
}

export default InfoRender;
