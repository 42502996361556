import { FC, ReactNode } from 'react';
import { useDispatch, ConnectedProps, connect } from 'react-redux';
import { FileActions } from '../../../Store/File/File.actions';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { maxObraAvatarUploadSize, obraAvatarImagesAccepts } from 'Utils/ObraUtils';
import toastHandler from '../../../Utils/toastHandler';

interface IUploadAvatar {
  className?: string;
  onUploadImage?: (urlUploadImage: string) => void;
  onFileImage?: (file: File) => void;
  children?: ReactNode;
  isDisabled?: boolean;
}

const clickInputFile = () => {
  document.getElementById('uploadAvatar')?.click();
};

const UploadAvatar: FC<Props> = (props) => {
  const dispatch = useDispatch();

  if (props.srcFileUploaded && props.onUploadImage) {
    props.onUploadImage(props.srcFileUploaded);
    dispatch(FileActions.resetState());
  }

  if (props.error) {
    toastHandler.handler({description: props.error, icon: 'error', title: ''});
    dispatch(FileActions.resetState());
  }

  const uploadImage = (ev: any) => {
    const file = ev.target.files[0];

    if (file) {
      if (!obraAvatarImagesAccepts.includes(file.type)) {
        return toastHandler.showError('Apenas arquivos de imagem com o formato JPG, PNG, GIF e JPEG são suportados.');
      }
      if (file.size > maxObraAvatarUploadSize) {
        return toastHandler.showError('O tamanho máximo suportado é de 128 MB.');
      }

      dispatch(FileActions.uploadAvatar(file));
      if (props?.onFileImage) props.onFileImage(file);
      ev.target.value = '';
    }
  };

  return (
    <span onClick={props.isDisabled ? undefined : clickInputFile}>
      {
        typeof props.children === 'function'
        ? props.children(props.isLoading)
        : props.children

      }
      <input onChange={uploadImage} id='uploadAvatar' type='file' style={{ display: 'none' }}/>
    </span>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.file,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IUploadAvatar;

export default connector(UploadAvatar);
