import React, { FC } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { GetAvatar } from '../../../Utils/generateThumbnail';
import { Icon } from '../../../Components/UI';
import { ColumnProps } from 'antd/lib/table';

import { MinhaContaActions } from '../../../Store/MinhaConta/MinhaConta.actions'
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';

import styles from './DetalhesConta.module.scss';
import Ellipsis from '../../../Components/UI/Ellipsis';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Table from '../../../Components/UI/Table';
import { dateToString } from '../../../Utils/DateUtils';
import { stringToPhone } from '../../../Utils/MaskUtils';

export interface IDados {
  title: string;
  value: string
}
const DetalhesConta: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const newDadosSeguranca = () => {
      const dadosSeguranca = [];
      dadosSeguranca.push(
        {"title": "Senha", "value": "********"}, 
      );
      return dadosSeguranca;
    }

    const newDadosAcesso = () => {
      const dadosAcesso = [];
      let positionsStudent = ["Estudante de Engenharia", "Estudante de Arquitetura", "Estagiário"];

      let titleTelefone = !props.userInfo.Telefone || props.userInfo.Telefone == '' ? 'Telefone*' : 'Telefone';
      let valueTelefone = !props.userInfo.Telefone || props.userInfo.Telefone == '' ? '*' : props.userInfo.Telefone;

      dadosAcesso.push(
        {"title": "E-mail", "value": props.userInfo.Email}, 
        {"title": "E-mail alternativo", "value": props.userInfo.EmailAlternativo},
        {"title": titleTelefone, "value": stringToPhone(valueTelefone)},
      );

      if(positionsStudent.indexOf(props.userInfo.CompanyPosition?.Name, 0) != -1){
        dadosAcesso.push({"title": "Faculdade", "value": props.userInfo.Faculdade});
      }

      return dadosAcesso;
    }

    const newDadosBasicos = () => {
      const dadosBasicos = [];
      dadosBasicos.push(
        {"title": "Nome", "value": props.userInfo.Nome}, 
        {"title": "Cargo", "value": props.userInfo.CompanyPosition?.Name}
      );
      return dadosBasicos;
    }

    const collumnsTable = (title: string): Array<ColumnProps<any>> => {
      return [
        {
          title: title,
          dataIndex: 'title',
          className: styles['dadoObrigatorio'],
          render:(text, record) => (
            <div className={styles['titleItem']}> 
              <span className={styles[`${text.substr(-1,1) == '*' ? 'titleObrigatorio' : 'title'}`]}>{text}</span>
            </div>
          )
        },
        {
          title: (<div className={styles['icon']} > <Icon color='cinzaIcone' icon='editar' onClick={title == 'Dados Segurança' ? () => dispatch(MinhaContaActions.setShowModalUpdatePassword(true)) : () => dispatch(MinhaContaActions.setShowModalUpdateConta(true))}/> </div>),
          dataIndex: 'value',
          className: styles['dadoObrigatorio'],
          render:(text, record) => (
            <div className={styles['valueItem']}> 
              <span title={text == '*' ? 'Campo obrigatório' : text} className={styles[`${text == '*' ? 'valueObrigatorio' : 'value'}`]}>{text == '*' ? 'Campo obrigatório' : text}</span>
            </div>
          )
        },
      ]
    }

    return (
      <>
        <BoxContainer height={228}>
          <div className={styles['minhaContaImg']}>
            <GetAvatar
              name={props.userInfo.Nome || ''} 
              shape='square' 
              fontSize={50} 
              src={props?.userInfo?.OriginalImageUrl}
              thumbSrc={props.userInfo?.ThumbnailImageUrl}
              thumbType="big"
            />
          </div>

          <div className={styles['minhaContaName']}>
            <strong>
              <Ellipsis phrase={props.userInfo.Nome || ''}  maxLength={13}/>
            </strong>
          </div>
          <div className={styles['minhaContaDataCadastro']} style={{visibility: props.userInfo.DateCreate ? 'visible' : 'hidden'}}>
            <span className={styles['dataCadastro']}>Usuário desde { props.userInfo.DateCreate ? dateToString(new Date (props.userInfo.DateCreate)) : '' }</span>
          </div>
        </BoxContainer>

        <div className = {styles['dadosContaLeft']}>
          <Table
            dataSource = {newDadosBasicos().map((dadosBaiscos: any, index: any) =>
              ({ ...dadosBaiscos, key: index })  
            )}
            columns = {collumnsTable('Dados básicos')}
          />

          <Table className = {styles['dadosSeguranca']}
            dataSource = {newDadosSeguranca().map((dadosSeguranca: any, index: any) =>
              ({ ...dadosSeguranca, key: index })  
            )}
            columns = {collumnsTable('Dados Segurança')}
          />
        </div>

        <div className = {styles['dadosContaRight']}>
          <Table
            dataSource = {newDadosAcesso().map((dadosAcesso: any, index: any) =>
              ({ ...dadosAcesso, key: index })  
            )}
            columns = {collumnsTable('Dados acesso')}
          />
        </div>
      </>
    );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(DetalhesConta);
