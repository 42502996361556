import { call, put } from 'redux-saga/effects';
import { ViewerApi } from '../../Data/Viewer.api';
import {
    ViewerActions,
    AutoDeskViewerAction,
    GetFileInfoAction
} from './Viewer.actions';

import toastHandler from '../../Utils/toastHandler';
import { loadError } from '../../Utils/Messages';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { IUserLimitsActive } from 'Store/Auth/interfaces/IAuthState';

export function* autodestViewer({ payload }: AutoDeskViewerAction) {
    try {
        const { data } = yield call(ViewerApi.RenderAutoDesk, payload);

        const userLimitsActive: IUserLimitsActive = {
          QtyRenderedFiles: 1,
        }
        yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

        yield put(ViewerActions.autoDeskViewerSuccess(data.data));
    } catch (err: any) {
        yield put(ViewerActions.autoDeskViewerFailure(err.errors[0]?.Message || 'error'));
    }
}

export function* getFileInfo({ payload }: GetFileInfoAction) {
    try {
        const { data } = yield call(ViewerApi.GetFileInfo, payload);
        yield put(ViewerActions.getFileInfoSuccess(data.data));
    } catch (err: any) {
        const error = err.errors ? err.errors[0].Message : loadError('renderização');
        toastHandler.showError(error);
        yield put(ViewerActions.getFileInfoFailure(err.errors[0]?.Message || 'error'));
    }
}

export function* pdfAutodestViewer({ payload }: AutoDeskViewerAction) {
    try {
        yield call(ViewerApi.RenderAutoDesk, payload);

        const currentUrl = window.location.pathname;
        const redirectUrl = `${currentUrl.substr(0, 7)}/1/${currentUrl.substr(10)}`;
        window.location.href = redirectUrl;
    } catch (err: any) {
        yield put(ViewerActions.autoDeskViewerFailure(err.errors[0]?.Message || 'error'));
    }
}
