import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'Store';
import { getFixedPanels, getNotFixedPanels } from 'Store/Panels/Panels.selector';
import { getUserInfo } from 'Store/Auth/Auth.selector';
import { Dropdown, MenuProps } from 'antd';
import { Icon } from 'Components/UI';
import Button from 'Components/UI/Button';
import emptyPanelsImg from 'Static/images/empty-panels.png';
import SimpleDnDListContext from 'Components/UI/SimpleDnDListContext';
import SimpleSortableItemWithButton from 'Components/UI/SimpleSortableItemWithButton';
import { UniqueIdentifier } from '@dnd-kit/core';
import { IPanelsResponse } from 'Data/interfaces/Panels/IPanelsResponse';
import { PanelsActions } from 'Store/Panels/Panels.actions';
import HighlighterWords from 'Components/UI/HighlightWords';
import SearchInput from 'Components/UI/SearchInput';
import MenuMainEmpty from './components/MenuMainEmpty';
import AccessPermission from 'Utils/AcessPermission';
import ActionsMenuItemDropdown from './components/ActionsMenuItemDropdown';
import { EllipsisOutlined } from '@ant-design/icons';
import { copyToClipboard } from 'Utils/TextUtils';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { panelsOptionsIcons } from '../PanelsEditModal/optionsObj';
import { IPanelsUpdateRequest } from 'Data/interfaces/Panels/IPanelsUpdateRequest';
import { IPanelsReorderRequest } from 'Data/interfaces/Panels/IPanelsReorderRequest';
import Tooltip from 'Components/UI/Tooltip';
import {
  ActionPanelsGlobalStyles,
  MenuButtonStyled,
  MenuEmptyStyled,
  MenuInputStyled,
  MenuItemStyled,
  MenuMainStyled,
  MenuTitleStyled,
} from './styles';

interface IPanelsDropdown {
  csId: number;
  onOpenAddEditModal: (panel?: IPanelsResponse) => void;
  onOpenDeleteModal: (panel: IPanelsResponse) => void;
}

const PanelsDropdown: FC<IPanelsDropdown> = ({
  children,
  csId,
  onOpenAddEditModal,
  onOpenDeleteModal,
}) => {
  const noPermissionPanelsUrlFlag = useFeatureFlag('no-permission-panels-url');
  const noPermissionPanelsUrl = (noPermissionPanelsUrlFlag.enabled && typeof noPermissionPanelsUrlFlag.value === 'string')
    ? noPermissionPanelsUrlFlag.value
    : 'https://www.maletadoengenheiro.com.br/blog';

  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const fixedPanels = useSelector(getFixedPanels);
  const notFixedPanels = useSelector(getNotFixedPanels);

  const [isActive, setIsActive] = useState(false);
  const [fixedActiveId, setFixedActiveId] = useState<UniqueIdentifier | null>(null);
  const [notFixedActiveId, setNotFixedActiveId] = useState<UniqueIdentifier | null>(null);
  const [search, setSearch] = useState('');
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);

  const filteredFixedPanel = useMemo(() => {
    if (search) {
      return fixedPanels.filter(panel => {
        return panel.Name.toLowerCase().includes(search.toLowerCase());
      })
    }

    return fixedPanels;
  }, [search, fixedPanels]);
  const filteredNotFixedPanel = useMemo(() => {
    if (search) {
      return notFixedPanels.filter(panel => {
        return panel.Name.toLowerCase().includes(search.toLowerCase());
      })
    }

    return notFixedPanels;
  }, [search, notFixedPanels]);

  const activeFixedPanel = useMemo(() => {
    if (fixedActiveId && filteredFixedPanel.length > 0) {
      const active = filteredFixedPanel.find(panel => panel.PanelId === fixedActiveId);
      if (active) {
        return active;
      }
    }
    return null;
  }, [filteredFixedPanel, fixedActiveId]);
  const activeNotFixedPanel = useMemo(() => {
    if (notFixedActiveId && filteredNotFixedPanel.length > 0) {
      const active = filteredNotFixedPanel.find(panel => panel.PanelId === notFixedActiveId);
      if (active) {
        return active;
      }
    }
    return null;
  }, [filteredNotFixedPanel, notFixedActiveId]);

  const handleRedirect = useCallback((id: string) => {
    const goToUrl = `/obras/panels/${csId}/${id}`;
    history.push(goToUrl);
    setIsActive(false);
  }, [csId]);

  const copySharedLink = (id: string) => {
    const context = userInfo?.CurrentTenantContext;
    copyToClipboard(
      `${window.location.origin}/construtora/${context}?redirectUrl=${encodeURIComponent(`/obras/panels/${csId}/${id}`)}`
    );
  };

  const handleFixePanel = (panelId: string, isFixed: boolean) => {
    const request: IPanelsUpdateRequest = {
      panelId,
      data: [{
        path: 'isFixed',
        op: 'replace',
        value: isFixed,
      }]
    };
    dispatch(PanelsActions.updatePanel(request));
  };

  const handleOpenNewPanel = () => {
    if (AccessPermission.isAdmin() || AccessPermission.isCoordenador()) {
      onOpenAddEditModal();
      setIsActive(false);

    } else {
      window.open(noPermissionPanelsUrl);
    }
  };

  const handleEditPanel = (panel: IPanelsResponse) => {
    if (AccessPermission.isAdmin() || AccessPermission.isCoordenador()) {
      onOpenAddEditModal(panel);
      setIsActive(false);

    } else {
      window.open(noPermissionPanelsUrl);
    }
  };

  const handleDeletePanel = (panel: IPanelsResponse) => {
    onOpenDeleteModal(panel);
    setIsActive(false);
  };

  const handleSetNewFixedData = useCallback((
    start: IPanelsResponse,
    end: IPanelsResponse,
    data: IPanelsResponse[],
  ) => {
    if (start?.PanelId && end?.PanelId) {
      const request: IPanelsReorderRequest = {
        panelToReorderId: start.PanelId,
        panelToReplaceId: end.PanelId
      };
      dispatch(PanelsActions.reorderPanel(request));
    }
    dispatch(PanelsActions.updateFixedPanelsList(data));
  }, [dispatch]);

  const handleSetNewNotFixedData = useCallback((
    start: IPanelsResponse,
    end: IPanelsResponse,
    data: IPanelsResponse[],
  ) => {
    if (start?.PanelId && end?.PanelId) {
      const request: IPanelsReorderRequest = {
        panelToReorderId: start.PanelId,
        panelToReplaceId: end.PanelId
      };
      dispatch(PanelsActions.reorderPanel(request));
    }
    dispatch(PanelsActions.updateNotFixedPanelsList(data));
  }, [dispatch]);

  const items = useMemo(() => {
    const defalt: MenuProps['items'] = [];

    if (filteredFixedPanel.length > 0 || filteredNotFixedPanel.length > 0 || search) {
      defalt.push(
        {
          label: (
            <MenuInputStyled onClick={(e) => e.stopPropagation()}>
              <SearchInput
                delay={0}
                setSearch={setSearch}
                inputPlaceholder="Buscar painéis..."
                customClassName="customSearch"
                inputClassName="customSearchInput"
              />
            </MenuInputStyled>
          ),
          key: '11',
          className: 'rowMenu',
        },
        {
          label: (
            <MenuMainStyled>
              {(filteredFixedPanel.length > 0 || filteredNotFixedPanel.length > 0) && (
                <>
                  {filteredFixedPanel.length > 0 && (
                    <>
                      <MenuTitleStyled>
                        <span className="textItem">
                          Fixados
                        </span>
                      </MenuTitleStyled>

                      <SimpleDnDListContext
                        data={filteredFixedPanel.map(panel => ({ ...panel, id: panel.PanelId }))}
                        dragOverlay={fixedActiveId && activeFixedPanel ? (
                          <SimpleSortableItemWithButton
                            key={activeFixedPanel.PanelId}
                            id={activeFixedPanel.PanelId}
                            activeId={fixedActiveId}
                            dragClassName="customDragRow"
                          >
                            <MenuItemStyled
                              key={activeFixedPanel.PanelId}
                              onClick={() => handleRedirect(activeFixedPanel.PanelId)}
                            >
                              <div className="left">
                                {panelsOptionsIcons[activeFixedPanel.Type]}
                                <span className="textItem">
                                  <HighlighterWords
                                    text={activeFixedPanel.Name}
                                    wordSearched={search}
                                    maxLength={25}
                                  />
                                </span>
                              </div>
                            </MenuItemStyled>
                          </SimpleSortableItemWithButton>
                        ) : null}
                        onActiveId={setFixedActiveId}
                        onSetNewData={handleSetNewFixedData}
                      >
                        {filteredFixedPanel.map(panel => (
                          <SimpleSortableItemWithButton
                            key={panel.PanelId}
                            id={panel.PanelId}
                            className="customRow"
                            disabled={!AccessPermission.isAdmin() && !AccessPermission.isCoordenador()}
                          >
                            <MenuItemStyled
                              key={panel.PanelId}
                              onClick={() => handleRedirect(panel.PanelId)}
                              isdropactive={`${activeDropdownId === panel.PanelId}`}
                            >
                              <div className="left">
                                {panelsOptionsIcons[panel.Type]}
                                <span className="textItem">
                                  <HighlighterWords
                                    text={panel.Name}
                                    wordSearched={search}
                                    maxLength={25}
                                  />
                                </span>
                              </div>
                              <div className="right">
                                <span>
                                  {(AccessPermission.isAdmin() || AccessPermission.isCoordenador()) && (
                                    <Tooltip
                                      placement="top"
                                      arrow={false}
                                      zIndex={1094}
                                      title="Desafixar Painel"
                                    >
                                      <span
                                        className="pinSpan"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFixePanel(panel.PanelId, !panel.IsFixed);
                                        }}
                                      >
                                        <Icon
                                          icon="unpin"
                                          customSize={16}
                                          className="pinIcon"
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                                <span className="rightHide">
                                  <ActionsMenuItemDropdown
                                    panel={panel}
                                    onActiveId={setActiveDropdownId}
                                    onShare={copySharedLink}
                                    onFixe={handleFixePanel}
                                    onEdit={handleEditPanel}
                                    onDelete={handleDeletePanel}
                                  >
                                    <span
                                      className="actionIcon"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <EllipsisOutlined rev="" />
                                    </span>
                                  </ActionsMenuItemDropdown>
                                </span>
                              </div>
                            </MenuItemStyled>
                          </SimpleSortableItemWithButton>
                        ))}
                      </SimpleDnDListContext>
                    </>
                  )}

                  {filteredNotFixedPanel.length > 0 && (
                    <>
                      <MenuTitleStyled>
                        <span className="textItem">
                          Painéis
                        </span>
                      </MenuTitleStyled>

                      <SimpleDnDListContext
                        data={filteredNotFixedPanel.map(panel => ({ ...panel, id: panel.PanelId }))}
                        dragOverlay={notFixedActiveId && activeNotFixedPanel ? (
                          <SimpleSortableItemWithButton
                            key={activeNotFixedPanel.PanelId}
                            id={activeNotFixedPanel.PanelId}
                            activeId={notFixedActiveId}
                            dragClassName="customDragRow"
                          >
                            <MenuItemStyled
                              key={activeNotFixedPanel.PanelId}
                              onClick={() => handleRedirect(activeNotFixedPanel.PanelId)}
                            >
                              <div className="left">
                                {panelsOptionsIcons[activeNotFixedPanel.Type]}
                                <span className="textItem">
                                  <HighlighterWords
                                    text={activeNotFixedPanel.Name}
                                    wordSearched={search}
                                    maxLength={27}
                                  />
                                </span>
                              </div>
                            </MenuItemStyled>
                          </SimpleSortableItemWithButton>
                        ) : null}
                        onActiveId={setNotFixedActiveId}
                        onSetNewData={handleSetNewNotFixedData}
                      >
                        {filteredNotFixedPanel.map(panel => (
                          <SimpleSortableItemWithButton
                            key={panel.PanelId}
                            id={panel.PanelId}
                            className="customRow"
                            disabled={!AccessPermission.isAdmin() && !AccessPermission.isCoordenador()}
                          >
                            <MenuItemStyled
                              key={panel.PanelId}
                              onClick={() => handleRedirect(panel.PanelId)}
                              isdropactive={`${activeDropdownId === panel.PanelId}`}
                            >
                              <div className="left">
                                {panelsOptionsIcons[panel.Type]}
                                <span className="textItem">
                                  <HighlighterWords
                                    text={panel.Name}
                                    wordSearched={search}
                                    maxLength={25}
                                  />
                                </span>
                              </div>
                              <div className="right">
                                <span className="rightHide">
                                  {(AccessPermission.isAdmin() || AccessPermission.isCoordenador()) && (
                                    <Tooltip
                                      placement="top"
                                      arrow={false}
                                      zIndex={1094}
                                      title="Fixar Painel"
                                    >
                                      <span
                                        className="pinSpan"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFixePanel(panel.PanelId, !panel.IsFixed);
                                        }}
                                      >
                                        <Icon
                                          icon="pin"
                                          customSize={16}
                                          className="pinIcon"
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                                <span className="rightHide">
                                  <ActionsMenuItemDropdown
                                    panel={panel}
                                    onActiveId={setActiveDropdownId}
                                    onShare={copySharedLink}
                                    onFixe={handleFixePanel}
                                    onEdit={handleEditPanel}
                                    onDelete={handleDeletePanel}
                                  >
                                    <span
                                      className="actionIcon"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <EllipsisOutlined rev="" />
                                    </span>
                                  </ActionsMenuItemDropdown>
                                </span>
                              </div>
                            </MenuItemStyled>
                          </SimpleSortableItemWithButton>
                        ))}
                      </SimpleDnDListContext>
                    </>
                  )}
                </>
              )}
              {filteredFixedPanel.length === 0 && filteredNotFixedPanel.length === 0 && (
                <MenuMainEmpty />
              )}
            </MenuMainStyled>
          ),
          key: '12',
          className: 'rowMain',
        },
      );

      if (AccessPermission.isAdmin() || AccessPermission.isCoordenador()) {
        defalt.push(
          {
            type: 'divider',
          },
          {
            label: (
              <MenuButtonStyled
                type="text"
                className="buttonAdd"
                onClick={handleOpenNewPanel}
              >
                <Icon
                  icon="adicionar"
                  customSize={12}
                  className="iconMenuItem"
                />
                <span className="textItem">
                  Adicionar painel
                </span>
              </MenuButtonStyled>
            ),
            key: '13',
            className: 'rowMenu',
          }
        );
      }

    } else {
      defalt.push({
        label: (
          <MenuEmptyStyled>
            <img
              src={emptyPanelsImg}
              alt="Lista de painéis vazia"
              className='emptyPanels'
            />
            <span className="textTitle">
              Adicione painéis à sua obra
            </span>
            <span className="textDescription">
              Adicione documentos e aplicativos externos para acessar facilmente com os painéis.
            </span>
            <Button
              type={(AccessPermission.isAdmin() || AccessPermission.isCoordenador())
                ? 'primary'
                : 'text'
              }
              className="buttonAdd"
              onClick={handleOpenNewPanel}
            >
              {(AccessPermission.isAdmin() || AccessPermission.isCoordenador()) && (
                <Icon
                  color="onPrimary"
                  icon="adicionar"
                  customSize={12}
                  className="iconMenuItem"
                />
              )}
              <span className="textItem">
                {(AccessPermission.isAdmin() || AccessPermission.isCoordenador())
                  ? 'Adicionar painel'
                  : 'Saiba mais'
                }
              </span>
            </Button>
          </MenuEmptyStyled>
        ),
        key: '14',
        className: 'rowEmpty',
      });
    }

    return defalt;
  }, [
    filteredFixedPanel,
    filteredNotFixedPanel,
    fixedActiveId,
    activeFixedPanel,
    notFixedActiveId,
    activeNotFixedPanel,
    search,
    activeDropdownId,
    handleOpenNewPanel,
    handleEditPanel,
    handleRedirect,
    handleSetNewFixedData,
    handleSetNewNotFixedData,
  ]);

  return (
    <>
      <ActionPanelsGlobalStyles />
      <Dropdown
        open={isActive}
        trigger={["click"]}
        className="PanelsDropdown"
        onOpenChange={(open) => setIsActive(open)}
        menu={{ items }}
      >
        {children}
      </Dropdown>
    </>
  )
};

export default PanelsDropdown;
