import styled, { css } from "styled-components";

export const Divider = styled.div`
  width: 1px;
  height: auto;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
`;

interface IMainWrapperLeft {
  showInfo?: string;
}

export const MainWrapperLeft = styled.div<IMainWrapperLeft>`
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding-right: 15px;
  padding-bottom: 15px;

  .cardHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};

    ${({showInfo}) => showInfo === 'true' && css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  }

  .cardMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    border-top: none;
    background-color: transparent;

    .title {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .subTitle {
      margin-top: 16px;

      &.first {
        margin-top: 6px;
      }

      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }

    .subLink {
      color: ${({theme}) => theme.colors.information.information};
    }

    .subBold {
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .tableWrapper {
      width: auto;
      height: 156px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
      margin-top: 16px;

      table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
        
        thead {
          tr {
            background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
            
            th {
              text-align: left;
              padding: 12px;
      
              font-size: 12px;
              font-weight: 600;
              line-height: 16.34px;
              color: ${({theme}) => theme.colors.surface.onSurface};
            }
          }
        }
  
        tbody {
          tr {
            border-top: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
            border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
            
            td {
              max-width: 225px;
              padding: 12px;
  
              :last-child {
                word-wrap: break-word;
                overflow-wrap: break-word;
              }
      
              font-size: 12px;
              font-weight: 400;
              line-height: 16.34px;
              color: ${({theme}) => theme.colors.surface.onSurface};
            }
          }
        }
      }
    }
  }
`;

export const CardTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  color: ${({theme}) => theme.colors.surface.onSurface};
`;

interface ICardSubTitle {
  isBold?: string;
  noMargin?: string;
}

export const CardSubTitle = styled.span<ICardSubTitle>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  margin-top: 6px;

  ${({isBold}) => isBold === 'true' && css`
    font-weight: 600;
    color: ${({theme}) => theme.colors.surface.onSurface};
  `}

  ${({noMargin}) => noMargin === 'true' && css`
    margin-top: 0;
  `}
`;

interface IInputDNSWrapper {
  showActionButton?: string
}

export const InputDNSWrapper = styled.div<IInputDNSWrapper>`
  width: 100%;
  margin-top: 6px;
  display: flex;

  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};

  form {
    display: flex;
    flex-direction: column;

    .infoText {
      margin-top: 10px;
      margin-right: 6px;
    }

    input {
      border-radius: 5px;
      height: 36px !important;
      width: 100%;
    }

    .formHeader {
      display: flex;

      .firstInput {
        max-width: 125px;
      }

      ${({showActionButton}) => showActionButton === 'true' && css`
        .firstInput {
          min-width: 125px;
        }

        .lastInput {
          width: 100%;
        }
      `}

      .dot {
        margin: 10px 6px 0 6px;
      }

      button {
        border-radius: 5px;
        height: 36px !important;
        max-height: 36px !important;
        margin-left: 6px;
      }
    }

    .errorCustomInput {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: ${({theme}) => theme.colors.danger.danger};
      padding: 0 !important;
      margin-top: 4px;
      height: 14px;
    } 
  }
`;

interface IDNSWrapper {
  isInternal?: string;
  isOK?: string;
}

export const DNSWrapper = styled.div<IDNSWrapper>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;

  .dnsConfirmIcon {
    color: ${({theme}) => theme.colors.success.success} !important;
  }

  .dnsRelogioIcon {
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;

    ${({isInternal, theme}) => isInternal === 'true' && css`
      color: ${theme.colors.warning.warning} !important;
    `}
  }
  
  .infoWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .info {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};

      ${({isInternal}) => isInternal === 'true' && css`
        color: ${({theme}) => theme.colors.warning.warning};
      `}

      ${({isOK}) => isOK === 'true' && css`
        color: ${({theme}) => theme.colors.success.success};
      `}
    }

    .dns {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      
      .dnsBold {
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
    }
  }

  .iconBtn {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dnsEditIcon {
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
  }

  .cnameBtn {
    border-radius: 5px;
    margin-left: 6px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
  }

  .internalBtn {
    border-radius: 5px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
  }
`;

interface IInfoWrapper {
  isInternal?: string;
  isError?: string;
}

export const InfoWrapper = styled.div<IInfoWrapper>`
  width: 100%;
  margin-top: 16px;
  
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
  }

  .infoInternal {
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
  }
  .infoInternalBold {
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
  }

  .infoError {
    line-height: 16px;
    color: ${({theme}) => theme.colors.danger.danger} !important;
  }
`;

export const MainWrapperRight = styled.div`
  width: 100%;
  max-width: 550px;
  padding-left: 15px;
  position: relative;
  
  .fixedContainer {
    width: 100%;
    max-width: 550px;
    position: fixed;
  }
`;

interface ISvgCard {
  hasCustom: string;
}

export const SvgCard = styled.div<ISvgCard>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};

  ${({hasCustom, theme}) => hasCustom === 'true' && css`
    svg {
      #customDomainPath {
        display: none;
      }
    }

    .overlap {
      position: absolute;
      top: 6px;
      left: 104px;
      width: 344px;
      height: 20px;
      border-radius: 5px;
      
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      color: ${theme.colors.surface.onSurface};

      .overlapRelogioIcon {
        color: ${theme.colors.warning.warning} !important;
      }
      .overlapCadeadoIcon {
        color: ${theme.colors.success.success} !important;
      }

      .domainText {

      }
    }
  `}
`;
