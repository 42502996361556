import { FC } from "react";
import emptySearch from "Static/images/empty-search.png";
import { Container } from "./styles";

interface IEmptySearch {
  searchText?: string;
}

const EmptySearch: FC<IEmptySearch> = ({ searchText }) => {
  return (
    <Container>
      <img
        src={emptySearch}
        className="imgSearch"
        alt=""
      />
      <span className="title">
        {`Nenhuma etapa encontrada por “${searchText}”.`}
      </span>
      <span className="subtitle">
        {'A etapa pesquisada não foi encontrada ou não existe. Verifique sua pesquisa e tente novamente.'}
      </span>
    </Container>
  )
}

export default EmptySearch;
