import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { history } from "Store";
import { IRenderingSignalR } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { SrcTypeOption } from "Data/interfaces/Panels/IPanelComponentsModels";
import { IPanelsContent } from "Data/interfaces/Panels/IPanelsCreateRequest";
import { IPanelsFileContent } from "Data/interfaces/Panels/IPanelsFileContent";
import { IPanelsFileInfo } from "Data/interfaces/Panels/IPanelsFileInfo";
import { IPanelsResponse, PanelTypeEnum } from "Data/interfaces/Panels/IPanelsResponse";
import { IAutodeskViewer } from "Data/interfaces/Viewer/IAutodeskViewer";
import { IRequestFileInfo } from "Data/interfaces/Viewer/IGetFileInfo";
import { PanelsActions } from "Store/Panels/Panels.actions";
import AccessPermission from "Utils/AcessPermission";
import { allOtherExtensions, extensions2dAnd3d } from "Utils/ViewerUtils";
import toastHandler from "Utils/toastHandler";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { TypeButton, TypeListButtons } from "Components/UI/ListButtons";
import AppStorage from 'Utils/AppStorage';
import { BUTTON_PANEL_PDF_LIST_KEY } from "./components/Header";
import { ListagemArquivosActions } from "Store/ListagemArquivos/ListagemArquivos.actions";
import { IDownloadFilesRequest } from "Data/interfaces/Obra/IDownloadFilesRequest";
import { ITenantData } from "Data/interfaces/Tenant/ITenantData";

const buttons: TypeButton = {
  'bim': {
    nameId: 'bim',
    placement: 'Visualizador BIM',
    icon: 'visualizar',
    iconSize: 10,
  },
  'pdf': {
    nameId: 'pdf',
    placement: 'Visualizador PDF',
    icon: 'arquivoPdf',
    iconSize: 10,
  },
};

interface IUsePanels {
  panelId: string;
  csId: number;
  fixedPanels: IPanelsResponse[];
  notFixedPanels: IPanelsResponse[];
  isLoading: boolean;
  panelsFileInfo?: IPanelsFileInfo;
  fileViewerAutodesk?: IRenderingSignalR;
  userInfo?: IUserInforResponse;
  obrasList?: IObraResponse[];
  globalLoading: boolean;
  currentTenant?: ITenantData | null;
}

const usePanels = ({
  panelId,
  csId,
  fixedPanels,
  notFixedPanels,
  panelsFileInfo,
  userInfo,
  obrasList,
  globalLoading,
  currentTenant,
}: IUsePanels) => {
  const dispatch = useDispatch();

  const viewerGoogle = 'https://docs.google.com/viewer?embedded=true&url=';
  const panels = fixedPanels.concat(notFixedPanels);
  const panel = panels.find(panel => panel.PanelId === panelId);
  const hasDownloadPermission = panelsFileInfo?.hasDownloadPermission && AccessPermission.downloadViewerButtonBlockedFiles(panelsFileInfo?.fileStatus);

  const oneCallIdentifier = useRef<string | null>(null);
  const [activeSrcTypeOption, setActiveSrcTypeOption] = useState<SrcTypeOption | null>(null);
  const [stringContent, setStringContent] = useState('');
  const [internalContent, setInternalContent] = useState<IPanelsFileContent | null>(null);
  const [activeListButton, setActiveListButton] = useState<TypeListButtons>(
    AppStorage.GetItem(BUTTON_PANEL_PDF_LIST_KEY, 'local') as TypeListButtons || 'pdf'
  );

  const handleActiveListButton = (value: TypeListButtons) => {
    setActiveListButton(value);
  };

  const isViewerRender = useMemo(() => {
    if (panelsFileInfo) {
      const is2d3dExtension = extensions2dAnd3d.indexOf(panelsFileInfo.extension.toLocaleLowerCase()) !== -1;
      const isBIMViewer = panelsFileInfo.extension === 'pdf' && activeListButton === 'bim';
      return is2d3dExtension || isBIMViewer;
    }
    return false;
  }, [activeListButton, panelsFileInfo]);

  const isInternalItem = panel?.Type === PanelTypeEnum.InternalItem;
  const isDropboxType = panel?.Type === PanelTypeEnum.Dropbox;

  const handleObraPermission = (
    currentConstructionSiteId: number,
    currentUserId: string,
    currentObrasList: IObraResponse[],
  ) => {
    if (currentObrasList.length > 0) {
      const obraPermission = currentObrasList.find(obra => obra.ConstructionSiteId === currentConstructionSiteId);
      if (obraPermission) {
        const haveObraPermission = obraPermission.SharedUsers.find((user: any) => user.UserFk === currentUserId);
        if (haveObraPermission) {
          return;
        }
      }
    }

    toastHandler.showError('Você não possui permissão de acesso a obra.');
    return history.replace('/');
  };

  const handleOpenFolder = async (folderId: string) => {
    const breadCrumbId = (internalContent?.BreadCrumbId || 0) > 0
      ? internalContent?.BreadCrumbId
      : (internalContent?.BreadCrumbItemIdDropbox || null);
    history.push(`/obras/filelist/${csId}/${folderId}${breadCrumbId ? `?breadCrumbId=${breadCrumbId}` : ''}`);
  };

  const handleDownload = () => {
    if (!panelsFileInfo) return;

    const request: IDownloadFilesRequest = {
      api: panelsFileInfo.apiId,
      constructionSiteId: csId,
      itemsId: [panelsFileInfo.fileIdentifier],
      constructionSiteName: panelsFileInfo.constructionName,
      tenantName: currentTenant?.CompanyName,
      userInfoTracking: userInfo,
      currentTenantTracking: currentTenant,
    };

    dispatch(ListagemArquivosActions.downloadFiles(request));
  };

  useEffect(() => {
    if (csId && userInfo?.Id && !globalLoading && obrasList) {
      handleObraPermission(csId, userInfo.Id, obrasList);
    }
  }, [csId, userInfo?.Id, globalLoading, obrasList]);

  useEffect(() => {
    if (panel?.Content) {
      const panelContent = JSON.parse(panel.Content) as IPanelsContent;
      const { type } = panelContent;

      setActiveSrcTypeOption(type);

      if (type === 'file' && csId) {
        const content = panelContent.content as IPanelsFileContent;
        setInternalContent(content);

        const request: IRequestFileInfo = {
          ConstructionSiteId: csId,
          FileApiId: content.FileApiId,
        };

        dispatch(PanelsActions.panelsGetFileInfo(request));

      } else {
        const content = panelContent.content as string;
        setStringContent(content);
      }
    }
  }, [isInternalItem, panel?.Content, csId]);

  useEffect(() => {
    if (
      csId && 
      panelsFileInfo && 
      panelsFileInfo.fileIdentifier !== oneCallIdentifier.current
    ) {
      const requestAutoDeskViewer: IAutodeskViewer = {
        ConstructionSiteId: csId,
        FileApiId: panelsFileInfo.fileIdentifier || panelsFileInfo.fileInfo.ApiId,
      };

      if (
        panelsFileInfo.fileRenderStatus ||
        (panelsFileInfo.extension === 'pdf' && panelsFileInfo.bucketFileName)
      ) {
        if (panelsFileInfo.fileRenderStatus !== 'success') {
          oneCallIdentifier.current = panelsFileInfo.fileIdentifier;
          dispatch(PanelsActions.panelsVerifyAutodeskProgress(requestAutoDeskViewer));
        }
        return;
      }

      if (
        panelsFileInfo.extension !== 'pdf' &&
        (allOtherExtensions.indexOf(panelsFileInfo.extension?.toLowerCase()) !== -1 ||
          panelsFileInfo.extension === '')
      ) {
        return;
      }

      oneCallIdentifier.current = panelsFileInfo.fileIdentifier;
      const requestPanelsViewer: IRenderingSignalR = {
        urn: panelsFileInfo.urn,
        etapa: 0,
        progress: 0,
        fileIdentifier: panelsFileInfo.fileIdentifier
      };
      dispatch(PanelsActions.setFileStatusRendering(requestAutoDeskViewer));
      dispatch(PanelsActions.panelsAutoDeskViewer(requestAutoDeskViewer));
      dispatch(PanelsActions.createStatusFilePanelViewer(requestPanelsViewer));
    }
  }, [panelsFileInfo, csId]);

  return {
    viewerGoogle,
    panel,
    hasDownloadPermission,
    activeSrcTypeOption,
    stringContent,
    isViewerRender,
    isInternalItem,
    isDropboxType,
    buttons,
    activeListButton,
    handleActiveListButton,
    handleOpenFolder,
    handleDownload,
  }
}

export default usePanels;
