import { BaseButtonProps } from 'antd/es/button/button';
import React, { FC } from 'react';
import Tooltip from '../Tooltip';
import styles from './ButtonDrawer.module.scss';

export interface IButtonDrawerProps extends BaseButtonProps {
    onClick: () => void;
    style?: any;
    placement: string;
    children: React.ReactNode;
    border?: boolean;
    delay?: boolean;
    hideHover?: boolean;
}

const ButtonDrawer: FC<IButtonDrawerProps> = ({
  onClick,
  style,
  placement,
  children,
  border,
  delay,
  hideHover,
}) => {
    return (
      <Tooltip 
        title={placement}
        placement="right"
        mouseEnterDelay={delay ? 2 : undefined}
      >
        <button 
          onClick={() => onClick()}
          className={`
            ${styles['buttonWrapper']} 
            ${border ? styles['border'] : ''}
            ${hideHover ? styles['hideHover'] : ''}
          `}
          style={{...style}}  
        >
          {children}
        </button>
      </Tooltip>
    );
};

export default ButtonDrawer;
