import { call, put } from 'redux-saga/effects';
import { UploadFilesAction, UploadActions, CheckUploadFilesAction } from './Upload.actions';
import { UploadApi } from '../../Data/Upload.api';
import { ICheckUploadFilesRequest } from '../../Data/interfaces/Upload/ICheckUploadFilesRequest';
import toastHandler from '../../Utils/toastHandler';
import { UploadChunksApi } from '../../Data/UploadChunks.api';
import { Mixpanel } from 'Utils/MixPanel';
import { ICheckUploadFilesInfoResponse } from 'Data/interfaces/Upload/IUploadFileData';

// export function* uploadFile({ payload }: UploadFilesAction) {
//   try {
//     const { data } = yield call(UploadApi.uploadFiles, payload);

//     payload.updateFileInfo = { ...data.data?.FileInfo, WebUrl: data.data?.WebUrl };
//     payload.identifier = data.data?.Identifier;
//     yield put(UploadActions.uploadFilesSuccess(payload));
//     yield put(ListagemArquivosActions.addNewFile(data.data));
    
//   } catch (e) {
//     payload.uploadError = true;
//     const msg = 'Erro ao realizar upload de arquivo';
//     const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
//     yield put(UploadActions.uploadFilesSuccess(payload));
//     yield put(UploadActions.uploadFilesFailure(error));
//     toastHandler.showError(error);
//   }
// }

export function* uploadChunkFile({ payload }: UploadFilesAction) {
  try {
    yield call(UploadChunksApi.uploadChunkFiles, payload);
  } catch (e) {
    // console.log('error dentro sagas', {e})
  }
}

export function* checkUploadFile({ payload }: CheckUploadFilesAction) {
  try {
    const filesName = payload.map((file) => { return file.newFileName || file.file.name });
    
    const checkFile: ICheckUploadFilesRequest = {
      ConstructionSiteId: payload[0].csId,
      CurrentFolderId: payload[0].folderId,
      ConstructionSiteDisciplineId: payload[0].constructionSiteDisciplineId,
      FilesName: filesName,
    };
    const { data } = yield call(UploadApi.checkUploadFiles, checkFile);

    if (data?.info && data.info?.validStages?.length > 0) {
      const checkInfo: ICheckUploadFilesInfoResponse = {
        csId: data.info.validStages[0].ConstructionSiteFk,
        validStages: data.info.validStages,
      }
      yield put(UploadActions.checkUploadFilesInfoPopulated(checkInfo));
    }
    
    for (let newFileData of payload) {
      newFileData.checkUploadFileInfo = data.data.find((newInfo: any) => newInfo.Name === (newFileData.newFileName || newFileData.file.name)) || undefined;
      newFileData.isChecking = false;

      const {
        userInfoTracking,
        currentTenantTracking,
        nomenclaturaControlTracking,
      } = newFileData;
      delete newFileData.userInfoTracking;
      delete newFileData.currentTenantTracking;
      delete newFileData.nomenclaturaControlTracking;

      Mixpanel.track({
        name: 'FILE_NAME_VALIDATION', 
        props: {
          success: !newFileData.checkUploadFileInfo?.HasInconsistencia,
          quantityInputs: newFileData.checkUploadFileInfo?.Division?.length,
          revisionNumber: newFileData.checkUploadFileInfo?.RevisionNumber,
          revisionInput: newFileData.checkUploadFileInfo?.Revision,
          revisionControl: nomenclaturaControlTracking?.HasRevisionControl,
          revisionControlQuantityInputs: nomenclaturaControlTracking?.VersionFields?.length,
        },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      });

      yield put(UploadActions.uploadFilesSuccess(newFileData));
    }

  } catch (e: any) {
    Mixpanel.track({
      name: 'FILE_NAME_VALIDATION_ERROR', 
      props: {
        quantityInputs: payload[0].checkUploadFileInfo?.Division?.length,
        revisionNumber: payload[0].checkUploadFileInfo?.RevisionNumber,
        revisionInput: payload[0].checkUploadFileInfo?.Revision,
        revisionControl: payload[0].nomenclaturaControlTracking?.HasRevisionControl,
        revisionControlQuantityInputs: payload[0].nomenclaturaControlTracking?.VersionFields?.length,
      },
      userInfo: payload[0].userInfoTracking,
      currentListTenant: payload[0].currentTenantTracking,
    });

    for (let errorFile of payload) {
      errorFile.uploadError = true;
      errorFile.isSending = false;
      yield put(UploadActions.uploadFilesSuccess(errorFile));
    }
    const msg = 'Error';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(UploadActions.uploadFilesFailure(error));
    toastHandler.showError(error)
  }
}
