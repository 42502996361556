import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';

export class FileApi {
  static async uploadAvatar(file: Blob): Promise<AxiosResponse<object>> {
    const axios = await Http.axios(false);
    const formData = new FormData();
    formData.append('File', file);
    return axios.post('/File/UploadAvatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
