import React from 'react'
import { useHistory } from 'react-router'
import { Tag } from 'antd'

import styles from './VersionItem.module.scss'
import { getUserAvatarOrThumb } from 'Utils/generateThumbnail'
import { formatDateByTime } from 'Utils/DateUtils'

type VersionItemProps = {
  avatarUrl: string | null;
  username: string;
  filename: string;
  description: string;
  itemUrl: string;
  currentVersion: boolean;
  date: string;
}

const VersionItem = ({ avatarUrl, username, filename, currentVersion, itemUrl, description, date }: VersionItemProps) => {
  const history = useHistory();

  return (
    <div className={styles['WrapperItem']} onClick={() => history.push(itemUrl)}>
      {getUserAvatarOrThumb(avatarUrl, username, "large")}
      
      <div className={styles['content']}>
        <p className={styles['username']}><span>{username}</span> {description}</p>
        <span className={styles['filename']}>{filename}</span>
        
        <div className={styles['subcontent']}>
          <p className={styles['date']}>{formatDateByTime(new Date(date))}</p>
          {currentVersion ? <Tag className={styles['tag']} color="rgba(0, 129, 255, 0.2)">Versão atual</Tag> : null}
        </div>
      </div>

    </div>
  )
}

export default VersionItem;
