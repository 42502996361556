import { IDataFields } from "Components/UI/SimpleDnDListContext";

export interface IPanelsResponse extends IDataFields {
  PanelId: string;
  Name: string;
  Content: string;
  Type: PanelTypeEnum;
  IsFixed: boolean;
  Order: number;
  ConstructionSiteFk: number;
}

export enum PanelTypeEnum {
  InternalItem,
  Custom,
  GoogleDocs,
  GoogleSheets,
  GoogleSlides,
  GoogleCalendar,
  GoogleDrive,
  GoogleMaps,
  Youtube,
  OneDrive,
  Dropbox,
  Miro,
  Loom,
}
