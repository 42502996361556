import styled, { css } from "styled-components";

interface IContainer {
  marginTop?: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  margin-top: ${({marginTop}) => marginTop || 0}px;
`;

export const Title = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

interface IDivider {
  nottop?: boolean;
}

export const Divider = styled.div<IDivider>`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 24px 0;

  ${({nottop}) => nottop && css`
    margin-top: 14px;
  `}
`;
