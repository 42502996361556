import React, { FC, useMemo } from 'react';
import Tooltip from '../../../../Components/UI/Tooltip';
import { Icon } from 'Components/UI';
import { IDisciplineResponse } from 'Data/interfaces/Obra/IDisciplineResponse';
import { getDisciplineIcon } from 'Utils/ObraUtils';
import { CardDiscipline, CardDisciplineTooltip, Container } from './styles';

export interface IDisciplinesTooltip {
  title: string;
  disciplines: IDisciplineResponse[];
}

const DisciplinesTooltip: FC<IDisciplinesTooltip> = ({
  title,
  disciplines,
}) => {
  const detailDisciplines = useMemo(() => (
    <Container>
      <div className="title">
        {title}
      </div>
      <div className="content">
        {disciplines.map((discipline, index) => (
          <CardDisciplineTooltip 
            key={`${discipline.ConstructionSiteDisciplinesId}-${index}`}
          >
            <Icon 
              icon={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name)}
              customSize={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name) === 'menuContexto' ? 12 : 14}
              customColor={'#BEC2C6'}
            />
            <span className="cardDisciplineText">{discipline?.CustomName || discipline?.Discipline?.Name}</span>
          </CardDisciplineTooltip>
        ))}
      </div>
    </Container>
  ), [title, disciplines]);

  return (
    <>
      {disciplines.map((discipline, index) => 
        <Tooltip
          title={detailDisciplines}
          placement="bottom"
          key={`user${index}`}
        >
          <CardDiscipline>
            <Icon 
              icon={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name)}
              customSize={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name) === 'menuContexto' ? 12 : 14}
              customColor={'#BEC2C6'}
            />
            <span className="cardDisciplineText">{discipline?.CustomName || discipline?.Discipline?.Name}</span>
          </CardDiscipline>
        </Tooltip> 
      )}
    </>
  );
}

export default DisciplinesTooltip;

