import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Campo "Email" deve ser preenchido')
    .email('Digite um endereço de email válido'),
  password: Yup.string()
    .required('Campo "Senha" deve ser preenchido'),
  confirmPassword: Yup.string()
    .required('Campo "Confirmar senha" deve ser preenchido')
    .oneOf([Yup.ref('password')], 'As senhas não conferem'),
});

export default schema;

export const schemaPassword = Yup.object().shape({
  password: Yup.string()
    .min(8, '8 caracteres')
    .matches(/[a-z]/, 'Letra minúscula')
    .matches(/[A-Z]/, 'Letra maiúscula')
    .matches(/[0-9]/, 'Número'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('$password'), undefined], 'As senhas devem ser iguais'),
});
