import { ColorName } from '../../../Utils/Color';
import { IBreadCrumb } from './IBreadCrumb';
import { IUpdatePlotagemArquivoStatusRequest } from './IUpdatePlotagemArquivoStatusRequest';

export interface IArquivoDataResponse {
  data: IArquivoData[];
  info: IBreadCrumb;
}

export interface IArquivoData {
    PlottingFilesId: number;
    Name: string;
    FileId: number;
    QtyCopies: number;
    Revision: string;
    NameWithoutRevision: string;
    Extension: string;
    DisciplineName: string;
    FileInfo: IUpdatePlotagemArquivoStatusRequest;
    Status: FilePlotagemStatusEnum;
    HasColor: boolean;
    UpdateDate: string;
    HasInconsistencia: boolean;
    PlottingDescription: string,
    PlotterResponsibleName: string,
    PlottingAddress: string,
    RequestDate: string,
    PlottingFk: number;
    ConstructionSiteDisciplineFk: number;
    FileInfoFk: number;
}

export enum FilePlotagemStatusEnum {
    NaoEntregue = 0,
    NoEscritorio = 1,
    NaObra = 2,
    Obsoleto = 3,
  }
  
export const FilePlotagemStatusEnumLabel = ['Não entregue', 'No escritório', 'Na obra', 'Obsoleto'];
export const FilePlotagemStatusEnumColor: ColorName[] = ['vermelho', 'verde', 'amarelo', 'cinzaSuperior'];