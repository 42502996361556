import React, { FC, useEffect } from 'react';

import Modal from '../../../../Components/UI/Modal/Modal';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import Button from '../../../../Components/UI/Button';
import ObrasConvidadosTab from '../Tabs/ObrasConvidadosTab';
import PermissoesObrasTab from '../Tabs/PermissoesObrasTab';
import Tabs from '../../../../Components/UI/Tabs/Tabs';
import { TenantActions } from '../../../../Store/Tenant/Tenant.actions';
import { IBindConstructionSitesToUser } from '../../../../Data/interfaces/Obra/IBindConstructionSitesToUser';
import { IBindConstructionSitesToInvitedUser } from '../../../../Data/interfaces/Obra/IBindConstructionSitesToInvitedUser';
import { Skeleton } from 'antd';
import { IUserInfoResponse } from '../../../../Data/interfaces/MinhaConta/IUserInfoResponse';

interface IObrasEPermissoes {
  isVisible?: boolean;
  workToEdit: any;
  isLoading: boolean;
  combinedUsers: any;
  currentUserLogged?: IUserInfoResponse;
  onCancel?: () => void;
}

const ObrasEPermissoes: FC<Props & IObrasEPermissoes> = (props) => {
  const dispatch = useDispatch();
  const [isSendingData, setSendingData] = React.useState<boolean>(false);
  const [workToEdit, setWorkToEdit] = React.useState<any>();

  useEffect(() => {
    setWorkToEdit(props.workToEdit || {});
  }, [props]);

  const updateWorks = (data: any) => {
    setWorkToEdit(data);
  };

  const usuarioAtual: any = props.combinedUsers?.filter(
    (user: any) => user.User.Id === props.currentUserLogged?.Id
  );

  let usuarioAtualSharedWorks: any

  if (usuarioAtual) {
    usuarioAtualSharedWorks = usuarioAtual[0]?.ConstructionSites?.filter(
      (obra: any) => obra.IsShared === true
    );
  }

  const getTabs = () => {
    const tabs = [
      {
        title: 'Obras',
        key: 'tabUsuario',
        children: props.isLoading ? (
          <div style={{ padding: 20 }}>
            <Skeleton loading={true} paragraph={{ rows: 4 }} />
          </div>
        ) : (
            <ObrasConvidadosTab
              workToEdit={workToEdit}
              changeWorks={updateWorks}
              currentUser={props.currentUser}
              usuarioAtualSharedWorks={usuarioAtualSharedWorks}
              combinedUsers={props.combinedUsers}
            />
          )
      },
      {
        title: 'Permissões',
        key: 'tabPermissoes',
        children: props.isLoading ? (
          <div style={{ padding: 20 }}>
            <Skeleton loading={true} paragraph={{ rows: 4 }} />
          </div>
        ) : (
            <PermissoesObrasTab
              workToEdit={workToEdit}
              changeWorks={updateWorks}
              currentUser={props.currentUser}
              usuarioAtualSharedWorks={usuarioAtualSharedWorks}
              combinedUsers={props.combinedUsers}
            />
          )
      }
    ];
    return tabs;
  };

  const bindWorks = () => {
    if (workToEdit.Id || workToEdit.InvitedUserId) {
      if (workToEdit.InvitedUserId) {
        const sendUpdateInviteUserData: IBindConstructionSitesToInvitedUser = {
          InviteId: workToEdit.InvitedUserId,
          BindConstructionSites: workToEdit.ConstructionSites.filter((x: any) => x.IsShared)
        };
        dispatch(
          TenantActions.updateTenantBindConstructionSitesToInvitedUser(
            sendUpdateInviteUserData
          )
        );
        setSendingData(true);
      } else {
        const sendUpdateUserData: IBindConstructionSitesToUser = {
          UserId: workToEdit.Id,
          BindConstructionSites: workToEdit.ConstructionSites.filter((x: any) => x.IsShared)
        };

        dispatch(
          TenantActions.updateTenantBindConstructionSitesToUser(sendUpdateUserData)
        );
        setSendingData(true);
      }
    } else {
      if (props.onCancel) {
        props.onCancel();
      }
    }
  };

  if (isSendingData && !props.isLoading) {
    setSendingData(false);
    if (props.onCancel) {
      props.onCancel();
    }
  }

  return props.isVisible ? (
    <Modal
      width={860}
      title={'Gerenciar ' + props.workToEdit?.Nome}
      visible={props.isVisible}
      onCancel={props.onCancel}
      footerButtons={
        <>
          <Button onClick={props.onCancel}>
            <span style={{ padding: '0 35px' }}>Cancelar</span>
          </Button>
          <Button type="primary" loading={props.isLoading} onClick={bindWorks}>
            <span style={{ padding: '0 35px' }}>Salvar</span>
          </Button>
        </>
      }
    >
      <Tabs tabs={getTabs()} />
    </Modal>
  ) : (
      <></>
    );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra.Users,
  ...state.obra,
  currentUser: state.minhaConta.userInfo,
  isLoading: state.tenant.customLoading,
  tenantRoles: state.tenant.TenantRoles || [],
  UsersWithConstruction: state.tenant.UsersWithConstruction
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

export default connector(ObrasEPermissoes);
