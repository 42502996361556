import { IUploadFileData } from "../Data/interfaces/Upload/IUploadFileData";
import { IFileData } from "../Data/interfaces/ListagemArquivos/IListFilesResponse";
import { store } from "../Store";
import { UploadActions } from "../Store/Upload/Upload.actions";
import { Http } from "../Utils/Http";
import { ListagemArquivosActions } from "../Store/ListagemArquivos/ListagemArquivos.actions";
import toastHandler from "../Utils/toastHandler";
import axios from "axios";
import { FederatedViewerActions } from "Store/FederatedViewer/FederatedViewer.actions";
import { IUploadViewerResponse } from "./interfaces/FederatedViewer/IUploadViewerResponse";
import { fakeBytesToMB } from "Utils/toolBox";
import { Mixpanel } from "Utils/MixPanel";
import { ITrackUploadBulkFiles } from "Components/UI/UploadFiles";
import dayjs from "dayjs";

interface IOptions {
  file: IUploadFileData;
  fileList?: IUploadFileData[];
  fileName: string;
  fileSize: number;
  chunkSize: number;
  cancelToken: any;
  fileIdUpload: string;
}

interface IProgress {
  sent: number;
  total: number;
  percentage: number;
}

class UploadChunks {
    threadsQuantity: number;
    chunkSize: number;
    fileData: IUploadFileData;
    uploadFileListData?: IUploadFileData[];
    file: File;
    fileName: string;
    fileSize: number;
    cancelToken: any;
    fileIdUpload: string;
    aborted: boolean;
    uploadedSize: number;
    progressCache: any;
    activeConnections: any;
    chunkTotal: number;
    parts: number[];
    uploadedParts: number[];
    lastResponse: IFileData;
    stopChunks: boolean;
    onProgressFn: (event: IProgress) => void
    onFinishFn: (value?: any) => void
    onErrorFn: (error?: any) => void

  constructor(options: IOptions) {
    this.threadsQuantity = 4;
    this.chunkSize = options.chunkSize;
    this.fileData = options.file;
    this.uploadFileListData = options.fileList;
    this.file = options.file.file;
    this.fileName = options.fileName;
    this.fileSize = options.fileSize;
    this.cancelToken = options.cancelToken;
    this.fileIdUpload = options.fileIdUpload;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.chunkTotal = 0;
    this.parts = [];
    this.uploadedParts = [];
    this.lastResponse = {} as IFileData;
    this.stopChunks = false;
    this.onProgressFn = () => {};
    this.onFinishFn = () => {};
    this.onErrorFn = () => {};
  }

  // starting the multipart upload request
  async start() {
    this.initialize()
  }

  async initialize() {
    try {
      // initializing the multipart request
      if (!this.file) {
        throw new Error("Upload não será iniciado sem um arquivo.");
      }
      if (!this.fileIdUpload) {
          throw new Error("Upload não será iniciado sem um Id do arquivo.");
      }

      const numberOfparts = Math.ceil(this.fileSize / this.chunkSize);
      this.chunkTotal = numberOfparts;
      this.parts = new Array(numberOfparts).fill((value: any) => {}).map((_, index) => index).reverse();
      
      this.sendNext();  
    } catch (error) {
      await this.complete(error)
    }
  }

  // try send chunks
  async sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    const stoppedId = await store.getState().upload.stopped.uploadFileId;
    if (stoppedId === this.fileIdUpload) {
      this.stopChunks = await store.getState().upload.stopped?.isStopped;
    }

    if (this.stopChunks) {
      return
    }
    
    if (this.aborted) {
      return 
    }
    
    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      return
    }

    const part = this.parts.pop()

    if (typeof part !== 'undefined') {
      const sentSize = part * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.upload(chunk, part, sendChunkStarted)
        .then((value) => {
          this.sendNext()
        })
        .catch((error) => {
          // re-send error chunk
          // this.parts.push(part)
          this.complete(error.errors || error)
        })
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: any, response?: any) {
    if (error) {
      const stoppedId = await store.getState().upload.stopped.uploadFileId;
      if (stoppedId === this.fileIdUpload) {
        this.stopChunks = await store.getState().upload.stopped?.isStopped;
      }

      if (!this.stopChunks) {
        this.onErrorFn(error)
      }
      
      await store.dispatch(UploadActions.stopUploadChunkFile({
        isStopped: true,
        uploadFileId: this.fileIdUpload,
      }));
      this.abort();
      return
    }

    if (response) {
      this.lastResponse = response;
      return this.completeUpload()
    }
  }

  // return 'data' complete response
  completeUpload() {
    if (JSON.stringify(this.lastResponse) !== '{}') {
      return this.onFinishFn(this.lastResponse)
    }  
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: number, event?: any) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded * 0.7
      }

      if (event.type === "complete") {
        this.progressCache[part] = event.loaded
      }

      if (event.type === "loadend") {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      let sent = Math.min(this.uploadedSize + inProgress, this.fileSize)

      const total = this.fileSize

      const percentage = Math.round((sent / total) * 100)
      
      this.onProgressFn({
        sent: sent > 0 ? sent : 0,
        total: total,
        percentage: percentage,
      })
    }
  }

  // uploading a part
  upload(chunk: Blob, part: any, sendChunkStarted: () => void): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const api = this.activeConnections[part] = await Http.axiosToDownloadUploadFile();
      
      sendChunkStarted();
      
      const formData = new FormData();
      formData.append('chunk', chunk, this.fileName);

      const { 
        csId, 
        folderId, 
        newFileName, 
        disciplineApiFolderId, 
        checkUploadFileInfo, 
        Api,
        inViewer,
        constructionSiteDisciplineId, 
      } = this.fileData;

      const objQuery = {
        csId,
        folderId,
        disciplineApiFolderId,
        newFileName: newFileName || '',
        fileIdToOverwrite: checkUploadFileInfo?.FileIdToOverwrite || '',
        chunkIndex: part,
        chunkTotal: this.chunkTotal,
        fileIdUpload: this.fileIdUpload,
        fileSize: this.fileSize,
        fileName: this.fileName,
        api: Api,
        inViewer: inViewer,
        constructionSiteDisciplineId,
      }
      Object.entries(objQuery).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const url = '/api/Upload/PostV2';

      api.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: any) => {
          this.handleProgress(part, progressEvent)
        },
        cancelToken: this.cancelToken.token,
      }).then(({data}) => {
        if (data.data) {
          this.complete(null, data.data)
        }
        
        this.handleProgress(part, {
          type: 'complete',
          loaded: this.chunkSize,
        })
        
        resolve(data.success)
        delete this.activeConnections[part]
      }).catch((error: any) => {
        reject(error)
        delete this.activeConnections[part]
      });
    })
  }

  onProgress(onProgress: any) {
    this.onProgressFn = onProgress
    return this
  }

  onFinish(onFinish: any) {
    this.onFinishFn = onFinish
    return this
  }

  onError(onError: any) {
    this.onErrorFn = onError
    return this
  }

  // cancel all pending request
  abort() {
    this.cancelToken.cancel()
  }
}

export class UploadChunksApi {
  static uploadChunkFiles(upload: IUploadFileData): any {
    const trackUploadBulkFiles = store.getState().upload.trackUploadBulkFiles;
    const currentTenant = store.getState().tenant.currentListTenant;

    const beforeUpload = dayjs();
    const destiny = {
      constructionSiteId: Number(upload.csId),
      disciplineId: upload.constructionSiteDisciplineId,
      disciplineApiFolderId: upload.disciplineApiFolderId,
      disciplineName: upload.trackDisciplineName, 
      folderId: upload.folderId,
      folderName: upload.trackFolderName,
    };
    const trackDefaultUpload = {
      destiny: destiny,
      fileSize: upload.file.size, 
      api: upload.Api,
    };
    
    const { uploadFileList } = store.getState().upload;
    const cancelToken = axios.CancelToken.source();

    // chunk size 4mb for all and unique size for OneDrive
    let chunkSize = 1024 * 1024 * 4;
    if (upload.Api === 5) {
      chunkSize = 12 * 320 * 1024;
    }

    const uploader = new UploadChunks({
      file: upload,
      fileList: uploadFileList,
      fileName: upload.file.name,
      fileSize: upload.file.size,
      chunkSize,
      cancelToken,
      fileIdUpload: upload.fileIdUpload,
    });

    uploader
      .onProgress(({sent}: IProgress) => {
        if (sent) {
          upload.sentSize = sent;
          store.dispatch(UploadActions.updateUploadFile(upload));
        }
      })
      .onFinish((data: IFileData) => {
        if (data) {
          const uploadResponseTime = dayjs().diff(beforeUpload, 'milliseconds');
          Mixpanel.track({
            name: 'FILE_UPLOAD', 
            props: {
              ...trackDefaultUpload,
              elapsed: uploadResponseTime,
            },
            userInfo: upload.userInfoTracking,
            currentListTenant: currentTenant,
          });

          if (trackUploadBulkFiles?.active) {
            const request: ITrackUploadBulkFiles = {
              type: trackUploadBulkFiles.lastItem ? 'finish' : 'time',
              elapsedTime: uploadResponseTime,
              destiny: destiny,
              api: upload.Api,
            };
            store.dispatch(UploadActions.trackUploadBulkFiles(request));
          }

          store.dispatch(ListagemArquivosActions.addNewFile({
            ...data,
            hasRevisionControl: upload.hasRevisionControl,
          }));
          
          if (upload.inViewer && upload.discipline) {
            const newData: IUploadViewerResponse = {
              file: {
                ...data,
                FileInfo: {
                  ...data.FileInfo,
                  ConstructionSiteDisciplineFk: upload.discipline.ConstructionSiteDisciplinesId,
                  inViewer: true,
                },
                SizeInBytes: fakeBytesToMB(data.SizeInBytes),
              }, 
              discipline: upload.discipline,
            };
            store.dispatch(FederatedViewerActions.addNewFile(newData));
          }

          upload.updateFileInfo = { 
            ...data.FileInfo, 
            WebUrl: data.WebUrl,
            ConstructionSiteDisciplineFk: upload.constructionSiteDisciplineId,
            inViewer: upload.inViewer, 
          };
          upload.identifier = data.Identifier;
          store.dispatch(UploadActions.uploadFilesSuccess(upload))

          if (data && uploadFileList) {
            upload.isSending = false;
            upload.uploadCompleted = true;
            store.dispatch(UploadActions.updateUploadFile(upload));
          }
        }
      })
      .onError((e: any) => {
        const uploadErrorTime = dayjs().diff(beforeUpload, 'milliseconds');
        Mixpanel.track({
          name: 'FILE_UPLOAD_ERROR', 
          props: {
            ...trackDefaultUpload,
            elapsed: uploadErrorTime,
            api: upload.Api,
          },
          userInfo: upload.userInfoTracking,
          currentListTenant: currentTenant,
        });

        if (trackUploadBulkFiles?.active) {
          const request: ITrackUploadBulkFiles = {
            type: 'error',
            elapsedTime: uploadErrorTime,
            destiny: destiny,
            api: upload.Api,
          };
          store.dispatch(UploadActions.trackUploadBulkFiles(request));
        }

        upload.isSending = false;
        upload.uploadError = true;
        upload.uploadCompleted = true;
        const msg = 'Erro ao realizar upload de arquivo';
        const error = e ? (e[0]?.Message || msg) : msg;
        store.dispatch(UploadActions.uploadFilesSuccess(upload));
        store.dispatch(UploadActions.uploadFilesFailure(error));
        toastHandler.showError(error);
      })
      .start()
  }
}
