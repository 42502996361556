const initials = ['B', 'KB', 'MB', 'GB', 'TB'];

export const sizeFileToInitials = (size: number) => {
  let initialIndex = 0;

  while (size / 1024 >= 1) {
    size = size / 1024;
    initialIndex++;
  }

  return `${size.toFixed(1)}${initials[initialIndex]}`;
};

export const sizeFileRangeToInitials = (
  currentSize: number, 
  totalSize: number,
  showSize = false,
) => {
  let initialIndex = 0;

  if (currentSize > totalSize) {
    currentSize = totalSize;
  }

  while (totalSize / 1024 >= 1) {
    totalSize /= 1024;
    currentSize /= 1024;
    initialIndex++;
  }

  if (showSize) {
    return `${currentSize.toFixed(1)}${initials[initialIndex]}/${totalSize.toFixed(1)}${initials[initialIndex]}`;    
  }

  return `${currentSize.toFixed(1)} / ${totalSize.toFixed(1)} ${initials[initialIndex]}`;
};

export function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export const msplit = (value: string, separators: string[]) => {
  return separators.reduce((segs, sep) => segs.reduce(
    (out, seg) => out.concat(seg.split(sep)), [] as string[]
  ), [value]).filter(x => x);
};

export const fakeBytesToMB = (size: number) => {
  const cutSize = size.toString().split('').slice(0, -5);
  const lastNumber = cutSize.pop();
  const formatSize = `${cutSize}.${lastNumber || 0}`;
  return parseFloat(formatSize);
}
