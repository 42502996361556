import { ColorName, getColor } from "Utils/Color";
import styled, { css } from "styled-components";

interface IStyledIcon {
  backgroundColor?: ColorName;
  color?: ColorName;
  size?: 'xxs' | 'xs' | 'md' | 'lg' | 'xxl';
  customSize?: number;
  customColor?: string;
  error?: boolean;
  disabled?: boolean;
  blocked?: boolean;
}

export const StyledIcon = styled.i<IStyledIcon>`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor ? getColor(backgroundColor) : 'transparent'};
  color: ${({color}) => getColor(color || 'preto')};

  ${({ color, theme }) => color === 'onPrimary' && css`
    color: ${theme.colors.primary.onPrimary};
  `}

  ${({ color, theme }) => color === 'primary' && css`
    color: ${theme.colors.primary.primary};
  `}

  ${({ customColor }) => !!customColor && css`
    color: ${customColor};
  `}

  ${({ size }) => size === 'xxs' && css`
    height: 12px;
    width: 12px;
    font-size: 12px;
  `}

  ${({ size }) => size === 'xs' && css`
    height: 24px;
    width: 24px;
    font-size: 14px;
  `}

  ${({ size }) => size === 'md' && css`
    height: 32px;
    width: 32px;
    font-size: 22px;
  `}

  ${({ size }) => size === 'lg' && css`
    height: 42px;
    width: 42px;
    font-size: 28px;
  `}

  ${({ size }) => size === 'xxl' && css`
    height: 62px;
    width: 62px;
    font-size: 52px;
  `}

  ${({ customSize }) => customSize && css`
    font-size: ${customSize}px;
  `}

  ${({ blocked, theme }) => blocked && css`
    color: ${theme.colors.surface.onSurfacePlaceholder};
  `}

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.surface.onSurfaceVariant};
  `}
  
  ${({ error, theme }) => error && css`
    color: ${theme.colors.danger.danger};
  `}
`;
