import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AdmActions } from 'Store/Adm/Adm.actions';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import styles from './TrocarPlanos.module.scss';
import BreadCrumb, { IBreadcrumbItem } from 'Components/UI/BreadCrumb';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import BoxPlanChange from '../Components/BoxPlanChange';
import { IStorePlanResponse } from 'Data/interfaces/Store/IStorePlanResponse';
import { numberSort } from 'Utils/Sort';

interface IPlanoFaturamento {
  children?: React.ReactNode;
}

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Faturamento',
    href: '/faturamento',
  },
  {
    description: 'Alterar plano',
    href: '/faturamento/planos',
  }
];

const TrocarPlanos: FC<Props> = ({ 
  Planos, 
  currentPlanId, 
  currentPlanOrder,
  userInfo,
}) => {
  const dispatch = useDispatch();

  const [plans, setPlans] = React.useState<IStorePlanResponse[]>()

  useEffect(() => {
    dispatch(AdmActions.listPlans());
  }, []);

  useEffect(() => {
    setPlans(
      Planos?.filter((plan: IStorePlanResponse) => plan.isActive === 1)
        .sort((a: any, b: any) => numberSort(a.Order, b.Order))
    );
  }, [Planos]);

  return (
    <>
      <PageHeader name="Escolha um plano" />
      <BreadCrumb breadcrumbs={breadCrumb}/>
      
      <div className={styles['grid-wrapper']}>  
        {plans?.map((plan, index) => (
          <BoxPlanChange
            key={index}
            plan={plan}
            currentPlanId={currentPlanId}
            currentPlanOrder={currentPlanOrder}
            userInfo={userInfo}
          />
        ))}
      </div>
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  Planos: state.adm.Planos,
  currentPlanId: state.auth.userInfo?.UserStoreItem.StorePlan?.StorePlansId,
  currentPlanOrder: state.auth.userInfo?.UserStoreItem.StorePlan?.Order,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & IPlanoFaturamento;

export default connector(TrocarPlanos);
