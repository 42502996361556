export function copyToClipboard(text: string) {
  const elem = document.createElement('textarea');
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
};

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.slice(1);
};

export const actionImgOpenInNewWindow = (url: string) => {
  window.open(url, '_blank');
};

export const actionImgPrint = (url: string) => {
  window.print()
};

export const actionDownloadImage = (url: string, fileName: string) => {
  fetch(url, {
    method: "GET",
    headers: {}
  })
    .then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTextAndLastWord = (text: string) => {
  const arrayText = text.split(' ');
  const lastWord = arrayText.pop();
  const partialText = arrayText.join(' ');

  return {
    partialText,
    lastWord,
  }
};
