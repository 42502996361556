import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  z-index: 2;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .iconFile {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .fileName {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-left: 6px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .btnFolder {
    height: 28px !important;
    width: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconFolder {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  }

  .btnHistory {
    height: 28px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 12px !important;
    margin-left: 6px;

    .iconHistory {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }

    .textHistory {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
    }
  }

  .btnListContainer {
    height: 28px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
  }
`;
