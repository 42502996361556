import styled, { css } from "styled-components";

interface ILeftBarStyled {
  isCollapsed?: string;
  onlyCurrentTenant?: string;
  visible?: string;
}

export const DropdownStyled = styled.div<ILeftBarStyled>`
  display: flex;
  justify-content: left;
  align-items: center;
  min-height: 44px;
  height: 44px;
  margin: 3px 8px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.primary.primary};
  user-select: none;

  .iconAvatar {
    margin-left: 8px;
    margin-right: 10px;
  }

  span {
    color: ${({theme}) => theme.colors.primary.onPrimary};
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${({isCollapsed}) => isCollapsed === 'true' && css`
    height: 44px;
    width: 44px;
    margin: 3px 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconAvatar {
      margin-right: 8px;
    }
  `}

  .textDropdown {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .iconToggleWrap {
    margin-right: 12px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    
    .iconChildrenTop {
      transform: rotate(180deg);
      align-items: flex-start;
      color: ${({theme}) => theme.colors.primary.onPrimary} !important;

      ${({visible}) => visible === 'true' && css`
        transform: rotate(0deg);
        align-items: flex-end;
      `}
    }

    .iconChildrenBottom {
      align-items: flex-start;
      color: ${({theme}) => theme.colors.primary.onPrimary} !important;
      
      ${({visible}) => visible === 'true' && css`
        align-items: flex-end;
        transform: rotate(180deg);
      `}
    }
  }

  ${({onlyCurrentTenant}) => onlyCurrentTenant === 'true' && css`
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(50, 65, 80, 0.15), rgba(50, 65, 80, 0.15)), ${({theme}) => theme.colors.primary.primary};
    }
  `}
`;

export const MenuDropdown = styled.div`
  width: 250px;
  max-height: 320px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: scroll;
`;

interface IMenuItem {
  active?: string;
}

export const MenuItemStyled = styled.div<IMenuItem>`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 48px;
  padding: 12px 17px 12px 15px;
  cursor: pointer;

  ${({active}) => active === 'true' && css`
    background-color: ${({theme}) => theme.colors.surface.surface};
  `}

  .iconItem {
    margin-right: 10px;
  }

  .textItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color:  ${({theme}) => theme.colors.surface.onSurface};

      .iconConfirmacao {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }
    }
  }

  &:hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 113px;
    background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 11px;
    background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  }
`
