import { FC, useMemo } from "react";
import Tabs, { ITabMap } from "../Tabs";
import Attachments from "../Attachments";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import useActionsContent from "./useActionsContent";
import History from "../History";

interface IActionsContent {
  csId: number;
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
}

const ActionsContent: FC<IActionsContent> = ({
  csId,
  updateActivity,
  loadingExternalLink,
}) => {
  useActionsContent();

  const items = useMemo(() => {
    const opts: ITabMap = new Map();

    opts.set('attachement', {
      id: 'attachement',
      label: 'Anexos',
      isDisabled: false,
      tagNumber: updateActivity?.Attachments.length,
      children: (
        <Attachments
          csId={csId}
          updateActivity={updateActivity}
          loadingExternalLink={loadingExternalLink}
        />
      ),
    });

    if (!!updateActivity) {
      opts.set('comments', {
        id: 'comments',
        label: 'Comentários',
        isDisabled: true,
        children: <></>,
      });
      opts.set('history', {
        id: 'history',
        label: 'Histórico',
        isDisabled: false,
        children: (
          <History
            csId={csId}
            updateActivity={updateActivity}
            loadingExternalLink={loadingExternalLink}
          />
        ),
        customContentPadding: '7px 14px',
      });
    }

    return opts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    csId,
    loadingExternalLink,
    updateActivity,
    updateActivity?.Attachments.length,
  ]);

  return (
    <Tabs
      defaultActiveTab="attachement"
      options={items}
      loadingExternalLink={loadingExternalLink}
    />
  );
}

export default ActionsContent;
