import React, { FC } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { GetAvatar } from '../../../Utils/generateThumbnail';
import { Icon } from '../../../Components/UI';
import { ColumnProps } from 'antd/lib/table';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Ellipsis from '../../../Components/UI/Ellipsis';
import Table from '../../../Components/UI/Table';

import { MinhaContaActions } from '../../../Store/MinhaConta/MinhaConta.actions'
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { stringToPhone, stringToCNPJ, stringToCEP } from '../../../Utils/MaskUtils';

import styles from './DadosEmpresa.module.scss';

const DadosEmpresa: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const newDadosEmpresa = () => {
      const dadosSeguranca = [];

      let titleTelefone = !props.userInfo?.Company?.Phone || props.userInfo?.Company?.Phone == '' ? 'Telefone*' : 'Telefone';
      let valueTelefone = !props.userInfo?.Company?.Phone || props.userInfo?.Company?.Phone == '' ? '*' : props.userInfo?.Company?.Phone;

      let titleNomeEmpresa = !props.userInfo?.Company?.Name || props.userInfo?.Company?.Name == '' ? 'Nome da empresa*' : 'Nome da empresa';
      let valueNomeEmpresa = !props.userInfo?.Company?.Name || props.userInfo?.Company?.Name == '' ? '*' : props.userInfo?.Company?.Name;
      dadosSeguranca.push(
        {"title": titleNomeEmpresa, "value": valueNomeEmpresa}, 
        {"title": "CNPJ", "value": stringToCNPJ(props.userInfo?.Company?.CNPJ)},
        {"title": titleTelefone, "value": stringToPhone(valueTelefone)},
        {"title": "Responsável", "value": props.userInfo?.Company?.OwnerName},
        {"title": "Site", "value": props.userInfo?.Company?.Site},
      );
      return dadosSeguranca;
    }

    const newEnderecoEmpresa = () => {
      const dadosSeguranca = [];

      let titleCEP = !props.userInfo?.Company?.Zip || props.userInfo?.Company?.Zip == '' ? 'CEP*' : 'CEP';
      let valueCEP = !props.userInfo?.Company?.Zip || props.userInfo?.Company?.Zip == '' ? '*' : stringToCEP(props.userInfo?.Company?.Zip);

      let titleLogradouro = !props.userInfo?.Company?.StreetAddress || props.userInfo?.Company?.StreetAddress == '' ? 'Logradouro*' : 'Logradouro';
      let valueLogradouro = !props.userInfo?.Company?.StreetAddress || props.userInfo?.Company?.StreetAddress == '' ? '*' : props.userInfo?.Company?.StreetAddress;

      let titleNumero = !props.userInfo?.Company?.Number || props.userInfo?.Company?.Number === undefined ? 'Número' : 'Número';
      let valueNumero = !props.userInfo?.Company?.Number || props.userInfo?.Company?.Number === undefined ? '' : props.userInfo?.Company?.Number;

      let titleBairro = !props.userInfo?.Company?.District || props.userInfo?.Company?.District == '' ? 'Bairro*' : 'Bairro';
      let valueBairro = !props.userInfo?.Company?.District || props.userInfo?.Company?.District == '' ? '*' : props.userInfo?.Company?.District;

      let titleCidade = !props.userInfo?.Company?.City || props.userInfo?.Company?.City == '' ? 'Cidade*' : 'Cidade';
      let valueCidade = !props.userInfo?.Company?.City || props.userInfo?.Company?.City == '' ? '*' : props.userInfo?.Company?.City;
      dadosSeguranca.push(
        {"title": titleCEP, "value": valueCEP}, 
        {"title": titleLogradouro, "value": valueLogradouro},
        {"title": titleNumero, "value": valueNumero},
        {"title": titleBairro, "value": valueBairro},
        {"title": titleCidade, "value": valueCidade},
      );
      return dadosSeguranca;
    }

    const collumnsTable = (title: string): Array<ColumnProps<any>> => {
      return [
        {
          title: title,
          dataIndex: 'title',
          className: styles['dadoObrigatorio'],
          render:(text, record) => (
            <div className={styles['titleItem']}> 
              <span className={styles[`${text.substr(-1,1) == '*' ? 'titleObrigatorio' : 'title'}`]}>{text}</span>
            </div>
          )
        },
        {
          title: (<div className={styles['icon']} > <Icon color='cinzaIcone' icon='editar' onClick = {() => dispatch(MinhaContaActions.setShowModalUpdateEmpresa(true))}/> </div>),
          dataIndex: 'value',
          className: styles['dadoObrigatorio'],
          render:(text, record) => (
            <div className={styles['valueItem']}> 
              <span title={text == '*' ? 'Campo obrigatório' : text} className={styles[`${text == '*' ? 'valueObrigatorio' : 'value'}`]}>{text == '*' ? 'Campo obrigatório' : text}</span>
            </div>
          )
        },
      ]
    }

    return (
      <>
        <BoxContainer height={213}>
          <div className={styles['dadosEmpresaImg']}>
            <GetAvatar 
              name={props.userInfo?.Company?.Name || ''}  
              shape='square' fontSize={50} 
              src={`${process.env.REACT_APP_IMAGE_STORAGE_URL?.concat(props.userInfo?.Company?.AvatarFileName)}`}
              thumbSrc={`${process.env.REACT_APP_IMAGE_STORAGE_URL?.replace('/images/', '/thumbnails/')}${props.userInfo?.Company?.AvatarFileName}`}
              thumbType="big"
            />
          </div>

          <div className={styles['dadosEmpresaName']}>
            <strong>
              <Ellipsis phrase={props.userInfo?.Company?.Name || ''}  maxLength={13}/>
            </strong>
          </div>
        </BoxContainer>

        <div className = {styles['dadosEmpresaLeft']}>
          <Table
            dataSource = {newDadosEmpresa().map((dadosBaiscos: any, index: any) =>
              ({ ...dadosBaiscos, key: index })  
            )}
            columns = {collumnsTable('Dados da empresa')}
          />
        </div>

        <div className = {styles['dadosEmpresaRight']}>
          <Table 
            dataSource = {newEnderecoEmpresa().map((dadosSeguranca: any, index: any) =>
              ({ ...dadosSeguranca, key: index })  
            )}
            columns = {collumnsTable('Endereço da empresa')}
          />
        </div>
      </>
    );  
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(DadosEmpresa);
