import React, { FC } from 'react';

import styles from './PlanInfo.module.scss';
import { Icon } from 'Components/UI';

type IPlanInfo = {
  advantage?: string;
}

const PlanInfo: FC<IPlanInfo> = ({ advantage }) => {
  return (
    <div className={styles['wrapperIcon']}>
      <Icon icon="confirmacao" color="primary" customSize={10}/>
      <span>{advantage}</span>
    </div>
  )
}

export default PlanInfo;