import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { ILoginResponse } from './interfaces/Auth/ILoginResponse';
import { IRegisterRequest } from './interfaces/Auth/IRegisterRequest';
import { ILoginRequest } from './interfaces/Auth/ILoginRequest';
import { IForgotPasswordRequest } from './interfaces/Auth/IForgotPasswordRequest';
import { IResetPasswordRequest } from './interfaces/Auth/IResetPasswordRequest';
import { IRegisterGoogleRequest } from './interfaces/Auth/IRegisterGoogleRequest';
import { IUserInforResponse } from './interfaces/Auth/IUserInfoResponse';
import { IBillingDataResponse } from './interfaces/Auth/IBillingData';
import { IBillingDataRequest } from './interfaces/Auth/IBillingDataRequest';

export class AuthApi {
  static async signIn(body: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> {
    const axios = await Http.axios();
    return axios.post('/Account/Login', body);
  }

  static async signUp(body: IRegisterRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Account/Cadastro', body);
  }

  static async listCompanyPosition(): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get('/Account/getCargos');
  }

  static async signUpGoogle(body: IRegisterGoogleRequest): Promise<AxiosResponse<ILoginResponse>> {
    const axios = await Http.axios();
    return axios.post('/ExternalAuth/RegisterUserGoogle', body);
  }

  static async forgotPassword(body: IForgotPasswordRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Account/ForgotPassword', body);
  }

  static async resetPassword(body: IResetPasswordRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Account/ResetPassword', body);
  }

  static async getUserInfo(): Promise<AxiosResponse<IUserInforResponse>> {
    const axios = await Http.axios();
    return axios.get('/Account/GetUserInfo');
  }

  static async updateBillingData(billingData: IBillingDataRequest): Promise<AxiosResponse<IBillingDataResponse>> {
    const axios = await Http.axios();
    return axios.put('/Account/UpdateBillingData', billingData);
  }
}
