import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  z-index: 1;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }

  &+& {
    margin-top: 6px;
  }

  .avatarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nameContainer {
    margin-left: 12px;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    margin-left: 12px;
    z-index: 2;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`} !important;
    }

    .iconEdit {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }
`;
