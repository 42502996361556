import styled from "styled-components";

export const ColumnHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  transition: ease .2s all;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};

  .title {
    font-weight: 600;
    font-size: 14px;
    margin-left: 2px;
    text-transform: none;
  }

  .filterWrap {
    display: flex;
    align-items: center;

    .title {
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
      cursor: pointer;
      user-select: none;
    }

    .filterIcon {
      margin-right: 3px;
      cursor: pointer;

      :hover {
        background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.onSecondaryHover}, ${theme.colors.secondary.secondary})`} !important;
      }
    }
  }  

  .tooltipContainerInfo {

    .ant-tooltip-arrow-content{
      background-color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  }

  .infoWrap {
    display: flex;
    
    .title {
      color: ${({ theme }) => theme.colors.secondary.onSecondary};
      cursor: pointer;
      user-select: none;
    }

    .infoIcon {
      margin-right: 3px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }  

  .orderButton {
    height: 26px;
    width: 26px;
    border-radius: 50px;
    margin-left: 4px;
    margin-right: 9px;
    border: 1px solid transparent;
    cursor: pointer;

    .orderIcon {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.onSecondaryHover}, ${theme.colors.secondary.secondary})`} !important;
    }
  }
`;
