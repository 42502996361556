import styled, { css } from "styled-components"

export const UploadFileWrapper = styled.div`
  padding: 21px 26px 15px 26px;
  width: 611px;
  background: #f8fafb;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;

  .headerUploadWrapper {
    display: flex;
    user-select: none;
    cursor: pointer;
    padding-right: 14px;

    .title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: ${({theme}) => theme.colors.surface.onSurface};
      flex: 1;
    }

    .headerUploadIcon {
      color: ${({theme}) => theme.colors.surface.onSurface} !important;
    }
  }

  .listFilesWrapper {
    max-height: 320px;
    overflow: auto;
  }
`;

export const FileItemWrapper = styled.div `
  display: flex;
  flex-direction: column;
  padding: 15px 25px 0 25px;
  position: relative;

  .fileItemDescriptionWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
  }

  .fileItemFooterWrapper {

    .loading {
      height: 26px;
      width: 26px !important;
    }
  }
`;


interface IFilenameAndEditWrapper {
  showedit?: string;
}

export const FilenameAndEditWrapper = styled.div<IFilenameAndEditWrapper>`
  display: flex;
  flex: 1;
  align-items: center;

  .filenameWrapper {
    margin-left: 28px;
    display: flex;
    flex-direction: column;

    .fileName {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      word-break: break-all;
      color: ${({theme}) => theme.colors.surface.onSurface};

      .hasError {
        display: flex;
        color: ${({theme}) => theme.colors.danger.danger};
      }
    }
    .fileSize {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  }

  .editButton {
    cursor: pointer;
    margin-left: 26px;
  }

  ${({showedit}) => showedit === 'true' && css`
    margin-right: 30px;
  `}
`;

interface IFormWrapper {
  isstory?: boolean;
  isdescription?: boolean;
}

export const FormWrapper = styled.div<IFormWrapper>`
  display: flex;
  flex-direction: row;

  ${({isstory, isdescription}) => isstory && isdescription && css`
    padding-right: 25px;
  `}
`;

interface INomenclatureFieldsWrapper {
  isstory?: boolean; 
}

export const NomenclatureFieldsWrapper = styled.div<INomenclatureFieldsWrapper>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-right: 49px;
  padding: 8px 0 0 2px;

  .fieldsWrapper {
    min-width: 90px;
    margin-right: ${({isstory}) => isstory ? 29 : 13}px;

    label {
       
      input {
        height: 30px;
      }
    }

    .fieldSelect {
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.surface.surface};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  }

  scrollbar-color: ${({theme}) => `${theme.colors.surface.onSurface}${theme.colors.surface.surface}`};
  scrollbar-width: thin;
`;

export const RemoveFileButton = styled.span`
  cursor: pointer;
  user-select: none;
`;

interface IConfirmButton {
  isdescription?: string;
  isnewfilename?: string;
}

export const ConfirmButton = styled.button<IConfirmButton>`
  padding: 3px 0 0 2px;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  outline: none;
  height: 26px;
  width: 26px;

  &:disabled {
    cursor: default;
    i {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    }
  }

  .confirmIcon {
    color: ${({theme}) => theme.colors.success.success} !important;
  }

  ${({isdescription}) => isdescription === 'true' && css`
    position: absolute;
    right: 25px;
    top: 15px;
  `}

  ${({isnewfilename}) => isnewfilename === 'true' && css`
    float: right;
  `}
`;

export const OpenOriginFolder = styled.span`
  height: 26px;
  width: 26px;
  background: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;

  .openCloudServiceIcon {
    color: ${({theme}) => theme.colors.surface.surface} !important;
  }
`;

export const NewFileNameOptionsWrap = styled.div`
  padding-bottom: 10px;

  .titleButtonWrap {
    display: flex;
    padding-bottom: 5px;

    .existFileTitle {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      flex: 1;
    }
  }

  .radioButtonsWrap {
    padding-left: 5px;

    .newFileNameOption {
      padding-left: 7px;
      color: ${({theme}) => theme.colors.surface.onSurface};
      word-break: break-all;
      cursor: pointer;

      .titleOption {
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
      }
    }
  }
`;
