import { FC } from 'react';
import styles from './ForgotPassword.module.scss';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Button from '../../../Components/UI/Button';
import Background from '../Backgroud/background';
import Form from '../../../Components/UI/Form/Form';
import { IForgotPasswordRequest } from '../../../Data/interfaces/Auth/IForgotPasswordRequest';
import { useDispatch, ConnectedProps, connect, useSelector } from 'react-redux';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import schema from './forgotPassword.schema';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import Field from '../../../Components/UI/Field';
import Link from 'Components/UI/Link';
import { getDomain } from 'Store/MultiDomain/MultiDomain.selector';

const ForgotPassword: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);

  const signup = (data: IForgotPasswordRequest) => {
    dispatch(AuthActions.userForgotPassword(data));
  };

  return (
    <Background>
      <BoxContainer height={domain?.LogoUrl ? 300 : 251} width={403}>
        <div className={styles['contentWrapper']}>
          <h2 className={styles['title']}>Esqueceu a senha?</h2>
          <Form schema={schema} onSubmit={signup}>
            <Field
              name="email"
              label="E-mail"
              icon="arroba"
              placeholder="usuario@dominio.com.br"
            />
            <div className={styles['buttonWrapper']}>
              <Button
                width="100%"
                type="primary"
                htmlType="submit"
                loading={props.isLoading}
              >
                Recuperar senha
              </Button>
            </div>
          </Form>

          <div className={styles['backLoginWrapper']}>
            <Link to="/login">
              Voltar para o login
            </Link>
          </div>

          {domain?.LogoUrl && (
            <p className={styles['powered-by']}>
              Powered by <span>Coordly</span>
            </p>
          )}
        </div>
      </BoxContainer>
    </Background>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

export default connector(ForgotPassword);
