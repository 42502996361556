import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: absolute;
  width: 275px;
  height: 280px;
  z-index: 2;
  background-color: ${({theme}) => theme.colors.surface.surface};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  .Wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 275px;
    height: 280px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;

      .iconBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        i {
          cursor: pointer;
        }

        .tag {
          height: 20px;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 8px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 10.89px;
          border-radius: 19px;
        }
      }

      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 19.07px;
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
      
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      }
    }

    .Footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .link {
        padding: 10px 20px;
        margin-right: 10px;

        a {
          font-family: Open Sans;
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          color: ${({theme}) => theme.colors.surface.onSurface};
          text-decoration: none;
        }
      }
    }
  }
`;
