import React, { FC, useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import styles from "./Planos.module.scss";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import PageHeader from "Components/UI/PageHeader/PageHeader";
import Table from "Components/UI/Table";
import TableActionTopbar, { ITableActionTopbarButton } from "Components/UI/TableActionTopbar";
import { getColumnsTablePlans } from "./Planos.columns";
import { IStorePlanResponse } from "Data/interfaces/Store/IStorePlanResponse";
import { AdmActions } from "Store/Adm/Adm.actions";
import { Skeleton } from "antd";
import ExclusaoPlanoForm from "./Modals/ExclusaoPlanoForm";
import PlanoForm from "./Modals/PlanoForm";
import { history } from "Store";

const Plano: FC<Props> = ({ Planos, isLoading }) => {
  const dispatch = useDispatch();

  const [selectedPlans, setSelectedPlans] = React.useState([]);
  const [planId, setPlanId] = React.useState<number>(0);
  const [planDeleteId, setPlanDeleteId] = React.useState<number>();
  const [showCreateOrUpdateModalPlan, setShowCreateOrUpdateModalPlan] = React.useState(false);
  const [showDeleteModalPlan, setShowDeleteModalPlan] = React.useState(false);

  const sortPlansList = (sortOrder: boolean, key: string) => {
    const sortedPlans = [
      ...(Planos?.sort((a: any, b: any) => {
        const propA = a[key] || 0 || '';
        const propB = b[key] || 0 || '';

        if (typeof propA === 'number' && typeof propB === 'number') {
          return sortOrder ? propB - propA : propA - propB;
        }

        return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
      }) || [])
    ];

    let dataSortedPlans: IStorePlanResponse[] | undefined = Planos;
    dataSortedPlans = sortedPlans;

    dispatch(AdmActions.listPlansUpdate(dataSortedPlans));
  };

  const getActionsToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        icon: 'voltar',
        description: 'Voltar para Controle Administrativo de Tenants',
        action: () => history.push('/adm/tenants'),
      },
      {
        icon: 'arquivar',
        description: 'Criar Plano',
        hidden: selectedPlans.length > 0,
        action: newPlan,
      },
      {
        icon: 'atualizar',
        description: 'Atualizar Plano',
        hidden: selectedPlans.length !== 1,
        action: () => {
          if (Planos){
            const index = selectedPlans[0];
            const plan = Planos[index];
            editPlan(plan.StorePlansId);
          }
        },
      },
      {
        icon: 'excluir',
        description: 'Excluir Plano',
        hidden: selectedPlans.length !== 1,
        action: () => {
          if (Planos) {
            const index = selectedPlans[0];
            const plan = Planos[index];
            deletePlan(plan.StorePlansId);
          }
        },
      },
    ];
  };

  const editPlan = (
    id: any
  ) => {
    setPlanId(id);
    setShowCreateOrUpdateModalPlan(true);
  };

  const deletePlan = (
    id: number
  ) => {
    setPlanDeleteId(id);
    setShowDeleteModalPlan(true);
  };

  const newPlan = () => {
    setPlanId(0);
    setSelectedPlans([]);
    setShowCreateOrUpdateModalPlan(true);
  }

  const closeModal = () => {
    setShowCreateOrUpdateModalPlan(false);
    setShowDeleteModalPlan(false);
  }

  const closeUpdateModal = () => {
    setPlanId(0);
    setSelectedPlans([]);
    setShowCreateOrUpdateModalPlan(false);
  }

  const onConfirmDelete = () => {
    setShowDeleteModalPlan(false);
    const request = {
      planId: planDeleteId
    }
    dispatch(AdmActions.deletePlan(request));
    setSelectedPlans([]);
  }

  const onChange = (selecteds: any) => setSelectedPlans(selecteds)

  const rowSelection = {
    onChange: onChange,
  };

  useEffect(() => {
    dispatch(AdmActions.listPlans());
  }, []);

  return (
    <>
      <PageHeader name="Controle Administrativo dos Planos" />

      <div className={styles['tableWrapper']}>
        <TableActionTopbar buttons={getActionsToolbar()} />
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={getColumnsTablePlans({
              plansList: Planos,
              sortPlansList
            })}
            dataSource={Planos?.map((plans: any, index: number) => (
              { ...plans, key: index }
            ))}
            rowSelection={rowSelection}
          />
        )}
      </div>

      {showCreateOrUpdateModalPlan ?
        <PlanoForm
          onClose={closeModal}
          planId={planId}
          visible={showCreateOrUpdateModalPlan}
          closeUpdateModal={closeUpdateModal}
        />
        : null}

      {showDeleteModalPlan ?
        <ExclusaoPlanoForm
          onCancel={closeModal}
          visible={showDeleteModalPlan}
          onConfirmDelete={onConfirmDelete}
        />
        : null}
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Plano);