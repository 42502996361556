import { FC } from 'react';
import { Tag } from 'antd';
import { Modal } from './styles';
import { Icon } from 'Components/UI';
import qrCodeImg from '../../../../Static/images/qr-code-info.png';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { useSelector } from 'react-redux';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import Button from 'Components/UI/Button';

type ModalModalInfoQRCodeProps = {
  visible: boolean;
  onCancel: () => void;
}

const ModalInfoQRCode: FC<ModalModalInfoQRCodeProps> = ({ 
  visible,
  onCancel, 
}) => {
  const theme = useSelector(getTheme);

  const qrCodeBlogUrlFlag = useFeatureFlag('qr-code-blog-url');
  const qrCodeUrl = (qrCodeBlogUrlFlag.enabled && typeof qrCodeBlogUrlFlag.value === 'string') 
    ? qrCodeBlogUrlFlag.value 
    : 'https://www.maletadoengenheiro.com.br/blog';

  if (!visible) {
    return null;
  }
  
  return (
    <Modal>
      <div className="header">
        <div className="imageBox">
          <img src={qrCodeImg} alt="Pranchas com QR Code" />
        </div>
        <div className="iconBox">
          <Icon 
            icon='cancelar' 
            customSize={10} 
            color='branco' 
            onClick={onCancel}
          />
        </div>
      </div>

      <div className="Wrapper">
        <div className="content">
          <div className="tagBox">
            <Tag color={theme.colors.primary.primary} className="tag">
              Nova funcionalidade
            </Tag>
          </div>

          <h4 className="title">
            Rastreamento de documentos via QR Code!
          </h4>
          <p className="description">
            Agora você pode incorporar um QR Code rastreável nas plotagens. Assim, quando alguém escanear uma prancha obsoleta, será notificado que a prancha deve ser substituída.
          </p>
        </div>

        <div className="Footer">
          <div className="link">
            <a
              href={qrCodeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Saiba mais
            </a>
          </div>
          <Button 
            type="primary"
            className="button"
            onClick={onCancel}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalInfoQRCode;
