import styled from "styled-components";
import { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.surface.surface};
  
  div {

    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 2px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .error {
    color: ${({theme}) => theme.colors.danger.danger};
  }
`;


interface IDNSWrapper {
  isInternal?: string;
  isOK?: string;
}

export const DNSWrapper = styled.div<IDNSWrapper>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;

  .dnsConfirmIcon {
    color: ${({theme}) => theme.colors.success.success} !important;
  }

  .dnsRelogioIcon {
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;

    ${({isInternal, theme}) => isInternal === 'true' && css`
      color: ${theme.colors.warning.warning} !important;
    `}
  }

  .dnsErrorIcon {
    color: ${({theme}) => theme.colors.danger.danger} !important;
  }
  
  .infoWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .info {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};

      ${({isInternal}) => isInternal === 'true' && css`
        color: ${({theme}) => theme.colors.warning.warning};
      `}

      ${({isOK}) => isOK === 'true' && css`
        color: ${({theme}) => theme.colors.success.success};
      `}
    }

    .dns {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      
      .dnsBold {
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
    }
  }

  .iconBtn {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dnsEditIcon {
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
  }

  .cnameBtn {
    border-radius: 5px;
    margin-left: 6px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
  }

  .internalBtn {
    border-radius: 5px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
  }
`;

interface IInfoWrapper {
  isInternal?: string;
  isError?: string;
}

export const InfoWrapper = styled.div<IInfoWrapper>`
  width: 100%;
  margin-top: 16px;
  
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
  }

  .infoInternal {
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
  }

  .infoError {
    line-height: 16px;
    color: ${({theme}) => theme.colors.danger.danger} !important;
  }
`;
