import React, { FC } from "react";

import styles from "./LoadingUploadDisciplineList.module.scss";

import SkeletonMain from "../LoadingMain";

export interface ILoadingUploadDisciplineList {
  multiple?: number;
  isCollapsed?: boolean;
}

const LoadingUploadDisciplineList: FC<ILoadingUploadDisciplineList> = ({ 
  multiple = 1, 
  isCollapsed,
}) => {
  const SkeletonUsers = () => {
    return (
      <div className={styles['rowWrapper']}>
        <div className={styles['rowLeft']}>
        <SkeletonMain 
          width="16px" 
          height="16px"
          minWidth="16px" 
          borderRadius="2px"
        />
        <SkeletonMain 
          width="191px" 
          height="12px" 
          borderRadius="2px" 
          marginLeft="12px" 
        />
        </div>
        <div>
          <SkeletonMain 
            width="8px" 
            height="8px"
            minWidth="8px" 
            borderRadius="2px"
          />
        </div>
      </div>
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <div className={styles['mainWrapper']}>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} />
      ))}
    </div>
  )
}

export default LoadingUploadDisciplineList;