import { FC, useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';

interface IHTMLEditor {
  html: string;
  onHtml: (html: string) => void;
}

const HTMLEditor: FC<IHTMLEditor> = ({
  html,
  onHtml,
}) => {
  const [key, setKey] = useState('key1');

  const handleBeforeChange = (editor: any, data: any, value: string) => {
    onHtml(value);
  };

  useEffect(() => {
    setKey('key2');
  }, []);

  return (
    <div>
      <CodeMirror
        key={key}
        value={html}
        options={{
          mode: 'htmlmixed',
          theme: 'default',
          lineNumbers: true,
          lineWrapping: true,
        }}
        onBeforeChange={handleBeforeChange}
      />
    </div>
  );
};

export default HTMLEditor;
