import { FC } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import {
  BtnContainer,
  Container,
  Content,
  Title,
} from "./styles";

interface IConfirmDefault {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDefault: FC<IConfirmDefault> = ({
  visible,
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">
            Substituir etapa padrão?
          </span>
        </Title>

        <Content>
          <span className="contentText">
            Você tem certeza que deseja substituir a etapa padrão atual?
          </span>
        </Content>

        <BtnContainer>
          <Button type="text" className="cancelBtn" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            type="primary"
            className="confirmBtn"
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </BtnContainer>
      </Container>
    </Modal>
  )
}

export default ConfirmDefault;
