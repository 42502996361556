import { call, put } from 'redux-saga/effects';
import { PlotagensApi } from '../../Data/Plotagens.api';
import toastHandler from '../../Utils/toastHandler';
import { loadError, createError } from '../../Utils/Messages';
import {
  PlotagemActions,
  listDisciplinasPlotagemAction,
  listPlotagemArquivosAction,
  UpdatePlotagemArquivosStatusAction,
  PlotRequestAction,
  listPlotagemSolicitacoesDisciplinasAction,
  ExcluiPlotagemArquivosAction,
  ReenviaPlotagemAction,
  RecebePlotagemAction, DownloadRelatorioExcelAction
} from './Plotagem.actions';
import {
  ListagemArquivosActions
} from '../ListagemArquivos/ListagemArquivos.actions';
import { IPlotagemSolicitacaoResponse } from '../../Data/interfaces/Plotagem/IPlotagemListResponse';
import { IDisciplineResponse } from '../../Data/interfaces/Plotagem/IPlotagemData';
import { FilePlotagemStatusEnum, IArquivoDataResponse } from '../../Data/interfaces/Plotagem/IArquivoData';
import { Mixpanel } from 'Utils/MixPanel';

export function* list() {
  try {
    const { data } = yield call(PlotagensApi.list);
    yield put(PlotagemActions.listSuccess(data.data));
  } catch {
    yield put(PlotagemActions.listFailure('Erro ao carregar obras'));
  }
}

export function* listDisciplinasPlotagem({ payload }: listDisciplinasPlotagemAction) {
  try {
    const { data } = yield call(PlotagensApi.listDisciplinasPlotagem, payload);
    const response = data.data;
    response.ConstructionSiteId = payload;
    const dataReturn: IDisciplineResponse = {
      data: data.data,
      info: data.info,
    }
    yield put(PlotagemActions.listDisciplinasPlotagemSuccess(dataReturn));
  } catch (e: any) {
    const msg = loadError('disciplinas');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.listDisciplinasPlotagemFailure(error));
  }
}

export function* listPlotagemArquivos({ payload }: listPlotagemArquivosAction) {
  try {
    const { data } = yield call(PlotagensApi.listPlotagemArquivos, payload);
    const response = data.data;
    response.ConstructionSiteId = payload;
    const dataReturn: IArquivoDataResponse = {
      data: data.data,
      info: data.info
    }
    yield put(PlotagemActions.listPlotagemArquivosSuccess(dataReturn));
  } catch (e: any) {
    const msg = loadError('arquivos');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.listPlotagemArquivosFailure(error));
  }
}

export function* updatePlotagemArquivosStatus({ payload }: UpdatePlotagemArquivosStatusAction) {
  try {
    const { userInfoTracking, currentTenantTracking } = payload;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    yield call(PlotagensApi.updatePlotagemArquivosStatus, payload);

    Mixpanel.track({
      name: 'PLOT_STATUS_CHANGE', 
      props: {
        constructionSiteId: Number(payload?.ConstructionSiteId),
        disciplineId: Number(payload?.ConstructionSiteDisciplineFk),
        status: payload?.Status,
        statusDescription: FilePlotagemStatusEnum[payload?.Status],
        fileQuantity: payload?.PlottingFilesId?.length,
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    yield put(PlotagemActions.listPlotagemArquivos(payload.ConstructionSiteDisciplineFk));
  } catch (e: any) {
    const msg = 'Erro ao alterar status da plotagem';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(PlotagemActions.updatePlotagemArquivosStatusFailure(error));
  }
}

export function* plotRequest({ payload }: PlotRequestAction) {
  const {
    disciplineIdTracking,
    userInfoTracking,
    currentTenantTracking,
  } = payload;
  delete payload.disciplineIdTracking;
  delete payload.userInfoTracking;
  delete payload.currentTenantTracking;

  try {
    const { data } = yield call(PlotagensApi.plotRequest, payload);

    payload.Files.map(file => 
      Mixpanel.track({
        name: 'PLOT_REQUEST', 
        props: {
          constructionSiteId: payload?.ConstructionSiteFk,
          disciplineId: disciplineIdTracking,
          disciplineApiFolderId: file?.ConstructionSiteDisciplineFolderId, 
          fileQuantity: file?.QtyCopies,
        },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      })
    );

    yield put(PlotagemActions.plotRequestSuccess(data.data));
    yield put(ListagemArquivosActions.setShowPlotRequestTable(false));
    toastHandler.showSuccess('Solicitação de plotagem realizada com sucesso.');
  } catch (e: any) {
    payload.Files.map(file => 
      Mixpanel.track({
        name: 'PLOT_REQUEST_ERROR', 
        props: {
          constructionSiteId: payload?.ConstructionSiteFk,
          disciplineId: disciplineIdTracking,
          disciplineApiFolderId: file?.ConstructionSiteDisciplineFolderId, 
          fileQuantity: file?.QtyCopies,
        },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      })
    );

    const msg = createError('solicitar plotagem');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.plotRequestFailure('Erro ao solicitar plotagem'));
  }
}

export function* listPlotagemSolicitacoes({ payload }: listPlotagemSolicitacoesDisciplinasAction) {
  try {
    const { data } = yield call(PlotagensApi.listPlotagemSolicitacoes, payload);
    const response = data.data;
    response.ConstructionSiteId = payload;
    yield put(PlotagemActions.listPlotagemSolicitacoesSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('Erro ao listar as solicitações de plotagem');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.listPlotagemSolicitacoesFailure(error));
  }
}

export function* listPlotagemSolicitacoesDisciplinas({ payload }: listPlotagemSolicitacoesDisciplinasAction) {
  try {
    const { data } = yield call(PlotagensApi.listPlotagemSolicitacoesDisciplinas, payload);
    const response = data.data;
    response.ConstructionSiteDisciplineFk = payload;
    const dataReturn: IPlotagemSolicitacaoResponse = {
      data: data.data,
      info: data.info,
    }
    yield put(PlotagemActions.listPlotagemSolicitacoesDisciplinasSuccess(dataReturn));
  } catch (e: any) {
    const msg = loadError('Erro ao listar as solicitações de plotagem');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.listPlotagemSolicitacoesDisciplinasFailure(error));
  }
}

export function* excluiArquivosPlotting({ payload }: ExcluiPlotagemArquivosAction) {
  try {
    const { data } = yield call(PlotagensApi.excluiArquivosPlotting, payload);
    yield put(PlotagemActions.excluiPlotagemArquivosSuccess(data.data));
  } catch {
    yield put(PlotagemActions.excluiPlotagemArquivosFailure('Erro ao excluir arquivo da plotagem'));
  }
}

export function* reenviaPlotagem({ payload }: ReenviaPlotagemAction) {
  yield put(PlotagemActions.setSubmitedReenviaSolicitacaoPlotagem(true));
  try {
    const { data } = yield call(PlotagensApi.reenviaPlotagem, payload);
    yield put(PlotagemActions.reenviaPlotagemSuccess(data.data));
    toastHandler.showSuccess('Solicitação de plotagem reenviada!');
    yield put(PlotagemActions.setShowModalReenviaSolicitacaoPlotagem(false));
    yield put(PlotagemActions.setSubmitedReenviaSolicitacaoPlotagem(true));
  } catch (e: any) {
    const msg = createError('plotagem');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.reenviaPlotagemFailure(error));
  }
}

export function* recebePlotagem({ payload }: RecebePlotagemAction) {
  try {
    const {
      disciplineIdTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.disciplineIdTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { data } = yield call(PlotagensApi.recebePlotagem, payload);

    Mixpanel.track({
      name: 'PLOT_STATUS_CHANGE', 
      props: {
        constructionSiteId: Number(payload?.ConstructionSiteId),
        disciplineId: Number(disciplineIdTracking),
        status: 1,
        statusDescription: FilePlotagemStatusEnum[1],
        fileQuantity: payload?.PlottingFilesId?.length,
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });

    yield put(PlotagemActions.plotRequestSuccess(data.data));
    toastHandler.showSuccess('Plotagem recebida com sucesso.');
  } catch (e: any) {
    const msg = loadError('Erro ao receber as solicitações de plotagem');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(PlotagemActions.plotRequestFailure('Erro ao receber plotagem'));
  }
}

export function* downloadRelatorioExcel({ payload }: DownloadRelatorioExcelAction) {
  try {
    const { 
      constructionSiteIdInfoTracking,
      userInfoTracking, 
      currentTenantTracking,
    } = payload;
    delete payload.constructionSiteIdInfoTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { data } = yield call(PlotagensApi.downloadRelatorioExcel, payload);

    Mixpanel.track({
      name: 'REPORT_CREATE', 
      props: {
        constructionSiteId: Number(constructionSiteIdInfoTracking),
        disciplineId: Number(payload.ConstructionSiteDisciplineId),
        fileQuantity: payload.ArquivosRelatorio.length,
        reportType: 'excel',
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    })

    yield put(PlotagemActions.downloadRelatorioExcelSuccess(data.data));
  } catch (e: any) {
    const msg = 'Erro ao realizar download do relatório';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(PlotagemActions.downloadRelatorioExcelFailure(error));
  }
}