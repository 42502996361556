import { IPlotagemState } from './interfaces/IPlotagemState';
import { PlotagemActionUnion, PlotagemActionKeys } from './Plotagem.actions';
import { IPlotagemData, IDisciplineResponse } from '../../Data/interfaces/Plotagem/IPlotagemData';
import { IPlotagemArquivo } from '../../Data/interfaces/Plotagem/IPlotagemArquivo';
import { IPlotRequestRequest } from '../../Data/interfaces/Plotagem/IPlotRequestRequest';
import { IUsersConstructionSiteResponse } from '../../Data/interfaces/User/IUsersConstructionSiteResponse';
import { IPlotagemSolicitacaoResponse } from '../../Data/interfaces/Plotagem/IPlotagemListResponse';
import { IArquivoDataResponse } from '../../Data/interfaces/Plotagem/IArquivoData';
import { IProgressDownloading } from '../../Data/interfaces/Plotagem/IDownloadRelatorioRequest';

const initialState: IPlotagemState = {
  Users: {} as IUsersConstructionSiteResponse,
  isLoading: false,
  Plotagem: {} as IPlotagemData,
  Arquivo: [],
  filters: {
    showDescription: false,
    showArquivo: false,
    showStatus: false,
    showVersions: false,
    showSolicitacao: {
      show: false,
      showDropdown: false,
      showData: false,
      showUsers: false,
    },
  },
  ArquivoPrint: [],
  ArquivoResponse: {} as IArquivoDataResponse,
  PlotagemSolicitacao: [],
  PlotagemSolicitacaoResponse: {} as IPlotagemSolicitacaoResponse,
  showModalReenviaSolicitacaoPlotagem: false,
  submitedReenviaSolicitacaoPlotagem: false,
  showModalExcluiArquivosPlotagem: false,
  PlotagemArquivo: {} as IPlotagemArquivo,
  Disciplina: [],
  DisciplinaResponse: {} as IDisciplineResponse,
  PlottingRequest: {} as IPlotRequestRequest,
  progressDownload: {} as IProgressDownloading,
};

const plotagemReducer = (state = initialState, action: PlotagemActionUnion): IPlotagemState => {
  switch (action.type) {
    case PlotagemActionKeys.LIST_PLOTAGEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_SUCCESS:
      return {
        ...state,
        PlotagensList: action.payload,
        isLoading: false,
      };

    // DISCIPLINAS
    case PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_SUCCESS:
      return {
        ...state,
        DisciplinaResponse: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_FAILED:
      return {
        ...state,
        error: action.payload,
        DisciplinaResponse: {} as IDisciplineResponse,
        isLoading: false,
      };
    case PlotagemActionKeys.UPDATE_PLOTAGEM_DISCIPLINES_LIST:
      return {
        ...state,
        isLoading: false,
        DisciplinaResponse: action.payload,
      };

    // ARQUIVOS
    case PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_SUCCESS:
      return {
        ...state,
        ArquivoResponse: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_LIST:
      return {
        ...state,
        isLoading: false,
        ArquivoResponse: action.payload,
      };

    // ALTERA STATUS DA PLOTAGEM
    case PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_SUCCESS:
      action.payload.PlottingFilesId.forEach((fileId) => {
        const plotagemArquivoStatus = state.Arquivo.find((file) => file.PlottingFilesId === fileId);
        if (plotagemArquivoStatus) {
          plotagemArquivoStatus.PlottingFilesId = fileId;
          plotagemArquivoStatus.Status = action.payload.Status;
        }
      });
      return {
        ...state,
        Arquivo: [...state.Arquivo],
        isLoading: false,
      };
    case PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // SOLICITA PLOTAGEM
    case PlotagemActionKeys.PLOT_REQUEST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.PLOT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PlotagemActionKeys.PLOT_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    // LISTA AS SOLICITAÇÕES DE PLOTAGEM
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_SUCCESS:
      return {
        ...state,
        PlotagemSolicitacao: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // LISTA AS SOLICITAÇÕES DE PLOTAGEM DISCIPLINAS
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_SUCCESS:
      return {
        ...state,
        PlotagemSolicitacaoResponse: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case PlotagemActionKeys.UPDATE_PLOTAGEM_SOLICITACOES_DISCIPLINAS_LIST:
      return {
        ...state,
        isLoading: false,
        PlotagemSolicitacaoResponse: action.payload,
      };

    // EXCLUI ARQUIVO DE PLOTAGEM
    case PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_SUCCESS:
      const removedIds = action.payload.PlottingFilesId;
      const newListArquivos = [...state.ArquivoResponse.data].filter(arquivo => removedIds.indexOf(arquivo.PlottingFilesId) === -1);
      const arquivoResponse = { ...state.ArquivoResponse };
      arquivoResponse.data = newListArquivos;

      return {
        ...state,
        isLoading: false,
        ArquivoResponse: arquivoResponse
      };

    // REENVIA PLOTAGEM
    case PlotagemActionKeys.REENVIA_PLOTAGEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.REENVIA_PLOTAGEM_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PlotagemActionKeys.REENVIA_PLOTAGEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    // REENVIA PLOTAGEM
    case PlotagemActionKeys.RECEBE_PLOTAGEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PlotagemActionKeys.RECEBE_PLOTAGEM_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PlotagemActionKeys.RECEBE_PLOTAGEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };


    case PlotagemActionKeys.SET_SHOW_MODAL_REENVIA_SOLICITACAO_PLOTAGEM:
      return {
        ...state,
        showModalReenviaSolicitacaoPlotagem: action.payload,
      };

    case PlotagemActionKeys.SET_SHOW_MODAL_EXCLUI_ARQUIVOS_PLOTAGEM:
      return {
        ...state,
        showModalExcluiArquivosPlotagem: action.payload,
      };

    case PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDownloading: true
      };
    case PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_SUCCESS:
      const progressBar = {
        total: 1000,
        loaded: 0,
      }
      return {
        ...state,
        isLoading: false,
        isDownloading: false,
        progressDownload: progressBar
      };
    case PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isDownloading: false
      };
    case PlotagemActionKeys.PROGRESS_DOWNLOAD:
      return {
        ...state,
        progressDownload: action.payload
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_DESCRIPTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          showDescription: action.payload,
        },
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_ARQUIVO:
      return {
        ...state,
        filters: {
          ...state.filters,
          showArquivo: action.payload,
        },
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showStatus: action.payload,
        },
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_VERSIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showVersions: action.payload,
        },
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO:
      return {
        ...state,
        filters: {
          ...state.filters,
          showSolicitacao: {
            show: action.payload.show,
            showDropdown: action.payload.showDropdown,
            showData: false,
            showUsers: false,
          },
        },
      };

    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          showSolicitacao: {
            show: true,
            showDropdown: false,
            showData: action.payload,
            showUsers: false,
          },
        },
      };
    case PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_USERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showSolicitacao: {
            show: true,
            showDropdown: false,
            showData: false,
            showUsers: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default plotagemReducer;
