import styled, { css } from "styled-components";

export const FilesContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 12px;
`;

export const FilesStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }

  .arrowIcon {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    margin-left: 6px;
  }
`;

interface IFileNameContainer {
  isdisabled?: string;
}

export const FileNameContainer = styled.div<IFileNameContainer>`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 6px 8px 6px 6px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin-top: 6px;
  word-break: break-all; 
  overflow: hidden;

  .assitantIcon {
    height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .assistantText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-left: 6px;
  }

  ${({ isdisabled, theme }) => isdisabled === 'true' && css`
    background-color: ${theme.colors.surface.surfaceContainer};

    .assitantIcon {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }

    .assistantText {
      text-decoration-line: line-through;
      color: ${theme.colors.surface.onSurfacePlaceholder};
    }
  `}
`;

type VariantType = 'revision' | 'render';

interface IFileStatusValue {
  isbloqued?: string;
  isold?: string;
  isvariant?: VariantType;
  customBGColor?: string;
  customColor?: string;
}

export const FileStatusValue = styled.span<IFileStatusValue>`
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${({ theme, customBGColor }) => customBGColor
    ? customBGColor
    : `color-mix(in srgb, ${theme.colors.success.successContainer}, ${theme.colors.surface.surface})`
  };
  opacity: ${({ isold }) => isold === 'true' ? 0.5 : 1};

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 6px;
  color: ${({ theme, customColor }) => customColor
    ? customColor
    : theme.colors.success.success
  };
  text-decoration: ${({ isold }) => isold === 'true'
    ? 'line-through'
    : 'none'};

  ${({ isbloqued, theme }) => isbloqued === 'true' && css`
    background-color: ${theme.colors.secondary.secondary};
    color: ${theme.colors.secondary.onSecondaryVariant};
  `}

  ${({ isvariant, theme }) => isvariant === 'revision' && css`
    color: ${theme.colors.secondary.onSecondary};
  `}

  ${({ isvariant, theme }) => isvariant === 'render' && css`
    color: ${theme.colors.information.information};
    background-color: ${`color-mix(in srgb, ${theme.colors.information.informationContainer}, ${theme.colors.surface.surface})`};
  `}
`;

export const FileMoveContainer = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .footer {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const CurveLink = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: start;
  justify-content: end;
  margin-top: 6px;
  margin-right: 6px;

  .curve {
    width: 14px;
    height: 14px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  }
`;

interface IFileDescriptionContainer {
  isdisabled?: string;
}

export const FileDescriptionContainer = styled.div<IFileDescriptionContainer>`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};
  margin-top: 6px;

  .assistantText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
  }

  ${({ isdisabled, theme }) => isdisabled === 'true' && css`
    .assistantText {
      text-decoration-line: line-through;
      color: ${theme.colors.surface.onSurfacePlaceholder};
    }
  `}
`;

interface IFileTitleContainer {
  isactive?: string;
}

export const FileTitleContainer = styled.div<IFileTitleContainer>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 12px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  
  &+& {
    margin-top: 6px;
  }

  .assistantText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
    text-decoration-line: line-through;
  }

  ${({ isactive, theme }) => isactive === 'true' && css`
    border: none;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};

    .assistantText {
      text-decoration-line: none;
      color: ${theme.colors.secondary.onSecondary};
    }
  `}
`;

interface IUsersContainer {
  isold?: boolean;
}

export const UsersContainer = styled.div<IUsersContainer>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin-left: -6px;

  &+& {
    margin-top: 12px;
  }

  .usersContent {
    margin-left: 6px;

    .avatar {
      opacity: ${({isold}) => isold ? 0.5 : 1};
    }

    .name {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ isold, theme }) => isold 
        ? theme.colors.surface.onSurfacePlaceholder
        : theme.colors.surface.onSurface};
      margin-left: 4px;
      text-decoration: ${({isold}) => isold ? 'line-through' : undefined};
    }
  }

  .disciplinesContent {
    display: flex;
    align-items: center;
    margin-left: 6px;

    .name {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ isold, theme }) => isold 
        ? theme.colors.surface.onSurfacePlaceholder
        : theme.colors.surface.onSurface};
      margin-left: 4px;
      text-decoration: ${({isold}) => isold ? 'line-through' : undefined};
    }

    .iconDisciplina {
      color: ${({ isold, theme }) => isold 
        ? theme.colors.surface.onSurfacePlaceholder
        : theme.colors.surface.onSurfaceVariant} !important;
    }
  }
`;

interface IFileDueDateValue {
  isold?: boolean;
}

export const FileDueDateValue = styled.span<IFileDueDateValue>`
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border-radius: 4px;

  .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ isold, theme }) => isold 
      ? theme.colors.surface.onSurfacePlaceholder
      : theme.colors.surface.onSurface};
    text-decoration: ${({isold}) => isold ? 'line-through' : undefined};
  }

  .iconDate {
    color: ${({ isold, theme }) => isold 
      ? theme.colors.surface.onSurfacePlaceholder
      : theme.colors.surface.onSurfaceVariant} !important;
  }

  ${({isold, theme}) => isold && css`
    .iconVisibility {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }

    .iconPriority {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }
  `}
`;

interface IPontisContainer {
  isold?: boolean;
}

export const PontisContainer = styled.div<IPontisContainer>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin-left: -6px;

  &+& {
    margin-top: 12px;
  }

  .name {
    border-radius: 4px;
    color: ${({ isold, theme }) => isold 
      ? theme.colors.surface.onSurfacePlaceholder
      : theme.colors.secondary.onSecondary};
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
    text-decoration: ${({isold}) => isold ? 'line-through' : undefined};
    padding: 2px 4px;
    margin-left: 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`;

interface IImgContainer {
  isempty?: boolean;
}

export const ImgContainer = styled.div<IImgContainer>`
  width: 96px;
  min-width: 96px;
  max-width: 96px;
  height: 72px;
  min-height: 72px;
  max-height: 72px;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};

  .img {
    width: 96px;
    min-width: 96px;
    max-width: 96px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    border-radius: 4px;
    object-fit: contain;
  }

  ${({isempty}) => isempty && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.colors.secondary.secondary};

    .iconEmptyAlert {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }

    .emptyText {
      font-size: 10px;
      font-weight: 600;
      line-height: 14px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  `}
`;
