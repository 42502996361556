import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 750px;
  height: 692px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const TitleContainer = styled.div`
  width: 750px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};

  .titleText {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .iconClose {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }
`;

export const Main = styled.div`
  width: 750px;
  height: 646px;
  display: flex;
  flex-direction: row;
`;

export const Left = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-right: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};
  
  .leftOptionList {
    display: flex;
    flex-direction: column;
  }
`;

interface IDivider {
  margin?: number;
}

export const Divider = styled.div<IDivider>`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin: ${({ margin }) => margin ? margin : 6}px 0;
`;

interface ILeftOptionRow {
  isactive?: string;
}

export const LeftOptionRow = styled.div<ILeftOptionRow>`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 0 12px;
  cursor: pointer;

  .leftCoordlyIconOptionRow {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
    background-color: ${({ theme }) => theme.colors.primary.primary} !important;
  }

  .leftIconOptionRowCustom {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .leftIconOptionRow {
    width: 16px;
    height: 16px;
  }

  .leftOptionRowText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  ${({ isactive }) => isactive === 'true' && css`
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};

    .leftOptionRowText {
      color: ${({ theme }) => theme.colors.primary.primary};
    }
  `}
`;

export const Right = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const RightMainContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .rightTitleText {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .rightDescriptionText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 6px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }
`;

export const CardSrcContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
`;

interface ICardSrcOption {
  isactive?: string;
}

export const CardSrcOption = styled.div<ICardSrcOption>`
  width: 214px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;

  .rightIconSrcOption {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
  }

  .rightSrcTypeText {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
  }

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.secondaryHover}, ${theme.colors.secondary.secondary})`};
  }

  ${({ isactive }) => isactive === 'true' && css`
    background-color: ${({ theme }) => theme.colors.secondary.secondary};

    .rightIconSrcOption {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  `}
`;

export const InputContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .rightInpuContainerHeader {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .rightInpuContainerMain {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .rightInputLabel {
    display: flex;
    flex-direction: row;
    align-items: center;

    .leftIconOptionRowCustom {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }

    .leftIconOptionRow {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .rightInputLabelText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
  
      .danger {
        color: ${({ theme }) => theme.colors.danger.danger};
      }
    }
  }

  .rightInput {
    height: 32px !important;
    border-radius: 3px;
    border: none;
    margin-top: 6px;
    background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};

    &:focus {
      outline-color: transparent;
    }

    ::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
    }
  }

  .rightInputArea {
    height: 200px !important;
    border-radius: 3px;
    border: none;
    margin-top: 6px;
    padding: 8px;
    resize: none;
    background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};

    &:focus {
      outline-color: transparent;
    }

    ::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
    }
  }
`;

export const RightFooterContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const RightFooterContent = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .rightFooterContentLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .rightCheckbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .rightCheckboxIcon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      }

      .rightCheckboxText {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }
    }
  }
`;
