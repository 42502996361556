import React, { CSSProperties, FC } from "react";

import styles from "./PlanContainer.module.scss";

interface IPlanContainer {
  children?: React.ReactNode;
  boxWrapper?: boolean;
  width?: string;
  heigth?: string;
  style?: CSSProperties;
}

const PlanContainer: FC<IPlanContainer> = ({ children, boxWrapper, style, heigth, width }) => {
  return (
    <div className={boxWrapper ? styles['PlanContainerWrapper'] : ''}>
      <div 
        className={styles['BoxContainer']} 
        style={{...style, height: heigth, width: width}}
      >
        {children}
      </div>
    </div>
  )
}

export default PlanContainer;