import axios, { AxiosInstance } from 'axios';
import { ACCESS_TOKEN_KEY, CURRENT_TENANT_KEY } from '../Store/Auth/Auth.constants';
import AppStorage from './AppStorage';
import { AuthUtils } from './AuthUtils';
import { SERVER_ERROR } from './Messages';
import toastHandler from '../Utils/toastHandler';
import { store } from 'Store';
import { AuthActions } from 'Store/Auth/Auth.actions';

let faliedQueue: any[] = [];
let isRefreshing = false;

export class Http {
  static async axios(showWarningAlert = true): Promise<AxiosInstance> {
    return new Promise((resolve) => {
      const authorization = AppStorage.GetItem(ACCESS_TOKEN_KEY);
      const currentTenantId = AppStorage.GetItem(CURRENT_TENANT_KEY, 'session') 
        || AppStorage.GetItem(CURRENT_TENANT_KEY);

      const headers = authorization
        ? {
            Authorization: `Bearer ${authorization}`,
            'Current-TenantId': currentTenantId || undefined
          }
        : undefined;

      const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_API_URL,
        headers
      });

      let timeout: number;
      if (showWarningAlert) {
        timeout = window.setInterval(async () => {
          await store.getState().auth.warningRequestTime;
          if (!store.getState().auth.warningRequestTime) {
            toastHandler.showWarningRequest(
              'Está demorando mais do que o normal...',
              'Aguarde mais um pouco ou tente recarregar sua página.'
            );
          }
          store.dispatch(AuthActions.warningRequestTime(true));
          window.clearTimeout(timeout);
        }, 12000);
      }

      const clearWarningMessage = async () => {
        window.clearTimeout(timeout);
        await store.dispatch(AuthActions.warningRequestTime(false));
      };

      instance.interceptors.response.use(
        (response) => {
          clearWarningMessage();

          return response;
        },
        (err) => Http.resolveErros(err, clearWarningMessage)
      );

      return resolve(instance);
    });
  }

  static async axiosToDownloadUploadFile(): Promise<AxiosInstance> {
    return new Promise((resolve) => {
      const authorization = AppStorage.GetItem(ACCESS_TOKEN_KEY);

      const headers = authorization
        ? {
            Authorization: `Bearer ${authorization}`
          }
        : undefined;

      const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_DOWNLOAD_URL,
        headers
      });
      instance.interceptors.response.use(undefined, Http.resolveErros);
      return resolve(instance);
    });
  }

  static resolveErros(err: any, clearWarningMessage?: () => void) {
    if (clearWarningMessage) {
      clearWarningMessage();
    }
    const res = err.response;
    switch (res.status) {
      case 401:
        return Http.refreshTokenInterceptor(err);
      case 500:
        const serverError = {
          success: false,
          errors: [{ Message: SERVER_ERROR }]
        };
        throw serverError;
      case 404:
        const serverErrorNotFound = {
          success: false,
          errors: [{ Message: SERVER_ERROR }]
        };
        throw serverErrorNotFound;
      default:
        throw err.response.data;
    }
  }

  static refreshTokenInterceptor(err: any) {
    const originalRequest = err.config;

    if (!originalRequest._retry) {
      if (isRefreshing) {
        return Http.addToFaliedQueue(originalRequest);
      }

      isRefreshing = true;
      originalRequest._retry = true;

      return Http.refreshToken(originalRequest);
    }
  }

  static refreshToken(originalRequest: any) {
    const user = AuthUtils.getLoggedUser();

    return new Promise(function(resolve, reject) {
      const instanceToRefresh = axios.create({
        baseURL: process.env.REACT_APP_BASE_API_URL
      });

      instanceToRefresh
        .post('/Account/RefreshToken', {
          RefreshToken: user.refreshToken
        })
        .then(({ data }) => {
          const result = JSON.parse(data.data);
          AuthUtils.setLoggedUser({
            ...user,
            accessToken: result.access_token,
            refreshToken: result.refresh_token,
            expiresIn: result.expires_in
          });

          Http.processQueue(undefined, result.access_token);

          resolve(
            axios({
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${result.access_token}`
              }
            })
          );
        })
        .catch((err: any) => {
          faliedQueue = [];
          Http.processQueue(err, undefined);
          reject(err);
          AuthUtils.logout();
          // Do not redirect if we are already in the login page
          if (window.location.href.indexOf('login') === -1) {
            window.location.href = '/login';
          }
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  static processQueue(err: any, token?: string) {
    faliedQueue.forEach((promise) => {
      if (err) {
        promise.reject(err);
      } else {
        promise.resolve(token);
      }
    });
  }

  static addToFaliedQueue(originalRequest: any) {
    return new Promise(function(resolve, reject) {
      faliedQueue.push({ resolve, reject });
    })
      .then((token: any) => {
        return axios({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${token}`
          }
        });
      })
      .catch((err: any) => Promise.reject(err));
  }

  static GetQueryParams(key: string) {
    return new URLSearchParams(window.location.search).get(key);
  }

  static objectToQueryString(obj: object) {
    return Object.keys(obj)
      .reduce((query, prop) => `${query}&${prop}=${(obj as any)[prop]}`, '')
      .substr(1);
  }

  static async getAxiosForge(accessToken: string): Promise<AxiosInstance> {
    return new Promise((resolve) => {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      };

      const instance = axios.create({
        baseURL: process.env.REACT_APP_FORGE_API_URL,
        headers
      });

      instance.interceptors.response.use(undefined, Http.resolveErros);

      return resolve(instance);
    });
  }
}
