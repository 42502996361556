import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: -12px;

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-top: 24px;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-top: 6px;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;

  .addBtn {
    display: flex;
    align-items: center;
  }

  .libBtn {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  .configBtn {
    display: flex;
    align-items: center;
  }

  .actionIcon {
    color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
  }

  .textBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
    margin-left: 6px;
  }

  .textConfigBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
  }

  .configIcon {
    color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    margin-left: 6px;
  }
`;
