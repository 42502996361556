import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import { Checkbox, Skeleton, Button } from 'antd';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import {
  IExtensionData,
  FileVisualizationTypeEnumLabel,
  IBindSuportedFileExtensions
} from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import { NomenclaturaActions } from '../../../Store/Nomenclatura/Nomenclatura.actions';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import toastHandler from '../../../Utils/toastHandler';
import Modal from '../../../Components/UI/Modal/Modal';
import { isObjectEmpty } from 'Utils/ObjectUtils';
import { Mixpanel } from 'Utils/MixPanel';
import styles from './Extensoes.module.scss';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Configurar Serviço',
    href: '#'
  },
  {
    description: 'Gerenciamento de extensões',
    href: '/config/extensoes'
  }
];

interface IExtensionGroup {
  type: string;
  extensions: string[];
  extensionsCheck: string[];
  indeterminate: boolean;
  checkAll: boolean;
}

const Extensoes: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const CheckboxGroup = Checkbox.Group;
  const [isSubmit, setIsSubmit] = useState(false);
  const [extensionsGroup, setExtensionsGroup] = useState<IExtensionGroup[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  if (!props.isLoading && isSubmit) {
    setModalVisible(false);
    setIsSubmit(false);
  }

  useEffect(() => {
    if (!props.ExtensionsData?.length) {
      dispatch(NomenclaturaActions.getExtensions());
    }
    if (props.ExtensionsData?.length && !isSubmit) {
      setExtensionsGroup(getExtensionsRowFromData(props.ExtensionsData));
    }
  }, [props.isLoading, props.ExtensionsData?.length]);

  const getExtensionsRowFromData = (extensionsData: IExtensionData[]) => {
    const extensionsGroup: IExtensionGroup[] = [];

    extensionsData.forEach((extensionData) => {
      let fileTypeLabel =
        FileVisualizationTypeEnumLabel.get(extensionData.FileVisualizationTypeEnum) ||
        'Indefined';

      if (!extensionsGroup.find((x) => x.type == fileTypeLabel))
        extensionsGroup.push({
          type: fileTypeLabel,
          extensions: [],
          extensionsCheck: [],
          checkAll: false,
          indeterminate: false
        } as IExtensionGroup);

      extensionsGroup
        .find((x) => x.type == fileTypeLabel)
        ?.extensions.push(extensionData.Extension);

      if (extensionData.Versionable)
        extensionsGroup
          .find((x) => x.type == fileTypeLabel)
          ?.extensionsCheck.push(extensionData.Extension);
    });

    extensionsGroup.forEach((extensionGroup) => {
      extensionGroup.indeterminate =
        !!extensionGroup.extensionsCheck.length &&
        extensionGroup.extensionsCheck.length < extensionGroup.extensions.length;
      extensionGroup.checkAll =
        extensionGroup.extensionsCheck.length === extensionGroup.extensions.length;
    });

    return extensionsGroup;
  };

  const onChange = (checkedList: any, type: string) => {
    const extensionsGroupAux = [...extensionsGroup];
    extensionsGroupAux.forEach((extensionGroup) => {
      if (extensionGroup.type == type) {
        extensionGroup.extensionsCheck = checkedList;
        extensionGroup.indeterminate =
          !!checkedList.length && checkedList.length < extensionGroup.extensions.length;
        extensionGroup.checkAll = checkedList.length === extensionGroup.extensions.length;
      }
    });

    setExtensionsGroup(extensionsGroupAux);
  };

  const onCheckAllChange = (e: any, type: string) => {
    const extensionsGroupAux = [...extensionsGroup];
    extensionsGroupAux.forEach((extensionGroup) => {
      if (extensionGroup.type == type) {
        extensionGroup.extensionsCheck = e.target.checked
          ? extensionGroup.extensions
          : [];
        extensionGroup.indeterminate = false;
        extensionGroup.checkAll = e.target.checked;
      }
    });

    setExtensionsGroup(extensionsGroupAux);
  };

  const getErrors = () => {
    let countExtensionCheck = 0;

    extensionsGroup.forEach((extensionGroup) => {
      extensionGroup.extensionsCheck.forEach((extension) => {
        countExtensionCheck++;
      });
    });

    if (countExtensionCheck == 0) {
      return 'Selecione uma ou mais extensões';
    }

    return null;
  };

  const submit = () => {
    setIsSubmit(true);

    if (!props.isLoading) {
      const errors = getErrors();
      if (errors) {
        toastHandler.showError(errors);
      } else {
        const requestData: IBindSuportedFileExtensions[] = [];
        extensionsGroup.forEach((extensionGroup) => {
          extensionGroup.extensionsCheck.forEach((extension) => {
            requestData.push({ Extension: extension } as IBindSuportedFileExtensions);
          });
        });

        dispatch(NomenclaturaActions.setExtensions(requestData));

        Mixpanel.track({
          name: 'EXTENSION_CONFIG_CHANGE',
          props: {},
          userInfo: props.userInfo,
          currentListTenant: props.currentTenant,
        });
      }
    }
  };

  useEffect(() => {
    if (!props.minhaConta || isObjectEmpty(props.minhaConta))
      dispatch(AuthActions.getUserInfo());
  }, []);

  const footerButtons = (
    <>
      <Button type="default" loading={props.isLoading} onClick={() => submit()}>
        Estou ciente e desejo prosseguir
      </Button>
      <Button
        type="primary"
        loading={props.isLoading}
        onClick={() => setModalVisible(false)}
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <>
      {props.minhaConta.CurrentRoleFk === 1 || props.minhaConta.CurrentRoleFk === 7 ? (
        <PageHeader name="Gerenciamento de extensões">
          <BreadCrumb breadcrumbs={breadCrumb} />
          <div className={styles['extensoesContainer']}>
            {props.isLoading && !isSubmit ? (
              <Skeleton active />
            ) : (
              <>
                {extensionsGroup.map((extensionGroup, index) => (
                  <div>
                    <div
                      className={'site-checkbox-all-wrapper ' + styles['extensionGroup']}
                    >
                      <Checkbox
                        indeterminate={extensionGroup.indeterminate}
                        checked={extensionGroup.checkAll}
                        onChange={(e) => onCheckAllChange(e, extensionGroup.type)}
                      >
                        {extensionGroup.type}
                      </Checkbox>
                    </div>
                    <CheckboxGroup
                      className={styles['extensionItem']}
                      options={extensionGroup.extensions}
                      value={extensionGroup.extensionsCheck}
                      onChange={(e) => onChange(e, extensionGroup.type)}
                    />
                  </div>
                ))}
                <div className={styles['saveButton']}>
                  <Button
                    type="primary"
                    onClick={() => setModalVisible(true)}
                    loading={props.isLoading}
                  >
                    <span style={{ padding: '0 35px' }}>Salvar</span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </PageHeader>
      ) : (
        <>
          {props.minhaConta.CurrentRoleFk === 4 ||
            props.minhaConta.CurrentRoleFk === 3 ||
            props.minhaConta.CurrentRoleFk === 5 ? (
            <Redirect to="/obras" />
          ) : (
            <></>
          )}
        </>
      )}
      <Modal
        width={500}
        title="Atenção"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footerButtons={footerButtons}
      >
        <div className={styles['textWrap']}>
          <div className={styles['margin']}>
            Atenção, essa alteração nas configurações pode impactar todo o sistema. Deseja
            confirmar?
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  extensions: state.nomenclatura.ExtensionsData,
  minhaConta: state.minhaConta.userInfo,
  userInfo: state.auth.userInfo,
  currentTenant: state.tenant.currentListTenant,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Extensoes);
