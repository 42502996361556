import React, { FC } from 'react';

import styles from './NovaPasta.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import Form from '../../../../Components/UI/Form/Form';
import Field from '../../../../Components/UI/Field';
import schema from './novaPasta.schema';

interface INovaPasta {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: (folderName: string) => void;
  isLoading?: boolean;
}

const NovaPasta: FC<INovaPasta> = ({ visible, isLoading, onCancel, onConfirm }) => {
  const [isSubmited, setIsSubmited] = React.useState(false);

  const createFolder = (values: any, errors: any) => {
    if (onConfirm && values) {
      onConfirm(values.folderName);
    }
    setIsSubmited(false);
  };

  const buttons = (
    <div>
      <Button height={37} onClick={onCancel}>
        <span style={{ padding: '0 20px' }}>
          Cancelar
        </span>
      </Button>
      <Button
        type='primary'
        height={37}
        onClick={() => setIsSubmited(true)}
        loading={isLoading}>
        <span style={{ padding: '0 18px' }}>
          Criar pasta
        </span>
      </Button>
    </div>
  );

  return (
    <Modal title='Nova pasta' footerButtons={buttons} visible={visible} width={692} onCancel={onCancel}>
      <Form onSubmit={createFolder} schema={schema} isSubmited={isSubmited} className={styles['form']} resetForm={true}>
        <Field name='folderName' label='Nome da pasta' />
      </Form>
    </Modal>
  );
};

export default NovaPasta;
