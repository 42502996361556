import styled, { css } from "styled-components"

export const Buttons = styled.div`
  .buttonCancel {
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  .buttonSubmit {
    border-radius: 5px;
  }
`;

export const MidleHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
`;

export const BodyDefault = styled.div`
  display: flex;
  align-items: center;
`;

interface IBodyDescription {
  isupdate?: string;
}

export const BodyDescription = styled.div<IBodyDescription>`
  ${({isupdate}) => isupdate === 'true' && css`
    display: flex;
    align-self: flex-start;
  `}
`;

interface IBodyText {
  isdescription?: string;
  iserror?: string;
}

export const BodyText = styled.span<IBodyText>`
  min-width: 100px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: ${({theme}) => theme.colors.surface.onSurface};

  ${({isdescription}) => isdescription === 'true' && css`
    margin-top: 16px;
  `}

  ${({iserror}) => iserror === 'true' && css`
    color: ${({theme}) => theme.colors.danger.danger};
  `}
`;
