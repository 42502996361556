import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  height: 44px;
  display: flex;
  align-items: center;
  
  .habIconDownload {
    color: #FFFFFF !important;
    font-size: 22px !important;
    cursor: pointer !important;
  }
  
  .habIconPrinter {
    color: #FFFFFF !important;
    font-size: 22px !important;
    cursor: pointer !important;
  }
  
  .habIconMoreAction {
    color: #FFFFFF !important;
    font-size: 22px !important;
    cursor: pointer !important;
  }
`;

export const ButtonIcon = styled.button`
  background-color: transparent;
  width: 36px;
  max-width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  overflow: hidden;

  &+& {
    margin-left: 12px;
  }
`;
