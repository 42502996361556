import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Select as SelectAnt } from 'antd';
import { SelectProps as SelectPropsAnt } from 'antd/es/select';
import { Field as FieldReact, FieldRenderProps } from 'react-final-form';
import styles from './NewSimpleSelect.module.scss';
import { Icon, IconName } from 'Components/UI/Icon';
import { ICustomOption } from 'Pages/Activities/Modals/ShowCreateActivity/useOptionsSelect';
import { 
  TypePathUpdateData, 
  TypeValueUpdateData,
} from 'Data/interfaces/Activities/IUpdateActivityRequest';
import { LabeledValue } from 'antd/lib/select';
import Tooltip from 'Components/UI/Tooltip';
import './select.css';
const { Option } = SelectAnt;

export interface IOptionData {
  value?: string | number;
  description: React.ReactNode | string;
  label?: string;
  removed?: boolean;
  isDivider?: boolean;
  customClass?: string;
  color?: string;
  icon?: IconName;
}

interface INewSimpleSelect extends SelectPropsAnt {
  options: ICustomOption[];
  optionsCustom?: ICustomOption[];
  name: string;
  style?: CSSProperties
  activePlaceholder?: boolean;
  isUpdate?: boolean;
  onBlur?: () => void;
  onSelect?: (e: any) => void;
  path?: TypePathUpdateData;
  onDynamicUpdateSubmit?: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  notRequired?: boolean;
  havePermission?: boolean;
}

const NewSimpleSelect: FC<INewSimpleSelect> = ({
  options,
  optionsCustom,
  name,
  style,
  activePlaceholder,
  isUpdate,
  onBlur,
  onSelect,
  path,
  onDynamicUpdateSubmit,
  notRequired,
  defaultValue,
  value,
  placeholder,
  havePermission,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const isSubmit = useRef(false);
  
  useEffect(() => {
    if (showToolTip) {
      const timeout = setTimeout(() => {
        setShowToolTip(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [showToolTip]);

  const handleCloseInput = () => {
    if (notRequired) {
      setTimeout(() => {
        if (!isSubmit.current) {
          setIsActive(false);
        }
      }, 150);
    } else {
      setIsActive(false);
    }
  };

  const handleClearInput = (
    field: FieldRenderProps<string | number | string[] | number[] | LabeledValue | LabeledValue[], HTMLElement>,
  ) => {
    isSubmit.current = true;
    if (isUpdate && path && onDynamicUpdateSubmit) {
      onDynamicUpdateSubmit(path, null);
      setIsActive(false);
    } else {
      field.input.onChange(null);
      setIsActive(false);
    }
  };

  return (
    <FieldReact name={name} defaultValue={defaultValue} initialValue={value}>
      {
        (propsField) => (
          isActive ? (
            <div
              className={styles['selectWrap']}
              style={style}
            >
              <SelectAnt
                {...propsField.input}
                value={propsField.input.value?.toString()?.length === 0 ? undefined : propsField.input.value}
                showArrow={false}
                defaultValue={defaultValue}
                placeholder={placeholder}
                onBlur={() => {
                  onBlur && onBlur();
                  handleCloseInput();
                }}
                onSelect={(e: any) => {
                  onSelect && onSelect(e);
                  if (isUpdate && path && onDynamicUpdateSubmit) {
                    onDynamicUpdateSubmit(path, e as number);
                  }
                  setIsActive(false);
                }}
                className="select-custom"
                defaultOpen
                showSearch
                autoFocus
                filterOption={(inputValue, options) => {
                  if (options?.key) {
                    return options.key.toString().toLowerCase().includes(inputValue.toLowerCase())
                  } else {
                    return true;
                  }
                }}
                style={{ width: '100%' }}
              >
                {options
                  .map((option, index: number) => (
                    <Option
                      value={option.id}
                      className={styles['option']}
                      key={option.key}
                    >
                      {option.label}
                    </Option>
                ))}
              </SelectAnt>
              {(notRequired && propsField.input.value) && (
                <span
                  className={styles['iconClear']}
                  onClick={() => handleClearInput(propsField)}
                >
                  <Icon 
                    icon="error"
                    color="cinzaOpaco"
                    customSize={10}
                  />
                </span>
              )}
            </div>
          ) : (
            <Tooltip
              overlay={
                showToolTip && (
                  'Você não tem permissão de alterar o status dessa atividade.' 
                )
              }
              placement="top"
              visible={showToolTip}
            >
            <div 
              className={`
                ${styles['noActiveWrapper']}
                ${havePermission ? '' : styles['nPCursor']}
                ${showToolTip ? styles['nPCursorTool'] : ''}
              `}
              onClick={() => {
                if (havePermission) {
                  setIsActive(true);
                } else {
                  setShowToolTip(true);
                }
                isSubmit.current = false;
              }}
            >
              <span className={`${activePlaceholder ? styles['noActiveText'] : styles['noActiveTextSoft']}`}>
                {optionsCustom && (
                  optionsCustom.find(value => value.id === propsField.input.value)?.label || placeholder
                )}
                {!optionsCustom && (
                  options.find(value => value.id === propsField.input.value)?.label || placeholder
                )}
              </span>
            </div>
            </Tooltip>
          )
        )
      }
    </FieldReact>
  );
};

export { Option };
export default NewSimpleSelect;
