import { IconName } from '../Components/UI';

export const disciplineIcons = new Map<string, IconName>([
  ['maleta-icons icon-ic-disciplina-architeture', 'disciplinaArquitetura'],
  ['maleta-icons icon-ic-disciplina-block', 'disciplinaEstrutura'],
  ['maleta-icons icon-ic-disciplina-energy', 'disciplinaEletrica'],
  ['maleta-icons icon-ic-disciplina-hidro', 'disciplinaHidrossanitaria'],
  ['maleta-icons icon-ic-disciplina-mec', 'disciplinaMecanica'],
  ['maleta-icons icon-ic-disciplina-foundation', 'disciplinaFundacao'],
  ['maleta-icons icon-ic-disciplina-cold', 'diciplinaAr'],
  ['maleta-icons icon-ic-disciplina-fire', 'disciplinaIncendio'],
  ['maleta-icons icon-ic-disciplina-custom', 'menuContexto'],
  ['menuContexto', 'menuContexto'],
]);

export const iconFileByExtension = (extension: string): IconName => {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'tif':
    case 'bmp':
    case 'svg':
      return 'arquivoImg';
    case 'rvt':
    case 'dwf':
    case 'dwfx':
    case 'rcp':
    case 'nwc':
    case 'nwd':
    case 'ifc':
      return 'arquivo3d';
    case 'dgn':
    case 'dxf':
    case 'dwg':
      return 'arquivo2d';
    case 'xls':
    case 'xlsx':
    case 'gsheet':
      return 'arquivoExcel';
    case 'pdf':
      return 'arquivoPdf';
    case 'txt':
    case 'docx':
    case 'doc':
    case 'odt':
    case 'gdoc':
    case 'gslides':
    case 'pptx':
    case 'ppt':
      return 'arquivoTxt';
    default:
      return 'arquivoTxt';
  }
};

export const iconFileByFilename = (filename: string) => {
  const split = filename.split('.');
  const extension = split[split.length - 1];
  return iconFileByExtension(extension);
};
