import { FC } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "Components/UI";
import {
  BtnContainer,
  Container,
  Content,
  Title,
} from "./styles";

interface IIntegrationDiscipline {
  visible: boolean;
  active?: boolean;
  loading?: boolean;
  isDefault?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const IntegrationDiscipline: FC<IIntegrationDiscipline> = ({
  visible,
  active,
  loading,
  isDefault,
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">
            {`${active ? 'Desativar' : 'Ativar'} validação da vinculação de disciplinas na nomenclatura?`}
          </span>
        </Title>

        <Content>
          <span className="contentText">
            {active
              ? 'Você tem certeza que deseja desativar a validação da vinculação de disciplinas na nomenclatura? Isso fará com que seja possível selecionar qualquer etapa na edição de nomenclatura, não limitando mais apenas às etapas vinculadas à disciplina a qual o arquivo está sendo enviado.'
              : 'Você tem certeza que deseja ativar a validação da vinculação de disciplinas na nomenclatura? Isso fará com que todos os arquivos já enviados e que serão enviados tenham as etapas da nomenclatura correspondente às etapas cadastradas vinculadas à disciplina do arquivo.'
            }
          </span>
          {!active && (
            <span className="warningBox">
              <Icon
                icon="alerta"
                customSize={16}
                className="alertIcon"
              />
              <span className="alertText">
                Essa ação pode ocasionar a invalidação da nomenclatura de arquivos que não tenham o campo de etapa correspondente com o cadastro de etapas vinculadas à disciplina.
              </span>
            </span>
          )}
        </Content>

        <BtnContainer>
          <Button type="text" className="cancelBtn" onClick={onCancel}>
            Cancelar
          </Button>
          {!isDefault && (
            <Button
              type="primary"
              className="deleteBtn"
              onClick={handleConfirm}
              primaryDanger={active}
            >
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      rev=""
                      color="white"
                      style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                    />
                  }
                />
              )}
              {active && (loading ? 'Desativando...' : 'Desativar')}
              {!active && (loading ? 'Ativando...' : 'Ativar')}
            </Button>
          )}
        </BtnContainer>
      </Container>
    </Modal>
  )
}

export default IntegrationDiscipline;
