import { FC } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Icon } from "Components/UI";
import { ButtonIcon, Container } from "./styles";
import ActionAttachmentDropdown from "../../../ActionAttachmentDropdown";

interface IHeaderActionsButtons {
  onPrint: () => void;
  onDownload: () => void;
  onOpen: () => void;
}

const HeaderActionsButtons: FC<IHeaderActionsButtons> = ({
  onPrint,
  onDownload,
  onOpen,
}) => {
  return (
    <Container onClick={() => undefined}>
      <ButtonIcon
        type="button"
        onClick={onDownload}
      >
        <Icon
          icon="download"
          customSize={14}
          className="habIconDownload"
        />
      </ButtonIcon>
      <ButtonIcon
        type="button"
        onClick={onPrint}
      >
        <PrinterOutlined rev="" className="habIconPrinter" />
      </ButtonIcon>
      <ActionAttachmentDropdown
        isPreview
        onOpenPage={onOpen}
      >
        <ButtonIcon type="button">
          <Icon
            icon="moreActions"
            customSize={14}
            className="habIconMoreAction"
          />
        </ButtonIcon>
      </ActionAttachmentDropdown>
    </Container>
  )
}

export default HeaderActionsButtons;
