import { createGlobalStyle } from "styled-components";

export const TabsStyled = createGlobalStyle`
  .ant-tabs-bar {
    margin: 0 !important;
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active {
    color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }

  .ant-tabs-nav-wrap {
    padding-left: 40px;
    padding-right: 14px;
    background-color: ${({theme}) => theme.colors.secondary.secondary};
    height: 44px !important;
  }

  .ant-tabs-tabpane {
    height: 330px;
  }

  .ant-tabs-ink-bar {
    background-color: ${({theme}) => theme.colors.primary.primary} !important;
    height: 4px !important;
  }

  .ant-tabs-tab {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
    user-select: none;
    padding: 12px 6px !important;
    margin-right: 28px !important;
  }

  .ant-tabs-tab-disabled {
    opacity: 0.5 !important;
  }

  .ant-tabs-tab-active {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;

    color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
    opacity: 1 !important; 
  }
`;
