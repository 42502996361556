import { AuthActionUnion, AuthActionKeys } from './Auth.actions';
import { IAuthState, IUserLimitsActive } from './interfaces/IAuthState';
import AppStorage from '../../Utils/AppStorage';
import { ACCESS_TOKEN_KEY, EXPIRES_AT_KEY, ISSUED_DATETIME_KEY, REFRESH_TOKEN_KEY } from '../../Store/Auth/Auth.constants';
import dayjs from 'dayjs';

const token = AppStorage.GetItem(ACCESS_TOKEN_KEY);
const refreshToken = AppStorage.GetItem(REFRESH_TOKEN_KEY);
const expiresAt = AppStorage.GetItem(EXPIRES_AT_KEY);
const issuedDatetime = AppStorage.GetItem(ISSUED_DATETIME_KEY);

const isTokenExpired = () => {
  return (
    !issuedDatetime || 
    !expiresAt || 
    dayjs(issuedDatetime).add(parseInt(expiresAt, 10), 'seconds').isBefore(dayjs())
  );
};

const initialState: IAuthState = {
  accessToken: token || undefined,
  isLogged: !isTokenExpired() || !!refreshToken,
  isLoading: false,
  hasExpired: isTokenExpired(),
  listCompanyPosition: [],
  loadingBillingData: false,
  warningRequestTime: false,
};

const authReducer = (state = initialState, action: AuthActionUnion): IAuthState => {
  switch (action.type) {
    case AuthActionKeys.AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionKeys.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        isLoading: false,
        accessToken: action.payload.access_token,
        message: undefined,
        error: undefined,
      };
    case AuthActionKeys.AUTH_LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        accessToken: undefined,
        isLogged: false,
        error: action.payload,
        message: undefined,
      };

    case AuthActionKeys.LIST_COMPANY_POSITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionKeys.LIST_COMPANY_POSITION_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AuthActionKeys.LIST_COMPANY_POSITION_SUCCESS:
      return {
        ...state,
        listCompanyPosition: action.payload,
        isLoading: false,
      };

    case AuthActionKeys.AUTH_REGISTER_REQUEST:
    case AuthActionKeys.AUTH_REGISTER_GOOGLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionKeys.AUTH_REGISTER_SUCCESS:
    case AuthActionKeys.AUTH_REGISTER_GOOGLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionKeys.AUTH_REGISTER_FAILED:
    case AuthActionKeys.AUTH_REGISTER_GOOGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case AuthActionKeys.AUTH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionKeys.AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionKeys.AUTH_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case AuthActionKeys.AUTH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionKeys.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionKeys.AUTH_RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case AuthActionKeys.GET_USER_INFO_REQUEST:
      return {
        ...state,
        userInfo: undefined,
        isLoading: true,
      };
    case AuthActionKeys.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        planFree: action.payload.UserStoreItem.isFree,
        userLimitsActive: {
          QtyActiveConstructionSites: action.payload.QtyActiveConstructionSites,
          QtyActiveUsers: action.payload.QtyActiveUsers,
          QtyRenderedFiles: action.payload.QtyRenderedFiles,
        },
        isLoading: false,
      };
    case AuthActionKeys.GET_USER_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    
    case AuthActionKeys.UPDATE_BILLING_DATA_REQUEST:
      return {
        ...state,
        loadingBillingData: true,
      };
    case AuthActionKeys.UPDATE_BILLING_DATA_SUCCESS:
      let newUserInfo = state.userInfo;
      if (newUserInfo) {
        newUserInfo.BillingData = action.payload;
      }
      return {
        ...state,
        userInfo: newUserInfo,
        loadingBillingData: false,
      };
    case AuthActionKeys.UPDATE_BILLING_DATA_FAILED:
      return {
        ...state,
        loadingBillingData: false,
      };

    case AuthActionKeys.WARNING_REQUEST_TIME:
      return {
        ...state,
        warningRequestTime: action.payload,
      };

    case AuthActionKeys.AUTH_LOGOUT:
      return {
        ...state,
        isLogged: false,
      };

    case AuthActionKeys.TOOGLE_LIMITS_ACTIVE:
      let newUserLimitsActive: IUserLimitsActive | undefined = state.userLimitsActive;
      if (action.payload.QtyActiveConstructionSites) {
        newUserLimitsActive = {
          ...newUserLimitsActive,
          QtyActiveConstructionSites: (newUserLimitsActive?.QtyActiveConstructionSites ?? 0) + action.payload.QtyActiveConstructionSites,
        };
      }
      if (action.payload.QtyActiveUsers) {
        newUserLimitsActive = {
          ...newUserLimitsActive,
          QtyActiveUsers: (newUserLimitsActive?.QtyActiveUsers ?? 0) + action.payload.QtyActiveUsers,
        };
      }
      if (action.payload.QtyRenderedFiles) {
        newUserLimitsActive = {
          ...newUserLimitsActive,
          QtyRenderedFiles: (newUserLimitsActive?.QtyRenderedFiles ?? 0) + action.payload.QtyRenderedFiles,
        };
      }
      return {
        ...state,
        userLimitsActive: newUserLimitsActive,
      };

    default:
      return state;
  }
};

export default authReducer;
