import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import styles from "./PlanoForm.module.scss";
import schema from "./PlanoForm.schema"
import Modal from "Components/UI/Modal/Modal";
import Form from "Components/UI/Form/Form";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import { AdmActions } from "Store/Adm/Adm.actions";
import { IStorePlanRequest } from "Data/interfaces/Store/IStorePlanRequest";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { Skeleton } from "antd";
import { IStorePlanResponse } from "Data/interfaces/Store/IStorePlanResponse";
import SelectForm, { IOptionData } from "Components/UI/SelectForm";

interface IPlanoForm {
  onClose: () => void;
  closeUpdateModal: () => void;
  planId: number;
  visible?: boolean;
}

const PlanoForm: FC<Props> = ({
  onClose,
  closeUpdateModal,
  isLoading,
  planId,
  visible,
  Plano
}) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [roleCustom, setRoleCustom] = useState(0);
  const [defaultValues, setDefaultValues] = useState({} as IStorePlanResponse);

  useEffect(() => {
    if (planId > 0)
      dispatch(AdmActions.getPlan(planId));
  }, [planId]);

  useEffect(() => {
    let plan: any = {};
    if (Plano) {
      plan = {
        ...Plano,
        AccessViewerTab: Plano.AccessViewerTab ? 1 : 0,
      }
    }
    setDefaultValues(plan ?? {} as IStorePlanResponse);
    setRoleCustom(Plano?.isCustom ?? 0);
  }, [Plano]);

  useEffect(() => {
    if (isSending && !isLoading) {
      setIsSending(false);
      closeUpdateModal();
      onCloseAux();
    }
  }, [isLoading]);

  const submit = (values: IStorePlanRequest) => {
    if (values) {
      setIsSending(true);
      dispatch(AdmActions.createOrUpdatePlan(values));
    }

    setIsSubmit(false);
  }

  const onCloseAux = () => {
    dispatch(AdmActions.getPlanSuccess({} as IStorePlanResponse));
    onClose();
  }

  const onChangeIsCustom = (isCustom: number) => {
    setRoleCustom(isCustom);
    setDefaultValues({
      ...defaultValues,
      MaxQtyConstructionSites: undefined,
      MaxQtyRenderedFiles: undefined,
      MaxQtyUsers: undefined,
      MaxQtyUploadFiles: undefined,
      Price: 0
    })
  }

  const optionsSelect = [
    { value: 1, description: 'Sim' },
    { value: 0, description: 'Não' }
  ] as IOptionData[];

  const buttons = (
    <div>
      <Button
        onClick={onCloseAux}
      >
        <span>Cancelar</span>
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        onClick={() => setIsSubmit(true)}
      >
        <span>Confirmar</span>
      </Button>
    </div>
  );

  return (
    <Modal
      title={planId ? 'Editar Plano' : 'Criar Novo Plano'}
      visible={visible}
      footerButtons={buttons}
      onCancel={onCloseAux}
    >
      <div
        className={styles['form-wrapper']}
        key={`status-${Plano?.Name}`}
      >
        {(planId > 0 && !Plano?.StorePlansId) || isLoading ?
          <Skeleton active></Skeleton>
          :
          <>
            <Form
              schema={schema}
              onSubmit={submit}
              isSubmited={isSubmit}
              resetForm={true}
              initialValues={defaultValues}
            >
              <Field
                label="Nome Plano"
                name="Name"
              />

              <div className={styles['field-numbers-wrapper']}>
                <label>
                  <small>Ativo</small>
                  <SelectForm
                    className={styles['selectRole']}
                    placeholder="Ativo"
                    options={optionsSelect}
                    name='isActive'
                    style={{ width: 160, paddingRight: 5 }}
                  />
                </label>
                <label>
                  <small>Popular</small>
                  <SelectForm
                    className={styles['selectRole']}
                    placeholder="Popular"
                    options={optionsSelect}
                    name='IsDefault'
                    style={{ width: 160, paddingRight: 5 }}
                  />
                </label>
                <label>
                  <small>Personalizado</small>
                  <SelectForm
                    onSelect={(value: any) => onChangeIsCustom(value)}
                    className={styles['selectRole']}
                    placeholder="Personalizado"
                    options={optionsSelect}
                    name='isCustom'
                    style={{ width: 160 }}
                  />
                </label>
              </div>

              <div className={styles['field-numbers-wrapper']}>
                <Field
                  label="Max Obras"
                  name="MaxQtyConstructionSites"
                  type="number"
                  disabled={roleCustom == 1}
                />
                <Field
                  label="Max Usuários"
                  name="MaxQtyUsers"
                  type="number"
                  className={styles['field-numbers']}
                  disabled={roleCustom == 1}
                />
                <Field
                  label="Max Renderizações"
                  name="MaxQtyRenderedFiles"
                  type="number"
                  className={styles['field-numbers']}
                  disabled={roleCustom == 1}
                />
              </div>

              <div className={styles['field-numbers-wrapper']}>
                <Field
                  label="Max Upload"
                  name="MaxQtyUploadFiles"
                  type="number"
                  className={styles['field-numbers-footer']}
                  disabled={roleCustom == 1}
                />
                <span style={{ width: 196, paddingLeft: 5, marginTop: '-35.5px' }}>
                  <label>
                    <small>Acesso Aba Viewer</small>
                    <SelectForm
                      className={styles['selectRole']}
                      placeholder="Acesso Aba Viewer"
                      options={optionsSelect}
                      name="AccessViewerTab"
                    />
                  </label>
                </span>
              </div>

              <div className={styles['field-numbers-wrapper']}>
                <Field
                  label="Preço"
                  name="Price"
                  type="number"
                  className={styles['field-numbers-footer']}
                  disabled={roleCustom == 1}
                />
                <Field
                  label="Ordem"
                  name="Order"
                  type="number"
                  className={styles['field-numbers-footer']}
                />
              </div>

              <div className={styles['text-area']}>
                <Field
                  label="Descrição"
                  name="Description"
                  typeInput="textarea"
                />
                <Field
                  label="Recursos do Plano"
                  name="Info"
                  typeInput="textarea"
                />
              </div>
            </Form>
          </>
        }
      </div>
    </Modal>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPlanoForm;

export default connector(PlanoForm);