import styled from "styled-components";

interface IWrapper {
  isCollapsed: boolean;
  maxHeightCalc: number;
}

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 96px);

  .inputTopBar{
    min-width: 260px;
  }
`;

export const HeaderBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 43px;
  margin-bottom: 12px;
`;

export const HeaderWrapper = styled.div`
  margin: 0 33px;
  margin-bottom: 12px;
`;

export const MainWrapper = styled.div<IWrapper>`
  position: relative;
  margin: 0 33px;
  max-width: ${({isCollapsed}) => isCollapsed 
    ? 'calc(100vw - 76px)' 
    : 'calc(100vw - 251px)'};
  max-height: calc(${({maxHeightCalc}) => maxHeightCalc}px - 234px);
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
`;
