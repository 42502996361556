import React, { FC } from 'react';

import styles from './AvisoArquivarObra.module.scss';
import Button from '../../../../Components/UI/Button';
import Modal from '../../../../Components/UI/Modal/Modal';

interface IAvisoArquivarObra {
  visible?: boolean;
  nomeObra?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const AvisoArquivarObra: FC<IAvisoArquivarObra> = ({ visible, nomeObra, onCancel, onConfirm }) => {
  const buttons = (
    <div>
      <span className={styles['link']} onClick={onConfirm}>
        Estou ciente e desejo arquivar
      </span>
      <Button className={styles['button']}  type='primary' height={37} onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );

  return (
    <Modal
      title='Atenção'
      footerButtons={buttons}
      visible={visible}
      width={487}
      onCancel={onCancel}>
      <div className={styles['descriptionWrapper']}>
        <div>
          Arquivando a Obra "{nomeObra}" você impossibilita aos
          colaboradores realizarem alterações nos seus arquivos.
        </div>

        <div>Deseja arquivá-la mesmo assim?</div>
      </div>
    </Modal>
  );
};

export default AvisoArquivarObra;
