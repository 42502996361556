import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IUserInfoResponse, IUpdateUserToPremium } from '../../Data/interfaces/MinhaConta/IUserInfoResponse';
import { INovaSenhaRequest } from '../../Data/interfaces/MinhaConta/INovaSenhaRequest';
import { IUpdateUserPersonalDataRequest } from '../../Data/interfaces/MinhaConta/IUpdateUserPersonalDataRequest';
import { IUpdateUserCompanyDataRequest } from '../../Data/interfaces/MinhaConta/IUpdateUserCompanyDataRequest';

export enum MinhaContaActionKeys {
    SET_SHOW_MODAL_UPDATE_CONTA = 'SET_SHOW_MODAL_UPDATE_CONTA',
    SET_SHOW_MODAL_UPDATE_PASSWORD = 'SET_SHOW_MODAL_UPDATE_PASSWORD',
    SET_SHOW_MODAL_UPDATE_EMPRESA = 'SET_SHOW_MODAL_UPDATE_EMPRESA',
    SET_SHOW_MODAL_UPDATE_PREMIUM = 'SET_SHOW_MODAL_UPDATE_PREMIUM',
    SET_SHOW_MODAL_CONFIRM_PREMIUM = 'SET_SHOW_MODAL_CONFIRM_PREMIUM',
    NOTIFY_USER_CONVERSION = 'NOTIFY_USER_CONVERSION',

    GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',

    GET_DADOS_EMPRESA_REQUEST = 'GET_USER_INFO_REQUEST',
    GET_DADOS_EMPRESA_SUCCESS = 'GET_USER_INFO_SUCCESS',
    GET_DADOS_EMPRESA_FAILED = 'GET_USER_INFO_FAILED',

    NOVA_SENHA_REQUEST = 'NOVA_SENHA_REQUEST',
    NOVA_SENHA_SUCCESS = 'NOVA_SENHA_SUCCESS',
    NOVA_SENHA_FAILED = 'NOVA_SENHA_FAILED',

    UPDATE_DADOS_PESSOAIS_REQUEST = 'UPDATE_DADOS_PESSOAIS_REQUEST',
    UPDATE_DADOS_PESSOAIS_SUCCESS = 'UPDATE_DADOS_PESSOAIS_SUCCESS',
    UPDATE_DADOS_PESSOAIS_FAILED = 'UPDATE_DADOS_PESSOAIS_FAILED',

    UPDATE_DADOS_EMPRESA_REQUEST = 'UPDATE_DADOS_EMPRESA_REQUEST',
    UPDATE_DADOS_EMPRESA_SUCCESS = 'UPDATE_DADOS_EMPRESA_SUCCESS',
    UPDATE_DADOS_EMPRESA_FAILED = 'UPDATE_DADOS_EMPRESA_FAILED'
}

export const MinhaContaActions = {
    setShowModalUpdateConta: (visible: boolean): SetShowModalUpdateContaAction =>
        createAction(MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_CONTA, visible),

    setShowModalUpdatePassword: (visible: boolean): SetShowModalUpdatePasswordAction =>
        createAction(MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PASSWORD, visible),

    setShowModalUpdateEmpresa: (visible: boolean): SetShowModalUpdateEmpresaAction =>
        createAction(MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_EMPRESA, visible),

    setShowModalUpdatePremium: (visible: boolean): setShowModalUpdatePremiumAction =>
        createAction(MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PREMIUM, visible),

    setShowModalConfirmPremium: (visible: boolean): setShowModalConfirmPremiumAction =>
        createAction(MinhaContaActionKeys.SET_SHOW_MODAL_CONFIRM_PREMIUM, visible),

    notifyUserConversion: (userToPremium: IUpdateUserToPremium): NotifyUserConversionAction =>
        createAction(MinhaContaActionKeys.NOTIFY_USER_CONVERSION, userToPremium),

    getUserInfo: (): GetUserInfoAction =>
        createAction(MinhaContaActionKeys.GET_USER_INFO_REQUEST),
    getUserInfoSuccess: (userInfo: IUserInfoResponse): GetUserInfoSuccessAction =>
        createAction(MinhaContaActionKeys.GET_USER_INFO_SUCCESS, userInfo),
    getUserInfoFailure: (err: string): GetUserInfoFailureAction =>
        createAction(MinhaContaActionKeys.GET_USER_INFO_FAILED, err),

    novaSenha: (request: INovaSenhaRequest): NovaSenhaAction =>
        createAction(MinhaContaActionKeys.NOVA_SENHA_REQUEST, request),
    novaSenhaSuccess: (response: INovaSenhaRequest): NovaSenhaSuccessAction =>
        createAction(MinhaContaActionKeys.NOVA_SENHA_SUCCESS, response),
    novaSenhaFailure: (err: string): NovaSenhaFailureAction =>
        createAction(MinhaContaActionKeys.NOVA_SENHA_FAILED, err),

    updateDadosPessoais: (request: IUpdateUserPersonalDataRequest): UpdateDadosPessoaisAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_REQUEST, request),
    updateDadosPessoaisSuccess: (response: IUserInfoResponse): UpdateDadosPessoaisSuccessAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_SUCCESS, response),
    updateDadosPessoaisFailure: (err: string): UpdateDadosPessoaisFailureAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_FAILED, err),

    updateDadosEmpresa: (request: IUpdateUserCompanyDataRequest): UpdateDadosEmpresaAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_REQUEST, request),
    updateDadosEmpresaSuccess: (response: IUserInfoResponse): UpdateDadosEmpresaSuccessAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_SUCCESS, response),
    updateDadosEmpresaFailure: (err: string): UpdateDadosEmpresaFailureAction =>
        createAction(MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_FAILED, err),
 
}

export type MinhaContaActionUnion = ActionsUnion<typeof MinhaContaActions>;

export type SetShowModalUpdateContaAction = Action<MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_CONTA, boolean>;

export type SetShowModalUpdatePasswordAction = Action<MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PASSWORD, boolean>;

export type SetShowModalUpdateEmpresaAction = Action<MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_EMPRESA, boolean>;

export type setShowModalUpdatePremiumAction = Action<MinhaContaActionKeys.SET_SHOW_MODAL_UPDATE_PREMIUM, boolean>;

export type setShowModalConfirmPremiumAction = Action<MinhaContaActionKeys.SET_SHOW_MODAL_CONFIRM_PREMIUM, boolean>;

export type NotifyUserConversionAction = Action<MinhaContaActionKeys.NOTIFY_USER_CONVERSION, IUpdateUserToPremium>;

export type GetUserInfoAction = Action<MinhaContaActionKeys.GET_USER_INFO_REQUEST>;
export type GetUserInfoSuccessAction = Action<MinhaContaActionKeys.GET_USER_INFO_SUCCESS, IUserInfoResponse>;
export type GetUserInfoFailureAction = Action<MinhaContaActionKeys.GET_USER_INFO_FAILED, string>;

export type NovaSenhaAction = Action<MinhaContaActionKeys.NOVA_SENHA_REQUEST, INovaSenhaRequest>;
export type NovaSenhaSuccessAction = Action<MinhaContaActionKeys.NOVA_SENHA_SUCCESS, INovaSenhaRequest>;
export type NovaSenhaFailureAction = Action<MinhaContaActionKeys.NOVA_SENHA_FAILED, string>;

export type UpdateDadosPessoaisAction = Action<MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_REQUEST, IUpdateUserPersonalDataRequest>;
export type UpdateDadosPessoaisSuccessAction = Action<MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_SUCCESS, IUserInfoResponse>;
export type UpdateDadosPessoaisFailureAction = Action<MinhaContaActionKeys.UPDATE_DADOS_PESSOAIS_FAILED, string>;

export type UpdateDadosEmpresaAction = Action<MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_REQUEST, IUpdateUserCompanyDataRequest>;
export type UpdateDadosEmpresaSuccessAction = Action<MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_SUCCESS, IUserInfoResponse>;
export type UpdateDadosEmpresaFailureAction = Action<MinhaContaActionKeys.UPDATE_DADOS_EMPRESA_FAILED, string>;