import React, { FC } from "react";
import { Modal } from "antd";
import { useFeatureFlag } from "Hooks/useFeatureFlag";

const {height} = window.screen

interface IVideoEx {
  visible: boolean;
  onCancel: () => void;
}

const VideoEx: FC<IVideoEx> = ({
  visible,
  onCancel,
}) => {
  const demoVideoViewerFlag = useFeatureFlag('demo-video-viewer');
  const demoVideoViewer = (demoVideoViewerFlag.enabled && typeof demoVideoViewerFlag.value === 'string') 
    ? demoVideoViewerFlag.value 
    : (process.env.REACT_APP_DEMO_VIDEO_VIEWER || '');

  const top = Number((height * 0.1).toFixed(0));
  
  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={onCancel}
      width={843}
      closable={false}
    >
      <iframe 
        title="Video tutorial do Viewer Federado"
        width="845px" 
        height="475px"
        src={demoVideoViewer}
        style={{
          position: 'absolute',
          top,
        }}
      />
    </Modal>
  )
}

export default VideoEx;
