import React, { FC } from 'react';

import styles from './BreadCrumb.module.scss';
import { Breadcrumb } from 'antd';
import { Icon, IconName } from '../Icon';
import useHistoryPush from 'Hooks/useHistoryPush';
import LoadingMain from '../CustomLoading/LoadingMain';

export interface IBreadcrumb {
  breadcrumbs: IBreadcrumbItem[];
  custom?: boolean;
}

export interface IBreadcrumbItem {
  description?: string;
  icon?: IconName;
  href: string;
  isLoading?: boolean;
}

const BreadCrumb: FC<IBreadcrumb> = ({ breadcrumbs, custom }) => {
  const { historyPush } = useHistoryPush();

  return (
    <Breadcrumb
      className={`${styles['breadcrumb']} ${custom ? styles['custom'] : ''}`}
      separator={<Icon customSize={7}
        style={{ display: 'inline', color: '#8697A8' }} icon='avancar' />
      }
    >
      <Breadcrumb.Item onClick={() => historyPush('/')} >
        <Icon customSize={10} style={{ display: 'inline' }} icon='menuHome' />
      </Breadcrumb.Item>
      {
        breadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb.Item onClick={() => historyPush(breadcrumb.href)} key={`breadCrumb${index}`}>
            {breadcrumb.isLoading ? (
              <LoadingMain width='52px' height='10px' borderRadius='2px' />
            ) : (
              <>
                {breadcrumb.icon ? <Icon customSize={10} style={{ display: 'inline' }} icon={breadcrumb.icon} /> : ''}
                {breadcrumb.description ? <span>{breadcrumb.description}</span> : ''}
              </>
            )}
          </Breadcrumb.Item>
        ))
      }
    </Breadcrumb>
  );
};

export default BreadCrumb;
