import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};
  overflow: hidden;
`;

interface IImageStyled {
  scale: number;
  isdragging?: string;
}

export const ImageStyled = styled.img<IImageStyled>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scale: ${({ scale }) => scale};
  z-index: 1;
  cursor: ${({isdragging}) => isdragging === 'true' ? 'grabbing' : 'grab'};
`;

export const Top = styled.div`
  position: absolute;
  width: 92px;
  height: 48px;
  top: 12px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin: auto;
  z-index: 2;

  @media print {
    display: none;
  }
`;

export const Footer = styled.div`
  position: absolute;
  width: 136px;
  height: 48px;
  bottom: 24px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin: auto;
  z-index: 2;

  @media print {
    display: none;
  }
`;

interface IButton {
  nohover?: string;
}

export const Button = styled.span<IButton>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  .less {
    font-size: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    padding-bottom: 4px;
  }
  
  .more {
    font-size: 22px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .iconColor {
    color: ${({ theme }) => theme.colors.surface.onSurface} !important;
  }

  ${({ nohover }) => nohover !== 'true' && css`
    cursor: pointer;
    
    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }
  `}
`;
