import { useEffect, useMemo, useRef, useState } from "react";
import { useSortable, defaultNewIndexGetter } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { UniqueIdentifier } from "@dnd-kit/core";
import Item from "../Item";
import { ITopicDnDResponse, ITopicStatus } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import AccessPermission from "Utils/AcessPermission";
import { useSelector } from "react-redux";
import { getUserInfo } from "Store/Auth/Auth.selector";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

interface ISortableItem {
  column?: ITopicStatus;
  activity: ITopicDnDResponse;
  activeId: UniqueIdentifier | null;
  onOpenShowActivity: (activity: string) => void;
  searchActivity: string;
  noPermission?: boolean;
}

const SortableItem = ({
  activity,
  column,
  activeId,
  onOpenShowActivity,
  searchActivity,
}: ISortableItem) => {
  const userInfo = useSelector(getUserInfo);
  const theme = useSelector(getTheme);

  const [showPreview, setShowPreview] = useState(false);
  const [letTimeout, setLetTimeout] = useState<NodeJS.Timeout | null>(null);

  const isDisabled = useMemo(() => {
    return !AccessPermission.statusActivitieEditPermission(
      userInfo,
      activity.CreationAuthor,
      activity.TopicAssignedUsers,
      activity.Visibility
    ) || showPreview;
  }, [
    activity.CreationAuthor,
    activity.TopicAssignedUsers,
    activity.Visibility,
    userInfo,
    showPreview,
  ]);
  const haveVisualizationPermission = useMemo(() => {
    return AccessPermission.visualizationActivitiePermission(
      userInfo,
      activity.CreationAuthor,
      activity.TopicAssignedUsers,
      activity.Visibility
    );
  }, [
    activity.CreationAuthor,
    activity.TopicAssignedUsers,
    activity.Visibility,
    userInfo,
  ]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: activity.Guid,
    getNewIndex: defaultNewIndexGetter,
    data: {
      startColumn: column,
    },
    disabled: isDisabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const currentDrag = useRef(isDragging);
  const previewImg = useRef(false);

  const handleMouseDown = () => {
    setLetTimeout(
      setTimeout(() => {
        if (
          !currentDrag.current &&
          !previewImg.current &&
          haveVisualizationPermission
        ) {
          onOpenShowActivity(activity.Guid);
        }
      }, 120)
    );
  };

  const previewForShow = () => {
    setShowPreview(true);
    if (letTimeout) {
      clearTimeout(letTimeout);
    }
  };

  const handlePreviewVisible = () => {
    setShowPreview(false);
    if (letTimeout) {
      clearTimeout(letTimeout);
    }
  };

  useEffect(() => {
    currentDrag.current = isDragging;
  }, [isDragging]);

  useEffect(() => {
    previewImg.current = showPreview;
  }, [showPreview]);

  useEffect(() => {
    return () => {
      if (letTimeout) {
        clearTimeout(letTimeout);
      }
    }
  }, [letTimeout]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseDown={handleMouseDown}
    >
      <Item
        activeId={activeId}
        activity={activity}
        column={column}
        searchActivity={searchActivity}
        disabled={isDisabled}
        overlay
        theme={theme}
        showPreview={showPreview}
        previewForShow={previewForShow}
        onPreviewVisible={handlePreviewVisible}
      />
    </div>
  );
}

export default SortableItem;
