import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationActions } from 'Store/Notification/Notification.actions';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import { Mixpanel } from 'Utils/MixPanel';
import { NotificationActionEnum } from 'Data/enums/Notification';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface INotificationSignalR {
  userInfo?: IUserInforResponse;
}

const NotificationSignalR = ({ userInfo }: INotificationSignalR) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const constructionsIds = useSelector(
    (state: IGlobalReducerState) => state.obra.constructionSiteIds
  );

  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    if (constructionsIds.length > 0) return;

    dispatch(ObraActions.listAllConstructionsSitesOfUser());
  }, []);

  useEffect(() => {

    if (connection) {
      connection.stop();
    }

    if (constructionsIds.length > 0) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_API_URL?.toString().replace("/api", "")}/hubs/notifications?csIds=[${constructionsIds}]`
        )
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      newConnection.serverTimeoutInMilliseconds = 100000;
      setConnection(newConnection);
    }
  }, [constructionsIds]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('newNotification', (message) => {
            const data: INotificationData = JSON.parse(message);

            if (
              !data.CheckUsersIdToNotify ||
              (data.CheckUsersIdToNotify && userInfo?.Id &&
                data.UsersIdToNotify.includes(userInfo.Id))
            ) {
              const notificationMap = {
                NotificationId: data.NotificationId,
                ConstructionSiteFk: data.ConstructionSiteFk,
                ItemId: data.ItemId,
                ItemsAux: data.ItemsAux,
                ItemParentId: data.ItemParentId,
                Action: data.Action,
                hasRead: false,
                UserFk: data.UserFk,
              } as INotificationsMapAux;

              dispatch(NotificationActions.listNewUpdateNotification({ newNotification: data }));
              dispatch(NotificationActions.updateNotificationMap(notificationMap));

              Mixpanel.track({
                name: 'NEW_NOTIFICATION',
                props: {
                  constructionSiteId: data.ConstructionSiteFk,
                  folderId: data.ItemParentId,
                  notificationId: data.NotificationId,
                  actionId: data.Action,
                  actionIdDescription: NotificationActionEnum[data.Action],
                  actionName: data.ActionName,
                },
                userInfo: userInfo,
                currentListTenant: currentTenant,
              });
            }
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return <></>;
};

export default memo(NotificationSignalR);
