import styled, { createGlobalStyle, css } from "styled-components";
import { Dropdown } from "antd";

interface IMenuDropdown {
  isactive?: string;
}

export const MenuDropdown = styled(Dropdown) <IMenuDropdown>`
  
  ${({ isactive }) => isactive === 'true' && css`
    display: flex !important;
  `}
`;

interface IMenuItemStyled {
  isdisabled?: string;
}

export const MenuItemStyled = styled.div<IMenuItemStyled>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .iconMenuItem {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  .textItem {
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  &:hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &:active {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
  }
    
  ${({ isdisabled }) => isdisabled === 'true' && css`
    cursor: not-allowed;

    .iconMenuItem {
      color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
    }

    .textItem {
      color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder};
    }
  `}
`

export const ActionAttachmentMenuGlobalStyles = createGlobalStyle`
  .rowMenu {

    &:hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    }

    &:active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`} !important;
    }
  }

  .ant-dropdown {
    z-index: 1090;
  }
`;
