import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";

const getHistoryDrawerVisible = (state: IGlobalReducerState) => state.history.historyDrawerVisible;
const getCurrentHistoryFileList = (state: IGlobalReducerState) => state.history.currentHistoryFileList;
const getCurrentHistoryActivityList = (state: IGlobalReducerState) => state.history.currentHistoryActivityList;
const getHistoryIsLoading = (state: IGlobalReducerState) => state.history.isLoading;

export { 
  getHistoryDrawerVisible,
  getCurrentHistoryFileList,
  getCurrentHistoryActivityList,
  getHistoryIsLoading,
};
