import { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { ButtonsWrapper, Wrapper } from "./styles";

interface IConfirmDeleteModal {
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDeleteModal: FC<IConfirmDeleteModal> = ({
  visible,
  loading,
  onCancel,
  onConfirm,
}) => {
  const footerConfirmButtons = (
    <ButtonsWrapper>
      <Button
        className="cancel"
        loading={loading}
        disabled={loading}
        onClick={onConfirm}
      >
        <span className="cancelText">Estou ciente e desejo excluir</span>
      </Button>
      <Button
        type="primary"
        className="confirm"
        onClick={onCancel}
      >
      <span className="confirmText">Cancelar</span>
      </Button>
    </ButtonsWrapper>
  );

  return (
    <Modal
      title="Atenção!"
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      width={487}
    >
      <Wrapper>
        <span className="description">
          Você tem certeza que deseja excluir <b>todos os dados</b>? Isso fará com que todos os dados de plotagens sejam <b>excluídos</b> e todas as atividades vinculadas à disciplina percam o vínculo.
        </span>
      </Wrapper>
    </Modal>
  )
}

export default ConfirmDeleteModal;
