import * as Yup from 'yup';

const schema = Yup.object().shape({
  Name: Yup.string().required('O campo deve ser preenchido'),
  Price: Yup.number().required('O campo deve ser preenchido').nullable(),
  Description: Yup.string().required('O campo deve ser preenchido'),
  Order: Yup.number().required('O campo deve ser preenchido'),
  Info: Yup.string().required('O campo deve ser preenchido'),
})

export default schema;