import { FC, ReactNode, useCallback } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

export interface IDataFields {
  id: string;
}

interface ISimpleDnDListContext<T> {
  data: IDataFields[];
  dragOverlay: ReactNode | null;
  onActiveId: (id: UniqueIdentifier | null) => void;
  onSetNewData: (start: T, end: T, data: T[]) => void;
}

export const SimpleDnDListContext: FC<ISimpleDnDListContext<any>> = ({
  children,
  data,
  dragOverlay,
  onActiveId,
  onSetNewData,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;

    return onActiveId(active.id);
  }, [onActiveId]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = data.findIndex(field => field.id === active.id);
      const overIndex = data.findIndex(field => field.id === over.id);

      const start = data[activeIndex];
      const end = data[overIndex];
      const newFields = arrayMove(data, activeIndex, overIndex);

      onSetNewData(start, end, newFields);
    }

    return onActiveId(null);
  }, [data, onActiveId, onSetNewData]);

  const handleDragCancel = useCallback(() => {
    return onActiveId(null);
  }, [onActiveId]);

  return (
    <DndContext
      key="SimpleDnDListContextKey"
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={(event) => handleDragStart(event)}
      onDragEnd={(event) => handleDragEnd(event)}
      onDragCancel={() => handleDragCancel()}
    >
      <SortableContext items={data}>
        {children}
      </SortableContext>
      <DragOverlay>
        {dragOverlay}
      </DragOverlay>
    </DndContext>
  );

}

export default SimpleDnDListContext;
