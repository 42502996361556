import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { Space } from "antd";
import { Color } from "antd/es/color-picker";
import { CustomColorPicker, CustomPanel } from "./styles";
import { Icon } from "../Icon";

interface IColorPicker {
  newColor: string;
  onChange: (value: string) => void;
};

const ColorPicker: FC<IColorPicker> = ({
  newColor,
  onChange,
}) => {
  const theme = useSelector(getTheme);
  const [open, setOpen] = useState(false);

  const colors = useMemo(() => {
    return [
      '#3547C8',
      '#ED5564',
      '#D74655',
      '#C1392B',
      '#FB6E52',
      '#F39C11',
      '#FFCE55',
      '#48CFAE',
      '#36BC9B',
      '#A0D468',
      '#5B9519',
      '#5D9CEC',
      '#166BDD',
      '#297FB8',
      '#3BAEDA',
      '#EC87BF',
      '#D4499B',
      '#AC92ED',
      '#967BDC',
      '#8D44AD',
      '#BD00FF',
      '#3547C8',
      '#0081FF',
      '#324150',
      '#8697A8',
      '#BEC2C6',
      '#EAEDF0',
    ];
  }, []);

  const handleChange = (value: Color) => {
    onChange(value.toHexString().toUpperCase());
  };

  return (
    <Space>
      <CustomColorPicker
        open={open}
        onOpenChange={setOpen}
        defaultValue={theme.colors.primary.primary}
        value={newColor}
        showText={(color) => (
          <div className="colorPicker">
            <span className="hex">{color.toHexString().toUpperCase()}</span>
            <Icon
              icon="expandir"
              customSize={14}
              customColor={theme.colors.surface.onSurfaceVariant}
            />
          </div>
        )}
        onChange={handleChange}
        styles={{
          popupOverlayInner: {
            width: '282px',
            height: 'auto ',
          }
        }}
        panelRender={(panel, {components: {Picker, Presets}}) => {
          return (
            <CustomPanel style={{width:'258px'}}>
              <Picker />
              <div className="divider" />
              <Presets />
            </CustomPanel>
          )
        }}
        presets={[
          {
            label: 'Recommended',
            colors,
          }
        ]}
      />
    </Space>
  )
}

export default ColorPicker;
