import React, { FC } from 'react';
import Button from '../../../../Components/UI/Button';
import PlanoAvisoObraUpgrade from '../../../../Static/images/plano-aviso-obra-upgrade.png';
import { Icon } from '../../../../Components/UI';
import Modal from 'Components/UI/Modal/Modal';
import styles from './AvisoUploadUpgrade.module.scss';

interface IAvisoObraUpgrade {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  order?: number;
  role?: number;
  planFree?: boolean;
  limitUpload?: number;
}

const AvisoUploadUpgrade: FC<IAvisoObraUpgrade> = ({ 
  visible, 
  onCancel, 
  onConfirm, 
  role, 
  planFree, 
  limitUpload,
}) => {
  
  return (
    <Modal
      footerButtons={false}
      visible={visible}
      width={'auto'}
      onCancel={onCancel}
    >
      <div className={styles['wrapper']}>
        <div className={styles['imageWrapper']}>
          <img src={PlanoAvisoObraUpgrade}></img>
        </div>
        <div className={`${styles['contentWrapper']} ${!planFree ? styles['contentWrapperPro'] : ''}`}>
          <div>
            <label className={styles['avisoLimiteObras']}>
              {`Atenção: você não pode enviar mais de ${limitUpload ? limitUpload : 10} arquivos simultaneamente!`}
            </label>
          </div>
          <h2>
            {role === 1 
              ? 'Dê um upgrade na sua conta gratuita'
              : 'Dê um upgrade na conta da sua empresa'
            }
          </h2>
          <h3>Para uploads ilimitados</h3>
          {planFree && <>  
            <p>O plano Premium ainda proporciona:</p>
            <div className={styles['icons-wrapper']}>
              <div className={`${styles['icons-text-wrapper']} ${styles['icons-text-margin']}`}>
                <div>
                  <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                  <span>Gestão de documentos 2D e 3D</span>
                </div>
                <div>
                  <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                  <span>Controle de nomeclatura</span>
                </div>
              </div>
              <div className={styles['icons-text-wrapper']}>
                <div>
                  <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                  <span>Gestão de usuários e papéis na obra</span>
                </div>
                <div>
                  <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                  <span>Controle de plotagem</span>
                </div>
              </div>
              <div style={{ marginBottom: 14 }}>&nbsp;</div>
            </div>
          </>}
          
          <div className={styles['button-wrapper']}>
            <Button className={styles['button-cancel']} type='link' width={190} height={48} onClick={onCancel}>
              Talvez mais tarde
            </Button>

            <Button 
              className={styles['button-confirm']} 
              type='primary' 
              ispremium="true"
              width={287} 
              height={48} 
              onClick={() => onConfirm()}
            >
              <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
              {role === 1 
                  ? 'Adquirir o plano Premium'
                  : 'Solicitar o plano Premium'
              }
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvisoUploadUpgrade;
