import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { GetAvatar } from 'Utils/generateThumbnail';
import { Icon } from '../Icon';
import { Spin } from "antd";
import { history } from 'Store';
import { Dispatch } from 'redux';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import LoadingMain from '../CustomLoading/LoadingMain';
import { LoadingOutlined } from '@ant-design/icons';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import Ellipsis from '../Ellipsis';
import PanelsDropdown from './components/PanelsDropdown';
import { PanelsActions } from 'Store/Panels/Panels.actions';
import PanelsEditModal from './components/PanelsEditModal';
import {
  coordlyOption,
  panelsOptions,
} from './components/PanelsEditModal/optionsObj';
import { INewPanelsOptions } from 'Data/interfaces/Panels/IPanelComponentsModels';
import { IPanelsResponse } from 'Data/interfaces/Panels/IPanelsResponse';
import ConfirmDeletePanels from './components/ConfirmDeletePanels';
import { getPanelsDeleteLoading } from 'Store/Panels/Panels.selector';
import { DividerMain, TabsTitle, TabsWrapper } from './styles';

interface ITabsShell {
  ConstructionSiteId: any;
  active: PathActiveEnum;
  loading?: boolean;
  currentPeperUser?: number;
  loadingObras?: boolean;
  match?: any;
  panelName?: string;
  dispatch: Dispatch;
}

export type TypePathRedirect = 'detail'
  | 'plotagens'
  | 'activities'
  | 'viewer'
  | 'panels';

export enum PathActiveEnum {
  detail = 1,
  plotagens = 2,
  activities = 3,
  viewer = 4,
  panels = 5,
  edit = 6,
};

const TabsShell: FC<Props> = ({
  ConstructionSiteId,
  ObrasList,
  active,
  loading,
  Users,
  isLoadingGetUsersConstruction,
  currentPeperUser,
  isCollapsed,
  archivesPath,
  plotagensPath,
  fixedPanels,
  notFixedPanels,
  match,
  panelName,
  dispatch,
}) => {
  const theme = useSelector(getTheme);
  const panelsDeleteLoading = useSelector(getPanelsDeleteLoading);

  const currentCsId = useRef(-1);
  const [showEditPanelsModal, setShowEditPanelsModal] = useState(false);
  const [panelForEdit, setPanelForEdit] = useState<IPanelsResponse | null>(null);
  const [activePanelsModal, setActivePanelsModal] = useState<INewPanelsOptions>(
    panelsOptions[0]
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [panelForDelete, setPanelForDelete] = useState<IPanelsResponse | null>(null);
  const [sentDeleteData, setSentDeleteData] = useState(false);

  const currentObra = useMemo(() => {
    return ObrasList?.find(obra => obra.ConstructionSiteId === Number(ConstructionSiteId));
  }, [ObrasList, ConstructionSiteId,]);

  const antIcon = <LoadingOutlined rev="" style={{ color: theme.colors.primary.primary, fontSize: 14 }} spin />;

  const isCustom = () => {
    if (currentObra?.OriginalImageUrl || currentObra?.ThumbnailImageUrl) {
      return true;
    }
    return false;
  }

  const handleRedirect = (path: string, init: TypePathRedirect) => {
    if (archivesPath && (archivesPath.params.constructionSiteId === ConstructionSiteId) && (archivesPath.url !== match.url) && init === 'detail') {
      return history.push(archivesPath.url);
    }
    if (plotagensPath && (plotagensPath.params.constructionSiteId === ConstructionSiteId) && (plotagensPath.url !== match.url) && init === 'plotagens') {
      return history.push(plotagensPath.url);
    }
    if (init === 'activities') {
      return history.push(path);
    }
    if (init === 'viewer') {
      return history.push(path);
    }
    return history.push(path);
  }

  const openEdicaoObra = (constructionSiteId: number) => {
    const request: IUpdateDisciplineStatusRequest = {
      csId: constructionSiteId
    }
    dispatch(ObraActions.getObra(request));
    dispatch(ObraActions.getUsersConstructionSite(constructionSiteId));
    dispatch(TenantActions.getUsers());
    history.push(`/obras/edit/${constructionSiteId}/details`)
  };

  const showEditPanels = (panel?: IPanelsResponse) => {
    if (panel) {
      setPanelForEdit(panel);

      const option = coordlyOption.id === panel.Type
        ? coordlyOption
        : panelsOptions.find(opt => opt.id === panel.Type);
      if (option) {
        setActivePanelsModal(option);
      }

    } else {
      setPanelForEdit(null);
    }

    setShowEditPanelsModal(true);
  };

  const hideEditPanels = () => {
    setShowEditPanelsModal(false);
    setPanelForEdit(null);
  };

  const handleOpenDeletePanel = (panel: IPanelsResponse) => {
    setPanelForDelete(panel);
    setOpenDeleteModal(true);
  };

  const handleConfirmDeletePanel = (panel: IPanelsResponse) => {
    dispatch(PanelsActions.deletePanel(panel.PanelId));
    setSentDeleteData(true);
  };

  const handleCloseDeletePanel = () => {
    setPanelForDelete(null);
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (sentDeleteData && !panelsDeleteLoading) {
      setSentDeleteData(false);
      handleCloseDeletePanel();
    }
  }, [panelsDeleteLoading, sentDeleteData]);

  useEffect(() => {
    if (!isLoadingGetUsersConstruction) {
      dispatch(ObraActions.usersForLimitInvited(Users?.InvitedUsers))
    }
  }, [isLoadingGetUsersConstruction]);

  useEffect(() => {
    if (ConstructionSiteId !== currentCsId.current) {
      dispatch(PanelsActions.list(ConstructionSiteId));
      currentCsId.current = ConstructionSiteId;
    }
  }, [ConstructionSiteId]);

  return (
    <>
      <TabsWrapper isCollapsed={`${isCollapsed}`}>
        <div className="tabsLeft">
          <div className="tabsObra">
            <span className="avatar">
              {!currentObra ? (
                <LoadingMain width='24px' height='24px' borderRadius='50%' />
              ) : (
                <GetAvatar
                  key={currentObra?.ConstructionSiteId}
                  name={currentObra?.Name || ''}
                  shape={isCustom() ? 'square' : 'circle'}
                  size={28}
                  src={currentObra?.AvatarFileName
                    ? `${process.env.REACT_APP_IMAGE_STORAGE_URL?.replace('hom', 'prod')}${currentObra.AvatarFileName}`
                    : currentObra?.OriginalImageUrl
                  }
                  thumbSrc={currentObra?.ThumbnailImageUrl}
                  thumbType="small"
                  custom={isCustom()}
                  dynamic={match.url?.includes(`/edit/${ConstructionSiteId}/details`)}
                />
              )}
            </span>
            {!currentObra ? (
              <LoadingMain width='68px' height='12px' borderRadius='2px' />
            ) : (
              <Ellipsis phrase={currentObra?.Name} maxLength={19} />
            )}
          </div>

          <div className="divider" />

          <TabsTitle
            active={active === PathActiveEnum.detail}
            onClick={() => handleRedirect(`/obras/detail/${ConstructionSiteId}`, 'detail')}
          >
            <span className="icon">
              {active === PathActiveEnum.detail && loading ? (
                <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
              ) : (
                <Icon icon="arquivo" size="xxs" style={{ marginRight: '6px' }} color={active === PathActiveEnum.detail ? 'primary' : 'cinzaPadrao'} />
              )}
            </span>
            <span>Arquivos</span>
          </TabsTitle>

          <TabsTitle
            active={active === PathActiveEnum.plotagens}
            onClick={() => handleRedirect(`/obras/plotagens/${ConstructionSiteId}`, 'plotagens')}
          >
            <span className="icon">
              {active === PathActiveEnum.plotagens && loading ? (
                <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
              ) : (
                <Icon icon="menuPlotagem" size="xxs" style={{ marginRight: '6px' }} color={active === PathActiveEnum.plotagens ? 'primary' : 'cinzaPadrao'} />
              )}
            </span>
            <span>Plotagens</span>
          </TabsTitle>

          <TabsTitle
            active={active === PathActiveEnum.activities}
            onClick={() => handleRedirect(`/obras/activities/detail/${ConstructionSiteId}`, 'activities')}
          >
            <span className="icon">
              {active === PathActiveEnum.activities && loading ? (
                <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
              ) : (
                <Icon icon="activities" customSize={14} style={{ marginLeft: '-6px' }} color={active === PathActiveEnum.activities ? 'primary' : 'cinzaPadrao'} />
              )}
            </span>
            <span>Atividades</span>
          </TabsTitle>

          <PanelsDropdown
            csId={ConstructionSiteId}
            onOpenAddEditModal={showEditPanels}
            onOpenDeleteModal={handleOpenDeletePanel}
          >
            <TabsTitle
              active={active === PathActiveEnum.panels}
            >
              <span className="icon">
                {active === PathActiveEnum.panels && loading ? (
                  <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
                ) : (
                  <Icon icon="panels" customSize={16} style={{ marginLeft: '-6px' }} color={active === PathActiveEnum.panels ? 'primary' : 'cinzaPadrao'} />
                )}
              </span>
              <span>{panelName || 'Painéis'}</span>
              {(fixedPanels.length > 0 || notFixedPanels.length > 0) && (
                <span className="countPanelsBadge">
                  {fixedPanels.length + notFixedPanels.length}
                </span>
              )}
              <Icon icon="expandir" customSize={12} style={{ margin: 0, marginLeft: '8px' }} color="cinzaPadrao" />
            </TabsTitle>
          </PanelsDropdown>

          <div className="divider" />

          <TabsTitle
            hidden={!currentObra?.IsIntegrationAvailable}
            active={active === PathActiveEnum.viewer}
            onClick={() => handleRedirect(`/obras/viewer/detail/${ConstructionSiteId}`, 'viewer')}
          >
            <span className="icon">
              {active === PathActiveEnum.viewer && loading ? (
                <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
              ) : (
                <Icon icon="visualizar" customSize={10} style={{ marginLeft: '-4px' }} color={active === PathActiveEnum.viewer ? 'primary' : 'cinzaPadrao'} />
              )}
            </span>
            <span>Viewer</span>
          </TabsTitle>
        </div>

        {(currentPeperUser === 1 || currentPeperUser === 7) && (
          <TabsTitle
            active={active === PathActiveEnum.edit}
            marginRight={30}
            onClick={() => (currentPeperUser === 1 || currentPeperUser === 7) ? openEdicaoObra(ConstructionSiteId) : undefined}
          >
            <span className="icon">
              {active === PathActiveEnum.edit && loading ? (
                <Spin indicator={antIcon} style={{ marginRight: '6px' }} />
              ) : (
                <Icon
                  icon="editar"
                  size="xxs"
                  style={{ marginRight: '6px' }}
                  color={active === PathActiveEnum.edit
                    ? 'primary'
                    : 'cinzaPadrao'
                  }
                />
              )}
            </span>
            <span>Editar Obra</span>
          </TabsTitle>
        )}
        {showEditPanelsModal && (
          <PanelsEditModal
            visible={showEditPanelsModal}
            active={activePanelsModal}
            csId={ConstructionSiteId}
            panelForEdit={panelForEdit}
            onActivePanel={setActivePanelsModal}
            onCancel={hideEditPanels}
          />
        )}
        {openDeleteModal && panelForDelete && (
          <ConfirmDeletePanels
            visible={openDeleteModal}
            panel={panelForDelete}
            loading={panelsDeleteLoading}
            onConfirm={() => handleConfirmDeletePanel(panelForDelete)}
            onCancel={handleCloseDeletePanel}
          />
        )}
      </TabsWrapper>
      <DividerMain />
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  currentPeperUser: state.auth.userInfo?.CurrentRoleFk,
  isCollapsed: state.obra.isCollapsed,
  archivesPath: state.obra.archivesPath,
  plotagensPath: state.obra.plotagensPath,
  fixedPanels: state.panels.fixedPanels,
  notFixedPanels: state.panels.notFixedPanels,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ITabsShell;

export default connector(TabsShell);
