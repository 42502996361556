import styled, { css } from "styled-components";

export const Divider = styled.div`
  width: 1px;
  height: auto;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
`;

export const MainWrapperLeft = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding-right: 15px;
  padding-bottom: 15px;

  .cardHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
  }

  .cardMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    background-color: ${({theme}) => theme.colors.surface.surface};
    margin-top: 16px;
  }
`;

export const CardTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const CardSubTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurface};
  margin-bottom: 6px;
`;

interface IImgWrapper {
 isProgress?: string;
}

export const ImgWrapper = styled.div<IImgWrapper>`
  width: 256px;
  height: 128px;
  background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 199px;
    height: 72px;
  }

  .progressBar {
    position: absolute;
    left: 10px;
    bottom: -8px;
    z-index: 2;
  }

  ${({isProgress}) => isProgress === 'true' && css`
    .progressMask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
      z-index: 1;
    }
  `}
`;

export const InfoTextBold = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurface};
  margin-top: 15px;
  margin-bottom: 6px;
`;

export const InfoText = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  margin-top: 6px;
`;

export const MainWrapperRight = styled.div`
  width: 100%;
  max-width: 550px;
  margin-left: 15px;
  position: relative;
  
  .fixedContainer {
    width: 100%;
    max-width: 550px;
    position: fixed;
  }
`;

interface ISvgCard {
  newColor: string;
}

export const SvgCard = styled.div<ISvgCard>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};

  svg {
    #customColorRect-1 {
      fill: ${({newColor}) => newColor};
    }

    #customColorRect-2 {
      fill: ${({newColor}) => newColor};
    }

    #customColorRect-3 {
      fill: ${({newColor}) => newColor};
    }

    #customColorRect-4 {
      fill: ${({newColor}) => newColor};
    }
    
    #customImgG-1 {
      display: none;
    }
  }

  .logo {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 55px;
    height: 20px;
  }
`;
