/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import { UniqueIdentifier, useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Virtuoso } from "react-virtuoso";
import { Icon } from "Components/UI";
import SortableItem from "../SortableItem";
import Badge from "Components/UI/Badge";
import Button from "Components/UI/Button";
import emptyActivities from "../../../../../../Static/images/empty-activities.png";
import emptySearchActivities from "../../../../../../Static/images/empty-search.png";
import { 
  ITopicDnDResponse, 
  ITopicStatus,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { Collapse, Container, Title } from "./styles";
import styles from '../../KanbanVision.module.scss';
import './stylesCSS.css';
import AccessPermission from "Utils/AcessPermission";

export interface IColumn {
  height: number;
  column: ITopicStatus;
  id: string;
  activeId: UniqueIdentifier | null;
  items: string[];
  title: string;
  activitiesList: ITopicDnDResponse[];
  totalItems: number;
  totalAllItems: number;
  totalFilteredAllItems: number;
  onOpenCreateActivity: () => void;
  onOpenShowActivity: (activity: string) => void;
  searchActivity: string;
  showFilters?: boolean;
  hasFilters: boolean;
}

const Column = ({ 
  height,
  id, 
  activeId,
  items, 
  title,
  activitiesList,
  column,
  totalItems,
  totalAllItems,
  totalFilteredAllItems,
  onOpenCreateActivity,
  onOpenShowActivity,
  searchActivity,
  showFilters,
  hasFilters,
}: IColumn) => {
  const [columnCollapse, setColumnCollapse] = useState(false);

  const { setNodeRef } = useDroppable({ id });

  const noActivities = totalAllItems <= 0;
  const noColumnActivities = totalItems <= 0;
  const noFilteredActivities = totalFilteredAllItems <= 0;
  const haveCreateActivitiePermission = AccessPermission.createOrEditActivitiePermission();

  const handleOpenCollapse = () => {
    setColumnCollapse(false);
  }

  const handleCloseCollapse = () => {
    setColumnCollapse(true);
  }

  const HeightPreservingItem = useCallback(({ children, ...props }) => {
    const [size, setSize] = useState(0);
    const knownSize = props["data-known-size"];
    useEffect(() => {
      setSize((prevSize) => {
        return knownSize == 0 ? prevSize : knownSize;
      });
    }, [knownSize]);
    // check style.css for the height-preserving-container rule
    return (
      <div
        {...props}
        className="height-preserving-container"
        style={{
          "--child-height": `${size}px`
        }}
      >
        {children}
      </div>
    );
  }, []);

  const EmptyColumn = useCallback(() => { 
    if (noFilteredActivities && column.Order === 1 && searchActivity) {
      return (
        <div 
          className={`
            ${styles['columnChildEmpty']} 
          `}
        >
          <div className={styles['emptyCard']}>
            <img src={emptySearchActivities} alt="" />
            <p className={styles['emptyTitle']}>
              {`Nenhuma atividade encontrada por “${searchActivity}”.`}
            </p>
            <p className={styles['emptyDescription']}>
              A atividade pesquisada não foi encontrada ou não existe. Verifique sua pesquisa e tente novamente.
            </p>
          </div>
        </div>
      );
    }

    if (noFilteredActivities && column.Order === 1 && hasFilters) {
      return (
        <div 
          className={`
            ${styles['columnChildEmpty']} 
          `}
        >
          <div className={styles['emptyCard']}>
            <img src={emptySearchActivities} alt="" />
            <p className={styles['emptyTitle']}>
              Nenhuma atividade encontrada.
            </p>
            <p className={styles['emptyDescription']}>
              Não foi encontrado nenhuma atividade com os filtros selecionados. Verifique os filtros e tente novamente.
            </p>
          </div>
        </div>
      );
    }

    if ((noActivities || noColumnActivities) && column.Order === 1 && !searchActivity) {
      return (
        <div 
          className={`
            ${styles['columnChildEmpty']} 
          `}
        >
          <div className={styles['emptyCard']}>
            <img src={emptyActivities} alt="" />
            <p className={styles['emptyTitle']}>
              {noActivities 
                ? 'Nenhuma atividade cadastrada'
                : 'Nenhuma atividade pendente'
              }
            </p>
            <p className={styles['emptyDescription']}>
              {noActivities 
                ? 'Ainda não foi cadastrada nenhuma atividade.'
                : 'Todas as atividades já estão em andamento ou finalizadas.'
              }
            </p>
            {haveCreateActivitiePermission && (
              <Button
                className={styles['button']}
                type="primary"
                height={37}
                onClick={onOpenCreateActivity}
              >
                <Icon 
                  icon="adicionar"
                  color="branco"
                  customSize={13}
                  className={styles['buttonIcon']}
                />
                <span className={styles['buttonText']}>Nova Atividade</span>
              </Button>
            )}
          </div>
        </div>
      );
    }

    return <></>;
  }, [
    column.Order, 
    haveCreateActivitiePermission, 
    noActivities, 
    noColumnActivities, 
    noFilteredActivities, 
    onOpenCreateActivity, 
    searchActivity,
    hasFilters,
  ]);

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      {columnCollapse && (
        <Collapse 
          className={`${styles['columnCollapse']} ${styles[column.TopicStatusId]}`}
          customColor={column.Color} 
          height={height}
          showFilters={showFilters}
        >
          <div className="columnHeaderCollapse">
            <Icon 
              icon="avancar"
              customColor="#8697A8"
              customSize={10}
              className="iconToggleOpen" 
              onClick={handleOpenCollapse}
            />            
            <Title customColor={column.Color}>
              {title}
            </Title>
            <Badge
              type="square"
              value={totalItems}
              color={column.Color}
            />
          </div>
        </Collapse>
      )}  
      {!columnCollapse && (
        <Container 
          className="column"
          customColor={column.Color}
          height={height}
          showFilters={showFilters}
        >
          <div className="columnHeader">
            <div className="columnHeaderLeft">
              <Title customColor={column.Color}>
                {title}
              </Title>
              <Badge
                type="square"
                value={totalItems}
                color={column.Color}
              />
            </div>
            <div className="columnHeaderRight">
              {!columnCollapse && (
                <Icon 
                  icon="kanbanCollapse"
                  customColor="#BEC2C6"
                  customSize={18}
                  className="iconToggleClose" 
                  onClick={handleCloseCollapse}
                />
              )}
              {(column.Order === 1 && haveCreateActivitiePermission) && (
                <Icon 
                  icon="adicionar"
                  customColor="#8697A8"
                  customSize={14}
                  className="iconChildrenAdd" 
                  onClick={onOpenCreateActivity}
                />
              )}
            </div>
          </div>
          
          <div className="columnChild">
            <Virtuoso 
              components={{
                Item: HeightPreservingItem,
                EmptyPlaceholder: EmptyColumn,
              }}
              scrollerRef={setNodeRef as any}
              style={{ height: height - (showFilters ? 394 : 352), width: '100%' }}
              totalCount={totalItems}
              data={activitiesList}
              // logLevel={LogLevel.DEBUG}
              itemContent={(index, activity) => (
                <SortableItem 
                  key={activity.Guid}
                  activeId={activeId}
                  activity={activity}
                  column={column}
                  searchActivity={searchActivity}
                  onOpenShowActivity={onOpenShowActivity}
                />
              )}
            />
          </div>
        </Container>
      )}
    </SortableContext>
  );
}

export default Column;
