import React, { FC, useEffect, useState } from 'react';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { history } from 'Store';
import BoxContainer from 'Components/UI/Containers/BoxContainer/BoxContainer';
import ObrasItem from './ObrasItem/ObrasItem';
import ListContainer from 'Components/UI/Containers/ListContainer/ListContainer';
import ObraArquivada from './ObraArquivada/ObraArquivada';
import PageHeader from 'Components/UI/PageHeader/PageHeader';
import ActionMenuObra from 'Components/UI/ActionMenu/ActionMenuItem';
import CadastroObra from './Modals/CadastroObra';
import { ObraActions } from 'Store/Obra/Obra.actions';
import UsuariosEPermissoes from './Modals/UsuariosEPermissoes';
import { formatDateByTime } from 'Utils/DateUtils';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { GetAvatar } from 'Utils/generateThumbnail';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import engineerImg from 'Static/images/engineer.png';
import Button from 'Components/UI/Button';
import ConexaoServico from './Modals/ConexaoServico';
import AvisoObraUpgrade from './Modals/AvisoObraUpgrade';
import AvisoUsuarioUpgrade from '../SharedModals/AvisoUsuarioUpgrade';
import LoadingObras from 'Components/UI/CustomLoading/LoadingObras';
import { Mixpanel } from 'Utils/MixPanel';
import { AuthUtils } from 'Utils/AuthUtils';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { HeaderActions } from './HeaderActions';
import { SearchRow } from './SearchRow';
import AppStorage from 'Utils/AppStorage';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import styles from './Obras.module.scss';

export enum ROLES {
  ADMIN = 1,
  COORDENADOR = 7
}

export const SEARCH_LOCAL_STORAGE_KEY = `CONSTRUCTION_SITE_SEARCH`;

const Obras: FC<Props> = ({
  isLoading,
  ObrasList,
  minhaConta,
  notification,
  planFree,
  userInfo,
  isLoadingGetUsersConstruction,
  Users,
  userLimitsActive,
  usersForLimitInvited,
  clearConstructionSiteSearch,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string')
    ? whatsappContactLinkFlag.value
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const firstOpen = React.useRef(0);
  const [usuariosEPermissoesVisible, setUsuariosEPermissoesVisible] = React.useState(
    false
  );
  const [cadastroObraVisible, setCadastroObraVisible] = React.useState(false);
  const [isEditObra, setIsEditObra] = React.useState(false);
  const [conexaoServicoVisible, setConexaoServicoVisible] = React.useState(false);
  const [csIdConexaoServico, setCsIdConexaoServico] = React.useState<number>();
  const [modalContaUpgradeVisible, setModalContaUpgradeVisible] = React.useState(false);
  const [modalUsuarioUpgradeVisible, setModalUsuarioUpgradeVisible] = React.useState(
    false
  );
  const [maxObras, setMaxObras] = React.useState<number>();
  const [maxUsers, setMaxUsers] = React.useState<number>();

  const currentSearchOnStorage = AppStorage.GetItem(SEARCH_LOCAL_STORAGE_KEY, 'local') || undefined;

  const [constructionSiteSearch, setConstructionSiteSearch] = useState<string | undefined>(currentSearchOnStorage);
  const [isSearchSubmited, setIsSearchSubmited] = useState(!!currentSearchOnStorage);

  const obras = ObrasList?.filter((obra) => !obra.isArchived);
  const obrasArquivadas = ObrasList?.filter((obra) => obra.isArchived);
  const obrasForShow = !!obras && obras.length > 0;

  useEffect(() => {
    if (firstOpen.current === 0 && userInfo?.Id && AuthUtils.getLoggedUser().userId) {
      Mixpanel.track({
        name: 'PAGE_VIEW',
        props: {},
        userInfo,
        currentListTenant: currentTenant,
      });
      firstOpen.current++;
    }
  }, [userInfo, AuthUtils.getLoggedUser().userId]);

  useEffect(() => {
    if (!isLoadingGetUsersConstruction) {
      dispatch(ObraActions.usersForLimitInvited(Users?.InvitedUsers))
    }
  }, [isLoadingGetUsersConstruction]);

  useEffect(() => {
    if (userInfo?.UserStoreItem.MaxQtyConstructionSites || userInfo?.UserStoreItem.StorePlan?.MaxQtyConstructionSites) {
      setMaxObras(userInfo.UserStoreItem.MaxQtyConstructionSites || userInfo?.UserStoreItem.StorePlan?.MaxQtyConstructionSites);
    }
    if (userInfo?.UserStoreItem.MaxQtyUsers || userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers) {
      setMaxUsers(userInfo?.UserStoreItem.MaxQtyUsers || userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers);
    }
  }, [userInfo]);

  useEffect(() => {
    if (clearConstructionSiteSearch) {
      removeSearch();
      dispatch(ObraActions.clearConstructionSiteSearch(false));
    }
  }, [clearConstructionSiteSearch]);

  const openConexaoServico = (csId: number) => {
    setConexaoServicoVisible(true);
    setCsIdConexaoServico(csId);
  };

  const openUsuariosEPermissoes = (constructionSiteId: number) => {
    if (!isLoading) {
      if (obras) {
        if (maxUsers) {
          if (userLimitsActive?.QtyActiveUsers && (userLimitsActive?.QtyActiveUsers >= maxUsers)) {
            setModalUsuarioUpgradeVisible(true);
          } else {
            setUsuariosEPermissoesVisible(true);
            dispatch(TenantActions.getTenantListUsersConstruction());
            dispatch(ObraActions.getUsersConstructionSite(constructionSiteId));
          }
        } else {
          setUsuariosEPermissoesVisible(true);
          dispatch(TenantActions.getTenantListUsersConstruction());
          dispatch(ObraActions.getUsersConstructionSite(constructionSiteId));
        }
      }
    }
  };

  const openCadastroObra = () => {
    if (!isLoading) {
      if ((maxObras && userLimitsActive?.QtyActiveConstructionSites)
        && (userLimitsActive?.QtyActiveConstructionSites >= maxObras)) {
        setModalContaUpgradeVisible(true);
      } else {
        setIsEditObra(false);
        dispatch(ObraActions.resetState());
        dispatch(TenantActions.getUsers());
        setCadastroObraVisible(true);
      }
    }
  };

  const closeModalContaUpgrade = () => {
    setModalContaUpgradeVisible(false);
  };

  const upgradePlanRedirect = (role: number, planOrder: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'obras',
        originPath: window.location.pathname
      },
      userInfo,
      currentListTenant: currentTenant,
    });

    closeModalContaUpgrade();
    if (role === 1) {
      if (planOrder === 4) {
        window.open(whatsappContactLink);
      } else {
        history.push('/faturamento/planos');
      }

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  }

  const filteredObras = obras?.filter(x => !constructionSiteSearch || x.Name.toLocaleLowerCase().indexOf(constructionSiteSearch.toLocaleLowerCase()) > -1);

  const removeSearch = () => {
    setConstructionSiteSearch(undefined);
    setIsSearchSubmited(false);
    AppStorage.RemoveItem(SEARCH_LOCAL_STORAGE_KEY, 'local');
  }

  return (
    <div className={styles['ObrasWrapper']}>
      <PageHeader
        name="Obras"
        showCount
        count={filteredObras?.length}
        isFiltered={!!constructionSiteSearch}
        actions={
          <HeaderActions
            isLoading={isLoading}
            minhaConta={minhaConta}
            obras={obras}
            obrasForShow={obrasForShow}
            openCadastroObra={openCadastroObra}
            onSearchChange={(search) => setConstructionSiteSearch(search)}
            onRemoveSearch={removeSearch}
            onSearch={(search) => {
              setConstructionSiteSearch(search);
              setIsSearchSubmited(true);
              AppStorage.SetItem(SEARCH_LOCAL_STORAGE_KEY, search, 'local');
            }}
          />
        }
      >
        <SearchRow
          obrasForShow={obrasForShow}
          show={isSearchSubmited}
          search={constructionSiteSearch}
          onRemoveSearch={removeSearch}
          count={filteredObras?.length}
        />

        {isLoading || obras === undefined ? (
          <LoadingObras multiple={6} />
        ) : (
          <>
            {filteredObras?.map((obra) => (
              <BoxContainer key={obra.ConstructionSiteId}>
                <ActionMenuObra
                  constructionSiteName={obra.Name}
                  constructionSiteId={obra.ConstructionSiteId}
                  activeObrasCount={obras && obras.length}
                  openInviteUser={() => openUsuariosEPermissoes(obra.ConstructionSiteId)}
                >
                  <ObrasItem
                    constructionSiteId={obra.ConstructionSiteId}
                    image={obra?.AvatarFileName
                      ? `${process.env.REACT_APP_IMAGE_STORAGE_URL?.replace('hom', 'prod')}${obra.AvatarFileName}`
                      : obra?.OriginalImageUrl
                    }
                    thumbSrc={obra?.ThumbnailImageUrl}
                    thumbType="big"
                    name={obra.Name}
                    isConnectedApi={obra.isConnectedApi}
                    data={formatDateByTime(new Date(obra.LastUpdateUtc))}
                    openUsuariosEPermissoes={() =>
                      openUsuariosEPermissoes(obra.ConstructionSiteId)
                    }
                    openConexaoServico={() => openConexaoServico(obra.ConstructionSiteId)}
                    sharedUsers={obra.SharedUsers}
                    currentPeperUser={minhaConta.CurrentRoleFk}
                    notification={notification}
                    search={{ show: !isSearchSubmited && !!constructionSiteSearch, search: constructionSiteSearch }}
                  />
                </ActionMenuObra>
              </BoxContainer>
            ))}
          </>
        )}
      </PageHeader>

      {obras && !obras.length && (
        <div className={styles['withoutConstruction']}>
          <img src={engineerImg} alt="Engineer" />
          <span className={styles['title']}>
            Você ainda não possui nenhuma obra, clique no + e crie sua primeira obra
          </span>
          <Button
            className={styles['buttonTour']}
            onClick={() => window.open('https://youtu.be/FeBXPmpL2og', '_blank')}
          >
            Quero ajuda com a minha primeira obra
          </Button>
        </div>
      )}

      {obrasArquivadas?.length ? (
        <PageHeader
          name="Obras arquivadas"
          showCount
          count={obrasArquivadas.length}
          isCollapsiable
        >
          <div className={styles['ListaObrasArquivadas']}>
            {obrasArquivadas?.map((obra) => (
              <ListContainer key={`obraArquivada${obra.ConstructionSiteId}`}>
                <ObraArquivada
                  ConstructionSiteId={obra.ConstructionSiteId}
                  avatar={<GetAvatar name={obra.Name} shape='circle' size='large' />}
                  name={obra.Name}
                  currentPeperUser={minhaConta.CurrentRoleFk}
                  activeObras={userLimitsActive?.QtyActiveConstructionSites ?? 0}
                  maxObras={maxObras}
                  setModalContaUpgradeVisible={setModalContaUpgradeVisible}
                />
              </ListContainer>
            ))}
          </div>
        </PageHeader>
      ) : (
        ''
      )}

      {cadastroObraVisible && <CadastroObra
        isVisible={cadastroObraVisible}
        isEdit={isEditObra}
        onCancel={() => setCadastroObraVisible(false)}
        firstInvitedUsers={usersForLimitInvited}
      />}
      {usuariosEPermissoesVisible && <UsuariosEPermissoes
        isVisible={usuariosEPermissoesVisible}
        onCancel={() => setUsuariosEPermissoesVisible(false)}
        maxUsers={maxUsers}
        firstInvitedUsers={usersForLimitInvited}
      />}
      {conexaoServicoVisible && <ConexaoServico
        csId={csIdConexaoServico}
        isVisible={conexaoServicoVisible}
        onCancel={() => setConexaoServicoVisible(false)}
      />}
      {modalContaUpgradeVisible && <AvisoObraUpgrade
        visible={modalContaUpgradeVisible}
        onCancel={closeModalContaUpgrade}
        onConfirm={upgradePlanRedirect}
        planFree={planFree}
        order={userInfo?.UserStoreItem.StorePlan?.Order}
        role={minhaConta.CurrentRoleFk}
      />}
      {modalUsuarioUpgradeVisible && <AvisoUsuarioUpgrade
        visible={modalUsuarioUpgradeVisible}
        onCancel={() => setModalUsuarioUpgradeVisible(false)}
      />}
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  ...state.auth,
  isLoading: state.obra.isLoading,
  minhaConta: state.minhaConta.userInfo,
  registerCompleted: state.minhaConta.userInfo?.isRegisterComplete,
  notification: state.notification.notificationsMap,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Obras);
