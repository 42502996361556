import { IFileState } from './interfaces/IFileState';
import { FileActionUnion, FileActionKeys } from './File.actions';

const initialState: IFileState = {
  srcFileUploaded: undefined,
  isLoading: false,
  error: undefined,
  message: undefined,
};

const fileReducer = (state = initialState, action: FileActionUnion): IFileState => {
  switch (action.type) {
    case FileActionKeys.UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case FileActionKeys.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        srcFileUploaded: action.payload,
        isLoading: false,
      };
    case FileActionKeys.UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FileActionKeys.UPLOAD_AVATAR_RESET_STATE:
      return {
        ...state,
        srcFileUploaded: undefined,
        isLoading: false,
        error: undefined,
        message: undefined,
      };
    default:
      return state;
  }
};

export default fileReducer;
