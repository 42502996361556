import { IStorePlanResponse } from "./IStorePlanResponse";

export interface IUserStoreItem {
  BillingDay?: number;
  ContractEndDate?: Date;
  ContractPrice?: number;
  ContractStartDate?: Date;
  MaxQtyConstructionSites?: number;
  MaxQtyRenderedFiles?: number;
  MaxQtyUploadFiles?: number;
  MaxQtyUsers?: number;
  PaymentMethod?: PaymentMethodEnum;
  Price?: number;
  StorePlan?: IStorePlanResponse;
  UpdateDateUtc?: Date;
  UpdateStorePlan?: IStorePlanResponse;
  UserStoreItemsId: number;
  isActive: number;
  isFree: boolean;
}

export enum PaymentMethodEnum {
  'Boleto bancário' = 1,
  'Cartão de crédito' = 2,
}
