import { IOptionData } from "Components/UI/Select";
import { FilterOperationMap } from "Data/interfaces/Activities/IFilters";

export const operationsFormatObj: Record<keyof FilterOperationMap, (custom?: boolean) => IOptionData> = {
  equal: (custom?: boolean) => {
    return {
      value: 'equal',
      description: 'é igual a',
      label: custom ? '=' : 'é igual a',
    }
  },
  different: (custom?: boolean) => {
    return {
      value: 'different',
      description: 'é diferente de',
      label: custom ? '≠' : 'é diferente de',
    }
  },
  bigger: (custom?: boolean) => {
    return {
      value: 'bigger',
      description: 'é maior que',
      label: custom ? '>' : 'é maior que',
    }
  },
  smaller: (custom?: boolean) => {
    return {
      value: 'smaller',
      description: 'é menor que',
      label: custom ? '<' : 'é menor que',
    }
  },
  biggerOrEqual: (custom?: boolean) => {
    return {
      value: 'biggerOrEqual',
      description: 'é maior ou igual a',
      label: custom ? '≥' : 'é maior ou igual a',
    }
  },
  lessOrEqual: (custom?: boolean) => {
    return {
      value: 'lessOrEqual',
      description: 'é menor ou igual a',
      label: custom ? '≤' : 'é menor ou igual a',
    }
  },
  contain: (custom?: boolean) => {
    return {
      value: 'contain',
      description: 'contém',
      label: 'contém',
    }
  },
  doesNotContain: (custom?: boolean) => {
    return {
      value: 'doesNotContain',
      description: 'não contém',
      label: 'não contém',
    }
  },
  beginsWith: (custom?: boolean) => {
    return {
      value: 'beginsWith',
      description: 'começa com',
      label: 'começa com',
    }
  },
  endsWith: (custom?: boolean) => {
    return {
      value: 'endsWith',
      description: 'termina com',
      label: 'termina com',
    }
  },
  empty: (custom?: boolean) => {
    return {
      value: 'empty',
      description: 'está vazio',
      label: 'está vazio',
    }
  },
  notEmpty: (custom?: boolean) => {
    return {
      value: 'notEmpty',
      description: 'não está vazio',
      label: 'não está vazio',
    }
  },
  before: (custom?: boolean) => {
    return {
      value: 'before',
      description: 'é antes de',
      label: 'é antes de',
    }
  },
  after: (custom?: boolean) => {
    return {
      value: 'after',
      description: 'é depois de',
      label: 'é depois de',
    }
  },
  onOrBefore: (custom?: boolean) => {
    return {
      value: 'onOrBefore',
      description: 'é em ou antes de',
      label: 'é em ou antes de',
    }
  },
  onOrAfter: (custom?: boolean) => {
    return {
      value: 'onOrAfter',
      description: 'é em ou depois de',
      label: 'é em ou depois de',
    }
  },
  between: (custom?: boolean) => {
    return {
      value: 'between',
      description: 'é entre',
      label: 'é entre',
    }
  },
};

export const optionsOperationsObj: Record<string, (keyof FilterOperationMap)[]> = {
  Visibility: ['contain', 'different'],
  TopicAssignedUsers: ['equal', 'contain', 'different', 'empty', 'notEmpty'],
  ConstructionSiteDisciplines: ['equal', 'contain', 'different'],
  CommunicationTypes: ['contain', 'different', 'empty', 'notEmpty'],
  TopicPoints: ['equal', 'contain', 'different'],
  Stage: ['contain', 'different', 'empty', 'notEmpty'],
  Type: ['contain', 'different'],
  DueDate: ['equal', 'before', 'after', 'onOrBefore', 'onOrAfter', 'between', 'empty', 'notEmpty'],
  Priority: ['contain', 'different', 'empty', 'notEmpty'],
  Description: ['equal', 'different', 'contain', 'doesNotContain', 'beginsWith', 'endsWith', 'empty', 'notEmpty'],
  Title: ['equal', 'different', 'contain', 'doesNotContain', 'beginsWith', 'endsWith'],
  Identifier: ['equal', 'different', 'bigger', 'smaller', 'biggerOrEqual', 'lessOrEqual'],
  Status: ['contain', 'different'],
};

export const optionsDefaultOperationsObj: Record<string, (keyof FilterOperationMap)> = {
  Visibility: 'contain',
  TopicAssignedUsers: 'contain',
  ConstructionSiteDisciplines: 'contain',
  CommunicationTypes: 'contain',
  TopicPoints: 'contain',
  Stage: 'contain',
  Type: 'contain',
  DueDate: 'equal',
  Priority: 'contain',
  Description: 'contain',
  Title: 'contain',
  Identifier: 'equal',
  Status: 'contain',
};

export const getPropertiesObj: Record<string, string[]> = {
  TopicAssignedUsers: ['Id'],
  ConstructionSiteDisciplines: ['ConstructionSiteDisciplinesId'],
  CommunicationTypes: ['TopicCommunicationTypeId'],
  TopicPoints: ['TopicPointId'],
  Stage: ['TopicStageId'],
  Type: ['TopicTypeId'],
  Status: ['TopicStatusId'],
};

export const arrayTopicKeysPtBr: Record<string, string> = { 
  Visibility: 'Visibilidade',
  TopicAssignedUsers: 'Responsáveis',
  ConstructionSiteDisciplines: 'Disciplinas',
  CommunicationTypes: 'Meio',
  TopicPoints: 'Local',
  Stage: 'Etapa',
  Type: 'Tipo',
  DueDate: 'Prazo limite',
  Priority: 'Prioridade',
  Description: 'Observação',
  Title: 'Resumo',
  Identifier: 'Código',
  Status: 'Status',
};
