import { FC, CSSProperties } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormTrigger,
} from 'react-hook-form';
import { IconName, Icon } from '../Icon';
import { LabelInput } from './styles';

export interface IFieldControled {
  name: string;
  control: Control;
  label: string;
  style?: CSSProperties;
  className?: string;
  icon?: IconName;
  rightIcon?: IconName;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLenght?: number;
  height?: string;
  autoFocus?: boolean;
  trigger: UseFormTrigger<FieldValues>;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

const FieldControled: FC<IFieldControled> = ({
  name,
  control,
  label,
  style,
  icon,
  rightIcon,
  type = 'text',
  placeholder = label,
  disabled,
  className,
  height,
  maxLenght,
  autoFocus,
  trigger,
  onFocus,
  onBlur,
  onClick,
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LabelInput
          hidden={type === 'hidden'}
          className={className || ''}
          hasicon={!!icon}
          isdisabled={disabled}
          invalid={!!error}
          style={style}
          height={height}
        >
          {icon ? <Icon icon={icon} className="fieldIcon" /> : ''}
          <input
            {...field}
            maxLength={maxLenght}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className="input"
            onChange={(e) => {
              field.onChange(e);
              trigger(name);
              onChange && onChange(e.target.value);
            }}
            onFocus={onFocus && onFocus}
            onBlur={() => {
              field.onBlur();
              onBlur && onBlur();
            }}
            onClick={onClick && onClick}
          />
          <span className="label">{label}</span>
          <div className="errorInput errorCustomInput">
            {error ? error.message : ''}
          </div>
        </LabelInput>
      )}
    />
  );
};

export default FieldControled;
