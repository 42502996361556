import React, { FC } from "react";
import Button from "Components/UI/Button";
import PlanoAvisoViewerUpgrade from '../../../../Static/images/activities-drawer.png';
import styles from "./ActivitiesDemo.module.scss";
import { useFeatureFlag } from "Hooks/useFeatureFlag";

const ActivitiesDemo: FC = () => {
  const activitiesLinkFlag = useFeatureFlag('activities-link');
  const activitiesLink = (activitiesLinkFlag.enabled && typeof activitiesLinkFlag.value === 'string') 
    ? activitiesLinkFlag.value 
    : (process.env.REACT_APP_ACTIVITIES_LINK || '');

  const openLink = () => {
    window.open(activitiesLink);
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['main']}>
        <div className={styles['header']}>
          <span>Em breve</span>
        </div>
        <div className={styles['content']}>
          <h3>Controle as atividades do seu projeto!</h3>
          <p>Em breve uma nova funcionalidade para o viewer!</p>
        </div>
        <img src={PlanoAvisoViewerUpgrade} alt="Imagem Kanban" />
        <Button 
          className={styles['button-confirm']} 
          width={95} 
          height={36} 
          onClick={() => openLink()}
          type="primary"
        >
          Saiba mais
        </Button>
      </div>
    </div>
  )
}

export default ActivitiesDemo;
