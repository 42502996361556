import { call, put } from 'redux-saga/effects';
import { TenantApi } from '../../Data/Tenant.api';
import { ObrasApi } from '../../Data/Obras.api';
import toastHandler from '../../Utils/toastHandler';
import { loadError, updateError } from '../../Utils/Messages';
import {
  TenantActions,
  GetTenantInviteInfoAction,
  ChangeContextAction,
  RemoveTenantInviteUserAction,
  TenantUsersConstructionReSendInviteAction,
  RemoveTenantUserAction,
  UpdateTenantBindConstructionSitesToUserAction,
  UpdateTenantBindConstructionSitesToInvitedUserAction,
  TenantBindInviedUsersAction,
  UpdateTenantUserRoleAction,
  UpdateTenantStatusAction
} from './Tenant.actions';
import { AuthUtils } from '../../Utils/AuthUtils';
import { history } from '..';

import { NotificationActions } from 'Store/Notification/Notification.actions';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { NomenclaturaActions } from 'Store/Nomenclatura/Nomenclatura.actions';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { IUserLimitsActive } from 'Store/Auth/interfaces/IAuthState';
import { Mixpanel } from 'Utils/MixPanel';

export function* getTenantUsers() {
  try {
    const { data } = yield call(TenantApi.getTenantUsersByUser);
    yield put(TenantActions.getTenantUsersSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('informações da construtora');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getTenantUsersFailure(error));
  }
}

export function* getUsers() {
  try {
    const { data } = yield call(TenantApi.getAllUsers);
    yield put(TenantActions.getUsersSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('usuários');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getUsersFailure(error));
  }
}

export function* getUsersWithConstruction() {
  try {
    const { data } = yield call(TenantApi.getTenanListUsersConstructionSites);
    yield put(TenantActions.getTenantListUsersConstructionSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('Erro ao listar os usuários');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getTenantListUsersConstructionFailure(error));
  }
}

export function* removeTenantInviteUser({ payload }: RemoveTenantInviteUserAction) {
  try {
    yield call(TenantApi.removeTenantInvite, payload);
    yield put(TenantActions.removeTenantInviteUserSuccess(payload));
    const userLimitsActive: IUserLimitsActive = {
      QtyActiveUsers: -1,
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
    toastHandler.showSuccess('Convite removido !');
  } catch (e: any) {
    const msg = loadError('Erro ao enviar a solicitação de remoçao de conta');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(TenantActions.removeTenantInviteUserFailure(error));
    toastHandler.showError(error);
  }
}

export function* removeTenantUser({ payload }: RemoveTenantUserAction) {
  try {
    yield call(TenantApi.removeTenantUser, payload);
    yield put(TenantActions.removeTenantUserSuccess(payload));
    const userLimitsActive: IUserLimitsActive = {
      QtyActiveUsers: -1,
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
    toastHandler.showSuccess('Usuário removido com sucesso!');
  } catch (e: any) {
    const msg = loadError('Erro ao remover o usuário');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    yield put(TenantActions.removeTenantUserFailure(error));
    toastHandler.showError(error);
  }
}

export function* reSendTenantInvite({
  payload
}: TenantUsersConstructionReSendInviteAction) {
  try {
    yield call(TenantApi.tenantReSendInvite, payload);
    yield put(TenantActions.tenantUsersConstructionReSendInviteSuccess(payload))
    toastHandler.showSuccess('Convite enviado!');
  } catch (e: any) {
    const msg = loadError('Erro ao reenviar o convite');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.tenantUsersConstructionReSendInviteFailure(error))
  }
}

export function* getTenantRoles() {
  try {
    const { data } = yield call(TenantApi.getAllTenantRoles);
    yield put(TenantActions.getTenantRolesSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('papéis');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getTenantRolesFailure(error));
  }
}

export function* getTenantInviteInfo({ payload }: GetTenantInviteInfoAction) {
  try {
    const { data } = yield call(TenantApi.getTenantInviteInfo, payload);
    yield put(TenantActions.getTenantInviteInfoSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('dados do usuário convidado');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getTenantInviteInfoFailure(error));
  }
}

export function* getUserTenants() {
  try {
    const { data } = yield call(TenantApi.getTenantsByUser);
    yield put(TenantActions.listUserTenantsSuccess(data.data));
  } catch (e: any) {
    const msg = loadError('construtoras');
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.listUserTenantsFailure(error));
  }
}

export function* changeContext({ payload }: ChangeContextAction) {
  try {

    yield put(NotificationActions.clearAllNotification());
    const { data } = yield call(TenantApi.changeContext, payload.tenantId);

    AuthUtils.setLoggedUser({
      ...AuthUtils.getLoggedUser(),
      currentTenant: payload.tenantId
    });

    yield put(AuthActions.getUserInfo());
    yield put(ObraActions.list());
    yield put(ObraActions.clearAllConstructionsSites());
    yield put(TenantActions.changeContextSuccess(data.data));
    yield put(NotificationActions.listNewNotification());
    yield put(NomenclaturaActions.getNomenclature());
    yield put(NomenclaturaActions.getExtensions());
    yield put(TenantActions.listUserTenants());
    yield put(ObraActions.listAllConstructionsSitesOfUser());

    history.push(payload.redirectUrl);
  } catch (e: any) {

    const msgError = e.errors ? e.errors[0]?.Message : null;
    if (msgError != null && msgError == 'Usuário não tem permissão para a ação.') {
      AuthUtils.logout();
      window.location.href = "/login?erro=Esse usuário não possui permissão para acesso a essa Empresa. Entre em contato com o Administrador da Conta.";
    }
    else {
      const error = updateError('construtora');
      toastHandler.showError(error);
      yield put(TenantActions.changeContextFailure(error));
    }
  }
}

export function* updateTenantBindConstructionSitesToUser({
  payload
}: UpdateTenantBindConstructionSitesToUserAction) {
  try {
    const { data } = yield call(ObrasApi.bindConstructionSitesToUser, payload);
    yield put(TenantActions.updateTenantBindConstructionSitesToUserSuccess(data.data));
    toastHandler.showSuccess('Usuários atualizados com sucesso.');
  } catch (e: any) {
    const msg = 'Erro ao vincular obra ao usuário';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.updateTenantBindConstructionSitesToUserFailure(error));
  }
}

export function* updateTenantBindConstructionSitesToInvitedUser({
  payload
}: UpdateTenantBindConstructionSitesToInvitedUserAction) {
  try {
    const { data } = yield call(ObrasApi.bindConstructionSitesToInvitedUser, payload);
    yield put(
      TenantActions.updateTenantBindConstructionSitesToInvitedUserSuccess(data.data[0])
    );
    toastHandler.showSuccess('Usuários atualizados com sucesso.');
  } catch (e: any) {
    const msg = 'Erro ao vincular obra ao usuário';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.updateTenantBindConstructionSitesToInvitedUserFailure(error));
  }
}

export function* tenantBindInvitedUser({ payload }: TenantBindInviedUsersAction) {
  const {
    firstUserTracking,
    userInfoTracking,
    currentTenantTracking,
  } = payload;
  delete payload.firstUserTracking;
  delete payload.userInfoTracking;
  delete payload.currentTenantTracking;

  try {
    const { data } = yield call(TenantApi.tenantBindInvitedUser, payload.Users);
    yield put(TenantActions.tenantBindInviedUsersSuccess(data.data));
    const userLimitsActive: IUserLimitsActive = {
      QtyActiveUsers: data.data.length,
    }
    if (firstUserTracking) {
      Mixpanel.track({
        name: 'INVITED_FIRST_USER', 
        props: {}, 
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      });
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
    toastHandler.showSuccess('Convites enviados com sucesso.');
  } catch (e: any) {
    const msg = 'Erro ao convidar os usuários';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.getTenantInviteInfoFailure(error));
  }
}

export function* updateTenantUserRole({ payload }: UpdateTenantUserRoleAction) {
  try {
    const { data } = yield call(TenantApi.bindConstructionSitesToUser, payload);
    yield put(TenantActions.updateTenantUserRoleSuccess(data.data));
    toastHandler.showSuccess('Papel do usuário atualizados com sucesso.');
  } catch (e: any) {
    const msg = 'Erro ao atualizar papel do usuário.';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.updateTenantUserRoleFailure(error));
  }
}

export function* updateTenantStatus({ payload }: UpdateTenantStatusAction) {
  try {
    yield call(TenantApi.updateTenantStatus, payload);
    yield put(TenantActions.updateTenantStatusSuccess(payload));
    let userLimitsActive: IUserLimitsActive;
    if (payload.isActive) {
      userLimitsActive = {
        QtyActiveUsers: 1,
      }
    } else {
      userLimitsActive = {
        QtyActiveUsers: -1,
      }
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
    toastHandler.showSuccess(`Usuário ${payload.isActive ? 'ativado' : 'inativado'} com sucesso.`);
  } catch (e: any) {
    const msg = 'Erro ao atualizar status do usuário.';
    const error = e.errors ? e.errors[0]?.Message || msg : msg;
    toastHandler.showError(error);
    yield put(TenantActions.updateTenantStatusFailure(error));
  }
}
