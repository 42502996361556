import * as Yup from "yup";

const msg = 'Este campo deve ser preenchido';

const schema = Yup.object().shape({
  cnpj: Yup.string().required(msg).nullable(),
  companyName: Yup.string().required(msg).nullable(),
  phone: Yup.string().required(msg).nullable(),
  email: Yup.string().email('Digite um email válido').required(msg).nullable(),
  streetAddress: Yup.string().required(msg).nullable(),
  number: Yup.number().required(msg).nullable(),
  district: Yup.string().required(msg).nullable(),
  city: Yup.string().required(msg).nullable(),
  state: Yup.string().required(msg).nullable(),
  zip: Yup.string().required(msg).nullable(),
});

export default schema;