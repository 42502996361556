import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { IObraResponse } from './interfaces/Obra/IObraListResponse';
import { IObraDetailsUpdate } from './interfaces/Obra/IObraDetails';
import { IObraDisciplinesRequest } from './interfaces/Obra/IObraDisciplines';
import { IObraInvitedUsersDeleteRequest, IObraInvitedUsersRequest, IObraUsersRequest } from './interfaces/Obra/IObraUsers';
import { IObraPermissionsRequest } from './interfaces/Obra/IObraPermissions';
import { IObraNomenclaturesRequest } from './interfaces/Obra/IObraNomenclaturas';
import { ICreateStageRequest, IDeleteStageRequest, ISettingsStageRequest, IUpdateStageRequest } from './interfaces/Obra/IObraStages';
import { IConstructionSiteStageLibraryRequest } from './interfaces/Obra/IObraLibraries';

export class ObrasApiV2 {
  static async updateDetails(constructionSiteId: number, request: IObraDetailsUpdate): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${constructionSiteId}/details`, request);
  }

  static async updateDisciplines(request: IObraDisciplinesRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${request.constructionStieId}/disciplines`, request.disciplines);
  }

  static async updateUsers(request: IObraUsersRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.post(`/v2/construction-sites/${request.constructionStieId}/users`, { userId: request.userId });
  }

  static async deleteUsers(request: IObraUsersRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.delete(`/v2/construction-sites/${request.constructionStieId}/users/${request.userId}`);
  }

  static async updateInvitedUsers(request: IObraInvitedUsersRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.post(`/v2/construction-sites/${request.constructionStieId}/invited-users`, { email: request.email, roleId: request.roleId });
  }

  static async deleteInvitedUsers(request: IObraInvitedUsersDeleteRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.delete(`/v2/construction-sites/${request.constructionStieId}/invited-users/${request.invitedEmail}`);
  }

  static async updatePermissions(request: IObraPermissionsRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${request.constructionStieId}/permissions`, request.permissions);
  }

  static async updateNomenclatures(request: IObraNomenclaturesRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${request.constructionStieId}/nomenclatures`, request.nomenclatures);
  }

  static async listStages(csId: number): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.get(`/v2/construction-sites/${csId}/stages`);
  }

  static async createStages(request: ICreateStageRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.post(`/v2/construction-sites/${request.constructionStieId}/stages`, request.stage);
  }

  static async updateStages(request: IUpdateStageRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${request.constructionStieId}/stages/${request.stageId}`, request.stage);
  }

  static async deleteStages(request: IDeleteStageRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.delete(`/v2/construction-sites/${request.constructionStieId}/stages/${request.stageId}`);
  }

  static async listLibraries(): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/v2/construction-sites/stage-libraries');
  }

  static async importLibrary(request: IConstructionSiteStageLibraryRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.post('/v2/construction-sites/stage/import', request);
  }

  static async settingsStages(request: ISettingsStageRequest): Promise<AxiosResponse<IObraResponse[]>> {
    const axios = await Http.axios();
    return axios.put(`/v2/construction-sites/${request.constructionStieId}/stage-settings`, request.config);
  }
}
