import styled from "styled-components"

export const Buttons = styled.div`
  margin-right: 4px;

  .buttonCancel {
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  .buttonSubmit {
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.danger.danger} !important;
    color: ${({theme}) => theme.colors.danger.onDanger} !important;
    margin-right: 4px !important;
    
    &:hover {
      background-color: ${({theme}) => theme.colors.danger.danger} !important;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 24px 14px 0 14px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  display: flex;
  flex-direction: column;

  .midleHr {
    border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    margin: 12px 10px 18px 10px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-left: 10px;
    margin-right: 10px;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const FooterHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 24px;
  margin-bottom: 0;
`;
