import { VersionFieldEnum } from 'Data/interfaces/Nomenclatura/INomenclatureData';
import * as Yup from 'yup';

export const createSchema = (versionFields: any[], stages?: string[]) => {
  const fields: any = {};
  versionFields?.forEach((field) => {
    let validation = Yup.string().required(' ');

    if (field.CampoVersaoId === VersionFieldEnum.Revisao) {
      validation = validation.matches(/\d+/g, 'Sem número');
    }

    if (stages && field.CampoVersaoId === VersionFieldEnum.Fase) {
      validation = validation.oneOf(stages, ' ');
    }

    fields[`field${field.Ordem}`] = validation;
  });

  return Yup.object().shape(fields);
};

const schema = Yup.object().shape({});

export default schema;
