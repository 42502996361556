import styled, { css } from "styled-components";

export const Container = styled.div`

`;

export const HeaderContent = styled.div`
  display: flex;
  margin-bottom: 4px;

  .clearInput {
    position: absolute;
    top: 6px;
    right: 2px;
    cursor: pointer;

    &:active {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }
`;

interface InputContainerProps {
  issingle?: string;
  isfocused?: string;
}

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width: 100%;

  .inputDefault {
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
    border-radius: 3px;
    border: none;

    &:focus {
      outline-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    ::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    ${({isfocused}) => isfocused === 'true' && css`
      border: 2px solid ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
      outline-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    `}
  }

  ${({issingle}) => issingle !== 'true' && css`
    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }
  `}
`;
