import React, { FC } from 'react';

import styles from './DisciplinaItem.module.scss';
import { Checkbox } from 'antd';
import { Icon, IconName } from '../../../../../../Components/UI';

export interface IDisciplinaItem {
  checked?: boolean;
  icon: IconName;
  disciplina: string;
  disabled?: boolean;
  onCheck: () => void;
}

const DisciplinaItem: FC<IDisciplinaItem> = ({ disciplina, icon, checked, disabled, onCheck }) => {
  return (
    <section
      className={`${styles['contentWrapper']} ${checked ? styles['disciplinaSelected'] : ''} ${disabled ? styles['disciplinaDisabled'] : ''}`}
      onClick={!disabled ? onCheck : undefined}>
      <Checkbox checked={checked} disabled={disabled} />
      <span className={styles['iconWrapper']}><Icon icon={icon} customSize={22} color='cinzaPadrao' /></span>
      <span className={styles['disciplinaTitle']}>{disciplina}</span>
    </section>
  );
};

export default DisciplinaItem;
