import { store } from 'Store';
import { AuthActions } from 'Store/Auth/Auth.actions';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  EXPIRES_AT_KEY,
  ISSUED_DATETIME_KEY,
  EMAIL_KEY,
  NOME_KEY,
  USER_IMAGE_KEY,
  CURRENT_TENANT_KEY,
  USER_ID_KEY,
  CURRENT_IS_FREEMIUM,
  IS_REGISTER_COMPLETE,
  TELEFONE_KEY,
  CURRENT_ROLE_FK,
  USER_COMPANY_POSITION_ID,
  USER_COMPANY_POSITION_TYPE,
  USER_HASH,
  TENANT_PLAN,
} from '../Store/Auth/Auth.constants';
import AppStorage from './AppStorage';

export class AuthUtils {
  static getLoggedUser(): ILoggedUser {
    return {
      accessToken: AppStorage.GetItem(ACCESS_TOKEN_KEY) || undefined,
      refreshToken: AppStorage.GetItem(REFRESH_TOKEN_KEY) || undefined,
      expiresIn: Number(AppStorage.GetItem(EXPIRES_AT_KEY)) || undefined,
      issuedDatetime: AppStorage.GetItem(ISSUED_DATETIME_KEY) || undefined,
      email: AppStorage.GetItem(EMAIL_KEY) || undefined,
      nome: AppStorage.GetItem(NOME_KEY) || undefined,
      telefone: AppStorage.GetItem(TELEFONE_KEY) || undefined,
      userImage: AppStorage.GetItem(USER_IMAGE_KEY) || undefined,
      currentTenant: Number(AppStorage.GetItem(CURRENT_TENANT_KEY, 'session') || Number(AppStorage.GetItem(CURRENT_TENANT_KEY))) || undefined,
      currentRoleFk: Number(AppStorage.GetItem(CURRENT_ROLE_FK)) || undefined,
      userId: AppStorage.GetItem(USER_ID_KEY) || undefined,
      currentIsFreemium: AppStorage.GetItem(CURRENT_IS_FREEMIUM) === 'true',
      isRegisterComplete: AppStorage.GetItem(IS_REGISTER_COMPLETE) === 'true',
      companyPositionId:
        Number(AppStorage.GetItem(USER_COMPANY_POSITION_ID)) || undefined,
      companyPositionType:
        Number(AppStorage.GetItem(USER_COMPANY_POSITION_TYPE)) || undefined,
      userHash: AppStorage.GetItem(USER_HASH) || undefined,
      plan: AppStorage.GetItem(TENANT_PLAN) || undefined
    };
  }

  static setLoggedUser(user: ILoggedUser) {
    AppStorage.SetItem(ACCESS_TOKEN_KEY, user.accessToken || '');
    AppStorage.SetItem(REFRESH_TOKEN_KEY, user.refreshToken || '');
    AppStorage.SetItem(EXPIRES_AT_KEY, user.expiresIn?.toString() || '');
    AppStorage.SetItem(ISSUED_DATETIME_KEY, new Date().toISOString());
    AppStorage.SetItem(EMAIL_KEY, user.email || '');
    AppStorage.SetItem(NOME_KEY, user.nome || '');
    AppStorage.SetItem(TELEFONE_KEY, user.telefone || '');
    AppStorage.SetItem(USER_IMAGE_KEY, user.userImage || '');
    AppStorage.SetItem(CURRENT_TENANT_KEY, user.currentTenant?.toString() || '');
    AppStorage.SetItem(CURRENT_TENANT_KEY, user.currentTenant?.toString() || '', 'session');
    AppStorage.SetItem(CURRENT_ROLE_FK, user.currentRoleFk?.toString() || '');
    AppStorage.SetItem(USER_ID_KEY, user.userId?.toString() || '');
    AppStorage.SetItem(CURRENT_IS_FREEMIUM, user.currentIsFreemium?.toString() || '');
    AppStorage.SetItem(IS_REGISTER_COMPLETE, user.isRegisterComplete?.toString() || '');
    AppStorage.SetItem(
      USER_COMPANY_POSITION_ID,
      user.companyPositionId?.toString() || ''
    );
    AppStorage.SetItem(
      USER_COMPANY_POSITION_TYPE,
      user.companyPositionType?.toString() || ''
    );
    AppStorage.SetItem(USER_HASH, user.userHash?.toString() || '');
    AppStorage.SetItem(TENANT_PLAN, user.plan || '');
  }

  static logout() {
    AuthUtils.setLoggedUser({} as ILoggedUser);
    store.dispatch(AuthActions.userLogout());
    AppStorage.SetRememberMe(false);
  }
}

export interface ILoggedUser {
  accessToken?: string;
  refreshToken?: string;
  issuedDatetime?: string;
  email?: string;
  nome?: string;
  telefone?: string;
  userImage?: string;
  expiresIn?: number;
  currentTenant?: number;
  userId?: string;
  currentIsFreemium?: boolean;
  isRegisterComplete?: boolean;
  currentRoleFk?: number;
  companyPositionId?: number;
  companyPositionType?: number;
  userHash?: string;
  plan?: string;
}
