import React, { FC, useEffect } from 'react';

import styles from './TableActionTopbar.module.scss';

function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTimeout(() => {
            callback();
          }, 200);
        }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}

export interface IDropdown {
  dropdownContent: React.ReactNode;
  toggleDropdown: () => void;
}

const DropdownActionTopbar: FC<IDropdown> = ({ dropdownContent, toggleDropdown }) => {
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, toggleDropdown);

  return (
    <div ref={wrapperRef} className={styles['dropdownMenu']}>
      {typeof dropdownContent === 'function' ? dropdownContent(toggleDropdown) : dropdownContent}
    </div>
  );
};

export default DropdownActionTopbar;
