import * as Yup from 'yup';

const schema = Yup.object().shape({
  Password:Yup.string()
    .required('O campo deve ser preenchido'),
});
export default schema;

export const schemaPassword = Yup.object().shape({
  NewPassword: Yup.string()
    .min(8, '8 caracteres')
    .matches(/[a-z]/, 'Letra minúscula')
    .matches(/[A-Z]/, 'Letra maiúscula')
    .matches(/[0-9]/, 'Número'),

  ConfirmNewPassword: Yup.string()
    .oneOf([Yup.ref('$newPassword'), undefined], 'As senhas devem ser iguais'),
});

