import { FC } from 'react';
import { Icon } from 'Components/UI';
import { MenuDropdown, MenuItemStyled } from './styles';

export interface IMoreFiltersActions {
  onClear: () => void;
  onCancel: () => void;
}

const MoreFiltersActions: FC<IMoreFiltersActions> = ({ 
  onClear, 
  onCancel,
}) => {
  const handleAction = () => {
    onClear();
    onCancel();
  }

  return (
    <MenuDropdown>
      <MenuItemStyled onClick={handleAction}>
        <Icon 
          icon="eraseFilter"
          customSize={16}
          className="clearFilters"
        />
        <span className="textItem">
          Redefinir filtros
        </span>
      </MenuItemStyled>
    </MenuDropdown>
  );
};

export default MoreFiltersActions;
