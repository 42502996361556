import { IGlobalReducerState } from '../Base/interface/IGlobalReducerState';

const getDomain = (state: IGlobalReducerState) => state.multiDomain;
const getTheme = (state: IGlobalReducerState) => state.multiDomain.theme;
const getDefaultInfoTheme = (state: IGlobalReducerState) => state.multiDomain.defaultInfo;
const getDomainLoading = (state: IGlobalReducerState) => state.multiDomain.isLoading;
const getImgUploadState = (state: IGlobalReducerState) => state.multiDomain.imgUploadState;

export { 
  getDomain, 
  getTheme, 
  getDefaultInfoTheme, 
  getDomainLoading, 
  getImgUploadState,
};
