import styled from "styled-components";

export const MenuDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 43px;
  z-index: 2;
  width: 200px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  box-shadow: 0px 6px 16px -2px rgba(0, 0, 0, 0.12);
`;

export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
  padding-right: 12px;
  cursor: pointer;

  .clearFilters {
    margin-right: 10px;
    color: ${({theme}) => theme.colors.secondary.onSecondaryVariant} !important;
  }

  .textItem {
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  &:hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &:active {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
  }
`
