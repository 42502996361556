import { IBreadCrumbItem } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { NotificationActionEnum } from "Data/enums/Notification";

import { INotificationsMapAux } from "Data/interfaces/Notification/INotificationsMapAux";
import { DisciplineIconsEnum } from "Pages/Disciplinas";
import { IconName } from "Components/UI";
import { TypeIconsEnum } from "Data/interfaces/Activities/IDragAndDropDataFormat";

export const GetDisciplineApiFolderIdFromBreadCrumb = (breadCrumbItem: IBreadCrumbItem) => {
    let disciplineApiFolderId: string;
    let breadCrumbAux: IBreadCrumbItem | undefined = breadCrumbItem;

    do {
        disciplineApiFolderId = breadCrumbAux.Identifier;
        breadCrumbAux = breadCrumbAux.PreviousItem;
    } while (breadCrumbAux && breadCrumbAux != null && breadCrumbAux?.Identifier != null);

    return disciplineApiFolderId;
}

export const mapNotification = (notifications: INotificationsMapAux[], uniqueId?: boolean) => {
  const allowedActions = [NotificationActionEnum.ArquivoMovido,
     NotificationActionEnum.NovoArquivo, NotificationActionEnum.NovaVersaoArquivo];
  
  const items = notifications.map((item: INotificationsMapAux) => {
    if (allowedActions.includes(item.Action) && !item.hasRead) {
      return uniqueId ? item.ItemId : item.ItemsAux;
    }

    return []
  }).flat();

  

  return items;
}

export const getDisciplineIcon = (name: string): IconName => {
  if ((DisciplineIconsEnum as any)[name]) {
    return (DisciplineIconsEnum as any)[name];
  }
  return 'menuContexto'; 
}

export const getTypeIcon = (name: string): IconName => {
  if ((TypeIconsEnum as any)[name]) {
    return (TypeIconsEnum as any)[name];
  }
  return 'activities'; 
}

export const obraAvatarImagesAccepts = [
  'image/apng',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
];

export const maxObraAvatarUploadSize = 1024 * 1024 * 128;
