import React, { FC, useEffect, useMemo } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { ITenantAdm } from "Data/interfaces/Tenant/ITenantAdm";
import { IOptionData } from "Components/UI/SelectForm";
import styles from "./TenantAdminChange.module.scss";
import { ITenantUsersResponse } from "Data/interfaces/Tenant/ITenantUsersResponse";
import { Dispatch } from "redux";
import { AdmActions } from "Store/Adm/Adm.actions";
import { IUpdateTenantAdminRequest } from "Data/interfaces/Tenant/IUpdateTenantAdminRequest";

interface ITenantAdminChange {
  visible: boolean;
  onCancel: () => void;
  tenant: ITenantAdm | null;
  tenantUsers?: ITenantUsersResponse[];
  dispatch: Dispatch;
  loading?: boolean;
} 

const TenantAdminChange: FC<ITenantAdminChange> = ({
  visible,
  onCancel,
  tenant,
  tenantUsers,
  dispatch,
  loading,
}) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [newAdmin, setNewAdmin] = React.useState('');
  const [sentChange, setSentChange] = React.useState(false);

  const options: IOptionData[] = useMemo(() => {
    const newOptions: IOptionData[] = [];
    if (tenantUsers?.length) {
      tenantUsers.map(user => {
        return newOptions.push({
          value: user.UserFk,
          description: user.User.Email,
          label: user.User.Email || ''
        })
      })
    }
    return newOptions;
  }, [tenantUsers]);

  const buttons = (
    <>
      <Button
        type="default"
        onClick={() => handleCancel()}
        >
        Cancelar
      </Button>
      <Button
        type="primary"
        onClick={() => setShowConfirmModal(true)}
      >
        Confirmar
      </Button>
    </>
  );

  const buttonsConfirm = (
    <>
      <Button
        type="default"
        onClick={() => setShowConfirmModal(false)}
        >
        Cancelar
      </Button>
      <Button
        type="primary"
        onClick={() => handleConfirmChange()}
        loading={loading}
      >
        Confirmar
      </Button>
    </>
  );

  useEffect(() => {
    if (sentChange && !loading) {
      handleCancel();
    }
  }, [loading, sentChange]);

  const handleSelect = (e: any) => {
    setNewAdmin(e.target.value);
  } 

  const handleCancel = () => {
    setNewAdmin('');
    setShowConfirmModal(false);
    setSentChange(false);
    onCancel();
  }

  const handleConfirmChange = () => {
    setSentChange(true);
    const newTenantAdmin = tenantUsers?.find(user => user.UserFk === newAdmin); 
    if (tenant && tenant.Tenant.TenantId && newTenantAdmin) {
      const request: IUpdateTenantAdminRequest = {
        tenantId: tenant.Tenant.TenantId,
        newAdminUserId: newAdmin,
      };
      dispatch(AdmActions.updateTenantAdmin(request));
    }
  }

  return (
    <Modal
      title="Trocar Admin do Tenant"
      visible={visible}
      onCancel={handleCancel}
      width={465}
      footerButtons={buttons} 
    >
      <div className={styles['wrapper']}>
        <div>
          {(tenant?.UserAdminName !== tenant?.UserAdminEmail) && (
            <p>Nome administrador atual: <b>{tenant?.UserAdminName}</b></p>
          )}
          <p>E-mail administrador atual: <b>{tenant?.UserAdminEmail}</b></p>

          <div className={styles['field-wrapper']}>
            <small>Escolha novo administrador</small>
            <select
              placeholder="Método Pagamento"
              name="paymentMethod"
              className={styles['selectRole']}
              onChange={handleSelect}
            >
              <option value="">Selecione novo administrador</option>
              {options.map(item => (
                <option value={item.value}>{item.description}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <Modal
          title="Atenção! Confirmar Troca do Admin"
          visible={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          footerButtons={buttonsConfirm} 
        >
          <div className={styles['wrapper']}>
            <div className={styles['danger']}>
              <p>
                Atenção: cuidando ao confirmar! Será alterado o Admin da conta! 
              </p>
            </div>
            <p>Novo administrador: <b>{tenantUsers?.find(user => user.UserFk === newAdmin)?.User.Email}</b></p>
          </div>
        </Modal>
      )}
    </Modal>
  )
};

export default TenantAdminChange;
