import React from 'react';

import { toast } from 'react-toastify';
import { Toast } from '../Components/UI/Toast/Toast';

export interface IError {
  title: string;
  description: string;
  icon: 'success' | 'error' | 'warning' | 'default' | 'atualizar';
  isGlobal?: boolean;
  info?: boolean;
  locationInfo?: string;
  timeRequest?: boolean;
  custom?: boolean;
  customAction?: () => void;
}

export class ToastHandler {
  ToastList = new Set<IError>();
  MaxToast = 7;

  handler(error: IError): void {
    if (error.isGlobal) {
      return;
    }

    this.sendToast(error);
  }

  showError(message: string, title: string = '', info?: boolean, locationInfo?:string) {
    this.sendToast({description: message, title, icon: 'error', info, locationInfo});
  }
  
  showSuccess(message: string, title: string = '') {
    this.sendToast({description: message, title, icon: 'success'});
  }

  showInfo(message: string, title: string = '') {
    this.sendToast({description: message, title, icon: 'atualizar', custom: true });
  }
  
  showWarningRequest(title: string, message: string = '') {
    this.sendToast({description: message, title, icon: 'warning', isGlobal: true, timeRequest: true});
  }

  showActivitieHasUpdate(message: string, title: string = '', customAction?: () => void) {
    this.sendToast({description: message, title, icon: 'atualizar', customAction });
  }

  externalDeleteUpdateToast() {
    toast.dismiss();
  }

  private sendToast(error: IError) {
    const isUpdate = error.icon === 'atualizar';
    if (isUpdate && !error.custom) {
      this.MaxToast = 1;
    }

    if (this.ToastList.size < this.MaxToast) {
      const isError = error.icon === 'error' ;
      const isWarning = error.timeRequest;

      const defaultAction = (id1: any) => {
        this.ToastList.delete(id1);
      }

      const handleCustomAction = (id1: any, customAction?: () => void) => {
        if(customAction) customAction();
        
        this.ToastList.delete(id1);
      }

      const id1: any = toast[isError ? 'error' : isUpdate ? 'info' : (isWarning ? 'warn' : 'success')](
        <Toast
          title={error.title}
          description={error.description}
          error={isError ? true : false}
          info={error.info}
          timeRequest={isWarning}
          locationInfo={error.locationInfo}
          iconCustom={isUpdate ? 'atualizar' : undefined}
          iconCustomColor={isUpdate ? '#0081FF' : undefined}
          onCustomAction={() => handleCustomAction(id1, error.customAction)}
        />,
        {
          className: `${isWarning ? 'WarningClass' : isUpdate ? 'UpdateClass' : ''}`,
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: (isUpdate && !error.custom) ? false : 5000,
          closeButton: true,
          closeOnClick: error.info === true ? false : true,
          onClose: () => defaultAction(id1),
        },
      );

      this.ToastList.add(id1);
    }
  }
}

const instance = new ToastHandler();

export default instance;
