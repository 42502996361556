import React, { FC } from 'react';

import styles from './DownloadFiles.module.scss';
import { Icon } from '../Icon';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import imageLoading from '../../../Static/images/loading.svg';

import ListContainer from '../Containers/ListContainer/ListContainer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const DownloadFiles: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [collapse, setCollapse] = React.useState(false);
  const toggle = () => { setCollapse(!collapse); };
  
  return (
    <div className={styles[`${props.uploadFileList?.length ? 'uploadFileWrapperAndUpload' : 'uploadFileWrapper'}`]} hidden={!(props.isDownloading || props.isDownloadingReport)}>
      <div className={styles['headerUploadWrapper']}>
        <span className={styles['title']} onClick={toggle}>Download de arquivos</span>
        <div onClick={toggle}>
          <Icon
            icon={collapse ? 'avancar' : 'expandir'}
            customSize={10}
            customColor='#44566C' 
          />
        </div>
      </div>
      <div className={styles['listFilesWrapper']} hidden={collapse}>
        <ListContainer height={'auto'}>
          <div className={styles['fileItemWrapper']}>
            <div className={styles['fileItemDescriptionWrapper']} >
              <Icon
                icon='arquivar'
                color='cinzaPadrao'
                customSize={24}
              />
              <div className={styles['filenameAndEditWrapper']} >
                <div className={styles['filenameWrapper']}>
                  <span className={styles['fileName']}>
                    {
                      (props.isDownloading || props.isDownloadingReport) && ( (props.loaded == 0 && props.total == 1000) || (props.loadedReport == 0 && props.totalReport == 1000))
                      ? <> Iniciando o download...</>
                      : <>
                        {props.isDownloading ? props.downloadFileList?.itemsId.length : '1'} arquivo(s).
                      </>
                    }
                  </span>
                </div>
              </div>
              <div>
                <CircularProgressbar
                  className={styles['loading']}
                  strokeWidth={14}
                  styles={buildStyles({ pathColor: '#3547C8', trailColor: '#EDEFF2' })}
                  value={props.isDownloading ? props.loaded / props.total : props.loadedReport / props.totalReport }  
                  maxValue={1}
                />
              </div>
            </div>
          </div>
        </ListContainer>
      </div>
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.listagemArquivos,
  ...state.upload,

  isDownloading: state.listagemArquivos.isDownloading,
  loaded: state.listagemArquivos.progressDownload?.loaded || 0,
  total: state.listagemArquivos.progressDownload?.total || 1000,

  isDownloadingReport: state.plotagem.isDownloading,
  loadedReport: state.plotagem.progressDownload?.loaded || 0,
  totalReport: state.plotagem.progressDownload?.total || 1000,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(DownloadFiles);
