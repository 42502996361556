import styled, { css } from "styled-components";

interface IWrapperStyled {
  isCollapsed?: string;
  isChildren?: string;
  active?: string;
}

export const WrapperStyled = styled.div<IWrapperStyled>`

  ${({isCollapsed}) => isCollapsed === 'true' && css`
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    transition: all 0.3s ease-out;
    border: none;
    z-index: 1;
    overflow-y: visible;
  `}

  .defaultMenuItem {
    display: flex;
    justify-content: left;
    min-height: 45px;
    height: 45px;
    align-items: center;
    background-color: ${({theme}) => theme.colors.surface.surface};
    border-left: 4px solid transparent !important;
    user-select: none;
    cursor: pointer;
 
    .defaultIcon {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      padding-left: 25px;
      padding-right: 25px;
    }

    .avatar {
      padding-left: 12px;
      padding-right: 12px;
    }

    p {
      margin-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .defaultIconToggleWrap {
      padding: 0 10px;

      .defaultIconChildren {
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }

    .iconPremium {
      width: 18px;
      position: relative;
      left: -14px;
    }

    .titleMenu {
      flex: 1;
      margin: 0 !important;
      height: 18px;
      display: flex;
      align-items: center;
    }

    :hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
      border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;

      .defaultIcon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }
    }

    ${({isCollapsed}) => isCollapsed === 'true' && css`
      height: 45px;
      display: block;

      p {
        visibility: hidden;
      }

      .children {
        visibility: hidden;
      }

      .defaultIcon {
        text-align: center;
        padding-top: 25px;
        padding-left: 22px;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-height: 820px) {
        .defaultIcon {
          padding-top: 25px;
        }
      }
    `}

    ${({active}) => active === 'true' && css`
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
      border-left: 4px solid ${({theme}) => theme.colors.primary.primary} !important;

      .defaultIcon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }

      > p {
        font-weight: bold;
      }

      .defaultIconToggleWrap {

        .defaultIconChildren {
          transform: rotate(90deg);
        }
      }
    `}

    ${({isChildren}) => isChildren === 'true' && css`
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer} !important;
      border-left: 4px solid transparent !important;

      :hover {
        background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
        border-left: 4px solid transparent !important;
      }
  
      .defaultIcon {
        font-size: 8px !important;
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    `}

    ${({isChildren, active}) => isChildren === 'true' && active === 'true' && css`
      .defaultIcon {
        color: ${({theme}) => theme.colors.primary.primary} !important;
      }
    `}
  }
`;