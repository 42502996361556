import { FC } from 'react'
import Highlighter from 'react-highlight-words';
import Ellipsis from '../Ellipsis';
import styles from './HighlightWords.module.scss';

export interface IHighlighterWords {
  text: string;
  wordSearched: string;
  className?: string;
  customColor?: string;
  maxLength?: number;
  alwaysLimited?: boolean;
}

const HighlighterWords: FC<IHighlighterWords> = ({
  text,
  wordSearched,
  className,
  customColor,
  maxLength,
  alwaysLimited,
}) => {

  if (!wordSearched && maxLength) {
    return (
      <Ellipsis
        phrase={text}
        maxLength={maxLength}
        className={className ? className : styles['defaultText']}
      />
    )
  }

  if (!wordSearched) {
    return (
      <div className={className ? className : styles['defaultText']}>
        {text}
      </div>
    )
  }

  let newText = text ? text.toString() : '';
  if (alwaysLimited && maxLength && newText.length > maxLength) {
    newText = `${newText.slice(0, maxLength - 3)}...`;
  }

  return (
    <Highlighter
      highlightStyle={{
        backgroundColor: customColor ? customColor : '#FAFF0080',
        padding: '1px',
        borderRadius: '3px',
      }}
      searchWords={[wordSearched]}
      autoEscape
      className={className}
      highlightClassName={className}
      textToHighlight={newText}
    />
  )
}

export default HighlighterWords;
