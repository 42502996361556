import { IGlobalReducerState } from '../Base/interface/IGlobalReducerState';

const getAdmTenantDomain = (state: IGlobalReducerState) => state.adm.tenantPersonalization;
const getAdmTenantDomainLoading = (state: IGlobalReducerState) => state.adm.tenantPersonalizationLoading;
const getAdmError = (state: IGlobalReducerState) => state.adm.error;

export { 
  getAdmTenantDomain,
  getAdmTenantDomainLoading,
  getAdmError,
};
