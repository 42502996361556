import { FC, useState } from "react";
import { Calendar } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Icon } from "Components/UI";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import {
  DateComponentWrapper,
  InternalDatePicker,
  RowHeader,
  TextDate,
} from "./styles";

interface IDatePickerInline {
  isDoubleDate?: boolean;
  firstDate?: Dayjs;
  firstDateSelected?: boolean;
  secondDate?: Dayjs;
  secondDateSelected?: boolean;
  onSelect: (value: string) => void;
}

const DatePickerInline: FC<IDatePickerInline> = ({
  isDoubleDate,
  firstDate,
  firstDateSelected,
  secondDate,
  secondDateSelected,
  onSelect,
}) => {
  const theme = useSelector(getTheme);

  const [month, setMonth] = useState(0);

  const handleSelect = (value: Dayjs) => {
    onSelect(value.format('YYYY-MM-DD'));
  };

  return (
    <InternalDatePicker>
      <Calendar
        fullscreen={false}
        locale={locale}
        mode="month"
        headerRender={({ value, type, onChange }) => {
          setMonth(value.month());
          const localDate = value.clone().locale('pt-br');
          const currentMonth = localDate.format('MMM');
          const currentYear = localDate.format('YYYY');

          return (
            <div style={{ padding: 8 }}>
              <RowHeader>
                <Icon
                  icon="retroceder"
                  customSize={16}
                  customColor={theme.colors.surface.onSurfacePlaceholder}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const now = localDate.month(localDate.month() - 1);
                    onChange(now);
                  }}
                />
                <TextDate><span className="month">{currentMonth}</span>{` de ${currentYear}`}</TextDate>
                <Icon
                  icon="avancar"
                  customSize={16}
                  customColor={theme.colors.surface.onSurfacePlaceholder}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const now = localDate.month(localDate.month() + 1);
                    onChange(now);
                  }}
                />
              </RowHeader>
            </div>
          );
        }}
        fullCellRender={(date, info) => {
          const iscurrentmonth = date.month() === month;
          const isSelected = date.isSame(firstDate, 'day') ||
            (isDoubleDate && date.isSame(secondDate, 'day'));
          const isRange = isDoubleDate &&
            firstDate &&
            secondDate &&
            date.isAfter(firstDate) &&
            date.isBefore(secondDate);

          return (
            <DateComponentWrapper
              iscurrentmonth={`${iscurrentmonth}`}
              isselected={`${isSelected}`}
              isrange={`${isRange}`}
              onClick={() => handleSelect(date)}
            >
              {info.originNode}
            </DateComponentWrapper>
          )
        }}
        disabledDate={isDoubleDate
          ? (date) => {
            if ((firstDate && secondDateSelected)) {
              if (date.isBefore(firstDate)) {
                return true
              }
            }
            if (secondDate && firstDateSelected) {
              if (date.isAfter(secondDate)) {
                return true
              }
            }
            return false
          }
          : undefined
        }
      />
    </InternalDatePicker>
  );
}

export default DatePickerInline;
