import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
`;

interface IInfoWrapper {
  borderRadius?: number;
}

export const InfoWrapper = styled.div<IInfoWrapper>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({borderRadius}) => borderRadius || 0}px;
  border: 1px dashed ${({theme}) => theme.colors.primary.primary};
  background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.primary.primary}, ${theme.colors.surface.surface})`};
  opacity: 0.5;

  * {
    pointer-events: none;
  }
`;
