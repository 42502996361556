import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoDrop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .iconInfoDrop{
    color: ${({theme}) => theme.colors.primary.primary} !important;
  }

  .infoDropText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.primary.primary};
    margin-top: 12px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  .uploadBtn {
    display: flex;
    align-items: center;
    height: 28px !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    .uploadIcon {
      color: ${({theme}) => theme.colors.primary.onPrimary} !important;
      margin-right: 6px;
    }

    :disabled {
      .uploadIcon {
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

interface IImgCard {
  isprogress?: string;
}

export const ImgCard = styled.div<IImgCard>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  margin-top: 12px;

  &+& {
    margin-top: 6px;
  }
  
  .image {
    position: relative;
    width: 96px;
    height: 72px;
    
    img {
      width: 96px;
      min-width: 96px;
      max-width: 96px;
      height: 72px;
      min-height: 72px;
      max-height: 72px;
      object-fit: contain;
      z-index: 1;
    }
    
    .progressBar {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      margin-bottom: 0;
      z-index: 2;
    }
  
    ${({isprogress}) => isprogress === 'true' && css`
      .progressMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
        z-index: 3;
      }
    `}
  }


  .imgContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-left: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
    padding: 0 16px;

    .imgTextContent {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .textName {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px; 
        color: ${({theme}) => theme.colors.surface.onSurface};
      }
      .textSize {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; 
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
        margin-top: 4px;
      }
    }

    .buttonCancelOrDelete {
      width: 28px !important;
      height: 28px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconCancelOrDelete {
        color: ${({theme}) => theme.colors.secondary.onSecondary} !important;
      }
    }
  }
`;
