import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IColorsTheme } from 'Static/styles/themes/default';
import { IUpdateTenantPersonalizationRequest } from 'Data/interfaces/MultiDomain/IUpdateMultiDomainPersonalization';
import { IMultiDomainPersonalizationResponse } from 'Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse';
import { IUploadPersonalization } from 'Data/interfaces/MultiDomain/IUploadPersonalization';
import { IUpdateUploadPersonalization } from 'Data/interfaces/MultiDomain/IUpdateUploadPersonalization';
import { ICheckDNSRequest } from 'Data/interfaces/MultiDomain/ICheckDNSRequest';

export enum MultiDomainActionKeys {
  MULTI_DOMAIN_REQUEST = 'MULTI_DOMAIN_REQUEST',
  MULTI_DOMAIN_SUCCESS = 'MULTI_DOMAIN_SUCCESS',
  MULTI_DOMAIN_FAILURE = 'MULTI_DOMAIN_FAILURE',

  TENANT_PERSONALIZATION_REQUEST = 'TENANT_PERSONALIZATION_REQUEST',
  TENANT_PERSONALIZATION_SUCCESS = 'TENANT_PERSONALIZATION_SUCCESS',
  TENANT_PERSONALIZATION_FAILURE = 'TENANT_PERSONALIZATION_FAILURE',

  UPDATE_TENANT_PERSONALIZATION_REQUEST = 'UPDATE_TENANT_PERSONALIZATION_REQUEST',
  UPDATE_TENANT_PERSONALIZATION_SUCCESS = 'UPDATE_TENANT_PERSONALIZATION_SUCCESS',
  UPDATE_TENANT_PERSONALIZATION_FAILURE = 'UPDATE_TENANT_PERSONALIZATION_FAILURE',

  UPLOAD_TENANT_PERSONALIZATION_REQUEST = 'UPLOAD_TENANT_PERSONALIZATION_REQUEST',
  UPLOAD_TENANT_PERSONALIZATION_SUCCESS = 'UPLOAD_TENANT_PERSONALIZATION_SUCCESS',
  UPLOAD_TENANT_PERSONALIZATION_FAILURE = 'UPLOAD_TENANT_PERSONALIZATION_FAILURE',
  UPDATE_UPLOAD_TENANT_PERSONALIZATION = 'UPDATE_UPLOAD_TENANT_PERSONALIZATION',

  CHECK_DNS_REQUEST = 'CHECK_DNS_REQUEST',
  CHECK_DNS_SUCCESS = 'CHECK_DNS_SUCCESS',
  CHECK_DNS_FAILURE = 'CHECK_DNS_FAILURE',

  TOOGLE_COLORS_THEME = 'TOOGLE_COLORS_THEME',
}

export const MultiDomainActions = {
  getMultiDomain: (request: string): GetMultiDomainAction =>
    createAction(MultiDomainActionKeys.MULTI_DOMAIN_REQUEST, request),
  getMultiDomainSuccess: (response: IMultiDomainPersonalizationResponse): GetMultiDomainSuccessAction =>
    createAction(MultiDomainActionKeys.MULTI_DOMAIN_SUCCESS, response),
  getMultiDomainFailure: (err: string): GetMultiDomainFailureAction =>
    createAction(MultiDomainActionKeys.MULTI_DOMAIN_FAILURE, err),

  getTenantPersonalization: (request: number): GetTenantPersonalizationAction =>
    createAction(MultiDomainActionKeys.TENANT_PERSONALIZATION_REQUEST, request),
  getTenantPersonalizationSuccess: (response: IMultiDomainPersonalizationResponse): GetTenantPersonalizationSuccessAction =>
    createAction(MultiDomainActionKeys.TENANT_PERSONALIZATION_SUCCESS, response),
  getTenantPersonalizationFailure: (err: string): GetTenantPersonalizationFailureAction =>
    createAction(MultiDomainActionKeys.TENANT_PERSONALIZATION_FAILURE, err),

  updateTenantPersonalization: (request: IUpdateTenantPersonalizationRequest): UpdateTenantPersonalizationAction =>  
    createAction(MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_REQUEST, request),
  updateTenantPersonalizationSuccess: (response: IMultiDomainPersonalizationResponse): UpdateTenantPersonalizationSuccessAction =>
    createAction(MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_SUCCESS, response),
  updateTenantPersonalizationFailure: (err: string): UpdateTenantPersonalizationFailureAction =>
    createAction(MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_FAILURE, err),

  uploadTenantPersonalization: (request: IUploadPersonalization): UploadTenantPersonalizationAction =>  
    createAction(MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_REQUEST, request),
  uploadTenantPersonalizationSuccess: (response: IMultiDomainPersonalizationResponse): UploadTenantPersonalizationSuccessAction =>
    createAction(MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_SUCCESS, response),
  uploadTenantPersonalizationFailure: (err: string): UploadTenantPersonalizationFailureAction =>
    createAction(MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_FAILURE, err),
  updateUploadTenantPersonalization: (request: IUpdateUploadPersonalization): UpdateUploadTenantPersonalizationAction =>  
    createAction(MultiDomainActionKeys.UPDATE_UPLOAD_TENANT_PERSONALIZATION, request),

  checkDNS: (request: ICheckDNSRequest): CheckDNSAction =>
    createAction(MultiDomainActionKeys.CHECK_DNS_REQUEST, request),
  checkDNSSuccess: (response: IMultiDomainPersonalizationResponse): CheckDNSSuccessAction =>
    createAction(MultiDomainActionKeys.CHECK_DNS_SUCCESS, response),
  checkDNSFailure: (err: string): CheckDNSFailureAction =>
    createAction(MultiDomainActionKeys.CHECK_DNS_FAILURE, err),

  toogleTheme: (theme: IColorsTheme): ToogleThemeAction =>
    createAction(MultiDomainActionKeys.TOOGLE_COLORS_THEME, theme),
};

export type MultiDomainUnion = ActionsUnion<typeof MultiDomainActions>;

export type GetMultiDomainAction = 
  Action<MultiDomainActionKeys.MULTI_DOMAIN_REQUEST, string>;
export type GetMultiDomainSuccessAction = 
  Action<MultiDomainActionKeys.MULTI_DOMAIN_SUCCESS, IMultiDomainPersonalizationResponse>;
export type GetMultiDomainFailureAction = 
  Action<MultiDomainActionKeys.MULTI_DOMAIN_FAILURE, string>;

export type GetTenantPersonalizationAction = 
  Action<MultiDomainActionKeys.TENANT_PERSONALIZATION_REQUEST, number>;
export type GetTenantPersonalizationSuccessAction = 
  Action<MultiDomainActionKeys.TENANT_PERSONALIZATION_SUCCESS, IMultiDomainPersonalizationResponse>;
export type GetTenantPersonalizationFailureAction = 
  Action<MultiDomainActionKeys.TENANT_PERSONALIZATION_FAILURE, string>;

export type UpdateTenantPersonalizationAction = 
  Action<MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_REQUEST, IUpdateTenantPersonalizationRequest>;
export type UpdateTenantPersonalizationSuccessAction = 
  Action<MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_SUCCESS, IMultiDomainPersonalizationResponse>;
export type UpdateTenantPersonalizationFailureAction = 
  Action<MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_FAILURE, string>;

export type UploadTenantPersonalizationAction = 
  Action<MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_REQUEST, IUploadPersonalization>;
export type UploadTenantPersonalizationSuccessAction = 
  Action<MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_SUCCESS, IMultiDomainPersonalizationResponse>;
export type UploadTenantPersonalizationFailureAction = 
  Action<MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_FAILURE, string>;
export type UpdateUploadTenantPersonalizationAction = 
  Action<MultiDomainActionKeys.UPDATE_UPLOAD_TENANT_PERSONALIZATION, IUpdateUploadPersonalization>;

export type CheckDNSAction = 
  Action<MultiDomainActionKeys.CHECK_DNS_REQUEST, ICheckDNSRequest>;
export type CheckDNSSuccessAction = 
  Action<MultiDomainActionKeys.CHECK_DNS_SUCCESS, IMultiDomainPersonalizationResponse>;
export type CheckDNSFailureAction = 
  Action<MultiDomainActionKeys.CHECK_DNS_FAILURE, string>;

export type ToogleThemeAction =
  Action<MultiDomainActionKeys.TOOGLE_COLORS_THEME, IColorsTheme>;
