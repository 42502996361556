import React, { FC } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';

import styles from './ActionMenu.module.scss';
import { Icon } from '../../../Components/UI';

interface IActionMenu {
  disciplineId: string;
  editDiscipline: () => void;
}

const ActionMenu: FC<IActionMenu> = ({ children, disciplineId, editDiscipline }) => {

  return (
    <>
      <ContextMenuTrigger id={`ActionMenuDisciplina${disciplineId}`} >
        {children}
      </ContextMenuTrigger>
      <ContextMenu  id={`ActionMenuDisciplina${disciplineId}`} className={styles['ActionMenu']}>
        <MenuItem onClick={editDiscipline}>
          <Icon icon='editar' customColor='#44566C' size='xs' /> Editar disciplina
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default ActionMenu;
