import { FC } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import Button from '../Button';
import toastHandler from 'Utils/toastHandler';
import { imagesAccepts, imagesNotAccepts } from 'Utils/MultidomailUtils';

interface IUploadCropImg {
  aspect: number;
  onChange: (file: RcFile) => void;
}

const UploadCropImg: FC<IUploadCropImg> = ({
  aspect,
  onChange,
}) => {
  const handleBeforeUpload = (file: RcFile) => {
    if (imagesNotAccepts.includes(file.type)) {
      toastHandler.showError(`Formato de imagem('${file.type}') não suportado`);

    } else {
      onChange(file);
    }

    return false;
  };

  return (
    <ImgCrop 
      aspect={aspect}
      rotationSlider={false}
    >
      <Upload
        action={undefined}
        beforeUpload={handleBeforeUpload}
        maxCount={1}
        itemRender={() => false}
        accept={imagesAccepts}
      >
        <Button
          type="text"
        >
          Escolher um arquivo
        </Button>
      </Upload>
    </ImgCrop>
  );
};

export default UploadCropImg;
