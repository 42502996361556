import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 24px 32px;
`

export const Header = styled.div`
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: ${({theme}) => theme.colors.surface.onSurface};

  .headerTextBold {
    font-weight: 600;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

interface ICard {
  selected?: string;
}

export const Card = styled.div<ICard>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  cursor: pointer;

  &+& {
    margin-top: 6px;
  }

  .cardLeft {

  }

  .cardRight {
    margin-left: 16px;
    display: flex;
    flex-direction: column;

    .cardHeader {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .cardDescription {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  }

  ${({selected}) => selected === 'true' && css`
    border: 1px solid ${({theme}) => theme.colors.primary.primary};
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.primary.onPrimary})`};
  `}
`;

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .footerText {
    margin-top: 4px;
    margin-left: 4px;
    text-align: justify;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.danger.danger};
  }
`;

interface IButtonsWrapper {
  disabled?: string
}

export const ButtonsWrapper = styled.div<IButtonsWrapper>`
  .cancel {
    width: 123px;
    height: 37px;
    border: none !important;
    margin-right: 12px;

    &:hover {
      border: none !important;
    }
    &:active {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }

    .cancelText {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      color: ${({theme}) => theme.colors.information.information} !important;
    }
  }

  .confirm {
    width: 123px;
    height: 37px;
    border: none;
    border-radius: 5px;
    margin-right: 24px;

    .confirmText {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
    }
  }
`;
