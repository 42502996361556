import { IRevenuesState } from './interfaces/IRevenuesState';
import { RevenueActionKeys, RevenueActionUnion } from './Revenues.actions';

const initialState: IRevenuesState = {
  isLoading: false,
  cancelPageUpdateBillingData: false,
  showModalExitBillingData: false,
  changePlan: undefined,
  showWarningDowngradePlan: {
    showWarning: 0,
    billingData: undefined,
  },
  cancelPageDowngrade: false,
  showModalExitDowngrade: false,
  urlRedirect: undefined,
  userStoreItem: undefined,
  getDowngradeInfo: undefined,
  downgradeRequestLoading: false
}

const revenuesReducer = (
  state = initialState,
  action: RevenueActionUnion
) => {
  switch (action.type) {
    case RevenueActionKeys.GET_PLAN_FOR_CHANGE:
      return {
        ...state,
        changePlan: action.payload
      }

    case RevenueActionKeys.URL_REDIRECT:
      return {
        ...state,
        urlRedirect: action.payload
      }

    case RevenueActionKeys.CANCEL_PAGE_UPDATE_BILLING_DATA:
      return {
        ...state,
        cancelPageUpdateBillingData: action.payload
      }
    
    case RevenueActionKeys.SHOW_MODAL_EXIT_BILLING_DATA:
      return {
        ...state,
        showModalExitBillingData: action.payload
      }

    case RevenueActionKeys.CANCEL_PAGE_DOWNGRADE:
      return {
        ...state,
        cancelPageDowngrade: action.payload
      }
    
    case RevenueActionKeys.SHOW_MODAL_EXIT_DOWNGRADE:
      return {
        ...state,
        showModalExitDowngrade: action.payload
      }

    case RevenueActionKeys.SHOW_WARNING_DOWNGRADE_PLAN:
      return {
        ...state,
        showWarningDowngradePlan: action.payload
      }

    case RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_REQUEST:
      return {
        ...state,
        downgradeRequestLoading: true
      };
    case RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_SUCCESS:
      return {
        ...state,
        userStoreItem: action.payload,
        downgradeRequestLoading: false
      };
    case RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        downgradeRequestLoading: false
      };

    case RevenueActionKeys.CANCEL_UPDATE_PLAN_REQUEST:
      return {
        ...state,
        downgradeRequestLoading: true
      };
    case RevenueActionKeys.CANCEL_UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        userStoreItem: action.payload,
        downgradeRequestLoading: false
      };
    case RevenueActionKeys.CANCEL_UPDATE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        downgradeRequestLoading: false
      };

    case RevenueActionKeys.GET_DOWNGRADE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RevenueActionKeys.GET_DOWNGRADE_INFO_SUCCESS:
      return {
        ...state,
        getDowngradeInfo: action.payload,
        isLoading: false
      };
    case RevenueActionKeys.GET_DOWNGRADE_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    default:
      return state;
  }
}

export default revenuesReducer;
