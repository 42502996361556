import React, { FC, useEffect } from 'react';
import { useDispatch, connect, ConnectedProps } from 'react-redux';

import styles from './UpdateConta.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import Form from '../../../../Components/UI/Form/Form';
import Field from '../../../../Components/UI/Field';
import Select from '../../../../Components/UI/Select';
import UploadAvatar from '../../../../Components/UI/UploadAvatar';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import toastHandler from '../../../../Utils/toastHandler';

import { Icon } from '../../../../Components/UI';

import schema from './updateConta.schema'

import { IUserInfoResponse } from '../../../../Data/interfaces/MinhaConta/IUserInfoResponse';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import { MinhaContaActions } from '../../../../Store/MinhaConta/MinhaConta.actions';
import { IUpdateUserPersonalDataRequest } from '../../../../Data/interfaces/MinhaConta/IUpdateUserPersonalDataRequest';
import { stringToPhone, stringToCPF, stringToCNPJ, cleanMask, stringToCEP } from '../../../../Utils/MaskUtils';

interface IModal {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: (newPassword: string) => void;
  isLoading?: boolean;
  userInfos: IUserInfoResponse;
  companyPositions: any;
}

const UpdateConta: FC<IModal> = ({ visible, isLoading, onCancel, onConfirm, userInfos, companyPositions }) => {
  const dispatch = useDispatch();
  const positionsStudent = ["Estudante de Engenharia", "Estudante de Arquitetura", "Estagiário", 6, 13, 14];

  const [imageUser, setImageUser] = React.useState<string>(userInfos?.OriginalImageUrl);
  const [updateImageUser, setUpdateImageUser] = React.useState<boolean>(false);

  const [positionCompany, setPositionCompany] = React.useState<any>(userInfos.CompanyPosition?.Name);
  const [updatePositionCompany, setUpdatePositionCompany] = React.useState<boolean>(false);

  const [isSubmited, setIsSubmited] = React.useState(false);

  const onUploadImageUser = (uploadedImage: string) => {
    setImageUser(uploadedImage);
    setUpdateImageUser(true);
  };

  useEffect(() => {
    setImageUser(userInfos?.OriginalImageUrl);
    setPositionCompany(userInfos.CompanyPosition?.Name);
  }, [userInfos.CompanyPosition?.Name, userInfos?.OriginalImageUrl]);


  const setNewPositionCompany = (value: any) => {
    setPositionCompany(value);
    setUpdatePositionCompany(true);
  }

  const updateDadosConta = (data: IUpdateUserPersonalDataRequest) => {

    if (!positionCompany) {
      toastHandler.showError('Informe o cargo');
    }
    else if (data) {

      if (!updatePositionCompany) {
        data.AvatarFileName = imageUser == '' ? '' : imageUser?.substr(imageUser?.lastIndexOf("/") + 1);

        if(companyPositions[companyPositions.findIndex(function (obj: any, index: any) {
          if (obj.description == positionCompany)
            return obj.value;
        })] !== undefined){
          data.CompanyPositionId = companyPositions[companyPositions.findIndex(function (obj: any, index: any) {
            if (obj.description == positionCompany)
              return obj.value;
          })].value;
        }else{
          data.CompanyPositionId = 0
        }

      } else {
        data.CompanyPositionId = positionCompany;
      }
      dispatch(MinhaContaActions.updateDadosPessoais(data));
    }

    setIsSubmited(false);
    setUpdatePositionCompany(false);
    setUpdateImageUser(false);
  };

  const buttons = (
    <div>
      <Button height={37} onClick={onCancel}>
        <span style={{ padding: '0 20px' }}>
          Cancelar
        </span>
      </Button>
      <Button
        type='primary'
        height={37}
        onClick={() => setIsSubmited(true)}
        loading={isLoading}>
        <span style={{ padding: '0 18px' }}>
          Atualizar
        </span>
      </Button>
    </div>
  );

  return (
    <Modal title='Detalhes da conta' footerButtons={buttons} visible={visible} width={798} onCancel={onCancel}>
      <div className={styles['boxDetalhesConta']}>
        <div className={styles['DetalhesContaLeft']}>
          <UploadAvatar onUploadImage={onUploadImageUser}>
            {
              (isLoading: boolean) => {
                return (
                  <div className={styles['changeImageWrapper']} style={{
                    backgroundImage: imageUser
                      ? `linear-gradient(rgba(46,163,242, .6), rgba(46,163,242, .6)),
                          url('${updateImageUser ? process.env.REACT_APP_IMAGE_STORAGE_URL : ''}${imageUser}')`
                      : '',
                  }}>
                    {
                      isLoading
                        ? <Spin indicator={<LoadingOutlined rev=""color='white' style={{ fontSize: 40, color: 'white' }} />} />
                        : (<>
                          <Icon color='branco' customSize={19} icon='editarImg' />
                          <span className={styles['changeImageLabel']} >Alterar imagem</span>
                        </>)
                    }
                  </div>);
              }
            }
          </UploadAvatar>

        </div>
        <div className={styles['DetalhesContaRight']}>
          <div className={styles['boxData']}>
            <Form onSubmit={updateDadosConta} isSubmited={isSubmited} schema={schema} resetForm={true}>

              <Field className={styles['fields']}
                name='Nome'
                label='Nome'
                value={userInfos?.Nome}
                placeholder='Insira seu nome'
              />

              <Field className={styles['fields']}
                name='EmailAlternativo'
                label='E-mail alternativo'
                value={userInfos?.EmailAlternativo}
                placeholder='Insira seu e-mail alternativo'
              />

              <Field className={styles['fields']}
                name='Telefone'
                label='Telefone'
                value={stringToPhone(userInfos?.Telefone || "")}
                parse={value => value && stringToPhone(value)}
                maxLenght={15}
              />

              <Select
                size='large'
                onSelect={setNewPositionCompany}
                className={styles['selectPositionCompany']}
                placeholder='Qual seu cargo?'
                options={companyPositions}
                defaultValue={positionCompany}
              />


              <Field className={styles['fields']}
                style={{ visibility: positionsStudent.indexOf(positionCompany, 0) != -1 ? 'visible' : 'hidden', marginTop: '28px' }}
                name='Faculdade'
                label='Faculdade'
                value={userInfos?.Faculdade}
              />

            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(UpdateConta);
