import { Icon } from "Components/UI";
import { iconFileByFilename } from "Utils/Icons";
import {
  FileNameContainer,
  FileStatusValue,
  FilesContentContainer,
  FilesStatusContainer,
} from "../styles";
import { HistoryFileContentRecord } from "Data/interfaces/History/IHistoryFileRecord";
import { HistoryFileActionEnum } from "Data/interfaces/History/IHistoryFileApi";

export const filesContentOptions: HistoryFileContentRecord = {
  [HistoryFileActionEnum.StatusChanged]: ({ item }) => {
    const isOldBloqued = item.From?.DisplayValue?.toLowerCase()?.includes('bloqueado');
    const isNewBloqued = item.To?.DisplayValue?.toLowerCase()?.includes('bloqueado');

    return (
      <FilesContentContainer>
        <FilesStatusContainer>
          <span className="title">Status: </span>
          <FileStatusValue isbloqued={`${isOldBloqued}`} isold="true">
            {item.From?.DisplayValue}
          </FileStatusValue>
          <Icon
            icon="rightArrow"
            customSize={16}
            className="arrowIcon"
          />
          <FileStatusValue isbloqued={`${isNewBloqued}`}>
            {item.To?.DisplayValue}
          </FileStatusValue>
        </FilesStatusContainer>
        <FileNameContainer>
          <Icon
            icon={iconFileByFilename(item.ItemName)}
            customSize={12}
            className="assitantIcon"
          />
          <span className="assistantText">{item.ItemName}</span>
        </FileNameContainer>
      </FilesContentContainer>
    )
  },
  [HistoryFileActionEnum.FileDownloaded]: ({ item }) => {
    return (
      <FilesContentContainer>
        <FileNameContainer>
          <Icon
            icon={iconFileByFilename(item.ItemName)}
            customSize={12}
            className="assitantIcon"
          />
          <span className="assistantText">{item.ItemName}</span>
        </FileNameContainer>
      </FilesContentContainer>
    )
  },
  // [HistoryFileActionEnum.upload]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileMoveContainer>
  //         <div className="header">
  //           <FileNameContainer>
  //             <Icon
  //               icon={getDisciplineIcon(item.parent)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.parent}</span>
  //           </FileNameContainer>
  //         </div>
  //         <div className="footer">
  //           <CurveLink><div className="curve" /></CurveLink>
  //           <FileNameContainer>
  //             <Icon
  //               icon={iconFileByFilename(item.where)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.where}</span>
  //           </FileNameContainer>
  //         </div>
  //       </FileMoveContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.rename]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileNameContainer>
  //         <Icon
  //           icon={iconFileByFilename(
  //             typeof item?.newValue === 'string' ? item.newValue : ''
  //           )}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.newValue}</span>
  //       </FileNameContainer>
  //       <FileNameContainer isdisabled="true">
  //         <Icon
  //           icon={iconFileByFilename(
  //             typeof item?.oldValue === 'string' ? item.oldValue : ''
  //           )}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.oldValue}</span>
  //       </FileNameContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.obsolet]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileMoveContainer>
  //         <div className="header">
  //           <FileNameContainer>
  //             <Icon
  //               icon={getDisciplineIcon(item.parent)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.parent}</span>
  //           </FileNameContainer>
  //         </div>
  //         <div className="footer">
  //           <CurveLink><div className="curve" /></CurveLink>
  //           <FileNameContainer>
  //             <Icon
  //               icon={iconFileByFilename(item.where)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.where}</span>
  //           </FileNameContainer>
  //         </div>
  //       </FileMoveContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.revision]: ({ item }) => {

  //   return (
  //     <FilesContentContainer>
  //       <FilesStatusContainer>
  //         <span className="title">Revisão: </span>
  //         <FileStatusValue isbloqued="true" isold="true">
  //           {item.oldValue}
  //         </FileStatusValue>
  //         <Icon
  //           icon="rightArrow"
  //           customSize={16}
  //           className="arrowIcon"
  //         />
  //         <FileStatusValue isbloqued="true" isvariant="revision">
  //           {item.newValue}
  //         </FileStatusValue>
  //       </FilesStatusContainer>
  //       <FileNameContainer>
  //         <Icon
  //           icon={iconFileByFilename(item.where)}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.where}</span>
  //       </FileNameContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.description]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileDescriptionContainer>
  //         <span className="assistantText">{item?.newValue}</span>
  //       </FileDescriptionContainer>
  //       <FileDescriptionContainer isdisabled="true">
  //         <span className="assistantText">{item?.oldValue}</span>
  //       </FileDescriptionContainer>
  //       <FileNameContainer>
  //         <Icon
  //           icon={iconFileByFilename(item.where)}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.where}</span>
  //       </FileNameContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.delete]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileNameContainer isdisabled="true">
  //         <Icon
  //           icon={iconFileByFilename(item.where)}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.where}</span>
  //       </FileNameContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.move]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileMoveContainer>
  //         <div className="header">
  //           <FileNameContainer>
  //             <Icon
  //               icon="arquivar"
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.parent}</span>
  //           </FileNameContainer>
  //         </div>
  //         <div className="footer">
  //           <CurveLink><div className="curve" /></CurveLink>
  //           <FileNameContainer>
  //             <Icon
  //               icon={iconFileByFilename(item.where)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.where}</span>
  //           </FileNameContainer>
  //         </div>
  //       </FileMoveContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.render]: ({ item }) => {
  //   const isOldRendering = typeof item.oldValue === 'string'
  //     ? item.oldValue?.toLowerCase()?.includes('renderizando')
  //     : item.oldValue?.includes('renderizando');
  //   const isNewRendering = typeof item.newValue === 'string'
  //     ? item.newValue?.toLowerCase()?.includes('renderizando')
  //     : item.newValue?.includes('renderizando');

  //   return (
  //     <FilesContentContainer>
  //       <FilesStatusContainer>
  //         <span className="title">Visualização: </span>
  //         <FileStatusValue
  //           isbloqued={`${!item?.oldValue}`}
  //           isold="true"
  //           isvariant={isOldRendering ? 'render' : undefined}
  //         >
  //           {item.oldValue || 'Vazio'}
  //         </FileStatusValue>
  //         <Icon
  //           icon="rightArrow"
  //           customSize={16}
  //           className="arrowIcon"
  //         />
  //         <FileStatusValue isvariant={isNewRendering ? 'render' : undefined}>
  //           {item.newValue}
  //         </FileStatusValue>
  //       </FilesStatusContainer>
  //       <FileNameContainer>
  //         <Icon
  //           icon={iconFileByFilename(item.where)}
  //           customSize={12}
  //           className="assitantIcon"
  //         />
  //         <span className="assistantText">{item.where}</span>
  //       </FileNameContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.newFolder]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileMoveContainer>
  //         <div className="header">
  //           <FileNameContainer>
  //             <Icon
  //               icon={getDisciplineIcon(item.parent)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.parent}</span>
  //           </FileNameContainer>
  //         </div>
  //         <div className="footer">
  //           <CurveLink><div className="curve" /></CurveLink>
  //           <FileNameContainer>
  //             <Icon
  //               icon="arquivar"
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.where}</span>
  //           </FileNameContainer>
  //         </div>
  //       </FileMoveContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryFileActionEnum.deleteFolder]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileMoveContainer>
  //         <div className="header">
  //           <FileNameContainer>
  //             <Icon
  //               icon={getDisciplineIcon(item.parent)}
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.parent}</span>
  //           </FileNameContainer>
  //         </div>
  //         <div className="footer">
  //           <CurveLink><div className="curve" /></CurveLink>
  //           <FileNameContainer isdisabled="true">
  //             <Icon
  //               icon="arquivar"
  //               customSize={12}
  //               className="assitantIcon"
  //             />
  //             <span className="assistantText">{item.where}</span>
  //           </FileNameContainer>
  //         </div>
  //       </FileMoveContainer>
  //     </FilesContentContainer>
  //   )
  // },
};
