import styled from "styled-components";

export const Container = styled.div`
  padding: 27px 36px 23px 36px;
  display: flex;
  flex-direction: row;
  height: auto;
  overflow: auto;
`;

export const NomenclatureContainer = styled.div`
  padding: 25px;
  height: 100%;
  min-width: 700px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
`;

export const NomenclatureTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
`;

export const NomenclatureDivider = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin-top: 15px;
  margin-bottom: 24px;
`;

export const ObrasTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({theme}) => theme.colors.surface.onSurface};
  margin-bottom: 16px;
`;

export const ObrasContainer = styled.div`
  padding: 25px;
  width: 350px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
`;

export const HorizontalHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  margin: 24px 0;
`;

export const VerticalHr = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  height: auto;
  margin: 0 15px;
`;
