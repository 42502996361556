import * as Yup from 'yup';

const schema = Yup.object().shape({
  Name: Yup.string()
    .required('O campo deve ser preenchido'),

  Phone: Yup.string()
    .required('O campo deve ser preenchido'),

  StreetAddress: Yup.string()
    .required('O campo deve ser preenchido'),

  Zip: Yup.string()
    .required('O campo deve ser preenchido'),

  Number: Yup.number().typeError('Digite um número válido'),
  
  District: Yup.string()
    .required('O campo deve ser preenchido'),

  City: Yup.string()
    .required('O campo deve ser preenchido'),

  State: Yup.string()
    .required('O campo deve ser preenchido'),
});

export default schema;
