import { IGlobalReducerState } from '../Base/interface/IGlobalReducerState';
import { createSelector } from 'reselect';

const isLoggedSelector = (state: IGlobalReducerState) => state.auth.isLogged;

const isAuthenticatedSelector = createSelector(
  isLoggedSelector,
  (isLogged: boolean) => isLogged
);

const getUserInfo = (state: IGlobalReducerState) => state.auth.userInfo;

export { isAuthenticatedSelector, getUserInfo };
