import React, { FC } from "react";

import styles from "./ExclusaoPlanoForm.module.scss";

import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";

interface IExclusaoPlanos {
  visible?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirmDelete?: () => void;
}

const ExclusaoPlanoForm: FC<IExclusaoPlanos> = ({
  visible, isLoading, onCancel, onConfirmDelete
}) => {
  const buttons = (
    <div>
      <Button onClick={onCancel} >
        <span>Não, Cancelar exclusão</span>
      </Button>
      <Button 
        onClick={onConfirmDelete} 
        loading={isLoading} 
        type="primary"
      >
        <span>Sim, excluir Plano</span>
      </Button>
    </div>
  )
  
  return (
    <div>
      <Modal
        title="Exclusão de Plano"
        footerButtons={buttons}
        visible={visible}
        onCancel={onCancel}
      >
        <div className={styles['textWrap']}>
          <div className={styles['margin']}>
            'Atenção: a exclusão do Plano é realizada de forma permanente, 
            não sendo possível desfazer a ação.'
          </div>
          <div>Deseja continuar?</div>
        </div>
      </Modal>
    </div>
  )
}

export default ExclusaoPlanoForm;