import { FC, memo, useCallback, useEffect, useState } from "react";
import { IModelsTree } from "Data/interfaces/FederatedViewer/IModelsTree";
import { Tree } from 'antd';
import Ellipsis from "Components/UI/Ellipsis";
import { Icon } from "Components/UI";
import styles from './VirtualTreeView.module.scss';
import './VirtualTreeView.css';

interface IVirtualTreeView {
  result: IModelsTree;
  hideNodes: number[];
  showNodes: number[];
  selectedNode?: number | null;
  onToggleNode: (node: number, urn: string) => void;
  onToggleSelectNode: (node: number, urn: string) => void;
}

const VirtualTreeView: FC<IVirtualTreeView> = ({
  result,
  hideNodes,
  showNodes,
  selectedNode,
  onToggleNode,
  onToggleSelectNode,
}) => {
  const [treeData, setTreeData] = useState<any[]>([]);

  const hide = useCallback((itemId: number, selectedParent?: boolean) => {
    if (hideNodes.includes(itemId) && !showNodes.includes(itemId)) return true;
    if (selectedParent && !showNodes.includes(itemId)) return true;
    return false;
  }, [hideNodes, showNodes]);

  useEffect(() => {
    const worker = new Worker(new URL('./treeWorker.js', import.meta.url));
    worker.onmessage = (e) => {
      setTreeData(e.data);
    };

    worker.postMessage({
      level: 0,
      array: result.list[0]?.children || [],
      selectedParent: hideNodes.includes(result.list[0]?.id),
      hideNodes,
      showNodes,
      selectedNode,
      result,
    });

    return () => {
      worker.terminate();
    };
  }, [result, hideNodes, showNodes, selectedNode]);
  
  const EllipsisMemo = memo(Ellipsis);
  const IconMemo = memo(Icon);

  return (
    <Tree
      height={400}
      treeData={treeData}
      titleRender={(item: any) => (
        <span
          className={`${styles['treeNodeRow']} ${item.metadata.id === selectedNode ? styles['treeNodeRowActive'] : ''}`}
          onClick={() => onToggleSelectNode(item.metadata.id, item.metadata.result.urn)}
          style={{ marginRight: `-${12 - (item.metadata.currentLevel + 0.68)}px` }}
        >
          <EllipsisMemo
            phrase={item.title}
            maxLength={17}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              onToggleNode(item.metadata.id, item.metadata.result.urn);
            }}
          >
            <IconMemo
              icon={hide(item.metadata.id, item.metadata.selectedParent) ? 'notVisualizar' : 'visualizar'}
              customSize={11}
              customColor={hide(item.metadata.id, item.metadata.selectedParent) ? '#BEC2C6' : '#324150'}
            />
          </span>
        </span>
      )}
    />
  );
};

export default VirtualTreeView;