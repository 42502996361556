import styled from "styled-components";

export const Container = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 12px;

  .moreFilterButton {
    cursor: pointer !important;
    height: 32px;
    width: 139px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .buttonIcon {
      margin-right: 10px;
      margin-bottom: 4px;
    }

    .buttonText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      margin-bottom: 4px;
    }
  }

  .moreFilterButtonMore {
    width: 32px;
    justify-content: center;
    margin-left: 5px;

    .buttonIcon {
      margin: 0;
    }
  }
`;
