import { CSSProperties, FC, useEffect, useState } from "react";
import useDebounce from "Hooks/useDebounce";
import { Icon } from "../Icon";
import { InputWrapper } from "./styles";

export interface ISearchInput {
  defaultValue?: string;
  inputPlaceholder?: string;
  customClassName?: string;
  inputClassName?: string | null;
  delay?: number;
  style?: CSSProperties;
  setSearch: (value: string) => void; 
  setSearchLoading?: (value: boolean) => void; 
}

const SearchInput: FC<ISearchInput> = ({
  defaultValue,
  inputPlaceholder,
  customClassName,
  inputClassName,
  delay,
  style,
  setSearch,
  setSearchLoading,
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { debouncedValue, debouncedLoading } = useDebounce(inputValue, delay || 600);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setSearch(debouncedValue);
    if (setSearchLoading) {
      setSearchLoading(debouncedLoading);
    }
  }, [debouncedValue, debouncedLoading]);

  const handleChangeSearch = (event: any) => {
      setInputValue(event.target.value);
  } 

  // const handleInputClear = (event: any) => {
  //   if (!event.target.value && setSearch) {
  //     setSearch('');
  //   }
  // }

  return (
    <InputWrapper className={customClassName}>
      <span className="icon">
        <Icon icon="buscar" color={focus ? 'primary' : 'cinzaPadrao'} size="xxs" />
      </span>
      <input 
        type="text" 
        value={inputValue}
        onChange={(e) => {
          handleChangeSearch(e);
          // handleInputClear(e);
        }}
        onFocus={(e) => setFocus(!!e)}
        onBlur={() => setFocus(false)}
        className={`input ${inputClassName ? inputClassName : ''}`}
        placeholder={inputPlaceholder}
        style={style}
      />
    </InputWrapper>
  )
}

export default SearchInput;
