import { FC, useMemo, useState } from 'react';
import type { MenuProps } from 'antd';
import { Icon } from 'Components/UI';
import {
  ActionAttachmentMenuGlobalStyles,
  MenuDropdown,
  MenuItemStyled,
} from './styles';
import Tooltip from 'Components/UI/Tooltip';
import AccessPermission from 'Utils/AcessPermission';

interface IActionAttachmentDropdown {
  hasDeletePermission?: boolean;
  isPreview?: boolean;
  onDownload?: () => void;
  onDelete?: () => void;
  onOpenPage: () => void;
}

const ActionAttachmentDropdown: FC<IActionAttachmentDropdown> = ({
  children,
  hasDeletePermission,
  isPreview,
  onDownload,
  onDelete,
  onOpenPage,
}) => {
  const [isActive, setIsActive] = useState(false);

  const items = useMemo(() => {
    const defalt: MenuProps['items'] = [];

    if (!isPreview && onDownload) {
      defalt.push({
        label: (
          <MenuItemStyled onClick={onDownload}>
            <Icon
              icon="download"
              customSize={14}
              className="iconMenuItem"
            />
            <span className="textItem">
              Fazer download
            </span>
          </MenuItemStyled>
        ),
        key: '0',
        className: 'rowMenu',
      });

      if (!AccessPermission.isConsultor() && onDelete) {
        defalt.push({
          label: (
            <Tooltip
              placement="top"
              overlay={
                hasDeletePermission
                  ? undefined
                  : 'Você não tem permissão de excluir anexos que você não é proprietário.'
              }
              zIndex={1099}
            >
              <MenuItemStyled onClick={onDelete} isdisabled={`${!hasDeletePermission}`}>
                <Icon
                  icon="excluir"
                  customSize={14}
                  className="iconMenuItem"
                />
                <span className="textItem">
                  Excluir
                </span>
              </MenuItemStyled>
            </Tooltip>
          ),
          key: '1',
          className: 'rowMenu',
        });
      }

      defalt.push({
        type: 'divider',
      });
    }

    defalt.push(
      {
        label: (
          <MenuItemStyled onClick={onOpenPage}>
            <Icon
              icon="openNewTab"
              customSize={14}
              className="iconMenuItem"
            />
            <span className="textItem">
              Abrir em uma nova guia
            </span>
          </MenuItemStyled>
        ),
        key: '3',
        className: 'rowMenu',
      }
    );

    return defalt;
  }, [hasDeletePermission, isPreview, onDelete, onDownload, onOpenPage]);

  return (
    <>
      <ActionAttachmentMenuGlobalStyles />
      <MenuDropdown
        trigger={["click"]}
        className="ActionAttachmentDropdown"
        onOpenChange={(open) => setIsActive(open)}
        menu={{ items }}
        isactive={`${isActive}`}
      >
        {children}
      </MenuDropdown>
    </>
  )
};

export default ActionAttachmentDropdown;
