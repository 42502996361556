import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 260px;
  height: 250px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
`;

interface IIconContainer {
  selected: boolean;
}

export const IconContainer = styled.div<IIconContainer>`
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;

  .icon {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`} !important;
  }

  ${({ selected, theme }) => selected && css`
    background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryPressed}, ${theme.colors.surface.surface})`} !important;

    .icon {
      color: ${theme.colors.primary.primary} !important;
    }
  `}    
`;
