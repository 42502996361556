import { FC, useMemo } from "react";
import { Icon } from "Components/UI";
import { IInvitedUser } from "Data/interfaces/User/IInvitedUser";
import { GetAvatar } from "Utils/generateThumbnail";
import Select from "Components/UI/Select";
import Button from "Components/UI/Button";
import HighlighterWords from "Components/UI/HighlightWords";
import { IRole } from "../../user.model";
import { Container } from "./styles";

interface IItemInvited {
  user: IInvitedUser;
  search: string;
  roles: IRole[];
  loading?: boolean;
  onRemove: (email: string) => void;
}

const ItemInvited: FC<IItemInvited> = ({
  user,
  search,
  roles,
  loading,
  onRemove,
}) => {
  const getSharedUserRoleName = useMemo(() => {
    const role = roles.find((role) => {
      return role.value === user.RoleFk;
    });
    return role?.label;
  }, [roles, user]);

  return (
    <Container>
      <div className="avatarContainer">
        <GetAvatar name={user.Email} />
        <HighlighterWords
          text={user.Email}
          wordSearched={search}
          className="userName"
        />
      </div>
      <div className="actionContainer">
        <div className="roleName">
          {getSharedUserRoleName}
        </div>
        {/* <Select
          placeholder="Selecione um papel"
          defaultValue={user.RoleFk}
          options={roles}
          onSelect={(value) => console.log('onSelect: ', { user, value })}
          className="selectRole"
          disabled={loading}
        /> */}
        <div className="watingContainer">
          <span className="waitingTitle">Aguardando</span>
          <Button
            type="text"
            onClick={() => loading ? undefined : onRemove(user.Email)}
            className="watingBtn"
            disabled={loading}
          >
            <Icon
              icon="cancelar"
              customSize={10}
              className="waitingIcon"
            />
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default ItemInvited;
