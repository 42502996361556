import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { ITenantRole } from '../../Data/interfaces/Tenant/ITenantRole';
import { ITenantUsersResponse } from '../../Data/interfaces/Tenant/ITenantUsersResponse';
import { ITenantUsersWithConstruction } from '../../Data/interfaces/Tenant/ITenantUsersWithConstruction';
import { IUserInforResponse } from '../../Data/interfaces/Auth/IUserInfoResponse';
import { ITenanInviteInfo } from '../../Data/interfaces/Tenant/ITenantInviteData';
import { ITenantData } from '../../Data/interfaces/Tenant/ITenantData';
import { IBindConstructionSitesToUser } from '../../Data/interfaces/Obra/IBindConstructionSitesToUser';
import { IBindConstructionSitesToInvitedUser } from '../../Data/interfaces/Obra/IBindConstructionSitesToInvitedUser';
import { ITenantBindInvitedUsersRequest } from '../../Data/interfaces/Tenant/ITenantBindInvitedUsers';
import { ITenantSharedUsers, ITenantInvitedUsers } from '../../Data/interfaces/Tenant/ITenantUsersWithConstruction';
import { ITenantUpdateTenantUserRole } from '../../Data/interfaces/Tenant/ITenantUpdateTenantUserRole';
import { IChangeContextData } from 'Data/interfaces/Tenant/IChangeContextData';
import { ITenantUpdateStatusRequest } from 'Data/interfaces/Tenant/ITenantUpdateStatusRequest';

export enum TenantActionKeys {
  GET_TENANT_ROLES_REQUEST = 'GET_TENANT_ROLES_REQUEST',
  GET_TENANT_ROLES_SUCCESS = 'GET_TENANT_ROLES_SUCCESS',
  GET_TENANT_ROLES_FAILED = 'GET_TENANT_ROLES_FAILED',

  LIST_USER_TENANTS_REQUEST = 'LIST_USER_TENANTS_REQUEST',
  LIST_USER_TENANTS_SUCCESS = 'LIST_USER_TENANTS_SUCCESS',
  LIST_USER_TENANTS_FAILED = 'LIST_USER_TENANTS_FAILED',

  GET_TENANT_USERS_REQUEST = 'GET_TENANT_USERS_REQUEST',
  GET_TENANT_USERS_SUCCESS = 'GET_TENANT_USERS_SUCCESS',
  GET_TENANT_USERS_FAILED = 'GET_TENANT_USERS_FAILED',

  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILED = 'GET_USERS_FAILED',

  GET_TENANT_INVITE_INFO_REQUEST = 'GET_TENANT_INVITE_INFO_REQUEST',
  GET_TENANT_INVITE_INFO_SUCCESS = 'GET_TENANT_INVITE_INFO_SUCCESS',
  GET_TENANT_INVITE_INFO_FAILED = 'GET_TENANT_INVITE_INFO_FAILED',

  CHANGE_CONTEXT_REQUEST = 'CHANGE_CONTEXT_REQUEST',
  CHANGE_CONTEXT_SUCCESS = 'CHANGE_CONTEXT_SUCCESS',
  CHANGE_CONTEXT_FAILED = 'CHANGE_CONTEXT_FAILED',

  TOGGLE_MENU_CONTEXT = 'TOGGLE_MENU_CONTEXT',

  GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST = 'GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST',
  GET_TENANT_LIST_USERS_CONSTRUCTION_SUCCESS = 'GET_TENANT_LIST_USERS_CONSTRUCTION_SUCCESS',
  GET_TENANT_LIST_USERS_CONSTRUCTION_FAILED = 'GET_TENANT_LIST_USERS_CONSTRUCTION_FAILED',

  REMOVE_TENANT_USER_REQUEST = 'REMOVE_TENANT_USER_REQUEST',
  REMOVE_TENANT_USER_SUCCESS = 'REMOVE_TENANT_USER_SUCCESS',
  REMOVE_TENANT_USER_FAILED = 'REMOVE_TENANT_USER_FAILED',


  REMOVE_TENANT_INVITE_USER_REQUEST = 'REMOVE_TENANT_INVITE_USER_REQUEST',
  REMOVE_TENANT_INVITE_USER_SUCCESS = 'REMOVE_TENANT_INVITE_USER_SUCCESS',
  REMOVE_TENANT_INVITE_USER_FAILED = 'REMOVE_TENANT_INVITE_USER_FAILED',

  TENANT_USERS_CONSTRUCTION_REMOVE_USER = 'TENANT_USERS_CONSTRUCTION_REMOVE_USER',
  TENANT_USERS_CONSTRUCTION_REMOVE_INVITE = 'TENANT_USERS_CONSTRUCTION_REMOVE_INVITE',

  TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST = 'TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST',
  TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_SUCCESS = 'TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_SUCCESS',
  TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_FAILED = 'TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_FAILED',

  SET_SHOW_CONFIRM_DELETE_USER = 'SET_SHOW_CONFIRM_DELETE_USER',

  UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST',
  UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_SUCCESS = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_SUCCESS',
  UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_FAILED = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_FAILED',

  UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST',
  UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_SUCCESS = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_SUCCESS',
  UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_FAILED = 'UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_FAILED',

  TENANT_BIND_INVITED_USERS_REQUEST = 'TENANT_BIND_INVITED_USERS_REQUEST',
  TENANT_BIND_INVITED_USERS_SUCCESS = 'TENANT_BIND_INVITED_USERS_SUCCESS',
  TENANT_BIND_INVITED_USERS_FAILED = 'TENANT_BIND_INVITED_USERS_FAILED',

  UPDATE_TENANT_USER_ROLE_REQUEST = 'UPDATE_TENANT_USER_ROLE_REQUEST',
  UPDATE_TENANT_USER_ROLE_SUCCESS = 'UPDATE_TENANT_USER_ROLE_SUCCESS',
  UPDATE_TENANT_USER_ROLE_FAILED = 'UPDATE_TENANT_USER_ROLE_FAILED',

  UPDATE_TENANT_STATUS_REQUEST = 'UPDATE_TENANT_STATUS_REQUEST',
  UPDATE_TENANT_STATUS_SUCCESS = 'UPDATE_TENANT_STATUS_SUCCESS',
  UPDATE_TENANT_STATUS_FAILED = 'UPDATE_TENANT_STATUS_FAILED',

  UPDATE_CURRENT_LIST_TENANT = 'UPDATE_CURRENT_LIST_TENANT',
}

export const TenantActions = {
  getTenantRoles: (): GetTenantRolesAction =>
    createAction(TenantActionKeys.GET_TENANT_ROLES_REQUEST),
  getTenantRolesSuccess: (tenantRoles: ITenantRole[]): GetTenantRolesSuccessAction =>
    createAction(TenantActionKeys.GET_TENANT_ROLES_SUCCESS, tenantRoles),
  getTenantRolesFailure: (err: string): GetTenantRolesFailureAction =>
    createAction(TenantActionKeys.GET_TENANT_ROLES_FAILED, err),

  listUserTenants: (): ListUserTenantsAction =>
    createAction(TenantActionKeys.LIST_USER_TENANTS_REQUEST),
  listUserTenantsSuccess: (tenants: ITenantData[]): ListUserTenantsSuccessAction =>
    createAction(TenantActionKeys.LIST_USER_TENANTS_SUCCESS, tenants),
  listUserTenantsFailure: (err: string): ListUserTenantsFailureAction =>
    createAction(TenantActionKeys.LIST_USER_TENANTS_FAILED, err),

  getTenantUsers: (): GetTenantUsersAction =>
    createAction(TenantActionKeys.GET_TENANT_USERS_REQUEST),
  getTenantUsersSuccess: (tenantUsers: ITenantUsersResponse[]): GetTenantUsersSuccessAction =>
    createAction(TenantActionKeys.GET_TENANT_USERS_SUCCESS, tenantUsers),
  getTenantUsersFailure: (err: string): GetTenantUsersFailureAction =>
    createAction(TenantActionKeys.GET_TENANT_USERS_FAILED, err),

  getUsers: (): GetUsersAction =>
    createAction(TenantActionKeys.GET_USERS_REQUEST),
  getUsersSuccess: (users: IUserInforResponse[]): GetUsersSuccessAction =>
    createAction(TenantActionKeys.GET_USERS_SUCCESS, users),
  getUsersFailure: (err: string): GetUsersFailureAction =>
    createAction(TenantActionKeys.GET_USERS_FAILED, err),

  getTenantInviteInfo: (emailHash: string): GetTenantInviteInfoAction =>
    createAction(TenantActionKeys.GET_TENANT_INVITE_INFO_REQUEST, emailHash),
  getTenantInviteInfoSuccess: (tenantInvite: ITenanInviteInfo): GetTenantInviteInfoSuccessAction =>
    createAction(TenantActionKeys.GET_TENANT_INVITE_INFO_SUCCESS, tenantInvite),
  getTenantInviteInfoFailure: (err: string): GetTenantInviteInfoFailureAction =>
    createAction(TenantActionKeys.GET_TENANT_INVITE_INFO_FAILED, err),

  changeContext: (request: IChangeContextData): ChangeContextAction =>
    createAction(TenantActionKeys.CHANGE_CONTEXT_REQUEST, request),
  changeContextSuccess: (res: IChangeContextData): ChangeContextSuccessAction =>
    createAction(TenantActionKeys.CHANGE_CONTEXT_SUCCESS, res),
  changeContextFailure: (err: string): ChangeContextFailureAction =>
    createAction(TenantActionKeys.CHANGE_CONTEXT_FAILED, err),

  toggleMenuContext: (toggle: boolean): ToggleMenuContext =>
    createAction(TenantActionKeys.TOGGLE_MENU_CONTEXT, toggle),

  getTenantListUsersConstruction: (): GetTenantListUsersConstructionAction =>
    createAction(TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST),
  getTenantListUsersConstructionSuccess: (tenantUsersWithConstruction: ITenantUsersWithConstruction): GetTenantListUsersConstructionSuccessAction =>
    createAction(TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_SUCCESS, tenantUsersWithConstruction),
  getTenantListUsersConstructionFailure: (err: string): GetTenantListUsersConstructionFailureAction =>
    createAction(TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_FAILED, err),

  tenantUsersConstructionRemoveUser: (user: string): TenantUsersConstructionRemoveUserAction =>
    createAction(TenantActionKeys.TENANT_USERS_CONSTRUCTION_REMOVE_USER, user),
  tenantUsersConstructionRemoveInvite: (user: number): TenantUsersConstructionRemoveInviteAction =>
    createAction(TenantActionKeys.TENANT_USERS_CONSTRUCTION_REMOVE_INVITE, user),
  setShowConfirmDeleteUser: (visible: boolean): SetShowConfirmDeleteUserAction =>
    createAction(TenantActionKeys.SET_SHOW_CONFIRM_DELETE_USER, visible),

  tenantUsersConstructionReSendInvite: (userId: number): TenantUsersConstructionReSendInviteAction =>
    createAction(TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST, userId),
  tenantUsersConstructionReSendInviteSuccess: (userId: number): TenantUsersConstructionReSendInviteSuccessAction =>
    createAction(TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_SUCCESS, userId),
  tenantUsersConstructionReSendInviteFailure: (err: string): TenantUsersConstructionReSendInviteFailureAction =>
    createAction(TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_FAILED, err),

  removeTenantUser: (userId: string): RemoveTenantUserAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_USER_REQUEST, userId),
  removeTenantUserSuccess: (userId: string): RemoveTenantUserSuccessAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_USER_SUCCESS, userId),
  removeTenantUserFailure: (err: string): RemoveTenantUserFailureAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_USER_FAILED, err),

  removeTenantInviteUser: (tenantInviteId: number): RemoveTenantInviteUserAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_INVITE_USER_REQUEST, tenantInviteId),
  removeTenantInviteUserSuccess: (tenantInviteId: number): RemoveTenantInviteUserSuccessAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_INVITE_USER_SUCCESS, tenantInviteId),
  removeTenantInviteUserFailure: (err: string): RemoveTenantInviteUserFailureAction =>
    createAction(TenantActionKeys.REMOVE_TENANT_INVITE_USER_FAILED, err),

  updateTenantBindConstructionSitesToUser: (ConstructionsSiteWithUser: IBindConstructionSitesToUser): UpdateTenantBindConstructionSitesToUserAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST, ConstructionsSiteWithUser),
  updateTenantBindConstructionSitesToUserSuccess: (response: ITenantSharedUsers):
    UpdateTenantBindConstructionSitesToUserSuccessAction => createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_SUCCESS, response),
  updateTenantBindConstructionSitesToUserFailure: (err: string): UpdateTenantBindConstructionSitesToUserFailureAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_FAILED, err),

  updateTenantBindConstructionSitesToInvitedUser: (ConstructionsSiteWithInvitedUser: IBindConstructionSitesToInvitedUser): UpdateTenantBindConstructionSitesToInvitedUserAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST, ConstructionsSiteWithInvitedUser),
  updateTenantBindConstructionSitesToInvitedUserSuccess: (response: ITenantInvitedUsers):
    UpdateTenantBindConstructionSitesToInvitedUserSuccessAction => createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_SUCCESS, response),
  updateTenantBindConstructionSitesToInvitedUserFailure: (err: string): UpdateTenantBindConstructionSitesToInvitedUserFailureAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_FAILED, err),

  tenantBindInviedUsersRequest: (bindInvitedUsers: ITenantBindInvitedUsersRequest): TenantBindInviedUsersAction =>
    createAction(TenantActionKeys.TENANT_BIND_INVITED_USERS_REQUEST, bindInvitedUsers),
  tenantBindInviedUsersSuccess: (response: ITenantInvitedUsers): TenantBindInviedUsersSuccessAction =>
    createAction(TenantActionKeys.TENANT_BIND_INVITED_USERS_SUCCESS, response),
  tenantBindInviedUsersFailure: (err: string): TenantBindInviedUsersFailureAction =>
    createAction(TenantActionKeys.TENANT_BIND_INVITED_USERS_FAILED, err),

  updateTenantUserRoleRequest: (updateTenantUserRole: ITenantUpdateTenantUserRole): UpdateTenantUserRoleAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_USER_ROLE_REQUEST, updateTenantUserRole),
  updateTenantUserRoleSuccess: (response: ITenantUpdateTenantUserRole): UpdateTenantUserRoleSuccessAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_USER_ROLE_SUCCESS, response),
  updateTenantUserRoleFailure: (err: string): UpdateTenantUserRoleFailureAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_USER_ROLE_FAILED, err),

  updateTenantStatus: (request: ITenantUpdateStatusRequest): UpdateTenantStatusAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_STATUS_REQUEST, request),
  updateTenantStatusSuccess: (response: ITenantUpdateStatusRequest): UpdateTenantStatusSuccessAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_STATUS_SUCCESS, response),
  updateTenantStatusFailure: (err: string): UpdateTenantStatusFailureAction =>
    createAction(TenantActionKeys.UPDATE_TENANT_STATUS_FAILED, err),

  updateCurrentListTenant: (tenant: ITenantData | null): UpdateCurrentListTenantAction =>
    createAction(TenantActionKeys.UPDATE_CURRENT_LIST_TENANT, tenant),
};

export type TenantActionUnion = ActionsUnion<typeof TenantActions>;

export type GetTenantRolesAction = Action<TenantActionKeys.GET_TENANT_ROLES_REQUEST>;
export type GetTenantRolesSuccessAction = Action<TenantActionKeys.GET_TENANT_ROLES_SUCCESS, ITenantRole[]>;
export type GetTenantRolesFailureAction = Action<TenantActionKeys.GET_TENANT_ROLES_FAILED, string>;

export type GetTenantListUsersConstructionAction = Action<TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST>;
export type GetTenantListUsersConstructionSuccessAction = Action<TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_SUCCESS, ITenantUsersWithConstruction>;
export type GetTenantListUsersConstructionFailureAction = Action<TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_FAILED, string>;

export type TenantUsersConstructionRemoveUserAction = Action<TenantActionKeys.TENANT_USERS_CONSTRUCTION_REMOVE_USER, string>;
export type TenantUsersConstructionRemoveInviteAction = Action<TenantActionKeys.TENANT_USERS_CONSTRUCTION_REMOVE_INVITE, number>;
export type SetShowConfirmDeleteUserAction = Action<TenantActionKeys.SET_SHOW_CONFIRM_DELETE_USER, boolean>;

export type TenantUsersConstructionReSendInviteAction = Action<TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST, number>;
export type TenantUsersConstructionReSendInviteSuccessAction = Action<TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_SUCCESS, number>;
export type TenantUsersConstructionReSendInviteFailureAction = Action<TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_FAILED, string>;

export type ListUserTenantsAction = Action<TenantActionKeys.LIST_USER_TENANTS_REQUEST>;
export type ListUserTenantsSuccessAction = Action<TenantActionKeys.LIST_USER_TENANTS_SUCCESS, ITenantData[]>;
export type ListUserTenantsFailureAction = Action<TenantActionKeys.LIST_USER_TENANTS_FAILED, string>;

export type GetTenantUsersAction = Action<TenantActionKeys.GET_TENANT_USERS_REQUEST>;
export type GetTenantUsersSuccessAction = Action<TenantActionKeys.GET_TENANT_USERS_SUCCESS, ITenantUsersResponse[]>;
export type GetTenantUsersFailureAction = Action<TenantActionKeys.GET_TENANT_USERS_FAILED, string>;

export type GetUsersAction = Action<TenantActionKeys.GET_USERS_REQUEST>;
export type GetUsersSuccessAction = Action<TenantActionKeys.GET_USERS_SUCCESS, IUserInforResponse[]>;
export type GetUsersFailureAction = Action<TenantActionKeys.GET_USERS_FAILED, string>;

export type GetTenantInviteInfoAction = Action<TenantActionKeys.GET_TENANT_INVITE_INFO_REQUEST, string>;
export type GetTenantInviteInfoSuccessAction = Action<TenantActionKeys.GET_TENANT_INVITE_INFO_SUCCESS, ITenanInviteInfo>;
export type GetTenantInviteInfoFailureAction = Action<TenantActionKeys.GET_TENANT_INVITE_INFO_FAILED, string>;

export type ChangeContextAction = Action<TenantActionKeys.CHANGE_CONTEXT_REQUEST, IChangeContextData>;
export type ChangeContextSuccessAction = Action<TenantActionKeys.CHANGE_CONTEXT_SUCCESS, IChangeContextData>;
export type ChangeContextFailureAction = Action<TenantActionKeys.CHANGE_CONTEXT_FAILED, string>;

export type ToggleMenuContext = Action<TenantActionKeys.TOGGLE_MENU_CONTEXT, boolean>;

export type RemoveTenantUserAction = Action<TenantActionKeys.REMOVE_TENANT_USER_REQUEST, string>;
export type RemoveTenantUserSuccessAction = Action<TenantActionKeys.REMOVE_TENANT_USER_SUCCESS, string>;
export type RemoveTenantUserFailureAction = Action<TenantActionKeys.REMOVE_TENANT_USER_FAILED, string>;

export type RemoveTenantInviteUserAction = Action<TenantActionKeys.REMOVE_TENANT_INVITE_USER_REQUEST, number>;
export type RemoveTenantInviteUserSuccessAction = Action<TenantActionKeys.REMOVE_TENANT_INVITE_USER_SUCCESS, number>;
export type RemoveTenantInviteUserFailureAction = Action<TenantActionKeys.REMOVE_TENANT_INVITE_USER_FAILED, string>;


export type UpdateTenantBindConstructionSitesToUserAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST, IBindConstructionSitesToUser>;
export type UpdateTenantBindConstructionSitesToUserSuccessAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_SUCCESS, ITenantSharedUsers>;
export type UpdateTenantBindConstructionSitesToUserFailureAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_FAILED, string>;

export type UpdateTenantBindConstructionSitesToInvitedUserAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST, IBindConstructionSitesToInvitedUser>;
export type UpdateTenantBindConstructionSitesToInvitedUserSuccessAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_SUCCESS, ITenantInvitedUsers>;
export type UpdateTenantBindConstructionSitesToInvitedUserFailureAction =
  Action<TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_FAILED, string>;


export type TenantBindInviedUsersAction = Action<TenantActionKeys.TENANT_BIND_INVITED_USERS_REQUEST, ITenantBindInvitedUsersRequest>;
export type TenantBindInviedUsersSuccessAction = Action<TenantActionKeys.TENANT_BIND_INVITED_USERS_SUCCESS, ITenantInvitedUsers>;
export type TenantBindInviedUsersFailureAction = Action<TenantActionKeys.TENANT_BIND_INVITED_USERS_FAILED, string>;

export type UpdateTenantUserRoleAction = Action<TenantActionKeys.UPDATE_TENANT_USER_ROLE_REQUEST, ITenantUpdateTenantUserRole>;
export type UpdateTenantUserRoleSuccessAction = Action<TenantActionKeys.UPDATE_TENANT_USER_ROLE_SUCCESS, ITenantUpdateTenantUserRole>;
export type UpdateTenantUserRoleFailureAction = Action<TenantActionKeys.UPDATE_TENANT_USER_ROLE_FAILED, string>;

export type UpdateTenantStatusAction = Action<TenantActionKeys.UPDATE_TENANT_STATUS_REQUEST, ITenantUpdateStatusRequest>;
export type UpdateTenantStatusSuccessAction = Action<TenantActionKeys.UPDATE_TENANT_STATUS_SUCCESS, ITenantUpdateStatusRequest>;
export type UpdateTenantStatusFailureAction = Action<TenantActionKeys.UPDATE_TENANT_STATUS_FAILED, string>;

export type UpdateCurrentListTenantAction = Action<TenantActionKeys.UPDATE_CURRENT_LIST_TENANT, ITenantData | null>;
