import { INotificationData } from "Data/interfaces/Notification/INotificationResponse";

export const booleanSort = (a: boolean, b: boolean) => {
  return a === b ? 0 : a ? -1 : 1;
};

export const numberSort = (a: number, b: number) => {
  return a < b ? - 1 : a > b ? 1 : 0;
}

export const getUnreadNotifications = (allNotification: INotificationData[]) => {
  const unreadNotifications: INotificationData[] = allNotification.filter((element: INotificationData) => !element.hasRead);
  return unreadNotifications;
}