import { store } from "../Store";
import toastHandler from "../Utils/toastHandler";
import axios from "axios";
import { 
  IAttachmentError,
  IAttachmentForUpload, 
  IAttachmentUpdateProgress,
} from "./interfaces/Activities/IAttachmentsForUpload";
import { UploadChunks } from "Utils/UploadChunks";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { Mixpanel } from "Utils/MixPanel";
import { IAttachment } from "./interfaces/Activities/ITopicResponse";
import dayjs from "dayjs";

interface IProgress {
  sent: number;
  total: number;
  percentage: number;
}

export class UploadChunksAttachmentsApi {
  static uploadChunkFiles(upload: IAttachmentForUpload): any {
    if (upload.file) {
      const beforeUpload = dayjs();
      const destiny = {
        constructionSiteId: Number(upload.constructionSiteId),
      };
      const trackDefaultUpload = {
        destiny: destiny,
        fileSize: upload.file.size, 
      };
      
      const cancelToken = axios.CancelToken.source();
      store.dispatch(ActivitiesActions.addUploadAttachmentCancelToken(cancelToken));
  
      const threadsQuantity = 4;
      const chunkSize = 1024 * 1024 * 4;
  
      const uploader = new UploadChunks({
        file: upload.file,
        fileName: upload.file.name,
        fileSize: upload.file.size,
        threadsQuantity,
        chunkSize,
        cancelToken,
        fileIdUpload: upload.id,
        objectQuery: {
          topicId: upload?.topicId || '',
          tenantId: upload.tenantId,
          constructionSiteId: Number(upload.constructionSiteId),
          size: upload.file.size,
          fileName: upload.file.name,
        },
        url: '/api/Topic/Attachment',
      });
  
      uploader
        .onProgress(({sent, percentage}: IProgress) => {
          if (sent && percentage) {
            const progress: IAttachmentUpdateProgress = {
              attachmentId: upload.id,
              sentSize: sent,
              percentage,
            };
            store.dispatch(ActivitiesActions.updateUploadAttachmentProgress(progress));
          }
        })
        .onFinish((data: IAttachment) => {
          if (data && data.File) {
            const uploadResponseTime = dayjs().diff(beforeUpload, 'milliseconds');
            Mixpanel.track({
              name: 'FILE_ATTACHMENT_UPLOAD', 
              props: {
                ...trackDefaultUpload,
                elapsed: uploadResponseTime,
              },
              userInfo: upload.userInfoTracking,
              currentListTenant: upload.tenantTracking,
            });

            store.dispatch(ActivitiesActions.uploadAttachmentSuccess({
              newAttachment: data,
              attachmentUploadedId: upload.id,
            }));
          }
        })
        .onError((e: any) => {
          const uploadErrorTime = dayjs().diff(beforeUpload, 'milliseconds');
          Mixpanel.track({
            name: 'FILE_ATTACHMENT_UPLOAD_ERROR', 
            props: {
              ...trackDefaultUpload,
              elapsed: uploadErrorTime,
            },
            userInfo: upload.userInfoTracking,
            currentListTenant: upload.tenantTracking,
          });
  
          const msg = 'Erro ao realizar upload de anexo';
          const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
          const errorResponse: IAttachmentError = {
            attachmentId: upload.id,
            error,
          };
          store.dispatch(ActivitiesActions.uploadAttachmentFailure(errorResponse));
          
          const notShowError = store.getState().activities.notShowError;
          if (!notShowError) {
            toastHandler.showError(error);
            store.dispatch(ActivitiesActions.notShowAttachmentCancelError(false));
          }
        })
        .start()
    }
  }
}
