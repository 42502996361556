import { Http } from '../Utils/Http';
import { AxiosResponse } from 'axios';
import { ITopicCreateRequest } from './interfaces/Activities/ITopicCreateRequest';
import { IChangeStatus } from './interfaces/Activities/IChangeStatus';
import { IUpdateActivityRequest } from './interfaces/Activities/IUpdateActivityRequest';
import { ITopicRequest } from './interfaces/Activities/ITopicRequest';

export class ActivitiesApi {
  static async listActivities(request: ITopicRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get(`/Topic/Get?constructionSiteId=${request.csId}${request.filtersId ? `&filterId=${request.filtersId}` : ''}`);
  }

  static async createActivity(request: ITopicCreateRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.post('/Topic/Post', request);
  }

  static async updateActivity(request: IUpdateActivityRequest): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.patch(`/Topic/Patch/${request.Guid}`, request.updateData);
  }

  static async changeStatus(request: IChangeStatus): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.patch(`/Topic/Patch/${request.Guid}`, request.updateData);
  }

  static async deleteActivity(activityId: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete(`/Topic/Delete/${activityId}`);
  }

  static async deleteAttachmentForCreate(fileId: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete(`/Topic/DeleteNewAttachment/${fileId}`);
  }
  
  static async deleteAttachment(topicAttachmentId: number): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.delete(`/Topic/DeleteExistingAttachment/${topicAttachmentId}`);
  }
  
  static async listHistory(topicId: string): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get(`/topic/${topicId}/history`);
  }
}
