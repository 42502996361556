import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { IUserStoreItem } from "Data/interfaces/Store/IUserStoreItem";
import { AdmActions } from "../../../../../Store/Adm/Adm.actions";
import styles from "./TenantApprovePlanChange.module.scss";
import { Divider } from "antd";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { numberToMoney } from "Utils/Money";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

interface ITenantApprovePlanChange {
  visible?: boolean;
  tenant?: IUserStoreItem;
  tenantId?: number;
  userInfo?: IUserInforResponse;
  onClose: () => void;
  closeEditTenantUpdateModal: () => void;
}

const TenantApprovePlanChange: FC<ITenantApprovePlanChange> = ({ 
  visible,
  tenant,
  tenantId,
  userInfo,
  onClose,
  closeEditTenantUpdateModal
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IGlobalReducerState) => state.adm.tenantIsLoading);
  const currentTenant = useSelector(getCurrentTenant);

  const [upgrade, setUpgrade] = React.useState(false);
  const [isSend, setIsSend] = React.useState(false);

  useEffect(() => {
    if (tenant &&tenant.UpdateStorePlan?.Order && tenant.StorePlan?.Order) {
      setUpgrade(tenant.UpdateStorePlan?.Order > tenant.StorePlan?.Order)
    }
  }, [tenant]);

  useEffect(() => {
    if (isSend && !isLoading) {
      onClose();
      closeEditTenantUpdateModal();
    }
  }, [isLoading]);

  const confirmUpdatePlan = (isAprove: boolean) => {
    if (tenant) {
      setIsSend(true);
      dispatch(AdmActions.confirmUpdatePlan({
        userStoreItemId: tenant?.UserStoreItemsId,
        isApproved: isAprove,
        isUpgradeTracking: upgrade,
        tenantIdTracking: tenantId,
        oldStorePlanTracking: tenant.StorePlan?.Name,
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      }));
    }
  }

  const buttons = (
    <>
      <Button 
        onClick={onClose}
        className={styles['buttons-upordown-modal']}
      >
        Cancel
      </Button>
      <Button 
        type="primary" 
        onClick={() => confirmUpdatePlan(false)}
        className={styles['buttons-upordown-modal']}
        danger
      >
        Reprovar
      </Button>
      <Button 
        type="primary"
        onClick={() => confirmUpdatePlan(true)}
        loading={isLoading} 
        className={styles['buttons-upordown-modal']}
      >
        {`Aprovar ${upgrade ? 'UPGRADE' : 'DOWNGRADE'}`}
      </Button>
    </>
  );

  return (
    <Modal
      title={`Revisão de ${upgrade ? 'Upgrade' : 'Downgrade'}`}
      visible={visible}
      onCancel={onClose}
      footerButtons={buttons}
    >
      <div className={styles['adm-main-wrapper-updown']}>
        <Divider orientation="left">{'Plano Atual'}</Divider>
        <div className={styles['divider-updown-wrapper']}>
          <div>
            <h4>{'Nome'}</h4>
            <span>{tenant?.StorePlan?.Name}</span>
          </div>
          <div>
            <h4>{'Máx. Obras'}</h4>
            <span>{tenant?.StorePlan?.MaxQtyConstructionSites}</span>
          </div>
          <div>
            <h4>{'Preço'}</h4>
            <span>{tenant?.StorePlan?.Price !== 0 ? numberToMoney(tenant?.StorePlan?.Price, 'real') : 'Grátis'}</span>
          </div>
        </div>

        <Divider 
          orientation="left" 
          style={{ color: `${upgrade ? 'green' : 'red'}`}}
        >
          {`Plano ${upgrade ? 'Upgrade' : 'Downgrade'}`}
        </Divider>
        <div className={styles['divider-updown-wrapper']}>
          <div>
            <h4>{'Nome'}</h4>
            <span>{tenant?.UpdateStorePlan?.Name}</span>
          </div>
          <div>
            <h4>{'Máx. Obras'}</h4>
            <span>{tenant?.UpdateStorePlan?.MaxQtyConstructionSites}</span>
          </div>
          <div>
            <h4>{'Preço'}</h4>
            <span>{tenant?.UpdateStorePlan?.Price !== 0 ? numberToMoney(tenant?.UpdateStorePlan?.Price, 'real') : 'Grátis'}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default TenantApprovePlanChange;
