import React, { FC } from 'react';

import styles from './TipoFilter.module.scss';
import Search from '../../../../Components/ShellHost/TopBar/Search/Search';
import { Checkbox } from 'antd';

export interface ITipoOption {
  description: string;
  renderNode?: React.ReactNode;
  value: string;
}

export interface ITipoFilter {
  hideSearch?: boolean;
  tipoOptions?: ITipoOption[];
  propsFilter: any;
  width?: string;
  showFilter?: (display: boolean) => void;
  placeholder?: string;
  className?: string;
}

const FilterModal: FC<ITipoFilter> = ({ hideSearch, className, tipoOptions, width, propsFilter, showFilter, placeholder }) => {
  const [searchInput, setSearchInput] = React.useState<string>();
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const changeFilterValues = (value: string, checked: boolean) => {
    if (checked) {
      propsFilter.setSelectedKeys([...selectedKeys, value.toLowerCase()]);
      setSelectedKeys([...selectedKeys, value.toLowerCase()]);
    } else {
      const index = selectedKeys.indexOf(value.toLowerCase());
      selectedKeys.splice(index, 1);
      propsFilter.setSelectedKeys([...selectedKeys]);
      setSelectedKeys([...selectedKeys]);
    }
    propsFilter.confirm();
    if (showFilter) {
      showFilter(true);
    }
  };

  return (
    <div style={{ minWidth: width ? width : '200px' }} className={className}>
      <Search
        placeholder={!placeholder ? 'Buscar formato' : placeholder}
        className={`${styles['searchTipo']} ${hideSearch ? styles['hide'] : ''}`}
        value={searchInput}
        changeValue={setSearchInput}
      />
      <div className={styles['tipoOptionsWrap']}>
        {
          tipoOptions?.filter(
            (tipoOption) => tipoOption.description.toLocaleLowerCase().includes(searchInput?.toLocaleLowerCase() || ''))
            .map((tipoOption) => (
            <div className={styles['tipoOption']} key={tipoOption.description} onClick={(e) => {
                e.currentTarget.getElementsByTagName('input').item(0)?.click();
              }}>
              <Checkbox
                className={styles['checkbox']}
                onChange={(props: any) =>
                  changeFilterValues(props.target.value, props.target.checked)
                }
                checked={propsFilter.selectedKeys.includes(tipoOption.value.toLowerCase())}
                value={tipoOption.value}
              />
                {
                  tipoOption.renderNode
                  ? tipoOption.renderNode
                  : <span className={styles['description']}>{tipoOption.description}</span>
                }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default FilterModal;
