import styled from "styled-components";

export const GaleryContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 52px;
`;

export const ListContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 46px;
`;

export const ApresentationContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 52px;
`;

export const GaleryMain = styled.div`
  width: 205px;
  height: 176px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 12px;

  .midle {
    display: flex;
    flex-direction: column;
    padding: 6px;
    
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
    }
  }
`;

export const ListMain = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 6px;

  .midle {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 12px;
    
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
    }
  }
`;

export const ApresentationHeader = styled.div`
  position: relative;
  width: 422px;
  height: 283px;
  display: flex;
  flex-direction: column;
  border: none !important;

  .midle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 12px;
    
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
    }
  }
`;

export const ApresentationMain = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
`;

export const ApresentationCard = styled.div`
  width: 72px;
  height: 48px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};

  &+& {
    margin-left: 6px;
  }
`;

export const MidleHr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin: 10px 0;
`;
