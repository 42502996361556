import { Icon } from 'Components/UI/Icon';
import ModalInfoQRCode from './ModalInfoQRCode';
import { useState } from 'react';

const InfoQRCodeDropdown = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  }

  return (
    <div>
      <Icon
        icon="informacaoAdicional"
        color="cinzaIcone"
        customSize={12}
        onClick={handleShowModal}
        style={{cursor: 'pointer'}}
      />
      <ModalInfoQRCode 
        visible={showModal}
        onCancel={handleShowModal} 
      />
    </div>
  )
}

export default InfoQRCodeDropdown;
