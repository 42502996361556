import { CSSProperties, FC } from 'react';
import { iconClasses, IconName } from './IconClasses';
import { ColorName } from '../../../Utils/Color';
import { StyledIcon } from './styles';

export interface IIconProps {
  icon?: IconName;
  className?: string;
  backgroundColor?: ColorName;
  color?: ColorName;
  size?: 'xxs' | 'xs' | 'md' | 'lg' | 'xxl';
  customSize?: number;
  style?: CSSProperties;
  customColor?: string;
  id?: string;
  onClick?: () => void;
  error?: boolean;
  disabled?: boolean;
  blocked?: boolean;
}

export const Icon: FC<IIconProps> = ({
  icon,
  style,
  className,
  id,
  onClick,
  size,
  color,
  backgroundColor,
  customSize,
  customColor,
  error,
  disabled,
  blocked,
}) => {
  return (
    <StyledIcon
      id={id}
      size={size || 'xs'}
      color={color}
      backgroundColor={backgroundColor}
      customSize={customSize}
      customColor={customColor}
      error={error}
      disabled={disabled}
      blocked={blocked}
      style={{ ...style }}
      className={`icon  ${icon ? iconClasses[icon] : ''} ${className}`}
      onClick={() => onClick && onClick()}
    />
  );
};
