import { FC, useState } from 'react';
import { Icon } from '../Icon';
import { RadioButton, UnselectedIcon } from './styles';

export interface IRadioOption {
  value: string | number;
  label: string | React.ReactNode;
}

export interface IRadioButtons {
  options: IRadioOption[];
  defaultValue?: string | number;
  onSelect?: (value?: string | number) => void;
}

const RadioButtons: FC<IRadioButtons> = ({ options, defaultValue, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string | number | undefined>(defaultValue);

  const onChange = (value: string | number) => {
    if (onSelect) {
      onSelect(value);
    }
    setSelectedOption(value);
  };
  return (
    <div>
      {options.map((option) => (
        <RadioButton onClick={() => onChange(option.value)}>
          {selectedOption === option.value ? (
            <Icon icon='confirmacao'
              customSize={15}
              className="selectedIcon"
            />
          ) : (
            <UnselectedIcon />
          )}
          <div>{option.label}</div>
        </RadioButton>
      ))}
    </div>
  );
};

export default RadioButtons;
