import React, { FC } from 'react';

import styles from './PermissaoObra.module.scss';
import { Icon, IconName } from '../../../../../../Components/UI';
import Tooltip from '../../../../../../Components/UI/Tooltip';
import { GetAvatar } from '../../../../../../Utils/generateThumbnail';
import { IUserInfoResponse } from '../../../../../../Data/interfaces/MinhaConta/IUserInfoResponse';
import AccessPermission from 'Utils/AcessPermission';
interface IPermissaoObra {
  username: string;
  userAvatar?: string;
  privileges: number[];
  hasPlottingPermission: boolean;
  index: number;
  onChange: (privileges: number[], index: number, hasPlottingPermission: boolean) => void;
  combinedUsers?: any;
  workToEdit?: any;
  currentUser?: IUserInfoResponse;
}

const buttonsPermission = ['Visualizar', 'Download', 'Upload', 'Editar'];

const PermissaoObra: FC<IPermissaoObra> = ({
  username,
  userAvatar,
  privileges,
  hasPlottingPermission,
  index,
  onChange,
  combinedUsers,
  workToEdit,
  currentUser
}) => {
  const changeHasPlottingPermission = () => {
    onChange(privileges, index, !hasPlottingPermission);
  };

  const changePermissionLevel = (level: number) => {
    if (level !== 0) {
      const permissionIndex = privileges.indexOf(level);
      permissionIndex >= 0
        ? privileges.splice(permissionIndex, 1)
        : privileges.push(level);
      onChange(privileges, index, hasPlottingPermission);
    }
  };

  const notCoordenadorPermissionChange = (roleName: string) => (
    roleName === 'Coordenador' && !AccessPermission.isAdmin() && !AccessPermission.isCoordenador()
  );

  return (
    <div className={styles['permissoesWrapper']}>
      <div className={`${styles['container']} ${styles['containerUsername']}`}>
        <GetAvatar name={username} shape='circle' src={userAvatar} />
        <span className={styles['username']}>{username}</span>
      </div>
      {buttonsPermission.map((name, i) => (
        <Tooltip title={name} key={`buttonPermission-${i}`}>
          <div
            className={`${styles['container']}
              ${styles['containerButton']}`}
            onClick={
              notCoordenadorPermissionChange(workToEdit?.Role?.RoleName)
                ? undefined
                : () => changePermissionLevel(i)
            }
          >
            <Icon
              icon={name.toLocaleLowerCase() as IconName}
              customSize={20}
              style={
                notCoordenadorPermissionChange(workToEdit?.Role?.RoleName)
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
              color={
                privileges !== null && privileges.includes(i)
                  ? 'primary'
                  : 'cinzaPadrao'
              }
            />
            <p
              className={
                privileges !== null && privileges.includes(i) ? styles['selected'] : ''
              }
            >
              {name.toLocaleUpperCase()}
            </p>
          </div>
        </Tooltip>
      ))}
      <Tooltip title="Plotagem">
        <div
          className={`${styles['container']}
          ${styles['containerButton']}`}
          onClick={
            notCoordenadorPermissionChange(workToEdit?.Role?.RoleName)
              ? undefined
              : changeHasPlottingPermission
          }
          style={
            notCoordenadorPermissionChange(workToEdit?.Role?.RoleName)
              ? { cursor: 'not-allowed' }
              : { cursor: 'pointer' }
          }
        >
          <Icon
            icon="menuPlotagem"
            customSize={20}
            color={hasPlottingPermission ? 'primary' : 'cinzaPadrao'}
          />
          <p className={hasPlottingPermission ? styles['selected'] : ''}>PLOTAGEM</p>
        </div>
      </Tooltip>
    </div>
  );
};

export default PermissaoObra;
