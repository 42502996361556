import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";

const getAttachmentsForUpload = (state: IGlobalReducerState) => state.activities.attachmentsForUpload;
const getAttachmentsUploading = (state: IGlobalReducerState) => state.activities.attachmentsUploading;
const getAttachmentsForCreate = (state: IGlobalReducerState) => state.activities.attachmentsForCreate;
const getIsLoadingAttachmentUploading = (state: IGlobalReducerState) => state.activities.isLoadingAttachmentUploading;
const getUploadAttachementCancelToken = (state: IGlobalReducerState) => state.activities.uploadAttachementCancelToken;
const getAttachementPreviewOn = (state: IGlobalReducerState) => state.activities.attachmentPreviewOn;
const getIsLoadingAttachmentDeleting = (state: IGlobalReducerState) => state.activities.isLoadingAttachmentDeleting;
const getAttachmentsError = (state: IGlobalReducerState) => state.activities.error;
const getAttachmentsNotShowError = (state: IGlobalReducerState) => state.activities.notShowError;
const getTopicStatusOptions = (state: IGlobalReducerState) => state.activities.topicStatusOptions;

export { 
  getAttachmentsForUpload,
  getAttachmentsUploading,
  getAttachmentsForCreate,
  getIsLoadingAttachmentUploading,
  getUploadAttachementCancelToken,
  getAttachementPreviewOn,
  getIsLoadingAttachmentDeleting,
  getAttachmentsError,
  getAttachmentsNotShowError,
  getTopicStatusOptions,
};
