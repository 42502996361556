import styled, { css } from "styled-components";

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .customName {
    height: 40px;

    input {
      float: left;
      padding-right: 30px;
      padding-left: 18px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }

    i {
      position: relative;
      left: -20px;
      top: -30px;
      float: right;
      cursor: pointer;
    }
  }

  .field {
    display: flex;
    align-items: center;
    width: 292px;
    height: 40px;
    margin-right: 15px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    font-size: 12px;

    div {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      font-size: 12px;
    }

    i {

    display: flex !important;
    align-items: center !important;
    }
  }

  .separator {
    width: 188px;
    height: 40px;
    margin-right: 15px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    font-size: 12px;

    div {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      font-size: 12px;
    }
  }

  .deleteButton {
    width: 40px;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;

    .deleteIcon {
      margin: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`; 

interface IDragButton {
  isgrabbing?: string;
}

export const DragButton = styled.div<IDragButton>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  border-radius: 5px;
  margin-left: -22px;
  cursor: grab;

  .iconDrag {
    font-size: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }

  ${({isgrabbing}) => isgrabbing === 'true' && css`
    cursor: grabbing;
  `}
`;
