import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin-top: 24px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => `color-mix(in srgb, ${theme.colors.warning.warningOutline}, ${theme.colors.surface.surface})`};
  background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.warning.warningContainer}, ${theme.colors.surface.surface})`} !important;

  .upBtn {
    display: flex;
    align-items: center;

    .crownIcon {
      color: ${({theme}) => theme.colors.surface.onSurface} !important;
    }

    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      color: ${({theme}) => theme.colors.surface.onSurface};
      margin-left: 6px;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    margin-top: 5px;
  }
`;

