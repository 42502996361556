import { FC, ReactNode, useMemo } from "react";
import SkeletonMain from "../LoadingMain";
import {
  GaleryContent,
  ListContent,
  GaleryMain,
  ListMain,
  ApresentationContent,
  ApresentationHeader,
  ApresentationMain,
  MidleHr,
  ApresentationCard,
} from "./styles";

interface ISkeletonBuilder {
  render: (index: number) => ReactNode;
}

export interface ILoadingAttachments {
  keyList: string;
}

const LoadingAttachments: FC<ILoadingAttachments> = ({ keyList }) => {
  const skeletonAttachment: Record<string, ISkeletonBuilder> = {
    'galery': {
      render: (index: number) => {
        return (
          <GaleryMain key={index.toString()}>
            <SkeletonMain width="205px" height="128px" />

            <div className="midle">
              <SkeletonMain width="100%" height="12px" borderRadius="2px" />

              <div className="footer">
                <SkeletonMain width="16px" height="16px" minWidth="16px" borderRadius="50%" />
                <SkeletonMain width="86.5px" height="12px" marginLeft="4px" borderRadius="2px" />
              </div>
            </div>
          </GaleryMain>
        )
      }
    },
    'list': {
      render: (index: number) => {
        return (
          <ListMain key={index.toString()}>
            <SkeletonMain width="96px" height="64px" />

            <div className="midle">
              <SkeletonMain width="100%" height="12px" borderRadius="2px" />

              <div className="footer">
                <SkeletonMain width="16px" height="16px" minWidth="16px" borderRadius="50%" />
                <SkeletonMain width="141px" height="12px" marginLeft="4px" borderRadius="2px" />
              </div>
            </div>
          </ListMain>
        )
      }
    },
    'apresentation': {
      render: (index: number) => {
        return (
          <ApresentationCard key={index.toString()}>
            <SkeletonMain width="72px" height="48px" />
          </ApresentationCard>
        )
      }
    },
  };

  const skeletonAttachmentMultiple: Record<string, number> = {
    'galery': 4,
    'list': 6,
    'apresentation': 6,
  };

  const multipleSkeletonsObras = [];
  for (let i = 0; i < skeletonAttachmentMultiple[keyList]; i++) {
    multipleSkeletonsObras.push(skeletonAttachment[keyList]);
  }

  const CurrentContent = useMemo(() => {
    if (keyList === 'galery') return GaleryContent;
    if (keyList === 'list') return ListContent;
    if (keyList === 'apresentation') return ApresentationContent;
    return GaleryContent;
  }, [keyList]);

  return (
    <CurrentContent>
      {keyList === 'apresentation' && (
        <>
          <ApresentationHeader>
            <SkeletonMain width="422px" height="243px" />

            <div className="midle">
              <SkeletonMain width="250px" height="12px" borderRadius="2px" />

              <div className="footer">
                <SkeletonMain width="16px" height="16px" minWidth="16px" borderRadius="50%" />
                <SkeletonMain width="100px" height="12px" marginLeft="4px" borderRadius="2px" />
              </div>
            </div>
          </ApresentationHeader>
          <MidleHr />
          <ApresentationMain>
            {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
          </ApresentationMain>
        </>
      )}
      {keyList !== 'apresentation' && (
        multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))
      )}
    </CurrentContent>
  )
}

export default LoadingAttachments;