import { IDisciplineResponse } from "../Obra/IDisciplineResponse";
import { IStage } from "../Obra/IObraStages";
import { IUserData } from "../User/IUserData";
import { 
  FileTypeAttachmentEnum, 
  TopicPriorityEnum, 
  TopicVisibilityEnum,
} from "./ITopicResponse";
import { Dayjs } from "dayjs";

export interface IDragAndDropDataFormat {
  activities: Record<string, ITopicDnDResponse>,
  columns: Record<string, ITopicStatusDnDFormat>,
  columnOrder: string[]; 
}

export interface ITopicStatusDnDFormat extends ITopicStatus {
  taskIds: string[];
}

export interface ITopicDnDResponse {
  Guid: string;
  Identifier: string;
  Title: string;
  Description: string | null;
  Priority: TopicPriorityEnum | null;
  Visibility: TopicVisibilityEnum;
  DueDate: Date | null;
  CreationDate: Date;
  ModifiedDate: Date;
  Stage: IStage | null;
  CommunicationTypes: ITopicCommunicationType | null;
  TopicPoints: ITopicPoint[];
  ConstructionSiteDisciplines: IDisciplineResponse[];
  TopicAssignedUsers: IUserData[];
  ConstructionSiteFk: number | null;
  CreationAuthor: IUserData | null;
  Status: ITopicStatus;
  Type: ITopicType;
  Attachments: ITopicAttachment[];
}

export interface ITopicAttachment {
  TopicAttachmentId: number;
  TopicFk: string;
  FileFk: string;
  FileURL: string;
  Thumbnail256x256Url: string;
  Thumbnail128x128Url: string;
  Thumbnail64x64Url: string;
  File: ITopicAttachmentFile;
};

export interface ITopicAttachmentFile {
  FileId: string;
  Name: string;
  Size: number;
  Type: FileTypeAttachmentEnum;
  CreationDate: Dayjs;
  CreatorUser: IUserData | null;
  TenantFk: number;
  ConstructionSiteFk: number;
};

export interface ITopicStatus {
  TopicStatusId: number;
  Name: string;
  Color: string;
  Order: number;
  TenantFk: number;
}
export interface ITopicType {
  TopicTypeId: number;
  Name: string;
  TenantFk: number;
  Tenant?: any;
}

export interface ITopicCommunicationType {
  TopicCommunicationTypeId: number;
  Name: string;
}

export interface ITopicPoint {
  TopicPointId: number;
  Name: string;
}

export enum TypeIconsEnum {
  'Tarefa' = 'activities',
  'Chamado de Obra' = 'megaphone',
  'Sugestão' = 'squareChat',
}

export const arrayTopicKeys: (keyof ITopicDnDResponse)[] = [ 
  'Visibility',
  'TopicAssignedUsers',
  'ConstructionSiteDisciplines',
  'CommunicationTypes',
  'TopicPoints',
  'Stage',
  'Type',
  'DueDate',
  'Priority',
  'Description',
  'Title',
  'Identifier',
  'Status',
];

export const topicKeysRequestFormat: Record<string, string> = {
  'Visibility': 'Visibility',
  'TopicAssignedUsers': 'TopicAssignedUsersFk',
  'ConstructionSiteDisciplines': 'ConstructionSiteDisciplinesFk',
  'CommunicationTypes': 'CommunicationTypeFk',
  'TopicPoints': 'TopicPointsFk',
  'Stage': 'StageFk',
  'Type': 'TypeFk',
  'DueDate': 'DueDate',
  'Priority': 'Priority',
  'Description': 'Description',
  'Title': 'Title',
  'Identifier': 'Identifier',
  'Status': 'StatusFk',
};

export const topicKeysResponseFormat: Record<string, string> = {
  'Visibility': 'Visibility',
  'TopicAssignedUsersFk': 'TopicAssignedUsers',
  'ConstructionSiteDisciplinesFk': 'ConstructionSiteDisciplines',
  'CommunicationTypeFk': 'CommunicationTypes',
  'TopicPointsFk': 'TopicPoints',
  'StageFk': 'Stage',
  'TypeFk': 'Type',
  'DueDate': 'DueDate',
  'Priority': 'Priority',
  'Description': 'Description',
  'Title': 'Title',
  'Identifier': 'Identifier',
  'StatusFk': 'Status',
};