import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'Components/UI/Button';
import Table from 'Components/UI/Table';
import { columnsTablePlot } from './index.helpers';
import { Icon } from 'Components/UI';
import { PlotagemActions } from 'Store/Plotagem/Plotagem.actions';
import { Skeleton } from 'antd';
import Form from 'Components/UI/Form/Form';
import Field from 'Components/UI/Field';
import schema from './index.schema';
import { Row, Col, Checkbox } from 'antd';
import { IReenviaPlotagem } from 'Data/interfaces/Plotagem/IReenviaPlotagem';
import { FooterWrapper, FormWrapper, ModalStyled, TableWrapper } from './styles';

interface IModal {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
  constructionSiteId: number;
  props: React.PropsWithChildren<any>;
}

interface IMails {
  Email: string;
  isChecked: boolean;
}

const ReenvioSolicitacao: FC<IModal> = ({
  constructionSiteId,
  props,
  visible,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [isSubmited, setIsSubmited] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState([]);
  const onChange = (selecteds: any,) => setSelectedPlot(selecteds);
  const [email, setNewEmail] = useState<string>('');
  const [nomeResponsavel, setNomeResponsavel] = useState<string>('');
  const [descricao, setDescricao] = useState<string>('');
  const [validateEmail, setValidateEmail] = useState(false);
  const [isShowRowEmail, setIsShowRowEmail] = useState(true);
  const [isStep, setStep] = useState(0);
  const [listaEmails, setlistaEmails] = useState<IMails[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>();

  const rowSelection = {
    selectedPlot,
    onChange,
  };

  useEffect(() => {
    if (props.isLoading) { return; }
    setStep(0);
    setSelectedPlot([]);
  }, [props.submitedReenviaSolicitacaoPlotagem, dispatch]);

  const onChangeMail = (checkedValues: any) => {
    setSelectedEmails(checkedValues);
  }

  useEffect(() => {
    dispatch(PlotagemActions.listPlotagemSolicitacoesDisciplinas(props.match.params.ConstructionSiteDisciplineFk));
  }, [props.match.params.ConstructionSiteDisciplineFk, dispatch]);

  const boxHiddeShow = () => {
    setIsShowRowEmail(!isShowRowEmail);
  }

  const setStates = (submited: boolean, validateEmail: boolean, e: any) => {
    e.form.reset();
    setIsSubmited(submited)
    setValidateEmail(validateEmail)
  }

  const insertEmail = () => {
    if (isSubmited && email !== '' && validateEmail) {
      listaEmails.push({ "Email": email, isChecked: false });
    }
    if (validateEmail) {
      boxHiddeShow();
    }
    setIsSubmited(false);
  }

  const selecionaEmails = () => {
    while (listaEmails.length) { listaEmails.pop(); }
    setNomeResponsavel(props.PlotagemSolicitacaoResponse.data[Number(selectedPlot)].PlotterResponsibleName);
    setDescricao(props.PlotagemSolicitacaoResponse.data[Number(selectedPlot)].Description);

    let resultadoEmails = props.PlotagemSolicitacaoResponse.data[Number(selectedPlot)].PlotterEmail.split(',');
    resultadoEmails.forEach((email: string) => {
      listaEmails.push({ 'Email': email, isChecked: false });
    });
    setlistaEmails(listaEmails);
    setStep(isStep + 1);
  }

  const reenviaPlotagem = () => {
    let mails: string[] = [];
    selectedEmails?.forEach((mail) => {
      mails.push(mail)
    });

    const plottingRequest: IReenviaPlotagem = {
      PlottingId: props.PlotagemSolicitacaoResponse.data[Number(selectedPlot)].PlottingId,
      ConstructionSiteId: constructionSiteId,
      Emails: mails
    }
    setStep(isStep + 1);
    dispatch(PlotagemActions.reenviaPlotagem(plottingRequest));
  }

  const cancelModal = () => {
    dispatch(PlotagemActions.setShowModalReenviaSolicitacaoPlotagem(false));
    setSelectedPlot([]);
    setTimeout(() => {
      setStep(0);
    }, 200);
  }

  const gerenciaBotao = () => {
    let qtEmailsSelected = selectedEmails?.length || 0;
    if ((selectedPlot.length === 1 && isStep === 0) || (qtEmailsSelected >= 1 && isStep === 1)) {
      return false;
    } else {
      return true;
    }
  }

  const mailCheck = (e: any, key: string) => {
    const emails = [...listaEmails]
    emails.forEach((email) => {
      if (email.Email == key)
        email.isChecked = e.target.checked;
    });
    setlistaEmails(emails);
  }

  const buttons = (
    <FooterWrapper>
      <Button height={37} onClick={cancelModal}>
        <span style={{ padding: '0 20px' }}>
          Cancelar
        </span>
      </Button>
      {isStep === 0 && (
        <Button
          type="primary"
          height={37}
          onClick={() => selecionaEmails()}
          loading={props.isLoading}
          disabled={gerenciaBotao()}
        >
          <span style={{ padding: '0 18px' }}>
            Continuar
          </span>
        </Button>
      )}
      {(isStep === 1 || isStep === 2) && (
        <Button
          type="primary"
          height={37}
          onClick={reenviaPlotagem}
          loading={props.isLoading}
          disabled={gerenciaBotao()}
        >
          <span style={{ padding: '0 18px' }}>
            Reenviar
          </span>
        </Button>
      )}
    </FooterWrapper>
  );

  const steps = [
    {
      content: (
        <>
          {
            props.isLoading
              ? <Skeleton active />
              : (
                <Table
                  columns={columnsTablePlot}
                  dataSource={props.PlotagemSolicitacaoResponse.data?.map((ploting: any, index: number) =>
                    ({ ...ploting, key: index }))}
                  scroll={{ y: 300 }}
                  isSpaced={true}
                  rowSelection={{
                    type: "radio",
                    ...rowSelection
                  }}
                />
              )
          }
        </>
      )
    },
    {
      content: (
        <FormWrapper>
          <div className="title">
            <Row style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Col span={1}>
                <Icon
                  color='cinzaPadrao'
                  icon='arquivar'
                  customSize={20}
                  className="icone"
                />
              </Col>
              <Col span={15}>
                <span className="spanSolicitacao">{descricao}</span>
              </Col>
              <Col span={7}>
                <span className="spanSolicitacao">{nomeResponsavel}</span>
              </Col>
            </Row>
          </div>
          <div>
            <Checkbox.Group
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
              onChange={onChangeMail}
            >
              {
                listaEmails?.map(mail => (
                  <Row
                    key={mail.Email}
                    className={mail.isChecked ? 'MailsChecked' : 'Mails'}
                  >
                    <Col span={24} >
                      <Checkbox
                        value={mail.Email}
                        className="e-mail"
                        onChange={(e) => mailCheck(e, mail.Email)}
                      >
                        <span className="e-mail">{mail.Email}</span>
                      </Checkbox>
                    </Col>
                  </Row>
                ))
              }
            </Checkbox.Group>
          </div>
          <div>
            <Form
              onSubmit={insertEmail}
              schema={schema}
              isSubmited={isSubmited}
              resetForm={true}
              className="formNovoEmail"
            >
              {
                (e: any) => (
                  <Row
                    justify="start"
                    className={isShowRowEmail ? 'NovoEmailHidde' : ''}
                  >
                    <Col span={24} >
                      <Field
                        name='Email'
                        label='E-mail'
                        placeholder='Novo e-mail'
                        value={email}
                        defaultValue={email}
                        onInput={setNewEmail}
                        className="fieldConfirmaAddEmail"
                      />
                      <Button
                        type="primary"
                        onClick={() => setStates(true, e.valid, e)}
                        width={99}
                        height={30}
                        className="btConfirmaAddEmail"
                      >
                        <span>
                          Confirmar
                        </span>
                      </Button>
                    </Col>
                  </Row>
                )
              }
            </Form>
            <Row>
              <Col span={24} >
                <span className="addNovoEmail" onClick={boxHiddeShow}>
                  + Novo e-mail
                </span>
              </Col>
            </Row>
          </div>
        </FormWrapper>
      )
    },
    {
      content: <Skeleton loading={true} paragraph={{ rows: 3 }} />,
    },

  ];

  const contentSteps = (
    <TableWrapper>
      <div>{steps[isStep].content}</div>
    </TableWrapper>
  );

  return (
    <ModalStyled
      title='Reenvio de solicitação de plotagem via e-mail'
      footerButtons={buttons}
      visible={visible}
      width={798}
      onCancel={onCancel}
      wrapClassName="modal"
      className="modalContainer"
    >
      {contentSteps}
    </ModalStyled>
  );
};

export default ReenvioSolicitacao;
