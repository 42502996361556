import React, { FC } from 'react';

import styles from './PermissaoUsuarioItem.module.scss';
import { Icon, IconName } from '../../../../../../Components/UI';
import Tooltip from '../../../../../../Components/UI/Tooltip';
import { GetAvatar, ThumbType } from '../../../../../../Utils/generateThumbnail';

interface IPermissaoUsuarioItem {
  username: string;
  userAvatar?: string;
  userAvatarThumb?: string;
  userAvatarThumbType?: ThumbType;
  privileges: number[];
  hasPlottingPermission?: boolean;
  index: number;
  onChange: (values: any) => void;
}

const buttonsPermission = ['Visualizar', 'Download', 'Upload', 'Editar'];

const PermissaoUsuarioItem: FC<IPermissaoUsuarioItem> = ({
  username,
  userAvatar,
  userAvatarThumb,
  userAvatarThumbType,
  privileges,
  hasPlottingPermission,
  index,
  onChange
}) => {
  const changeHasPlottingPermission = () => {
    onChange({
      privileges,
      index,
      hasPlottingPermission: !hasPlottingPermission
    });
  };

  const changePermissionLevel = (level: number) => {
    if (level !== 0) {
      const permissionIndex = privileges.indexOf(level);
      permissionIndex >= 0
        ? privileges.splice(permissionIndex, 1)
        : privileges.push(level);
      onChange({
        index,
        hasPlottingPermission,
        privileges
      });
    }
  };

  return (
    <div className={styles['permissoesWrapper']}>
      <div className={`${styles['container']} ${styles['containerUsername']}`}>
        <GetAvatar
          name={username}
          shape="circle"
          src={userAvatar}
          thumbSrc={userAvatarThumb}
          thumbType={userAvatarThumbType}
        />
        <span className={styles['username']}>{username}</span>
      </div>

      {buttonsPermission.map((name, i) => (
        <Tooltip title={name} key={`buttonPermission-${i}`}>
          <div
            className={`${styles['container']}
              ${styles['containerButton']}`}
            onClick={() => changePermissionLevel(i)}
          >
            <Icon
              icon={name.toLocaleLowerCase() as IconName}
              customSize={20}
              color={privileges.includes(i) ? 'primary' : 'cinzaPadrao'}
            />
            <p className={privileges.includes(i) ? styles['selected'] : ''}>
              {name.toLocaleUpperCase()}
            </p>
          </div>
        </Tooltip>
      ))}
      <Tooltip title="Plotagem">
        <div
          className={`${styles['container']}
          ${styles['containerButton']}`}
          onClick={changeHasPlottingPermission}
        >
          <Icon
            icon="menuPlotagem"
            customSize={20}
            color={hasPlottingPermission ? 'primary' : 'cinzaPadrao'}
          />
          <p className={hasPlottingPermission ? styles['selected'] : ''}>PLOTAGEM</p>
        </div>
      </Tooltip>
    </div>
  );
};

export default PermissaoUsuarioItem;
