import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
`;

interface ICardOption {
  isActive: string;
}

export const CardOption = styled.div<ICardOption>`
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 16px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  cursor: pointer;

  :hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &+& {
    margin-left: 8px;
  }

  .cardOptionsIcon {
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;

    ${({isActive, theme}) => isActive === 'true' && css`
      color: ${theme.colors.surface.onSurface} !important;
    `}
  }

  .cardOptionsText {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-left: 6px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  ${({isActive, theme}) => isActive === 'true' && css`
    background-color: ${`color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
  `}
`;
