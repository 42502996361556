import * as Yup from 'yup';

const schema = Yup.object().shape({

  Email: Yup.string()
  .email('Digite um endereço de email válido'),

});

export default schema;
