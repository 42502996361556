import { FC } from "react";
import Ellipsis from "Components/UI/Ellipsis";
import { Icon, IconName } from "Components/UI/Icon";
import { iconFileByExtension } from "Utils/Icons";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import Input from "Components/UI/Input";
import { IPanelsFileContent } from "Data/interfaces/Panels/IPanelsFileContent";
import { FilesSelectedWrapper, RowFilesSelected } from "../styles";

interface ISelectedFile {
  disciplineFolder: IDisciplineViewerList;
  item: IPanelsFileContent;
  panelName: string;
  onPanelName: (value: string) => void;
  onIconLeft: (name: string) => IconName;
  onClear: () => void;
}

const SelectedFile: FC<ISelectedFile> = ({
  item,
  disciplineFolder,
  panelName,
  onPanelName,
  onIconLeft,
  onClear,
}) => {
  return (
    <FilesSelectedWrapper>
      <span className="title">
        Nome do painel <span className="warn">*</span>
      </span>
      <Input
        label=""
        placeholder="Nome do Painel..."
        value={panelName}
        onInput={onPanelName}
        inputClassName="inputFiles"
      />
      <span className="titleFile">
        Arquivo <span className="warn">*</span>
      </span>
      <RowFilesSelected>
        <div className="leftRowFilesSelected">
          <Icon
            icon={item.IsFolder
              ? 'arquivar'
              : (item.inObsoleteFolder
                ? 'obsoleteArchive'
                : iconFileByExtension(item.Extension)
              )
            }
            customSize={18}
            className="iconLeftRowFilesSelected"
          />
          <span className="leftRowFilesSelectedMain">
            <Ellipsis
              phrase={item.Name}
              maxLength={57}
              className="filesSelectedText"
            />
            <span className="leftRowFilesSelectedMainRight">
              <Icon
                icon={onIconLeft(disciplineFolder.CustomName || disciplineFolder.Discipline.Name)}
                customSize={10}
                className="iconleftRowFilesSelectedMainRight"
              />
              <Ellipsis
                phrase={disciplineFolder.CustomName || disciplineFolder.Discipline.Name}
                maxLength={27}
                className="textleftRowFilesSelectedMainRight"
              />
            </span>
          </span>
        </div>
        <Icon
          icon="trocarStatus"
          customSize={12}
          className="iconChange"
          onClick={onClear}
        />
      </RowFilesSelected>
    </FilesSelectedWrapper>
  )
};

export default SelectedFile;
