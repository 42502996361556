import { IHistoryActivityRequestList, IHistoryActivityResponseList } from 'Data/interfaces/History/IHistoryActivityApi';
import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import {
  IHistoryFileRequestList,
  IHistoryFileResponseList,
} from 'Data/interfaces/History/IHistoryFileApi';

export enum HistoryActionKeys {
  SHOW_HISTORY_DRAWER = 'SHOW_HISTORY_DRAWER',

  LIST_HISTORY_FILE_REQUEST = 'LIST_HISTORY_FILE_REQUEST',
  LIST_HISTORY_FILE_SUCCESS = 'LIST_HISTORY_FILE_SUCCESS',
  LIST_HISTORY_FILE_FAILURE = 'LIST_HISTORY_FILE_FAILURE',

  LIST_HISTORY_ACTIVITY_REQUEST = 'LIST_HISTORY_ACTIVITY_REQUEST',
  LIST_HISTORY_ACTIVITY_SUCCESS = 'LIST_HISTORY_ACTIVITY_SUCCESS',
  LIST_HISTORY_ACTIVITY_FAILURE = 'LIST_HISTORY_ACTIVITY_FAILURE',
}

export const HistoryActions = {
  showHistoryDrawer: (data: boolean): ShowHistoryDrawerAction =>
    createAction(HistoryActionKeys.SHOW_HISTORY_DRAWER, data),

  listHistoryFileRequest: (data: IHistoryFileRequestList): ListHistoryFileRequestAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_FILE_REQUEST, data),
  listHistoryFileSuccess: (data: IHistoryFileResponseList): ListHistoryFileSuccessAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_FILE_SUCCESS, data),
  listHistoryFileFailure: (data: string): ListHistoryFileFailureAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_FILE_FAILURE, data),

  listHistoryActivityRequest: (data: IHistoryActivityRequestList): ListHistoryActivityRequestAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_ACTIVITY_REQUEST, data),
  listHistoryActivitySuccess: (data: IHistoryActivityResponseList): ListHistoryActivitySuccessAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_ACTIVITY_SUCCESS, data),
  listHistoryActivityFailure: (data: string): ListHistoryActivityFailureAction =>
    createAction(HistoryActionKeys.LIST_HISTORY_ACTIVITY_FAILURE, data),
};

export type HistoryActionUnion = ActionsUnion<typeof HistoryActions>;

export type ShowHistoryDrawerAction = Action<HistoryActionKeys.SHOW_HISTORY_DRAWER, boolean>;

export type ListHistoryFileRequestAction = Action<HistoryActionKeys.LIST_HISTORY_FILE_REQUEST, IHistoryFileRequestList>;
export type ListHistoryFileSuccessAction = Action<HistoryActionKeys.LIST_HISTORY_FILE_SUCCESS, IHistoryFileResponseList>;
export type ListHistoryFileFailureAction = Action<HistoryActionKeys.LIST_HISTORY_FILE_FAILURE, string>;

export type ListHistoryActivityRequestAction = Action<HistoryActionKeys.LIST_HISTORY_ACTIVITY_REQUEST, IHistoryActivityRequestList>;
export type ListHistoryActivitySuccessAction = Action<HistoryActionKeys.LIST_HISTORY_ACTIVITY_SUCCESS, IHistoryActivityResponseList>;
export type ListHistoryActivityFailureAction = Action<HistoryActionKeys.LIST_HISTORY_ACTIVITY_FAILURE, string>;
