import { FC } from "react";
import { Icon, IconName } from "Components/UI";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { List } from "antd";
import HighlighterWords from "Components/UI/HighlightWords";
import { IHandleOpenFolder } from "..";

interface ISelectFileListDisciplines {
  filteredDisciplines: any;
  onDisciplineFolder: (value: IDisciplineViewerList | null) => void;
  onOpenFolder: (data: IHandleOpenFolder) => void;
  onIconLeft: (name: string) => IconName;
  searchDisciplineFile: string;
}

const SelectFileListDisciplines: FC<ISelectFileListDisciplines> = ({
  filteredDisciplines,
  onDisciplineFolder,
  onOpenFolder,
  onIconLeft,
  searchDisciplineFile,
}) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={filteredDisciplines}
      split={false}
      renderItem={(item: any) => (
        <List.Item
          className="rowDisciplinesList"
          onClick={() => {
            onDisciplineFolder(item);
            onOpenFolder({
              folderId: item.DisciplineApiFolderId,
              ConstructionSiteDisciplinesId: item.ConstructionSiteDisciplinesId,
            });
          }}
        >
          <div className="leftRowDisciplinesList">
            <Icon
              icon={onIconLeft(item.CustomName || item.Discipline.Name)}
              customSize={18}
              className="iconLeftRowDisciplinesList"
            />
            <HighlighterWords
              text={item.CustomName || item.Discipline.Name}
              wordSearched={searchDisciplineFile}
              className="disciplineListText"
            />
          </div>
          <Icon
            icon="retroceder"
            customSize={8}
            className="iconExpanded"
          />
        </List.Item>
      )}
    />
  )
}

export default SelectFileListDisciplines;
