import styled from "styled-components";

interface IContainer {
  marginTop?: number;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;

export const Title = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
`;

interface IMain {
  direction?: string;
  edit?: boolean;
}

export const Main = styled.div<IMain>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  margin-top: 24px;

  .changeImageWrapper {
    position: relative;
    width: 197px;
    height: 126px;
    background: ${({ theme }) => theme.colors.primary.primary};
    opacity: 0.75;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary.onPrimary};
    overflow: hidden;
    cursor: ${({ edit }) => edit ? 'pointer' : 'default'};

    .changeImageImg {
      width: 197px;
      height: 126px;
      object-fit: contain;
    }

    .changeImageBlur {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: ${({ theme }) => `linear-gradient(${theme.colors.primary.primary}99, ${theme.colors.primary.primary}99)`};
    }

    .changeImageContent {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .changeImageLabel {      
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: ${({ theme }) => theme.colors.primary.onPrimary};
      }
    }
  }

  .formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;

    .formWrapperTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .containerName {
      width: 100%;

      .fieldName {
        margin-top: 5px;
        width: 100%;
      }
    }

    .containerType {
      margin-left: 16px;

      .fieldType {
        margin-top: 5px;
      }
    }

    .containerAdrress {

      .fieldAdrress {
        margin-top: 5px;
      }
    }

    .fieldLabel {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }
  }
  
  .danger {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.danger.danger};
  }
  
  .footer {
    width: auto;

    .archiveBtn {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 24px;
  
      .archiveIcon {
        color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
        margin-right: 4px;
        margin-left: -4px;
      }
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin: 24px 0;
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .saveBtn {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  .cancelBtn {
    margin-left: 6px;
  }
`;
