import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from 'antd';
import VersionItem from './VersionItem'

import styles from './Drawer.module.scss'
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';

import { formatDateByTime } from 'Utils/DateUtils';

type DrawerProps = {
  onclose: () => void,
  visible: boolean,
}

type TitleProps = {
  numberOfRevisions: number;
}

const DrawerTitle = ({ numberOfRevisions }: TitleProps) => {
  return (
    <div className={styles['title']}>
      <h1>Histórico de versões</h1>
      <p>{numberOfRevisions} versões</p>
    </div>
  )
}

const VersionHistory = ({ onclose, visible }: DrawerProps) => {
  const version: INotificationData[] = useSelector((state: IGlobalReducerState) => state.notification.fileVersionHistory);

  const headerStyles: CSSProperties = {
    backgroundColor: '#F8FAFB',
    padding: '16px 24px 4px'
  }

  return (
    <Drawer
      title={<DrawerTitle numberOfRevisions={version.length} />}
      placement="right"
      closable
      headerStyle={headerStyles}
      onClose={onclose}
      width={450}
      visible={visible}
      className={styles['Drawer']}
    >
      {version.map(({ AvatarFileNameUser, UserName, ItemName, Description, DateCreateUtc, ItemUrl }: INotificationData, idx) => (
        <section className={styles['versionSection']} key={`key-history-version-${ItemName}`}>
          <p className={styles['day']}>{formatDateByTime(new Date(DateCreateUtc))}</p>

          <VersionItem
            avatarUrl={AvatarFileNameUser}
            username={UserName}
            filename={ItemName}
            description={Description}
            date={DateCreateUtc}
            itemUrl={ItemUrl}
            currentVersion={idx === 0 ? true : false}
          />
        </section>
      ))}
    </Drawer>
  )
}

export default VersionHistory;
