import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 224px);
  display: flex;
  flex-direction: row;
  padding: 16px;
  padding-bottom: 0;
`;

export const DrawerLocal = styled.div`
  min-width: 200px;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  background-color: ${({theme}) => theme.colors.surface.surface};
  padding: 12px;
`;

interface IDrawerItem {
  active?: boolean;
  disabled?: boolean;
}

export const DrawerItem = styled.div<IDrawerItem>`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 0 4px;
  cursor: pointer;

  &+& {
    margin-top: 12px;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`};
  }

  .iconDrawer {
    color: ${({theme}) => theme.colors.secondary.onSecondaryVariant} !important;
  }
  
  .textDrawer {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({theme}) => theme.colors.secondary.onSecondary};
    margin-left: 6px;
  }

  .badgeDrawer {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};
    padding: 0 4px;
    margin-left: 8px;
    
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: ${({theme}) => theme.colors.primary.primary};
  }

  ${({active}) => active && css`
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};

    .iconDrawer {
      color: ${({theme}) => theme.colors.primary.primary} !important;
    }
    
    .textDrawer {
      color: ${({theme}) => theme.colors.primary.primary};
    }
  `}

  ${({disabled}) => disabled && css`
    background-color: ${({ theme }) => theme.colors.surface.surface};
    cursor: default;
    
    :hover {
      background-color: ${({ theme }) => theme.colors.surface.surface};
    }

    .iconDrawer {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
    }
    
    .textDrawer {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    .badgeDrawer {
      background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};

      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  margin-left: 16px;
  overflow-y: scroll;
`;
