import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';

export enum HelperActionKeys {
  UPLOAD_IMGS_ONERROR_SET = 'UPLOAD_IMGS_ONERROR_SET',
  UPLOAD_IMGS_ONERROR_DEL = 'UPLOAD_IMGS_ONERROR_DEL',
}

export const HelperActions = {
  uploadImgsOnErrorSet: (setId: string): UploadImgsOnErrorSetAction =>
    createAction(HelperActionKeys.UPLOAD_IMGS_ONERROR_SET, setId),
  uploadImgsOnErrorDel: (delId: string): UploadImgsOnErrorDelAction =>
    createAction(HelperActionKeys.UPLOAD_IMGS_ONERROR_DEL, delId),
};

export type HelperActionUnion = ActionsUnion<typeof HelperActions>;

export type UploadImgsOnErrorSetAction = Action<HelperActionKeys.UPLOAD_IMGS_ONERROR_SET, string>;
export type UploadImgsOnErrorDelAction = Action<HelperActionKeys.UPLOAD_IMGS_ONERROR_DEL, string>;
