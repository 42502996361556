import React, { Dispatch, FC, SetStateAction } from 'react';

import styles from './ObraArquivada.module.scss';
import { IArchivedStatusRequest } from '../../../Data/interfaces/Obra/IArchivedStatusRequest';
import { useDispatch } from 'react-redux';
import { ObraActions } from '../../../Store/Obra/Obra.actions';
import Modal from '../../../Components/UI/Modal/Modal';
import Button from '../../../Components/UI/Button';

export interface IObraArquivada {
  avatar: React.ReactNode;
  name: string;
  ConstructionSiteId: number;
  currentPeperUser?: number;
  activeObras: number;
  maxObras?: number;
  setModalContaUpgradeVisible: Dispatch<SetStateAction<boolean>>;
}

const ObraArquivada: FC<IObraArquivada> = ({ 
  avatar, 
  name, 
  ConstructionSiteId, 
  currentPeperUser, 
  activeObras,
  maxObras, 
  setModalContaUpgradeVisible
 }) => {
  const dispatch = useDispatch();
  const [isAlertModal, setIsAlertModal] = React.useState(false);

  const desarquivarObra =() => {
    if (maxObras && activeObras >= maxObras) {
      setModalContaUpgradeVisible(true);
    
    } else {
      setIsAlertModal(true);
    }
  };

  const confirmDesarquivarObra = () => {
    const status: IArchivedStatusRequest = {
      ConstructionSiteId,
      isArchived: false,
    };
    dispatch(ObraActions.updateArchivedStatus(status));
  };

  const buttons = (
    <div>
      <Button 
        height={37}
        onClick={() => setIsAlertModal(false)}
      >
        <span style={{ padding: '0 18px' }}>
          Cancelar
        </span>
      </Button>
      <Button 
        type="primary" 
        height={37}
        onClick={() => confirmDesarquivarObra()}
      >
        <span style={{ padding: '0 18px' }}>
          Sim, desarquivar obra
        </span>
      </Button>
    </div>
  );

  return (
    <div className={styles['ObraArquivada']}>
      <div>
        {avatar}
        <strong>{name}</strong>
      </div>
      {currentPeperUser === 1 || currentPeperUser === 7 ? (
      <span onClick={() => desarquivarObra()} className={styles['linkDesarquivar']}>Desarquivar</span>
      ) : (
        <></>
      )}
      <Modal 
        title='Exclusão de arquivo' 
        footerButtons={buttons} 
        visible={isAlertModal} 
        width={450} 
        onCancel={() => setIsAlertModal(false)}
      >
        <div className={styles['textWrap']} >
          Você tem certeza que deseja desarquivar a obra <strong>{name}</strong> ?
        </div>

      </Modal>
    </div>

    
  );
};

export default ObraArquivada;
