import { HistoryActivityContentRecord } from "Data/interfaces/History/IHistoryActivityRecord";
import { Icon } from "Components/UI";
// import { GetAvatar } from "Utils/generateThumbnail";
// import { getDisciplineIcon } from "Utils/ObraUtils";
// import { TopicPriorityEnum, TopicVisibilityEnum } from "Data/interfaces/Activities/ITopicResponse";
// import { handlePriority, handleVisibility } from "Utils/ActivitiesUtils";
import { HistoryActivityActionEnum } from "Data/interfaces/History/IHistoryActivityApi";
import {
  // FileTitleContainer,
  FileStatusValue,
  FilesContentContainer,
  FilesStatusContainer,
  // UsersContainer,
  // FileDueDateValue,
  // PontisContainer,
  // ImgContainer,
} from "../styles";

export const activitiesContentOptions: HistoryActivityContentRecord = {
  [HistoryActivityActionEnum.Created]: () => null,
  [HistoryActivityActionEnum.StatusChanged]: ({ item, activitiesStatusInfo }) => {
    const oldOpt = activitiesStatusInfo?.find(opt => {
      return opt.Name?.toLowerCase() === item.From.DisplayValue?.toLowerCase();
    });
    const newOpt = activitiesStatusInfo?.find(opt => {
      return opt.Name?.toLowerCase() === item.To.DisplayValue?.toLowerCase();
    });

    return (
      <FilesContentContainer>
        <FilesStatusContainer>
          <FileStatusValue
            customColor={oldOpt?.Color}
            customBGColor={`${oldOpt?.Color}1F`}
          >
            {item.From.DisplayValue}
          </FileStatusValue>
          <Icon
            icon="rightArrow"
            customSize={16}
            className="arrowIcon"
          />
          <FileStatusValue
            customColor={newOpt?.Color}
            customBGColor={`${newOpt?.Color}1F`}
          >
            {item.To.DisplayValue}
          </FileStatusValue>
        </FilesStatusContainer>
      </FilesContentContainer>
    )
  },
  // [HistoryActivityActionEnum.title]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileTitleContainer isactive="true">
  //         <span className="assistantText">{item?.newValue}</span>
  //       </FileTitleContainer>
  //       <FileTitleContainer>
  //         <span className="assistantText">{item?.oldValue}</span>
  //       </FileTitleContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.description]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileTitleContainer isactive="true">
  //         <span className="assistantText">{item?.newValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //       <FileTitleContainer>
  //         <span className="assistantText">{item?.oldValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.assignedUsers]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       {item.newValue && (
  //         <UsersContainer>
  //           {typeof item.newValue === 'string' ? (
  //             <div className="usersContent">
  //               <span className="avatar">
  //                 <GetAvatar name={item.newValue} size={16} />
  //               </span>
  //               <span className="name">{item.newValue}</span>
  //             </div>
  //           ) : (
  //             <>
  //               {item.newValue?.length > 0 && item.newValue.map(value => (
  //                 <div className="usersContent">
  //                   <span className="avatar">
  //                     <GetAvatar name={value} size={16} />
  //                   </span>
  //                   <span className="name">{value}</span>
  //                 </div>
  //               ))}
  //             </>
  //           )}
  //         </UsersContainer>
  //       )}
  //       {item.oldValue && (
  //         <UsersContainer isold>
  //           {typeof item.oldValue === 'string' ? (
  //             <div className="usersContent">
  //               <span className="avatar">
  //                 <GetAvatar name={item.oldValue} size={16} />
  //               </span>
  //               <span className="name">{item.oldValue}</span>
  //             </div>
  //           ) : (
  //             <>
  //               {item.oldValue?.length > 0 && item.oldValue.map(value => (
  //                 <div className="usersContent">
  //                   <span className="avatar">
  //                     <GetAvatar name={value} size={16} />
  //                   </span>
  //                   <span className="name">{value}</span>
  //                 </div>
  //               ))}
  //             </>
  //           )}
  //         </UsersContainer>
  //       )}
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.disciplines]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       {item.newValue && (
  //         <UsersContainer>
  //           {typeof item.newValue === 'string' ? (
  //             <div className="disciplinesContent">
  //               <Icon
  //                 icon={getDisciplineIcon(item.newValue)}
  //                 className="iconDisciplina"
  //               />
  //               <span className="name">{item.newValue}</span>
  //             </div>
  //           ) : (
  //             <>
  //               {item.newValue?.length > 0 && item.newValue.map(value => (
  //                 <div key={value} className="disciplinesContent">
  //                   <Icon
  //                     icon={getDisciplineIcon(value)}
  //                     className="iconDisciplina"
  //                   />
  //                   <span className="name">{value}</span>
  //                 </div>
  //               ))}
  //             </>
  //           )}
  //         </UsersContainer>
  //       )}
  //       {item.oldValue && (
  //         <UsersContainer isold>
  //           {typeof item.oldValue === 'string' ? (
  //             <div className="disciplinesContent">
  //               <Icon
  //                 icon={getDisciplineIcon(item.oldValue)}
  //                 className="iconDisciplina"
  //               />
  //               <span className="name">{item.oldValue}</span>
  //             </div>
  //           ) : (
  //             <>
  //               {item.oldValue?.length > 0 && item.oldValue.map(value => (
  //                 <div key={value} className="disciplinesContent">
  //                   <Icon
  //                     icon={getDisciplineIcon(value)}
  //                     className="iconDisciplina"
  //                   />
  //                   <span className="name">{value}</span>
  //                 </div>
  //               ))}
  //             </>
  //           )}
  //         </UsersContainer>
  //       )}
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.dueDate]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FilesStatusContainer>
  //         <FileDueDateValue isold>
  //           {item.oldValue && (
  //             <Icon
  //               icon="calendar"
  //               customSize={18}
  //               className="iconDate"
  //             />
  //           )}
  //           <span className="value">
  //             {item.oldValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //         <Icon
  //           icon="rightArrow"
  //           customSize={16}
  //           className="arrowIcon"
  //         />
  //         <FileDueDateValue>
  //           {item.newValue && (
  //             <Icon
  //               icon="calendar"
  //               customSize={18}
  //               className="iconDate"
  //             />
  //           )}
  //           <span className="value">
  //             {item.newValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //       </FilesStatusContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.visibility]: ({ item, theme }) => {
  //   const visibilityEnum: Record<string, TopicVisibilityEnum> = {
  //     'Público': TopicVisibilityEnum.Public,
  //     'Privado': TopicVisibilityEnum.Private,
  //     'Rascunho': TopicVisibilityEnum.Draft,
  //   };

  //   return (
  //     <FilesContentContainer>
  //       <FilesStatusContainer>
  //         <FileDueDateValue isold>
  //           {typeof item.oldValue === 'string' && theme && (
  //             <Icon
  //               icon={handleVisibility(visibilityEnum[item.oldValue], theme).icon}
  //               customSize={handleVisibility(visibilityEnum[item.oldValue], theme).size}
  //               className="iconVisibility"
  //             />
  //           )}
  //           <span className="value">
  //             {item.oldValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //         <Icon
  //           icon="rightArrow"
  //           customSize={16}
  //           className="arrowIcon"
  //         />
  //         <FileDueDateValue>
  //           {typeof item.newValue === 'string' && theme && (
  //             <Icon
  //               icon={handleVisibility(visibilityEnum[item.newValue], theme).icon}
  //               customSize={handleVisibility(visibilityEnum[item.newValue], theme).size}
  //               customColor={handleVisibility(visibilityEnum[item.newValue], theme).color}
  //               className="iconVisibility"
  //             />
  //           )}
  //           <span className="value">
  //             {item.newValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //       </FilesStatusContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.priority]: ({ item, theme }) => {
  //   const visibilityEnum: Record<string, TopicPriorityEnum> = {
  //     'Alta': TopicPriorityEnum.High,
  //     'Média': TopicPriorityEnum.Medium,
  //     'Baixa': TopicPriorityEnum.Low,
  //   };

  //   return (
  //     <FilesContentContainer>
  //       <FilesStatusContainer>
  //         <FileDueDateValue isold>
  //           {typeof item.oldValue === 'string' && theme && (
  //             <Icon
  //               icon={handlePriority(visibilityEnum[item.oldValue], theme).icon}
  //               customSize={handlePriority(visibilityEnum[item.oldValue], theme).size}
  //               className="iconPriority"
  //             />
  //           )}
  //           <span className="value">
  //             {item.oldValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //         <Icon
  //           icon="rightArrow"
  //           customSize={16}
  //           className="arrowIcon"
  //         />
  //         <FileDueDateValue>
  //           {typeof item.newValue === 'string' && theme && (
  //             <Icon
  //               icon={handlePriority(visibilityEnum[item.newValue], theme).icon}
  //               customSize={handlePriority(visibilityEnum[item.newValue], theme).size}
  //               customColor={handlePriority(visibilityEnum[item.newValue], theme).color}
  //               className="iconPriority"
  //             />
  //           )}
  //           <span
  //             className="value"
  //             style={{
  //               color: typeof item.newValue === 'string' && theme
  //                 ? handlePriority(visibilityEnum[item.newValue], theme).color
  //                 : undefined
  //             }}
  //           >
  //             {item.newValue || 'Vazio'}
  //           </span>
  //         </FileDueDateValue>
  //       </FilesStatusContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.stage]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileTitleContainer isactive="true">
  //         <span className="assistantText">{item?.newValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //       <FileTitleContainer>
  //         <span className="assistantText">{item?.oldValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.points]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       {item.newValue && (
  //         <PontisContainer>
  //           {typeof item.newValue === 'string' ? (
  //             <span className="name">{item.newValue}</span>
  //           ) : (
  //             <>
  //               {item.newValue?.length > 0 && item.newValue.map(value => (
  //                 <span key={value} className="name">{value}</span>
  //               ))}
  //             </>
  //           )}
  //         </PontisContainer>
  //       )}
  //       {item.oldValue && (
  //         <PontisContainer isold>
  //           {typeof item.oldValue === 'string' ? (
  //             <span className="name">{item.oldValue}</span>
  //           ) : (
  //             <>
  //               {item.oldValue?.length > 0 && item.oldValue.map(value => (
  //                 <span key={value} className="name">{value}</span>
  //               ))}
  //             </>
  //           )}
  //         </PontisContainer>
  //       )}
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.communication]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       <FileTitleContainer isactive="true">
  //         <span className="assistantText">{item?.newValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //       <FileTitleContainer>
  //         <span className="assistantText">{item?.oldValue || 'Vazio'}</span>
  //       </FileTitleContainer>
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.addAttachments]: ({ item }) => {
  //   return (
  //     <FilesContentContainer>
  //       {item.newValue && typeof item.newValue === 'string' && (
  //         <ImgContainer>
  //           <img alt="" src={img} className="img" />
  //         </ImgContainer>
  //       )}
  //       {!item.newValue && (
  //         <ImgContainer isempty>
  //           <Icon 
  //             icon="alerta"
  //             customSize={16}
  //             className="iconEmptyAlert"
  //           />
  //           <span className="emptyText">Anexo excluído</span>
  //         </ImgContainer>
  //       )}
  //     </FilesContentContainer>
  //   )
  // },
  // [HistoryActivityActionEnum.deleteAttachments]: () => null,
};
