import styled from "styled-components";

export const FormWrapper = styled.div`
  padding: 20px;
`;

export const FormContainer = styled.div`
  .rowName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .selectRole {
      width: 156px !important;
      margin-right: 5px !important;
    }
  }

  .rowIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    .iconContainer {
      padding: 4px;
      border-radius: 4px;
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryPressed}, ${theme.colors.surface.surface})`} !important;
      margin-left: 16px;
      
      .icon {
        color: ${({ theme }) => theme.colors.primary.primary} !important;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-top: 4px;

  .checkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    .checkText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      margin-left: 6px;
    }
  }

  .containerField {
    margin-left: 8px;
  }

  .addButton {
    height: 36px !important;
    margin-left: 8px;

    .addIconButton {
      color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
    }
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 4px;

  .column1 {
    width: 72px;
    margin-left: 12px;
  }
  .column2 {
    width: 100%;
    margin-left: 12px;
  }

  .columnText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

export const Main = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

export const Card = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 8px;

  .row1 {
    width: 72px;
    margin-left: 12px;
  }

  .row2 {
    width: 100%;
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
  
  .tagDefault {
    border-radius: 4px;
    padding: 2px;
    margin-left: 6px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};

    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .removeButton {
    height: 24px !important;
    width: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`;
