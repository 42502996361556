import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

interface ISpinCustom {
  color?: string;
  size?: number;
}

const SpinCustom: FC<ISpinCustom> = ({
  color,
  size,
}) => {
  const theme = useSelector(getTheme);

  const antIcon = (
    <LoadingOutlined rev=""
      style={{ 
        color: color ? color : theme.colors.primary.primary, 
        fontSize: size ? size : 22, 
      }} 
      spin
    />
  )

  return <Spin indicator={antIcon}/>;
}

export default SpinCustom;
