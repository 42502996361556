import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { INomenclatureData, ICampoVersao, IVersionField, IExtensionData, IBindSuportedFileExtensions } from '../../Data/interfaces/Nomenclatura/INomenclatureData';

export enum NomenclaturaActionKeys {
  CREATE_NOMENCLATURA_REQUEST = 'CREATE_NOMENCLATURA_REQUEST',
  CREATE_NOMENCLATURA_SUCCESS = 'CREATE_NOMENCLATURA_SUCCESS',
  CREATE_NOMENCLATURA_FAILED = 'CREATE_NOMENCLATURA_FAILED',

  GET_NOMENCLATURA_REQUEST = 'GET_NOMENCLATURA_REQUEST',
  GET_NOMENCLATURA_SUCCESS = 'GET_NOMENCLATURA_SUCCESS',
  GET_NOMENCLATURA_FAILED = 'GET_NOMENCLATURA_FAILED',

  GET_CAMPO_VERSAO_REQUEST = 'GET_CAMPO_VERSAO_REQUEST',
  GET_CAMPO_VERSAO_SUCCESS = 'GET_CAMPO_VERSAO_SUCCESS',
  GET_CAMPO_VERSAO_FAILED = 'GET_CAMPO_VERSAO_FAILED',

  REMOVE_MESSAGES_NOMENCLATURA = 'REMOVE_MESSAGES_NOMENCLATURA',

  GET_EXTENSOES_REQUEST = 'GET_EXTENSOES_REQUEST',
  GET_EXTENSOES_SUCCESS = 'GET_EXTENSOES_SUCCESS',
  GET_EXTENSOES_FAILED = 'GET_EXTENSOES_FAILED',

  SET_EXTENSOES_REQUEST = 'SET_EXTENSOES_REQUEST',
  SET_EXTENSOES_SUCCESS = 'SET_EXTENSOES_SUCCESS',
  SET_EXTENSOES_FAILED = 'SET_EXTENSOES_FAILED',
}

export const NomenclaturaActions = {
  create: (nomenclature: INomenclatureData): CreateAction =>
    createAction(NomenclaturaActionKeys.CREATE_NOMENCLATURA_REQUEST, nomenclature),
  createSuccess: (nomenclature: INomenclatureData): CreateSuccessAction =>
    createAction(NomenclaturaActionKeys.CREATE_NOMENCLATURA_SUCCESS, nomenclature),
  createFailure: (err: string): CreateFailureAction =>
    createAction(NomenclaturaActionKeys.CREATE_NOMENCLATURA_FAILED, err),

  getNomenclature: (): GetNomenclatureAction =>
    createAction(NomenclaturaActionKeys.GET_NOMENCLATURA_REQUEST),
  getNomenclatureSuccess: (nomenclature: INomenclatureData): GetNomenclatureSuccessAction =>
    createAction(NomenclaturaActionKeys.GET_NOMENCLATURA_SUCCESS, nomenclature),
  getNomenclatureFailure: (err: string): GetNomenclatureFailureAction =>
    createAction(NomenclaturaActionKeys.GET_NOMENCLATURA_FAILED, err),

  getCampoVersao: (): GetCampoVersaoAction =>
    createAction(NomenclaturaActionKeys.GET_CAMPO_VERSAO_REQUEST),
  getCampoVersaoSuccess: (CampoVersao: ICampoVersao[]): GetCampoVersaoSuccessAction =>
    createAction(NomenclaturaActionKeys.GET_CAMPO_VERSAO_SUCCESS, CampoVersao),
  getCampoVersaoFailure: (err: string): GetCampoVersaoFailureAction =>
    createAction(NomenclaturaActionKeys.GET_CAMPO_VERSAO_FAILED, err),

  removeMensages: (): removeMensagesAction =>
    createAction(NomenclaturaActionKeys.REMOVE_MESSAGES_NOMENCLATURA),

  getExtensions: (): GetExtensionsAction =>
    createAction(NomenclaturaActionKeys.GET_EXTENSOES_REQUEST),
  getExtensionsSuccess: (Extensions: IExtensionData[]): GetExtensionsSuccessAction =>
    createAction(NomenclaturaActionKeys.GET_EXTENSOES_SUCCESS, Extensions),
  getExtensionsFailure: (err: string): GetExtensionsFailureAction =>
    createAction(NomenclaturaActionKeys.GET_EXTENSOES_FAILED, err),

  setExtensions: (extensions: IBindSuportedFileExtensions[]): SetExtensionsAction =>
    createAction(NomenclaturaActionKeys.SET_EXTENSOES_REQUEST, extensions),
  setExtensionsSuccess: (extensions: IExtensionData[]): SetExtensionsSuccessAction =>
    createAction(NomenclaturaActionKeys.SET_EXTENSOES_SUCCESS, extensions),
  setExtensionseFailure: (err: string): SetExtensionsFailureAction =>
    createAction(NomenclaturaActionKeys.SET_EXTENSOES_FAILED, err),
};

export type NomenclaturaActionUnion = ActionsUnion<typeof NomenclaturaActions>;

export type CreateAction = Action<NomenclaturaActionKeys.CREATE_NOMENCLATURA_REQUEST, INomenclatureData>;
export type CreateSuccessAction = Action<NomenclaturaActionKeys.CREATE_NOMENCLATURA_SUCCESS, INomenclatureData>;
export type CreateFailureAction = Action<NomenclaturaActionKeys.CREATE_NOMENCLATURA_FAILED, string>;

export type GetNomenclatureAction = Action<NomenclaturaActionKeys.GET_NOMENCLATURA_REQUEST>;
export type GetNomenclatureSuccessAction = Action<NomenclaturaActionKeys.GET_NOMENCLATURA_SUCCESS, INomenclatureData>;
export type GetNomenclatureFailureAction = Action<NomenclaturaActionKeys.GET_NOMENCLATURA_FAILED, string>;

export type GetCampoVersaoAction = Action<NomenclaturaActionKeys.GET_CAMPO_VERSAO_REQUEST>;
export type GetCampoVersaoSuccessAction = Action<NomenclaturaActionKeys.GET_CAMPO_VERSAO_SUCCESS, ICampoVersao[]>;
export type GetCampoVersaoFailureAction = Action<NomenclaturaActionKeys.GET_CAMPO_VERSAO_FAILED, string>;

export type removeMensagesAction = Action<NomenclaturaActionKeys.REMOVE_MESSAGES_NOMENCLATURA>;

export type GetExtensionsAction = Action<NomenclaturaActionKeys.GET_EXTENSOES_REQUEST>;
export type GetExtensionsSuccessAction = Action<NomenclaturaActionKeys.GET_EXTENSOES_SUCCESS, IExtensionData[]>;
export type GetExtensionsFailureAction = Action<NomenclaturaActionKeys.GET_EXTENSOES_FAILED, string>;

export type SetExtensionsAction = Action<NomenclaturaActionKeys.SET_EXTENSOES_REQUEST, IBindSuportedFileExtensions[]>;
export type SetExtensionsSuccessAction = Action<NomenclaturaActionKeys.SET_EXTENSOES_SUCCESS, IExtensionData[]>;
export type SetExtensionsFailureAction = Action<NomenclaturaActionKeys.SET_EXTENSOES_FAILED, string>;