import styled from "styled-components";

interface IWrapper {
  isCollapsed: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 81px);
  
  .headerWrapper {
    margin: 0 24px;
  }
  
  .mainWrapper {
    margin: 0 24px;
    max-width: ${({isCollapsed}) => isCollapsed 
      ? 'calc(100vw - 76px)' 
      : 'calc(100vw - 251px)'};
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
      background-color: #BEC2C6;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 11px;
      background-color: #BEC2C6;
    }
  }

  .inputTopBar{
    min-width: 260px;
  }
`;

export const HeaderBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 43px;
`;
