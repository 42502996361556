import styled, { css } from "styled-components";

interface IFieldRow {
  showinhover?: boolean;
  isabsolute?: boolean;
}

export const FieldRow = styled.div<IFieldRow>`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({isabsolute}) => isabsolute && css`
    margin-left: -20px;
    padding-left: 20px;
  `}

  ${({showinhover}) => showinhover && css`
    .hovershow {
      display: none;
    }

    :hover {
      .hovershow {
        display: flex;
      }
    }
  `}
`; 

interface IDragButton {
  isgrabbing?: string;
  isabsolute?: boolean;
}

export const DragButton = styled.div<IDragButton>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 2px;
  cursor: grab;

  .iconDrag {
    font-size: 14px;
    color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder} !important;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }

  ${({isgrabbing}) => isgrabbing === 'true' && css`
    cursor: grabbing !important;
  `}

  ${({isabsolute}) => isabsolute && css`
    position: absolute;
    margin-left: -18px;
  `}
`;
