import { FC } from 'react';
import { history } from '../../../../Store/index';
import { MenuLogoStyled } from './styles';
import { IMultiDomainState } from 'Store/MultiDomain/interface/IMultiDomainState';
import poweredBy from 'Static/images/powered-by.png';

export interface IMenuLogo {
  image: string;
  imageCollapse: string;
  isCollapsed: boolean;
  domain: IMultiDomainState;
}

const MenuLogo: FC<IMenuLogo> = ({ 
  image, 
  imageCollapse, 
  isCollapsed,
  domain
}) => {
  return (
    <MenuLogoStyled iscollapsed={`${isCollapsed}`}>
      <img 
        src={isCollapsed ? imageCollapse : image} 
        alt='Logo da maleta' 
        onClick={() => history.push('/obras')} 
      />
      {domain?.LogoUrl && !isCollapsed && (
        <img src={poweredBy} alt="decorative powered by logo" />
      )}
    </MenuLogoStyled>
  );
};

export default MenuLogo;
