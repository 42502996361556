import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileExtension, isValid3DExtension } from "Utils/ViewerUtils";
import { ObraActions } from "Store/Obra/Obra.actions";
import { IAutodeskViewer } from "Data/interfaces/Viewer/IAutodeskViewer";
import { FederatedViewerActions } from "Store/FederatedViewer/FederatedViewer.actions";
import { history } from "Store";
import toastHandler from '../../Utils/toastHandler';
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { Mixpanel } from "Utils/MixPanel";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

export interface INode {
  node: number | null;
  urn: string | null;
}

export interface IView {
  view: Autodesk.Viewing.BubbleNode | null;
  urn: string | null;
  id: number | null;
}

export interface ICamera {
  camera: Autodesk.Viewing.BubbleNode | null;
  urn: string | null;
  id: number | null;
}

export interface IPrancha {
  prancha: Autodesk.Viewing.BubbleNode | null;
  urn: string | null;
  id: number | null;
}

export interface IChildrenNames {
  urn: string;
  name: string;
}

export type IDrawerOptions = 'models' | 'views' | 'viewpoints' | 'pranchas' | 'activities';

interface IUseFederatedViewer {
  match: any;
  isLoading: boolean;
  urns: string[];
  userInfo?: IUserInforResponse;
  viewerListDisciplines: IDisciplineViewerList[];
  loadingDiscipline: boolean;
}

const useFederatedViewer = ({
  match,
  isLoading,
  urns,
  userInfo,
  viewerListDisciplines,
  loadingDiscipline,
}: IUseFederatedViewer) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const oneTryRender = useRef(0);
  
  const [drawerFederatedVisible, setDrawerFederatedVisible] = useState(true);
  const [searchDiscipline, setSearchDiscipline] = useState('');
  const [searchDisciplineFiles, setSearchDisciplineFiles] = useState('');
  const [searchItens, setSearchItens] = useState('');
  const [activeDrawer, setActiveDrawer] = useState<IDrawerOptions>('models');
  const [activeDisciplineId, setActiveDisciplineId] = useState<number | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [upfileViewer, setUpfileViewer] = useState({} as File);
  const [showSelectUploadPath, setShowSelectUploadPath] = useState(false);
  const [showCTAViewerUpgrade, setShowCTAViewerUpgrade] = useState(false);
  const [showCTAMultipleModelsUpgrade, setShowCTAMultipleModelsUpgrade] = useState(false);
  const [indexList, setIndexList] = useState(0);
  const [selectUploadPathFromMaleta, setSelectUploadPathFromMaleta] = useState(false);
  const [loadUrn, setLoadUrn] = useState<string | null>(null);
  const [toggleHideShowNode, setToggleHideShowNode] = useState<INode>({node: null, urn: null});
  const [toggleSelectNode, setToggleSelectNode] = useState<INode>({node: null, urn: null});
  const [showModalProgressLimitRender, setShowModalProgressLimitRender] = useState(false);
  const [toggleView, setToggleView] = useState<IView>({view: null, urn: null, id: null});
  const [toggleViewCamera, setToggleViewCamera] = useState<ICamera>({camera: null, urn: null, id: null});
  const [togglePrancha, setTogglePrancha] = useState<IPrancha>({prancha: null, urn: null, id: null});

  const filteredEmptyDisciplines = useMemo(() => {
    return viewerListDisciplines.filter(discipline => 
      discipline.FilesList?.length && (
        (
          discipline.CustomName && 
          discipline.CustomName.toLowerCase().includes(searchDiscipline.toLowerCase())
          ) ||
        discipline.Discipline.Name.toLowerCase().includes(searchDiscipline.toLowerCase())
      ));
  }, [viewerListDisciplines, searchDiscipline]);

  const childrenActiveDisciplines = useMemo(() => {
    const activeDiscipline = viewerListDisciplines.find(discipline => discipline.ConstructionSiteDisciplinesId === activeDisciplineId);
    if (activeDiscipline) {
      const listFiles = activeDiscipline.FilesList;
      if (Array.isArray(listFiles)) {
        return listFiles.filter(file => file.Name.toLowerCase().includes(searchDisciplineFiles.toLowerCase()));
      }
    }
    return undefined;
  }, [activeDisciplineId, searchDisciplineFiles, viewerListDisciplines]);

  const childrenActiveUrnsNames = useMemo(() => {
    const childrenNames: IChildrenNames[] = [];
    childrenActiveDisciplines?.map(item => {
      if (item.FileInfo.BucketFile?.Urn) {
        childrenNames.push({
          urn: `urn:${item.FileInfo.BucketFile.Urn}`,
          name: item.Name,
        });
      }
    });
    return childrenNames;
  }, [viewerListDisciplines, childrenActiveDisciplines, urns]);

  useEffect(() => {
    dispatch(ObraActions.toggleCollapse(true));
  }, []);

  useEffect(() => {
    dispatch(FederatedViewerActions.listDisciplines(match.params.constructionSiteId));
  }, []);

  useEffect(() => {
    dispatch(ObraActions.getObra({csId: match.params.constructionSiteId}));
  }, []);
  
  useEffect(() => {
    if (viewerListDisciplines.length && !loadingDiscipline) {
      viewerListDisciplines.map(discipline => {
        return dispatch(FederatedViewerActions.listFiles(discipline.ConstructionSiteDisciplinesId));
      })
    }
  }, [loadingDiscipline]);

  // RENDERIZAÇÃO AUTOMÁTICA
  useEffect(() => {
    if (viewerListDisciplines.length && !isLoading && (oneTryRender.current === 0)) {
      viewerListDisciplines.map(discipline => {
        if (discipline.FilesList?.some(file => !file.FileInfo.BucketFile || file.FileInfo.BucketFile === null || (file.FileInfo.BucketFile?.Status && ![1].includes(file.FileInfo.BucketFile.Status)))) {
          discipline.FilesList.map(file => {
            if (file.FileInfo.BucketFile && file.FileInfo.BucketFile?.Status === 2) {
              dispatch(FederatedViewerActions.verifyViewerAutodeskProgress({ 
                constructionSiteId: match.params.constructionSiteId, 
                filesApiId: [file.Identifier.ApiId || file.Identifier.ItemId] 
              }));
            }
            if (!file.FileInfo.BucketFile || file.FileInfo.BucketFile?.Status === null || file.FileInfo.BucketFile?.Status === 0) {
              const request: IAutodeskViewer = {
                ConstructionSiteId: match.params.constructionSiteId,
                FileApiId: file.Identifier.ApiId || file.Identifier.ItemId,
              };
        
              dispatch(FederatedViewerActions.setFileStatusRendering(request));
              dispatch(FederatedViewerActions.autoDeskViewer(request));
            }
            return file;
          });
          setTimeout(() => {
            oneTryRender.current = 1;
          }, 1000)
        }
        return discipline;
      });
      setTimeout(() => {
        oneTryRender.current = 0;
      }, 5000)
    }
  }, [viewerListDisciplines, isLoading]);

  const handleUpgradePlan = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION', 
      props: {
        origin: 'federated-viewer',
        originPath: window.location.pathname
      },
      userInfo,
      currentListTenant: currentTenant,
    });

    if (userInfo?.CurrentRoleFk === 1) {
      history.push('/faturamento/planos');
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  }

  const handleShowCTARender = () => {
    setShowCTAViewerUpgrade(true);
  }

  const handleOpenCTAModal = (index: number) => {
    setShowCTAMultipleModelsUpgrade(true);
    setIndexList(index);
  }

  const handleCloseCTAModal = () => {
    setShowCTAMultipleModelsUpgrade(false);
  }

  const handleShowProgressLimitRender = () => {
    setShowModalProgressLimitRender(true);
  }

  const handleCloseProgressLimitRender = () => {
    setShowModalProgressLimitRender(false);
  }

  const handleSearch = (value: string) => {
    if (activeDrawer === 'models') {
      if (activeDisciplineId) {
        setSearchDiscipline('');
        setSearchDisciplineFiles(value);
      } else {
        setSearchDiscipline(value);
        setSearchDisciplineFiles('');
      }
    } else {
      setSearchItens(value);
      setSearchDiscipline('');
      setSearchDisciplineFiles('');
    }
  }

  const clearAllSearch = () => {
    setSearchDiscipline('');
    setSearchDisciplineFiles('');
    setSearchItens('');
  }

  const uploadFileViewer = () => {
    document.getElementById('uploadFileViewer')?.click();
  }

  const handleUploadFileViewer = (files: FileList | null) => {
    if (files) {
      if (files[0].size) {
         const extension = getFileExtension(files[0].name);
         if (isValid3DExtension(extension)) {
           setUpfileViewer(files[0]);
           setSelectUploadPathFromMaleta(false);
           setShowSelectUploadPath(true);
         } else {
          toastHandler.showError('Faça upload de uma extensão válida para o viewer!');
         }
      }
    }
  } 

  const handleMaletaFileViewer = () => {
    setSelectUploadPathFromMaleta(true);
    setShowSelectUploadPath(true);
  }

  const handleActiveUrns = (urn: string, extension: string) => {
    dispatch(FederatedViewerActions.setUrns(urn));
    dispatch(FederatedViewerActions.setExtensionValidated({urn, extension}));
  }

  const cancelUploadFileViewer = () => {
    setUpfileViewer({} as File);
    setShowSelectUploadPath(false);
    setSelectUploadPathFromMaleta(false);
  }

  const handleActiveList = (value: IDrawerOptions) => {
    setActiveDrawer(value);
    clearAllSearch();
  }

  const openDiscipline = (disciplineId: number) => {
    setActiveDisciplineId(disciplineId);
    clearAllSearch();
  }

  const backDisciplines = () => {
    dispatch(FederatedViewerActions.clearAllSets());
    setActiveDisciplineId(null);
    clearAllSearch();
  }

  const showDrawerFederated = () => {
    setDrawerFederatedVisible(true);
  };

  const closeDrawerFederated = () => {
    setDrawerFederatedVisible(false);
  };

  const handleToogleNode = (node: number | null, urn: string | null) => {
    setToggleHideShowNode({node: null, urn: null});
    setToggleHideShowNode({node, urn});
  };
  
  const handleToogleSelectNode = (node: number | null, urn: string | null) => {
    setToggleSelectNode({node: null, urn: null});
    setToggleSelectNode({node, urn});
  };
  
  const handleToogleView = (
    view: Autodesk.Viewing.BubbleNode | null, 
    urn: string | null,
    id: number | null,
  ) => {
    setToggleView({view: null, urn: null, id: null});
    setToggleView({view, urn, id});
  };

  const handleToogleViewCamera = (
    camera: Autodesk.Viewing.BubbleNode | null, 
    urn: string | null,
    id: number | null,
  ) => {
    setToggleViewCamera({camera: null, urn: null, id: null});
    setToggleViewCamera({camera, urn, id});
  };
    
  const handleTooglePrancha = (
    prancha: Autodesk.Viewing.BubbleNode | null, 
    urn: string | null,
    id: number | null,
  ) => {
      setTogglePrancha({prancha: null, urn: null, id: null});
      setTogglePrancha({prancha, urn, id});
  };
      
  const handleClearAllToogles = () => {
    setLoadUrn(null);
    setToggleHideShowNode({node: null, urn: null});
    setToggleSelectNode({node: null, urn: null});
    setToggleViewCamera({camera: null, urn: null, id: null});
    setTogglePrancha({prancha: null, urn: null, id: null});
    setToggleView({view: null, urn: null, id: null})
  };

  const handleOpenVideoModal = () => {
    setShowVideo(true);
  }

  const handleCloseVideoModal = () => {
    setShowVideo(false);
  }

  const handleLoadUrn = (urn: string | null) => {
    setLoadUrn(urn);
  }

  return {
    activeDrawer,
    handleActiveList,
    drawerFederatedVisible,
    closeDrawerFederated,
    showDrawerFederated,
    handleSearch,
    activeDisciplineId,
    uploadFileViewer,
    handleMaletaFileViewer,
    handleShowCTARender,
    handleShowProgressLimitRender,
    filteredEmptyDisciplines,
    openDiscipline,
    backDisciplines,
    childrenActiveDisciplines,
    searchDiscipline,
    searchDisciplineFiles,
    childrenActiveUrnsNames,
    loadUrn,
    handleActiveUrns,
    handleOpenCTAModal,
    handleToogleNode,
    handleToogleSelectNode,
    dispatch,
    handleOpenVideoModal,
    handleCloseVideoModal,
    handleUpgradePlan,
    searchItens,
    handleLoadUrn,
    toggleView,
    handleToogleView,
    toggleViewCamera,
    handleToogleViewCamera,
    handleTooglePrancha,
    toggleHideShowNode,
    toggleSelectNode,
    togglePrancha,
    handleUploadFileViewer,
    showVideo,
    showSelectUploadPath,
    cancelUploadFileViewer,
    upfileViewer,
    selectUploadPathFromMaleta,
    showCTAViewerUpgrade,
    setShowCTAViewerUpgrade,
    showCTAMultipleModelsUpgrade,
    handleCloseCTAModal,
    indexList,
    showModalProgressLimitRender,
    handleCloseProgressLimitRender,
    handleClearAllToogles,
  }
}

export default useFederatedViewer;
