import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;

  .avatarContainer {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    padding-left: 16px;
    padding-right: 10px;

    .userName {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      margin-left: 16px;
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

interface IBtnContainer {
  hasprivileges?: boolean;
  isdisabled?: boolean;
}

export const BtnContainer = styled.div<IBtnContainer>`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-left: 6px;
  cursor: pointer;

  .iconPermissionBtn {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};

    .iconPermissionBtn {
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }

  ${({ hasprivileges, theme }) => hasprivileges && css`
    background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};
    border: none;

    .iconPermissionBtn {
      color: ${theme.colors.primary.primary} !important;
    }

    :hover {
      background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.primary.primaryContainer})`};

      .iconPermissionBtn {
        color: ${theme.colors.primary.primary} !important;
      }
    }
  `}

  ${({ isdisabled, hasprivileges, theme }) => isdisabled && css`
    background-color: ${hasprivileges
      ? `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`
      : theme.colors.surface.surfaceContainer
    };
    border: ${hasprivileges && 'none'};
    cursor: not-allowed;

    .iconPermissionBtn {
      color: ${hasprivileges 
        ? `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`
        : theme.colors.surface.onSurfacePlaceholder
      } !important;
    }

    :hover {
      background-color: ${hasprivileges
        ? `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`
        : theme.colors.surface.surfaceContainer
      };
      border: ${hasprivileges && 'none'};
      cursor: not-allowed;

      .iconPermissionBtn {
        color: ${hasprivileges 
          ? `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`
          : theme.colors.surface.onSurfacePlaceholder
        } !important;
      }
    }
  `}
`;
