import { FC } from 'react';
import Button from 'Components/UI/Button';
import PlanoAvisoPersonalizationUpgrade from 'Static/images/plano-aviso-personalization-upgrade.png';
import tagEnterprise from 'Static/images/tag-enterprise.png';
import { Icon } from 'Components/UI';
import { TagWrapper } from './styles';

interface IPersonalizationCTAUpgrade {
  onConfirm: (role: number, order: number) => void;
  order?: number;
  role?: number;
}

const PersonalizationCTAUpgrade: FC<IPersonalizationCTAUpgrade> = ({ 
  onConfirm, 
  order,
  role, 
}) => {
  const isAdmin = role === 1;
  const isStart = (order || 0) <= 1;
  
  return (
    <TagWrapper>
      <div className="wrapper">
        <div className="tag-popularity">
          <img src={tagEnterprise} alt="Tag Plano Pro" />
        </div>
        <div className="imageWrapper">
          <img src={PlanoAvisoPersonalizationUpgrade} alt="Imagem CTA Upgrade Activity" />
        </div>
        <div className={`contentWrapper ${!isStart ? 'contentWrapperPro' : ''}`}>
          <div>
            <label className="avisoLimiteObras">
              {isAdmin 
                ? 'Atenção: você não possui acesso à personalização do sistema!'
                : 'Atenção: sua empresa não possui acesso à personalização do sistema!'
              }
            </label>
          </div>
          <h2>
            {isStart  
              ? isAdmin 
                  ? 'Dê um upgrade na sua conta gratuita'
                  : 'Dê um upgrade na conta da sua empresa'
              : isAdmin
                  ? 'Dê um upgrade no seu plano'
                  : 'Dê um upgrade no plano da sua empresa'
            }
          </h2>
          <h3>
            {isAdmin 
              ? 'Para personalizar seu sistema'
              : 'Para personalizar o sistema'
            }
          </h3>
          {isStart && (
            <>  
              <p>O plano Enterprise ainda proporciona:</p>
              <div className="icons-wrapper">
                <div className={`icons-text-wrapper icons-text-margin`}>
                  <div>
                    <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                    <span>Gestão de documentos 2D e 3D</span>
                  </div>
                  <div>
                    <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                    <span>Controle de nomeclatura</span>
                  </div>
                  <div>
                    <Icon icon={'activities'} customSize={16} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                    <span>Controle de atividades</span>
                  </div>
                </div>
                <div className="icons-text-wrapper">
                  <div>
                    <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                    <span>Gestão de usuários e papéis na obra</span>
                  </div>
                  <div>
                    <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                    <span>Controle de plotagem</span>
                  </div>
                </div>
                <div style={{ marginBottom: 14 }}>&nbsp;</div>
              </div>
            </>
          )}
          
          <div className="button-wrapper"> 
            <Button 
              className="button-confirm"
              type='primary' 
              ispremium="true"
              width={228} 
              height={48} 
              onClick={() => onConfirm(role || 0, order || 0)}
            >
              <Icon icon="crown" className="icon" size="xxs" color="cinzaEscuro" />
              {isAdmin 
                  ? 'Adquirir o plano Enterprise'
                  : 'Solicitar o plano Enterprise'
              }
            </Button>
          </div>
        </div>
      </div>
    </TagWrapper>
  );
};

export default PersonalizationCTAUpgrade;
