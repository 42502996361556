import { FC } from 'react';
import { Link as DomLink } from 'react-router-dom';
import styled from 'styled-components';

export interface ILink {
  to: string;
  target?: string;
  rel?: string;
  className?: string;
  isExternal?: boolean;
}

const Link: FC<ILink> = ({ to, target, rel, isExternal, children, className }) => {
  if (isExternal) {
    return (
      <a href={to} target={target} rel={rel} className={className}>
        {children}
      </a>
    );
  }

  return (
    <DomLink to={to} target={target} rel={rel} className={className}>
      {children}
    </DomLink>
  );
};

export default styled(Link)`
  color: ${({theme}) => theme.colors.primary.primary};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-left: 2px;

  ${({theme}) => (theme?.font ? `font-family: ${theme.font.name};` : undefined)};

  &:hover {
    color: ${({theme}) => theme.colors.primary.primaryHover};
    text-decoration: underline;
  }
`;
