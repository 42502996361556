import React, { FC } from "react";

import styles from "./BoxAviso.module.scss";

interface IBoxAviso {
  children: React.ReactNode;
  img: Iimg;
  button?: React.ReactNode;
  stylesProps?: CSSStyleSheet
}

interface Iimg {
  arquivo?: string;
  alt?: string;
  width?: string;
  height?: string;
} 

const BoxAviso: FC<IBoxAviso> = ({ children, img, button, stylesProps }) => {
  return (
    <div className={styles['Grid']}>

        <div className={styles['grid-left']}>
          <div className={styles['grid-left-image']} >
            <img 
              src={img.arquivo} 
              alt={img.arquivo} 
              style={{ ...stylesProps ,width: img.width, height: img.height }} 
            />
          </div>

          <div className={styles['grid-left-children']}>
            {children}
          </div>
        </div>

      <div className={styles['grid-right']}>
        {button}
      </div>
    </div>
  )
}

export default BoxAviso;
