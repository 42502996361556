import { FC, useState } from 'react';
import AppStorage from 'Utils/AppStorage';
import { Icon, IconName } from '../Icon';
import {
  ButtonAction,
  ButtonDrawer,
  CustomPopover,
  Overlaycontainer,
} from './styles';

export interface IListButtonsButtonsTypes {
  nameId: TypeListButtons;
  placement: string;
  icon: IconName;
  iconSize: number;
}

export type TypeButton = Record<string, IListButtonsButtonsTypes>;

export type TypeListButtons = keyof TypeButton;
interface IListButtons {
  storageKey: string;
  buttons: TypeButton;
  activeButton: TypeListButtons;
  disabled?: boolean;
  onActiveOption: (value: TypeListButtons) => void;
}

const ListButtons: FC<IListButtons> = ({
  storageKey,
  buttons,
  activeButton,
  disabled,
  onActiveOption,
}) => {
  const [visible, setVisible] = useState<boolean>();

  const buttonsArray = Object.values(buttons).map(button => button);

  const overlay = (
    <Overlaycontainer>
      {buttonsArray.map(button => (
        <ButtonAction
          key={`${button.nameId}`}
          onClick={() => handleClick(button.nameId)}
        >
          <span className="left">
            <Icon
              icon={button.icon}
              customSize={button.iconSize}
              className="btnActionIconLeft"
            />
            <span>{button.placement}</span>
          </span>
          {(activeButton === button.nameId) && (
            <Icon
              icon="confirmacao"
              customSize={13}
              className="btnActionIconConfirm"
            />
          )}
        </ButtonAction>
      ))}
    </Overlaycontainer>
  );

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleClick = (nameId: TypeListButtons) => {
    AppStorage.SetItem(storageKey, nameId, 'local');
    onActiveOption(nameId);
    handleVisibleChange(false);
  }

  return (
    <CustomPopover
      content={overlay}
      placement="bottomLeft"
      trigger={'click'}
      open={disabled ? false : visible}
      onOpenChange={disabled ? undefined : handleVisibleChange}
      overlayInnerStyle={{padding: 0}}
    >
      <ButtonDrawer disabled={`${disabled}`}>
        <Icon
          icon={buttons[activeButton].icon}
          customSize={buttons[activeButton].iconSize}
          className="btnDrawerIcon"
        />
        <span>{buttons[activeButton].placement}</span>
        <Icon
          icon="expandir"
          customSize={10}
          className="btnDrawerExpand"
        />
      </ButtonDrawer>
    </CustomPopover>
  );
}

export default ListButtons;
