import { IRenderingSignalR } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import { FC } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ContentRenderizando } from "./styles";

interface ILoadingRender {
  fileViewerAutodesk: IRenderingSignalR | undefined;
}

const LoadingRender: FC<ILoadingRender> = ({ fileViewerAutodesk }) => {
  const getNameEtapa = () => {
    const etapa =
      (fileViewerAutodesk?.etapa && fileViewerAutodesk?.etapa < 4) && fileViewerAutodesk?.progress == 100
        ? fileViewerAutodesk?.etapa + 1
        : fileViewerAutodesk?.etapa;

    switch (etapa) {
      case 0:
        return 'Carregando informações do arquivo';
      case 1:
        return 'Download do Serviço de armazenamento';
      case 2:
        return 'Fazendo upload para Servidor';
      case 3:
        return 'Processamento da visualização';
      case 5:
        return 'Erro ao processar arquivo';
      default:
        return 'Iniciando processamento de arquivo';
    }
  };

  return (
    <ContentRenderizando key="render" iserror={`${fileViewerAutodesk?.etapa === 5}`}>
      <div className="infoRenderizando">
        <div>
          <CircularProgressbar
            className="circularProgress"
            strokeWidth={14}
            styles={buildStyles({ pathColor: '#0081FF', trailColor: '#cdcdcd' })}
            value={
              (fileViewerAutodesk?.progress == 100
                ? 0
                : fileViewerAutodesk?.progress) ?? 0
            }
            maxValue={100}
          />
        </div>
        <div className="infoEtapas">
          <label>
            Etapa{' '}
            {fileViewerAutodesk?.progress == 100
              ? fileViewerAutodesk?.etapa + 1
              : fileViewerAutodesk?.etapa ?? 0}{' '}
            de 3:
          </label>
          <p>{getNameEtapa()}</p>
        </div>
      </div>
    </ContentRenderizando>
  )
}

export default LoadingRender;
