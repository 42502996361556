import React, { FC } from 'react';
import { Tabs as TabsAnt } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/lib/tabs';
import { TabsStyled } from './styles';

const { TabPane } = TabsAnt;

export interface ITab extends TabsProps {
  tabs: ITabPane[];
}

export interface ITabPane extends TabPaneProps {
  title: string;
  children?: React.ReactNode;
}

const Tabs: FC<ITab> = (props) => {
  return (
    <>
      <TabsStyled />
      <TabsAnt {...props}>
        {props.tabs?.map((pane: ITabPane) => <TabPane {...pane} tab={pane.title} />)}
      </TabsAnt>
    </>
  );
};

export default Tabs;
