import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import React, { FC } from "react";
import logoCrowFree from '../../../../Static/images/logo-crow-free.png';
import styles from "./CTAMultipleModels.module.scss"

interface ICTAMultipleModels {
  onConfirm: () => void;
  onClose: () => void;
  role?: number;
  indexList: number;
}

const CTAMultipleModels: FC<ICTAMultipleModels> = ({
  onConfirm,
  onClose,
  role,
  indexList,
}) => {

  return (
    <div 
      className={styles['wrapperCTA']}
      style={{
        top:indexList * 45 + 270,
        left:308,
      }}
    >
      <div className={styles['iconWrapperCTA']}>
        <img src={logoCrowFree} alt="" />
      </div>
      <div className={styles['contentWrapperCTA']}>
        <p className={styles['firstTextCTA']}>Dê um upgrade na sua conta gratuita</p>
        <p className={styles['secondTextCTA']}>Para VISUALIZAR múltiplos modelos</p>
        <Button 
          className={styles['button-confirm']} 
          ispremium="true"
          width={215} 
          height={36} 
          onClick={onConfirm}
        >
          <Icon icon="crown" className={styles['icon']} size="xxs" color="cinzaEscuro" />
          {role === 1 
            ? 'Adquirir o plano Premium'
            : 'Solicitar o plano Premium'
          }
        </Button>
      </div>
      <Icon 
        icon="errorSenha"
        className={styles['btnCloseCTA']}
        onClick={onClose}
        customSize={8}
        customColor={'#BEC2C6'}
      />
    </div>
  )
}

export default CTAMultipleModels;
