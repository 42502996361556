import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IPlotagemResponse, IPlotagemSolicitacao, IPlotagemSolicitacaoResponse } from '../../Data/interfaces/Plotagem/IPlotagemListResponse';
import { IDisciplineResponse } from '../../Data/interfaces/Plotagem/IPlotagemData';
import { IArquivoData, IArquivoDataResponse } from '../../Data/interfaces/Plotagem/IArquivoData';
import { IUpdatePlotagemArquivoStatusRequest } from '../../Data/interfaces/Plotagem/IUpdatePlotagemArquivoStatusRequest';
import { IPlotRequestRequest } from '../../Data/interfaces/Plotagem/IPlotRequestRequest';
import { IPlotagemArquivo } from '../../Data/interfaces/Plotagem/IPlotagemArquivo';
import { IReenviaPlotagem } from '../../Data/interfaces/Plotagem/IReenviaPlotagem';
import { IDownloadRelatorioRequest, IProgressDownloading } from '../../Data/interfaces/Plotagem/IDownloadRelatorioRequest';

export enum PlotagemActionKeys {
  LIST_PLOTAGEM_REQUEST = 'LIST_PLOTAGEM_REQUEST',
  LIST_PLOTAGEM_SUCCESS = 'LIST_PLOTAGEM_SUCCESS',
  LIST_PLOTAGEM_FAILED = 'LIST_PLOTAGEM_FAILED',

  LIST_DISCIPLINES_PLOTAGEM_REQUEST = 'LIST_DISCIPLINES_PLOTAGEM_REQUEST',
  LIST_DISCIPLINES_PLOTAGEM_SUCCESS = 'LIST_DISCIPLINES_PLOTAGEM_SUCCESS',
  LIST_DISCIPLINES_PLOTAGEM_FAILED = 'LIST_DISCIPLINES_PLOTAGEM_FAILED',

  UPDATE_PLOTAGEM_DISCIPLINES_LIST = 'UPDATE_PLOTAGEM_DISCIPLINES_LIST',

  LIST_PLOTAGEM_ARQUIVOS_REQUEST = 'LIST_PLOTAGEM_ARQUIVOS_REQUEST',
  LIST_PLOTAGEM_ARQUIVOS_SUCCESS = 'LIST_PLOTAGEM_ARQUIVOS_SUCCESS',
  LIST_PLOTAGEM_ARQUIVOS_FAILED = 'LIST_PLOTAGEM_ARQUIVOS_FAILED',

  UPDATE_PLOTAGEM_ARQUIVOS_LIST = 'UPDATE_PLOTAGEM_ARQUIVOS_LIST',

  LIST_PLOTAGEM_SOLICITACOES_REQUEST = 'LIST_PLOTAGEM_SOLICITACOES_REQUEST',
  LIST_PLOTAGEM_SOLICITACOES_SUCCESS = 'LIST_PLOTAGEM_SOLICITACOES_SUCCESS',
  LIST_PLOTAGEM_SOLICITACOES_FAILED = 'LIST_PLOTAGEM_SOLICITACOES_FAILED',

  LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST = 'LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST',
  LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_SUCCESS = 'LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_SUCCESS',
  LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_FAILED = 'LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_FAILED',

  UPDATE_PLOTAGEM_SOLICITACOES_DISCIPLINAS_LIST = 'UPDATE_PLOTAGEM_SOLICITACOES_DISCIPLINAS_LIST',

  UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST = 'UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST',
  UPDATE_PLOTAGEM_ARQUIVOS_STATUS_SUCCESS = 'UPDATE_PLOTAGEM_ARQUIVOS_STATUS_SUCCESS',
  UPDATE_PLOTAGEM_ARQUIVOS_STATUS_FAILED = 'UPDATE_PLOTAGEM_ARQUIVOS_STATUS_FAILED',

  PLOT_REQUEST_REQUEST = 'PLOT_REQUEST_REQUEST',
  PLOT_REQUEST_SUCCESS = 'PLOT_REQUEST_SUCCESS',
  PLOT_REQUEST_FAILED = 'PLOT_REQUEST_FAILED',

  SET_SHOW_MODAL_REENVIA_SOLICITACAO_PLOTAGEM = 'SET_SHOW_MODAL_REENVIA_SOLICITACAO_PLOTAGEM',
  SET_SUBMITED_REENVIA_SOLICITACAO_PLOTAGEM = 'SET_SUBMITED_REENVIA_SOLICITACAO_PLOTAGEM',

  EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST = 'EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST',
  EXCLUI_ARQUIVOS_PLOTAGEM_SUCCESS = 'EXCLUI_ARQUIVOS_PLOTAGEM_SUCCESS',
  EXCLUI_ARQUIVOS_PLOTAGEM_FAILED = 'EXCLUI_ARQUIVOS_PLOTAGEM_FAILED',

  SET_SHOW_MODAL_EXCLUI_ARQUIVOS_PLOTAGEM = 'SET_SHOW_MODAL_EXCLUI_ARQUIVOS_PLOTAGEM',

  REENVIA_PLOTAGEM_REQUEST = 'REENVIA_PLOTAGEM_REQUEST',
  REENVIA_PLOTAGEM_SUCCESS = 'REENVIA_PLOTAGEM_SUCCESS',
  REENVIA_PLOTAGEM_FAILED = 'REENVIA_PLOTAGEM_FAILED',

  RECEBE_PLOTAGEM_REQUEST = 'RECEBE_PLOTAGEM_REQUEST',
  RECEBE_PLOTAGEM_SUCCESS = 'RECEBE_PLOTAGEM_SUCCESS',
  RECEBE_PLOTAGEM_FAILED = 'RECEBE_PLOTAGEM_FAILED',

  DOWNLOAD_RELATORIO_EXCEL_REQUEST = 'DOWNLOAD_RELATORIO_EXCEL_REQUEST',
  DOWNLOAD_RELATORIO_EXCEL_SUCCESS = 'DOWNLOAD_RELATORIO_EXCEL_SUCCESS',
  DOWNLOAD_RELATORIO_EXCEL_FAILED = 'DOWNLOAD_RELATORIO_EXCEL_FAILED',
  PROGRESS_DOWNLOAD = 'PROGRESS_DOWNLOAD',

  TOOGLE_SHOW_FILTER_DESCRIPTION = 'TOOGLE_SHOW_FILTER_DESCRIPTION',
  TOOGLE_SHOW_FILTER_ARQUIVO = 'TOOGLE_SHOW_FILTER_ARQUIVO',
  TOOGLE_SHOW_FILTER_STATUS = 'TOOGLE_SHOW_FILTER_STATUS',
  TOOGLE_SHOW_FILTER_VERSIONS = 'TOOGLE_SHOW_FILTER_VERSIONS',
  TOOGLE_SHOW_FILTER_SOLICITACAO = 'TOOGLE_SHOW_FILTER_SOLICITACAO',
  TOOGLE_SHOW_FILTER_SOLICITACAO_DATA = 'TOOGLE_SHOW_FILTER_SOLICITACAO_DATA',
  TOOGLE_SHOW_FILTER_SOLICITACAO_USERS = 'TOOGLE_SHOW_FILTER_SOLICITACAO_USERS',
}

export const PlotagemActions = {
  list: (): ListAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_REQUEST),
  listSuccess: (plotagemList: IPlotagemResponse[]): ListSuccessAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SUCCESS, plotagemList),
  listFailure: (err: string): ListFailureAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_FAILED, err),

  listDisciplinasPlotagem: (constructionSiteId: number): listDisciplinasPlotagemAction =>
    createAction(PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_REQUEST, constructionSiteId),
  listDisciplinasPlotagemSuccess: (disciplines: IDisciplineResponse): listDisciplinasPlotagemSuccessAction =>
    createAction(PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_SUCCESS, disciplines),
  listDisciplinasPlotagemFailure: (err: string): listDisciplinasPlotagemFailureAction =>
    createAction(PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_FAILED, err),
  updatePlotagemDisciplinesList: (disciplines: IDisciplineResponse): UpdatePlotagemDisciplinesListAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_DISCIPLINES_LIST, disciplines),

  // LISTAGEM DE AQUIVOS
  listPlotagemArquivos: (ConstructionSiteDisciplineFk: number): listPlotagemArquivosAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_REQUEST, ConstructionSiteDisciplineFk),
  listPlotagemArquivosSuccess: (archives: IArquivoDataResponse): listPlotagemArquivosSuccessAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_SUCCESS, archives),
  listPlotagemArquivosFailure: (err: string): listPlotagemArquivosFailureAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_FAILED, err),
  updatePlotagemArquivosList: (files: IArquivoDataResponse): UpdatePlotagemArquivosListAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_LIST, files),

  // LISTA PLOTAGEM SOLICITAÇÕES, EM DESENVOLVIMENTO
  listPlotagemSolicitacoes: (ConstructionSiteDisciplineFk: number): listPlotagemSolicitacoesAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_REQUEST, ConstructionSiteDisciplineFk),
  listPlotagemSolicitacoesSuccess: (archives: IPlotagemSolicitacao[]): listPlotagemSolicitacoesSuccessAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_SUCCESS, archives),
  listPlotagemSolicitacoesFailure: (err: string): listPlotagemSolicitacoesFailureAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_FAILED, err),

  listPlotagemSolicitacoesDisciplinas: (ConstructionSiteDisciplineFk: number): listPlotagemSolicitacoesDisciplinasAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST, ConstructionSiteDisciplineFk),
  listPlotagemSolicitacoesDisciplinasSuccess: (archives: IPlotagemSolicitacaoResponse): listPlotagemSolicitacoesDisciplinasSuccessAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_SUCCESS, archives),
  listPlotagemSolicitacoesDisciplinasFailure: (err: string): listPlotagemSolicitacoesDisciplinasFailureAction =>
    createAction(PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_FAILED, err),

  updatePlotagemSolicitacoesDisciplinasList: (files: IPlotagemSolicitacaoResponse): UpdatePlotagemSolicitacoesDisciplinasListAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_SOLICITACOES_DISCIPLINAS_LIST, files),

  updatePlotagemArquivosStatus: (request: IUpdatePlotagemArquivoStatusRequest): UpdatePlotagemArquivosStatusAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST, request),
  updatePlotagemArquivosStatusSuccess: (response: IUpdatePlotagemArquivoStatusRequest): UpdatePlotagemArquivosStatusSuccessAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_SUCCESS, response),
  updatePlotagemArquivosStatusFailure: (err: string): UpdatePlotagemArquivosStatusFailureAction =>
    createAction(PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_FAILED, err),

  plotRequest: (request: IPlotRequestRequest): PlotRequestAction =>
    createAction(PlotagemActionKeys.PLOT_REQUEST_REQUEST, request),
  plotRequestSuccess: (response: IPlotRequestRequest): PlotRequestSuccessAction =>
    createAction(PlotagemActionKeys.PLOT_REQUEST_SUCCESS, response),
  plotRequestFailure: (err: string): PlotRequestFailureAction =>
    createAction(PlotagemActionKeys.PLOT_REQUEST_FAILED, err),

  setShowModalReenviaSolicitacaoPlotagem: (visible: boolean): SetShowModalReenviaSolicitacaoPlotagemAction =>
    createAction(PlotagemActionKeys.SET_SHOW_MODAL_REENVIA_SOLICITACAO_PLOTAGEM, visible),
  setSubmitedReenviaSolicitacaoPlotagem: (visible: boolean): SetSubmitedReenviaSolicitacaoPlotagemAction =>
    createAction(PlotagemActionKeys.SET_SUBMITED_REENVIA_SOLICITACAO_PLOTAGEM, visible),

  excluiPlotagemArquivos: (request: IPlotagemArquivo): ExcluiPlotagemArquivosAction =>
    createAction(PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST, request),
  excluiPlotagemArquivosSuccess: (response: IPlotagemArquivo): ExcluiPlotagemArquivosSuccessAction =>
    createAction(PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_SUCCESS, response),
  excluiPlotagemArquivosFailure: (err: string): ExcluiPlotagemArquivosFailureAction =>
    createAction(PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_FAILED, err),

  setShowModalExcluiArquivosPlotagem: (visible: boolean): SetShowModalExcluiArquivosPlotagemAction =>
    createAction(PlotagemActionKeys.SET_SHOW_MODAL_EXCLUI_ARQUIVOS_PLOTAGEM, visible),

  reenviaPlotagem: (request: IReenviaPlotagem): ReenviaPlotagemAction =>
    createAction(PlotagemActionKeys.REENVIA_PLOTAGEM_REQUEST, request),
  reenviaPlotagemSuccess: (response: IReenviaPlotagem): ReenviaPlotagemSuccessAction =>
    createAction(PlotagemActionKeys.REENVIA_PLOTAGEM_SUCCESS, response),
  reenviaPlotagemFailure: (err: string): ReenviaPlotagemFailureAction =>
    createAction(PlotagemActionKeys.REENVIA_PLOTAGEM_FAILED, err),

  recebePlotagem: (request: IPlotagemArquivo): RecebePlotagemAction =>
    createAction(PlotagemActionKeys.RECEBE_PLOTAGEM_REQUEST, request),
  recebePlotagemSuccess: (response: IPlotagemArquivo): RecebePlotagemSuccessAction =>
    createAction(PlotagemActionKeys.RECEBE_PLOTAGEM_SUCCESS, response),
  recebePlotagemFailure: (err: string): RecebePlotagemFailureAction =>
  createAction(PlotagemActionKeys.RECEBE_PLOTAGEM_FAILED, err),

  downloadRelatorioExcel: (request: IDownloadRelatorioRequest): DownloadRelatorioExcelAction =>
    createAction(PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_REQUEST, request),
  downloadRelatorioExcelSuccess: (response: any): DownloadRelatorioExcelSuccessAction =>
    createAction(PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_SUCCESS, response),
  downloadRelatorioExcelFailure: (err: string): DownloadRelatorioExcelFailureAction =>
    createAction(PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_FAILED, err),
  progressDownload: (request: IProgressDownloading): ProgressDownloadAction =>
    createAction(PlotagemActionKeys.PROGRESS_DOWNLOAD, request),

  setShowFilterDescription: (visible: boolean): ToogleShowDescriptionAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_DESCRIPTION, visible),

  setShowFilterArquivo: (visible: boolean): ToogleShowArquivoAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_ARQUIVO, visible),

  setShowFilterStatus: (visible: boolean): ToogleShowFilterStatusAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_STATUS, visible),

  setShowVersions: (visible: boolean): ToogleShowFilterVersionsAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_VERSIONS, visible),

  setShowSolicitacao: (visible: boolean, dropdownVisible: boolean): ToogleShowFilterSolicitacaoAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO, { show: visible, showDropdown: dropdownVisible }),
  setShowSolicitacaoData: (visible: boolean): ToogleShowFilterSolicitacaoDataAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_DATA, visible),
  setShowSolicitacaoUsers: (visible: boolean): ToogleShowFilterSolicitacaoUsersAction =>
    createAction(PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_USERS, visible),
};

export type PlotagemActionUnion = ActionsUnion<typeof PlotagemActions>;

export type ListAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_REQUEST>;
export type ListSuccessAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SUCCESS, IPlotagemResponse[]>;
export type ListFailureAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_FAILED, string>;

export type listDisciplinasPlotagemAction = Action<PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_REQUEST, number>;
export type listDisciplinasPlotagemSuccessAction = Action<PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_SUCCESS, IDisciplineResponse>;
export type listDisciplinasPlotagemFailureAction = Action<PlotagemActionKeys.LIST_DISCIPLINES_PLOTAGEM_FAILED, string>;

export type listPlotagemArquivosAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_REQUEST, number>;
export type listPlotagemArquivosSuccessAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_SUCCESS, IArquivoDataResponse>;
export type listPlotagemArquivosFailureAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_ARQUIVOS_FAILED, string>;

export type UpdatePlotagemDisciplinesListAction = Action<PlotagemActionKeys.UPDATE_PLOTAGEM_DISCIPLINES_LIST, IDisciplineResponse>;

export type UpdatePlotagemArquivosListAction = Action<PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_LIST, IArquivoDataResponse>;


export type UpdatePlotagemArquivosStatusAction =
  Action<PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_REQUEST, IUpdatePlotagemArquivoStatusRequest>;
export type UpdatePlotagemArquivosStatusSuccessAction =
  Action<PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_SUCCESS, IUpdatePlotagemArquivoStatusRequest>;
export type UpdatePlotagemArquivosStatusFailureAction =
  Action<PlotagemActionKeys.UPDATE_PLOTAGEM_ARQUIVOS_STATUS_FAILED, string>;

export type listPlotagemSolicitacoesAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_REQUEST, number>;
export type listPlotagemSolicitacoesSuccessAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_SUCCESS, IPlotagemSolicitacao[]>;
export type listPlotagemSolicitacoesFailureAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_FAILED, string>;

export type listPlotagemSolicitacoesDisciplinasAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_REQUEST, number>;
export type listPlotagemSolicitacoesDisciplinasSuccessAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_SUCCESS, IPlotagemSolicitacaoResponse>;
export type listPlotagemSolicitacoesDisciplinasFailureAction = Action<PlotagemActionKeys.LIST_PLOTAGEM_SOLICITACOES_DISCIPLINAS_FAILED, string>;

export type UpdatePlotagemSolicitacoesDisciplinasListAction = Action<PlotagemActionKeys.UPDATE_PLOTAGEM_SOLICITACOES_DISCIPLINAS_LIST, IPlotagemSolicitacaoResponse>;

export type PlotRequestAction = Action<PlotagemActionKeys.PLOT_REQUEST_REQUEST, IPlotRequestRequest>;
export type PlotRequestSuccessAction = Action<PlotagemActionKeys.PLOT_REQUEST_SUCCESS, IPlotRequestRequest>;
export type PlotRequestFailureAction = Action<PlotagemActionKeys.PLOT_REQUEST_FAILED, string>;

export type SetShowModalReenviaSolicitacaoPlotagemAction = Action<PlotagemActionKeys.SET_SHOW_MODAL_REENVIA_SOLICITACAO_PLOTAGEM, boolean>;
export type SetSubmitedReenviaSolicitacaoPlotagemAction = Action<PlotagemActionKeys.SET_SUBMITED_REENVIA_SOLICITACAO_PLOTAGEM, boolean>;

export type ExcluiPlotagemArquivosAction =
  Action<PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_REQUEST, IPlotagemArquivo>;
export type ExcluiPlotagemArquivosSuccessAction =
  Action<PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_SUCCESS, IPlotagemArquivo>;
export type ExcluiPlotagemArquivosFailureAction =
  Action<PlotagemActionKeys.EXCLUI_ARQUIVOS_PLOTAGEM_FAILED, string>;

export type SetShowModalExcluiArquivosPlotagemAction = Action<PlotagemActionKeys.SET_SHOW_MODAL_EXCLUI_ARQUIVOS_PLOTAGEM, boolean>;

export type ReenviaPlotagemAction =
  Action<PlotagemActionKeys.REENVIA_PLOTAGEM_REQUEST, IReenviaPlotagem>;
export type ReenviaPlotagemSuccessAction =
  Action<PlotagemActionKeys.REENVIA_PLOTAGEM_SUCCESS, IReenviaPlotagem>;
export type ReenviaPlotagemFailureAction =
  Action<PlotagemActionKeys.REENVIA_PLOTAGEM_FAILED, string>;

export type RecebePlotagemAction =
  Action<PlotagemActionKeys.RECEBE_PLOTAGEM_REQUEST, IPlotagemArquivo>;
export type RecebePlotagemSuccessAction =
  Action<PlotagemActionKeys.RECEBE_PLOTAGEM_SUCCESS, IPlotagemArquivo>;
export type RecebePlotagemFailureAction =
  Action<PlotagemActionKeys.RECEBE_PLOTAGEM_FAILED, string>;

export type DownloadRelatorioExcelAction = Action<PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_REQUEST, IDownloadRelatorioRequest>;
export type DownloadRelatorioExcelSuccessAction = Action<PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_SUCCESS, any>;
export type DownloadRelatorioExcelFailureAction = Action<PlotagemActionKeys.DOWNLOAD_RELATORIO_EXCEL_FAILED, string>;
export type ProgressDownloadAction = Action<PlotagemActionKeys.PROGRESS_DOWNLOAD, IProgressDownloading>;

export type ToogleShowDescriptionAction =
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_DESCRIPTION, boolean>;

export type ToogleShowArquivoAction =
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_ARQUIVO, boolean>;

export type ToogleShowFilterStatusAction = 
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_STATUS, boolean>;

export type ToogleShowFilterVersionsAction = 
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_VERSIONS, boolean>;

export type ToogleShowFilterSolicitacaoAction = 
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO, { showDropdown: boolean; show: boolean; }>;
export type ToogleShowFilterSolicitacaoDataAction = 
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_DATA, boolean>;
export type ToogleShowFilterSolicitacaoUsersAction = 
  Action<PlotagemActionKeys.TOOGLE_SHOW_FILTER_SOLICITACAO_USERS, boolean>;
