import React, { FC } from "react";

import styles from "./LoadingReceberPlotagens.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingReceberPlotagens {
  multiple?: number;
}

const LoadingReceberPlotagens: FC<ILoadingReceberPlotagens> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (
        <div key={index.toString()} className={styles['main']}>
          <div className={styles['header']} style={{ width: '100%' }}>
            <SkeletonMain width="16px" height="16px" borderRadius="4px" marginLeft="18px" minWidth="16px"/>
            <SkeletonMain width="16px" height="16px" borderRadius="4px" marginLeft="12px" minWidth="16px"/>
            <SkeletonMain width="16px" height="16px" borderRadius="4px" marginLeft="12px" minWidth="16px"/>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" marginLeft="12px" />
          </div>
          <div className={styles['header']} style={{ width: '280px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </div>
          <div className={styles['header']} style={{ width: '280px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </div>
          <div className={styles['header']} style={{ width: '400px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </div>      
        </div>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      <div className={styles['header-table']}>
        <div className={styles['first']} style={{ width: '100%', paddingLeft: '18px' }}>
          <ColumnHeader
            title="SOLICITAÇÃO"
            showOrder={false}
          />
        </div>
        <div className={styles['first']} style={{ width: '280px' }}>
          <ColumnHeader
            title="ARQUIVOS"
            showOrder={false}
          />
        </div>
        <div className={styles['first']} style={{ width: '280px' }}>
          <ColumnHeader
            title="CÓPIAS"
            showOrder={false}
          />
        </div>
        <div className={styles['first']} style={{ width: '400px' }}>
          <ColumnHeader
            title="ENTREGAR PARA"
            showOrder={false}
          />
        </div>
      </div>
      {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
    </>
  )
}

export default LoadingReceberPlotagens;