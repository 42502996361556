import { FC, useCallback, useMemo } from "react";
import { getDisciplineIcon } from "Utils/ObraUtils";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import Tooltip from "Components/UI/Tooltip";
import { Container, DisciplineContainer } from "./styles";
import { IConstructionSiteStage, IStage } from "Data/interfaces/Obra/IObraStages";
import HighlighterWords from "Components/UI/HighlightWords";

interface IItem {
  stage: IStage;
  search: string;
  disciplines: any[];
  onUpdate: (stage: IStage) => void;
  onRemove: (stage: IStage) => void;
}

const Item: FC<IItem> = ({
  stage,
  search,
  disciplines,
  onUpdate,
  onRemove,
}) => {
  const limit = 9;

  const disciplinesList = useMemo(() => {
    let data: IConstructionSiteStage[] = []
    if (stage.IsDefault && disciplines?.length > 0) {
      for (const discipline of disciplines) {
        if (discipline.ConstructionSiteDisciplinesId) {
          data.push({
            ConstructionSiteDisciplineFk: discipline.ConstructionSiteDisciplinesId,
            ConstructionSiteDiscipline: discipline,
            ConstructionSiteStageFk: stage.TopicStageId,
          });
        }
      }
    } else {
      data = stage.ConstructionSiteStageDisciplines;
    }

    return data;
  }, [disciplines, stage]);

  const showDisciplines = useMemo(() => {
    const total = disciplinesList?.length;
    let data = disciplinesList;
    let rest: IConstructionSiteStage[] = [];

    if (total > limit) {
      data = disciplinesList.slice(0, limit + 1);
      rest = disciplinesList.slice(
        limit,
        disciplinesList?.length
      );
    }

    return {
      data,
      rest,
      total,
    };
  }, [disciplinesList, limit]);

  const showIcon = useCallback((index: number) => {
    if (
      (showDisciplines.total < limit) ||
      (index < showDisciplines.data?.length - 1)
    ) {
      return true;
    }

    return false;
  }, [showDisciplines, limit]);

  return (
    <Container>
      <div className={`row1`}>
        <HighlighterWords
          text={stage.Order.toString()}
          wordSearched={search}
          className="rowText"
        />
      </div>
      <div className={`row2`}>
        <HighlighterWords
          text={stage.Acronym || ''}
          wordSearched={search}
          className="rowText"
        />
      </div>
      <div className={`row3`}>
        <HighlighterWords
          text={stage.Name}
          wordSearched={search}
          className="rowText"
        />
        {stage.IsDefault && <span className="tagDefault">Padrão</span>}
      </div>
      <div className={`row4`}>
        {showDisciplines?.data && showDisciplines.data?.length > 0 &&
          showDisciplines.data.map((discipline, index) => (
            <Tooltip
              key={discipline.ConstructionSiteDisciplineFk}
              title={showIcon(index)
                ? (discipline.ConstructionSiteDiscipline.CustomName ||
                  discipline.ConstructionSiteDiscipline.Discipline.Name)
                : undefined
              }
            >
              <DisciplineContainer istext={!showIcon(index)}>
                {showIcon(index) && (
                  <Icon
                    icon={getDisciplineIcon(
                      discipline.ConstructionSiteDiscipline.CustomName ||
                      discipline.ConstructionSiteDiscipline.Discipline.Name
                    )}
                    customSize={12}
                    className="disciplineIcon"
                  />
                )}
                {!showIcon(index) && (
                  <span className="textTotal">{`+${showDisciplines.total - limit}`}</span>
                )}
              </DisciplineContainer>
            </Tooltip>
          ))}
        {(!showDisciplines?.data ||
          (showDisciplines?.data && showDisciplines.data?.length === 0)) && (
            <span className="emptyText">Sem disciplinas vinculadas</span>
          )}
      </div>
      <div className={`row5`}>
        <Button
          type="text"
          className="actionBtn"
          onClick={() => onUpdate(stage)}
        >
          <Icon
            icon="editar"
            customSize={12}
            className="actionIcon"
          />
        </Button>
        <Button
          type="text"
          className="actionBtn"
          onClick={() => onRemove(stage)}
        >
          <Icon
            icon="excluir"
            customSize={12}
            className="actionIcon"
          />
        </Button>
      </div>
    </Container >
  )
}

export default Item;
