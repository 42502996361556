import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
  }
  
  * {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    scrollbar-color: #8697a8;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 11px;
      background-color: transparent;
      margin-right: 1px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 4px;
      border-radius: 11px;
      background-color: #8697a8;
    }
  }

  span.ant-table-filter-icon {
    left: 200px !important;
  }

  ${({theme}) => `${theme.font?.fontFace}`}
`;
