import { combineReducers } from 'redux';

import auth from './Auth/Auth.reducer';
import obra from './Obra/Obra.reducer';
import nomenclatura from './Nomenclatura/Nomenclatura.reducer';
import file from './File/File.reducer';
import upload from './Upload/Upload.reducer';
import disciplina from './Disciplina/Disciplina.reducer';
import tenant from './Tenant/Tenant.reducer';
import listagemArquivos from './ListagemArquivos/ListagemArquivos.reducer';
import plotagem from './Plotagem/Plotagem.reducer';
import minhaConta from './MinhaConta/MinhaConta.reducer';
import viewer from './Viewer/Viewer.reducer';
import notification from './Notification/Notification.reducer';
import updateStatus from './UpdateStatusFileList/UpdateStatus.reducer';
import revenues from './Revenues/Revenues.reducer';
import adm from './Adm/Adm.reducer';
import federatedViewer from './FederatedViewer/FederatedViewer.reducer';
import activities from './Activities/Activities.reducer';
import multiDomain from './MultiDomain/MultiDomain.reducer';
import panels from './Panels/Panels.reducer';
import helper from './Helper/Helper.reducer';
import history from './History/History.reducer';

export const rootReducer = combineReducers({
  auth,
  obra,
  nomenclatura,
  file,
  disciplina,
  tenant,
  listagemArquivos,
  upload,
  plotagem,
  minhaConta,
  viewer,
  notification,
  updateStatus,
  revenues,
  adm,
  federatedViewer,
  activities,
  multiDomain,
  panels,
  helper,
  history,
});
