import styled, { css } from "styled-components";
import { Button } from "antd";
import { IButtonProps } from ".";

export const StyledButton = styled(Button) <IButtonProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer !important;

  ${({ theme }) => theme.font ? `font-family: ${theme.font.name} !important;` : undefined};

  :focus-visible {
    outline: 4px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`} !important;
  }

  background-color: ${({ theme }) => theme.colors.surface.surface} !important;
  border: 1px solid ${({ theme }) => theme.colors.primary.primary} !important;
  color: ${({ theme }) => theme.colors.primary.primary} !important;

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`} !important;
  }
  :active {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryPressed}, ${theme.colors.surface.surface})`} !important;
  }
  :focus {
    border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`} !important;
  }

  ${({ type }) => type === 'primary' && css`
    background-color: ${({ theme }) => theme.colors.primary.primary} !important;
    color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
    border: none !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryHover}, ${theme.colors.primary.primary})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryPressed}, ${theme.colors.primary.primary})`} !important;
    }
    :focus {
      border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryOutline}, ${theme.colors.primary.primary})`} !important;
    }
  `}

  ${({ shape }) => shape === 'circle' && css`
    border-radius: 50% !important;
    background-color: ${({ theme }) => theme.colors.primary.primary} !important;
    color: ${({ theme }) => theme.colors.primary.onPrimary} !important;
    border: none !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryHover}, ${theme.colors.primary.primary})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryPressed}, ${theme.colors.primary.primary})`} !important;
    }
    :focus {
      border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.onPrimaryOutline}, ${theme.colors.primary.primary})`} !important;
    }
  `}

  ${({ type }) => type === 'text' && css`
    background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    border: none !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.onSecondaryHover}, ${theme.colors.secondary.secondary})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.onSecondaryPressed}, ${theme.colors.secondary.secondary})`} !important;
    }
    :focus {
      border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.onSecondaryOutline}, ${theme.colors.secondary.secondary})`} !important;
    }
  `}

  ${({ danger }) => !!danger && css`
    background-color: ${({ theme }) => theme.colors.surface.surface} !important;
    border: 1px solid ${({ theme }) => theme.colors.danger.danger} !important;
    color: ${({ theme }) => theme.colors.danger.danger} !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerHover}, ${theme.colors.surface.surface})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerPressed}, ${theme.colors.surface.surface})`} !important;
    }
    :focus {
      border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerOutline}, ${theme.colors.surface.surface})`} !important;
    }
  `}

  ${({ primaryDanger }) => !!primaryDanger && css`
    background-color: ${({ theme }) => theme.colors.danger.danger} !important;
    border: none;
    color: ${({ theme }) => theme.colors.danger.onDanger} !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.onDangerHover}, ${theme.colors.danger.danger})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.onDangerPressed}, ${theme.colors.danger.danger})`} !important;
    }
    :focus {
      border: 2px solid ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.onDangerOutline}, ${theme.colors.danger.danger})`} !important;
    }
  `}

  ${({ customText, isShared }) => !!customText && css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 12px !important;
    width: 147px;
    background-color: ${({ theme }) => theme.colors.surface.surface} !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`} !important;
    }

    ${!!isShared && css`
      border: 1px solid ${({ theme }) => theme.colors.success.success} !important;
      color: ${({ theme }) => theme.colors.success.success} !important;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.information.information} !important;
        color: ${({ theme }) => theme.colors.success.success} !important;
      }
    `};
  `}

  :disabled {
    background-color: ${({ theme }) => theme.colors.surface.surfaceOutline} !important;
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
    border: 1px solid ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
    cursor: not-allowed !important;
  }

  ${({ ispremium }) => ispremium === 'true' && css`
    background-color: ${({ theme }) => theme.colors.premium.premium} !important;
    color: ${({ theme }) => theme.colors.premium.onPremium} !important;
    border: none !important;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.premium.onPremiumHover}, ${theme.colors.premium.premium})`} !important;
    }
    :active {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.premium.onPremiumPressed}, ${theme.colors.premium.premium})`} !important;
    }
    :focus {
      border: none !important;
    }
    :focus-visible {
      outline: 4px solid ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }
  `}

  ${({ domainloading, theme }) => domainloading === 'true' && css`
    background-color: ${theme.colors.surface.surface} !important;
  `}
`;