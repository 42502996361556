import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'Store';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { RevenuesActions } from 'Store/Revenues/Revenues.actions';

const useHistoryPush = () => {
  const dispatch = useDispatch();

  const showExitBillingData = useSelector((state: IGlobalReducerState) => state.revenues.cancelPageUpdateBillingData);
  const showExitDowgrade = useSelector((state: IGlobalReducerState) => state.revenues.cancelPageDowngrade);
  const urlRedirect = useSelector((state: IGlobalReducerState) => state.revenues.urlRedirect);

  useEffect(() => {
    if (!showExitBillingData) {
      historyRedirect();
    }
  }, [showExitBillingData]);

  useEffect(() => {
    if (!showExitDowgrade) {
      historyRedirect();
    }
  }, [showExitDowgrade]);

  const historyPush = (path: string) => {
    if(showExitBillingData) {
      dispatch(RevenuesActions.showModalExitBillingData(true));
      return dispatch(RevenuesActions.urlRedirect(path));
    }
    if(showExitDowgrade) {
      dispatch(RevenuesActions.showModalExitDowgrade(true));
      return dispatch(RevenuesActions.urlRedirect(path));
    }
    
    return  history.push(path);
  }

  const historyRedirect = () => {
    if(urlRedirect && (!showExitBillingData && !showExitDowgrade)) {
      history.push(urlRedirect);
      return dispatch(RevenuesActions.urlRedirect(''));
    }
  }

  return { historyPush, historyRedirect, urlRedirect };
};

export default useHistoryPush;
