import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import styles from "./Tenants.module.scss";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import PageHeader from "Components/UI/PageHeader/PageHeader";
import TableActionTopbar, { ITableActionTopbarButton } from "Components/UI/TableActionTopbar";
import Table from "Components/UI/Table";
import { getColumnsTableTenants } from "./Tenants.columns";
import { AdmActions } from "Store/Adm/Adm.actions";
import TenantUpdate from "./Modals/TenantUpdate";
import { history } from "Store";
import { Skeleton } from "antd";
import { ITenantAdm } from "Data/interfaces/Tenant/ITenantAdm";
import { IUserStoreItemRequest } from "Data/interfaces/Store/IUserStoreItemRequest";
import TenantBillingData from "./Modals/TenantBillingData";
import TenantApprovePlanChange from "./Modals/TenantApprovePlanChange";
import TenantAdminChange from "./Modals/TenantAdminChange";
import TenantPersonalization from "./Modals/TenantPersonalization";

const Tenants: FC<Props> = ({
  Tenants,
  tenantIsLoading,
  Planos,
  tenantUsers,
  adminChangeLoading,
  userInfo,
}) => {
  const dispatch = useDispatch();

  const [selectedRowTenant, setSelectedRowTenant] = useState([]);
  const [showCreateOrUpdateModalTenants, setShowCreateOrUpdateModalTenants] = useState(false);
  const [showBillingDataTenants, setShowBillingDataTenants] = useState(false);
  const [showPersonalization, setShowPersonalization] = useState(false);
  const [showFilterCompanyTenants, setShowFilterCompanyTenants] = useState(false);
  const [showFilterEmailTenants, setShowFilterEmailTenants] = useState(false);
  const [showFilterNameTenants, setShowFilterNameTenants] = useState(false);
  const [tenant, setTenant] = useState<IUserStoreItemRequest>();
  const [tenantUpOrDown, setTenantUpOrDown] = useState<ITenantAdm[]>();
  const [tenantName, setTenantName] = useState<string>('');
  const [userAdminId, setUserAdminId] = useState<string>('');
  const [showTenantReviewPlanChange, setShowTenantReviewPlanChange] = useState(false);
  const [showTenantAdminChange, setShowTenantAdminChange] = useState(false);
  const [tenantAdmin, setTenantAdmin] = useState<ITenantAdm | null>(null);

  const sortTenantsList = (sortOrder: boolean, key: string, key2?: string) => {
    const sortedTenants = [
      ...(Tenants?.sort((a: any, b: any) => {
        let propA = a[key] || 0 || '';
        let propB = b[key] || 0 || '';

        if (key2) {
          propA = a[key][key2] || 0 || '';
          propB = b[key][key2] || 0 || '';
        }

        if ((typeof propA !== 'number' && typeof propB !== 'number') &&
          (typeof propA !== 'object' && typeof propB !== 'object') &&
          (propA.includes('/') || propB.includes('/'))) {
          // @ts-ignore
          const propAD = propA.split('/' || '-').reverse().join('');
          // @ts-ignore
          const propBD = propB.split('/' || '-').reverse().join('');
          return sortOrder ? propBD - propAD : propAD - propBD;
        }

        if (typeof propA === 'number' && typeof propB === 'number') {
          return sortOrder ? propB - propA : propA - propB;
        }

        return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
      }) || [])
    ];

    let dataSortedTenants: ITenantAdm[] | undefined = Tenants;
    dataSortedTenants = sortedTenants;

    dispatch(AdmActions.listTenantsUpdate(dataSortedTenants));
  };

  const onChange = (selecteds: any, selectedTenant: any) => {
    setSelectedRowTenant(selecteds);
    setTenantUpOrDown(selectedTenant);
  };

  const editTenant = (
    tenant: IUserStoreItemRequest,
    tenantName: string,
  ) => {
    setTenant(tenant);
    setTenantName(tenantName);
    setShowCreateOrUpdateModalTenants(true);
  };

  const editTenantAdmin = (tenant: ITenantAdm) => {
    if (tenant.Tenant.TenantId) {
      setTenantAdmin(tenant);
      dispatch(AdmActions.listTenantUsers(tenant.Tenant.TenantId));
      setShowTenantAdminChange(true);
    }
  };

  const editTenantPersonalization = (tenantId: number) => {
    dispatch(AdmActions.getTenantPersonalizationAdm(tenantId));
    setShowPersonalization(true);
  };

  const showBillingData = (
    userAdminId: string,
    tenantName: string,
  ) => {
    setUserAdminId(userAdminId);
    setTenantName(tenantName);
    setShowBillingDataTenants(true);
  }

  const closeEditTenantModal = () => {
    setShowCreateOrUpdateModalTenants(false);
    setShowBillingDataTenants(false);
    setShowTenantReviewPlanChange(false);
    setShowPersonalization(false);
  }

  const closeEditTenantUpdateModal = () => {
    setSelectedRowTenant([]);
    setTenantAdmin(null);
    setShowTenantAdminChange(false);
    setShowBillingDataTenants(false);
    setShowCreateOrUpdateModalTenants(false);
    setShowTenantReviewPlanChange(false);
    setShowPersonalization(false);
  }

  const getActionToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        icon: 'criativy',
        description: 'Gerenciamento Personalização',
        hidden: selectedRowTenant.length !== 1,
        action: () => {
          if (Tenants) {
            const index = selectedRowTenant[0];
            const tenant = Tenants[index];
            if (tenant?.Tenant?.TenantId) {
              editTenantPersonalization(tenant.Tenant.TenantId);
            }
          }
        }
      },
      {
        icon: 'menuGerenciarUsuario',
        description: 'Trocar admin do Tenant',
        hidden: selectedRowTenant.length !== 1,
        action: () => {
          if (Tenants) {
            const index = selectedRowTenant[0];
            const tenant = Tenants[index];
            editTenantAdmin(tenant);
          }
        }
      },
      {
        icon: 'menuContexto',
        description: 'Gerenciamento de Tenant',
        hidden: selectedRowTenant.length !== 1,
        action: () => {
          if (Tenants) {
            const index = selectedRowTenant[0];
            const tenant = Tenants[index];
            editTenant(
              {
                UserStoreItemsId: tenant.UserStoreItem.UserStoreItemsId,
                billingDay: tenant.UserStoreItem.BillingDay,
                maxQtyConstructionSites: tenant.UserStoreItem.MaxQtyConstructionSites,
                maxQtyUploadFiles: tenant.UserStoreItem.MaxQtyUploadFiles,
                price: tenant.UserStoreItem.Price,
                storePlanFk: tenant.UserStoreItem.StorePlan?.StorePlansId,
                contractStartDate: tenant.UserStoreItem.ContractStartDate,
                contractEndDate: tenant.UserStoreItem.ContractEndDate,
                contractPrice: tenant.UserStoreItem.ContractPrice,
                isActive: tenant.UserStoreItem.isActive,
                paymentMethod: tenant.UserStoreItem.PaymentMethod,
                tenantIdTracking: tenant.Tenant.TenantId,
                oldStorePlanTracking: tenant.UserStoreItem.StorePlan?.Name,
                oldStorePlanOrderTracking: tenant.UserStoreItem.StorePlan?.Order,
              },
              tenant.Tenant.CompanyName,
            );
          }
        }
      },
      {
        icon: 'arquivoTxt',
        description: 'Dados de Faturamento',
        hidden: selectedRowTenant.length !== 1,
        action: () => {
          if (Tenants) {
            const index = selectedRowTenant[0];
            const tenant = Tenants[index];
            showBillingData(
              tenant.UserAdminId,
              tenant.Tenant.CompanyName,
            );
          }
        }
      },
      {
        icon: 'atualizar',
        description: ((tenantUpOrDown && tenantUpOrDown.length > 0) &&
          (tenantUpOrDown[0].UserStoreItem.StorePlan && tenantUpOrDown[0].UserStoreItem.UpdateStorePlan) &&
          (tenantUpOrDown[0]?.UserStoreItem.UpdateStorePlan?.Order > tenantUpOrDown[0]?.UserStoreItem.StorePlan?.Order))
          ? 'Revisão de Upgrade' : 'Revisão de Downgrade',
        hidden: (selectedRowTenant.length !== 1) || (tenantUpOrDown && !tenantUpOrDown[0]?.UserStoreItem.UpdateStorePlan),
        action: () => {
          if (tenantUpOrDown?.length === 1) {
            setShowTenantReviewPlanChange(true);
          }
        }
      },
      {
        icon: 'cadeado',
        description: 'Controle de Planos',
        hidden: selectedRowTenant.length !== 0,
        action: () => history.push('/adm/planos'),
      },
      {
        icon: 'phases',
        description: 'Controle de Etapas',
        hidden: selectedRowTenant.length !== 0,
        action: () => history.push('/adm/stages'),
      },
    ]
  };

  const rowSelection = {
    selectedRowTenant,
    onChange
  };

  useEffect(() => {
    dispatch(AdmActions.listTenants());
    dispatch(AdmActions.listPlans());
  }, [dispatch]);

  return (
    <>
      <PageHeader
        name="Controle Administrativo de Tenants"
      />
      <div className={styles['tableWrapper']}>
        <TableActionTopbar buttons={getActionToolbar()} />
        {tenantIsLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={getColumnsTableTenants({
              tenantsList: Tenants,
              plansList: Planos,
              sortTenantsList: sortTenantsList,
              showFilterCompanyTenants: showFilterCompanyTenants,
              setShowFilterCompanyTenants: setShowFilterCompanyTenants,
              showFilterEmailTenants: showFilterEmailTenants,
              setShowFilterEmailTenants: setShowFilterEmailTenants,
              showFilterNameTenants: showFilterNameTenants,
              setShowFilterNameTenants: setShowFilterNameTenants
            })}
            dataSource={Tenants?.map((tenants: any, index: number) => (
              { ...tenants, key: index }
            ))}
            rowSelection={rowSelection}
          />
        )}
      </div>

      {showPersonalization && (
        <TenantPersonalization
          visible={showPersonalization}
          onClose={closeEditTenantModal}
          closeEditTenantPersonalizationModal={closeEditTenantUpdateModal}
        />
      )}
      {showCreateOrUpdateModalTenants && (
        <TenantUpdate
          tenant={tenant}
          tenantName={tenantName}
          onClose={closeEditTenantModal}
          visible={showCreateOrUpdateModalTenants}
          closeEditTenantUpdateModal={closeEditTenantUpdateModal}
        />
      )}
      {showBillingDataTenants && (
        <TenantBillingData
          UserAdminId={userAdminId}
          tenantName={tenantName}
          onClose={closeEditTenantModal}
          visible={showBillingDataTenants}
          closeEditTenantUpdateModal={closeEditTenantUpdateModal}
        />
      )}
      {showTenantReviewPlanChange && (
        <TenantApprovePlanChange
          tenant={
            (tenantUpOrDown && tenantUpOrDown.length === 1)
              ? tenantUpOrDown[0]?.UserStoreItem : undefined
          }
          tenantId={
            (tenantUpOrDown && tenantUpOrDown.length === 1)
              ? tenantUpOrDown[0]?.Tenant.TenantId : undefined
          }
          userInfo={userInfo}
          onClose={closeEditTenantModal}
          visible={showTenantReviewPlanChange}
          closeEditTenantUpdateModal={closeEditTenantUpdateModal}
        />
      )}
      {showTenantAdminChange && (
        <TenantAdminChange
          onCancel={closeEditTenantUpdateModal}
          visible={showTenantAdminChange}
          tenant={tenantAdmin}
          tenantUsers={tenantUsers}
          dispatch={dispatch}
          loading={adminChangeLoading}
        />
      )}
    </>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm,
  userInfo: state.auth.userInfo,
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux;

export default connector(Tenants);