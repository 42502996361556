import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import { Carousel, Progress } from "antd";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import {
  getAttachmentsForUpload,
  getAttachmentsUploading,
} from "Store/Activities/Activities.selector";
import {
  ITopicAttachment,
  ITopicDnDResponse,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import AvatarsItems from "Pages/Activities/components/AvatarsItems";
import { CarouselRef } from "antd/es/carousel";
import ActionAttachmentDropdown from "../../../ActionAttachmentDropdown";
import ImagePreviewGroup from "Components/UI/ImagePreviewGroup";
import HeaderActionsButtons from "../HeaderActionsButtons";
import {
  actionImgPrint,
  actionDownloadImage,
  actionImgOpenInNewWindow,
} from "Utils/TextUtils";
import Ellipsis from "Components/UI/Ellipsis";
import { IUserData } from "Data/interfaces/User/IUserData";
import {
  CarrouselWrapper,
  Content,
  ImgCard,
  ImgCardCarousel,
  ListContent,
  MidleHr,
} from "./styles";

import imgPlaceholder from 'Static/images/image_placeholder.webp';

interface IApresentationAttachmentsVision {
  updateActivity: ITopicDnDResponse | null;
  onHasPermissionDeleteAttachment: (creationUploadFk?: IUserData | null) => boolean;
  onCancel: (attachmentId: string | number) => void;
  onDelete: (attachment?: ITopicAttachment | null) => void;
}

const ApresentationAttachmentsVision: FC<IApresentationAttachmentsVision> = ({
  updateActivity,
  onHasPermissionDeleteAttachment,
  onCancel,
  onDelete,
}) => {
  const theme = useSelector(getTheme);
  const attachmentsUploading = useSelector(getAttachmentsUploading);
  const attachmentsForUpload = useSelector(getAttachmentsForUpload);

  const firstMountRef = useRef(0);
  const attachmentsTotalRef = useRef(0);
  const carouselRef = useRef<CarouselRef | null>(null);
  const listaRef = useRef<HTMLDivElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  const filteredUploading = useMemo(() => {
    if (
      attachmentsUploading?.id &&
      attachmentsForUpload.attachmentsIds.includes(attachmentsUploading.id)
    ) {
      const attsIds = attachmentsForUpload.attachmentsIds.filter(att => att !== attachmentsUploading.id);
      return {
        ...attachmentsForUpload,
        attachmentsIds: attsIds,
      }
    }

    return attachmentsForUpload;
  }, [attachmentsForUpload, attachmentsUploading]);
  const attachmentsTotal = updateActivity?.Attachments.length || 0;
  const currentAttachmentPreview = useMemo(() => {
    if (updateActivity?.Attachments && updateActivity.Attachments.length > 0) {
      return updateActivity.Attachments[currentAttachmentIndex];
    }

    return null;
  }, [currentAttachmentIndex, updateActivity]);

  const handleSliderChange = (index: number) => {
    setSlideIndex(index);
    setCurrentAttachmentIndex(index);

    if (listaRef.current) {
      const cardWidth = listaRef.current?.scrollWidth / listaRef.current?.childElementCount || 0;
      const newScrollLeft = index * cardWidth;

      listaRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handlePrev = () => {
    carouselRef.current?.prev();
    setSlideIndex(prev => {
      if (prev === 0) {
        return attachmentsTotal - 1;
      }

      return prev - 1;
    });
  }

  const handleNext = () => {
    carouselRef.current?.next();
    setSlideIndex(prev => {
      if (prev === attachmentsTotal - 1) {
        return 0;
      }

      return prev + 1;
    });
  }

  const handleGoTo = (index: number) => {
    carouselRef.current?.goTo(index);
    setSlideIndex(index);
  }

  useEffect(() => {
    handleSliderChange(slideIndex);
  }, [slideIndex]);

  useEffect(() => {
    if (firstMountRef.current === 0) {
      firstMountRef.current++;
      attachmentsTotalRef.current = attachmentsTotal;
      handleGoTo(0);
    }

    if (
      firstMountRef.current > 0 &&
      attachmentsTotal !== attachmentsTotalRef.current
    ) {
      attachmentsTotalRef.current = attachmentsTotal;
      handleGoTo(0);
    }
  }, [attachmentsTotal]);

  return (
    <Content>
      {updateActivity?.Attachments && updateActivity.Attachments.length > 0 && (
        <>
          <CarrouselWrapper>
            <Carousel
              ref={carouselRef}
              initialSlide={0}
              dots={false}
              infinite
            >
              {updateActivity.Attachments.map((attachment) => {
                if (attachment) {
                  return (
                    <ImgCardCarousel
                      key={attachment.TopicAttachmentId}
                      onClick={() => setShowPreview(true)}
                    >
                      <div className="image">
                        <img
                          src={attachment.Thumbnail256x256Url}
                          alt="Imagem de anexo"
                          onError={(e) => e.currentTarget.src = imgPlaceholder}
                        />
                      </div>


                      <div className="imgContent">
                        <div className="imgTextContent">
                          <span className="textName">
                            <Ellipsis phrase={attachment.File.Name} maxLength={69} />
                          </span>
                          <span className="imgTextCreatorContent">
                            {attachment.File.CreatorUser && (
                              <AvatarsItems
                                users={[attachment.File.CreatorUser]}
                                customNormalSize={16}
                                customMarginRight="0"
                                customMarginTop="-10px"
                                showDetail
                              />
                            )}
                            <span className="textCreator">
                              {attachment.File.CreationDate.format('DD/MM/YYYY HH:mm')}
                            </span>
                          </span>
                        </div>
                      </div>
                    </ImgCardCarousel>
                  )
                }

                return null;
              })}
            </Carousel>
            <span
              className="iconBtnPrev"
              onClick={handlePrev}
            >
              <Icon
                icon="retroceder"
                customSize={12}
                className="iconPrev"
              />
            </span>
            <span
              className="iconBtnNext"
              onClick={handleNext}
            >
              <Icon
                icon="avancar"
                customSize={12}
                className="iconNext"
              />
            </span>
          </CarrouselWrapper>
          {showPreview && (
            <ImagePreviewGroup
              imagesForShow={updateActivity.Attachments.map(att => att.FileURL)}
              srcName={currentAttachmentPreview?.File.Name}
              visible={showPreview}
              visibleOn
              current={currentAttachmentIndex}
              HeaderButtons={(
                <HeaderActionsButtons
                  onPrint={() => {
                    if (currentAttachmentPreview) {
                      actionImgPrint(currentAttachmentPreview.FileURL);
                    }
                  }}
                  onDownload={() => {
                    if (currentAttachmentPreview) {
                      actionDownloadImage(currentAttachmentPreview.FileURL, currentAttachmentPreview.File.Name);
                    }
                  }}
                  onOpen={() => {
                    if (currentAttachmentPreview) {
                      actionImgOpenInNewWindow(currentAttachmentPreview.FileURL);
                    }
                  }}
                />
              )}
              onCurrentIndex={setCurrentAttachmentIndex}
              onChangeVisible={setShowPreview}
            />
          )}

          <MidleHr />
        </>
      )}
      <ListContent ref={listaRef}>
        {attachmentsUploading && (
          <ImgCard isprogress="true">
            <div className="image">
              <img
                src={imgPlaceholder}
                alt="Imagem de anexo"
              />
              <Progress
                percent={attachmentsUploading.percentage}
                size={[60, 5]}
                showInfo={false}
                trailColor={theme.colors.surface.surface}
                strokeColor={theme.colors.primary.primary}
                className="progressBar"
              />
              <div className="progressMask" />
              <div className="progressMask" />
            </div>

            <Button
              type="text"
              className="buttonCancelOrDelete"
              onClick={() => onCancel(attachmentsUploading.id)}
            >
              <Icon
                icon="cancelar"
                customSize={12}
                className="iconCancelOrDelete"
              />
            </Button>
          </ImgCard>
        )}
        {filteredUploading.attachmentsIds.length > 0 && (
          <>
            {filteredUploading.attachmentsIds.map((imgId) => {
              const attachment = filteredUploading.attachments[imgId];

              if (attachment && attachment?.file && imgId) {
                const hasError = attachment.isError;

                return (
                  <ImgCard key={imgId} haserror={`${hasError}`}>
                    <div className="image">
                      {hasError && (
                        <Icon
                          icon="alerta"
                          customSize={24}
                          className="iconError"
                          error
                        />
                      )}
                      {!hasError && (
                        <img
                          src={imgPlaceholder}
                          alt="Imagem de anexo"
                        />
                      )}
                      <div className="progressMask" />
                    </div>

                    <Button
                      type="text"
                      className="buttonCancelOrDelete"
                      onClick={() => onCancel(imgId)}
                    >
                      <Icon
                        icon={hasError ? 'excluir' : 'cancelar'}
                        customSize={12}
                        className="iconCancelOrDelete"
                      />
                    </Button>
                  </ImgCard>
                )
              }

              return null;
            })}
          </>
        )}
        {updateActivity?.Attachments && updateActivity?.Attachments.length > 0 &&
          updateActivity.Attachments.map((attachment, index) => {
            if (attachment) {
              const isActive = index === slideIndex;
              const hasPermission = onHasPermissionDeleteAttachment(
                attachment?.File?.CreatorUser
              );

              return (
                <ImgCard
                  key={attachment.TopicAttachmentId}
                  isprogress="true"
                  active={`${isActive}`}
                  onClick={() => handleGoTo(index)}
                >
                  <div className="image">
                    <img
                      src={attachment.Thumbnail64x64Url}
                      alt="Imagem de anexo"
                      onError={(e) => e.currentTarget.src = imgPlaceholder}
                    />
                  </div>

                  <ActionAttachmentDropdown
                    hasDeletePermission={hasPermission}
                    onDownload={() => actionDownloadImage(attachment.FileURL, attachment.File.Name)}
                    onDelete={() => onDelete(attachment)}
                    onOpenPage={() => actionImgOpenInNewWindow(attachment.FileURL)}
                  >
                    <Button
                      type="text"
                      className="btnOpenActionAttachment"
                    >
                      <Icon
                        icon="moreActions"
                        customSize={14}
                        className="iconOpenActionAttachment"
                      />
                    </Button>
                  </ActionAttachmentDropdown>
                </ImgCard>
              )
            }

            return null;
          })}
      </ListContent>
    </Content>
  )
};

export default ApresentationAttachmentsVision;
