import { FC } from "react";
import SkeletonMain from "../LoadingMain";
import { MainWrapper } from "./styles";

export interface ILoadingLeftBar {
  multiple?: number;
  isCollapsed?: boolean;
}

const LoadingLeftBar: FC<ILoadingLeftBar> = ({ multiple = 1, isCollapsed }) => {
  const SkeletonUsers = () => {
    return (
      <div className="rowWrapper">
        <SkeletonMain 
          width="14px" 
          height="14px" 
          borderRadius="4px" 
          marginLeft={isCollapsed ? "18px" : "22px"} 
        />
        {!isCollapsed && <SkeletonMain 
          width="100%" 
          height="12px" 
          borderRadius="2px" 
          marginLeft="19px" 
          maxWidth="63px" 
        />}
      </div>
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <MainWrapper iscollapsed={`${isCollapsed}`}>
      <div className="rowWrapper firstRow">
        <SkeletonMain 
          isCustom="skeleton-custom" 
          width="28px"
          minWidth="28px" 
          height="28px" 
          borderRadius="4px"
          marginLeft={isCollapsed ? "8px" : "8px"} 
        />
        {!isCollapsed && (
          <span className="rowText">
            <SkeletonMain 
              isCustom="skeleton-custom" 
              width="90%" 
              height="12px" 
              borderRadius="2px" 
              marginLeft="12px" 
              maxWidth="156px" 
              marginTop="1px" 
            />
          </span>
        )}
      </div>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} />
      ))}
    </MainWrapper>
  )
}

export default LoadingLeftBar;