export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXPIRES_AT_KEY = 'expires_at';
export const ISSUED_DATETIME_KEY = 'issued_datetime';
export const NOME_KEY = 'nome';
export const EMAIL_KEY = 'email';
export const TELEFONE_KEY = 'telefone';
export const USER_IMAGE_KEY = 'user_image';
export const CURRENT_TENANT_KEY = 'current_tenant';
export const CURRENT_ROLE_FK = 'current_role_fk';
export const USER_ID_KEY = 'user_id';
export const CURRENT_IS_FREEMIUM = 'current_is_freemium';
export const IS_REGISTER_COMPLETE = 'is_register_complete';
export const USER_COMPANY_POSITION_ID = 'user_company_position_id';
export const USER_COMPANY_POSITION_TYPE = 'user_company_position_type';
export const USER_HASH = 'user_hash';
export const TENANT_PLAN = 'tenant_plan';
