import styled, { css } from "styled-components";

interface IMainWrapper {
  iscollapsed: string;
}

export const MainWrapper = styled.div<IMainWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;

  .rowWrapper {
    display: flex;
    height: 46px;
    align-items: center;
  }

  .firstRow {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 44px;
    height: 44px;
    margin: 3px 8px;
    padding-right: 16px;
    border-radius: 6px;
    background: ${({theme}) => theme.colors.primary.primary};
  
    ${({iscollapsed}) => iscollapsed === 'true' && css`
      margin: 3px 2px;
      margin-left: 4px;
    `}

    .rowText {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;
