import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  margin-top: 8px;
  width: 180px;
  height: 239px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .titleLote {
    height: 34px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;

    .textLote {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    }
  }

  .mainLote {
    height: 48px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    .mainLoteLeft {
      display: flex;
      flex-direction: row;
      align-items: center;

      .iconLote {
        margin-left: -4px;
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      }

      .labelLote {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
        margin-left: 2px;
      }
    }
  }

  .footerLote {
    height: 56px;
    width: 180px;
    border-top: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;

    .btnLote {
      width: 100%;
    }
  }
`;

interface ICounter {
  active: boolean;
}

export const Counter = styled.span<ICounter>`
  min-width: 80px;
  max-width: 80px;
  height: 32px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};
  display: flex;
  align-items: center;
  justify-content: space-around;

  .iconCounter {
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
  }

  .textCounter {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ active, theme }) => active
    ? theme.colors.secondary.onSecondary
    : theme.colors.surface.onSurfacePlaceholder};
  }
`;
