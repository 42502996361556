import { Icon } from "Components/UI/Icon";
import React, { FC } from "react";
import styles from "./EmptySearch.module.scss";

export interface IEmptySearch {
  searchDiscipline?: string;
  searchDisciplineFiles?: string;
}

const EmptySearch: FC<IEmptySearch> = ({
  searchDiscipline,
  searchDisciplineFiles,
}) => {

  return (
    <div className={styles['wrapper']}>
      <div className={styles['search']}>
        <Icon icon="buscar" customColor="#BEC2C6" customSize={16} />
      </div>
      {searchDiscipline && (
        <>
          <p className={styles['firstChild']}>
            Disciplina {`"${searchDiscipline}"`} não encontrada.
          </p>
          <p className={styles['lastChild']}>
            A disciplina pode não ter nenhum modelo cadastrado, e por isso não está aparecendo na listagem.
          </p>
        </>
      )}
      {searchDisciplineFiles && (
        <>
          <p className={styles['firstChild']}>
            Modelo {`"${searchDisciplineFiles}"`} não encontrado.
          </p>
          <p className={styles['lastChild']}>
            Não foi encontrado nenhum modelo com esse nome. Verifique sua pesquisa ou procure o modelo na Coordly através do botão +
          </p>
        </>
      )}
    </div>
  )
}

export default EmptySearch;
