import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { UploadActions } from '../../../Store/Upload/Upload.actions';
import { IUploadFileData } from 'Data/interfaces/Upload/IUploadFileData';
import { ApisEnum } from 'Data/enums/Apis';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { Icon } from '../Icon';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import StageFileItem from './StageFileItem';
import FileItem from './FileItem';
import { UploadFileWrapper } from './styles';

export interface ITrackUploadBulkFiles {
  type?: 'init' | 'time' | 'last' | 'finish' | 'error';
  active?: boolean;
  elapsedTime?: number;
  totalFilesSize?: number;
  totalFiles?: number;
  lastItem?: boolean;
  destiny?: any;
  api?: ApisEnum;
}

const UploadFiles: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const stagesFeatureValidateFlag = useFeatureFlag('stages-feature-validate');
  const stagesFeatureValidate = stagesFeatureValidateFlag.enabled && stagesFeatureValidateFlag.value === 'test';

  const [collapse, setCollapse] = useState(false);

  const toggle = () => { setCollapse(!collapse); };

  const isAllFilesFinished = () => {
    return props.uploadFileList?.every((uploadFile) => uploadFile.uploadCompleted || uploadFile.removed || uploadFile.uploadError);
  };

  const closeUploadFiles = () => {
    dispatch(UploadActions.updateUploadFileList([]));
  };

  const removeFile = (index: number) => {
    if (props.uploadFileList) {
      props.uploadFileList[index].removed = true;
      dispatch(UploadActions.updateUploadFileList([...props.uploadFileList]));
    }
  };

  const changeFilename = (index: number, name: string) => {
    if (props.uploadFileList) {
      props.uploadFileList[index].newFileName = name;
      props.uploadFileList[index].checkUploadFileInfo = undefined;
      dispatch(UploadActions.updateUploadFileList([...props.uploadFileList]));
    }
  };

  useEffect(() => {
    const uploadSingleFile = (data: IUploadFileData) => {
      dispatch(UploadActions.uploadChunkFiles(data));
    }

    if (props.uploadFileList && props.uploadFileList.length > 0) {
      // track upload bulk
      const notUpload = props.uploadFileList.filter(file => !file.uploadCompleted);
      if (!props.trackUploadBulkFiles?.active && notUpload.length > 1) {
        const totalSize = notUpload.reduce((acc, value) => {
          return acc += value.file.size;
        }, 0);
        const request: ITrackUploadBulkFiles = {
          type: 'init',
          active: true,
          totalFiles: notUpload.length,
          totalFilesSize: totalSize,
          elapsedTime: 0,
          lastItem: false,
        };
        dispatch(UploadActions.trackUploadBulkFiles(request));
      }
      if (props.trackUploadBulkFiles?.active && notUpload.length === 1) {
        const request: ITrackUploadBulkFiles = {
          type: 'last',
          lastItem: true,
        };
        dispatch(UploadActions.trackUploadBulkFiles(request));
      }

      //realizar upload do arquivo
      const filesUploading = props.uploadFileList.filter(x => x.isSending);

      if (!filesUploading || filesUploading?.length === 0) {
        //verificar check de nomenclatura
        //agora a verificação continua em lote, mas arquivos adicionados
        //enquanto um está em upload são verificados no próximo lote,
        //assim que o upload daquele finalizar
        const filesToCheckNomenclature = props.uploadFileList?.filter(x => !(x?.checkUploadFileInfo || x.isChecking));

        if (filesToCheckNomenclature && filesToCheckNomenclature.length > 0) {
          filesToCheckNomenclature.forEach(x => x.isChecking = true);
          dispatch(UploadActions.checkUploadFiles(filesToCheckNomenclature));
        }

        const filesToUpload = props.uploadFileList.filter(file =>
          !file.checkUploadFileInfo?.FileNameExist &&
          !file.checkUploadFileInfo?.HasInconsistencia &&
          !file.isSending &&
          !file.isChecking &&
          !file.uploadCompleted &&
          !file.updateFileInfo?.finished &&
          !file.uploadError);

        if (filesToUpload?.length > 0) {
          const fileToUpload = filesToUpload[0];
          fileToUpload.isSending = true;

          if (!fileToUpload.uploadCompleted && !fileToUpload.updateFileInfo?.finished) {
            fileToUpload.Api = props.obra.Api;

            if (props.obra.VersionConfig) {
              fileToUpload.nomenclaturaControlTracking = props.obra.VersionConfig;
              fileToUpload.hasRevisionControl = props.obra.VersionConfig.HasRevisionControl;
            }

            uploadSingleFile(fileToUpload);
          }
        }
      }
    }
  }, [
    dispatch,
    props.uploadFileList,
    props.obra,
    props.trackUploadBulkFiles?.active,
    props.NomenclatureData,
  ]);

  useEffect(() => {
    if (props.trackUploadBulkFilesNow && props.trackUploadBulkFiles) {
      if (props.trackUploadBulkFiles.type === 'error') {
        Mixpanel.track({
          name: 'FILE_BULK_UPLOAD_ERROR',
          props: {
            quantity: props.trackUploadBulkFiles?.totalFiles,
            elapsed: props.trackUploadBulkFiles?.elapsedTime,
            destiny: props.trackUploadBulkFiles?.destiny,
            api: props.trackUploadBulkFiles?.api,
            filesTotalSize: props.trackUploadBulkFiles?.totalFilesSize,
          },
          userInfo: props.userInfo,
          currentListTenant: currentTenant,
        });
      }

      if (props.trackUploadBulkFiles.type === 'finish') {
        Mixpanel.track({
          name: 'FILE_BULK_UPLOAD',
          props: {
            quantity: props.trackUploadBulkFiles.totalFiles,
            elapsed: props.trackUploadBulkFiles.elapsedTime,
            destiny: props.trackUploadBulkFiles.destiny,
            api: props.trackUploadBulkFiles.api,
            filesTotalSize: props.trackUploadBulkFiles.totalFilesSize,
          },
          userInfo: props.userInfo,
          currentListTenant: currentTenant,
        });
      }

      dispatch(UploadActions.clearTrackUploadBulkFiles());
    }
  }, [
    props.trackUploadBulkFilesNow,
    props.trackUploadBulkFiles,
    props.userInfo,
  ]);

  return (
    <UploadFileWrapper hidden={!props.uploadFileList?.length}>
      <div className="headerUploadWrapper">
        <span className="title" onClick={toggle}>Envio de arquivos</span>
        {isAllFilesFinished() ? (
          <div onClick={closeUploadFiles}>
            <Icon
              icon='errorSenha'
              customSize={10}
              className="headerUploadIcon"
            />
          </div>
        ) : (
          <div onClick={toggle}>
            <Icon
              icon={collapse ? 'avancar' : 'expandir'}
              customSize={10}
              className="headerUploadIcon"
            />
          </div>
        )}
      </div>
      <div className="listFilesWrapper" hidden={collapse}>
        {props.uploadFileList?.map((uploadFile, index) => {
          if (stagesFeatureValidate) {
            return (
              <StageFileItem
                key={`uploadedItem${index}`}
                index={index}
                uploadFile={uploadFile}
                isLoading={props.isLoadingFileList}
                obra={props.obra}
                stages={props.validStages?.hasOwnProperty(uploadFile.csId)
                  ? props.validStages[uploadFile.csId]
                  : []
                }
                stagesFeatureValidate={stagesFeatureValidate}
                removeFile={removeFile}
                changeFilename={changeFilename}
              />
            )
          }
          return (
            <FileItem
              key={`uploadedItem${index}`}
              index={index}
              uploadFile={uploadFile}
              nomenclature={props.obra.VersionConfig}
              isLoading={props.isLoadingFileList}
              removeFile={removeFile}
              changeFilename={changeFilename}
            />
          )
        })}
      </div>
    </UploadFileWrapper>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  ...state.upload,
  isLoadingFileList: state.listagemArquivos.isLoading,
  userInfo: state.auth.userInfo,
  obra: state.listagemArquivos.Obra,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(UploadFiles);
