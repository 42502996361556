import { FC, useEffect, useState } from 'react';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import styles from './UpdateEmpresa.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import Form from '../../../../Components/UI/Form/Form';
import Field from '../../../../Components/UI/Field';
import { Icon } from '../../../../Components/UI';
import UploadAvatar from '../../../../Components/UI/UploadAvatar';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import schema from './updateEmpresa.schema';
import Tabs from '../../../../Components/UI/Tabs/Tabs';
import {
  stringToPhone,
  stringToCPF,
  stringToCNPJ,
  cleanMask,
  stringToCEP
} from '../../../../Utils/MaskUtils';
import { Row, Col } from 'antd';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import { MinhaContaActions } from '../../../../Store/MinhaConta/MinhaConta.actions';
import { IUpdateUserCompanyDataRequest } from 'Data/interfaces/MinhaConta/IUpdateUserCompanyDataRequest';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import PlanoAvisoPersonalizationUpgrade from 'Static/images/plano-aviso-personalization-upgrade.png';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { Mixpanel } from 'Utils/MixPanel';
import { history } from 'Store';
import { Wrapper } from './styles';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface IModal {
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: (value: boolean) => void;
  isLoading?: boolean;
  userInfos: IUserInforResponse | undefined;
}

const UpdateEmpresa: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string') 
    ? whatsappContactLinkFlag.value 
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const userRole = props.userInfo?.CurrentRoleFk;
  const isAdmin = userRole === 1;
  const planOrder = props?.userInfo?.UserStoreItem?.StorePlan?.Order;
  const isStart = (planOrder || 0) <= 1;
  const isEnterprise = planOrder && (planOrder >= 4);

  const [tabSelected, setTabSelected] = useState('0');
  const [isSubmited, setIsSubmited] = useState(false);
  const [imageCompany, setImageCompany] = useState<string>(
    props.userInfos?.Company?.AvatarFileName || ''
  );
  const [updateImageCompany, setUpdateImageCompany] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>(
    props.userInfos?.Company?.Name || ''
  );
  const [companyPhone, setCompanyPhone] = useState<string>(
    props.userInfos?.Company?.Phone || ''
  );
  const [companyStreetAddress, setCompanyStreetAddress] = useState<string>(
    props.userInfos?.Company?.StreetAddress || ''
  );
  const [companyZip, setCompanyZip] = useState<string>(
    props.userInfos?.Company?.Zip || ''
  );
  const [companyNumber, setCompanyNumber] = useState<number | undefined | null>(
    props.userInfos?.Company?.Number || undefined || null
  );
  const [companyDistrict, setCompanyDistrict] = useState<string>(
    props.userInfos?.Company?.District || ''
  );
  const [companyComplement, setCompanyComplement] = useState<string>(
    props.userInfos?.Company?.Complement || ''
  );
  const [companyCity, setCompanyCity] = useState<string>(
    props.userInfos?.Company?.City || ''
  );
  const [companyState, setCompanyState] = useState<string>(
    props.userInfos?.Company?.State || ''
  );

  const [companyCnpj, setCompanyCnpj] = useState<string>(
    props.userInfos?.Company?.CNPJ || ''
  );
  const [companyOwnerName, setCompanyOwnerName] = useState<string>(
    props.userInfos?.Company?.OwnerName|| ''
  );
  const [companyOwnerCpf, setCompanyOwnerCpf] = useState<string>(
    props.userInfos?.Company?.OwnerCPF || ''
  );
  const [companySite, setCompanySite] = useState<string>(
    props.userInfos?.Company?.Site || ''
  );
  const [zipInvalid, setZipInvalid] = useState<string>('');

  const onUploadImageCompany = (uploadedImage: string) => {
    setImageCompany(uploadedImage);
    setUpdateImageCompany(true);
  };

  useEffect(() => {
    setImageCompany(props.userInfos?.Company?.AvatarFileName || '');
  }, [props.userInfos]);

  useEffect(() => {
    setCompanySite(props?.userInfo?.Company?.Site || '')
    setCompanyOwnerCpf(props?.userInfo?.Company?.OwnerCPF || '')
    setCompanyCnpj(props?.userInfo?.Company?.CNPJ ||'');
    setCompanyOwnerName(props?.userInfo?.Company?.OwnerName || '')
    setCompanyStreetAddress(props.userInfos?.Company?.StreetAddress || '');
    setCompanyZip(props.userInfos?.Company?.Zip || '');
    setCompanyNumber(props.userInfos?.Company?.Number || undefined || null);
    setCompanyDistrict(props.userInfos?.Company?.District || '');
    setCompanyComplement(props.userInfos?.Company?.Complement || '');
    setCompanyCity(props.userInfos?.Company?.City || '');
    setCompanyState(props.userInfos?.Company?.State || '');
    setCompanyPhone(props.userInfos?.Company?.Phone || '');
    setCompanyName(props.userInfos?.Company?.Name || '');
  }, [props.userInfos]);

  const updateDadosEmpresa = () => {
    setIsSubmited(false);
    if (companyName === '' || companyPhone === '') {
      setTabSelected('0');
    } else if (
      companyStreetAddress === '' ||
      companyZip === '' ||
      companyNumber === undefined ||
      companyDistrict === '' ||
      companyCity === '' ||
      companyState === ''
    ) {
      setTabSelected('1');
    } else {
      const data: IUpdateUserCompanyDataRequest = {
        AvatarFileName:
          imageCompany === ''
            ? ''
            : imageCompany?.substr(imageCompany?.lastIndexOf('/') + 1),
        StreetAddress: companyStreetAddress,
        Zip: companyZip,
        Number: companyNumber || null,
        District: companyDistrict,
        Complement: companyComplement,
        City: companyCity,
        State: companyState,
        Phone: companyPhone,
        Name: companyName,
        CNPJ: companyCnpj,
        OwnerName: companyOwnerName,
        OwnerCPF: companyOwnerCpf,
        Site: companySite,
      };
      dispatch(MinhaContaActions.updateDadosEmpresa(data));
      setUpdateImageCompany(false);
      if (props.onConfirm) {
        props.onConfirm(true);
      }
    }
  };

  const searchNewAddress = async (e: any) => {
    let zipCode = cleanMask(e);
    setCompanyZip(zipCode);

    if (zipCode.length === 8) {
      try {
        const addressResponse = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
        const data = await addressResponse.json();
        if (data && !data.erro) {
          setZipInvalid('');
          setCompanyStreetAddress(data.logradouro);
          setCompanyDistrict(data.bairro);
          setCompanyCity(data.localidade);
          setCompanyState(data.uf);
        } else if (data.erro) {
          setZipInvalid('CEP inválido, esteja ciente ao prosseguir');
        }
      } catch (err) {}
    }
  };

  const upgradePlanRedirect = (role: number, planOrder: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION', 
      props: {
        origin: 'minha-conta',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    if (role === 1) {
      if (planOrder === 4) {
        window.open(whatsappContactLink);
      } else {
        history.push('/faturamento/planos');
      }

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const buttons = (
    <div>
      <Button height={37} onClick={props.onCancel}>
        <span style={{ padding: '0 20px' }}>Cancelar</span>
      </Button>
      {tabSelected === '2' ? (
        <Button 
          type='primary' 
          ispremium="true"
          width={272} 
          height={37} 
          onClick={() => upgradePlanRedirect(userRole || 0, planOrder || 0)}
        >
          <span style={{marginRight: '6px'}}>
            <Icon 
              icon="crown" 
              size="xxs" 
              color="cinzaEscuro" 
            />
          </span>
          {isAdmin 
              ? 'Adquirir o plano Enterprise'
              : 'Solicitar o plano Enterprise'
          }
        </Button>
      ) : (
        <Button
          type="primary"
          htmlType="submit"
          height={37}
          onClick={() => setIsSubmited(true)}
          loading={props.isLoading}
        >
          <span style={{ padding: '0 18px' }}>Atualizar</span>
        </Button>
      )}
    </div>
  );

  const tabs = [
    {
      title: 'Dados básicos',
      key: '0',
      disable: false,
      children: (
        <div className={styles['boxDadosEmpresa']}>
          <div className={styles['DadosEmpresaLeft']}>
            <UploadAvatar onUploadImage={onUploadImageCompany}>
              {(isLoading: boolean) => {
                return (
                  <div
                    className={styles['changeImageWrapper']}
                    style={{
                      backgroundImage: imageCompany
                        ? `linear-gradient(rgba(46,163,242, .6), rgba(46,163,242, .6)),
                              url('${process.env.REACT_APP_IMAGE_STORAGE_URL}${imageCompany}')`
                        : ''
                    }}
                  >
                    {isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            rev=""
                            color="white"
                            style={{ fontSize: 40, color: 'white' }}
                          />
                        }
                      />
                    ) : (
                      <>
                        <Icon color="branco" customSize={19} icon="editarImg" />
                        <span className={styles['changeImageLabel']}>Alterar imagem</span>
                      </>
                    )}
                  </div>
                );
              }}
            </UploadAvatar>
          </div>
          <div className={styles['DadosEmpresaRight']}>
            <div className={styles['boxData']}>
              <Field
                name="Name"
                className={styles['fields']}
                defaultValue={companyName}
                onInput={setCompanyName}
                label="Nome da empresa"
              />

              <Field
                name="CNPJ"
                className={styles['fields']}
                defaultValue={stringToCNPJ(companyCnpj || '')}
                label="CNPJ"
                onInput={setCompanyCnpj}
                parse={(value) => value && stringToCNPJ(value)}
                maxLenght={18}
              />

              <Field
                name="Phone"
                className={styles['fields']}
                defaultValue={stringToPhone(companyPhone)}
                label="Telefone"
                onInput={setCompanyPhone}
                parse={(value) => value && stringToPhone(value)}
                maxLenght={15}
              />

              <Field
                name="OwnerName"
                className={styles['fields']}
                defaultValue={companyOwnerName}
                onInput={setCompanyOwnerName}
                label="Responsável pela empresa"
              />

              <Field
                name="OwnerCPF"
                className={styles['fields']}
                defaultValue={stringToCPF(companyOwnerCpf || '')}
                label="CPF do responsável"
                onInput={setCompanyOwnerCpf}
                parse={(value) => value && stringToCPF(value)}
                maxLenght={14}
              />

              <Field
                name="Site"
                className={styles['fields']}
                defaultValue={companySite|| ''}
                label="Site corporativo"
                onInput={setCompanySite}
              />
            </div>
          </div>
        </div>
      )
    },
    {
      title: 'Endereço',
      key: '1',
      disable: false,
      children: (
        <div className={styles['boxDadosEmpresa']}>
          <div className={styles['DadosEndereco']}>
            <div className={styles['boxData']}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Field
                    className={styles['fields']}
                    name="StreetAddress"
                    defaultValue={companyStreetAddress}
                    onInput={setCompanyStreetAddress}
                    label="Logradouro"
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={10}>
                  <Field
                    className={styles['fields']}
                    name="Zip"
                    defaultValue={stringToCEP(companyZip)}
                    onInput={searchNewAddress}
                    label="CEP"
                    maxLenght={9}
                    outError={zipInvalid}
                  />
                </Col>
                <Col span={7}>
                  <Field
                    className={styles['fields']}
                    name="Number"
                    defaultValue={companyNumber || undefined}
                    onInput={(value) => setCompanyNumber(parseInt(value))}
                    label="Número"
                  />
                </Col>
                <Col span={7}>
                  <Field
                    className={styles['fields']}
                    name="District"
                    defaultValue={companyDistrict}
                    onInput={setCompanyDistrict}
                    label="Bairro"
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={10}>
                  <Field
                    className={styles['fields']}
                    name="Complement"
                    defaultValue={companyComplement}
                    onInput={setCompanyComplement}
                    label="Complemento"
                  />
                </Col>
                <Col span={7}>
                  <Field
                    className={styles['fields']}
                    name="City"
                    defaultValue={companyCity}
                    onInput={setCompanyCity}
                    label="Cidade"
                  />
                </Col>
                <Col span={7}>
                  <Field
                    className={styles['fields']}
                    name="State"
                    defaultValue={companyState}
                    onInput={setCompanyState}
                    label="Estado"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )
    }
  ];

  if (!isEnterprise) {
    tabs.push({
      title: 'Personalização',
      key: '2',
      disable: false,
      children: (
        <Wrapper>
          <div className="imageWrapper">
            <img src={PlanoAvisoPersonalizationUpgrade} alt="Imagem CTA Upgrade Activity" />
          </div>
          <div className={`contentWrapper ${!isStart ? 'contentWrapperPro' : ''}`}>
            <h2>
              {isStart  
                ? isAdmin 
                    ? 'Dê um upgrade na sua conta gratuita'
                    : 'Dê um upgrade na conta da sua empresa'
                : isAdmin
                    ? 'Dê um upgrade no seu plano'
                    : 'Dê um upgrade no plano da sua empresa'
              }
            </h2>
            <h3>
              {isAdmin 
                ? 'Para personalizar seu sistema'
                : 'Para personalizar o sistema'
              }
            </h3>
            {isStart && (
              <>  
                <p>O plano Enterprise ainda proporciona:</p>
                <div className="icons-wrapper">
                  <div className={`icons-text-wrapper icons-text-margin`}>
                    <div>
                      <Icon icon={'arquivo3d'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} /> 
                      <span>Gestão de documentos 2D e 3D</span>
                    </div>
                    <div>
                      <Icon icon={'estrelaVazia'} size={'xs'} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                      <span>Controle de nomeclatura</span>
                    </div>
                    <div>
                      <Icon icon={'activities'} customSize={16} customColor={'#8697A8'} style={{ paddingLeft: 0, marginLeft: 0 }} />
                      <span>Controle de atividades</span>
                    </div>
                  </div>
                  <div className="icons-text-wrapper">
                    <div>
                      <Icon icon={'menuGerenciarUsuario'} size={'xs'} customColor={'#8697A8'} /> 
                      <span>Gestão de usuários e papéis na obra</span>
                    </div>
                    <div>
                      <Icon icon={'menuPlotagem'} size={'xs'} customColor={'#8697A8'} /> 
                      <span>Controle de plotagem</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: 14 }}>&nbsp;</div>
                </div>
              </>
            )}
          </div>
        </Wrapper>
      )
    });
  }

  return (
    <Modal
      title="Dados da empresa"
      footerButtons={buttons}
      visible={props.visible}
      width={800}
      onCancel={props.onCancel}
    >
      <Form
        onSubmit={updateDadosEmpresa}
        isSubmited={isSubmited}
        schema={schema}
        resetForm={true}
        className={styles['form']}
      >
        <Tabs
          tabs={tabs}
          activeKey={tabSelected}
          onTabClick={(key: string) => {
            setTabSelected(key);
          }}
        />
      </Form>
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.minhaConta,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IModal;

export default connector(UpdateEmpresa);
