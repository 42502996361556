import React from 'react';

import { Tag, Dropdown, Spin } from 'antd';
import { Icon } from 'Components/UI/Icon';

import './StatusDropdown.css';
import styles from './StatusDropdown.module.scss';
import { ITenantUpdateStatusRequest } from 'Data/interfaces/Tenant/ITenantUpdateStatusRequest';
import { LoadingOutlined } from '@ant-design/icons';

interface IStatusDropdown {
  text: string;
  isInvited?: boolean;
  isAdmin?: boolean;
  updateStatusRequest?: ITenantUpdateStatusRequest;
  overlay: React.ReactNode;
  activeLoading?: boolean;
  disabled?: boolean;
}

export const colorText = (type: string) => {
  if (type.toLocaleLowerCase() === 'ativo') return '#09B66D';
  if (type.toLocaleLowerCase() === 'inativo') return '#BEC2C6';
  
  return '#324150'
}

export const colorTag = (type: string) => {
  if (type.toLocaleLowerCase() === 'ativo') return 'rgba(9, 182, 109, 0.15)';
  if (type.toLocaleLowerCase() === 'inativo') return 'rgba(190, 194, 198, 0.15)';
  
  return 'rgba(50, 65, 80, 0.15)'
}

const StatusDropdown = ({ 
  text, 
  isInvited, 
  isAdmin, 
  overlay, 
  activeLoading,
  disabled 
}: IStatusDropdown) => {
  const antIcon = <LoadingOutlined rev=""style={{ color: '#09B66D' , fontSize: 12 }} spin />;

  return (
    <>
      {!isInvited ? (isAdmin ? (
        <Tag 
          color={colorTag(text)}  
          className={styles['tagPending']}
        >
          <span style={{ color: colorText(text) }}>
            {text}
          </span>
        </Tag>
      ) : (
        <Dropdown 
          overlay={overlay as any}
          trigger={['click']}
          placement="bottomLeft"
          disabled={disabled}
          className={`${disabled ? 'dropdownCursorDisabled' : ''}`}
        >
          {!activeLoading ? (
            <Tag color={colorTag(text)} className={`${styles['tagRender']} ${styles[text.toLowerCase()]}`}>
              <span style={{ color: colorText(text) }} >
                {text}
              </span>
              {!disabled && <Icon icon="expandir" customSize={8} customColor={colorText(text)} />}
            </Tag>
          ) : (
            <Tag color={colorTag('ativo')} className={`${styles['tagRender']} ${styles['ativo'.toLowerCase()]}`}>
              {activeLoading && <Spin indicator={antIcon}/>}
              <span style={{ color: colorText('ativo') }}>
                Ativo
              </span>
            </Tag>
          )}
        </Dropdown>
        )
      ) : (
        <Tag 
          color={colorTag('pendente')}  
          className={styles['tagPending']}
        >
          <span style={{ color: colorText('pendente') }}>
            Pendente
          </span>
        </Tag>
      )}
    </>
  )
}

export default StatusDropdown