export type ColorName =
  | 'cinzaPadrao'
  | 'cinzaOpaco'
  | 'primary'
  | 'onPrimary'
  | 'cinzaSuperior'
  | 'branco'
  | 'vermelho'
  | 'verde'
  | 'preto'
  | 'transparent'
  | 'cinzaIcone'
  | 'amarelo'
  | 'cinzaEscuro';

export const colorClasses = {
  cinzaPadrao: '#B3C0CE',
  cinzaOpaco: '#BDBDBD',
  primary: '#3547C8',
  onPrimary: '#FFFFFF',
  cinzaSuperior: '#8697A8',
  branco: '#FFFFFF',
  vermelho: '#E2574C',
  verde: '#09B66D',
  preto: '#000000',
  transparent: 'transparent',
  cinzaIcone: '#44566C',
  amarelo: '#FDBF5E',
  cinzaEscuro: '#324150'
};

export const getColor = (color: ColorName): string => colorClasses[color];

export const isDarken = (color: string): boolean => {
  const R = parseInt(color.substring(1,3),16);
  const G = parseInt(color.substring(3,5),16);
  const B = parseInt(color.substring(5,7),16);

  const Y = 0.2126*R + 0.7152*G + 0.0722*B

  if (Y > 128) {
    return false;
  }

  return true;
}
