import { IDisciplinaState } from './interfaces/IDisciplinaState';
import { DisciplinaActionUnion, DisciplinaActionKeys } from './Disciplina.actions';
import { DisciplineFolderStatusEnum } from 'Data/interfaces/Obra/IDisciplineResponse';
import { FileStatusInStorageEnum } from 'Data/interfaces/Obra/IDisciplineSAStatusResponse';

const initialState: IDisciplinaState = {
  isLoading: false,
  Obra: undefined,
  disciplineContainsFile: 0,
  isDisciplineLoading: false,
  disciplineSAStatus: undefined,
  existingFolderApiId: undefined,
  isDisciplineStatusSALoading: false,
  isDisciplineSolutionLoading: false,
  listArchivesNewDisciplineNoCache: null,
};

const disciplinaReducer = (
  state = initialState,
  action: DisciplinaActionUnion
): IDisciplinaState => {
  switch (action.type) {
    case DisciplinaActionKeys.LIST_DISCIPLINE_REQUEST:
      return {
        ...state,
        Obra: undefined,
        isLoading: true
      };
    case DisciplinaActionKeys.LIST_DISCIPLINE_SUCCESS:
      return {
        ...state,
        Obra: { ...action.payload },
        isLoading: false
      };
    case DisciplinaActionKeys.LIST_DISCIPLINE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case DisciplinaActionKeys.LIST_DISCIPLINE_UPDATE:
      return {
        ...state,
        Obra: { ...action.payload },
        isLoading: false
      };

    case DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case DisciplinaActionKeys.CREATE_OR_UPDATE_DISCIPLINE_SUCCESS:
      if (state.Obra) {
        const index = state.Obra.Disciplines.findIndex(
          (discipline: any) =>
            discipline.ConstructionSiteDisciplinesId ===
            action.payload.ConstructionSiteDisciplinesId
        );
        if (index >= 0) {
          state.Obra.Disciplines[index] = action.payload;
        } else {
          state.Obra.Disciplines = [...state.Obra.Disciplines, action.payload];
        }
      }
      return {
        ...state,
        isLoading: false
      };

    case DisciplinaActionKeys.DISCIPLINES_RESET_STATE:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        message: undefined
      };

    case DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_SUCCESS:
      return {
        ...state,
        disciplineContainsFile: action.payload,
        isLoading: false
      };
    case DisciplinaActionKeys.LIST_FILES_ON_DISCIPLINE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case DisciplinaActionKeys.DELETE_DISCIPLINE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DisciplinaActionKeys.DELETE_DISCIPLINE_SUCCESS:
      let currentStateObras = state;
      if (currentStateObras.Obra) {
        const index = currentStateObras.Obra.Disciplines.findIndex(
          (discipline: any) =>
            discipline.ConstructionSiteDisciplinesId ===
            action.payload.ConstructionSiteDisciplineId,
        );
        currentStateObras.Obra.Disciplines.splice([index], 1)
      }
      return {
        ...state,
        ...currentStateObras,
        isLoading: false
      };
    case DisciplinaActionKeys.DELETE_DISCIPLINE_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case DisciplinaActionKeys.STATUS_SA_DISCIPLINE_REQUEST:
      return {
        ...state,
        disciplineSAStatus: undefined,
        existingFolderApiId: undefined,
        isDisciplineStatusSALoading: true
      };
    case DisciplinaActionKeys.STATUS_SA_DISCIPLINE_SUCCESS:
      let ObraDisciplineStatus = state.Obra;
      if (
        action.payload.disciplineSAStatus === FileStatusInStorageEnum.Ok &&
        ObraDisciplineStatus && 
        ObraDisciplineStatus?.Disciplines && 
        ObraDisciplineStatus.Disciplines.length > 0
      ) {
        const disciplineRestoreIndex = ObraDisciplineStatus.Disciplines.findIndex((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId === action.payload.ConstructionSiteDisciplineId;
        })
        if (disciplineRestoreIndex !== -1) {
          ObraDisciplineStatus.Disciplines[disciplineRestoreIndex].FolderStatus = DisciplineFolderStatusEnum.Sync;
        }
      }

      return {
        ...state,
        Obra: ObraDisciplineStatus ? { ...ObraDisciplineStatus } : undefined,
        disciplineSAStatus: action.payload.disciplineSAStatus,
        existingFolderApiId: action.payload.existingFolderApiId,
        isDisciplineStatusSALoading: false
      };
    case DisciplinaActionKeys.STATUS_SA_DISCIPLINE_FAILED:
      return {
        ...state,
        disciplineSAStatus: undefined,
        existingFolderApiId: undefined,
        isDisciplineStatusSALoading: false
      };

    case DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_REQUEST:
      return {
        ...state,
        isDisciplineSolutionLoading: true
      };
    case DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_SUCCESS:
      let ObraDisciplineRestore = state.Obra;
      if (
        ObraDisciplineRestore && 
        ObraDisciplineRestore?.Disciplines && 
        ObraDisciplineRestore.Disciplines.length > 0 && 
        action.payload
      ) {
        const disciplineRestoreIndex = ObraDisciplineRestore.Disciplines.findIndex((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId === action.payload;
        })
        if (disciplineRestoreIndex !== -1) {
          ObraDisciplineRestore.Disciplines[disciplineRestoreIndex].FolderStatus = DisciplineFolderStatusEnum.Sync;
        }
      }

      return {
        ...state,
        Obra: ObraDisciplineRestore ? { ...ObraDisciplineRestore } : undefined,
        isDisciplineSolutionLoading: false
      };
    case DisciplinaActionKeys.RESTORE_SA_DISCIPLINE_FAILED:
      return {
        ...state,
        isDisciplineSolutionLoading: false
      };

    case DisciplinaActionKeys.CREATE_SA_DISCIPLINE_REQUEST:
      return {
        ...state,
        isDisciplineSolutionLoading: true
      };
    case DisciplinaActionKeys.CREATE_SA_DISCIPLINE_SUCCESS:
      let ObraDisciplineCreate = state.Obra;
      if (
        ObraDisciplineCreate && 
        ObraDisciplineCreate?.Disciplines && 
        ObraDisciplineCreate.Disciplines.length > 0 && 
        action.payload
      ) {
        const disciplineRestoreIndex = ObraDisciplineCreate.Disciplines.findIndex((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId === action.payload.ConstructionSiteDisciplinesId;
        })
        if (disciplineRestoreIndex !== -1) {
          ObraDisciplineCreate.Disciplines[disciplineRestoreIndex] = action.payload;
        }
      }

      return {
        ...state,
        Obra: ObraDisciplineCreate ? { ...ObraDisciplineCreate } : undefined,
        isDisciplineSolutionLoading: false
      };
    case DisciplinaActionKeys.CREATE_SA_DISCIPLINE_FAILED:
      return {
        ...state,
        isDisciplineSolutionLoading: false
      };
    case DisciplinaActionKeys.LIST_ARCHIVES_NEW_DISCIPLINE_NO_CACHE:
      return {
        ...state,
        listArchivesNewDisciplineNoCache: action.payload
      };

    case DisciplinaActionKeys.DELETE_SA_DISCIPLINE_REQUEST:
      return {
        ...state,
        isDisciplineSolutionLoading: true
      };
    case DisciplinaActionKeys.DELETE_SA_DISCIPLINE_SUCCESS:
      let ObraDisciplineDelete = state.Obra;
      if (
        ObraDisciplineDelete && 
        ObraDisciplineDelete?.Disciplines && 
        ObraDisciplineDelete.Disciplines.length > 0 && 
        action.payload
      ) {
        const filteredDisciplines = ObraDisciplineDelete.Disciplines.filter((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId !== action.payload;
        })
        ObraDisciplineDelete.Disciplines = filteredDisciplines;
      }

      return {
        ...state,
        Obra: ObraDisciplineDelete ? { ...ObraDisciplineDelete } : undefined,
        isDisciplineSolutionLoading: false
      };
    case DisciplinaActionKeys.DELETE_SA_DISCIPLINE_FAILED:
      return {
        ...state,
        isDisciplineSolutionLoading: false
      };

    case DisciplinaActionKeys.MERGE_SA_DISCIPLINE_REQUEST:
      return {
        ...state,
        isDisciplineSolutionLoading: true
      };
    case DisciplinaActionKeys.MERGE_SA_DISCIPLINE_SUCCESS:
      let ObraDisciplineMerge = state.Obra;
      if (
        ObraDisciplineMerge && 
        ObraDisciplineMerge?.Disciplines && 
        ObraDisciplineMerge.Disciplines.length > 0 && 
        action.payload
      ) {
        const disciplineIndex = ObraDisciplineMerge.Disciplines.findIndex((discipline: any) => {
          return discipline.ConstructionSiteDisciplinesId === action.payload.ConstructionSiteDisciplinesId;
        })
        if (disciplineIndex !== -1) {
          ObraDisciplineMerge.Disciplines[disciplineIndex] = action.payload;
        }
      }

      return {
        ...state,
        Obra: ObraDisciplineMerge ? { ...ObraDisciplineMerge } : undefined,
        isDisciplineSolutionLoading: false
      };
    case DisciplinaActionKeys.MERGE_SA_DISCIPLINE_FAILED:
      return {
        ...state,
        isDisciplineSolutionLoading: false
      };
      
    default:
      return state;
  }
};

export default disciplinaReducer;
