import styled, { css } from "styled-components";

interface IBackgroundBlue {
  loading?: string
}

export const BackgroundBlue = styled.div<IBackgroundBlue>`
  position: fixed;
  background-color: ${({theme}) => theme.colors.tertiary.tertiary};
  width: 50%;
  height: 100%;
  z-index: -1;

  ${({loading, theme}) => loading === 'true' && css`
    background-color: ${theme.colors.surface.surface};
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  
  :first-child {
    margin: 0;
  }

  .center {
    position: fixed;

    .bolinhasTopo {
      position: absolute;
      bottom: -50px;
      left: -30px;
      z-index: -1;
  
      circle {
        fill: ${({theme}) => theme.colors.tertiary.onTertiary};
      }
    }
      
    .bolinhasFundo {
      position: absolute;
      top: -25px;
      right: -35px;
      z-index: -1;
  
      circle {
        fill: ${({theme}) => theme.colors.surface.surfaceOutline};
      }
    }
  }
`;