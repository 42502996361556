import React, { FC, useEffect, useState } from 'react';
import { IconName, Icon } from '../../../UI';
import useHistoryPush from 'Hooks/useHistoryPush';
import LoadingMain from 'Components/UI/CustomLoading/LoadingMain';
import UpdateEmpresa from 'Pages/MinhaConta/Modals/UpdateEmpresa';
import styles from './CompletarCadastro.module.scss';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';

export interface ICompletarCadastro {
  icon?: IconName;
  title: string;
  path?: string;
  className?: string;
  isLoading?: boolean;
  userInfo?: IUserInforResponse;
  updateEmpresaLoading?: boolean;
}

const CompletarCadastro: FC<ICompletarCadastro> = (
  {
    icon,
    title,
    path,
    className,
    isLoading,
    userInfo,
    updateEmpresaLoading,
  },
) => {
  const [visibleModalUpdateEmpresa, setVisibleModalUpdateEmpresa] = useState(false);
  const [sentSubmit, setSentSubmit] = useState(false);

  useEffect(() => {
    if (sentSubmit && !updateEmpresaLoading) {
      setVisibleModalUpdateEmpresa(false);
    }
  }, [sentSubmit, updateEmpresaLoading]);

  const handleConfirm = (value: boolean) => {
    setSentSubmit(value);
  }

  return (
    <>
      <div className={`
        ${styles['MenuItem']}
        ${className || ''}`}
        onClick={() => isLoading ? undefined : setVisibleModalUpdateEmpresa(true)}
      >
        {isLoading ? (
          <>
            <LoadingMain isCustom="skeleton-danger" width="14px" minWidth="14px" height="14px" borderRadius="4px" />
            <LoadingMain isCustom="skeleton-danger" width="140px" height="10px" marginLeft="16px" borderRadius="2px" />
          </>
        ) : (
          <>
            <Icon
              icon={icon}
              color={'branco'}
              customSize={14} className={styles['icon']}
            />
            <span className={styles['titleMenu']}>{title}</span>
          </>
        )}
      </div>

      {visibleModalUpdateEmpresa && (
        <UpdateEmpresa
          visible={visibleModalUpdateEmpresa}
          onCancel={() => setVisibleModalUpdateEmpresa(false)}
          userInfos={userInfo || undefined}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default CompletarCadastro;
