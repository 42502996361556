import { IPanelsResponse } from 'Data/interfaces/Panels/IPanelsResponse';
import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IPanelsCreateRequest } from 'Data/interfaces/Panels/IPanelsCreateRequest';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { IListFilesRequest } from 'Data/interfaces/ListagemArquivos/IListFilesRequest';
import { IFileData, IListFilesResponse, IRenderingSignalR } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IRequestFileInfo } from 'Data/interfaces/Viewer/IGetFileInfo';
import { IPanelsUpdateRequest } from 'Data/interfaces/Panels/IPanelsUpdateRequest';
import { IPanelsReorderRequest } from 'Data/interfaces/Panels/IPanelsReorderRequest';
import { IAutodeskViewer } from 'Data/interfaces/Viewer/IAutodeskViewer';
import { IPanelsFileInfo } from 'Data/interfaces/Panels/IPanelsFileInfo';
import { IPanelsVerifyAutodeskProgress } from 'Data/interfaces/Panels/IPanelsVerifyAutodeskProgress';

export enum PanelsActionKeys {
  PANELS_LIST_REQUEST = 'PANELS_LIST_REQUEST',
  PANELS_LIST_SUCCESS = 'PANELS_LIST_SUCCESS',
  PANELS_LIST_FAILURE = 'PANELS_LIST_FAILURE',
  
  UPDATE_FIXED_PANELS_LIST = 'UPDATE_FIXED_PANELS_LIST',
  UPDATE_NOT_FIXED_PANELS_LIST = 'UPDATE_NOT_FIXED_PANELS_LIST',

  PANELS_CREATE_REQUEST = 'PANELS_CREATE_REQUEST',
  PANELS_CREATE_SUCCESS = 'PANELS_CREATE_SUCCESS',
  PANELS_CREATE_FAILURE = 'PANELS_CREATE_FAILURE',

  PANELS_UPDATE_REQUEST = 'PANELS_UPDATE_REQUEST',
  PANELS_UPDATE_SUCCESS = 'PANELS_UPDATE_SUCCESS',
  PANELS_UPDATE_FAILURE = 'PANELS_UPDATE_FAILURE',

  PANELS_REORDER_REQUEST = 'PANELS_REORDER_REQUEST',
  PANELS_REORDER_SUCCESS = 'PANELS_REORDER_SUCCESS',
  PANELS_REORDER_FAILURE = 'PANELS_REORDER_FAILURE',

  PANELS_DELETE_REQUEST = 'PANELS_DELETE_REQUEST',
  PANELS_DELETE_SUCCESS = 'PANELS_DELETE_SUCCESS',
  PANELS_DELETE_FAILURE = 'PANELS_DELETE_FAILURE',

  PANELS_LIST_DISCIPLINA_REQUEST = 'PANELS_LIST_DISCIPLINA_REQUEST',
  PANELS_LIST_DISCIPLINA_SUCCESS = 'PANELS_LIST_DISCIPLINA_SUCCESS',
  PANELS_LIST_DISCIPLINA_FAILURE = 'PANELS_LIST_DISCIPLINA_FAILURE',

  PANELS_LIST_FILES_REQUEST = 'PANELS_LIST_FILES_REQUEST',
  PANELS_LIST_FILES_SUCCESS = 'PANELS_LIST_FILES_SUCCESS',
  PANELS_LIST_FILES_FAILURE = 'PANELS_LIST_FILES_FAILURE',
  PANELS_LIST_FILES_UPDATE = 'PANELS_LIST_FILES_UPDATE',
  PANELS_LIST_FILES_MOVE_TO_CLEAR = 'PANELS_LIST_FILES_MOVE_TO_CLEAR',

  PANELS_GET_FILE_INFO_REQUEST = 'PANELS_GET_FILE_INFO_REQUEST',
  PANELS_GET_FILE_INFO_SUCCESS = 'PANELS_GET_FILE_INFO_SUCCESS',
  PANELS_GET_FILE_INFO_FAILURE = 'PANELS_GET_FILE_INFO_FAILURE',

  SHARE_LINK_PANELS_REQUEST = 'SHARE_LINK_PANELS_REQUEST',
  SHARE_LINK_PANELS_SUCCESS = 'SHARE_LINK_PANELS_SUCCESS',
  SHARE_LINK_PANELS_FAILURE = 'SHARE_LINK_PANELS_FAILURE',
  SHARE_LINK_PANELS_CLEAR = 'SHARE_LINK_PANELS_CLEAR',

  PANELS_AUTODESK_VIEWER_REQUEST = 'PANELS_AUTODESK_VIEWER_REQUEST',
  PANELS_AUTODESK_VIEWER_SUCCESS = 'PANELS_AUTODESK_VIEWER_SUCCESS',
  PANELS_AUTODESK_VIEWER_FAILURE = 'PANELS_AUTODESK_VIEWER_FAILURE',

  CREATE_STATUS_FILE_PANEL_VIEWER = 'UPDATE_STATUS_FILE_PANEL_VIEWER',
  UPDATE_STATUS_FILE_PANEL_VIEWER = 'UPDATE_STATUS_FILE_PANEL_VIEWER',  

  SET_AUTODESK_VIEWER_RENDERING_RESPONSE = 'SET_AUTODESK_VIEWER_RENDERING_RESPONSE',
  SET_FILE_STATUS_RENDERING = 'SET_FILE_STATUS_RENDERING',
  PANELS_VERIFY_AUTODESK_PROGRESS = 'PANELS_VERIFY_AUTODESK_PROGRESS',
}

export const PanelsActions = {
  list: (request: number): PanelsListAction =>
    createAction(PanelsActionKeys.PANELS_LIST_REQUEST, request),
  listSuccess: (response: IPanelsResponse[]): PanelsListSuccessAction =>
    createAction(PanelsActionKeys.PANELS_LIST_SUCCESS, response),
  listFailure: (err: string): PanelsListFailureAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FAILURE, err),

  updateFixedPanelsList: (data: IPanelsResponse[]): UpdateFixedPanelsListAction =>
    createAction(PanelsActionKeys.UPDATE_FIXED_PANELS_LIST, data),
  updateNotFixedPanelsList: (data: IPanelsResponse[]): UpdateNotFixedPanelsListAction =>
    createAction(PanelsActionKeys.UPDATE_NOT_FIXED_PANELS_LIST, data),

  createPanel: (request: IPanelsCreateRequest): PanelsCreateAction =>
    createAction(PanelsActionKeys.PANELS_CREATE_REQUEST, request),
  createPanelSuccess: (response: IPanelsResponse): PanelsCreateSuccessAction =>
    createAction(PanelsActionKeys.PANELS_CREATE_SUCCESS, response),
  createPanelFailure: (err: string): PanelsCreateFailureAction =>
    createAction(PanelsActionKeys.PANELS_CREATE_FAILURE, err),

  updatePanel: (request: IPanelsUpdateRequest): PanelsUpdateAction =>
    createAction(PanelsActionKeys.PANELS_UPDATE_REQUEST, request),
  updatePanelSuccess: (response: IPanelsResponse): PanelsUpdateSuccessAction =>
    createAction(PanelsActionKeys.PANELS_UPDATE_SUCCESS, response),
  updatePanelFailure: (err: string): PanelsUpdateFailureAction =>
    createAction(PanelsActionKeys.PANELS_UPDATE_FAILURE, err),

  reorderPanel: (request: IPanelsReorderRequest): PanelsReorderAction =>
    createAction(PanelsActionKeys.PANELS_REORDER_REQUEST, request),
  reorderPanelSuccess: (response: IPanelsResponse): PanelsReorderSuccessAction =>
    createAction(PanelsActionKeys.PANELS_REORDER_SUCCESS, response),
  reorderPanelFailure: (err: string): PanelsReorderFailureAction =>
    createAction(PanelsActionKeys.PANELS_REORDER_FAILURE, err),

  deletePanel: (request: string): PanelsDeleteAction =>
    createAction(PanelsActionKeys.PANELS_DELETE_REQUEST, request),
  deletePanelSuccess: (response: string): PanelsDeleteSuccessAction =>
    createAction(PanelsActionKeys.PANELS_DELETE_SUCCESS, response),
  deletePanelFailure: (err: string): PanelsDeleteFailureAction =>
    createAction(PanelsActionKeys.PANELS_DELETE_FAILURE, err),

  panelsListDisciplina: (request: IUpdateDisciplineStatusRequest): PanelsListDisciplinaAction =>
    createAction(PanelsActionKeys.PANELS_LIST_DISCIPLINA_REQUEST, request),
  panelsListDisciplinaSuccess: (obra: IObraResponse): PanelsListDisciplinaSuccessAction =>
    createAction(PanelsActionKeys.PANELS_LIST_DISCIPLINA_SUCCESS, obra),
  panelsListDisciplinaFailure: (err: string): PanelsListDisciplinaFailureAction =>
    createAction(PanelsActionKeys.PANELS_LIST_DISCIPLINA_FAILURE, err),

  panelsListFiles: (listFilesRequest: IListFilesRequest): PanelsListFilesAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FILES_REQUEST, listFilesRequest),
  panelsListFilesSuccess: (listFilesResponse: IListFilesResponse): PanelsListFilesSuccessAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FILES_SUCCESS, listFilesResponse),
  panelsListFilesFailure: (err: string): PanelsListFilesFailureAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FILES_FAILURE, err),
  panelsListFilesUpdate: (files: IFileData[]): PanelsListFilesUpdateAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FILES_UPDATE, files),
  panelsListFilesMoveToClear: (): PanelsListFilesMoveToClearAction =>
    createAction(PanelsActionKeys.PANELS_LIST_FILES_MOVE_TO_CLEAR),

  panelsGetFileInfo: (request: IRequestFileInfo): PanelsGetFileInfoAction =>
    createAction(PanelsActionKeys.PANELS_GET_FILE_INFO_REQUEST, request),
  panelsGetFileInfoSuccess: (response: IPanelsFileInfo): PanelsGetFileInfoSuccessAction =>
    createAction(PanelsActionKeys.PANELS_GET_FILE_INFO_SUCCESS, response),
  panelsGetFileInfoFailure: (err: string): PanelsGetFileInfoFailureAction =>
    createAction(PanelsActionKeys.PANELS_GET_FILE_INFO_FAILURE, err),

  shareLinkActivity: (request: string): ShareLinkPanelsAction =>  
    createAction(PanelsActionKeys.SHARE_LINK_PANELS_REQUEST, request),
  shareLinkPanelsSuccess: (response: string): ShareLinkPanelsSuccessAction =>
    createAction(PanelsActionKeys.SHARE_LINK_PANELS_SUCCESS, response),
  shareLinkPanelsFailure: (err: string): ShareLinkPanelsFailureAction =>
    createAction(PanelsActionKeys.SHARE_LINK_PANELS_FAILURE, err),
  shareLinkPanelsClear: (): ShareLinkPanelsClearAction =>
    createAction(PanelsActionKeys.SHARE_LINK_PANELS_CLEAR),

  panelsAutoDeskViewer: (request: IAutodeskViewer): PanelsAutoDeskViewerAction =>
    createAction(PanelsActionKeys.PANELS_AUTODESK_VIEWER_REQUEST, request),
  panelsAutoDeskViewerSuccess: (response: IPanelsFileInfo): PanelsAutoDeskViewerSuccessAction =>
    createAction(PanelsActionKeys.PANELS_AUTODESK_VIEWER_SUCCESS, response),
  panelsAutoDeskViewerFailure: (err: string): PanelsAutoDeskViewerFailureAction =>
    createAction(PanelsActionKeys.PANELS_AUTODESK_VIEWER_FAILURE, err),

  createStatusFilePanelViewer: (request: IRenderingSignalR): CreateStatusFilePanelViewerAction =>
    createAction(PanelsActionKeys.CREATE_STATUS_FILE_PANEL_VIEWER, request),
  updateStatusFilePanelViewer: (request: IRenderingSignalR): UpdateStatusFilePanelViewerAction =>
    createAction(PanelsActionKeys.UPDATE_STATUS_FILE_PANEL_VIEWER, request),

  panelsAutodeskViewerRenderingResponse: (data: IRenderingSignalR): PanelsAutodeskViewerRenderingResponseAction =>
    createAction(PanelsActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE, data),
  setFileStatusRendering: (data: IAutodeskViewer): SetFileStatusRenderingAction =>
    createAction(PanelsActionKeys.SET_FILE_STATUS_RENDERING, data),
  panelsVerifyAutodeskProgress: (data: IPanelsVerifyAutodeskProgress): PanelsVerifyAutodeskProgressAction =>
    createAction(PanelsActionKeys.PANELS_VERIFY_AUTODESK_PROGRESS, data),
};

export type PanelsActionUnion = ActionsUnion<typeof PanelsActions>;

export type PanelsListAction = Action<PanelsActionKeys.PANELS_LIST_REQUEST, number>;
export type PanelsListSuccessAction = Action<PanelsActionKeys.PANELS_LIST_SUCCESS, IPanelsResponse[]>;
export type PanelsListFailureAction = Action<PanelsActionKeys.PANELS_LIST_FAILURE, string>;

export type UpdateFixedPanelsListAction = Action<PanelsActionKeys.UPDATE_FIXED_PANELS_LIST, IPanelsResponse[]>;
export type UpdateNotFixedPanelsListAction = Action<PanelsActionKeys.UPDATE_NOT_FIXED_PANELS_LIST, IPanelsResponse[]>;

export type PanelsCreateAction = Action<PanelsActionKeys.PANELS_CREATE_REQUEST, IPanelsCreateRequest>;
export type PanelsCreateSuccessAction = Action<PanelsActionKeys.PANELS_CREATE_SUCCESS, IPanelsResponse>;
export type PanelsCreateFailureAction = Action<PanelsActionKeys.PANELS_CREATE_FAILURE, string>;

export type PanelsUpdateAction = Action<PanelsActionKeys.PANELS_UPDATE_REQUEST, IPanelsUpdateRequest>;
export type PanelsUpdateSuccessAction = Action<PanelsActionKeys.PANELS_UPDATE_SUCCESS, IPanelsResponse>;
export type PanelsUpdateFailureAction = Action<PanelsActionKeys.PANELS_UPDATE_FAILURE, string>;

export type PanelsReorderAction = Action<PanelsActionKeys.PANELS_REORDER_REQUEST, IPanelsReorderRequest>;
export type PanelsReorderSuccessAction = Action<PanelsActionKeys.PANELS_REORDER_SUCCESS, IPanelsResponse>;
export type PanelsReorderFailureAction = Action<PanelsActionKeys.PANELS_REORDER_FAILURE, string>;

export type PanelsDeleteAction = Action<PanelsActionKeys.PANELS_DELETE_REQUEST, string>;
export type PanelsDeleteSuccessAction = Action<PanelsActionKeys.PANELS_DELETE_SUCCESS, string>;
export type PanelsDeleteFailureAction = Action<PanelsActionKeys.PANELS_DELETE_FAILURE, string>;

export type PanelsListDisciplinaAction = Action<PanelsActionKeys.PANELS_LIST_DISCIPLINA_REQUEST, IUpdateDisciplineStatusRequest>;
export type PanelsListDisciplinaSuccessAction = Action<PanelsActionKeys.PANELS_LIST_DISCIPLINA_SUCCESS, IObraResponse>;
export type PanelsListDisciplinaFailureAction = Action<PanelsActionKeys.PANELS_LIST_DISCIPLINA_FAILURE, string>;

export type PanelsListFilesAction = Action<PanelsActionKeys.PANELS_LIST_FILES_REQUEST, IListFilesRequest>;
export type PanelsListFilesSuccessAction = Action<PanelsActionKeys.PANELS_LIST_FILES_SUCCESS, IListFilesResponse>;
export type PanelsListFilesFailureAction = Action<PanelsActionKeys.PANELS_LIST_FILES_FAILURE, string>;
export type PanelsListFilesUpdateAction = Action<PanelsActionKeys.PANELS_LIST_FILES_UPDATE, IFileData[]>;
export type PanelsListFilesMoveToClearAction = Action<PanelsActionKeys.PANELS_LIST_FILES_MOVE_TO_CLEAR>;

export type PanelsGetFileInfoAction = Action<PanelsActionKeys.PANELS_GET_FILE_INFO_REQUEST, IRequestFileInfo>;
export type PanelsGetFileInfoSuccessAction = Action<PanelsActionKeys.PANELS_GET_FILE_INFO_SUCCESS, IPanelsFileInfo>;
export type PanelsGetFileInfoFailureAction = Action<PanelsActionKeys.PANELS_GET_FILE_INFO_FAILURE, string>;

export type ShareLinkPanelsAction = Action<PanelsActionKeys.SHARE_LINK_PANELS_REQUEST, string>;
export type ShareLinkPanelsSuccessAction = Action<PanelsActionKeys.SHARE_LINK_PANELS_SUCCESS, string>;
export type ShareLinkPanelsFailureAction = Action<PanelsActionKeys.SHARE_LINK_PANELS_FAILURE, string>;
export type ShareLinkPanelsClearAction = Action<PanelsActionKeys.SHARE_LINK_PANELS_CLEAR>;

export type PanelsAutoDeskViewerAction = Action<PanelsActionKeys.PANELS_AUTODESK_VIEWER_REQUEST, IAutodeskViewer>;
export type PanelsAutoDeskViewerSuccessAction = Action<PanelsActionKeys.PANELS_AUTODESK_VIEWER_SUCCESS, IPanelsFileInfo>;
export type PanelsAutoDeskViewerFailureAction = Action<PanelsActionKeys.PANELS_AUTODESK_VIEWER_FAILURE, string>;

export type CreateStatusFilePanelViewerAction = Action<PanelsActionKeys.CREATE_STATUS_FILE_PANEL_VIEWER, IRenderingSignalR>;
export type UpdateStatusFilePanelViewerAction = Action<PanelsActionKeys.UPDATE_STATUS_FILE_PANEL_VIEWER, IRenderingSignalR>;

export type PanelsAutodeskViewerRenderingResponseAction = Action<PanelsActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE, IRenderingSignalR>;
export type SetFileStatusRenderingAction = Action<PanelsActionKeys.SET_FILE_STATUS_RENDERING, IAutodeskViewer>;
export type PanelsVerifyAutodeskProgressAction = Action<PanelsActionKeys.PANELS_VERIFY_AUTODESK_PROGRESS, IPanelsVerifyAutodeskProgress>;
