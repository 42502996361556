import { FC } from "react";
import { Icon } from "Components/UI";
import { Container } from "./styles";

interface IEmptyList {
  text: string;
}
const EmptyList: FC<IEmptyList> = ({text}) => {
  return ( 
    <Container>
      <span className="icon">
        <Icon 
          icon="menuContexto"
          customSize={16}
          className="iconContexto"
        />
      </span>
      <span className="text">{text}</span>
    </Container>
  )
}

export default EmptyList;
