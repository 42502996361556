import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  max-height: 280px;

  .title {
    font-size: 10px;
    line-height: 13.62px;
    font-weight: 600;
    color: #BEC2C6;
    margin-bottom: 6px;
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const CardDiscipline = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
  background-color: #EAEDF0;
  border-radius: 12px;
  white-space: nowrap;
  margin-right: 4px;
  cursor: pointer;

  .cardDisciplineText {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 600;
    color: #324150;
    padding-left: 2px;
  }
`;

export const CardDisciplineTooltip = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
  background-color: #EAEDF0;
  border-radius: 12px;
  white-space: nowrap;
  cursor: pointer;

  &+& {
    margin-top: 6px;
  }

  .cardDisciplineText {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 600;
    color: #324150;
    padding-left: 2px;
    margin-top: 2px;
  }
`;
