import { 
  IDragAndDropDataFormat,
  ITopicDnDResponse,
} from 'Data/interfaces/Activities/IDragAndDropDataFormat';
import { 
  IChangeStatus, 
} from 'Data/interfaces/Activities/IChangeStatus';
import { Container } from './styles';
import Table from 'Components/UI/Table';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { getIsCollapsed } from 'Store/Obra/Obra.selector';
import useListVision from './useListVision';
import { IFiltroOptions } from 'Data/interfaces/Activities/IFilters';
import { getAttachementPreviewOn } from 'Store/Activities/Activities.selector';
import { useState } from 'react';

interface IListVision {
  listActivities?: IDragAndDropDataFormat;
  height: number;
  searchActivity: string;
  onOpenCreateActivity: () => void;
  onOpenShowActivity: (activity: string) => void;
  endStatus?: IChangeStatus;
  showFilters?: boolean;
  filters?: Record<string, IFiltroOptions<ITopicDnDResponse>>;
  hasFilters: boolean;
  width: number;
}

const ListVision = ({
  listActivities,
  height,
  searchActivity,
  onOpenCreateActivity,
  onOpenShowActivity,
  showFilters,
  filters,
  hasFilters,
  width,
}: IListVision) => {
  const isShellCollapsed = useSelector(getIsCollapsed);
  const attachementPreviewOn = useSelector(getAttachementPreviewOn);

  const {
    columns,
    filteredActivities,
    renderEmpty,
  } = useListVision({
    listActivities,
    height,
    searchActivity,
    onOpenCreateActivity,
    onOpenShowActivity,
    filters,
    showFilters,
    hasFilters,
  });

  const leftBarAndPaddingWidth = width - (isShellCollapsed ? 102 : 278);
  const heightAndFiltersShow = height - 332.5 - (showFilters ? 41.5 : 0);
  
  return (
    <Container>
      <ConfigProvider
        renderEmpty={renderEmpty}
      >
        <Table
          virtual
          scroll={{ y: heightAndFiltersShow, x: leftBarAndPaddingWidth }}
          columns={columns}
          dataSource={filteredActivities.map(
            (activity, index) => ({ ...activity, key: index })
          )}
          rowKey={(record: ITopicDnDResponse) => record.Guid}
          className="listTable"
          rowClassName="fileListRow"
          onRow={(record) => {
            return {
              onDoubleClick: () => attachementPreviewOn 
                ? undefined 
                : onOpenShowActivity(record.Guid),
            };
          }}
        />
      </ConfigProvider>
    </Container>
  );
}

export default ListVision;
 