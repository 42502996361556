import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .simpleViewer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100%) !important;
    height: calc(100%) !important; 
  }
`;
