import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalDrawerStyled = createGlobalStyle`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 450px;
  min-height: 90px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  overflow: hidden;
  
  .left {
    display: flex;
    flex-direction: column;
    align-items: start;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .tag {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 8px 6px 6px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
      margin-top: 6px;

      .tagText {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
        word-break: break-all; 
        overflow: hidden; 
        margin-left: 6px;
      }
      
      .tagIcon {
        height: 16px;
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      }
    }
  }

  .closeBtn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
    cursor: pointer;

    .closeIcon {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.secondary.secondaryHover}, ${theme.colors.secondary.secondary})`} !important;
    }
  }
`;

interface IContent {
  loading?: boolean;
}

export const Content = styled.div<IContent>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.surfaceContainer};
  
  ${({ loading }) => loading && css`
    padding: 0 24px;
    overflow-y: scroll;
  `}
`;

interface IDataBadgeContainer {
  first?: boolean;
}

export const DataBadgeContainer = styled.span<IDataBadgeContainer>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ first }) => first ? '6px' : 0};
  
  .text {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 999px;

    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant};
    background-color: ${({ theme }) => theme.colors.secondary.secondary};
  }
`;

export const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
`;
