import { FC, useEffect, useLayoutEffect } from 'react';
import { Switch, Route } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { history } from 'Store';
import { getUserInfo, isAuthenticatedSelector } from 'Store/Auth/Auth.selector';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import AuthenticatedRoutes from 'Routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from 'Routes/UnauthenticatedRoutes';
import Viewer from '../Viewer';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { 
  ReactPlugin, 
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { AuthUtils } from 'Utils/AuthUtils';
import SignalRNotification from 'Components/UI/SignalR/notification';
import { NotificationActions } from 'Store/Notification/Notification.actions';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { Mixpanel } from 'Utils/MixPanel';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { GlobalStyle } from 'Static/styles/global';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import ShellHost from 'Components/ShellHost';
import UploadFiles from 'Components/UI/UploadFiles';
import DownloadFiles from 'Components/UI/DownloadFiles';
import MultiDomain from 'Components/MultiDomain';
import MobilePopUp from 'Components/MobilePopUp';
import HistoryDrawer from 'Components/HistoryDrawer';
import { ThemeProvider } from 'styled-components';

interface IApp {
  isAuthenticated: boolean;
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history }
    }
  }
});
appInsights.loadAppInsights();

const App: FC<IApp> = ({ isAuthenticated }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfo);
  const theme = useSelector(getTheme);

  useEffect(() => {
    const errorListening = (event: any) => {
      if (
        event.currentTarget?.NOP_VIEWER?.clientContainer &&
        event.message === 'Script error.'
      ) {
        const viewerDiv = event.currentTarget?.NOP_VIEWER?.clientContainer;
        const viewerDivClass = `.${viewerDiv?.classList[0]}`;
        const div = window.document.querySelector(viewerDivClass);
        if (!div) window.location.reload();
      }
    }
    if (window !== null) {
      window.addEventListener('error', errorListening);
    }

    return () => {
      window.removeEventListener('error', errorListening);
    }
  }, []);

  useLayoutEffect(() => {
    if (isAuthenticated && window.location.pathname.indexOf('/construtora/') != 0) {
      dispatch(AuthActions.getUserInfo());
    }

    if (isAuthenticated) {
      const userId = AuthUtils.getLoggedUser().userId;
      if (userId) Mixpanel.identify(userId);
    }
  }, [isAuthenticated]);

  const showViewer =
    useLocation()
      .pathname.substr(1)
      .split('/')[0] == 'viewer'
      ? true
      : false;
  const autenticationWithInvite = useLocation().pathname.split('/login/')[1]
    ? true
    : false;

  const notificationsMap = useSelector(
    (state: IGlobalReducerState) => state.notification.notificationsMap
  );

  useEffect(() => {
    const notificationIds = notificationsMap
      .filter((x) => !x.hasRead && x.hasView)
      .map((item) => item.NotificationId);

    //Marcar como lida as notificações no Backend
    if (notificationIds?.length > 0) {
      dispatch(NotificationActions.setNotificationAsRead(notificationIds));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const forceToLogin = () => {
    AuthUtils.logout();
  };

  useEffect(() => {
    if (!userInfo) return;

    const currentState = flagsmith.getState();
    const newIdentity = `${window.location.hostname.replaceAll('.', '-')}_tenant_id_${userInfo?.CurrentTenantContext
      }`;

    if (newIdentity !== currentState.identity) {
      flagsmith.identify(newIdentity, {
        role: userInfo?.CurrentRoleFk?.toString() || '',
        plan: userInfo?.UserStoreItem?.StorePlan?.Name || ''
      });
    }

    const userId = AuthUtils.getLoggedUser().userId;
    if (userInfo.Id && !userId) {
      Mixpanel.identify(userInfo.Id);
    }
  }, [userInfo]);

  return (
    <FlagsmithProvider
      options={{
        environmentID: process.env.REACT_APP_FLAG_SMITH_ENV_ID || '',
        identity: `${window.location.hostname.replaceAll('.', '-')}_tenant_id_${AuthUtils.getLoggedUser().currentTenant
          }`,
        traits: {
          role: AuthUtils.getLoggedUser().currentRoleFk?.toString() || '',
          plan: AuthUtils.getLoggedUser().plan || '',
          url: window.location.hostname.replaceAll('.', '-')
        }
      }}
      flagsmith={flagsmith}
    >
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <MultiDomain isAuthenticated={isAuthenticated} />
          <MobilePopUp isAuthenticated={isAuthenticated} />

          {autenticationWithInvite === true ? forceToLogin() : <></>}
          {isAuthenticated && autenticationWithInvite === false ? (
            <>
              {// fileViewer == URN  para arquivos 2D/3D
                // fileViewer == FileInfoViewer para demais tipos de arquivos
                showViewer ? (
                  <>
                    <Switch>
                      <Route
                        path="/viewer/:viewerType/:constructionSiteId/:fileApiId/:breadCrumbId"
                        component={Viewer}
                      />
                    </Switch>
                  </>
                ) : (
                  <></>
                )}

              <ShellHost hiddenShell={showViewer}>
                <AuthenticatedRoutes />
                <UploadFiles />
                <DownloadFiles />
                <HistoryDrawer />
                <SignalRNotification userInfo={userInfo} />
              </ShellHost>
            </>
          ) : (
            <UnauthenticatedRoutes />
          )}
        </>
      </ThemeProvider>
    </FlagsmithProvider>
  );
};

const mapStateProps = (state: IGlobalReducerState) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state)
  };
};

export default withAITracking(reactPlugin, connect(mapStateProps)(App));
