import React, { FC, useEffect } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import styles from "./TenantUpdate.module.scss";
import schema from "./TenantUpdate.schema";
import Modal from "Components/UI/Modal/Modal";
import Form from "Components/UI/Form/Form";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import SelectForm from "Components/UI/SelectForm";
import { AdmActions } from "Store/Adm/Adm.actions";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { IOptionData } from "Components/UI/SelectForm";
import { Divider, Skeleton } from "antd";
import { IUserStoreItemRequest } from "Data/interfaces/Store/IUserStoreItemRequest";
import { PaymentMethodEnum } from "Data/interfaces/Store/IUserStoreItem";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

interface ITenantUpdate {
  tenant?: IUserStoreItemRequest;
  tenantName: string;
  onClose: () => void;
  closeEditTenantUpdateModal: () => void;
  visible?: boolean;
}

const TenantUpdate: FC<Props> = ({
  tenant,
  tenantName,
  Planos, 
  onClose,
  closeEditTenantUpdateModal, 
  visible, 
  tenantIsLoading,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [defaultValues, setDefaultValues] = React.useState({} as IUserStoreItemRequest);

  useEffect(() => {
    dispatch(AdmActions.listPlans());
  }, [])
  
  useEffect(() => {
    setDefaultValues(tenant ?? {} as IUserStoreItemRequest);
  }, [tenant])

  useEffect(() => {
    if (isSending && !tenantIsLoading) {
      setIsSending(false);
      closeEditTenantUpdateModal();
      onCloseAux();
    }
  }, [tenantIsLoading])

  const submit = (values: IUserStoreItemRequest) => {
    if (values) {
      setIsSending(true);

      const newPlanOrder = Planos?.find(plan => plan.StorePlansId === values.storePlanFk)?.Order;
      const isUpgrade = (newPlanOrder || 0) > (tenant?.oldStorePlanOrderTracking || 0);
      const isUpdate = (newPlanOrder || 0) !== (tenant?.oldStorePlanOrderTracking || 0);
      const request: IUserStoreItemRequest = {
        ...values,
        isUpdateTracking: isUpdate,
        isUpgradeTracking: isUpgrade,
        tenantIdTracking: tenant?.tenantIdTracking,
        oldStorePlanTracking: tenant?.oldStorePlanTracking,
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      }

      dispatch(AdmActions.updateTenant(request));
    }
    setIsSubmit(false);
  }

  const onCloseAux = () => {
    onClose();
  }

  const buttons = (
    <div>
      <Button
        onClick={onCloseAux}
      >
        <span>Cancelar</span>
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={tenantIsLoading}
        onClick={() => setIsSubmit(true)}
      >
        <span>Atualizar</span>
      </Button>
    </div>
  );

  const plansRoles = (Planos?.map((plan) => (
    { value: plan.StorePlansId, description: (plan.isActive ? plan.Name : `${plan.Name} (Inativo)`) }
  ))
    ) as IOptionData[];

  const methodsOptions = [
    { value: 1, description: PaymentMethodEnum[1] },
    { value: 2, description: PaymentMethodEnum[2] },
  ] as IOptionData[];

  const statusOptions = [
    { value: 1, description: 'Ativo' },
    { value: 0, description: 'Inativo' },
  ] as IOptionData[];

  return (
    <Modal
      title="Gerenciamento de Tenant"
      visible={visible}
      footerButtons={buttons}
      onCancel={onCloseAux}
      width={676}
    >
      <div 
        className={styles['form-wrapper']} 
        key={`status-${tenantName}`}
      >
        {tenantIsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div>
              <h4>{tenantName}</h4>
            </div>

            <Form
              schema={schema}
              onSubmit={submit}
              isSubmited={isSubmit}
              resetForm={true}
              initialValues={defaultValues}
            >
              <div className={styles['field-main-wrapper']}>

                <Divider orientation="left" style={{ color: '#BDBDBD' }}>Dados do Plano</Divider>

                <div className={styles['field-wrapper']}>
                  <Field
                    label="Max Obras"
                    name="maxQtyConstructionSites"
                    type="number"
                    className={styles['field-input']}
                  />
                  <Field
                    label="Max Uploads"
                    name="maxQtyUploadFiles"
                    type="number"
                    className={styles['field-input']}
                  />
                </div>

                <div className={styles['field-wrapper']}>
                  <SelectForm
                    className={styles['selectRole']}
                    placeholder="Plano"
                    options={plansRoles}
                    name='storePlanFk'
                    style={{ width: 210, paddingRight: 5, marginTop: 8 }}
                  />
                  <Field
                    label="Preço"
                    name="price"
                    type="number"
                    className={styles['field-input']}
                  />
                </div>


                <Divider orientation="left" style={{ color: '#BDBDBD' }}>Dados do Contrato</Divider>

                <div className={styles['field-wrapper']}>
                  <SelectForm
                    className={styles['selectRole']}
                    placeholder="Status"
                    options={statusOptions}
                    name='isActive'
                    style={{ width: 210, paddingRight: 5, marginTop: 8 }}
                  />
                  <Field
                    label="Início do Contrato"
                    name="contractStartDate"
                    type="date"
                    className={styles['field-input']}
                  />
                  <Field
                    label="Fim do Contrato"
                    name="contractEndDate"
                    type="date"
                    className={styles['field-input']}
                  />
                </div>
                <div className={styles['field-wrapper']}>
                  <SelectForm
                    className={styles['selectRole']}
                    placeholder="Método Pagamento"
                    options={methodsOptions}
                    name='paymentMethod'
                    style={{ width: 210, paddingRight: 5, marginTop: 8 }}
                  />
                  <Field
                    label="Valor do Contrato"
                    name="contractPrice"
                    type="number"
                    className={styles['field-input']}
                  />
                  <Field
                    label="Dia de Faturamento"
                    name="billingDay"
                    type="number"
                    className={styles['field-input']}
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </div>
    </Modal>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm,
  userInfo: state.auth.userInfo,
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & ITenantUpdate;

export default connector(TenantUpdate);