import React, { FC } from 'react';

import styles from './ResetPassword.module.scss';
import BoxContainer from '../../../Components/UI/Containers/BoxContainer/BoxContainer';
import Button from '../../../Components/UI/Button';
import Background from '../Backgroud/background';
import Form from '../../../Components/UI/Form/Form';
import { IResetPasswordRequest } from '../../../Data/interfaces/Auth/IResetPasswordRequest';
import { useDispatch, ConnectedProps, connect, useSelector } from 'react-redux';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import schema, { schemaPassword } from './resetPassword.schema';
import FloatingFeedback from '../../../Components/UI/Input/TextInput/FloatingFeedback/FloatingFeedback';
import { Http } from '../../../Utils/Http';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import Field from '../../../Components/UI/Field';
import Link from 'Components/UI/Link';
import { getDomain } from 'Store/MultiDomain/MultiDomain.selector';

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const ResetPassword: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);
  const code = Http.GetQueryParams('code') || '';

  const resetPassword = (data: IResetPasswordRequest) => {
    data.code = code.replace(/ /g, '+');
    dispatch(AuthActions.userResetPassword(data));
  };

  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [passwordFocus, setPasswordFocus] = React.useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = React.useState<string | undefined>(
    undefined
  );
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState<boolean>(false);

  return (
    <Background>
      <BoxContainer height={domain?.LogoUrl ? 450 : 400} width={403}>
        <div className={styles['contentWrapper']}>
          <h2 className={styles['title']}>Recuperar Senha</h2>
          <Form schema={schema} onSubmit={resetPassword}>
            <Field
              name="email"
              label="E-mail"
              icon="arroba"
              placeholder="usuario@dominio.com.br"
            />
            <Field
              name="password"
              label="Senha"
              icon="cadeado"
              type="password"
              onInput={setPassword}
              onBlur={() => setPasswordFocus(false)}
              onFocus={() => setPasswordFocus(true)}
            />
            <FloatingFeedback
              name="password"
              schema={schemaPassword}
              inputValue={password}
              visible={passwordFocus}
              className={styles['passwordFeedback']}
            />

            <Field
              name="confirmPassword"
              label="Confirme a senha"
              icon="cadeado"
              type="password"
              onInput={setConfirmPassword}
              onBlur={() => setConfirmPasswordFocus(false)}
              onFocus={() => setConfirmPasswordFocus(true)}
            />
            <FloatingFeedback
              name="confirmPassword"
              schema={schemaPassword}
              inputValue={confirmPassword}
              visible={confirmPasswordFocus}
              className={styles['confirmPasswordFeedback']}
              context={{ password }}
            />

            <div className={styles['buttonWrapper']}>
              <Button
                width="100%"
                type="primary"
                htmlType="submit"
                loading={props.isLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>

          <div className={styles['backLoginWrapper']}>
            <Link to="/login">
              Voltar para o login
            </Link>
          </div>

          {domain?.LogoUrl && (
            <p className={styles['powered-by']}>
              Powered by <span>Coordly</span>
            </p>
          )}
        </div>
      </BoxContainer>
    </Background>
  );
};

export default connector(ResetPassword);
