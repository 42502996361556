import { AxiosResponse } from 'axios';
import { Http } from '../Utils/Http';
import { IUpdateStorePlanRequest } from './interfaces/Store/IUpdateStorePlanRequest';

export class RevenueApi {
  static async updatePlan(updateRequest: IUpdateStorePlanRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post('/Account/RequestUpdateStorePlan', updateRequest);
  }

  static async cancelUpdatePlan(): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post('/Account/CancelUpdateStorePlan');
  }

  static async getDowngradeInfo(storePlanId: number): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.get(`/Account/GetDowngradeInfo/${storePlanId}`);
  }
}
