import { FC } from "react";
import { Icon, IconName } from "Components/UI/Icon";
import { GetAvatar, ThumbType } from "Utils/generateThumbnail";
import { BGColor, Container, Text, TextImg } from "./styles";
import "./styles.css";

interface IOptionImg {
  label: string;
  src?: string;
  thumbSrc?: string;
  thumbType?: ThumbType;
  avatar?: boolean;
  icon?: IconName;
  customColor?: string;
  customSize?: number;
  bgColor?: string;
  textColor?: string;
}

const OptionCustom: FC<IOptionImg> = ({
  label,
  src,
  thumbSrc,
  thumbType,
  avatar,
  icon,
  customColor,
  customSize,
  bgColor,
  textColor,
}) => {
  if (avatar) {
    return (
      <Container>
        <span style={{marginRight: '8px'}}>
          <GetAvatar
            name={label}
            shape='circle'
            size={18}
            fontSize={12}
            src={src}
            thumbSrc={thumbSrc}
            thumbType={thumbType}
          />
        </span>
        <TextImg>{label}</TextImg>
      </Container>
    );
  }
  if (icon && bgColor) {
    return (
      <Container>
        <BGColor bgColor={bgColor}>
          <span>
            <Icon
              icon={icon}
              customSize={customSize ? customSize : 14}
              customColor={customColor}
              style={{marginRight: '8px'}}
            />
          </span>
          <TextImg textColor={textColor}>
              {label}
          </TextImg>
        </BGColor>
      </Container>
    );
  }
  if (bgColor) {
    return (
      <Container>
        <BGColor bgColor={bgColor}>
          <TextImg textColor={textColor}>
              {label}
          </TextImg>
        </BGColor>
      </Container>
    );
  }
  if (icon) {
    return (
      <Container >
        <span>
          <Icon
            icon={icon}
            customSize={customSize ? customSize : 14}
            customColor={customColor}
            style={{marginRight: '8px'}}
          />
        </span>
        <TextImg textColor={textColor}>
            {label}
        </TextImg>
      </Container>
    );
  }
  return (
    <Container>
      <Text>{label}</Text>
    </Container>
  );
}

export default OptionCustom;
