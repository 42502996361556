import { FC, useMemo } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import {
  attachmentsImagesAccepts,
  attachmentsImagesNotAccepts,
} from "Utils/ActivitiesUtils";
import UploadImg from "Components/UI/UploadImg";
import FileDrop from "../../../FileDrop";
import { Progress } from "antd";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { sizeFileRangeToInitials } from "Utils/toolBox";
import {
  getAttachmentsForCreate,
  getAttachmentsForUpload,
  getAttachmentsUploading,
} from "Store/Activities/Activities.selector";
import {
  ITopicAttachment,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import Ellipsis from "Components/UI/Ellipsis";
import AvatarsItems from "Pages/Activities/components/AvatarsItems";
import { Container, Content, Header, ImgCard } from "./styles";

import imgPlaceholder from 'Static/images/image_placeholder.webp';

interface IRegisterAttachments {
  hasPermissionAddAttachment?: boolean;
  dropFocus?: boolean;
  onChange: (files: File | File[]) => void;
  onCancel: (attachmentId: string | number) => void;
  onDelete: (attachment?: ITopicAttachment | null) => void;
}

const RegisterAttachments: FC<IRegisterAttachments> = ({
  hasPermissionAddAttachment,
  dropFocus,
  onChange,
  onCancel,
  onDelete,
}) => {
  const theme = useSelector(getTheme);
  const attachmentsUploading = useSelector(getAttachmentsUploading);
  const attachmentsForUpload = useSelector(getAttachmentsForUpload);
  const attachmentsForCreate = useSelector(getAttachmentsForCreate);

  const filteredUploading = useMemo(() => {
    if (
      attachmentsUploading?.id &&
      attachmentsForUpload.attachmentsIds.includes(attachmentsUploading.id)
    ) {
      const attsIds = attachmentsForUpload.attachmentsIds.filter(att => att !== attachmentsUploading.id);
      return {
        ...attachmentsForUpload,
        attachmentsIds: attsIds,
      }
    }

    return attachmentsForUpload;
  }, [attachmentsForUpload, attachmentsUploading]);

  return (
    <>
      <Header>
        <FileDrop
          borderRadius={5}
          onDropFiles={onChange}
          isDisabled={!hasPermissionAddAttachment}
        >
          <Container isfocus={`${dropFocus}`}>
            <Icon
              icon="upload"
              className="uploadIcon"
              customSize={24}
            />
            <span className="infoText">Arraste arquivos para anexar</span>
            <span className="infoSubText">ou, se preferir</span>

            {hasPermissionAddAttachment && (
              <UploadImg
                imagesAccepts={attachmentsImagesAccepts.join(', ')}
                imagesNotAccepts={attachmentsImagesNotAccepts}
                button={(
                  <Button type="text">
                    Selecione um arquivo
                  </Button>
                )}
                onChange={onChange}
              />
            )}
          </Container>
        </FileDrop>
      </Header>

      <Content>
        {attachmentsForCreate.map(attachment => {
          if (attachment) {
            return (
              <ImgCard key={`forCreate-${attachment.FileFk}`} isprogress="true">
                <div className="image">
                  <img
                    src={attachment.Thumbnail64x64Url}
                    alt="Imagem de anexo"
                    onError={(e) => e.currentTarget.src = imgPlaceholder}
                  />
                </div>

                <div className="imgContent">
                  <div className="imgTextContent">
                    <span className="textName">
                      <Ellipsis phrase={attachment.File.Name} maxLength={42} />
                    </span>
                    <span className="imgTextCreatorContent">
                      {attachment.File.CreatorUser && (
                        <AvatarsItems
                          users={[attachment.File.CreatorUser]}
                          customNormalSize={16}
                          customMarginRight="0"
                          customMarginTop="-10px"
                          showDetail
                        />
                      )}
                      <span className="textCreator">
                        {attachment.File.CreationDate.format('DD/MM/YYYY HH:mm')}
                      </span>
                    </span>
                  </div>
                  <Button
                    type="text"
                    className="buttonCancelOrDelete"
                    onClick={() => onDelete(attachment)}
                  >
                    <Icon
                      icon="excluir"
                      customSize={14}
                      className="iconCancelOrDelete"
                    />
                  </Button>
                </div>
              </ImgCard>
            )
          }

          return null;
        })}
        {attachmentsUploading && (
          <ImgCard key={`uploading-${attachmentsUploading.id}`} isprogress="true">
            <div className="image">
              <img
                src={imgPlaceholder}
                alt="Imagem de anexo"
              />
              <Progress
                percent={attachmentsUploading.percentage}
                size={[76, 5]}
                showInfo={false}
                trailColor={theme.colors.surface.surface}
                strokeColor={theme.colors.primary.primary}
                className="progressBar"
              />
              <div className="progressMask" />
            </div>

            <div className="imgContent">
              <div className="imgTextContent">
                <span className="textName">
                  <Ellipsis
                    phrase={attachmentsUploading?.file?.name || ''}
                    maxLength={42}
                  />
                </span>
                <span className="textSize">
                  {sizeFileRangeToInitials(
                    attachmentsUploading.sentSize,
                    attachmentsUploading.file?.size || 0,
                    true
                  )
                  }
                </span>
              </div>
              <Button
                type="text"
                className="buttonCancelOrDelete"
                onClick={() => onCancel(attachmentsUploading.id)}
              >
                <Icon
                  icon="cancelar"
                  customSize={14}
                  className="iconCancelOrDelete"
                />
              </Button>
            </div>
          </ImgCard>
        )}
        {filteredUploading.attachmentsIds.map(imgId => {
          const attachment = filteredUploading.attachments[imgId];

          if (attachment && attachment?.file && attachment.id) {
            const hasError = attachment.isError;

            return (
              <ImgCard key={`forUpload-${attachment.id}`} haserror={`${hasError}`}>
                <div className="image">
                  {hasError && (
                    <Icon
                      icon="alerta"
                      customSize={24}
                      className="iconError"
                      error
                    />
                  )}
                  {!hasError && (
                    <img
                      src={imgPlaceholder}
                      alt="Imagem de anexo"
                    />
                  )}
                </div>

                <div className="imgContent">
                  <div className="imgTextContent">
                    <span className="textName">
                      <Ellipsis phrase={attachment.file.name} maxLength={42} />
                    </span>
                    <span className="textSize">
                      {hasError
                        ? 'Erro no upload do arquivo'
                        : sizeFileRangeToInitials(
                          0,
                          attachment.file.size,
                          true
                        )
                      }
                    </span>
                  </div>
                  <Button
                    type="text"
                    className="buttonCancelOrDelete"
                    onClick={() => onCancel(attachment.id)}
                  >
                    <Icon
                      icon={hasError ? 'excluir' : 'cancelar'}
                      customSize={14}
                      className="iconCancelOrDelete"
                    />
                  </Button>
                </div>
              </ImgCard>
            )
          }

          return null;
        })}
      </Content>
    </>
  )
};

export default RegisterAttachments;
