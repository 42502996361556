import { FC, useEffect, useState } from 'react';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { TenantActions } from '../../../Store/Tenant/Tenant.actions';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { IChangeContextData } from 'Data/interfaces/Tenant/IChangeContextData';
import { Http } from 'Utils/Http';

export interface IChangeContext {
  match: any;
}

const ChangeContext: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [redirectUrl] = useState(Http.GetQueryParams('redirectUrl'));

  useEffect(() => {
    if (props.match.params.tenantId) {

      const request = {
        tenantId: props.match.params.tenantId,
        redirectUrl: redirectUrl ?? '/obras'
      } as IChangeContextData;

      dispatch(TenantActions.changeContext(request));
    }
  }, [dispatch, props.match.params.tenantId]);

  return (<></>);
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
});

const connector = connect(
  mapState,
);

type Props = ConnectedProps<typeof connector> & IChangeContext;

export default connector(ChangeContext);
