import { ActionsUnion, Action, createAction } from 'Utils/actionHelper';
import { IStorePlanResponse } from 'Data/interfaces/Store/IStorePlanResponse';
import { IUpdateStorePlanRequest } from 'Data/interfaces/Store/IUpdateStorePlanRequest';
import { IDowngradeRequest } from 'Pages/Faturamento/DadosFaturamento';
import { IUserStoreItem } from 'Data/interfaces/Store/IUserStoreItem';
import { IGetDowngradeInfoResponse } from 'Data/interfaces/Auth/IGetDowngradeInfoResponse';
import { IUserStoreTrackingRequest } from 'Data/interfaces/Store/IUserStoreTrackingRequest';

export enum RevenueActionKeys {
  GET_PLAN_FOR_CHANGE = "GET_PLAN_FOR_CHANGE",

  URL_REDIRECT = "URL_REDIRECT",

  CANCEL_PAGE_UPDATE_BILLING_DATA = "CANCEL_PAGE_UPDATE_BILLING_DATA",

  SHOW_MODAL_EXIT_BILLING_DATA = "SHOW_MODAL_EXIT_BILLING_DATA",
  
  CANCEL_PAGE_DOWNGRADE = "CANCEL_PAGE_DOWNGRADE",

  SHOW_MODAL_EXIT_DOWNGRADE = "SHOW_MODAL_EXIT_DOWNGRADE",

  SHOW_WARNING_DOWNGRADE_PLAN = "SHOW_WARNING_DOWNGRADE_PLAN",

  UPGRADE_OR_DOWGRADE_PLAN_REQUEST = 'UPGRADE_OR_DOWGRADE_PLAN_REQUEST',
  UPGRADE_OR_DOWGRADE_PLAN_SUCCESS = 'UPGRADE_OR_DOWGRADE_PLAN_SUCCESS',
  UPGRADE_OR_DOWGRADE_PLAN_FAILURE = 'UPGRADE_OR_DOWGRADE_PLAN_FAILURE',

  CANCEL_UPDATE_PLAN_REQUEST = 'CANCEL_UPDATE_PLAN_REQUEST',
  CANCEL_UPDATE_PLAN_SUCCESS = 'CANCEL_UPDATE_PLAN_SUCCESS',
  CANCEL_UPDATE_PLAN_FAILURE = 'CANCEL_UPDATE_PLAN_FAILURE',

  GET_DOWNGRADE_INFO_REQUEST = 'GET_DOWNGRADE_INFO_REQUEST',
  GET_DOWNGRADE_INFO_SUCCESS = 'GET_DOWNGRADE_INFO_SUCCESS',
  GET_DOWNGRADE_INFO_FAILURE = 'GET_DOWNGRADE_INFO_FAILURE',
}

export const RevenuesActions = {
  getPlanForChange: (togglePlan: IStorePlanResponse): GetPlanForChangeProps => 
    createAction(RevenueActionKeys.GET_PLAN_FOR_CHANGE, togglePlan),

  urlRedirect: (urlRedirect: string): UrlRedirectProps => 
    createAction(RevenueActionKeys.URL_REDIRECT, urlRedirect),

  cancelPageUpdateBillingData: (toggle: boolean): CancelPageUpdateBillingDataProps => 
    createAction(RevenueActionKeys.CANCEL_PAGE_UPDATE_BILLING_DATA, toggle),
  
  showModalExitBillingData: (toggle: boolean): ShowModalExitBillingDataProps => 
    createAction(RevenueActionKeys.SHOW_MODAL_EXIT_BILLING_DATA, toggle),

  cancelPageDowgrade: (toggle: boolean): CancelPageDowgradeProps => 
    createAction(RevenueActionKeys.CANCEL_PAGE_DOWNGRADE, toggle),
  
  showModalExitDowgrade: (toggle: boolean): ShowModalExitDowgradeProps => 
    createAction(RevenueActionKeys.SHOW_MODAL_EXIT_DOWNGRADE, toggle),
  
  showWarningDowngradePlan: (downgradeRequest: IDowngradeRequest): ShowWarningDowngradePlanProps => 
    createAction(RevenueActionKeys.SHOW_WARNING_DOWNGRADE_PLAN, downgradeRequest),

  upgradeOrDowngradePlan: (request: IUpdateStorePlanRequest): UpgradeOrDowngradePlanAction =>
    createAction(RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_REQUEST, request),
  upgradeOrDowngradePlanSuccess: (response: IUserStoreItem): UpgradeOrDowngradePlanSuccessAction =>
    createAction(RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_SUCCESS, response),
  upgradeOrDowngradePlanFailure: (err: string): UpgradeOrDowngradePlanFailureAction =>
    createAction(RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_FAILURE, err),

  cancelUpdatePlan: (request: IUserStoreTrackingRequest): CancelUpdatePlanAction =>
    createAction(RevenueActionKeys.CANCEL_UPDATE_PLAN_REQUEST, request),
  cancelUpdatePlanSuccess: (response: IUserStoreItem): CancelUpdatePlanSuccessAction =>
    createAction(RevenueActionKeys.CANCEL_UPDATE_PLAN_SUCCESS, response),
  cancelUpdatePlanFailure: (err: string): CancelUpdatePlanFailureAction =>
    createAction(RevenueActionKeys.CANCEL_UPDATE_PLAN_FAILURE, err),

  getDowgradeInfo: (request: number): GetDowgradeInfoAction =>
    createAction(RevenueActionKeys.GET_DOWNGRADE_INFO_REQUEST, request),
  getDowgradeInfoSuccess: (response: IGetDowngradeInfoResponse): GetDowgradeInfoSuccessAction =>
    createAction(RevenueActionKeys.GET_DOWNGRADE_INFO_SUCCESS, response),
  getDowgradeInfoFailure: (err: string): GetDowgradeInfoFailureAction =>
    createAction(RevenueActionKeys.GET_DOWNGRADE_INFO_FAILURE, err),
}

export type RevenueActionUnion = ActionsUnion<typeof RevenuesActions>;

export type GetPlanForChangeProps = Action<RevenueActionKeys.GET_PLAN_FOR_CHANGE, IStorePlanResponse>;

export type UrlRedirectProps = Action<RevenueActionKeys.URL_REDIRECT, string>;

export type CancelPageUpdateBillingDataProps = Action<RevenueActionKeys.CANCEL_PAGE_UPDATE_BILLING_DATA, boolean>;

export type ShowModalExitBillingDataProps = Action<RevenueActionKeys.SHOW_MODAL_EXIT_BILLING_DATA, boolean>;

export type CancelPageDowgradeProps = Action<RevenueActionKeys.CANCEL_PAGE_DOWNGRADE, boolean>;

export type ShowModalExitDowgradeProps = Action<RevenueActionKeys.SHOW_MODAL_EXIT_DOWNGRADE, boolean>;

export type ShowWarningDowngradePlanProps = Action<RevenueActionKeys.SHOW_WARNING_DOWNGRADE_PLAN, IDowngradeRequest>;

export type UpgradeOrDowngradePlanAction = Action<RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_REQUEST, IUpdateStorePlanRequest>;
export type UpgradeOrDowngradePlanSuccessAction = Action<RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_SUCCESS, IUserStoreItem>;
export type UpgradeOrDowngradePlanFailureAction = Action<RevenueActionKeys.UPGRADE_OR_DOWGRADE_PLAN_FAILURE, string>;

export type CancelUpdatePlanAction = Action<RevenueActionKeys.CANCEL_UPDATE_PLAN_REQUEST, IUserStoreTrackingRequest>;
export type CancelUpdatePlanSuccessAction = Action<RevenueActionKeys.CANCEL_UPDATE_PLAN_SUCCESS, IUserStoreItem>;
export type CancelUpdatePlanFailureAction = Action<RevenueActionKeys.CANCEL_UPDATE_PLAN_FAILURE, string>;

export type GetDowgradeInfoAction = Action<RevenueActionKeys.GET_DOWNGRADE_INFO_REQUEST, number>;
export type GetDowgradeInfoSuccessAction = Action<RevenueActionKeys.GET_DOWNGRADE_INFO_SUCCESS, IGetDowngradeInfoResponse>;
export type GetDowgradeInfoFailureAction = Action<RevenueActionKeys.GET_DOWNGRADE_INFO_FAILURE, string>;
