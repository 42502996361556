import { FC } from "react";
import SearchViewerInput from "Components/UI/SearchViewerInput";
import { Icon, IconName } from "Components/UI";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import { IBreadcrumbPath, IHandleOpenFolder } from "..";
import { Header } from "../styles";

interface ISelectFileHeader {
  value: string;
  onSearch: (value: string) => void;
  disciplineFolder: IDisciplineViewerList | null;
  onListDisciplines: () => void;
  isLoading: boolean;
  onOpenFolder: (data: IHandleOpenFolder) => void;
  onIconLeft: (name: string) => IconName;
  breadCrumbs: IBreadcrumbPath[];
}

const SelectFileHeader: FC<ISelectFileHeader> = ({
  value,
  onSearch,
  disciplineFolder,
  onListDisciplines,
  isLoading,
  onOpenFolder,
  onIconLeft,
  breadCrumbs,
}) => {
  return (
    <Header>
      <SearchViewerInput
        value={value}
        onSearch={onSearch}
        inputPlaceholder="Procure por um arquivo"
        maxWidth="100%"
        marginBottom="0"
        inputClassName="customInput"
        iconClassName="customIcon"
        isDebounce={false}
      />
      <div className="mainHeader">
        <Icon
          icon="menuHome"
          customSize={12}
          onClick={() => onListDisciplines()}
          className="menuHome"
        />
        {(!isLoading && disciplineFolder) && (
          <div className="rowHeader">
            <Icon
              icon="retroceder"
              customSize={6}
              className="iconBreadCrumb"
            />
            <span
              onClick={() => onOpenFolder({
                folderId: disciplineFolder.DisciplineApiFolderId,
                ConstructionSiteDisciplinesId: disciplineFolder?.ConstructionSiteDisciplinesId,
              })}
              className="rowHeaderDiscipline"
            >
              <Icon
                icon={onIconLeft(disciplineFolder.CustomName || disciplineFolder.Discipline.Name)}
                customSize={10}
                className="iconLeft"
              />
              {disciplineFolder.CustomName || disciplineFolder.Discipline.Name}
            </span>
          </div>
        )}
        {!isLoading && (
          breadCrumbs.map((breadCrumb) => (
            <div key={breadCrumb.folderId} className="rowHeader">
              <Icon
                icon="retroceder"
                customSize={6}
                className="iconBreadCrumb"
              />
              <span
                onClick={() => onOpenFolder({
                  folderId: breadCrumb.folderId,
                  ConstructionSiteDisciplinesId: breadCrumb?.ConstructionSiteDisciplinesId,
                  folderName: breadCrumb.name
                })}
                className="rowHeaderDiscipline"
              >
                {breadCrumb.name}
              </span>
            </div>
          ))
        )}
      </div>
    </Header>
  )
}

export default SelectFileHeader;
