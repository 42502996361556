import { call, put } from 'redux-saga/effects';
import { FileApi } from '../../Data/File.api';
import { FileActions, UploadAvatarAction } from './File.actions';

export function* uploadAvatar({ payload }: UploadAvatarAction) {
  try {
    const { data } = yield call(FileApi.uploadAvatar, payload);
    yield put(FileActions.uploadAvatarSuccess(data.data.fileName));
  } catch (err: any) {
    yield put(FileActions.uploadAvatarFailure(err.errors[0].Message));
  }
}
