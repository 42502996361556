import { ITenantState } from './interfaces/ITenantState';
import { ITenantUsersWithConstruction } from '../../Data/interfaces/Tenant/ITenantUsersWithConstruction';
import { TenantActionUnion, TenantActionKeys } from './Tenant.actions';

const initialState: ITenantState = {
  TenantRoles: [],
  TenantUsers: [],
  Users: [],
  UsersWithConstruction: {} as ITenantUsersWithConstruction,
  isLoading: false,
  toggleMenuContext: false,
  setshowConfirmModalDeleteUser: false,
  reSendInviteLoading: false,
  reSendInviteId: undefined,
  customLoading: false,
  currentListTenant: null,
};

const tenantReducer = (state = initialState, action: TenantActionUnion): ITenantState => {
  switch (action.type) {
    case TenantActionKeys.GET_TENANT_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.GET_TENANT_ROLES_SUCCESS:
      const tenantRoles = action.payload.filter(
        (role) => !['Admin', 'Personalizado'].includes(role.RoleName)
      );
      return {
        ...state,
        TenantRoles: [...tenantRoles],
        isLoading: false
      };
    case TenantActionKeys.GET_TENANT_ROLES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case TenantActionKeys.GET_TENANT_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.GET_TENANT_USERS_SUCCESS:
      return {
        ...state,
        TenantUsers: [...action.payload],
        isLoading: false
      };
    case TenantActionKeys.GET_TENANT_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.GET_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.GET_USERS_SUCCESS:
      return {
        ...state,
        Users: [...action.payload],
        isLoading: false
      };
    case TenantActionKeys.GET_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.GET_TENANT_INVITE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.GET_TENANT_INVITE_INFO_SUCCESS:
      return {
        ...state,
        TenantInviteInfo: action.payload,
        isLoading: false
      };
    case TenantActionKeys.GET_TENANT_INVITE_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.LIST_USER_TENANTS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.LIST_USER_TENANTS_SUCCESS:
      return {
        ...state,
        Tenants: [...action.payload],
        isLoading: false
      };
    case TenantActionKeys.LIST_USER_TENANTS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.CHANGE_CONTEXT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.CHANGE_CONTEXT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TenantActionKeys.CHANGE_CONTEXT_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.TOGGLE_MENU_CONTEXT:
      return {
        ...state,
        toggleMenuContext: action.payload
      };
    case TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_SUCCESS:
      return {
        ...state,
        UsersWithConstruction: action.payload,
        isLoading: false
      };
    case TenantActionKeys.GET_TENANT_LIST_USERS_CONSTRUCTION_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case TenantActionKeys.REMOVE_TENANT_USER_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.REMOVE_TENANT_USER_SUCCESS:
      let tenantUsers = { ...state }.UsersWithConstruction?.tenantUsers;
      let userId = action.payload;
      let index = tenantUsers?.findIndex((x) => x.User.Id == userId);
      state.UsersWithConstruction?.tenantUsers?.splice(index ?? -1, 1);

      return {
        ...state,
        customLoading: false
      };
    case TenantActionKeys.REMOVE_TENANT_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };

    case TenantActionKeys.REMOVE_TENANT_INVITE_USER_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.REMOVE_TENANT_INVITE_USER_SUCCESS:
      let tenantInviteUsers = { ...state }.UsersWithConstruction?.tenantInvitedUsers;
      let inviteUserId = action.payload;
      let indexInvite = tenantInviteUsers?.findIndex(
        (x) => x.InvitedUserId == inviteUserId
      );
      state.UsersWithConstruction?.tenantInvitedUsers?.splice(indexInvite ?? -1, 1);

      return {
        ...state,
        customLoading: false
      };
    case TenantActionKeys.REMOVE_TENANT_INVITE_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };

    case TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_REQUEST:
      return {
        ...state,
        reSendInviteId: action.payload,
        reSendInviteLoading: true
      };
    case TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_SUCCESS:
      return {
        ...state,
        reSendInviteId: undefined,
        reSendInviteLoading: false
      };
    case TenantActionKeys.TENANT_USERS_CONSTRUCTION_RE_SEND_INVITE_FAILED:
      return {
        ...state,
        error: action.payload,
        reSendInviteId: undefined,
        reSendInviteLoading: false
      };

    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_SUCCESS:
      if (state.UsersWithConstruction) {
        const index = state.UsersWithConstruction.tenantUsers.findIndex(
          (tenantUser: any) => tenantUser.User.Id === action.payload.User.Id
        );
        state.UsersWithConstruction.tenantUsers[index] = action.payload;
      }
      return {
        ...state,
        customLoading: false
      };
    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };
    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_SUCCESS:
      if (state.UsersWithConstruction) {
        const index = state.UsersWithConstruction.tenantInvitedUsers.findIndex(
          (tenantInviteUser: any) =>
            tenantInviteUser.InvitedUserId === action.payload.InvitedUserId
        );
        state.UsersWithConstruction.tenantInvitedUsers[index] = action.payload;
      }
      return {
        ...state,
        customLoading: false
      };
    case TenantActionKeys.UPDATE_TENANT_CONSTRUCTION_SITE_TO_INVITED_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };
    case TenantActionKeys.TENANT_BIND_INVITED_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TenantActionKeys.TENANT_BIND_INVITED_USERS_SUCCESS:
      if (state.UsersWithConstruction) {
        const combinedUsers: any = state.UsersWithConstruction.tenantInvitedUsers?.concat(action.payload);
        state.UsersWithConstruction.tenantInvitedUsers = combinedUsers
      }
      return {
        ...state,
        isLoading: false
      };
    case TenantActionKeys.TENANT_BIND_INVITED_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case TenantActionKeys.UPDATE_TENANT_USER_ROLE_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.UPDATE_TENANT_USER_ROLE_SUCCESS:
      const newUsersRoleState = state.UsersWithConstruction?.tenantUsers;
      if (newUsersRoleState) {
        const indexToChangePaper = newUsersRoleState.findIndex(
          (user) => user.User.Id === action.payload.UserId,
        );
        newUsersRoleState[indexToChangePaper].Role.TenantRoleId = action.payload.RoleId

        if (state.TenantRoles) {
          const roleName = state.TenantRoles.find((role) => role.TenantRoleId === action.payload.RoleId)?.RoleName;
          if (roleName) {
            newUsersRoleState[indexToChangePaper].Role.RoleName = roleName;
          }
        }
      }
      return {
        ...state,
        ...newUsersRoleState,
        customLoading: false
      };
    case TenantActionKeys.UPDATE_TENANT_USER_ROLE_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };

    case TenantActionKeys.UPDATE_TENANT_STATUS_REQUEST:
      return {
        ...state,
        customLoading: true
      };
    case TenantActionKeys.UPDATE_TENANT_STATUS_SUCCESS:
      if (state.UsersWithConstruction) {
        const indexToChangePaper = state.UsersWithConstruction.tenantUsers.findIndex(
          (user) => user.TenantUserId === action.payload.tenantUserId,
        );
        state.UsersWithConstruction.tenantUsers[indexToChangePaper].isActive = action.payload.isActive
      }

      return {
        ...state,
        customLoading: false
      };
    case TenantActionKeys.UPDATE_TENANT_STATUS_FAILED:
      return {
        ...state,
        error: action.payload,
        customLoading: false
      };
      
    case TenantActionKeys.UPDATE_CURRENT_LIST_TENANT:
      return {
        ...state,
        currentListTenant: action.payload,
      };

    default:
      return state;
  }
};

export default tenantReducer;
