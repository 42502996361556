import styled, { css } from "styled-components";

interface IMainContent {
  hasCheck?: boolean;
  isDatePicker?: boolean;
};

interface IStatusIcon {
  customColor: string;
};

interface IShowComplex {
  customMargin?: string;
};

export const Container = styled.div`
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }
  
  .filterButton {
    cursor: pointer !important;
    height: 32px;
    width: auto;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .textId {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }

    .textOperation {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      margin-left: 4px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }

    .textDescription {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({theme}) => theme.colors.surface.onSurface};
      margin-left: 4px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  padding-bottom: 12px;

  .textTitle {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  .deleteButton {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    background-color: transparent !important;
    cursor: pointer;
    
    &:hover {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.danger.dangerHover}, ${theme.colors.surface.surface})`} !important;
      border: none !important;
    }
    &:focus {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.danger.dangerPressed}, ${theme.colors.surface.surface})`} !important;
      border: none !important;
    }
    &:active {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.danger.dangerPressed}, ${theme.colors.surface.surface})`} !important;
      border: none !important;
    }

    .deleteIcon {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant} !important;    

      &:hover {
        color: ${({theme}) => theme.colors.danger.danger} !important;
      }
      &:focus {
        color: ${({theme}) => theme.colors.danger.danger} !important;
      }
      &:active {
        color: ${({theme}) => theme.colors.danger.danger} !important;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .headerContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    .selectContainer {
      width: 100%;
      height: 32px;
  
      &:first-child {
        margin-right: 3px;
      }

      &:last-child {
        margin-left: 3px;
      }
  
      .ant-select-selector, .select {
        width: 100%;
        border: none;
      }
    }
  }
`;

export const MainContent = styled.div<IMainContent>`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  max-height: 205px;
  overflow-y: auto;
  padding-left: 0;
    
  ${({ hasCheck }) => hasCheck && css`
    padding-left: 6px;
  `}

  ${({ isDatePicker }) => isDatePicker && css`
    height: auto;
    max-height: 100%;
  `}

  .checkBox {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .inputDefault {
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
    border-radius: 3px;
    border: none;

    &:focus {
      outline-color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }

    ::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }
  }
`;

export const Check = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;

  .text {
    margin-left: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;

export const ShowComplex = styled.div<IShowComplex>`
  display: flex;
  align-items: center;
  margin-left: ${({ customMargin }) => customMargin || 0};

  .text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.surface.onSurface};
    margin-left: 4px;
  }

  .textOperation {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    margin-left: 4px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
  }
`;

export const StatusIcon = styled.div<IStatusIcon>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ customColor }) => customColor};
  margin-left: 4px;
`;
