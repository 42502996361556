export const extensions2dAnd3d = [
  'dwg', 'dxf', 'dgn', 'rvt', 'nwc', 'nwd',
  'ifc', 'dwf', 'dwfx', 'rcp',
];

export const extensions3d = [
  'rvt', 'dwf', 'nwc', 'nwd', 'ifc', 'dwfx',
  'rcp', 'skp',
];

export const allOtherExtensions = [
  'jpg', 'jpeg', 'png', 'tif', 'bmp', 'doc',
  'docx', 'docm', 'ppt', 'pps', 'ppsx', 'ppsm',
  'pptx', 'pptm', 'xls', 'xlsx', 'xlsm', 'rtf',
  'pdf', 'txt', 'html', 'gdoc', 'gsheet', 'gslides',
];

export const extensionsImage = [
  'jpg', 'jpeg', 'png', 'tif', 'bmp', 'webp',
  'gif',
];

export const extensionViews2d3d = ['rvt', 'dwf', 'dwfx', 'ifc', 'rcp', 'skp'];

export const extensionPranchas = ['rvt', 'dwf', 'dwfx'];

export const extensionViewpoints = ['nwc', 'nwd'];

export const isValid3DExtension = (extension: string) => {
  if (extensions3d.indexOf(extension.toLocaleLowerCase()) !== -1) {
    return true;
  }
  return false;
}

export const getFileExtension = (filename: string) => {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
}
