import { IUpdateStatusFileList } from './interfaces/IUpdateStatusFileList';
import { UpdateStatusActionUnion, UpdateStatusActionKeys } from './UpdateStatus.actions';
import AppStorage from 'Utils/AppStorage';

const initialState: IUpdateStatusFileList = {
  isLoading: false,
  status: 'atualizado',
  lastUpdate: AppStorage.GetItem('lastUpdateFileList'),
};


const updateStatusFileListReducer = (state = initialState, action: UpdateStatusActionUnion): IUpdateStatusFileList => {
  switch (action.type) {
    case UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        status: 'atualizando'
      }

    case UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 'atualizado',
        lastUpdate: action.payload,
      }

    case UpdateStatusActionKeys.UPDATE_STATUS_FILE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: 'error'
      }

    default:
      return state;
  }
};

export default updateStatusFileListReducer;
