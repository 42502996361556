import styled, { css } from "styled-components";

interface ITopbarWrapper {
  showfilters?: string;
}

export const TopbarWrapper = styled.div<ITopbarWrapper>`
  height: 38px;
  margin: 15px 0;
  width: 100%;
  background: ${({theme}) => theme.colors.surface.surface};
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 6px;
  position: relative;
  align-items: center;

  .TopbarInput {
    display: flex;
    
    .TopbarInputSearch {
      margin-left: 6px;
      margin-right: 36px;
      min-width: 200px;
    }
  }

  .TopBarButtons {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
  }

  ${({showfilters}) => showfilters === 'true' && css`
    margin-bottom: 12px;  
  `}

  .totalSelectedFiles {
    left: 22px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    position: absolute;
  }

  @media print {
    display: none;
  }
`;

interface ITableActionTopbarButtonWrapper {
  disabled?: string;
  blocked?: string;
}

export const TableActionTopbarButtonWrapper = styled.div<ITableActionTopbarButtonWrapper>`
  height: 100%;
  position: relative;
  z-index: 2;

  .TableActionTopbarButton {
    height: 100%;
    padding: 0 12px 0 10px;
    margin: 0 3px 0 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .active {
      background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`};
      
      span {
        color: ${({theme}) => theme.colors.primary.primary};
      }
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      padding-left: 5px;
      user-select: none;
    }
  }

  :hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};

    .titleButton {
      color: ${({theme}) => theme.colors.primary.primary};
    }
  }

  ${({disabled}) => disabled === 'true' && css`
    span {
      color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
    }
  
    .TableActionTopbarButton {
      cursor: not-allowed;
    }
  
    :hover {
  
      .titleButton {
        color: ${({theme}) => theme.colors.surface.onSurfaceVariant};
      }
    }
  `}

  ${({blocked}) => blocked === 'true' && css`
    span {
      color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
    }
    
    :hover {

      .titleButton {
        color: ${({theme}) => theme.colors.surface.onSurfacePlaceholder};
      }
    }
  `}
`;
