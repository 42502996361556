import styled from "styled-components";
import { ColorPicker } from "antd";

export const CustomColorPicker = styled(ColorPicker)`
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  
  &:hover {
    border: 1px solid ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  &.ant-color-picker-trigger-active {
    box-shadow: none;
    border: 1px solid ${({theme}) => theme.colors.primary.primary};  
  }

  .colorPicker {
    display: flex;
    align-items: center;

    .hex {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16.34px;
      margin-left: 4px;
      margin-right: 8px;
      color: ${({theme}) => theme.colors.surface.onSurface};
    }
  }
`;

export const CustomPanel = styled.div`

  .ant-color-picker-slider-container {
    display: flex;
    align-items: center;


    .ant-color-picker-slider-group {
      margin-top: 12px;
    }

    .ant-color-picker-slider-alpha {
      display: none;
    }
  }
  
  .ant-color-picker-input-container {
    height: 24px;
    margin-top: 12px;

    .ant-select-selector {
      min-height: 24px !important;
    }

    .ant-select-arrow {
      font-size: 10px !important;
      color: ${({theme}) => theme.colors.surface.onSurface} !important;
    }

    .ant-input-number  {
      min-width: 56px;
      padding-left: 4px;
    }
  }

  .divider {
    height: 1px;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
    width: 100%;
    margin-top: 12px;
  }

  .ant-collapse-header {
    padding: 12px 16px !important;

    .ant-collapse-arrow {
      font-size: 10px !important;
      color: ${({theme}) => theme.colors.surface.onSurface} !important;
    }
  }
`;
