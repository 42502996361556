import styled, { css } from "styled-components";
import { Tag } from "antd";

export interface ICustomTag {
  error?: boolean;
}

export const CustomTag = styled(Tag)<ICustomTag>`
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 8px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 10.89px;
  border-radius: 19px;
  color: #FFF !important;
  cursor: pointer;

  i {
    margin-left: -6px;
  }

  ${({error}) => error && css`
    background: #E2574C !important;
  `}
`;
