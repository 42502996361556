import { FC, useEffect, useState } from "react";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { IConstructionSiteStageLibrary } from "Data/interfaces/Obra/IObraLibraries";
import { useDispatch } from "react-redux";
import { AdmActions } from "Store/Adm/Adm.actions";
import { TextWrap } from "./styles";

interface IDeleteForm {
  visible: boolean;
  isLoading?: boolean;
  deleteLib: IConstructionSiteStageLibrary;
  onCancel: () => void;
}

const DeleteForm: FC<IDeleteForm> = ({
  visible,
  isLoading,
  deleteLib,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const [isSending, setIsSending] = useState(false);

  const handleConfirmDelete = () => {
    setIsSending(true);
    dispatch(AdmActions.deleteLibrary(deleteLib.ConstructionSiteStageLibraryId));
  }

  const buttons = (
    <div>
      <Button onClick={onCancel} >
        <span>Cancelar</span>
      </Button>
      <Button
        onClick={handleConfirmDelete}
        loading={isLoading}
        type="primary"
      >
        <span>Confirmar</span>
      </Button>
    </div>
  );

  useEffect(() => {
    if (isSending && !isLoading) {
      setIsSending(false);
      onCancel();
    }
  }, [isLoading]);

  return (
    <div>
      <Modal
        title="Exclusão de Biblioteca"
        footerButtons={buttons}
        visible={visible}
        onCancel={onCancel}
      >
        <TextWrap>
          <div className="margin">
            {`Atenção: a exclusão da Biblioteca "${deleteLib.Name}" é realizada de forma permanente,
            não sendo possível desfazer a ação.`}
          </div>
          <div>Deseja continuar?</div>
        </TextWrap>
      </Modal>
    </div>
  )
}

export default DeleteForm;
