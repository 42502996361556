import { call, put } from 'redux-saga/effects';
import { TenantApi } from '../../Data/Tenant.api';
import {
  CheckDNSAction,
  GetMultiDomainAction, GetTenantPersonalizationAction, MultiDomainActions, UpdateTenantPersonalizationAction, UploadTenantPersonalizationAction,
} from './MultiDomain.action';
import toastHandler from '../../Utils/toastHandler';
import { getDefaultDomain } from 'Utils/MultidomailUtils';
import { IMultiDomainPersonalizationResponse } from 'Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse';
import { AdmActions } from 'Store/Adm/Adm.actions';

export function* getMultiDomain({ payload }: GetMultiDomainAction) {
  try {
    const { data } = yield call(TenantApi.getMultiDomain, payload);

    yield put(MultiDomainActions.getMultiDomainSuccess(data?.data));

  } catch (err: any) {
    const defaultDomainConfig = getDefaultDomain['default'];
    const currentDomainConfig: IMultiDomainPersonalizationResponse = {
      TenantFk: 0,
      Status: defaultDomainConfig.Status,
      DomainName: defaultDomainConfig.DomainName,
      Tenant: defaultDomainConfig.Tenant,
      LoginBannerUrl: defaultDomainConfig.LoginBannerUrl,
      LogoUrl: defaultDomainConfig.LogoUrl,
      PrimaryColor: defaultDomainConfig.PrimaryColor,
      SecondaryColor: defaultDomainConfig.SecondaryColor,
    }

    yield put(MultiDomainActions.getMultiDomainSuccess(currentDomainConfig));
    
    const msg = 'Erro ao carregar personalização do Domínio.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(MultiDomainActions.getMultiDomainFailure(error));
  }
}

export function* getTenantPersonalization({ payload }: GetTenantPersonalizationAction) {
  try {
    const { data } = yield call(TenantApi.getTenantPersonalization, payload);

    yield put(MultiDomainActions.getTenantPersonalizationSuccess(data.data));

  } catch (err: any) {
    const msg = 'Erro ao carregar personalização do Tenant.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(MultiDomainActions.getTenantPersonalizationFailure(error));
  }
}

export function* updateTenantPersonalization({ payload }: UpdateTenantPersonalizationAction) {
  try {
    const { data } = yield call(TenantApi.updateTenantPersonalization, payload);

    if (payload.isAdmConfig) {
      yield put(AdmActions.getTenantPersonalizationAdmSuccess(data.data));
      yield put(MultiDomainActions.updateTenantPersonalizationSuccess({} as IMultiDomainPersonalizationResponse));

    } else {
      yield put(MultiDomainActions.updateTenantPersonalizationSuccess(data.data));
    }

  } catch (err: any) {
    const msg = 'Ocorreu um erro ao atualizar o Tenant.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(MultiDomainActions.updateTenantPersonalizationFailure(error));
    yield put(AdmActions.getTenantPersonalizationAdmFailure(error));
    toastHandler.showError(error);
  }
}

export function* uploadTenantPersonalization({ payload }: UploadTenantPersonalizationAction) {
  try {
    const { data } = yield call(TenantApi.uploadTenantPersonalization, payload);

    yield put(MultiDomainActions.uploadTenantPersonalizationSuccess(data.data));
    
  } catch (err: any) {
    const msg = 'Erro ao realizar upload da imagem.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(MultiDomainActions.uploadTenantPersonalizationFailure(error));
    toastHandler.showError(error);
  }
}

export function* checkDNS({ payload }: CheckDNSAction) {
  try {
    const { data } = yield call(TenantApi.checkDNS, payload.tenantFk);

    if (payload.isAdmConfig) {
      yield put(AdmActions.getTenantPersonalizationAdmSuccess(data.data));
      yield put(MultiDomainActions.checkDNSSuccess({} as IMultiDomainPersonalizationResponse));
      
    } else {
      yield put(MultiDomainActions.checkDNSSuccess(data.data));
    }
    
    if ([2, 3].includes(data?.data?.Status)) {
      toastHandler.showSuccess('CNAME Verificado com Sucesso');
    }

  } catch (err: any) {
    const msg = 'Erro ao verificar DNS.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(MultiDomainActions.checkDNSFailure(error));
    yield put(AdmActions.getTenantPersonalizationAdmFailure(error));
  }
}
