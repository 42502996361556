import styled, { css } from "styled-components";

interface IContainer {
  ischecked?: boolean;
  isdisabled?: boolean;
  isloading?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};

  &+& {
    margin-top: 6px;
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
    border: none;

    .icon {
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    }
  }

  .icon {
    color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
    margin-left: 14px;
  }

  .disciplinaTitle {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-left: 10px;
  }

  ${({isloading, theme}) => isloading && css`
    border: 1px solid ${theme.colors.surface.surfaceOutline};
    background-color: ${theme.colors.surface.surfaceContainer};

    .icon {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }

    .disciplinaTitle {
      color: ${theme.colors.surface.onSurfaceVariant};
    }

    :hover {
      border: 1px solid ${theme.colors.surface.surfaceOutline};
      background-color: ${theme.colors.surface.surfaceContainer};

      .icon {
        color: ${({ theme }) => theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }
  `}

  ${({ischecked, theme}) => ischecked && css`
    border: 1px solid ${`color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`};
    background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`};

    .icon {
      color: ${theme.colors.surface.onSurfaceVariant} !important;
    }

    .disciplinaTitle {
      color: ${theme.colors.surface.onSurface};
    }
  `}

  ${({isdisabled, theme}) => isdisabled && css`
    background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`};
    border: none;

    .icon {
      color: ${theme.colors.surface.onSurfacePlaceholder} !important;
    }

    .disciplinaTitle {
      color: ${theme.colors.surface.onSurfaceVariant};
    }

    :hover {
      background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`};
      border: none;

      .icon {
        color: ${theme.colors.surface.onSurfacePlaceholder} !important;
      }
    }
  `}
`;