import { ObrasApi } from 'Data/Obras.api';
import { call, put } from 'redux-saga/effects';
import toastHandler from 'Utils/toastHandler';
import { HistoryActions, ListHistoryActivityRequestAction, ListHistoryFileRequestAction } from './History.actions';
import { IHistoryFileApiResponse, IHistoryFileResponseList } from 'Data/interfaces/History/IHistoryFileApi';
import { ActivitiesApi } from 'Data/ActivitiesApi';
import { IHistoryActivityResponseList } from 'Data/interfaces/History/IHistoryActivityApi';

export function* listFile({ payload }: ListHistoryFileRequestAction) {
  try {
    let responseApi: IHistoryFileApiResponse[];

    if (payload.isFolder) {
      const { data } = yield call(ObrasApi.listHistoryFolder, payload.itemId);
      responseApi = data.data;
    } else {
      const { data } = yield call(ObrasApi.listHistoryFile, payload.itemId);
      responseApi = data.data;
    }

    const response: IHistoryFileResponseList = {
      title: payload.title,
      parentName: payload.parentName,
      histories: responseApi,
    }
    yield put(HistoryActions.listHistoryFileSuccess(response));

  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : 'Erro ao carregar histórico';
    toastHandler.showError(error);
    yield put(HistoryActions.listHistoryFileFailure(error));
  }
}

export function* listActivity({ payload }: ListHistoryActivityRequestAction) {
  try {
    const { data } = yield call(ActivitiesApi.listHistory, payload.topicId);

    const response: IHistoryActivityResponseList = {
      histories: data.data,
    }
    yield put(HistoryActions.listHistoryActivitySuccess(response));

  } catch (err: any) {
    const error = err?.errors ? err.errors[0].Message : 'Erro ao carregar histórico';
    toastHandler.showError(error);
    yield put(HistoryActions.listHistoryActivityFailure(error));
  }
}
