import { call, put } from 'redux-saga/effects';

import { RevenueApi } from 'Data/Revenue.api';
import { CancelUpdatePlanAction, GetDowgradeInfoAction, RevenuesActions, UpgradeOrDowngradePlanAction } from './Revenues.actions';

import { requisitonSuccess, requisitonError } from 'Utils/Messages';
import toastHandler from 'Utils/toastHandler';
import { Mixpanel } from 'Utils/MixPanel';
import { store } from 'Store';

export function* upgradeOrDowngradePlan({ payload }: UpgradeOrDowngradePlanAction) {
  const { userInfoTracking, currentTenantTracking } = payload;
  delete payload.userInfoTracking;
  delete payload.currentTenantTracking;

  try {
    const { data } = yield call(RevenueApi.updatePlan, payload);
    yield put(RevenuesActions.upgradeOrDowngradePlanSuccess(data.data));
    toastHandler.showSuccess(requisitonSuccess('troca de plano'));

    const upOrDownPlan = data.data?.StorePlan;
    const upOrDownNewPlan = data.data?.UpdateStorePlan;
    if ((upOrDownNewPlan?.Order || 0) > (upOrDownPlan?.Order || 0)) {
      Mixpanel.track({
        name: 'TENANT_UPGRADE', 
        props: {
          storePlan: upOrDownPlan?.Name,
          newStorePlan: upOrDownNewPlan?.Name,
          storePlanChangeDate: new Date().toLocaleString(),
        },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      });
      
    } else {
      Mixpanel.track({
        name: 'TENANT_DOWNGRADE', 
        props: {
          storePlan: upOrDownPlan?.Name,
          newStorePlan: upOrDownNewPlan?.Name,
          storePlanChangeDate: new Date().toLocaleString(),
        },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      });
    }

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : requisitonError('troca de plano');
    toastHandler.showError(error);
    yield put(RevenuesActions.upgradeOrDowngradePlanFailure(error));

    const planName = store.getState().auth.userInfo?.UserStoreItem?.StorePlan?.Name;
    Mixpanel.track({
      name: 'TENANT_CHANGE_PLAN_ERROR', 
      props: {
        storePlan: planName,
        storePlanChangeDate: new Date().toLocaleString(),
      },
      userInfo: userInfoTracking,
      currentListTenant: currentTenantTracking,
    });
  }
};

export function* cancelUpdatePlan({ payload }: CancelUpdatePlanAction) {
  try {
    const { data } = yield call(RevenueApi.cancelUpdatePlan);
    yield put(RevenuesActions.cancelUpdatePlanSuccess(data.data));
    toastHandler.showSuccess(requisitonSuccess('cancelamento da troca de plano'));

    const upOrDownPlan = data.data?.StorePlan;
    const upOrDownNewPlan = payload.userInfoTracking?.UserStoreItem.UpdateStorePlan;
    if ((upOrDownNewPlan?.Order || 0) > (upOrDownPlan?.Order || 0)) {
      Mixpanel.track({
        name: 'TENANT_UPGRADE_CANCEL',
        props: {
          storePlan: upOrDownPlan?.Name,
          newStorePlan: upOrDownNewPlan?.Name,
          storePlanChangeDate: new Date().toLocaleString(),
        },
        userInfo: payload.userInfoTracking,
        currentListTenant: payload.currentTenantTracking,
      });
      
    } else {
      Mixpanel.track({
        name: 'TENANT_DOWNGRADE_CANCEL',
        props: {
          storePlan: upOrDownPlan?.Name,
          newStorePlan: upOrDownNewPlan?.Name,
          storePlanChangeDate: new Date().toLocaleString(),
        },
        userInfo: payload.userInfoTracking,
        currentListTenant: payload.currentTenantTracking,
      });
    }

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : requisitonSuccess('cancelamento da troca de plano');
    toastHandler.showError(error);
    yield put(RevenuesActions.cancelUpdatePlanFailure(error));

    const planName = store.getState().auth.userInfo?.UserStoreItem?.StorePlan?.Name;
    Mixpanel.track({
      name: 'TENANT_CHANGE_PLAN_CANCEL_ERROR', 
      props: {
        storePlan: planName,
        storePlanChangeDate: new Date().toLocaleString(),
      },
      userInfo: payload.userInfoTracking,
      currentListTenant: payload.currentTenantTracking,
    });
  }
};

export function* getDowgradeInfo({ payload }: GetDowgradeInfoAction) {
  try {
    const { data } = yield call(RevenueApi.getDowngradeInfo, payload);
    yield put(RevenuesActions.getDowgradeInfoSuccess(data.data));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : requisitonError('de informações do plano');
    toastHandler.showError(error);
    yield put(RevenuesActions.getDowgradeInfoFailure(error));
  }
};
