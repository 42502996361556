import { FC, useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { Checkbox, Skeleton, Spin } from 'antd';
import { Icon } from '../../../Components/UI';
import { IOptionData } from '../../../Components/UI/Select';
import { SelectValue } from 'antd/lib/select';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import { NomenclaturaActions } from '../../../Store/Nomenclatura/Nomenclatura.actions';
import {
  INomenclatureData,
  IVersionField,
  VersionFieldEnum,
} from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import { INomenclatureObraData } from 'Data/interfaces/Obra/ICreateOrUpdate';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import toastHandler from '../../../Utils/toastHandler';
import Button from '../Button';
import SortableItem from './components/SortableItem';
import DnDContext from './components/DnDContext';
import { DragOverlay, UniqueIdentifier } from '@dnd-kit/core';
import PremiumDropdown from './components/PremiumDropdown';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { history } from 'Store';
import { v4 as uuid } from "uuid";
import { LoadingOutlined } from '@ant-design/icons';
import {
  Divider,
  NomenclaturePreview,
  NomenclatureStructure,
  NomenclatureSwitch,
  SwitchStyled,
  TitlePreviewCheckbox,
} from './styles';

const separatorTypes: IOptionData[] = [
  {
    label: '_',
    description: 'Underline (_)',
    value: 0,
  },
  {
    label: '.',
    description: 'Ponto (.)',
    value: 1,
  },
  {
    label: '-',
    description: 'Traço (-)',
    value: 2,
  },
];

export interface INomenclatureFieldRow {
  id: string;
  fields: IOptionData[];
  separators: IOptionData[];
  fieldSelected?: number | string;
  separatorSelected?: number | string;
  fieldCustomName?: string;
}

export interface INomenclatura {
  isSubmited?: boolean;
  isTabVersion?: boolean;
  isFirstObra?: boolean;
  isEditObra?: boolean;
  obraVersionData?: INomenclatureData;
  versionConfigFk?: number;
  showEditObra?: boolean;
  showEditLoading?: boolean;
  hasValidateStagesInNomenclature?: boolean;
  onCancel?: () => void;
  onShow?: () => void;
  onSubmit: (props?: INomenclatureObraData, erros?: string[]) => void;
}

const Nomenclatura: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const firstRender = useRef(0);
  const dontChange = useRef(false);
  const [revisionControl, setRevisionControl] = useState<boolean>(true);
  const [nomenclatureFields, setNomenclatureFields] = useState<INomenclatureFieldRow[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isTabSubmit, setIsTabSubmit] = useState(false);
  const [isGlobalPattern, setIsGlobalPattern] = useState(true);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const isDisabled = !props.isFirstObra && props.isTabVersion && isGlobalPattern;
  const isPro = (props.userInfo?.UserStoreItem.StorePlan?.Order || 0) >= 3;
  const isFreeOrStandard = (props.userInfo?.UserStoreItem.StorePlan?.Order || 0) <= 2;

  const VersionData = useMemo(() => {
    if (props.isTabVersion && props.obraVersionData) {
      return props.obraVersionData;
    }

    return props.NomenclatureData;
  }, [props.isTabVersion, props.NomenclatureData, props.obraVersionData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableFields: IOptionData[] = props.CampoVersao?.map((campo) => ({
    value: campo.CampoVersaoId,
    description: campo.NomeCampo === 'Fase' ? 'Etapa' : campo.NomeCampo,
    label: campo.NomeCampo === 'Fase' ? 'Etapa' : campo.NomeCampo,
  })) || [];

  if (!props.isLoading && isSubmit) {
    setIsSubmit(false);
  }

  const addVersionFieldNomenclaturaRow = useCallback((versionFields: IVersionField[]) => {
    const nomencatureFieldsAux: INomenclatureFieldRow[] = [];

    versionFields.forEach((versionField) => {
      availableFields.forEach((field) => {
        if (field.value !== 1 && field.value !== 29 && field.value !== versionField.CampoVersaoId &&
          versionFields.filter((versionFieldRemoved) => versionFieldRemoved.CampoVersaoId === field.value).length > 0) {

          field.removed = true;
        } else {
          field.removed = false;
        }
      });

      nomencatureFieldsAux.push({
        id: uuid(),
        fields: JSON.parse(JSON.stringify(availableFields)),
        separators: separatorTypes,
        separatorSelected: versionField.Separador,
        fieldSelected: versionField.CampoVersaoId,
        fieldCustomName: versionField.CampoPersonalizado,
      } as INomenclatureFieldRow);
    });

    return nomencatureFieldsAux;
  }, [availableFields]);

  const newNomenclaturaRow = useCallback(() => {
    return {
      id: uuid(),
      fields: JSON.parse(JSON.stringify(availableFields)),
      separators: separatorTypes,
      separatorSelected: 0,
      fieldSelected: 1,
    };
  }, [availableFields]);

  const updateAllAvaliableFields = useCallback(() => {
    availableFields?.forEach((field) => {
      if (field.value !== 1 &&
        field.value !== 29 &&
        nomenclatureFields.filter((nomenclatureField) => nomenclatureField.fieldSelected === field.value).length > 0) {
        field.removed = true;
      }
    });
  }, [availableFields, nomenclatureFields]);

  const getErrors = useCallback(() => {
    const hasEmptyField = undefined !== nomenclatureFields
      .find((field, index) => (
        field.fieldSelected === 1 || (field.fieldSelected === 29 && !field?.fieldCustomName) ||
        (field.separatorSelected === undefined && nomenclatureFields.length !== index + 1)
      ));
    if (hasEmptyField) {
      return 'Os campos devem ser preenchidos';
    }
    const hasRevisionError = revisionControl && !nomenclatureFields
      .find((field) => (
        field.fieldSelected === 4
      ));

    if (hasRevisionError) {
      return 'É obrigatório conter o campo "Revisão"';
    }

    return null;
  }, [nomenclatureFields, revisionControl]);

  const submit = useCallback(() => {
    setIsSubmit(true);

    if (!props.isLoading) {
      const errors = getErrors();
      if (errors) {
        if (props.isTabVersion) {
          props.onSubmit(undefined, [errors]);
        } else {
          toastHandler.showError(errors);
        }

      } else {
        const requestData: INomenclatureData = {
          IsTabSubmit: props.isTabVersion,
          HasRevisionControl: revisionControl,
          VersionFields: nomenclatureFields.map((field, index) => ({
            Ordem: index,
            CampoVersaoId: field.fieldSelected,
            Separador: field.separatorSelected || 0,
            CampoPersonalizado: field.fieldSelected === 29 ? field.fieldCustomName : null,
          } as IVersionField)),
        };

        if (props.isTabVersion && !props.isFirstObra) {
          const requestObraData: INomenclatureObraData = {
            isVersionControlDefault: isGlobalPattern,
            VersionConfig: requestData,
          };

          props.onSubmit(requestObraData);

        } else {
          dispatch(NomenclaturaActions.create(requestData));
        }
      }
    }
  }, [
    dispatch,
    getErrors,
    nomenclatureFields,
    props,
    revisionControl,
    isGlobalPattern,
  ]);

  const deleteRow = (index: number) => {
    if ((nomenclatureFields.length - 1) === index) {
      nomenclatureFields[index - 1].separatorSelected = 0;
    }

    const removedField = nomenclatureFields.splice(index, 1);
    if (removedField[0].fieldSelected !== undefined) {
      updateNomenclatureFields(undefined, removedField[0].fieldSelected);
    }
  };

  const insertRow = () => {
    setNomenclatureFields([
      ...nomenclatureFields,
      newNomenclaturaRow(),
    ]);
  };

  const updateNomenclatureFields = (
    index?: number,
    oldValue?: any | string,
    newValue?: any,
  ) => {
    nomenclatureFields.forEach((nomenclatura, indexNomenclatura) => {
      if (index === undefined || index !== indexNomenclatura) {
        nomenclatura.fields.forEach((field) => {
          if (newValue && field.value === newValue) {
            field.removed = true;
          }

          if (field.value === oldValue || field.value === 1 || field.value === 29) {
            field.removed = false;
          }
        });
      }
    });

    setNomenclatureFields([...nomenclatureFields]);
  };

  const fieldChange = (
    index: number,
    selectValue: SelectValue,
    customName?: string,
  ) => {
    const oldSelectedValue = nomenclatureFields[index]['fieldSelected'];
    nomenclatureFields[index]['fieldSelected'] = selectValue as number;
    nomenclatureFields[index].fieldCustomName = (selectValue as number === 29)
      ? customName
      : undefined;

    updateNomenclatureFields(index, oldSelectedValue, selectValue);
  };

  const separatorChange = (index: number, selectValue: SelectValue) => {
    nomenclatureFields[index]['separatorSelected'] = selectValue as number;

    setNomenclatureFields([...nomenclatureFields]);
  };

  const upgradePlanRedirect = (role: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'nomenclatura_tabs',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    if (role === 1) {
      history.push('/faturamento/planos');

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const toggleGlobalPattern = () => {
    setIsGlobalPattern(prev => !prev);
  };

  const handleCancel = () => {
    if (!props.onCancel) return;
    dontChange.current = true;

    if (!props.versionConfigFk && props.NomenclatureData.VersionFields?.length) {
      setRevisionControl(props.NomenclatureData.HasRevisionControl);
      setNomenclatureFields(
        addVersionFieldNomenclaturaRow(props.NomenclatureData.VersionFields)
      );
    } else {
      setNomenclatureFields(
        addVersionFieldNomenclaturaRow(VersionData.VersionFields)
      );
      setRevisionControl(VersionData.HasRevisionControl);
    }
    updateAllAvaliableFields();

    if (!props.versionConfigFk) {
      setIsGlobalPattern(true);
    } else {
      setIsGlobalPattern(false);
    }

    props.onCancel();
  }

  useEffect(() => {
    if (props.isFirstObra || !props.versionConfigFk) {
      setIsGlobalPattern(true);
    } else {
      setIsGlobalPattern(false);
    }
  }, [props.isFirstObra, props.versionConfigFk]);

  useEffect(() => {
    if (!!VersionData.VersionFields) {
      setNomenclatureFields(
        addVersionFieldNomenclaturaRow(VersionData.VersionFields)
      );
      setRevisionControl(VersionData.HasRevisionControl);
      firstRender.current++;
    }

    if (!VersionData.VersionFields) {
      dispatch(NomenclaturaActions.getNomenclature());
    }
    if (!props.CampoVersao) {
      dispatch(NomenclaturaActions.getCampoVersao());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    VersionData.HasRevisionControl,
    VersionData.VersionFields,
    props.CampoVersao
  ]);

  useEffect(() => {
    updateAllAvaliableFields();
  }, [
    addVersionFieldNomenclaturaRow,
    availableFields.length,
    newNomenclaturaRow,
    nomenclatureFields,
    VersionData,
    updateAllAvaliableFields,
  ]);

  useEffect(() => {
    if (firstRender.current > 0) {
      if (isGlobalPattern && props.NomenclatureData.VersionFields?.length) {
        setRevisionControl(props.NomenclatureData.HasRevisionControl);
        setNomenclatureFields(
          addVersionFieldNomenclaturaRow(props.NomenclatureData.VersionFields)
        );
      } else {
        setNomenclatureFields(
          addVersionFieldNomenclaturaRow(VersionData.VersionFields)
        );
        setRevisionControl(VersionData.HasRevisionControl);
      }
      updateAllAvaliableFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalPattern]);

  useEffect(() => {
    if (props.isSubmited) {
      if (!isTabSubmit) {
        setIsTabSubmit(true);
        submit();
      } else {
        setIsTabSubmit(false);
        props.onSubmit();
      }
    } else {
      setIsTabSubmit(false);
    }
  }, [isSubmit, isTabSubmit, props, props.isSubmited, submit]);

  useEffect(() => {
    if (firstRender.current > 0 && !dontChange.current) {
      if (props.onShow && props.onCancel && props.isEditObra) {
        let show = false;
        if (
          !!props.versionConfigFk !== !isGlobalPattern ||
          props.obraVersionData?.HasRevisionControl !== revisionControl ||
          props.obraVersionData?.VersionFields?.length !== nomenclatureFields.length
        ) {
          show = true;
        }
        for (const i in nomenclatureFields) {
          if (nomenclatureFields[i] && props.obraVersionData?.VersionFields[i]) {
            if (
              nomenclatureFields[i].fieldSelected !== props.obraVersionData.VersionFields[i].CampoVersaoId ||
              nomenclatureFields[i].separatorSelected !== props.obraVersionData.VersionFields[i].Separador ||
              nomenclatureFields[i].fieldCustomName !== props.obraVersionData.VersionFields[i].CampoPersonalizado
            ) {
              show = true;
              break;
            }
          } else {
            show = true;
            break;
          }
        }

        if (show) {
          props.onShow();
        } else {
          props.onCancel();
        }
      }
    }
    if (dontChange.current) {
      dontChange.current = false;
    }
  }, [
    isGlobalPattern,
    nomenclatureFields,
    revisionControl,
    props.isEditObra,
    props.obraVersionData,
    props.NomenclatureData.VersionFields,
    props.versionConfigFk,
  ]);

  return (
    <>
      {props.isLoading && !isSubmit ? (
        <Skeleton active />
      ) : (
        <div>
          {((props.isTabVersion && !props.isFirstObra) || props.isEditObra) && (
            <NomenclatureSwitch>
              {isPro && (
                <SwitchStyled
                  size="small"
                  checked={isGlobalPattern}
                  onChange={toggleGlobalPattern}
                />
              )}
              {isFreeOrStandard && (
                <PremiumDropdown
                  role={props.userInfo?.CurrentRoleFk || 0}
                  onRedirect={upgradePlanRedirect}
                >
                  <SwitchStyled
                    size="small"
                    checked
                  />
                </PremiumDropdown>
              )}
              <span className="switchText">
                Usar padrão de nomenclatura global
              </span>
            </NomenclatureSwitch>
          )}
          <TitlePreviewCheckbox>
            <span className="titlePreview">
              Pré-visualização da nomenclatura
            </span>
            <Checkbox
              checked={revisionControl}
              onChange={() => setRevisionControl(!revisionControl)}
              disabled={isDisabled}
            >
              Controle de revisão
            </Checkbox>
          </TitlePreviewCheckbox>
          <NomenclaturePreview istabversion={`${props.isTabVersion}`}>
            {nomenclatureFields
              .filter((nomenclature) => nomenclature.fieldSelected && (Number(nomenclature.fieldSelected) > 1)).length > 0 ?
              nomenclatureFields
                .filter((nomenclature) => nomenclature.fieldSelected && (Number(nomenclature.fieldSelected) > 1))
                .map((nomenclature, index, array) => {
                  const field = nomenclature.fieldSelected !== 29
                    ? nomenclature.fields
                      .find((fieldType) => fieldType.value === nomenclature.fieldSelected)?.description
                    : nomenclature.fieldCustomName;
                  const separator = nomenclature.separators
                    .find((separatorType) => separatorType.value === nomenclature.separatorSelected)?.label;

                  return (
                    <>
                      <span className="spansPreview" key={`fieldPreview${index}`}>{field}</span>
                      {
                        array.length - 1 !== index
                          ? (
                            <span className="spansPreview" key={`separatorPreview${index}`}>
                              {separator}
                            </span>
                          )
                          : ''
                      }
                    </>
                  );
                })
              : 'Nenhum campo adicionado'
            }
          </NomenclaturePreview>
          <NomenclatureStructure isshowedit={props.showEditObra}>
            <span className="nomenclatureStructureTitle">
              Estrutura da nomenclatura
            </span>
            <div className="nomenclatureFieldsContainer">
              <DnDContext
                nomenclatureFields={nomenclatureFields}
                dragOverlay={(
                  <DragOverlay>
                    {activeId && (
                      <SortableItem
                        nomenclatureField={nomenclatureFields.find(field => field.id === activeId) as INomenclatureFieldRow}
                        nomenclatureFields={nomenclatureFields}
                        isDisabled={isDisabled}
                        index={nomenclatureFields.findIndex(field => field.id === activeId)}
                        isDragDisabled={isDisabled}
                        activeId={activeId}
                        removeDisabled={
                          (nomenclatureFields?.find(field => field?.id === activeId)?.fieldSelected === VersionFieldEnum.Fase &&
                            props.hasValidateStagesInNomenclature) ||
                          false
                        }
                        fieldChange={fieldChange}
                        separatorChange={separatorChange}
                        deleteRow={deleteRow}
                      />
                    )}
                  </DragOverlay>
                )}
                onActiveId={setActiveId}
                onNomenclatureFields={setNomenclatureFields}
              >
                {nomenclatureFields.map((nomenclatureField, index) => {
                  const removeDisabled = (Number(nomenclatureField.fieldSelected) === VersionFieldEnum.Fase &&
                    props.hasValidateStagesInNomenclature) || false;

                  return (
                    <SortableItem
                      nomenclatureField={nomenclatureField}
                      nomenclatureFields={nomenclatureFields}
                      isDisabled={isDisabled}
                      index={index}
                      isDragDisabled={isDisabled}
                      removeDisabled={removeDisabled}
                      fieldChange={fieldChange}
                      separatorChange={separatorChange}
                      deleteRow={deleteRow}
                    />
                  )
                })}
              </DnDContext>
            </div>
            <Button
              disabled={isDisabled}
              type="text"
              className="linkNewRow"
              onClick={insertRow}
            >
              <Icon
                icon="adicionar"
                customSize={12}
                className='iconAdd'
                disabled={isDisabled}
              />
              Adicionar novo campo
            </Button>
            {props.showEditObra && <Divider />}
            {((!props.isTabVersion && !props.isEditObra) || props.showEditObra) && (
              <div className="saveButton">
                <Button
                  type='primary'
                  loading={props.isLoading}
                  className="saveBtn"
                  onClick={submit}
                >
                  {props.showEditLoading && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          rev=""
                          color="white"
                          style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                        />
                      }
                    />
                  )}
                  {props.showEditLoading ? 'Salvando...' : 'Salvar alterações'}
                </Button>
                {props.onCancel && (
                  <Button
                    type="text"
                    className="cancelBtn"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                )}
              </div>
            )}
          </NomenclatureStructure>
        </div>
      )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  camposVersao: state.nomenclatura.CampoVersao,
  userInfo: state.auth.userInfo,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & INomenclatura;

export default connector(Nomenclatura);
