import * as Yup from 'yup';

export const createSchema = (rows: number) => {
  const fields: any = {};
  for(let i=0; i < rows; i++) {
    fields[`QtyCopies${i}`] = Yup.number().min(1, ' ').required(' ');
  }

  return Yup.object().shape(fields);
};

const schema = Yup.object().shape({

  descSolicitacao: Yup.string()
  .required('O campo deve ser preenchido.'),

  entregarPara: Yup.string()
  .required('O campo deve ser preenchido.'),

});

export default schema;