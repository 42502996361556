import styled, { css } from "styled-components";

export const Container = styled.div`
  position: absolute;
  margin-top: 8px;
  width: 120px;
  height: 120px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExternalCard = styled.div`
  width: 96px;
  height: 96px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.surface.surfaceOutline};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InternalCard = styled.div`
  width: 96px;
  height: 96px;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const OptionCard = styled.div`
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center; 
`;

interface ActiveCardProps {
  isactive: boolean;
}

export const ActiveCard = styled.div<ActiveCardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: none;

  .point {
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: ${({theme}) => theme.colors.surface.surfaceOutline};
  }
  
  &:hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.primary.primaryHover}, ${theme.colors.surface.surface})`};
  }

  ${({ isactive }) => isactive && css`
    background-color: ${({theme}) => theme.colors.primary.primary};
    
    .point {
      display: none;
    }
  `}
`;
