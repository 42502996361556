import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.surface.surface};
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 11px;
  background-color: ${({theme}) => theme.colors.surface.surface};
`;

interface ICardOption {
  isactive: string;
  isdisabled: string;
}

export const CardOption = styled.div<ICardOption>`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background-color: ${({theme}) => theme.colors.surface.surface};
  cursor: pointer;

  :hover {
    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  .cardOptionsText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: ${({theme}) => theme.colors.surface.onSurface};
  }

  ${({isactive, theme}) => isactive === 'true' && css`
    border-bottom: 2px solid ${theme.colors.primary.primary};
  `}

  ${({isdisabled, theme}) => isdisabled === 'true' && css`
    cursor: not-allowed;
    
    .cardOptionsText {
      font-weight: 400;
      color: ${theme.colors.surface.onSurfacePlaceholder};
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-height: 568px !important;
  background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
  padding: 14px;
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
