import { FC } from "react";
import SkeletonMain from "../LoadingMain";
import styles from "./LoadingDisciplineSAConnection.module.scss";

export interface ILoadingDisciplineSAConnection {
  multiple?: number;
}

const LoadingDisciplineSAConnection: FC<ILoadingDisciplineSAConnection> = ({ 
  multiple = 1
}) => {
  const SkeletonUsers = () => {
    return (
      <div className={styles['rowWrapper']}>
        <div className={styles['rowWrapperLeft']}>
          <SkeletonMain 
            width="20px" 
            height="20px" 
            minWidth="20px"
            borderRadius="3px"
            marginLeft="0"
          />
        </div>
        <div className={styles['rowWrapperRight']}>
          <SkeletonMain 
            width="50%" 
            height="12px" 
            borderRadius="2px"
          />
          <SkeletonMain 
            width="100%" 
            height="12px" 
            borderRadius="2px"
            marginTop="8px"
          />
          <SkeletonMain 
            width="100%" 
            height="12px" 
            borderRadius="2px"
            marginTop="4px"
          />
          <SkeletonMain 
            width="50%" 
            height="12px" 
            borderRadius="2px"
            marginTop="4px"
          />
        </div>
      </div>
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <div className={styles['mainWrapper']}>
      <div className={`${styles['firstRow']}`}>
        <SkeletonMain 
          width="100%"
          height="14px" 
          borderRadius="2px"
          marginLeft="0"
        />
        <SkeletonMain 
          width="100%"
          height="14px" 
          borderRadius="2px"
          marginTop="5px"
          marginLeft="0"
        />
        <SkeletonMain 
          width="50%"
          height="14px" 
          borderRadius="2px"
          marginTop="5px"
          marginLeft="0"
        />
      </div>
      {multipleSkeletonsObras.map((Skeleton, i) => (
        <Skeleton key={`skeleton-key-${i}`} />
      ))}
    </div>
  )
}

export default LoadingDisciplineSAConnection;