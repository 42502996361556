import styled, { css } from "styled-components";

interface IShellHostStyled {
  isCollapsed?: string;
}

export const ShellHostStyled = styled.div<IShellHostStyled>`
  width: 100vw;
  height: 100vh;

  .content {
    position: absolute;
    width: 100%;
    height: 100vh;
  }

  .main {
    left: 225px;
    position: relative;
    height: calc(100vh - 61px);
    max-height: calc(100vh - 61px);
    width: calc(100vw - 227px);
    background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
    transition: all 0.05s ease-out;
    padding-bottom: 20px;
    overflow: auto;

    ${({isCollapsed}) => isCollapsed === 'true' && css`
      left: 52px;
      position: absolute;
      width: calc(100vw - 52px);
      transition: all 0.3s ease-out;
    `}
  }

  @media print {
    .main {
      background-color: ${({theme}) => theme.colors.surface.surfaceContainer};
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: auto;
    }
  }
`;

