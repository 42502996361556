import { FC, useState } from 'react';
import { Icon } from '../../../../Components/UI';
import Button from '../../../../Components/UI/Button';
import { 
  CustomPopover, 
  Modal, 
} from './styles';
import { ApisEnumLabel } from 'Data/enums/Apis';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';

interface IErrorSAConnection {
  platform: number;
  onAction: () => void;
  triggered?: boolean;
  userRole?: number;
}

const ErrorSAConnection: FC<IErrorSAConnection> = ({
  platform, 
  onAction,
  triggered,
  userRole, 
  children,
}) => {
  const disciplineSAErrorBlogUrlFlag = useFeatureFlag('discipline-sa-error-blog-url');
  const diciplineSAErrorUrl = (disciplineSAErrorBlogUrlFlag.enabled && typeof disciplineSAErrorBlogUrlFlag.value === 'string') 
    ? disciplineSAErrorBlogUrlFlag.value 
    : 'https://www.maletadoengenheiro.com.br/blog';

  const [visible, setVisible] = useState<boolean>();

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleAction = () => {
    onAction();
    hide();
  }

  const overlay = (
    <Modal>
      <div className="Wrapper">
        <div className="content">
          <div className="iconBox">
            <h4 className="title">
              {`Ocorreu um erro ao tentar conectar com a disciplina no ${ApisEnumLabel[platform]}.`}
            </h4>
            <Icon 
              icon="cancelar" 
              customSize={12} 
              color="cinzaOpaco" 
              onClick={hide}
              className="closeIcon"
            />
          </div>

          <p className="description">
            {userRole === 1
              ? `Não conseguimos conectar com a pasta da disciplina no ${ApisEnumLabel[platform]}, e por isso, você não consegue abrir essa pasta. Para resolver esse problema é necessário fazer a migração dos dados ou excluir a disciplina.`
              : `Não conseguimos conectar com a pasta da disciplina no ${ApisEnumLabel[platform]}, e por isso, você não consegue abrir essa pasta. Para resolver esse problema é necessário entrar em contato com o administrador da conta.`
            } 
          </p>
        </div>

        <div className="Footer">
          <div className="link">
            <a
              href={diciplineSAErrorUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Saiba mais
            </a>
          </div>
          <Button 
            type="primary"
            onClick={userRole === 1 ? handleAction : hide}
            className="buttonAction"
          >
            {userRole === 1 ? 'Corrigir erro' : 'Fechar'}
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <CustomPopover 
      content={overlay} 
      placement="bottomLeft" 
      trigger="click"
      open={triggered ? false : visible} 
      onOpenChange={handleVisibleChange}
      overlayInnerStyle={{padding: 0}}
    > 
      {children}
    </CustomPopover>
  );
}

export default ErrorSAConnection;
