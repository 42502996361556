import { IFileViewerListResponse } from 'Data/interfaces/FederatedViewer/IFileViewerListResponse';
import { IFileData, IRenderingSignalR } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IUpdateStatusInViewer } from 'Data/interfaces/ListagemArquivos/IUpdateStatusInViewer';
import { IUpdateStatusInViewerResponse } from 'Data/interfaces/ListagemArquivos/IUpdateStatusInViewerResponse';
import { ObrasApi } from 'Data/Obras.api';
import { ViewerApi } from 'Data/Viewer.api';
import { call, put } from 'redux-saga/effects';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { IUserLimitsActive } from 'Store/Auth/interfaces/IAuthState';
import { loadError } from 'Utils/Messages';
import toastHandler from '../../Utils/toastHandler';
import { AutoDeskViewerAction, FederatedViewerActions, ListDisciplinesAction, ListFilesAction, UpdateStatusInViewerAction, VerifyViewerAutodeskProgressAction } from './FederatedViewer.actions';

export function* listDisciplines({ payload }: ListDisciplinesAction) {
  try {
    const { data } = yield call(ViewerApi.listDisciplines, payload);
    yield put(FederatedViewerActions.listDisciplinesSuccess(data.data));

  } catch (e: any) {
    const msg = 'Erro ao listar disciplinas';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(FederatedViewerActions.listDisciplinesFailure(error));
    toastHandler.showError(error);
  }
}

export function* listFiles({ payload }: ListFilesAction) {
  try {
    const { data } = yield call(ViewerApi.listFiles, payload);
    const response: IFileViewerListResponse = {
      constructionSiteDisciplineId: payload,
      fileList: data.data,
    }
    yield put(FederatedViewerActions.listFilesSuccess(response));

  } catch (e: any) {
    const msg = 'Erro ao listar arquivos';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(FederatedViewerActions.listFilesFailure(error));
    toastHandler.showError(error);
  }
}

export function* updateStatusInViewer({ payload }: UpdateStatusInViewerAction) {
  try {
    const request: IUpdateStatusInViewer = { ...payload };
    delete request.discipline;
    delete request.currentFile;
    const { data } = yield call(ViewerApi.updateStatusInViewer, request);
    const response: IUpdateStatusInViewerResponse = {
      file: {
        ...payload.currentFile || {} as IFileData,
        FileInfo: {
          ...payload.currentFile?.FileInfo,
          ...data.data,
        },
        SizeInBytes: data.data.Size,
      },
      discipline: payload.discipline,
    }
    yield put(FederatedViewerActions.updateStatusInViewerSuccess(response));

  } catch (e: any) {
    const msg = 'Erro ao incluir arquivo da Coordly';
    const error = e.errors ? (e.errors[0]?.Message || msg) : msg;
    yield put(FederatedViewerActions.updateStatusInViewerFailure(error));
    toastHandler.showError(error);
  }
}

export function* autodeskViewer({ payload }: AutoDeskViewerAction) {
  try {
      const { data } = yield call(ViewerApi.RenderAutoDesk, payload);

      const userLimitsActive: IUserLimitsActive = {
        QtyRenderedFiles: 1,
      }
      yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

      yield put(FederatedViewerActions.autoDeskViewerSuccess(data.data));
  } catch (err: any) {
      yield put(FederatedViewerActions.autoDeskViewerFailure(err.errors[0]?.Message || 'error'));
  }
}

export function* verifyViewerAutodeskProgress({ payload }: VerifyViewerAutodeskProgressAction) {
  try {
    for (let i = 0; i < payload.filesApiId.length; i++) {
      const currentFileApiId: string = payload.filesApiId[i];
      const { data } = yield call(ObrasApi.verifyFileRenderProgress, currentFileApiId, payload.constructionSiteId);
      
      const res = data && data.data;
      if (!res) { continue; }

      let etapa = 0;
      let progress = res.progress && res.progress.indexOf('%') !== -1 ? Number(res.progress.split('%')[0]) : 0;

      switch (res.status) {
        case 'inprogress':
          etapa = 3
          break;
        case 'success':
          etapa = 4;
          progress = 100;
          break;
        case 'notstarted':
          etapa = 1;
          break;
        case 'failed':
          etapa = 5;
          progress = 100;
          break;
      }

      let body: IRenderingSignalR = {
        etapa,
        fileIdentifier: currentFileApiId || '',
        progress,
        urn: res.urn
      };
      yield put(FederatedViewerActions.autodeskViewerRenderingResponse(body));
      yield put(FederatedViewerActions.updateStatusFileViewerAutodesk(body));
    }

  } catch (e) {
    const error = loadError('informações da renderização de um arquivo');
    toastHandler.showError(error);
  }
}
