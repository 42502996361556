import { IUserInforResponse } from '../Auth/IUserInfoResponse';
import { ColorName } from '../../../Utils/Color';
import { IUpdateFileInfoRequest } from '../Obra/IUpdateFileInfoRequest';
import { IObraResponse } from '../Obra/IObraListResponse';
import { ISignalRArchive } from '../Upload/IUploadFileData';
import { ICurrentUserPrivileges } from '../User/ICurrentUserPrivileges';

export interface IListFilesResponse {
  BreadCrumbItem: IBreadCrumbItem;
  Files: IFileData[];
  // HasChangeStructure: boolean;
  ObsoleteFolderId: string;
  isObsoleteFiles?: boolean;
  ConstructionSite: IObraResponse;
  LastUpdateDate: Date;
  CloudServiceFolderUrl: string;
  RequestManageObsoleteFiles: boolean;
  CurrentCsUserPermissions?: ICurrentUserPrivileges;
}

export interface IBreadCrumbItem {
  BreadCrumbItemIdDropbox: string;
  BreadCrumbItemId?: number;
  Name: string;
  ApiExtraId?: string;
  Identifier: string;
  Type: BreadCrumbTypesEnum;
  Application: ApplicationEnum;
  ConstructionSiteId: number;
  UserFk?: string;
  PreviousItem?: IBreadCrumbItem;
  User: IUserInforResponse;
}

export interface IFileData {
  Name: string;
  MimeType?: string;
  Identifier: IFileIdentifier;
  IsFolder: boolean;
  ModifiedBy: string;
  SizeInBytes: number;
  HasInconsistencia: boolean;
  HasNomenclatureControl?: boolean;
  InconsistenciasMsg?: string;
  Versions?: IFileData[];
  Revision?: string;
  NameWithoutRevision?: string;
  LastUpdate: string;
  RenderingStatus: BucketFileStatusEnum;
  VisualizationType?: FileVisualizationTypeEnum;
  DisplayOrder: number;
  Extension: string;
  IconClass: string;
  FileInfo: IUpdateFileInfoRequest;
  Status: FileStatusEnum;
  inObsoleteFolder: boolean;
  FileNameExist: boolean;
  FileNameNewRevision: any;
  FileNameCopy: any;
  RevisionNumber: number;
  RenderingSignalR: IRenderingSignalR;
  FileInfoViewer: string;
  FileIdToOverwrite: string;
  WebUrl: string;
  hasRevisionControl: boolean;

  // objeto de renderizar arquivo, provisório
  UploadRenderingStatus?: ISignalRArchive;
}

export interface IRenameFileResponse {
  FileApiId: string;
  Name: string;
  Revision: string;
  NameWithoutRevision: string;
  RevisionNumber: number;
}

export interface IFileIdentifier {
  ItemId: string;
  ApiId: string;
}

export interface IRenderingSignalR {
  etapa: EtapaEnum;
  api?: string;
  fileIdentifier: string;
  progress: number;
  urn?: string
  version?: undefined;
  timeStamp?: number;
  bucketFileName?: string;
}

export enum EtapaEnum {
  LoadingInfoFile = 0,
  DownloadSA = 1,
  UploadServer = 2,
  ProcessRender = 3,
  Rendered = 4,
  ErrorRender = 5,
}

export enum BreadCrumbTypesEnum {
  Common = 0,
  Hub = 1,
  Project = 2,
  Folder = 3,
  Item = 4,
}

export enum ApplicationEnum {
  Todas = 0,
  A360 = 1,
  Dropbox = 2,
  GoogleDrive = 3,
  Bim360 = 4,
}

export enum BucketFileStatusEnum {
  NotRendered = 0,
  Rendered = 1,
  Rendering = 2,
  Error = 3,
}

export enum BucketFileStatusBREnum {
  '-' = 0,
  'Renderizado' = 1,
  'Renderizando' = 2,
  'Erro' = 3,
}
export const BucketFileStatusEnumLabel = ['-', 'Renderizado', 'Rederizando', 'Erro'];
export const BucketFileStatusEnumColor: ColorName[] = ['cinzaSuperior', 'verde', 'cinzaSuperior', 'vermelho'];

export enum FileVisualizationTypeEnum {
  Tipo3D = 0,
  Tipo2D = 1,
  Imagem = 2,
  Office = 3,
  Texto = 4,
  Indefined = 5,
}

export enum FileStatusEnum {
  Bloqueado = 0,
  Liberado = 1,
}
export const FileStatusEnumLabel = ['Bloqueado', 'Liberado'];
export const FileStatusEnumColor: ColorName[] = ['cinzaSuperior', 'verde'];
