import { FC, useEffect, useMemo, useState } from "react";
import { Divider as AntDivider, Progress, Radio, RadioChangeEvent } from "antd";
import ColorPicker from "Components/UI/ColorPicker";
import { 
  CardSubTitle,
  CardTitle,
  Divider, 
  ImgWrapper, 
  InfoText, 
  InfoTextBold, 
  MainWrapperLeft, 
  MainWrapperRight,
  SvgCardBanner,
  WarningCard,
} from "./styles";
import ButtonsAction from "../ButtonsAction";
import { useSelector } from "react-redux";
import { 
  getDefaultInfoTheme, 
  getDomain, 
  getDomainLoading, 
  getImgUploadState, 
  getTheme,
} from "Store/MultiDomain/MultiDomain.selector";
import UploadCropImg from "Components/UI/UploadCropImg";
import { MultiDomainActions } from "Store/MultiDomain/MultiDomain.action";
import { 
  ITenantPersonalizationUpdateData, 
  IUpdateTenantPersonalizationRequest,
  TypePathUpdateData,
} from "Data/interfaces/MultiDomain/IUpdateMultiDomainPersonalization";
import { Dispatch } from "redux";
import { RcFile } from "antd/es/upload/interface";
import { 
  IUploadPersonalization,
} from "Data/interfaces/MultiDomain/IUploadPersonalization";
import { Icon } from "Components/UI";
import { 
  MultiDomainPersonalizationStatusEnum,
} from "Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse";
import { loadImage } from "Utils/MultidomailUtils";
import poweredBy from 'Static/images/powered-by.png';
import { IActiveTab } from "../OptionsTabs";
import { tabOptions } from "Pages/Personalization";
import { LoginPreviewBackground } from "./LoginPreviewBackground";

enum RadioOptions {
  'Padrão',
  'Personalizado',
}

interface ILoginScreenTab {
  dispatch: Dispatch<any>;
  tenantFk?: number;
  onActiveTab: (tab: IActiveTab) => void;
}

const LoginScreenTab: FC<ILoginScreenTab> = ({
  dispatch,
  tenantFk,
  onActiveTab,
}) => {
  const theme = useSelector(getTheme);
  const domain = useSelector(getDomain);
  const defaultInfoTheme = useSelector(getDefaultInfoTheme);
  const loading = useSelector(getDomainLoading);
  const imgUploadState = useSelector(getImgUploadState);

  const [radioOpt, setRadioOpt] = useState<RadioOptions>(0);
  const [newColor, setNewColor] = useState(theme.colors.tertiary.tertiary);
  const [newImg, setNewImg] = useState<string | undefined>(undefined);
  const [currentFile, setCurrentFile] = useState<RcFile | null>(null);
  const [currentAction, setCurrentAction] = useState<TypePathUpdateData | null>(null);

  const handleChange = (value: string) => {
    setNewColor(value);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setRadioOpt(e.target.value);
  };

  const handleUpdate = (
    useTenantFk: number,
    updateData: ITenantPersonalizationUpdateData[]
  ) => {
    if (useTenantFk && updateData.length > 0) {
      const request: IUpdateTenantPersonalizationRequest = {
        TenantFk: useTenantFk,
        updateData,
      }
      dispatch(MultiDomainActions.updateTenantPersonalization(request));
    }
  }

  const handleUpdateImage = () => {
    setCurrentAction('LoginBannerUrl');

    if (currentFile) {
      const request: IUploadPersonalization = {
        path: 'BannerLogin',
        file: currentFile,
      }
      
      return dispatch(MultiDomainActions.uploadTenantPersonalization(request));
    }

    if (tenantFk) {
      const updateData: ITenantPersonalizationUpdateData[] = [];

      if (!isCustom && newImg) {
        updateData.push({
          path: "LoginBannerUrl",
          op: 'replace',
          value: null,
        });
      }

      return handleUpdate(tenantFk, updateData);
    }
  };
  
  const handleUpdateColor = () => {
    if (tenantFk) {
      const updateData: ITenantPersonalizationUpdateData[] = [];
      updateData.push({
        path: "SecondaryColor",
        op: 'replace',
        value: newColor,
      });
  
      handleUpdate(tenantFk, updateData);
      setCurrentAction('SecondaryColor');
    }
  };
  
  const handleImageChange = (file: RcFile) => {
    setCurrentFile(file);
  };

  const handleUpdateCancelImage = () => {
    setCurrentFile(null);
    setRadioOpt(prev => {
      if (prev === 0 && newImg) {
        return 1;
      }

      return 0;
    });
  };

  const handleUpdateCancelColor = () => {
    setNewColor(theme.colors.tertiary.tertiary);
  };

  useEffect(() => {
    if (imgUploadState?.uploadCompleted) {
      setCurrentFile(null);
    }
  }, [imgUploadState?.uploadCompleted]);

  useEffect(() => {
    if (theme.colors.tertiary.tertiary) {
      setNewColor(theme.colors.tertiary.tertiary);
    }
    setNewImg(domain.LoginBannerUrl);
    if (domain.LoginBannerUrl) {
      setRadioOpt(1);
    }
  }, [theme.colors.tertiary.tertiary, domain.LoginBannerUrl]);

  const progressPercent = useMemo(() => {
    if (imgUploadState?.sentSize && currentFile?.size) {
      const sentSize = imgUploadState?.sentSize || 0;
      const fileSize = currentFile?.size || 0;
      const total = sentSize > fileSize
        ? sentSize
        : fileSize;
      const sent = Math.min(sentSize, total);
      const percentage = Math.round((sent / total) * 100)
  
      return percentage;
    }

    return 0;
  }, [currentFile?.size, imgUploadState?.sentSize]);

  const isCustom = radioOpt === RadioOptions.Personalizado;
  const hasChangeColor = newColor !== theme.colors.tertiary.tertiary;
  const imgSending = imgUploadState?.isSending && imgUploadState.path === 'BannerLogin';
  const bannerActionLoading = currentAction === 'LoginBannerUrl' && loading && !imgSending;
  const colorActionLoading = currentAction === 'SecondaryColor' && loading;
  const OK = MultiDomainPersonalizationStatusEnum.ConfiguracaoConcluida === domain.Status;
  const hasPendency = !OK && newImg;

  return (
    <>
      <MainWrapperLeft>
        <div className="cardHeader">
          <CardTitle>Banner</CardTitle>
          <AntDivider />
            <Radio.Group 
              onChange={handleRadioChange} 
              value={radioOpt}
            >
              <Radio value={RadioOptions["Padrão"]}>
                {RadioOptions[RadioOptions["Padrão"]]}
              </Radio>
              <Radio value={RadioOptions["Personalizado"]}>
                {RadioOptions[RadioOptions["Personalizado"]]}
              </Radio>
            </Radio.Group>
            {isCustom && (
              <>
                <ImgWrapper isProgress="true">
                  {(currentFile || newImg) && (
                    <img 
                      src={loadImage(currentFile || newImg, true)} 
                      alt="Imagem Banner Preview" 
                    />
                  )}
                  {imgSending && (
                    <>
                      <Progress 
                        percent={progressPercent} 
                        size={[160, 5]} 
                        showInfo={false}
                        trailColor={theme.colors.surface.surface}
                        strokeColor={theme.colors.primary.primary}
                        className="progressBar"
                      />
                      <div className="progressMask" />
                    </>
                  )}
                </ImgWrapper>
                <InfoTextBold>
                  Carregar um arquivo
                </InfoTextBold>
                <UploadCropImg aspect={300 / 500} onChange={handleImageChange}/>
                <InfoText>
                  O tamanho recomendado para o banner é de 300 pixels por 500 pixels.
                </InfoText>
              </>
            )}
            {!isCustom && (
              <ImgWrapper>
                <img 
                  src={loadImage(defaultInfoTheme.LoginBannerUrl, true)} 
                  alt="Imagem Banner Padrão Coordly" 
                />
              </ImgWrapper>
            )}
            {(isCustom || (!isCustom && newImg)) && (
              <>
                <AntDivider />
                <ButtonsAction 
                  disabledConfirm={isCustom && !currentFile}
                  loading={bannerActionLoading}
                  onConfirm={handleUpdateImage}
                  onCancel={handleUpdateCancelImage}
                />
              </>
            )}
        </div>

        <div className="cardMain">
          <CardTitle>Cores da navegação</CardTitle>
          <AntDivider />
          <CardSubTitle>Cor de Fundo</CardSubTitle>
          <ColorPicker newColor={newColor} onChange={handleChange} />
          {hasChangeColor && (
            <ButtonsAction 
              loading={colorActionLoading}
              marginTop={15}  
              onConfirm={handleUpdateColor}
              onCancel={handleUpdateCancelColor}
            />
          )}
        </div>
      </MainWrapperLeft>

      <Divider />

      <MainWrapperRight> 
        <div className="fixedContainer">
          <SvgCardBanner newColor={newColor} haveLogo={`${!!domain?.LogoUrl}`}>
            <LoginPreviewBackground />
            {(currentFile || newImg) ? (
              <img 
                className="bannerImage"
                src={loadImage(currentFile || newImg, true)} 
                alt="Imagem Banner Preview"
              />
            ) : (
              <img 
                className="bannerImage"
                src={loadImage(defaultInfoTheme.LoginBannerUrl, true)} 
                alt="Imagem Banner Padrão Coordly" 
              />
            )}
            {domain?.LogoUrl ? (
              <>
                <img 
                  className="logoImage"
                  src={loadImage(domain.LogoUrl, true)} 
                  alt="Imagem Logo Preview"
                />
                <img 
                  className="poweredByImage"
                  src={poweredBy} 
                  alt="Decorative powered by logo"
                />
              </>
            ) : null}
          </SvgCardBanner>
          {hasPendency && (
            <WarningCard>
              <div className="iconWarningWrapper">
                <Icon
                  icon="aviso"
                  className="warningIcon"
                  customSize={18}
                />
              </div>
              <div className="textWarningWrapper">
                <span className="warningTextBold">
                  Configuração do domínio pendente
                </span>
                <span className="warningText">
                  A página não está disponível pois a configuração do domínio personalizado ainda não foi finalizada. Acesse a página de <span className="warningAction" onClick={() => onActiveTab(tabOptions[2])}>Domínio Personalizado</span> para concluir a configuração.
                </span>
              </div>
            </WarningCard>
          )}
        </div>
      </MainWrapperRight>
    </>
  )
}

export default LoginScreenTab;
