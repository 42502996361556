import styled from "styled-components";
import foto from '../../../../Static/images/fundo-personalization-blur.png';

export const TagWrapper = styled.div`
  background-image: url(${foto});
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vh - 203px);
  display: flex;
  width: 100%;

  .tag-popularity {
    position: absolute;
    top: -3px;
    right: -3px;
  }

  .wrapper {
    position: relative;
    padding: 50px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 56px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    margin: auto;

    .imageWrapper {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .contentWrapper {
      width: 100%;
      margin-left: 42px;
      font-family: "Open Sans", sans-serif;

      .avisoLimiteObras {
        width: auto;
        color: #E2574C;
        border: 2px solid #E2574C;
        border-radius: 25px;
        padding: 8px 18px;
        font-weight: 600;
        font-size: 12px;
      }

      h2 {
        font-weight: 700;
        font-size: 22px;
        margin-top: 32px;
        line-height: 33px;
        color: #324150;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-top: 6px;
        color: #8697a8;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      p {
        color: #BEC2C6;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 0;
      }

      .icons-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        
        .icons-text-margin {
          margin-right: 23px;
        }

        .icons-text-wrapper {
          div {
            display: flex;
            align-items: center;
            
            &+div {
              margin-top: 16px;
            }

            i {
              margin-right: 12px;
            }

            span {
              color: #8697A8;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
            }
          }
        }
      }

      .button-wrapper{
        display: flex;
        margin-top: 48px;

        .button-confirm {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          color: #324150;
          line-height: 16px;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 0;
          }
        }
      }
    }

    .contentWrapperPro {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
