import React, { FC } from 'react';
import { Http } from '../../../Utils/Http';
import { history } from '../../../Store';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import { TenantActions } from '../../../Store/Tenant/Tenant.actions';
import { ILoginResponse } from '../../../Data/interfaces/Auth/ILoginResponse';

const ExternalAuth: FC = () => {
  const dispatch = useDispatch();
  const token = Http.GetQueryParams('access_token');
  if (token) {
    const loginData: ILoginResponse = {
      access_token: token,
      refresh_token: Http.GetQueryParams('refresh_token') || '',
      expires_in: +(Http.GetQueryParams('expires_in') || 0),
      beforeUrl: Http.GetQueryParams('redirectUrl') || '/obras'
    };

    dispatch(AuthActions.userLoginSuccess(loginData));
    dispatch(TenantActions.getTenantRoles());
    dispatch(TenantActions.getTenantUsers());

    const url = loginData.beforeUrl
      ?.replace('/login', '/obras')
      .replace('/login/', '/obras');
    if (url) {
      window.location.href = url;
    } else {
      history.push('/obras');
    }
  }

  return <></>;
};

export default ExternalAuth;
