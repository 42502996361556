import { FC } from 'react';
import { ButtonProps } from 'antd';
import { StyledButton } from './styles';

export interface IButtonProps extends ButtonProps {
  height?: number;
  width?: number | string;
  ispremium?: string;
  customText?: boolean;
  isShared?: boolean;
  domainloading?: string;
  primaryDanger?: boolean;
}

const Button: FC<IButtonProps> = (props) => {
  return (
    <StyledButton
      {...props}
      style={{
        height: props.height ?? 37,
        width: props.width,
      }}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;
