import React, { FC } from 'react';
import Table from '../../../Components/UI/Table';
import ColumnHeader from '../../../Components/UI/Table/ColumnHeader';
import { FilePlotagemStatusEnumLabel, IArquivoData, FilePlotagemStatusEnumColor } from '../../../Data/interfaces/Plotagem/IArquivoData';
import { getColor } from '../../../Utils/Color';
import logoMaletaCollapse from '../../../Static/images/logo-maleta-collapse.png';

import styles from './PlotagemRelatorioArquivos.module.scss';

export interface IRelatorioPlotagem {
    arquivosPrint: IArquivoData[],
    disciplineName?: string,
    obraName?: string
}

const columnsTable = () => {
    return [
        {
            title: (<ColumnHeader
                title={'Arquivo'}
            />),
            dataIndex: 'Name',
        },
        {
            title: (<ColumnHeader
                title={'Qtd'}
            />),
            dataIndex: 'QtyCopies',
        },
        {
            title: (<ColumnHeader
                title={'Solicitado'}
            />),
            dataIndex: 'RequestDate',
            render: (text: string, record: any) => {
                return (
                    <>
                        { new Date(text).toLocaleDateString()}
                    </>
                );
            },
        },
        {
            title: (<ColumnHeader
                title={'Solicitante'}
            />),
            dataIndex: 'PlotterResponsibleName',
        },
        {
            title: (<ColumnHeader
                title={'Versão'}
            />),
            dataIndex: 'Revision',
        },
        {
            title: (<ColumnHeader
                title={'Recebido'}
            />),
            dataIndex: 'UpdateDate',
            render: (text: string, record: any) => {
                return (
                    <>
                        { record.Status == 0 ? '' : new Date(text).toLocaleDateString()}
                    </>
                );
            },
        },
        {
            title: (
                <ColumnHeader
                    title={'Status'}
                />
            ),
            className: styles['collumnStatus'],
            dataIndex: 'Status',
            render: (status: number, record: any) => {
                return <div className={styles['collumnStatus']}>{getStatusValue(status)}</div>;
            },
        }
    ]
};

const getStatusValue = (status: number) => {
    const color = FilePlotagemStatusEnumColor[status];
    return <>
        <div className={styles['flagStatus']} style={{ backgroundColor: getColor(color) }}></div>
        <span style={{color: getColor(color)}}>{FilePlotagemStatusEnumLabel[status]}</span>
    </>;
}

const getDateNow = () => {
    const meses = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
    ];

    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');

    var formattedDate = `${dd} ${meses[date.getMonth()].toUpperCase()} ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
    return formattedDate;
}

const PlotagemRelatorioArquivos: FC<IRelatorioPlotagem> = (props) => {

    return (
        <div className={styles['containerWrapper']}>
            <div className={styles['headerWrapper']}>
                <h3>RELATÓRIO DE PLOTAGEM - OBRA {props.obraName}</h3>
                <p>{props.disciplineName}</p>
            </div>
            <Table className={styles['tableWrapper']}
                columns={columnsTable()}
                dataSource={props.arquivosPrint.map((discipline: any, index: number) =>
                    ({ ...discipline, key: index }))} />
            <div className={styles['footerWrapper']}>
                <div className={styles['imgFooter']}>
                    <img src={logoMaletaCollapse}></img>
                    <p>Coordly</p>
                </div>
                <div className={styles['hourFooter']}>
                    <p>{getDateNow()}</p>
                </div>
            </div>
        </div>)
}

export default PlotagemRelatorioArquivos;
