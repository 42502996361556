import styled, { css } from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 52px;
`;

interface IImgCard {
  isprogress?: string;
  haserror?: string;
}

export const ImgCard = styled.div<IImgCard>`
  position: relative;
  width: 205px;
  height: 192px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 12px;
  
  .image {
    position: relative;
    width: 205px;
    height: 128px;
    
    img {
      width: 205px;
      min-width: 205px;
      max-width: 205px;
      height: 128px;
      min-height: 128px;
      max-height: 128px;
      object-fit: contain;
      z-index: 1;
    }
    
    .progressBar {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      margin-bottom: 0;
      z-index: 2;
    }
  
    ${({ isprogress }) => isprogress === 'true' && css`
      .progressMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
        z-index: 3;
      }
    `}
    
    ${({ haserror }) => haserror === 'true' && css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 205px;
      min-height: 128px;
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.danger.dangerContainer}, ${theme.colors.surface.surface})`};
    `}
  }

  .imgContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-left: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    padding: 6px;

    .imgTextContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: normal;
      overflow: hidden;

      .textName {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px; 
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }

      .textSize {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; 
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
        margin-top: 4px;
      }

      .imgTextCreatorContent {
        height: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 4px;

        .textCreator {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; 
          color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
          margin-left: 6px;
        }
      }

      ${({ haserror }) => haserror === 'true' && css`
        .textName {
          color: ${({ theme }) => theme.colors.danger.danger};
        }
        .textSize {
          color: ${({ theme }) => theme.colors.danger.danger};
        }
      `}
    }
  }
  
  .buttonCancelOrDelete {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    z-index: 3;
    display: none;

    .iconCancelOrDelete {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }
  }
  
  .btnOpenActionAttachment {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 0;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .iconOpenActionAttachment {
      color: ${({ theme }) => theme.colors.secondary.onSecondaryVariant} !important;
    }

    :focus {
      border: none !important;
    }
  }

  .btnOpenActionAttachment {
    display: none;
  }

  :hover {
    .buttonCancelOrDelete {
      display: flex;
    }

    .btnOpenActionAttachment {
      display: flex;
    }

    .ActionAttachmentDropdown {
      display: flex;
    }

    background-color: ${({theme}) => `color-mix(in srgb, ${theme.colors.surface.onSurfacePressed}, ${theme.colors.surface.surface})`} !important;
  }
`;
