import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Left = styled.div`
  width: 100%;
  max-width: 206px;
  height: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  border-right: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  overflow: auto;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-left: 12px;
  }
`;

interface IChildren {
  isactive?: boolean;
}

export const Children = styled.div<IChildren>`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  .titleChildren {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-left: 12px;
  }

  ${({ isactive, theme }) => isactive && css`
    background-color: ${`color-mix(in srgb, ${theme.colors.primary.primaryContainer}, ${theme.colors.surface.surface})`} !important;
  `}
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin: 6px 0;
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

export const Top = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  padding-bottom: 16px;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-top: 6px;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  padding-bottom: 12px;
  margin-top: 16px;

  .checkbox {
    display: flex;
    align-items: center;
    height: 22px;
    padding-right: 16px;
  }

  .columnSigla {
    display: flex;
    align-items: center;
    height: 22px;
    width: 66px;
    padding-left: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .columnEtapa {
    display: flex;
    align-items: center;
    height: 22px;
    width: 100%;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 415px;
  max-height: 415px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  overflow-y: scroll;
  padding-bottom: 6px;
`;

interface IBodyContent {
  notop?: boolean;
}

export const BodyContent = styled.div<IBodyContent>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: ${({ notop }) => notop ? 0 : 6}px;

  .title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-left: 48px;
    margin-top: 6px;
  }

  .row {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;

    .checkbox {
      height: 36px;
      display: flex;
      align-items: center;
      padding-right: 16px;
    }

    .rightRow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
      padding: 0 16px;

      .columnSigla {
        width: 48px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }  

      .columnEtapa {
        width: 100%;
        padding-left: 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }  
      
      .tagDefault {
        border-radius: 4px;
        padding: 2px 4px;
        margin-left: 6px;
        background-color: ${({ theme }) => theme.colors.secondary.secondary};

        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-top: 16px;

  .textLeft {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
  }

  .btnFooter {
    height: 32px !important;
    display: flex;
    align-items: center;

    .textBtn {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.primary.onPrimary};
    }
  }

  .btnFooterDanger {
    margin-left: 16px;
  }
`;
