import { FC } from 'react';
import ImgBolasFundo from '../../../Static/images/bolinhas-fundo-login.png';
import ImgBolasTopo from '../../../Static/images/bolinhas-topo-login.png';
import { BackgroundBlue, MenuWrapper } from './styles';

interface IBackground {
  loading?: boolean;
}

const Background: FC<IBackground> = ({ children, loading }) => {
  return (
    <>
      <BackgroundBlue loading={`${loading}`} />
      <MenuWrapper>
        <div className="center">
          {children}
          <img src={ImgBolasTopo} className="bolinhasFundo" />
          <img src={ImgBolasFundo} className="bolinhasTopo" />
        </div>
      </MenuWrapper>
    </>
  );
};

export default Background;
