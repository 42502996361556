import React, { FC } from "react";
import { Icon } from "../Icon";
import styles from "./CustomEmptyData.module.scss";

export interface ICustomEmptyData {
  title?: string;
  subtitle?: string;
}

const CustomEmptyData: FC<ICustomEmptyData> = ({
  title,
  subtitle,
}) => {
  return (
    <div className={styles['mainWrapper']}>
      <div className={styles['wrapperContentTop']}>
        <div className={styles['spanContent']} />
        <Icon icon="buscar" color="primary" size="xxs" />
      </div>
      <div className={styles['wrapperContent']}>
        <div className={styles['spanContent']} style={{ background: '#3547C8' }} />
      </div>
      <div className={styles['wrapperContent']}>
        <div className={styles['spanContent']} />
      </div>
      <div className={styles['wrapperContent']}>
        <div className={styles['spanContent']} />
      </div>
      <div className={styles['wrapperContentBottom']}>
        <span className={styles['textBottomHead']}>
          {title}
        </span>
        <span className={styles['textBottomFooter']}>
          {subtitle}
        </span>
      </div>
    </div>
  )
}

export default CustomEmptyData;
