import { FC } from "react";
import { Container, Left } from "./styles";
import Button from "Components/UI/Button";
import { Mixpanel } from "Utils/MixPanel";
import { history } from "Store";
import { useSelector } from "react-redux";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { Icon } from "Components/UI";

interface TCallToAction {
  userInfo?: IUserInforResponse;
}

const CallToAction: FC<TCallToAction> = ({
  userInfo,
}) => {
  const currentTenant = useSelector(getCurrentTenant);
  const currentRole = userInfo?.CurrentRoleFk;

  const openUpgradePremium = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'convidar-usuarios-modal',
        originPath: window.location.pathname
      },
      userInfo: userInfo,
      currentListTenant: currentTenant,
    });

    if (currentRole === 1) {
      history.push('/faturamento/planos')
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  return (
    <Container>
      <Left>
        <span className="title">
          {currentRole === 1
            ? 'Atenção: você atingiu o limite de usuários gratuitos!'
            : 'Atenção: sua empresa atingiu o limite de usuários gratuitos!'
          }
        </span>
        <span className="text">
          {currentRole === 1
            ? 'Dê um upgrade na sua conta gratuita para convidar mais usuários'
            : 'Dê um upgrade na conta da sua empresa gratuita para convidar mais usuários'
          }
        </span>
      </Left>
      <Button
        ispremium="true"
        className="upBtn"
        onClick={openUpgradePremium}
      >
        <Icon
          icon="crown"
          customSize={14}
          className="crownIcon"
        />
        <span className="text">Fazer upgrade</span>
      </Button>
    </Container>
  )
}

export default CallToAction;