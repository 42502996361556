import * as Yup from 'yup';
import { VersionFieldEnum } from 'Data/interfaces/Nomenclatura/INomenclatureData';

export const createSchema = (
  versionFields: any[],
  hasNomenclatureControl?: boolean,
  stages?: string[],
) => {
  const fields: any = {};

  if (hasNomenclatureControl) {
    versionFields?.forEach((field) => {
      let validation = Yup.string().required('O campo deve ser preenchido');

      if (field.CampoVersaoId === VersionFieldEnum.Revisao) {
        validation = validation.matches(/\d+/g, 'Sem número');
      }

      if (stages && field.CampoVersaoId === VersionFieldEnum.Fase) {
        validation = validation.oneOf(stages, ' ');
      }

      fields[`field${field.Ordem}`] = validation;
    });
  }
  else {
    fields['simpleFileName'] = Yup.string().required('O campo deve ser preenchido');
  }

  return Yup.object().shape(fields);
};
