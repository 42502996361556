import { FC, useMemo, useState } from "react";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import PageHeader from "Components/UI/PageHeader/PageHeader";
import BreadCrumb, { IBreadcrumbItem } from "Components/UI/BreadCrumb";
import OptionsTabs, { IActiveTab } from "./components/OptionsTabs";
import { 
  HeaderBreadcrumb, 
  HeaderWrapper, 
  MainWrapper, 
  Wrapper,
} from "./styles";
import AppearanceTab from "./components/AppearanceTab";
import LoginScreenTab from "./components/LoginScreenTab";
import useWindowSize from "Hooks/useWindowSize";
import CustomDomainTab from "./components/CustomDomainTab";
import { AuthUtils } from "Utils/AuthUtils";
import PersonalizationCTAUpgrade from "./components/PersonalizationCTAUpgrade";
import { useFeatureFlag } from "Hooks/useFeatureFlag";
import { history } from "Store";
import { Mixpanel } from "Utils/MixPanel";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

export const tabOptions: IActiveTab[] = [
  {
    id: 'appearance',
    label: 'Aparência',
    icon: 'criativy',
  },
  {
    id: 'loginScreen',
    label: 'Tela de Login',
    icon: 'person',
  },
  {
    id: 'customDomain',
    label: 'Domínio Personalizado',
    icon: 'globe',
  },
];

interface IPersonalization {}

const Personalization: FC<Props> = ({
  isCollapsed,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string') 
    ? whatsappContactLinkFlag.value 
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const tenantFk = AuthUtils.getLoggedUser()?.currentTenant;
  const { height } = useWindowSize();
  
  const [activeTab, setActiveTab] = useState<IActiveTab>(tabOptions[0]);
  
  const breadCrumbs = useMemo(() => {
    const data: IBreadcrumbItem[] = [
      {
        href: '/config/personalization',
        description: 'Configurar serviço',
      },
      {
        href: '/config/personalization',
        description: 'Personalização',
      },
      {
        href: `/config/personalization`,
        description: activeTab.label,
      },
    ];

    return data;
  }, [activeTab]);

  const handleActiveTab = (tab: IActiveTab) => {
    setActiveTab(tab);
  }

  const upgradePlanRedirect = (role: number, planOrder: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION', 
      props: {
        origin: 'personalization',
        originPath: window.location.pathname
      },
      userInfo,
      currentListTenant: currentTenant,
    });

    if (role === 1) {
      if (planOrder === 4) {
        window.open(whatsappContactLink);
      } else {
        history.push('/faturamento/planos');
      }

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const isAppearanceTab = activeTab.id === 'appearance';
  const isLoginScreen = activeTab.id === 'loginScreen';
  const isCustomDomain = activeTab.id === 'customDomain';
  const isEnterprise = userInfo?.UserStoreItem?.StorePlan?.Order 
  && userInfo?.UserStoreItem?.StorePlan?.Order >= 4;

  return (
    <Wrapper>
      <PageHeader
        name={'Personalização'}
      >
        <HeaderBreadcrumb>
          <BreadCrumb breadcrumbs={breadCrumbs} />
        </HeaderBreadcrumb>
        {!isEnterprise && (
          <PersonalizationCTAUpgrade 
            onConfirm={upgradePlanRedirect}
            order={userInfo?.UserStoreItem.StorePlan?.Order}
            role={userInfo?.CurrentRoleFk}
          />
        )}
        {isEnterprise && (
          <>
            <HeaderWrapper>
              <OptionsTabs 
                activeTab={activeTab} 
                options={tabOptions} 
                onActiveTab={handleActiveTab}
              />
            </HeaderWrapper>
            <MainWrapper isCollapsed={isCollapsed} maxHeightCalc={height}>
              {isAppearanceTab && (
                <AppearanceTab 
                  dispatch={dispatch}
                  tenantFk={tenantFk}
                />
              )}
              {isLoginScreen && (
                <LoginScreenTab 
                  dispatch={dispatch}
                  tenantFk={tenantFk}
                  onActiveTab={handleActiveTab}
                />
              )}
              {isCustomDomain && (
                <CustomDomainTab 
                  dispatch={dispatch}
                  tenantFk={tenantFk}
                />
              )}
            </MainWrapper>
          </>
        )}
      </PageHeader>
    </Wrapper>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  isCollapsed: state.obra.isCollapsed,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPersonalization;

export default connector(Personalization);
