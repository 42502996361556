import { FC, useState } from 'react';

import { Icon } from '../../../UI';
import { useDispatch } from 'react-redux';
import { TenantActions } from '../../../../Store/Tenant/Tenant.actions';
import Ellipsis from 'Components/UI/Ellipsis';
import useHistoryPush from 'Hooks/useHistoryPush';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';
import { Popover } from 'antd';
import { GetAvatar } from 'Utils/generateThumbnail';
import { DropdownStyled, MenuDropdown, MenuItemStyled } from './styles';

export interface IMenuItemContext {
  currentTenant?: ITenantData;
  tenants?: ITenantData[];
  isCollapsed: boolean;
  toggleMenuContext?: boolean;
}

const MenuItemContext: FC<IMenuItemContext> = (
  {
    currentTenant,
    tenants,
    isCollapsed,
    toggleMenuContext,
  },
) => {
  const dispatch = useDispatch();
  const { historyPush } = useHistoryPush();

  const [visible, setVisible] = useState<boolean>();

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const changeContext = (path: string) => {
    if (path) {
      dispatch(TenantActions.toggleMenuContext(false));
      historyPush(path);
    } else {
      dispatch(TenantActions.toggleMenuContext(!toggleMenuContext));
    }
  };

  const onlyCurrentTenant = () => {
    if (tenants?.length === 1) {
      return true;
    }
    return false;
  }

  const overlay = (
    <MenuDropdown>
      {tenants && tenants.map((tenant, i) => {
        const active = currentTenant?.TenantId === tenant.TenantId ? true : false;
        
          return (
            <MenuItemStyled
              key={`item-menu-${i}`} 
              active={`${active}`}
              onClick={() => changeContext(`/construtora/${tenant.TenantId}`)}
            >
              <span className="iconItem">
                <GetAvatar
                  name={tenant.CompanyName}
                  shape='square'
                  size={28}
                  src={tenant.OriginalImageUrl || tenant.AvatarFileName}
                  thumbSrc={tenant.OriginalImageUrl?.replace('/images/', '/thumbnails/')}
                  thumbType="small"
                />
              </span>
              <span className="textItem">
                <span>
                  <Ellipsis phrase={tenant.CompanyName || ''} maxLength={25}></Ellipsis>
                </span>
                {active && (
                  <span>
                    <Icon 
                      icon="confirmacao" 
                      className="iconConfirmacao"
                    />
                  </span>
                )}
              </span>
            </MenuItemStyled>
          )
        })
      }
    </MenuDropdown>
  )

  return (
    <Popover
      content={overlay} 
      placement="bottomRight" 
      trigger="click"
      open={!onlyCurrentTenant() ? visible : false} 
      onOpenChange={(visible) => !onlyCurrentTenant() ? handleVisibleChange(visible) : undefined}
      overlayStyle={{position: 'absolute' , paddingTop: '0px', paddingLeft: '8px'}}
      overlayInnerStyle={{padding: 0, borderRadius: '4px !important', overflow: 'hidden'}}
    >
      <DropdownStyled 
        isCollapsed={`${isCollapsed}`}
        onlyCurrentTenant={`${!onlyCurrentTenant()}`}
        onClick={() => changeContext('')}
        visible={`${visible}`}
      >
        <span className="iconAvatar">
          <GetAvatar
            name={currentTenant?.CompanyName || ''}
            shape='square'
            size={28}
            src={currentTenant?.OriginalImageUrl || currentTenant?.AvatarFileName || ''}
            thumbSrc={currentTenant?.OriginalImageUrl?.replace('/images/', '/thumbnails/')}
            thumbType="small"
          />
        </span>
        <span className="textDropdown">
          <span className="titleMenu">
            <Ellipsis phrase={currentTenant?.CompanyName || ''} maxLength={25}/>
          </span>
        </span>
        {(!onlyCurrentTenant() && !isCollapsed) && (
          <span className="iconToggleWrap">
            <Icon 
              icon="expandir"
              customSize={10}
              className="iconChildrenTop"/>
            <Icon 
              icon="expandir"
              customSize={10}
              className="iconChildrenBottom"/>
          </span>
        )}
      </DropdownStyled>
    </Popover>
  );
};

export default MenuItemContext;
