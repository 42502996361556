import { FC, useEffect, useRef, useState } from "react";
import { actionFileDescription, contentFileIcon, filesContentOptions } from "../";
import { Icon } from "Components/UI";
import { GetAvatar } from "Utils/generateThumbnail";
import { formatDateFromNow, formatTitleHistoryDate } from "Utils/DateUtils";
import { ITopicStatus } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { IHistoryFile } from "Data/interfaces/History/IHistoryFileList";
import {
  DataBadgeContainer,
  Left as LeftH,
  Line as LineH,
} from "Components/HistoryDrawer/styles";
import {
  Container,
  Left,
  Right,
  RightHeader,
  RightContent,
  Line,
  Wrapper,
} from "./styles";

interface ICard {
  item: IHistoryFile;
  topicStatusOptions?: ITopicStatus[];
}

const Card: FC<ICard> = ({ item, topicStatusOptions }) => {
  const theme = useSelector(getTheme);

  const ref = useRef<HTMLDivElement | null>(null);
  const [height, seetHeight] = useState(48 / 2);

  useEffect(() => {
    if (ref.current) {
      seetHeight(ref.current.offsetHeight / 2)
    }
  }, []);

  return (
    <Wrapper>
      {item.titleOn && (
        <DataBadgeContainer first={item.first}>
          {!item.first && (<LeftH><LineH /></LeftH>)}
          <span className="text">{formatTitleHistoryDate(item.DateTimeTitle)}</span>
        </DataBadgeContainer>
      )}
      <Container ref={ref}>
        <Left>
          <Line height={height} istransparent={`${item.first}`} />
          <div className="iconContainer">
            <Icon
              icon={contentFileIcon[item.Action].icon}
              customSize={contentFileIcon[item.Action]?.size || 12}
              className="iconLeft"
            />
          </div>
          <Line height={height} istransparent={`${item.last}`} />
        </Left>

        <Right>
          <RightHeader>
            <span className="rightHeaderLeftText">
              {item.Author && (
                <>
                  <GetAvatar
                    src={item.Author.OriginalImageUrl}
                    thumbSrc={item.Author.ThumbnailImageUrl}
                    name={item.Author?.Nome || ''}
                    size={16}
                  />
                  <span className="name">{item.Author.Nome || ''}</span>
                </>
              )}
              <span className="action">{actionFileDescription[item.Action]}</span>
            </span>
            <span className="date">{formatDateFromNow(item.DateTimeUtcFormated)}</span>
          </RightHeader>
          <RightContent>
            {filesContentOptions[item.Action]({
              item,
              theme,
              activitiesStatusInfo: topicStatusOptions,
            })}
          </RightContent>
        </Right>
      </Container>
    </Wrapper>
  )
}

export default Card;
