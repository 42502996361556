import ColumnHeader from "Components/UI/Table/ColumnHeader";
import {
  ITopicDnDResponse,
  ITopicStatus,
  ITopicType,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { ColumnProps } from "antd/lib/table";
import { IDisciplineResponse } from "Data/interfaces/Obra/IDisciplineResponse";
import { IUserData } from "Data/interfaces/User/IUserData";
import { dateToLocaleString, isExpiredDate } from "Utils/DateUtils";
import OptionCustom from "Components/UI/NewFormGroup/OptionImg";
import { Icon } from "Components/UI";
import { handlePriority, handleVisibility } from "Utils/ActivitiesUtils";
import { getTypeIcon } from "Utils/ObraUtils";
import AvatarsItems from "Pages/Activities/components/AvatarsItems";
import DisciplinesTooltip from "Pages/Activities/components/DisciplinesTooltip";
import HighlighterWords from "Components/UI/HighlightWords";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import Tooltip from "Components/UI/Tooltip";
import { TypeOrderType } from "../../useListVision";
import ImagePreview from "Components/UI/ImagePreview";
import ImagePreviewGroup from "Components/UI/ImagePreviewGroup";
import HeaderActionsButtons from "Pages/Activities/Modals/ShowCreateActivity/components/Attachments/components/HeaderActionsButtons";
import { IImgsOnErrorControl } from "Data/interfaces/Helper/IImgsOnErrorControl";
import {
  actionImgPrint,
  actionDownloadImage,
  actionImgOpenInNewWindow,
} from "Utils/TextUtils";
import {
  ColumnDate,
  ColumnDisciplines,
  ColumnIdentifier,
  ColumnPriority,
  ColumnStatus,
  ColumnTitle,
  ColumnType,
  ColumnUsers,
} from "./styles";

import imgPlaceholder from 'Static/images/image_placeholder.webp';

interface IGetColumnsTableActivities {
  searchActivity: string;
  theme: IMDTheme;
  currentAttachmentIndex: number;
  imgsOnErrorControl: IImgsOnErrorControl;
  showPreview: Map<string, boolean>;
  onOpenShowActivity: (activity: string) => void;
  onOrderOpt: (orderType?: TypeOrderType, sorterOrder?: boolean) => void;
  setCurrentAttachmentIndex: (index: number) => void;
  onImgOnError: (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
    id: string,
    originalURL: string
  ) => void;
  onPreview: (id: string, visible: boolean) => void;
}

export const getColumnsTableActivities: (input: IGetColumnsTableActivities) => Array<ColumnProps<any>> = ({
  searchActivity,
  theme,
  currentAttachmentIndex,
  imgsOnErrorControl,
  showPreview,
  onOpenShowActivity,
  onOrderOpt,
  setCurrentAttachmentIndex,
  onImgOnError,
  onPreview,
}) => {
  return [
    {
      dataIndex: 'Identifier',
      width: 70,
      title: (
        <ColumnHeader
          title="Cód."
          sorter={(sortDirection) => onOrderOpt('Identifier', sortDirection)}
        />
      ),
      render: (text: string, record: ITopicDnDResponse) => (
        <ColumnIdentifier>{`#${text}`}</ColumnIdentifier>
      ),
    },
    {
      dataIndex: 'Visibility',
      width: 54,
      title: (
        <ColumnHeader
          title="V."
          showOrder={false}
        />
      ),
      render: (text: number, record: ITopicDnDResponse) => (
        <span>
          <Icon
            icon={handleVisibility(text, theme).icon}
            customSize={handleVisibility(text, theme).size}
            customColor={handleVisibility(text, theme).color}
          />
        </span>
      ),
    },
    {
      dataIndex: 'Title',
      width: 'auto',
      title: (
        <ColumnHeader
          title="Resumo"
          sorter={(sortDirection) => onOrderOpt('Title', sortDirection)}
        />
      ),
      render: (text: string, record: ITopicDnDResponse) => {
        const currentAttachmentPreview = record.Attachments[currentAttachmentIndex];

        return (
          <ColumnTitle>
            <span className="titleResume" onClick={() => onOpenShowActivity(record.Guid)}>
              <HighlighterWords
                text={text}
                wordSearched={searchActivity}
                className="highlighterWords"
              />
            </span>
            <span className="titleContent">
              {record?.Attachments && record.Attachments.length > 0 && (
                <>
                  {record.Attachments.length === 1 && (
                    <div className="singleImgContainer">
                      <ImagePreview
                        isUnique
                        srcPreview={record.Attachments[0].FileURL}
                        className="img"
                        src={record.Attachments[0].Thumbnail64x64Url}
                        srcName={record.Attachments[0].File.Name}
                        alt=""
                        onError={(e) => e.currentTarget.src = imgPlaceholder}
                        HeaderButtons={(
                          <HeaderActionsButtons
                            onPrint={() => {
                              actionImgPrint(record.Attachments[0].FileURL);
                            }}
                            onDownload={() => {
                              actionDownloadImage(record.Attachments[0].FileURL, record.Attachments[0].File.Name);
                            }}
                            onOpen={() => {
                              actionImgOpenInNewWindow(record.Attachments[0].FileURL);
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {record.Attachments.length > 1 && (
                    <ImagePreviewGroup
                      imagesForShow={record.Attachments.map(att => att.FileURL)}
                      srcName={currentAttachmentPreview?.File.Name}
                      visible={showPreview.get(record.Guid)}
                      visibleOn
                      onChangeVisible={(visible) => onPreview(record.Guid, visible)}
                      current={currentAttachmentIndex}
                      HeaderButtons={(
                        <HeaderActionsButtons
                          onPrint={() => {
                            if (currentAttachmentPreview) {
                              actionImgPrint(currentAttachmentPreview.FileURL);
                            }
                          }}
                          onDownload={() => {
                            if (currentAttachmentPreview) {
                              actionDownloadImage(currentAttachmentPreview.FileURL, currentAttachmentPreview.File.Name);
                            }
                          }}
                          onOpen={() => {
                            if (currentAttachmentPreview) {
                              actionImgOpenInNewWindow(currentAttachmentPreview.FileURL);
                            }
                          }}
                        />
                      )}
                      onCurrentIndex={setCurrentAttachmentIndex}
                    >
                      <div className="multipleImgContainer" onClick={() => onPreview(record.Guid, true)}>
                        <img
                          className="img"
                          src={record.Attachments[0].Thumbnail64x64Url}
                          alt=""
                          onError={(e) => e.currentTarget.src = imgPlaceholder}
                        />
                        <img
                          className="img"
                          src={record.Attachments[1].Thumbnail64x64Url}
                          alt=""
                          onError={(e) => e.currentTarget.src = imgPlaceholder}
                        />
                        <span className="imgCount">
                          <span className="textCount">
                            {`+${record.Attachments.length - 1}`}
                          </span>
                        </span>
                      </div>
                    </ImagePreviewGroup>
                  )}
                </>
              )}
              {record.ConstructionSiteDisciplines.length <= 0 && (
                <Tooltip
                  placement="top"
                  overlay="Erro no cadastro da atividade"
                >
                  <span>
                    <Icon
                      icon="aviso"
                      customSize={14}
                      className="cardIconAlarm"
                    />
                  </span>
                </Tooltip>
              )}
            </span>
          </ColumnTitle>
        )
      },
    },
    {
      dataIndex: 'Type',
      width: 160,
      title: (
        <ColumnHeader
          title="Tipo"
          sorter={(sortDirection) => onOrderOpt('Type', sortDirection)}
        />
      ),
      render: (text: ITopicType, record: ITopicDnDResponse) => (
        <ColumnType>
          <Icon
            icon={getTypeIcon(text.Name || '')}
            customSize={14}
            className="cardIconHeader"
          />
          <span className="text">{text.Name}</span>
        </ColumnType>
      ),
    },
    {
      dataIndex: 'Priority',
      width: 100,
      title: (
        <ColumnHeader
          title="Priorid."
          sorter={(sortDirection) => onOrderOpt('Priority', sortDirection)}
        />
      ),
      render: (text: number, record: ITopicDnDResponse) => (
        <ColumnPriority customcolor={handlePriority(text, theme).color}>
          <Icon
            icon={handlePriority(text, theme).icon}
            customSize={handlePriority(text, theme).size}
            customColor={handlePriority(text, theme).color}
          />
          <span className="text">{handlePriority(text, theme).label}</span>
        </ColumnPriority>
      ),
    },
    {
      dataIndex: 'ConstructionSiteDisciplines',
      width: 175,
      title: (
        <ColumnHeader
          title="Disciplinas"
          sorter={(sortDirection) => onOrderOpt('ConstructionSiteDisciplines', sortDirection)}
        />
      ),
      render: (text: IDisciplineResponse[], record: ITopicDnDResponse) => (
        <ColumnDisciplines>
          <DisciplinesTooltip title="Disciplinas" disciplines={text} />
        </ColumnDisciplines>
      ),
    },
    {
      dataIndex: 'TopicAssignedUsers',
      width: 125,
      title: (
        <ColumnHeader
          title="Responsáveis"
          sorter={(sortDirection) => onOrderOpt('TopicAssignedUsers', sortDirection)}
        />
      ),
      render: (text: IUserData[], record: ITopicDnDResponse) => (
        <ColumnUsers>
          <AvatarsItems users={text} showDetail />
        </ColumnUsers>
      ),
    },
    {
      dataIndex: 'DueDate',
      width: 100,
      title: (
        <ColumnHeader
          title="Prazo"
          sorter={(sortDirection) => onOrderOpt('DueDate', sortDirection)}
        />
      ),
      render: (text: Date | null, record: ITopicDnDResponse) => (
        text ? (
          <ColumnDate error={`${isExpiredDate(text)}`}>
            {dateToLocaleString(text)}
          </ColumnDate>
        ) : (
          <></>
        )
      ),
    },
    {
      dataIndex: 'Status',
      width: 127,
      title: (
        <ColumnHeader
          title="Status"
          sorter={(sortDirection) => onOrderOpt('Status', sortDirection)}
        />
      ),
      render: (text: ITopicStatus, record: ITopicDnDResponse) => (
        <ColumnStatus>
          <OptionCustom
            label={text.Name}
            bgColor={`${text.Color}26`}
            textColor={`${text.Color}`}
          />
        </ColumnStatus>
      ),
    }
  ];
};
