import React, { Dispatch, ReactNode } from 'react';
import { PlotagemActions, PlotagemActionUnion } from '../../../Store/Plotagem/Plotagem.actions';

import { IArquivoData, FilePlotagemStatusEnum, IArquivoDataResponse } from '../../../Data/interfaces/Plotagem/IArquivoData';
import { history } from '../../../Store';

import { prop } from '../../../Utils/toolBox';
import { datetimeToString } from '../../../Utils/DateUtils';
import { GetAvatar } from '../../../Utils/generateThumbnail';

import { ITableActionTopbarButton } from '../../../Components/UI/TableActionTopbar';
import { Icon } from '../../../Components/UI';
import { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';

import Tooltip from '../../../Components/UI/Tooltip';
import { iconFileByExtension } from '../../../Utils/Icons';
import { ColumnProps } from 'antd/lib/table';
import ColumnHeader from '../../../Components/UI/Table/ColumnHeader';
import Tag from '../../../Components/UI/Tag';
import { ColorName } from '../../../Utils/Color';
import { FilePlotagemStatusEnumLabel, FilePlotagemStatusEnumColor } from '../../../Data/interfaces/Plotagem/IArquivoData';
import FilterModal, { ITipoOption } from './../Modals/FilterModal';
import { Menu } from 'antd';

import styles from './PlotagemArquivos.module.scss';
import { IListagemArquivosFilters } from '../../../Store/Plotagem/interfaces/IPlotagemState';
import { IBreadCrumb } from '../../../Data/interfaces/Plotagem/IBreadCrumb';
import { IPlotagemSolicitacaoFiles } from 'Data/interfaces/Plotagem/IPlotagemListResponse';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';

export interface IPlotagemArquivos {
  match: any
}

export interface IButtonsTableToolbar {
  selectedRowKeys: number[];
  arquivosPlotagem: IArquivoData[];
  dispatch: Dispatch<PlotagemActionUnion>;
}

export type TipoRelatorio = 'pdf' | 'excel';

export const breadCrumb: IBreadcrumbItem[] = [];

export const sortArquivosPlotagemList = (
  dataIndex: any,
  sortOrder: boolean,
  fileList: IArquivoData[],
  breadCrumArquivos: IBreadCrumb,
  dispatch: Dispatch<PlotagemActionUnion>
) => {
  const sortedFiles = [...fileList?.sort((a: IArquivoData, b: IArquivoData) => {
    const propA = prop(a, dataIndex) || '';
    const propB = prop(b, dataIndex) || '';

    if (typeof propA === 'number' && typeof propB === 'number') {
      return sortOrder ? propB - propA : propA - propB;
    }

    return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
  }) || []];

  const dataSortedFiles: IArquivoDataResponse = {
    data: sortedFiles,
    info: breadCrumArquivos,
  }

  dispatch(PlotagemActions.updatePlotagemArquivosList(dataSortedFiles));
};

export const solicitedTooltip = (text: string, record: IPlotagemSolicitacaoFiles) => (
  <div className={styles['tooltipModifiedBy']}>
    <div className={styles['title']}>
      SOLICITADO EM
    </div>
    <div className={styles['value']}>
      {datetimeToString(new Date(text))}
    </div>

    <div className={`${styles['title']} ${styles['margin-top']}`}>
      REALIZADA POR
    </div>
    <div>
      <GetAvatar name={record.PlotterRequestName} />
      <span className={`${styles['padding-left']} ${styles['value']}`}>
        {record.PlotterRequestName}
      </span>
    </div>

    <div className={`${styles['title']} ${styles['margin-top']}`}>
      LOCAL DE ENTREGA
    </div>
    <div>
      <div className={styles['nameCollumn']} style={{ display: 'flex', alignItems: 'center'}}>
        <Icon
          color='cinzaPadrao'
          icon='localizacao'
        />
        <span className={`${styles['padding-left']} ${styles['value']}`}>
          {record.PlottingAddress}
        </span>
      </div>
    </div>

    {record.Status === 1 || record.Status === 2 ?
      (<><div className={`${styles['title']} ${styles['margin-top']}`}>
        DATA DE RECEBIMENTO
      </div>
        <div className={`${styles['padding-left']} ${styles['value']}`}>
          {datetimeToString(new Date(record.UpdateDate))}
        </div></>)
      : ''}

  </div >
);

export const setTag = (text: string, color: ColorName) => (
  <Tag color={color}>{text}</Tag>
);

export const getFilterOptions = (fileList: any[], key: string, render?: (value: any) => string, validation?: (index: any) => boolean, renderNode?: (value: any) => ReactNode) => {
  const types: any = {};
  const filterList: ITipoOption[] = [];
  fileList?.forEach((file) => {
    if (file[key] === undefined || file[key] === null) { return; }

    const updateIndex = render ? render(file[key]) : file[key];
    if (types[updateIndex] === undefined && (!validation || validation && validation(updateIndex))) {
      types[updateIndex] = true;
      filterList.push({
        description: updateIndex.toString(),
        renderNode: renderNode && renderNode(updateIndex),
        value: updateIndex.toString(),
      });
    }
  });
  return filterList;
};

export const columnsTable = (arquivosPlotagem: IArquivoData[], filters: IListagemArquivosFilters, arquivosPlotagemBreadCrumb: IBreadCrumb, dispatch: React.Dispatch<any>, arquivosPlotagemPrint: IArquivoData[]): Array<ColumnProps<any>> => {
  return [
    {
      title: (<ColumnHeader
        title={'Solicitação'}
        sorter={(sortDirection: boolean) => sortArquivosPlotagemList('PlottingDescription', sortDirection, arquivosPlotagem, arquivosPlotagemBreadCrumb, dispatch)}
        showFilter={() => dispatch(PlotagemActions.setShowFilterDescription(true))}
      />),
      dataIndex: 'PlottingDescription',
      className: styles['columnPlottingDescription'],
      onFilter: (value: any, record: any) => (record.PlottingDescription === value),
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(arquivosPlotagem, 'PlottingDescription', (x: string) => x.trim());

        return <FilterModal
          width='300px'
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowFilterDescription(visible))} />;
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(PlotagemActions.setShowFilterDescription(visible)),
      filterDropdownVisible: filters.showDescription,
      render: (text, record) => (
        <div className={styles['nameCollumn']}>
          <Icon
            color='cinzaPadrao'
            icon={record.IsFolder
              ? 'arquivar'
              : iconFileByExtension(record.Extension)}
            customSize={18}
          />
          <span title={text} className={styles['requestName']} style={{ color: '#44566C', padding: '0 18px' }}>
            {text}
          </span>
        </div>
      ),
    },
    {
      title: (<ColumnHeader
        title={'Arquivo'}
        sorter={(sortDirection: boolean) => sortArquivosPlotagemList('Name', sortDirection, arquivosPlotagem, arquivosPlotagemBreadCrumb, dispatch)}
        showFilter={() => dispatch(PlotagemActions.setShowFilterArquivo(true))}
      />),
      dataIndex: 'Name',
      className: styles['columnfileCollumn'],
      onFilter: (value: any, record: any) => (record.Name === value),
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(arquivosPlotagem, 'Name', (x: string) => x.trim());

        return <FilterModal
          width='300px'
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowFilterArquivo(visible))} />;
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(PlotagemActions.setShowFilterArquivo(visible)),
      filterDropdownVisible: filters.showArquivo,
      render: (text, record) => (
        <span title={text} className={styles['fileName']}>
          {text}
        </span>
      ),
    },
    {
      title: (<ColumnHeader
        title={'Versão'}
        sorter={(sortDirection: boolean) => sortArquivosPlotagemList('Revision', sortDirection, arquivosPlotagem, arquivosPlotagemBreadCrumb, dispatch)}
        showFilter={() => dispatch(PlotagemActions.setShowVersions(true))}
      />),
      dataIndex: 'Revision',
      className: styles['columnRevision'],
      onFilter: (value: any, record: any) => (record.Revision === value.toLocaleUpperCase()),
      onFilterDropdownVisibleChange: (visible) => dispatch(PlotagemActions.setShowVersions(visible)),
      filterDropdownVisible: filters.showVersions,
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(arquivosPlotagem, 'Revision', (x: string) => x || '0');

        return <FilterModal
          width='200px'
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowVersions(visible))} />;
      },
    },
    {
      title: (<ColumnHeader
        title={'Solicitado'}
        sorter={(sortDirection: boolean) => sortArquivosPlotagemList('RequestDate', sortDirection, arquivosPlotagem, arquivosPlotagemBreadCrumb, dispatch)}
        showFilter={() => dispatch(PlotagemActions.setShowSolicitacao(true, true))}
      />),
      dataIndex: 'RequestDate',
      className: styles['columnRequestDate'],
      onFilter: (value: any, record: any) => {
        const requestDate = new Date(record.RequestDate).toLocaleDateString();
        return (requestDate === value || record.PlotterResponsibleName === value);
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(PlotagemActions.setShowSolicitacao(visible, false)),
      filterDropdownVisible: filters.showSolicitacao.show,
      filterDropdown: (propsFilters: any) => {
        if (filters.showSolicitacao.showData) {
          const showDataFilters = getFilterOptions(arquivosPlotagem, 'RequestDate', (v: any) => new Date(v).toLocaleDateString());

          return <FilterModal
            width='300px'
            propsFilter={propsFilters}
            tipoOptions={showDataFilters}
            showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowSolicitacaoData(visible))}
          />;
        }

        if (filters.showSolicitacao.showUsers) {
          const showUsersFilters = getFilterOptions(arquivosPlotagem, 'PlotterResponsibleName', undefined, (index) => index !== '-', (updateIndex) => (
            <div className={styles['filterSolicitadoPor']}>
              {updateIndex}
            </div>
          ));

          return <FilterModal
            width='300px'
            propsFilter={propsFilters}
            tipoOptions={showUsersFilters}
            showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowSolicitacaoUsers(visible))}
          />;
        }

        if (filters.showSolicitacao.showDropdown) {
          return <Menu className={styles['menu-container']}>
            <Menu.Item onClick={() => dispatch(PlotagemActions.setShowSolicitacaoData(true))}>
              <div className={`ant-menu-submenu ant-menu-submenu-vertical ${styles['menu']}`} >
                <p className={styles['text']}>Filtrar por data de solicitação</p>
                <div className={`ant-menu-submenu-title ${styles['icon']}`}>
                  <i className='ant-menu-submenu-arrow'></i>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(PlotagemActions.setShowSolicitacaoUsers(true))}>
              <div className={`ant-menu-submenu ant-menu-submenu-vertical ${styles['menu']}`} >
                <p className={styles['text']}>Filtrar por solicitante</p>
                <div className={`ant-menu-submenu-title`}>
                  <i className='ant-menu-submenu-arrow'></i>
                </div>
              </div>
            </Menu.Item>
          </Menu>
        }

        return <></>;
      },
      render: (text: string, record: any) => {
        return (
          <Tooltip overlay={solicitedTooltip(text, record)}>
            <span className={styles['solicitedDate']} >
              {new Date(text).toLocaleDateString()}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <ColumnHeader
          title={'Status'}
          sorter={(sortDirection: boolean) => sortArquivosPlotagemList('Status', sortDirection, arquivosPlotagem, arquivosPlotagemBreadCrumb, dispatch)}
          showFilter={() => dispatch(PlotagemActions.setShowFilterStatus(true))}
        />
      ),
      dataIndex: 'Status',
      className: styles['columnStatus'],
      onFilter: (value: any, record: any) => (record.Status !== null && record.Status.toString() === value),
      filterDropdown: (propsFilters: any) => {
        const showDataFilters = getFilterOptions(
          arquivosPlotagem,
          'Status',
          undefined,
          undefined,
          (x: number) => setTag(FilePlotagemStatusEnumLabel[x], FilePlotagemStatusEnumColor[x]));

        return <FilterModal
          hideSearch={true}
          propsFilter={propsFilters}
          tipoOptions={showDataFilters}
          showFilter={(visible: boolean) => dispatch(PlotagemActions.setShowFilterStatus(visible))}
        />;
      },
      onFilterDropdownVisibleChange: (visible) => dispatch(PlotagemActions.setShowFilterStatus(visible)),
      filterDropdownVisible: filters.showStatus,
      render: (status: number, record: any) => {
        status = status || 0;
        return (record.IsFolder ? '-'
          : setTag(FilePlotagemStatusEnumLabel[status], FilePlotagemStatusEnumColor[status]));
      },
    }
  ]
};

export const getButtonsTableToolbar = (
  selectedArquivos: never[], 
  arquivosPlotagem: IArquivoData[], 
  dispatch: React.Dispatch<any>, 
  ConstructionSiteDisciplineFk: number,
  constructionSiteId: number, 
  onRelatorio: ((tipoRelatorio: TipoRelatorio) => void), 
  isDownloading?: boolean,
  disciplineName?: string | null,
  userInfo?: IUserInforResponse,
  currentTenant?: ITenantData | null,
): ITableActionTopbarButton[] => {
  if (selectedArquivos && selectedArquivos.length) {
    return [
      {
        icon: 'trocarStatus',
        description: `Trocar status`,
        dropdownMenu: getDropdownStatusChange(selectedArquivos, arquivosPlotagem, dispatch, ConstructionSiteDisciplineFk, constructionSiteId, userInfo, currentTenant),
      },
      {
        icon: 'mover',
        description: `Receber de plotagem`,
        action: () => { history.push(`/obras/plotagens/filelist/receber/${constructionSiteId}/${ConstructionSiteDisciplineFk}${disciplineName ? `?folderName=${disciplineName}` : ''}`) }
      },
      {
        icon: 'excluir',
        action: () => { dispatch(PlotagemActions.setShowModalExcluiArquivosPlotagem(true)); }
      },
    ]
  }
  else {
    return [
      {
        icon: 'arroba',
        description: `Reenvio de solicitação via e-mail`,
        action: () => { dispatch(PlotagemActions.setShowModalReenviaSolicitacaoPlotagem(true)) }
      },
      {
        icon: 'solicitarPlotagem',
        description: `Imprimir relatório`,
        disabled: isDownloading,
        dropdownMenu: getDropdownImprimirRelatorio(dispatch, ConstructionSiteDisciplineFk, onRelatorio),
      },
      {
        icon: 'mover',
        description: `Receber de plotagem`,
        action: () => { history.push(`/obras/plotagens/filelist/receber/${constructionSiteId}/${ConstructionSiteDisciplineFk}${disciplineName ? `?folderName=${disciplineName}` : ''}`) }
      },
    ];
  }
};

const getDropdownStatusChange = (
  keys: number[], 
  listFiles: IArquivoData[], 
  dispatch: React.Dispatch<any>, 
  ConstructionSiteDisciplineFk: number, 
  constructionSiteId: number,
  userInfo?: IUserInforResponse,
  currentTenant?: ITenantData | null,
) => (
    <div className={styles['menuDropdown']} >
      <div
        className={styles['options']}
        onClick={() => changeStatusFiles(keys, listFiles, dispatch, FilePlotagemStatusEnum.NaoEntregue, ConstructionSiteDisciplineFk, constructionSiteId, userInfo, currentTenant)}>
        Não entregue
      </div>
      <div
        className={styles['options']}
        onClick={() => changeStatusFiles(keys, listFiles, dispatch, FilePlotagemStatusEnum.NoEscritorio, ConstructionSiteDisciplineFk, constructionSiteId, userInfo, currentTenant)}>
        No escritório
      </div>
      <div
        className={styles['options']}
        onClick={() => changeStatusFiles(keys, listFiles, dispatch, FilePlotagemStatusEnum.NaObra, ConstructionSiteDisciplineFk, constructionSiteId, userInfo, currentTenant)}>
        Na obra
      </div>
    </div>
  );

const getDropdownImprimirRelatorio = (dispatch: React.Dispatch<any>, ConstructionSiteDisciplineFk: number, onRelatorio: ((tipoRelatorio: TipoRelatorio) => void)) => (
  <div className={styles['menuDropdown']} >
    <div
      className={styles['options']}
      onClick={() => onRelatorio('pdf')}>
      Imprimir listagem
    </div>
    <div
      className={styles['options']}
      onClick={() => onRelatorio('excel')}>
      Gerar Excel
    </div>
  </div >
);

const changeStatusFiles = (
  keys: number[],
  listFiles: IArquivoData[],
  dispatch: React.Dispatch<any>,
  status: FilePlotagemStatusEnum,
  constructionSiteDisciplineFk: number,
  constructionSiteId: number,
  userInfo?: IUserInforResponse,
  currentTenant?: ITenantData | null,
) => {
  const plottingFilesId = keys.map((key) => {
    return listFiles[key].PlottingFilesId
  });
  dispatch(PlotagemActions.updatePlotagemArquivosStatus({ 
    ConstructionSiteId: constructionSiteId, 
    ConstructionSiteDisciplineFk: constructionSiteDisciplineFk, 
    Status: status, 
    PlottingFilesId: plottingFilesId, 
    userInfoTracking: userInfo,
    currentTenantTracking: currentTenant, 
  }));
};

export const getArquivosRelatorio = (arquivos: IArquivoData[]) => {
  const helper: any = {};
  const result = arquivos.reduce(function (r: any, o) {

    if (o.Status !== 3) {
      const key: string = o.Status + '-' + o.Name;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].QtyCopies += o.QtyCopies;
      }
    }

    return r;
  }, []);

  return result;
}
