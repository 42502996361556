import styled from "styled-components";
import Modal from "Components/UI/Modal/Modal";

export const ModalStyled = styled(Modal)`
  .modalContainer {
    background: none !important;
  }
`;

export const FooterWrapper = styled.div`
  padding: 0px 16px 0px 16px;
`;

export const FormWrapper = styled.div`
  .title {
    background: ${({ theme }) => theme.colors.surface.surfaceOutline};
    border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    user-select: none;
    align-items: center;
  
    .spanSolicitacao {
      margin-top: 8px;
      margin-left: 15px;
      word-break: break-all;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }
  
    .icone {
      margin-top: 4px;
      margin-left: 10px;
    }
  }

  .Mails {
    min-width: auto;
    width: auto;
    background: ${({ theme }) => theme.colors.surface.surface} !important;
    border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    user-select: none;
    align-items: center;
    margin-top: 5px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .e-mail {
      margin-left: 10px;
    }
  }

  .MailsChecked {
    min-width: auto;
    width: auto;
    background: ${({ theme }) => `color-mix(in srgb, ${theme.colors.primary.primaryPressed}, ${theme.colors.surface.surface})`};
    border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    user-select: none;
    align-items: center;
    margin-top: 5px;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .e-mail {
      margin-left: 10px;
    }
  }

  .formNovoEmail {
    margin-top: 16px !important;

    .NovoEmailHidde {
      width: 100%;
      display: none !important;
    }

    .fieldConfirmaAddEmail {
      float: left !important;
  
      input {
        height: 30px !important;
        width: 619px !important;
      }
    }

    .btConfirmaAddEmail {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 19px !important;
      margin-left: 15px;
    }
  }

  .addNovoEmail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.information.information};
    cursor: pointer;
  }
`;

export const TableWrapper = styled.div`
  padding: 20px 32px 10px 32px;

  thead>tr>td {
    background-color: ${({ theme }) => theme.colors.surface.surfaceOutline} !important;
    border-radius: 0px !important;
  }
`;
