import { FC } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import { Container } from "./styles";
import premiumImg from "Static/images/logo-crow-free.png";

interface IPremiumCard {
  role: number;
  onRedirect: (role: number) => void;
}

const PremiumCard: FC<IPremiumCard> = ({ role, onRedirect }) => {
  return (
    <Container>
      <img
        className="imgPremium"
        src={premiumImg}
        alt="Imagem Coroa Premium"
      />
      <span className="textTitle">
        {role === 1
          ? 'Dê um upgrade na sua conta'
          : 'Dê um upgrade na conta da sua empresa'
        }
      </span>
      <span className="textDescription">
        Para personalizar nomenclaturas por obra
      </span>
      <Button
        className="btnPremium"
        ispremium="true"
        onClick={() => onRedirect(role)}
      >
        <Icon
          icon="crown"
          customSize={12}
          className="iconCrow"
        />
        {role === 1
          ? 'Adquirir o plano Pro'
          : 'Solicitar o plano Pro'
        }
      </Button>
    </Container>
  )
}

export default PremiumCard;
