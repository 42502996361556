import { IGlobalReducerState } from '../Base/interface/IGlobalReducerState';

const getFixedPanels = (state: IGlobalReducerState) => state.panels.fixedPanels;
const getNotFixedPanels = (state: IGlobalReducerState) => state.panels.notFixedPanels;
const getPanelsLoading = (state: IGlobalReducerState) => state.panels.isLoading;
const getPanelsCreateLoading = (state: IGlobalReducerState) => state.panels.isCreateLoading;
const getPanelsUpdateLoading = (state: IGlobalReducerState) => state.panels.isUpdateLoading;
const getPanelsDeleteLoading = (state: IGlobalReducerState) => state.panels.isDeleteLoading;
const getNewPanelId = (state: IGlobalReducerState) => state.panels.newPanelId;

export { 
  getFixedPanels, 
  getNotFixedPanels,
  getPanelsLoading,
  getPanelsCreateLoading,
  getPanelsUpdateLoading,
  getPanelsDeleteLoading,
  getNewPanelId,
};
