import { useMemo, useState } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Icon, IconName } from "Components/UI";
import { DisciplineIconsEnum } from "Pages/Disciplinas";
import DisciplinesTooltip from "../../../AvatarsItems";
import { Badge } from "antd";
import {
  ITopicDnDResponse,
  ITopicStatus,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { getTypeIcon } from "Utils/ObraUtils";
import { dateToLocaleString, isExpiredDate } from "Utils/DateUtils";
import HighlighterWords from "Components/UI/HighlightWords";
import { handlePriority, handleVisibility } from "Utils/ActivitiesUtils";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import Tooltip from "Components/UI/Tooltip";
import ImagePreview from "Components/UI/ImagePreview";
import ImagePreviewGroup from "Components/UI/ImagePreviewGroup";
import {
  actionImgPrint,
  actionDownloadImage,
  actionImgOpenInNewWindow,
} from "Utils/TextUtils";
import HeaderActionsButtons from "Pages/Activities/Modals/ShowCreateActivity/components/Attachments/components/HeaderActionsButtons";
import { Container } from "./styles";

import imgPlaceholder from 'Static/images/image_placeholder.webp';

interface IItem {
  column?: ITopicStatus;
  activity: ITopicDnDResponse;
  overlay?: boolean;
  activeId: UniqueIdentifier | null;
  searchActivity: string;
  disabled?: boolean;
  theme: IMDTheme;
  showPreview?: boolean;
  previewForShow?: () => void;
  onPreviewVisible?: (value: boolean) => void;
}

export interface IPriorityOrVisibility {
  icon: IconName;
  label: string;
  color: string;
  size: number;
}

const Item = ({
  activity,
  column,
  overlay,
  activeId,
  searchActivity,
  disabled,
  theme,
  previewForShow,
  onPreviewVisible,
  showPreview,
}: IItem) => {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);
  const hasNotification = false;

  const currentAttachmentPreview = useMemo(() => {
    if (activity?.Attachments && activity.Attachments.length > 0) {
      return activity.Attachments[currentAttachmentIndex];
    }

    return null;
  }, [currentAttachmentIndex, activity]);

  const getDisciplineIcon = (name: string): IconName => {
    if ((DisciplineIconsEnum as any)[name]) {
      return (DisciplineIconsEnum as any)[name];
    }
    return 'menuContexto';
  };

  return (
    <Container
      borderColor={column?.Color}
      overlay={overlay && activeId === activity.Guid}
      disabled={disabled}
    >
      {activity?.Attachments && activity.Attachments.length > 0 && (
        <>
          {activity.Attachments.length === 1 && (
            <div className="singleImgContainer" onMouseDown={previewForShow}>
              <ImagePreview
                isUnique
                srcPreview={activity.Attachments[0].FileURL}
                className="img"
                src={activity.Attachments[0].Thumbnail128x128Url}
                srcName={activity.Attachments[0].File.Name}
                alt=""
                visible={showPreview}
                visibleOn
                onChangeVisible={onPreviewVisible}
                onError={(e) => e.currentTarget.src = imgPlaceholder}
                HeaderButtons={(
                  <HeaderActionsButtons
                    onPrint={() => {
                      actionImgPrint(activity.Attachments[0].FileURL);
                    }}
                    onDownload={() => {
                      actionDownloadImage(activity.Attachments[0].FileURL, activity.Attachments[0].File.Name);
                    }}
                    onOpen={() => {
                      actionImgOpenInNewWindow(activity.Attachments[0].FileURL);
                    }}
                  />
                )}
              />
              <span className="imgHover" />
            </div>
          )}
          {activity.Attachments.length > 1 && (
            <ImagePreviewGroup
              imagesForShow={activity.Attachments.map(att => att.FileURL)}
              srcName={currentAttachmentPreview?.File.Name}
              visible={showPreview}
              visibleOn
              onChangeVisible={onPreviewVisible}
              current={currentAttachmentIndex}
              HeaderButtons={(
                <HeaderActionsButtons
                  onPrint={() => {
                    if (currentAttachmentPreview) {
                      actionImgPrint(currentAttachmentPreview.FileURL);
                    }
                  }}
                  onDownload={() => {
                    if (currentAttachmentPreview) {
                      actionDownloadImage(currentAttachmentPreview.FileURL, currentAttachmentPreview.File.Name);
                    }
                  }}
                  onOpen={() => {
                    if (currentAttachmentPreview) {
                      actionImgOpenInNewWindow(currentAttachmentPreview.FileURL);
                    }
                  }}
                />
              )}
              onCurrentIndex={setCurrentAttachmentIndex}
            >
              <div className="multipleImgContainer" onMouseDown={previewForShow}>
                <img
                  className="img"
                  src={activity.Attachments[0].Thumbnail128x128Url}
                  alt=""
                  onError={(e) => e.currentTarget.src = imgPlaceholder}
                />
                <img
                  className="img"
                  src={activity.Attachments[1].Thumbnail128x128Url}
                  alt=""
                  onError={(e) => e.currentTarget.src = imgPlaceholder}
                />
                <span className="imgCount">
                  <span>{`+${activity.Attachments.length - 1}`}</span>
                </span>
                <span className="imgHover" />
              </div>
            </ImagePreviewGroup>
          )}
        </>
      )}
      <div className="cardHeader">
        <div className="cardHeaderLeft">
          <span className="cardTextHeader">{`#${activity.Identifier}`}</span>
          <Icon
            icon={getTypeIcon(activity.Type?.Name || '')}
            customSize={14}
            customColor={'#BEC2C6'}
            className="cardIconHeader"
          />
          <span className="cardTextHeader">{activity.Type?.Name}</span>
          {hasNotification && <Badge color="#FF3D57" className="dot" />}
        </div>
        {activity.ConstructionSiteDisciplines.length <= 0 &&
          <Tooltip
            placement="top"
            overlay="Erro no cadastro da atividade"
          >
            <span>
              <Icon
                icon="aviso"
                customSize={14}
                customColor={'#FF3D57'}
                className="cardIconAlarm"
              />
            </span>
          </Tooltip>
        }
      </div>
      <HighlighterWords
        text={activity.Title}
        wordSearched={searchActivity}
        maxLength={131}
        className="cardTitleText"
      />
      {(activity.TopicAssignedUsers.length > 0) && (
        <div className="cardAvatars">
          <DisciplinesTooltip users={activity.TopicAssignedUsers} />
        </div>
      )}
      {(activity.ConstructionSiteDisciplines.length > 0) && (
        <div className="cardDisciplinesWrapper">
          {activity.ConstructionSiteDisciplines.map((discipline, index) => (
            <div
              key={`${discipline.ConstructionSiteDisciplinesId}- ${index}`}
              className="cardDiscipline"
            >
              <Icon
                icon={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name)}
                customSize={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name) === 'menuContexto' ? 12 : 14}
                customColor={'#BEC2C6'}
              />
              <span className="cardDisciplineText">{discipline?.CustomName || discipline?.Discipline?.Name}</span>
            </div>
          ))}
        </div>
      )}
      {activity.DueDate && (
        <div className="cardDate">
          <Icon
            icon="calendar"
            customSize={16}
            customColor={isExpiredDate(activity.DueDate) ? '#E2574C' : '#BEC2C6'}
          />
          <span className="cardDateText">
            {dateToLocaleString(activity.DueDate)}
          </span>
        </div>
      )}
      <div className="cardTags">
        {(activity.Visibility || activity.Visibility === 0) && (
          <div className="cardTagVisibility">
            <Icon
              icon={handleVisibility(activity.Visibility, theme).icon}
              customSize={handleVisibility(activity.Visibility, theme).size}
              customColor={handleVisibility(activity.Visibility, theme).color}
            />
            <span className="cardTagText">
              {handleVisibility(activity.Visibility, theme).label}
            </span>
          </div>
        )}
        {activity.Priority && (
          <div className="cardTagPriority">
            <Icon
              icon={handlePriority(activity.Priority, theme).icon}
              customSize={handlePriority(activity.Priority, theme).size}
              customColor={handlePriority(activity.Priority, theme).color}
            />
            <span
              className="cardTagText"
              style={{ color: handlePriority(activity.Priority, theme).color }}
            >
              {handlePriority(activity.Priority, theme).label}
            </span>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Item;
