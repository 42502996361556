import React, { FC } from "react";

import styles from "./LoadingActivitiesExternalLinkContent.module.scss";

import SkeletonMain from "../LoadingMain";
import { Icon } from "Components/UI/Icon";

const LoadingActivitiesExternalLinkContent: FC = () => {
  const Skeleton = () => {
    return (
      <div className={styles['wrapper']}>
        <div>
          <SkeletonMain 
            width="100%" 
            height="16px"
            minWidth="75px" 
            borderRadius="2px"
            marginTop="10px"
          />
        </div>
        
        <hr className={styles['midleHr']} />
        <div className={styles['bodyForm']}>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="10px"
            />
            <SkeletonMain 
              width="100px" 
              height="16px"
              minWidth="100px" 
              borderRadius="2px"
              marginTop="10px"
              marginLeft="60px"
            />
          </div>
          <div className={styles['bodyArea']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="22px"
            />
            <div className={styles['bodyAreaRight']}>
              <SkeletonMain 
                width="100%" 
                height="12px"
                minWidth="75px" 
                borderRadius="2px"
                marginTop="22px"
              />
              <SkeletonMain 
                width="100%" 
                height="12px"
                minWidth="75px" 
                borderRadius="2px"
                marginTop="8px"
              />
              <SkeletonMain 
                width="50%" 
                height="12px"
                minWidth="75px" 
                borderRadius="2px"
                marginTop="8px"
              />
            </div>
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="24px"
            />
            <SkeletonMain 
              width="16px" 
              height="16px"
              minWidth="16px" 
              borderRadius="50%"
              marginTop="24px"
              marginLeft="60px"
            />
            <SkeletonMain 
              width="75px" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="4px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="24px"
            />
            <SkeletonMain 
              width="12px" 
              height="12px"
              minWidth="12px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="60px"
            />
            <SkeletonMain 
              width="75px" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="4px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="24px"
            />
            <SkeletonMain 
              width="12px" 
              height="12px"
              minWidth="12px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="60px"
            />
            <SkeletonMain 
              width="65px" 
              height="12px"
              minWidth="65px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="4px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="25px"
            />
            <SkeletonMain 
              width="12px" 
              height="12px"
              minWidth="12px" 
              borderRadius="2px"
              marginTop="25px"
              marginLeft="60px"
            />
            <SkeletonMain 
              width="55px" 
              height="12px"
              minWidth="55px" 
              borderRadius="2px"
              marginTop="25px"
              marginLeft="4px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="26px"
            />
            <SkeletonMain 
              width="12px" 
              height="12px"
              minWidth="12px" 
              borderRadius="2px"
              marginTop="26px"
              marginLeft="60px"
            />
            <SkeletonMain 
              width="35px" 
              height="12px"
              minWidth="35px" 
              borderRadius="2px"
              marginTop="26px"
              marginLeft="4px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="25px"
            />
            <SkeletonMain 
              width="100%" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginTop="25px"
              marginLeft="60px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="24px"
            />
            <SkeletonMain 
              width="100%" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="60px"
            />
          </div>
          <div className={styles['bodyDefault']}>
            <SkeletonMain 
              width="50px" 
              height="12px"
              minWidth="50px" 
              borderRadius="2px"
              marginTop="24px"
            />
            <SkeletonMain 
              width="100%" 
              height="12px"
              minWidth="75px" 
              borderRadius="2px"
              marginTop="24px"
              marginLeft="60px"
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles['mainWrapper']}>
        <Skeleton />
    </div>
  )
}

export default LoadingActivitiesExternalLinkContent;