import * as Yup from 'yup';

const schema = Yup.object().shape({
  Nome: Yup.string()
    .required('O campo deve ser preenchido'),

    Telefone: Yup.string()
    .required('O campo deve ser preenchido'),

});

export default schema;

