import { FC, ReactNode, useState } from 'react';
import { InfoWrapper, Wrapper } from './styles';

export interface IFileDrop {
  maxCount?: number;
  borderRadius?: number;
  info?: ReactNode;
  isDisabled?: boolean;
  onDropFiles: (files: File | File[]) => void;
  onFocus?: (focus: boolean) => void;
}

const FileDrop: FC<IFileDrop> = ({
  children,
  maxCount,
  borderRadius,
  info,
  isDisabled,
  onDropFiles,
  onFocus,
}) => {
  const [displayZone, setDisplayZone] = useState(false);

  const onDragOver = (ev: any) => {
    ev.preventDefault();

    if (isDisabled) return;
  };

  const onDragEnter = (ev: any) => {
    if (isDisabled) return;

    if (ev.dataTransfer.types.includes('Files')) {
      setDisplayZone(true);
      if (onFocus) onFocus(true);
    }
  };

  const onDragLeave = (ev: any) => {
    if (isDisabled) return;

    setDisplayZone(false);
    if (onFocus) onFocus(false);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();

    if (isDisabled) return;
    setDisplayZone(false);
    if (onFocus) onFocus(false);

    if (maxCount === 1) {
      onDropFiles(ev.dataTransfer.files[0]);

    } else {
      if (ev.dataTransfer.files.length > 0) {
        const dropFiles: File[] = [];

        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          dropFiles.push(ev.dataTransfer.files[i]);
        }
        onDropFiles(dropFiles);
      }
    }
  };

  return (
    <Wrapper onDragEnter={onDragEnter}>
      {children}
      {!isDisabled && (
        <InfoWrapper
          borderRadius={borderRadius}
          hidden={!displayZone}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          {info}
        </InfoWrapper>
      )}
    </Wrapper>
  );
};

export default FileDrop;
