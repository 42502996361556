import React, { FC, useEffect } from 'react';

import styles from './DisciplinasTab.module.scss';
import schema from './disciplinaTab.schema';
import DisciplinaItem from './DisciplinaItem';
import Button from '../../../../../Components/UI/Button';
import { IconName } from '../../../../../Components/UI/Icon';
import Form from '../../../../../Components/UI/Form/Form';
import Field from '../../../../../Components/UI/Field';
import { IDiscipline, IDisciplineData } from '../../../../../Data/interfaces/Obra/IObraData';
import { connect, ConnectedProps } from 'react-redux';
import { IGlobalReducerState } from '../../../../../Store/Base/interface/IGlobalReducerState';

const disciplineIcons = new Map<string, string>([
  ['maleta-icons icon-ic-disciplina-architeture', 'disciplinaArquitetura'],
  ['maleta-icons icon-ic-disciplina-block', 'disciplinaEstrutura'],
  ['maleta-icons icon-ic-disciplina-energy', 'disciplinaEletrica'],
  ['maleta-icons icon-ic-disciplina-hidro', 'disciplinaHidrossanitaria'],
  ['maleta-icons icon-ic-disciplina-mec', 'disciplinaMecanica'],
  ['maleta-icons icon-ic-disciplina-foundation', 'disciplinaFundacao'],
  ['maleta-icons icon-ic-disciplina-cold', 'diciplinaAr'],
  ['maleta-icons icon-ic-disciplina-fire', 'disciplinaIncendio'],
  ['menuContexto', 'menuContexto'],
]);

interface IDiscipliansTab {
  isSubmited?: boolean;
  onSubmit: (props?: object, error?: string[]) => void;
}

const DiscipliansTab: FC<Props> = ({ isSubmited, onSubmit, disciplines, disciplinesData }) => {
  const [disciplinesList, setDisciplinesList] = React.useState<IDisciplineData[]>([]);

  useEffect(() => {
    const list: IDisciplineData[] = JSON.parse(JSON.stringify(disciplinesData));
    disciplines?.forEach((discipline) => {
      const disciplineData = list?.find((disciplineChecked) =>
        !disciplineChecked.IsCustom && disciplineChecked.DisciplineId === discipline.DisciplineFk);

      if (disciplineData) {
        disciplineData.IsChecked = true;
        disciplineData.Disabled = true;
      } else {
        list.push({
          Name: 'Personalizado',
          CustomDispName: discipline.CustomName,
          DisciplineId: 9,
          Icon: 'menuContexto',
          IsChecked: true,
          IsCustom: true,
          Disabled: true
        });
      }
    });
    setDisciplinesList([...list]);
  }, [disciplines, disciplinesData]);

  const onCheck = (index: number) => {
    disciplinesList[index].IsChecked = !disciplinesList[index].IsChecked;
    setDisciplinesList(JSON.parse(JSON.stringify(disciplinesList)));
  };

  const addDiscipline = (data: any) => {
    const newDiscipline: IDisciplineData = {
      DisciplineId: 9,
      Name: 'Personalizado',
      CustomDispName: data.CustomDispName,
      IsChecked: true,
      IsCustom: true,
      Icon: 'menuContexto',
    };
    setDisciplinesList([...disciplinesList, newDiscipline]);
  };

  const hasErrors = () => {
    const withoutDisciplines = disciplinesList.find((discipline) => discipline.IsChecked) === undefined;
    if (withoutDisciplines) {
      return ['Pelo menos uma disciplina deve ser selecionada'];
    }
  };

  if (isSubmited) {
    const errors = hasErrors();
    if (errors !== undefined) {
      onSubmit(undefined, errors);
    } else {
      const Disciplines: IDiscipline[] = disciplinesList
        .filter((discipline) => discipline.IsChecked)
        .map((discipline) => ({
          CustomName: discipline.CustomDispName,
          DisciplineFk: discipline.DisciplineId,
          IsCustom: discipline.IsCustom,
        }));

      onSubmit({ Disciplines: [...Disciplines] });
    }
  }

  return (
    <div className={styles['disciplinasWrapper']}>
      <div>
        <span className={styles['titleTab']}>Selecione as disciplinas desejadas</span>

        <div className={styles['disciplinasList']}>
          {
            disciplinesList.map((disciplina, index) => (
              <DisciplinaItem
                key={index}
                disciplina={disciplina.CustomDispName ?? disciplina.Name}
                icon={disciplineIcons.get(disciplina.Icon) as IconName}
                checked={disciplina.IsChecked}
                disabled={disciplina.Disabled}
                onCheck={() => onCheck(index)}
              />
            ))
          }
        </div>
      </div>

      <div className={styles['novaDisciplinaWrapper']}>
        <span className={styles['titleTab']}>Não achou a disciplina desejada? Configure uma nova disciplina</span>
        <Form onSubmit={addDiscipline} schema={schema} resetForm={true}>
          <div className={styles['formNovaDisciplina']}>
            <Field name='CustomDispName' label='Nova disciplina' />
            <Button type='primary' htmlType='submit' height={34}>
              <span className={styles['titleButton']}>Criar</span>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  disciplines: state.obra.Obra.Disciplines,
  disciplinesData: state.obra.Disciplines,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IDiscipliansTab;

export default connector(DiscipliansTab);
