import React, { FC } from 'react';
import { Container } from './styles';

export interface IListContainer {
  children?: React.ReactNode;
  height?: number | string;
  className?: string;
  onClick?: () => void;
}

const ListContainer: FC<IListContainer> = ({ children, height = 70, className = '', onClick }) => {
  return (
    <Container
      className={className || ''}
      style={{ height }}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

export default ListContainer;
