import { MultiDomainActionKeys, MultiDomainUnion } from './MultiDomain.action';
import { IMultiDomainState } from './interface/IMultiDomainState';
import light from 'Static/styles/themes/light';
import defaultDomain from 'Static/multidomain/default.json';
import { MultiDomainPersonalizationStatusEnum } from 'Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse';

const initialState: IMultiDomainState = {
  defaultInfo: defaultDomain,
  Status: defaultDomain.Status,
  DomainName: defaultDomain.DomainName,
  Tenant: defaultDomain.Tenant,
  LoginBannerUrl: undefined,
  LogoUrl: undefined,
  theme: {
    colors: light,
    font: undefined,
  },
  imgUploadState: undefined,
  isLoading: false,
};

const themeReducer = (state = initialState, action: MultiDomainUnion): IMultiDomainState => {
  switch (action.type) {
    case MultiDomainActionKeys.MULTI_DOMAIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MultiDomainActionKeys.MULTI_DOMAIN_SUCCESS:
      const newConfigDomain = action.payload;
      let newStateStatus = state.Status; 
      let newStateDomainName = state.DomainName; 
      let newStateTenant = state.Tenant; 
      let newStateLoginBannerUrl = state.LoginBannerUrl; 
      let newStateLogoUrl = state.LogoUrl; 
      const newStateTheme = {...state.theme}; 

      if (newConfigDomain.Status) {
        newStateStatus = newConfigDomain.Status;

        if (
          newConfigDomain.Status === MultiDomainPersonalizationStatusEnum.ConfiguracaoConcluida || 
          newConfigDomain.Status === MultiDomainPersonalizationStatusEnum.Padrao
        ) {
          if (newConfigDomain.DomainName) {
            newStateDomainName = newConfigDomain.DomainName;
          }

          if (newConfigDomain.Tenant) {
            newStateTenant = newConfigDomain.Tenant;
          }
    
          if (newConfigDomain.PrimaryColor) {
            newStateTheme.colors.primary.primary = newConfigDomain.PrimaryColor;
          }

          if (newConfigDomain.Status !== MultiDomainPersonalizationStatusEnum.Padrao) {  
            if (newConfigDomain.LoginBannerUrl) {
              newStateLoginBannerUrl = newConfigDomain.LoginBannerUrl;
            }
  
            if (newConfigDomain.LogoUrl) {
              newStateLogoUrl = newConfigDomain.LogoUrl;
            }

          } else {
            newStateLoginBannerUrl = undefined;
            newStateLogoUrl = undefined;
          }

          if (newConfigDomain.SecondaryColor) {
            newStateTheme.colors.tertiary.tertiary = newConfigDomain.SecondaryColor;
          }
        }
      }

      return {
        ...state,
        Status: newStateStatus,
        DomainName: newStateDomainName,
        Tenant: newStateTenant,
        LoginBannerUrl: newStateLoginBannerUrl,
        LogoUrl: newStateLogoUrl,
        theme: {...newStateTheme},
        isLoading: false,
      };
    case MultiDomainActionKeys.MULTI_DOMAIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case MultiDomainActionKeys.TENANT_PERSONALIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MultiDomainActionKeys.TENANT_PERSONALIZATION_SUCCESS:
      const newConfigTenantPersonalization = action.payload;
      let newStateTenantPersonalizationStatus = state.Status; 
      let newStateTenantPersonalizationDomainName = state.DomainName; 
      let newStateTenantPersonalizationTenant = state.Tenant; 
      let newStateTenantPersonalizationLoginBannerUrl = state.LoginBannerUrl; 
      let newStateTenantPersonalizationLogoUrl = state.LogoUrl; 
      const newStateTenantPersonalizationTheme = {...state.theme}; 

      if (newConfigTenantPersonalization.Status) {
        newStateTenantPersonalizationStatus = newConfigTenantPersonalization.Status;
      }

      if (newConfigTenantPersonalization.DomainName) {
        newStateTenantPersonalizationDomainName = newConfigTenantPersonalization.DomainName;
      }

      if (newConfigTenantPersonalization.Tenant) {
        newStateTenantPersonalizationTenant = newConfigTenantPersonalization.Tenant;
      }

      if (newConfigTenantPersonalization.PrimaryColor) {
        newStateTenantPersonalizationTheme.colors.primary.primary = newConfigTenantPersonalization.PrimaryColor;
      }

      if (newConfigTenantPersonalization.Status !== MultiDomainPersonalizationStatusEnum.Padrao) {  
        if (newConfigTenantPersonalization.LoginBannerUrl) {
          newStateTenantPersonalizationLoginBannerUrl = newConfigTenantPersonalization.LoginBannerUrl;
        }

        if (newConfigTenantPersonalization.LogoUrl) {
          newStateTenantPersonalizationLogoUrl = newConfigTenantPersonalization.LogoUrl;
        }
      }

      if (newConfigTenantPersonalization.SecondaryColor) {
        newStateTenantPersonalizationTheme.colors.tertiary.tertiary = newConfigTenantPersonalization.SecondaryColor;
      }

      return {
        ...state,
        Status: newStateTenantPersonalizationStatus,
        DomainName: newStateTenantPersonalizationDomainName,
        Tenant: newStateTenantPersonalizationTenant,
        LoginBannerUrl: newStateTenantPersonalizationLoginBannerUrl,
        LogoUrl: newStateTenantPersonalizationLogoUrl,
        theme: {...newStateTenantPersonalizationTheme},
        isLoading: false,
      };
    case MultiDomainActionKeys.TENANT_PERSONALIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_SUCCESS:
      const newUpdateTenantPersonalization = action.payload;
      let newStateUpdateStatus = state.Status; 
      let newStateUpdateDomainName = state.DomainName; 
      let newStateUpdateTenant = state.Tenant; 
      const newStateUpdateTheme = {...state.theme};

      if (newUpdateTenantPersonalization.Status) {
        newStateUpdateStatus = newUpdateTenantPersonalization.Status;
      }

      if (newUpdateTenantPersonalization.Tenant) {
        newStateUpdateTenant = newUpdateTenantPersonalization.Tenant;
      }

      if (newUpdateTenantPersonalization.DomainName) {
        newStateUpdateDomainName = newUpdateTenantPersonalization.DomainName;
      }

      if (newUpdateTenantPersonalization.PrimaryColor) {
        newStateUpdateTheme.colors.primary.primary = newUpdateTenantPersonalization.PrimaryColor;
      }

      if (newUpdateTenantPersonalization.SecondaryColor) {
        newStateUpdateTheme.colors.tertiary.tertiary = newUpdateTenantPersonalization.SecondaryColor;
      }

      return {
        ...state,
        Status: newStateUpdateStatus,
        DomainName: newStateUpdateDomainName,
        Tenant: newStateUpdateTenant,
        LoginBannerUrl:  newUpdateTenantPersonalization.LoginBannerUrl,
        LogoUrl: newUpdateTenantPersonalization.LogoUrl,
        theme: {...newStateUpdateTheme},
        isLoading: false,
      };
    case MultiDomainActionKeys.UPDATE_TENANT_PERSONALIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_REQUEST:
      return {
        ...state,
        imgUploadState: undefined,
        isLoading: true,
      };
    case MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_SUCCESS:
      const newUploadImageTenantPersonalization = action.payload;
      let newStateUploadStatus = state.Status; 
      let newStateUploadLoginBannerUrl = state.LoginBannerUrl; 
      let newStateUploadLogoUrl = state.LogoUrl; 

      if (newUploadImageTenantPersonalization.Status) {
        newStateUploadStatus = newUploadImageTenantPersonalization.Status;
      }

      if (newUploadImageTenantPersonalization.Status !== MultiDomainPersonalizationStatusEnum.Padrao) {  
        if (newUploadImageTenantPersonalization.LoginBannerUrl) {
          newStateUploadLoginBannerUrl = newUploadImageTenantPersonalization.LoginBannerUrl;
        }

        if (newUploadImageTenantPersonalization.LogoUrl) {
          newStateUploadLogoUrl = newUploadImageTenantPersonalization.LogoUrl;
        }
      }

      return {
        ...state,
        Status: newStateUploadStatus,
        LoginBannerUrl: newStateUploadLoginBannerUrl,
        LogoUrl: newStateUploadLogoUrl,
        isLoading: false,
      };
    case MultiDomainActionKeys.UPLOAD_TENANT_PERSONALIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        imgUploadState: undefined,
        error: action.payload,
      };
    case MultiDomainActionKeys.UPDATE_UPLOAD_TENANT_PERSONALIZATION:
      const updateUploadImageTenantPersonalization = action.payload;

      return {
        ...state,
        imgUploadState: {...updateUploadImageTenantPersonalization},
        isLoading: false,
      };

    case MultiDomainActionKeys.CHECK_DNS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MultiDomainActionKeys.CHECK_DNS_SUCCESS:
      const newConfigDNSDomain = action.payload;
      let newConfigDNSDomainStatus = state.Status; 
      let newConfigDNSDomainDomainName = state.DomainName; 
      let newConfigDNSDomainTenant = state.Tenant; 
      let newConfigDNSDomainLoginBannerUrl = state.LoginBannerUrl; 
      let newConfigDNSDomainLogoUrl = state.LogoUrl; 
      const newConfigDNSDomainTheme = {...state.theme}; 

      if (newConfigDNSDomain.Status) {
        newConfigDNSDomainStatus = newConfigDNSDomain.Status;
      }

      if (newConfigDNSDomain.DomainName) {
        newConfigDNSDomainDomainName = newConfigDNSDomain.DomainName;
      }

      if (newConfigDNSDomain.Tenant) {
        newConfigDNSDomainTenant = newConfigDNSDomain.Tenant;
      }

      if (newConfigDNSDomain.PrimaryColor) {
        newConfigDNSDomainTheme.colors.primary.primary = newConfigDNSDomain.PrimaryColor;
      }

      if (newConfigDNSDomain.Status !== MultiDomainPersonalizationStatusEnum.Padrao) {  
        if (newConfigDNSDomain.LoginBannerUrl) {
          newConfigDNSDomainLoginBannerUrl = newConfigDNSDomain.LoginBannerUrl;
        }

        if (newConfigDNSDomain.LogoUrl) {
          newConfigDNSDomainLogoUrl = newConfigDNSDomain.LogoUrl;
        }
      }

      if (newConfigDNSDomain.SecondaryColor) {
        newConfigDNSDomainTheme.colors.tertiary.tertiary = newConfigDNSDomain.SecondaryColor;
      }

      return {
        ...state,
        Status: newConfigDNSDomainStatus,
        DomainName: newConfigDNSDomainDomainName,
        Tenant: newConfigDNSDomainTenant,
        LoginBannerUrl: newConfigDNSDomainLoginBannerUrl,
        LogoUrl: newConfigDNSDomainLogoUrl,
        theme: {...newConfigDNSDomainTheme},
        isLoading: false,
      };
    case MultiDomainActionKeys.CHECK_DNS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

  case MultiDomainActionKeys.TOOGLE_COLORS_THEME:
    const newTheme = {
      ...state.theme.colors,
      ...action.payload,
    };

    return {
      ...state,
      ...newTheme,
    };

  default:
    return state;
  }
};

export default themeReducer;
