import {
  UserRegisterAction,
  AuthActions,
  UserLoginAction,
  UserForgotPasswordAction,
  UserResetPasswordAction,
  UserRegisterGoogleAction,
  UserLoginSuccessAction,
  UpdateBillingDataAction
} from './Auth.actions';
import { call, put } from 'redux-saga/effects';
import { AuthApi } from '../../Data/Auth.api';
import { history } from '../';
import { AuthUtils } from '../../Utils/AuthUtils';
import {
  loadError,
  createError,
  SEND_EMAIL_RESET_PASSWORD_MESSAGE,
  PASSWORD_CHANGED_SUCCESSFULLY,
  updateSuccess,
  updateError
} from '../../Utils/Messages';
import toastHandler from '../../Utils/toastHandler';
import { Mixpanel } from 'Utils/MixPanel';

export function* signUp({ payload }: UserRegisterAction) {
  try {
    const { data } = yield call(AuthApi.signUp, payload);
    const messageSuccess = payload.tenantInviteEmailHash
      ? 'Conta criada com sucesso'
      : 'Verificar o email de confirmação';
    yield put(AuthActions.userRegisterSuccess(messageSuccess));

    Mixpanel.identify(data.data.Id);
    Mixpanel.track({
      name: 'USER_SIGNUP', 
      props: {
        userId: data.data.Id,
        userRole: data.info.currentRoleFk,
        companyPositionId: payload?.companyPositionId,
        invited: !!payload?.tenantInviteEmailHash,
      }
    });

    toastHandler.showSuccess(messageSuccess);
    history.push('/login');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : createError('conta');
    toastHandler.showError(error);
    yield put(AuthActions.userRegisterFailure(error));

    Mixpanel.track({
      name: 'USER_SIGNUP_ERROR', 
      props: {
        companyPositionId: payload?.companyPositionId,
        invited: !!payload?.tenantInviteEmailHash,
      }
    });
  }
}

export function* listCompanyPosition() {
  try {
    const { data } = yield call(AuthApi.listCompanyPosition);
    yield put(AuthActions.listCompanyPositionSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : createError('conta');
    toastHandler.showError(error);
    yield put(AuthActions.listCompanyPositionFailure(error));
  }
}

export function* signUpGoogle({ payload }: UserRegisterGoogleAction) {
  try {
    const { data } = yield call(AuthApi.signUpGoogle, payload);

    const messageSuccess = payload.TenantInviteEmailHash
      ? 'Conta criada com sucesso'
      : 'Verificar o email de confirmação';
    yield put(AuthActions.userRegisterSuccess(messageSuccess));

    Mixpanel.identify(data.info.userId);
    Mixpanel.track({
      name: 'USER_SIGNUP', 
      props: {
        userId: data.info.userId,
        userRole: data.info.currentRoleFk,
        companyPositionId: payload?.CompanyPositionId,
        invited: !!payload?.TenantInviteEmailHash,
      }
    });
    
    toastHandler.showSuccess(messageSuccess);
    history.push('/login');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao logar com o google';
    toastHandler.showError(error);
    yield put(AuthActions.userLoginFailure(error));
    yield put(AuthActions.userRegisterGoogleFailure(error));

    Mixpanel.track({
      name: 'USER_SIGNUP_ERROR', 
      props: {
        companyPositionId: payload?.CompanyPositionId,
        invited: !!payload?.TenantInviteEmailHash,
      }
    });
  }
}

export function* signIn({ payload }: UserLoginAction) {
  try {
    const { beforeUrl, password, username, TenantInviteEmailHash } = payload;

    const { data } = yield call(AuthApi.signIn, {
      password,
      username,
      TenantInviteEmailHash
    });
    const loginResponse = JSON.parse(data.data);

    history.push('/obras');
    yield put(AuthActions.userLoginSuccess({ beforeUrl, ...loginResponse }));
    yield put(AuthActions.getUserInfoSuccess(data.info));

  } catch (e: any) {
    const error =
      e.errors && e.errors.length
        ? e.errors[0].Message
        : 'Ocorreu um erro ao realizar login';
    toastHandler.showError(error);
    yield put(AuthActions.userLoginFailure(error));
  }
}

export function* forgotPassword({ payload }: UserForgotPasswordAction) {
  try {
    yield call(AuthApi.forgotPassword, payload);
    yield put(AuthActions.userForgotPasswordSuccess(SEND_EMAIL_RESET_PASSWORD_MESSAGE));
    toastHandler.showSuccess(SEND_EMAIL_RESET_PASSWORD_MESSAGE);
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao tentar recuperar a senha';
    toastHandler.showError(error);
    yield put(AuthActions.userForgotPasswordFailure(error));
  }
}

export function* resetPassword({ payload }: UserResetPasswordAction) {
  try {
    yield call(AuthApi.resetPassword, payload);
    yield put(AuthActions.userResetPasswordSuccess(PASSWORD_CHANGED_SUCCESSFULLY));
    toastHandler.showSuccess(PASSWORD_CHANGED_SUCCESSFULLY);
    history.push('/login');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao tentar alterar a sua senha';
    toastHandler.showError(error);
    yield put(AuthActions.userResetPasswordFailure(error));
  }
}

export function* getUserInfo() {
  try {
    const { data } = yield call(AuthApi.getUserInfo);
    const userInfo = data.data;

    AuthUtils.setLoggedUser({
      ...AuthUtils.getLoggedUser(),
      plan: userInfo?.UserStoreItem?.StorePlan?.Name,
      currentTenant: userInfo.CurrentTenantContext,
      nome: userInfo.Nome,
      email: userInfo.Email,
      userImage: userInfo.OriginalImageUrl,
      userId: userInfo.Id,
      currentIsFreemium: userInfo.CurrentIsFreemium,
      companyPositionId: userInfo.CompanyPosition.CompanyPositionId,
      companyPositionType: userInfo.CompanyPosition.Type,
      currentRoleFk: userInfo.CurrentRoleFk,
      isRegisterComplete: userInfo.isRegisterComplete,
      userHash: userInfo.UserHash
    });
    yield put(AuthActions.getUserInfoSuccess(userInfo));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('informações do usuário');
    // Do not fire errors on login page
    if (window.location.href.indexOf('login') === -1) {
      toastHandler.showError(error);
    }
    yield put(AuthActions.getUserInfoFailure(error));
  }
}

export function* loginSuccess({ payload }: UserLoginSuccessAction) {
  const { beforeUrl } = payload;

  AuthUtils.setLoggedUser({
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
    expiresIn: payload.expires_in
  });

  yield put(AuthActions.getUserInfo());

  if (
    !beforeUrl ||
    beforeUrl === '/login' ||
    beforeUrl?.includes('/login') ||
    beforeUrl === '/'
  ) {
    history.push('/obras');
  } else {
    window.location.replace(beforeUrl);
  }
}

export function* updateBillingData({ payload }: UpdateBillingDataAction) {
  try {
    const { data } = yield call(AuthApi.updateBillingData, payload);

    yield put(AuthActions.updateBillingDataSuccess(data.data));
    toastHandler.showSuccess(updateSuccess('dados de faturamento'));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : updateError('dados de faturamento');
    toastHandler.showError(error);
    yield put(AuthActions.updateBillingDataFailure(error));
  }
}
