import React, { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import styles from "./ConfirmDeleteModal.module.scss";

interface IConfirmDeleteModal {
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  activitieIdentifier: string;
}

const ConfirmDeleteModal: FC<IConfirmDeleteModal> = ({
  visible,
  loading,
  onCancel,
  onConfirm,
  activitieIdentifier,
}) => {
  const footerConfirmButtons = (
    <div className={styles['buttons']}>
      <Button
        onClick={onCancel}
        type="text"
        className={styles['buttonCancel']}
      >
        Cancelar
      </Button>
      <Button
        id="deleteBtn"
        type="primary"
        htmlType="submit"
        onClick={onConfirm}
        className={styles['buttonSubmit']}
        loading={loading}
        danger
      >
        Excluir atividade
      </Button>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerConfirmButtons}
      footerActive={false}
      width={400}
      customClassName
    >
      <div className={styles['wrapper']}>
        <span className={styles['title']}>
          Exclusão de atividade
        </span>
        <hr className={styles['midleHr']} />
        <span className={styles['description']}>
          Você tem certeza que deseja excluir a atividade <b>{`#${activitieIdentifier}`}</b>?
        </span>
        <span className={styles['description']}>
          Essa ação é <b>permanente</b> e não pode ser desfeita
        </span>
      </div>
      <hr className={styles['footerHr']} />
    </Modal>
  )
}

export default ConfirmDeleteModal;
